import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from 'reactstrap';
import SvgTournees from '../../SvgComponents/TourneesVertes';
import SvgTelephone from '../../SvgComponents/TelephoneVert';
import RecycledList from 'react-recycled-scrolling';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TaskDisplay from './TaskComponent/TaskDisplay';
import BurgerHeader from '../../SvgComponents/BurgerHeader';
import ValidationVert from '../../SvgComponents/ValidationVert';
import { withTranslation } from 'react-i18next';

const styleFilter: any = {
  backgroundColor: 'lightgrey',
  borderRadius: '50%',
  zIndex: '10',
  textAlign: 'center',
  height: '3em',
  width: '3em',
  cursor: 'pointer',
};

class Column extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      checked: false,
      openFilter: {
        round: false,
        device: false,
      },
    };

    this.getAllCommunication = this.getAllCommunication.bind(this);
    this.onClickCancelAll = this.onClickCancelAll.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  getAllCommunication() {
    const { getAllCommunication } = this.props;
    getAllCommunication(null);
  }

  onClickCancelAll() {
    const { onClickCancelAll, column } = this.props;
    onClickCancelAll(column.id);
  }

  handleScroll = e => {};

  myRef = React.createRef();

  render() {
    const {
      allTasks,
      column,
      taskLabel,
      removeTask,
      onDeleteAdd,
      onClickBorderLine,
      onStart,
      taskStarting,
      unDropColumn,
      multiDragSelect,
      multiDisplay,
      uncheckedTask,
      checkedDisabled,
      checkedIsDisabled,
      checked,
      delAffect,
      addAffect,
      onClickCancelAll,
      getAllCommunication,
      unDropAffect,
      loadingList,
      getIdRound,
      labels,
      isOverring,
      beforeStart,
      isOverContent,
      onDragStart,
      onDragEnd,
      onBeforeDragStart,
      updateDrop,
      dragStop,
      taskOverring,
      handleFilterChange,
      tasks,
      updateTriMod,
      columnFiltered,
      isAffect,
      switchToPartial,
      partiel,
      t,
      filter,
    } = this.props;
    const { scrollTop, openFilter } = this.state;
    const { title } = column;
    const columnTitle = { tournees: t('all.round.round_plural'), portables: t('all.device.phone_plural') };
    let noDrop = false;
    if (unDropColumn === column.id) {
      noDrop = true;
    }
    let columnChecked = false;
    if (checkedIsDisabled === column.id) {
      if (multiDisplay.length === 0) {
        columnChecked = false;
      } else {
        columnChecked = true;
      }
    }

    const attrList = tasks.map((task: any, index: any) => ({
      task: task,
      index: index,
      labelTask: taskLabel,
      column: column,
      removeTask: removeTask,
      onDeleteAdd: onDeleteAdd,
      fctBorder: onClickBorderLine,
      onStart: onStart,
      taskStarting: taskStarting,
      multiSelect: checked,
      noDrop: noDrop,
      multiDragSelect: multiDragSelect,
      multiDisplay: multiDisplay,
      uncheckedTask: uncheckedTask,
      checkedDisabled: checkedDisabled,
      checkedIsDisabled: columnChecked,
      delAffect: delAffect,
      addAffect: addAffect,
      allTasks: allTasks,
      onClickCancelAll: onClickCancelAll,
      getAllCommunication: getAllCommunication,
      unDropAffect: unDropAffect,
      getIdRound: getIdRound,
      labels: labels,
      isOverring: isOverring,
      beforeStart: beforeStart,
      isOverContent: isOverContent,
      onDragStart: onDragStart,
      onDragEnd: onDragEnd,
      onBeforeDragStart: onBeforeDragStart,
      updateDrop: updateDrop,
      dragStop: dragStop,
      taskOverring: taskOverring,
      openFilter: openFilter,
      affectList: isAffect,
      switchToPartial: switchToPartial,
      partiel: partiel,
    }));
    return (
      <div
        className="col-md-6"
        id="column-drag"
        style={{
          paddingRight: title === columnTitle.tournees ? '5px' : '15px',
          paddingLeft: title !== columnTitle.tournees ? '7.5px' : '15px',
          zIndex: title === columnTitle.tournees ? 3 : 2,
        }}
      >
        <div className="information-container-header">
          {title === columnTitle.tournees && (
            <div className="header-drag-column">
              <div className={'title'}>
                <SvgTournees fill="#31c6b3" height="2.5em" />
                <h3>{columnTitle.tournees}</h3>
              </div>
              <Input
                onChange={(e: any) => handleFilterChange(e, true)}
                placeholder={t('all.filter.round_filter_plural')}
                defaultValue={filter.round}
              />
              <div style={{ zIndex: 10, marginLeft: '10px' }}>
                <UncontrolledDropdown className="float-right">
                  <DropdownToggle nav>
                    <BurgerHeader fill="#31c6b3" height="1.5em" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => updateTriMod('default', column.id)}>
                      {t('columns.text.date_added')}
                      {columnFiltered.col1 === 'default' && !columnFiltered.inversed1 && (
                        <ValidationVert fill={'#31c6b3'} height={'1em'} style={{ marginLeft: '3ch' }} />
                      )}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => updateTriMod('code', column.id, false)}>
                      {t('columns.text.code_abc_order')}
                      {columnFiltered.col1 === 'code' && !columnFiltered.inversed1 && (
                        <ValidationVert fill={'#31c6b3'} height={'1em'} style={{ marginLeft: '3ch' }} />
                      )}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => updateTriMod('code', column.id, true)}>
                      {t('columns.text.code_abc_disorder')}
                      {columnFiltered.col1 === 'code' && columnFiltered.inversed1 && (
                        <ValidationVert fill={'#31c6b3'} height={'1em'} style={{ marginLeft: '3ch' }} />
                      )}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => updateTriMod('libelle', column.id, false)}>
                      {t('columns.text.wording_abc_order')}
                      {columnFiltered.col1 === 'libelle' && !columnFiltered.inversed1 && (
                        <ValidationVert fill={'#31c6b3'} height={'1em'} style={{ marginLeft: '3ch' }} />
                      )}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => updateTriMod('libelle', column.id, true)}>
                      {t('columns.text.wording_abc_disorder')}
                      {columnFiltered.col1 === 'libelle' && columnFiltered.inversed1 && (
                        <ValidationVert fill={'#31c6b3'} height={'1em'} style={{ marginLeft: '3ch' }} />
                      )}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => updateTriMod('actions', column.id, false)}>
                      {t('columns.text.actions_max_to_min')}
                      {columnFiltered.col1 === 'actions' && !columnFiltered.inversed1 && (
                        <ValidationVert fill={'#31c6b3'} height={'1em'} style={{ marginLeft: '3ch' }} />
                      )}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => updateTriMod('actions', column.id, true)}>
                      {t('columns.text.actions_min_to_max')}
                      {columnFiltered.col1 === 'actions' && columnFiltered.inversed1 && (
                        <ValidationVert fill={'#31c6b3'} height={'1em'} style={{ marginLeft: '3ch' }} />
                      )}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => updateTriMod('charges', column.id, false)}>
                      {t('columns.text.loaded_max_to_min')}
                      {columnFiltered.col1 === 'charges' && !columnFiltered.inversed1 && (
                        <ValidationVert fill={'#31c6b3'} height={'1em'} style={{ marginLeft: '3ch' }} />
                      )}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => updateTriMod('charges', column.id, true)}>
                      {t('columns.text.loaded_min_to_max')}
                      {columnFiltered.col1 === 'charges' && columnFiltered.inversed1 && (
                        <ValidationVert fill={'#31c6b3'} height={'1em'} style={{ marginLeft: '3ch' }} />
                      )}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          )}
          {title === columnTitle.portables && (
            <div className="header-drag-column">
              <div className={'title'}>
                <SvgTelephone fill="#31c6b3" height="2.5em" />
                <h3>{columnTitle.portables}</h3>
              </div>
              <Input
                onChange={(e: any) => handleFilterChange(e, false)}
                placeholder={t('all.filter.device_filter_plural')}
                defaultValue={filter.device}
              />
              <div style={{ zIndex: 10, marginLeft: '10px' }}>
                <UncontrolledDropdown className="float-right">
                  <DropdownToggle nav>
                    <BurgerHeader fill="#31c6b3" height="1.5em" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => updateTriMod('default', column.id)}>
                      {t('columns.text.date_added')}
                      {columnFiltered.col2 === 'default' && !columnFiltered.inversed2 && (
                        <ValidationVert fill={'#31c6b3'} height={'1em'} style={{ marginLeft: '3ch' }} />
                      )}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => updateTriMod('code', column.id, false)}>
                      {t('columns.text.code_abc_order')}
                      {columnFiltered.col2 === 'code' && !columnFiltered.inversed2 && (
                        <ValidationVert fill={'#31c6b3'} height={'1em'} style={{ marginLeft: '3ch' }} />
                      )}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => updateTriMod('code', column.id, true)}>
                      {t('columns.text.code_abc_disorder')}
                      {columnFiltered.col2 === 'code' && columnFiltered.inversed2 && (
                        <ValidationVert fill={'#31c6b3'} height={'1em'} style={{ marginLeft: '3ch' }} />
                      )}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => updateTriMod('libelle', column.id, false)}>
                      {t('columns.text.wording_abc_order')}
                      {columnFiltered.col2 === 'libelle' && !columnFiltered.inversed2 && (
                        <ValidationVert fill={'#31c6b3'} height={'1em'} style={{ marginLeft: '3ch' }} />
                      )}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => updateTriMod('libelle', column.id, true)}>
                      {t('columns.text.wording_abc_disorder')}
                      {columnFiltered.col2 === 'libelle' && columnFiltered.inversed2 && (
                        <ValidationVert fill={'#31c6b3'} height={'1em'} style={{ marginLeft: '3ch' }} />
                      )}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => updateTriMod('actions', column.id, false)}>
                      {t('columns.text.actions_max_to_min')}
                      {columnFiltered.col2 === 'actions' && !columnFiltered.inversed2 && (
                        <ValidationVert fill={'#31c6b3'} height={'1em'} style={{ marginLeft: '3ch' }} />
                      )}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => updateTriMod('actions', column.id, true)}>
                      {t('columns.text.actions_min_to_max')}
                      {columnFiltered.col2 === 'actions' && columnFiltered.inversed2 && (
                        <ValidationVert fill={'#31c6b3'} height={'1em'} style={{ marginLeft: '3ch' }} />
                      )}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => updateTriMod('charges', column.id, false)}>
                      {t('columns.text.loaded_max_to_min')}
                      {columnFiltered.col2 === 'charges' && !columnFiltered.inversed2 && (
                        <ValidationVert fill={'#31c6b3'} height={'1em'} style={{ marginLeft: '3ch' }} />
                      )}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => updateTriMod('charges', column.id, true)}>
                      {t('columns.text.loaded_min_to_max')}
                      {columnFiltered.col2 === 'charges' && columnFiltered.inversed2 && (
                        <ValidationVert fill={'#31c6b3'} height={'1em'} style={{ marginLeft: '3ch' }} />
                      )}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          )}
        </div>

        <div>
          <div
            className={`information-container column-synchronisation`}
            id={columnTitle.tournees ? 'popup-scroll' : ''}
            style={{ maxHeight: window.innerHeight - 180, height: tasks.length * 205 }}
            ref={this.myRef}
          >
            <span className="infoLibelle">
              <RecycledList itemFn={TaskDisplay} attrList={attrList} itemHeight={190} />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { users, authentication, alert, locations, synchro } = state;
  const { user } = authentication;
  return {
    user,
    users,
    alert,
    locations,
    synchro,
  };
}

const mapping: any = connect(mapStateToProps)(Column);

const connectedCol = withRouter(mapping);
const tr = withTranslation()(connectedCol);
export default tr;
