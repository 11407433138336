/* eslint-disable react/require-default-props */

import React, { Fragment } from 'react';
import NoFilled from '../Message/NoFilled';
import NoPresent from '../Message/NoPresent';
import DigitRoll from 'digit-roll-react';
import { useTranslation } from 'react-i18next';

type Props = {
  backgroundColor: string;
  indexType: string;
  wheels: number;
  date?: string;
  indexNumber?: number;
};

export default function IndexWrappedLight(props: Props) {
  const { backgroundColor, indexType, wheels, date, indexNumber } = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <>
        <div className="row-meter-bar" style={{ padding: 0, width: '100%' }}>
          <div className="clean-meter-bar indexWrappedLight" style={{ margin: 0, height: '85px' }}>
            <div className="flex-box" style={{ height: '100%' }}>
              <div
                className={
                  'c20 left ' + (backgroundColor === 'green' ? 'background-texture' : 'background-texture-yellow')
                }
                style={{ width: '20%', padding: '20px 2%', position: 'relative' }}
              >
                <h3 className="absoluteVerticalCenter" style={{ textAlign: 'center', margin: '0 5px' }}>
                  {indexType}
                </h3>
              </div>
              <div
                className="c20 b-right"
                style={{ width: '25%', height: '100%', padding: '20px 2% 2% 2%', margin: 0 }}
              >
                <div className="flex-box center">
                  <div className="centerAlign date" style={{ overflowWrap: 'break-word', width: '100%' }}>
                    <span>{t('index_wrapped.label.date')}</span>
                    {date ? <h3 style={{ overflowWrap: 'break-word' }}>{date}</h3> : <NoFilled />}
                  </div>
                </div>
              </div>
              <div
                className="c20 b-right"
                style={{ width: '55%', height: '100%', padding: '20px 2% 2% 2%', border: 0 }}
              >
                <div className="flex-box center">
                  <div className="centerAlign">
                    {Number.isInteger(indexNumber) ? (
                      <DigitRoll num={indexNumber} length={wheels} divider="" />
                    ) : (
                      <NoPresent msg={t('index_wrapped.no_data.index_not_present')} style={{ paddingTop: '5px' }} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Fragment>
  );
}
