import React from 'react';

const ChangeMeter = (props: any) => (
  <svg viewBox="0 0 155.97 121.33" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="pictos">
        <path
          fill={props.fill}
          d="M13,44.29H85.63c3.29,0,5.68-4.21,4.87-8-3-14.44-10.18-27.91-13.35-32.94C76.22,1.87,74.72,0,73.14,0H25.43c-1.58,0-3.08,1.58-4,3.08-3.16,5-10.3,18.37-13.35,32.86-.72,3.77,1.71,8.35,5,8.35"
        />
        <path
          fill={props.fill}
          d="M74.76,83.52c-.12-1.29-.2-2.64-.2-3.93,0-.53,0-1,0-1.54A39.57,39.57,0,0,1,77,65.84a38.65,38.65,0,0,1,3.16-6.61,39.81,39.81,0,1,1-5.43,24.29m39.63-45.68A41.6,41.6,0,0,0,81.17,54.4l1-4.71a2.52,2.52,0,0,0-2.51-2.51H19.07a2.54,2.54,0,0,0-2.53,2.51l3.33,16.15H7.58l-.08-.69a1,1,0,0,0-1.09-.9,1,1,0,0,0-.9,1v-.08a1,1,0,0,0-1.1-.9,1,1,0,0,0-.88,1v-.08a1,1,0,0,0-1.1-.9,1,1,0,0,0-.9,1.1l.05.52H.68a.71.71,0,0,0-.68.69V77.39a.7.7,0,0,0,.68.69H2.79l.09.74a1,1,0,0,0,1,.89h.08a1,1,0,0,0,.9-1v.08a1,1,0,0,0,1,.89h.08a1,1,0,0,0,.89-1v.08a1,1,0,0,0,1,.89h.08a1,1,0,0,0,.9-1.1l0-.53H22.27l.77,3.7a2.52,2.52,0,0,0,2.51,2.51H72.82a41.71,41.71,0,1,0,41.57-46.45"
        />
        <path
          fill={props.fill}
          d="M128.63,60.48a.85.85,0,0,0-1.14,0,.72.72,0,0,0-.19.57v4.67h-6.21a11,11,0,0,0-3.13.4,14.42,14.42,0,0,0-2.59,1,10.87,10.87,0,0,0-2.2,1.66,19.78,19.78,0,0,0-1.78,2,18.15,18.15,0,0,0-1.54,2.43c-.53,1-1,1.82-1.3,2.56s-.73,1.62-1.22,2.67c-.72,1.74-1.37,3.17-1.9,4.14-.4.73-.77,1.38-1.09,1.86A11.37,11.37,0,0,1,103.12,86a5.28,5.28,0,0,1-1.66,1.1,6.09,6.09,0,0,1-2.12.35H93.91a.9.9,0,0,0-.57.21.71.71,0,0,0-.2.57v4.66a.9.9,0,0,0,.2.57.69.69,0,0,0,.57.2h5.43a10.9,10.9,0,0,0,3.09-.4,14.83,14.83,0,0,0,2.6-1,10.79,10.79,0,0,0,2.19-1.67,18.6,18.6,0,0,0,1.78-2,19.17,19.17,0,0,0,1.55-2.43c.52-1,1-1.82,1.29-2.56s.72-1.62,1.22-2.67c.72-1.75,1.38-3.17,1.9-4.14.41-.73.77-1.38,1.09-1.87a12.93,12.93,0,0,1,1.22-1.5,5.12,5.12,0,0,1,1.66-1.1,6.07,6.07,0,0,1,2.11-.36h6.21v4.67a.64.64,0,0,0,.24.52.77.77,0,0,0,.53.25,1,1,0,0,0,.58-.21l7.74-7.75a.7.7,0,0,0,.21-.56.85.85,0,0,0-.21-.57Z"
        />
        <path
          fill={props.fill}
          d="M93.62,71.84h0a.83.83,0,0,0,.33,0h5.4a5.32,5.32,0,0,1,2,.36,6,6,0,0,1,1.54.86,5.88,5.88,0,0,1,1.22,1.38,12.9,12.9,0,0,1,1,1.54c.25.44.53,1,.9,1.74a47.91,47.91,0,0,1,3.32-6.61c-2.59-3.65-5.88-5.44-9.93-5.44H94a.79.79,0,0,0-.44.13c-.05,0-.09,0-.13.08a.7.7,0,0,0-.2.56v4.63a.9.9,0,0,0,.2.57c0,.08.13.11.24.16"
        />
        <path
          fill={props.fill}
          d="M128.63,82.23a.91.91,0,0,0-.56-.25,1,1,0,0,0-.58.2.7.7,0,0,0-.19.57v4.67h-6.21a5.34,5.34,0,0,1-2-.37,6,6,0,0,1-1.54-.85,5.66,5.66,0,0,1-1.22-1.38,16.61,16.61,0,0,1-1-1.54c-.25-.44-.53-1-.9-1.75a51.24,51.24,0,0,1-3.28,6.62,20.56,20.56,0,0,0,1.34,1.7c.48.49.9.93,1.33,1.3a7.72,7.72,0,0,0,1.42,1c.53.29,1,.49,1.43.69a5.89,5.89,0,0,0,1.54.45c.6.12,1.13.2,1.54.24s1,.08,1.7.12,1.3,0,1.79,0,1.09,0,1.94,0h2.08v4.67a.66.66,0,0,0,.24.53.71.71,0,0,0,.53.24.87.87,0,0,0,.56-.2l7.75-7.75a.69.69,0,0,0,.2-.57.87.87,0,0,0-.2-.56Z"
        />
      </g>
    </g>
  </svg>
);

export default ChangeMeter;
