import React, { SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { alertActions, dashboardActions } from '../_actions';
import Ajout from '../SvgComponents/AjoutBleu';
import { withRouter } from 'react-router';
import userActions from '../_actions/user.actions';
import { withTranslation } from 'react-i18next';

interface State {
  name: string;
  saved: boolean;
  modal: boolean;
}

/**
 * @class AddDashBoardComponent
 * @extends {React.Component<Props, State>}
 */
class AddDashBoardComponent extends React.Component<any, State> {
  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof AddDashBoardComponent
   */
  constructor(props: any) {
    super(props);
    this.state = {
      modal: false,
      name: '',
      saved: false,
    };
  }

  componentDidMount() {
    const { dispatch, user } = this.props;
    dispatch(userActions.get(user.id));
  }

  /**
   * Ferme automatiquement la modal en cas de réussite
   * à la sauvegarde
   *
   * @param {Props} prevProps
   * @param {State} prevState
   * @method componentDidUpdate
   * @memberof AddDashBoardComponent
   */
  componentDidUpdate(prevProps: any, prevState: State) {
    const { history, dashboards, match } = this.props;
    const { name } = this.state;
    if (name.length > 0 && !prevState.saved) {
      this.toggleAutoClose();
    }
    const dashboardSaved = dashboards.items && dashboards.items.find(d => d.name === name);
    if (dashboardSaved) {
      history.push({
        pathname: `/locations/${match.params.locationId}/dashboards/${dashboardSaved.id}`,
      });
    }
  }

  /**
   * Gère l'ouverture et fermeture de la modal
   *
   * @method toggle
   * @memberof AddDashBoardComponent
   */
  toggle = () => {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
    const { modal } = this.state;
    this.setState({
      modal: !modal,
      saved: false,
      name: '',
    });
  };

  /**
   * Gère la fermeture de la modal en cas de sauvegarde
   *
   * @method toggleAutoClose
   * @memberof AddDashBoardComponent
   */
  toggleAutoClose = () => {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
    this.setState({
      modal: false,
      saved: true,
    });
  };

  /**
   * Gère les droits d'ajouter un dashboard
   *
   * @returns {boolean} Le contrôle
   * @method couldAddDashboard
   * @memberof AddDashBoardComponent
   */
  couldAddDashboard = () => {
    const { user, locations, users } = this.props;
    const roleList = ['DIOPTASE', 'SUPERADMIN', 'ADMIN'];
    if (roleList.includes(user.role.name)) {
      return true;
    }

    if (locations.fetchedLocation && locations.fetchedLocation.tournee) {
      //CETTE LIGNE ALEXIS
      return true;
    }
    if (
      users &&
      users.fetchedUser &&
      locations.fetchedLocation &&
      users.fetchedUser.profils.find(
        (el: any) =>
          el.profil.permissions.find((permission: any) => permission.name === 'edit.dashboard') &&
          el.locationCode === locations.fetchedLocation.code
      )
    ) {
      return true;
    }
    return false;
  };

  /**
   * Gère la soumission valide du formulaire, et la
   * sauvegarde du dashboard
   *
   * @method handleValidSubmit
   * @memberof AddDashBoardComponent
   */
  handleValidSubmit = (event: SyntheticEvent, values: any) => {
    const { dispatch } = this.props;
    this.setState({
      name: values.name,
    });

    const { locations, dashboards } = this.props;
    const dashboard = {
      name: values.name,
      location: locations.fetchedLocation,
      private: false, // todo
      personal: false, // todo
    };
    console.log('kdls', dashboard);
    dispatch(dashboardActions.save(dashboard, dashboards.items));
  };

  /**
   * Gère les erreurs de validation du formulaire
   *
   * @method handleInvalidSubmit
   * @memberof AddDashBoardComponent
   */
  handleInvalidSubmit = (values: any) => {
    this.setState({
      name: values.name,
    });
  };

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof AddDashBoardComponent
   */
  render() {
    const { className, alert, t } = this.props;
    const { name, saved, modal } = this.state;

    return (
      <div>
        {this.couldAddDashboard() && (
          <div id="addDashboard" onClick={this.toggle} role="presentation">
            <Ajout className="add" />
            <UncontrolledTooltip placement="bottom" target="addDashboard">
              {' '}
              {t('add_dashboard.title.add_dashboard')}
            </UncontrolledTooltip>
            <Modal isOpen={modal} toggle={this.toggle} className={className}>
              <ModalHeader toggle={this.toggle}>{t('add_dashboard.title.add_dashboard')}</ModalHeader>
              <AvForm onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
                <ModalBody>
                  <div>{alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}</div>
                  {!saved && (
                    <div>
                      <AvField
                        name="name"
                        value={name}
                        label={t('add_dashboard.text.dashboard_name')}
                        required
                        errorMessage={t('add_dashboard.error_msg.dashboard_name_error')}
                      />
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  {!saved && (
                    <Button color="danger" onClick={this.toggle}>
                      {t('all.button.cancel')}
                    </Button>
                  )}
                  {!saved && <Button color="primary">{t('all.button.register')}</Button>}
                </ModalFooter>
              </AvForm>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, locations, dashboards, alert, users } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    locations,
    dashboards,
    users,
  };
}

const connectedAddDashBoard = withRouter(connect(mapStateToProps)(AddDashBoardComponent));
const tr = withTranslation()(connectedAddDashBoard);
export default tr;
