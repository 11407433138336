import React from 'react';

const SvgCodeBarreVert = (props: any) => (
  <svg viewBox="0 0 100.25 71.57" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <rect fill={props.fill} width="7.16" height="71.57" />
        <rect fill={props.fill} x="57.28" width="14.33" height="71.57" />
        <rect fill={props.fill} x="78.79" width="7.16" height="71.57" />
        <rect fill={props.fill} x="93.08" width="7.18" height="71.57" />
        <rect fill={props.fill} x="14.34" width="21.45" height="71.57" />
        <rect fill={props.fill} x="42.96" width="7.18" height="71.57" />
      </g>
    </g>
  </svg>
);
export default SvgCodeBarreVert;
