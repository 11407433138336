import React, { useState } from 'react';
import moment from 'moment';
import { UncontrolledTooltip } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import sensorActions from '../_actions/sensor.actions';
import SynchroHeader from '../SvgComponents/SynchroHeader';
import SvgSynchroHeader from '../SvgComponents/SynchroHeader';

const Synchronisation = (props: any) => {
  const { dispatch, t } = props;
  const [error, setError] = useState(null);
  const [hour, setHour] = useState('');
  const [date, setDate] = useState('');

  const handleSubmit = () => {
    if (date.length > 0 && hour.length > 0) {
      const sDate = moment(`${date} ${hour}`)
        .utc(true)
        .toISOString();
      dispatch(sensorActions.putSyncTime(1, sDate));
    } else {
      setError(t('sensor.error.synchro'));
    }
  };
  return (
    <div
      className="downlink-container tele-releve-container"
      id="tab-container"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
    >
      <div className="tele-releve-header flex-box">
        <SynchroHeader fill="#31c6b3" height="2.5em" />
        <h2 style={{ marginLeft: '20px' }}>{t('sensor.title.synchro')}</h2>
      </div>
      <div className="wrapper">
        <div id={'synchro-horloge'} className="clickable round" role="presentation" onClick={handleSubmit}>
          <SvgSynchroHeader height="1.3em" />
          <UncontrolledTooltip placement="bottom" target={`synchro-horloge`}>
            {t('sensor.title.synchro')}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { sensor } = state;
  return {
    sensor,
  };
}

const mapping: any = connect(mapStateToProps)(Synchronisation);
const connectedUser = withRouter(mapping);
export default withTranslation()(connectedUser);
