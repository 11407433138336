import React from 'react';

const SvgStockHeader = (props: any) => (
  <svg viewBox="0 0 28.83 28.93" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_3" data-name="Calque 3">
        <path d="M0,22.91,0,9.77a.9.9,0,0,1,1.25-.83l12,5.11a.91.91,0,0,1,.55.83L13.8,28a.9.9,0,0,1-1.25.83l-12-5.12A.91.91,0,0,1,0,22.91ZM27.45,10.38l-11,4.79,0,12.17,11-4.79,0-12.17M27.9,8.8a.9.9,0,0,1,.9.9l0,13.15a.87.87,0,0,1-.54.82L16.35,28.85a.92.92,0,0,1-.36.07.9.9,0,0,1-.9-.9l0-13.14a.9.9,0,0,1,.54-.83l12-5.18a.84.84,0,0,1,.35-.07ZM14.33,1.39,2.87,6.51l11.48,5.06L25.81,6.45,14.33,1.39m0-1.39a.9.9,0,0,1,.36.08L27.28,5.63a.9.9,0,0,1,0,1.64L14.72,12.88a.76.76,0,0,1-.36.08.94.94,0,0,1-.37-.07L1.4,7.34a.9.9,0,0,1,0-1.65L14,.08A1,1,0,0,1,14.33,0Z" />
      </g>
    </g>
  </svg>
);

export default SvgStockHeader;
