import React from 'react';

const SvgGeolocVert = (props: any) => (
  <svg viewBox="0 0 123.01 123.01" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M119.17,57.66H112.6A51.31,51.31,0,0,0,65.35,10.41V3.84a3.85,3.85,0,0,0-7.69,0v6.57A51.31,51.31,0,0,0,10.41,57.66H3.84a3.85,3.85,0,0,0,0,7.69h6.57A51.31,51.31,0,0,0,57.66,112.6v6.57a3.85,3.85,0,0,0,7.69,0V112.6A51.31,51.31,0,0,0,112.6,65.35h6.57a3.85,3.85,0,0,0,0-7.69ZM65.35,104.89v-3.66a3.85,3.85,0,1,0-7.69,0v3.66A43.62,43.62,0,0,1,18.12,65.35h3.66a3.85,3.85,0,1,0,0-7.69H18.12A43.62,43.62,0,0,1,57.66,18.12v3.66a3.85,3.85,0,1,0,7.69,0V18.12a43.62,43.62,0,0,1,39.54,39.54h-3.66a3.85,3.85,0,1,0,0,7.69h3.66A43.62,43.62,0,0,1,65.35,104.89Z"
        />
        <path
          fill={props.fill}
          d="M61.51,46.13A15.38,15.38,0,1,0,76.88,61.51,15.4,15.4,0,0,0,61.51,46.13Zm0,23.06a7.69,7.69,0,1,1,7.68-7.68A7.7,7.7,0,0,1,61.51,69.19Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgGeolocVert;
