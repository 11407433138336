import React from 'react';

const CompteurModifie = (props: any) => (
  <svg viewBox="0 0 129.2 115.19" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M25.82,64.47a3.44,3.44,0,0,0-3.44,3.43L26.9,89.81H10.22l-.1-1A1.34,1.34,0,0,0,7.45,89l0-.13A1.34,1.34,0,0,0,4.76,89l0-.13a1.35,1.35,0,0,0-1.47-1.21,1.33,1.33,0,0,0-1.2,1.47l.07.7H1.4A1.4,1.4,0,0,0,0,91.21V105a1.4,1.4,0,0,0,1.4,1.4H3.76l.1,1a1.34,1.34,0,0,0,1.34,1.21h.13a1.35,1.35,0,0,0,1.21-1.35v.14a1.33,1.33,0,0,0,1.33,1.21H8a1.34,1.34,0,0,0,1.2-1.35l0,.14a1.33,1.33,0,0,0,1.33,1.21h.14a1.33,1.33,0,0,0,1.2-1.47l-.07-.73H30.32l1,5a3.43,3.43,0,0,0,3.43,3.44H60.22V64.47Z"
        />
        <path
          stroke={props.fill} //BORDER BOTTOM RIGHT
          d="M103,66.89a1,1,0,0,1,1,.84L99.55,89.32,99,92.24H126.4V104H96.53l-.4,1.93-1,5,0,.24v.25a1,1,0,0,1-1,1H71V66.89h32m0-2.42H68.61v50.35H94.05a3.43,3.43,0,0,0,3.43-3.44l1-5h21.66l.1,1a1.34,1.34,0,0,0,1.34,1.21h.13a1.35,1.35,0,0,0,1.21-1.35v.14a1.34,1.34,0,0,0,1.34,1.21h.13a1.34,1.34,0,0,0,1.2-1.35l0,.14a1.33,1.33,0,0,0,1.33,1.21h.14a1.33,1.33,0,0,0,1.2-1.47l-.09-1a1.4,1.4,0,0,0,.6-1.15V91.21a1.4,1.4,0,0,0-1.4-1.4h-.8l-.1-1a1.35,1.35,0,0,0-1.34-1.22h-.13a1.34,1.34,0,0,0-1.2,1.34l0-.13a1.34,1.34,0,0,0-1.33-1.22h-.14a1.34,1.34,0,0,0-1.2,1.34l0-.13a1.34,1.34,0,0,0-1.33-1.22h-.14a1.33,1.33,0,0,0-1.2,1.47l.07.7H101.93l4.52-21.91A3.44,3.44,0,0,0,103,64.47Z"
        />
        <path
          stroke={props.fill} //BORDER TOP RIGHT
          d="M94.3,2.8c.34,0,1.62.62,3.4,3.45a141.67,141.67,0,0,1,9,17.25,136.54,136.54,0,0,1,8.84,26.69A7.9,7.9,0,0,1,114,56.58a3.81,3.81,0,0,1-2.77,1.56H71V2.8H94.3m0-2.42H68.61V60.56h42.63c4.44,0,7.72-5.73,6.63-10.87C113.72,30.05,104.05,11.8,99.75,5,98.46,2.91,96.44.38,94.3.38Z"
        />
        <path
          fill={props.fill}
          d="M34.54.38c-2.14,0-4.16,2.16-5.44,4.21-4.3,6.84-14,25-18.12,44.61-1.09,5.15,2.18,11.36,6.63,11.36H60.22V.38Z"
        />
        <path
          fill="none"
          d="M94.17,3c.34,0,1.61.62,3.39,3.45a137.82,137.82,0,0,1,9,17.25,136.6,136.6,0,0,1,8.85,26.69,7.89,7.89,0,0,1-1.49,6.39,3.78,3.78,0,0,1-2.76,1.55H70.9V3H94.17"
        />
        <path
          fill="none"
          d="M103.5,66.9a1,1,0,0,1,1,.83l-4.45,21.59-.61,2.92h27.45V104H97l-.4,1.94-1,5,0,.24v.25a1,1,0,0,1-1,1h-23V66.9h32"
        />
      </g>
    </g>
  </svg>
);

export default CompteurModifie;
