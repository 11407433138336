import React from 'react';
import GestionUtilisateurBlanc from '../SvgComponents/GestionUtilisateurBlanc';
import AdresseVert from '../SvgComponents/AdresseVert';
import NbCompteurVert from '../SvgComponents/NbCompteurVert';
import SvgGeolocVert from '../SvgComponents/GeolocVert';
import SvgMailVert from '../SvgComponents/MailVert';
import SvgTelephoneVert from '../SvgComponents/TelephoneVert';
import SvgNoteVert from '../SvgComponents/NoteVert';

function getC(key, props) {
  switch (key) {
    case 'contact':
      return <GestionUtilisateurBlanc {...props} />;
    case 'address':
      return <AdresseVert {...props} />;
    case 'meter':
      return <NbCompteurVert {...props} />;
    case 'geoloc':
      return <SvgGeolocVert {...props} />;
    case 'email':
      return <SvgMailVert {...props} />;
    case 'phone':
      return <SvgTelephoneVert {...props} />;
    case 'note':
      return <SvgNoteVert {...props} />;

    default:
      break;
  }
}

export default getC;
