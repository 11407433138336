import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { detect } from 'detect-browser';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { DragAndDrop } from '../_components';
import synchroAction from '../_actions/synchro.actions';
import synchroComAction from '../_actions/synchrocom.actions';
import gestionnaireAction from '../_actions/gestionnaire.actions';
import ErrorBand from '../Bands/Error';
import Loading from '../_animations/Loading';
import { tourneeActions } from '../_actions';

let interval = null;

const Synchronisation = props => {
  const { dispatch, synchrocom, match, synchro, t, i18n } = props;
  const { params } = match;

  const [filter, setFilter] = useState({ round: '', device: '' });
  const [checked, setChecked] = useState(false);
  const [stickyButton, setStickyButton] = useState(false);

  const getCurrentInfo = () => {
    dispatch(synchroAction.getLabels(params.GestionnaireId));
    dispatch(synchroComAction.getCurrentCommunication(params.GestionnaireId));
    dispatch(synchroComAction.getCurrentRounds(params.GestionnaireId));
  };

  const handleKeyboardInput = e => {
    const code = e.keyCode ? e.keyCode : e.which;
    if (code === 91 || code === 17) {
      setChecked(!checked);
    }
  };

  useEffect(() => {
    localStorage.setItem('synchroFilter-fast', JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    dispatch(synchroAction.getAllDevice(params.GestionnaireId));
    dispatch(synchroAction.getAllRoundFull(params.GestionnaireId));
    dispatch(synchroAction.getLabels(params.GestionnaireId));
    dispatch(synchroAction.getCurrentVersion());
    dispatch(synchroAction.getMiniVersion());
    dispatch(synchroComAction.getCurrentCommunication(params.GestionnaireId));

    document.addEventListener('keydown', handleKeyboardInput, false);
    dispatch(gestionnaireAction.getGestionnaire(params.GestionnaireId));
    dispatch(tourneeActions.getRotateOption());

    interval = setInterval(getCurrentInfo, 5000);

    const mountFastFilters = localStorage.getItem('synchroFilter-fast');
    if (mountFastFilters) {
      setFilter(JSON.parse(mountFastFilters));
    }

    return () => {
      document.removeEventListener('keydown', handleKeyboardInput, false);
      window.onscroll = null;
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    clearInterval(interval);
    if (!synchrocom.hasCom) {
      interval = setInterval(getCurrentInfo, 5000);
    } else {
      interval = setInterval(getCurrentInfo, 1000);
    }
  }, [synchrocom.hasCom]);

  const handleChangeChecked = check => {
    setChecked(check);
  };

  const handleChange = (e, round) => {
    if (round) {
      setFilter({
        round: e.target.value,
        device: filter.device,
      });
    } else {
      setFilter({
        round: filter.round,
        device: e.target.value,
      });
    }
  };

  const displayButton = e => {
    if (e.clientY > window.innerHeight - 20) {
      setStickyButton(true);
    } else {
      setStickyButton(false);
    }
  };

  const handleChangeAdd = onDragEnd => {
    if (onDragEnd) {
      setChecked(false);
    } else {
      setChecked(!checked);
    }
  };
  return (
    <div onKeyDown={handleKeyboardInput}>
      {synchro.error && (
        <ErrorBand
          message={
            i18n.exists(`synchronisation.error_band.${synchro.error}`)
              ? t(`synchronisation.error_band.${synchro.error}`)
              : synchro.error
          }
        />
      )}
      {(!synchro.roundsUp || !synchro.devicesUp) && <Loading message="Chargement de la synchro en cours ..." />}
      {synchro.roundsUp && synchro.devicesUp && (
        <div className="drag-and-drop" id="drag-drop-synchro" onMouseOver={displayButton}>
          <DragAndDrop
            filter={filter}
            checked={checked}
            handleChangeChecked={handleChangeAdd}
            stateTasks={synchro.tasks}
            labels={synchro.labels}
            stickyButton={stickyButton}
            handleFilterChange={handleChange}
            handleChange={handleChangeChecked}
          />
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { users, authentication, alert, locations, synchro, synchrocom, currentState, gestionnaire } = state;
  const { user } = authentication;
  return {
    user,
    users,
    alert,
    locations,
    synchro,
    currentState,
    gestionnaire,
    synchrocom,
  };
}

const mapping = connect(mapStateToProps)(Synchronisation);

const connectedUser = withRouter(mapping);
const tr = withTranslation()(connectedUser);
export default tr;
