import React, { useEffect, useState } from 'react';
import './style.scss';
import { Line } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import AlertBar from './AlertBar';
import InfoMap from './InfoMap';
import Rouage from './Rouage';
import sensorActions from '../_actions/sensor.actions';
import ChartBar from '../SvgComponents/ChartBar';
import ChartBarVerticalNotAvailable from '../SvgComponents/ChartBarVerticalNotAvailable';

const SensorAdeTemp = ({ sensor, users, dispatch, t, ...props }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dropValue, setDropValue] = useState('minutes');
  const [isRight, setRight] = useState(false);
  const [pOptions, setOptions] = useState({
    usersMail: [],
    retention: '',
    fields: {},
    continuousAlertSending: false,
  });

  useEffect(() => {
    const info = _.get(sensor, 'sensor.info.sensorAlert');
    if (info) {
      setDropValue(info.retention.unit);
      setOptions(info);
      setSelectedUsers(info.usersMail);
      if (_.get(info, 'continuousAlertSending')) {
        setRight(!_.get(info, 'continuousAlertSending'));
      }
    }
  }, [sensor]);

  const temperatures = _.get(sensor, 'sensor.data.temperature');
  const temperaturesS2 = _.get(sensor, 'sensor.data.temperature2');

  const tempData = (canvas: any) => {
    const ctx = canvas.getContext('2d');
    const gradient0 = ctx.createLinearGradient(0, 375, -1, 0);
    gradient0.addColorStop(1, '#31c6b3');
    gradient0.addColorStop(0, 'transparent');
    return {
      labels: _.keys(temperatures).map(d => moment(d).format('DD/MM/YYYY, h:mm')),
      datasets: [
        {
          label: 'Max',
          data: _.keys(temperatures).map(el => _.get(sensor, 'sensor.info.sensorAlert.fields.temperature.max')),
          fill: false,
          borderColor: '#C42021b8',
          pointRadius: 0,
        },
        {
          label: 'Min',
          data: _.keys(temperatures).map(el => _.get(sensor, 'sensor.info.sensorAlert.fields.temperature.min')),
          fill: false,
          borderColor: '#1E78C2b8',
          pointRadius: 0,
        },
        {
          label: 'Température',
          data: _.values(temperatures),
          fill: false,
          backgroundColor: gradient0,
          borderColor: '#31c6b3',
        },
      ].filter(el => _.size(el) > 0),
    };
  };

  const tempData2 = (canvas: any) => {
    const ctx = canvas.getContext('2d');
    const gradient0 = ctx.createLinearGradient(0, 375, -1, 0);
    gradient0.addColorStop(1, '#31c6b3');
    gradient0.addColorStop(0, 'transparent');
    return {
      labels: _.keys(temperatures).map(d => moment(d).format('DD/MM/YYYY, h:mm')),
      datasets: [
        {
          label: 'Max',
          data: _.keys(temperatures).map(el => _.get(sensor, 'sensor.info.sensorAlert.fields.temperature2.max')),
          fill: false,
          borderColor: '#C42021b8',
          pointRadius: 0,
        },
        {
          label: 'Min',
          data: _.keys(temperatures).map(el => _.get(sensor, 'sensor.info.sensorAlert.fields.temperature2.min')),
          fill: false,
          borderColor: '#1E78C2b8',
          pointRadius: 0,
        },
        {
          label: 'Température Sonde 2',
          data: _.values(temperaturesS2),
          fill: false,
          backgroundColor: gradient0,
          borderColor: '#31c6b3',
        },
      ].filter(el => _.size(el) > 0),
    };
  };

  const handleValidOptions = (minMax: any) => {
    const copyOptions = _.cloneDeep(pOptions);
    _.set(copyOptions, 'fields', minMax);
    if (isRight) {
      _.set(copyOptions, 'retention', null);
    } else {
      _.set(copyOptions, 'retention.unit', dropValue);
    }
    _.set(copyOptions, 'usersMail', selectedUsers);
    _.set(copyOptions, 'continuousAlertSending', !isRight);
    dispatch(sensorActions.addAlert(copyOptions, _.get(sensor, 'sensor.info.sensorId')));
  };

  const options = {
    plugins: {
      t1: false,
      datalabels: {
        display: false,
      },
    },
    legend: {
      display: false,
    },
    spanGaps: true,
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const alertProps = {
    options: pOptions,
    users,
    setDropValue,
    setSelectedUsers,
    setOptions,
    dropValue,
    setRight,
    isRight,
  };

  return (
    <div className="sensor-graph-container">
      <div className="info-container">
        <InfoMap />
      </div>
      {users.items && _.get(sensor, 'sensor.info.sensorAlert') && (
        <AlertBar
          t={t}
          {...alertProps}
          info={_.get(sensor, 'sensor.info.sensorAlert')}
          setRight={setRight}
          isRight={isRight}
        />
      )}
      <div className="sensors-wrapper">
        <div className="charts-container">
          <div className="chart-container">
            <div className="flex-between">
              <h3>Température Sonde 1 (°C)</h3>
              {users && sensor.sensor && (
                <Rouage
                  users={users.items}
                  validFunction={handleValidOptions}
                  alert={sensor.sensor.info.sensorAlert}
                  themeKey="temperature"
                />
              )}
            </div>
            {_.size(temperatures) > 0 ? (
              <Line options={options} data={tempData} />
            ) : (
              <ChartBarVerticalNotAvailable height="200px" fill="grey" />
            )}
          </div>
          {_.size(temperaturesS2) > 0 && (
            <div className="chart-container">
              <div className="flex-between">
                <h3>Température Sonde 2 (°C)</h3>
                {users && sensor.sensor && (
                  <Rouage
                    users={users.items}
                    validFunction={handleValidOptions}
                    alert={sensor.sensor.info.sensorAlert}
                    themeKey="temperature2"
                  />
                )}
              </div>
              <Line options={options} data={tempData2} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { sensor, users } = state;
  return {
    sensor,
    users,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(SensorAdeTemp)));
