const mapboxConstants = {
  GEOJSON_REQUEST: 'GEOJSON_REQUEST',
  GEOJSON_SUCCESS: 'GEOJSON_SUCCESS',
  GEOJSON_FAILURE: 'GEOJSON_FAILURE',

  METER_REQUEST: 'METER_REQUEST',
  METER_SUCCESS: 'METER_SUCCESS',
  METER_FAILURE: 'METER_FAILURE',

  GATEWAY_FAILURE: 'GATEWAY_FAILURE',
  GATEWAY_SUCCESS: 'GATEWAY_SUCCESS',

  POLYGONS_FAILURE: 'POLYGONS_FAILURE',
  POLYGONS_SUCCESS: 'POLYGONS_SUCCESS',

  POST_POLYGONS_FAILURE: 'POST_POLYGONS_FAILURE',
  POST_POLYGONS_SUCCESS: 'POST_POLYGONS_SUCCESS',

  GET_POINTS_WITHIN_SUCCESS: 'GET_POINTS_WITHIN_SUCCESS',
  GET_POINTS_WITHIN_FAILURE: 'GET_POINTS_WITHIN_FAILURE',

  SEND_ROUND_SUCCESS: 'SEND_ROUND_SUCCESS',
  SEND_ROUND_FAILURE: 'SEND_ROUND_FAILURE',

  RANGE_ON_SUCCESS: 'RANGE_ON_SUCCESS',

  CLEAR: 'CLEAR',
};

export default mapboxConstants;
