import { geolocalisationConstants } from '../_constants';

/**
 * Récupère la géolocalisation si elle est activée dans
 * le navigateur
 *
 * @method get
 * @returns {Object} La géoloc
 */
function get() {
  const getUserPosition = () => ({
    type: geolocalisationConstants.GEOLOCATION_REQUEST,
    loading: true,
  });

  const locationSuccess = (position: Object) => ({
    type: geolocalisationConstants.GEOLOCATION_SUCCESS,
    position,
  });

  const locationError = (error: Object) => ({
    type: geolocalisationConstants.GEOLOCATION_FAILURE,
    error,
  });

  return (dispatch: Function) => {
    dispatch(getUserPosition());
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => dispatch(locationSuccess(position)),
        error => dispatch(locationError(error))
      );
    } else {
      dispatch(locationError('The browser does not support geolocation'));
    }
  };
}

const geolocationActions = {
  get,
};
export default geolocationActions;
