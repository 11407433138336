import { authHeader, handleResponse } from '../_helpers';

async function getAllCustomExport() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/customexport/`, requestOptions);
  return handleResponse(response);
}
async function getFavoriCustomExport() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/customexport/favori`, requestOptions);
  return handleResponse(response);
}

async function getCustomExport(exportId: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/customexport/${exportId}`, requestOptions);
  return handleResponse(response);
}

async function deleteExport(exportId: number) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };
  const response = await fetch(`/api/customexport/${exportId}`, requestOptions);
  return handleResponse(response);
}

async function changeFavoriState(exportId: number, newFavoriState: boolean) {
  const requestOptions = {
    method: newFavoriState ? 'POST' : 'DELETE',
    headers: authHeader(),
  };
  const response = await fetch(`/api/customexport/${exportId}/favori`, requestOptions);
  return handleResponse(response);
}

async function saveCustomExport(body: any) {
  body.exportFilters.forEach(e => {
    delete e.filter.filter;
    if (_.isArray(e.userInput)) e.userInput = _.join(e.userInput.map(el => el.value), '|');
    if (_.isPlainObject(e.userInput)) e.userInput = e.userInput.value;
  });
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };
  const response = await fetch(`/api/customexport/`, requestOptions);
  return handleResponse(response);
}

async function updateCustomExport(body: any, exportId: number) {
  body.exportFilters.forEach(e => {
    delete e.filter.filter;
    if (_.isArray(e.userInput)) e.userInput = _.join(e.userInput.map(el => el.value), '|');
    if (_.isPlainObject(e.userInput)) e.userInput = e.userInput.value;
  });
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };
  const response = await fetch(`/api/customexport/${exportId}`, requestOptions);
  return handleResponse(response);
}

async function launchCustomExport(exportId: number, locationId: string, fillFilters: any) {
  const body = JSON.stringify(
    _.mapValues(fillFilters, e => {
      console.log('launchCustomExport::service::29', e);
      if (_.isArray(e)) return _.join(e.map(el => el.value), '|');
      if (_.isPlainObject(e)) return e.value;
      return e;
    })
  );
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body,
  };
  const response = await fetch(`/api/customexport/${exportId}/location/${locationId}`, requestOptions);
  return response.blob().then((blob: any) => {
    return { content: blob, filename: response.headers.get('export-name') };
  });
}

async function launchCustomExportStatus(exportId: number, locationId: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/customexport/${exportId}/location/${locationId}`, requestOptions);
  if (response.ok) {
    return response.blob().then((blob: any) => {
      return { content: blob, filename: response.headers.get('export-name') };
    });
  }
  return response;
}

export default {
  getAllCustomExport,
  saveCustomExport,
  launchCustomExport,
  launchCustomExportStatus,
  getCustomExport,
  changeFavoriState,
  updateCustomExport,
  getFavoriCustomExport,
  deleteExport,
};
