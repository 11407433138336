import React, { useEffect, useState } from 'react';

import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import stub from './stub';
import LineChart from './LineChart';
import './style.scss';
import _ from 'lodash';
import moment from 'moment';
import SvgCapteur from '../SvgComponents/Capteur';
import CollonneVert from '../SvgComponents/ColonneVert';
import SvgDashboard from '../SvgComponents/TableauBordBlanc';
import Colonne from '../SvgComponents/ColonneVert';

const Cluster = props => {
  const { t } = props;
  const [isFlex, setIsFlex] = useState(false);
  const [hideList, setHideList] = useState([]);
  const cluster = stub[0];
  // COM = comfort
  useEffect(() => {
    // dispatch(get)
  }, []);

  const handleHide = index => {
    let clone = _.cloneDeep(hideList);
    if (hideList.includes(index)) {
      clone = hideList.filter(el => el !== index);
    } else {
      clone.push(index);
    }
    setHideList(clone);
  };

  return (
    <div className="sensor-cluster-container">
      <div className="main-container">
        <div className="title">
          <SvgDashboard height={'2.5em'} fill={'#31c6b3'} className={'svg-dashboard'} />
          <h2>{cluster.name}</h2>
          <div
            className={`radio-button ${isFlex ? 'off' : 'on'}`}
            onClick={() => setIsFlex(false)}
            style={{ transform: 'rotate(90deg)' }}
          >
            <Colonne height="1.2em" />
          </div>
          <div className={`radio-button ${isFlex ? 'on' : 'off'}`} onClick={() => setIsFlex(true)}>
            <Colonne height="1.2em" />
          </div>
        </div>
        <div className="sensors-container" style={{ display: isFlex ? 'flex' : 'block' }}>
          {cluster.sensors.map((sensor, wIndex) => {
            return (
              <div
                className="sensor-wrapper"
                style={{ maxWidth: isFlex ? (cluster.sensors.length > 2 ? '30%' : '45%') : '100%' }}
              >
                <div className={`serial ${isFlex ? 'flex' : ''}`} onClick={() => handleHide(wIndex)}>
                  <div className="round-svg">
                    <SvgCapteur height="2em" fill="white" />
                  </div>
                  <h4>{sensor.info.sensorSerial}</h4>
                </div>
                {!hideList.includes(wIndex) &&
                  _.keys(sensor.data).map((el, index) => {
                    const dataList = _.entries(_.get(sensor, `data.${el}`)).map(a => a[1]);
                    const labels = _.entries(_.get(sensor, `data.${el}`)).map(a => moment(a[0]).format('DD/MM/YYYY'));
                    const datasets = [
                      _.get(sensor, `info.sensorAlert.fields.${el}.max`)
                        ? {
                            label: 'Max',
                            data: dataList.map(() =>
                              parseInt(_.get(sensor, `info.sensorAlert.fields.${el}.max`, 0), 10)
                            ),
                            fill: false,
                            borderColor: '#C42021b8',
                            pointRadius: 0,
                          }
                        : {},
                      _.get(sensor, `info.sensorAlert.fields.${el}.min`)
                        ? {
                            label: 'Min',
                            data: dataList.map(() =>
                              parseInt(_.get(sensor, `info.sensorAlert.fields.${el}.min`, 0), 10)
                            ),
                            fill: false,
                            borderColor: '#1E78C2b8',
                            pointRadius: 0,
                          }
                        : {},
                      {
                        label: el,
                        data: dataList,
                        backgroundColor: index % 2 === 0 ? '#bfefffab' : '#dbeee0ab',
                        borderColor: index % 2 === 0 ? '#bfefff' : '#dbeee0',
                        fill: false,
                      },
                    ].filter(filter => _.size(filter) > 0);
                    return (
                      <div className="chart-title" style={{ paddingLeft: isFlex ? '0' : '70px' }}>
                        <h5>{t(`all.text.${el}`)}</h5>
                        <div className="chart-container">
                          <LineChart datasets={datasets} labels={labels} />
                        </div>
                      </div>
                    );
                  })}
              </div>
            );
          })}
          {!isFlex && <div className="fil-bar" />}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { sensor } = state;
  return {
    sensor,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(Cluster)));
