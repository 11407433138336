import React from 'react';
import { ManagePdi } from './index';

export function LinkerPdi(props: any) {
  console.log(props);
  return <ManagePdi linkMode={true} {...props} />;
}

export function UnlinkerPdi(props: any) {
  return <ManagePdi linkMode={false} {...props} />;
}
