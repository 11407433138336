import _ from 'lodash';

async function getProgress() {
  const requestOptions = {
    method: 'GET',
  };
  let response = await fetch('/api/application/initialProgress', requestOptions);
  return !response.ok ? '1' : response.text();
}

const initialProgressService = {
  getProgress,
};

export default initialProgressService;
