import React from 'react';

const SvgFluidAutre = (props: any) => (
  <svg viewBox="0 0 59.41 106" {...props}>
    <path
      fill={props.fill}
      d="M41.09,75.74V46.58a2.25,2.25,0,0,0-4.5,0V75.74a10.12,10.12,0,1,0,4.5,0ZM38.84,91.22A5.63,5.63,0,0,1,38.36,80a3.91,3.91,0,0,0,.48.05,4,4,0,0,0,.47-.05,5.63,5.63,0,0,1-.47,11.23Z"
    />
    <path
      fill={props.fill}
      d="M51.5,68.85V12.6a12.67,12.67,0,0,0-25.33,0V68.85a21,21,0,0,0-7,22.68,20.57,20.57,0,0,0,39.29,0,21,21,0,0,0-7-22.66ZM54.2,90.2a16.09,16.09,0,0,1-30.71,0A16.55,16.55,0,0,1,29.7,71.84a2.22,2.22,0,0,0,1-1.84V12.62a8.17,8.17,0,0,1,16.33,0V70a2.22,2.22,0,0,0,1,1.84A16.45,16.45,0,0,1,54.2,90.2Z"
    />
    <path fill={props.fill} d="M15.23,65.21h-13a2.25,2.25,0,0,0,0,4.5h13a2.25,2.25,0,1,0,0-4.5Z" />
    <path fill={props.fill} d="M15.23,34H7.36a2.25,2.25,0,1,0,0,4.5h7.87a2.25,2.25,0,0,0,0-4.5Z" />
    <path fill={props.fill} d="M15.23,23.45h-13a2.25,2.25,0,0,0,0,4.5h13a2.25,2.25,0,0,0,0-4.5Z" />
    <path fill={props.fill} d="M15.23,13.1H7.36a2.25,2.25,0,0,0,0,4.5h7.87a2.25,2.25,0,0,0,0-4.5Z" />
    <path fill={props.fill} d="M15.23,2.75h-13a2.25,2.25,0,0,0,0,4.5h13a2.25,2.25,0,0,0,0-4.5Z" />
    <path fill={props.fill} d="M15.23,54.65H7.36a2.25,2.25,0,0,0,0,4.5h7.87a2.25,2.25,0,0,0,0-4.5Z" />
    <path fill={props.fill} d="M15.23,44.33h-13a2.25,2.25,0,0,0,0,4.5h13a2.25,2.25,0,1,0,0-4.5Z" />
  </svg>
);

export default SvgFluidAutre;
