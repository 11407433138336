/**
 * Vérifie si le contenu du widget passé en paramètre
 * se base sur des données de compteur virtuel
 *
 * @method checkIsVirtual
 * @param {any} widgetContent Contenu de widget
 * @returns {boolean}
 */
function checkIsVirtual(widgetContent: any) {
  const dataSource = widgetContent.dataSourceProperty;
  const conditions = dataSource.displayProperty.condition;

  let isVirtual: boolean = false;

  for (let c in conditions) {
    const cond = conditions[c];
    if ('MeterSerialNumber' === cond.conditionTitle && cond.conditionValue.startsWith('V-')) {
      isVirtual = true;
      break;
    }
  }

  return isVirtual;
}

/**
 * Adapte le displayId si le type de données concerne un
 * compteur virtuel, le renvoie inchangé sinon
 *
 * @method getDisplayId
 * @param {any} widget Widget
 * @returns {string} Le displayId
 */
export default function getDisplayId(widget: any) {
  const isVirtual = checkIsVirtual(widget);
  let displayId = widget.dataSourceProperty.displayID;
  if (isVirtual && !displayId.startsWith('VIRTUAL') && !displayId.startsWith('MULTI')) {
    displayId = `VIRTUAL_${displayId}`;
  }

  return displayId;
}
