import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import sensorActions from '../_actions/sensor.actions';
import userActions from '../_actions/user.actions';

import { locationActions } from '../_actions';
import SensorAdeComfort from './SensorAdeComfort';
import SensorElectrovanne from './SensorElectrovanne';
import SensorAdeDeltaP from './SensorAdeDeltaP';
import SensorAdeComfortCO2 from './SensorAdeComfortCO2';
import DryContactSensor from './DryContactSensor';
import SensorAdeTemp from './SensorAdeTemp';
import Loading from '../_animations/Loading';

const sensorDisplayer = (type: string, sensorLoaded: boolean) => {
  switch (type) {
    case 'STR-EV':
      return <SensorElectrovanne />;
    case 'ADE-DTP':
      return <SensorAdeDeltaP />;
    case 'ADE-COM':
      return <SensorAdeComfort />;
    case 'ADE-TEMP':
      return <SensorAdeTemp />;
    case 'ADE-CO2':
      return <SensorAdeComfortCO2 />;
    case 'ADE-DRYC':
      return <DryContactSensor />;
    default:
      return sensorLoaded ? <div>Capteur inconnu</div> : <Loading />;
  }
};

const LigneHorizontal = styled.div`
  border-bottom: 1px solid lightgrey;
  width: 90%;
  margin: 1%;
`;

const SensorSwitch = (props: any) => {
  const {
    dispatch,
    match: {
      params: { sensorId, locationId },
    },
    sensor,
  } = props;
  useEffect(() => {
    dispatch(sensorActions.getSensorById(sensorId));
    dispatch(locationActions.getInfos(locationId));
    dispatch(userActions.getAll());

    return () => {
      dispatch(sensorActions.clear());
    };
  }, []);

  const typeSensor: string = _.get(sensor, 'sensor.info.type');

  return (
    <div className="sensor-container">{sensorDisplayer(typeSensor, _.get(sensor, 'info.sensorId') === sensorId)}</div>
  );
};

function mapStateToProps(state: any) {
  const { sensor, users } = state;
  return {
    sensor,
    users,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(SensorSwitch)));
