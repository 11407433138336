import React from 'react';

const SvgMesInformationsBlanc = (props: any) => (
  <svg viewBox="0 0 22.74 12.8" {...props}>
    <g id="mes_informations_blanc_svg__Calque_2" data-name="Calque 2">
      <g id="mes_informations_blanc_svg__Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          d="M17.85 3.62h.37a1.12 1.12 0 0 1 1.12 1.11v1.75a.33.33 0 0 1-.15.27.47.47 0 0 0-.21.38v1.69a.53.53 0 0 0 .3.48 13.61 13.61 0 0 1 2.4 1.54.37.37 0 0 1 .14.3v1.2a.47.47 0 0 0 .46.46.46.46 0 0 0 .46-.46v-1.2a1.29 1.29 0 0 0-.48-1 14.59 14.59 0 0 0-2.36-1.56V7.35a1.28 1.28 0 0 0 .35-.87V4.73a2 2 0 0 0-2-2h-.38a2 2 0 0 0-2 2v1.75a1.28 1.28 0 0 0 .35.87v.59a.46.46 0 0 0 .46.46.45.45 0 0 0 .46-.46v-.8a.46.46 0 0 0-.2-.38.31.31 0 0 1-.15-.28V4.74a1.12 1.12 0 0 1 1.12-1.12zM.48 10.13a1.33 1.33 0 0 0-.48 1v1.19a.45.45 0 0 0 .46.46.46.46 0 0 0 .46-.46v-1.17a.41.41 0 0 1 .14-.31 14.27 14.27 0 0 1 2.4-1.54.53.53 0 0 0 .3-.48V7.14a.44.44 0 0 0-.21-.38.33.33 0 0 1-.15-.28V4.74a1.12 1.12 0 0 1 1.12-1.12h.37A1.12 1.12 0 0 1 6 4.74v1.74a.33.33 0 0 1-.15.28.44.44 0 0 0-.21.38V8a.46.46 0 0 0 .46.46.45.45 0 0 0 .47-.46v-.65a1.26 1.26 0 0 0 .35-.87V4.74a2 2 0 0 0-2-2h-.4a2 2 0 0 0-2 2v1.74a1.26 1.26 0 0 0 .35.87v1.24a15.08 15.08 0 0 0-2.39 1.54z"
        />
        <path
          fill={props.fill}
          d="M16.89 12.8a.45.45 0 0 0 .46-.46v-1.55a1.52 1.52 0 0 0-.57-1.2 18.06 18.06 0 0 0-3.1-2V5.83a1.47 1.47 0 0 0 .45-1.07V2.5A2.5 2.5 0 0 0 11.64 0h-.49a2.5 2.5 0 0 0-2.5 2.5v2.26a1.48 1.48 0 0 0 .46 1.07v1.74A18.44 18.44 0 0 0 6 9.59a1.54 1.54 0 0 0-.56 1.2v1.55a.45.45 0 0 0 .45.46"
        />
      </g>
    </g>
  </svg>
);

export default SvgMesInformationsBlanc;
