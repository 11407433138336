import React, { Component } from 'react';
import Switch from 'react-switch';
import _ from 'lodash';

export default class SwitchButton extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { checked: false };
  }

  render() {
    const {
      switchClass,
      handleChange,
      checked,
      onColor,
      offColor,
      height,
      width,
      offHandleColor,
      onHandleColor,
      disabled,
    } = this.props;
    return (
      <label>
        <Switch
          className={switchClass}
          height={height}
          width={width}
          onColor={onColor}
          offColor={offColor}
          onChange={handleChange}
          checked={checked}
          checkedIcon={false}
          uncheckedIcon={false}
          offHandleColor={offHandleColor}
          onHandleColor={onHandleColor}
          disabled={disabled}
        />
      </label>
    );
  }
}

//onChange={() => { this.handleChange; this.props.checkedSwitch}}
