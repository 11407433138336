import React, { Component } from 'react';
import { useMediaQuery } from 'react-responsive';
import { UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import styled from 'styled-components';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SvgCalendrierVert from '../../../SvgComponents/CalendrierVert';
import SvgBatterieVert from '../../../SvgComponents/BatterieVerte';
import SvgAntenneOn from '../../../SvgComponents/SaphirAntenneOn';
import SvgAntenneOff from '../../../SvgComponents/SaphirAntenneOff';
import Label from './Label';
import ValidateButtons from './ValidateButtons';
import SvgCroixNoir from '../../../SvgComponents/croixNoir';
import synchroActions from '../../../_actions/synchro.actions';

const SynchroBattery = styled.div`
  background-color: ${(props: any) => (props.lvlcolor ? props.lvlcolor : '#d3d3d3')};
  width: ${(props: any) => props.x}px;
  height: 16px;
  position: absolute;
  margin: 3px;
`;

class DeviceInfo extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.batteryColor = this.batteryColor.bind(this);
    this.batteryDisplay = this.batteryDisplay.bind(this);
  }

  batteryColor(percent: any) {
    if (percent <= 20) {
      return 'red';
    }
    if (percent > 20 && percent <= 50) {
      return 'orange';
    }
    if (percent > 50) {
      return 'greenyellow';
    }
    if (percent === -1) {
      return 'lightgrey';
    }
  }

  batteryDisplay = () => {
    const { synchrocom, task } = this.props;
    const isBigScreen = useMediaQuery({ query: '(min-width: 1550px)' });
    const isNormalScreen = useMediaQuery({ query: '(max-width: 1549px)' });

    return (
      <>
        {isBigScreen && (
          <div>
            <h5 style={{ marginBottom: 0 }}>
              {synchrocom && synchrocom.battery && synchrocom.battery[task.id] && (
                <b>{`${this.props.synchrocom.battery[task.id]}%`}</b>
              )}
            </h5>
          </div>
        )}
        {isNormalScreen && (
          <div>
            <h5 style={{ marginBottom: 0 }}>
              {synchrocom && synchrocom.battery && synchrocom.battery[task.id] && (
                <b>{`${this.props.synchrocom.battery[task.id]}%`}</b>
              )}
            </h5>
          </div>
        )}
      </>
    );
  };

  responsInfoDisplay = () => {
    const { synchrocom, task, versionInfo, deathLabel, affects, t } = this.props;
    const isBigScreen = useMediaQuery({ query: '(min-width: 1450px)' });
    const isNormalScreen = useMediaQuery({ query: '(max-width: 1449px)' });
    const fullLabels = _.concat(deathLabel.affectation, deathLabel.desaffectation);

    return (
      <>
        {(isBigScreen || (fullLabels.length === 0 && affects.length === 0)) && (
          <div
            style={{ display: 'flex', textAlign: 'center', height: '45%', marginBottom: '2%', alignItems: 'center' }}
          >
            <div className="synchro-date">
              {synchrocom && synchrocom.updateDate && synchrocom.updateDate[task.id] && (
                <div className="synchro-date-box" style={{ padding: '5px 0', display: 'flex', lineHeight: 'normal' }}>
                  <div>
                    <SvgCalendrierVert height="2.3em" />
                  </div>
                  <b style={{ marginLeft: '10px' }} id={`last-communication-${task.id}`}>
                    {moment.utc(synchrocom.updateDate[task.id]).format(t('all.date_format.date_and_time'))}
                  </b>
                  <UncontrolledTooltip placement="bottom" target={`last-communication-${task.id}`}>
                    {' '}
                    {t('all.device.last_login_date')}
                  </UncontrolledTooltip>
                </div>
              )}
              {synchrocom && synchrocom.updateDate && !synchrocom.updateDate[task.id] && (
                <div style={{ textAlign: 'center', color: '#000' }}>{t('device_info.text.first_com')}</div>
              )}
            </div>
            {synchrocom && synchrocom.version && (
              <div className="synchro-version">
                <div className="synchro-version-container" id={`synchro-version-${task.id}`}>
                  {versionInfo.svg}
                  {synchrocom.version[task.id] && (
                    <UncontrolledTooltip placement="bottom" target={`synchro-version-${task.id}`}>
                      {' '}
                      {versionInfo.tooltip}
                    </UncontrolledTooltip>
                  )}
                  <b style={{ marginLeft: '5px', textAlign: 'center' }}>
                    {synchrocom.version[task.id] ? synchrocom.version[task.id] : '?'}
                  </b>
                </div>
              </div>
            )}
          </div>
        )}
        {isNormalScreen && (fullLabels.length > 0 || affects.length > 0) && (
          <div
            style={{ display: 'block', textAlign: 'center', height: '45%', marginBottom: '2%', alignItems: 'center' }}
          >
            <div className="synchro-date" style={{ width: '100%' }}>
              {synchrocom && synchrocom.updateDate && synchrocom.updateDate[task.id] && (
                <div className="synchro-date-box" style={{ padding: '5px 0', display: 'flex', lineHeight: 'normal' }}>
                  <span style={{ marginLeft: '10px' }} id={`last-communication-${task.id}`}>
                    {moment.utc(synchrocom.updateDate[task.id]).format(t('all.date_format.date_and_time'))}
                  </span>
                  <UncontrolledTooltip placement="bottom" target={`last-communication-${task.id}`}>
                    {t('all.device.last_login_date')}
                  </UncontrolledTooltip>
                </div>
              )}
              {synchrocom && synchrocom.updateDate && !synchrocom.updateDate[task.id] && (
                <span style={{ textAlign: 'center', color: '#000' }}>{t('device_info.text.first_com')}</span>
              )}
            </div>
            {synchrocom && synchrocom.version && (
              <div className="synchro-version" style={{ borderLeft: 'none', width: '100%' }}>
                <div className="synchro-version-container" id={`synchro-version-${task.id}`} style={{ padding: 0 }}>
                  {synchrocom.version[task.id] && (
                    <UncontrolledTooltip placement="bottom" target={`synchro-version-${task.id}`}>
                      {' '}
                      {versionInfo.tooltip}
                    </UncontrolledTooltip>
                  )}
                  <span style={{ marginLeft: '5px', textAlign: 'center' }}>
                    {t('all.text.version')}: {synchrocom.version[task.id] ? synchrocom.version[task.id] : '?'}
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  disaffectAll = () => {
    const { dispatch, match, task } = this.props;
    const { params } = match;
    const { GestionnaireId } = params;
    dispatch(synchroActions.disaffectAllForDevice(GestionnaireId, task.id));
  };

  render() {
    const {
      task,
      synchrocom,
      deathLabel,
      getAllCommunication,
      cancelOne,
      affects,
      delLabel,
      desaffect,
      switchToPartial,
      partiel,
      column,
      t,
      addCode,
    } = this.props;

    const fullLabels = _.concat(deathLabel.affectation, deathLabel.desaffectation).filter(el => el);
    const copyAffects = _.cloneDeep(affects).filter(el => el);
    return (
      <div
        style={{
          display: fullLabels.length > 0 || copyAffects.length > 0 ? 'flex' : 'block',
          height: '100%',
          width: '100%',
        }}
      >
        <div style={{ height: '100%', width: fullLabels.length > 0 || copyAffects.length > 0 ? '50%' : '100%' }}>
          <this.responsInfoDisplay />
          <div style={{ display: 'flex', textAlign: 'center', height: '45%', alignItems: 'center' }}>
            <div className="synchro-batterie">
              <div>
                <div className="battery-component">
                  {synchrocom && synchrocom.battery && synchrocom.battery[task.id] > 0 && (
                    <SynchroBattery
                      x={36 * (this.props.synchrocom.battery[task.id] / 100)}
                      lvlcolor={this.batteryColor(this.props.synchrocom.battery[task.id])}
                    />
                  )}
                  {synchrocom && synchrocom.battery && synchrocom.battery[task.id] > 0 && (
                    <SynchroBattery x={0} lvlcolor={-1} />
                  )}
                  <SvgBatterieVert width="4em" />
                </div>
                <this.batteryDisplay />
              </div>
            </div>
            <div className="synchro-online">
              <div className="online-component" id={`synchro-online-${task.id}`}>
                {synchrocom && synchrocom.online && synchrocom.online[task.id] ? (
                  <>
                    <SvgAntenneOn width="2em" />
                    <UncontrolledTooltip placement="bottom" target={`synchro-online-${task.id}`}>
                      {' '}
                      {t('all.device.connected')}
                    </UncontrolledTooltip>
                  </>
                ) : (
                  <>
                    <SvgAntenneOff width="2em" fill="#D3D3D3" />
                    <UncontrolledTooltip placement="bottom" target={`synchro-online-${task.id}`}>
                      {' '}
                      {t('all.device.disconnected')}
                    </UncontrolledTooltip>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {(fullLabels.length > 0 || copyAffects.length > 0) && (
          <div style={{ width: '50%' }}>
            <div className="label-list-container">
              <div style={{ width: '100%', borderLeft: '1px solid lightgrey' }}>
                <div
                  className="labels-container"
                  onScroll={(e: any) => {
                    e.stopPropagation();
                  }}
                >
                  {fullLabels.length > 0 && (
                    <>
                      <span style={{ width: '100%', borderBottom: '1px solid lightgrey', marginTop: '5px' }}>
                        {t('device_info.text.waiting_action', { count: fullLabels.length })}
                      </span>
                      {fullLabels &&
                        fullLabels.map(
                          (el: any, index: number) =>
                            el && (
                              <Label
                                label={el}
                                cancelOne={delLabel}
                                switchToPartial={switchToPartial}
                                partiel={partiel}
                                column={column}
                                withCode
                                isDevice
                              />
                            )
                        )}
                    </>
                  )}
                  {copyAffects.length > 0 && (
                    <>
                      <div
                        className="flex-box"
                        style={{
                          width: '100%',
                          borderBottom: '1px solid lightgrey',
                          marginBottom: '5px',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>{t('device_info.text.x_loaded_round', { count: copyAffects.length })}</span>
                        <div className="red-cross">
                          <div className="svg" onClick={() => this.disaffectAll()}>
                            <SvgCroixNoir height="1.7em" fill="#dc3545" />
                          </div>
                          <span className="span">{t('tournee_ressources.column_exported_tooltip.unload_all')}</span>
                        </div>
                      </div>
                      {copyAffects.map((el: string) => (
                        <Label
                          label={el}
                          cancelOne={desaffect}
                          loaded
                          withCode
                          isDevice
                          partiel={partiel}
                          switchToPartial={switchToPartial}
                          column={column}
                        />
                      ))}
                    </>
                  )}
                </div>
                {fullLabels.length > 0 && (
                  <ValidateButtons
                    getAllCommunication={getAllCommunication}
                    cancelOne={cancelOne}
                    online={synchrocom && synchrocom.online && synchrocom.online[task.id]}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { synchro, synchrocom } = state;
  return {
    synchro,
    synchrocom,
  };
}

const mapping: any = connect(mapStateToProps)(DeviceInfo);

const connectedTask = withRouter(mapping);
const tr = withTranslation()(connectedTask);
export default tr;
