import React from 'react';

const SvgSauvegardeBleu = (props: any) => (
  <svg viewBox="0 0 14.58 14.58" {...props}>
    <g id="sauvegarde_bleu_svg__Calque_2" data-name="Calque 2">
      <g id="sauvegarde_bleu_svg__Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          d="M12.48 0H1.71A1.72 1.72 0 0 0 0 1.71v11.16a1.72 1.72 0 0 0 1.71 1.71h11.16a1.72 1.72 0 0 0 1.71-1.71V2.1zM10 1.14V4.5H3.42V1.14zm1.2 12.3H3.42v-4.5h7.74zm2.28-.57a.58.58 0 0 1-.57.57h-.61V7.8h-10v5.64h-.59a.57.57 0 0 1-.57-.57V1.71a.56.56 0 0 1 .57-.57h.57v4.5h8.82v-4.5h.9l1.43 1.43z"
        />
        <path fill={props.fill} d="M7.8 1.68h1.14v2.25H7.8z" />
      </g>
    </g>
  </svg>
);

export default SvgSauvegardeBleu;
