import _ from 'lodash';
import synchroConstants from '../_constants/synchro.constants';
import importConstants from '../_constants/import.constants';

export default function synchro(state: any = {}, action: any) {
  switch (action.type) {
    case synchroConstants.GETALLROUND_FULL_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.GETALLROUND_FULL_SUCCESS:
      state.tasks = _.merge(_.cloneDeep(state.tasks) || {}, action.rounds || {});
      state.roundsUp = true;
      state.tourneeRessources = action.rounds;
      return {
        ...state,
      };
    case synchroConstants.GETALLROUND_FULL_FAILURE:
      return {
        error: 'erreur serveur: #10000',
      };

    case synchroConstants.GETALLDEVICES_REQUEST:
      state.loadingDevices = true;
      return {
        ...state,
      };
    case synchroConstants.GETALLDEVICES_SUCCESS:
      state.loadingDevices = false;
      const fusionDevices = _.merge(_.cloneDeep(state.tasks) || {}, action.devices || {});

      return {
        ...state,
        devices: action.devices,
        devicesUp: true,
        tasks: fusionDevices,
      };

    case synchroConstants.GETALLDEVICES_FAILURE:
      return {
        error: "Code d'erreur: 10001, serveur erreur",
      };

    case synchroConstants.COMMUNICATION_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.COMMUNICATION_SUCCESS:
      return {
        ...state,
      };
    case synchroConstants.COMMUNICATION_FAILURE:
      return {
        error: "Code d'erreur: 10003, serveur erreur",
      };

    case synchroConstants.COM_ALL_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.COM_ALL_SUCCESS:
      return {
        ...state,
      };
    case synchroConstants.COM_ALL_FAILURE:
      return {
        error: "Code d'erreur: 10004, serveur erreur",
      };

    case synchroConstants.CANCEL_ALL_REQUEST:
      if (state.labels) {
        state.labels.affect = [];
        state.labels.disaffect = [];
      }
      return {
        ...state,
      };
    case synchroConstants.CANCEL_ALL_SUCCESS:
      return {
        ...state,
      };
    case synchroConstants.CANCEL_ALL_FAILURE:
      return {
        error: 'erreur serveur: #10005',
      };

    case synchroConstants.CANCEL_DEVICE_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.CANCEL_DEVICE_SUCCESS:
      state.labels.disaffect = _.cloneDeep(state.labels.disaffect).filter((el: any) => el.device !== action.deviceId);
      return {
        ...state,
      };
    case synchroConstants.CANCEL_DEVICE_FAILURE:
      return {
        error: "Code d'erreur: 10006, serveur erreur",
      };
    case synchroConstants.COM_DEVICE_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.COM_DEVICE_SUCCESS:
      return {
        ...state,
      };
    case synchroConstants.COM_DEVICE_FAILURE:
      return {
        ...state,
        devicesError: action.error,
      };
    case synchroConstants.CANCEL_ROUND_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.CANCEL_ROUND_SUCCESS:
      return {
        ...state,
      };
    case synchroConstants.CANCEL_ROUND_FAILURE:
      return {
        error: "Code d'erreur: 10008, serveur erreur",
      };
    case synchroConstants.COM_ROUND_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.COM_ROUND_SUCCESS:
      return {
        ...state,
      };
    case synchroConstants.COM_ROUND_FAILURE:
      return {
        ...state,
        devicesError: action.error,
      };

    case synchroConstants.UPDATENAME_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.UPDATENAME_SUCCESS:
      const updateDevice = _.entries(action.device)[0];
      _.entries(state.tasks).find(el => el[0] === updateDevice[0])[1].content = updateDevice[1].content;
      return {
        ...state,
        tasks: state.tasks,
      };
    case synchroConstants.UPDATENAME_FAILURE:
      return {
        error: "Code d'erreur: 10010, serveur erreur",
      };

    case synchroConstants.GETALLMESSAGES_REQUEST:
      return {
        loading: true,
      };
    case synchroConstants.GETALLMESSAGES_SUCCESS:
      const messagesList = action.messages.filter(el => el.code !== '  ');
      return {
        messages: messagesList,
      };
    case synchroConstants.GETALLMESSAGES_FAILURE:
      return {
        error: "Code d'erreur: 10011, serveur erreur",
      };
    case synchroConstants.GETALLMARQUAGES_REQUEST:
      return {
        loading: true,
      };
    case synchroConstants.GETALLMARQUAGES_SUCCESS:
      const marquagesList = action.marquages.filter(el => el.code !== '  ');
      return {
        marquages: marquagesList,
      };
    case synchroConstants.GETALLMARQUAGES_FAILURE:
      return {
        error: "Code d'erreur: 10012, serveur erreur",
      };
    case synchroConstants.SYNCHRODEVICE_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.SYNCHRODEVICE_SUCCESS:
      return {
        ...state,
      };
    case synchroConstants.SYNCHRODEVICE_FAILURE:
      return {
        error: "Code d'erreur: 10013, serveur erreur",
      };
    case synchroConstants.SYNCHROROUND_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.SYNCHROROUND_SUCCESS:
      return {
        ...state,
      };
    case synchroConstants.SYNCHROROUND_FAILURE:
      return {
        error: "Code d'erreur: 10014, serveur erreur",
      };

    case synchroConstants.ADDAFFECT_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.ADDAFFECT_SUCCESS:
      _.forIn(action.affect, function(value, key) {
        if (_.has(state.tasks[key], 'serial')) {
          action.affect[key].forEach(element => {
            state.labels.affect.unshift({ tournee: element, device: key });
          });
        } else {
          action.affect[key].forEach(element => {
            state.labels.affect.unshift({ tournee: key, device: element });
          });
        }
      });
      return {
        ...state,
      };
    case synchroConstants.ADDAFFECT_FAILURE:
      return {
        ...state,
        error: "Code d'erreur: 10015, serveur erreur",
      };
    case synchroConstants.DELAFFECT_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.DELAFFECT_SUCCESS:
      let newAffect = _.cloneDeep(state.labels.affect);
      _.forIn(action.affect, function(value, key) {
        if (_.has(state.tasks[key], 'serial')) {
          action.affect[key].forEach((element: any) => {
            newAffect = newAffect.filter((el: any) => !(el.tournee === element && el.device === key));
          });
        } else {
          action.affect[key].forEach((element: any) => {
            newAffect = newAffect.filter((el: any) => !(el.tournee === key && el.device === element));
          });
        }
      });
      state.labels.affect = newAffect;
      return {
        ...state,
      };
    case synchroConstants.DELAFFECT_FAILURE:
      return {
        ...state,
        error: "Code d'erreur: 10016, serveur erreur",
      };
    case synchroConstants.ADDDESAFFECT_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.ADDDESAFFECT_SUCCESS:
      _.forIn(action.desaffect, function(value, key) {
        if (_.has(state.tasks[key], 'serial')) {
          action.desaffect[key].forEach(element => {
            state.labels.disaffect.unshift({ tournee: element, device: key });
          });
        } else {
          action.desaffect[key].forEach(element => {
            state.labels.disaffect.unshift({ tournee: key, device: element });
          });
        }
      });
      return {
        ...state,
      };
    case synchroConstants.ADDDESAFFECT_FAILURE:
      return {
        ...state,
        error: "Code d'erreur: 10017, serveur erreur",
      };
    case synchroConstants.DELDESAFFECT_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.DELDESAFFECT_SUCCESS:
      _.forIn(action.desaffect, function(value, key) {
        if (_.has(state.tasks[key], 'serial')) {
          action.desaffect[key].forEach(element => {
            state.labels.disaffect = _.cloneDeep(state.labels.disaffect).filter(
              el => !(el.tournee === element && el.device === key)
            );
          });
        } else {
          action.desaffect[key].forEach(element => {
            state.labels.disaffect = _.cloneDeep(state.labels.disaffect).filter(
              el => !(el.tournee === key && el.device === element)
            );
          });
        }
      });
      return {
        ...state,
      };
    case synchroConstants.DELDESAFFECT_FAILURE:
      return {
        ...state,
        error: "Code d'erreur: 10018, serveur erreur",
      };
    case synchroConstants.GETLABELS_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.GETLABELS_SUCCESS:
      if (state.labels !== action.labels) {
        state.labels = action.labels;
        return {
          ...state,
          loading: false,
        };
      }
      break;
    case synchroConstants.GETLABELS_FAILURE:
      return {
        ...state,
        error: "Code d'erreur: 10019, serveur erreur",
      };
    case synchroConstants.GETCURRENTVERSION_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.GETCURRENTVERSION_SUCCESS:
      if (state && state.version) {
        state.version = _.merge(action.version, state.version);
        return {
          ...state,
        };
      }
      return {
        version: action.version,
        ...state,
      };

    case synchroConstants.GETCURRENTVERSION_FAILURE:
      return {
        error: "Code d'erreur: 10020, serveur erreur",
      };
    case synchroConstants.GETMINIVERSION_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.GETMINIVERSION_SUCCESS:
      if (state && state.version) {
        state.version = _.merge(action.version, state.version);
        return {
          ...state,
        };
      }
      return {
        version: action.version,
        ...state,
      };

    case synchroConstants.UPDATE_UNDROP_REQUEST:
      state.unDrop = [];
      return {
        ...state,
      };
    case synchroConstants.UPDATE_UNDROP_SUCCESS:
      state.unDrop = action.devicesId;
      return {
        ...state,
      };

    case synchroConstants.GETMINIVERSION_FAILURE:
      return {
        error: "Code d'erreur: 10021, serveur erreur",
      };

    case synchroConstants.GET_FULLAFFECT_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.GET_FULLAFFECT_SUCCESS:
      const newState = _.cloneDeep(state);
      _.entries(action.fullAffect).forEach((el: any) => {
        if (newState.tasks && newState.tasks[el[0]] && newState.tasks[el[0]].affectList) {
          newState.tasks[el[0]].affectList = [];
          _.cloneDeep(el[1]).forEach(t => newState.tasks[el[0]].affectList.push(t));
        }
      });
      return {
        ...newState,
      };
    case synchroConstants.GET_FULLAFFECT_FAILURE:
      return {
        error: "Code d'erreur: 10022, serveur erreur",
      };
    case synchroConstants.ADD_MESSAGES_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.ADD_MESSAGES_SUCCESS:
      state.messages = state.messages.filter(
        (markS: any) => !action.messages.find((markA: any) => markA.code === markS.code)
      );
      state.messages = _.concat(state.messages, action.messages);
      return {
        ...state,
      };
    case synchroConstants.ADD_MESSAGES_FAILURE:
      return {
        ...state,
      };
    case synchroConstants.ADD_MARQUAGES_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.ADD_MARQUAGES_SUCCESS:
      state.marquages = state.marquages.filter(
        (markS: any) => !action.marquages.find((markA: any) => markA.code === markS.code)
      );
      state.marquages = _.concat(state.marquages, action.marquages);
      return {
        ...state,
      };
    case synchroConstants.ADD_MARQUAGES_FAILURE:
      return {
        ...state,
      };
    case synchroConstants.GET_DEVICE_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.GET_DEVICE_SUCCESS:
      return {
        device: action.serial,
        ...state,
      };
    case synchroConstants.GET_DEVICE_FAILURE:
      return {
        error: 'Impossible de récupérer les informations du téléphone',
        ...state,
      };
    case synchroConstants.LOGIN_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.LOGIN_SUCCESS:
      return {
        ...state,
      };
    case synchroConstants.LOGIN_FAILURE:
      return {
        error: "Impossible de s'autentifier",
        ...state,
      };
    case synchroConstants.LOGOUT_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.LOGOUT_SUCCESS:
      return {
        ...state,
      };
    case synchroConstants.LOGOUT_FAILURE:
      return {
        error: 'Problème de déconnexion',
        ...state,
      };
    case importConstants.IMPORT_MESSAGES_SUCCESS:
      const oldList = state.messages.filter((markS: any) =>
        action.data.find((markA: any) => markA.code === markS.code)
      );
      const newList = action.data.filter((el: any) => !state.messages.find((message: any) => el.code === message.code));

      state.messages = state.messages.filter(
        (markS: any) => !action.data.find((markA: any) => markA.code === markS.code)
      );

      state.messages = _.concat(state.messages, action.data).map((el: any) => {
        if (oldList.find((old: any) => old.code === el.code)) {
          el.old = true;
        }
        if (newList.find((newM: any) => newM.code === el.code)) {
          el.new = true;
        }
        return el;
      });

      return {
        ...state,
      };
    case importConstants.IMPORT_MESSAGES_FAILURE:
      return {
        error: action.error,
      };
    case importConstants.IMPORT_MARQUAGES_SUCCESS:
      const oldListM = state.marquages.filter((markS: any) =>
        action.data.find((markA: any) => markA.code === markS.code)
      );
      const newListM = action.data.filter(
        (el: any) => !state.marquages.find((marquage: any) => el.code === marquage.code)
      );

      state.marquages = state.marquages.filter(
        (markS: any) => !action.data.find((markA: any) => markA.code === markS.code)
      );

      state.marquages = _.concat(state.marquages, action.data).map((el: any) => {
        if (oldListM.find((old: any) => old.code === el.code)) {
          el.old = true;
        }
        if (newListM.find((newM: any) => newM.code === el.code)) {
          el.new = true;
        }
        return el;
      });
      return {
        ...state,
      };
    case importConstants.IMPORT_MARQUAGES_FAILURE:
      return {
        error: action.error,
      };
    case synchroConstants.SWITCH_PARTIAL_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.SWITCH_PARTIAL_SUCCESS:
      const actionObj = {
        tournee: action.affectation.roundCode,
        device: action.affectation.deviceSerial,
        partialOption: action.affectation.partial,
      };
      const isAffect = state.labels.affect.find(
        (el: any) => actionObj.tournee === el.tournee && actionObj.device === el.device
      );
      const isDisaffect = state.labels.disaffect.find(
        (el: any) => actionObj.tournee === el.tournee && actionObj.device === el.device
      );
      if (isAffect) {
        const index = state.labels.affect.findIndex(
          (el: any) => actionObj.tournee === el.tournee && actionObj.device === el.device
        );
        state.labels.affect[index] = actionObj;
      }
      if (isDisaffect) {
        const index = state.labels.disaffect.findIndex(
          (el: any) => actionObj.tournee === el.tournee && actionObj.device === el.device
        );
        state.labels.disaffect[index] = actionObj;
      }
      return {
        ...state,
      };
    case synchroConstants.SWITCH_PARTIAL_FAILURE:
      return {
        error: 'Error partial',
        ...state,
      };
    case synchroConstants.DISAFFECT_ALL_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.DISAFFECT_ALL_SUCCESS:
      const disaffectList = _.cloneDeep(state.labels.disaffect);
      const key = Object.keys(action.device)[0];
      action.device[key].forEach((code: any) => {
        const dLabel = { tournee: code, device: key };
        disaffectList.push(dLabel);
      });
      state.labels.disaffect = disaffectList;
      return {
        ...state,
      };
    case synchroConstants.DISAFFECT_ALL_FAILURE:
      return {
        error: 'Action impossible',
        ...state,
      };
    case synchroConstants.DISAFFECT_ALL_FOR_ROUND_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.DISAFFECT_ALL_FOR_ROUND_SUCCESS:
      const disaffectListR = _.cloneDeep(state.labels.disaffect);
      const cKey = Object.keys(action.round)[0];
      action.round[cKey].forEach((code: any) => {
        const rLabel = { tournee: code, device: key };
        disaffectListR.push(rLabel);
      });
      state.labels.disaffect = disaffectListR;
      return {
        ...state,
      };
    case synchroConstants.DISAFFECT_ALL_FOR_ROUND_FAILURE:
      return {
        error: 'Action impossible',
        ...state,
      };
    case synchroConstants.GET_DEVICES_ERROR_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.GET_DEVICES_ERROR_SUCCESS:
      return {
        ...state,
        devicesError: action.devices,
      };
    case synchroConstants.GET_DEVICES_ERROR_FAILURE:
      return {
        ...state,
        error: 'Action impossible',
      };
    case synchroConstants.REMOVE_DEVICES_ERROR_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.REMOVE_DEVICES_ERROR_SUCCESS:
      return {
        ...state,
        devicesError: action.devices,
      };
    case synchroConstants.REMOVE_DEVICES_ERROR_FAILURE:
      return {
        ...state,
        error: 'Action impossible',
      };
    case synchroConstants.ROTATE_ROUND_REQUEST:
      return {
        ...state,
        rotationLoading: state.rotationLoading
          ? (state.rotationLoading[action.roundCode] = true)
          : { [action.roundCode]: true },
      };
    case synchroConstants.ROTATE_ROUND_SUCCESS:
      return {
        ...state,
        rotationLoading: state.rotationLoading[action.roundCode] = undefined,
      };
    case synchroConstants.ROTATE_ROUND_FAILURE:
      return {
        ...state,
        rotationLoading: state.rotationLoading[action.roundCode] = undefined,
        error: action.error,
      };
    case synchroConstants.DATA_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case synchroConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
