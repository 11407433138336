import React from 'react';

const SvgCompteurVert = (props: any) => (
  <svg viewBox="0 0 14.99 12.82" {...props}>
    <g id="compteur_vert_svg__Calque_2" data-name="Calque 2">
      <g id="compteur_vert_svg__Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          d="M14.84 10h-.09v-.09a.16.16 0 0 0-.17-.13.14.14 0 0 0-.13.15.14.14 0 0 0-.16-.13.15.15 0 0 0-.14.15.15.15 0 0 0-.15-.17.15.15 0 0 0-.14.16V10H12l.51-2.45a.39.39 0 0 0-.39-.39H2.89a.38.38 0 0 0-.38.39L3 10H1.14v-.09A.14.14 0 0 0 1 9.78a.15.15 0 0 0-.14.15.15.15 0 0 0-.16-.13.15.15 0 0 0-.14.15.15.15 0 0 0-.16-.13.14.14 0 0 0-.14.16V10h-.1a.16.16 0 0 0-.16.16v1.54a.16.16 0 0 0 .16.16h.26V12a.15.15 0 0 0 .15.13.14.14 0 0 0 .16-.13.15.15 0 0 0 .15.13A.14.14 0 0 0 1 12a.15.15 0 0 0 .15.13.14.14 0 0 0 .18-.13v-.08H3.4l.11.56a.38.38 0 0 0 .39.38h7.2a.38.38 0 0 0 .38-.38l.12-.56H14V12a.15.15 0 0 0 .15.13.14.14 0 0 0 .13-.15.14.14 0 0 0 .15.13.15.15 0 0 0 .14-.15.14.14 0 0 0 .15.13.15.15 0 0 0 .14-.16v-.11a.16.16 0 0 0 .06-.13v-1.51a.16.16 0 0 0-.08-.18zM2 6.74h11c.5 0 .87-.64.75-1.22a17.47 17.47 0 0 0-2-5c-.16-.24-.39-.52-.62-.52H3.87c-.24 0-.47.24-.61.47a17 17 0 0 0-2 5c-.15.58.21 1.27.74 1.27z"
        />
      </g>
    </g>
  </svg>
);

export default SvgCompteurVert;
