import React, { Component, Fragment } from 'react';
import { Button } from 'reactstrap';
import { FaCog, FaRedo } from 'react-icons/fa';
import _, { cloneDeep, isArray, isUndefined } from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import translate from '../_helpers/locale-helpers';
import { formatAlarmData } from '../_helpers/alarms-types-helper';
import { convertDate } from '.';
import { alertActions } from '../_actions';
import SimpleWidget from './SimpleWidget';
import FullScreenWidget from './FullScreenWidget';
import WidgetBlanc from '../SvgComponents/WidgetBlanc';
import { colors, locales } from '../_interfaces/reducers';
import { Widget } from '../_entities/widget';
import { formatAdditionalData, formatMultiMeterData } from '../_helpers/statements-helper';
import moment from 'moment';
import { widgetConstants } from '../_constants';
import TinyWidget from './TinyWidget';
import LoadingBand from '../Bands/Loading';
import ErrorBand from '../Bands/Error';
import widgetActions from '../_actions/widget.actions';
import WidgetChart from './WidgetChart';
import SvgPetitEcranVert from '../SvgComponents/PetitEcranVert';
import { withTranslation } from 'react-i18next';

interface Props {
  data: Widget | undefined | any;
  remove?: Function;
  openEditor?: Function;
  couldEditWidget?: boolean;
  quitFullScreen?: Function;
  displayFullScreen?: Function;
  widgets: {
    widgetId: number | null | undefined;
    error: string | null | undefined;
    updatedWidget: any | null | undefined;
    widgetContentReturn: any | null | undefined;
    data: any;
  };
  colors: colors;
  locales: locales;
  dispatch: Function;
  match: any;
  modal?: boolean;
  isPreview?: boolean;
  dataPreview: any;
  containerId: any;
}

interface State {
  data: any;
  displayInfo: boolean;
  hashKeyChart: number;
  errorFetch: string;
  updated: boolean;
  loading: boolean;
  loadingCount: number;
  isMasked: boolean;
}

/**
 * @class BaseWidget
 * @extends {Component}
 */
class BaseWidget extends Component<Props, State> {
  interval: NodeJS.Timeout | undefined;

  static defaultProps = {
    isPreview: false,
  };

  /**
   * Définit l'intervale de rafraichissement des widgets,
   * et créé l'instance
   *
   * @param {Props} props Propriétés
   * @constructor
   * @memberof BaseWidget
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      data: {},
      displayInfo: false,
      hashKeyChart: 0,
      errorFetch: '',
      updated: false,
      loading: false,
      loadingCount: 0,
      isMasked: false,
    };

    const displayRefresh = props.data != undefined ? props.data.content.dataSourceProperty.displayRefresh : 0;
    if (displayRefresh != 0) {
      this.interval = setInterval(() => this.updateData(), displayRefresh * 1000);
    }
  }

  /**
   * Récupère le contenu des widgets au montage du
   * composant
   *
   * @memberof BaseWidget
   * @method componentDidMount
   */
  componentDidMount() {
    const { data } = this.state;
    const { data: propsData } = this.props;

    if (data && data.configured) {
      this.getContent(data.id);
    } else if (propsData && propsData.configured) {
      this.getContent(propsData.id);
    } else {
      const baseData = propsData;
      this.setState({
        data: baseData,
      });
    }
  }

  /**
   * A la mise à jour du composant, récupère le contenu
   * du widget s'il a été mis à jour via le WidgetCreator
   *
   * @param {Props} prevProps Props précédentes
   * @param {State} prevState State précédent
   * @param {any} snapshot Snapshot
   */
  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    const { widgets, data, dispatch } = this.props;
    const { widgets: prevWidgets } = prevProps;
    if (
      undefined !== widgets.updatedWidget &&
      undefined !== data &&
      widgets.updatedWidget.id === data.id &&
      widgets.updatedWidget !== prevWidgets.updatedWidget
    ) {
      setTimeout(() => {
        this.getContent(widgets.updatedWidget.id);
      }, 50);
    }
  }

  /**
   * Détermine si le composant doit être mis à jour
   *
   * @param {Object} nextProps
   * @param {Object} nextState
   * @returns {boolean} La mise à jour ou non
   * @memberof BaseWidget
   */
  shouldComponentUpdate(nextProps: any, nextState: any) {
    const { displayInfo } = this.state;
    const { data } = nextState;
    const { data: baseData, widgets } = this.props;

    //Si la pop up d'édition est ouverte on ne met pas à jour
    if (nextProps.modal) {
      return false;
    }

    return (
      (widgets.updatedWidget && widgets.updatedWidget.id === data.id) ||
      (baseData && data.id === baseData.id) ||
      displayInfo !== nextState.displayInfo
    );
  }

  /**
   * Supprime l'intervale au démontage du composant
   *
   * @method componentWillUnmount
   * @memberof BaseWidget
   */
  componentWillUnmount() {
    if (this.interval != undefined) {
      clearInterval(this.interval);
    }
  }

  // END LIFE CYCLE

  /**
   * Récupère le contenu du widget s'il a été configuré
   * et qu'aucune erreur n'a été remontée
   *
   * @method updateData
   * @memberof BaseWidget
   */
  updateData = () => {
    const { dispatch, modal } = this.props;
    const { data, errorFetch } = this.state;
    if (!modal && data.configured && errorFetch.length === 0) {
      this.getContent(data.id);
    }
  };

  static getDerivedStateFromProps(props: any, state: State) {
    let cloneState: any = _.clone(state);
    cloneState.data = props.data;
    if (
      cloneState.data &&
      cloneState.data.content &&
      props.data.id &&
      props.widgets &&
      props.widgets.data &&
      props.widgets.data[props.data.id]
    ) {
      const returnedData = {
        data: _.entries(props.widgets.data[props.data.id])
          .map(el => _.entries(el[1]))
          .flat()
          .map(value => ({ consumption: value[0], date: value[1] })),
      };
      cloneState.updated = true;
      cloneState.loading = false;
      //returnedData.data = props.widgets.data[props.data.id]
    }
    return cloneState;
  }

  /**
   * Récupère les infos du widget sans passer par Redux
   *
   * @method getContent
   * @memberof BaseWidget
   * @param {number} widgetId L'id du widget
   */
  getContent = (widgetId: number) => {
    const { data, dispatch } = this.props;
    dispatch(widgetActions.getWidgetData(data.id, data.content.dataSourceProperty.displayID));
  };

  /**
   * Gère l'affichage ou non de la tooltip d'infos
   * du widget
   *
   * @method toggleInfo
   * @memberof BaseWidget
   */
  toggleInfo = () => {
    const { displayInfo } = this.state;
    this.setState({
      displayInfo: !displayInfo,
    });
  };

  /**
   * Gère l'ouverture du composant d'édition du
   * widget
   *
   * @method openWidgetEditor
   * @memberof BaseWidget
   */
  openWidgetEditor = () => {
    const { openEditor, dispatch } = this.props;
    const { data } = this.state;
    dispatch(alertActions.clear());
    if (openEditor) {
      openEditor(data.id);
    }
  };

  /**
   * Relance la récupération du contenu du widget
   * dans le cas où la liaison avec le back serait
   * perdue
   *
   * @method launchInterval
   * @memberof BaseWidget
   */
  launchInterval = () => {
    const { dispatch } = this.props;
    const { data } = this.state;
    this.setState({ errorFetch: '' });
    // dispatch(widgetActions.getContent(data.id));
    this.getContent(data.id);
  };

  /**
   * Construit le sous-titre en fonction du type
   * de widget et de son intervalle
   *
   * @method getSubtitleData
   * @memberof BaseWidget
   * @param {string} displayValue Type de widget
   * @param {any[]} conditions Condition
   * @returns {string} Le sous-titre
   */
  getSubtitleData = (dataSourceProperty: any) => {
    const { locales } = this.props;

    let display = '';
    if (dataSourceProperty) {
      let data: any[] = [];
      if (dataSourceProperty.displayValue) {
        display = `${translate('fr', 'displayValue', dataSourceProperty.displayValue, locales.locale)}`;
      }
      if (
        dataSourceProperty.displayProperty &&
        dataSourceProperty.displayProperty.condition &&
        dataSourceProperty.displayProperty.condition.length > 0
      ) {
        const interval: any = dataSourceProperty.displayProperty.condition.find(
          (it: any) => it.conditionType === 'DateInterval'
        );
        if (interval && null !== interval.conditionValue) {
          data.push(
            `${interval.conditionValue.value} ${translate(
              'fr',
              'conditionHelper',
              interval.conditionValue.name,
              locales.locale,
              'name'
            )}`
          );
          data.push(translate('fr', 'conditionHelper', interval.conditionValue.zoom, locales.locale, 'zoom'));
        }
      }
      if (dataSourceProperty.displayUnit) {
        data.push(translate('fr', 'unit', dataSourceProperty.displayUnit, locales.locale));
      }

      if (data.length > 0) {
        display = `${display} - ${data.join(' / ')}`;
      }
    }

    return display;
  };

  /**
   * Construit le bloc header, contenant notamment la tooltip
   * d'infos et le lien vers la fiche du module
   *
   * @method getHeader
   * @memberof BaseWidget
   * @returns {JSX} Le bloc header
   */
  getHeader = () => {
    const { locales, match, quitFullScreen, t } = this.props;
    const { data } = this.state;
    const dataSourceProperty = data.content ? data.content.dataSourceProperty : null;
    const displayValue = dataSourceProperty ? dataSourceProperty.displayValue : '';
    const conditions = dataSourceProperty ? dataSourceProperty.displayProperty.condition : [];

    const conditionSerialNumber = conditions.find((c: any) => c.conditionTitle.includes('SerialNumber'));
    let sourceType: string = '';
    if (undefined !== conditionSerialNumber) {
      sourceType = !conditionSerialNumber.conditionValue.startsWith('V-')
        ? data.content.dataSourceName.toLowerCase()
        : 'virtual';
    }

    const linkToInfo =
      sourceType.length > 0
        ? `/locations/${match.params.locationId}/${sourceType}s/info?serial=${conditionSerialNumber.conditionValue}`
        : '';
    return (
      <Fragment>
        {data.configured && (
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <h2 style={{ fontSize: '1.2em', maxWidth: '75%', overflow: 'hidden' }}>
              <span>
                <WidgetBlanc height="1em" width="1em" fill="#31c6b3" />
              </span>{' '}
              {conditionSerialNumber && <Link to={linkToInfo}>{conditionSerialNumber.conditionValue}</Link>} -{' '}
              {data.name} <br />
              <span style={{ fontWeight: 'normal', fontSize: '12px', marginLeft: '6px' }}>
                {this.getSubtitleData(dataSourceProperty)}
              </span>
            </h2>
            {quitFullScreen && (
              <div className="head-widget">
                <div
                  className="clickable round"
                  role="presentation"
                  onClick={quitFullScreen as any}
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  <SvgPetitEcranVert className="add" fill="#808080" height="1.5em" width="1.5em" />
                </div>
              </div>
            )}
            <div className="clearfix" />
          </div>
        )}
        {!data.configured && undefined !== data.content && (
          <span style={{ textAlign: 'justify' }}>
            <h2 style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
              {t('widget.text.not_configured')} -{' '}
              {t(`widget.display_value.${data.content.dataSourceProperty.displayValue.toLowerCase()}`)} <br />
              <span style={{ fontWeight: 'normal', fontSize: '12px', marginLeft: '6px' }}>
                {this.getSubtitleData(displayValue)}
              </span>
            </h2>
          </span>
        )}
      </Fragment>
    );
  };

  /**
   * Teste si le contenu du widget représente les alarmes
   * d'un compteur
   *
   * @returns {boolean}
   * @method isMeterAlarm
   * @memberof BaseWidget
   */
  isMeterAlarm = () => {
    const { data } = this.state;
    return data.content.dataSourceProperty.displayValue === 'MeterAlarm';
  };

  validReadsTooltip = (tooltipModel: any, additionalInfo: any) => {
    if (tooltipModel.opacity > 0) {
      let { footer, title, body } = tooltipModel;
      const existing = additionalInfo.values.find((it: any) => {
        if (it.hasOwnProperty('serial')) {
          let bodySerial = body[0].lines[0];
          let checkSerial = new RegExp(`${it.serial}`);
          return it.date === title[0] && checkSerial.test(bodySerial);
        } else {
          return it.date === title[0];
        }
      });
      if (existing && !existing.valid) {
        footer.push('Lecture invalide');
        footer.push(`${existing.missings} compteurs manquants`);
        tooltipModel.footerFontColor = 'red';
        tooltipModel.width = 170;
        tooltipModel.height = 75;
      }
    }
  };

  alarmsTooltip = (tooltipModel: any, zoom: string) => {
    if (tooltipModel.opacity > 0) {
      const { locales }: any = this.props;
      const { data } = this.state;
      const date = tooltipModel.title[0];
      let label = tooltipModel.body[0].lines[0].split(':');
      const filteredAlarms = data.content.returnedData.filter((it: any) => convertDate(it.date, zoom) === date);
      let meters: any = [];
      filteredAlarms.forEach((a: any) => {
        const alarmLabel: any = a.data.find(
          (l: any) => translate('fr', 'alarmType', l.type, locales.locale) === label[0]
        );
        if (undefined !== alarmLabel) {
          alarmLabel.meters.forEach((it: any) => {
            meters.push(it.serial);
          });
        }
      });
      label[1] = meters.join(', ');
      tooltipModel.height = 60;
      tooltipModel.width = 140;
      tooltipModel.body[0].lines[0] = `${label[0]}:`;
      tooltipModel.footer[0] = label[1];
    }
  };

  /**
   * Construit le bloc contenant le graphe du widget
   *
   * @returns {JSX} Le bloc body
   * @method getBody
   * @memberof BaseWidget
   */
  getBody = (isMasked: boolean) => {
    const { data, hashKeyChart, errorFetch, loading } = this.state;
    const { locales, colors, widgets, isPreview, dataPreview, containerId } = this.props;
    const dicoData = data.content && data.content.returnedData ? data.content.returnedData : [];
    const maskedAlarms = dicoData.dataMasked;
    const isConfigured = data.configured;
    console.log(errorFetch);
    if (isConfigured) {
      if (loading) {
        return <LoadingBand message="Chargement du widget" />;
      }
      //return <YieldChart />;
      if (errorFetch.length > 0) {
        return (
          <div>
            <ErrorBand message={errorFetch} />
            <Button color="primary" size="sm" onClick={this.launchInterval}>
              <FaRedo />
            </Button>
          </div>
        );
      }
      if (dicoData) {
        //Si enrichi
        const xIsTime = data.content.dataSourceProperty.displayProperty.condition.find(
          (elem: any) => elem.conditionType === 'DateInterval'
        );
        let displayId = data.content.dataSourceProperty.displayID;
        const additionalInfo: any = {
          type: displayId,
          values: [],
          colors: [],
        };

        let type = data.content.dataSourceProperty.displayProperty.displayType;

        let labels: any = [];
        let values: any = [];
        let sortedData: any = [];
        //Attention à refaire un sort par date et à formater les date en fonction du zoom
        //Le zoom ne dois pas fonctionner
        const zoom = xIsTime ? xIsTime.conditionValue.zoom : 'Day';

        if (isArray(dicoData)) {
          sortedData = dicoData.sort((a: any, b: any) => {
            let dateA = moment(a.date);
            let dateB = moment(b.date);
            return dateA.diff(dateB);
          });
        } else {
          sortedData = dicoData.data.sort((a: any, b: any) => {
            let dateA = moment(a.date);
            let dateB = moment(b.date);
            return dateA.diff(dateB) > 0;
          });
        }
        switch (displayId) {
          case widgetConstants.READINDEXWIDGET:
            let vmeterRead = sortedData.find((it: any) => undefined !== it.vmId);
            if (undefined !== vmeterRead) {
              values = sortedData.map((it: any) => {
                return {
                  value: it.index,
                  meterCount: it.meterCount,
                  indexCount: it.indexCount,
                };
              });
            } else {
              values = sortedData.map((it: any) => it.index);
            }
            labels = sortedData.map((it: any) => it.date);
            break;
          case widgetConstants.METERYIELDWIDGET:
          case widgetConstants.MULTIREADINDEXWIDGET:
          case widgetConstants.MULTIREADCONSOWIDGET:
            let result: any = formatMultiMeterData(sortedData, dicoData.refId, displayId);
            values = result.values;
            labels = result.labels;
            break;
          case widgetConstants.READCONSUMTIONWIDGET:
            let vmeterConso = sortedData.find((it: any) => undefined !== it.vmId);
            if (undefined !== vmeterConso) {
              values = sortedData.map((it: any) => {
                return {
                  value: it.consumption,
                  meterCount: it.meterCount,
                  indexCount: it.indexCount,
                };
              });
            } else {
              values = sortedData.map((it: any) => it.consumption);
            }
            labels = sortedData.map((it: any) => it.date);
            break;
          case widgetConstants.READRADIOWIDGET:
            values = sortedData.map((it: any) => it.index);
            labels = sortedData.map((it: any) => it.date);
            break;
          case widgetConstants.ALARMWIDGET:
            if (this.isMeterAlarm()) {
              const alarmData = formatAlarmData(cloneDeep(sortedData), locales, colors, isMasked);
              type = 'StackedHistogram';
              // eslint-disable-next-line prefer-destructuring
              labels = alarmData.labels;
              // eslint-disable-next-line prefer-destructuring
              values = alarmData.values;
            }
            break;
          default:
            this.setState({
              errorFetch: 'Erreur lors de la lecture de la réponse',
            });
        }
        if (displayId === widgetConstants.MULTIREADINDEXWIDGET || displayId === widgetConstants.MULTIREADCONSOWIDGET) {
          values = values.map((it: any) => {
            it.data = formatAdditionalData(it.data, labels, additionalInfo, zoom, it.label);
            return it;
          });
        } else {
          values = formatAdditionalData(values, labels, additionalInfo, zoom);
        }
        if (widgets.data) {
          return (
            <WidgetChart
              colors={colors}
              locales={locales}
              widgetType={displayId}
              data={widgets.data[data.id]}
              type={type}
              zoom={
                data.content.dataSourceProperty.displayProperty.condition[
                  data.content.dataSourceProperty.displayProperty.condition.length - 1
                ].conditionValue.zoom
              }
              containerId={containerId}
            />
          );
        }
      }
    }
    if (isPreview) {
      let type = data.content.dataSourceProperty.displayProperty.displayType;
      return (
        <WidgetChart
          data={dataPreview}
          type={type}
          zoom={
            data.content.dataSourceProperty.displayProperty.condition[
              data.content.dataSourceProperty.displayProperty.condition.length - 1
            ].conditionValue.zoom
          }
        />
      );
    }
    return <FaCog size={100} style={{ marginTop: '9%' }} />;
  };

  /**
   * Gère la suppression du widget
   *
   * @method removeWidget
   * @memberof BaseWidget
   */
  removeWidget = () => {
    const { remove } = this.props;
    const { data } = this.state;
    if (remove) {
      remove(data.id);
    }
  };

  masqueAlarms = () => {
    this.setState({
      isMasked: !this.state.isMasked,
    });
  };

  /**
   * Construit le widget simple ou full screen, avec
   * les différents blocs
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof BaseWidget
   */
  render() {
    const { data, isMasked } = this.state;
    const { quitFullScreen, displayFullScreen, couldEditWidget, isPreview } = this.props;
    const dicoData = data.content ? data.content.returnedData : [];
    const properties = data.content && data.content.dataSourceProperty.displayProperty;
    return (
      <Fragment>
        {isPreview && <TinyWidget bodyWidget={this.getBody(isMasked)} />}
        {!isUndefined(quitFullScreen) && isUndefined(displayFullScreen) && isUndefined(couldEditWidget) && (
          <FullScreenWidget
            headerWidget={this.getHeader()}
            bodyWidget={this.getBody(isMasked)}
            isConfigured={data.configured}
            dicoData={dicoData}
            properties={properties}
          />
        )}{' '}
        {isUndefined(quitFullScreen) && !isUndefined(displayFullScreen) && !isUndefined(couldEditWidget) && (
          <SimpleWidget
            headerWidget={this.getHeader()}
            bodyWidget={this.getBody(isMasked)}
            isConfigured={data.configured}
            displayFullScreen={displayFullScreen}
            couldEditWidget={couldEditWidget}
            openWidgetEditor={this.openWidgetEditor}
            remove={this.removeWidget}
            widgetId={data.id}
          />
        )}{' '}
      </Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { widgets, locales, colors } = state;

  return {
    widgets,
    locales,
    colors,
  };
}

export default withTranslation()(connect(mapStateToProps)(BaseWidget));
