const sensorConstants = {
  GETALL_REQUEST: 'SENSOR_GETALL_REQUEST',
  GETALL_SUCCESS: 'SENSOR_GETALL_SUCCESS',
  GETALL_FAILURE: 'SENSOR_GETALL_FAILURE',

  GET_BY_ID_REQUEST: 'SENSOR_GET_BY_ID_REQUEST',
  GET_BY_ID_SUCCESS: 'SENSOR_GET_BY_ID_SUCCESS',
  GET_BY_ID_FAILURE: 'SENSOR_GET_BY_ID_FAILURE',

  PUT_STREGA_OPEN_REQUEST: 'PUT_STREGA_OPEN_REQUEST',
  PUT_STREGA_OPEN_SUCCESS: 'PUT_STREGA_OPEN_SUCCESS',
  PUT_STREGA_OPEN_FAILURE: 'PUT_STREGA_OPEN_FAILURE',

  PUT_STREGA_CLOSE_REQUEST: 'PUT_STREGA_CLOSE_REQUEST',
  PUT_STREGA_CLOSE_SUCCESS: 'PUT_STREGA_CLOSE_SUCCESS',
  PUT_STREGA_CLOSE_FAILURE: 'PUT_STREGA_CLOSE_FAILURE',

  PUT_STREGA_UPLINK_FREQUENCY_REQUEST: 'PUT_STREGA_UPLINK_FREQUENCY_REQUEST',
  PUT_STREGA_UPLINK_FREQUENCY_SUCCESS: 'PUT_STREGA_UPLINK_FREQUENCY_SUCCESS',
  PUT_STREGA_UPLINK_FREQUENCY_FAILURE: 'PUT_STREGA_UPLINK_FREQUENCY_FAILURE',

  PUT_STREGA_UPLINK_SCHEDULE_TIME_REQUEST: 'PUT_STREGA_UPLINK_SCHEDULE_TIME_REQUEST',
  PUT_STREGA_UPLINK_SCHEDULE_TIME_SUCCESS: 'PUT_STREGA_UPLINK_SCHEDULE_TIME_SUCCESS',
  PUT_STREGA_UPLINK_SCHEDULE_TIME_FAILURE: 'PUT_STREGA_UPLINK_SCHEDULE_TIME_FAILURE',

  PUT_STREGA_SYNCTIME_REQUEST: 'PUT_STREGA_SYNCTIME_REQUEST',
  PUT_STREGA_SYNCTIME_SUCCESS: 'PUT_STREGA_SYNCTIME_SUCCESS',
  PUT_STREGA_SYNCTIME_FAILURE: 'PUT_STREGA_SYNCTIME_FAILURE',

  ADD_ALERT_REQUEST: 'ADD_ALERT_REQUEST',
  ADD_ALERT_SUCCESS: 'ADD_ALERT_SUCCESS',
  ADD_ALERT_FAILURE: 'ADD_ALERT_FAILURE',

  CLEAR: 'SENSOR_CLEAR',
};

export default sensorConstants;
