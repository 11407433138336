import React, { Component, Fragment } from 'react';
import './style.scss';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import _, { keys as _keys } from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, {
  Comparator,
  customFilter,
  FILTER_TYPES,
  selectFilter,
  textFilter,
} from 'react-bootstrap-table2-filter';
import { ColumnData } from '../_interfaces/column';
import ListTools from './ListTools';
import history from '../_helpers/history';
import confirm from '../_components';
import ColumnSelector from '../ColumnSelector/ColumnSelector';
import { QueryBuilder } from '../QueryBuilder';
import listOperator from '../_shared/OperatorList';
import { Rule } from '../_interfaces/filter';
import { downloadCSV } from '../FileDownload';
import { translateBasicAlarm } from '../_helpers/locale-helpers';
import listColNumber from '../_helpers/filterNumber-helpers';

import CompteurVert from '../SvgComponents/CompteurVert';
import RadioVert from '../SvgComponents/RadioVert';
import PdiVert from '../SvgComponents/PdiVert';
import Ajout from '../SvgComponents/AjoutBleu';
import SvgAjoutBleu from '../SvgComponents/AjoutBleu';
import PoubelleBleue from '../SvgComponents/PoubelleBleu';
import EditionBleue from '../SvgComponents/EditionBleu';
import ErrorBand from '../Bands/Error';
import SvgEye from '../SvgComponents/Eye';
import BurgerHeader from '../SvgComponents/BurgerHeader';
import { connect } from 'react-redux';
import { translate } from '../_helpers';
import CustomInput from './CustomInput';
import Loading from '../_animations/Loading';
import { pdiActions } from '../_actions';
import { getFiltersRequest } from '../QueryBuilder/FilterLSManager';
import RemoteTable from '../RemoteTable/RemoteTable';
import StaticList from '../TourneeFiches/StaticList';
import OptionsFilterList from '../_shared/OptionsFilterList';
import { withTranslation } from 'react-i18next';
import { getLocalStorage } from '../_helpers/localStorage-helper';

interface Props extends React.Props<any> {
  reduxData: { items: Array<any>; loading: boolean; error: string };
  title: string;
  displayingColumns: any;
  sourceColumns: Array<ColumnData>;
  dataFiltered: Array<any>;
  rowEvents: { onClick: Function };
  type: string;
  changeDisplayingColumns: Function;
  sendData: Function;
  defaultFilterURL: Array<Rule>;
  locationId: number;
  removeItem: Function;
  localColumns: any;
  filterSelected?: any | null;
  locale?: any;
  virtualMeterCheck: Function;
  isTournee: boolean;
  resetDefaultColumns: Function;
  fastFilter: Function;
  filter: any;
  t: Function;
}

class ListRender extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      init: false,
      tableOptions: { filters: {}, page: 1, sizePerPage: 10, sortField: null, sortOrder: null },
      filter: {},
      isOpen: null,
    };
    this.exportCsv = this.exportCsv.bind(this);
    this.exportDat = this.exportDat.bind(this);
  }
  /**
   * Construit le header de la liste (QueryBuilder et/ou ColumnSelector)
   *
   * @method getHeaderOfList
   * @memberof List
   * @param {Array} reduxData Liste de meters ou radio ou pdis récupéré de la base en fonction du type de liste
   * @returns Représentation du header
   */
  getHeaderOfList = () => {
    const {
      type,
      reduxData,
      sourceColumns,
      sendData,
      defaultFilterURL,
      changeDisplayingColumns,
      locationId,
      localColumns,
      filterSelected,
      locale,
      isTournee,
      resetDefaultColumns,
      locations,
      t,
    } = this.props;

    const { isOpen } = this.state;

    // Formattage des colonnes pour correspondre à la forme voulue et permettre de récuperer le type du champ
    const listFields = sourceColumns
      .filter((col: any) => 'classes' in col && col.dataField !== 'locationId')
      .map(col => {
        let typeData;
        let options: any[] = [];
        // S'il s'agit d'un type qui ne peut pas être determiné en regardant le type de la valeur, renseigné dans la liste specificFieldType
        const specificFieldType = ListTools.findSpecificType(col.dataField);
        if (specificFieldType !== undefined) {
          typeData = specificFieldType;
          if (typeData == ListTools.specificType.selectAlarmTypeBasic) {
            // options = [{ label: 'pastLeak', value: 'pastLeak' }, { label: "n'quoi", value: "n'quoi" }];
            options = translateBasicAlarm('fr', locale).sort((a, b) => {
              if (a.label < b.label) return -1;
              if (a.label === b.label) return 0;
              return 1;
            });
          } else if (typeData == ListTools.specificType.selectContValue) {
            const splitField = col.dataField.split('.');
            options = _.get(StaticList, splitField[splitField.length - 1]);
          }
        } else {
          typeData = listColNumber.includes(col.dataField) // Laisser avec === null sinon il considère "" comme null aussi
            ? 'number'
            : 'text';
        }
        return {
          label: t('columns.' + col.dataField),
          value: col.dataField,
          type: typeData,
          opts: options.length > 0 ? options : undefined,
          filter: textFilter({ placeholder: col.text }),
        };
      })
      .sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label === b.label) return 0;
        return 1;
      });

    if (_.get(locations, 'content.nbMeters') === 0) return;

    const handleOpen = (value, state) => {
      if (state) {
        this.setState({
          isOpen: value,
        });
      } else {
        this.setState({
          isOpen: null,
        });
      }
    };

    const defaultConfig =
      defaultFilterURL.length > 0
        ? QueryBuilder.WrappedComponent.regenerateOperations(defaultFilterURL)
        : defaultFilterURL;
    if (locations.fetchedLocation) {
      switch (type) {
        case ListTools.typeOfList.Pdi:
          return (
            <div style={{ display: 'flex', flex: 1 }} className={'list-render-header'} id={'query-filter'}>
              <div style={{ flex: isOpen === 'filter' ? '10 0 auto' : '1 0 auto', marginRight: '20px' }}>
                <div className="filter-container">
                  <QueryBuilder
                    sendListFiltered={sendData}
                    listData={reduxData.itemsRangeDate || reduxData.items}
                    backData={reduxData.itemsRangeDate !== null}
                    listOperator={listOperator}
                    listFilters={listFields}
                    defaultConfig={defaultConfig}
                    idContext={type}
                    idSite={locationId}
                    filterSelected={filterSelected}
                    isOpen={isOpen === 'filter'}
                    handleOpen={s => handleOpen('filter', s)}
                  />
                </div>
              </div>
              <div style={{ flex: isOpen === 'column' ? '10 0 auto' : '1 0 auto' }}>
                <div className="filter-container">
                  {sourceColumns && (
                    <ColumnSelector
                      listColumns={sourceColumns}
                      changeDisplay={changeDisplayingColumns}
                      defaultConfig
                      saveOption
                      localColumns={localColumns}
                      idSite={locationId}
                      resetDefaultColumns={resetDefaultColumns}
                      type={type}
                      isOpen={isOpen === 'column'}
                      handleOpen={s => handleOpen('column', s)}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        case ListTools.typeOfList.VMeters:
          break;
        default:
          return (
            <Fragment>
              {/*<div className="filter-container">
                <QueryBuilder
                  sendListFiltered={sendData}
                  listData={reduxData.items}
                  listOperator={listOperator}
                  listFilters={listFields}
                  defaultConfig={defaultConfig}
                  idContext={type}
                  idSite={locationId}
                  filterSelected={filterSelected}
                />
              </div>
              <div style={{ display: 'none' }}>
                <div className="filter-container">
                  <ColumnSelector
                    listColumns={sourceColumns}
                    changeDisplay={changeDisplayingColumns}
                    defaultConfig
                    saveOption
                    localColumns={localColumns}
                    idSite={locationId}
                    resetDefaultColumns={resetDefaultColumns}
                  />
                </div>
              </div>*/}
            </Fragment>
          );
      }
    }
  };

  getTitleIcon = () => {
    const { type } = this.props;
    switch (type) {
      case 'MTR_SEARCH_QUERY':
        return <CompteurVert height="1em" width="1em" fill="#31c6b3" strokeWidth="1" />;
      case 'VMTR_SEARCH_QUERY':
        return <CompteurVert height="1em" width="1em" fill="white" stroke="#31c6b3" strokeWidth="1" />;
      case 'RDO_SEARCH_QUERY':
        return <RadioVert height="1em" width="1em" stroke="#31c6b3" fill="white" strokeWidth="1" />;
      case 'PDI_SEARCH_QUERY':
        return <PdiVert height="1em" width="1em" fill="#31c6b3" />;
      default:
        return '';
    }
  };

  async deleteVirtualMeter(e: any, row: any) {
    const { Fragment } = React;
    const { t } = this.props;

    const result: any = await confirm({
      title: (
        <Fragment>
          <strong>{t('list_render.delete_modal_title.delete_x_virtual_meter', { rowName: row.name })}</strong>
        </Fragment>
      ),
      message: '',
      confirmText: t('all.button.delete'),
      confirmColor: 'danger',
      cancelColor: 'primary',
      cancelText: t('all.button.cancel'),
    });
    if (result) {
      const { removeItem } = this.props;
      removeItem(row, ListTools.typeOfList.VMeters);
    }
  }

  getVirtualMeterActions = () => {
    const { locationId, virtualMeterCheck, t } = this.props;
    return {
      dataField: 'action',
      isDummyField: true,
      align: 'center',
      headerStyle: () => ({ width: '150px' }),
      text: '',
      formatter: (cellContent: any, row: any) => (
        <Row>
          <Col md="4">
            <div
              id={`vmeter${row.id}-sheet`}
              className="clickable round"
              role="presentation"
              onClick={(e: any) => {
                history.push(`/locations/${locationId}/virtuals/info?id=${row.id}`);
              }}
            >
              <SvgEye height="1.3em" width="1.3em" />
              <UncontrolledTooltip placement="bottom" target={`vmeter${row.id}-sheet`}>
                {' '}
                {t('all.round.go_to_record')}
              </UncontrolledTooltip>
            </div>
          </Col>
          <Col md="4">
            {virtualMeterCheck() ? (
              <div
                id={`vmeter${row.id}-edit`}
                className="clickable round"
                role="presentation"
                onClick={(e: any) => {
                  history.push(`/locations/${locationId}/virtuals/edit?id=${row.id}`);
                }}
              >
                <EditionBleue height="1em" width="1em" />
                <UncontrolledTooltip placement="bottom" target={`vmeter${row.id}-edit`}>
                  {' '}
                  {t('all.meter.edit_virtual_meter')}
                </UncontrolledTooltip>
              </div>
            ) : (
              <div id={`vmeter${row.id}-edit`} className="round-disabled delete-alert" role="presentation">
                <EditionBleue height="1em" width="1em" fill="white" />
              </div>
            )}
          </Col>
          <Col md="4">
            {virtualMeterCheck() ? (
              <div
                id={`vmeter${row.id}-delete`}
                className="clickable round"
                role="presentation"
                onClick={(e: any) => this.deleteVirtualMeter(e, row)}
              >
                <PoubelleBleue height="1em" width="1em" />
                <UncontrolledTooltip placement="bottom" target={`vmeter${row.id}-delete`}>
                  {' '}
                  {t('all.meter.delete_virtual_meter')}
                </UncontrolledTooltip>
              </div>
            ) : (
              <div id={`vmeter${row.id}-delete`} className="round-disabled delete-alert" role="presentation">
                <PoubelleBleue height="1em" width="1em" fill="white" />
              </div>
            )}
          </Col>
        </Row>
      ),
    };
  };

  getTypeString = (type: string) => {
    const { t } = this.props;
    switch (type) {
      case ListTools.typeOfList.Pdi:
        return t('list_render.list_type.pdi');
      case ListTools.typeOfList.Radios:
        return t('list_render.list_type.radio_plural');
      case ListTools.typeOfList.Meters:
        return t('list_render.list_type.meter_plural');
      case ListTools.typeOfList.VMeters:
        return t('list_render.list_type.virtual_meter_plural');
      default:
        return '';
    }
  };

  private static getDataKeys(elem: any): ArrayLike<string> {
    let baseKeys = _keys(elem);
    let returnedKeys: string[] = [];
    let addKeys = function(el: any, keys: string[], key: string) {
      for (let i in keys) {
        let newKey = key.length > 0 ? `${key}.${keys[i]}` : keys[i];
        if (_.isPlainObject(el[keys[i]])) {
          let newKeys = _keys(el[keys[i]]);
          addKeys(el[keys[i]], newKeys, newKey);
        } else {
          returnedKeys.push(newKey);
        }
      }
    };

    addKeys(elem, baseKeys, '');
    return returnedKeys;
  }

  exportCsv(fastFiltersRemote, sortOptions) {
    const { locales, dispatch, tourneeCode, locationId, type, displayingColumns } = this.props;
    const fastFilters = {};
    _.entries().map(it => (fastFilters[it[0]] = it[1].filterVal));
    let trads = {
      alarms: _.get(locales, 'locale.fr.alarmType'),
    };
    for (let optionsFilterListKey in OptionsFilterList) {
      trads[optionsFilterListKey.replace('.', '')] = OptionsFilterList[optionsFilterListKey];
    }

    dispatch(
      pdiActions.exportPdiCsv(
        locationId,
        getFiltersRequest(`filter-${type}-${tourneeCode || locationId}`),
        { field: sortOptions.sortField, way: sortOptions.sortOrder },
        fastFilters,
        displayingColumns.map(el => {
          return { fieldName: el.dataField, label: el.text };
        }),
        trads,
        localStorage.getItem(`filter-${type}-${tourneeCode || locationId}_optionor`) === 'true'
      )
    );
  }

  exportDat(fastFiltersRemote, sortOptions) {
    const { dispatch, tourneeCode, locationId, type } = this.props;
    const fastFilters = {};
    _.entries(fastFiltersRemote).map(it => (fastFilters[it[0]] = it[1].filterVal));
    dispatch(
      pdiActions.exportPdiDat(
        locationId,
        getFiltersRequest(`filter-${type}-${tourneeCode || locationId}`),
        { field: sortOptions.sortField, way: sortOptions.sortOrder },
        fastFilters,
        localStorage.getItem(`filter-${type}-${tourneeCode || locationId}_optionor`) === 'true'
      )
    );
  }

  componentDidMount() {
    const { type, tournee, locationId } = this.props;
    const mountFastFilters = localStorage.getItem('virtualMeterFilter-fast');
    if (mountFastFilters) {
      this.setState({ filter: JSON.parse(mountFastFilters) });
    }
    let keyStorage = `filter-${type}-`;
    if (type === ListTools.typeOfList.Fiche && this.props.location.pathname.includes('/gestionnaires')) {
      keyStorage = keyStorage + tournee.info.code;
    } else {
      keyStorage = keyStorage + locationId;
    }
    const filterData = getLocalStorage(keyStorage);
    const columnData = getLocalStorage(`columns-${locationId}-${type}`);

    const isOpen = filterData ? 'filter' : columnData ? 'column' : null;

    this.setState({
      isOpen: isOpen,
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: any) {
    const { init, height, filter } = this.state;

    // Filtres rapides -> Conversion value
    const docs: any = document.getElementsByClassName('unsortable-input');
    if (docs.length > 0) {
      for (let i = 0; i < docs.length; i++) {
        const doc = docs[i];
        doc.addEventListener('click', (e: any) => {
          e.stopPropagation();
        });
      }
    }

    if (
      filter.current !== prevState.filter.current ||
      filter[filter.current] !== prevState.filter[prevState.filter.current]
    ) {
      localStorage.setItem('virtualMeterFilter-fast', JSON.stringify(filter));
    }
  }

  dataToCSV = (columns: any, data: any) => {
    const { locale, locations } = this.props;
    let copyData = _.cloneDeep(data);
    const booleanFields = ['state', 'withAlarm', 'withAlarmMasked'];
    const translateFields = ['alarms', 'alarmsMasked'];
    columns.forEach((column: any) => {
      if (booleanFields.includes(column.dataField) || column.dataField.includes('splitAlarms')) {
        if (column.dataField === 'state') {
          copyData.forEach((el: any) => {
            el.state = el.state ? 'Actif' : 'Inactif';
          });
        } else {
          copyData.forEach((el: any) => {
            _.set(el, column.dataField, _.get(el, column.dataField) ? 'Oui' : 'Non');
          });
        }
      }
      if (translateFields.includes(column.dataField)) {
        copyData.forEach((el: any) => {
          const trad = _.get(el, column.dataField)
            .map((traduction: string) => traduction.replace(/ /g, ''))
            .map((traduction: string) => translate('fr', 'alarmType', traduction, locale))
            .join();
          el[column.dataField] = trad;
        });
      }
    });
    downloadCSV(columns, copyData, `export-${locations.fetchedLocation.name}`);
  };
  render() {
    const {
      reduxData,
      dataFiltered,
      title,
      rowEvents,
      type,
      locationId,
      tourneeCode,
      virtualMeterCheck,
      isTournee,
      pdis,
      locale,
      ruleList,
      t,
      customexport,
      vmeters,
    } = this.props;
    const { filter } = this.state;
    let { displayingColumns } = this.props;
    const linkAddVMeter = `/locations/${locationId}/virtuals/add`;
    const linkToLinkPdi = `/locations/${locationId}/pdi/link`;
    const unlinkToLinkPdi = `/locations/${locationId}/pdi/unlink`;
    let copyData = reduxData.items;
    if (displayingColumns.length === 0) {
      displayingColumns = ListTools.getEmptyColumns(type);
      displayingColumns.forEach(el => (el.filter = ListTools.withFilter(type, el.text)));
    }
    const listTotal = copyData && copyData.length;
    displayingColumns.forEach((c: any) => {
      if (c.dataField !== 'action') {
        c.formatter = ListTools.findFormatter(c.dataField);
        c.text = t(`columns.${c.dataField}`);
        c.filter = textFilter({
          placeholder: c.text,
          defaultValue: _.get(this.state, `tableOptions.filters['${c.dataField}'].filterVal`),
        });
        if (c.dataField === 'state' || c.dataField === 'withAlarm' || c.dataField === 'withAlarmMasked') {
          const selectOptions =
            c.dataField !== 'state'
              ? {
                  0: t('all.text.no'),
                  1: t('all.text.yes'),
                }
              : {
                  0: t('all.text.inactive'),
                  1: t('all.text.active'),
                };
          c.filter = selectFilter({ options: selectOptions, placeholder: t('all.text.all_plural').toUpperCase() });
        }

        if (c.dataField.includes('date')) {
          c.filter = customFilter({
            type: FILTER_TYPES.DATE,
            onFilter: value => {
              const cloneFilter = _.cloneDeep(filter);
              cloneFilter[c.dataField] = value;
              cloneFilter.current = c.dataField;
              if (cloneFilter[c.dataField] !== filter[c.dataField]) {
                this.setState({ filter: cloneFilter });
              }
            },
            defaultValue: filter[c.dataField],
          });
          c.filterRenderer = (onFilter: any, column: any) => <CustomInput onFilter={onFilter} column={column} />;
        }

        if (c.dataField === 'alarms' || c.dataField === 'alarmsMasked') {
          c.filter = textFilter({
            placeholder: c.text,
          });
          c.filterValue = (cell: any, row: any) => {
            return t('alarm.type.' + cell.toLowerCase());
          };
        }
      }
    });

    if (type === ListTools.typeOfList.VMeters) {
      displayingColumns[0].filter = textFilter({
        placeholder: t('all.filter.filter_by_name'), // custom the input placeholder
        className: 'mask-custom-text-filter', // custom classname on input
        onFilter: value => {
          const cloneFilter = _.cloneDeep(filter);
          cloneFilter.name = value;
          cloneFilter.current = 'name';
          if (cloneFilter.name !== filter.name) {
            this.setState({ filter: cloneFilter });
          }
        },
        defaultValue: filter.name,
        comparator: Comparator.LIKE, // default is Comparator.LIKE
      });
      displayingColumns.push(this.getVirtualMeterActions());
    }

    const rowStyle = (row, rowIndex) => {
      return { overflowWrap: 'break-word' };
    };
    const exportProcessing =
      customexport.export_processing ||
      pdis.loadingFilters ||
      _.get(pdis, 'processing') ||
      _.get(pdis, 'processingDat');
    const count = vmeters && vmeters.items ? vmeters.items.length : pdis ? pdis.count : 0;
    return (
      <>
        {reduxData && !reduxData.items && (
          <Loading message={t('all.text.type_x_list_loading', { type: t(this.getTypeString(type)) })} />
        )}
        <div
          className="col-md-12 "
          style={{ overflow: 'hidden', maxHeight: 'calc(100vh - 105px)', height: '100%', overflowY: 'auto' }}
        >
          {reduxData && reduxData.error && <ErrorBand message={reduxData.error} />}
          {dataFiltered && (
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              {reduxData && reduxData.items && locationId && (
                <Fragment>
                  {this.getHeaderOfList()}
                  <div
                    className={displayingColumns && displayingColumns.length > 6 ? 'crystalList-container' : ''}
                    style={{ height: `100%`, minHeight: count >= 10 ? '540px' : 'inherit', flex: 10 }}
                    id={'table-wrapper-h'}
                  >
                    {displayingColumns && displayingColumns.length > 0 && (
                      <div
                        className="table-info-container"
                        style={{
                          paddingBottom: count >= 10 ? '50px' : 'none',
                          position: 'relative',
                          height: 'inherit',
                        }}
                      >
                        <h2 style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ marginRight: '10px' }}>{this.getTitleIcon()}</span>
                          {pdis && !pdis.loadingRangeDate ? count : '...'} {title}
                          {type === ListTools.typeOfList.VMeters && virtualMeterCheck() && (
                            <div style={{ position: 'absolute', right: '40px' }}>
                              <a className="float-right" href={linkAddVMeter}>
                                <div id="addWidget" className="round" role="presentation">
                                  <Ajout className="add" fill="curentcolor" />
                                  <UncontrolledTooltip placement="bottom" target="addWidget">
                                    {' '}
                                    {t('all.meter.add_virtual_meter')}
                                  </UncontrolledTooltip>
                                </div>
                              </a>
                            </div>
                          )}
                          {(type === ListTools.typeOfList.Pdi || type === ListTools.typeOfList.Meters) &&
                          virtualMeterCheck() &&
                          pdis &&
                          pdis.items &&
                          !isTournee &&
                          pdis.items.length > 0 ? (
                            <UncontrolledDropdown
                              className="float-right"
                              style={{ position: 'absolute', right: '40px' }}
                            >
                              <DropdownToggle nav>
                                <BurgerHeader fill="#808080" height="1em" width="1em" />
                              </DropdownToggle>
                              <DropdownMenu right>
                                <DropdownItem disabled={exportProcessing} href={linkToLinkPdi}>
                                  {t('all.text.link')}
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem disabled={exportProcessing} href={unlinkToLinkPdi}>
                                  {t('all.text.unlink')}
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          ) : (
                            <div style={{ position: 'absolute', right: '40px' }}>
                              {(type === ListTools.typeOfList.Pdi || type === ListTools.typeOfList.Meters) && (
                                <a href={linkToLinkPdi}>
                                  <div style={{ display: 'flex' }} id={'associate-alone'}>
                                    <SvgAjoutBleu height={'0.80em'} fill={'#000'} />
                                  </div>
                                  <UncontrolledTooltip placement="bottom" target={`associate-alone`}>
                                    {' '}
                                    {t('all.text.link')}
                                  </UncontrolledTooltip>
                                </a>
                              )}
                            </div>
                          )}
                        </h2>
                        {type !== ListTools.typeOfList.VMeters && pdis && !pdis.loadingRangeDate && (
                          <RemoteTable
                            keyStorage={`filter-${type}-${tourneeCode || locationId}`}
                            clickOnRow={rowEvents.onClick}
                            displayingColumns={displayingColumns}
                            ruleList={ruleList}
                            optionOr={this.props.optionOr}
                            exportCsvCallback={this.exportCsv}
                            exportDatCallback={this.exportDat}
                          />
                        )}
                        {type === ListTools.typeOfList.VMeters && listTotal > 0 && (
                          <BootstrapTable
                            keyField="id"
                            key={Math.floor(Math.random() * Math.floor(1024))}
                            data={copyData || []}
                            columns={displayingColumns}
                            bootstrap4
                            bordered={false}
                            hover
                            filter={filterFactory()}
                            rowStyle={rowStyle}
                            headerClasses="crystalList-column vmeter-tab"
                            pagination={copyData.length > 10 ? paginationFactory() : null}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { pdis, locations, locales, customexport, tournee } = state;
  return {
    pdis,
    locations,
    locales,
    customexport,
    tournee,
  };
}

export default withTranslation()(connect(mapStateToProps)(ListRender));
