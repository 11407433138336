import JwtDecode from 'jwt-decode';
import { userConstants } from '../_constants';
import { action } from '../_interfaces/action';

let token: any;

const getToken = localStorage.getItem('token');
if (getToken) {
  token = JwtDecode(getToken);
}
const initialState =
  token && token.user
    ? { loggingIn: false, loggedIn: true, user: token.user }
    : { loggingIn: false, loggedIn: false, user: undefined };

export default function authentication(state = initialState, action: action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        loggedIn: false,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        loggingIn: false,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return initialState;
    case userConstants.LOGOUT:
      return initialState;
    default:
      return state;
  }
}
