import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { withTranslation } from 'react-i18next';

type State = {
  name: string;
  saved: boolean;
  device: any;
};
/**
 * @class EditdeviceComponent
 * @extends {React.Component<Props, State>}
 */
class EditDevice extends React.Component<any, State> {
  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof EditdeviceComponent
   */
  constructor(props: any) {
    super(props);
    this.state = {
      name: '',
      saved: false,
      device: {},
    };
  }

  /**
   * Met à jour le state en fonction de l'état d'edition
   * du device
   *
   * @static
   * @param {Props} props
   * @param {State} state
   * @returns {State} Le nouveau state
   * @method getDerivedStateFromProps
   * @memberof EditdeviceComponent
   */
  static getDerivedStateFromProps(props: any, state: State) {
    console.log(props, state);
    if (props.device.id) {
      return {
        device: props.device,
        name: props.device.content,
        facturationCode: props.device.factuCode,
        saved: false,
      };
    }
    if (state.device && props.device && state.device.id === props.device.id && props.alert.type === 'alert-success') {
      return { device: {}, name: '', facturationCode: '', saved: true };
    }
    if (state.device.id !== props.device.id) {
      return { device: props.device, name: props.device.name, facturationCode: props.device.factuCode, saved: false };
    }
    return null;
  }

  /**
   * Ferme la modal d'édition dans le cas où le
   * device a été sauvegardé
   *
   * @param {Props} prevProps
   * @param {State} prevState
   * @method componentDidUpdate
   * @memberof EditdeviceComponent
   */
  componentDidUpdate(prevProps: any, prevState: State) {
    const { name } = this.state;
    const { isEdited } = this.props;
    if (name.length > 0 && !prevState.saved) {
      isEdited();
    }
  }

  /**
   * Gère les droits d'édition du device
   *
   * @returns {boolean} Le contrôle
   * @method couldEditdevice
   * @memberof EditdeviceComponent
   */
  couldEditdevice = () => {
    const { user } = this.props;
    const { device } = this.state;

    if (['DIOPTASE', 'SUPERADMIN', 'ADMIN', 'USER'].includes(user.role.name)) {
      return true;
    }

    return device && device.owner && user.id === device.owner.id;
  };

  /**
   * Met à jour le device à la soumission valide
   * du formulaire
   *
   * @param {Object} event Evènement
   * @param {Object} values Valeurs du formulaire
   * @method handleValidSubmit
   * @memberof EditdeviceComponent
   */
  handleValidSubmit = (event: Object, values: any) => {
    const { dispatch, edit } = this.props;
    const { device } = this.state;
    this.setState({
      name: values.name,
    });
    edit(event, values);
  };

  /**
   * Gère la soumission invalide du formulaire
   *
   * @param {any} values Valeurs du formulaire
   * @method handleInvalidSubmit
   * @memberof EditdeviceComponent
   */
  handleInvalidSubmit = (values: any) => {
    this.setState({
      name: values.name,
    });
  };

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof EditdeviceComponent
   */
  render() {
    const { className, alert, edit, isOpen, t } = this.props;
    const { name, saved, facturationCode } = this.state;
    console.log('render::render::132', facturationCode, name);
    return (
      <div>
        {this.couldEditdevice() && (
          <div>
            <Modal isOpen={isOpen} toggle={edit} className={className}>
              <ModalHeader toggle={edit}>{t('edit_device.main_title.edit_phone')}</ModalHeader>
              <AvForm onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
                <ModalBody>
                  <div>{alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}</div>
                  {!saved && (
                    <div>
                      <AvField
                        name="name"
                        value={name}
                        label={t('edit_device.field_label.phone_name')}
                        required
                        errorMessage={t('edit_device.field_error_msg.name_required_min_2_char')}
                      />
                      <AvField
                        name="facturationCode"
                        value={facturationCode}
                        label={t('columns.facturationCode')}
                        validate={{
                          maxLength: { value: 2, errorMessage: t('edit_device.text.code_max_2_char') },
                        }}
                      />
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  {!saved && (
                    <Button color="danger" onClick={edit}>
                      {t('all.button.cancel')}
                    </Button>
                  )}
                  {!saved && <Button color="secondary">{t('all.button.register')}</Button>}
                </ModalFooter>
              </AvForm>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, locations, devices, alert } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    locations,
    devices,
  };
}

const connectedEditDevice = connect(mapStateToProps)(EditDevice);
const tr = withTranslation()(connectedEditDevice);
export default tr;
