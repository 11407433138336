const stub = [
  {
    id: 0,
    name: 'cluster 1',
    sensors: [
      {
        info: {
          roundCode: 'SITE06',
          roundName: 'SENORIALES 80',
          type: 'ADE-COM',
          sensorId: 1,
          sensorDevEUI: '0018B210000078B6',
          sensorStreamId: null,
          sensorDownlinkUrl: null,
          sensorSerial: '0018B210000078B6',
          sensorConfig: {
            lastLaunch: 1645695733202,
            fields: {
              temperature: {
                min: 20,
              },
            },
            usersMail: [],
            retention: null,
          },
          sensorAlert: {
            lastLaunch: null,
            fields: {
              temperature: {
                min: 20,
                max: 30,
              },
              humidity: {
                min: 39,
                max: 43,
              },
            },
            usersMail: [],
            retention: null,
          },
        },
        lastData: null,
        data: {
          temperature: {
            '2022-02-25T11:00:00.000+0000': 21.4,
            '2022-02-25T12:00:00.000+0000': 21.4,
            '2022-02-25T13:00:00.000+0000': 21.3,
            '2022-02-25T21:00:00.000+0000': 12.1,
            '2022-02-26T22:00:00.000+0000': 11.9,
          },
          humidity: {
            '2022-02-25T11:00:00.000+0000': 39,
            '2022-02-25T12:00:00.000+0000': 40,
            '2022-02-25T13:00:00.000+0000': 40,
            '2022-02-25T21:00:00.000+0000': 37,
            '2022-02-26T22:00:00.000+0000': 38,
          },
        },
      },
      {
        info: {
          roundCode: 'SITE06',
          roundName: 'SENORIALES 80',
          type: 'ADE-DTP',
          sensorId: 2,
          sensorDevEUI: '0018B26000002976',
          sensorStreamId: null,
          sensorDownlinkUrl: null,
          sensorSerial: '0018B26000002976',
          sensorConfig: {},
          sensorAlert: {
            lastLaunch: null,
            fields: {
              temperature: {
                min: 20,
                max: 30,
              },
              humidity: {
                min: 32,
                max: 38,
              },
            },
            usersMail: [],
            retention: null,
          },
        },
        lastData: null,
        data: {
          temperature: {
            '2022-02-25T11:00:00.000+0000': 21.4,
            '2022-02-25T12:00:00.000+0000': 21.4,
            '2022-02-25T13:00:00.000+0000': 21.3,
            '2022-02-25T21:00:00.000+0000': 12.1,
            '2022-02-26T22:00:00.000+0000': 11.9,
          },
          humidity: {
            '2022-02-25T11:00:00.000+0000': 39,
            '2022-02-25T12:00:00.000+0000': 40,
            '2022-02-25T13:00:00.000+0000': 40,
            '2022-02-25T21:00:00.000+0000': 37,
            '2022-02-26T22:00:00.000+0000': 38,
          },
        },
      },
    ],
  },
  {
    id: 1,
    name: 'cluster 2',
    sensors: [
      {
        info: {
          roundCode: 'SITE06',
          roundName: 'SENORIALES 80',
          type: 'ADE-COM',
          sensorId: 1,
          sensorDevEUI: '0018B210000078B6',
          sensorStreamId: null,
          sensorDownlinkUrl: null,
          sensorSerial: '0018B210000078B6',
          sensorConfig: {
            lastLaunch: 1645695733202,
            fields: {
              temperature: {
                min: 20,
              },
            },
            usersMail: [],
            retention: null,
          },
          sensorAlert: {
            lastLaunch: null,
            fields: {},
            usersMail: [],
            retention: null,
          },
        },
        lastData: null,
        data: {},
      },
      {
        info: {
          roundCode: 'SITE06',
          roundName: 'SENORIALES 80',
          type: 'ADE-DTP',
          sensorId: 2,
          sensorDevEUI: '0018B26000002976',
          sensorStreamId: null,
          sensorDownlinkUrl: null,
          sensorSerial: '0018B26000002976',
          sensorConfig: {},
          sensorAlert: null,
        },
        lastData: null,
        data: {},
      },
    ],
  },
];

export default stub;
