import React from 'react';

const SvgArrowRightVert = (props: any) => (
  <svg viewBox="0 0 19.06 25.99" {...props}>
    <path
      d="M16.5,8.9L9.9,3.4c-0.2-0.2-0.5-0.2-0.7,0L2.5,8.9C2.3,9.1,2.3,9.5,2.5,9.7c0.1,0.1,0.3,0.2,0.4,0.2h1.7v5
			c0,0.3,0.2,0.6,0.6,0.6l0,0h3.3c0.3,0,0.6-0.2,0.6-0.6v-3.3h1.2v3.3c0,0.3,0.3,0.6,0.6,0.6H14c0.3,0,0.6-0.2,0.6-0.6c0,0,0,0,0,0
			v-5h1.7c0.3,0,0.6-0.3,0.5-0.6C16.7,9.2,16.6,9,16.5,8.9z M14,8.8c-0.3,0-0.6,0.3-0.6,0.6v5h-2.2V11c0-0.3-0.3-0.5-0.6-0.5H8.4
			c-0.3,0-0.6,0.2-0.6,0.6c0,0,0,0,0,0v3.3H5.7v-5c0-0.3-0.3-0.6-0.6-0.6H4.4l5.1-4.3l5.1,4.3L14,8.8z"
      fill={props.fill}
      data-name="Calque 3"
    />
  </svg>
);

export default SvgArrowRightVert;
