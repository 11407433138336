import React from 'react';
import SvgTournees from '../SvgComponents/TourneesVertes';
import SvgTelephone from '../SvgComponents/TelephoneVert';

function getC(key, props) {
  switch (key) {
    case 'phone':
      return <SvgTelephone {...props} />;
    case 'round':
      return <SvgTournees {...props} />;
    default:
      break;
  }
}

export default getC;
