import React from 'react';

const SvgChartPie = (props: any) => (
  <svg viewBox="0 0 200 200" {...props}>
    <path
      d="M109,25.8c-2.4,0-4.3,1.9-4.3,4.3v61.2c0,2.4,1.9,4.3,4.3,4.3h60.9c2.4,0,4.3-1.9,4.3-4.3
					C174.2,55.3,145,25.8,109,25.8z"
      fill={props.fill}
    />
    <path
      d="M117,164.5l-21.1-56.6V47.8c0-2.4-1.9-4.3-4.3-4.3c-35.8,0-65.8,29-65.8,65.2c0,35.9,29.7,65.5,65.8,65.5
					c7.9,0,15.6-1.4,22.9-4.2C116.7,169.2,117.9,166.7,117,164.5z M91.6,165.5c-31.5,0-57.1-25.5-57.1-56.8
					c0-29.7,23.3-54.2,52.8-56.4v56.4c0,0.5,0.1,1,0.3,1.5l19.8,53.1C102.2,164.8,97,165.5,91.6,165.5z"
      fill={props.fill}
    />
    <path
      d="M169.9,104.3H109c-1.4,0-2.8,0.7-3.6,1.9c-0.8,1.2-1,2.7-0.5,4l21.4,57.3c0.8,2.3,3.4,3.4,5.6,2.5
					c25.3-9.5,42.3-34.2,42.3-61.4C174.2,106.3,172.3,104.3,169.9,104.3z M132.9,160.2L115.3,113h50.1
					C163.8,133.4,151.3,151.6,132.9,160.2z"
      fill={props.fill}
    />
  </svg>
);

export default SvgChartPie;
