import React, { useEffect } from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import _ from 'lodash';

const StepPopover: React.FC<{
  id: string;
  target: string;
  open: boolean;
  onClose: () => void;
  confirm?: boolean;
  setConfirm?: React.Dispatch<React.SetStateAction<boolean>>;
  withTwoSteps?: boolean;
  t: any;
  permission: boolean;
  action: (e: any) => void;
  title: string;
  warningText?: any;
  warningElement?: Element;
  confirmText: string;
  confirmElement?: Element;
  confirmButtonText?: string;
}> = ({
  id,
  target,
  open,
  onClose,
  confirm,
  setConfirm,
  withTwoSteps,
  t,
  permission,
  action,
  title,
  warningText,
  warningElement,
  confirmText,
  confirmButtonText,
  confirmElement,
}) => {
  useEffect(() => {
    window.addEventListener('click', (e: any) => {
      if (e.target && e.target.id !== id) {
        onClose();
        setConfirm && setConfirm(false);
      }
    });
  }, []);

  return (
    <UncontrolledPopover id={id} target={target} trigger="legacy" placement="auto" isOpen={open}>
      <div style={{ pointerEvents: 'none' }}>
        {permission && (
          <div
            style={{
              width: '100%',
            }}
          >
            <PopoverHeader style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
              <p
                style={{
                  fill: 'red',
                  margin: '0 10px',
                  fontSize: '20px',
                }}
              >
                &#x26A0;
              </p>
              <p style={{ margin: 0 }}>{title}</p>
            </PopoverHeader>
            <PopoverBody>
              {confirm && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        width: 9,
                        height: 9,
                        borderRadius: 100,
                        border: '1px solid red',
                        background: 'red',
                        margin: 10,
                      }}
                    />
                    <div
                      style={{
                        width: 9,
                        height: 9,
                        borderRadius: 100,
                        border: '1px solid red',
                        margin: 10,
                        background: 'red',
                      }}
                    />
                  </div>
                  {warningText && warningText}
                  {warningElement && warningElement}
                  <div
                    style={{
                      margin: '10px 0',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      pointerEvents: 'auto',
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-dark"
                      onClick={e => {
                        e.stopPropagation();
                        onClose();
                        setConfirm && setConfirm(false);
                      }}
                    >
                      {t('all.button.cancel')}
                    </button>
                    <button type="button" className="btn btn-danger" style={{ margin: '0 10px' }} onClick={action}>
                      {confirmButtonText || t('all.button.delete')}
                    </button>
                  </div>
                </>
              )}
              {!confirm && (
                <>
                  {withTwoSteps && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          width: 9,
                          height: 9,
                          borderRadius: 100,
                          border: '1px solid red',
                          background: 'red',
                          margin: 10,
                        }}
                      />
                      <div style={{ width: 9, height: 9, borderRadius: 100, border: '1px solid red', margin: 10 }} />
                    </div>
                  )}
                  <p style={{ margin: '10px 0', textAlign: 'center' }}>{confirmText}</p>
                  {confirmElement && confirmElement}
                  <div
                    style={{
                      margin: '10px 0',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      pointerEvents: 'auto',
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-dark"
                      onClick={e => {
                        e.stopPropagation();
                        onClose();
                      }}
                    >
                      {t('all.button.cancel')}
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{ margin: '0 10px' }}
                      onClick={e => {
                        e.stopPropagation();
                        if (withTwoSteps) {
                          setConfirm && setConfirm(true);
                        } else {
                          action(e);
                        }
                      }}
                    >
                      {withTwoSteps ? t('all.button.next') : confirmButtonText || t('all.button.delete')}
                    </button>
                  </div>
                </>
              )}
            </PopoverBody>
          </div>
        )}
      </div>
    </UncontrolledPopover>
  );
};

export default StepPopover;
