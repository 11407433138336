import React from 'react';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import SvgTemperature from '../SvgComponents/Temperature';

const Temperature = (props: any) => {
  const { sensor, t } = props;
  const sData = {
    labels: Object.keys(_.get(sensor, 'sensor.data.temperature', [])).map((el: any) =>
      moment(el).format('DD/MM/YYYY HH:mm')
    ),
    datasets: [
      {
        label: t('all.text.temperature'),
        data: Object.values(_.get(sensor, 'sensor.data.temperature', [])),
        fill: false,
        backgroundColor: '#31c6b3',
        borderColor: '#31c6b3',
      },
    ],
  };
  return (
    <div className="col-md-12 downlink-container tele-releve-container" id="tab-container" style={{ width: '100%' }}>
      <div className="tele-releve-header flex-box" style={{ marginBottom: '15px' }}>
        <SvgTemperature fill="#31c6b3" height="2em" />
        <h2 style={{ marginLeft: '20px' }}>{t('all.text.temperature')}</h2>
      </div>
      <Line
        data={sData}
        options={{
          plugins: {
            t1: false,
            datalabels: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};

function mapStateToProps(state: any) {
  const { sensor } = state;
  return {
    sensor,
  };
}

export default withTranslation()(connect(mapStateToProps)(Temperature));
