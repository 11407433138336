import React, { useEffect, useState } from 'react';
import './style.scss';
import { Line } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import InfoMap from './InfoMap';
import Rouage from './Rouage';
import sensorActions from '../_actions/sensor.actions';
import AlertBar from './AlertBar';
import ChartBarVerticalNotAvailable from '../SvgComponents/ChartBarVerticalNotAvailable';
import BestDateComponent from '../_components/BestDateComponent';

const SensorAdeComfort = ({ sensor, users, dispatch, t, ...props }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dropValue, setDropValue] = useState('minutes');
  const [isRight, setRight] = useState(false);
  const [pOptions, setOptions] = useState({
    usersMail: [],
    retention: '',
    fields: {},
    continuousAlertSending: false,
  });
  const [interval, setInterval] = useState({
    dateMin: moment().subtract(1, 'month'),
    dateMax: moment(),
    zoom: 'Day',
  });

  const handleChange = (value: any, key: any) => {
    const clone: any = _.cloneDeep(interval);
    clone[key] = key === 'zoom' ? value : moment(value);
    setInterval(clone);
  };

  useEffect(() => {
    const info = _.get(sensor, 'sensor.info.sensorAlert');
    if (info) {
      setDropValue(info.retention.unit);
      setOptions(info);
      setSelectedUsers(info.usersMail);
      setRight(!_.get(info, 'continuousAlertSending'));
    }
  }, [sensor.sensor]);

  const pressures = _.get(sensor, 'sensor.data.pressure');
  const handleValidOptions = (minMax: any) => {
    const copyOptions = _.cloneDeep(pOptions);
    _.merge(_.get(copyOptions, 'fields'), minMax);
    if (isRight) {
      _.set(copyOptions, 'retention', null);
    } else {
      _.set(copyOptions, 'retention.unit', dropValue);
    }
    _.set(copyOptions, 'usersMail', selectedUsers);
    _.set(copyOptions, 'continuousAlertSending', !isRight);
    dispatch(sensorActions.addAlert(copyOptions, _.get(sensor, 'sensor.info.sensorId')));
  };
  const pressureData = (canvas: any) => {
    const ctx = canvas.getContext('2d');
    const gradient0 = ctx.createLinearGradient(0, 375, -1, 0);
    gradient0.addColorStop(1, '#31c6b3');
    gradient0.addColorStop(0, 'transparent');
    return {
      labels: _.keys(pressures).map(d => moment.utc(d).format('DD/MM/YYYY HH:mm')),
      datasets: [
        {
          label: 'Max',
          data: _.keys(pressures).map(el => _.get(sensor, 'sensor.info.sensorAlert.fields.pressure.max')),
          fill: false,
          borderColor: '#C42021b8',
          pointRadius: 0,
        },
        {
          label: 'Min',
          data: _.keys(pressures).map(el => _.get(sensor, 'sensor.info.sensorAlert.fields.pressure.min')),
          fill: false,
          borderColor: '#1E78C2b8',
          pointRadius: 0,
        },
        {
          label: 'Pression',
          data: _.values(pressures),
          fill: false,
          backgroundColor: gradient0,
          borderColor: '#31c6b3',
        },
      ],
    };
  };

  const options = {
    plugins: {
      t1: false,
      datalabels: {
        display: false,
      },
    },
    legend: {
      display: false,
    },
    spanGaps: true,
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const alertProps = {
    options: pOptions,
    users,
    setDropValue,
    setSelectedUsers,
    setOptions,
    selectedUsers,
    dropValue,
    isRight,
    setRight,
  };

  return (
    <div className="sensor-graph-container delta">
      <div className="info-container">
        <InfoMap />
      </div>
      {false && (
        <div className="flex-box intervalle-sensor-container">
          <h2>Intervalle d'affichage</h2>
          <BestDateComponent dateMin={interval.dateMin} dateMax={interval.dateMax} handleChange={handleChange} />
        </div>
      )}
      {users.items && _.get(sensor, 'sensor.info') && (
        <AlertBar
          t={t}
          {...alertProps}
          info={_.get(sensor, 'sensor.info.sensorAlert', {}) || {}}
          setRight={setRight}
          isRight={isRight}
        />
      )}
      <div className="sensors-wrapper">
        <BestDateComponent dateMin={interval.dateMin} dateMax={interval.dateMax} handleChange={handleChange} />
        <div className="charts-container">
          <div className="chart-container" style={{ padding: 0 }}>
            <div className="flex-between">
              <h3>Pression (pa)</h3>
              {users && (
                <Rouage
                  users={users.items}
                  alert={sensor.sensor.info.sensorAlert}
                  validFunction={handleValidOptions}
                  themeKey="pressure"
                />
              )}
            </div>
            {!_.isEmpty(pressures) ? (
              <Line options={options} data={pressureData} />
            ) : (
              <ChartBarVerticalNotAvailable height="300px" fill="grey" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { sensor, users } = state;
  return {
    sensor,
    users,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(SensorAdeComfort)));
