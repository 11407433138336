/**
 * Récupère tous les marque-pages du site courant
 *
 * @method getAll
 * @param {number} locationId Id du site
 * @returns {Object} La liste des bookmarks
 */
import enqueteConstants from '../_constants/enquete.constants';
import enqueteService from '../_services/enquete.service';

function getAll() {
  function request() {
    return { type: enqueteConstants.GET_ALL_REQUEST };
  }
  function success(enquetes: Object) {
    return { type: enqueteConstants.GET_ALL_SUCCESS, enquetes };
  }
  function failure(error: Object) {
    return { type: enqueteConstants.GET_ALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    enqueteService
      .getAll()
      .then((state: Object) => dispatch(success(state)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getByNumPage(numPage: string) {
  function request() {
    return { type: enqueteConstants.GET_BY_NUM_PAGE_REQUEST };
  }
  function success(enquete: Object) {
    return { type: enqueteConstants.GET_BY_NUM_PAGE_SUCCESS, enquete };
  }
  function failure(error: Object) {
    return { type: enqueteConstants.GET_BY_NUM_PAGE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    enqueteService
      .getByNumPage(numPage)
      .then((state: Object) => dispatch(success(state)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function savePage(body: object) {
  function request() {
    return { type: enqueteConstants.SAVE_PAGE_REQUEST };
  }
  function success(enquete: Object) {
    return { type: enqueteConstants.SAVE_PAGE_SUCCESS, enquete };
  }
  function failure(error: Object) {
    return { type: enqueteConstants.SAVE_PAGE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    enqueteService
      .savePage(body)
      .then((enquete: Object) => dispatch(success(enquete)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function updatePage(body: object, numPage: string) {
  function request() {
    return { type: enqueteConstants.SAVE_PAGE_REQUEST };
  }
  function success(enquete: Object) {
    return { type: enqueteConstants.SAVE_PAGE_SUCCESS, enquete };
  }
  function failure(error: Object) {
    return { type: enqueteConstants.SAVE_PAGE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    enqueteService
      .updatePage(body, numPage)
      .then((enquete: Object) => dispatch(success(enquete)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getLinks(gestionnaireId: number) {
  function request() {
    return { type: enqueteConstants.GET_LINKS_REQUEST };
  }
  function success(enquetelinks: Object) {
    return { type: enqueteConstants.GET_LINKS_SUCCESS, enquetelinks };
  }
  function failure(error: Object) {
    return { type: enqueteConstants.GET_LINKS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    enqueteService
      .getLinks(gestionnaireId)
      .then((enquetelinks: Object) => dispatch(success(enquetelinks)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function saveLinks(links: any) {
  function request() {
    return { type: enqueteConstants.SAVE_LINKS_REQUEST };
  }
  function success(savedLinks: Object) {
    return { type: enqueteConstants.SAVE_LINKS_SUCCESS, savedLinks };
  }
  function failure(error: Object) {
    return { type: enqueteConstants.SAVE_LINKS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    enqueteService
      .saveLinks(links)
      .then((savedLinks: Object) => dispatch(success(savedLinks)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function deleteEnquetePage(enqueteContent: string) {
  function request() {
    return { type: enqueteConstants.DELETE_PAGE_REQUEST };
  }
  function success(enquetes: Object) {
    return { type: enqueteConstants.DELETE_PAGE_SUCCESS, enquetes };
  }
  function failure(error: Object) {
    return { type: enqueteConstants.DELETE_PAGE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    enqueteService
      .deleteEnquetePage(enqueteContent)
      .then((state: Object) => dispatch(success(state)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

export default {
  getAll,
  updatePage,
  getByNumPage,
  savePage,
  getLinks,
  saveLinks,
  deleteEnquetePage,
};
