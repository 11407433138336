import { User } from '../_entities/user';
import { dashboard } from '../_entities/dashboard';

/**
 * Permet de formater le nom du dashboard
 * @method formatDashboardName
 * @param {user} User un utilisateur
 * @param {dashboard} dashboard un tableau de board
 * @returns {string} le nom du tableau de bord
 */
export default function formatDashboardName(user: User, dashboard: dashboard, t: Function): string {
  if (user.id === dashboard.owner.id && dashboard.personal) {
    return t('dashboard_helper.dashboard_name.my_dashboard');
  }
  if (user.id !== dashboard.owner.id && dashboard.personal) {
    return t('dashboard_helper.dashboard_name.dashboard_of_x', { userName: dashboard.owner.userName.split('@')[0] });
  }
  return dashboard.name;
}
