import React from 'react';
import SourceSheet from './SourceSheet';

export function SourceSheetMeter(props: any) {
  return <SourceSheet displayMode="Meter" {...props} />;
}

export function SourceSheetRadio(props: any) {
  return <SourceSheet displayMode="Radio" {...props} />;
}
