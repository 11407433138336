import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { userActions, alertActions } from '../_actions';
import { users, alert } from '../_interfaces/reducers';

import { User } from '../_entities/user';
import _debounce from 'lodash.debounce';
import { userService } from '../_services';

import GestionUtilisateurBlanc from '../SvgComponents/GestionUtilisateurBlanc';
import MoniteurBlanc from '../SvgComponents/MonitorBlanc';
import { withTranslation } from 'react-i18next';

interface Props {
  add: Function;
  users: users;
  user: User;
  dispatch: Function;
  alert: alert;
  isOpen: boolean;
  tourmaline: boolean;
  isTourmalineOpen: boolean;
  t: Function;
}

interface State {
  firstName: string;
  lastName: string;
  email: string;
  added: boolean | null | undefined;
  ucid: string;
}

/**
 * Gère la création d'utilisateur
 *
 * @class CreateUserComponent
 * @extends Component
 */
class CreateUserComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      added: null,
      ucid: '',
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.users.userUpdated) {
      return {
        email: '',
        lastName: '',
        firstName: '',
        added: true,
        ucid: '',
      };
    } else {
      return state;
    }
  }

  /**
   * @method componentDidMount
   */
  componentDidMount() {
    const { dispatch, tourmaline }: any = this.props;
    dispatch(alertActions.clear());
  }

  /**
   * Nettoie le state au démontage
   *
   * @method componentWillUnmount
   * @memberof CreateUserComponent
   */
  componentWillUnmount() {
    const { dispatch }: any = this.props;
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
      added: null,
    });
    dispatch(alertActions.clear());
  }

  /**
   * Gère l'action à la validation du formulaire
   *
   * @method handleValidSubmit
   * @param {Object} event Evènement
   * @param {any} values Valeurs du formulaire
   */
  handleValidSubmit = (event: Object, values: any) => {
    const { tourmaline } = this.props;
    this.setState({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      ucid: values.UCID,
    });
    const { dispatch, users } = this.props;
    const { firstName, lastName, email, ucid } = this.state;
    // on créé un utilisateur pour ne pas influencer l'état
    const userToCreate = {
      role: 'USER',
      email,
      userName: email,
      firstName,
      lastName,
    };
    if (tourmaline) {
      dispatch(userActions.saveTourmaline(ucid, users.items));
    } else {
      dispatch(userActions.save(userToCreate, users.items));
    }
  };

  /**
   * Gère l'action à l'echec de validation du formulaire
   *
   * @method handleValidSubmit
   * @param {Object} event Evènement
   * @param {any} values Valeurs du formulaire
   */
  handleInvalidSubmit = (event: Object, errors: Object, values: any) => {
    this.setState({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
    });
  };

  /**
   * Valide le formulaire (check email)
   *
   * @method validate
   * @param {string} value Valeur
   * @param {any} ctx Contexte
   * @param {any} input Input
   * @param {any} cb Callback
   * @returns {any} Le résultat
   */
  validate = _debounce((value, ctx, input, cb) => {
    if (!value) {
      return;
    }

    try {
      userService
        .existEmail(value)
        .then((result: any) => {
          if (result && result.message && result.message === 'false') {
            cb(true);
          } else {
            cb("L'adresse est déjà utilisée");
          }
        })
        .catch((error: any) => console.log(error));
    } catch (error) {
      console.error(error);
    }
  }, 500);

  displayForm = () => {
    const { tourmaline, t } = this.props;
    const { email, firstName, lastName, ucid } = this.state;
    if (tourmaline) {
      return (
        <div>
          <AvField name="UCID" value={ucid} label="UCID" required errorMessage={t('add_user.field.ucid_code_error')} />
        </div>
      );
    } else {
      return (
        <div>
          <AvField
            name="email"
            value={email}
            label={t('add_user.field.email_unique')}
            type="email"
            required
            validate={{
              async: this.validate,
            }}
            errorMessage={t('add_user.field.email_error')}
          />
          <AvField
            name="lastName"
            value={lastName}
            label={t('all.contact.last_name')}
            required
            errorMessage={t('add_user.field.name_error')}
            validate={{
              pattern: {
                value: '^[A-Za-z]+$',
                errorMessage: t('add_user.field.field_only_alphabetical'),
              },
              minLength: {
                value: 2,
                errorMessage: t('add_user.field.minimum_x_size', { min: 2 }),
              },
              maxLength: {
                value: 50,
                errorMessage: t('add_user.field.maximum_x_size', { max: 50 }),
              },
            }}
          />
          <AvField
            name="firstName"
            value={firstName}
            label={t('all.contact.first_name')}
            required
            errorMessage={t('add_user.field.name_error')}
            validate={{
              minLength: {
                value: 2,
                errorMessage: t('add_user.field.minimum_x_size', { min: 2 }),
              },
            }}
          />
        </div>
      );
    }
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { alert, isOpen, add, tourmaline, t } = this.props;
    const { added } = this.state;
    return (
      <>
        <ModalBody>
          <Modal isOpen={isOpen} toggle={add as any}>
            <div className="wrapper-modal-addUser">
              <ModalHeader toggle={add as any}>
                {tourmaline ? <MoniteurBlanc height="1.5em" /> : <GestionUtilisateurBlanc height="1.5em" />}
                <div style={{ marginLeft: '5%' }}>{t('add_user.button.add_new_user')}</div>
              </ModalHeader>
            </div>
            <AvForm onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
              <ModalBody>
                <div>{alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}</div>
                {!added && (
                  <div>
                    <this.displayForm />
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={add as any}>
                  {t('all.button.cancel')}
                </Button>
                {!added && <Button color="primary">{t('all.button.register')}</Button>}
              </ModalFooter>
            </AvForm>
          </Modal>
        </ModalBody>{' '}
      </>
    );
  }
}
function mapStateToProps(state: any) {
  const { authentication, alert, users } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    users,
  };
}

const connectedCreateUserComponent = connect(mapStateToProps)(CreateUserComponent);
const tr = withTranslation()(connectedCreateUserComponent);
export default tr;
