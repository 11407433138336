import React from 'react';

const SvgEtoileFavorisHeader = (props: any) => (
  <svg viewBox="0 0 24.24 23.14" {...props}>
    <path
      d="M24.21 8.75a.67.67 0 0 0-.55-.46L16.1 7.21 12.7.37a.68.68 0 0 0-1.21 0L8.14 7.24.57 8.36a.69.69 0 0 0-.57.46.67.67 0 0 0 .18.69l5.48 5.31-1.23 7.54a.67.67 0 0 0 .66.78.58.58 0 0 0 .31-.08l6.75-3.57L18.92 23a.71.71 0 0 0 .31.08.68.68 0 0 0 .67-.67.41.41 0 0 0 0-.16l-1.31-7.48L24 9.44a.67.67 0 0 0 .18-.69zm-6.82 5.32a.67.67 0 0 0-.19.6l1.14 6.53-5.88-3.07a.7.7 0 0 0-.63 0L6 21.24l1.1-6.54a.67.67 0 0 0-.19-.6L2.12 9.49l6.56-1a.68.68 0 0 0 .51-.37l2.91-6 3 5.94a.7.7 0 0 0 .5.37l6.57.93z"
      fill={props.fill}
      data-name="Calque 2"
    />
  </svg>
);

export default SvgEtoileFavorisHeader;
