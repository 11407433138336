import { authHeader, handleResponse } from '../_helpers';

async function getGenericData(id: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/remoteReading/data/generic/${id}`, requestOptions);
  return handleResponse(response);
}

const remoteReadingService = {
  getGenericData,
};

export default remoteReadingService;
