import blueRadio from './gouttes/releve_sans_alarme_radio_non_tele.png';
import blueTele from './gouttes/releve_sans_alarme_tele.png';
import purpleRadio from './gouttes/releve_avec_alarme_radio_non_tele.png';
import purpleTele from './gouttes/releve_avec_alarme_tele.png';
import redNoRadio from './gouttes/non_releve_sans_radio.png';
import redRadio from './gouttes/non_releve_radio_non_tele.png';
import redTele from './gouttes/non_releve_tele.png';
import greenNoRadio from './gouttes/releve_manuelle_sans_radio.png';
import greenTele from './gouttes/releve_manuelle_tele.png';
import greenRadio from './gouttes/releve_manuelle_radio.png';
import yellowRadio from './gouttes/radio_modifie.png';
import yellowTele from './gouttes/tele_modifie.png';
import yellowManual from './gouttes/manual_modifie.png';
import antenneActive from './antennes/antenne_active.png';
import antenneInactive from './antennes/antenne_inactive.png';
import homeMarkerImg from './home/home_marker.png';
import { Expression } from 'mapbox-gl';
import sbr from './gouttes/selectedbr.png';
import sbt from './gouttes/selectedbt.png';
import sgm from './gouttes/selectedgm.png';
import sgr from './gouttes/selectedgr.png';
import sgt from './gouttes/selectedgt.png';
import spr from './gouttes/selectedpr.png';
import spt from './gouttes/selectedpt.png';
import srm from './gouttes/selectedrm.png';
import srr from './gouttes/selectedrr.png';
import srt from './gouttes/selectedrt.png';
import sym from './gouttes/selectedym.png';
import syr from './gouttes/selectedyr.png';
import syt from './gouttes/selectedyt.png';

export interface Image {
  url: string;
  id: string;
}

const gouttesImages: Array<Image> = [
  { url: redNoRadio, id: 'red_manual' },
  { url: redRadio, id: 'red_radio' },
  { url: redTele, id: 'red_telereleve' },
  { url: greenNoRadio, id: 'green_manual' },
  { url: greenRadio, id: 'green_radio' },
  { url: greenTele, id: 'green_telereleve' },
  { url: blueRadio, id: 'blue_radio' },
  { url: blueTele, id: 'blue_telereleve' },
  { url: purpleRadio, id: 'purple_radio' },
  { url: purpleTele, id: 'purple_telereleve' },
  { url: yellowManual, id: 'yellow_manual' },
  { url: yellowRadio, id: 'yellow_radio' },
  { url: yellowTele, id: 'yellow_telereleve' },

  { url: sbr, id: 'blue_radio_selected' },
  { url: sbt, id: 'blue_telereleve_selected' },
  { url: sgm, id: 'green_manual_selected' },
  { url: sgr, id: 'green_radio_selected' },
  { url: sgt, id: 'green_telereleve_selected' },
  { url: spr, id: 'purple_radio_selected' },
  { url: spt, id: 'purple_telereleve_selected' },
  { url: srm, id: 'red_manual_selected' },
  { url: srr, id: 'red_radio_selected' },
  { url: srt, id: 'red_telereleve_selected' },
  { url: sym, id: 'yellow_manual_selected' },
  { url: syr, id: 'yellow_radio_selected' },
  { url: syt, id: 'yellow_telereleve_selected' },
];

const gatewayImages: Array<Image> = [
  { url: antenneActive, id: 'antenneActive' },
  { url: antenneInactive, id: 'antenneInactive' },
];

const homeMarkerSrc: Image = { url: homeMarkerImg, id: 'homeMarker' };

const iconImage: Expression = [
  'match',
  ['get', 'ficheState'],
  'red_manual',
  'red_manual',
  'red_radio',
  'red_radio',
  'red_telereleve',
  'red_telereleve',
  'green_manual',
  'green_manual',
  'green_radio',
  'green_radio',
  'green_telereleve',
  'green_telereleve',
  'blue_radio',
  'blue_radio',
  'blue_telereleve',
  'blue_telereleve',
  'purple_radio',
  'purple_radio',
  'purple_telereleve',
  'purple_telereleve',
  'yellow_manual',
  'yellow_manual',
  'yellow_radio',
  'yellow_radio',
  'yellow_telereleve',
  'yellow_telereleve',
  'blue_radio_selected',
  'blue_radio_selected',
  'blue_telereleve_selected',
  'blue_telereleve_selected',
  'green_manual_selected',
  'green_manual_selected',
  'green_radio_selected',
  'green_radio_selected',
  'green_telereleve_selected',
  'green_telereleve_selected',
  'purple_radio_selected',
  'purple_radio_selected',
  'purple_telereleve_selected',
  'purple_telereleve_selected',
  'red_manual_selected',
  'red_manual_selected',
  'red_radio_selected',
  'red_radio_selected',
  'red_telereleve_selected',
  'red_telereleve_selected',
  'yellow_manual_selected',
  'yellow_manual_selected',
  'yellow_radio_selected',
  'yellow_radio_selected',
  'yellow_telereleve_selected',
  'yellow_telereleve_selected',
  'red_manual',
];

const gatewayImage: Expression = ['case', ['boolean', ['get', 'active'], true], 'antenneActive', 'antenneInactive'];

const trad: Array<string> = [
  'static_list.label.not_indexed_manual_read',
  'static_list.label.not_indexed_radio_read',
  'static_list.label.not_indexed_telereleve_read',
  'static_list.label.indexed_no_radio',
  'static_list.label.indexed_with_radio',
  'static_list.label.indexed_with_telereleve',
  'static_list.label.indexed_radio_read',
  'static_list.label.indexed_telereleve_read',
  'static_list.label.indexed_radio_read_with_alarm',
  'static_list.label.indexed_telereleve_read_with_alarm',
  'static_list.label.edited_manual_read',
  'static_list.label.edited_radio_read',
  'static_list.label.edited_with_telereleve',
];

const allImages = {
  gouttesImages,
  iconImage,
  trad,
  gatewayImage,
  gatewayImages,
  homeMarkerSrc,
};

export default allImages;
