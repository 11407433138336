import React from 'react';

const SvgHomeFavori = (props: any) => (
  <svg viewBox="0 0 171.1 154" {...props}>
    <g id="Calque_1">
      <g>
        <path
          fill={props.fill}
          d="M100.4,127.8c-1.8-4.7-0.6-10,3.2-13.4l18.6-17l0-0.1l35.9-35.2c1.3-1.2,1.7-2.9,1.2-4.6
          c-0.5-1.6-1.9-2.8-3.6-3L106,47.5l-22.4-45c-0.8-1.5-2.3-2.5-4-2.5c-1.7,0-3.2,0.9-4,2.5L53.5,47.6L3.8,55c-1.7,0.3-3,1.4-3.6,3
          c-0.5,1.6-0.1,3.4,1.1,4.5l36.1,35l-8.3,49.6c-0.3,1.7,0.4,3.3,1.8,4.3c0.8,0.6,1.7,0.9,2.6,0.9c0.7,0,1.4-0.2,2.1-0.5L80,128.2
          l27.3,14.3v-7.8C104.2,133.5,101.7,131,100.4,127.8z"
        />
        <path
          fill={props.fill}
          d="M170.1,121l-5.3-4.8v-16c0-1-0.8-1.8-1.8-1.8h-12.7c-1,0-1.8,0.8-1.8,1.8v1l-6.4-5.8
          c-0.6-0.6-1.4-0.8-2.2-0.8s-1.5,0.3-2.2,0.8l-13.6,12.4L109.7,121c-1,0.9-1.3,2.3-0.8,3.5c0.5,1.2,1.7,2,3,2h4.5v20.7v4.9
          c0,1,0.8,1.8,1.8,1.8h15.4c1,0,1.8-0.8,1.8-1.8v-15.6h9v15.6c0,1,0.8,1.8,1.8,1.8h15.4c1,0,1.8-0.8,1.8-1.8v-25.6h4.5
          c1.3,0,2.5-0.8,3-2C171.4,123.3,171.1,121.9,170.1,121z"
        />
      </g>
    </g>
  </svg>
);

export default SvgHomeFavori;
