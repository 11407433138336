import meterGatewayConstants from '../_constants/meterGateway.constants';
import { action } from '../_interfaces/action';

export default function meterGateway(state = {}, action: action) {
  switch (action.type) {
    case meterGatewayConstants.GET_METER_REQUEST:
      return {
        ...state,
        actions: null,
        items: [],
      };
    case meterGatewayConstants.GET_METER_SUCCESS:
      return {
        ...state,
        meters: action.meters,
        loading: false,
      };
    case meterGatewayConstants.GET_METER_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Impossible d'obtenir la liste des compteurs",
      };
    case meterGatewayConstants.GET_GATEWAY_REQUEST:
      return {
        ...state,
        actions: null,
        items: [],
      };
    case meterGatewayConstants.GET_GATEWAY_SUCCESS:
      return {
        ...state,
        //@ts-ignore
        gateways: action.gateways,
        loading: false,
      };
    case meterGatewayConstants.GET_GATEWAY_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Impossible d'obtenir la liste des gateways",
      };
    case meterGatewayConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
