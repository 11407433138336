import mailConstants from '../_constants/mail.constants';
import { action } from '../_interfaces/action';

export default function mails(state = {}, action: action) {
  switch (action.type) {
    case mailConstants.SUCCESS:
      return {
        sended: true,
      };
    case mailConstants.ERROR:
      return {
        sended: false,
        error: action.error,
      };
    case mailConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
