import { bookmarConstants } from '../_constants';
import { bookmarkService } from '../_services';
import { bookmark } from '../_entities/bookmark';

/**
 * Récupère tous les marque-pages du site courant
 *
 * @method getAll
 * @param {number} locationId Id du site
 * @returns {Object} La liste des bookmarks
 */
function getAll(locationId: number) {
  function request() {
    return {
      type: bookmarConstants.GETALL_REQUEST,
    };
  }

  function success(bookmarks: Object) {
    return {
      type: bookmarConstants.GETALL_SUCCESS,
      bookmarks,
    };
  }

  function failure(error: Object) {
    return {
      type: bookmarConstants.GETALL_FAILURE,
      error,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    bookmarkService
      .getAll(locationId)
      .then((bookmarks: Object) => dispatch(success(bookmarks)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getAllHome() {
  function request() {
    return { type: bookmarConstants.GET_ALL_HOME_REQUEST };
  }

  function success(bookmarks: Object) {
    return { type: bookmarConstants.GET_ALL_HOME_SUCCESS, bookmarks };
  }

  function failure(error: Object) {
    return { type: bookmarConstants.GET_ALL_HOME_FAILURE, error };
  }

  return (dispatch: Function) => {
    dispatch(request());
    bookmarkService
      .getAllHome()
      .then((bookmarks: Object) => dispatch(success(bookmarks)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Sauvegarde le marque-page passé en paramètre
 *
 * @method save
 * @param {bookmark} bookmark Marque-page
 * @param {Array<bookmark>} bookmarks Liste des marque-pages
 * @returns {Object} L'état de la sauvegarde
 */
function save(bookmark: bookmark, bookmarks = <bookmark[]>[]) {
  function success(savedBookmark: Object) {
    return {
      type: bookmarConstants.SAVE_SUCCESS,
      bookmarks,
      savedBookmark,
    };
  }
  return (dispatch: Function) => {
    bookmarkService
      .save(bookmark)
      .then((savedBookmark: Object) => {
        dispatch(success(savedBookmark));
      })
      .catch((error: Object) => {
        console.log(error);
      });
  };
}

/**
 * Met à jour le marque-page passé en paramètre
 *
 * @method save
 * @param {bookmark} bookmark Marque-page
 * @param {Array<bookmark>} bookmarks Liste des marque-pages
 * @returns {Object} L'état de la mise à jour
 */
function deleteBookmark(bookmark: bookmark, bookmarks = <bookmark[]>[]) {
  function request() {
    return {
      type: bookmarConstants.DELETE_REQUEST,
      deleteBookmark: bookmark,
    };
  }
  function failure(error: Object) {
    return {
      type: bookmarConstants.DELETE_FAILURE,
      error,
    };
  }
  function success(deletedBookmark: bookmark) {
    return {
      type: bookmarConstants.DELETE_SUCCESS,
      bookmarks,
      deletedBookmark,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    bookmarkService
      .deleteBookmark(bookmark)
      .then(() => {
        dispatch(success(bookmark));
        dispatch(getAll(bookmark.locationId));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

/**
 * Vide le state des bookmarks
 *
 * @method clear
 */
function clear() {
  return {
    type: bookmarConstants.CLEAR,
  };
}
const bookmarActions = {
  getAll,
  save,
  deleteBookmark,
  clear,
  getAllHome,
};

export default bookmarActions;
