import { autocompleteService } from '../_services';
/**
 * Gère l'autocomplétion en passant une liste
 * de conditions
 *
 * @method autocomplete
 * @param {Array<any>} conditions Conditions
 * @returns {Object} Les résultats
 */
function autocomplete(conditions = <any>[]) {
  return autocompleteService.autocomplete(conditions);
}

const autocompleteActions = {
  autocomplete,
};

export default autocompleteActions;
