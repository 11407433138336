import React from 'react';

const EnqueteSvg = (props: any) => (
  <svg viewBox="0 0 122.72 98.58" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="pictos">
        <path
          fill={props.fill}
          d="M22.58,30.71a4.69,4.69,0,0,1,.92-.06h86.05V16.92a1.59,1.59,0,0,0-1.6-1.6H51.48L41.77,1.75A4.2,4.2,0,0,0,38.36,0H5.85A5.8,5.8,0,0,0,0,5.8V84.15L18.19,34.64a5.41,5.41,0,0,1,4.39-3.93"
        />
        <path
          fill={props.fill}
          d="M121.62,35H23.53a1.29,1.29,0,0,0-1.2.93L0,97.2a1.3,1.3,0,0,0,.27.88,1.22,1.22,0,0,0,1,.5H99.35a1.28,1.28,0,0,0,1.2-.92l22.17-60.84c-.06-.62-.28-1.81-1.1-1.81"
        />
      </g>
    </g>
  </svg>
);

export default EnqueteSvg;
