import React from 'react';

const SvgGestionUtilisateurBlanc = (props: any) => (
  <svg viewBox="0 0 11.92 12.8" {...props}>
    <path
      d="M11.46 12.8a.46.46 0 0 0 .46-.46v-1.55a1.55 1.55 0 0 0-.57-1.2 18.44 18.44 0 0 0-3.11-2V5.83a1.48 1.48 0 0 0 .46-1.07V2.5A2.51 2.51 0 0 0 6.2 0h-.49a2.5 2.5 0 0 0-2.49 2.5v2.26a1.51 1.51 0 0 0 .45 1.07v1.74a18.06 18.06 0 0 0-3.1 2A1.52 1.52 0 0 0 0 10.79v1.55a.45.45 0 0 0 .46.46"
      fill={props.fill}
      data-name="Calque 2"
    />
  </svg>
);

export default SvgGestionUtilisateurBlanc;
