import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Input, Button, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

interface Props {
  saveConfig: Function;
  updateConfig: Function;
  toggle: Function;
  openModal: boolean;
  listOfExistingConfig: any;
}

interface State {
  nameForSave: string;
  confirmModal: boolean;
  closeAll: boolean;
}

class ModalSauvegardeConfig extends Component<Props, State> {
  toggleNested: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      nameForSave: '',
      confirmModal: false,
      closeAll: false,
    };
  }

  shouldComponentUpdate(props: Props, state: State) {
    const { nameForSave, confirmModal } = this.state;
    const { openModal } = this.props;
    return props.openModal !== openModal || nameForSave !== state.nameForSave || confirmModal !== state.confirmModal;
  }

  saveQuery = () => {
    const { saveConfig } = this.props;
    const { nameForSave } = this.state;
    saveConfig(nameForSave);

    this.toggle();
  };

  updateQuery = () => {
    const { updateConfig, listOfExistingConfig } = this.props;
    const { nameForSave } = this.state;
    updateConfig(
      nameForSave,
      listOfExistingConfig.filter((conf: any) => conf.label.toLowerCase() === nameForSave.toLowerCase())[0].id
    );
  };

  verifBeforeSave = () => {
    const { listOfExistingConfig } = this.props;
    const { nameForSave } = this.state;

    if (
      listOfExistingConfig.length > 0 &&
      listOfExistingConfig.filter((conf: any) => conf.label.toLowerCase() === nameForSave.toLowerCase()).length > 0
    ) {
      this.toggleConfirm();
    } else {
      this.saveQuery();
    }
  };

  toggle = () => {
    const { toggle } = this.props;
    toggle();
    this.setState({ nameForSave: '' });
  };

  toggleConfirm = () => {
    const { confirmModal } = this.state;
    this.setState({ confirmModal: !confirmModal, closeAll: false });
  };

  toggleAll = () => {
    const { confirmModal } = this.state;
    this.updateQuery();
    this.setState({ confirmModal: !confirmModal, closeAll: true });
  };

  render() {
    const { openModal, toggle, t } = this.props;
    const { nameForSave, confirmModal, closeAll } = this.state;

    return (
      <Modal isOpen={openModal} toggle={toggle as any}>
        <ModalHeader toggle={this.toggle}>{t('saving_settings_modal.text.saving_conf')}</ModalHeader>
        <ModalBody>
          <div>
            <Row>
              <Col>
                <Input
                  type="text"
                  name="namefilters"
                  id="namefilters"
                  placeholder={t('saving_settings_modal.text.backup_name')}
                  value={nameForSave}
                  onChange={e => this.setState({ nameForSave: e.target.value })}
                />
                <Modal
                  isOpen={confirmModal}
                  toggle={this.toggleNested as any}
                  onClosed={closeAll ? this.toggle : undefined}
                >
                  <ModalHeader>Configuration déjà existante</ModalHeader>
                  <ModalBody>
                    <p>Une configuration avec le même nom existe déjà.</p>
                    <p>Voulez vous l'écraser ?</p>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={this.toggleConfirm}>
                      Retour
                    </Button>{' '}
                    <Button color="primary" onClick={this.toggleAll}>
                      Ecraser
                    </Button>
                  </ModalFooter>
                </Modal>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              this.verifBeforeSave();
            }}
          >
            {t('all.button.save')}
          </Button>{' '}
          <Button color="danger" onClick={this.toggle}>
            {t('all.button.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const tr = withTranslation()(ModalSauvegardeConfig);
export default tr;
