import React from 'react';
import { Button, FormGroup, Label, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import translate from '../_helpers/locale-helpers';
import SimpleGmap from '../_components/SimpleGmap';
import Geocode from 'react-geocode';
import SvgCompteurVert from '../SvgComponents/CompteurBlanc';
import { AutocompleteField } from '../Widget';
import { pdiActions, meterActions } from '../_actions';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import ErrorBand from '../Bands/Error';
import LoadingBand from '../Bands/Loading';
import SuccessBand from '../Bands/Success';
import { withTranslation } from 'react-i18next';

Geocode.setApiKey('AIzaSyBSRYNpTYG6I-BWu_MSDpnkDyuKsJrafc4');
Geocode.setLanguage('fr');

interface State {
  pdi: {
    address: {
      streetNumber: string;
      streetNumberAdditionnal: string;
      street: string;
      complement: string;
      zipcode: string;
      city: string;
      country: string;
    };
    gpsPosition: {
      lat: number;
      lng: number;
      alt: number;
    };
    meterSerial: string;
  };
  gpsSearchLaunched: boolean;
}

/**
 * @class LocationAdressChooser
 * @extends {React.Component<Props, State>}
 */
class LocationAdressChooser extends React.Component<any, State> {
  /**
   * @param {any} props Propriétés
   * @memberof DisplayTypeChooser
   */
  constructor(props: any) {
    super(props);

    this.state = {
      pdi: {
        address: {
          streetNumber: '',
          streetNumberAdditionnal: '',
          street: '',
          complement: '',
          zipcode: '',
          city: '',
          country: '',
        },
        gpsPosition: {
          lat: 0,
          lng: 0,
          alt: 0,
        },
        meterSerial: '',
      },
      gpsSearchLaunched: false,
    };

    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.formatAddress = this.formatAddress.bind(this);
  }

  /**
   * Récupère les datasources au montage du
   * composant
   *
   * @method componentDidMount
   * @memberof WidgetTemplateCreatorComponent
   */
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(meterActions.getUnlinkedMeters());
  }

  componentDidUpdate() {
    const { history, pdis } = this.props;
    if (pdis && pdis.createdPdi && pdis.createdPdi.id) {
      //history.push('/stock');
    }
  }

  /**
   * Gère le passage à l'étape suivante et la mise à jour
   * du template
   *
   * @param {Object} e Evènement
   * @method send
   * @memberof DisplayTypeChooser
   */
  send = (e: any) => {
    e.preventDefault();
    this.setState({ gpsSearchLaunched: true });
    const { update, nextStep } = this.props;
    const { pdi } = this.state;
    Geocode.fromAddress(this.formatAddress()).then(
      (response: any) => {
        const { lat, lng } = response.results[0].geometry.location;
        pdi.gpsPosition.lat = lat;
        pdi.gpsPosition.lng = lng;
        this.setState({
          pdi,
          gpsSearchLaunched: false,
        });
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  getValidationsForField = (name: string) => {
    switch (name) {
      case 'city':
        return {
          required: this.hasRequired(name),
          pattern: {
            value: '[A-Za-z]+',
            errorMessage: 'Le nom de la ville ne doit pas contenir de chiffres ou de caractères spéciaux',
          },
        };

      case 'streetNumber':
        return {
          required: this.hasRequired(name),
          pattern: { value: '[0-9]', errorMessage: 'Le numéro de la voie doit contenir exclusivement des chiffres' },
        };

      case 'zipcode':
        return {
          required: this.hasRequired(name),
          pattern: { value: '[0-9]{5}', errorMessage: 'Le code postal doit contenir 5 chiffres' },
        };

      default:
        return {
          required: this.hasRequired(name),
        };
    }
  };

  hasRequired = (name: string) => {
    switch (name) {
      case 'city':
        return { value: true, errorMessage: 'Renseigner la ville' };

      case 'street':
        return { value: true, errorMessage: 'Renseigner le nom de la rue' };

      case 'zipcode':
        return { value: true, errorMessage: 'Renseigner le code postal' };

      default:
        return { value: false };
    }
  };

  /**
   * Gère le changement du type d'affichage
   *
   * @param {Object} event Evènement
   * @method handleAddressChange
   * @memberof DisplayTypeChooser
   */
  handleAddressChange = (event: any) => {
    const { pdi } = this.state;
    const key: string = event.target.id;
    const value: string = event.target.value;
    pdi.address[key] = value;
    this.setState({
      pdi,
    });
  };

  formatAddress() {
    const { pdi } = this.state;
    const addressInline: string =
      pdi.address.streetNumber +
      pdi.address.streetNumberAdditionnal +
      pdi.address.street +
      pdi.address.complement +
      pdi.address.zipcode +
      pdi.address.city +
      pdi.address.country;
    return addressInline;
  }

  /**
   * Gère l'autocomplétion sur le formulaire
   *
   * @param {string} type Type
   * @param {string} field Champ
   * @param {string} userInput Saisie (valeur)
   * @returns {Promise}
   * @method autocompleteservice
   * @memberof WidgetOptionComponent
   */
  autocompleteservice = (type: string, field: string, userInput: string) => {
    // TODO
    // A améliorer
    const { meters } = this.props;

    const metersUnlinked =
      meters &&
      meters.unlinkedMeters &&
      meters.unlinkedMeters.filter((meter: string) => meter.toLowerCase().startsWith(userInput.toLowerCase()));

    return Promise.all(metersUnlinked);
  };

  handleValidSubmit = (ev: any, values: any) => {
    const { dispatch } = this.props;
    const { pdi } = this.state;
    pdi.meterSerial = values.MeterSerialNumber;
    dispatch(pdiActions.createPdi(pdi));
  };

  handleChange = (e: any) => {
    const { pdi } = this.state;
    pdi.meterSerial = e.target.value;
    this.setState({ pdi });
  };

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof DisplayTypeChooser
   */
  render() {
    const { locales, meters, pdis, t } = this.props;

    const { pdi, gpsSearchLaunched } = this.state;

    const locale = locales.locale;

    return (
      <Row>
        {location && (
          <Col lg="6">
            <AvForm onValidSubmit={this.send}>
              <div className="locationchoice">
                {Object.keys(pdi.address).map((key: string) => (
                  <FormGroup key={key}>
                    <Label for={key}>{t('all.address.' + key)}</Label>
                    <AvField
                      type="text"
                      name={key}
                      id={key}
                      placeholder={t('all.address.' + key)}
                      onChange={this.handleAddressChange}
                      value={pdi.address[key]}
                      autoComplete="NONE"
                      validate={this.getValidationsForField(key)}
                    />
                  </FormGroup>
                ))}
              </div>
              <Button color="info">{t('all.button.search')}</Button>
            </AvForm>
          </Col>
        )}
        {gpsSearchLaunched && (
          <Col lg="6">
            <LoadingBand message={t('location_address_chooser.loading_band.search_gps')} />
          </Col>
        )}
        {!gpsSearchLaunched && pdi && pdi.gpsPosition && pdi.gpsPosition.lat !== 0 && pdi.gpsPosition.lng !== 0 && (
          <Col lg="6">
            <div className="presentation-container-map">
              <SimpleGmap lat={pdi.gpsPosition.lat} lng={pdi.gpsPosition.lng} />
            </div>

            {meters && meters.unlinkedMeters && (
              <div style={{ marginTop: '20px', marginLeft: '20px' }}>
                <h2>
                  <span>
                    <SvgCompteurVert height="1em" width="1em" fill="#31c6b3" />
                  </span>
                  {t('location_address_chooser.link_container_title.link_meter')}
                </h2>
                <AvForm onValidSubmit={this.handleValidSubmit}>
                  <AutocompleteField
                    key="MeterSerialNumber"
                    title="MeterSerialNumber"
                    name="MeterSerialNumber"
                    label={t('all.meter.meter_serial')}
                    required
                    value=""
                    helpMessage={t('all.text.required')}
                    errorMessage={t('all.text.required_field')}
                    service={this.autocompleteservice}
                    type="AutoCompleteString"
                  />
                  <Button color="primary">{t('all.button.register')}</Button>
                </AvForm>
              </div>
            )}

            {meters && meters.error && (
              <div style={{ marginTop: '20px', marginLeft: '20px' }}>
                <ErrorBand message={meters.error} />
              </div>
            )}
            <br />
          </Col>
        )}

        {pdis && pdis.createdPdi && pdis.createdPdi.id && (
          <Col lg="12" style={{ marginTop: '20px', marginRight: '20px' }}>
            <SuccessBand message={t('location_address_chooser.success_band.pdi_created')} />
          </Col>
        )}
      </Row>
    );
  }
}

function mapStateToProps(state: any) {
  const { locales, meters, pdis } = state;
  return {
    locales,
    meters,
    pdis,
  };
}

const LocationAdressChooserConnectedComponent = connect(mapStateToProps)(LocationAdressChooser);
const tr = withTranslation()(LocationAdressChooserConnectedComponent);
export default tr;
