import React, { Component } from 'react';
import { CustomInput, FormGroup, Spinner } from 'reactstrap';
import cloneDeep from 'lodash/cloneDeep';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import synchroAction from '../../_actions/synchro.actions';
import synchroActions from '../../_actions/synchro.actions';
import SvgSaphir from '../../SvgComponents/SaphirOrange';
import SvgSaphirWarning from '../../SvgComponents/SaphirAttention';
import DeviceInfo from './TaskComponent/DeviceInfo';
import DragIcone from './TaskComponent/DragIcone';
import TaskButtons from './TaskComponent/TaskButtons';
import RoundInfo from './TaskComponent/RoundInfo';
import MessageCom from './TaskComponent/MessageCom';
import CheckBox from './TaskComponent/CheckBox';
import SvgAttentionJaune from '../../SvgComponents/AttentionJaune';
import SvgCroixNoir from '../../SvgComponents/croixNoir';

class Task extends Component<any, any> {
  constructor(props: any) {
    super(props);
    const copyTask = _.cloneDeep(props.synchro.tasks[props.task.id]);
    this.state = {
      deathLabel: {
        affectation: [],
        desaffectation: [],
      },
      affect: _.cloneDeep(copyTask.affectList),
      checked: false,
      taskName: '',
      taskInfo: {},
      displayInfo: false,
      isOver: '',
      isAffect: false,
      enter: false,
      prevLoad: false,
      lastEnter: '',
      isLastChecked: false,
    };
    this.getAllCommunication = this.getAllCommunication.bind(this);
    this.cancelOne = this.cancelOne.bind(this);
    this.onClickAffecte = this.onClickAffecte.bind(this);
    this.onClickDesaffecte = this.onClickDesaffecte.bind(this);
    this.multiDrag = this.multiDrag.bind(this);
    this.onClickSynchro = this.onClickSynchro.bind(this);
    this.versionCheck = this.versionCheck.bind(this);
    this.onClickInfo = this.onClickInfo.bind(this);
    this.dragLeave = this.dragLeave.bind(this);
    this.dragStartBis = this.dragStartBis.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.dragOver = this.dragOver.bind(this);
    this.drop = this.drop.bind(this);
    this.dragEnter = this.dragEnter.bind(this);
    this.onClickRotate = this.onClickRotate.bind(this);
    this.removeDeviceError = this.removeDeviceError.bind(this);
  }

  timer: any = null;

  componentDidMount() {
    const { task } = this.props;
    const { taskInfo } = this.state;
    if (task && task.content) {
      this.setState({
        taskName: task.content,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { prevLoad } = this.state;
    const { dispatch, match, task, synchro } = this.props;
    const { params } = match;
    if (
      prevProps &&
      prevProps.synchrocom &&
      prevProps.synchrocom.currentState &&
      prevProps.synchrocom.currentState[task.id] !== prevLoad
    ) {
      if (prevLoad) {
        dispatch(synchroAction.getFullAffect(params.GestionnaireId));
        dispatch(synchroActions.getAllRoundFull(params.GestionnaireId));
        dispatch(synchroAction.getListDeviceError());
      }
      this.setState({
        prevLoad: prevProps.synchrocom.currentState[task.id],
      });
    }
    if (
      Array.isArray(synchro.devicesError) &&
      !this.timer &&
      synchro.devicesError.map((el: { first: string; second: string }) => el.second).includes(task.id)
    ) {
      this.timer = setInterval(this.removeDeviceError, 15000);
      window.addEventListener('beforeunload', this.removeDeviceError);
    }
  }

  static getDerivedStateFromProps(props: any, state: any) {
    const { labelTask, delAffect, addAffect, task, dispatch, synchro, t } = props;
    const copyTask = _.cloneDeep(labelTask);
    const copyState = _.cloneDeep(state);
    copyState.deathLabel.affectation = cloneDeep(
      _.uniqWith(
        copyTask.filter(
          (el: any) => el.destination && el.destination.droppableId === props.task.id && el.destination.index !== 5
        ),
        _.isEqual
      )
    );
    copyState.deathLabel.desaffectation = cloneDeep(
      _.uniqWith(
        copyTask.filter(
          (el: any) => el.destination && el.destination.droppableId === props.task.id && el.destination.index === 5
        ),
        _.isEqual
      )
    );
    if (props.multiSelect) {
      if (props.multiDisplay.length > 0 && props.multiDisplay[props.multiDisplay.length - 1].id === props.task.id) {
        copyState.isLastChecked = true;
      } else {
        copyState.isLastChecked = false;
      }
    } else {
      copyState.isLastChecked = false;
      copyState.checked = false;
    }
    if (props.task.id === delAffect.id) {
      copyState.affect = copyState.affect.filter((el: any) => el.id !== delAffect.id);
    }

    const newAffectList = _.cloneDeep(_.uniqWith(addAffect, _.isEqual));
    if (newAffectList.length > 0) {
      newAffectList.forEach(element => {
        if (
          element.destination.droppableId === props.task.id &&
          copyState.affect.find((el: any) => el === element.taskTitle) === undefined
        ) {
          copyState.affect.push(element.taskTitle);
        }
      });
    }
    if (
      props.synchro &&
      props.synchro.unDrop &&
      props.synchro.unDrop.find((el: any) => el === props.task.content || el === props.task.id)
    ) {
      copyState.isAffect = true;
    } else {
      copyState.isAffect = false;
    }
    if (copyState.affect !== props.synchro.tasks[props.task.id].affectList) {
      const copyTask = _.cloneDeep(props.task);
      copyState.affect = _.cloneDeep(
        copyTask.affectList.map((el: any) => {
          if (props.allTasks[el]) {
            return {
              id: el,
              title: props.allTasks[el].content,
              firstId: task.id,
            };
          }
        })
      );
    }
    if (copyState.affect.filter((el: any) => !el).length > 0 && !synchro.error) {
      dispatch(synchroAction.dataError(t('error_boundary.data')));
    }
    copyState.affect = copyState.affect.filter(
      (el: any) =>
        !copyState.deathLabel.desaffectation.find(
          (elem: any) => el && (elem.taskId === el.id || elem.destination.droppableId === el.id)
        )
    );
    return copyState;
  }

  onClickAffecte(e: string) {
    const { removeTask, task } = this.props;
    removeTask(task.id, e);
  }

  onClickDesaffecte(label: string) {
    const { affect } = this.state;
    const { onDeleteAdd, task } = this.props;

    this.setState({
      affect: affect.filter((el: any) => el !== label),
    });
    onDeleteAdd(task.id, label);
  }

  multiDrag(isChecked: any) {
    const { multiDragSelect, task } = this.props;
    this.setState({ checked: isChecked });
    if (isChecked === true) {
      multiDragSelect(task, 'add');
    } else {
      multiDragSelect(task, 'remove');
    }
  }

  getAllCommunication() {
    const { getAllCommunication, task } = this.props;
    getAllCommunication(task);
  }

  cancelOne() {
    const { onClickCancelAll, task } = this.props;
    onClickCancelAll(task);
  }

  edit = (e: Object, device: any) => {
    const { dispatch, task, match } = this.props;
    const { params } = match;
    this.setState({
      taskName: device.name,
    });
    dispatch(synchroAction.updateDeviceName(device.name, task.id, params.id));
  };

  onClickSynchro(type: boolean) {
    const { dispatch, task, columnDisabled, match } = this.props;
    const { params } = match;
    const device = 'column-2';
    if (columnDisabled === device) {
      dispatch(synchroAction.synchroDevice(task.id, params.GestionnaireId));
    } else {
      dispatch(synchroAction.synchroRound(task.id, params.GestionnaireId));
    }
  }

  onClickRotate() {
    const { dispatch, task, match } = this.props;
    const { params } = match;
    dispatch(synchroAction.rotateRound(task.id, params.GestionnaireId));
  }

  onClickInfo() {
    const { displayInfo } = this.state;
    this.setState({
      displayInfo: !displayInfo,
    });
  }

  versionCheck(version: any, type: any) {
    const { synchro } = this.props;
    const myVersion = version.split('.');
    const miniVersion = _.get(synchro, 'version.minimum', '').split('.');
    const currentVersion = _.get(synchro, 'version.current', '').split('.');
    const length = myVersion.length;
    if (version === null) {
      return 1;
    }
    const result = [];
    if (type === 'current') {
      for (let i = 0; i < length; i++) {
        result[i] = parseInt(myVersion[i]) - parseInt(currentVersion[i]);
      }
    } else {
      for (let i = 0; i < length; i++) {
        result[i] = parseInt(myVersion[i]) - parseInt(miniVersion[i]);
      }
    }
    if (result[0] > 0) {
      return 0;
    }
    if (result[0] < 0) {
      return -1;
    }
    if (result[1] > 0) {
      return 0;
    }
    if (result[1] < 0) {
      return -1;
    }
    if (result[2] > 0) {
      return 0;
    }
    if (result[2] < 0) {
      return -1;
    }

    return 0;
  }

  dragStartBis(e: any) {
    const { onDragStart } = this.props;
    onDragStart({
      draggableId: e.target.id,
      mode: 'fluide',
      source: {
        droppableId: e.target.id,
        index: undefined,
      },
      type: 'default',
    });
    // e.dataTransfer.setData('text/plain', ''); // Nécessaire pour Firefox
  }

  drop(e: any) {
    e.stopPropagation();
    this.setState({
      enter: false,
    });
  }

  dragEnter(e) {
    const { labelsList, taskStarting, columnDisabled, updateDrop, allTasks, isAffect, noDrop } = this.props;
    const { isOver, affect } = this.state;
    e.preventDefault();
    if (
      columnDisabled === 'column-1' &&
      labelsList.affect.find((el: any) => el.tournee === e.target.id && el.device === taskStarting)
    ) {
      this.setState({
        isAffect: true,
      });
    } else if (
      columnDisabled === 'column-2' &&
      labelsList.affect.find((el: any) => el.tournee === taskStarting && el.device === e.target.id)
    ) {
      this.setState({
        isAffect: true,
      });
    } else if (affect.find((el: any) => el === allTasks[taskStarting].content)) {
      this.setState({
        isAffect: true,
      });
    }
    this.setState({
      isOver: e.target.id,
      lastEnter: e.target.id,
      enter: true,
    });

    updateDrop(noDrop ? '' : e.target.id);
  }

  dragOver(e: any) {
    const { isAffect } = this.state;
    if (!isAffect) {
      e.preventDefault();
    }
  }

  dragLeave(e: any) {
    e.preventDefault(); // On autorise le drop d'éléments
    this.setState({
      isAffect: false,
      enter: false,
      isOver: '',
    });
  }

  onDragEnd(e: any) {
    const { isOver, lastEnter, affect } = this.state;
    const { onDragEnd, taskStarting, dispatch, dragStop, synchro, taskOverring } = this.props;
    this.setState({
      enter: false,
    });
    if (
      taskStarting !== isOver &&
      _.has(synchro.tasks[taskOverring], 'content') &&
      affect.find((el: any) => el === synchro.tasks[taskOverring].content) === undefined
    ) {
      onDragEnd();
    }
    dragStop();
    dispatch(synchroAction.updateUndroppable([]));
  }

  removeDeviceError() {
    const { dispatch, task } = this.props;
    dispatch(synchroAction.removeDeviceError(task.id));
    clearInterval(this.timer);
    this.timer = null;
    window.removeEventListener('beforeunload', this.removeDeviceError);
  }

  render() {
    const { deathLabel, affect, displayInfo, isAffect, enter, checked, isLastChecked, taskName } = this.state;
    const {
      task,
      multiSelect,
      noDrop,
      multiDisplay,
      checkedIsDisabled,
      synchrocom,
      columnDisabled,
      onStart,
      match,
      fctBorder,
      affectList,
      taskStarting,
      column,
      switchToPartial,
      partiel,
      synchro,
      t,
    }: any = this.props;
    const { params } = match;
    const versionInfo = {
      svg: <SvgSaphir fill="#CE7830" height="2em" />,
      tooltip: t('all.device.device_own_last_version'),
    };
    const loading = synchrocom && synchrocom.currentState && synchrocom.currentState[task.id];
    const roundLoading =
      (synchrocom && synchrocom.rounds && synchrocom.rounds[task.id]) ||
      (synchro && synchro.rotationLoading && synchro.rotationLoading[task.rndCode]);
    if (columnDisabled === 'column-2' && task && synchrocom.version && synchrocom.version[task.id]) {
      if (this.versionCheck(synchrocom.version[task.id], 'current') === -1) {
        versionInfo.svg = <SvgSaphirWarning fill="#CE7830" height="2em" />;
        versionInfo.tooltip = t('all.device.device_not_own_last_version');
      }

      if (this.versionCheck(synchrocom.version[task.id], 'minimum') === -1) {
        versionInfo.svg = <SvgSaphirWarning fill="#F24141" height="2em" />;
        versionInfo.tooltip = t('all.device.device_with_obsolete_version');
      }
    }
    let displayAllInfo = false;
    if (!noDrop && onStart) {
      displayAllInfo = true;
    }
    return (
      <div style={{ zIndex: 2 }}>
        <FormGroup check style={{ paddingLeft: '0', marginBottom: '2%' }}>
          {this.props.columnDisabled === 'column-1' && (
            <div
              className="synchro-column"
              style={{ display: 'flex' }}
              id={task.id}
              onDragLeave={this.dragLeave}
              onDragEnter={this.dragEnter}
              onDrop={this.drop}
            >
              {multiSelect && !checkedIsDisabled && (
                <div
                  style={{
                    marginRight: '5px',
                    marginTop: '10px',
                  }}
                >
                  <CheckBox
                    isChecked={multiDisplay.find((el: any) => el.id === task.id)}
                    handleChange={this.multiDrag}
                  />
                </div>
              )}
              <div
                className="display-info"
                style={{ backgroundColor: 'transparent', cursor: 'grab', zIndex: 10000 }}
                id={task.id}
                draggable={!multiSelect || isLastChecked}
                onDragStart={this.dragStartBis}
                onDrop={this.drop}
                onDragOver={this.dragOver}
                onDragEnd={this.onDragEnd}
              >
                <div
                  className={isLastChecked && multiDisplay.length > 1 ? 'infoLibelle-drag multi' : 'infoLibelle-drag'}
                  onDoubleClick={() => fctBorder(task)}
                  style={{
                    borderRadius: '10px',
                    backgroundColor:
                      (checked && !isLastChecked) ||
                      (!!multiDisplay.find((el: any) => el.id === task.id) && !isLastChecked)
                        ? 'lightgrey'
                        : noDrop && taskStarting
                        ? 'white'
                        : Array.isArray(synchro.devicesError) &&
                          synchro.devicesError
                            .map((el: { first: string; second: string }) => el.second)
                            .includes(task.id)
                        ? '#dc3545'
                        : roundLoading
                        ? 'grey'
                        : isAffect
                        ? 'lightgrey'
                        : !isAffect && enter
                        ? 'lightgreen'
                        : affectList.find((el: any) => el === task.content)
                        ? 'lightgreen'
                        : 'white',
                    pointerEvents: onStart ? 'none' : 'all',
                    opacity: isAffect ? '0.5' : '1',
                  }}
                >
                  <div className="task-content-wrapper">
                    <div className="synchro-task-header">
                      <div className="draggable-content-wrapper" style={{ padding: '0 5px' }}>
                        <div className="drag-title-clone" style={{ display: 'flex' }}>
                          <DragIcone />
                          <h3 style={{ maxWidth: '60%', minWidth: 'fit-content' }}>{taskName}</h3>
                        </div>
                        <div
                          style={{
                            marginTop: '5px',
                            color: 'black',
                            fontSize: '14px',
                            paddingLeft: '5px',
                          }}
                        >
                          <b>
                            <i style={{ paddingLeft: '0px' }}>{this.props.task.rndCode}</i>
                          </b>
                        </div>
                        {Array.isArray(synchro.devicesError) &&
                        synchro.devicesError
                          .map((el: { first: string; second: string }) => el.second)
                          .includes(task.id) ? (
                          <div
                            className="svg"
                            onClick={this.removeDeviceError}
                            style={{
                              height: '30px',
                              width: '30px',
                              marginRight: '10px',
                              marginLeft: 'auto',
                              cursor: 'pointer',
                            }}
                          >
                            <SvgCroixNoir onClick={this.removeDeviceError} fill="white" />
                          </div>
                        ) : roundLoading ? (
                          <Spinner
                            animation="grow"
                            size="2em"
                            role="status"
                            aria-hidden="true"
                            style={{ position: 'absolute', right: '10px' }}
                          />
                        ) : (
                          <TaskButtons
                            task={task}
                            loading={loading}
                            params={params}
                            onClickSynchro={this.onClickSynchro}
                            onClickRotate={this.onClickRotate}
                            isRound
                          />
                        )}
                      </div>
                    </div>

                    <div className="task-content-drag">
                      {Array.isArray(synchro.devicesError) &&
                      synchro.devicesError
                        .map((el: { first: string; second: string }) => el.second)
                        .includes(task.id) ? (
                        <div
                          style={{
                            color: 'white',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <SvgAttentionJaune fill="white" />
                          <h4 style={{ maxWidth: '60%', minWidth: 'fit-content', textAlign: 'center' }}>
                            {t(
                              `device_info.error_text.${
                                synchro.devicesError.find(
                                  (el: { first: string; second: string }) => el.second === task.id
                                ).first
                              }`
                            )}
                            <br />
                            {t(`device_info.error_text.contact`)}
                          </h4>
                        </div>
                      ) : (
                        !roundLoading && (
                          <RoundInfo
                            task={task}
                            displayInfo={displayInfo}
                            displayAllInfo={displayAllInfo}
                            deathLabel={deathLabel}
                            cancelOne={this.cancelOne}
                            getAllCommunication={this.getAllCommunication}
                            delLabel={this.onClickAffecte}
                            affects={affect}
                            desaffect={this.onClickDesaffecte}
                            switchToPartial={switchToPartial}
                            partiel={partiel}
                            column={column}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.props.columnDisabled === 'column-2' && (
            <div
              className="synchro-column"
              style={{ display: 'flex' }}
              id={task.id}
              onDrop={this.drop}
              onDragLeave={this.dragLeave}
              onDragEnter={this.dragEnter}
            >
              {multiSelect && !checkedIsDisabled && (
                <div
                  style={{
                    marginRight: '5px',
                    marginTop: '10px',
                  }}
                >
                  <CheckBox
                    isChecked={!!multiDisplay.find((el: any) => el.id === task.id)}
                    handleChange={this.multiDrag}
                  />
                </div>
              )}
              <div
                className="display-info"
                style={{ backgroundColor: 'transparent', cursor: 'grab', zIndex: 10000 }}
                id={task.id}
                draggable={!multiSelect || isLastChecked}
                onDragStart={this.dragStartBis}
                onDrop={this.drop}
                onDragOver={this.dragOver}
                onDragEnd={this.onDragEnd}
              >
                <div
                  className={isLastChecked && multiDisplay.length > 1 ? 'infoLibelle-drag multi' : 'infoLibelle-drag'}
                  onDoubleClick={() => fctBorder(task)}
                  style={{
                    borderRadius: '10px',
                    backgroundColor:
                      (checked && !isLastChecked) ||
                      (!!multiDisplay.find((el: any) => el.id === task.id) && !isLastChecked)
                        ? 'lightgrey'
                        : noDrop && taskStarting
                        ? 'white'
                        : Array.isArray(synchro.devicesError) &&
                          synchro.devicesError
                            .map((el: { first: string; second: string }) => el.second)
                            .includes(task.id)
                        ? '#dc3545'
                        : loading
                        ? 'grey'
                        : isAffect && !multiSelect
                        ? 'lightgrey'
                        : (!isAffect || multiSelect) && enter
                        ? 'lightgreen'
                        : affectList.find((el: any) => el === task.content)
                        ? 'lightgreen'
                        : 'white',
                    pointerEvents: onStart ? 'none' : 'all',
                    opacity: isAffect && !multiSelect ? '0.5' : '1',
                  }}
                >
                  <div className="task-content-wrapper">
                    <div className="synchro-task-header">
                      <div className="draggable-content-wrapper" style={{ padding: '0 10px' }}>
                        <DragIcone />
                        <h3
                          style={{
                            maxWidth: '60%',
                            textOverflow: 'ellispsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {taskName}
                        </h3>
                        <div style={{ marginTop: '5px', color: 'black', fontSize: '14px', marginLeft: '5%' }}>
                          <b>
                            <i>{this.props.task.serial}</i>
                          </b>
                        </div>
                        {Array.isArray(synchro.devicesError) &&
                        synchro.devicesError
                          .map((el: { first: string; second: string }) => el.second)
                          .includes(task.id) ? (
                          <div
                            className="svg"
                            onClick={this.removeDeviceError}
                            style={{
                              height: '30px',
                              width: '30px',
                              marginRight: '10px',
                              marginLeft: 'auto',
                              cursor: 'pointer',
                            }}
                          >
                            <SvgCroixNoir onClick={this.removeDeviceError} fill="white" />
                          </div>
                        ) : loading ? (
                          <Spinner
                            animation="grow"
                            size="2em"
                            role="status"
                            aria-hidden="true"
                            style={{ position: 'absolute', right: '10px' }}
                          />
                        ) : (
                          <TaskButtons
                            task={task}
                            loading={loading}
                            params={params}
                            onClickSynchro={this.onClickSynchro}
                          />
                        )}
                      </div>
                    </div>
                    <div className="task-content-drag">
                      {Array.isArray(synchro.devicesError) &&
                      synchro.devicesError
                        .map((el: { first: string; second: string }) => el.second)
                        .includes(task.id) ? (
                        <div
                          style={{
                            color: 'white',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <SvgAttentionJaune fill="white" />
                          <h4 style={{ maxWidth: '60%', minWidth: 'fit-content', textAlign: 'center' }}>
                            {t(
                              `device_info.error_text.${
                                synchro.devicesError.find(
                                  (el: { first: string; second: string }) => el.second === task.id
                                ).first
                              }`
                            )}
                            <br />
                            {t(`device_info.error_text.contact`)}
                          </h4>
                        </div>
                      ) : loading ? (
                        <MessageCom message={synchrocom.currentCommand[task.id] || ''} />
                      ) : (
                        <div style={{ height: '100%' }}>
                          <DeviceInfo
                            task={task}
                            synchrocom={synchrocom}
                            versionInfo={versionInfo}
                            deathLabel={deathLabel}
                            cancelOne={this.cancelOne}
                            getAllCommunication={this.getAllCommunication}
                            delLabel={this.onClickAffecte}
                            affects={affect}
                            desaffect={this.onClickDesaffecte}
                            switchToPartial={switchToPartial}
                            partiel={partiel}
                            column={column}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </FormGroup>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { users, authentication, alert, locations, synchro, synchrocom } = state;
  const { user } = authentication;
  return {
    user,
    users,
    alert,
    locations,
    synchro,
    synchrocom,
  };
}

const mapping: any = connect(mapStateToProps)(Task);

const connectedTask = withRouter(mapping);
const tr = withTranslation()(connectedTask);
export default tr;
