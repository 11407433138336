import { meterConstants } from '../_constants';
import { meterService } from '../_services';

/**
 * Récupère tous les compteurs du site courant
 *
 * @method getAll
 * @param {number} siteId Id du site
 * @returns {Object} Les compteurs
 */
function getAll(siteId: number) {
  function request() {
    return { type: meterConstants.GETALL_REQUEST };
  }
  function success(meters: Object) {
    return { type: meterConstants.GETALL_SUCCESS, meters };
  }
  function failure(error: Object) {
    return { type: meterConstants.GETALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    meterService
      .getAll(siteId)
      .then((meters: Object) => dispatch(success(meters)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère tous les compteurs du site courant
 *
 * @method getAll
 * @param {number} siteId Id du site
 * @returns {Object} Les compteurs
 */
function getAllFromLocation(locationId: number) {
  function request() {
    return { type: meterConstants.GETALLFROMLOCATION_REQUEST };
  }
  function success(meters: Object) {
    return { type: meterConstants.GETALLFROMLOCATION_SUCCESS, locationId, meters };
  }
  function failure(error: Object) {
    return { type: meterConstants.GETALLFROMLOCATION_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    meterService
      .getAllFromLocation(locationId)
      .then((meters: Object) => dispatch(success(meters)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère tous les compteurs du site courant
 *
 * @method getAll
 * @param {number} siteId Id du site
 * @returns {Object} Les compteurs
 */
function getAllWithChildren(locationId: number) {
  function request() {
    return { type: meterConstants.GETALLWITHCHILDREN_REQUEST };
  }
  function success(meters: Object) {
    return { type: meterConstants.GETALLWITHCHILDREN_SUCCESS, meters };
  }
  function failure(error: Object) {
    return { type: meterConstants.GETALLWITHCHILDREN_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    meterService
      .getAllWithChildren(locationId)
      .then((meters: Object) => dispatch(success(meters)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère tous les compteurs du site courant
 *
 * @method getAll
 * @param {number} siteId Id du site
 * @returns {Object} Les compteurs
 */
function getUnlinkedMeters() {
  function request() {
    return { type: meterConstants.GETUNLINKEDMETERS_REQUEST };
  }
  function success(unlinkedMeters: Object) {
    return { type: meterConstants.GETUNLINKEDMETERS_SUCCESS, unlinkedMeters };
  }
  function failure(error: Object) {
    return { type: meterConstants.GETUNLINKEDMETERS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    meterService
      .getUnlinkedMeters()
      .then((unlinkedMeters: Object) => dispatch(success(unlinkedMeters)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère tous les compteurs du site courant
 *
 * @method createMeterFromSerial
 * @param {number} siteId Id du site
 * @returns {Object} Les compteurs
 */
function createMeterFromSerial(meter: any) {
  function request() {
    return { type: meterConstants.CREATEMETER_REQUEST };
  }
  function success(createdMeter: Object) {
    return { type: meterConstants.CREATEMETER_SUCCESS, createdMeter };
  }
  function failure(error: Object) {
    return { type: meterConstants.CREATEMETER_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    meterService
      .createMeterFromSerial(meter)
      .then((createdMeter: Object) => dispatch(success(createdMeter)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère tous les compteurs du site courant
 *
 * @method findInfosWithSerial
 * @param {number} siteId Id du site
 * @returns {Object} Les compteurs
 */
function findInfosWithSerial(serial: string) {
  function request() {
    return { type: meterConstants.FINDINFOWITHSERIAL_REQUEST };
  }
  function success(serialInfo: Object) {
    return { type: meterConstants.FINDINFOWITHSERIAL_SUCCESS, serialInfo };
  }
  function failure(error: Object) {
    return { type: meterConstants.FINDINFOWITHSERIAL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    meterService
      .findInfosWithSerial(serial)
      .then((serialInfo: Object) => dispatch(success(serialInfo)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère tous les compteurs du site courant
 *
 * @method getAll
 * @param {number} siteId Id du site
 * @returns {Object} Les compteurs
 */
function getManufacturers() {
  function request() {
    return { type: meterConstants.GETMANUFACTURERS_REQUEST };
  }
  function success(manufacturers: Object) {
    return { type: meterConstants.GETMANUFACTURERS_SUCCESS, manufacturers };
  }
  function failure(error: Object) {
    return { type: meterConstants.GETMANUFACTURERS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    meterService
      .getManufacturers()
      .then((manufacturers: Object) => dispatch(success(manufacturers)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère les infos du compteur, ainsi que les lectures, conso et alarmes
 *
 * @method getInfos
 * @param {string} id Id du compteur
 * @param {number} siteId Id du site
 * @param {any} contentReads Lectures
 * @param {any} contentConso Consommations
 * @param {any} contentAlarms Alarmes
 * @returns {Object} Toutes les infos du compteur
 */
function getInfos(id: string, siteId: number, contentReads: any, contentConso: any, contentAlarms: any) {
  const dataReads = contentReads;
  const dataAlarms = contentAlarms;
  const dataConso = contentConso;
  let meter: any;
  function request() {
    return { type: meterConstants.GETINFO_REQUEST };
  }
  function success(fetchedMeter: Object) {
    return { type: meterConstants.GETINFO_SUCCESS, fetchedMeter };
  }
  function failure(error: Object) {
    return { type: meterConstants.GETINFO_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    meterService
      .getInfos(siteId, id)
      .then((fetchedMeter: Object) => {
        meter = fetchedMeter;
        if (meter.general) {
          dataReads.dataSourceProperty.displayProperty.condition[0].conditionValue = meter.general.serial;
          dataConso.dataSourceProperty.displayProperty.condition[0].conditionValue = meter.general.serial;
          dataAlarms.dataSourceProperty.displayProperty.condition[0].conditionValue = meter.general.serial;
        }
        return Promise.all([
          meterService.getReads(siteId, dataReads),
          meterService.getConsumptions(siteId, dataConso),
          meterService.getAlarms(siteId, dataAlarms),
        ]);
      })
      .then((data: any) => {
        meter.statements = data[0];
        meter.consumption = data[1];
        meter.alarms = data[2];
        dispatch(success(meter));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère toutes les lectures du compteur passé en
 * paramètre
 *
 * @method getReads
 * @param {number} siteId Id du site
 * @param {Object} content Conditions
 * @param {any} currentMeter Compteur
 * @returns {Object} Lectures
 */
function getReads(siteId: number, content: Object, currentMeter: any) {
  const meter = currentMeter;
  function success(fetchedMeter: Object) {
    return { type: meterConstants.GETSTATES_SUCCESS, fetchedMeter };
  }
  function failure(error: Object) {
    return { type: meterConstants.GETSTATES_FAILURE, error };
  }
  return (dispatch: Function) => {
    meterService
      .getReads(siteId, content)
      .then((statements: any) => {
        meter.statements = statements;
        dispatch(success(meter));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère toutes les consommations du compteur passé en
 * paramètre
 *
 * @method getConsumptions
 * @param {number} siteId Id du site
 * @param {Object} content Conditions
 * @param {any} currentMeter Compteur
 * @returns {Object} Consommations
 */
function getConsumptions(siteId: number, content: Object, currentMeter: any) {
  const meter = currentMeter;
  function success(fetchedMeter: Object) {
    return { type: meterConstants.GETSTATES_SUCCESS, fetchedMeter };
  }
  function failure(error: Object) {
    return { type: meterConstants.GETSTATES_FAILURE, error };
  }
  return (dispatch: Function) => {
    meterService
      .getConsumptions(siteId, content)
      .then((statements: any) => {
        meter.consumption = statements;
        dispatch(success(meter));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère toutes les alarmes du compteur passé en
 * paramètre
 *
 * @method getAlarms
 * @param {number} siteId Id du site
 * @param {Object} content Conditions
 * @param {any} currentMeter Compteur
 * @returns {Object} Alarmes
 */
function getAlarms(siteId: number, content: Object, currentMeter: any) {
  const meter = currentMeter;
  function success(fetchedMeter: Object) {
    return { type: meterConstants.GETALARMS_SUCCESS, fetchedMeter };
  }
  function failure(error: Object) {
    return { type: meterConstants.GETALARMS_FAILURE, error };
  }
  return (dispatch: Function) => {
    meterService
      .getAlarms(siteId, content)
      .then((alarms: any) => {
        meter.alarms = alarms;
        dispatch(success(meter));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère toutes les lectures, consos et alarmes du compteur
 * passé en paramètre
 *
 * @method getStatementsAndAlarms
 * @param {number} siteId Id du site
 * @param {any} contentReads Conditions des lectures
 * @param {any} contentConso Conditions des consos
 * @param {any} contentAlarms Conditions des alarmes
 * @param {any} currentMeter Compteur
 * @returns {Object} Lectures, consommations et alarmes
 */
function getStatementsAndAlarms(
  siteId: number,
  contentReads: any,
  contentConso: any,
  contentAlarms: any,
  currentMeter: any
) {
  const meter = currentMeter;

  function request() {
    return { type: meterConstants.GETDATA_REQUEST };
  }
  function success(fetchedMeter: Object) {
    return { type: meterConstants.GETDATA_SUCCESS, fetchedMeter };
  }
  function failure(error: Object) {
    return { type: meterConstants.GETDATA_FAILURE, error };
  }

  return (dispatch: Function) => {
    contentReads.dataSourceProperty.displayProperty.condition[0].conditionValue = meter.general.serial;
    contentConso.dataSourceProperty.displayProperty.condition[0].conditionValue = meter.general.serial;
    contentAlarms.dataSourceProperty.displayProperty.condition[0].conditionValue = meter.general.serial;

    dispatch(request());

    Promise.all([
      meterService.getReads(siteId, contentReads),
      meterService.getConsumptions(siteId, contentConso),
      meterService.getAlarms(siteId, contentAlarms),
    ])
      .then((data: any) => {
        meter.statements = data[0];
        meter.consumption = data[1];
        meter.alarms = data[2];
        dispatch(success(meter));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getAllMetersFromPdi(pdiId: number, rndCode: string, content: any) {
  function request() {
    return { type: meterConstants.GET_ALLFROMPDI_REQUEST };
  }
  function tooLongReceive() {
    return { type: meterConstants.GET_ALLFROMPDI_TOOLONG };
  }
  function success(meterList: Object) {
    return { type: meterConstants.GET_ALLFROMPDI_SUCCESS, meterList };
  }
  function failure(error: Object) {
    return { type: meterConstants.GET_ALLFROMPDI_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    setTimeout(function() {
      dispatch(tooLongReceive());
    }, 2000);
    meterService
      .getAllMetersFromPdi(pdiId, rndCode, content)
      .then((meters: Object) => dispatch(success(meters)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getInfosData(id: any, rndId: number, interval: { dateMin: string; zoom: string; dataMax: string }) {
  function request() {
    return { type: meterConstants.GET_METER_INFOS_DATA_REQUEST };
  }
  function success(meter: Object) {
    return { type: meterConstants.GET_METER_INFOS_DATA_SUCCESS, meter };
  }
  function failure(error: Object) {
    return { type: meterConstants.GET_METER_INFOS_DATA_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    meterService
      .getInfosData(id, rndId, interval)
      .then((meter: Object) => dispatch(success(meter)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Vide le state des compteurs
 *
 * @method clear
 */
function clear() {
  return {
    type: meterConstants.CLEAR,
  };
}

const meterActions = {
  getAll,
  getAllWithChildren,
  getManufacturers,
  getUnlinkedMeters,
  findInfosWithSerial,
  createMeterFromSerial,
  getInfos,
  getReads,
  getConsumptions,
  getAlarms,
  getStatementsAndAlarms,
  getAllMetersFromPdi,
  clear,
  getInfosData,
  getAllFromLocation,
};

export default meterActions;
