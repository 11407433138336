import React from 'react';

const SvgFavorisVert = (props: any) => (
  <svg viewBox="0 0 27.65 26.4" {...props}>
    <path
      d="M27.61 10a.74.74 0 0 0-.61-.54l-8.63-1.23-3.88-7.8a.77.77 0 0 0-1.38 0L9.28 8.26.66 9.54a.76.76 0 0 0-.42 1.31l6.26 6.06-1.45 8.6a.74.74 0 0 0 .31.74.71.71 0 0 0 .44.15.74.74 0 0 0 .36-.09l7.7-4.08 7.73 4a.85.85 0 0 0 .35.08.77.77 0 0 0 .77-.76.52.52 0 0 0 0-.18l-1.49-8.54 6.22-6.1a.74.74 0 0 0 .2-.79zm-7.77 6.08a.77.77 0 0 0-.22.68l1.3 7.45-6.71-3.5a.75.75 0 0 0-.71 0l-6.69 3.52 1.26-7.46a.78.78 0 0 0-.22-.68l-5.43-5.27 7.49-1.11a.75.75 0 0 0 .57-.42l3.33-6.79 3.37 6.77a.77.77 0 0 0 .58.42l7.49 1.07z"
      fill={props.fill}
      data-name="Calque 2"
    />
  </svg>
);

export default SvgFavorisVert;
