import moment from 'moment';
import React from 'react';
import _ from 'lodash';
import { textFilter } from 'react-bootstrap-table2-filter';
import i18next from 'i18next';
import { storedColumnsService, storedFilterService } from '../_services';
import Picto from '../TourneeFiches/Picto_correspondance';
import getC from '../TourneeFiches/Picto_correspondance';
import StaticList from '../TourneeFiches/StaticList';
import SvgGeolocVert from '../SvgComponents/GeolocVert';
import ChangementCompteur from '../SvgComponents/ChangementCompteur';
import AjoutBleu from '../SvgComponents/AjoutBleu';
import LinkBleu from '../SvgComponents/LinkBleu';
import UnlinkBleu from '../SvgComponents/UnlinkBleu';

export default class ListTools {
  static filterURL: any = {
    nogeolocalisation: [
      {
        filter: {
          label: 'Longitude',
          type: 'number',
          value: 'gpsPosition.lng',
        },
        num: 0,
        operator: {
          value: '== null',
        },
        userInput: '',
      },
      {
        filter: {
          label: 'Latitude',
          type: 'number',
          value: 'gpsPosition.lat',
        },
        num: 0,
        operator: {
          value: '== null',
        },
        userInput: '',
      },
    ],
    geolocalisation: [
      {
        filter: {
          label: 'Longitude',
          type: 'number',
          value: 'gpsPosition.lng',
        },
        num: 0,
        operator: {
          value: '!= null',
        },
        userInput: '',
      },
      {
        filter: {
          label: 'Latitude',
          type: 'number',
          value: 'gpsPosition.lat',
        },
        num: 0,
        operator: {
          value: '!= null',
        },
        userInput: '',
      },
    ],
    indexe: [
      {
        filter: {
          label: 'Indexé',
          type: 'checkbox',
          value: 'indexed',
        },
        num: 0,
        operator: {
          value: 'True',
        },
        userInput: '',
      },
    ],
    noIndex: [
      {
        filter: {
          label: 'Indexé',
          type: 'checkbox',
          value: 'indexed',
        },
        num: 0,
        operator: {
          value: 'False',
        },
        userInput: '',
      },
    ],
    update: [
      {
        filter: {
          label: 'Modifié',
          type: 'checkbox',
          value: 'modified',
        },
        num: 0,
        operator: {
          value: 'True',
        },
        userInput: '',
      },
    ],
    noUpdate: [
      {
        filter: {
          label: 'Modifié',
          type: 'checkbox',
          value: 'modified',
        },
        num: 0,
        operator: {
          value: 'False',
        },
        userInput: '',
      },
    ],
    noIndexButUpdate: [
      {
        filter: {
          label: 'Modifié',
          type: 'checkbox',
          value: 'modified',
        },
        num: 0,
        operator: {
          value: 'True',
        },
        userInput: '',
      },
      {
        filter: {
          label: 'Indexé',
          type: 'checkbox',
          value: 'indexed',
        },
        num: 0,
        operator: {
          value: 'False',
        },
        userInput: '',
      },
    ],
    conso: [
      {
        filter: {
          label: 'Etat de la consommation',
          type: 'selectContValue',
          value: 'consoState',
          opts: StaticList.consoState,
        },
        num: 0,
        operator: {
          value: '=',
        },
        userInput: '',
      },
    ],
    noAmr: [
      {
        filter: {
          label: 'Numéro de série radio',
          type: 'text',
          value: 'radioSerial',
        },
        num: 0,
        operator: {
          value: 'vide',
          noEntry: true,
        },
        userInput: '',
      },
    ],
    withoutAlarm: [
      {
        filter: {
          label: 'Etat',
          type: 'selectCont',
          value: 'ficheState',
          opts: StaticList.etatFiche.map(el => {
            return {
              value: el.picto,
              label: (
                <div>
                  {getC(el.picto, { height: '1.5em', width: '1.5em' })}
                  {el.label}
                </div>
              ),
            };
          }),
        },
        num: 0,
        operator: {
          value: '=',
        },
        userInput: {
          value: 'blue_radio',
          label: (
            <div>
              {getC('blue_radio', { height: '1.5em', width: '1.5em' })}
              {StaticList.etatFiche.find(el => el.picto === 'blue_radio').label}
            </div>
          ),
        },
      },
    ],
    withAlarm: [
      {
        filter: {
          label: 'Etat',
          type: 'selectCont',
          value: 'ficheState',
          opts: StaticList.etatFiche.map(el => {
            return {
              value: el.picto,
              label: (
                <div>
                  {getC(el.picto, { height: '1.5em', width: '1.5em' })}
                  {el.label}
                </div>
              ),
            };
          }),
        },
        num: 0,
        operator: {
          value: '=',
        },
        userInput: {
          value: 'purple_radio',
          label: (
            <div>
              {getC('purple_radio', { height: '1.5em', width: '1.5em' })}
              {StaticList.etatFiche.find(el => el.picto === 'purple_radio').label}
            </div>
          ),
        },
      },
    ],
    noReadAmr: [
      {
        filter: {
          label: 'Numéro de série radio',
          type: 'text',
          value: 'radioSerial',
        },
        num: 0,
        operator: {
          value: 'notVide',
        },
        userInput: '',
      },
      {
        filter: {
          label: 'Indexé',
          type: 'checkbox',
          value: 'indexed',
        },
        num: 0,
        operator: {
          value: 'False',
        },
        userInput: '',
      },
    ],
    annee: [
      {
        filter: {
          label: 'Année installation',
          type: 'number',
          value: 'installDate',
        },
        num: 0,
        operator: {
          value: '=',
        },
        userInput: '',
      },
    ],
    radioType: [
      {
        filter: {
          label: 'Constructeur radio',
          type: 'text',
          value: 'radioManufacturer',
        },
        num: 0,
        operator: {
          value: 'contient',
        },
        userInput: '',
      },
    ],
  };

  static specificType = {
    bool: 'checkbox',
    date: 'date',
    selectCont: 'selectCont',
    selectAlarmTypeBasic: 'selectAlarmTypeBasic',
    selectContValue: 'selectContValue',
    number: 'number',
  };

  static specificFieldTypeRegex: { [fieldType: string]: Array<RegExp> } = {
    text: [/^lastUpdateByUser$/, /consumptionState/],
    checkbox: [
      /^splitAlarms/,
      /^state$/,
      /^with/,
      /^has/,
      /indexed/,
      /modified/,
      /oldMeterEndDate/,
      /simultaneousAlarms/,
      /seuilOverflow$/,
    ],
    number: [
      /consumption/,
      /index/i,
      /wheels/i,
      /offset/i,
      /longitude/i,
      /latitude/i,
      /altitude/i,
      /lng/i,
      /lat/i,
      /alt/i,
      /^installDate$/i,
    ],
    date: [/date/i, /^updated/, /^triggerAlarms/, /^closeAlarms/],
    selectAlarmTypeBasic: [/^alarms/],
    selectContValue: [/state/i, /fluidType/i, /readMethod/i],
  };

  static findSpecificType(fieldName: string): string {
    const found = _.entries(ListTools.specificFieldTypeRegex).find(([, value]) =>
      value.some(reg => reg.test(fieldName))
    );
    return (found && found[0]) || 'text';
  }

  // Différents type de liste (uilisés par ListInstance)
  static typeOfList: any = {
    MeterTelereleve: 'MTR_INFO_TELERELEVE',
    Meters: 'MTR_SEARCH_QUERY',
    Meters_search: 'MTR_SEARCH_PAGE',
    Fiche: 'FICHE_SEARCH_QUERY',
    LightFiche: 'LIGHT_FICHE_SEARCH_QUERY',
    VMeters: 'VMTR_SEARCH_QUERY',
    Radios: 'RDO_SEARCH_QUERY',
    Radios_search: 'RDO_SEARCH_PAGE',
    Pdi: 'PDI_SEARCH_QUERY',
    Pdi_search: 'PDI_SEARCH_PAGE',
    VPdi: 'VPDI_SEARCH_QUERY',
    Mask: 'MASK_SEARCH_QUERY',
    Link: 'LINK_SEARCH_QUERY',
    PdiLight: 'PDILIGHT_SEARCH_QUERY',
    PdiExtract: 'PDIEXTRACT_SEARCH_QUERY',
    DataExtract: 'DATAEXTRACT_SEARCH_QUERY',
    ImportTelereleve: 'IMPORTTELERELEVE_SEARCH_QUERY',
    Alert: 'ALERT_SEARCH_QUERY',
    VMeters_ListMeter: 'VMETER_METERLIST_SEARCH_QUERY',
    ExportPDI: 'EXPORT_PDI_SEARCH_QUERY',
    CustomExport: 'CUSTOM_EXPORT_SEARCH_QUERY',
  };

  static skipFormatter = (cell: any) => cell;

  // Formattage de la colonne Alarme
  static formatterAlarme = (cell: any) =>
    cell === 1 || cell === true ? i18next.t('all.text.yes') : i18next.t('all.text.no');

  // Formattage de la colonne Etat du PDI
  static formatterState = (cell: number) => {
    if (cell !== null && isNaN(parseInt(`${cell}`, 10))) {
      return cell;
    }
    return cell === 1 ? i18next.t('all.text.active') : i18next.t('all.text.inactive');
  };

  static formatterMeterState = (cell: any) => {
    return cell === null ? i18next.t('all.text.mounted') : i18next.t('all.text.unmounted');
  };
  static formatterTypeAlarm = (cell: string) => {
    if (cell && !Array.isArray(cell)) {
      return (
        <div>
          {(cell || '')
            .split(',')
            .map(c => {
              return <div>{i18next.t(`alarm.type.${c.trim().toLowerCase()}`)}</div>;
            })
            .join(', \n')}
        </div>
      );
    }
    if (cell && Array.isArray(cell)) {
      return (
        <div>
          {cell.map(c => {
            return <div>{i18next.t(`alarm.type.${c.trim().toLowerCase()}`)}</div>;
          })}
        </div>
      );
    }
  };

  static formatInformationsClpMtr = (info: string) => {
    return info ? (
      <div>
        {_.range(0, _.min([150, info.length]), 30).map(i => {
          return (
            info.substr(_.max([0, i - 1]), 30).trim().length > 0 && <div>{info.substr(_.max([0, i - 1]), 30)}</div>
          );
        })}
      </div>
    ) : null;
  };

  static formatterIndex = (index: number, row: any) => {
    const pulseWeight = _.get(row, 'meter.pulseWeight') || _.get(row, 'pulseWeight');
    if (pulseWeight === null || pulseWeight === undefined) return index;
    const nbDecimals = pulseWeight.toString().split('.').length > 1 ? pulseWeight.toString().split('.')[1].length : 0;
    return _.isNumber(index) ? index.toFixed(nbDecimals) : '';
  };

  static formatBoolean = (value: boolean) => (value ? i18next.t('all.text.yes') : i18next.t('all.text.no'));

  static formatSerialWithAction = (value: string, row: any) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{value}</span>
        {row.isSelected && row.linked ? (
          <LinkBleu width="1.5em" />
        ) : row.isSelected ? (
          <AjoutBleu width="1.5em" />
        ) : (
          <UnlinkBleu width="1.5em" />
        )}
      </div>
    );
  };

  static formatReverseBoolean = (value: boolean) => (!value ? i18next.t('all.text.yes') : i18next.t('all.text.no'));

  static formatterIndexLiterToM3 = (index: number, row: any) => {
    return ListTools.formatterIndex(index / 1000, row);
  };

  static formatDate = (cell: string) =>
    moment(cell).isValid()
      ? moment.utc(cell).format(`${i18next.t('all.date_format.date')} ${moment.HTML5_FMT.TIME_SECONDS}`)
      : '';

  static formatDateWithMinutes = (cell: string) =>
    cell != undefined && moment(cell).isValid()
      ? moment.utc(cell).format(`${i18next.t('all.date_format.date')} ${moment.HTML5_FMT.TIME}`)
      : '';

  static formatYearDate = (cell: string) => (moment(cell).isValid() ? parseInt(moment(cell).format(`YYYY`)) : null);

  static formatFicheState = (cell: string) =>
    Picto(cell, {
      style: { display: 'block', margin: 'auto' },
      height: '2.2em',
      width: '2.2em',
    });

  static formatFluidType = (cell: string) => i18next.t(`meter.type.${cell}`);

  static formatReadMethod = (cell: string) => (cell ? i18next.t(`all.read_meter.type.${cell}`) : '');

  static formatDateDay = (cell: string) =>
    moment(cell).isValid() ? moment.utc(cell).format(i18next.t('all.date_format.date')) : '';

  static formatChangementCptDate = (cell: string) => (moment(cell).isValid() ? <ChangementCompteur width="2em" /> : '');

  static formatConsoState = (cell: string) => cell && _.capitalize(cell.replace('Conso ', ''));

  static formatUnknownManufacturer = (cell: string) =>
    cell && cell === 'UNKNOWN' ? i18next.t('all.text.unknown') : cell;

  static formatBooleanToImage = (cell: string) => (
    <div style={{ textAlign: 'center', fontSize: '1.2em' }}>
      {cell ? <span style={{ color: 'green' }}>{'\u2714'}</span> : '\u274c'}
    </div>
  );

  static formatGeoloc = (cell: boolean) => (
    <SvgGeolocVert
      style={{ display: 'block', margin: 'auto' }}
      width="2em"
      height="2em"
      fill={cell ? '#71d70b' : '#ec2020'}
    />
  );

  static formatterByRegexp: { [formatter: string]: Array<RegExp> } = {
    formatterAlarme: [/^with/],
    formatterMeterState: [/^meter.endDate$/],
    formatterState: [/(?!consumptionState)state/],
    formatterTypeAlarm: [/^alarms/],
    formatDateDay: [/installDateNewMeter/, /startDate/],
    formatDateWithMinutes: [/(current|previous)Date/, /^triggerAlarms/, /^closeAlarms/],
    formatChangementCptDate: [/oldMeterEndDate/],
    formatDate: [/^(?!(install)).*date(?!(by))/i, /updatedAt/],
    formatBoolean: [/t1CurrentExist/, /^splitAlarms/, /simultaneousAlarms/, /seuilOverflow$/],
    skipFormatter: [/radioIndex/i],
    formatterIndexLiterToM3: [/^meterIndex$/],
    formatterIndex: [/^(?!current|previous).*(?!radio)index$/i, /(?!consumptionState)consumption/i],
    formatFicheState: [/ficheState/],
    formatInformationsClpMtr: [/Informations/],
    formatGeoloc: [/hasGpsPosition/],
    formatConsoState: [/ConsoState/],
    formatUnknownManufacturer: [/cptManufacturer/],
    formatReverseBoolean: [/withCreation/],
    formatFluidType: [/fluid/],
    formatReadMethod: [/readMethod/],
    formatBooleanToImage: [/^canProvision$/i],
  };

  static findFormatter(fieldName: string): string | null {
    const found = _.entries(ListTools.formatterByRegexp).find(([, value]) => value.some(reg => reg.test(fieldName)));
    return (found && found[0] && _.get(ListTools, found && found[0])) || null;
  }

  /**
   * Trie les dates dans un tableau (gère les valeurs vides en leur
   * donnant le timestamp 0)
   *
   * @static
   * @method sortTableDates
   * @param {any} a date A
   * @param {any} b date B
   * @param {string} order Ordre de tri
   * @param {any} dataField Champ
   * @param {any} rowA Ligne A
   * @param {any} rowB Ligne B
   */
  static sortTableDates = (a: any, b: any, order: string, dataField: any, rowA: any, rowB: any) => {
    const baseDate = '01/01/1970 00:00:00';
    if (undefined === a || a === null || a.length === 0) {
      a = baseDate;
    }
    if (undefined === b || b === null || b.length === 0) {
      b = baseDate;
    }
    if (order === 'desc') {
      return moment(b, `DD/MM/YYYY ${moment.HTML5_FMT.TIME_SECONDS}`).diff(
        moment(a, `DD/MM/YYYY ${moment.HTML5_FMT.TIME_SECONDS}`)
      );
    }
    return moment(a, `DD/MM/YYYY ${moment.HTML5_FMT.TIME_SECONDS}`).diff(
      moment(b, `DD/MM/YYYY ${moment.HTML5_FMT.TIME_SECONDS}`)
    );
  };

  /**
   * Met à jour les templates de relèves et alarmes,
   * puis renvoie le tout au composant parent
   *
   * @static
   * @method sortDates
   * @memberof IntervalSelectorComponent
   * @param {string} a Date sous forme de string
   * @param {string} b Date sous forme de string
   * @param {'asc'|'desc'} order Ordonnancement
   */
  static sortDates = (a: string, b: string, order: any) => {
    if (order === 'asc') {
      if (moment(a).isBefore(moment(b))) {
        return -1;
      }
      if (moment(a).isSame(moment(b))) {
        return 0;
      }
      return 1;
    }
    if (order === 'desc') {
      if (moment(a).isBefore(moment(b))) {
        return 1;
      }
      if (moment(a).isSame(moment(b))) {
        return 0;
      }
      return -1;
    }
    return 0;
  };

  static getEmptyColumns = (type: string) => {
    let localeType = '';
    switch (type) {
      case ListTools.typeOfList.VMeters:
        localeType = 'virtualMeter';
        break;
      case ListTools.typeOfList.Radios:
      case ListTools.typeOfList.Meters:
      case ListTools.typeOfList.Pdi:
        localeType = 'pdi';
        break;
      case ListTools.typeOfList.DataExtract:
        localeType = 'extract';
      case ListTools.typeOfList.VMeters_ListMeter:
        localeType = 'pdi.meter';
        break;
    }
    return ListTools.getDefaultColumns(type).map((col: any) => {
      return {
        dataField: col,
        text: i18next.t(`columns.${col}`),
      };
    });
  };

  static withFilter = (type: string, placeholder: string) => {
    let localeType = '';
    switch (type) {
      case ListTools.typeOfList.VMeters:
        localeType = 'virtualMeter';
        break;
      case ListTools.typeOfList.Meters:
        localeType = 'meter';
        break;
      case ListTools.typeOfList.Radios:
        localeType = 'radio';
        break;
      case ListTools.typeOfList.Pdi:
        localeType = 'pdi';
        break;
    }
    return textFilter({ placeholder });
  };

  /**
   * Selon le type, récupère une liste des colonnes à afficher par défaut
   *
   * @static
   * @method getDefaultColumns
   * @memberof List
   * @param {string} type Type de liste (peut prendre les valeurs de typeOfList)
   */
  static getDefaultColumns = (type: string) => {
    switch (type) {
      case ListTools.typeOfList.Meters:
        return ['meter.serial', 'meter.manufacturer', 'meter.model'];
      case ListTools.typeOfList.VMeters_ListMeter:
        return ['serial', 'manufacturer', 'model'];
      case ListTools.typeOfList.Meters_search:
        return ['location.name', 'serial', 'manufacturer', 'model'];
      case ListTools.typeOfList.ImportTelereleve:
        return ['meterSerialNumber', 'radioSerialNumber', 'rdsDate', 'meterIndex', 'withCreation'];
      case ListTools.typeOfList.Fiche:
        return [
          'ficheState',
          'pdi.cardOrder',
          'meter.currentSerial',
          'meter.installDate',
          'meter.diameter',
          'meter.manufacturer',
          'radio.serial',
          'radio.manufacturer',
        ];
      case ListTools.typeOfList.LightFiche:
        return [
          'ficheState',
          'cardOrder',
          'clpInformations',
          'marquage',
          'cptSerial',
          'revisedCptSerial',
          'installDate',
          'wheels',
          'cptManufacturer',
          'mtrInformations',
          'message',
          'particularMessageMeter',
          'hasGpsPosition',
          'oldMeterEndDate',
          'newMeterSerial',
          'installDateNewMeter',
          'radioSerial',
          'radioManufacturer',
          'amrReference',
          'indexed',
          'modified',
          'withAlarm',
          'previousDate',
          'previousIndex',
          'currentDate',
          'currentIndex',
          'consumption',
          'consoState',
          'alarms',
          'updateDate',
          'lastUpdateByUser',
        ];
      case ListTools.typeOfList.VMeters:
        return ['name', 'updatedAt'];
      case ListTools.typeOfList.Radios:
        return ['radio.serial', 'radio.manufacturer', 'radio.type'];
      case ListTools.typeOfList.Radios_search:
        return ['location.name', 'manufacturer', 'serial', 'type'];
      case ListTools.typeOfList.Pdi:
        return ['reference', 'state', 'address.concatAdress', 'meter.serial', 'read.meterIndex'];
      case ListTools.typeOfList.Pdi_search:
        return [
          'location.name',
          'reference',
          'state',
          'meter.serial',
          'address.concatAdress',
          'read.meterIndex',
          'meter.endDate',
        ];
      case ListTools.typeOfList.PdiExtract:
        return [
          'reference',
          'meter.serial',
          'radio.serial',
          'address.street',
          'address.zipcode',
          'address.city',
          'read.date',
          'read.meterIndex',
        ];
      case ListTools.typeOfList.DataExtract:
        return ['radioSerial', 'readDate', 'compteurSerial', 'readCompteurIndex', 'consumption', 'alarms'];
      case ListTools.typeOfList.PdiLight:
        return ['reference', 'meter.serial'];
      case ListTools.typeOfList.VPdi:
        return [
          'reference',
          'customInformation.code_reseau',
          'customInformation.code_tournee',
          'address.concatAdress',
          'meter.serial',
          'meter.manufacturer',
          'meter.diameter',
          'radio.serial',
          'radio.manufacturer',
        ];
      case ListTools.typeOfList.MeterTelereleve:
        return [
          'clpReference',
          'meterSerial',
          'radioSerial',
          'meterModel',
          'readData.method',
          'readData.index',
          'readData.consumption',
          'readData.date',
          'readData.alarm',
          'address',
        ];
      case ListTools.typeOfList.Mask:
        return ['meter.serial', 'radio.serial', 'withAlarm', 'alarms'];
      case ListTools.typeOfList.Link:
        return [
          'reference',
          'state',
          'customInformation.code_tournee',
          'address.concatAdress',
          'meter.serial',
          'meter.manufacturer',
          'radio.serial',
          'radio.manufacturer',
        ];
      case ListTools.typeOfList.Alert:
        return [
          'reference',
          'address.concatAdress',
          'meter.serial',
          'meter.manufacturer',
          'meter.diameter',
          'radio.serial',
          'radio.manufacturer',
          'read.date',
          'read.meterIndex',
          'read.consumption',
        ];
      case 'MTR_serial':
        return ['meter.serial'];
      case ListTools.typeOfList.ExportPDI:
        return [
          'reference',
          'address.concatAdress',
          'meter.serial',
          'meter.manufacturer',
          'meter.diameter',
          'radio.serial',
          'radio.manufacturer',
          'read.date',
          'read.meterIndex',
          'read.consumption',
        ];
      case ListTools.typeOfList.CustomExport:
        return ['exportName', 'exportFilename', 'exportOwner.userName'];
      default:
        return [];
    }
  };

  /**
   * Gère les différents accès aux filtres du local storage
   *
   * @method manageLocalFilters
   * @param {number} locationId Id du site
   * @param {any} filters Filtres
   * @param {string} action Action
   * @param {string} type Type
   * @returns {Object} Les filtres locaux
   */
  static manageLocalFilters = (locationId: number, filters: any, action: string, type?: string): any => {
    if (action === 'clear') {
      return storedFilterService.clearFilters(locationId);
    }
    const allTypes = Object.keys(ListTools.typeOfList).map(it => ListTools.typeOfList[it]);
    const listType = allTypes.find(t => t === type);

    if (listType !== undefined && type) {
      switch (action) {
        case 'get':
          return storedFilterService.getFilters(locationId, type);
        case 'post':
          return storedFilterService.setFilters(locationId, filters, type);
        case 'delete':
          return storedFilterService.removeContextFilters(locationId, type);
      }
    }

    return { value: [] };
  };

  /**
   * Gère les différents accès aux colonnes du local storage
   *
   * @static
   * @method manageLocalColumns
   * @param {number} locationId Id du site
   * @param {any} columns Colonnes
   * @param {string} action Action
   * @returns {Object} Les colonnes locales
   */
  static manageLocalColumns = (locationId: number, columns: any, action: string): any => {
    switch (action) {
      case 'get':
        return storedColumnsService.getColumns(locationId);
      case 'post':
        return storedColumnsService.setColumns(locationId, columns);
      case 'delete':
        return storedColumnsService.removeContextColumns(locationId);
    }

    return { value: {} };
  };
}
