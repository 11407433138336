import React from 'react';

const SvgFlagEs = (props: any) => (
  <svg viewBox="0 0 512 512" {...props}>
    <path
      fill="#FFDA44"
      d="M0,256c0,31.3,5.6,61.3,15.9,89L256,367.3L496.1,345c10.3-27.7,15.9-57.7,15.9-89s-5.6-61.3-15.9-89L256,144.7
	L15.9,167C5.6,194.7,0,224.7,0,256z"
    />
    <g>
      <path fill="#D80027" d="M496.1,167C459.9,69.5,366.1,0,256,0S52.1,69.5,15.9,167H496.1z" />
      <path fill="#D80027" d="M15.9,345c36.2,97.5,130,167,240.1,167s203.9-69.5,240.1-167H15.9z" />
    </g>
  </svg>
);

export default SvgFlagEs;
