import React from 'react';

const SvgGoutteCluster3parts = (props: any) => (
  <svg viewBox="0 0 224.1 224.1" {...props}>
    <path
      fill="#FFF"
      d="M161.6,96c-3.4-10.6-10.1-19.6-18.9-26c-8.6-6.3-19.2-9.9-30.6-9.9c-11.4,0-22,3.7-30.6,9.9
	C72.7,76.3,66,85.4,62.5,96c-1.6,5.1-2.5,10.5-2.5,16.1c0,5.6,0.9,11,2.5,16.1c3.4,10.6,10.1,19.6,18.9,26
	c8.6,6.3,19.2,9.9,30.6,9.9s22-3.7,30.6-9.9c8.8-6.4,15.5-15.5,18.9-26c1.6-5.1,2.5-10.5,2.5-16.1C164.1,106.4,163.2,101,161.6,96z
	 M112,112L112,112L112,112L112,112L112,112z"
    />
    <path
      fill="none"
      d="M60,112C60,112,60,112,60,112c0,5.6,0.9,11,2.5,16.1c3.4,10.6,10.1,19.6,18.9,26c8.6,6.3,19.2,9.9,30.6,9.9
	c9.5,0,18.4-2.5,26-7L112,112L60,112z"
    />
    <path
      fill="none"
      d="M138.1,67c-7.7-4.4-16.5-7-26-7c-11.4,0-22,3.7-30.6,9.9C72.7,76.3,66,85.4,62.5,96C60.9,101,60,106.4,60,112
	l52.1,0L138.1,67z"
    />
    <path
      fill="none"
      d="M161.6,128.1c1.6-5.1,2.5-10.5,2.5-16.1c0-5.6-0.9-11-2.5-16.1c-3.4-10.6-10.1-19.6-18.9-26
	c-1.5-1.1-3-2.1-4.6-3L112,112l26,45.1c1.6-0.9,3.1-1.9,4.6-3C151.4,147.8,158.1,138.7,161.6,128.1z"
    />

    <path
      opacity={props.fillCluster[0].opacityOut}
      fill={props.fillCluster[0].color}
      enableBackground="new"
      d="M112,204.1c-2,0-4.1-0.1-6.2-0.2c-17.9-1.2-34.4-7.5-47.9-17.4c-15.6-11.4-27.4-27.5-33.5-46
	c-2.9-9-4.5-18.6-4.5-28.4l-20,0v0c0,12.1,1.9,23.7,5.5,34.6c7.4,22.7,21.8,42.2,40.7,56c18.5,13.5,41.2,21.4,65.8,21.4
	c20.4,0,39.5-5.5,56-15.1l-10-17.3C144.4,199.6,128.7,204.1,112,204.1z"
    />
    <path
      opacity={props.fillCluster[0].opacityMiddle}
      fill={props.fillCluster[0].color}
      enableBackground="new"
      d="M112,184.1c-15.8,0-30.4-5.1-42.3-13.8c-12.2-8.9-21.4-21.4-26.2-36c-2.3-7-3.5-14.5-3.5-22.3l0,0H20
	c0,9.9,1.6,19.5,4.5,28.4c6.1,18.5,17.9,34.6,33.5,46c13.6,9.9,30,16.2,47.9,17.4c2.1,0.1,4.1,0.2,6.2,0.2c16.6,0,32.4-4.5,46-12.4
	l-10-17.3C137.5,180.6,125.2,184.1,112,184.1z"
    />
    <path
      opacity={props.fillCluster[0].opacityIn}
      fill={props.fillCluster[0].color}
      d="M43.5,134.3c4.8,14.6,14,27.1,26.2,36c11.9,8.7,26.5,13.8,42.3,13.8c13.1,0,25.4-3.5,36-9.7l-10-17.3
	c-7.7,4.4-16.5,7-26,7c-11.4,0-22-3.7-30.6-9.9c-8.8-6.4-15.5-15.5-18.9-26c-1.6-5.1-2.5-10.5-2.5-16.1c0,0,0,0,0,0H40l0,0
	C40,119.8,41.3,127.3,43.5,134.3z"
    />

    <path
      opacity={props.fillCluster[1].opacityOut}
      fill={props.fillCluster[1].color}
      d="M218.6,77.4c-7.4-22.7-21.8-42.2-40.7-56c-3.2-2.3-6.4-4.4-9.8-6.4l-10,17.3c2.8,1.6,5.5,3.4,8.1,5.3
	c15.6,11.4,27.4,27.5,33.5,46c3.5,10.9,5.1,22.5,4.3,34.6c-0.5,7.8-2,15.2-4.3,22.3c-6.1,18.7-18,34.7-33.5,46
	c-2.6,1.9-5.3,3.6-8.1,5.2l10,17.3c3.4-2,6.7-4.1,9.8-6.4c18.9-13.8,33.3-33.3,40.7-56c3.6-10.9,5.5-22.5,5.5-34.6
	S222.2,88.3,218.6,77.4z"
    />
    <path
      opacity={props.fillCluster[1].opacityMiddle}
      fill={props.fillCluster[1].color}
      d="M154.4,53.8c12.2,8.9,21.4,21.4,26.2,36c2.3,7,3.5,14.5,3.5,22.3s-1.3,15.2-3.5,22.3c-4.8,14.6-14,27.1-26.2,36
	c-2,1.5-4.1,2.8-6.3,4.1l10,17.3c2.8-1.6,5.5-3.4,8.1-5.2c15.5-11.3,27.4-27.2,33.5-46c2.3-7.1,3.8-14.5,4.3-22.3
	c0.8-12.1-0.8-23.8-4.3-34.6c-6.1-18.5-17.9-34.6-33.5-46c-2.6-1.9-5.3-3.6-8.1-5.3l-10,17.3C150.2,51,152.3,52.3,154.4,53.8z"
    />
    <path
      opacity={props.fillCluster[1].opacityIn}
      fill={props.fillCluster[1].color}
      d="M142.6,69.9c8.8,6.4,15.5,15.5,18.9,26c1.6,5.1,2.5,10.5,2.5,16.1c0,5.6-0.9,11-2.5,16.1
	c-3.4,10.6-10.1,19.6-18.9,26c-1.5,1.1-3,2.1-4.6,3l10,17.3c2.2-1.3,4.3-2.6,6.3-4.1c12.2-8.9,21.4-21.4,26.2-36
	c2.3-7,3.5-14.5,3.5-22.3s-1.3-15.2-3.5-22.3c-4.8-14.6-14-27.1-26.2-36c-2-1.5-4.1-2.8-6.3-4.1l-10,17.3
	C139.6,67.9,141.2,68.9,142.6,69.9z"
    />

    <path
      opacity={props.fillCluster[2].opacityOut}
      fill={props.fillCluster[2].color}
      enableBackground="new"
      d="M20.2,105.9c0.5-7.8,2-15.2,4.3-22.3c6.1-18.7,18-34.7,33.5-46C73.3,26.5,92,20,112,20c2,0,4.1,0.1,6.2,0.2
	c14.5,1,28,5.3,39.9,12.1l10-17.3C151.6,5.5,132.4,0,112,0C87.5,0,64.7,8,46.2,21.4c-18.9,13.8-33.3,33.3-40.7,56
	C1.9,88.3,0,100,0,112l20,0C20,110,20.1,108,20.2,105.9z"
    />
    <path
      opacity={props.fillCluster[2].opacityMiddle}
      fill={props.fillCluster[2].color}
      enableBackground="new"
      d="M112,20C92,20,73.3,26.5,58,37.6c-15.5,11.3-27.4,27.2-33.5,46c-2.3,7.1-3.8,14.5-4.3,22.3
	C20.1,108,20,110,20,112h20c0-7.8,1.3-15.2,3.5-22.3c4.8-14.6,14-27.1,26.2-36C81.6,45.1,96.2,40,112,40c13.1,0,25.4,3.5,36,9.7
	l10-17.3c-11.8-6.8-25.3-11.2-39.9-12.1C116.1,20.1,114.1,20,112,20z"
    />
    <path
      opacity={props.fillCluster[2].opacityIn}
      fill={props.fillCluster[2].color}
      d="M62.5,96c3.4-10.6,10.1-19.6,18.9-26C90,63.7,100.6,60,112,60c9.5,0,18.4,2.5,26,7l10-17.3
	c-10.6-6.1-22.9-9.7-36-9.7c-15.8,0-30.4,5.1-42.3,13.8c-12.2,8.9-21.4,21.4-26.2,36c-2.3,7-3.5,14.5-3.5,22.3h20
	C60,106.4,60.9,101,62.5,96z"
    />
  </svg>
);

export default SvgGoutteCluster3parts;
