import React from 'react';

const SvgLoupeMoins = (props: any) => (
  <svg viewBox="0 0 19.33 19.33" {...props}>
    <path
      d="M19.09,18l-4.65-4.64a8.13,8.13,0,1,0-1.14,1.14L18,19.09A.81.81,0,0,0,19.09,18ZM8.15,14.67a6.53,6.53,0,1,1,6.52-6.52,6.53,6.53,0,0,1-6.52,6.52Z"
      fill={props.fill}
    />
    <path d="M11.44,9.14H4.85a1,1,0,1,1,0-2h6.59a1,1,0,0,1,0,2Z" fill={props.fill} />
  </svg>
);

export default SvgLoupeMoins;
