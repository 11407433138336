import { authHeader, handleResponse } from '../_helpers';

async function getAllPicPath(serialList: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(serialList),
  };
  const response = await fetch(`/api/picture/path`, requestOptions);
  return handleResponse(response);
}

const pictureService = {
  getAllPicPath,
};

export default pictureService;
