import React, { Component, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Input, Button, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import { DragAndDrop, Search } from '../_components';
import _ from 'lodash';
import LoadingBand from '../Bands/Loading';
import synchroAction from '../_actions/synchro.actions';
import synchroComAction from '../_actions/synchrocom.actions';
import gestionnaireAction from '../_actions/gestionnaire.actions';
import ErrorBand from '../Bands/Error';
import SvgMultiDragVert from '../SvgComponents/MultiDragVert';
import SwitchButton from '../_components/SwitchButton';
import { detect } from 'detect-browser';
import SvgCmdVert from '../SvgComponents/CmdVert';
import SvgCtrlVert from '../SvgComponents/CtrlVert';
import SynchroHeader from '../SvgComponents/SynchroHeader';
import SvgSynchroHeader from '../SvgComponents/SynchroHeader';
import BurgerHeader from '../SvgComponents/BurgerHeader';
import FiltreVert from '../SvgComponents/FiltreVert';
import DeviceRound from './DeviceRound';
import TourneesVertes from '../SvgComponents/TourneesVertes';
import SvgFlecheNoir from '../SvgComponents/FlecheNoir';
import ActionContainer from './ActionContainer';
import Spinner from 'reactstrap/lib/Spinner';

const device = {
  affectList: ['1010', '1030', '1100'],
  communication_status: false,
  content: 'Telephone de Hugo',
  id: 'c',
  serial: 'C96d86a0',
};

class DeviceSynchro extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      rounds: [],
      filter: '',
      displayInfo: false,
      actions: [],
      displayActions: false,
      sortType: 'default',
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.handleInfo = this.handleInfo.bind(this);
    this.handleAffect = this.handleAffect.bind(this);
    this.cancelOne = this.cancelOne.bind(this);
  }

  static getDerivedStateFromProps(props: any, state: any) {
    const { synchro } = props;
    const { init } = state;
    let cloneState: any = _.clone(state);
    if (synchro && synchro.tasks && !init) {
      cloneState.rounds = _.entries(synchro.tasks).map((el: any) => el[1]);
    }
    return cloneState;
  }

  handleFilter(e: any) {
    this.setState({
      filter: e.target.value,
    });
  }

  handleInfo() {
    this.setState({
      displayInfo: true,
    });
  }

  handleAffect(round: any, state: boolean, isAffect: any) {
    const { actions } = this.state;
    let copyActions = _.cloneDeep(actions);
    if (state) {
      copyActions.push({
        type: isAffect ? 'desaffec' : 'affec',
        rndCode: round.rndCode,
      });
    } else {
      copyActions = copyActions.filter((el: any) => el.rndCode !== round.rndCode);
    }
    this.setState({
      actions: copyActions,
    });
  }

  openActions = () => {
    const actionContainer = document.getElementById('slider-container');
    if (actionContainer) {
      actionContainer.style.display = 'block';
      this.setState({
        displayActions: true,
      });
    }
  };

  closeActions = () => {
    const actionContainer = document.getElementById('slider-container');
    if (actionContainer) {
      actionContainer.style.display = 'none';
      this.setState({
        displayActions: false,
      });
    }
  };

  cancelOne(rndCode: string) {
    const { actions } = this.state;
    const copyActions = _.cloneDeep(actions).filter((el: any) => el.rndCode !== rndCode);
    this.setState({
      actions: copyActions,
    });
  }

  sortedList = (list: any) => {
    const { sortType, actions } = this.state;
    const { synchro } = this.props;
    let copyList = _.cloneDeep(list);
    switch (sortType) {
      case 'affect':
        return copyList.sort(
          (a: any, b: any) =>
            (actions.find((el: any) => el.rndCode === b.rndCode && el.type === 'affec') ? 1 : 0) -
            (actions.find((el: any) => el.rndCode === a.rndCode && el.type === 'affec') ? 1 : 0)
        );
      case 'desaffect':
        return copyList.sort(
          (a: any, b: any) =>
            (actions.find((el: any) => el.rndCode === b.rndCode && el.type === 'desaffec') ? 1 : 0) -
            (actions.find((el: any) => el.rndCode === a.rndCode && el.type === 'desaffec') ? 1 : 0)
        );
      case 'charge':
        return copyList.sort(
          (a: any, b: any) =>
            (synchro.device.affectList.find((el: any) => el === b.rndCode) ? 1 : 0) -
            (synchro.device.affectList.find((el: any) => el === a.rndCode) ? 1 : 0)
        );
      default:
        return copyList;
    }
  };

  render() {
    const { rounds, filter, actions, displayActions, displayInfo } = this.state;
    const { synchro } = this.props;
    const roundList = this.sortedList(rounds.filter((round: any) => round.content.includes(filter)));
    const affectList = actions.filter((el: any) => el.type === 'affec');
    const desaffectList = actions.filter((el: any) => el.type === 'desaffec');
    return (
      <div className="synchro-device">
        <div className={'header'}>
          <div style={{ width: '10%' }} onClick={() => this.setState({ displayInfo: false })}>
            <SvgSynchroHeader height={'2em'} fill={'#31c6b3'} />
          </div>
          <h3>Synchronisation</h3>
          <UncontrolledDropdown style={{ width: '10%' }}>
            <DropdownToggle nav style={{ padding: 0 }}>
              <BurgerHeader height={'2em'} fill={'#31c6b3'} />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={this.handleInfo}>Information</DropdownItem>
              <DropdownItem divider />
              <DropdownItem href={'/saphir/signin'}>Se déconnecter</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className={'body'}>
          <div className={'tri-container'}>
            <Input className={'filter-name'} placeholder={'Nom de la tournée'} onChange={this.handleFilter} />
            <div className={'svg-filter'}>
              <UncontrolledDropdown style={{ width: '10%' }}>
                <DropdownToggle nav style={{ padding: 0 }}>
                  <FiltreVert height={'2em'} fill={'#31c6b3'} />
                </DropdownToggle>
                <DropdownMenu left>
                  <DropdownItem onClick={() => this.setState({ sortType: 'default' })}>Défaut</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => this.setState({ sortType: 'affect' })}>Affectation</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => this.setState({ sortType: 'desaffect' })}>Désaffectation</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => this.setState({ sortType: 'charge' })}>Chargé</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            {!displayActions && (
              <div className={'open-fleche'} onClick={this.openActions}>
                <TourneesVertes fill={'white'} height={'2em'} />
              </div>
            )}
          </div>
          <div className={'separator'} />
          {synchro.roundsUp ? (
            <div className={'rounds-container'}>
              {!displayInfo &&
                roundList.map((round: any) => (
                  <DeviceRound
                    round={round}
                    deviceInfo={synchro.device}
                    handleAffect={this.handleAffect}
                    actions={actions}
                  />
                ))}
              {displayInfo && (
                <>
                  <div className={'information-container-device'}>
                    <h5>Nom du téléphone: {synchro.device.content}</h5>
                    <h5>Numéro de série: {synchro.device.serial}</h5>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className={'spinner-container'}>
              <Spinner className={'round-spinner'} />
            </div>
          )}
          <div className={'synchro-button'}>
            <Button>
              <h5>Synchroniser</h5>
            </Button>{' '}
          </div>
        </div>
        <div className={'black-opacity'} id={'slider-container'}>
          <ActionContainer
            affectList={affectList}
            desaffectList={desaffectList}
            closeActions={this.closeActions}
            cancelOne={this.cancelOne}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { synchro } = state;
  return {
    synchro,
  };
}

const mapping: any = connect(mapStateToProps)(DeviceSynchro);

const connectedUser = withRouter(mapping);
export default connectedUser;
