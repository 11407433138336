import React from 'react';

const WeakCons = (props: any) => (
  <svg viewBox="0 0 108.15 117.5" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="pictos">
        <path
          fill={props.fill}
          d="M46.53,7.56V91.74L12.87,58.08A7.54,7.54,0,0,0,2.21,68.75l46.54,46.53a7.51,7.51,0,0,0,10.65,0l46.53-46.53A7.55,7.55,0,0,0,95.27,58.06L61.62,91.74V7.56a7.55,7.55,0,1,0-15.09,0"
        />
      </g>
    </g>
  </svg>
);
export default WeakCons;
