import React, { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { customTooltips } from '../Widget/Chart';
import locales from '../_shared/Locale.json';
import NoFilled from '../Message/NoFilled';

const getFormat = (zoom: any) => {
  switch (zoom) {
    case 'Hour':
      return 'YYYY/MM/DD HH:mm';
    case 'Month':
      return 'MM/YYYY';
    case 'Year':
      return 'YYYY';
    default:
      return 'DD/MM/YYYY';
  }
};

const BarChart = (props: any) => {
  const { labels, data, t, zoom, rmtColorList, metersInfo, formatTime, meter } = props;
  const canvasRef = useRef(null);
  const options: any = {
    legend: false,
    emptyOverlay: {
      message: t('all.text.no_data_available'),
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      position: 'nearest',
    },
    layout: {
      padding: {
        top: 20,
        bottom: 20,
      },
    },
    plugins: {
      datalabels: {
        display: 'auto',
        color(ctx: any) {
          // use the same color as the border
          // return ctx.dataset.backgroundColor;
          return '#4e4e4e';
        },
        borderWidth: 0,
        anchor: 'end',
        clamp: true,
        align: 'end',
      },
      t1: true,
    },
  };
  if (metersInfo) {
    options.tooltips = {
      custom: (ctx: any) => customTooltips(ctx, metersInfo, locales, formatTime, t, canvasRef),
    };
  }

  const dataS = {
    labels: labels && labels.map((el: any) => moment(el).format(getFormat(zoom))),
    datasets: [
      {
        label: meter.general.serial,
        data,
        backgroundColor: rmtColorList || '#97ddfb',
        borderColor: rmtColorList || '#97ddfb',
      },
    ],
  };
  return _.isEmpty(data) ? (
    <div className="infoDisplay noFilled" style={{ textAlign: 'center', fontSize: '2rem', margin: '1rem 0' }}>
      {t('all.text.no_data_available')}
    </div>
  ) : (
    <Bar data={dataS} options={options} ref={canvasRef} />
  );
  // return (<Bar data={dataS} options={options} ref={canvasRef} />);
};

const tr = withTranslation()(BarChart);
export default tr;
