import teleReleveConstants from '../_constants/teleReleve.constants';
import teleReleveService from '../_services/teleReleve.service';

/**
 * Récupère tous les marque-pages du site courant
 *
 * @method getAll
 * @param {number} locationId Id du site
 * @returns {Object} La liste des bookmarks
 */
function getAvailable() {
  function request() {
    return { type: teleReleveConstants.GET_AVAILABLE_REQUEST };
  }
  function success(availables: Object) {
    return { type: teleReleveConstants.GET_AVAILABLE_SUCCESS, availables };
  }
  function failure(error: Object) {
    return { type: teleReleveConstants.GET_AVAILABLE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    teleReleveService
      .getAvailable()
      .then((state: Object) => dispatch(success(state)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getGateway() {
  function request() {
    return { type: teleReleveConstants.GET_GATEWAY_REQUEST };
  }
  function success(gateways: Object) {
    return { type: teleReleveConstants.GET_GATEWAY_SUCCESS, gateways };
  }
  function failure(error: Object) {
    return { type: teleReleveConstants.GET_GATEWAY_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    teleReleveService
      .getGateway()
      .then((state: Object) => dispatch(success(state)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function provision(body: any) {
  function request() {
    return { type: teleReleveConstants.PROVISION_REQUEST };
  }
  function success(availables: Object) {
    return { type: teleReleveConstants.PROVISION_SUCCESS, availables };
  }
  function failure(error: Object) {
    return { type: teleReleveConstants.PROVISION_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    teleReleveService
      .provision(body)
      .then((state: Object) => {
        dispatch(success(state));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getProgressOfUpdateRadio() {
  function request() {
    return { type: teleReleveConstants.PROVISION_UPDATE_PROGRESS_REQUEST };
  }
  function success(progress: Object) {
    return { type: teleReleveConstants.PROVISION_UPDATE_PROGRESS_SUCCESS, progress };
  }
  function failure(error: Object) {
    return { type: teleReleveConstants.PROVISION_UPDATE_PROGRESS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    teleReleveService
      .getProgressOfUpdateRadio()
      .then((progress: any) => {
        const { nbUpdatedTelereleve, nbTelereleveToUpdate } = progress;
        dispatch(success(progress));
        if (nbUpdatedTelereleve !== nbTelereleveToUpdate) {
          setTimeout(() => dispatch(getProgressOfUpdateRadio()), 1000);
        }
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function updateProvisioningRadio() {
  function request() {
    return { type: teleReleveConstants.PROVISION_UPDATE_REQUEST };
  }
  function success() {
    return { type: teleReleveConstants.PROVISION_UPDATE_SUCCESS };
  }
  function failure(error: Object) {
    return { type: teleReleveConstants.PROVISION_UPDATE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    teleReleveService
      .updateProvisioningRadio()
      .then(() => {
        dispatch(success());
        dispatch(getProgressOfUpdateRadio());
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Vide le state des bookmarks
 *
 * @method clear
 */
function clear() {
  return {
    type: teleReleveConstants.CLEAR,
  };
}
const teleReleveActions = {
  getAvailable,
  provision,
  clear,
  updateProvisioningRadio,
  getProgressOfUpdateRadio,
  getGateway,
};

export default teleReleveActions;
