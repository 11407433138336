import React from 'react';

const SvgErreurInstallBleu = (props: any) => (
  <svg viewBox="0 0 4.08 21.01" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path fill={props.fill} d="M4.08,13.74H0V0H4.08Zm0,7.27H0V17.36h4Z" />
      </g>
    </g>
  </svg>
);
export default SvgErreurInstallBleu;
