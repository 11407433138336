import React from 'react';

const SvgMasqueVert = (props: any) => (
  <svg viewBox="0 0 30.75 16.59" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M30.75,8.22a6.64,6.64,0,0,0-2.52-5.16A12.3,12.3,0,0,0,23.09.74,33.16,33.16,0,0,0,15.4,0c-.78,0-1.55,0-2.32.05A32,32,0,0,0,8.61.54,14.31,14.31,0,0,0,3.06,2.67,6.71,6.71,0,0,0,.12,7.17a9,9,0,0,0,.47,4.62A6.67,6.67,0,0,0,4.68,15.9a12,12,0,0,0,4.19.69,5.9,5.9,0,0,0,3.89-1.34c.58-.46,1.09-1,1.69-1.43a1.41,1.41,0,0,1,1.81,0,10.64,10.64,0,0,1,1.22,1,6,6,0,0,0,3.33,1.7,11.32,11.32,0,0,0,5-.51A6.71,6.71,0,0,0,30,12.25a9.13,9.13,0,0,0,.76-4ZM8.3,11.16c-2.17,0-3.93-2.07-3.93-2.07S6.13,7,8.3,7s3.94,2.08,3.94,2.08-1.77,2.07-3.94,2.07Zm14.84,0c-2.17,0-3.93-2.07-3.93-2.07S21,7,23.14,7s3.94,2.08,3.94,2.08-1.76,2.07-3.94,2.07Z"
        />
      </g>
    </g>
  </svg>
);
export default SvgMasqueVert;
