import profilConstants from '../_constants/profil.constants';
import { action } from '../_interfaces/action';

export default function profil(state: any = {}, action: any) {
  switch (action.type) {
    case profilConstants.GET_ALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case profilConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        items: action.profils,
        loading: false,
      };
    case profilConstants.GETVERSION_REQUEST:
      return {
        loading: true,
      };
    case profilConstants.GETVERSION_SUCCESS:
      return {
        version: action.version,
      };
    case profilConstants.GETVERSION_FAILURE:
      return {
        error: "Impossible d'obtenir le numéro de version",
      };
    case profilConstants.GET_ALL_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des profils",
      };
    case profilConstants.GET_ALL_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case profilConstants.GET_ALL_TYPE_SUCCESS:
      return {
        ...state,
        types: action.typePerm,
        loading: false,
      };
    case profilConstants.GET_ALL_TYPE_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des permissions",
      };
    case profilConstants.SAVE_PROFIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case profilConstants.SAVE_PROFIL_SUCCESS:
      state.items.unshift(action.profil);
      return {
        ...state,
        loading: false,
      };
    case profilConstants.SAVE_PROFIL_FAILURE:
      return {
        error: "Impossible d'ajouter ce profil",
      };
    case profilConstants.DELETE_PROFIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case profilConstants.DELETE_PROFIL_SUCCESS:
      state.items = state.items.filter((el: any) => el.name !== action.profil.name);
      return {
        ...state,
        loading: false,
      };
    case profilConstants.DELETE_PROFIL_FAILURE:
      return {
        error: 'Impossible de supprimer ce profil',
      };
    case profilConstants.SAVE_PROFIL_LINKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case profilConstants.SAVE_PROFIL_LINKS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case profilConstants.SAVE_PROFIL_LINKS_FAILURE:
      return {
        error: "Impossible d'ajouter ce profil",
      };

    case profilConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
