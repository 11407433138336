import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, Comparator } from 'react-bootstrap-table2-filter';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

interface Props extends React.Props<any> {
  content: any;
  locale: any;
  isReport: boolean;
  t: Function;
}

interface State {
  body: any;
  link: string;
}

/**
 * @class AlertContent
 * @extends Component
 */
class AlertContent extends Component<Props, State> {
  static defaultProps = {
    isReport: false,
  };
  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);
    let body: any;
    try {
      body = JSON.parse(props.content);
    } catch (e) {
      body = {
        id: '',
        result: props.content,
      };
    }

    this.getRangeConsumptionTemplate = this.getRangeConsumptionTemplate.bind(this);
    this.getGapConsumptionTemplate = this.getGapConsumptionTemplate.bind(this);
    this.getGapConsumptionComparisonTemplate = this.getGapConsumptionComparisonTemplate.bind(this);
    this.getAverageRangeConsumptionTemplate = this.getAverageRangeConsumptionTemplate.bind(this);
    this.getRangeRealConsumptionTemplate = this.getRangeRealConsumptionTemplate.bind(this);
    this.getNoReadForMeterAlert = this.getNoReadForMeterAlert.bind(this);
    this.getAlertConsumptionHour = this.getAlertConsumptionHour.bind(this);
    this.getAlarmsTemplate = this.getAlarmsTemplate.bind(this);
    this.formatDate = this.formatDate.bind(this);

    this.state = {
      body,
      link: window.location.origin,
    };
  }

  /**
   * Formate la date de la cellule du tableau passée en paramètre
   *
   * @method formatDate
   * @param {any} cell Cellule
   * @param {any} row Ligne
   * @returns {JSX} La cellule formatée
   */
  formatDate = (cell: any, row: any) => {
    const { t } = this.props;
    return (
      <span>
        {moment(cell).isValid()
          ? moment(cell).format(t('all.date_format.date_x_time', { time: moment.HTML5_FMT.TIME_SECONDS }))
          : ''}
      </span>
    );
  };

  /**
   * Formate les types d'alarme de la cellule du tableau passée en
   * paramètre
   *
   * @method formatTypes
   * @param {any} cell Cellule
   * @param {any} row Ligne
   * @returns {JSX} La cellule formatée
   */
  formatTypes = (cell: any, row: any) => {
    const { t } = this.props;
    return <span>{cell.map((it: string) => t(it)).join(', ')}</span>;
  };

  /**
   * Format le serial/nom de compteur/virtuel de la cellule du tableau
   * passée en paramètre
   *
   * @method formatSerialName
   * @param {any} cell Cellule
   * @param {any} row Ligne
   * @returns {JSX} La cellule formatée
   */
  formatSerialName = (cell: any, row: any) => {
    const { body, link } = this.state;
    const isVirtual = cell && cell.startsWith('V-');
    if (isVirtual) {
      return (
        <a href={`${link}/locations/${body.locationId}/virtuals/info?id=${row.id}`} target="_blank">
          {cell}
        </a>
      );
    } else {
      return (
        <a href={`${link}/locations/${body.locationId}/meters/info?id=${row.id}`} target="_blank">
          {cell}
        </a>
      );
    }
  };

  meterFilter = textFilter({
    placeholder: this.props.t('all.filter.filter_by_serial'),
    className: 'meter-custom-text-filter',
    defaultValue: '',
    comparator: Comparator.LIKE,
  });

  vmeterFilter = textFilter({
    placeholder: this.props.t('all.filter.filter_by_name'),
    className: 'vmeter-custom-text-filter',
    defaultValue: '',
    comparator: Comparator.LIKE,
  });

  tableColumns: any = {
    RangeConsumption: {
      meters: [
        {
          dataField: 'serial',
          text: this.props.t('all.column_text.meter'),
          sort: this.props.isReport,
          formatter: this.formatSerialName,
          filter: this.props.isReport ? this.meterFilter : null,
        },
        {
          dataField: 'consumption',
          text: this.props.t('all.column_text.cons'),
          sort: this.props.isReport,
        },
        {
          dataField: 'previousDate',
          text: this.props.t('alert_content.column_text.since_date'),
          sort: this.props.isReport,
          formatter: this.formatDate,
        },
        {
          dataField: 'currentDate',
          text: this.props.t('alert_content.column_text.to_date'),
          sort: this.props.isReport,
          formatter: this.formatDate,
        },
      ],
      virtualMeters: [
        {
          dataField: 'name',
          text: this.props.t('all.column_text.virtual_meter'),
          sort: this.props.isReport,
          formatter: this.formatSerialName,
          filter: this.props.isReport ? this.vmeterFilter : null,
        },
        {
          dataField: 'consumption',
          text: this.props.t('all.column_text.cons'),
          sort: this.props.isReport,
        },
        {
          dataField: 'previousDate',
          text: this.props.t('alert_content.column_text.since_date'),
          sort: this.props.isReport,
          formatter: this.formatDate,
        },
        {
          dataField: 'currentDate',
          text: this.props.t('alert_content.column_text.to_date'),
          sort: this.props.isReport,
          formatter: this.formatDate,
        },
      ],
    },
    RangeRealConsumption: {
      meters: [
        {
          dataField: 'serial',
          text: this.props.t('all.column_text.meter'),
          sort: this.props.isReport,
          formatter: this.formatSerialName,
          filter: this.props.isReport ? this.meterFilter : null,
        },
        {
          dataField: 'consumption',
          text: this.props.t('all.column_text.cons'),
          sort: this.props.isReport,
        },
        {
          dataField: 'date',
          text: this.props.t('alert_content.column_text.read_date'),
          sort: this.props.isReport,
          formatter: this.formatDate,
        },
      ],
      virtualMeters: [
        {
          dataField: 'name',
          text: this.props.t('all.column_text.virtual_meter'),
          sort: this.props.isReport,
          formatter: this.formatSerialName,
          filter: this.props.isReport ? this.vmeterFilter : null,
        },
        {
          dataField: 'consumption',
          text: this.props.t('all.column_text.cons'),
          sort: this.props.isReport,
        },
        {
          dataField: 'date',
          text: this.props.t('alert_content.column_text.read_date'),
          sort: this.props.isReport,
          formatter: this.formatDate,
        },
      ],
    },
    GapConsumption: {
      meters: [
        {
          dataField: 'serial',
          text: this.props.t('all.column_text.meter'),
          sort: this.props.isReport,
          formatter: this.formatSerialName,
          filter: this.props.isReport ? this.meterFilter : null,
        },
        {
          dataField: 'averageConsumptionByDay',
          text: this.props.t('alert_content.column_text.avg_day_cons'),
          sort: this.props.isReport,
        },
        {
          dataField: 'averageConsumptionReference',
          text: this.props.t('alert_content.column_text.ref_cons'),
          sort: this.props.isReport,
        },
        {
          dataField: 'gapConsumption',
          text: this.props.t('alert_content.column_text.cons_gap'),
          sort: this.props.isReport,
        },
        {
          dataField: 'previousDate',
          text: this.props.t('alert_content.column_text.since_date'),
          sort: this.props.isReport,
          formatter: this.formatDate,
        },
        {
          dataField: 'currentDate',
          text: this.props.t('alert_content.column_text.to_date'),
          sort: this.props.isReport,
          formatter: this.formatDate,
        },
      ],
      virtualMeters: [
        {
          dataField: 'name',
          text: this.props.t('all.column_text.virtual_meter'),
          sort: this.props.isReport,
          formatter: this.formatSerialName,
          filter: this.props.isReport ? this.vmeterFilter : null,
        },
        {
          dataField: 'averageConsumptionByDay',
          text: this.props.t('alert_content.column_text.avg_day_cons'),
          sort: this.props.isReport,
        },
        {
          dataField: 'averageConsumptionReference',
          text: this.props.t('alert_content.column_text.ref_cons'),
          sort: this.props.isReport,
        },
        {
          dataField: 'gapConsumption',
          text: this.props.t('alert_content.column_text.cons_gap'),
          sort: this.props.isReport,
        },
        {
          dataField: 'previousDate',
          text: this.props.t('alert_content.column_text.since_date'),
          sort: this.props.isReport,
          formatter: this.formatDate,
        },
        {
          dataField: 'currentDate',
          text: this.props.t('alert_content.column_text.to_date'),
          sort: this.props.isReport,
          formatter: this.formatDate,
        },
      ],
    },
    AverageConsumption: {
      meters: [
        {
          dataField: 'serial',
          text: this.props.t('all.column_text.meter'),
          sort: this.props.isReport,
          formatter: this.formatSerialName,
          filter: this.props.isReport ? this.meterFilter : null,
        },
        {
          dataField: 'averageConsumptionByDay',
          text: this.props.t('alert_content.column_text.avg_day_cons'),
          sort: this.props.isReport,
        },
        {
          dataField: 'averageConsumptionReference',
          text: this.props.t('alert_content.column_text.ref_cons'),
          sort: this.props.isReport,
        },
        {
          dataField: 'gapAverageConsumption',
          text: this.props.t('alert_content.column_text.cons_gap'),
          sort: this.props.isReport,
        },
        {
          dataField: 'previousDate',
          text: this.props.t('alert_content.column_text.since_date'),
          sort: this.props.isReport,
          formatter: this.formatDate,
        },
        {
          dataField: 'currentDate',
          text: this.props.t('alert_content.column_text.to_date'),
          sort: this.props.isReport,
          formatter: this.formatDate,
        },
      ],
      virtualMeters: [
        {
          dataField: 'name',
          text: this.props.t('all.column_text.virtual_meter'),
          sort: this.props.isReport,
          formatter: this.formatSerialName,
          filter: this.props.isReport ? this.vmeterFilter : null,
        },
        {
          dataField: 'averageConsumptionByDay',
          text: this.props.t('alert_content.column_text.avg_day_cons'),
          sort: this.props.isReport,
        },
        {
          dataField: 'averageConsumptionReference',
          text: this.props.t('alert_content.column_text.ref_cons'),
          sort: this.props.isReport,
        },
        {
          dataField: 'gapAverageConsumption',
          text: this.props.t('alert_content.column_text.cons_gap'),
          sort: this.props.isReport,
        },
        {
          dataField: 'previousDate',
          text: this.props.t('alert_content.column_text.since_date'),
          sort: this.props.isReport,
          formatter: this.formatDate,
        },
        {
          dataField: 'currentDate',
          text: this.props.t('alert_content.column_text.to_date'),
          sort: this.props.isReport,
          formatter: this.formatDate,
        },
      ],
    },
    Alarms: {
      meters: [
        {
          dataField: 'serial',
          text: this.props.t('all.column_text.meter'),
          sort: this.props.isReport,
          formatter: this.formatSerialName,
          filter: this.props.isReport ? this.meterFilter : null,
        },
        {
          dataField: 'date',
          text: this.props.t('all.column_text.date'),
          sort: this.props.isReport,
          formatter: this.formatDate,
        },
        {
          dataField: 'types',
          text: this.props.t('all.column_text.type_plural'),
          sort: this.props.isReport,
          formatter: this.formatTypes,
        },
      ],
      virtualMeters: [
        {
          dataField: 'name',
          text: this.props.t('all.column_text.virtual_meter'),
          sort: this.props.isReport,
          formatter: this.formatSerialName,
          filter: this.props.isReport ? this.vmeterFilter : null,
        },
        {
          dataField: 'date',
          text: this.props.t('all.column_text.date'),
          sort: this.props.isReport,
          formatter: this.formatDate,
        },
        {
          dataField: 'types',
          text: this.props.t('all.column_text.type_plural'),
          sort: this.props.isReport,
          formatter: this.formatTypes,
        },
      ],
    },
    NoReadForMeterAlert: {
      meters: [
        {
          dataField: 'serial',
          text: this.props.t('all.column_text.meter'),
          sort: this.props.isReport,
          formatter: this.formatSerialName,
          filter: this.props.isReport ? this.meterFilter : null,
        },
        {
          dataField: 'lastReadDate',
          text: this.props.t('all.column_text.date'),
          sort: this.props.isReport,
          formatter: this.formatDate,
        },
        {
          dataField: 'nbReadOffset',
          text: this.props.t('alert_content.column_text.gap_last_date'),
          sort: this.props.isReport,
        },
      ],
    },
    ConsumptionHour: {
      meters: [
        {
          dataField: 'serial',
          text: this.props.t('all.column_text.meter'),
          sort: this.props.isReport,
          formatter: this.formatSerialName,
          filter: this.props.isReport ? this.meterFilter : null,
        },
        {
          dataField: 'lastReadDate',
          text: this.props.t('all.column_text.date'),
          sort: this.props.isReport,
          formatter: this.formatDate,
        },
        {
          dataField: 'lastConsumption',
          text: this.props.t('all.column_text.cons'),
          sort: this.props.isReport,
        },
      ],
    },
  };

  /**
   * Construit le tableau des résultats de l'alerte en
   * fonction du type
   *
   * @method getMetersTables
   * @param {any} body Contenu de l'alerte
   * @param {string} type Type
   * @returns {JSX} Les tableaux
   */
  getMetersTables = (body: any, type: string) => {
    const { isReport, t } = this.props;
    let options = {};

    if (!isReport) {
      options = {
        hideSizePerPage: true,
        sizePerPage: 15,
      };
    }
    if (body && body.result) {
      body.result.metersDatas = body.result.metersDatas
        ? body.result.metersDatas.map((it: any, index: any) => {
            it.baseId = index;
            return it;
          })
        : [];
      body.result.virtualMetersDatas = body.result.virtualMetersDatas
        ? body.result.virtualMetersDatas.map((it: any, index: any) => {
            it.baseId = index;
            return it;
          })
        : [];
    }
    const noData =
      undefined !== body.result.metersDatas &&
      body.result.metersDatas.length === 0 &&
      undefined !== body.result.virtualMetersDatas &&
      body.result.virtualMetersDatas.length === 0;
    return (
      <Fragment>
        {body.result.metersDatas && body.result.metersDatas.length > 0 && (
          <div>
            <p>{t('alert_content.table_title.alert_meter_list_and_info')}</p>
            {isReport && (
              <BootstrapTable
                keyField="baseId"
                bootstrap4
                bordered={false}
                hover
                data={body.result.metersDatas}
                columns={this.tableColumns[type].meters}
                pagination={paginationFactory()}
                filter={filterFactory()}
              />
            )}
            {!isReport && (
              <BootstrapTable
                keyField="baseId"
                bootstrap4
                bordered={false}
                hover
                data={body.result.metersDatas}
                columns={this.tableColumns[type].meters}
                pagination={paginationFactory(options)}
              />
            )}
          </div>
        )}
        {body.result.virtualMetersDatas && body.result.virtualMetersDatas.length > 0 && (
          <Fragment>
            <p>{t('alert_content.table_title.alert_virtual_meter_list_and_info')}</p>
            {isReport && (
              <BootstrapTable
                keyField="baseId"
                data={body.result.virtualMetersDatas}
                columns={this.tableColumns[type].virtualMeters}
                pagination={paginationFactory()}
                filter={filterFactory()}
                bootstrap4
                bordered={false}
                hover
              />
            )}
            {!isReport && (
              <BootstrapTable
                keyField="baseId"
                data={body.result.virtualMetersDatas}
                columns={this.tableColumns[type].virtualMeters}
                pagination={paginationFactory(options)}
                bootstrap4
                bordered={false}
                hover
              />
            )}
          </Fragment>
        )}
        {noData && <p>{t('alert_content.warning.no_alert_found')}</p>}
      </Fragment>
    );
  };

  /**
   * Rend le template range consumption
   *
   * @method getRangeConsumptionTemplate
   * @param {any} body Contenu
   * @returns {JSX} le template
   */
  getRangeConsumptionTemplate = (body: any) => {
    const { t } = this.props;
    return (
      <div>
        <h3>{t('alert_content.alarm_title.cons_alert')}</h3>
        <p>{t('alert_content.alarm_text.m3_cons_interval_of_cons_avg')}</p>
        {this.getMetersTables(body, 'RangeConsumption')}
      </div>
    );
  };

  /**
   * Rend le template gap consumption
   *
   * @method getGapConsumptionTemplate
   * @param {any} body Contenu
   * @returns {JSX} le template
   */
  getGapConsumptionTemplate = (body: any) => {
    const { t } = this.props;
    return (
      <div>
        <h3>{t('alert_content.alarm_title.cons_gap')}</h3>
        <p>{t('alert_content.alarm_text.pct_cons_interval_of_cons_avg')}</p>
        {this.getMetersTables(body, 'GapConsumption')}
      </div>
    );
  };

  /**
   * Rend le template gap consumption comparison
   *
   * @method getGapConsumptionComparisonTemplate
   * @param {any} body Contenu
   * @returns {JSX} le template
   */
  getGapConsumptionComparisonTemplate = (body: any) => {
    const { t } = this.props;
    let data: any[] = [];
    if (body && body.result) {
      body.result.meterRef.type = t('alert_content.meter_type.ref');
      body.result.meterComp.type = t('alert_content.meter_type.witness');

      data.push(body.result.meterRef);
      data.push(body.result.meterComp);
    }

    const columns = [
      {
        dataField: 'serial',
        text: t('all.column_text.meter'),
        formatter: this.formatSerialName,
      },
      {
        dataField: 'type',
        text: t('all.column_text.type'),
      },
      {
        dataField: 'averageConsumption',
        text: t('all.column_text.cons_avg'),
      },
      {
        dataField: 'consumption',
        text: t('all.column_text.cons'),
      },
    ];
    return (
      <div>
        <h3>{t('alert_content.alarm_title.cons_comparison')}</h3>
        <div>
          <p>{t('alert_content.alarm_text.cons_interval_of_two_meter')}</p>
          <h4>{t('alert_content.alarm_result.result')}</h4>
          <strong>{t('alert_content.alarm_result.x_cons_gap', { gapCons: body.gapConsumption })}</strong>
          <BootstrapTable keyField="id" data={data} columns={columns} bootstrap4 bordered={false} hover />
        </div>
      </div>
    );
  };

  /**
   * Rend le template average range consumption
   *
   * @method getAverageRangeConsumptionTemplate
   * @param {any} body Contenu
   * @returns {JSX} le template
   */
  getAverageRangeConsumptionTemplate = (body: any) => {
    const { t } = this.props;
    return (
      <div>
        <h3>{t('alert_content.alarm_title.cons_avg')}</h3>
        <p>{t('alert_content.alarm_text.m3_cons_interval_of_cons_avg')}</p>
        {this.getMetersTables(body, 'AverageConsumption')}
      </div>
    );
  };

  /**
   * Rend le template range real consumption
   *
   * @method getRangeRealConsumptionTemplate
   * @param {any} body Contenu
   * @returns {JSX} le template
   */
  getRangeRealConsumptionTemplate = (body: any) => {
    const { t } = this.props;
    return (
      <div>
        <h3>{t('alert_content.alarm_title.real_cons')}</h3>
        <p>{t('alert_content.alarm_text.cons_limit_since_integration')}</p>
        {this.getMetersTables(body, 'RangeRealConsumption')}
      </div>
    );
  };

  getNoReadForMeterAlert = (body: any) => {
    const { t } = this.props;
    return (
      <div>
        <h3>{t('alert_content.alarm_title.read_gap')}</h3>
        <p>{t('alert_content.alarm_text.read_gap_today')}</p>
        {this.getMetersTables(body, 'NoReadForMeterAlert')}
      </div>
    );
  };

  getAlertConsumptionHour = (body: any) => {
    const { t } = this.props;
    return (
      <div>
        <h3>{t('alert_content.alarm_title.read_gap')}</h3>
        <p>{t('alert_content.alarm_text.cons_of_period')}</p>
        {this.getMetersTables(body, 'ConsumptionHour')}
      </div>
    );
  };

  /**
   * Rend le template alarms
   *
   * @method getAlarmsTemplate
   * @param {any} body Contenu
   * @returns {JSX} le template
   */
  getAlarmsTemplate = (body: any) => {
    const { t } = this.props;
    return (
      <div>
        <h3>{t('alert_content.alarm_title.alarm_mfr')}</h3>
        <p>{t('alert_content.alarm_text.alarm_meter_since_integration')}</p>
        {this.getMetersTables(body, 'Alarms')}
      </div>
    );
  };

  /**
   * Rend le template en fonction des données reçues
   *
   * @method getAlarmsTemplate
   * @returns {JSX} le template
   */
  getTemplate = () => {
    const { body } = this.state;
    let template: any;

    switch (body.id) {
      case 'RangeConsumption':
        template = this.getRangeConsumptionTemplate(body);
        break;
      case 'GapConsumption':
        template = this.getGapConsumptionTemplate(body);
        break;
      case 'GapConsumptionComparison':
        template = this.getGapConsumptionComparisonTemplate(body);
        break;
      case 'AverageRangeConsumption':
        template = this.getAverageRangeConsumptionTemplate(body);
        break;
      case 'RangeRealConsumption':
        template = this.getRangeRealConsumptionTemplate(body);
        break;
      case 'Alarms':
        template = this.getAlarmsTemplate(body);
        break;
      case 'NoReadForMeterAlert':
        template = this.getNoReadForMeterAlert(body);
        break;
      case 'ConsumptionHour':
        template = this.getAlertConsumptionHour(body);
        break;
      default:
        template = body.result;
        break;
    }
    return template;
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    return this.getTemplate();
  }
}

const tr = withTranslation()(AlertContent);
export default tr;
