import React, { Component } from 'react';
import { Button } from 'reactstrap';
import SvgAjoutBleu from '../../SvgComponents/AjoutBleu';
import SvgValidation from '../../SvgComponents/ValidationVert';
import { withTranslation } from 'react-i18next';

class DragButton extends Component<any, any> {
  constructor(props: Readonly<any>) {
    super(props);

    this.state = {
      cancel: false,
      apply: false,
    };

    this.editApplyButton = this.editApplyButton.bind(this);
    this.editCancelButton = this.editCancelButton.bind(this);
  }

  componentDidMount() {
    const cancel = document.getElementsByClassName('cancel')[0];
    const apply = document.getElementsByClassName('apply')[0];

    cancel.addEventListener('mouseenter', () => this.setState({ cancel: true }));
    apply.addEventListener('mouseenter', () => this.setState({ apply: true }));
    cancel.addEventListener('mouseleave', () => this.setState({ cancel: false }));
    apply.addEventListener('mouseleave', () => this.setState({ apply: false }));
  }

  editCancelButton() {
    this.setState({
      cancel: true,
    });
  }

  editApplyButton() {
    this.setState({
      apply: true,
    });
  }

  render() {
    const { onClickCancelAll, getAllCommunication, t } = this.props;
    const { cancel, apply } = this.state;
    return (
      <div className="synchro-button-wrapper-column" style={{ zIndex: '10' }}>
        <div
          className="button-bottom-column cancel"
          onClick={() => onClickCancelAll(null)}
          style={{ backgroundColor: cancel ? 'white' : '#dc3545', borderColor: '#dc3545' }}
        >
          <div className={'body-container'}>
            <SvgAjoutBleu
              fill={cancel ? '#dc3545' : 'white'}
              style={{ transform: 'rotate(45deg)', marginRight: cancel ? '5px' : '0' }}
              height={'2em'}
            />
            {cancel && (
              <div className={'span-button'}>
                <h5 style={{ color: cancel ? '#dc3545' : 'white' }}>{t('all.button.cancel')}</h5>
              </div>
            )}
          </div>
        </div>
        <div
          className="button-bottom-column apply"
          onClick={() => getAllCommunication(null)}
          style={{ backgroundColor: apply ? 'white' : '#31c6b3', borderColor: '#31c6b3' }}
        >
          <div className={'body-container'}>
            <SvgValidation
              fill={apply ? '#31c6b3' : 'white'}
              height={'1.8em'}
              style={{ marginRight: apply ? '5px' : '0' }}
            />
            {apply && (
              <div className={'span-button'}>
                <h5 style={{ color: apply ? '#31c6b3' : 'white' }}>{t('all.button.apply')}</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const tr = withTranslation()(DragButton);
export default tr;
