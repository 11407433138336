import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class NoFilled extends PureComponent {
  render() {
    const { t } = this.props;
    return <div className={'infoDisplay noFilled'}>{t('all.text.not_specified')}</div>;
  }
}

NoFilled.propTypes = {};

const tr = withTranslation()(NoFilled);
export default tr;
