import { mapActions } from '.';
import mapboxConstants from '../_constants/mapbox.constants';
import mapboxService from '../_services/mapbox.service';

function getGeojson() {
  function success(geojson: Object) {
    return { type: mapboxConstants.GEOJSON_SUCCESS, geojson };
  }
  function failure(error: Object) {
    return { type: mapboxConstants.GEOJSON_FAILURE, error };
  }
  return (dispatch: Function) => {
    mapboxService
      .getAllLayers()
      .then((geojson: Object) => dispatch(success(geojson)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getMeters() {
  function success(meters: Object) {
    return { type: mapboxConstants.METER_SUCCESS, meters };
  }
  function failure(error: Object) {
    return { type: mapboxConstants.METER_FAILURE, error };
  }
  return (dispatch: Function) => {
    mapboxService
      .getMeters()
      .then((meters: any) => {
        dispatch(success(meters));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getGateways() {
  function success(gateways: Object) {
    return { type: mapboxConstants.GATEWAY_SUCCESS, gateways };
  }
  function failure(error: Object) {
    return { type: mapboxConstants.GATEWAY_FAILURE, error };
  }
  return (dispatch: Function) => {
    mapboxService
      .getGateways()
      .then((gateways: any) => {
        dispatch(success(gateways));
        const isInactive = 'inactive_gateway';
        const isActive = 'active_gateway';
        gateways
          .filter((el: any) => el.gpsPosition)
          .map((el: any) => ({
            id: el.id,
            name: el.name,
            gpsPosition: el.gpsPosition,
            active: el.active,
            isDevice: el.isDevice,
            icon: el.active ? isActive : isInactive,
          }));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getPolygons() {
  function success(polygons: Object) {
    return { type: mapboxConstants.POLYGONS_SUCCESS, polygons };
  }
  function failure(error: Object) {
    return { type: mapboxConstants.POLYGONS_FAILURE, error };
  }
  return (dispatch: Function) => {
    mapboxService
      .getPolygons()
      .then((polygons: Object) => {
        dispatch(success(polygons));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function postPolygons(polygons: any) {
  function success(polygons: Object) {
    return { type: mapboxConstants.POST_POLYGONS_SUCCESS, polygons };
  }
  function failure(error: Object) {
    return { type: mapboxConstants.POST_POLYGONS_FAILURE, error };
  }
  return (dispatch: Function) => {
    mapboxService
      .postPolygons(polygons)
      .then((polygons: Array<any>) => dispatch(success(polygons)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getPointsInPolygons() {
  function success(points: any) {
    return { type: mapboxConstants.GET_POINTS_WITHIN_SUCCESS, points };
  }
  function failure(error: Object) {
    return { type: mapboxConstants.GET_POINTS_WITHIN_FAILURE, error };
  }
  return (dispatch: Function) => {
    mapboxService
      .getPointsInPolygons()
      .then((points: Array<any>) => {
        dispatch(success(points));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function sendRound(round: Object) {
  function success(round: Object) {
    return { type: mapboxConstants.SEND_ROUND_SUCCESS, round };
  }
  function failure(error: Object) {
    return { type: mapboxConstants.SEND_ROUND_FAILURE, error };
  }
  return (dispatch: Function) => {
    mapboxService
      .sendRound(round)
      .then((round: Object) => dispatch(success(round)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function setRangeOn(rangeOn: boolean) {
  function success(rangeOn: boolean) {
    return { type: mapboxConstants.RANGE_ON_SUCCESS, rangeOn };
  }
  return (dispatch: Function) => {
    dispatch(success(rangeOn));
  };
}

function clear() {
  return { type: mapboxConstants.CLEAR };
}

const mapboxActions = {
  getGeojson,
  getMeters,
  getGateways,
  getPolygons,
  postPolygons,
  getPointsInPolygons,
  sendRound,
  setRangeOn,
  clear,
};

export default mapboxActions;
