import { authHeader, handleResponse } from '../_helpers';
import { Setting } from '../_entities/settings';

const uri = '/api/setting';

async function getAllByType(userId: any, type: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let response = await fetch(`${uri}/user/${userId}/type/${type}`, requestOptions);
  return handleResponse(response);
}

async function save(filter: Setting, userId: number, type: string) {
  const toSave = filter;
  toSave.type = type;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(toSave),
  };
  let response = await fetch(`${uri}/user/${userId}/type/${type}`, requestOptions);
  return handleResponse(response);
}

async function deleteFilter(filterId: number, userId: number, type: string) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };
  let response = await fetch(`${uri}/user/${userId}/type/${type}/${filterId}`, requestOptions);
  return handleResponse(response);
}

async function update(filter: Setting, filterId: number, userId: number, type: string) {
  filter.type = type;
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(filter),
  };
  let response = await fetch(`${uri}/user/${userId}/type/${type}/${filterId}`, requestOptions);
  return handleResponse(response);
}

const filtreService = {
  getAllByType,
  save,
  deleteFilter,
  update,
};
export default filtreService;
