import React from 'react';
export default { 
translation: { 
"all.button.submit":"___", 
"all.button.validate":"___", 
"all.button.edit":"___", 
"all.button.integrate":"___", 
"all.button.close":"___", 
"all.button.cancel":"___", 
"all.button.delete":"___", 
"all.button.register":"___", 
"all.button.change_file":"___", 
"all.button.search":"___", 
"all.button.reset":"___", 
"all.button.back":"___", 
"all.button.export":"___", 
"all.button.select":"___", 
"all.button.add_to_favorites":"___", 
"all.button.next":"___", 
"all.button.apply":"___", 
"all.button.save":"___", 
"all.button.giveup":"___", 
"all.button.recup":"___", 
"all.button.confirm":"___", 
"all.button.copy":"___", 
"all.button.paste":"___", 
"all.button.apply.all":"___", 
"all.button.cancel.all":"___", 
"all.button.modification":"___", 
"all.button.send":"___", 
"all.text.block":"___", 
"all.text.factu":"___", 
"all.column_text.code":"___", 
"all.column_text.label":"___", 
"all.column_text.name":"___", 
"all.column_text.state":"___", 
"all.column_text.row":"___", 
"all.column_text.pdi_ref":"___", 
"all.column_text.serial_meter":"___", 
"all.column_text.meter":"___", 
"all.column_text.virtual_meter":"___", 
"all.column_text.type":"___", 
"all.column_text.type_plural":"___", 
"all.column_text.cons":"___", 
"all.column_text.cons_avg":"___", 
"all.column_text.date":"___", 
"all.column_id":"___", 
"all.brand.itron":"___", 
"all.address.city":"___", 
"all.address.country":"___", 
"all.address.complement":"___", 
"all.address.street":"___", 
"all.address.street_number":"___", 
"all.address.streetNumber":"___", 
"all.address.street_number_additionnal":"___", 
"all.address.streetNumberAdditionnal":"___", 
"all.address.zipcode":"___", 
"all.contact.civility":"___", 
"all.contact.civility_male":"___", 
"all.contact.civility_female":"___", 
"all.contact.first_name":"___", 
"all.contact.last_name":"___", 
"all.contact.phone":"___", 
"all.contact.email":"___", 
"all.contact.mail":"___", 
"all.contact.note":"___", 
"all.contact.no_contact":"___", 
"all.devices":"___", 
"all.filter.filter_by_serial":"___", 
"all.filter.filter_by_ref":"___", 
"all.filter.filter_by_name":"___", 
"all.filter.saved_filter":"___", 
"all.filter.saved_filter_plural":"___", 
"all.filter.no_conf_filter_saved":"___", 
"all.filter.delete_saved_filter":"___", 
"all.filter.add_filter":"___", 
"all.filter.delete_filter":"___", 
"all.filter.filter":"___", 
"all.filter.filter_plural":"___", 
"all.filter.mask_filter":"___", 
"all.filter.alarm_type_filter":"___", 
"all.filter.round_filter":"___", 
"all.filter.round_filter_plural":"___", 
"all.filter.device_filter":"___", 
"all.filter.device_filter_plural":"___", 
"all.date.date":"___", 
"all.date.date_plural":"___", 
"all.date.updated_on":"___", 
"all.date.update_date":"___", 
"all.date_format.date":"___", 
"all.date_format.date_back":"___", 
"all.date_format.date_with_time":"___", 
"all.date_format.date_and_time":"___", 
"all.date.today_date":"___", 
"all.date.end_date":"___", 
"all.user.user":"___", 
"all.user.user_plural":"___", 
"all.user.profile":"___", 
"all.user.profile_plural":"___", 
"all.user.add_profile":"___", 
"all.user.delete_profile":"___", 
"all.user.profile_name":"___", 
"all.user.role":"___", 
"all.user.permission_plural":"___", 
"all.user.my_info_plural":"___", 
"all.user.logout":"___", 
"all.user.user_required":"___", 
"all.user.pwd":"___", 
"all.user.logIn":"___", 
"all.text.all":"___", 
"all.text.all_plural":"___", 
"all.text.no":"___", 
"all.text.yes":"___", 
"all.text.unknown":"___", 
"all.text.no_data_available":"___", 
"all.text.indexed":"___", 
"all.text.indexed_plural":"___", 
"all.text.not_indexed":"___", 
"all.text.not_indexed_plural":"___", 
"all.text.modified":"___", 
"all.text.modified_plural":"___", 
"all.text.not_modified":"___", 
"all.text.not_modified_plural":"___", 
"all.text.gps":"___", 
"all.text.gps_plural":"___", 
"all.text.no_gps":"___", 
"all.text.no_gps_plural":"___", 
"all.text.multiple_select":"___", 
"all.text.edited_not_indexed_plural":"___", 
"all.text.active":"___", 
"all.text.inactive":"___", 
"all.text.latitude":"___", 
"all.text.longitude":"___", 
"all.text.not_possible_deletion":"___", 
"all.text.unity":"___", 
"all.text.configure":"___", 
"all.text.filter_explanation":"___", 
"all.text.required_field_character_condition":"___", 
"all.text.option":"___", 
"all.text.option_plural":"___", 
"all.text.select_file":"___", 
"all.text.already_exist":"___", 
"all.text.imported":"___", 
"all.text.msg":"___", 
"all.text.marking":"___", 
"all.text.required":"___", 
"all.text.required_field":"___", 
"all.text.required_field_plural":"___", 
"all.text.virtual_meter":"___", 
"all.text.note":"___", 
"all.text.manager":"___", 
"all.text.manager_plural":"___", 
"all.text.import_mfr":"___", 
"all.text.silex_import":"___", 
"all.text.write_note":"___", 
"all.text.record":"___", 
"all.text.record_plural":"___", 
"all.text.update_date":"___", 
"all.text.marquage":"___", 
"all.text.message":"___", 
"all.text.particular_msg":"___", 
"all.text.import_billing":"___", 
"all.text.notif":"___", 
"all.text.notif_plural":"___", 
"all.text.alert_plural":"___", 
"all.text.detail_plural":"___", 
"all.text.stock":"___", 
"all.text.synchro":"___", 
"all.text.start_new_releve":"___", 
"all.text.phone":"___", 
"all.text.phone_plural":"___", 
"all.text.name.export":"___", 
"all.text.stat_plural":"___", 
"all.text.forbidden_access":"___", 
"all.text.none_male":"___", 
"all.text.none_female":"___", 
"all.text.name":"___", 
"all.text.update_selected_conf":"___", 
"all.text.save_new_conf":"___", 
"all.text.operator":"___", 
"all.text.start_date":"___", 
"all.text.end_date":"___", 
"all.text.new":"___", 
"all.text.name_lenght_max_100":"___", 
"all.text.link":"___", 
"all.text.unlink":"___", 
"all.text.model":"___", 
"all.text.export_to":"___", 
"all.text.export_to_csv":"___", 
"all.text.export_to_teleo":"___", 
"all.text.type_x_list_loading":"___", 
"all.text.not_specified":"___", 
"all.text.zoom":"___", 
"all.text.interval":"___", 
"all.text.export_data_from_x":"___", 
"all.text.save_pdf":"___", 
"all.text.preview_pdf":"___", 
"all.text.export_row_data":"___", 
"all.text.average":"___", 
"all.text.type":"___", 
"all.text.type_plural":"___", 
"all.text.information":"___", 
"all.text.information_plural":"___", 
"all.text.state":"___", 
"all.text.start":"___", 
"all.text.end":"___", 
"all.text.ok":"___", 
"all.text.more_info_plural":"___", 
"all.text.last_update":"___", 
"all.text.unlock":"___", 
"all.text.locked":"___", 
"all.text.lock":"___", 
"all.text.link_all":"___", 
"all.text.dissociate_all":"___", 
"all.text.threshold":"___", 
"all.text.edit_information":"___", 
"all.text.edit_password":"___", 
"all.text.text_email":"___", 
"all.text.mark_all_as_read":"___", 
"all.text.title":"___", 
"all.text.read_notification":"___", 
"all.text.resource_list":"___", 
"all.text.validate-_deletion_link":"___", 
"all.text.widgets":"___", 
"all.text.lora_import":"___", 
"all.text.code":"___", 
"all.text.waiting_action":"___", 
"all.text.waiting_action_plural":"___", 
"all.text.go_back":"___", 
"all.text.go_backward_step":"___", 
"all.text.greater_than":"___", 
"all.text.less_than":"___", 
"all.text.not_define":"___", 
"all.text.summary":"___", 
"all.text.calculation_method":"___", 
"all.text.delete_x_ask":"___", 
"all.text.irreversible_action_will_delete_x":"___", 
"all.text.loading":"___", 
"all.text.selection":"___", 
"all.text.more_informations_plural":"___", 
"all.text.select_all":"___", 
"all.text.deselect_all":"___", 
"all.text.submit_file":"___", 
"all.text.install_date":"___", 
"all.text.reference":"___", 
"all.text.general_male":"___", 
"all.text.previous":"___", 
"all.text.close":"___", 
"all.text.doclose":"___", 
"all.text.open":"___", 
"all.text.doopen":"___", 
"all.text.humidity":"___", 
"all.text.temperature":"___", 
"all.text.pressure":"___", 
"all.text.co2":"___", 
"all.text.downlink":"___", 
"all.text.monday":"___", 
"all.text.tuesday":"___", 
"all.text.wednesday":"___", 
"all.text.thursday":"___", 
"all.text.friday":"___", 
"all.text.saturday":"___", 
"all.text.sunday":"___", 
"all.text.version":"___", 
"all.text.previous_releve":"___", 
"all.text.value":"___", 
"all.text.field":"___", 
"all.text.filter":"___", 
"all.text.result":"___", 
"all.text.other":"___", 
"all.text.subscriber":"___", 
"all.text.portable_informations":"___", 
"all.text.portable_alias":"___", 
"all.text.irreversible_action_warning":"___", 
"all.text.other_plural_fem":"___", 
"all.text.mounted":"___", 
"all.text.unmounted":"___", 
"all.threshold.min_x_threshold":"___", 
"all.threshold.max_x_threshold":"___", 
"all.alert.alert":"___", 
"all.alert.alert_plural":"___", 
"all.alert.alert_name":"___", 
"all.alert.alert_type":"___", 
"all.alert.alert_conf":"___", 
"all.alert.delete_alert":"___", 
"all.alert.edit_alert":"___", 
"all.alert.view_report_plural":"___", 
"all.alert.deactivate_alert":"___", 
"all.alert.irreversible_action_deactivate_alert":"___", 
"all.homepage.homepage":"___", 
"all.homepage.choose_homepage":"___", 
"all.mask.mask_plural":"___", 
"all.mask.edit_mask":"___", 
"all.mask.delete_mask":"___", 
"all.mask.add_mask":"___", 
"all.mask.mask_edition":"___", 
"all.mask.mask_name":"___", 
"all.device.last_login_date":"___", 
"all.device.connected":"___", 
"all.device.disconnected":"___", 
"all.device.device_own_last_version":"___", 
"all.device.device_not_own_last_version":"___", 
"all.device.device_with_obsolete_version":"___", 
"all.device.edit_device":"___", 
"all.device.delete_device":"___", 
"all.device.phone":"___", 
"all.device.phone_plural":"___", 
"all.dashboard.dashboard_name":"___", 
"all.dashboard.name_need_least_2_char":"___", 
"all.dashboard.dashboard_favorite_plural":"___", 
"all.dashboard.dashboard_plural":"___", 
"all.pdi.pdi":"___", 
"all.pdi.link_pdi_ref":"___", 
"all.pdi.pdi_ref":"___", 
"all.pdi.link_location_pdi":"___", 
"all.pdi.dissociate_location_pdi":"___", 
"all.pdi.go_to_pdi":"___", 
"all.pdi.state_pdi":"___", 
"all.pdi.add_location":"___", 
"all.pdi.loading_location":"___", 
"all.location.location":"___", 
"all.location.location_plural":"___", 
"all.location.address":"___", 
"all.location.contact":"___", 
"all.location.note":"___", 
"all.location_button.access_location":"___", 
"all.location.location_info":"___", 
"all.location.pls_first_delete_child_location":"___", 
"all.location.delete_location":"___", 
"all.round.location_follow_up":"___", 
"all.round.round":"___", 
"all.round.round_plural":"___", 
"all.round.round_code":"___", 
"all.round.round_name":"___", 
"all.round.go_to_record":"___", 
"all.round.go_to_round":"___", 
"all.round.assigned_round_plural":"___", 
"all.round.round_info":"___", 
"all.round.round_follow_up":"___", 
"all.round.round_mngmt":"___", 
"all.round.round_import":"___", 
"all.meter.serial":"___", 
"all.meter.mfr":"___", 
"all.meter.meter_serial":"___", 
"all.meter.radio_serial":"___", 
"all.meter.meter_serial_ref":"___", 
"all.meter.meter_serial_control":"___", 
"all.meter.available_meter":"___", 
"all.meter.available_meter_plural":"___", 
"all.meter.selection_explanation":"___", 
"all.meter.confirm_x_selection_explanation":"___", 
"all.meter.confirm_x_selection_explanation_plural":"___", 
"all.meter.selected_meter":"___", 
"all.meter.selected_meter_plural":"___", 
"all.meter.real_meter_diameter":"___", 
"all.meter.meter_model":"___", 
"all.meter.meter_manufacturer":"___", 
"all.meter.meter_index":"___", 
"all.meter.index":"___", 
"all.meter.meter_ref":"___", 
"all.meter.control_meter":"___", 
"all.meter.meter":"___", 
"all.meter.meter_plural":"___", 
"all.meter.indexed_meter":"___", 
"all.meter.indexed_meter_plural":"___", 
"all.meter.modified_meter":"___", 
"all.meter.modified_meter_plural":"___", 
"all.meter.all_meter_nb":"___", 
"all.meter.virtual_meter_plural":"___", 
"all.meter.pulse_weight":"___", 
"all.meter.wheels_number":"___", 
"all.meter.add_virtual_meter":"___", 
"all.meter.go_to_meter":"___", 
"all.meter.export_data_from_valid":"___", 
"all.meter.edit_virtual_meter":"___", 
"all.meter.delete_virtual_meter":"___", 
"all.meter.virtual_meter_edition":"___", 
"all.meter.virtual_meter_name":"___", 
"all.meter.linked_meter_plural":"___", 
"all.meter.stack_index_plural":"___", 
"all.meter.meter_to_x_plural":"___", 
"all.meter.all_meter_dissociation_easy":"___", 
"all.meter.total_number_read_given_period":"___", 
"all.read_meter.read_last_date":"___", 
"all.read_meter.read_previous_date":"___", 
"all.read_meter.total_conso_in_day":"___", 
"all.read_meter.total_conso_in_period":"___", 
"all.read_meter.daily_avg_calc_info":"___", 
"all.read_meter.daily_avg_cons_ratio":"___", 
"all.read_meter.daily_avg_cons":"___", 
"all.read_meter.tendency":"___", 
"all.read_meter.cons":"___", 
"all.read_meter.cons_gap":"___", 
"all.read_meter.last_read":"___", 
"all.read_meter.cons_history":"___", 
"all.read_meter.no_current_read":"___", 
"all.read_meter.read_history":"___", 
"all.read_meter.empty_cons":"___", 
"all.read_meter.high_cons":"___", 
"all.read_meter.normal_cons":"___", 
"all.read_meter.low_cons":"___", 
"all.read_meter.cons_comparison":"___", 
"all.read_meter.max_index":"___", 
"all.read_meter.read_start_date":"___", 
"all.read_meter.read_method":"___", 
"all.read_meter.cons_m3":"___", 
"all.read_meter.cons_history_m3":"___", 
"all.read_meter.cons_history_m3_facturation":"___", 
"all.read_meter.cons_history_m3_radio":"___", 
"all.read_meter.type.M":"___", 
"all.read_meter.type.A":"___", 
"all.read_meter.type.T":"___", 
"all.read_meter.consumptions":"___", 
"all.read_meter.read_meters":"___", 
"all.read_meter.not_read_meters":"___", 
"all.alarm_meter.alarm":"___", 
"all.alarm_meter.alarm_plural":"___", 
"all.alarm_meter.alarm_type":"___", 
"all.alarm_meter.alarms_list":"___", 
"all.alarm_meter.show_masked_alarm_plural":"___", 
"all.alarm_meter.alarm_history_plural":"___", 
"all.radio.radio":"___", 
"all.radio.radio_plural":"___", 
"all.radio.all_radio_nb":"___", 
"all.radio.current_radio":"___", 
"all.radio.go_to_radio":"___", 
"all.radio.no_linked_radio":"___", 
"all.radio.radio_history":"___", 
"all.radio.no_old_radio_linked":"___", 
"all.radio.radio_manufacturer":"___", 
"all.radio.radio_model":"___", 
"all.radio.mount_date":"___", 
"edit_device.field_label.phone_name":"___", 
"edit_device.field_error_msg.name_required_min_2_char":"___", 
"edit_device.main_title.edit_phone":"___", 
"edit_device.main_title.delete_phone":"___", 
"edit_device.text.code_max_2_char":"___", 
"edit_device.modal.text_confirm":"___", 
"edit_device.modal.text_warning_confirm":"___", 
"edit_device.modal.text_warning_confirm_round":"___", 
"edit_device.modal.text_warning_confirm_x_round":"___", 
"gestionnaires.main_title.managers":"___", 
"gestionnaires.tree_view_placeholder.search_manager":"___", 
"import_constructeur.main_title.import_mfr":"___", 
"import_silex.main_title.import_silex":"___", 
"import_silex.input_label.silex_file":"___", 
"import_silex.error_band.load_x_file_failed":"___", 
"import_silex.loading_band.silex_file_loading":"___", 
"import_silex.submission_title.submission_file":"___", 
"import_silex.button.admin_submit":"___", 
"import_silex.button.radio_file_submit":"___", 
"import_silex.success_msg.x_loaded_silex_file":"___", 
"import_silex.success_msg.x_loaded_silex_file_plural":"___", 
"import_silex.report_title.importation_report":"___", 
"import_silex.column_header.round_code":"___", 
"import_silex.column_header.round_name":"___", 
"import_silex.column_header.round_status":"___", 
"import_silex.column_header.first_row":"___", 
"import_silex.column_header.last_row":"___", 
"import_silex.status_text.already_exist":"___", 
"import_silex.status_text.imported":"___", 
"import_silex.error_band.already_exists_round":"___", 
"import_silex.error_band.already_exists_rounds":"___", 
"list_adder.main_title.add_x_list":"___", 
"list_adder.modal_header.add_x_type":"___", 
"list_adder.modal_header.file_x_type":"___", 
"list_marquages.loading_band.loading_marking":"___", 
"list_messages.loading_band.loading_msg":"___", 
"list_message_add_file":"___", 
"list_message_no_file":"___", 
"list_message_or":"___", 
"list_message_manually":"___", 
"list_message_add":"___", 
"synchronisation.loading_band.loading_rounds_":"___", 
"synchronisation.option.partial_sync":"___", 
"synchronisation.error_band.devices_not_connected":"___", 
"tournee_ressources.title_tab.rounds":"___", 
"tournee_ressources.column_text.affected_phone":"___", 
"tournee_ressources.column_text.round_code":"___", 
"tournee_ressources.column_exported_tooltip.unload_round":"___", 
"tournee_ressources.column_exported_tooltip.load_round":"___", 
"tournee_ressources.column_exported_tooltip.waiting_load":"___", 
"tournee_ressources.column_exported_tooltip.unload_all":"___", 
"tournee_ressources.column_exported_tooltip.waiting_unload":"___", 
"tournee_ressources.column_exported_tooltip.exported_x_date":"___", 
"tournee_ressources.column_exported_tooltip.never_exported":"___", 
"tournee_ressources.column_exported_tooltip.exported_round":"___", 
"tournee_ressources.tooltip.delete_round":"___", 
"tournee_ressources.tooltip.export_round":"___", 
"tournee_ressources.tooltip.access_round_detail":"___", 
"tournee_ressources.popup_title.ask_delete_x_round":"___", 
"tournee_ressources.popup_title.some_round_cant_delete":"___", 
"tournee_ressources.deletable_rounds_confirmation":"___", 
"tournee_ressources.no_round_can_be_deleted":"___", 
"tournee_ressources.popup_title.ask_delete_x_round_plural":"___", 
"tournee_ressources.popup_msg.delete_x_round_data":"___", 
"tournee_ressources.popup_msg.delete_x_round_data_plural":"___", 
"tournee_ressources.popup_msg.delete_x_round_not_eligible":"___", 
"tournee_ressources.popup_msg.delete_x_round_not_eligible_plural":"___", 
"tournee_ressources.column.avancement":"___", 
"synchro.command.listround":"___", 
"synchro.command.listdevice":"___", 
"synchro.command.uploadround":"___", 
"synchro.command.downloadround":"___", 
"synchro.command.downloadroundresponse":"___", 
"synchro.command.closedevice":"___", 
"synchro.command.closedeviceresponse":"___", 
"synchro.command.listroundresponse":"___", 
"synchro.command.uploadroundresponse":"___", 
"synchro.command.listdeviceresponse":"___", 
"synchro.command.removeround":"___", 
"synchro.command.removeroundresponse":"___", 
"synchro.command.downloadmark":"___", 
"synchro.command.downloadmessage":"___", 
"synchro.command.busydevice":"___", 
"synchro.command.error":"___", 
"synchro.command.downloadamrkey":"___", 
"tournee_ressources.delete_round":"___", 
"tournee_ressources.cannot_delete_round":"___", 
"tournee_ressources.round_not_selected":"___", 
"tournee_ressources.round_pending_affectation":"___", 
"tournee_ressources.round_pending_deaffectation":"___", 
"tournee_ressources.round_modif_without_export":"___", 
"tournee_ressources.round_used_by_devices":"___", 
"tournee_ressources.round_never_exported":"___", 
"tournee_ressources.deleting_round":"___", 
"tournee_ressources.deleting_round_plural":"___", 
"conflict_handling.change_type.meter_change":"___", 
"conflict_handling.title.singular":"___", 
"conflict_handling.title.plural":"___", 
"conflict_handling.title.neutral":"___", 
"conflict_handling.associated_devices":"___", 
"conflict_handling.author":"___", 
"conflict_handling.handling.main_instruction":"___", 
"conflict_handling.execution_time.delayed":"___", 
"conflict_handling.execution_time.immediate":"___", 
"conflict_handling.no_conflict_handling":"___", 
"conflict_handling.loading":"___", 
"conflict_handling.no_conflict_handling_on_round":"___", 
"conflict_handling.conflict_open_button":"___", 
"conflict_handling.conflict_in_round":"___", 
"conflict_handling.conflict_in_round_and_device":"___", 
"conflict_handling.no_date":"___", 
"conflict_handling.conflict_in_device":"___", 
"tournee_detail.info_container.round_code":"___", 
"tournee_detail.info_container.round_msg":"___", 
"tournee_detail.info_container.start_read_date":"___", 
"tournee_detail.info_container.all_meter_nb":"___", 
"tournee_detail.info_container.all_radio_nb":"___", 
"tournee_detail.map_title.any_meter_gps":"___", 
"tournee_detail.field_placeholder.write_note":"___", 
"tournee_detail.tooltip.edit_note":"___", 
"tournee_detail.main_title.round_name":"___", 
"tournee_fiche.main_title.record":"___", 
"tournee_fiche.main_title.record_plural":"___", 
"tournee_fiche.tooltip.invalid_cache":"___", 
"tournee_fiche.loading_band.load_round_record":"___", 
"tournee_stat.container_title.radio_type":"___", 
"tournee_stat.container_title.radio_read_state":"___", 
"tournee_stat.container_title.cons_state":"___", 
"tournee_stat.container_title.status_edited":"___", 
"tournee_stat.container_state_read.not_read":"___", 
"tournee_stat.container_state_read.not_radio":"___", 
"tournee_stat.container_state_read.read_with_alarm":"___", 
"tournee_stat.container_state_read.read_with_no_alarm":"___", 
"tournee_stat.container_label.edited_not_indexed":"___", 
"tournee_stat.container_label.edited_not_indexed_plural":"___", 
"tournee_stat.container_label.not_edited_not_indexed":"___", 
"tournee_stat.container_label.not_edited_not_indexed_plural":"___", 
"tournee_stat.container_title.nb_meter_year":"___", 
"tournee_stat.container_title.nb_meter":"___", 
"tournee_creation.container_label.template":"___", 
"tournee_creation.container_label.create_tournee":"___", 
"tournee_creation.container_label.template_list":"___", 
"tournee_creation.container_label.tournee_informations":"___", 
"tournee_creation.container_label.tournee_template":"___", 
"tournee_creation.input_label.autorize_meter_change":"___", 
"tournee_creation.dropzone.or_drop_file":"___", 
"tournee_creation.dropzone.drop_autorized":"___", 
"tournee_creation.dropzone.drop_unautorized":"___", 
"tournee_creation.dropzone.incorrect_format ":"___", 
"tournee_creation.dropzone.authorized_format":"___", 
"tournee_creation.button.start":"___", 
"tournee_creation.input_label.line_x":"___", 
"tournee_creation.container_label.template_creation":"___", 
"tournee_creation.container_label.tag_creation":"___", 
"tournee_creation.container_label.list_tag":"___", 
"tournee_creation.container_label.list_tag_subtitle":"___", 
"tournee_creation.input_label.modifiable":"___", 
"tournee_creation.input_label.unmodifiable":"___", 
"tournee_creation.input_label.code_placeholder":"___", 
"tournee_creation.input_label.message_placeholder":"___", 
"tournee_creation.input_label.wheels_placeholder":"___", 
"tournee_creation.input_label.pdi_template_placeholder":"___", 
"tournee_creation.input_label.meter_template_placeholder":"___", 
"tournee_creation.template_popover.title":"___", 
"tournee_creation.template_popover.confirm_text":"___", 
"tournee_creation.input_error.empty_round_code":"___", 
"tournee_creation.input_error.round_code_already_exists":"___", 
"tournee_creation.input_error.empty_template":"___", 
"tournee_creation.input_error.round_wheels_too_high":"___", 
"tournee_creation.input_error.round_wheels_too_low":"___", 
"tournee_creation.input_error.template_name_already_exists":"___", 
"tournee_creation.input_error.empty_template_name":"___", 
"tournee_creation.input_error.line_too_long":"___", 
"tournee_creation.input_error.empty_tag_name":"___", 
"tournee_creation.input_error.tag_name_already_exists":"___", 
"tournee_creation.input_error.empty_tag_info":"___", 
"tournee_creation.input_error.empty_tag_size":"___", 
"tournee_creation.input_error.tag_size_too_high":"___", 
"tournee_creation.input_error.tag_size_too_low":"___", 
"tournee_creation.input_label.type_text":"___", 
"tournee_creation.input_label.type_number":"___", 
"tournee_creation.success.templateCreation":"___", 
"tournee_creation.success.templateUpdate":"___", 
"tournee_creation.success.templateDeletion":"___", 
"tournee_creation.success.tagCreation":"___", 
"tournee_creation.success.tagUpdate":"___", 
"tournee_creation.success.tagDeletion":"___", 
"tournee_creation.success.roundCreation":"___", 
"tournee_creation.loading_band.template_creation":"___", 
"tournee_creation.loading_band.template_update":"___", 
"tournee_creation.loading_band.template_deletion":"___", 
"tournee_creation.loading_band.tag_creation":"___", 
"tournee_creation.loading_band.tag_update":"___", 
"tournee_creation.loading_band.tag_deletion":"___", 
"tournee_creation.loading_band.tournee_creation":"___", 
"tournee_creation.error_band.tag_not_found":"___", 
"tournee_creation.error_band.tag_still_linked":"___", 
"tournee_creation.error_band.errors_in_tag":"___", 
"tournee_creation.error_band.template_not_found":"___", 
"tournee_creation.error_band.errors_in_template":"___", 
"tournee_creation.error_band.tag_in_use":"___", 
"tournee_creation.error_band.errors_in_tournee":"___", 
"tournee_creation.input_error.tournee_name_is_empty":"___", 
"tournee_creation.input_error.tournee_name_already_exists":"___", 
"tournee_creation.input_error.tournee_code_is_empty":"___", 
"tournee_creation.input_error.tournee_default_wheels_too_low":"___", 
"tournee_creation.input_error.tournee_default_wheels_too_high":"___", 
"tournee_creation.input_error.tournee_collect_point_template_is_missing":"___", 
"tournee_creation.input_error.tournee_meter_template_is_missing":"___", 
"tournee_creation.input_error.tournee_meter_list_is_empty":"___", 
"tournee_creation.input_error.tournee_meter_list_size_over_limit":"___", 
"create_meter.main_title.create_meter":"___", 
"create_meter.field_validate.serial_least_5_char":"___", 
"create_meter.field_validate.serial_max_20_char":"___", 
"create_meter.field_validate.serial_valid":"___", 
"create_meter.field_error_msg.need_serial":"___", 
"create_meter.success_band.meter_created":"___", 
"create_pdi.main_title.create_pdi":"___", 
"location_address_chooser.loading_band.search_gps":"___", 
"location_address_chooser.link_container_title.link_meter":"___", 
"location_address_chooser.success_band.pdi_created":"___", 
"location_address_chooser.warning_message.format_city_name":"___", 
"location_address_chooser.warning_message.format_streetnumber_name":"___", 
"location_address_chooser.warning_message.format_zipcode_name":"___", 
"stock_importation.text.launch_by":"___", 
"stock_importation.main_title.import_billing":"___", 
"stock_importation.input_file_label.import_file":"___", 
"stock_importation.step_1_loading_band.send_file":"___", 
"stock_importation.step_2_loading_band.check_file":"___", 
"stock_importation.step_3_success_band.integration_x_row_and_x_modified":"___", 
"stock_importation.step_3_success_band.nb_meter_archived":"___", 
"stock_importation.step_3_success_band.integration_x_row_and_x_modified_plural":"___", 
"stock_importation.error.serial_too_long":"___", 
"stock_importation.error.wheels_wrong_value":"___", 
"stock_importation.error.date_too_old":"___", 
"stock_importation.error.index_too_long":"___", 
"stock_importation.error.depose_date_expected":"___", 
"stock_importation.error.serial_number_missing":"___", 
"stock_importation.error.depose_index_expected":"___", 
"stock_importation.importation_report.action_meter_change":"___", 
"stock_importation.importation_report.action_creation":"___", 
"stock_importation.importation_report.action_unauthorized":"___", 
"stock_importation.importation_report.action_modification":"___", 
"stock_importation.importation_report.detail_address":"___", 
"stock_importation.importation_report.detail_start_date":"___", 
"stock_importation.importation_report.detail_meter_serial":"___", 
"stock_importation.importation_report.detail_notes":"___", 
"stock_importation.importation_report.detail_custom_info":"___", 
"stock_importation.importation_report.detail_state":"___", 
"stock_importation.importation_report.detail_revised_wheels":"___", 
"stock_importation.importation_report.detail_meter_model":"___", 
"stock_importation.importation_report.detail_empty":"___", 
"stock_importation.importation_report.download_button":"___", 
"stock_importation.importation_report.download_loading":"___", 
"stock_importation.importation_report.download_error":"___", 
"stock_importation.importation_report.detail_gps_longitude":"___", 
"stock_importation.importation_report.detail_gps_latitude":"___", 
"stock_importation.importation_report.detail_gps_altitude":"___", 
"stock_importation.importation_report.detail_gps_score":"___", 
"stock_importation.importation_report.detail_gps_accuracy":"___", 
"stock_importation.error.not_match_config":"___", 
"import.loading_band.import_already_loading":"___", 
"import.step_1_title.first_step_load_file":"___", 
"import.step_1_success_band.load_file":"___", 
"import.step_1_loading_band.file_analysis":"___", 
"import.step_2_title.second_step_check_error":"___", 
"import.list.x_row":"___", 
"import.step.warning":"___", 
"import.step_2_help.check_error_for_import":"___", 
"import.step_2_button.test_integration":"___", 
"import.step_2_success_band.test_integration":"___", 
"import.step_3_title.third_step_check_integration":"___", 
"import.step_error.error":"___", 
"import.step_3_help.check_error_for_finish_import":"___", 
"import.step_3_loading_band.integration_file":"___", 
"import.step_4_title.integration_report":"___", 
"import.anomaly_text.emptyMeterSerial":"___", 
"import.anomaly_text.dateLineError":"___", 
"import.anomaly_text.emptyPDIReference":"___", 
"import.anomaly_text.doublonMeter":"___", 
"import.anomaly_text.doublonPdi":"___", 
"import.anomaly_text.unfoundModel":"___", 
"import.anomaly_text.datePoseBeforeActual":"___", 
"import.anomaly_text.collectPointUsedByRound":"___", 
"import.anomaly_text.meterUsedByRound":"___", 
"import.anomaly_text.doublons":"___", 
"import.anomaly_text.doublonsMeter":"___", 
"import.anomaly_text.doublonsPdi":"___", 
"import.anomaly_text.field_with_date_inf_1970":"___", 
"import.anomaly_text.datePoseNull":"___", 
"import_component.input_file_label.import_file":"___", 
"import_component.step_2_loading_band.testing_file":"___", 
"import_component.step_3_success_band.integration_x_row_success":"___", 
"import_component.step_3_success_band.integration_x_row_success_plural":"___", 
"alert_content.alarm_title.alarm_mfr":"___", 
"alert_content.alarm_title.read_gap":"___", 
"alert_content.alarm_title.real_cons":"___", 
"alert_content.alarm_title.cons_avg":"___", 
"alert_content.alarm_title.cons_comparison":"___", 
"alert_content.alarm_title.cons_gap":"___", 
"alert_content.alarm_title.cons_alert":"___", 
"alert_content.alarm_text.alarm_meter_since_integration":"___", 
"alert_content.alarm_text.cons_of_period":"___", 
"alert_content.alarm_text.read_gap_today":"___", 
"alert_content.alarm_text.cons_limit_since_integration":"___", 
"alert_content.alarm_text.m3_cons_interval_of_cons_avg":"___", 
"alert_content.alarm_text.cons_interval_of_two_meter":"___", 
"alert_content.alarm_text.pct_cons_interval_of_cons_avg":"___", 
"alert_content.alarm_result.result":"___", 
"alert_content.alarm_result.x_cons_gap":"___", 
"alert_content.meter_type.ref":"___", 
"alert_content.meter_type.witness":"___", 
"alert_content.table_title.alert_meter_list_and_info":"___", 
"alert_content.table_title.alert_virtual_meter_list_and_info":"___", 
"alert_content.warning.no_alert_found":"___", 
"alert_content.column_text.gap_last_date":"___", 
"alert_content.column_text.to_date":"___", 
"alert_content.column_text.since_date":"___", 
"alert_content.column_text.ref_cons":"___", 
"alert_content.column_text.avg_day_cons":"___", 
"alert_content.column_text.cons_gap":"___", 
"alert_content.column_text.read_date":"___", 
"alert_creator.name.rangeconsumption":"___", 
"alert_creator.name.gapconsumption":"___", 
"alert_creator.name.gapconsumptioncomparison":"___", 
"alert_creator.name.alarms":"___", 
"alert_creator.name.averagerangeconsumption":"___", 
"alert_creator.name.rangerealconsumption":"___", 
"alert_creator.name.noreadformeteralert":"___", 
"alert_creator.name.consumptionhour":"___", 
"alert_creator.extra_info.rangeconsumption":"___", 
"alert_creator.extra_info.gapconsumption":"___", 
"alert_creator.extra_info.gapconsumptioncomparison":"___", 
"alert_creator.extra_info.alarms":"___", 
"alert_creator.extra_info.averagerangeconsumption":"___", 
"alert_creator.extra_info.rangerealconsumption":"___", 
"alert_creator.extra_info.noreadformeteralert":"___", 
"alert_creator.extra_info.consumptionhour":"___", 
"alert_creator.unit.number":"___", 
"alert_creator.unit.m3":"___", 
"alert_creator.unit.percent":"___", 
"alert_creator.unit.time":"___", 
"best_date_component.label.start_date":"___", 
"best_date_component.label.end_date":"___", 
"best_date_component.label.zoom":"___", 
"best_date_component.zoom_option.hour":"___", 
"best_date_component.zoom_option.day":"___", 
"best_date_component.zoom_option.week":"___", 
"best_date_component.zoom_option.month":"___", 
"best_date_component.zoom_option.year":"___", 
"best_date_component.text.custom":"___", 
"best_date_component.error":"___", 
"bread_crumb.name.crystal_cloud":"___", 
"bread_crumb.name.location":"___", 
"bread_crumb.name.location_plural":"___", 
"bread_crumb.name.masks":"___", 
"bread_crumb.name.dashboard":"___", 
"bread_crumb.name.dashboard_plural":"___", 
"bread_crumb.name.user":"___", 
"bread_crumb.name.user_plural":"___", 
"bread_crumb.name.widget":"___", 
"bread_crumb.name.widget_plural":"___", 
"bread_crumb.name.meter":"___", 
"bread_crumb.name.meter_plural":"___", 
"bread_crumb.name.virtual_meter":"___", 
"bread_crumb.name.virtual_meter_plural":"___", 
"bread_crumb.name.radio":"___", 
"bread_crumb.name.radio_plural":"___", 
"bread_crumb.name.pdi":"___", 
"bread_crumb.name.pdi_plural":"___", 
"bread_crumb.name.new":"___", 
"bread_crumb.name.search":"___", 
"bread_crumb.name.notif":"___", 
"bread_crumb.name.notif_plural":"___", 
"bread_crumb.name.alert":"___", 
"bread_crumb.name.alert_plural":"___", 
"bread_crumb.name.detail":"___", 
"bread_crumb.name.detail_plural":"___", 
"bread_crumb.name.report":"___", 
"bread_crumb.name.report_plural":"___", 
"bread_crumb.name.stock":"___", 
"bread_crumb.name.create_pdi":"___", 
"bread_crumb.name.create_meter":"___", 
"bread_crumb.name.billing_import":"___", 
"bread_crumb.name.link_pdi":"___", 
"bread_crumb.name.unlink_pdi":"___", 
"bread_crumb.name.synchro":"___", 
"bread_crumb.name.round":"___", 
"bread_crumb.name.round_plural":"___", 
"bread_crumb.name.record":"___", 
"bread_crumb.name.record_plural":"___", 
"bread_crumb.name.phone":"___", 
"bread_crumb.name.phone_plural":"___", 
"bread_crumb.name.import_mfr":"___", 
"bread_crumb.name.silex_import":"___", 
"bread_crumb.name.msg_list":"___", 
"bread_crumb.name.marking_list":"___", 
"bread_crumb.name.manager":"___", 
"bread_crumb.name.manager_plural":"___", 
"bread_crumb.name.export":"___", 
"bread_crumb.name.stat":"___", 
"bread_crumb.name.stat_plural":"___", 
"bread_crumb.name.forbidden_access":"___", 
"bread_crumb.name.profile":"___", 
"bread_crumb.name.profile_plural":"___", 
"bread_crumb.name.edit_x_virtual_meter":"___", 
"bread_crumb.name.edit_x_alarm":"___", 
"bread_crumb.name.edit_x_mask_alarm":"___", 
"bread_crumb.name.edit_x_custom_export":"___", 
"bread_crumb.name.personal_dashboard_of_x":"___", 
"bread_crumb.name.telereleve":"___", 
"bread_crumb.name.provisionning":"___", 
"bread_crumb.name.support":"___", 
"bread_crumb.name.sync":"___", 
"bread_crumb.name.remotereading":"___", 
"bread_crumb.name.synchrotools":"___", 
"bread_crumb.name.importbilling":"___", 
"bread_crumb.name.export_brut":"___", 
"bread_crumb.name.new_tournee":"___", 
"bread_crumb.name.create_tournee":"___", 
"bread_crumb.name.templates":"___", 
"confirm.msg.you_sure":"___", 
"confirm.title.warning":"___", 
"confirm.confirm_text.ok":"___", 
"error_boundary.main_title.cannot_show_page":"___", 
"error_boundary.data":"___", 
"forbidden.main_title.not_authorized":"___", 
"help_modal.button_send.send_msg":"___", 
"help_modal.main_title.send_msg_to_support":"___", 
"help_modal.success_msg.msg_sent_thanks":"___", 
"help_modal.field_label.msg_subject":"___", 
"help_modal.field_required.min_size_10_char":"___", 
"help_modal.field_subject_error.max_size_10_char":"___", 
"help_modal.field_msg_error.max_size_10_char":"___", 
"not_found.main_title.not_found":"___", 
"notification_modal.tooltip_notif.notif":"___", 
"notification_modal.tooltip_notif.notif_plural":"___", 
"notification_modal.tooltip_notif.x_notif_not_read":"___", 
"notification_modal.tooltip_notif.x_notif_not_read_plural":"___", 
"search.main_title_label.fast_search":"___", 
"search.main_title.general":"___", 
"search.input_search.serial_or_address":"___", 
"search.info_tooltip.search_in_pdi_meter_radio":"___", 
"search.search_result.result_of_search":"___", 
"search.search_result.x_pdi":"___", 
"search.search_result.x_meter":"___", 
"search.search_result.x_meter_plural":"___", 
"search.search_result.x_radio":"___", 
"search.search_result.x_radio_plural":"___", 
"search.info_title.general_info":"___", 
"search.info_title.general_info_plural":"___", 
"search.record_access_button.go_to_record":"___", 
"sidebar.nav_link.home":"___", 
"sidebar.nav_link.location":"___", 
"sidebar.nav_link.location_plural":"___", 
"sidebar.nav_link.round":"___", 
"sidebar.nav_link.round_plural":"___", 
"sidebar.nav_link.profile_manager":"___", 
"sidebar.nav_link.profile_manager_plural":"___", 
"sidebar.nav_link.user_manager":"___", 
"sidebar.nav_link.user_manager_plural":"___", 
"sidebar.nav_link.my_info_plural":"___", 
"sidebar.nav_link.info_plural":"___", 
"sidebar.nav_link.import_billing":"___", 
"sidebar.nav_link.global_search":"___", 
"sidebar.nav_link.lora_import":"___", 
"sidebar.nav_link.installer_manager":"___", 
"sidebar_location.nav_link.round_detail_plural":"___", 
"sidebar_location.nav_link.location_detail_plural":"___", 
"sidebar_location.nav_link.record_plural":"___", 
"sidebar_location.nav_link.stat_plural":"___", 
"sidebar_location.nav_link.dashboard_plural":"___", 
"sidebar_location.nav_link.alert_plural":"___", 
"sidebar_location.nav_link.mask_alarm_plural":"___", 
"sidebar_location.nav_link.meter_plural":"___", 
"sidebar_location.nav_link.virtual_meter_plural":"___", 
"sidebar_location.nav_link.radio":"___", 
"sidebar_location.nav_link.pdi":"___", 
"sidebar_location.nav_link.search":"___", 
"sidebar_location.nav_link.export":"___", 
"sidebar_stock.nav_title.stock":"___", 
"sidebar_stock.nav_link.resources_list":"___", 
"sidebar_stock.nav_link.create_meter":"___", 
"sidebar_stock.nav_link.create_pdi":"___", 
"sidebar_synchro.nav_title.synchro":"___", 
"sidebar_synchro.nav_link.round":"___", 
"sidebar_synchro.nav_link.round_plural":"___", 
"sidebar_synchro.nav_link.phone":"___", 
"sidebar_synchro.nav_link.phone_plural":"___", 
"sidebar_synchro.nav_link.import_mfr":"___", 
"sidebar_synchro.nav_link.msg_list":"___", 
"sidebar_synchro.nav_link.marking_list":"___", 
"sidebar_synchro.nav_link.silex_import":"___", 
"sidebar_synchro.nav_link.enquiry":"___", 
"sidebar_synchro.nav_link.enquiry_plural":"___", 
"sidebar_synchro.nav_link.new_round":"___", 
"sidebar_tournees.nav_link.round_detail":"___", 
"sidebar_tournees.nav_link.record_plural":"___", 
"sidebar_tournees.nav_link.stat_plural":"___", 
"single_gmap.loading_band.loading_map":"___", 
"single_gmap.failure_text.not_able_show_map":"___", 
"single_gmap.map_setting.language":"___", 
"single_gmap.text.no_radio":"___", 
"tableau_gestion.menu_option.add":"___", 
"tableau_gestion.menu_option.unlink":"___", 
"tableau_gestion.menu_option.link":"___", 
"tableau_gestion.menu_option.alarm_plural":"___", 
"tableau_gestion.loading_band.exporting":"___", 
"tableau_gestion.error_band.exporting":"___", 
"tournee_navbar.nav_tooltip.synchro":"___", 
"tournee_navbar.nav_tooltip.stock":"___", 
"tournee_navbar.nav_tooltip.dashboard_favorite_plural":"___", 
"tournee_navbar.nav_tooltip.my_info_plural":"___", 
"tournee_navbar.nav_tooltip.disconnect":"___", 
"edit_dashboard.main_title.edit_dashboard":"___", 
"edit_dashboard.name_field_label.dashboard_name":"___", 
"edit_dashboard.name_field_error_msg.name_need_least_2_char":"___", 
"locations.loading_band.loading_location_info":"___", 
"locations.button.access_round":"___", 
"locations.error_msg.no_location_found":"___", 
"locations.placeholder.search_location":"___", 
"locations.placeholder.search_rounds":"___", 
"locations.title.all_rounds":"___", 
"locations.text.total_nb_meter":"___", 
"locations.text.total_nb_sensor":"___", 
"locations.text.total_nb_radio":"___", 
"locations.text.no_sub_location":"___", 
"dashboard_helper.dashboard_name.my_dashboard":"___", 
"dashboard_helper.dashboard_name.dashboard_of_x":"___", 
"fiche_detail.label.detail_plural":"___", 
"fiche_detail.label.diameter":"___", 
"fiche_detail.label.install_year":"___", 
"fiche_detail.label.wheel_nb":"___", 
"fiche_detail.label.revision":"___", 
"fiche_detail.label.mfr":"___", 
"fiche_detail.label.model":"___", 
"fiche_detail.label.msg":"___", 
"fiche_detail.label.particular_msg":"___", 
"fiche_detail.title.new_meter":"___", 
"fiche_detail.title.old_meter":"___", 
"fiche_detail.title.old_meter_plural":"___", 
"fiche_detail.label.show_old_meter":"___", 
"fiche_detail.label.show_new_meter":"___", 
"fiche_detail.title.meter":"___", 
"fiche_detail.title.revise_meter":"___", 
"fiche_detail.title.install_read":"___", 
"fiche_detail.title.uninstall_read":"___", 
"fiche_detail.title.cons_m3":"___", 
"fiche_detail.title.avg":"___", 
"fiche_detail.label.radio":"___", 
"fiche_detail.label.new_radio":"___", 
"fiche_detail.label.old_radio":"___", 
"fiche_detail.label.show_old_radio":"___", 
"fiche_detail.label.show_new_radio":"___", 
"fiche_detail.label.internal_id":"___", 
"fiche_detail.label.install_date":"___", 
"fiche_detail.label.pulse_weight":"___", 
"fiche_detail.error_msg.no_radio":"___", 
"fiche_detail.title.historical_alarm_plural":"___", 
"fiche_detail.title.previous_alarm_plural":"___", 
"fiche_detail.title.current_alarm_plural":"___", 
"fiche_detail.title.record_number":"___", 
"fiche_detail.label.marking":"___", 
"fiche_detail.label.last_update":"___", 
"fiche_detail.title.no_gps":"___", 
"fiche_detail.title.historical_cons":"___", 
"fiche_detail.title.start_index":"___", 
"fiche_detail.title.closest_reference_index":"___", 
"fiche_detail.title.previous_index":"___", 
"fiche_detail.title.end_index":"___", 
"fiche_detail.title.current_index":"___", 
"fiche_detail.text.no_alarm":"___", 
"fiche_detail.text.saisie_index":"___", 
"fiche_detail.title.round":"___", 
"fiche_detail.conso.range_tooltip":"___", 
"fiche_detail.conso.null_range_tooltip":"___", 
"index_wrapped.label.read":"___", 
"index_wrapped.read_method.radio":"___", 
"index_wrapped.read_method.manual":"___", 
"index_wrapped.label.date":"___", 
"index_wrapped.no_data.index_not_present":"___", 
"index_wrapped.popover.title":"___", 
"index_wrapped.popover.confirm_text":"___", 
"static_list.label.not_indexed_manual_read":"___", 
"static_list.label.not_indexed_radio_read":"___", 
"static_list.label.not_indexed_telereleve_read":"___", 
"static_list.label.edited_manual_read":"___", 
"static_list.label.edited_radio_read":"___", 
"static_list.label.edited_with_telereleve":"___", 
"static_list.label.indexed_no_radio":"___", 
"static_list.label.indexed_with_radio":"___", 
"static_list.label.indexed_with_telereleve":"___", 
"static_list.label.indexed_radio_read":"___", 
"static_list.label.indexed_telereleve_read":"___", 
"static_list.label.indexed_radio_read_with_alarm":"___", 
"static_list.label.indexed_telereleve_read_with_alarm":"___", 
"static_list.label.normal":"___", 
"static_list.value.normal_cons":"___", 
"static_list.label.weak":"___", 
"static_list.value.weak_cons":"___", 
"static_list.label.nul":"___", 
"static_list.value.nul_cons":"___", 
"static_list.label.strong":"___", 
"static_list.value.strong_cons":"___", 
"column_list.label.pdi_ref":"___", 
"column_list.label.meter":"___", 
"column_list.label.id_meter":"___", 
"column_list.label.serial_meter":"___", 
"column_list.label.revised_serial_meter":"___", 
"column_list.label.c":"___", 
"column_list.label.city":"___", 
"column_list.label.country":"___", 
"column_list.label.index_meter":"___", 
"column_list.label.serial_radio":"___", 
"column_list.label.alarm":"___", 
"column_list.label.altitude":"___", 
"column_list.label.network_code":"___", 
"column_list.label.round_code":"___", 
"column_list.label.radio_mfr":"___", 
"column_list.label.meter_mfr":"___", 
"column_list.label.last_read_date":"___", 
"column_list.label.sealing_color":"___", 
"column_list.label.meter_diameter":"___", 
"column_list.label.radio_id":"___", 
"column_list.label.pdi_id":"___", 
"column_list.label.meter_id":"___", 
"bookmark_location.error_band.impossible_get_favorite":"___", 
"bookmark.loading_band.loading_favorite_dashboard":"___", 
"display_bookmark.link_text.dashboard_nb_x":"___", 
"column_selector.no_options_msg.no_more_column_available":"___", 
"column_selector.no_options_msg.no_corresponding_column":"___", 
"column_selector.text.column_plural":"___", 
"column_selector.text.delete_saved_conf":"___", 
"column_selector.text.no_saved_col_conf":"___", 
"home_page.title.hello":"___", 
"home_page.subtitle.your_dashboard":"___", 
"list_render.list_type.pdi":"___", 
"list_render.list_type.radio_plural":"___", 
"list_render.list_type.meter_plural":"___", 
"list_render.list_type.virtual_meter_plural":"___", 
"list_render.delete_modal_title.delete_x_virtual_meter":"___", 
"list_tools.label.cons_state":"___", 
"list_tools.text.cons":"___", 
"notifications.tooltip.delete_selected":"___", 
"notifications.loading_band.loading_notification_plural":"___", 
"notifications.popup_delete_title.delete_selected_notification_plural":"___", 
"notifications.popup_delete_title.delete_notification":"___", 
"notifications.popup_delete_msg.sure_delete_selected_notification_plural":"___", 
"notifications.popup_delete_msg.sure_delete_notification":"___", 
"historique_compteur.title.install_date":"___", 
"historique_compteur.title.uninstall_date":"___", 
"historique_compteur.text.dashboard_1":"___", 
"historique_compteur.text.serial_meter":"___", 
"historique_compteur.text.actual_meter":"___", 
"historique_compteur.text.go_to_meter_detail":"___", 
"porte_ouverte.label.duration_door_opening":"___", 
"porte_ouverte.label.door_opening":"___", 
"location_creator.title.add_location":"___", 
"location_creator.title.edit_location":"___", 
"identity_chooser.text.location_name":"___", 
"identity_chooser.text.new_location_name":"___", 
"identity_chooser.text.link_to_location":"___", 
"identity_chooser.text.select_location_to_link":"___", 
"identity_chooser.input_option.no_location":"___", 
"identity_chooser.text.location_x_belong_to_first_level":"___", 
"identity_chooser.text.location_x_belong_to_location_x":"___", 
"identity_chooser.error_msg.max_length_name":"___", 
"identity_chooser.error_msg.max_length_name_required":"___", 
"identity_chooser.warning_msg.no_location_to_link":"___", 
"identity_chooser.button.create_location":"___", 
"identity_chooser.button.edit_location":"___", 
"widget.type_source.meter":"___", 
"widget.type_source.radio":"___", 
"widget.display_type.curve":"___", 
"widget.display_type.histogram":"___", 
"widget.display_value.meterreadingvalue":"___", 
"widget.display_value.meterreadingconsumption":"___", 
"widget.display_value.radioreadingvalue":"___", 
"widget.display_value.meteralarm":"___", 
"widget.display_value.metermultireadingvalue":"___", 
"widget.display_value.metermultireadingconsumption":"___", 
"widget.display_value.meteryield":"___", 
"widget.condition_title.meterserialnumber":"___", 
"widget.condition_title.radioserialnumber":"___", 
"widget.condition_title.meterserialnumberref":"___", 
"widget.condition_title.meterserialnumber_":"___", 
"widget.condition_title.readingdateinterval":"___", 
"widget.condition_title.alarmtype":"___", 
"widget.condition_title.meters":"___", 
"widget.condition_title.rangeconsumption":"___", 
"widget.condition_title.gapconsumption":"___", 
"widget.condition_title.meterslistautocomplete":"___", 
"widget.condition_title.period":"___", 
"widget.condition_title.meterserialnumber_1":"___", 
"widget.graph_type.index_meter_histogram":"___", 
"widget.graph_type.index_meter_curve":"___", 
"widget.graph_type.consumption_meter_histogram":"___", 
"widget.graph_type.consumption_meter_curve":"___", 
"widget.graph_type.alarm_meter":"___", 
"widget.graph_type.multiple_meter_consumption_histogram":"___", 
"widget.graph_type.multiple_meter_consumption_curve":"___", 
"widget.graph_type.multiple_meter_index_histogram":"___", 
"widget.graph_type.multiple_meter_index_curve":"___", 
"widget.graph_type.index_radio_histogram":"___", 
"widget.graph_type.index_radio_curve":"___", 
"widget.graph_type.meter_yield_curve":"___", 
"widget_last.text.no_alarm_type":"___", 
"widget_last.title.summary":"___", 
"widget_last.text.widget_name":"___", 
"widget_last.text.widget_type":"___", 
"widget_last.text.widget_display":"___", 
"widget.text.not_configured":"___", 
"location_creator.button.check_on_map":"___", 
"location_creator.title.location_place":"___", 
"location_creator.text.address_entry":"___", 
"location_creator.button.edit_address":"___", 
"location_creator.text.corrected_address":"___", 
"location_creator.text.choose_address":"___", 
"location_creator.title.location_contact":"___", 
"location_creator.title.location_note":"___", 
"location_creator.placeholder.write_note":"___", 
"location_creator.text.no_location_selected":"___", 
"display_dashboards.title.favorite_dashboard":"___", 
"display_dashboards.title.dashboard_plural":"___", 
"display_dashboards.filter_placeholder.filter_by_dashboard":"___", 
"display_dashboards.button.delete_dashboard":"___", 
"display_dashboards.button.rename_dashboard":"___", 
"display_dashboard.confirm_modal_title.delete_x_dashboard":"___", 
"display_dashboards.delete_modal.title":"___", 
"display_dashboards.delete_modal. warning_confirm_dashboard":"___", 
"display_dashboards.delete_modal.warning_confirm":"___", 
"display_dashboards.delete_modal.confirm":"___", 
"add_dashboard.title.add_dashboard":"___", 
"add_dashboard.text.dashboard_name":"___", 
"add_dashboard.error_msg.dashboard_name_error":"___", 
"dashboard.button.switch_display_mode":"___", 
"dashboard.button.exit_display_mode":"___", 
"dashboard.button.delete_widget_from_dashboard":"___", 
"dashboard.button.delete_from_favorite":"___", 
"add_widget.button.add_widget":"___", 
"add_widget.text.reading_value":"___", 
"add_widget.text.source":"___", 
"add_group.title":"___", 
"widget_creator.title.configure_widget":"___", 
"widget_option.error_msg.widget_name_error":"___", 
"range_configurator.title.gap_consumption_percent":"___", 
"alarm_creator.title.create_alert":"___", 
"alarm_creator.text.add_alert":"___", 
"alarms.filter_placeholder.alert_filter":"___", 
"alarm_configurator.title.alert_edition":"___", 
"alarm_configurator.placeholder.alert_name":"___", 
"alarm_options.text.calculation_method":"___", 
"alarm_options.text.alert_name_required_with_min_length":"___", 
"period_selector.text.trigger_period":"___", 
"period_selector.text.schedule_interval":"___", 
"mask_alarm.button.access_card":"___", 
"mask_alarm.text.delete_mask_action":"___", 
"mask_alarm.text.nb_days_with_alarm":"___", 
"mask_alarm.text.no_meter_associated":"___", 
"mask_alarm.text.alarm_of_meters":"___", 
"mask_alarm.text.no_alarm_masked":"___", 
"source_sheet.text.internal_number":"___", 
"source_sheet.text.current_meter":"___", 
"source_sheet.text.no_linked_meter":"___", 
"source_sheet.text.meter_linked_history":"___", 
"source_sheet.text.no_old_meter_linked":"___", 
"printable_source_sheet.text.x_data_from_x_to_x_zoom_x":"___", 
"virtual_meter.text.last_best_read":"___", 
"virtual_meter.text.invalid":"___", 
"virtual_meter.text.read_meter_plural":"___", 
"virtual_meter.text.only_valid_read_plural":"___", 
"virtual_meter.text.load_index":"___", 
"virtual_meter.text.load_consumption":"___", 
"virtual_meter.text.load_last_best_read":"___", 
"virtual_meter.text.load_alarms":"___", 
"alarm_selector.text.type_to_display_plural":"___", 
"saving_settings_modal.text.saving_conf":"___", 
"saving_settings_modal.text.backup_name":"___", 
"source_sheet_pdi.text.alarm_mask":"___", 
"source_sheet_pdi.text.display_interval":"___", 
"source_sheet_pdi.text.meter_loading":"___", 
"source_sheet_pdi.text.terrain":"___", 
"source_sheet_pdi.text.many_data_loading":"___", 
"source_sheet_pdi.text.invalid_interval":"___", 
"source_sheet_pdi.text.averages_unavailable":"___", 
"source_sheet_pdi.text.no_data_display":"___", 
"source_sheet_pdi.text.display_type_read":"___", 
"export_pdi.text.export_preview":"___", 
"export_pdi.text.refresh_preview":"___", 
"export_pdi.text.cvs_export_progress":"___", 
"export_pdi.text.cvs_export_done":"___", 
"export_pdi.text.separator":"___", 
"export_pdi.text.comma":"___", 
"export_pdi.text.semicolon":"___", 
"export_pdi.text.vertical_bar":"___", 
"users.text.user_management":"___", 
"users.text.search_user":"___", 
"users.text.add_user":"___", 
"users.text.add_tourmaline_user":"___", 
"add_user.button.add_new_user":"___", 
"add_user.field.ucid_code_error":"___", 
"add_user.field.email_unique":"___", 
"add_user.field.email_error":"___", 
"add_user.field.name_error":"___", 
"add_user.field.field_only_alphabetical":"___", 
"add_user.field.minimum_x_size":"___", 
"add_user.field.maximum_x_size":"___", 
"user.text.remove_profile":"___", 
"user.text.select_location":"___", 
"user.text.select_profile":"___", 
"user.text.remove_permission_to_user":"___", 
"user.text.last_connection":"___", 
"user.text.generate_pwd":"___", 
"user.text.reset_pwd":"___", 
"user.text.admin_all_gestionnaires":"___", 
"user.text.admin_all_locations":"___", 
"user.text.user_is_admin":"___", 
"user.text.select_lang":"___", 
"user.text.lang_choosen":"___", 
"user.text.select_role":"___", 
"user.text.please_fill_password":"___", 
"user.text.new_password":"___", 
"user.text.rewrite_new_password":"___", 
"user.text.password_not_match":"___", 
"user.text.delete":"___", 
"user.text.unlock":"___", 
"user.text.lock":"___", 
"user.text.sure":"___", 
"user.text.nb.phone":"___", 
"user.text.nb.deliveries":"___", 
"user.text.authorization":"___", 
"user.text.language":"___", 
"user.text.french":"___", 
"user.text.english":"___", 
"user.text.spanish":"___", 
"user.button.save.key":"___", 
"location_modal.title_ask.delete_x_location":"___", 
"location_modal.text.delete_location_effect":"___", 
"columns.text.date_added":"___", 
"columns.text.abc_order":"___", 
"columns.text.abc_disorder":"___", 
"columns.text.max_to_min":"___", 
"columns.text.min_to_max":"___", 
"columns.text.code_abc_order":"___", 
"columns.text.code_abc_disorder":"___", 
"columns.text.wording_abc_order":"___", 
"columns.text.wording_abc_disorder":"___", 
"columns.text.actions_max_to_min":"___", 
"columns.text.actions_min_to_max":"___", 
"columns.text.loaded_max_to_min":"___", 
"columns.text.loaded_min_to_max":"___", 
"avancement_chart.text.indexed_x_on_x":"___", 
"avancement_chart.text.indexed_x_on_x_plural":"___", 
"avancement_chart.text.edited_x_on_x":"___", 
"avancement_chart.text.edited_x_on_x_plural":"___", 
"device_info.text.waiting_action":"___", 
"device_info.text.waiting_action_plural":"___", 
"device_info.text.x_loaded_round":"___", 
"device_info.text.x_loaded_round_plural":"___", 
"device_info.text.first_com":"___", 
"device_info.error_text.load":"___", 
"device_info.error_text.integrate":"___", 
"device_info.error_text.contact":"___", 
"round_info.text.indexed":"___", 
"round_info.text.remaining":"___", 
"round_info.text.edited":"___", 
"round_info.text.indexed_x_percent_plural":"___", 
"round_info.text.edited_x_percent_plural":"___", 
"round_info.text.loaded_on_x_phone":"___", 
"round_info.text.loaded_on_x_phone_plural":"___", 
"task_duplication.text.code_x":"___", 
"alarm_updater.text.alert_edition":"___", 
"conso_interval.text.threshold_required":"___", 
"conso_interval.text.threshold_x":"___", 
"last.text.recipient_user_plural":"___", 
"manage_alarm.text.x_linked_pdi_to_location":"___", 
"manage_alarm.text.x_linked_pdi_to_location_plural":"___", 
"manage_alarm.text.x_dissociate_pdi_to_location":"___", 
"manage_alarm.text.x_dissociate_pdi_to_location_plural":"___", 
"cluster_marker_gm.title.meter_detail_plural":"___", 
"cluster_meter_list.text.close_meter_list":"___", 
"google_map_react_c.text.center_selected_meter":"___", 
"contact_chooser.text.lastname_only_letters":"___", 
"contact_chooser.text.firstname_only_letters":"___", 
"contact_chooser.text.phone_number_needs_10_number":"___", 
"contact_chooser.text.give_name_info":"___", 
"contact_chooser.text.give_email_info":"___", 
"loraimport.text.nb_link_in_file":"___", 
"loraimport.text.nb_new_link_in_file":"___", 
"loraimport.text.import_lorafile_processing":"___", 
"loraimport.text.import_file_failed":"___", 
"loraimport.text.import_lora":"___", 
"loraimport.text.lorafile_process_success":"___", 
"import_billing.label.header_size":"___", 
"import_billing.label.first_cell_row":"___", 
"import_billing.label.first_cell_col":"___", 
"import_billing.label.column_size":"___", 
"import_billing.label.end_of_first_cell":"___", 
"import_billing.label.first_line_number":"___", 
"import_billing.label.id_cell_row":"___", 
"import_billing.label.id_cell_col":"___", 
"import_billing.label.id_cell_content":"___", 
"import_billing.label.id_cell":"___", 
"import_billing.label.sub":"___", 
"import_billing.label.pdi":"___", 
"import_billing.label.round":"___", 
"import_billing.label.cpt":"___", 
"import_billing.label.else":"___", 
"import_billing.label.pdi_ref":"___", 
"import_billing.label.pdi_state":"___", 
"import_billing.label.pdi_address_number":"___", 
"import_billing.label.pdi_number_additionnal":"___", 
"import_billing.label.pdi_street":"___", 
"import_billing.label.pdi_additionnal_street":"___", 
"import_billing.label.pdi_complement_address":"___", 
"import_billing.label.pdi_appart":"___", 
"import_billing.label.pdi_floor":"___", 
"import_billing.label.pdi_building":"___", 
"import_billing.label.pdi_stair":"___", 
"import_billing.label.pdi_zipcode":"___", 
"import_billing.label.pdi_city":"___", 
"import_billing.label.pdi_note_plural":"___", 
"import_billing.label.round_code":"___", 
"import_billing.label.round_name":"___", 
"import_billing.label.cpt_number":"___", 
"import_billing.label.cpt_wheels":"___", 
"import_billing.label.cpt_install_date":"___", 
"import_billing.label.cpt_oldnumber":"___", 
"import_billing.label.date_depose":"___", 
"import_billing.label.index_depose":"___", 
"import_billing.label.index_pose":"___", 
"import_billing.label.cpt_color_filling":"___", 
"import_billing.label.branchement_code_reseau":"___", 
"import_billing.label.date_start_subscription":"___", 
"import_billing.label.date_end_subscription":"___", 
"import_billing.label.state_subscription":"___", 
"import_billing.label.gps":"___", 
"import_billing.label.gps_latitude":"___", 
"import_billing.label.gps_longitude":"___", 
"import_billing.label.gps_altitude":"___", 
"import_billing.label.gps_score":"___", 
"import_billing.label.gps_source":"___", 
"import_billing.placeholder.value_select":"___", 
"import_billing.state.removed.match":"___", 
"import_billing.state.inactif.match":"___", 
"pdi.PDI_GPS_LONGITUDE":"___", 
"pdi.PDI_GPS_LATITUDE":"___", 
"pdi.PDI_GPS_SCORE":"___", 
"pdi.PDI_GPS_SOURCE":"___", 
"pdi.PDI_GPS_ALTITUDE":"___", 
"pdi.PDI_GPS_ACCURACY":"___", 
"pdi.PDI_REF_ID":"___", 
"export_round_factu":"___", 
"export_round_teleo":"___", 
"nbColumns":"___", 
"general.label.round_code":"___", 
"general.label.round_default_wheels":"___", 
"general.label.round_libelle":"___", 
"general.label.default_language":"___", 
"general.label.end_date_demo":"___", 
"general.label.in_demo_mode":"___", 
"general.label.saphir_version_current":"___", 
"general.label.skip_trigger_alarm":"___", 
"general.label.fetch_size_query":"___", 
"general.label.supported_language":"___", 
"general.label.timezone_id":"___", 
"general.label.archive.inactif.pdi":"___", 
"general.label.apply.if_in_round":"___", 
"general.label.telereleve.patrimoine.last_update":"___", 
"general.ignore.t2t4.mrlId":"___", 
"general.seuil.limit":"___", 
"general.only.current_read_table.pdi":"___", 
"synchronisation.label.export_silex_encoding":"___", 
"synchronisation.label.export_silex_encoding_detection_method":"___", 
"synchronisation.label.export_silex_name":"___", 
"synchronisation.label.telereleve_sup_startdate":"___", 
"synchronisation.label.refresh_synchro_devices":"___", 
"synchronisation.label.remove_missing_t3":"___", 
"synchronisation.label.silex_export_geoloc":"___", 
"synchronisation.label.silex_export_radio":"___", 
"synchronisation.label.silex_export_enquete":"___", 
"synchronisation.label.silex_import_allow_doublons":"___", 
"synchronisation.label.synchro_allow_creation_radiomodel":"___", 
"synchronisation.label.stop_and_go":"___", 
"synchronisation.label.import_all":"___", 
"synchronisation.label.import_t0":"___", 
"synchronisation.label.import_t1":"___", 
"synchronisation.label.import_t2":"___", 
"synchronisation.label.import_t3":"___", 
"synchronisation.label.import_t4":"___", 
"synchronisation.label.import_t6":"___", 
"synchronisation.label.import_t7":"___", 
"synchronisation.label.saphir_version_current":"___", 
"synchronisation.ignore_invalid_histo_infuture":"___", 
"synchronisation.ignore_previous_index_value":"___", 
"synchronisation.option.rotate.round":"___", 
"remote_reading.label.default_format_lora_file":"___", 
"remote_reading.label.default_format_lora_file_column_number":"___", 
"remote_reading.label.has_remote_reading":"___", 
"remote_reading.label.app_eui_column_index":"___", 
"remote_reading.label.app_key_column_index":"___", 
"remote_reading.label.dev_eui_column_index":"___", 
"remote_reading.label.lora_serial_column_index":"___", 
"remote_reading.label.radio_serial_column_index":"___", 
"remote_reading.nbColumns":"___", 
"report.label.logo_path":"___", 
"report.label.specific_template_directory":"___", 
"report.label.custom_template_names":"___", 
"support.title.report":"___", 
"meter.unit.W":"___", 
"meter.unit.E":"___", 
"meter.unit.":"___", 
"meter.unit.C":"___", 
"meter.unit.H":"___", 
"meter.unit.G":"___", 
"meter.unit.T":"___", 
"meter.unit.O":"___", 
"meter.type.W":"___", 
"meter.type.E":"___", 
"meter.type.":"___", 
"meter.type.C":"___", 
"meter.type.H":"___", 
"meter.type.G":"___", 
"meter.type.T":"___", 
"meter.type.O":"___", 
"last_reading.text.tooltip_real_previous_index":"___", 
"last_reading.text.tooltip_reference_index":"___", 
"last_reading.text.no_last_read":"___", 
"last_reading.text.no_previous_read":"___", 
"last_reading.text.index_meter":"___", 
"tournee_band.text.list_devices":"___", 
"releve_meter_status.text.red_manual":"___", 
"releve_meter_status.text.red_radio":"___", 
"releve_meter_status.text.yellow_manual":"___", 
"releve_meter_status.text.yellow_radio":"___", 
"releve_meter_status.text.green_manual":"___", 
"releve_meter_status.text.green_radio":"___", 
"releve_meter_status.text.blue_radio":"___", 
"releve_meter_status.text.blue_telereleve":"___", 
"releve_meter_status.text.purple_radio":"___", 
"releve_meter_status.text.purple_telereleve":"___", 
"sensor.text.sensor":"___", 
"sensor.text.sensor_plural":"___", 
"sensor.plannification":"___", 
"sensor.title.manual_management":"___", 
"sensor.title.synchro":"___", 
"sensor.title.frequency":"___", 
"sensor.error.wrong_plage":"___", 
"sensor.error.previous_date_sup":"___", 
"sensor.error.fix_previous_plage":"___", 
"sensor.text.minutes":"___", 
"sensor.text.hour":"___", 
"sensor.error.synchro":"___", 
"sensor.title.vanne_status":"___", 
"sensor.title.open_planned":"___", 
"sensor.text.remove_interval":"___", 
"sensor.text.add_interval":"___", 
"sensor.text.when_vanne_open":"___", 
"sensor.text.when_vanne_close":"___", 
"sensor.type.STR-EV":"___", 
"sensor.type.ADE-COM":"___", 
"sensor.type.ADE-CO2":"___", 
"sensor.type.ADE-DRYC":"___", 
"sensor.type.ADE-DTP":"___", 
"sensor.type.ADE-TEMP":"___", 
"sensor.text.sensor_type":"___", 
"sensor.text.serial":"___", 
"sensor.dashboard":"___", 
"sensor.data.temperature":"___", 
"sensor.data.temperature2":"___", 
"sensor.data.humidity":"___", 
"sensor.data.co2":"___", 
"sensor.data.pressure":"___", 
"sensor.data.valveOpen":"___", 
"support.title.importbilling":"___", 
"support.title.remotereading":"___", 
"support.title.sync":"___", 
"support.title.general":"___", 
"support.title.column":"___", 
"support.title.script":"___", 
"support.option.duplicateAllowed":"___", 
"support.subtitle.autochange":"___", 
"support.subtitle.billing_import":"___", 
"support.autochange.truncate":"___", 
"support.autochange.truncate_at":"___", 
"support.autochange.left":"___", 
"support.autochange.right":"___", 
"support.autochange.replace_date":"___", 
"support.title.export":"___", 
"replace_silex_lines":"___", 
"saphir_version_current":"___", 
"stop_and_go":"___", 
"refresh_synchro_devices":"___", 
"silex.export.alarm.past":"___", 
"silex.export.change_readmethod_T_to_A":"___", 
"export_round_csv_custom":"___", 
"export_round_csv_custom_format_dir":"___", 
"import_round_csv_custom":"___", 
"export_round_csv_encoding":"___", 
"synchrotools.option.download_round":"___", 
"synchrotools.option.remove_round":"___", 
"synchrotools.option.upload_round":"___", 
"synchrotools.option.rounds":"___", 
"synchrotools.button.connect":"___", 
"synchrotools.button.disconnect":"___", 
"synchrotools.option.command":"___", 
"synchrotools.button.launchCommand":"___", 
"remotereading.text.read_with_no_alarm":"___", 
"remotereading.text.read_with_alarm":"___", 
"remotereading.text.read_sort_by_model":"___", 
"remotereading.text.meter_alarm":"___", 
"remotereading.text.without_alarm":"___", 
"remotereading.text.meter_alarm_by_model":"___", 
"remotereading.text.alarm_type":"___", 
"remotereading.text.synthese":"___", 
"remotereading.text.in_alarm":"___", 
"remotereading.text.read":"___", 
"remotereading.text.not_read":"___", 
"remotereading.text.no_meter_in_interval ":"___", 
"remotereading.text.link_meter_now":"___", 
"remotereading.text.no_meter_link_to":"___", 
"remotereading.text.select_default":"___", 
"remotereading.text.map_view":"___", 
"remotereading.text.list_view":"___", 
"remotereading.text.for_current_round":"___", 
"remotereading.text.for_selected_period":"___", 
"remotereading.text.by_read_mode":"___", 
"remotereading.text.by_manufacturer":"___", 
"remotereading.text.by_status":"___", 
"remotereading.text.multi_choices_allow":"___", 
"remotereading.text.read_method":"___", 
"remotereading.text.select_at_least":"___", 
"remotereading.text.one_manufacturer":"___", 
"remotereading.text.one_status":"___", 
"remotereading.text.one_read_method":"___", 
"remotereading.text.manufacturer":"___", 
"remotereading.text.alarm_detail":"___", 
"remotereading.text.match_selected_meter":"___", 
"remotereading.text.meter_geolocated":"___", 
"remotereading.text.no_meter_to_display":"___", 
"enquete.text.add_enq_page":"___", 
"enquete.text.link_enq_round":"___", 
"enquete.text.name_enquete_page":"___", 
"enquete.text.field_list":"___", 
"enquete.text.field_type":"___", 
"enquete.field_type.A":"___", 
"enquete.field_type.N":"___", 
"enquete.field_type.C":"___", 
"enquete.text.delete_field":"___", 
"enquete.text.delete_field_impossible":"___", 
"enquete.text.add_field":"___", 
"enquete.text.field":"___", 
"enquete.text.field_libelle":"___", 
"enquete.text.field_size":"___", 
"enquete.text.field_default_value":"___", 
"enquete.text.no_field_exist":"___", 
"enquete.text.no_field_selected":"___", 
"enquete.text.choices_list":"___", 
"enquete.text.associated_page":"___", 
"enquetes.text.edit_enquete":"___", 
"enquete.text.edit_impossible_in_used":"___", 
"enquete.text.enquete_deleted_links_warning":"___", 
"enquete.text.deletion_enquetes":"___", 
"enquete.text.placeholder_create_options":"___", 
"enquete.text.remove_impossible_in_used":"___", 
"enquetes.text.remove_enquete":"___", 
"enquetes.popover.title":"___", 
"enquete.popover.confirm_text":"___", 
"enquete.error.choice_max_length":"___", 
"enquete.text.round_loaded_devices_no_update_allow":"___", 
"enquete.text.select_enquete":"___", 
"enquete.text.each_option_max_20_char":"___", 
"enquete.text.libelle_max_16_char":"___", 
"enquete.text.size_max_30":"___", 
"enquete.text.default_value_max_size":"___", 
"lostpass.text.password_identifiant_lost":"___", 
"lostpass.text.get_identifants":"___", 
"lostpass.text.enter_email":"___", 
"lostpass.text.your_email":"___", 
"lostpass.text.enter_valid_email":"___", 
"lostpass.text.check_email":"___", 
"alarm.type.fraud":"___", 
"alarm.type.leak":"___", 
"alarm.type.backflow":"___", 
"alarm.type.meterblock":"___", 
"alarm.type.clock":"___", 
"alarm.type.moduledefect":"___", 
"alarm.type.moduleremoved":"___", 
"alarm.type.configuration":"___", 
"alarm.type.reversedmeter":"___", 
"alarm.type.overflow":"___", 
"alarm.type.underflow":"___", 
"alarm.type.brokenpipe":"___", 
"alarm.type.battery":"___", 
"alarm.type.pocfraud":"___", 
"alarm.type.pocleak":"___", 
"alarm.type.pocbackflow":"___", 
"alarm.type.pocmeterblock":"___", 
"alarm.type.pocoverflow":"___", 
"alarm.type.endofbattery":"___", 
"alarm.type.clockfault":"___", 
"alarm.type.itronbatteryalarm":"___", 
"alarm.type.itrondetectionalarm":"___", 
"alarm.type.itronmagnetictamperalarm":"___", 
"alarm.type.itronmemorizedremovalalarm":"___", 
"alarm.type.itronmemoryalarm":"___", 
"alarm.type.itronrealtimeremovalalarm":"___", 
"alarm.type.itronmemorizedtamperalarm":"___", 
"alarm.type.itronrealtimetamperalarm":"___", 
"alarm.type.itronnotwatermediumtype":"___", 
"alarm.type.itronchecksumcodealarm":"___", 
"alarm.type.itroncorruptdataalarm":"___", 
"alarm.type.itroncurrentyearyearlyindexabovethresholdalarm":"___", 
"alarm.type.itroncurrentyearyearlyindexbelowthresholdalarm":"___", 
"alarm.type.itrondateandtimereconfigurationalarm":"___", 
"alarm.type.itronmeterblockedalarm":"___", 
"alarm.type.itronmonthlyleakagealarm":"___", 
"alarm.type.itronpreviousyearyearlyindexabovethresholdalarm":"___", 
"alarm.type.itronpreviousyearyearlyindexbelowthresholdalarm":"___", 
"alarm.type.itronrfwakeupalarm":"___", 
"alarm.type.itronreconfigurationalarm":"___", 
"alarm.type.itronreversedmeteralarm":"___", 
"alarm.type.itronyearlybackflowalarm":"___", 
"alarm.type.itronyearlyleakagealarm":"___", 
"alarm.type.itronyearlypeakalarm":"___", 
"alarm.type.itroneverbluenabled":"___", 
"alarm.type.itroncablecutalarm":"___", 
"alarm.type.itron.everbluenabled":"___", 
"alarm.type.pastleak":"___", 
"alarm.type.currentleak":"___", 
"alarm.type.pastmecafraud":"___", 
"alarm.type.currentmecafraud":"___", 
"alarm.type.pastmagnetfraud":"___", 
"alarm.type.currentmagnetfraud":"___", 
"alarm.type.pastmeterblock":"___", 
"alarm.type.currentmeterblock":"___", 
"alarm.type.metrologyerror":"___", 
"alarm.type.mediumabsent":"___", 
"alarm.type.specificerror":"___", 
"alarm.type.modulesetting":"___", 
"alarm.type.temperature":"___", 
"alarm.type.fraud.current":"___", 
"alarm.type.fraud.poc":"___", 
"alarm.type.fraud.past":"___", 
"alarm.type.leak.past":"___", 
"alarm.type.leak.poc":"___", 
"alarm.type.leak.current":"___", 
"alarm.type.leak.current.high":"___", 
"alarm.type.backflow.past":"___", 
"alarm.type.backflow.current":"___", 
"alarm.type.backflow.poc":"___", 
"alarm.type.pastbackflow":"___", 
"alarm.type.currentbackflow":"___", 
"alarm.type.underflow.past":"___", 
"alarm.type.pocunderflow":"___", 
"alarm.type.underflow.current":"___", 
"alarm.type.underflow.poc":"___", 
"alarm.type.overflow.past":"___", 
"alarm.type.overflow.current":"___", 
"alarm.type.overflow.poc":"___", 
"alarm.type.meterblock.past":"___", 
"alarm.type.meterblock.current":"___", 
"alarm.type.meterblock.poc":"___", 
"alarm.type.battery.past":"___", 
"alarm.type.battery.current":"___", 
"alarm.type.battery.poc":"___", 
"alarm.type.clock.past":"___", 
"alarm.type.clock.current":"___", 
"alarm.type.clock.poc":"___", 
"alarm.type.moduledefect.past":"___", 
"alarm.type.moduledefect.current":"___", 
"alarm.type.moduledefect.poc":"___", 
"alarm.type.modulesetting.past":"___", 
"alarm.type.modulesetting.current":"___", 
"alarm.type.modulesetting.poc":"___", 
"alarm.type.reversedmeter.past":"___", 
"alarm.type.reversedmeter.current":"___", 
"alarm.type.reversedmeter.poc":"___", 
"alarm.type.mediumabsent.past":"___", 
"alarm.type.mediumabsent.current":"___", 
"alarm.type.mediumabsent.poc":"___", 
"alarm.type.brokenpipe.past":"___", 
"alarm.type.brokenpipe.current":"___", 
"alarm.type.brokenpipe.poc":"___", 
"alarm.type.temperature.past":"___", 
"alarm.type.temperature.current":"___", 
"alarm.type.temperature.poc":"___", 
"alarm.type.specificerror.past":"___", 
"alarm.type.specificerror.current":"___", 
"alarm.type.specificerror.poc":"___", 
"alarm.type.fraud.mecanic.past":"___", 
"alarm.type.fraud.mecanic.current":"___", 
"alarm.type.fraud.mecanic.poc":"___", 
"alarm.type.fraud.magnetic.past":"___", 
"alarm.type.fraud.magnetic.current":"___", 
"alarm.type.fraud.magnetic.poc":"___", 
"alarm.type.temperature.low.past":"___", 
"alarm.type.temperature.low.current":"___", 
"alarm.type.temperature.low.poc":"___", 
"alarm.type.temperature.hight.past":"___", 
"alarm.type.temperature.hight.current":"___", 
"alarm.type.temperature.hight.poc":"___", 
"alarm.type.moduleremoved.past":"___", 
"alarm.type.moduleremoved.current":"___", 
"alarm.type.moduleremoved.poc":"___", 
"alarm.type.itron.battery":"___", 
"alarm.type.itron.detection":"___", 
"alarm.type.itron.magnetic.tamper":"___", 
"alarm.type.itron.memorizedremoval":"___", 
"alarm.type.itron.memory":"___", 
"alarm.type.itron.realtimeremoval":"___", 
"alarm.type.itron.memorizedtamper":"___", 
"alarm.type.itron.realtimetamper":"___", 
"alarm.type.itron.notwatermedium":"___", 
"alarm.type.itron.checksumcode":"___", 
"alarm.type.itron.corruptdata":"___", 
"alarm.type.itron.currentyearyearlyindexabovethreshold":"___", 
"alarm.type.itron.currentyearyearlyindexbelowthreshold":"___", 
"alarm.type.itron.dateandtimereconfiguration":"___", 
"alarm.type.itron.meterblocked":"___", 
"alarm.type.itron.monthlyleakage":"___", 
"alarm.type.itron.previousyearyearlyindexabovethreshold":"___", 
"alarm.type.itron.previousyearyearlyindexbelowthreshold":"___", 
"alarm.type.itron.rfwakeup":"___", 
"alarm.type.itron.reconfiguration":"___", 
"alarm.type.itron.reversedmeter":"___", 
"alarm.type.itron.yearlybackflow":"___", 
"alarm.type.itron.yearlyleakage":"___", 
"alarm.type.itron.yearlypeak":"___", 
"alarm.type.itron.cablecut":"___", 
"alarm.type.birdz.reversedmountmeter":"___", 
"alarm.type.birdz.oversizedsmalldiameter":"___", 
"alarm.type.birdz.oversizedbigdiameter":"___", 
"alarm.type.birdz.undersizedsmalldiameter":"___", 
"alarm.type.birdz.undersizedbigdiameter":"___", 
"alarm.type.birdz.irregularblock":"___", 
"alarm.type.birdz.lowtemperaturefreeze":"___", 
"alarm.type.birdz.reversedmeter":"___", 
"alarm.type.birdz.magneticfraud":"___", 
"alarm.type.birdz.unmountedmodule":"___", 
"alarm.type.birdz.backflowl1":"___", 
"alarm.type.birdz.backflowl2":"___", 
"alarm.type.birdz.hightemperaturesmalldiameter":"___", 
"alarm.type.birdz.hightemperaturebigdiameter":"___", 
"alarm.type.birdz.stoppersistence":"___", 
"alarm.type.birdz.irregularleak":"___", 
"alarm.type.integra.meterblocked":"___", 
"alarm.type.integra.minwatertemp":"___", 
"alarm.type.integra.maxwatertemp":"___", 
"alarm.type.integra.minambianttemp":"___", 
"alarm.type.integra.maxambianttemp":"___", 
"alarm.type.integra.lowbattery":"___", 
"alarm.type.integra.bubbles":"___", 
"alarm.type.integra.tamper":"___", 
"alarm.type.integra.leak":"___", 
"alarm.type.integra.burst":"___", 
"alarm.type.integra.dry":"___", 
"alarm.type.integra.reverseflow":"___", 
"alarm.type.integra.asiccommunicationerror":"___", 
"alarm.type.axioma.abdormalcondition":"___", 
"alarm.type.axioma.burst":"___", 
"alarm.type.axioma.burst.temporary":"___", 
"alarm.type.axioma.burst.permanent":"___", 
"alarm.type.axioma.backflow":"___", 
"alarm.type.axioma.backflow.temporary":"___", 
"alarm.type.axioma.backflow.permanent":"___", 
"alarm.type.axioma.waterfreeze":"___", 
"alarm.type.axioma.waterfreeze.temporary":"___", 
"alarm.type.axioma.waterfreeze.permanent":"___", 
"alarm.type.axioma.leakage":"___", 
"alarm.type.axioma.leakage.temporary":"___", 
"alarm.type.axioma.leakage.permanent":"___", 
"alarm.type.axioma.lowbattery":"___", 
"alarm.type.axioma.lowbattery.temporary":"___", 
"alarm.type.axioma.lowbattery.permanent":"___", 
"alarm.type.axioma.dry":"___", 
"alarm.type.axioma.dry.permanent":"___", 
"alarm.type.axioma.manipulation":"___", 
"alarm.type.axioma.manipulation.permanent":"___", 
"alarm.type.axioma.lowtemperature":"___", 
"alarm.type.axioma.lowtemperature.permanent":"___", 
"alarm.type.diehl.leakage":"___", 
"alarm.type.diehl.leakagehist":"___", 
"alarm.type.diehl.manipulation":"___", 
"alarm.type.diehl.manipulationhist":"___", 
"alarm.type.diehl.blocked":"___", 
"alarm.type.diehl.blockedhist":"___", 
"alarm.type.diehl.unclipped":"___", 
"alarm.type.diehl.unclippedhist":"___", 
"alarm.type.diehl.overflow":"___", 
"alarm.type.diehl.overflowhist":"___", 
"alarm.type.diehl.underflow":"___", 
"alarm.type.diehl.underflowhist":"___", 
"alarm.type.diehl.backflow":"___", 
"alarm.type.diehl.backflowhist":"___", 
"alarm.type.diehl.tempsensor":"___", 
"alarm.type.diehl.tempsensorhist":"___", 
"alarm.type.diehl.battery":"___", 
"alarm.type.diehl.batteryhist":"___", 
"alarm.type.birdz.startflowsmalldiameter":"___", 
"alarm.type.birdz.startflowbigdiameter":"___", 
"alarm.type.birdz.undercountbigdiameter":"___", 
"alarm.type.birdz.watchdog":"___", 
"alarm.type.birdz.hourset":"___", 
"alarm.type.birdz.anormalrestflowvalue":"___", 
"alarm.type.birdz.supervisiontension":"___", 
"alarm.type.birdz.modulereconfigured":"___", 
"alarm.type.birdz.solenoidvalve":"___", 
"alarm.type.birdz.noisedefensemaxrssicrossed":"___", 
"alarm.type.birdz.noisedefenseradioreceptionsuspended":"___", 
"alarm.type.birdz.noisedefenselrlisteningsuspended":"___", 
"alarm.type.birdz.lowtemperatureradiostoppage":"___", 
"alarm.type.birdz.limitimmediatealarmcycle":"___", 
"alarm.type.birdz.hoursetdelayexpiration":"___", 
"alarm.type.birdz.acquisitionflooddefault":"___", 
"alarm.type.birdz.destinationtime":"___", 
"mask.text.select_at_least_once_meter":"___", 
"mask.text.select_at_least_once_alarm":"___", 
"columns.meter.serial":"___", 
"columns.serial":"___", 
"columns.radio.serial":"___", 
"columns.withAlarm":"___", 
"columns.alarms":"___", 
"columns.reference":"___", 
"columns.state":"___", 
"columns.withAlarmMasked":"___", 
"columns.triggerAlarms.leak":"___", 
"columns.triggerAlarms.fraud":"___", 
"columns.triggerAlarms.backflow":"___", 
"columns.closeAlarms.leak":"___", 
"columns.closeAlarms.fraud":"___", 
"columns.closeAlarms.backflow":"___", 
"columns.codeTourneeReleve":"___", 
"columns.labelTourneeReleve":"___", 
"columns.splitAlarms.Fraudpoc":"___", 
"columns.splitAlarms.Fraudcurrent":"___", 
"columns.splitAlarms.Fraudpast":"___", 
"columns.splitAlarms.Leakpoc":"___", 
"columns.splitAlarms.Leakcurrent":"___", 
"columns.splitAlarms.Leakpast":"___", 
"columns.splitAlarms.Backflowpoc":"___", 
"columns.splitAlarms.Backflowcurrent":"___", 
"columns.splitAlarms.Backflowpast":"___", 
"columns.splitAlarms.Batterycurrent":"___", 
"columns.splitAlarms.Batterypast":"___", 
"columns.splitAlarms.ModuleDefectcurrent":"___", 
"columns.splitAlarms.ModuleDefectpast":"___", 
"columns.splitAlarms.Clockcurrent":"___", 
"columns.splitAlarms.Clockpast":"___", 
"columns.splitAlarms.MeterBlockpoc":"___", 
"columns.splitAlarms.MeterBlockpast":"___", 
"columns.splitAlarms.Underflowpoc":"___", 
"columns.splitAlarms.Underflowpast":"___", 
"columns.splitAlarms.Underflowcurrent":"___", 
"columns.splitAlarms.Overflowpoc":"___", 
"columns.splitAlarms.Overflowcurrent":"___", 
"columns.splitAlarms.Overflowpast":"___", 
"columns.previousMeter.installDate":"___", 
"columns.previousMeter.previousMountedMeterSerial":"___", 
"columns.previousMeter.deposeIndex":"___", 
"columns.alarmsMasked":"___", 
"columns.simultaneousAlarms":"___", 
"columns.clpInformations":"___", 
"columns.customInformation.couleur_plombage":"___", 
"columns.customInformation.code_reseau":"___", 
"columns.customInformation.code_tournee":"___", 
"columns.customInformation.name_tournee":"___", 
"columns.customInformation.etat_abonnement":"___", 
"columns.customInformation.date_debut_abonnement":"___", 
"columns.customInformation.date_fin_abonnement":"___", 
"columns.customInformation.complement_voie":"___", 
"columns.read.date":"___", 
"columns.read.lastDate":"___", 
"columns.read.meterIndex":"___", 
"columns.read.radioIndex":"___", 
"columns.read.consumption":"___", 
"columns.read.readMethod":"___", 
"columns.read.source":"___", 
"columns.read.previousDate":"___", 
"columns.read.previousMeterIndex":"___", 
"columns.read.offsetIndex":"___", 
"columns.address.city":"___", 
"columns.address.country":"___", 
"columns.address.complement":"___", 
"columns.address.street":"___", 
"columns.address.streetNumber":"___", 
"columns.address.streetNumberAdditionnal":"___", 
"columns.address.concatAdress":"___", 
"columns.address.zipcode":"___", 
"columns.address.appartement":"___", 
"columns.address.batiment":"___", 
"columns.address.etage":"___", 
"columns.address.escalier":"___", 
"columns.meter.diameter":"___", 
"columns.meter.model":"___", 
"columns.model":"___", 
"columns.meter.manufacturer":"___", 
"columns.manufacturer":"___", 
"columns.meter.mtrInformations":"___", 
"columns.meter.startDate":"___", 
"columns.meter.lastUpdateDate":"___", 
"columns.meter.endDate":"___", 
"columns.meter.fluidType":"___", 
"columns.gpsPosition.alt":"___", 
"columns.gpsPosition.lat":"___", 
"columns.gpsPosition.lng":"___", 
"columns.radio.manufacturer":"___", 
"columns.radio.type":"___", 
"columns.updatedAt":"___", 
"columns.name":"___", 
"columns.average":"___", 
"columns.type":"___", 
"columns.location.name":"___", 
"columns.meter.mtrNote":"___", 
"columns.previousRadio.installUnmount":"___", 
"columns.previousRadio.previousMountedRadioSerial":"___", 
"columns.meter.message":"___", 
"columns.releveTerrain.previousIndex":"___", 
"columns.releveTerrain.currentIndex":"___", 
"columns.releveTerrain.previousDate":"___", 
"columns.releveTerrain.currentDate":"___", 
"columns.releveTerrain.consumption":"___", 
"columns.releveTerrain.consumptionState":"___", 
"columns.releveTerrain.seuilOverflow":"___", 
"columns.ficheState":"___", 
"columns.cardOrder":"___", 
"columns.marquage":"___", 
"columns.cptSerial":"___", 
"columns.revisedCptSerial":"___", 
"columns.installDate":"___", 
"columns.wheels":"___", 
"columns.cptManufacturer":"___", 
"columns.mtrInformations":"___", 
"columns.message":"___", 
"columns.particularMessageMeter":"___", 
"columns.hasGpsPosition":"___", 
"columns.oldMeterEndDate":"___", 
"columns.newMeterSerial":"___", 
"columns.installDateNewMeter":"___", 
"columns.radioSerial":"___", 
"columns.radioManufacturer":"___", 
"columns.amrReference":"___", 
"columns.indexed":"___", 
"columns.modified":"___", 
"columns.previousDate":"___", 
"columns.previousIndex":"___", 
"columns.currentDate":"___", 
"columns.currentIndex":"___", 
"columns.consoState":"___", 
"columns.updateDate":"___", 
"columns.lastUpdateByUser":"___", 
"columns.facturationCode":"___", 
"columns.meterSerialNumber":"___", 
"columns.radioSerialNumber":"___", 
"columns.rdsDate":"___", 
"columns.meterIndex":"___", 
"columns.withCreation":"___", 
"columns.t1CurrentExist":"___", 
"columns.readDate":"___", 
"columns.compteurSerial":"___", 
"columns.readCompteurIndex":"___", 
"columns.consumption":"___", 
"columns.readRadioIndex":"___", 
"columns.radioSensorPort":"___", 
"columns.clpReference":"___", 
"columns.readRadioPulse":"___", 
"columns.codeTournee":"___", 
"columns.codeReseau":"___", 
"columns.nomTournee":"___", 
"exportpdi.text.load_reads":"___", 
"exportpdi.text.csv_export_processing":"___", 
"exportpdi.text.no_read_found":"___", 
"columns.withPreviousAlarm":"___", 
"columns.lastUpdate":"___", 
"columns.cptStatusId":"___", 
"columns.newDate":"___", 
"columns.group.pdi":"___", 
"columns.group.triggeralarms":"___", 
"columns.group.closealarms":"___", 
"columns.group.splitalarms":"___", 
"columns.group.previousmeter":"___", 
"columns.group.custominformation":"___", 
"columns.group.address":"___", 
"columns.group.read":"___", 
"columns.group.meter":"___", 
"columns.group.gpsposition":"___", 
"columns.group.radio":"___", 
"columns.group.previousradio":"___", 
"columns.group.releveterrain":"___", 
"columns.exportname":"___", 
"columns.exportfilename":"___", 
"columns.exportowner.username":"___", 
"columns.datePreviousReleve":"___", 
"columns.listReleve_dateReleve":"___", 
"linefilter.operator.date.=":"___", 
"linefilter.operator.date.>=":"___", 
"linefilter.operator.date.<=":"___", 
"linefilter.operator.date.vide":"___", 
"linefilter.operator.date.notvide":"___", 
"linefilter.operator.date.between":"___", 
"linefilter.operator.text.contient":"___", 
"linefilter.operator.text.contientpas":"___", 
"linefilter.operator.text.commencepar":"___", 
"linefilter.operator.text.finipar":"___", 
"linefilter.operator.text.vide":"___", 
"linefilter.operator.text.notvide":"___", 
"linefilter.operator.checkbox.true":"___", 
"linefilter.operator.checkbox.false":"___", 
"linefilter.operator.state.checkbox.true":"___", 
"linefilter.operator.state.checkbox.false":"___", 
"linefilter.operator.number.>":"___", 
"linefilter.operator.number.>=":"___", 
"linefilter.operator.number.<":"___", 
"linefilter.operator.number.<=":"___", 
"linefilter.operator.number.=":"___", 
"linefilter.operator.number.!=":"___", 
"linefilter.operator.number.== null":"___", 
"linefilter.operator.number.!= null":"___", 
"linefilter.userinput.placeholder_input":"___", 
"linefilter.operator.selectcontvalue.=":"___", 
"linefilter.operator.selectcontvalue.!=":"___", 
"linefilter.operator.selectcont.=":"___", 
"linefilter.operator.selectcont.!=":"___", 
"linefilter.operator.selectalarmtypebasic.=":"___", 
"linefilter.operator.selectalarmtypebasic.!=":"___", 
"virtualmeter.text.name_already_exist":"___", 
"permission.text.read.location":"___", 
"permission.text.edit.dashboard":"___", 
"permission.text.edit.virtualMeter":"___", 
"permission.text.edit.alert":"___", 
"permission.text.edit.maskAlarm":"___", 
"permission.text.edit.locationInfo":"___", 
"permission.text.edit.pdi":"___", 
"permission.text.edit.subLocation":"___", 
"permission.text.read.round":"___", 
"permission.text.use.synchro":"___", 
"permission.text.import.synchro":"___", 
"permission.text.edit.round":"___", 
"permission.text.import.factu":"___", 
"permission.text.export.brute":"___", 
"permission.text.delete.phone":"___", 
"users_list.text.view_informations":"___", 
"users_list.text.block_user":"___", 
"users_list.text.unblock_user":"___", 
"users_list.text.delete_user":"___", 
"tournee_fiches.text.recup_telereleve":"___", 
"tournee_fiches.text.recup_reads_telereleve":"___", 
"tournee_fiches.text.reads_get":"___", 
"tournee_fiches.text.reads_get_plural":"___", 
"tournee_fiches.text.new_reads":"___", 
"tournee_fiches.text.new_reads_plural":"___", 
"tournee_fiches.text.replaced_reads":"___", 
"tournee_fiches.text.replaced_reads_plural":"___", 
"tournee_fiches.text.recup_reads_processing":"___", 
"tournee_fiches.text.no_read_found":"___", 
"tournee_fiches.text.replace_reads_by_more_recent":"___", 
"tournee_fiches.text.lookup_reads_processing":"___", 
"tournee_fiches.text.rotate_fiches":"___", 
"tourneefiches.text.rotation_index_description":"___", 
"tourneefiches.text.rotation_index_warning":"___", 
"rapport.name.amrRead":"___", 
"rapport.name.cptRead":"___", 
"rapport.name.cptReadOrderByOrder":"___", 
"rapport.name.cptReadOrderByDate":"___", 
"rapport.name.cptReadOrderByStatus":"___", 
"rapport.name.resumeRead":"___", 
"rapport.name.agingMeter":"___", 
"rapport.name.cptDetail":"___", 
"rapport.name.amrDetail":"___", 
"rapport.name.enqueteDetail":"___", 
"rapport.name.cptReadAndAmr":"___", 
"rapport.name.quickSummary":"___", 
"rapport.name.statusReleve":"___", 
"rapport.option.sort_by":"___", 
"rapport.option.sorted_by":"___", 
"rapport.option.view_pdf":"___", 
"rapport.option.full_screen":"___", 
"rapport.option.preview_pdf":"___", 
"rapport.text.no_report_selected":"___", 
"rapport.error.generic":"___", 
"rapport.error.no_data":"___", 
"import_silex.text.import_rounds":"___", 
"import_silex.text.round_file":"___", 
"import_silex.columns.identifiant":"___", 
"import_silex.columns.field":"___", 
"import_silex.columns.temoin_value":"___", 
"import_silex.columns.generated_value":"___", 
"import_silex.text.wrong_encoding":"___", 
"import_silex.text.load_file_failed":"___", 
"import_silex.text.select_round":"___", 
"import_silex.text.silex_format":"___", 
"import_silex.error_text.lines_incorrect_length":"___", 
"import_silex.error_text.no_line_id":"___", 
"import_silex.error_text.silex_error":"___", 
"import_silex.error_text.no_round_code":"___", 
"import_silex.error_text.no_order_number":"___", 
"import_silex.error_text.no_subscriber_info":"___", 
"import_silex.error_text.no_meter_info":"___", 
"import_silex.error_text.no_theoretical_serial":"___", 
"import_silex.error_text.no_theoretical_old_serial":"___", 
"import_silex.error_text.no_change_meter_authorisation":"___", 
"import_silex.error_text.no_amr_install_date":"___", 
"import_silex.error_text.no_amr_index_number":"___", 
"import_silex.error_text.no_meter_max_value":"___", 
"import_silex.error_text.no_meter_unit":"___", 
"import_silex.error_text.no_amr_reference":"___", 
"import_silex.error_text.no_amr_serial":"___", 
"import_silex.error_text.no_amr_internal_id":"___", 
"import_silex.error_text.no_amr_data":"___", 
"import_silex.error_text.no_pulse_weight":"___", 
"import_silex.error_text.no_amr_max_value":"___", 
"import_silex.error_text.no_previous_read_date":"___", 
"import_silex.error_text.no_previous_meter_index":"___", 
"import_silex.error_text.no_previous_amr_index":"___", 
"import_silex.error_text.no_previous_alarms":"___", 
"import_silex.error_text.no_previous_status":"___", 
"import_silex.error_text.no_new_serial":"___", 
"import_silex.error_text.no_number_of_digits":"___", 
"import_silex.error_text.no_start_index":"___", 
"import_silex.error_text.no_install_date":"___", 
"import_silex.error_text.no_meter_serial":"___", 
"import_silex.error_text.no_read_date":"___", 
"import_silex.error_text.no_meter_index":"___", 
"import_silex.error_text.no_backflow_index":"___", 
"import_silex.error_text.no_fraud_alarm":"___", 
"import_silex.error_text.no_leak_alarm":"___", 
"import_silex.error_text.no_backflow_alarm":"___", 
"import_silex.error_text.no_battery_alarm":"___", 
"import_silex.error_text.no_faulty_module_alarm":"___", 
"import_silex.error_text.no_clock_alarm":"___", 
"import_silex.error_text.no_page_number":"___", 
"import_silex.error_text.no_actual_serial":"___", 
"import_silex.error_text.missing_t0_empty":"___", 
"import_silex.error_text.missing_t1_empty":"___", 
"import_silex.error_text.missing_t2_empty":"___", 
"import_silex.error_text.missing_t5_empty":"___", 
"import_silex.error_text.missing_t6_empty":"___", 
"import_silex.error_text.missing_t2":"___", 
"import_silex.error_text.missing_t5":"___", 
"import_silex.error_text.missing_t6":"___", 
"import_silex.error_text.wrong_data":"___", 
"import_silex.error_text.meter_in_round":"___", 
"import_silex.column_label.errors":"___", 
"import_silex.column_label.lines":"___", 
"import_silex.column_label.types":"___", 
"import_silex.column_label.positions":"___", 
"import_silex.column_label.description":"___", 
"import_silex.column_label.associated_round":"___", 
"provisioning.text.provisionned":"___", 
"provisioning.text.not_provisionned":"___", 
"provisioning.text.actions":"___", 
"telereleveinfo.text.network":"___", 
"telereleveinfo.text.deveui":"___", 
"telereleveinfo.text.last_telereleve_read":"___", 
"telereleveinfo.text.meter_index":"___", 
"telereleveinfo.text.radio_index":"___", 
"telereleveinfo.text.provision_radio":"___", 
"telereleveinfo.text.deprovision_radio":"___", 
"telereleveinfo.text.provision_impossible":"___", 
"telereleveinfo.text.missing_informations":"___", 
"telereleveinfo.text.reprovision_impossible_message":"___", 
"telereleve.text.telereleve":"___", 
"telereleve.column.networkName":"___", 
"telereleve.column.clpReference":"___", 
"telereleve.column.mtrSerialNumber":"___", 
"telereleve.column.radioSerialNumber":"___", 
"telereleve.column.devEUI":"___", 
"telereleve.column.providerName":"___", 
"telereleve.column.canProvision":"___", 
"telereleve.column.adress":"___", 
"telereleve.text.load_telereleve":"___", 
"provisionning.text.provision_processing":"___", 
"custom_export.title.custom_export":"___", 
"custom_export.title.custom_export_plural":"___", 
"custom_export.text.add_custom_export":"___", 
"custom_export.text.export_name":"___", 
"custom_export.text.export_filename":"___", 
"custom_export.text.edit_custom_export":"___", 
"custom_export.text.duplicate":"___", 
"custom_export.text.launch_export":"___", 
"custom_export.text.delete_export":"___", 
"custom_export.text.define_at_launch":"___", 
"custom_export.text.sorting":"___", 
"custom_export.text.add_filter_value_to_filename":"___", 
"custom_export.text.generatedDate":"___", 
"custom_export.text.generatedHour":"___", 
"custom_export.text.nameSite":"___", 
"gateway.text.gateway":"___", 
"gateway_meter_captured.columns.field":"___", 
"gateway_geolocated.columns.field":"___", 
"gateway_name.text.map":"___", 
"gateway_geolocated_meter_captured.columns.field":"___", 
"gateway_geolocated_meter_last_read.text":"___", 
"gateway.registration_date":"___", 
"gayeway.last_communication_date":"___", 
"gateway.model":"___", 
"gateway.informations":"___", 
"manage_pdi.text.nb_meter_to_link":"___", 
"manage_pdi.text.nb_meter_to_unlink":"___", 
"mapbox_button.text.save_polygon":"___", 
"mapbox.return_all_gateways":"___", 
"tournee.text.export.success":"___", 
"tournee.text.export.error_detected":"___", 
"tournee.text.export_factu.success":"___", 
"tournee.text.forbidden":"___", 
"tournee.text.unauthorized":"___", 
"tournee.text.failure":"___", 
"tournee.text.notfound":"___", 
"tournee.text.success":"___", 
"tournee.text.export_round_csv_custom":"___", 
"tournee.text.export_round_csv_custom_choices":"___", 
"tournee.text.export_dat":"___", 
"tournee.text.export_csv":"___", 
"tournee.text.error.lines_incorrect_length":"___", 
"tournee.text.error.wrongData":"___", 
"tournee.text.error.no_line_id":"___", 
"tournee.text.error.silex_format_error":"___", 
"tournee .text.error.last_try":"___", 
"galery.title":"___", 
"galery.title_plural":"___", 
"galery.others":"___", 
"galery.picture_name":"___", 
"galery.fold":"___", 
} 
}; 
