import meterConstants from '../_constants/meter.constants';
import { action } from '../_interfaces/action';

export default function meters(state = {}, action: action) {
  switch (action.type) {
    case meterConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case meterConstants.GETALL_SUCCESS:
      return {
        items: action.meters,
      };
    case meterConstants.GETALL_FAILURE:
      return {
        error: "Impossible d'obtenir la liste distante des compteurs",
      };

    case meterConstants.GETALLWITHCHILDREN_REQUEST:
      return {
        loading: true,
      };
    case meterConstants.GETALLWITHCHILDREN_SUCCESS:
      return {
        items: action.meters,
      };
    case meterConstants.GETALLWITHCHILDREN_FAILURE:
      return {
        error: "Impossible d'obtenir la liste distante des compteurs",
      };

    case meterConstants.GETALLFROMLOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case meterConstants.GETALLFROMLOCATION_SUCCESS:
      console.log(action, state);
      const locations = {
        locationId: action.locationId,
        meters: action.meters.length,
      };
      const stateSite = state.locations || [];
      return {
        ...state,
        locations: [...stateSite, locations],
        loading: false,
      };
    case meterConstants.GETALLFROMLOCATION_FAILURE:
      return {
        ...state,
        error: "Impossible d'obtenir la liste distante des compteurs",
      };
    case meterConstants.GETUNLINKEDMETERS_REQUEST:
      return {
        loading: true,
      };
    case meterConstants.GETUNLINKEDMETERS_SUCCESS:
      return {
        unlinkedMeters: action.unlinkedMeters,
      };
    case meterConstants.GETUNLINKEDMETERS_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des compteurs orphelins",
      };
    case meterConstants.GETMANUFACTURERS_REQUEST:
      return {
        loading: true,
      };
    case meterConstants.GETMANUFACTURERS_SUCCESS:
      return {
        manufacturers: action.manufacturers,
      };
    case meterConstants.GETMANUFACTURERS_FAILURE:
      return {
        error: "Impossible d'obtenir la liste distante des compteurs",
      };
    case meterConstants.GET_METER_INFOS_DATA_REQUEST:
      return {
        ...state,
        loadingData: true,
      };
    case meterConstants.GET_METER_INFOS_DATA_SUCCESS:
      return {
        ...state,
        fetchedMeter: action.meter,
      };
    case meterConstants.GET_METER_INFOS_DATA_FAILURE:
      return {
        error: "Impossible d'obtenir la liste distante des compteurs",
      };
    case meterConstants.FINDINFOWITHSERIAL_REQUEST:
      return {
        loading: true,
      };
    case meterConstants.FINDINFOWITHSERIAL_SUCCESS:
      return {
        serialInfo: action.serialInfo,
      };
    case meterConstants.FINDINFOWITHSERIAL_FAILURE:
      return {
        error: "Impossible d'obtenir une correspondance",
      };
    case meterConstants.CREATEMETER_REQUEST:
      state.createdMeter = { loading: true };
      return {
        ...state,
      };
    case meterConstants.CREATEMETER_SUCCESS:
      state.createdMeter = action.createdMeter;
      return {
        ...state,
      };
    case meterConstants.CREATEMETER_FAILURE:
      state.createdMeter = { error: action.error };
      return {
        ...state,
      };
    case meterConstants.GETINFO_REQUEST:
      return {
        loadingMeterInfo: true,
        ...state,
      };
    case meterConstants.GETINFO_SUCCESS:
      return {
        ...state,
        fetchedMeter: action.fetchedMeter,
        loadingMeterInfo: false,
      };
    case meterConstants.GETINFO_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingMeterInfo: false,
      };
    case meterConstants.GETALLINFO_REQUEST:
      return {
        loading: true,
      };
    case meterConstants.GETALLINFO_SUCCESS:
      return {
        fetchedMeter: action.fetchedMeter,
      };
    case meterConstants.GETALLINFO_FAILURE:
      return {
        error: "Impossible d'obtenir les informations distantes du compteur",
      };
    case meterConstants.GETSTATES_REQUEST:
      return {
        loading: true,
      };
    case meterConstants.GETSTATES_SUCCESS:
      return {
        fetchedMeter: action.fetchedMeter,
      };
    case meterConstants.GETSTATES_FAILURE:
      return {
        error: "Impossible d'obtenir les informations de relevés du compteur",
      };
    case meterConstants.GETALARMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case meterConstants.GETALARMS_SUCCESS:
      return {
        ...state,
        fetchedMeter: action.fetchedMeter,
        loading: false,
      };
    case meterConstants.GETALARMS_FAILURE:
      return {
        ...state,
        error: "Impossible d'obtenir les alarmes du compteur",
      };
    case meterConstants.GETDATA_REQUEST:
      return {
        ...state,
        loadingMeterInfo: true,
      };
    case meterConstants.GETDATA_SUCCESS:
      return {
        ...state,
        loadingMeterInfo: false,
      };
    case meterConstants.GETDATA_FAILURE:
      return {
        error: "Impossible d'obtenir les données complémentaires du compteur",
        loadingMeterInfo: false,
      };
    case meterConstants.GET_ALLFROMPDI_REQUEST:
      return {
        ...state,
        loadingMeterInfo: true,
        requestData: true,
      };
    case meterConstants.GET_ALLFROMPDI_TOOLONG:
      return {
        ...state,
        tooLong: state.requestData,
      };
    case meterConstants.GET_ALLFROMPDI_SUCCESS:
      const newStart: any = [];
      action.meterList.map(el => {
        if (el.general.endDate) {
          newStart.push(el.general.endDate);
        }
      });
      const list = action.meterList ? action.meterList : [];
      return {
        ...state,
        allMetersInfo: list,
        startDates: newStart,
        loadingMeterInfo: false,
        tooLong: false,
        requestData: false,
      };
    case meterConstants.GET_ALLFROMPDI_FAILURE:
      return {
        ...state,
        error: "Impossible d'obtenir les alarmes du compteur",
        requestData: false,
        loadingMeterInfo: false,
      };
    case meterConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
