function getColumns(locationId: number) {
  let data: any = null; //localStorage.getItem(`site${locationId}`);
  if (data && null !== data) {
    data = JSON.parse(data);
    const parsedColumns = data.columns !== undefined ? data.columns : { value: {} };
    return parsedColumns;
  }
  return { value: {} };
}

function setColumns(locationId: number, columns: any) {
  let existingData = localStorage.getItem(`site${locationId}`);
  let baseData: any = {};
  if (existingData && null !== existingData) {
    baseData = JSON.parse(existingData);
  }
  baseData.columns = columns;
  const strColumns = JSON.stringify(baseData);
  localStorage.setItem(`site${locationId}`, strColumns);
  return baseData.columns || { value: {} };
}

function removeContextColumns(locationId: number) {
  let key = `site${locationId}`;
  let data = localStorage.getItem(key);
  if (data && null !== data) {
    let newData = JSON.parse(data);
    delete newData.columns;
    newData = JSON.stringify(newData);
    localStorage.setItem(`site${locationId}`, newData);
  }
  return { value: {} };
}

const storedColumnsService = {
  getColumns,
  setColumns,
  removeContextColumns,
};

export default storedColumnsService;
