import React from 'react';
import { Button, Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getActiveClassByPath } from '../_helpers';
import { User } from '../_entities/user';
import TelephoneVert from '../SvgComponents/TelephoneVert';
import TourneesVertes from '../SvgComponents/TourneesVertes';
import ImportVert from '../SvgComponents/ImportBleu';
import gestionnaireAction from '../_actions/gestionnaire.actions';
import SvgMessageVert from '../SvgComponents/MessageVert';
import SvgMarquagesVert from '../SvgComponents/MarquageVert';
import { withTranslation } from 'react-i18next';
import EnqueteSvg from '../SvgComponents/EnqueteSvg';
import SvgManageTournee from '../SvgComponents/ManageTournee';

interface Props {
  user: User;
  locations: any;
  gestionnaire: any;
  location: any;
  t: any;
}
interface State {
  isOpen: boolean;
  back: boolean;
}

/**
 * @class SidebarLocationComponent
 * @extends Component
 */
class SidebarStockComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      back: false,
    };
  }

  /**
   * @method toggle
   */
  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  componentDidMount() {
    const { dispatch, location } = this.props;
    const newId = location.pathname.split('/')[2];
    dispatch(gestionnaireAction.getGestionnaire(newId));
  }

  render() {
    const { isOpen, back } = this.state;
    const { match, location, t } = this.props;
    return (
      <div>
        <div className="sidebar-location">
          <Navbar className="sidebar-location-wrapper sidebar-stock" dark expand="md">
            <div className="sidebar-synchro">
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav navbar>
                  <NavbarBrand
                    href={`/gestionnaires/${_.get(match, 'params.GestionnaireId')}/synchronisation`}
                    className="center"
                    style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                  >
                    {t('all.round.round_mngmt')}
                  </NavbarBrand>
                  <NavItem>
                    <NavLink
                      className={getActiveClassByPath(location.pathname, 'manager')}
                      href={`/gestionnaires/${_.get(match, 'params.GestionnaireId')}/synchronisation`}
                    >
                      <SvgManageTournee fill="currentcolor" stroke="currentcolor" height="1.2em" width="1.2em" />
                      {t('all.round.round_mngmt')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={getActiveClassByPath(location.pathname, 'tournees')}
                      href={`/gestionnaires/${_.get(match, 'params.GestionnaireId')}/synchronisation/tournees`}
                    >
                      <TourneesVertes fill="currentcolor" stroke="currentcolor" height="1em" width="1em" />
                      {t('sidebar_synchro.nav_link.round_plural')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={getActiveClassByPath(location.pathname, 'portables')}
                      href={`/gestionnaires/${_.get(match, 'params.GestionnaireId')}/synchronisation/portables`}
                    >
                      <TelephoneVert fill="currentcolor" stroke="currentcolor" height="1em" width="1em" />
                      {t('sidebar_synchro.nav_link.phone_plural')}
                    </NavLink>
                  </NavItem>
                  {false && (
                    <NavItem>
                      <NavLink
                        className={getActiveClassByPath(location.pathname, 'constructeur')}
                        href={`/gestionnaires/${_.get(match, 'params.GestionnaireId')}/synchronisation/constructeur`}
                      >
                        <ImportVert fill="currentcolor" stroke="currentcolor" height="1em" width="1em" />
                        {t('sidebar_synchro.nav_link.import_mfr')}
                      </NavLink>
                    </NavItem>
                  )}
                  <NavItem>
                    <NavLink
                      className={getActiveClassByPath(location.pathname, 'messages')}
                      href={`/gestionnaires/${_.get(match, 'params.GestionnaireId')}/synchronisation/messages`}
                    >
                      <SvgMessageVert height="1em" width="1em" fill="currentcolor" stroke="currentcolor" />
                      {t('sidebar_synchro.nav_link.msg_list')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={getActiveClassByPath(location.pathname, 'marquages')}
                      href={`/gestionnaires/${_.get(match, 'params.GestionnaireId')}/synchronisation/marquages`}
                    >
                      <SvgMarquagesVert height="1em" width="1em" fill="currentcolor" stroke="currentcolor" />
                      {t('sidebar_synchro.nav_link.marking_list')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={getActiveClassByPath(location.pathname, 'enquetes')}
                      href={`/gestionnaires/${_.get(match, 'params.GestionnaireId')}/synchronisation/enquetes`}
                    >
                      <EnqueteSvg fill="currentcolor" stroke="currentcolor" height="1em" width="1em" />
                      {t('sidebar_synchro.nav_link.enquiry_plural')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={getActiveClassByPath(location.pathname, 'silex')}
                      href={`/gestionnaires/${_.get(match, 'params.GestionnaireId')}/synchronisation/silex`}
                    >
                      <ImportVert fill="currentcolor" stroke="currentcolor" height="1em" width="1em" />
                      {t('all.round.round_import')}
                    </NavLink>
                  </NavItem>
                  {back && (
                    <NavItem className={'backSidebar'}>
                      <Button
                        onClick={() => {
                          console.log('CLIK BACK');
                          this.setState({ back: false });
                        }}
                      >
                        {t('all.button.back')}
                      </Button>
                    </NavItem>
                  )}
                </Nav>
              </Collapse>
            </div>
          </Navbar>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, locations, gestionnaire, tournee } = state;
  const { user } = authentication;
  return {
    user,
    locations,
    gestionnaire,
    tournee,
  };
}

const mapping: any = connect(mapStateToProps)(SidebarStockComponent);

const connectedSidebarStock = withRouter(mapping);
const tr = withTranslation()(connectedSidebarStock);
export default tr;
