import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import InfoMap from './InfoMap';
import Historique from './Historique';
import Temperature from './Temperature';
import Manual from './Manual';
import Synchronisation from './Synchronisation';
import Frequence from './Frequence';

import sensorActions from '../_actions/sensor.actions';

import StatusPlanifier from './StatusPlanifier';
import { locationActions } from '../_actions';

const LigneHorizontal = styled.div`
  border-bottom: 1px solid lightgrey;
  width: 90%;
  margin: 1%;
`;

const SensorElectrovanne = (props: any) => {
  const {
    dispatch,
    match: {
      params: { sensorId, locationId },
    },
    sensor,
  } = props;
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (!init) {
      dispatch(sensorActions.getSensorById(sensorId));
      dispatch(locationActions.getInfos(locationId));
      setInit(true);
    }
  });
  return (
    <div className="sensor-container">
      {sensor && sensor.sensor && (
        <>
          {sensor.sensor && <InfoMap />}
          <Historique keyProps={'valvestatus'} />
          {!_.isEmpty(_.get(sensor, 'sensor.info.sensorDownlinkUrl')) && <StatusPlanifier />}
          {!_.isEmpty(_.get(sensor, 'sensor.info.sensorDownlinkUrl')) && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Manual />
              <Synchronisation />
            </div>
          )}
          {!_.isEmpty(_.get(sensor, 'sensor.info.sensorDownlinkUrl')) && <Frequence />}
          <Temperature />
        </>
      )}
    </div>
  );
};

function mapStateToProps(state: any) {
  const { sensor } = state;
  return {
    sensor,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(SensorElectrovanne)));
