import alarmConstants from '../_constants/alarm.constants';

export default function alarms(state: any = {}, action: any) {
  switch (action.type) {
    case alarmConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case alarmConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.alarms,
      };
    case alarmConstants.GETALL_FAILURE:
      return {
        error: "Impossible d'obtenir la liste d'alertes",
      };

    case alarmConstants.GETALL_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case alarmConstants.GETALL_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        types: action.types,
      };
    case alarmConstants.GETALL_TYPE_FAILURE:
      return {
        error: "Impossible d'obtenir la liste d'alertes",
      };
    case alarmConstants.GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case alarmConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchedAlarm: action.fetchedAlarm,
      };
    case alarmConstants.GET_FAILURE:
      return {
        error: "Impossible d'obtenir les informations de l'alerte",
      };
    case alarmConstants.SAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case alarmConstants.SAVE_SUCCESS:
      return {
        items: (state.items || []).concat(action.savedAlarm),
        savedAlarm: action.savedAlarm,
      };
    case alarmConstants.SAVE_FAILURE:
      return {
        items: state.items || [],
        error: "Impossible de sauvegarder l'alerte",
      };
    case alarmConstants.UPDATE_SUCCESS:
      return {
        ...state,
        savedAlarm: action.updatedAlarm,
      };
    case alarmConstants.UPDATE_FAILURE:
      return {
        items: state.items,
        error: "Impossible de sauvegarder l'alerte",
      };
    case alarmConstants.DELETE_SUCCESS:
      return {
        items: state.items.filter((alarm: any) => alarm.id !== action.alarmId),
      };
    case alarmConstants.DELETE_FAILURE:
      return {
        items: state.items,
        error: "Impossible de supprimer l'alerte",
      };
    case alarmConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
