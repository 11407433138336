import { cloneDeep as _cloneDeep, cloneDeep } from 'lodash';

/**
 * Retourne le bon format de datasets en fonction du type
 * et des données additionnelles
 *
 * @method formatDatasets
 * @param {string} type Type
 * @param {any} data Données
 * @param {any} additional Données additionnelles
 * @param {any} datasets Jeux de données
 */
export function formatDatasets(type: string, data: any, additional: any, datasets: any, isMasked: boolean | undefined) {
  const copyDatasets = _cloneDeep(datasets);
  if (type !== 'Mix' && type !== 'StackedHistogram') {
    const checkRef = data.find((it: any) => Object.keys(it).length > 0 && it.hasOwnProperty('ref'));
    const set: any = {
      strokeColor: '#E8575A',
      highlightFill: 'rgba(220,220,220,0.75)',
      highlightStroke: 'rgba(220,220,220,1)',
      backgroundColor: '#80dfff',
      type: type === 'Curve' ? 'line' : 'bar',
    };

    if (undefined !== checkRef) {
      if (type === 'Curve') {
        set.fill = false;
        set.borderColor = '#80dfff';
      }
      data = data.sort((a: any, b: any) => {
        if (a.ref) {
          return -1;
        }
        return 1;
      });

      data.forEach((it: any, key: any) => {
        const currentSet = _cloneDeep(set);
        if (it.ref) {
          if (type === 'Curve') {
            currentSet.borderColor = '#2BC687';
          }
          currentSet.backgroundColor = '#2BC687';
        }
        if (additional && additional.colors && additional.colors.length > 0) {
          const addColor = additional.colors.find((color: any) => color.label === it.label);
          if (undefined !== addColor && addColor.values.length > 0) {
            const colors = addColor.values;
            if (type !== 'Curve') {
              currentSet.backgroundColor = colors;
            } else {
              currentSet.pointBackgroundColor = colors;
              currentSet.pointBorderColor = colors;
            }
          }
        }
        currentSet.data = it.data;
        currentSet.yAxisID = `y-${it.yAxisId}`;
        currentSet.label = it.label;
        copyDatasets.push(currentSet);
      });
    } else {
      set.data = data;
      if (additional && additional.colors && additional.colors.length > 0) {
        if (type !== 'Curve') {
          set.backgroundColor = additional.colors;
        } else {
          set.pointBackgroundColor = additional.colors;
        }
      }
      copyDatasets.push(set);
    }
  } else {
    data.forEach((elem: any) => {
      copyDatasets.push({
        fillColor: '#E8575A',
        strokeColor: '#E8575A',
        highlightFill: 'rgba(220,220,220,0.75)',
        highlightStroke: 'rgba(220,220,220,1)',
        data: elem.data,
        type: elem.type,
        label: elem.label,
        backgroundColor: elem.color,
      });
    });
  }
  return copyDatasets;
}
