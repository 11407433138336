import React from 'react';

const NormalConso = (props: any) => (
  <svg viewBox="0 0 117.53 108.15" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="pictos">
        <path
          fill={props.fill}
          d="M7.56,61.62H91.74L58.09,95.27a7.54,7.54,0,0,0,10.66,10.67L115.32,59.4a7.51,7.51,0,0,0,0-10.65L68.75,2.22A7.54,7.54,0,1,0,58.06,12.87L91.71,46.53H7.56a7.55,7.55,0,1,0,0,15.09"
        />
      </g>
    </g>
  </svg>
);
export default NormalConso;
