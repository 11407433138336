import { reportConstants } from '../_constants';
import { reportService } from '../_services';

/**
 * Récupère tous les rapports liés à l'alerte passée
 * en paramètre, sur le site courant
 *
 * @method getAllByAlert
 * @param userId
 * @param rndCode
 * @returns {Object} Les rapports
 */
function getAllByAlert(userId: number, rndCode: string) {
  function request() {
    return { type: reportConstants.GETALL_REQUEST };
  }
  function success(reports: any) {
    return { type: reportConstants.GETALL_SUCCESS, reports };
  }
  function failure(error: Object) {
    return { type: reportConstants.GETALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    reportService
      .getAllByAlert(userId, rndCode)
      .then((reports: any) => dispatch(success(reports)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère le rapport ciblé
 *
 * @method get
 * @param id Id de l'alerte
 * @param reportId Id du rapport
 * @param rndCode Code du site
 * @returns {Object} Le rapport
 */
function get(id: number, reportId: number, rndCode: string) {
  function request() {
    return { type: reportConstants.GET_REQUEST };
  }
  function success(fetchedReport: any) {
    return { type: reportConstants.GET_SUCCESS, fetchedReport };
  }
  function failure(error: Object) {
    return { type: reportConstants.GET_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    reportService
      .get(id, reportId, rndCode)
      .then((fetchedReport: any) => {
        dispatch(success(fetchedReport));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Vide le state des rapports
 *
 * @method clear
 */
function clear() {
  return {
    type: reportConstants.CLEAR,
  };
}

const notificationActions = {
  getAllByAlert,
  get,
  clear,
};

export default notificationActions;
