import React from 'react';

const SvgColonneVert = (props: any) => (
  <svg viewBox="0 0 32.93 27.24" {...props}>
    <g id="colonne_vert_svg__Calque_2" data-name="Calque 2">
      <g id="colonne_vert_svg__Calque_2-2" data-name="Calque 2">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          fill={props.fill}
          d="M.82.82H8.1v25.6H.82zM12.83.82h7.28v25.6h-7.28zM24.83.82h7.28v25.6h-7.28z"
        />
      </g>
    </g>
  </svg>
);

export default SvgColonneVert;
