import React from 'react';

const SvgGoutteTelereleve = (props: any) => (
  <svg viewBox="0 0 132.4 154.5" {...props}>
    <path
      fill="#000000"
      d="M130.8,101.2c-1.9-7.3-5.5-13.9-10.1-19.2c-1.1-1.2-2.6-1.9-4.1-1.9c-1.3,0-2.5,0.4-3.6,1.3
			c-2.3,2-2.5,5.4-0.5,7.7c3.6,4.2,6.3,9.2,7.8,14.9c0.6,2.2,0.9,4.4,1.1,6.6c0.8,10.6-3,20.8-10,28.3c-2.1,2.2-1.9,5.6,0.3,7.7
			c1.1,1,2.4,1.5,3.8,1.5c0.1,0,0.3,0,0.4,0c1.3-0.1,2.6-0.7,3.5-1.7c9.1-9.6,14-22.9,12.9-36.6C132,106.9,131.6,104,130.8,101.2z"
    />
    <path
      fill="#000000"
      d="M114.5,111c-0.1-1.8-0.4-3.6-0.9-5.4c-1.2-4.4-3.3-8.4-6-11.7c-1.1-1.3-2.6-2-4.2-2c-1.2,0-2.5,0.4-3.5,1.3
			c-2.3,1.9-2.6,5.4-0.7,7.7c1.8,2.1,3.1,4.6,3.9,7.5c0.3,1.1,0.5,2.3,0.6,3.4c0.4,4.9-1.1,9.6-4,13.3c-1.8,2.4-1.4,5.8,1,7.6
			c1,0.8,2.1,1.1,3.3,1.1c0.1,0,0.3,0,0.4,0c1.5-0.1,2.9-0.8,3.9-2.1C112.8,126.1,115.1,118.7,114.5,111z"
    />
    <path
      fill="#000000"
      d="M96.2,112.4C96.2,112.4,96.2,112.3,96.2,112.4c-0.3-4-2.8-7.1-6.1-8.6c-1.3-0.6-2.7-0.9-4.2-0.9
			c-0.3,0-0.5,0-0.8,0c-5.3,0.4-9.4,4.8-9.5,10c0,0.3,0,0.7,0,1.1c0,0,0,0.1,0,0.1c0.3,3.8,2.6,6.9,5.8,8.4v28.1
			c0,2.1,1.8,3.9,3.9,3.9h1.2c2.1,0,3.9-1.8,3.9-3.9v-28.1c3.4-1.6,5.7-5.1,5.8-9C96.2,113.1,96.2,112.8,96.2,112.4z"
    />
    <path
      fill="#000000"
      d="M51.5,122.5c-0.6-2.2-0.9-4.4-1.1-6.6c-0.8-10.6,3-20.8,10-28.3c2.1-2.2,1.9-5.6-0.3-7.7
			c-1.1-1-2.4-1.5-3.8-1.5c-0.1,0-0.3,0-0.4,0c-1.3,0.1-2.6,0.7-3.5,1.7c-9.1,9.6-14,22.9-12.9,36.6c0.2,2.8,0.7,5.7,1.5,8.5
			c1.9,7.3,5.5,13.9,10.1,19.2c1.1,1.2,2.6,1.9,4.1,1.9c1.3,0,2.5-0.4,3.6-1.3c2.3-2,2.5-5.4,0.5-7.7
			C55.7,133.2,53,128.2,51.5,122.5z"
    />
    <path
      fill="#000000"
      d="M68.7,118c-0.3-1.1-0.5-2.3-0.6-3.4c-0.4-4.9,1.1-9.6,4-13.3c1.8-2.4,1.4-5.8-1-7.6c-1-0.8-2.1-1.1-3.3-1.1
			c-0.1,0-0.3,0-0.4,0c-1.5,0.1-2.9,0.8-3.9,2.1c-4.5,5.8-6.8,13.2-6.2,20.8c0.1,1.8,0.4,3.6,0.9,5.4c1.2,4.4,3.3,8.4,6,11.7
			c1.1,1.3,2.6,2,4.2,2c1.2,0,2.5-0.4,3.5-1.3c2.3-1.9,2.6-5.4,0.7-7.7C70.8,123.4,69.5,120.9,68.7,118z"
    />
    <path
      fill={props.fillGoutte}
      d="M33.6,117.3L33.6,117.3L33.6,117.3c-1.2-15.2,4.1-30.2,14.5-41.2c2-2.1,4.7-3.4,7.5-3.6c0.3,0,0.5,0,0.8,0
		c2.9,0,5.7,1.1,7.8,3.1c2.2,2.1,3.5,4.9,3.6,8c0,1-0.1,2-0.3,3c0.1,0,0.2,0,0.3,0c2.5,0,5,0.8,7,2.4c2.4,1.9,4,4.6,4.4,7.6
		c0.1,0.6,0.1,1.2,0.1,1.9c1.7-0.7,3.5-1.2,5.4-1.4c0.4,0,0.9-0.1,1.3-0.1c2.1,0,4.2,0.4,6.1,1.2c0-0.6,0-1.1,0-1.7
		c0.3-3,1.7-5.8,4.1-7.7c1.9-1.6,4.3-2.5,6.9-2.6c-2.4-12.4-8.8-27.3-19.3-44.6C73.1,24.1,61.4,9,55.4,1.6c-0.8-1-2-1.6-3.3-1.6
		c0,0,0,0,0,0c0,0,0,0,0,0c-1.3,0-2.5,0.6-3.3,1.6c-6,7.4-17.8,22.5-28.3,39.9C9.4,59.6,2.9,75.2,0.8,87.9c0,0.1,0,0.2-0.1,0.3
		c0,0.3-0.1,0.6-0.1,0.9c0,0,0,0,0,0C0.2,91.7,0,94.2,0,96.5c0,26.8,20.3,48.9,46.4,51.7c-5.3-6.2-9.1-13.4-11.2-21.4
		C34.4,123.8,33.9,120.7,33.6,117.3z"
    />
  </svg>
);

export default SvgGoutteTelereleve;
