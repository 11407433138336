import { feature, Feature, Properties } from '@turf/turf';
import { log } from 'console';
import _ from 'lodash';
import mapboxgl, { Map, Marker } from 'mapbox-gl';

const colorDisplay = (type: string): string => {
  switch (type) {
    case 'b':
      return '#91e0f2';
    case 'p':
      return '#a63c76';
    case 'r':
      return '#f24141';
    case 'g':
      return '#26a653';
    case 'y':
      return '#f2ae2e';
    default:
      return '#333';
  }
};

function donutSegment(colors: Array<string>) {
  const length = colors.length;

  if (length !== 3 && length < 5) {
    return `<path fill="none" d="M164.1,112c0-4.2-0.5-8.3-1.5-12.2C163.6,103.7,164.1,107.8,164.1,112L164.1,112z"/>
    <path fill="none" d="M20,110.4c0,0.6,0,1.1,0,1.7l0,0C20,111.5,20,110.9,20,110.4z"/>
    <path fill="none" d="M161.6,128.1c-3.4,10.6-10.1,19.6-18.9,26c-8.6,6.3-19.2,9.9-30.6,9.9v0c11.4,0,22-3.7,30.6-9.9
      C151.4,147.8,158.1,138.7,161.6,128.1c1.6-5.1,2.5-10.5,2.5-16.1c0,0,0,0,0,0h0C164.1,117.7,163.2,123.1,161.6,128.1z"/>
    <path fill="none" d="M112,164.1c-11.4,0-22-3.7-30.6-9.9c-8.8-6.4-15.5-15.5-18.9-26c-0.4-1.3-0.8-2.6-1.1-3.9
      c0.3,1.3,0.7,2.6,1.1,3.9c3.4,10.6,10.1,19.6,18.9,26C90,160.4,100.6,164.1,112,164.1L112,164.1L112,164.1z"/>
    <path fill=${colorDisplay(
      colors[0]
    )} opacity=0.5 d="M20,110.4c0-1.5,0.1-3,0.2-4.5c0.5-7.8,2-15.2,4.3-22.3c6.1-18.7,18-34.7,33.5-46C73.3,26.5,92,20,112,20V0
      C87.5,0,64.7,8,46.2,21.4c-18.9,13.8-33.3,33.3-40.7,56C1.9,88.3,0,100,0,112h20C20,111.5,20,110.9,20,110.4z"/>
    <path fill=${colorDisplay(
      colors[length < 4 ? (length == 1 ? 0 : 1) : 2]
    )} opacity=0.5 d="M204.1,113.7c0,1.5-0.1,3-0.2,4.5c-0.5,7.8-2,15.2-4.3,22.3c-6.1,18.7-18,34.7-33.5,46
      c-15.3,11.1-34,17.6-54.1,17.6v20c24.6,0,47.3-8,65.8-21.4c18.9-13.8,33.3-33.3,40.7-56c3.6-10.9,5.5-22.5,5.5-34.6h-20
      C204.1,112.6,204.1,113.2,204.1,113.7z"/>
    <path fill=${colorDisplay(
      colors[length < 4 ? 0 : 1]
    )} opacity=0.5 d="M105.9,203.9c-17.9-1.2-34.4-7.5-47.9-17.4c-15.6-11.4-27.4-27.5-33.5-46c-2.9-9-4.5-18.6-4.5-28.4H0
      c0,12.1,1.9,23.7,5.5,34.6c7.4,22.7,21.8,42.2,40.7,56c18.5,13.5,41.2,21.4,65.8,21.4v-20C110,204.1,108,204,105.9,203.9z"/>
    <path fill=${colorDisplay(
      colors[length < 4 ? (length == 1 ? 0 : 1) : 3]
    )} opacity=0.5 d="M177.9,21.4C159.4,8,136.6,0,112,0v20c2,0,4.1,0.1,6.2,0.2c17.9,1.2,34.4,7.5,47.9,17.4
      c15.6,11.4,27.4,27.5,33.5,46c2.9,9,4.5,18.6,4.5,28.4h20c0-12.1-1.9-23.7-5.5-34.6C211.2,54.7,196.8,35.2,177.9,21.4z"/>
    <path fill=${colorDisplay(
      colors[length < 4 ? 0 : 1]
    )} opacity=0.8 d="M43.5,134.3c-2.3-7-3.5-14.5-3.5-22.3H20c0,9.9,1.6,19.5,4.5,28.4c6.1,18.5,17.9,34.6,33.5,46
      c13.6,9.9,30,16.2,47.9,17.4c2.1,0.1,4.1,0.2,6.2,0.2v-20c-15.8,0-30.4-5.1-42.3-13.8C57.6,161.5,48.3,148.9,43.5,134.3z"/>
    <path fill=${colorDisplay(
      colors[0]
    )} opacity=0.8 d="M40,112L40,112L40,112c0-7.8,1.3-15.2,3.5-22.3c4.8-14.6,14-27.1,26.2-36C81.6,45.1,96.2,40,112,40V20
      C92,20,73.3,26.5,58,37.6c-15.5,11.3-27.4,27.2-33.5,46c-2.3,7.1-3.8,14.5-4.3,22.3c-0.1,1.5-0.2,3-0.2,4.5c0,0.6,0,1.1,0,1.7H40z"
      />
    <path fill=${colorDisplay(
      colors[length < 4 ? (length == 1 ? 0 : 1) : 3]
    )} opacity=0.8 d="M154.4,53.8c12.2,8.9,21.4,21.4,26.2,36c2.3,7,3.5,14.5,3.5,22.3l0,0v0h20c0-9.9-1.6-19.5-4.5-28.4
      c-6.1-18.5-17.9-34.6-33.5-46c-13.6-9.9-30-16.2-47.9-17.4c-2.1-0.1-4.1-0.2-6.2-0.2v20C127.9,40,142.5,45.1,154.4,53.8z"/>
    <path fill=${colorDisplay(
      colors[length < 4 ? (length == 1 ? 0 : 1) : 2]
    )} opacity=0.8 d="M184.1,112c0,7.8-1.3,15.2-3.5,22.3c-4.8,14.6-14,27.1-26.2,36c-11.9,8.7-26.5,13.8-42.3,13.8v20
      c20,0,38.8-6.5,54.1-17.6c15.5-11.3,27.4-27.2,33.5-46c2.3-7.1,3.8-14.5,4.3-22.3c0.1-1.5,0.2-3,0.2-4.5c0-0.6,0-1.1,0-1.7H184.1z"
      />
    <path fill=${colorDisplay(
      colors[length < 4 ? 0 : 1]
    )} opacity=0.5 d="M204.1,113.7c0-0.6,0-1.1,0-1.7l0,0C204.1,112.6,204.1,113.2,204.1,113.7z"/>
    <path fill="#26A653" opacity=0.8 d="M204.1,113.7c0-0.6,0-1.1,0-1.7l0,0C204.1,112.6,204.1,113.2,204.1,113.7z"/>
    <path fill=${colorDisplay(
      colors[length < 4 ? 0 : 1]
    )} d="M81.5,154.2c-8.8-6.4-15.5-15.5-18.9-26c-0.4-1.3-0.8-2.6-1.1-3.9c-0.9-3.9-1.5-8-1.5-12.2H40
      c0,7.8,1.3,15.2,3.5,22.3c4.8,14.6,14,27.1,26.2,36c11.9,8.7,26.5,13.8,42.3,13.8v-20C100.6,164.1,90,160.4,81.5,154.2z"/>
    <path fill=${colorDisplay(
      colors[length < 4 ? (length == 1 ? 0 : 1) : 2]
    )} d="M164.1,112.1c0,5.6-0.9,11-2.5,16.1c-3.4,10.6-10.1,19.6-18.9,26c-8.6,6.3-19.2,9.9-30.6,9.9v20
    c15.8,0,30.4-5.1,42.3-13.8c12.2-8.9,21.4-21.4,26.2-36c2.3-7,3.5-14.5,3.5-22.3L164.1,112.1C164.1,112,164.1,112,164.1,112.1z"/>
    <path fill=${colorDisplay(
      colors[length < 4 ? (length == 1 ? 0 : 1) : 3]
    )} d="M154.4,53.8C142.5,45.1,127.9,40,112,40v20c11.4,0,22,3.7,30.6,9.9c8.8,6.4,15.5,15.5,18.9,26
      c0.4,1.3,0.8,2.6,1.1,3.9c0.9,3.9,1.5,8,1.5,12.2h20v0c0-7.8-1.3-15.2-3.5-22.3C175.8,75.2,166.5,62.6,154.4,53.8z"/>
      <path fill=${colorDisplay(
        colors[0]
      )} d="M60,112c0-5.6,0.9-11,2.5-16.1c3.4-10.6,10.1-19.6,18.9-26C90,63.7,100.6,60,112,60h0V40
      c-15.8,0-30.4,5.1-42.3,13.8c-12.2,8.9-21.4,21.4-26.2,36c-2.3,7-3.5,14.5-3.5,22.3v0H60z"/>
    <path fill="#FFFFFF" d="M60,112.1C60,112,60,112,60,112.1L60,112.1c0,4.2,0.5,8.3,1.5,12.2C60.5,120.4,60,116.3,60,112.1z"/>
    <path fill="#FFFFFF" d="M161.6,96c-3.4-10.6-10.1-19.6-18.9-26c-8.6-6.3-19.2-9.9-30.6-9.9c-11.4,0-22,3.7-30.6,9.9
      C72.7,76.3,66,85.4,62.5,96c-1.6,5.1-2.5,10.5-2.5,16.1c0,5.6,0.9,11,2.5,16.1c3.4,10.6,10.1,19.6,18.9,26
      c8.6,6.3,19.2,9.9,30.6,9.9s22-3.7,30.6-9.9c8.8-6.4,15.5-15.5,18.9-26c1.6-5.1,2.5-10.5,2.5-16.1C164.1,106.4,163.2,101,161.6,96z
       M112,112L112,112L112,112L112,112L112,112z"/>
    <line fill="none" x1="-18.4" y1="112" x2="242.5" y2="112"/>
    <line fill="none" x1="177.3" y1="225" x2="46.8" y2="-0.9"/>`;
  }
  if (length === 3) {
    return `<path fill="#FFFFFF" d="M161.6,96c-3.4-10.6-10.1-19.6-18.9-26c-8.6-6.3-19.2-9.9-30.6-9.9c-11.4,0-22,3.7-30.6,9.9
    C72.7,76.3,66,85.4,62.5,96c-1.6,5.1-2.5,10.5-2.5,16.1c0,5.6,0.9,11,2.5,16.1c3.4,10.6,10.1,19.6,18.9,26
    c8.6,6.3,19.2,9.9,30.6,9.9s22-3.7,30.6-9.9c8.8-6.4,15.5-15.5,18.9-26c1.6-5.1,2.5-10.5,2.5-16.1C164.1,106.4,163.2,101,161.6,96z
     M112,112L112,112L112,112L112,112L112,112z"/>
  <path fill="none" d="M60,112C60,112,60,112,60,112c0,5.6,0.9,11,2.5,16.1c3.4,10.6,10.1,19.6,18.9,26c8.6,6.3,19.2,9.9,30.6,9.9
    c9.5,0,18.4-2.5,26-7L112,112L60,112z"/>
  <path fill="none" d="M138.1,67c-7.7-4.4-16.5-7-26-7c-11.4,0-22,3.7-30.6,9.9C72.7,76.3,66,85.4,62.5,96C60.9,101,60,106.4,60,112
    l52.1,0L138.1,67z"/>
  <path fill="none" d="M161.6,128.1c1.6-5.1,2.5-10.5,2.5-16.1c0-5.6-0.9-11-2.5-16.1c-3.4-10.6-10.1-19.6-18.9-26
    c-1.5-1.1-3-2.1-4.6-3L112,112l26,45.1c1.6-0.9,3.1-1.9,4.6-3C151.4,147.8,158.1,138.7,161.6,128.1z"/>
  <path opacity=0.6 fill=${colorDisplay(
    colors[0]
  )} d="M112,204.1c-2,0-4.1-0.1-6.2-0.2c-17.9-1.2-34.4-7.5-47.9-17.4c-15.6-11.4-27.4-27.5-33.5-46
    c-2.9-9-4.5-18.6-4.5-28.4l-20,0v0c0,12.1,1.9,23.7,5.5,34.6c7.4,22.7,21.8,42.2,40.7,56c18.5,13.5,41.2,21.4,65.8,21.4
    c20.4,0,39.5-5.5,56-15.1l-10-17.3C144.4,199.6,128.7,204.1,112,204.1z"/>
  <path opacity=0.6 fill=${colorDisplay(
    colors[1]
  )} d="M218.6,77.4c-7.4-22.7-21.8-42.2-40.7-56c-3.2-2.3-6.4-4.4-9.8-6.4l-10,17.3c2.8,1.6,5.5,3.4,8.1,5.3
    c15.6,11.4,27.4,27.5,33.5,46c3.5,10.9,5.1,22.5,4.3,34.6c-0.5,7.8-2,15.2-4.3,22.3c-6.1,18.7-18,34.7-33.5,46
    c-2.6,1.9-5.3,3.6-8.1,5.2l10,17.3c3.4-2,6.7-4.1,9.8-6.4c18.9-13.8,33.3-33.3,40.7-56c3.6-10.9,5.5-22.5,5.5-34.6
    S222.2,88.3,218.6,77.4z"/>
  <path opacity=0.6 fill=${colorDisplay(
    colors[2]
  )} d="M20.2,105.9c0.5-7.8,2-15.2,4.3-22.3c6.1-18.7,18-34.7,33.5-46C73.3,26.5,92,20,112,20c2,0,4.1,0.1,6.2,0.2
    c14.5,1,28,5.3,39.9,12.1l10-17.3C151.6,5.5,132.4,0,112,0C87.5,0,64.7,8,46.2,21.4c-18.9,13.8-33.3,33.3-40.7,56
    C1.9,88.3,0,100,0,112l20,0C20,110,20.1,108,20.2,105.9z"/>
  <path opacity=0.8 fill=${colorDisplay(
    colors[0]
  )} d="M112,184.1c-15.8,0-30.4-5.1-42.3-13.8c-12.2-8.9-21.4-21.4-26.2-36c-2.3-7-3.5-14.5-3.5-22.3l0,0H20
    c0,9.9,1.6,19.5,4.5,28.4c6.1,18.5,17.9,34.6,33.5,46c13.6,9.9,30,16.2,47.9,17.4c2.1,0.1,4.1,0.2,6.2,0.2c16.6,0,32.4-4.5,46-12.4
    l-10-17.3C137.5,180.6,125.2,184.1,112,184.1z"/>
  <path opacity=0.8 fill=${colorDisplay(
    colors[2]
  )} d="M112,20C92,20,73.3,26.5,58,37.6c-15.5,11.3-27.4,27.2-33.5,46c-2.3,7.1-3.8,14.5-4.3,22.3
    C20.1,108,20,110,20,112h20c0-7.8,1.3-15.2,3.5-22.3c4.8-14.6,14-27.1,26.2-36C81.6,45.1,96.2,40,112,40c13.1,0,25.4,3.5,36,9.7
    l10-17.3c-11.8-6.8-25.3-11.2-39.9-12.1C116.1,20.1,114.1,20,112,20z"/>
  <path opacity=0.8 fill=${colorDisplay(
    colors[1]
  )} d="M154.4,53.8c12.2,8.9,21.4,21.4,26.2,36c2.3,7,3.5,14.5,3.5,22.3s-1.3,15.2-3.5,22.3c-4.8,14.6-14,27.1-26.2,36
    c-2,1.5-4.1,2.8-6.3,4.1l10,17.3c2.8-1.6,5.5-3.4,8.1-5.2c15.5-11.3,27.4-27.2,33.5-46c2.3-7.1,3.8-14.5,4.3-22.3
    c0.8-12.1-0.8-23.8-4.3-34.6c-6.1-18.5-17.9-34.6-33.5-46c-2.6-1.9-5.3-3.6-8.1-5.3l-10,17.3C150.2,51,152.3,52.3,154.4,53.8z"/>
  <path fill=${colorDisplay(
    colors[2]
  )} d="M62.5,96c3.4-10.6,10.1-19.6,18.9-26C90,63.7,100.6,60,112,60c9.5,0,18.4,2.5,26,7l10-17.3
    c-10.6-6.1-22.9-9.7-36-9.7c-15.8,0-30.4,5.1-42.3,13.8c-12.2,8.9-21.4,21.4-26.2,36c-2.3,7-3.5,14.5-3.5,22.3h20
    C60,106.4,60.9,101,62.5,96z"/>
  <path fill=${colorDisplay(
    colors[0]
  )} d="M43.5,134.3c4.8,14.6,14,27.1,26.2,36c11.9,8.7,26.5,13.8,42.3,13.8c13.1,0,25.4-3.5,36-9.7l-10-17.3
    c-7.7,4.4-16.5,7-26,7c-11.4,0-22-3.7-30.6-9.9c-8.8-6.4-15.5-15.5-18.9-26c-1.6-5.1-2.5-10.5-2.5-16.1c0,0,0,0,0,0H40l0,0
    C40,119.8,41.3,127.3,43.5,134.3z"/>
  <path fill=${colorDisplay(
    colors[1]
  )} d="M142.6,69.9c8.8,6.4,15.5,15.5,18.9,26c1.6,5.1,2.5,10.5,2.5,16.1c0,5.6-0.9,11-2.5,16.1
    c-3.4,10.6-10.1,19.6-18.9,26c-1.5,1.1-3,2.1-4.6,3l10,17.3c2.2-1.3,4.3-2.6,6.3-4.1c12.2-8.9,21.4-21.4,26.2-36
    c2.3-7,3.5-14.5,3.5-22.3s-1.3-15.2-3.5-22.3c-4.8-14.6-14-27.1-26.2-36c-2-1.5-4.1-2.8-6.3-4.1l-10,17.3
    C139.6,67.9,141.2,68.9,142.6,69.9z"/>`;
  }

  if (length === 5) {
    return `<path opacity=0.5 fill=${colorDisplay(
      colors[3]
    )} d="M20.2,105.9c0.5-7.8,2-15.2,4.3-22.3l-19-6.2C1.9,88.3,0,100,0,112s1.9,23.7,5.5,34.6l19-6.2
    C21,129.6,19.4,118,20.2,105.9z"/>
  <path opacity=0.5 fill=${colorDisplay(
    colors[4]
  )} d="M112,20V0C87.5,0,64.7,8,46.2,21.4L58,37.6C73.3,26.5,92,20,112,20z"/>
  <path opacity=0.5 fill=${colorDisplay(
    colors[4]
  )} d="M58,37.6L46.2,21.4c-18.9,13.8-33.3,33.3-40.7,56l19,6.2C30.6,64.9,42.5,48.9,58,37.6z"/>
  <path opacity=0.5 fill=${colorDisplay(
    colors[0]
  )} d="M199.6,83.6l19-6.2c-7.4-22.7-21.8-42.2-40.7-56l-11.7,16.2C181.7,49,193.5,65.1,199.6,83.6z"/>
  <path opacity=0.5 fill=${colorDisplay(
    colors[0]
  )} d="M118.2,20.2c17.9,1.2,34.4,7.5,47.9,17.4l11.7-16.2C159.4,8,136.6,0,112,0v20C114.1,20,116.1,20.1,118.2,20.2z"
    />
  <path opacity=0.6 fill=${colorDisplay(
    colors[2]
  )} d="M112,204.1v20c24.6,0,47.3-8,65.8-21.4l-11.8-16.2C150.8,197.6,132.1,204.1,112,204.1z"/>
  <path opacity=0.5 fill=${colorDisplay(
    colors[1]
  )} d="M218.6,77.4l-19,6.2c3.5,10.9,5.1,22.5,4.3,34.6c-0.5,7.8-2,15.2-4.3,22.3l19,6.2c3.6-10.9,5.5-22.5,5.5-34.6
    S222.2,88.3,218.6,77.4z"/>
  <path opacity=0.5 fill=${colorDisplay(
    colors[3]
  )} d="M24.5,140.5l-19,6.2c7.4,22.7,21.8,42.2,40.7,56L58,186.5C42.4,175.1,30.6,159,24.5,140.5z"/>
  <path opacity=0.5 fill=${colorDisplay(
    colors[1]
  )} d="M166.1,186.5l11.8,16.2c18.9-13.8,33.3-33.3,40.7-56l-19-6.2C193.5,159.2,181.6,175.2,166.1,186.5z"/>
  <path opacity=0.5 fill=${colorDisplay(
    colors[2]
  )} d="M105.9,203.9c-17.9-1.2-34.4-7.5-47.9-17.4l-11.7,16.2c18.5,13.5,41.2,21.4,65.8,21.4v-20
    C110,204.1,108,204,105.9,203.9z"/>
  <path opacity=0.5 fill="#F2AE2E" d="M43.5,134.3l-19,6.2c6.1,18.5,17.9,34.6,33.5,46l11.8-16.2C57.6,161.5,48.3,148.9,43.5,134.3z"/>
  <path opacity=0.8 fill=${colorDisplay(
    colors[3]
  )} d="M43.5,134.3l-19,6.2c6.1,18.5,17.9,34.6,33.5,46l11.8-16.2C57.6,161.5,48.3,148.9,43.5,134.3z"/>
  <path opacity=0.5 fill="#F2AE2E" d="M112,40V20C92,20,73.3,26.5,58,37.6l11.8,16.2C81.6,45.1,96.2,40,112,40z"/>
  <path opacity=0.8 fill=${colorDisplay(
    colors[4]
  )} d="M112,40V20C92,20,73.3,26.5,58,37.6l11.8,16.2C81.6,45.1,96.2,40,112,40z"/>
  <path opacity=0.5 fill="#F2AE2E" d="M69.7,53.8L58,37.6c-15.5,11.3-27.4,27.2-33.5,46l19,6.2C48.3,75.2,57.6,62.6,69.7,53.8z"/>
  <path opacity=0.8 fill=${colorDisplay(
    colors[4]
  )} d="M69.7,53.8L58,37.6c-15.5,11.3-27.4,27.2-33.5,46l19,6.2C48.3,75.2,57.6,62.6,69.7,53.8z"/>
  <path opacity=0.5 fill="#F2AE2E" d="M154.4,53.8l11.8-16.2c-13.6-9.9-30-16.2-47.9-17.4c-2.1-0.1-4.1-0.2-6.2-0.2v20
    C127.9,40,142.5,45.1,154.4,53.8z"/>
  <path opacity=0.8 fill=${colorDisplay(
    colors[0]
  )} d="M154.4,53.8l11.8-16.2c-13.6-9.9-30-16.2-47.9-17.4c-2.1-0.1-4.1-0.2-6.2-0.2v20
    C127.9,40,142.5,45.1,154.4,53.8z"/>
  <path opacity=0.5 fill="#F2AE2E" d="M40,112c0-7.8,1.3-15.2,3.5-22.3l-19-6.2c-2.3,7.1-3.8,14.5-4.3,22.3c-0.8,12.1,0.8,23.8,4.3,34.6l19-6.2
    C41.3,127.3,40,119.8,40,112z"/>
  <path opacity=0.8 fill=${colorDisplay(
    colors[3]
  )} d="M40,112c0-7.8,1.3-15.2,3.5-22.3l-19-6.2c-2.3,7.1-3.8,14.5-4.3,22.3c-0.8,12.1,0.8,23.8,4.3,34.6l19-6.2
    C41.3,127.3,40,119.8,40,112z"/>
  <path opacity=0.5 fill="#F2AE2E" d="M184.1,112c0,7.8-1.3,15.2-3.5,22.3l19,6.2c2.3-7.1,3.8-14.5,4.3-22.3c0.8-12.1-0.8-23.8-4.3-34.6l-19,6.2
    C182.8,96.8,184.1,104.3,184.1,112z"/>
  <path opacity=0.8 fill=${colorDisplay(
    colors[1]
  )} d="M184.1,112c0,7.8-1.3,15.2-3.5,22.3l19,6.2c2.3-7.1,3.8-14.5,4.3-22.3c0.8-12.1-0.8-23.8-4.3-34.6l-19,6.2
    C182.8,96.8,184.1,104.3,184.1,112z"/>
  <path opacity=0.5 fill="#F2AE2E" d="M69.7,170.3L58,186.5c13.6,9.9,30,16.2,47.9,17.4c2.1,0.1,4.1,0.2,6.2,0.2v-20C96.2,184.1,81.6,179,69.7,170.3z
    "/>
  <path opacity=0.8 fill=${colorDisplay(
    colors[2]
  )} d="M69.7,170.3L58,186.5c13.6,9.9,30,16.2,47.9,17.4c2.1,0.1,4.1,0.2,6.2,0.2v-20C96.2,184.1,81.6,179,69.7,170.3z
    "/>
  <path opacity=0.5 fill="#F2AE2E" d="M154.4,170.3l11.8,16.2c15.5-11.3,27.4-27.2,33.5-46l-19-6.2C175.8,148.9,166.5,161.5,154.4,170.3z"/>
  <path opacity=0.8 fill=${colorDisplay(
    colors[1]
  )} d="M154.4,170.3l11.8,16.2c15.5-11.3,27.4-27.2,33.5-46l-19-6.2C175.8,148.9,166.5,161.5,154.4,170.3z"/>
  <path opacity=0.5 fill="#F2AE2E" d="M112,184.1v20c20,0,38.8-6.5,54.1-17.6l-11.8-16.2C142.5,179,127.9,184.1,112,184.1z"/>
  <path opacity=0.8 fill=${colorDisplay(
    colors[2]
  )} d="M112,184.1v20c20,0,38.8-6.5,54.1-17.6l-11.8-16.2C142.5,179,127.9,184.1,112,184.1z"/>
  <path opacity=0.5 fill="#F2AE2E" d="M166.1,37.6l-11.8,16.2c12.2,8.9,21.4,21.4,26.2,36l19-6.2C193.5,65.1,181.7,49,166.1,37.6z"/>
  <path opacity=0.8 fill=${colorDisplay(
    colors[0]
  )} d="M166.1,37.6l-11.8,16.2c12.2,8.9,21.4,21.4,26.2,36l19-6.2C193.5,65.1,181.7,49,166.1,37.6z"/>
  <path fill=${colorDisplay(
    colors[2]
  )} d="M81.5,154.2l-11.7,16.2c11.9,8.7,26.5,13.8,42.3,13.8v-20C100.6,164.1,90,160.4,81.5,154.2z"/>
  <path fill=${colorDisplay(
    colors[3]
  )} d="M60,112.1c0-5.6,0.9-11,2.5-16.1l-19-6.2c-2.3,7-3.5,14.5-3.5,22.3s1.3,15.2,3.5,22.3l19-6.2
    C60.9,123.1,60,117.7,60,112.1z"/>
  <path fill=${colorDisplay(
    colors[3]
  )} d="M62.5,128.1l-19,6.2c4.8,14.6,14,27.1,26.2,36l11.7-16.2C72.7,147.8,66,138.7,62.5,128.1z"/>
  <path fill=${colorDisplay(
    colors[4]
  )} d="M81.5,69.9L69.7,53.8c-12.2,8.9-21.4,21.4-26.2,36l19,6.2C66,85.4,72.7,76.3,81.5,69.9z"/>
  <path fill=${colorDisplay(colors[4])} d="M112,60V40c-15.8,0-30.4,5.1-42.3,13.8l11.7,16.2C90,63.7,100.6,60,112,60z"/>
  <path fill=${colorDisplay(
    colors[1]
  )} d="M164.1,112.1c0,5.6-0.9,11-2.5,16.1l19,6.2c2.3-7,3.5-14.5,3.5-22.3s-1.3-15.2-3.5-22.3l-19,6.2
    C163.2,101,164.1,106.4,164.1,112.1z"/>
  <path fill=${colorDisplay(colors[0])} d="M112,60c11.4,0,22,3.7,30.6,9.9l11.7-16.2C142.5,45.1,127.9,40,112,40V60z"/>
  <path fill=${colorDisplay(
    colors[2]
  )} d="M112,164.1v20c15.8,0,30.4-5.1,42.3-13.8l-11.7-16.2C134.1,160.4,123.5,164.1,112,164.1z"/>
  <path fill=${colorDisplay(
    colors[0]
  )} d="M161.6,96l19-6.2c-4.8-14.6-14-27.1-26.2-36l-11.7,16.2C151.4,76.3,158.1,85.4,161.6,96z"/>
  <path fill=${colorDisplay(
    colors[1]
  )} d="M142.6,154.2l11.7,16.2c12.2-8.9,21.4-21.4,26.2-36l-19-6.2C158.1,138.7,151.4,147.8,142.6,154.2z"/>
  <rect x="112" y="112" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -46.4126 112.0498)" fill="#FFFFFF" width="0" height="0"/>
  <polygon fill="#FFFFFF" points="112,112 112,112 112,112 "/>
  <polygon fill="#FFFFFF" points="112,112 112,112 112,112 "/>
  <path fill="#FFFFFF" d="M161.6,96c-3.4-10.6-10.1-19.6-18.9-26c-8.6-6.3-19.2-9.9-30.6-9.9c-11.4,0-22,3.7-30.6,9.9
    C72.7,76.3,66,85.4,62.5,96c-1.6,5.1-2.5,10.5-2.5,16.1c0,5.6,0.9,11,2.5,16.1c3.4,10.6,10.1,19.6,18.9,26
    c8.6,6.3,19.2,9.9,30.6,9.9s22-3.7,30.6-9.9c8.8-6.4,15.5-15.5,18.9-26c1.6-5.1,2.5-10.5,2.5-16.1C164.1,106.4,163.2,101,161.6,96z
     M112,112L112,112L112,112L112,112L112,112z"/>
  <line fill="none" x1="-18.4" y1="112" x2="242.5" y2="112"/>
  <line fill="none" x1="177.3" y1="225" x2="46.8" y2="-0.9"/>`;
  }
}

function createDonutChart(props: any, display: any): ChildNode | null {
  const offsets = [];
  const counts = _.range(0, 13).map(idx => _.get(props, 'water-' + idx));

  let total = 0;
  for (const count of counts) {
    total += count;
    offsets.push(total);
  }

  const fontSize = total >= 1000 ? 32 : total >= 100 ? 46 : total >= 10 ? 46 : 48;
  const r = total >= 10000 ? 60 : total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
  const r0 = Math.round(r * 0.6);
  const w = r * 2 * (total >= 1000 ? 1.2 : 1.5);
  const h = r * 2 * (total >= 1000 ? 1.2 : 1.5);
  let html = `<div style='z-index: 1' class="${props.cluster_id === display.current.id ? 'hide' : ''}" id="cluster-${
    props.cluster_id
  }"><svg width="${w}" height="${w}" viewbox="0 0 224.1 224.1" text-anchor="middle" style="font: ${fontSize}px sans-serif;">`;
  const blueValue = offsets[7];
  const purpleValue = offsets[9];
  const redValue = offsets[2];
  const greenValue = offsets[5];
  const yellowValue = offsets[12];

  let colorValue = [];

  if (redValue > 0) {
    colorValue.push('r');
  }
  if (greenValue > redValue) {
    colorValue.push('g');
  }
  if (blueValue > greenValue) {
    colorValue.push('b');
  }
  if (purpleValue > blueValue) {
    colorValue.push('p');
  }
  if (yellowValue > purpleValue) {
    colorValue.push('y');
  }
  //console.log('CLUSTTERRRRR CONSTRUCTION', props.cluster_id, display)
  html += donutSegment(colorValue);

  html += `<circle cx="${112.05}" cy="${112.05}" r="${r0 * 1.5}" fill="white"/>
        <text dominant-baseline="central" style="font: ${fontSize}px sans-serif; font-weight: 600" transform="translate(${112.05}, ${112.05})">
        ${total.toLocaleString()}
        </text>
        </svg>
        </div>`;

  const el = document.createElement('div');
  el.innerHTML = html;
  return el.firstChild;
}

const markers: any = {};
let markersOnScreen: any = {};

function clusterConstructor(features: any, map: Map, display: any): void {
  const newMarkers: any = {};
  for (const feature of features) {
    const coords: GeoJSON.GeometryCollection = feature.geometry.coordinates;
    const props = feature.properties;
    if (!props.cluster) continue;
    const id: number = props.cluster_id;

    let marker: Marker = markers[id];
    if (marker) {
      marker.remove();
    }
    const el = createDonutChart(props, display);
    //@ts-ignore
    marker = markers[id] = new mapboxgl.Marker({ element: el }).setLngLat(coords);
    newMarkers[id] = marker;
  }
  for (const id in markersOnScreen) {
    //if (!newMarkers[id]) console.log("ON SCREEN", markersOnScreen[id]);
    if (!newMarkers[id]) {
      markersOnScreen[id].remove();
    }
  }

  for (const id in newMarkers) {
    newMarkers[id].addTo(map);
  }
  markersOnScreen = newMarkers;
}

const clusterConstruction = {
  clusterConstructor,
};

export default clusterConstruction;
