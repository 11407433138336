import React, { useState } from 'react';
import SvgValidation from '../../../SvgComponents/ValidationVert';

const CheckBox = (props: any) => {
  const { isChecked, handleChange } = props;
  const [checked, setChecked] = useState(isChecked);

  const handleClick = () => {
    handleChange(!checked);
    setChecked(!checked);
  };

  const container = {
    height: '18px',
    width: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: checked ? '#31c6b3' : '#fff',
    border: '1px solid',
    borderColor: checked ? '#31c6b3' : '#bbb',
    borderRadius: '0.25rem',
    padding: '3px',
  };
  return (
    <div className="task-checkbox" onClick={() => handleClick()} style={container}>
      {checked && <SvgValidation height="20px" fill="white" />}
    </div>
  );
};

export default CheckBox;
