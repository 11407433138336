import React from 'react';

const SvgMeterWithAlarm = (props: any) => (
  <svg viewBox="0 0 200 200" {...props}>
    <path
      d="M200,168.2l-10.9-10.9l0-30.9c0-14.9-9.3-27.3-23.7-31.5l-2.9-0.9c-0.3-0.2-0.5-0.5-0.6-0.8l0-4.9
			c0-1.5-0.6-2.9-1.7-4c-1.1-1.1-2.6-1.7-4-1.7c0,0,0,0,0,0c-3.1,0-5.8,2.7-5.8,5.8l0,4.7c-0.2,0.4-0.4,0.8-0.8,1.1l-2.8,0.8
			c-14.4,4.2-23.6,16.6-23.6,31.5l0,30.8c0,0.1,0,0.1-0.1,0.2l-10.8,10.8l0,2.1l87.7-0.1L200,168.2z"
      fill={props.fill}
    />
    <path
      d="M47.8,154.2v-0.4l-2.1-10H14.5h-1.9H8.3v-12.7h3.1h1.9h29.9l-6.6-31.9c0,0,0.1-0.1,0.1-0.1H123
			c1.5-1.8,3.1-3.4,4.8-4.9c1.4-1.2,2.8-2.3,4.4-3.3c2.5-1.7,5.3-3.1,8.2-4.2c0-0.2,0.1-0.5,0.1-0.7H26.3c-1.1,0-2-1-2.4-1.5
			c-1.6-2-2.4-5.3-1.9-7.8c4.9-23.1,16.3-45.4,22.3-54.9c1.6-2.5,3-3.4,3.4-3.4l81.6,0c0.4,0.1,1.7,1,3.5,3.9
			c5.6,8.9,16,29,21.3,50.7c0.7-0.1,1.3-0.1,2-0.1c0,0,0,0,0,0c2.4,0,4.7,0.6,6.8,1.6c-5.3-24-17-46.7-23.1-56.5
			c-3.2-5.1-6.7-7.7-10.4-7.7H47.7c-5.1,0-8.7,4.5-10.4,7.2C35.6,20,20.2,45,13.9,74.9c-1.1,5,0.3,10.8,3.5,14.7
			c2.4,3,5.5,4.6,8.9,4.6H30c-1.1,1.4-1.8,3.2-1.8,5.1v0.4l4.8,23.1H20.2c-0.3-0.5-0.8-1-1.3-1.4c-1.2-1-2.7-1.4-4.3-1.3
			c-0.4,0-0.8,0.1-1.1,0.2c-0.7-0.2-1.5-0.3-2.3-0.2c-0.4,0-0.8,0.1-1.1,0.2c-0.7-0.2-1.5-0.3-2.3-0.2c-1.5,0.2-2.9,0.9-3.9,2.1
			c-0.3,0.4-0.6,0.9-0.8,1.3c-1.8,1-3.1,3-3.1,5.2v17.4c0,3.2,2.5,5.8,5.7,5.9c1,1.7,2.9,2.8,5,2.8c0.2,0,0.3,0,0.6,0
			c0.4,0,0.8-0.1,1.1-0.2c0.5,0.2,1.1,0.2,1.7,0.2c0.2,0,0.3,0,0.6,0c0.4,0,0.8-0.1,1.1-0.2c0.5,0.2,1.1,0.2,1.7,0.2
			c0.2,0,0.3,0,0.6,0c1.9-0.2,3.5-1.2,4.4-2.7H39l0.5,2.7c0.3,4.4,3.9,8,8.4,8h56c0.2-0.2,0.3-0.5,0.6-0.7l7.6-7.6H48
			C47.9,154.4,47.8,154.3,47.8,154.2z"
      fill={props.fill}
    />
    <path
      d="M156.1,190C156.1,190,156.1,190,156.1,190C156.2,190,156.2,190,156.1,190C156.2,190,156.2,190,156.1,190
			c7,0,12.9-4.6,15-10.9l-30,0C143.2,185.4,149.1,190,156.1,190z"
      fill={props.fill}
    />
  </svg>
);

export default SvgMeterWithAlarm;
