import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import confirm from '../_components';
import translate from '../_helpers/locale-helpers';

import Stats from './Stats';

interface State {
  name: string;
}

/**
 * @class LastComponent
 * @extends {React.Component<Props, State>}
 */
class LastComponent extends React.Component<any, State> {
  /**
   * @param {Props} props
   * @memberof LastComponent
   */
  constructor(props: any) {
    super(props);
    this.validate = this.validate.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.state = {
      name: '',
    };
  }

  /**
   * Affiche une modal de confirmation de passage à
   * l'étape précédente, et y retourne en fonction
   * du choix de l'utilisateur
   *
   * @async
   * @method validate
   * @memberof LastComponent
   */
  async validate() {
    const { previousStep } = this.props;
    const { Fragment } = React;

    const result = await confirm({
      title: (
        <Fragment>
          <strong>Retourner en arrière </strong>
        </Fragment>
      ),
      message: "Retourner à l'étape précédente ?",
      confirmText: 'Oui',
      cancelText: 'Non',
      confirmColor: 'danger',
      cancelColor: 'primary',
    });
    if (result) {
      previousStep();
    }
  }

  /**
   * Gère le comportement à la soumission du
   * formulaire
   *
   * @param {Object} event Evènement
   * @param {Object} values Valeurs du formulaire
   * @memberof LastComponent
   */
  handleValidSubmit(event: any, values: any) {
    const { update, send } = this.props;
    this.setState({
      name: values.name,
    });
    update('name', values.name);
    send();
  }

  /**
   * Gère le comportement à la soumission
   * invalide du formulaire
   *Props
   * @param {Object} event Evènement
   * @param {Object} errors Erreurs
   * @param {Object} values Valeurs du formulaire
   * @memberof LastComponent
   */
  handleInvalidSubmit(event: any, errors: any, values: any) {
    this.setState({
      name: values.name,
    });
  }

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof LastComponent
   */
  render() {
    const { isActive, form, locales } = this.props;
    const { name } = this.state;

    if (!isActive) return null;
    return (
      <div className="abstractInModal">
        <h3>Résumé</h3>
        <p>
          <span className="label-text">Source de données :</span>{' '}
          {translate('fr', 'dataSourceName', form.datasource.dataSourceName, locales.locale)}
        </p>
        <p>
          <span className="label-text">Valeur à afficher :</span>{' '}
          {translate('fr', 'displayValue', form.property.displayValue, locales.locale)}
        </p>
        <p>
          <span className="label-text">Unité :</span>{' '}
          {translate('fr', 'unit', form.property.displayUnit, locales.locale)}
        </p>
        <p>
          <span className="label-text">Type de représentation :</span>{' '}
          {translate('fr', 'displayType', form.display.displayType, locales.locale)}
        </p>
        <AvForm onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
          <AvField
            name="name"
            value={name}
            label="Nom"
            required
            helpMessage="Nom du widget"
            errorMessage="Le nom du widget est obligatoire et doit avoir plus de 2 caractères"
          />
          <div className="bottomChoice">
            <Row>
              <Col md="4">
                <Stats {...this.props} previousStep={this.validate} />
              </Col>
              <Col md="8" className="text-right">
                <Button color="primary">Enregistrer</Button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { locales } = state;
  return {
    locales,
  };
}

const LastConnectedComponent = connect(mapStateToProps)(LastComponent);
export default LastConnectedComponent;
