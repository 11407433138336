import React from 'react';

const SvgLock = (props: any) => (
  <svg viewBox="0 0 13.87 18.49" {...props}>
    <path
      d="M13.49 6.94h-1.16V5.39a5.4 5.4 0 0 0-10.79 0v1.55H.39a.38.38 0 0 0-.39.38V17a1.54 1.54 0 0 0 1.54 1.54h10.79A1.54 1.54 0 0 0 13.87 17V7.32a.38.38 0 0 0-.38-.38zM7.85 12.8l.24 2.2a.37.37 0 0 1-.1.3.34.34 0 0 1-.28.13H6.17a.36.36 0 0 1-.29-.13.37.37 0 0 1-.1-.3L6 12.8a1.55 1.55 0 1 1 1.83 0zM10 6.94H3.85V5.39a3.09 3.09 0 0 1 6.17 0zm1.54 0h-.77V5.39a3.86 3.86 0 0 0-7.71 0v1.55h-.75V5.39a4.63 4.63 0 0 1 9.25 0z"
      fill={props.fill}
      data-name="Calque 2"
    />
  </svg>
);

export default SvgLock;
