import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import _ from 'lodash';

import { withTranslation } from 'react-i18next';
import moment from 'moment';
import selector from './pack_svg/selector';

const switchFormat = zoom => {
  switch (zoom.toLowerCase()) {
    case 'minute':
      return 'DD/MM/YYYY HH:mm';
    case 'hour':
      return 'DD/MM/YYYY';
    case 'day':
      return 'DD/MM/YYYY';
    case 'month':
      return 'MM/YYYY';
    case 'year':
      return 'YYYY';
    default:
      return 'DD/MM/YYYY';
  }
};

const HistoChart = props => {
  const { meterList, alarms, labels, zoom, t } = props;
  const [size, setSize] = useState({ width: 1480, height: 700 });
  const [dots, setDots] = useState([]);
  const [bars, setBars] = useState([]);
  const [svgs, setSvgs] = useState([]);
  const [alarmList, setAlarmList] = useState([]);
  const currentDots = useRef(dots);
  const currentBars = useRef(dots);
  const currentSvgs = useRef(svgs);
  const currentAlarms = useRef(alarmList);
  const canvas = document.getElementById('canvas-x');
  const drawChart = () => {
    const totalDatas = meterList.map(el => el.datas).flat();
    const min = Math.min(...totalDatas.map(el => (el.land ? el.value : el)));
    const max = Math.max(...totalDatas.map(el => (el.land ? el.value : el)));

    const unite = parseInt('1'.padEnd(Math.round(max).toString().length, '0'), 10);

    const sMin = (min / unite) * unite > 0 ? 0 : (min / unite) * unite;
    const sMax = (max / unite) * unite + max * 0.05;

    const alarmChartHeight = 70 * alarms.length + 50;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const canvasInfo = canvas.getBoundingClientRect();

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.font = '14px Arial';
      ctx.textAlign = 'center';
      ctx.fillStyle = 'black';

      let marginLeft = ctx.measureText(sMax.toFixed(3).toString()).width + 20;
      const diff = Math.abs(sMax) + Math.abs(sMin);

      for (let i = 0; i < 10; i++) {
        const w = ctx.measureText(
          (diff / 10) * (10 - i) >= 1000000
            ? `${(Math.round(((diff / 10) * (10 - i) - Math.abs(sMin)) * 1000) / 1000 / 1000).toFixed(3)}k`
            : `${(Math.round(((diff / 10) * (10 - i) - Math.abs(sMin)) * 1000) / 1000).toFixed(3)}`
        ).width;
        if (marginLeft < w) {
          marginLeft = w;
        }
      }

      marginLeft += 15;

      ctx.beginPath();
      ctx.strokeStyle = 'grey';
      ctx.lineWidth = 1;
      ctx.moveTo(marginLeft, 20);
      ctx.lineTo(marginLeft, canvasInfo.height + 10 - alarmChartHeight);
      ctx.moveTo(marginLeft - 10, canvasInfo.height - alarmChartHeight);
      ctx.lineTo(canvasInfo.width, canvasInfo.height - alarmChartHeight);
      ctx.fillText(sMin.toString(), 30, canvasInfo.height - alarmChartHeight + 3.5);

      if (alarms.length > 0) {
        ctx.moveTo(marginLeft, canvasInfo.height - alarmChartHeight + 15);
        ctx.lineTo(marginLeft, canvasInfo.height - 30);
        ctx.moveTo(marginLeft - 10, canvasInfo.height - 40);
        ctx.lineTo(canvasInfo.width, canvasInfo.height - 40);
      }
      ctx.stroke();

      // SCALE
      const tickY = (canvasInfo.height - alarmChartHeight - 20) / 10;
      const tickX = (canvasInfo.width - marginLeft) / (labels.length - 0.5);

      const graphHeight = canvasInfo.height - alarmChartHeight - 20;

      ctx.strokeStyle = '#ececec';
      ctx.strokeWidth = 1;

      const paddingLeft = labels.length > 1 ? 0 : (canvasInfo.width - marginLeft) / 2;

      ctx.beginPath();
      for (let i = 0; i < 10; i++) {
        ctx.moveTo(marginLeft - 10, 20 + tickY * i);
        ctx.lineTo(canvasInfo.width, 23.5 + tickY * i);
        ctx.fillText(
          (diff / 10) * (10 - i) >= 1000000
            ? `${(Math.round(((diff / 10) * (10 - i) - Math.abs(sMin)) * 1000) / 1000 / 1000).toFixed(3)}k`
            : `${(Math.round(((diff / 10) * (10 - i) - Math.abs(sMin)) * 1000) / 1000).toFixed(3)}`,
          marginLeft / 2,
          23.5 + tickY * i
        );
      }

      const labelsPositionList = [];

      labels.forEach((el, index) => {
        ctx.moveTo(marginLeft + paddingLeft + tickX * index, 20);
        ctx.lineTo(marginLeft + paddingLeft + tickX * index, canvasInfo.height - alarmChartHeight + 5);

        ctx.moveTo(
          marginLeft + paddingLeft + tickX * index,
          canvasInfo.height - alarmChartHeight + (alarms.length > 0 ? 15 : 0)
        );
        ctx.lineTo(marginLeft + paddingLeft + tickX * index, canvasInfo.height - (alarms.length > 0 ? 30 : 40));
        const space =
          canvasInfo.width -
          (marginLeft +
            paddingLeft +
            tickX * index +
            ctx.measureText(moment.parseZone(el).format(switchFormat(zoom))).width);

        const x = marginLeft + paddingLeft + tickX * index;
        const y = canvasInfo.height - 20;
        const w = ctx.measureText(moment.parseZone(el).format(switchFormat(zoom))).width;
        if (space >= -25 && !labelsPositionList.find(el => x < el.x + el.w)) {
          ctx.fillText(moment.parseZone(el).format(switchFormat(zoom)), x, y);
          if (zoom.toLowerCase() === 'hour') {
            ctx.fillText(
              moment
                .parseZone(el)
                .format(switchFormat('minute'))
                .split(' ')[1],
              x,
              y + 15
            );
          }
          labelsPositionList.push({ x, y, w });
        }
      });
      ctx.stroke();

      // DATA
      ctx.fillStyle = '#31c6b3';
      ctx.strokeStyle = '#31c6b3';

      const dotsList = [];
      const barsList = [];
      const alarmsList = [];

      const textOverlap = [];

      let dotIndex = 0;
      let lineIndex = 0;
      let barIndex = 0;

      let dataLabelIndex = 0;

      const tamp = {
        x: null,
        y: null,
      };

      meterList.forEach((meter, meterIndex) => {
        ctx.fillStyle = '#8E8DBE';
        meter.datas.forEach((el, index) => {
          // CONSO TERRAINS
          if (el.land) {
            const x =
              barIndex > 0
                ? marginLeft + tickX * barIndex + paddingLeft - tickX / 4
                : marginLeft + paddingLeft + tickX * barIndex - 10;
            const y =
              20 +
              graphHeight -
              (((el.value || el) + Math.abs(sMin)) / (Math.abs(sMin) + Math.abs(sMax))) * graphHeight;
            const barWidth = barIndex > 0 ? tickX / 2 : 20;
            const barHeight =
              graphHeight -
              (graphHeight - (((el.value || el) + Math.abs(sMin)) / (Math.abs(sMin) + Math.abs(sMax))) * graphHeight);

            ctx.beginPath();
            ctx.fillStyle = 'rgba(142,141,190,0.4)';
            ctx.rect(x, 20, barWidth, graphHeight);
            ctx.fill();

            ctx.beginPath();
            ctx.fillStyle = 'rgba(142,141,190,1)';
            ctx.rect(x, y, barWidth, barHeight);
            ctx.fill();

            barsList.push({
              x1: x,
              y1: y,
              x2: x + barWidth,
              y2: y + barHeight,
              value: el.value || el,
              label: meter.label,
              date: labels[barIndex],
            });
          }
          barIndex++;
        });

        const color = meterList.length > 1 ? (meterIndex % 2 === 0 ? '#D78521' : '#31c6b3') : '#31c6b3';
        ctx.fillStyle = color;
        ctx.strokeStyle = color;

        ctx.lineWidth = 2;

        ctx.beginPath();
        meter.datas.forEach((el, index) => {
          if (meterIndex > 0 && index === 0 && tamp.x && tamp.y) {
            ctx.moveTo(tamp.x, tamp.y);
          }
          ctx.lineTo(
            marginLeft + tickX * lineIndex,
            20 +
              graphHeight -
              (((el.value === 0 ? 0 : el.value || el) + Math.abs(sMin)) / (Math.abs(sMin) + Math.abs(sMax))) *
                graphHeight
          );
          tamp.x = marginLeft + tickX * lineIndex;
          tamp.y =
            20 +
            graphHeight -
            (((el.value === 0 ? 0 : el.value || el) + Math.abs(sMin)) / (Math.abs(sMin) + Math.abs(sMax))) *
              graphHeight;
          lineIndex++;
        });
        ctx.stroke();

        ctx.lineWidth = 1;

        // POINT CONSO
        meter.datas.forEach((el, i) => {
          ctx.beginPath();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.arc(
            marginLeft + paddingLeft + tickX * dotIndex,
            20 +
              graphHeight -
              (((el.value === 0 ? 0 : el.value || el) + Math.abs(sMin)) / (Math.abs(sMin) + Math.abs(sMax))) *
                graphHeight,
            5,
            0,
            2 * Math.PI,
            true
          );
          dotsList.push({
            x: marginLeft + paddingLeft + tickX * dotIndex,
            y:
              20 +
              graphHeight -
              (((el.value === 0 ? 0 : el.value || el) + Math.abs(sMin)) / (Math.abs(sMin) + Math.abs(sMax))) *
                graphHeight,
            r: 5,
            value: el.value === 0 ? 0 : el.value || el,
            label: meter.label,
            date: labels[dotIndex],
          });
          ctx.globalCompositeOperation = 'source-over';
          ctx.fill();
          dotIndex++;
        });

        meter.datas.forEach((el, index) => {
          const x = marginLeft + paddingLeft + tickX * dataLabelIndex;
          const y =
            graphHeight + 10 - (((el.value || el) + Math.abs(sMin)) / (Math.abs(sMin) + Math.abs(sMax))) * graphHeight;
          const w = ctx.measureText(el.value).width;
          // CONSO TERRAINS
          if (
            el.land &&
            dataLabelIndex > 0 &&
            !textOverlap.find(el => x < el.x + el.w || y > el.y + el.h || y + 15 < el.y)
          ) {
            ctx.beginPath();
            ctx.fillStyle = 'black';
            ctx.fillText(el.value, x, y);
            textOverlap.push({
              x,
              y,
              w,
              h: 15,
            });
          }
          dataLabelIndex++;
        });
      });

      ctx.fillStyle = '#31c6b3';

      // ALARMES
      const svgList = [];
      alarms.forEach((el, index) => {
        ctx.fillStyle = el.color;
        const img = new Image();
        img.onload = function() {
          ctx.drawImage(img, 10, canvasInfo.height - alarmChartHeight + 70 * (index + 1) - 40, 30, 30);
          svgList.push({
            x1: 10,
            y1: canvasInfo.height - alarmChartHeight + 70 * (index + 1) - 40,
            x2: 40,
            y2: canvasInfo.height - alarmChartHeight + 70 * (index + 1) - 10,
            label: t(`alarm.type.${el.type.toLowerCase()}`),
          });
        };
        img.src = selector(el.type.toUpperCase());
        el.data.forEach(data => {
          if (data.start >= 0) {
            const x = marginLeft + tickX * data.start;
            const y = canvasInfo.height - alarmChartHeight + 70 * (index + 1) - 40;
            const barWidth = tickX * (data.end - data.start);
            const barHeight = 30;
            ctx.beginPath();
            ctx.rect(x, y, barWidth, barHeight);
            ctx.fill();
            alarmsList.push({
              x1: x,
              y1: y,
              x2: x + barWidth,
              y2: y + barHeight,
              index: { start: data.start, end: data.end },
              label: t(`alarm.type.${el.type.toLowerCase()}`),
            });
          } else {
            const x = marginLeft + paddingLeft + tickX * data;
            const y = canvasInfo.height - alarmChartHeight + 70 * (index + 1) - 40 + 15;
            ctx.beginPath();
            ctx.arc(x, y, 8, 0, 2 * Math.PI, true);
            dotsList.push({
              x,
              y,
              r: 8,
              value: data,
              label: t(`alarm.type.${el.type.toLowerCase()}`),
              isAlarm: true,
            });
            ctx.fill();
          }
        });
      });
      ctx.stroke();
      // eslint-disable-next-line no-use-before-define
      canvas.addEventListener('mousemove', handleMouse);
      setDots(dotsList);
      setBars(barsList);
      setAlarmList(alarmsList);
      setSvgs(svgList);
    }
  };

  const handleMouse = e => {
    const tooltip = document.getElementById('tooltip');
    const line = document.getElementById('hover-line');

    if (canvas && tooltip) {
      const ctxTip = tooltip.getContext('2d');
      const ctxLine = line.getContext('2d');
      const canvasInfo = canvas.getBoundingClientRect();
      const mouseX = e.clientX - canvasInfo.x;
      const mouseY = e.clientY - canvasInfo.y;
      let hit = false;
      currentDots.current &&
        currentDots.current.forEach(dot => {
          const dx = mouseX - dot.x;
          const dy = mouseY - dot.y;
          if ((dx * dx < 20 && !dot.isAlarm) || (dx * dx + dy * dy < 40 && dot.isAlarm)) {
            hit = true;
            tooltip.style.left = `${dot.x}px`;
            tooltip.style.top = `${dot.y + 10}px`;
            tooltip.height = 50;
            tooltip.style.height = '50px';
            ctxTip.clearRect(0, 0, 200, 70);
            ctxTip.fillStyle = 'white';
            ctxTip.font = '14px sans-serif';
            ctxTip.fillText(dot.label, 5, 15);
            ctxTip.font = '12px arial';
            ctxTip.fillText(
              dot.isAlarm
                ? `Date: ${moment
                    .parseZone(labels[dot.value])
                    .format(switchFormat(zoom.toLowerCase() === 'hour' ? 'minute' : zoom))}`
                : `Consommation: ${dot.value === 0 ? 0 : dot.value} m3`,
              5,
              30
            );
            if (!dot.isAlarm) {
              ctxTip.fillText(
                `Date: ${moment
                  .parseZone(dot.date)
                  .format(switchFormat(zoom.toLowerCase() === 'hour' ? 'minute' : zoom))}`,
                5,
                45
              );
            }
            tooltip.style.visibility = 'visible';
            tooltip.style.backgroundColor = '#000000b3';
          }
        });
      currentBars.current &&
        currentBars.current.forEach(bar => {
          if (mouseX >= bar.x1 && mouseX <= bar.x2 && (mouseY >= bar.y1 && mouseY <= bar.y2)) {
            hit = true;
            tooltip.style.left = `${bar.x1 + (bar.x2 - bar.x1) / 2}px`;
            tooltip.style.top = `${bar.y1 + 10}px`;
            tooltip.height = 50;
            tooltip.style.height = '50px';
            ctxTip.clearRect(0, 0, 200, 70);
            ctxTip.fillStyle = 'white';
            ctxTip.font = '14px sans-serif';
            ctxTip.fillText(`${bar.label} - Terrain`, 5, 15);
            ctxTip.font = '12px arial';
            ctxTip.fillText(`Consommation: ${bar.value === 0 ? 0 : bar.value} m3`, 5, 30);
            ctxTip.fillText(
              `Date: ${moment
                .parseZone(bar.date)
                .format(switchFormat(zoom.toLowerCase() === 'hour' ? 'minute' : zoom))}`,
              5,
              45
            );
            tooltip.style.visibility = 'visible';
            tooltip.style.backgroundColor = '#000000b3';
          }
        });
      currentSvgs.current &&
        currentSvgs.current.forEach(svg => {
          if (mouseX >= svg.x1 && mouseX <= svg.x2 && (mouseY >= svg.y1 && mouseY <= svg.y2)) {
            hit = true;
            tooltip.style.left = `${svg.x1}px`;
            tooltip.style.top = `${svg.y1 + 10}px`;
            tooltip.height = 30;
            tooltip.style.height = '30px';
            ctxTip.height = `10px`;
            ctxTip.clearRect(0, 0, 200, 70);
            ctxTip.fillStyle = 'white';
            ctxTip.font = '14px sans-serif';
            ctxTip.fillText(`${svg.label}`, 5, 20);
            ctxTip.font = '12px arial';
            tooltip.style.visibility = 'visible';
            tooltip.style.backgroundColor = '#000000b3';
          }
        });
      currentAlarms.current &&
        currentAlarms.current.forEach(bar => {
          if (mouseX >= bar.x1 && mouseX <= bar.x2 && (mouseY >= bar.y1 && mouseY <= bar.y2)) {
            hit = true;
            tooltip.style.left = `${bar.x1 + (bar.x2 - bar.x1) / 2}px`;
            tooltip.style.top = `${bar.y1 + 10}px`;
            tooltip.height = 40;
            tooltip.style.height = '40px';
            ctxTip.clearRect(0, 0, 200, 70);
            ctxTip.fillStyle = 'white';
            ctxTip.font = '14px sans-serif';
            ctxTip.fillText(`${bar.label}`, 5, 15);
            ctxTip.font = '12px arial';
            ctxTip.fillText(
              `Du ${moment
                .parseZone(labels[bar.index.start])
                .format(switchFormat(zoom.toLowerCase() === 'hour' ? 'minute' : zoom))} au ${moment
                .parseZone(labels[bar.index.end])
                .format(switchFormat(zoom.toLowerCase() === 'hour' ? 'minute' : zoom))} `,
              5,
              35
            );
            tooltip.style.visibility = 'visible';
            tooltip.style.backgroundColor = '#000000b3';
          }
        });
      if (!hit) {
        tooltip.style.left = '-200px';
        tooltip.style.visibility = 'hidden';
        tooltip.style.border = 'none';
      }
      ctxLine.beginPath();
      ctxLine.clearRect(0, 0, size.width, size.height);
      ctxLine.moveTo(mouseX, 20);
      ctxLine.lineTo(mouseX, canvasInfo.height - 30);
      ctxLine.stroke();
    }
  };

  const handleLeave = () => {
    const tooltip = document.getElementById('tooltip');

    const line = document.getElementById('hover-line');
    const ctxLine = line.getContext('2d');

    ctxLine.beginPath();
    ctxLine.clearRect(0, 0, size.width, size.height);
    ctxLine.stroke();
  };

  useEffect(() => {
    drawChart();
    window.addEventListener('resize', () => {
      let container = document.getElementById('chart-wrapper');
      if (container) {
        container = container.getBoundingClientRect();
        const copy = _.cloneDeep(size);
        copy.width = container.width;
        setSize(copy);
      }
    });
    let container = document.getElementById('chart-wrapper');
    if (container) {
      container = container.getBoundingClientRect();
      const copy = _.cloneDeep(size);
      copy.width = container.width;
      copy.height = 560 + 70 * alarms.length;
      setSize(copy);
    }
  }, []);

  useEffect(() => {
    drawChart();
  }, [canvas]);

  useEffect(() => {
    drawChart();
  }, [size]);

  useEffect(() => {
    currentDots.current = dots;
    currentBars.current = bars;
    currentAlarms.current = alarmList;
    currentSvgs.current = svgs;
  }, [dots || bars || alarmList || svgs]);

  useEffect(() => {
    drawChart();
  }, [meterList]);

  useEffect(() => {
    drawChart();
  }, [labels]);

  return (
    <div className="histo-chart-container" id="chart-wrapper">
      <canvas id="canvas-x" height={size.height} width={size.width || 1480} onMouseLeave={handleLeave} />
      <canvas id="hover-line" height={size.height} width={size.width || 1480} />
      <canvas id="tooltip" height="70px" width="250px" />
    </div>
  );
};

const tr = withTranslation()(HistoChart);
export default tr;
