import React from 'react';

const FusionTournees = (props: any) => (
  <svg viewBox="0 0 132.23 149.11" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="pictos">
        <path
          fill={props.fill}
          d="M90.21,146.24a39.25,39.25,0,1,1,0-78.49,35.2,35.2,0,0,1,3.58.16,39.25,39.25,0,0,1-3.58,78.33M66.74,9.65V21.4H25ZM93.79,65.13V25.6a4.15,4.15,0,0,0-4.16-4.16H75.11V4.16A4.12,4.12,0,0,0,73.45.85,4.07,4.07,0,0,0,69.81.16L3.64,21A4.43,4.43,0,0,0,1.5,22.41,4.08,4.08,0,0,0,0,25.6v103a4.16,4.16,0,0,0,4.16,4.16H56.89a42.06,42.06,0,1,0,36.9-67.63"
        />
        <path
          fill={props.fill}
          d="M109,91H71.34a.7.7,0,0,0-.68.68v7.11a.71.71,0,0,0,.68.69H109a.72.72,0,0,0,.68-.69V91.65A.67.67,0,0,0,109,91"
        />
        <path
          fill={props.fill}
          d="M109,102.8H71.34a.7.7,0,0,0-.68.69v7.09a.71.71,0,0,0,.68.7H109a.72.72,0,0,0,.68-.7v-7.09a.65.65,0,0,0-.68-.69"
        />
        <path
          fill={props.fill}
          d="M109,114.63H71.34a.7.7,0,0,0-.68.68v7.11a.71.71,0,0,0,.68.69H109a.72.72,0,0,0,.68-.69v-7.11a.65.65,0,0,0-.68-.68"
        />
      </g>
    </g>
  </svg>
);

export default FusionTournees;
