import React from 'react';
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import translate from '../_helpers/locale-helpers';

interface State {
  datasource: any;
}

/**
 * @class DatasourceChooserComponent
 * @extends {React.Component<Props>}
 */
class DatasourceChooserComponent extends React.Component<any, State> {
  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof DatasourceChooserComponent
   */
  constructor(props: any) {
    super(props);
    this.send = this.send.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);

    const { datasource } = this.props;
    this.state = {
      datasource,
    };
  }

  /**
   * Met à jour le template avec la datasource
   * et passe à l'étape suivante
   *
   * @param {any} e Evènement
   * @method send
   * @memberof DatasourceChooserComponent
   */
  send(e: any) {
    e.preventDefault();
    const { update, nextStep } = this.props;
    const { datasource } = this.state;
    update('datasource', datasource);
    nextStep();
  }

  /**
   * Gère le comportement au changement de la datasource
   *
   * @param {Object} event Evènement
   * @method handleOptionChange
   * @memberof DatasourceChooserComponent
   */
  handleOptionChange(event: any) {
    const { datasources } = this.props;
    const finded = datasources.filter((item: any) => event.target.value === item.dataSourceName);
    if (finded && finded.length > 0) {
      this.setState({
        datasource: finded[0],
      });
    }
  }

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof DatasourceChooserComponent
   */
  render() {
    const { isActive, datasources, locales, t } = this.props;
    const { datasource } = this.state;

    if (!isActive) return null;
    return (
      <div>
        {datasources && (
          <Form onSubmit={this.send}>
            <h4>Choix de la source de données</h4>
            <div className="datasourceChoices">
              {datasources.map((item: any) => (
                <div className="templateChoices" key={item.dataSourceName}>
                  <FormGroup check key={item.dataSourceName}>
                    <Label check>
                      {' '}
                      <input
                        type="radio"
                        id={item.dataSourceName}
                        name={item.dataSourceName}
                        value={item.dataSourceName}
                        onChange={this.handleOptionChange}
                        checked={datasource.dataSourceName === item.dataSourceName}
                      />{' '}
                      {t(
                        `widget.type_source.${translate(
                          'fr',
                          'dataSourceName',
                          item.dataSourceName,
                          locales.locale
                        ).toLowerCase()}`
                      )}
                    </Label>
                  </FormGroup>
                </div>
              ))}
            </div>
            <div className="bottomChoice">
              <Row>
                <Col md="12" className="text-right">
                  <Button color="primary">Continuer</Button>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { locales } = state;
  return {
    locales,
  };
}

const DatasourceChooserConnectedComponent = connect(mapStateToProps)(DatasourceChooserComponent);
export default DatasourceChooserConnectedComponent;
