import React from 'react';

const SvgReglagesBleu = (props: any) => (
  <svg viewBox="0 0 18.73 18.73" {...props}>
    <g id="reglages_bleu_svg__Calque_2" data-name="Calque 2">
      <g id="reglages_bleu_svg__Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          d="M17.54 7.09h-1.71a.39.39 0 0 1-.37-.25.38.38 0 0 1 .09-.44l1.2-1.21a1.15 1.15 0 0 0 .35-.84 1.18 1.18 0 0 0-.35-.84L15.22 2a1.26 1.26 0 0 0-1.68 0l-1.21 1.18a.4.4 0 0 1-.44.09.39.39 0 0 1-.25-.38v-1.7A1.19 1.19 0 0 0 10.45 0H8.28a1.19 1.19 0 0 0-1.19 1.19v1.7a.39.39 0 0 1-.25.38.4.4 0 0 1-.44-.09L5.19 2a1.26 1.26 0 0 0-1.68 0L2 3.51a1.21 1.21 0 0 0 0 1.68L3.18 6.4a.4.4 0 0 1 .09.44.39.39 0 0 1-.38.25h-1.7A1.19 1.19 0 0 0 0 8.28v2.17a1.19 1.19 0 0 0 1.19 1.19h1.7a.4.4 0 0 1 .29.69L2 13.54a1.18 1.18 0 0 0-.34.84 1.21 1.21 0 0 0 .34.84l1.54 1.53a1.23 1.23 0 0 0 1.68 0l1.21-1.2a.38.38 0 0 1 .44-.09.39.39 0 0 1 .25.37v1.71a1.19 1.19 0 0 0 1.19 1.19h2.17a1.19 1.19 0 0 0 1.19-1.19v-1.71a.39.39 0 0 1 .25-.37.38.38 0 0 1 .44.09l1.21 1.2a1.23 1.23 0 0 0 1.68 0l1.53-1.53a1.18 1.18 0 0 0 .35-.84 1.15 1.15 0 0 0-.35-.84l-1.2-1.21a.38.38 0 0 1-.09-.44.38.38 0 0 1 .37-.25h1.71a1.19 1.19 0 0 0 1.19-1.19V8.28a1.19 1.19 0 0 0-1.22-1.19zm-3.17 4.35a1.55 1.55 0 0 0 .35 1.72l1.2 1.23-1.55 1.53-1.21-1.2a1.58 1.58 0 0 0-2.7 1.11v1.72h-2.2v-1.72a1.53 1.53 0 0 0-1-1.45 1.5 1.5 0 0 0-.62-.13 1.55 1.55 0 0 0-1.1.47l-1.23 1.2-1.5-1.55L4 13.16a1.58 1.58 0 0 0-1.12-2.7h-1.7v-2.2h1.7A1.58 1.58 0 0 0 4 5.57L2.81 4.34l1.55-1.53L5.57 4a1.58 1.58 0 0 0 2.69-1.11V1.18h2.18v1.7A1.58 1.58 0 0 0 13.16 4l1.23-1.2 1.53 1.55-1.2 1.21a1.57 1.57 0 0 0 1.11 2.69h1.72v2.17h-1.72a1.56 1.56 0 0 0-1.46 1.02z"
        />
        <path
          fill={props.fill}
          d="M9.36 6.08a3.29 3.29 0 1 0 3.29 3.29 3.28 3.28 0 0 0-3.29-3.29zm2.12 3.29a2.12 2.12 0 1 1-2.12-2.12 2.12 2.12 0 0 1 2.12 2.12z"
        />
      </g>
    </g>
  </svg>
);

export default SvgReglagesBleu;
