import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import './style.scss';
import { Col, FormGroup, Label, Row, UncontrolledTooltip } from 'reactstrap';
import _ from 'lodash';
import { Chart } from '../Widget/Chart';
import Picto from './Picto_correspondance';
import { localeActions, locationActions, mapActions, tourneeActions } from '../_actions';
import HistoriqueVert from '../SvgComponents/HistoriqueVert';
import NoFilled from '../Message/NoFilled';
import PictoAlarm from '../pdi_vue/PictoAlarm';
import CompteurModifie from '../SvgComponents/CompteurModifie';
import ListTools from '../List/ListTools';
import FlecheRetourLarge from '../SvgComponents/FlecheRetourLarge';
import FlecheAllerLarge from '../SvgComponents/FlecheAllerLarge';
import NoPresent from '../Message/NoPresent';
import NormalConso from '../SvgComponents/NormalConso';
import WeakCons from '../SvgComponents/WeakCons';
import StrongCons from '../SvgComponents/StrongCons';
import SmallWeakCons from '../SvgComponents/SmallWeakCons';
import SmallStrongCons from '../SvgComponents/SmallStrongCons';
import ChangeMeter from '../SvgComponents/ChangeMeter';
import IndexWrapped from './IndexWrapped';
import Triangle from './Triangle';
import IndexWrappedLight from './IndexWrappedLight';
import colorActions from '../_actions/color.actions';
import colorize from '../_helpers/colorize-helper';
import { withTranslation } from 'react-i18next';
import EnqueteSvg from '../SvgComponents/EnqueteSvg';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import StyledSelect from './useFullComponent/StyledSelect';
import StyledInput from './useFullComponent/StyledInput';
import EditionBleu from '../SvgComponents/EditionBleu';
import HistoAlarmChart from './HistoAlarmChart';
import SvgCroixNoir from '../SvgComponents/croixNoir';
import SvgValidation from '../SvgComponents/ValidationVert';
import Mapbox from '../Mapbox/components/Mapbox';
import BarChart from './useFullComponent/BarChart';
import { Grow, Tooltip } from '@material-ui/core';
import AttentionRounded from '../SvgComponents/AttentionRounded';
import GaleryPhoto from '../SourceSheet/GaleryPhoto';

interface ListMeter {
  id: number;
  serial: string;
  latitude: number;
  longitude: number;
}

interface Read {
  meterIndex: number;
  date: string;
  methodLetter: string;
  consumption: number;
  tendency: number;
  average: number;
  minConsumption: number;
  maxConsumption: number;
}

interface Pdi {
  cardOrder: number;
  informations: string;
  marquage: string;
  lastUpdate: string;
}

interface Radio {
  installDate: string;
  removeDate: string;
  internalSerial: string;
  composeInternalSerial: string;
  manufacturer: string;
  model: string;
  pulseWeight: number;
  serial: string;
}

interface Meter {
  theoricSerial: string;
  revisedSerial: string;
  diameter: number;
  informations: string;
  installDate: string;
  latitude: number;
  longitude: number;
  manufacturer: string;
  message: string;
  model: string;
  particularMessage: string;
  readPose: Read;
  updatedDate: string;
  wheels: number;
  revisedWheels: number;
  radio: Radio;
}

interface ReadHistory {
  date: string;
  meterConsumption: number;
}

interface FetchedFiche {
  actualAlarms: [any];
  baseMeter: Meter;
  changeMeter: Meter;
  ficheState: string;
  pdi: Pdi;
  previousAlarms: [any];
  previousRead: Read;
  radio: Radio;
  read: Read;
  changeRadio: Radio;
  readHistory: [ReadHistory];
}

interface Tournee {
  code: string;
  name: string;
  message: string;
  startDate?: string;
  totalCpt: number;
  totalRadio: number;
  indexedMeters: number;
  modifiedMeters: number;
  listMeters: ListMeter[];
  info: string;
  fetchedFiche: FetchedFiche;
}

interface Props {
  tournee: Tournee;
  dispatch: Function;
  match: any;
  locations: any;
  locales: any;
  colors: any;
  t: Function;
}

const FIELD_EDITABLE = {
  MTR_INFORMATIONS: 'mtrInformations',
  CLP_INFORMATIONS: 'clpInformations',
  PARTICULAR_MSG: 'particularMessage',
  MARQUAGE: 'marquage',
  MESSAGE: 'message',
  METER_SERIAL: 'meterSerial',
  INDEX: 'index',
};

class FicheDetail extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.handleFlip = this.handleFlip.bind(this);
    this.radioContainer = this.radioContainer.bind(this);
    this.getMeterInfo = this.getMeterInfo.bind(this);
    this.getConsBlock = this.getConsBlock.bind(this);
    this.alarmRadioContainer = this.alarmRadioContainer.bind(this);
    this.generateBlockAlarms = this.generateBlockAlarms.bind(this);
    this.generateLineInfo = this.generateLineInfo.bind(this);
    this.changeFieldValue = this.changeFieldValue.bind(this);
    this.changeCurrentEditField = this.changeCurrentEditField.bind(this);
    this.closeEditField = this.closeEditField.bind(this);
    this.isIndexBelow = this.isIndexBelow.bind(this);
    this.verifUpdateInfo = this.verifUpdateInfo.bind(this);

    this.state = {
      init: false,
      flipped: false,
      dataAlarms: [],
      labelAlarms: [],
      editableField: '',
      editableFieldValue: null,
    };
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { tourneeCode, locationId, ficheId } = match.params;
    dispatch(localeActions.load());
    dispatch(colorActions.load());
    if (tourneeCode) {
      dispatch(tourneeActions.getFicheTournee(tourneeCode, ficheId));
      dispatch(tourneeActions.getTournee(tourneeCode));
      dispatch(tourneeActions.getEditInfos(tourneeCode));
    }

    locationId && dispatch(locationActions.get(locationId));
    moment.updateLocale(moment.locale(), { invalidDate: '-' });
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(mapActions.clearMarker());
    dispatch(tourneeActions.clear());
  }

  static getDerivedStateFromProps(props: any, state: any) {
    const { locales, colors, t } = props;
    const copyState = _.clone(state);
    if (props.tournee && props.tournee.fetchedFiche) {
      copyState.labelAlarms = props.tournee.fetchedFiche.alarmHistory.map((el: any) => el.date);
      const types = _.uniq(props.tournee.fetchedFiche.alarmHistory.map((el: any) => el.types).flat());
      copyState.dataAlarms = types.map((type: any) => {
        const newElem = {
          color: colorize('chart', 'alarmType', type, colors.color),
          data: copyState.labelAlarms.map((label: string) =>
            props.tournee.fetchedFiche.alarmHistory.find((el: any) => el.date === label && el.types.includes(type))
              ? 1
              : 0
          ),
          label: t('alarm.type.' + type.toLowerCase()),
        };
        return newElem;
      });
    }
    return copyState;
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot?: any) {
    const { locations, dispatch, match } = this.props;
    const { init } = this.state;
    if (locations.fetchedLocation && !init) {
      dispatch(tourneeActions.getFicheTournee(locations.fetchedLocation.code, match.params.ficheId));
      dispatch(tourneeActions.getTournee(locations.fetchedLocation.code));
      dispatch(tourneeActions.getEditInfos(locations.fetchedLocation.code));
      this.setState({ init: true });
    }
  }

  changeCurrentEditField(fieldName: string) {
    this.setState({ editableField: fieldName, editableFieldValue: null });
  }

  changeFieldValue() {
    const { editableField, editableFieldValue } = this.state;
    const {
      tournee: {
        fetchedFiche: {
          pdi: { cardOrder },
          baseMeter: { theoricSerial },
        },
        info: { code },
      },
      dispatch,
    } = this.props;
    const sendableEditableFieldValue = JSON.parse(JSON.stringify(editableFieldValue));
    if (sendableEditableFieldValue && sendableEditableFieldValue.date) {
      sendableEditableFieldValue.date = moment.utc(sendableEditableFieldValue.date).toISOString(true);
    }
    dispatch(tourneeActions.updateFieldFiche(code, theoricSerial, editableField, sendableEditableFieldValue));
    this.closeEditField();
  }

  isIndexBelow() {
    const { editableField, editableFieldValue } = this.state;
    const {
      tournee: {
        fetchedFiche: { previousRead },
      },
    } = this.props;
    if (previousRead && previousRead.meterIndex) {
      return editableField === 'index' && editableFieldValue && editableFieldValue.index < previousRead.meterIndex;
    } else {
      return false;
    }
  }

  closeEditField() {
    this.setState({ editableField: '', editableFieldValue: null });
  }

  deleteSelectOption(field) {
    const {
      tournee: {
        fetchedFiche: {
          pdi: { cardOrder },
          baseMeter: { theoricSerial },
        },
        info: { code },
      },
      dispatch,
    } = this.props;
    if (field === 'marquage') {
      dispatch(tourneeActions.deleteMarquage(code, theoricSerial));
    } else {
      dispatch(tourneeActions.deleteMessage(code, theoricSerial));
    }
  }

  verifUpdateInfo = (field: any) => {
    const { tournee } = this.props;
    switch (field) {
      case FIELD_EDITABLE.CLP_INFORMATIONS:
        return _.get(tournee, 'editInfos.roundSubscriberArea', []).length > 0;
      case FIELD_EDITABLE.MTR_INFORMATIONS:
        return _.get(tournee, 'editInfos.roundSubscriberArea', []).length > 0;
      default:
        return true;
    }
  };

  generateLineInfo(title: string, info: any, imgName: string, nameEditableField: string = null) {
    const { editableField } = this.state;
    const {
      tournee: {
        fetchedFiche: {
          pdi: { marquage },
          baseMeter: { message },
        },
      },
      t,
    } = this.props;
    return (
      <div
        className="row meterInfo"
        style={{ marginLeft: '10px', maxWidth: '90%', display: 'flex', flexDirection: 'row' }}
      >
        <div className=" picto" style={{ marginTop: '10px', padding: '0 15px' }}>
          {Picto(imgName) ? (
            Picto(imgName, {
              style: { marginRight: '10px' },
              height: '2em',
              width: '2em',
              stroke: '#31c6b3',
              fill: '#31c6b3',
              strokeWidth: '0',
            })
          ) : (
            <div style={{ width: '35.5px' }} />
          )}{' '}
        </div>
        <div className="" style={{ flex: 1, padding: '0 15px' }}>
          <p>
            <span className="infoLibelle">{title}</span> <br />
            {!_.values(FIELD_EDITABLE).includes(nameEditableField) || nameEditableField !== editableField ? (
              info ? (
                <div className="infoDisplay" style={{ overflowWrap: 'break-word' }}>
                  {info}{' '}
                </div>
              ) : (
                <NoFilled />
              )
            ) : (
              this.generateEditField(nameEditableField)
            )}
            {editableField === nameEditableField && (
              <div className={'button-group'}>
                <div className={'sticky-button'} onClick={this.closeEditField}>
                  <span>{t('all.button.cancel')}</span>
                  <SvgCroixNoir height={'1.7em'} />
                </div>
                <div className={'sticky-button'} onClick={this.changeFieldValue}>
                  <span>{t('all.button.register')}</span>
                  <SvgValidation height={'1em'} />
                </div>
              </div>
            )}
          </p>
        </div>
        {nameEditableField && editableField !== nameEditableField && this.verifUpdateInfo(nameEditableField) && (
          <div style={{ display: 'flex', alignItems: 'auto', justifyContent: 'center' }}>
            <span
              id={`exportTournee${title}`}
              className={`clickable`}
              role="presentation"
              style={{ marginLeft: '10px', marginTop: '10px' }}
              onClick={() => this.changeCurrentEditField(nameEditableField)}
            >
              <EditionBleu height="1em" width="1em" />
            </span>
            {((nameEditableField === 'marquage' && marquage) || (nameEditableField === 'message' && message)) && (
              <span
                id={`exportTournee${title}`}
                className={`clickable`}
                role="presentation"
                style={{ marginLeft: '10px', marginTop: '10px' }}
                onClick={() => this.deleteSelectOption(nameEditableField)}
              >
                <SvgCroixNoir fill={'#dc3545'} height="23px" width="auto" />
              </span>
            )}
          </div>
        )}
      </div>
    );
  }

  generateEditField(field: string) {
    const { tournee } = this.props;
    const { editableFieldValue } = this.state;
    switch (field) {
      case FIELD_EDITABLE.MARQUAGE:
        return (
          <StyledSelect
            optionList={_.get(tournee, `editInfos.marquages`, []).map(({ code, label }) => ({ label, value: code }))}
            placeholder="Marquage"
            handleChange={e => this.setState({ editableFieldValue: e })}
            value={
              editableFieldValue ||
              _.get(tournee, 'editInfos.marquages', []).find(
                ({ label }) => label === _.get(tournee, 'fetchedFiche.pdi.marquage')
              ) ||
              {}
            }
          />
        );
      case FIELD_EDITABLE.MESSAGE:
        return (
          <StyledSelect
            optionList={_.get(tournee, `editInfos.messages`, []).map(({ code, label }) => ({ label, value: code }))}
            placeholder="Message"
            handleChange={e => this.setState({ editableFieldValue: e })}
            value={
              editableFieldValue ||
              _.get(tournee, 'editInfos.messages', []).find(
                ({ label }) => label === _.get(tournee, 'fetchedFiche.baseMeter.message')
              ) ||
              {}
            }
          />
        );
      case FIELD_EDITABLE.CLP_INFORMATIONS:
        return _.get(tournee, 'editInfos.roundSubscriberArea', []).map(field => {
          const { libelle, position, taille, type } = field;
          const value =
            _.get(editableFieldValue, `[${position}.value]`) ||
            _.get(tournee, 'fetchedFiche.pdi.informations')
              .slice(position - 1, position - 1 + taille)
              .trim();
          return (
            <FormGroup>
              <Label for="exampleEmail">{libelle}</Label>
              <StyledInput
                type={type === 'A' ? 'text' : 'number'}
                value={type === 'N' ? Number.parseInt(value) : value}
                handleChange={e => {
                  const { editableFieldValue } = this.state;
                  const cloneValue = _.cloneDeep(editableFieldValue) || {};
                  _.set(cloneValue, position, { ...field, value: e.target.value });
                  this.setState({ editableFieldValue: cloneValue });
                }}
              />
            </FormGroup>
          );
        });
      case FIELD_EDITABLE.MTR_INFORMATIONS:
        return _.get(tournee, 'editInfos.roundMeterArea', []).map(field => {
          const { libelle, position, taille, type } = field;
          const value =
            _.get(editableFieldValue, `[${position}.value]`) ||
            _.get(tournee, 'fetchedFiche.baseMeter.informations')
              .slice(position - 1, position - 1 + taille)
              .trim();
          return (
            <FormGroup>
              <Label for="exampleEmail">{libelle}</Label>
              <StyledInput
                type={type === 'A' ? 'text' : 'number'}
                value={type === 'N' ? Number.parseInt(value) : value}
                handleChange={e => {
                  const { editableFieldValue } = this.state;
                  const cloneValue = _.cloneDeep(editableFieldValue) || {};
                  _.set(cloneValue, position, { ...field, value: e.target.value });
                  this.setState({ editableFieldValue: cloneValue });
                }}
              />
            </FormGroup>
          );
        });
      case FIELD_EDITABLE.PARTICULAR_MSG:
        return (
          <StyledInput
            type={'text'}
            maxlength={30}
            value={editableFieldValue || _.get(tournee, 'fetchedFiche.baseMeter.particularMessage')}
            handleChange={e => this.setState({ editableFieldValue: _.get(e, 'target.value') })}
          />
        );
    }
  }

  generateDoubleLineInfo(title1: string, info1: any, title2: string, info2: any, imgName: string) {
    return (
      <div
        className="row meterInfo"
        style={{ marginLeft: '10px', maxWidth: '90%', display: 'flex', flexDirection: 'row' }}
      >
        <div className=" picto" style={{ marginTop: '10px', padding: '0 15px' }}>
          {Picto(imgName) ? (
            Picto(imgName, {
              style: { marginRight: '10px' },
              height: '2em',
              width: '2em',
              stroke: '#31c6b3',
              fill: '#31c6b3',
              strokeWidth: '0',
            })
          ) : (
            <div style={{ width: '35.5px' }} />
          )}{' '}
        </div>
        <div className="" style={{ flex: 1, padding: '0 15px' }}>
          <p>
            {info1 || info2 ? (
              <Row style={{ margin: 0, padding: 0 }}>
                <Col md={6} style={{ padding: 0 }}>
                  <span className="infoLibelle" style={{ padding: 0 }}>
                    {title1}
                  </span>
                  <br />
                  <span
                    className="infoDisplay"
                    style={{ margin: 0, overflowWrap: 'break-word', opacity: info2 && info1 ? 0.5 : 1 }}
                  >
                    {info1 ? info1 : info2}{' '}
                  </span>
                </Col>
                {info2 && info1 && (
                  <>
                    <Col md={2} style={{ padding: 0, position: 'relative', width: '15px' }}>
                      <span className="absoluteVerticalCenter">
                        <FlecheAllerLarge width="1.5em" height="1.5em" />
                      </span>
                    </Col>
                    <Col md={4} style={{ padding: 0 }}>
                      <span className="infoLibelle" style={{ padding: 0 }}>
                        {title2}
                      </span>
                      <br />
                      <span className="infoDisplay" style={{ overflowWrap: 'break-word', margin: 0 }}>
                        {info2}{' '}
                      </span>
                    </Col>
                  </>
                )}
              </Row>
            ) : (
              <NoFilled />
            )}
          </p>
        </div>
      </div>
    );
  }

  generateBlockAlarms(alarmList: Array<string>, title: string) {
    const { t } = this.props;
    const miniTitle = t('fiche_detail.title.current_alarm_plural') ? 'c' : 'p';
    return (
      <Col style={{ padding: '0', margin: '0' }}>
        <div>
          <div className="presentation-body-light" style={{ display: 'flex', flexDirection: 'column' }}>
            <p className="index-title">{title}</p>
            <Row style={{ margin: 0, marginLeft: '20px' }}>
              {alarmList && alarmList.length > 0 ? (
                alarmList.map((el: any, index: any) => (
                  <div style={{ width: '20px', marginRight: '20px' }} id={`${miniTitle}-${index}`}>
                    {PictoAlarm(el.split('.')[0], {
                      height: '2em',
                      width: '2em',
                      // eslint-disable-next-line no-nested-ternary
                      stroke: ['poc', 'past'].includes(el.split('.')[1])
                        ? '#808080'
                        : el.split('.')[1] === 'current'
                        ? '#CF7830'
                        : '#ffffff',
                      fill: ['poc', 'past'].includes(el.split('.')[1])
                        ? '#808080'
                        : el.split('.')[1] === 'current'
                        ? '#CF7830'
                        : '#ffffff',
                      strokeWidth: '0',
                    })}
                    <UncontrolledTooltip target={`${miniTitle}-${index}`} placement={'bottom'}>
                      {t(`alarm.type.${el.toLowerCase()}`)}
                    </UncontrolledTooltip>
                  </div>
                ))
              ) : (
                <span className="absoluteCentered" style={{ fontSize: '1.3em' }}>
                  {t('fiche_detail.text.no_alarm')}
                </span>
              )}
            </Row>
          </div>
        </div>
      </Col>
    );
  }

  handleFlip() {
    const { flipped } = this.state;
    const flipCardsInner = document.querySelectorAll<HTMLElement>('.flip-card-inner')!;

    Array.prototype.forEach.call(flipCardsInner, function(item) {
      item.style.transform = flipped ? 'rotateY(0deg)' : 'rotateY(180deg)';
    });

    this.setState({
      flipped: !flipped,
    });
  }

  getMeterInfo(meter: Meter) {
    const { t } = this.props;
    return (
      meter && (
        <div className="display-info">
          {this.generateLineInfo(
            t('fiche_detail.label.detail_plural'),
            meter.informations,
            'meter_info',
            FIELD_EDITABLE.MTR_INFORMATIONS
          )}
          {this.generateLineInfo(t('fiche_detail.label.diameter'), meter.diameter, 'diameter')}
          {this.generateLineInfo(t('fiche_detail.label.install_year'), meter.installDate, 'date')}
          {this.generateDoubleLineInfo(
            t('fiche_detail.label.wheel_nb'),
            meter.wheels,
            t('fiche_detail.label.revision'),
            meter.revisedWheels,
            'wheels'
          )}
          {this.generateLineInfo(
            t('fiche_detail.label.mfr'),
            meter.manufacturer === 'UNKNOWN' ? t('all.text.unknown') : meter.manufacturer,
            'manufacturer'
          )}
          {this.generateLineInfo(t('fiche_detail.label.model'), meter.model, 'modelmeter')}
          {this.generateLineInfo(t('fiche_detail.label.msg'), meter.message, 'message', FIELD_EDITABLE.MESSAGE)}
          {this.generateLineInfo(
            t('fiche_detail.label.particular_msg'),
            meter.particularMessage,
            'note',
            FIELD_EDITABLE.PARTICULAR_MSG
          )}
        </div>
      )
    );
  }

  getMeterContainer(meter: Meter, isNew: boolean, read?: Read) {
    const { tournee, t } = this.props;
    const { flipped } = this.state;
    return (
      meter && (
        <div className="ficheDetailMeter" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <div
            className="presentation-container"
            style={{ margin: '0', minHeight: '670px', flex: 1, display: 'flex', flexDirection: 'column' }}
          >
            <Row
              className={isNew ? 'presentation-header-yellow' : 'presentation-header'}
              style={{ backgroundSize: '800px', margin: 0, padding: '10px', zIndex: flipped ? 0 : 1 }}
            >
              {tournee && tournee.fetchedFiche && tournee.fetchedFiche.changeMeter ? (
                <>
                  <Col md="10" style={{ padding: 0 }}>
                    <Row style={{ margin: 0 }}>
                      <span
                        style={{ alignSelf: 'center', padding: 0, paddingTop: isNew ? '5px' : '', overflow: 'hidden' }}
                      >
                        {isNew ? (
                          <ChangeMeter width="3em" height="3em" fill="white" />
                        ) : (
                          <CompteurModifie width="2em" height="2em" fill="white" />
                        )}
                      </span>
                      <Col>
                        <span className="presentation-title" style={{ padding: 0 }}>
                          {isNew ? t('fiche_detail.title.new_meter') : t('fiche_detail.title.old_meter')}
                        </span>
                        <span className="presentation-main-title" style={{ margin: 0 }}>
                          {meter.theoricSerial}{' '}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="2" className="arrowContainer">
                    <div
                      className={isNew ? 'round clickable yellowHover' : 'round clickable'}
                      id={'change_meter' + meter.theoricSerial.replace(' ', '_')}
                      onClick={this.handleFlip}
                      role="presentation"
                      style={{
                        padding: '0',
                        paddingTop: '5px',
                      }}
                    >
                      {isNew ? (
                        <FlecheRetourLarge width="1.5em" height="1.5em" fill="#333" />
                      ) : (
                        <FlecheAllerLarge width="1.5em" height="1.5em" fill="#333" />
                      )}
                      <UncontrolledTooltip target={'change_meter' + meter.theoricSerial.replace(' ', '_')}>
                        {isNew ? t('fiche_detail.label.show_old_meter') : t('fiche_detail.label.show_new_meter')}
                      </UncontrolledTooltip>
                    </div>
                  </Col>
                </>
              ) : (
                <Col style={{ padding: 0 }}>
                  <Row style={{ margin: 0, padding: 0 }}>
                    <Col md={5} style={{ padding: 0 }}>
                      <span className="presentation-title" style={{ padding: 0 }}>
                        {t('fiche_detail.title.meter')}
                      </span>
                      <span
                        className="presentation-main-title"
                        style={{ margin: 0, opacity: meter.revisedSerial ? 0.5 : 1 }}
                      >
                        {meter.theoricSerial}{' '}
                      </span>
                    </Col>
                    {meter.revisedSerial && (
                      <>
                        <Col md={2} style={{ padding: 0, position: 'relative' }}>
                          <span className="absoluteVerticalCenter">
                            <FlecheAllerLarge width="1.5em" height="1.5em" />
                          </span>
                        </Col>
                        <Col md={5} style={{ padding: 0 }}>
                          <span className="presentation-title" style={{ padding: 0 }}>
                            {t('fiche_detail.title.revise_meter')}
                          </span>
                          <span className="presentation-main-title" style={{ margin: 0 }}>
                            {meter.revisedSerial}{' '}
                          </span>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              )}
            </Row>
            <div className="presentation-body" style={{ backfaceVisibility: 'hidden', flex: 1, overflow: 'hidden' }}>
              {this.getMeterInfo(meter)}
            </div>
          </div>
          <div className="poseIndex">
            {_.get(tournee, 'fetchedFiche.changeMeter') && (
              <div>
                <Triangle orientation="down" borderColor="#ebecf1" style={{ zIndex: 2, position: 'relative' }} />
                <div style={{ position: 'relative', marginTop: '-10px' }}>
                  <IndexWrappedLight
                    backgroundColor={isNew ? 'yellow' : 'green'}
                    indexType={isNew ? t('fiche_detail.title.install_read') : t('fiche_detail.title.uninstall_read')}
                    wheels={meter.wheels}
                    date={read && moment.utc(read.date).format('DD/MM/YYYY')}
                    indexNumber={read && read.meterIndex}
                  />
                  {((!flipped && isNew) || (flipped && !isNew)) && (
                    <Triangle
                      orientation="down"
                      backgroundColor="#ebecf1"
                      style={{ position: 'absolute', left: '50%', top: 0, transform: 'translate(-50%)', zIndex: 1 }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )
    );
  }

  getClassSize(length: number) {
    let classSize = 'nbXs';

    switch (true) {
      case length <= 3:
        classSize = 'nbXl';
        break;
      case length <= 6:
        classSize = 'nbL';
        break;
      case length <= 9:
        classSize = 'nbM';
        break;
      case length <= 12:
        classSize = 'nbS';
        break;
    }

    return classSize;
  }

  getConsBlock(read: Read) {
    const { t } = this.props;
    const cons = read.consumption;
    const minCons = read.minConsumption;
    const maxCons = read.maxConsumption;
    const avg = read.average;
    const consMaxedOut = (avg > maxCons && maxCons !== null) || (minCons === 0 && maxCons === 0);
    const consMinedOut = avg < minCons && minCons !== null;
    const isConsOutOfRange = (consMaxedOut || consMinedOut) && (maxCons !== null || minCons != null);
    const classSizeCons = this.getClassSize(cons.toString().length);
    const classSizeAvg = this.getClassSize(avg.toString().length);

    let inferiorMargin;
    let superiorMargin;

    function numberWithSpaces(x: number) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    return (
      <>
        <Row
          className="consumption"
          style={
            isConsOutOfRange
              ? consMaxedOut
                ? { position: 'relative', backgroundColor: '#DC3545FF' }
                : { position: 'relative', backgroundColor: '#3584b7' }
              : { position: 'relative' }
          }
        >
          <Triangle
            orientation="down"
            style={{ position: 'absolute', left: '50%', top: 0, transform: 'translate(-50%)', zIndex: 49 }}
            backgroundColor="#ebecf1"
            borderColor={isConsOutOfRange ? (consMaxedOut ? '#DC3545FF' : '#3584b7') : '#2c303b'}
          />
          <Col md={3} className="conso-section" style={{ textAlign: 'center', padding: 0 }}>
            <h3
              dangerouslySetInnerHTML={{
                __html: t('all.read_meter.cons_m3', { interpolation: { escapeValue: false } }),
              }}
            />
            <div style={{ padding: 0 }}>
              <div className={classSizeCons + ' avg'}>{numberWithSpaces(cons)}</div>
            </div>
          </Col>
          <Col
            md={6}
            className="conso-section"
            style={{
              textAlign: 'center',
              padding: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <h3>
              {t('fiche_detail.title.avg')}{' '}
              {isConsOutOfRange && (
                <Tooltip
                  TransitionComponent={Grow}
                  placement="top"
                  arrow
                  title={
                    maxCons === 0 && minCons === 0
                      ? t('fiche_detail.conso.null_range_tooltip')
                      : t('fiche_detail.conso.range_tooltip')
                  }
                >
                  <span>
                    <AttentionRounded width={'20px'} fill="white" />
                  </span>
                </Tooltip>
              )}
            </h3>
            <span className={classSizeAvg + ' avg'}> {avg.toFixed(3)} </span>
            <div className={'limitContainer'}>
              {minCons && minCons !== 0 ? (
                <span className={`consoRange ${consMinedOut && 'consoOver'}`}>min : {minCons.toFixed(3)}</span>
              ) : (
                <></>
              )}
              {maxCons && maxCons !== 0 ? (
                <span className={`consoRange ${consMaxedOut && 'consoOver'}`}>max : {maxCons.toFixed(3)}</span>
              ) : (
                <></>
              )}
            </div>
          </Col>
          <Col md={3} className="conso-section" style={{ textAlign: 'center', padding: 0 }}>
            <h3>{t('all.read_meter.tendency')}</h3>
            <span style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
              {this.getArrowCons(read.tendency)}
            </span>
          </Col>
        </Row>
        <Triangle
          orientation="down"
          style={{ zIndex: 50, position: 'inherit' }}
          backgroundColor={isConsOutOfRange ? (consMaxedOut ? '#DC3545FF' : '#3584b7') : '#2c303b'}
          borderColor={'#ebecf1'}
        />
      </>
    );
  }

  getArrowCons(tendency: number) {
    switch (true) {
      case tendency > 50:
        return <StrongCons width="70%" height="70%" fill="#fff" />;
      case tendency > 25:
        return <SmallStrongCons width="70%" height="70%" fill="#fff" />;
      case tendency < -25:
        return <SmallWeakCons width="70%" height="70%" fill="#fff" />;
      case tendency < -50:
        return <WeakCons width="70%" height="70%" fill="#fff" />;
      default:
        return <NormalConso width="70%" height="70%" fill="#fff" />;
    }
  }

  radioContainer(radio: Radio, isNew: boolean) {
    const { tournee, t } = this.props;
    const flipRadio =
      _.get(tournee, 'fetchedFiche.changeMeter') ||
      _.get(tournee, 'fetchedFiche.changeRadio') ||
      _.get(tournee, 'fetchedFiche.radio.removeDate');

    return (
      <div className="presentation-container" style={{ margin: 0, height: '100%' }}>
        <Row
          className={flipRadio ? (isNew ? 'presentation-header-yellow' : 'presentation-header') : 'presentation-header'}
          style={{ margin: 0, padding: '10px', backgroundSize: '800px' }}
        >
          <Col md="10" style={{ padding: 0 }}>
            <Row style={{ margin: 0 }}>
              <Col>
                <span className="presentation-title" style={{ padding: 0 }}>
                  {flipRadio
                    ? isNew
                      ? t('fiche_detail.label.new_radio')
                      : t('fiche_detail.label.old_radio')
                    : t('fiche_detail.label.radio')}
                </span>
                <span className="presentation-main-title" style={{ margin: 0 }}>
                  {radio ? radio.serial : '-'}
                </span>
              </Col>
            </Row>
          </Col>
          <Col md="2" className="arrowContainer">
            {flipRadio && (
              <div
                className={isNew ? 'round clickable yellowHover' : 'round clickable'}
                id={'change_radio' + (radio ? radio.serial : 'NoRadio')}
                onClick={this.handleFlip}
                role="presentation"
                style={{
                  padding: '0',
                  paddingTop: '5px',
                }}
              >
                {isNew ? (
                  <FlecheRetourLarge width="1.5em" height="1.5em" fill="#333" />
                ) : (
                  <FlecheAllerLarge width="1.5em" height="1.5em" fill="#333" />
                )}
                <UncontrolledTooltip target={'change_radio' + (radio ? radio.serial : 'NoRadio')}>
                  {isNew ? t('fiche_detail.label.show_old_radio') : t('fiche_detail.label.show_new_radio')}
                </UncontrolledTooltip>
              </div>
            )}
          </Col>
        </Row>
        <div className="presentation-body" style={{ backfaceVisibility: 'hidden', flex: 1 }}>
          {radio ? (
            <div className="display-info">
              {this.generateLineInfo(
                t('fiche_detail.label.mfr'),
                radio.manufacturer === 'UNKNOWN' ? t('all.text.unknown') : radio.manufacturer,
                'manufacturer'
              )}
              {this.generateLineInfo(t('fiche_detail.label.model'), radio.model ? radio.model : '-', 'modelradio')}
              {this.generateLineInfo(
                t('fiche_detail.label.internal_id'),
                radio.composeInternalSerial,
                'internalserialradio'
              )}
              {this.generateLineInfo(
                t('fiche_detail.label.install_date'),
                moment.utc(radio.installDate).format('DD/MM/YYYY'),
                'date'
              )}
              {this.generateLineInfo(t('fiche_detail.label.pulse_weight'), radio.pulseWeight, 'pulse')}
            </div>
          ) : (
            <NoPresent
              msg={t('fiche_detail.error_msg.no_radio')}
              style={{ textAlign: 'center', fontSize: '2em', width: '100%', paddingTop: '50px' }}
            />
          )}
        </div>
      </div>
    );
  }

  alarmRadioContainer(radio: Radio) {
    const { tournee, t } = this.props;
    const { dataAlarms, labelAlarms } = this.state;
    const readHistory = _.get(tournee, 'fetchedFiche.readHistory');
    return (
      radio &&
      radio.serial && (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div className="container" style={{ padding: '0', flex: '1', marginBottom: '15px' }}>
            <div className="table-info-container" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <h2>
                <span>
                  <HistoriqueVert height="1em" width="1em" fill="#31c6b3" />
                </span>
                <span
                  style={{ paddingLeft: '25px' }}
                  dangerouslySetInnerHTML={{
                    __html: t('all.read_meter.cons_history_m3_radio', { interpolation: { escapeValue: false } }),
                  }}
                />
              </h2>
              <div className="chart-container-light">
                {readHistory && (
                  <BarChart
                    labels={_.get(tournee, 'fetchedFiche.readHistory', []).map((el: ReadHistory) => el.date)}
                    data={_.get(tournee, 'fetchedFiche.readHistory', []).map(
                      (el: ReadHistory) => el.meterConsumption || 0
                    )}
                    t={t}
                  />
                )}
              </div>
            </div>
          </div>
          <Row style={{ margin: '0' }}>
            {this.generateBlockAlarms(
              _.get(tournee, 'fetchedFiche.previousAlarms', []),
              t('fiche_detail.title.previous_alarm_plural')
            )}
            <span style={{ width: '15px' }} />
            {this.generateBlockAlarms(
              _.get(tournee, 'fetchedFiche.actualAlarms', []),
              t('fiche_detail.title.current_alarm_plural')
            )}
          </Row>
          <div className="container" style={{ padding: '0', marginTop: '15px', flex: 1 }}>
            <div className="table-info-container" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <h2>
                <span>
                  <HistoriqueVert height="1em" width="1em" fill="#31c6b3" />
                </span>
                {t('fiche_detail.title.historical_alarm_plural')}
              </h2>{' '}
              <HistoAlarmChart labels={labelAlarms} datas={dataAlarms} t={t} />
            </div>
          </div>
        </div>
      )
    );
  }

  render() {
    const { tournee, t } = this.props;
    const { editableField, editableFieldValue } = this.state;
    const fetchedFiche = tournee && tournee.fetchedFiche;
    const changeMeter = fetchedFiche && tournee.fetchedFiche.changeMeter;
    const baseMeter = fetchedFiche && tournee.fetchedFiche.baseMeter;
    const changeRadio = fetchedFiche && tournee.fetchedFiche.changeRadio;
    const radio = fetchedFiche && tournee.fetchedFiche.radio;
    const read = fetchedFiche && tournee.fetchedFiche.read;
    const previousRead = fetchedFiche && tournee.fetchedFiche.previousRead;
    const readsFacturation = fetchedFiche && tournee.fetchedFiche.readsFacturation;
    const readHistory = fetchedFiche && tournee.fetchedFiche.readHistory;
    const pdi = fetchedFiche && tournee.fetchedFiche.pdi;
    const enquete = fetchedFiche && tournee.fetchedFiche.enquiryResult;
    return (
      <div className="col-md-12">
        {tournee && tournee.info && fetchedFiche && (
          <div>
            <Row>
              <Col lg="4">
                <div className="presentation-container" style={{ minHeight: '100%', marginBottom: '0' }}>
                  <div className="presentation-header" style={{ backgroundSize: '800px' }}>
                    <span className="presentation-title">{t('fiche_detail.title.record_number')}</span>
                    <span className="presentation-main-title">{pdi && pdi.cardOrder} </span>
                  </div>
                  <div className="presentation-body">
                    <div className="display-info">
                      {this.generateLineInfo(
                        t('fiche_detail.label.detail_plural'),
                        pdi && pdi.informations && ListTools.formatInformationsClpMtr(pdi.informations),
                        'pdi_info',
                        FIELD_EDITABLE.CLP_INFORMATIONS
                      )}
                      {this.generateLineInfo(
                        t('fiche_detail.label.marking'),
                        pdi && pdi.marquage,
                        'marquage',
                        FIELD_EDITABLE.MARQUAGE
                      )}
                      {this.generateLineInfo(
                        t('fiche_detail.label.last_update'),
                        moment.utc(pdi && pdi.lastUpdate).format('DD/MM/YYYY'),
                        'date'
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="8">
                <div>
                  {(baseMeter && baseMeter.latitude) || (changeMeter && changeMeter.latitude) ? (
                    <Mapbox
                      initialMap={{
                        baseLng: (changeMeter && changeMeter.longitude) || baseMeter.longitude,
                        baseLat: (changeMeter && changeMeter.latitude) || baseMeter.latitude,
                        baseZoom: 17,
                      }}
                      style={{ width: '100%', height: '40vh', borderRadius: '10px' }}
                      uniqIcon={fetchedFiche && fetchedFiche.ficheState}
                      isRoundDetail
                    />
                  ) : (
                    <h2 style={{ margin: 'auto' }}>{t('fiche_detail.title.no_gps')}</h2>
                  )}
                </div>
              </Col>
            </Row>

            <Row className="consMeter" style={{ marginTop: '15px' }}>
              <Col lg="4" style={{}}>
                <div className="flip-card" style={{ paddingLeft: '10px', position: 'relative', zIndex: 3 }}>
                  <div
                    className={changeMeter ? 'flip-card-inner' : ''}
                    style={{
                      minHeight: changeMeter ? '771px' : '695px',
                      position: changeMeter ? 'initial' : 'relative',
                    }}
                  >
                    <div className="flip-card-front">
                      {this.getMeterContainer(
                        changeMeter ? changeMeter : baseMeter,
                        !!changeMeter,
                        changeMeter ? changeMeter.readPose : undefined
                      )}
                    </div>
                    <div className="flip-card-back">
                      {changeMeter && this.getMeterContainer(baseMeter, false, read)}
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="8" style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="container" style={{ padding: '0', flex: '1' }}>
                  <div
                    className="table-info-container"
                    style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                  >
                    <h2>
                      <span>
                        <HistoriqueVert height="1em" width="1em" fill="#31c6b3" />
                      </span>
                      <span
                        style={{ paddingLeft: '25px' }}
                        dangerouslySetInnerHTML={{
                          __html: t('all.read_meter.cons_history_m3_facturation', {
                            interpolation: { escapeValue: false },
                          }),
                        }}
                      />
                    </h2>
                    <div className="chart-container-light">
                      {readsFacturation && (
                        <BarChart
                          labels={_.get(tournee, 'fetchedFiche.readsFacturation', []).map((el: ReadHistory) => el.date)}
                          data={_.get(tournee, 'fetchedFiche.readsFacturation', []).map(
                            (el: ReadHistory) => el.meterConsumption || 0
                          )}
                          t={t}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <Row style={{ margin: '0', position: 'relative' }}>
                  <Col style={{ padding: 0 }}>
                    <div style={{ height: '15px', width: '15px' }} />
                    <div style={{ position: 'relative' }}>
                      <IndexWrapped
                        indexType={t('fiche_detail.title.previous_index')}
                        readMethod={previousRead && previousRead.methodLetter}
                        wheels={baseMeter && baseMeter.wheels ? baseMeter.wheels : 5}
                        date={
                          previousRead && previousRead.date ? moment.utc(previousRead.date).format('DD/MM/YYYY') : ''
                        }
                        indexNumber={previousRead && previousRead.meterIndex}
                        t={t}
                      />
                      {read && _.isNumber(read.consumption) && (
                        <Triangle orientation="down" style={{ marginTop: '-1px', zIndex: 50, position: 'inherit' }} />
                      )}
                    </div>
                    {read && _.isNumber(read.consumption) && this.getConsBlock(tournee.fetchedFiche.read)}
                    <div
                      style={{
                        position: 'relative',
                        marginTop: read && _.isNumber(read.consumption) ? '-10px' : '20px',
                      }}
                    >
                      {read && _.isNumber(read.consumption) && (
                        <Triangle
                          orientation="down"
                          style={{
                            position: 'absolute',
                            left: '50%',
                            top: 0,
                            transform: 'translate(-50%)',
                            zIndex: 49,
                          }}
                          backgroundColor="#ebecf1"
                        />
                      )}
                      <IndexWrapped
                        indexType={t('fiche_detail.title.current_index')}
                        readMethod={read && read.methodLetter}
                        wheels={baseMeter && baseMeter.wheels ? baseMeter.wheels : 5}
                        date={read && read.date ? moment.utc(read.date).format('DD/MM/YYYY') : ''}
                        indexNumber={read && read.meterIndex}
                        minDate={
                          previousRead && previousRead.date ? moment.utc(previousRead.date).format('DD/MM/YYYY') : ''
                        }
                        t={t}
                        changeInputIndex={(result: any) => {
                          this.setState({ editableFieldValue: result });
                        }}
                        updateIndex={() => this.changeFieldValue()}
                        changeCurrentEditField={this.changeCurrentEditField}
                        isIndexBelow={this.isIndexBelow}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginTop: '15px', marginBottom: '15px' }}>
              <Col lg="4">
                <div className="flip-card" style={{ paddingLeft: '10px', position: 'relative', zIndex: 3 }}>
                  <div
                    className="flip-card-inner"
                    style={{ minHeight: (!changeMeter || !changeMeter.radio) && !radio ? '205px' : '405px' }}
                  >
                    <div className="flip-card-front">
                      {this.radioContainer(
                        changeMeter
                          ? changeMeter.radio
                          : changeRadio
                          ? changeRadio
                          : radio && radio.removeDate
                          ? null
                          : radio,
                        changeMeter || changeRadio || (radio && radio.removeDate)
                      )}
                    </div>
                    <div className="flip-card-back">
                      {(changeMeter || changeRadio || (radio && radio.removeDate)) && this.radioContainer(radio, false)}
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="8">{this.alarmRadioContainer(changeMeter ? changeMeter.radio : radio)}</Col>
            </Row>
            {enquete && !_.isEmpty(enquete) && (
              <div className="table-info-container">
                <h2>
                  <EnqueteSvg height="1em" width="1em" fill="#31c6b3" className="svg-table-title" />{' '}
                  {t('sidebar_synchro.nav_link.enquiry_plural')}
                  <span className="addItem float-right" />
                </h2>
                <div>
                  <BootstrapTable
                    keyField="enqNumPage"
                    data={enquete}
                    columns={[
                      {
                        dataField: 'enquiryPageName',
                        text: t('enquete.text.name_enquete_page'),
                        headerStyle: () => {
                          return { width: '30%' };
                        },
                        sort: true,
                      },
                      {
                        dataField: 'results',
                        text: t('enquete.text.field_list'),
                        formatter: field => (
                          <div className="fields-enquete">
                            {' '}
                            {field.map(el => (
                              <p>
                                <span style={{ float: 'left', width: '20ch', fontWeight: 'bold' }}>{el.libelle}</span>{' '}
                                {el.result}
                              </p>
                            ))}
                          </div>
                        ),
                      },
                    ]}
                    bootstrap4
                    hover
                    bordered={false}
                    filter={filterFactory()}
                  />
                </div>
              </div>
            )}
            <div style={{ paddingLeft: '10px', paddingTop: '15px' }}>
              <GaleryPhoto />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { tournee, locations, locales, colors } = state;

  return {
    tournee,
    locations,
    locales,
    colors,
  };
}

const FicheDetailMapped = connect(mapStateToProps)(FicheDetail);
const tr = withTranslation()(FicheDetailMapped);
export default tr;
