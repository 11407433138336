import React, { Component } from 'react';
import GestionTable from '../_components/TableauGestion';
import SvgMarquagesVert from '../SvgComponents/MarquageVert';
import synchroAction from '../_actions/synchro.actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import TableRow from '../_components/TableRow';
import LoadingBand from '../Bands/Loading';
import { withTranslation } from 'react-i18next';

export class ListMarquages extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props;
    const { params } = match;
    dispatch(synchroAction.getAllMarquages(params.GestionnaireId));
  }

  render() {
    const { synchro, imports, t } = this.props;

    const svg = <SvgMarquagesVert height="1em" width="1em" fill="#31c6b3" className="svg-table-title" />;

    const columns = [
      {
        dataField: 'code',
        text: t('all.column_text.code'),
      },
      {
        dataField: 'label',
        text: t('all.column_text.label'),
      },
    ];

    return (
      <div style={{ width: '100%' }}>
        {synchro && synchro.loading && <LoadingBand message={t('list_marquages.loading_band.loading_marking')} />}
        {synchro && synchro.marquages && (
          <div className="container-tab-synchro">
            <GestionTable
              columns={columns}
              data={imports && imports.marquages ? imports.marquages : synchro.marquages}
              svgTitle={svg}
              titleTab={t('all.text.marking')}
              withAdd
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { synchro, gestionnaire, imports } = state;
  return {
    alert,
    synchro,
    gestionnaire,
    imports,
  };
}

const mapping: any = connect(mapStateToProps)(ListMarquages);

const connectedUser = withRouter(mapping);
const tr = withTranslation()(connectedUser);
export default tr;
