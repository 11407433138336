import React from 'react';

const SvgInfoMeter = (props: any) => (
  <svg viewBox="0 0 134.35 121.77" {...props}>
    <g>
      <path
        fill={props.fill}
        d="M95.27,68.68c0.27-1.34,0.41-2.57,0.41-3.65c0-0.41-0.02-0.73-0.04-0.98c-0.41-0.06-1.01-0.11-1.84-0.11
		c-0.91,0-1.9,0.16-2.95,0.46c-1.36,0.4-2.61,0.81-3.71,1.22l-10.6,3.92l1.78-7.28H25.08c-1.84,0-3.34,1.49-3.34,3.34l4.39,21.28
		H9.92l-0.09-0.93c-0.07-0.72-0.71-1.24-1.43-1.17c-0.68,0.07-1.18,0.64-1.17,1.31l-0.01-0.13c-0.07-0.72-0.72-1.24-1.43-1.17
		c-0.68,0.07-1.17,0.64-1.17,1.31l-0.01-0.13c-0.07-0.72-0.71-1.24-1.43-1.17c-0.72,0.07-1.24,0.71-1.17,1.43l0.07,0.68H1.36
		C0.61,86.87,0,87.48,0,88.23v13.39c0,0.75,0.61,1.36,1.36,1.36h2.29l0.09,0.96c0.07,0.67,0.63,1.18,1.3,1.18
		c0.04,0,0.09,0,0.13-0.01c0.68-0.07,1.18-0.64,1.17-1.31l0.01,0.13c0.07,0.67,0.63,1.18,1.3,1.18c0.04,0,0.09,0,0.13-0.01
		c0.68-0.07,1.17-0.64,1.17-1.31l0.01,0.13c0.07,0.67,0.63,1.18,1.3,1.18c0.04,0,0.09,0,0.13-0.01c0.72-0.07,1.24-0.71,1.17-1.43
		l-0.07-0.7h17.95l1,4.85c0,1.84,1.49,3.34,3.34,3.34h53.1c-0.25-1.31-0.4-2.66-0.4-4.06c0-1.94,0.14-3.92,0.41-5.89
		c0.27-1.93,0.67-4.03,1.25-6.42l0.03-0.12l0.03-0.12l5.83-20.63C94.54,72.01,94.94,70.3,95.27,68.68z"
      />
      <path
        fill={props.fill}
        d="M17.1,58.47h47.76h14.39l1.54-6.28l0.73-2.97l2.84-1.15c3.98-1.62,7.83-3.02,11.44-4.16
		c4.07-1.29,8.03-1.95,11.75-1.95c4.1,0,7.72,0.65,10.86,1.89c-0.15-0.6-0.31-1.2-0.47-1.79c-4.31-0.49-8.13-2.23-11.38-5.22
		c-3.95-3.64-6.05-8.34-6.05-13.59c0-4.69,1.69-8.95,4.88-12.43c-1.41-2.63-2.67-4.79-3.64-6.34C100.5,2.46,98.54,0,96.46,0H33.55
		c-2.08,0-4.04,2.11-5.29,4.09c-4.18,6.64-13.57,24.26-17.6,43.34C9.61,52.43,12.79,58.47,17.1,58.47z"
      />
      <path
        fill={props.fill}
        d="M124.17,111.78c-3.01,0-5.14-0.49-6.37-1.49c-1.22-0.99-1.83-2.86-1.83-5.6c0-0.47,0.04-1.05,0.11-1.71
		c0.09-0.87,0.24-1.9,0.46-3.1c0.37-2.13,0.8-4.02,1.28-5.68l2.07-7.33l0.09-0.32l-0.04-0.36c-0.04-0.43,0.15-0.82,0.44-1.08
		l3.25-11.51c0.57-1.89,0.96-3.97,1.17-6.24c0.22-2.26,0.32-3.85,0.32-4.75c0-4.35-1.52-7.87-4.57-10.59
		c-0.35-0.31-0.71-0.59-1.09-0.87c-2.96-2.14-6.93-3.21-11.92-3.21c-3.13,0-6.44,0.56-9.94,1.67c-3.5,1.11-7.16,2.44-10.99,4
		l-1.19,4.85l-0.37,1.53c1.14-0.42,2.5-0.88,4.09-1.35c0.23-0.07,0.46-0.12,0.7-0.18c1.34-0.34,2.66-0.53,3.95-0.53
		c1.78,0,3.22,0.18,4.33,0.53c0.81,0.25,1.46,0.6,1.92,1.03c0.66,0.63,1.11,1.55,1.37,2.76c0.17,0.8,0.26,1.72,0.26,2.77
		c0,1.46-0.17,3.08-0.53,4.85c-0.35,1.78-0.8,3.65-1.32,5.63L93.98,96.2c-0.52,2.17-0.9,4.12-1.14,5.85
		c-0.24,1.73-0.35,3.42-0.35,5.06c0,1.44,0.2,2.79,0.56,4.06c0.7,2.48,2.08,4.64,4.16,6.47c3.14,2.75,7.54,4.14,13.21,4.14
		c3.69,0,6.92-0.48,9.71-1.45c2.78-0.97,6.52-2.37,11.19-4.22l1.56-6.37c-0.81,0.38-2.11,0.81-3.89,1.31
		C127.19,111.53,125.58,111.78,124.17,111.78z"
      />
      <path
        fill={props.fill}
        d="M116,35.53c1.4,0.43,2.89,0.67,4.48,0.67c3.82,0,7.09-1.27,9.8-3.8c2.72-2.52,4.07-5.59,4.07-9.18
		c0-3.58-1.35-6.65-4.07-9.21c-2.71-2.55-5.98-3.82-9.8-3.82c-3.83,0-7.12,1.27-9.85,3.82c-0.9,0.84-1.63,1.73-2.24,2.68
		c-1.24,1.94-1.87,4.12-1.87,6.53c0,3.59,1.37,6.66,4.11,9.18C112.23,33.88,114.02,34.92,116,35.53z"
      />
    </g>
  </svg>
);

export default SvgInfoMeter;
