import React from 'react';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { values } from 'lodash';
import EditionBleu from '../SvgComponents/EditionBleu';

interface selectInputProps {
  handleChange: Function;
  placeholder: string;
  text: string;
  isInvalid: boolean | null;
  isValid: boolean;
  hasChanged: boolean;
  items: Array<string>;
  value: string;
}
const SelectInput = (props: selectInputProps) => {
  const { handleChange, placeholder, text, value, items, isInvalid, isValid, hasChanged } = props;
  return (
    <div className="styled-input-select">
      <div className="form__group field">
        <InputLabel htmlFor="name" className={`form__label ${isInvalid ? 'error' : ''} ${isValid ? 'valid' : ''}`}>
          {text}
          {hasChanged && <EditionBleu height="16px" fill={isInvalid ? '#ff2281' : '#38ef7d'} />}
        </InputLabel>
        <Select
          className={`form__field ${isInvalid ? 'error' : ''} ${isValid ? 'valid' : ''}`}
          placeholder={placeholder}
          required
          value={value}
          onChange={(e: any) => handleChange(e)}
        >
          {items.map((it: string) => (
            <MenuItem value={it}>{it}</MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default SelectInput;
