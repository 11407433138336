import React from 'react';

const SvgManuel = (props: any) => (
  <svg viewBox="0 0 67.8 85.7" {...props}>
    <g id="Calque_2" />
    <g id="Calque_1">
      <g>
        <g>
          <path
            fill={props.fill}
            d="M60.7,25c-1.5,0-2.8,0.4-4,1.2c-1-2.8-3.6-4.8-6.7-4.8c-1.5,0-2.8,0.4-4,1.2c-1-2.8-3.6-4.8-6.7-4.8
				c-1.3,0-2.5,0.3-3.6,1V7.1c0-3.9-3.2-7.1-7.1-7.1s-7.1,3.2-7.1,7.1v41.7L11.6,44c-1.5-0.7-3.1-1.1-4.8-1.1
				c-3.8,0-6.8,3.1-6.8,6.8c0,1.8,0.7,3.5,2,4.8l23.1,23.1c5.2,5.2,12.2,8.1,19.5,8.1c12.8,0,23.2-10.4,23.2-23.2V32.1
				C67.8,28.2,64.6,25,60.7,25z M64.2,62.5c0,10.8-8.8,19.6-19.6,19.6c-6.4,0-12.5-2.5-17-7L4.5,52c-0.6-0.6-1-1.4-1-2.3
				c0-1.8,1.5-3.2,3.2-3.2c1.1,0,2.2,0.3,3.2,0.8l12.4,6.2c0.6,0.3,1.2,0.2,1.7-0.1c0.5-0.3,0.8-0.9,0.8-1.5V7.1
				c0-2,1.6-3.6,3.6-3.6s3.6,1.6,3.6,3.6V41c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8V25c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6V41
				c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8V28.6c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6V41c0,1,0.8,1.8,1.8,1.8
				c1,0,1.8-0.8,1.8-1.8v-8.9c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6V62.5z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgManuel;
