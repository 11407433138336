import React from 'react';

const SvgTemperature = (props: any) => (
  <svg viewBox="0 0 56.5 95.8" {...props}>
    <g id="Calque_2" />
    <g id="Calque_1">
      <g>
        <path
          fill={props.fill}
          d="M25.4,62.1v-37c0-1.6-1.3-2.8-2.8-2.8c-1.6,0-2.8,1.3-2.8,2.8v37c-4.9,1.3-8.6,5.7-8.6,11.1
			c0,6.3,5.1,11.4,11.4,11.4S34,79.5,34,73.2C34,67.9,30.4,63.4,25.4,62.1z M22.6,79c-3.2,0-5.8-2.6-5.8-5.8c0-3.2,2.6-5.8,5.8-5.8
			c3.2,0,5.8,2.6,5.8,5.8C28.4,76.4,25.8,79,22.6,79z"
        />
        <path
          fill={props.fill}
          d="M36.6,55.4V13.9C36.6,6.3,30.3,0,22.6,0C15,0,8.7,6.3,8.7,13.9v41.4C3.2,59.6,0,66.2,0,73.2
			c0,12.5,10.2,22.6,22.6,22.6c12.5,0,22.6-10.2,22.6-22.6C45.3,66.2,42,59.6,36.6,55.4z M22.6,90.2c-9.4,0-17-7.6-17-17
			c0-5.6,2.8-10.9,7.5-14.1c0.8-0.5,1.2-1.4,1.2-2.3V13.9c0-4.6,3.7-8.3,8.3-8.3c4.6,0,8.3,3.7,8.3,8.3v42.8c0,0.9,0.5,1.8,1.2,2.3
			c4.7,3.2,7.5,8.4,7.5,14.1C39.7,82.6,32,90.2,22.6,90.2z"
        />
        <path
          fill={props.fill}
          d="M53.7,44.8H45c-1.6,0-2.8,1.3-2.8,2.8s1.3,2.8,2.8,2.8h8.7c1.6,0,2.8-1.3,2.8-2.8S55.3,44.8,53.7,44.8z"
        />
        <path
          fill={props.fill}
          d="M53.7,33.6H45c-1.6,0-2.8,1.3-2.8,2.8c0,1.6,1.3,2.8,2.8,2.8h8.7c1.6,0,2.8-1.3,2.8-2.8
			C56.5,34.9,55.3,33.6,53.7,33.6z"
        />
        <path
          fill={props.fill}
          d="M53.7,22.4H45c-1.6,0-2.8,1.3-2.8,2.8c0,1.6,1.3,2.8,2.8,2.8h8.7c1.6,0,2.8-1.3,2.8-2.8
			C56.5,23.6,55.3,22.4,53.7,22.4z"
        />
        <path
          fill={props.fill}
          d="M45,16.7h8.7c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8H45c-1.6,0-2.8,1.3-2.8,2.8
			C42.2,15.5,43.4,16.7,45,16.7z"
        />
      </g>
    </g>
  </svg>
);

export default SvgTemperature;
