import React, { Component, Fragment } from 'react';
import { Label, Col, Row } from 'reactstrap';
import { AvField, AvGroup } from 'availity-reactstrap-validation';
import cloneDeep from 'lodash/cloneDeep';
import translate from '../_helpers/locale-helpers';
import locale from '../_shared/Locale.json';

/**
 * Composant gérant l'intervalle de date (dans les widgets notamment)
 *
 * @class DateInterval
 * @extends Component
 */
export default class HourPeriod extends Component<any, {}> {
  constructor(props: any) {
    super(props);
    const { values, title } = this.props;

    this.state = {
      values,
      title,
      conditions: props.conditions,
      selectedInterval: null,
      intervalValues: [],
      minValue: 0,
      maxValue: 0,
      zoom: [],
    };
    this.onChange = this.onChange.bind(this);
    this.setIntervalValues = this.setIntervalValues.bind(this);
    this.setZoom = this.setZoom.bind(this);
  }

  /**
   * @method componentDidMount
   */
  componentDidMount() {
    const { values, title }: any = this.state;
    const { conditions, period } = this.props;
    const valuesMounted = cloneDeep(values);
    if (valuesMounted[`${title}Name`]) {
      const interval = conditions.filter((c: any) => c.name === valuesMounted[`${title}Name`])[0];
      this.setState({ selectedInterval: cloneDeep(interval) });
      this.setIntervalValues(interval);
    } else {
      this.setIntervalValues(conditions[0]);
    }
  }

  /**
   * @method componentDidUpdate
   * @param {any} prevProps Props précédentes
   * @param {any} prevState State précédent
   */
  componentDidUpdate(prevProps: any, prevState: any) {}

  /**
   * @method onChange
   * @param {any} e Evènement
   */
  onChange(e: any) {
    const { minValue, maxValue }: any = this.state;
    let minVal = minValue;
    let maxVal = maxValue;
    if (e.target.name.includes('min')) {
      minVal = e.target.value;
    } else if (e.target.name.includes('max')) {
      maxVal = e.target.value;
    }
    this.setState({
      minValue: minVal,
      maxValue: maxVal,
      values: { min: minVal, max: maxVal },
    });
  }

  /**
   * Met à jour l'intervalle sélectionné
   *
   * @method setIntervalValues
   * @param {any} selectedInterval Intervalle sélectionné
   */
  setIntervalValues(selectedInterval: any) {
    const splited = selectedInterval.value.split('-');
    const intervalValues = [];
    const begin: number = parseInt(splited[0], 10);
    const end: number = parseInt(splited[1], 10);
    // fait planter chrome si i+=1
    for (let i: number = begin; i <= end; i++) {
      intervalValues.push(i);
    }
    this.setState({
      intervalValues,
      minValue: intervalValues[0],
      maxValue: intervalValues[intervalValues.length - 1],
    });
  }

  /**
   * Sélectionne un zoom
   *
   * @method setZoom
   * @param {any} selectedInterval Intervalle sélectionné
   */
  setZoom(selectedInterval: any) {
    this.setState({ zoom: selectedInterval.zoom });
  }

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { conditions, intervalValues, minValue, maxValue }: any = this.state;
    let minVal: number = parseInt(minValue, 10);
    let maxVal: number = parseInt(maxValue, 10);
    const { title } = this.props;
    return (
      <AvGroup>
        <Label for={`${title}Name`}>{translate('fr', 'alarmForm', 'hourinterval', locale)}</Label>
        <Row>
          <Col md="3">
            <AvField
              type="select"
              name={`${title}Value_min`}
              id={`${title}Value_min`}
              value={minVal}
              onChange={this.onChange}
            >
              {intervalValues.slice(0, intervalValues.findIndex((el: number) => el === maxVal)).map((item: any) => (
                <option key={item} value={item}>
                  {' '}
                  {item}
                </option>
              ))}
            </AvField>
          </Col>
          <Col md="3">
            <AvField
              type="select"
              name={`${title}Value_max`}
              id={`${title}Value_max`}
              value={maxVal}
              onChange={this.onChange}
            >
              {intervalValues.slice(intervalValues.findIndex((el: number) => el === minVal) + 1).map((item: any) => (
                <option key={item} value={item}>
                  {' '}
                  {item}
                </option>
              ))}
            </AvField>
          </Col>
        </Row>
      </AvGroup>
    );
  }
}
