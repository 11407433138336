import React, { useState } from 'react';
import Select from 'react-select';
import './style.scss';
import { withTranslation } from 'react-i18next';

const StyledSelect = (props: any) => {
  const { optionList, handleChange, value, placeholder } = props;
  const [filter, setFilter]: any = useState(value);

  const filterChange = (newFilter: any) => {
    setFilter(newFilter);
    handleChange(newFilter);
  };

  return (
    <div className="styled-select">
      <Select
        options={optionList}
        placeholder={placeholder}
        onChange={filterChange}
        value={filter}
        isSearchable
        className="select"
      />
    </div>
  );
};

export default withTranslation()(StyledSelect);
