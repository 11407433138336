import React from 'react';

const SvfUnlinkBleu = (props: any) => (
  <svg viewBox="0 0 25.88 25.88" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          d="M24.55,15.8l-3.3-3.3A4.38,4.38,0,0,0,18,11.17a4.44,4.44,0,0,0-3.3,1.39l-2.16,2.16A4.45,4.45,0,0,0,11.17,18a4.37,4.37,0,0,0,1.31,3.22l3.27,3.28A4.33,4.33,0,0,0,19,25.88a4.41,4.41,0,0,0,3.22-1.31l2.33-2.32a4.55,4.55,0,0,0,0-6.45Zm-2.16,4.29-2.33,2.32a1.53,1.53,0,0,1-1.08.41,1.42,1.42,0,0,1-1.07-.43l-3.27-3.28A1.46,1.46,0,0,1,14.2,18a1.55,1.55,0,0,1,.52-1.16l.29.31c.17.16.28.28.34.34l.3.23a1.23,1.23,0,0,0,.41.21,1.49,1.49,0,0,0,.43.06A1.54,1.54,0,0,0,18,16.49a1.49,1.49,0,0,0-.06-.43,1.4,1.4,0,0,0-.2-.41l-.24-.3L17.18,15l-.31-.29A1.52,1.52,0,0,1,18,14.21a1.5,1.5,0,0,1,1.08.44L22.39,18A1.47,1.47,0,0,1,22.84,19,1.43,1.43,0,0,1,22.39,20.09Z"
          fill={props.fill}
        />
        <path
          d="M13.32,11.17a4.48,4.48,0,0,0,1.4-3.32A4.38,4.38,0,0,0,13.4,4.63L10.13,1.35A4.32,4.32,0,0,0,6.9,0,4.38,4.38,0,0,0,3.68,1.32L1.35,3.63A4.33,4.33,0,0,0,0,6.85a4.41,4.41,0,0,0,1.33,3.24l3.3,3.3a4.45,4.45,0,0,0,3.24,1.33,4.45,4.45,0,0,0,3.3-1.4Zm-2.79-2.8a2.55,2.55,0,0,0-.3-.24,1.41,1.41,0,0,0-.4-.21,2,2,0,0,0-.44,0A1.5,1.5,0,0,0,7.87,9.39a2,2,0,0,0,0,.44,1.41,1.41,0,0,0,.21.4,2.55,2.55,0,0,0,.24.3c.05.06.17.18.34.34l.3.3a1.57,1.57,0,0,1-1.14.49,1.47,1.47,0,0,1-1.08-.43l-3.3-3.3a1.46,1.46,0,0,1-.44-1.08,1.42,1.42,0,0,1,.44-1.06L5.82,3.47A1.56,1.56,0,0,1,6.9,3.05,1.46,1.46,0,0,1,8,3.49l3.26,3.28a1.47,1.47,0,0,1,.45,1.08A1.58,1.58,0,0,1,11.17,9l-.3-.3C10.71,8.54,10.59,8.42,10.53,8.37Z"
          fill={props.fill}
        />
        <path
          d="M15.92,6a.94.94,0,0,0,.51.14,1,1,0,0,0,.86-.49l2-3.4a1,1,0,1,0-1.72-1l-2,3.4A1,1,0,0,0,15.92,6Z"
          fill={props.fill}
        />
        <path
          d="M19.63,9.86a1,1,0,0,0,.88.52,1,1,0,0,0,.48-.12l3.47-1.9a1,1,0,1,0-1-1.75L20,8.51A1,1,0,0,0,19.63,9.86Z"
          fill={props.fill}
        />
        <path
          d="M18.88,6.91a1,1,0,0,0,1.42,0l2.85-2.74a1,1,0,0,0,0-1.42,1,1,0,0,0-1.41,0L18.91,5.5A1,1,0,0,0,18.88,6.91Z"
          fill={props.fill}
        />
      </g>
    </g>
  </svg>
);

export default SvfUnlinkBleu;
