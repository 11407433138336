import React from 'react';
import { connect } from 'react-redux';
import { GoogleApiWrapper, Map } from 'google-maps-react';
import { withRouter } from 'react-router-dom';
import MarkerClustererComponent from './MarkerClustererComponent';
import LoadingBand from '../Bands/Loading';
import { withTranslation } from 'react-i18next';

interface Props {
  lat: number | string;
  lng: number | string;
  loaded: any;
  google: any;
  name: string;
  markersToCreate: Array<Object>;
  history: any;
  dispatch: any;
  t: any;
}

interface State {
  lat?: number | string;
  lng?: number | string;
}

/**
 * Affiche la map classique
 *
 * @class MapContainer
 * @exports Component
 */
export class MapContainer extends React.Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    const { lat: propLat, lng: propLng, markersToCreate } = props;
    if (propLat && propLat !== '') {
      return {
        lat: propLat,
        lng: propLng,
      };
    } else if (markersToCreate.length > 0) {
      const validMarker: any = markersToCreate.find((it: any) => it.gpsPosition !== null);
      if (validMarker !== undefined) {
        return {
          lat: validMarker.gpsPosition.lat,
          lng: validMarker.gpsPosition.lng,
        };
      }
    }

    return {
      lat: state.lat,
      lng: state.lng,
    };
  }

  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      lat: '',
      lng: '',
    };
  }

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { t } = this.props;
    try {
      const { loaded, google, markersToCreate, history } = this.props;
      const { lat, lng } = this.state;

      if (!loaded) return <LoadingBand message={t('single_gmap.loading_band.loading_map')} />;
      return (
        <Map className="map" google={google} zoom={12} initialCenter={{ lat, lng }} center={{ lat, lng }}>
          {markersToCreate.length > 0 && (
            <MarkerClustererComponent markersToCreate={markersToCreate} history={history} />
          )}
        </Map>
      );
    } catch (error) {
      return <div className="map">{t('single_gmap.failure_text.not_able_show_map')}</div>;
    }
  }
}

function mapStateToProps(state: any) {
  const { authentication, geolocation } = state;
  const { user } = authentication;
  return {
    user,
    geolocation,
  };
}

const mapping: any = connect(mapStateToProps)(MapContainer);

const tinyGmap = withRouter(mapping);
const tinyGmapGoogleApiWrapper = GoogleApiWrapper((props: any) => ({
  apiKey: 'AIzaSyBSRYNpTYG6I-BWu_MSDpnkDyuKsJrafc4',
  language: 'fr',
}))(tinyGmap);

const tr = withTranslation()(tinyGmapGoogleApiWrapper);
export default tr;
