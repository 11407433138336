import React, { useRef, useState } from 'react';
import { Button, Col, PopoverBody, PopoverHeader, UncontrolledPopover, UncontrolledTooltip } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import SvgSynchroHeader from '../../../SvgComponents/SynchroHeader';
import SvgInfoVert from '../../../SvgComponents/InformationVert';
import SvgRotationIndex from '../../../SvgComponents/RotationIndex';

const TaskButtons = (props: any) => {
  const { task, loading, params, onClickSynchro, history, isRound, t, tournee, onClickRotate } = props;
  const myRef = useRef<HTMLDivElement>(null);

  const onRotationClick = () => {
    onClickRotate();
    document.body.click();
  };

  return (
    <Col
      md="4"
      style={{
        maxWidth: 'fit-content',
        width: '-moz-fit-content',
        paddingRight: '0',
        position: 'absolute',
        right: '10px',
        display: loading ? 'none' : 'block',
      }}
    >
      <div className="flex-box" style={{ width: '-moz-fit-content' }}>
        {isRound && (
          <div
            id={`synchro-info-${task.id}`}
            className="clickable round"
            role="presentation"
            onClick={() =>
              history.push(`/gestionnaires/${params.GestionnaireId}/synchronisation/tournees/${task.rndCode}`)
            }
            style={{ marginRight: '10px' }}
          >
            <SvgInfoVert height="1.3em" style={{ marginTop: '2px' }} />
            <UncontrolledTooltip placement="bottom" target={`synchro-info-${task.id}`}>
              {t('all.round.go_to_round')}
            </UncontrolledTooltip>
          </div>
        )}
        <div
          style={{ marginRight: '10px' }}
          id={`synchro-task-${task.id}`}
          className="clickable round"
          role="presentation"
          onClick={onClickSynchro}
        >
          <SvgSynchroHeader height="1.3em" style={{ marginTop: '2px' }} />
          <UncontrolledTooltip placement="bottom" target={`synchro-task-${task.id}`}>
            {t('all.text.synchro')}
          </UncontrolledTooltip>
        </div>
        {isRound && _.get(tournee, 'rotateOptions.active') && (
          <div id={`synchro-rotate-${task.id}`} className="clickable round" role="presentation" ref={myRef}>
            <SvgRotationIndex height="1.3em" style={{ marginTop: '2px' }} />
            <UncontrolledTooltip placement="bottom" target={`synchro-rotate-${task.id}`}>
              {t('tournee_fiches.text.rotate_fiches')}
            </UncontrolledTooltip>
            <UncontrolledPopover trigger={'legacy'} placement="top" target={`synchro-rotate-${task.id}`}>
              <PopoverHeader style={{ color: 'red' }}>&#x26A0; Rotation des index</PopoverHeader>
              <PopoverBody style={{ display: 'grid' }}>
                <p>{t('tourneefiches.text.rotation_index_description')}</p>
                <p>{t('tourneefiches.text.rotation_index_warning')}</p>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <Button color="danger" onClick={onRotationClick}>
                    {t('all.button.confirm')}
                  </Button>
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        )}
      </div>
    </Col>
  );
};

function mapStateToProps(state: any) {
  const { users, authentication, tournee } = state;
  const { user } = authentication;
  return {
    user,
    users,
    tournee,
  };
}

const mapping: any = connect(mapStateToProps)(TaskButtons);

const connectedButtons = withRouter(mapping);
const tr = withTranslation()(connectedButtons);
export default tr;
