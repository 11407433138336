import React from 'react';

const SvgMeterModel = (props: any) => (
  <svg viewBox="0 0 200 200" {...props}>
    <path
      d="M109.5,140.5l25.6-25.6l4.6-22.5c0-2.4-1.9-4.3-4.3-4.3H32.4c-2.4,0-4.3,1.9-4.3,4.3l5.7,27.5H12.8l-0.1-1.2
			c-0.1-0.9-0.9-1.6-1.8-1.5c-0.9,0.1-1.5,0.8-1.5,1.7l0-0.2c-0.1-0.9-0.9-1.6-1.8-1.5C6.6,117.3,6,118,6,118.9l0-0.2
			c-0.1-0.9-0.9-1.6-1.8-1.5c-0.9,0.1-1.6,0.9-1.5,1.8l0.1,0.9H1.8c-1,0-1.8,0.8-1.8,1.8v17.3c0,1,0.8,1.8,1.8,1.8h3l0.1,1.2
			c0.1,0.9,0.8,1.5,1.7,1.5c0.1,0,0.1,0,0.2,0c0.9-0.1,1.5-0.8,1.5-1.7l0,0.2c0.1,0.9,0.8,1.5,1.7,1.5c0.1,0,0.1,0,0.2,0
			c0.9-0.1,1.5-0.8,1.5-1.7l0,0.2c0.1,0.9,0.8,1.5,1.7,1.5c0.1,0,0.1,0,0.2,0c0.9-0.1,1.6-0.9,1.5-1.8l-0.1-0.9H38l1.3,6.3
			c0,2.4,1.9,4.3,4.3,4.3h37.9c0.7-0.9,1.4-1.7,2.2-2.5C90.6,141.9,100.2,138.9,109.5,140.5z"
      fill={props.fill}
    />
    <path
      d="M22.1,83.2h61.6h62c5.6,0,9.7-7.2,8.3-13.6c-5.2-24.6-17.3-47.5-22.7-56.1c-1.6-2.6-4.1-5.7-6.8-5.7H43.3
			c-2.7,0-5.2,2.7-6.8,5.3C31.1,21.6,19,44.4,13.8,69C12.4,75.4,16.5,83.2,22.1,83.2z"
      fill={props.fill}
    />
    <path
      d="M128.8,130.5l-17.3,17.3c-8.1-2.6-17.1-0.5-23.1,5.6c-4.4,4.4-6.7,10.2-6.7,16.1c0,2.8,0.5,5.6,1.6,8.3
			c0.3,0.7,0.8,1.1,1.5,1.3c0.7,0.1,1.4-0.1,1.9-0.6l13.2-13.2l8.8,8.8l-13.2,13.2c-0.5,0.5-0.7,1.2-0.6,1.9
			c0.1,0.7,0.6,1.2,1.3,1.5c8.4,3.3,18,1.3,24.4-5.1l0,0c6.1-6.1,8.2-15,5.6-23.1l44-44c8.1,2.6,17.1,0.5,23.1-5.6
			c6.4-6.4,8.4-16,5.1-24.4c-0.3-0.7-0.8-1.1-1.5-1.3c-0.7-0.1-1.4,0.1-1.9,0.6l-13.2,13.2l-8.8-8.8l13.2-13.2
			c0.5-0.5,0.7-1.2,0.6-1.9c-0.1-0.7-0.6-1.2-1.3-1.5c-8.4-3.3-18-1.3-24.4,5.1c-6.1,6.1-8.2,15-5.6,23.1l-16.5,16.5L128.8,130.5z
			 M100.9,187.7l12.2-12.2c0.8-0.8,0.8-2.1,0-2.9l-11.7-11.7c-0.4-0.4-0.9-0.6-1.5-0.6c-0.5,0-1.1,0.2-1.5,0.6l-12.2,12.2
			c-1.2-6,0.7-12.4,5.1-16.8c5.2-5.2,13.1-6.9,20-4.2c0.8,0.3,1.6,0.1,2.2-0.5l45.9-45.9c0.4-0.4,0.6-0.9,0.6-1.5
			c0-0.3,0-0.5-0.1-0.8c-2.7-6.9-1.1-14.8,4.2-20c4.5-4.5,10.8-6.3,16.8-5.1l-12.2,12.2c-0.8,0.8-0.8,2.1,0,2.9l11.7,11.7
			c0.8,0.8,2.1,0.8,2.9,0l12.2-12.2c1.2,6-0.7,12.4-5.1,16.8c-5.2,5.2-13.1,6.9-20,4.2c-0.8-0.3-1.6-0.1-2.2,0.5l-45.9,45.9
			c-0.6,0.6-0.8,1.5-0.5,2.2c2.7,6.9,1.1,14.8-4.2,20C113.2,187.1,106.9,188.9,100.9,187.7L100.9,187.7z M100.9,187.7"
      fill={props.fill}
    />
  </svg>
);

export default SvgMeterModel;
