import _ from 'lodash';
import React, { Component } from 'react';
import { Input, Row } from 'reactstrap';
import SvgCroixRouge from '../SvgComponents/CroixRouge';

export default class CustomInput extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      valid: false,
      annee: 0,
      mois: 0,
      jour: 0,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e: any) {
    const { onFilter } = this.props;
    const annee = parseInt(e.target.value.split('-')[0]);
    const mois = parseInt(e.target.value.split('-')[1]);
    const jour = parseInt(e.target.value.split('-')[2]);
    if (e.target.value) {
      if (annee > 1950) {
        onFilter(e.target.value);
        this.setState({
          valid: true,
        });
      } else {
        onFilter('');
        this.setState({
          valid: false,
        });
      }
      this.setState({
        annee,
        mois,
        jour,
      });
    } else {
      this.setState({
        annee: null,
        valid: false,
      });
      onFilter('');
    }
  }

  render() {
    const { valid, mois, jour, annee } = this.state;
    return (
      <Input
        type="date"
        className={`unsortable-input ${
          _.size(_.get(this.props, 'column.filter.props.defaultValue')) > 0 ? '' : 'placeholder-selected'
        } ${_.get(this.props, 'column.filter.props.className')}`}
        onChange={e => {
          e.persist();
          if (!this.launchDebounceChange)
            this.launchDebounceChange = _.debounce(
              () => this.handleChange(e),
              _.get(this.props, 'column.filter.props.delay')
            );
          this.launchDebounceChange();
        }}
        valid={valid && mois && jour && annee > 1950}
        invalid={!valid && mois && jour && annee <= 1950}
        style={{ marginBottom: '0.5rem' }}
        disabled={_.get(this.props, 'column.filter.props.disabled')}
        defaultValue={_.get(this.props, 'column.filter.props.defaultValue')}
      />
    );
  }
}
