import React from 'react';

const SvgFuiteBleu = (props: any) => (
  <svg viewBox="0 0 17.92 20.93" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M17.92,0H0V5.63H6.16c.79-1,1.5-1.95,2-2.52a.65.65,0,0,1,.92,0c.47.57,1.18,1.51,2,2.54h6.91Z"
        />
        <path
          fill={props.fill}
          d="M8.24,5.87a36.55,36.55,0,0,0-2.92,4c-1.4,2.31-2.11,4.22-2.11,5.68a5.37,5.37,0,0,0,10.73,0c0-1.46-.71-3.37-2.11-5.68a35.26,35.26,0,0,0-2.92-4s-.23-.26-.34-.26S8.24,5.87,8.24,5.87Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgFuiteBleu;
