import React from 'react';

const SvgSaphirAntenneOff = (props: any) => (
  <svg viewBox="0 0 82.85 135.73" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="pictos">
        <path
          fill={props.fill}
          d="M45.37,112.2,60,102l8,25.87ZM24.24,97.51l.86-2.78H57.75l.86,2.78-17.19,12Zm-9.36,30.4,8-25.87L37.48,112.2ZM41.54,73.52,57,90.23H26.19Zm-7.42-8.08,4.37,4.76-8.78,9.54Zm7.3-23.7,5.84,18.92-5.71,6.22-5.9-6.42ZM53,79.36,44.6,70.2l4.19-4.56ZM43.58,33.43a2.25,2.25,0,0,0-4.3,0L8.67,132.82a2.23,2.23,0,0,0,3.42,2.5l29.33-20.38,29.34,20.38a2.29,2.29,0,0,0,2.61,0,2.25,2.25,0,0,0,.82-2.48Z"
        />
        <path
          fill={props.fill}
          d="M14.44,55.09a2.25,2.25,0,0,0,0-3.18,34,34,0,0,1,0-48.07,2.25,2.25,0,0,0,0-3.18,2.26,2.26,0,0,0-3.19,0,38.54,38.54,0,0,0,0,54.43,2.31,2.31,0,0,0,3.19,0"
        />
        <path
          fill={props.fill}
          d="M78.36,27.88a33.76,33.76,0,0,1-9.94,24,2.25,2.25,0,0,0,0,3.18,2.3,2.3,0,0,0,3.18,0A38.54,38.54,0,0,0,71.6.66a2.25,2.25,0,1,0-3.18,3.18,33.79,33.79,0,0,1,9.94,24"
        />
        <path
          fill={props.fill}
          d="M19.62,46.72a2.29,2.29,0,0,0,3.19,0,2.26,2.26,0,0,0,0-3.18,22.17,22.17,0,0,1,0-31.33A2.25,2.25,0,0,0,19.62,9a26.68,26.68,0,0,0,0,37.69"
        />
        <path
          fill={props.fill}
          d="M66.53,27.88a22,22,0,0,1-6.48,15.66,2.26,2.26,0,0,0,0,3.19,2.32,2.32,0,0,0,3.18,0,26.7,26.7,0,0,0,0-37.7,2.25,2.25,0,0,0-3.18,3.18,22,22,0,0,1,6.48,15.67"
        />
        <path
          fill={props.fill}
          d="M28,38.36a2.3,2.3,0,0,0,3.18,0,2.21,2.21,0,0,0,.66-1.59,2.25,2.25,0,0,0-.66-1.6,10.33,10.33,0,0,1,0-14.6,2.25,2.25,0,0,0,0-3.17,2.3,2.3,0,0,0-3.18,0,14.82,14.82,0,0,0,0,21"
        />
        <path
          fill={props.fill}
          d="M54.71,27.88a10.25,10.25,0,0,1-3,7.29,2.26,2.26,0,0,0,0,3.19,2.31,2.31,0,0,0,3.19,0,14.82,14.82,0,0,0,0-21,2.26,2.26,0,0,0-3.19,0A2.22,2.22,0,0,0,51,19a2.26,2.26,0,0,0,.65,1.59,10.26,10.26,0,0,1,3,7.3"
        />
      </g>
    </g>
  </svg>
);
export default SvgSaphirAntenneOff;
