const readMethodList = {
  T: {
    label: 'Télérelève',
    value: 'T',
  },
  M: {
    label: 'Manuelle',
    value: 'M',
  },
  A: {
    label: 'Radio',
    value: 'A',
  },
};

const alarms = [
  {
    label: 'Compteur bloqué',
    value: 'MeterBlock',
  },
  {
    label: 'Compteur inversé',
    value: 'ReversedMeter',
  },
  {
    label: 'Conduite cassée',
    value: 'BrokenPipe',
  },
  {
    label: 'Configuration incohérente',
    value: 'Configuration',
  },
  {
    label: 'Dysfonctionnement du module',
    value: 'ModuleDefect',
  },
  {
    label: 'Fraude',
    value: 'Fraud',
  },
  {
    label: 'Fuite',
    value: 'Leak',
  },
  {
    label: 'Horloge',
    value: 'Clock',
  },
  {
    label: 'Module démonté',
    value: 'ModuleRemoved',
  },
  {
    label: "Retour d'eau",
    value: 'Backflow',
  },
  {
    label: 'Sous-débit',
    value: 'Underflow',
  },
  {
    label: 'Sur-débit',
    value: 'Overflow',
  },
];

const readMethodSelectRule = (A, M, T) => {
  let without = null;
  if (A && M && T) {
    return null;
  }
  if (!A && !M && !T) {
    return null;
  }
  if (A && M) {
    without = 'T';
  }
  if (A && T) {
    without = 'M';
  }
  if (T && M) {
    without = 'A';
  }
  if (without) {
    return {
      filter: {
        label: 'Méthode de lecture',
        value: 'read.readMethod',
        type: 'selectContValue',
        filter: {
          props: {
            placeholder: 'Méthode de lecture',
          },
        },
      },
      operator: {
        label: "N'est pas",
        value: '!=',
      },
      userInput: readMethodList[without],
      num: 1,
      filterApplied: true,
    };
  }
  return {
    filter: {
      label: 'Méthode de lecture',
      value: 'read.readMethod',
      type: 'selectContValue',
      filter: {
        props: {
          placeholder: 'Méthode de lecture',
        },
      },
    },
    operator: {
      label: 'Est',
      value: '=',
    },
    // eslint-disable-next-line no-nested-ternary
    userInput: readMethodList[T ? 'T' : M ? 'M' : 'A'],
    num: 0,
    filterApplied: true,
  };
};

const ruleListConverter = (type, extra) => {
  switch (type) {
    case 'withAlarm':
      return {
        filter: {
          label: 'Avec alarmes',
          value: 'withAlarm',
          type: 'checkbox',
          filter: {
            props: {
              placeholder: 'En alarme',
            },
          },
        },
        operator: {
          label: 'Oui',
          value: 'True',
          noEntry: true,
        },
        userInput: null,
        num: 1,
        filterApplied: true,
      };
    case 'withoutAlarm':
      return {
        filter: {
          label: 'Avec alarmes',
          value: 'withAlarm',
          type: 'checkbox',
          filter: {
            props: {
              placeholder: 'En alarme',
            },
          },
        },
        operator: {
          label: 'Non',
          value: 'False',
          noEntry: true,
        },
        userInput: null,
        num: 1,
        filterApplied: true,
      };
    case 'notRead':
      return {
        filter: {
          label: 'Date de dernière lecture',
          value: 'read.date',
          type: 'date',
          filter: {
            props: {
              placeholder: 'Date de dernière lecture',
            },
          },
        },
        operator: {
          label: 'Antérieur à ',
          value: '<=',
        },
        userInput: extra,
        num: 0,
        filterApplied: true,
      };
    case 'read':
      return {
        filter: {
          label: 'Date de dernière lecture',
          value: 'read.date',
          type: 'date',
          filter: {
            props: {
              placeholder: 'Date de dernière lecture',
            },
          },
        },
        operator: {
          label: 'Postérieur à ',
          value: '>=',
        },
        userInput: extra,
        num: 0,
        filterApplied: true,
      };
    case 'manufacturer':
      const ex = [
        {
          label: 'Zenner',
          value: 'Zenner',
        },
      ];
      return {
        filter: {
          label: 'Constructeur du compteur',
          value: 'meter.manufacturer',
          type: 'text',
          filter: {
            props: {
              placeholder: 'Constructeur du compteur',
            },
          },
        },
        operator: {
          label: 'Contient',
          value: 'contient',
        },
        userInput: extra.map(el => ({
          label: el.replace(/^./, el[0].toUpperCase()),
          value: el.replace(/^./, el[0].toUpperCase()),
        })),
        num: 5,
        filterApplied: true,
      };
    case 'readMethod':
      return readMethodSelectRule(extra.A, extra.M, extra.T);
    case 'alarmType':
      console.log(alarms.find(el => extra.alarmType.toLowerCase().includes(el.value.toLowerCase())));
      return {
        filter: {
          label: 'Alarmes',
          value: 'alarms',
          type: 'selectAlarmTypeBasic',
          opts: alarms,
          filter: {
            props: {
              placeholder: 'Alarmes',
            },
          },
        },
        operator: {
          label: 'Est',
          value: '=',
        },
        userInput: alarms.find(el => extra.alarmType.toLowerCase().includes(el.value.toLowerCase())),
        num: 0,
        filterApplied: true,
      };
    default:
      return null;
  }
};

export default ruleListConverter;
