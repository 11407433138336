import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Spinner } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import filterFactory, { Comparator, selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import SvgTeleReleve from '../SvgComponents/LogoTeleReleve';
import teleActions from '../_actions/teleReleve.actions';
import teleReleveActions from '../_actions/teleReleve.actions';
import LoadingBand from '../Bands/Loading';
import { QueryBuilder } from '../QueryBuilder';
import listOperator from '../_shared/OperatorList';
import ListTools from '../List/ListTools';
import { Template } from '../_entities/template';
import SvgLogoSigfox from '../SvgComponents/LogoSigfox';
import SvgLora from '../SvgComponents/LogoLora';
import SvgCroixNoir from '../SvgComponents/croixNoir';
import SwitchButton from '../_components/SwitchButton';
import TabStateProvisioning from '../MasksAlarmCreator/TabStateProvisioning';
import Loading from '../_animations/Loading';

class Provisionning extends Component<any, any> {
  constructor(Props: any) {
    super(Props);
    this.state = {
      init: false,
      teleData: [],
      columns: [],
      selectedRows: [],
      verif: false,
      openModal: false,
      unSelect: {},
      handleTabs: false,
      multi: false,
      fastContainerStyle: {},
      isToggle: null,
      listRadioSelected: new Map(),
      activeMod: 0,
      actionNumber: [0, 0],
    };
    this.receiveData = this.receiveData.bind(this);
    this.toggleDepro = this.toggleDepro.bind(this);
    this.hideDepro = this.hideDepro.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.generateRowEvents = this.generateRowEvents.bind(this);
    this.handleButton = this.handleButton.bind(this);
    this.rowStyle = this.rowStyle.bind(this);
    this.launchProvision = this.launchProvision.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.deselectAll = this.deselectAll.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(teleActions.getAvailable());
    dispatch(teleReleveActions.getProgressOfUpdateRadio());
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll(e: any) {
    const { handleTabs }: any = this.state;
    const doc = document.getElementById('recap-tab-id');
    const fastContainer = document.getElementById('fast-moving');
    const tabContainer = document.getElementById('tab-container');
    if (doc) {
      const info = doc.getBoundingClientRect();
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - info.height) {
        this.setState({
          handleTabs: true,
        });
      } else if (handleTabs) {
        this.setState({
          handleTabs: false,
        });
      }
    }
    // console.log(fastContainer, tabContainer);
    if (fastContainer && tabContainer) {
      const fastInfo = fastContainer.getBoundingClientRect();
      const tabInfo = tabContainer.getBoundingClientRect();
      const containerStyle: any = {
        transform: 'translateY(0px)',
      };
      if (window.scrollY >= 85 && fastInfo.y + fastInfo.height <= tabInfo.y + tabInfo.height) {
        containerStyle.transform = `translateY(${window.scrollY - 85}px)`;
      }
      if (window.scrollY <= 85) {
        containerStyle.transform = `translateY(0px)`;
      }
      const y = tabInfo.height - fastInfo.height;
      if (window.scrollY >= y) {
        containerStyle.transform = `translateY(${y}px)`;
      }
      // console.log('test');

      this.setState({
        fastContainerStyle: containerStyle,
      });
    }
  }

  static getDerivedStateFromProps(props: any, state: any) {
    const { telereleve, dispatch, t } = props;
    const copyState = _.cloneDeep(state);
    if (telereleve.items && telereleve.items.length > 0) {
      if (!state.init) {
        copyState.teleData = _.sortBy(telereleve.items, o => (o.canProvision ? 1 : 2));
        copyState.init = true;
      }
      if (telereleve.actions) {
        _.get(telereleve, 'actions.success', []).forEach(el => copyState.listRadioSelected.delete(el));
        copyState.activeMod = 0;
        copyState.init = false;
        dispatch(teleReleveActions.getAvailable());
      }
      const keys = _.keys(telereleve.items[0]).filter(el => el !== 'gpsPosition');
      const selectOptions = [{ value: true, label: 'Oui' }, { value: false, label: 'Non' }];
      const selectProtocole = [{ value: 'Lora', label: 'Lora' }, { value: 'Sigfox', label: 'Sigfox' }];
      copyState.columns = (keys || []).map((champ: any) => {
        const teleFilter = textFilter({
          placeholder: t(`telereleve.column.${champ}`), // custom the input placeholder
          className: 'dashboard-custom-text-filter', // custom classname on input
          defaultValue: '', // default filtering value
          comparator: Comparator.LIKE, // default is Comparator.LIKE
        });
        if (champ !== 'canProvision' && champ !== 'networkName') {
          return {
            dataField: champ,
            text: t(`telereleve.column.${champ}`),
            sort: true,
            filter: teleFilter,
            formatter: ListTools.findFormatter(champ),
            classes: 'crystalList-column',
          };
        }
        if (champ === 'canProvision') {
          return {
            dataField: champ,
            text: t(`telereleve.column.${champ}`),
            sort: true,
            formatter: ListTools.findFormatter(champ),
            filter: selectFilter({
              placeholder: t(`telereleve.column.${champ}`),
              options: selectOptions,
            }),
          };
        }
        return {
          dataField: champ,
          text: t(`telereleve.column.${champ}`),
          sort: true,
          filter: selectFilter({
            placeholder: t(`telereleve.column.${champ}`),
            options: selectProtocole,
          }),
          formatter: (cellContent: any, row: Template) => (
            <div style={{ textAlign: 'center' }}>
              {/lora/i.test(cellContent) ? (
                <SvgLora height="2em" />
              ) : /sigfox/i.test(cellContent) ? (
                <SvgLogoSigfox height="2em" />
              ) : (
                <div />
              )}
            </div>
          ),
        };
      });
      copyState.columns.forEach((el: any) => {
        if (el.dataField === 'radioSerialNumber' || el.dataField === 'devEUI' || el.dataField === 'mtrSerialNumber') {
          el.headerStyle = () => ({ width: '20%' });
        } else {
          el.headerStyle = () => ({ width: '10%' });
        }
      });
    }
    return copyState;
  }

  receiveData: any = (dataFiltered: any) => {
    if (dataFiltered) {
      this.setState({ teleData: dataFiltered });
    }
  };

  launchProvision() {
    const { listRadioSelected } = this.state;
    const { telereleve, dispatch } = this.props;
    const radios = [...listRadioSelected.entries()];
    const toRegister = radios.filter(el => !el[1]).map(el => el[0]);
    const toUnregister = radios.filter(el => el[1]).map(el => el[0]);
    dispatch(teleReleveActions.provision({ toRegister, toUnregister }));
  }

  selectAll() {
    const { teleData, activeMod } = this.state;
    const listRadioSelected = _.cloneDeep(this.state.listRadioSelected);
    switch (activeMod) {
      case 0:
        teleData
          .filter(row => row.providerName !== null)
          .forEach(row => listRadioSelected.set(row.devEUI, row.providerName !== null));
        break;
      case 1:
        teleData
          .filter(row => row.providerName === null && row.canProvision)
          .forEach(row => listRadioSelected.set(row.devEUI, row.providerName !== null));
        break;
    }
    this.setState({
      listRadioSelected,
    });
  }

  deselectAll() {
    const { teleData, activeMod } = this.state;
    let listRadioSelected = _.cloneDeep(this.state.listRadioSelected);
    switch (activeMod) {
      case 0:
        listRadioSelected = new Map(_.entries(listRadioSelected).filter(([key, value]) => !value));
        break;
      case 1:
        listRadioSelected = new Map(_.entries(listRadioSelected).filter(([key, value]) => value));
        break;
      case 2:
        listRadioSelected.clear();
        break;
    }
    this.setState({
      listRadioSelected,
      activeMod: listRadioSelected.size === 0 ? 0 : activeMod,
    });
  }

  listFieldsGenerator() {
    const { columns } = this.state;
    const { telereleve, t } = this.props;
    let listFields = [];
    if (telereleve.items && telereleve.items.length > 0 && columns.length > 0) {
      listFields = columns
        .map((col: any) => {
          const typeData = ListTools.findSpecificType(col.dataField);
          return {
            label: t(`telereleve.column.${col.dataField}`),
            value: col.dataField,
            type: typeData,
          };
        })
        .sort((a: any, b: any) => {
          if (a.label < b.label) return -1;
          if (a.label === b.label) return 0;
          return 1;
        });
    }
    return listFields;
  }

  generateRowEvents() {
    const rowEvents = {
      onClick: (e: any, row: any, rowIndex: number) => {
        const { activeMod } = this.state;
        const listRadioSelected = _.cloneDeep(this.state.listRadioSelected);
        if (!(_.get(row, 'providerName') !== null || _.get(row, 'canProvision'))) return;
        if (listRadioSelected.has(row.devEUI)) {
          listRadioSelected.delete(row.devEUI);
        } else {
          listRadioSelected.set(row.devEUI, row.providerName !== null);
        }
        this.setState({
          listRadioSelected,
          activeMod: _.size(listRadioSelected) === 0 && activeMod > 1 ? 0 : activeMod,
        });
      },
    };
    return rowEvents;
  }

  generateLittleColumns() {
    const { columns, verif } = this.state;
    const fieldList = ['mtrSerialNumber', 'radioSerialNumber'];
    const littleColumns = columns.filter((el: any) => fieldList.find((field: string) => field === el.dataField));
    littleColumns.push({
      text: '',
      headerStyle: () => ({ width: '50px' }),
      formatter: (cellContent: any, row: any) => {
        return (
          <div
            onClick={() => this.deleteRow(row)}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <SvgCroixNoir width="2em" />
          </div>
        );
      },
    });
    return littleColumns;
  }

  deleteRow = (row: any, byPass: boolean) => {
    const { selectedRows, verif } = this.state;
    if (verif && !byPass) {
      this.setState({
        openModal: true,
        unSelect: row,
      });
    } else {
      const copyList = _.cloneDeep(selectedRows).filter((el: any) => el.devEUI !== row.devEUI);
      this.setState({
        selectedRows: copyList,
      });
      this.toggle();
    }
  };

  toggle = () => {
    this.setState({
      openModal: false,
    });
  };

  toggleDepro(isProvi: boolean) {
    const doc: any = document.getElementsByClassName(isProvi ? 'un' : 'deux')[0];
    if (doc.classList.value.includes('closed')) {
      doc.classList.remove('closed');
    }
    doc.classList.add('open');
    this.setState({
      isToggle: isProvi ? 'un' : 'deux',
    });
  }

  hideDepro(isProvi: boolean) {
    const doc: any = document.getElementsByClassName(isProvi ? 'un' : 'deux')[0];
    doc.classList.remove('open');
    doc.classList.add('closed');
    this.setState({
      isToggle: null,
    });
  }

  handleButton() {
    const { handleTabs } = this.state;
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - (handleTabs ? 450 : 0)) {
    } else {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }

  rowStyle(row) {
    const { listRadioSelected, activeMod } = this.state;
    const devEUI = _.get(row, 'devEUI');
    const isSelected = listRadioSelected.has(devEUI);
    if (isSelected && activeMod < 2) {
      return { backgroundColor: row.providerName === null ? '#bddbd1' : '#f4c5bb' };
    }
    if (activeMod === 1 && !row.canProvision) {
      // return {backgroundColor: 'rgba(248,200,149,0.78)'}
    } else if (activeMod === 2) {
      return { backgroundColor: row.providerName === null ? '#bddbd1' : '#f4c5bb' };
    }
    return {};
  }

  render() {
    const { telereleve, t } = this.props;
    const { progress } = telereleve;
    const {
      columns,
      teleData,
      selectedRows,
      verif,
      openModal,
      unSelect,
      handleTabs,
      multi,
      fastContainerStyle,
      listRadioSelected,
      activeMod,
    } = this.state;
    const listFields = this.listFieldsGenerator();
    const addList = selectedRows.filter((el: any) => !el.providerName);
    const delList = selectedRows.filter((el: any) => el.providerName);
    const littleColumns = this.generateLittleColumns();
    const rowEvents = this.generateRowEvents();
    console.log('Provisionning::render::389', moment.utc(_.get(progress, 'lastLaunch')));
    return (
      <>
        {telereleve.loading && <Loading />}
        <div style={{ display: 'flex' }}>
          {!telereleve.loading && (
            <div style={{ padding: '0 20px' }} className="telereleve-container-provisionning">
              <Modal isOpen={openModal} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>Retirer de la sélection</ModalHeader>
                <ModalBody>
                  <div className="modal-confirm-container">
                    <h5>Etes de vous sur de vouloir retirer la tele revele suivante:</h5>
                    <span style={{ display: 'block' }}>Numéro de série compteur: {unSelect.mtrSerialNumber}</span>
                    <span style={{ display: 'block' }}>Numéro de série radio: {unSelect.radioSerialNumber}</span>
                    <span style={{ display: 'block' }}>devEUI: {unSelect.devEUI}</span>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={this.toggle}>
                    Annuler
                  </Button>
                  <Button color="primary" onClick={() => this.deleteRow(unSelect, true)}>
                    Valider
                  </Button>
                </ModalFooter>
              </Modal>
              {!verif && (
                <>
                  <Fragment>
                    <div className="filter-container">
                      <QueryBuilder
                        sendListFiltered={this.receiveData}
                        listData={telereleve.items}
                        listOperator={listOperator}
                        listFilters={listFields}
                        idContext="TELE_RELEVE"
                      />
                    </div>
                  </Fragment>
                  <div style={{ display: 'flex' }}>
                    <div className="col-md-12 tele-releve-container" id="tab-container" style={{ flex: '0 0 100%' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="tele-releve-header flex-box">
                          <SvgTeleReleve fill="#31c6b3" height="4em" />
                          <h2>
                            {teleData && teleData.length} {t('telereleve.text.telereleve')}
                          </h2>
                        </div>
                        <Button
                          onClick={() => {
                            const { dispatch } = this.props;
                            dispatch(teleReleveActions.updateProvisioningRadio());
                          }}
                          disabled={_.get(progress, 'nbUpdatedTelereleve') !== _.get(progress, 'nbTelereleveToUpdate')}
                        >
                          <div>
                            {_.get(progress, 'nbUpdatedTelereleve') !== _.get(progress, 'nbTelereleveToUpdate') && (
                              <Spinner
                                style={{ marginRight: '5px' }}
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                            {_.get(progress, 'nbUpdatedTelereleve') !== _.get(progress, 'nbTelereleveToUpdate')
                              ? 'Mise à jour du patrimoine en cours ...'
                              : 'Mettre à jour le patrimoine'}
                          </div>
                          {_.get(progress, 'lastLaunch') && (
                            <span>
                              Dernière mise à jour :{' '}
                              {moment(_.get(progress, 'lastLaunch')).format(`${t('all.date_format.date')}`)}
                            </span>
                          )}
                        </Button>
                      </div>
                      {false && (
                        <div
                          style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}
                          className="multi-selection-provisionning"
                        >
                          <h5 style={{ marginBottom: 0, color: 'black', marginRight: '5px' }}>Multi-sélection</h5>
                          <SwitchButton
                            height={20}
                            width={40}
                            style={{ marginBottom: 0 }}
                            checked={multi}
                            handleChange={() => this.setState({ multi: !multi })}
                            onColor="#409F95"
                            offColor="#999999"
                            offHandleColor="#CCCCCC"
                            onHandleColor="#31C6B3"
                          />
                        </div>
                      )}
                      {telereleve.provisionProcessing && (
                        <LoadingBand message={`${t('provisionning.text.provision_processing')} ...`} />
                      )}
                      {_.get(progress, 'nbUpdatedTelereleve') !== _.get(progress, 'nbTelereleveToUpdate') && (
                        <Progress
                          animated
                          value={
                            (_.get(progress, 'nbUpdatedTelereleve', 0) / _.get(progress, 'nbTelereleveToUpdate', 1)) *
                            100
                          }
                        >
                          {`${_.get(progress, 'nbUpdatedTelereleve', 0)}/${_.get(progress, 'nbTelereleveToUpdate', 1)}`}
                        </Progress>
                      )}
                      <div className="tele-releve-body" id="tab-id">
                        <div className="selected-content">
                          <TabStateProvisioning
                            handleMod={index => {
                              this.setState({ activeMod: index });
                            }}
                            activeMod={_.get(this.state, 'activeMod')}
                            withAction={_.size(listRadioSelected) > 0}
                            decoupage={[
                              _.size(teleData.filter(el => el.providerName !== null)),
                              _.size(teleData.filter(el => el.providerName === null)),
                              [
                                _.size([...listRadioSelected.values()].filter(el => !el)),
                                _.size([...listRadioSelected.values()].filter(el => el)),
                              ],
                            ]}
                          />
                          <div
                            style={{
                              borderLeft: '1px solid lightgrey',
                              borderRight: '1px solid lightgrey',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              <Button
                                style={{ margin: '10px' }}
                                onClick={this.selectAll}
                                disabled={_.get(this.state, 'activeMod') === 2}
                              >
                                {t('all.text.select_all')}
                              </Button>
                              <Button style={{ margin: '10px' }} onClick={this.deselectAll}>
                                {t('all.text.deselect_all')}
                              </Button>
                            </div>
                            <Button
                              style={{ margin: '10px', display: _.size(listRadioSelected) === 0 ? 'none' : '' }}
                              onClick={this.launchProvision}
                              disabled={_.get(this.state, 'activeMod') < 2}
                            >
                              {t('all.button.apply')}
                            </Button>
                          </div>
                          {_.size(telereleve.items) > 0 && (
                            <div style={{ borderLeft: '1px solid lightgrey', borderRight: '1px solid lightgrey' }}>
                              <BootstrapTable
                                keyField="devEUI"
                                bootstrap4
                                bordered={false}
                                rowEvents={multi ? {} : rowEvents}
                                rowStyle={this.rowStyle}
                                hover
                                data={_.sortBy(
                                  teleData.filter(el =>
                                    _.get(this.state, 'activeMod') === 0
                                      ? el.providerName !== null
                                      : _.get(this.state, 'activeMod') === 1
                                      ? el.providerName === null
                                      : listRadioSelected.has(el.devEUI)
                                  ),
                                  o => (o.canProvision ? 1 : 2)
                                )}
                                columns={columns}
                                pagination={teleData.length > 10 ? paginationFactory() : null}
                                headerClasses="crystalList-column"
                                rowClasses={row =>
                                  _.get(row, 'providerName') !== null || _.get(row, 'canProvision') ? 'clickable' : ''
                                }
                                filter={filterFactory()}
                                options={{
                                  sortName: 'canProvision', // default sort column name
                                  sortOrder: 'asc', // default sort order
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {selectedRows.length === 1 && false && (
                      <div className="fast-provisioning-container-big" id="fast-moving" style={fastContainerStyle}>
                        <div className="fast-provisioning-container">
                          <div className="header">
                            <h3>Fast provisioning</h3>
                          </div>
                          <div className="info-container">
                            {_.entries(selectedRows[0]).map((el: any) => (
                              <div style={{ marginBottom: '10px' }}>
                                <span>{Provisionning.columnsTextGenerator(el[0])}</span>
                                <h4>{el[0] !== 'canProvision' ? el[1] : el[1] ? 'Oui' : 'Non'}</h4>
                              </div>
                            ))}
                          </div>
                          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', zIndex: 10000 }}>
                            <Button
                              type="submit"
                              color="primary"
                              style={{ color: 'white', cursor: 'pointer' }}
                              onClick={() => console.log('provision')}
                            >
                              {selectedRows[0].providerName ? 'Déprovisionner' : 'Provisionner'}
                            </Button>{' '}
                          </div>
                          <div className="clip-container" />
                        </div>
                      </div>
                    )}
                    {selectedRows.length > 1 && !handleTabs && (
                      <div className="provi-postite-container" id="fast-moving" style={fastContainerStyle}>
                        <div className="postite un">
                          <div
                            className="provisionning-container"
                            style={{
                              height: '350px',
                              backgroundColor: '#31c6b3',
                              border: '1px solid #31c6b3',
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: 'white',
                                padding: '10px',
                                borderRadius: '5px',
                                height: '100%',
                                maxHeight: '100%',
                                overflow: 'auto',
                              }}
                            >
                              <div className="header">
                                <h3>{addList.length} Provisionner</h3>
                              </div>
                              {telereleve.items && addList.length > 0 && (
                                <BootstrapTable
                                  keyField="devEUI"
                                  bootstrap4
                                  bordered
                                  hover
                                  data={addList}
                                  columns={littleColumns}
                                  filter={filterFactory()}
                                  headerClasses="crystalList-column"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="postite deux">
                          <div
                            className="provisionning-container"
                            style={{
                              height: '350px',
                              backgroundColor: '#dc3545',
                              border: '1px solid #dc3545',
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: 'white',
                                padding: '10px',
                                borderRadius: '5px',
                                height: '100%',
                                maxHeight: '100%',
                                overflow: 'auto',
                              }}
                            >
                              <div className="header">
                                <h3>{delList.length} Déprovisionner</h3>
                              </div>
                              {telereleve.items && delList.length > 0 && (
                                <BootstrapTable
                                  keyField="devEUI"
                                  bootstrap4
                                  bordered
                                  hover
                                  data={delList}
                                  columns={littleColumns}
                                  filter={filterFactory()}
                                  headerClasses="crystalList-column"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="button-provi-container">
                          <Button
                            color="primary"
                            style={{ color: 'white' }}
                            id="button-id"
                            onClick={() => {
                              window.scrollTo(0, document.body.scrollHeight);
                              this.setState({ verif: true });
                            }}
                          >
                            Valider
                          </Button>{' '}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
              {verif && (
                <div
                  style={{
                    padding: '15px',
                    backgroundColor: 'white',
                    border: '1px solid lightgrey',
                    borderRadius: '5px',
                    marginBottom: '15px',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <div
                      className="provisionning-container"
                      style={{
                        height: 'auto',
                        width: '50%',
                        marginRight: '7.5px',
                        border: '1px solid lightgrey',
                        borderTop: '5px solid #31c6b3',
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: 'white',
                          padding: '10px',
                          borderRadius: '5px',
                          height: '100%',
                          maxHeight: '100%',
                          overflow: 'auto',
                        }}
                      >
                        <div className="header" style={{ height: '10%' }}>
                          <h3>{addList.length} Provisionner</h3>
                        </div>
                        {telereleve.items && addList.length > 0 && (
                          <BootstrapTable
                            keyField="devEUI"
                            bootstrap4
                            bordered
                            hover
                            data={addList}
                            columns={littleColumns}
                            filter={filterFactory()}
                            pagination={addList.length > 10 ? paginationFactory() : null}
                          />
                        )}
                        {addList.length === 0 && (
                          <div
                            style={{
                              width: '100%',
                              height: '85%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <h5 style={{ marginBottom: '0' }}>Aucun compteur à provisionner</h5>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="provisionning-container"
                      style={{
                        height: 'auto',
                        width: '50%',
                        marginLeft: '7.5px',
                        border: '1px solid lightgrey',
                        borderTop: '5px solid #dc3545',
                      }}
                      id="recap-tab-id"
                    >
                      <div
                        style={{
                          backgroundColor: 'white',
                          padding: '10px',
                          borderRadius: '5px',
                          height: '100%',
                          maxHeight: '100%',
                          overflow: 'auto',
                        }}
                      >
                        <div className="header" style={{ height: '10%' }}>
                          <h3>{delList.length} Déprovisionner</h3>
                        </div>
                        {telereleve.items && delList.length > 0 && (
                          <BootstrapTable
                            keyField="devEUI"
                            bootstrap4
                            bordered
                            hover
                            data={delList}
                            columns={littleColumns}
                            filter={filterFactory()}
                            pagination={delList.length > 10 ? paginationFactory() : null}
                          />
                        )}
                        {delList.length === 0 && (
                          <div
                            style={{
                              width: '100%',
                              height: '85%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <h5 style={{ marginBottom: '0' }}>Aucun compteur à déprovisionner</h5>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      marginTop: '15px',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      color="primary"
                      style={{ color: 'white' }}
                      id="button-id"
                      onClick={() => {
                        this.setState({ verif: false });
                      }}
                    >
                      Confirmer
                    </Button>{' '}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, telereleve } = state;
  const { user } = authentication;

  return {
    user,
    telereleve,
  };
}

const mapping: any = connect(mapStateToProps)(Provisionning);
const connectedProvisionning = withRouter(mapping);
export default withTranslation()(connectedProvisionning);
/*
                      <div className={"button-provi-container"} style={{zIndex: 1}}>
                        <Button
                          color="primary"
                          style={{ color: 'white' }}
                          id={'button-id'}
                          onClick={() => {
                            window.scrollTo(0, document.body.scrollHeight);
                          }}
                        >
                          Valider
                        </Button>{' '}
                      </div>
 */
