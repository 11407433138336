import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import EtoileFavoriHeader from '../SvgComponents/EtoileFavorisHeader';
import BurgerHeader from '../SvgComponents/BurgerHeader';
import StockHeader from '../SvgComponents/StockHeader';
import SynchroHeader from '../SvgComponents/SynchroHeader';
import gestionnaireActions from '../_actions/gestionnaire.actions';
import { CrystalBreadCrumb, HelpModal, NotificationModal } from './index';
import { getActiveClassByPath } from '../_helpers';
import { withTranslation } from 'react-i18next';

interface Props {
  user: any;
  dispatch: Function;
  gestionnaire: any;
  t: any;
}
interface State {
  isOpen: boolean;
  tooltipOpen: boolean;
}

/**
 * @class MainNavbar
 * @extends Component
 */
class TourneeNavbar extends React.Component<Props, State> {
  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      tooltipOpen: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    console.log(this.props);
    dispatch(gestionnaireActions.getAllGestionnaires());
  }

  /**
   * Gère l'ouverture de la modal de support
   *
   * @method toggle
   */
  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  /**
   * Gère l'ouverture de la tooltip
   *
   * @method toggleTooltip
   */
  toggleTooltip = () => {
    const { tooltipOpen } = this.state;
    this.setState({
      tooltipOpen: !tooltipOpen,
    });
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { isOpen } = this.state;
    const { user, gestionnaire, t } = this.props;
    if (!user) return null;
    return (
      <div>
        <Navbar expand="md" id="tournee-navbar">
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem className="header-item">
                <NavLink
                  className={getActiveClassByPath(location.pathname, 'synchronisation')}
                  href={
                    gestionnaire && gestionnaire.gestionnaires && gestionnaire.gestionnaires.length === 1
                      ? `/gestionnaires/${gestionnaire.gestionnaires[0].id}/synchronisation`
                      : '/gestionnaires'
                  }
                  id="nav-synchronisation"
                >
                  <SynchroHeader fill="currentcolor" height="1.5em" width="1.5em" />
                  <UncontrolledTooltip placement="bottom" target="nav-synchronisation">
                    {t('tournee_navbar.nav_tooltip.synchro')}
                  </UncontrolledTooltip>
                </NavLink>
              </NavItem>
              <NavItem className="header-item">
                <NavLink className={getActiveClassByPath(location.pathname, 'stock')} href="/stock" id="nav-stock">
                  <StockHeader fill="currentcolor" height="1.5em" width="1.5em" />
                  <UncontrolledTooltip placement="bottom" target="nav-stock">
                    {t('tournee_navbar.nav_tooltip.stock')}
                  </UncontrolledTooltip>
                </NavLink>
              </NavItem>
              <NavItem className="header-item">
                <NotificationModal />
              </NavItem>
              <NavItem className="header-item">
                <NavLink
                  className={getActiveClassByPath(location.pathname, 'bookmarks')}
                  href="/bookmarks"
                  id="nav-bookmarks"
                >
                  <EtoileFavoriHeader fill="currentcolor" height="1.5em" width="1.5em" />
                  <UncontrolledTooltip placement="bottom" target="nav-bookmarks">
                    {t('tournee_navbar.nav_tooltip.dashboard_favorite_plural')}
                  </UncontrolledTooltip>
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar className="header-item-last">
                <DropdownToggle nav caret>
                  <BurgerHeader fill="#808080" height="1.5em" width="1.5em" />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag={Link} to={`/users/${user.id}`}>
                    {t('tournee_navbar.nav_tooltip.my_info_plural')}
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem tag={Link} to="/signin/">
                    {t('tournee_navbar.nav_tooltip.disconnect')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, gestionnaire } = state;
  const { user } = authentication;
  return {
    user,
    gestionnaire,
  };
}

const mapping: any = connect(mapStateToProps)(TourneeNavbar);

const connectedMainNavbar = withRouter(mapping);
const tr = withTranslation()(connectedMainNavbar);
export default tr;
