import React from 'react';

const SvgTableauBordBlanc = (props: any) => (
  <svg viewBox="0 0 16 16" {...props}>
    <path
      d="M0 8.89h7.11V0H0zM0 16h7.11v-5.33H0zm8.89 0H16V7.11H8.89zm0-16v5.33H16V0z"
      fill={props.fill}
      data-name="Calque 2"
    />
  </svg>
);

export default SvgTableauBordBlanc;
