import React, { Component } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';

interface Props {
  meters: any;
  typesAlarm: any;
  alarmTemplates: any;
  handleChange: Function;
  t: Function;
}

class AlarmConstructeurOption extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, typesAlarm, handleChange, alarmTemplates } = this.props;
    let indexList: any = [];
    if (typesAlarm) {
      const index = alarmTemplates.content.dataSourceProperty.condition.findIndex(
        (el: any) => el.conditionTitle === 'AlarmType'
      );
      indexList =
        _.get(alarmTemplates, 'content.dataSourceProperty.condition[index].conditionValue') &&
        alarmTemplates.content.dataSourceProperty.condition[index].conditionValue.map((el: any) =>
          typesAlarm.findIndex((type: any) => type.name === el)
        );
    }
    return (
      <div className="wrapper">
        <div className={'alarm'}>
          <div className={'type-alarm'} style={{ width: '35%' }}>
            <h5>{t('all.alarm_meter.alarm_type')}</h5>
            <Select
              cacheOptions
              options={
                typesAlarm &&
                typesAlarm.map((el: any) => ({ value: el.name, label: t(`alarm.type.${el.name.toLowerCase()}`) }))
              }
              defaultValue={
                indexList &&
                indexList.map(
                  (el: any) =>
                    typesAlarm.map((el: any) => ({
                      value: el.name,
                      label: t(`alarm.type.${el.name.toLowerCase()}`),
                    }))[el]
                )
              }
              defaultOptions
              isMulti
              onChange={(e: any) => handleChange(e)}
              placeholder={t('all.alarm_meter.alarm_type')}
            />
          </div>
        </div>
      </div>
    );
  }
}

const tr = withTranslation()(AlarmConstructeurOption);
export default tr;
