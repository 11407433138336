import React from 'react';

const SvgRotationIndex = (props: any) => (
  <svg viewBox="0 0 123.3 96.2" {...props}>
    <g>
      <path
        fill={props.fill}
        d="M61.3,16.2C61.3,16.2,61.3,16.2,61.3,16.2l-10,1.6L28,14c-0.4-0.1-0.8-0.1-1.2,0L3,17.9c-1.7,0.3-3,1.7-3,3.4
		v13.1c0,1.9,1.6,3.5,3.5,3.5c2,0,3.5-1.6,3.5-3.5V24.2l20.3-3.3l20.3,3.3v10.1c0,1.9,1.6,3.5,3.5,3.5c0.1,0,0.2,0,0.4,0
		c1.8-0.2,3.2-1.7,3.2-3.4V24.2l8.9-1.4c-0.4-0.5-0.8-1-1.1-1.6C61.8,19.7,61.3,18,61.3,16.2z"
      />
      <path
        fill={props.fill}
        d="M70.7,88.5l-15.9-2.6V75.8c0-0.8-0.3-1.6-0.8-2.2c-0.2-0.3-0.5-0.5-0.8-0.7c-0.6-0.4-1.2-0.6-2-0.6
		c-1.9,0-3.5,1.6-3.5,3.5v10.1l-20.3,3.3L7.1,85.9V75.8c0-1.9-1.6-3.5-3.5-3.5c-2,0-3.5,1.6-3.5,3.5v13.1c0,1.7,1.3,3.1,3,3.4
		l23.9,3.9c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0l23.3-3.8l19.2,3.1c-0.3-1-0.4-2-0.4-3.1C70,91,70.3,89.7,70.7,88.5z"
      />
      <g>
        <path
          fill={props.fill}
          d="M81.3,12.4c-0.7,0-1.3,0.2-1.9,0.5c-0.1,0-0.2,0-0.4,0l7.4-7.7C87.6,4,87.6,2,86.4,0.9
			c-1.2-1.2-3.2-1.1-4.3,0.1l-12.4,13c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.5,0.1,1,0.4,1.4c0.1,0.3,0.3,0.5,0.6,0.7l13,12.4
			c1.2,1.2,3.2,1.1,4.3-0.1c1.2-1.2,1.1-3.2-0.1-4.3l-6.7-6.4c0.3,0.1,0.7,0.2,1.1,0.2c18.9,0,34.3,15.4,34.3,34.3
			c0,0.8,0,1.7-0.1,2.5c-0.1,2.1,1.4,3.9,3.5,4.1c0.1,0,0.2,0,0.3,0c2,0,3.7-1.5,3.8-3.6c0.1-1,0.1-2,0.1-3
			C123.3,31.2,104.5,12.4,81.3,12.4z"
        />
        <path
          fill={props.fill}
          d="M100.8,82.5c-1.6,1.1-3.2,2-4.9,2.8c-1.9,0.9-2.7,3.2-1.8,5.1c0.7,1.4,2,2.2,3.5,2.2c0.5,0,1.1-0.1,1.6-0.4
			c2.1-1,4.1-2.1,6-3.5c1.7-1.2,2.2-3.6,1-5.3C104.9,81.7,102.5,81.3,100.8,82.5z"
        />
        <path
          fill={props.fill}
          d="M117.5,66.9c-1.9-0.9-4.2-0.1-5.1,1.8c-0.8,1.7-1.8,3.4-2.8,4.9c-1.2,1.7-0.8,4.1,1,5.3
			c0.7,0.5,1.4,0.7,2.2,0.7c1.2,0,2.4-0.6,3.2-1.7c1.3-1.9,2.5-3.9,3.5-6C120.2,70.1,119.4,67.8,117.5,66.9z"
        />
        <path
          fill={props.fill}
          d="M83.8,88.5c-0.8,0.1-1.6,0.1-2.5,0.1c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8c1,0,2,0,3-0.1
			c2.1-0.1,3.7-2,3.5-4.1C87.7,89.9,85.9,88.3,83.8,88.5z"
        />
      </g>
    </g>
  </svg>
);

export default SvgRotationIndex;
