import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import StepWizard from 'react-step-wizard';
import { alarmActions, localeActions, alertActions } from '../_actions';
import Last from './Last';
import AlarmeOption from './AlarmOption';
import AlarmVert from '../SvgComponents/AlarmeVert';
import { alert, alarms } from '../_interfaces/reducers';
import { User } from '../_entities/user';
import { Alert } from '../_entities/alert';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';

type Props = {
  alert: alert;
  dispatch: any;
  alarms: alarms;
  locationId: any;
  availableUsers: Array<User>;
  alarmToUpdate: Alert | {};
  toggle: any;
  isOpen: boolean;
  t: Function;
};

type State = {
  form: any;
};

/**
 * @class AlarmUpdaterComponent
 * @extends {React.Component<Props, State>}
 */
class AlarmUpdaterComponent extends React.Component<Props & {}, State> {
  /**
   * Met à jour le state si l'alerte affiche un succès
   *
   * @static
   * @param {Props} props
   * @param {State} state
   * @returns {State} Le nouveau state
   * @method getDerivedStateFromProps
   * @memberof AlarmUpdaterComponent
   */
  static getDerivedStateFromProps(props: Props, state: State) {
    let form = state.form;
    if (props.alarmToUpdate) {
      form = props.alarmToUpdate;
    }
    if (props.alert.type === 'alert-success') {
      return {
        form,
      };
    }
    return {
      form,
    };
  }

  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof AlarmUpdaterComponent
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {},
    };
  }

  /**
   * Récupère les types d'alarme au montage du
   * composant
   *
   * @method componentDidMount
   * @memberof AlarmUpdaterComponent
   */
  componentDidMount() {
    const { dispatch } = this.props;
    this.updateForm('saved', false);
    dispatch(localeActions.load());
  }

  /**
   * Ferme la modale en cas de réussite à la sauvegarde
   *
   * @method componentDidUpdate
   * @memberof AlarmUpdaterComponent
   */
  componentDidUpdate() {
    const { alert, toggle, dispatch, isOpen } = this.props;
    const { form } = this.state;
    if (alert && alert.type === 'alert-success') {
      if (form.saved) {
        toggle();
      } else if (!isOpen) {
        dispatch(alertActions.clear());
      }
    }
  }

  onStepChange = (stats: any) => {};

  /**
   * Met à jour les informations du formulaire
   *
   * @param {string} key
   * @param {string} value
   * @method updateForm
   * @memberof AlarmUpdaterComponent
   */
  updateForm = (key: any, value: any) => {
    const { form }: any = this.state;
    form[key] = value;
    this.setState({ form });
  };

  /**
   * Gère la sauvegarde de l'alarme
   *
   * @method send
   * @memberof AlarmUpdaterComponent
   */
  send = () => {
    const { form }: any = this.state;
    const { dispatch, alarms } = this.props;
    let { alarmToUpdate }: any = this.props;

    let alarm: any = cloneDeep(alarmToUpdate);
    alarm.content = form.content;
    alarm.users = form.users;

    dispatch(alarmActions.update(alarm));
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { alert, locationId, availableUsers, toggle, isOpen, t } = this.props;
    const { alarmToUpdate }: any = this.props;
    const { form } = this.state;
    return (
      <Modal isOpen={isOpen} toggle={toggle} backdrop size="lg">
        <ModalHeader toggle={toggle}>
          <AlarmVert height="1em" width="1em" fill="currentcolor" /> &nbsp; {t('alarm_updater.text.alert_edition')}
        </ModalHeader>
        <ModalBody>
          <div>{alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}</div>
          {alarmToUpdate && Object.keys(alarmToUpdate).length > 0 && (
            <div className="col-md-12">
              <StepWizard onStepChange={this.onStepChange}>
                {
                  <AlarmeOption
                    hashKey="alarmOptions"
                    content={form.content}
                    update={this.updateForm}
                    locationId={locationId}
                    locationCode={alarmToUpdate.locationCode}
                  />
                }
                <Last
                  hashKey="TheEnd!"
                  update={this.updateForm}
                  availableUsers={availableUsers}
                  send={this.send}
                  form={form}
                />
              </StepWizard>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}>
            {t('all.button.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, alarms, alert, locale } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    alarms,
    locale,
  };
}

const alarmUpdaterConnectedComponent = connect(mapStateToProps)(AlarmUpdaterComponent);
const tr = withTranslation()(alarmUpdaterConnectedComponent);
export default tr;
