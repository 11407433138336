import React from 'react';
import _ from 'lodash';

import SvgMeterWithAlarm from '../SvgComponents/MeterWithAlarm';
import SvgMeterModel from '../SvgComponents/MeterModel';
import SvgReadMeterType from '../SvgComponents/ReadMeterType';
import SvgReadMeter from '../SvgComponents/ReadMeter';
import SvgDoigtVert from '../SvgComponents/DoigtVert';
import SimpleDateComponent from '../_components/SimpleDateComponent';

const cardDisplayer = t => [
  {
    type: 'synthese',
    title: t('remotereading.text.synthese'),
  },
  {
    type: 'read',
    data: [],
    title: t('virtual_meter.text.read_meter_plural'),
    svg: <SvgReadMeter height="2em" fill="#31c6b3" />,
  },
  {
    type: 'updatedMeter',
    data: [],
    title: t('all.meter.modified_meter_plural'),
    svg: <SvgMeterWithAlarm height="2em" fill="#31c6b3" />,
  },
  {
    type: 'read_mode',
    data: [],
    title: t('remotereading.text.read_method'),
    svg: <SvgReadMeterType height="2em" fill="#31c6b3" />,
  },
  {
    type: 'model',
    data: [],
    title: t('remotereading.text.read_sort_by_model'),
    svg: <SvgMeterModel height="2em" fill="#31c6b3" />,
  },
  {
    type: 'alarmType',
    data: [],
    title: t('remotereading.text.alarm_type'),
    svg: <SvgMeterWithAlarm height="2em" fill="#31c6b3" />,
  },
];

const optionsBox = t => [
  [
    t('remotereading.text.by_status'),
    [
      {
        title: t('remotereading.text.read_with_no_alarm'),
        code: 'withoutAlarm',
      },
      {
        title: t('remotereading.text.read_with_alarm'),
        code: 'withAlarm',
      },
      {
        title: t('remotereading.text.not_read'),
        code: 'notRead',
      },
    ],
  ],
  [t('remotereading.text.by_manufacturer'), []],
  [
    t('remotereading.text.by_read_mode'),
    [
      {
        title: t('all.read_meter.type.T'),
        code: 'T',
      },
      {
        title: t('all.read_meter.type.M'),
        code: 'M',
      },
      {
        title: t('all.read_meter.type.A'),
        code: 'A',
      },
    ],
  ],
];

const BandInfo = ({ nbrTotal, nbrGeoloc, nbrSelect, t }) => {
  return (
    <div className="band-info-container">
      <span>
        <b>
          {nbrSelect} {t('all.meter.meter_plural')}
        </b>{' '}
        {t('remotereading.text.match_selected_meter', { nbMeters: nbrTotal })}{' '}
        <b>{t('remotereading.text.meter_geolocated', { meterGeolocated: nbrGeoloc })}</b>
      </span>
    </div>
  );
};

const filterGpsMeterWithOptionsToFillMarker = (selectedBox, gpsMeter, startDate, meterModels) => {
  let list = _.cloneDeep(gpsMeter);
  const models = [];
  const readMethods = [];

  meterModels.forEach(el => selectedBox[el.toLowerCase()] && models.push(el));

  selectedBox.T && readMethods.push('T');
  selectedBox.M && readMethods.push('M');
  selectedBox.A && readMethods.push('A');

  list = list.filter(el => models.includes(el.meterModel));
  let readList =
    selectedBox.withAlarm || selectedBox.withoutAlarm
      ? selectedBox.withAlarm && selectedBox.withoutAlarm
        ? list.filter(el => el.readData)
        : selectedBox.withAlarm
        ? list.filter(el => _.size(_.get(el, 'readData.alarm')) > 0)
        : list.filter(el => _.size(_.get(el, 'readData.alarm')) === 0)
      : [];

  const notReadList = selectedBox.notRead ? list.filter(el => !el.readData) : [];
  if (readList.length > 0) {
    readList = _.cloneDeep(readList).filter(el => readMethods.includes(_.get(el, 'readData.method')));
  }
  let finalList = _.concat(notReadList, readList);

  if (selectedBox.alarmType) {
    finalList = _.cloneDeep(finalList).filter(el => {
      return Object.values(el.readData.alarm).find(alarm =>
        alarm.toLowerCase().includes(selectedBox.alarmType.toLowerCase())
      );
    });
  }
  return finalList;
};

const defaultSelectedBox = {
  withoutAlarm: false,
  withAlarm: false,
  notRead: true,
  T: false,
  M: false,
  A: false,
};

const initSelectedBox = meterModels => {
  let boxs = {
    withoutAlarm: false,
    withAlarm: false,
    notRead: false,
    T: false,
    M: false,
    A: false,
  };
  meterModels.forEach(el => {
    boxs = _.set(boxs, el.toLowerCase(), true);
  });
  return boxs;
};

const RemoteDate = props => {
  const { t } = props;
  return (
    <div className="second-date-component">
      <div className="flex-box">
        <div className="title-date">
          <div className="svg-container">
            <SvgDoigtVert height="2em" fill="#31c6b3" />
          </div>
          <h3>{t('source_sheet_pdi.text.display_interval')}</h3>
        </div>
        <SimpleDateComponent {...props} />
      </div>
    </div>
  );
};

const getColor = type => {
  switch (type) {
    case 'Diehl':
    case 'Remote reading':
      return '#007bff';
    case 'Sensus':
    case 'read':
      return '#28a745';
    case 'Non relevé':
    case 'notRead':
      return '#F24141';
    case 'Alarme':
    case 'withAlarm':
      return '#A63C76';
    case 'R':
    case 'A':
    case 'withoutAlarm':
      return '#91E0F2';
    case 'inconnu':
    case 'Inconnu':
    case 'UNKNOWN':
      return '#ffc107';
    case 'M':
    case 'Manuel':
    case 'Manual':
    case 'Relevé':
      return '#26A653';
    default:
      return '#17a2b8';
  }
};

export default {
  cardDisplayer,
  optionsBox,
  BandInfo,
  defaultSelectedBox,
  initSelectedBox,
  filterGpsMeterWithOptionsToFillMarker,
  RemoteDate,
  getColor,
};
