import React from 'react';

const SvgCloseEye = (props: any) => (
  <svg viewBox="0 0 200 200" {...props}>
    <path
      fill={props.fill || '#2E3643'}
      d="M159.6,82.9c-15.3-14.7-35.4-22.8-56.6-22.8c0,0,0,0,0,0c0,0,0,0,0,0c-21.2,0-41.3,8.1-56.6,22.8
			C45,84.3,45,86.6,46.4,88c0.7,0.7,1.7,1.1,2.6,1.1c0.9,0,1.8-0.3,2.5-1c13.9-13.4,32.2-20.8,51.6-20.8c19.3,0,37.7,7.4,51.6,20.8
			c1.4,1.4,3.7,1.3,5.1-0.1C161.1,86.6,161.1,84.3,159.6,82.9z"
    />
    <path
      fill="none"
      stroke={props.fill || '#2E3643'}
      strokeWidth={9}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M56.1,113.7c12.6,14.8,29.2,23,46.8,23c0,0,0,0,0,0s0,0,0,0c17.5,0,34.2-8.2,46.8-23"
    />
    <line
      fill="none"
      stroke={props.fill || '#2E3643'}
      strokeWidth={9}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="81.2"
      y1="133.4"
      x2="73.3"
      y2="145.1"
    />
    <line
      fill="none"
      stroke={props.fill || '#2E3643'}
      strokeWidth={9}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="103.1"
      y1="136.7"
      x2="103.1"
      y2="149.7"
    />
    <line
      fill="none"
      stroke={props.fill || '#2E3643'}
      strokeWidth={9}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="124.7"
      y1="133.4"
      x2="132.7"
      y2="145.2"
    />
  </svg>
);

export default SvgCloseEye;
