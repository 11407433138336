import React, { Component } from 'react';
import GestionTable from '../_components/TableauGestion';
import SvgMessageVert from '../SvgComponents/MessageVert';
import synchroAction from '../_actions/synchro.actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import TableRow from '../_components/TableRow';
import LoadingBand from '../Bands/Loading';
import SvgAjoutBleu from '../SvgComponents/AjoutBleu';
import { withTranslation } from 'react-i18next';

class ListMessages extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props;
    const { params } = match;
    dispatch(synchroAction.getAllMessages(params.GestionnaireId));
  }

  render() {
    const { synchro, imports, t } = this.props;

    const svg = <SvgMessageVert height="1em" width="1em" fill="#31c6b3" className="svg-table-title" />;

    const columns = [
      {
        dataField: 'code',
        text: t('all.column_text.code'),
      },
      {
        dataField: 'label',
        text: t('all.column_text.label'),
      },
    ];

    return (
      <div>
        {synchro && synchro.loading && <LoadingBand message={t('list_messages.loading_band.loading_msg')} />}
        {synchro && synchro.messages && (
          <div className="container-tab-synchro">
            <GestionTable
              columns={columns}
              data={synchro.messages}
              svgTitle={svg}
              titleTab={t('all.text.msg')}
              withAdd
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { synchro, gestionnaire, imports } = state;
  return {
    alert,
    synchro,
    gestionnaire,
    imports,
  };
}

const mapping: any = connect(mapStateToProps)(ListMessages);

const connectedUser = withRouter(mapping);
export default withTranslation()(connectedUser);
