import React, { Component } from 'react';
import { connect } from 'react-redux';
import { locales } from '../_interfaces/reducers';
import { Col, Row } from 'reactstrap';
import { searchActions } from '../_actions';
import { cloneDeep } from 'lodash';
import Select, { components } from 'react-select';

interface Props {
  locales: locales;
  dispatch: any;
  locationCode: any;
  update: Function;
  condition: any;
}

interface State {
  meterList: Array<any>;
  query: string;
  results: any;
  mtrKey: number;
}

const NoOptionsMessage = (props: any) => {
  let newProps = cloneDeep(props);
  newProps.children = 'Pas de résultat';
  return <components.NoOptionsMessage {...newProps} />;
};

/**
 * @class MeterSelector
 * @extends {Component<Props, State>}
 */
class MeterSelector extends Component<Props, State> {
  /**
   * @constructor
   * @param {Props} props Propriétés
   */
  constructor(props: Props) {
    super(props);
    const { condition } = this.props;
    const meterList =
      condition.conditionValue && typeof condition.conditionValue !== 'string' ? condition.conditionValue : [];
    this.state = {
      mtrKey: Math.floor(Math.random() * Math.floor(1024)),
      query: '',
      results: [],
      meterList,
    };
  }

  /**
   * Tronque les résultats s'ils dépassent 15 entrées
   * et retourne le check
   *
   * @method checkTotal
   * @returns {boolean} Le dépassement ou non de 15 entrées
   */
  checkTotal = (data: any): boolean => {
    if (data.length > 15) {
      let diff = data.length - 15;
      data.splice(14, diff);
    }
    return data.length > 15;
  };

  /**
   * Formate la date en fonction de l'existence des
   * différents champs présents
   *
   * @method formatAddress
   * @returns {string} L'adresse formatée
   */
  formatAddress = (address: any) => {
    let formatedAddress = '';
    if (this.checkAddressField(address.streetNumber)) {
      formatedAddress += `${address.streetNumber} `;
    }
    if (this.checkAddressField(address.streetNumberAdditionnal)) {
      formatedAddress += `${address.streetNumberAdditionnal} `;
    }
    if (this.checkAddressField(address.street)) {
      formatedAddress += `${address.street} `;
    }
    if (this.checkAddressField(address.complement)) {
      formatedAddress += `${address.complement} `;
    }
    if (this.checkAddressField(address.city)) {
      formatedAddress += `, ${address.city} `;
    }
    if (this.checkAddressField(address.zipcode)) {
      formatedAddress += `${address.zipcode}`;
    }

    return formatedAddress;
  };

  /**
   * Vérifie l'existence et l'intégrité d'un champ d'adresse
   *
   * @method checkAddressField
   * @param {any} field Champ
   * @returns {boolean} l'intégrité du champ
   */
  checkAddressField = (field: any) => {
    return undefined !== field && null !== field && field.length > 0;
  };

  /**
   * Lance la recherche lors de la saisie dans l'input
   * et formate les résultats
   *
   * @method handleInputChange
   * @param {string} value Saisie
   * @param {any} action Action
   */
  handleInputChange = (value: string, action: any) => {
    const { locationCode } = this.props;
    this.setState({
      query: value,
    });
    if (value.length > 1) {
      const specific = value.startsWith('V-') ? 'vmeter' : 'pdi';
      try {
        searchActions
          .specificSearch(value, locationCode, specific)
          .then((results: any) => {
            if (undefined !== results && null !== results) {
              results = results.map((it: any) => {
                let res = {
                  label: '',
                  value: '',
                };
                if (it.address) {
                  const address = this.formatAddress(it.address);
                  res.label = `${it.meter.serial} (${address})`;
                  res.value = it.meter.serial;
                } else {
                  res.label = it.name;
                  res.value = it.name;
                }
                return res;
              });

              if (this.checkTotal(results)) {
                const moreOption = {
                  label: '...',
                  value: 'none',
                };
                results.push(moreOption);
              }
              const data = {
                query: value,
                results,
              };
              this.setState(data);
            }
          })
          .catch((error: any) => {
            console.error(error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  /**
   * Gère les différents évènements liés au selecteur
   * de compteurs (ajout, suppression, clear)
   *
   * @method handleMeters
   * @param {any} items Compteurs
   * @param {any} action Action
   */
  handleMeters = (items: any, action: any) => {
    let { meterList }: any = this.state;
    const { update, condition } = this.props;
    let existing;
    switch (action.action) {
      case 'select-option':
        if (action.option && action.option.value !== 'none') {
          existing = meterList.find((it: any) => it === action.option.value);
          if (existing === undefined) {
            meterList.push(action.option.value);
          }
        }
        break;
      case 'remove-value':
        if (action.removedValue && action.removedValue.value !== 'none') {
          existing = meterList.find((it: any) => it === action.removedValue.value);
          if (existing !== undefined) {
            const mtrIndex = meterList.indexOf(existing);
            meterList.splice(mtrIndex, 1);
          }
        }
        break;
      case 'clear':
        meterList = [];
        break;
    }

    update(meterList, condition.conditionTitle);
    this.setState({ meterList });
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { results, meterList: mtrs } = this.state;
    return (
      <div style={{ marginBottom: '25px' }} id="meterSelector">
        <Row>
          <Col xs="12">
            <Select
              options={results}
              isMulti
              closeMenuOnSelect={false}
              components={{ NoOptionsMessage }}
              name="meters"
              id="meters"
              onInputChange={this.handleInputChange}
              onChange={this.handleMeters}
              value={mtrs.map(it => ({ label: it, value: it }))}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, locales, pdis } = state;
  const { user } = authentication;

  return {
    alert,
    user,
    pdis,
    locales,
  };
}

const connectedMeterSelector = connect(mapStateToProps)(MeterSelector);
export default connectedMeterSelector;
