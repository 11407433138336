const gestionnaireConstants = {
  GETALLGESTIONNAIRE_REQUEST: 'GESTIONNAIRE_GETALL_REQUEST',
  GETALLGESTIONNAIRE_SUCCESS: 'GESTIONNAIRE_GETALL_SUCCESS',
  GETALLGESTIONNAIRE_FAILURE: 'GESTIONNAIRE_GETALL_FAILURE',

  GETGESTIONNAIRE_REQUEST: 'GESTIONNAIRE_GET_REQUEST',
  GETGESTIONNAIRE_SUCCESS: 'GESTIONNAIRE_GET_SUCCESS',
  GETGESTIONNAIRE_FAILURE: 'GESTIONNAIRE_GET_FAILURE',

  SWITCH_DEVICE_DELETION_PERMISSION_REQUEST: 'SWITCH_DEVICE_DELETION_PERMISSION_REQUEST',
  SWITCH_DEVICE_DELETION_PERMISSION_SUCCESS: 'SWITCH_DEVICE_DELETION_PERMISSION_SUCCESS',
  SWITCH_DEVICE_DELETION_PERMISSION_FAILURE: 'SWITCH_DEVICE_DELETION_PERMISSION_FAILURE',

  ADD_GESTIONNAIRE_PERM_REQUEST: 'ADD_GESTIONNAIRE_PERM_REQUEST',
  ADD_GESTIONNAIRE_PERM_SUCCESS: 'ADD_GESTIONNAIRE_PERM_SUCCESS',
  ADD_GESTIONNAIRE_PERM_FAILURE: 'ADD_GESTIONNAIRE_PERM_FAILURE',

  GET_GESTIONNAIRE_PERM_REQUEST: 'GET_GESTIONNAIRE_PERM_REQUEST',
  GET_GESTIONNAIRE_PERM_SUCCESS: 'GET_GESTIONNAIRE_PERM_SUCCESS',
  GET_GESTIONNAIRE_PERM_FAILURE: 'GET_GESTIONNAIRE_PERM_FAILURE',

  DELETE_GESTIONNAIRE_PERM_REQUEST: 'DELETE_GESTIONNAIRE_PERM_REQUEST',
  DELETE_GESTIONNAIRE_PERM_SUCCESS: 'DELETE_GESTIONNAIRE_PERM_SUCCESS',
  DELETE_GESTIONNAIRE_PERM_FAILURE: 'DELETE_GESTIONNAIRE_PERM_FAILURE',

  CLEAR: 'GESTIONNAIRE_CLEAR',
};

export default gestionnaireConstants;
