const radioConstants = {
  GETALL_REQUEST: 'RADIO_GETALL_REQUEST',
  GETALL_SUCCESS: 'RADIO_GETALL_SUCCESS',
  GETALL_FAILURE: 'RADIO_GETALL_FAILURE',

  GETALLWITHCHILDREN_REQUEST: 'RADIO_GETALLWITHCHILDREN_REQUEST',
  GETALLWITHCHILDREN_SUCCESS: 'RADIO_GETALLWITHCHILDREN_SUCCESS',
  GETALLWITHCHILDREN_FAILURE: 'RADIO_GETALLWITHCHILDREN_FAILURE',

  GETINFO_REQUEST: 'RADIO_GETINFO_REQUEST',
  GETINFO_SUCCESS: 'RADIO_GETINFO_SUCCESS',
  GETINFO_FAILURE: 'RADIO_GETINFO_FAILURE',

  GETSTATES_REQUEST: 'RADIO_GETSTATES_REQUEST',
  GETSTATES_SUCCESS: 'RADIO_GETSTATES_SUCCESS',
  GETSTATES_FAILURE: 'RADIO_GETSTATES_FAILURE',

  GETALARMS_REQUEST: 'RADIO_GETALARMS_REQUEST',
  GETALARMS_SUCCESS: 'RADIO_GETALARMS_SUCCESS',
  GETALARMS_FAILURE: 'RADIO_GETALARMS_FAILURE',

  GETDATA_SUCCESS: 'RADIO_GETDATA_SUCCESS',
  GETDATA_FAILURE: 'RADIO_GETDATA_FAILURE',

  GET_INFO_REQUEST: 'GET_INFO_REQUEST',
  GET_INFO_SUCCESS: 'GET_INFO_SUCCESS',
  GET_INFO_FAILURE: 'GET_INFO_FAILURE',

  CLEAR: 'RADIO_CLEAR',
};

export default radioConstants;
