import { authHeader, handleResponse } from '../_helpers';

async function save(filters: any, startDate: any, endDate: any, zoom: string, locationId: number) {
  filters.forEach(e => {
    delete e.filter.filter;
    if (_.isArray(e.userInput)) e.userInput = _.join(e.userInput.map(el => el.value), '|');
    if (_.isPlainObject(e.userInput)) e.userInput = e.userInput.value;
  });
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      filters,
      startDate,
      zoom,
      endDate,
    }),
  };
  const response = await fetch(`/api/exportdata/location/${locationId}/default`, requestOptions);
  return handleResponse(response);
}

async function exportCsv(
  filters: any,
  listFields: any,
  startDate: any,
  endDate: any,
  zoom: string,
  separator: any,
  locationId: number,
  alarmType: any
) {
  filters.forEach(e => {
    delete e.filter.filter;
    if (_.isArray(e.userInput)) e.userInput = _.join(e.userInput.map(el => el.value), '|');
    if (_.isPlainObject(e.userInput)) e.userInput = e.userInput.value;
  });
  const pdiExport = {
    filters: filters,
    startDate: startDate,
    endDate: endDate,
    zoom: zoom,
    separator: separator,
    orderFields: listFields,
    alarmType: alarmType,
  };
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(pdiExport),
  };
  console.log('exportCsv::exportCsv::47', locationId);
  let response = await fetch(`/api/exportdata/location/${locationId}/default/csv`, requestOptions);
  return handleResponse(response);
}

const filtreService = {
  save,
  exportCsv,
};
export default filtreService;
