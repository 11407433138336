const pdiConstants = {
  GETALL_REQUEST: 'PDI_GETALL_REQUEST',
  GETALL_SUCCESS: 'PDI_GETALL_SUCCESS',
  GETALL_FAILURE: 'PDI_GETALL_FAILURE',

  GET_FULL_REQUEST: 'PDI_GETFULL_REQUEST',
  GET_FULL_SUCCESS: 'PDI_GETFULL_SUCCESS',
  GET_FULL_FAILURE: 'PDI_GETFULL_FAILURE',

  GETALLWITHCHILDREN_REQUEST: 'PDI_GETALLWITHCHILDREN_REQUEST',
  GETALLWITHCHILDREN_SUCCESS: 'PDI_GETALLWITHCHILDREN_SUCCESS',
  GETALLWITHCHILDREN_FAILURE: 'PDI_GETALLWITHCHILDREN_FAILURE',

  GETALLWITHCHILDREN_LIMIT_REQUEST: 'PDI_GETALLWITHCHILDREN_LIMIT_REQUEST',
  GETALLWITHCHILDREN_LIMIT_SUCCESS: 'PDI_GETALLWITHCHILDREN_LIMIT_SUCCESS',
  GETALLWITHCHILDREN_LIMIT_FAILURE: 'PDI_GETALLWITHCHILDREN_LIMIT_FAILURE',

  GETALLWITHCHILDREN_RANGEDATE_REQUEST: 'PDI_GETALLWITHCHILDREN_RANGEDATE_REQUEST',
  GETALLWITHCHILDREN_RANGEDATE_SUCCESS: 'PDI_GETALLWITHCHILDREN_RANGEDATE_SUCCESS',
  GETALLWITHCHILDREN_RANGEDATE_FAILURE: 'PDI_GETALLWITHCHILDREN_RANGEDATE_FAILURE',

  GETALLWITHCHILDREN_WITHFILTERS_REQUEST: 'PDI_GETALLWITHCHILDREN_WITHFILTERS_REQUEST',
  GETALLWITHCHILDREN_WITHFILTERS_SUCCESS: 'PDI_GETALLWITHCHILDREN_WITHFILTERS_SUCCESS',
  GETALLWITHCHILDREN_WITHFILTERS_FAILURE: 'PDI_GETALLWITHCHILDREN_WITHFILTERS_FAILURE',

  EXPORT_PDI_CSV_REQUEST: 'EXPORT_PDI_CSV_REQUEST',
  EXPORT_PDI_CSV_SUCCESS: 'EXPORT_PDI_CSV_SUCCESS',
  EXPORT_PDI_CSV_FAILURE: 'EXPORT_PDI_CSV_FAILURE',

  EXPORT_PDI_CSV_STATUS_REQUEST: 'EXPORT_PDI_CSV_STATUS_REQUEST',
  EXPORT_PDI_CSV_STATUS_SUCCESS: 'EXPORT_PDI_CSV_STATUS_SUCCESS',
  EXPORT_PDI_CSV_STATUS_FAILURE: 'EXPORT_PDI_CSV_STATUS_FAILURE',

  EXPORT_PDI_DAT_REQUEST: 'EXPORT_PDI_DAT_REQUEST',
  EXPORT_PDI_DAT_SUCCESS: 'EXPORT_PDI_DAT_SUCCESS',
  EXPORT_PDI_DAT_FAILURE: 'EXPORT_PDI_DAT_FAILURE',

  GET_REMOTE_PDI_REQUEST: 'GET_REMOTE_PDI_REQUEST',
  GET_REMOTE_PDI_SUCCESS: 'GET_REMOTE_PDI_SUCCESS',
  GET_REMOTE_PDI_FAILURE: 'GET_REMOTE_PDI_FAILURE',

  GETSTOCK_REQUEST: 'PDI_GETSTOCK_REQUEST',
  GETSTOCK_SUCCESS: 'PDI_GETSTOCK_SUCCESS',
  GETSTOCK_FAILURE: 'PDI_GETSTOCK_FAILURE',

  LINKPDI_REQUEST: 'PDI_LINKPDI_REQUEST',
  LINKPDI_SUCCESS: 'PDI_LINKPDI_SUCCESS',
  LINKPDI_FAILURE: 'PDI_LINKPDI_FAILURE',

  MAPID_TO_SERIAL_REQUEST: 'MAPID_TO_SERIAL_REQUEST',
  MAPID_TO_SERIAL_SUCCESS: 'MAPID_TO_SERIAL_SUCCESS',
  MAPID_TO_SERIAL_FAILURE: 'MAPID_TO_SERIAL_FAILURE',

  UNLINKPDI_REQUEST: 'PDI_UNLINKPDI_REQUEST',
  UNLINKPDI_SUCCESS: 'PDI_UNLINKPDI_SUCCESS',
  UNLINKPDI_FAILURE: 'PDI_UNLINKPDI_FAILURE',

  LINKPDI_ALL_REQUEST: 'PDI_LINKPDI_ALL_REQUEST',
  LINKPDI_ALL_SUCCESS: 'PDI_LINKPDI_ALL_SUCCESS',
  LINKPDI_ALL_FAILURE: 'PDI_LINKPDI_ALL_FAILURE',

  UNLINKPDI_ALL_REQUEST: 'PDI_UNLINKPDI_ALL_REQUEST',
  UNLINKPDI_ALL_SUCCESS: 'PDI_UNLINKPDI_ALL_SUCCESS',
  UNLINKPDI_ALL_FAILURE: 'PDI_UNLINKPDI_ALL_FAILURE',

  UNLINKACTIONSPDI_REQUEST: 'PDI_UNLINKACTIONSPDI_REQUEST',
  UNLINKACTIONSPDI_SUCCESS: 'PDI_UNLINKACTIONSPDI_SUCCESS',
  UNLINKACTIONSPDI_FAILURE: 'PDI_UNLINKACTIONSPDI_FAILURE',

  CREATEPDI_REQUEST: 'PDI_CREATEPDI_REQUEST',
  CREATEPDI_SUCCESS: 'PDI_CREATEPDI_SUCCESS',
  CREATEPDI_FAILURE: 'PDI_CREATEPDI_FAILURE',

  GETINFO_REQUEST: 'PDI_GETINFO_REQUEST',
  GETINFO_SUCCESS: 'PDI_GETINFO_SUCCESS',
  GETINFO_FAILURE: 'PDI_GETINFO_FAILURE',

  ADD_GPS_POSITION_REQUEST: 'ADD_GPS_POSITION_REQUEST',
  ADD_GPS_POSITION_SUCCESS: 'ADD_GPS_POSITION_SUCCESS',
  ADD_GPS_POSITION_FAILURE: 'ADD_GPS_POSITION_FAILURE',

  CLEAR: 'PDI_CLEAR',
  CLEAR_RANGEDATE: 'PDI_CLEAR_RANGEDATE',
};

export default pdiConstants;
