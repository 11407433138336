import React from 'react';
import SvgFraudeVert from '../SvgComponents/Alarme_Fraude_Vert';
import SvgFuiteBleu from '../SvgComponents/Alarme_Fuite_Bleu';
import SvgRetourEauBleu from '../SvgComponents/Alarme_RetourEau_Bleu';
import SvgArretCompteurBleu from '../SvgComponents/Alarme_ArretCompteur_Bleu';
import SvgBatterieFaibleBleu from '../SvgComponents/Alarme_BatterieFaible_Bleu';
import SvgHorlogeVert from '../SvgComponents/Alarme_Horloge_Vert';
import SvgErreurInstallBleu from '../SvgComponents/Alarme_ErreurInstall_Bleu';
import SvgSurDebitVert from '../SvgComponents/Alarme_Surdebit_Vert';
import SvgSousDebitBleu from '../SvgComponents/Alarme_SousDebit_Bleu';

export default function getC(key, props) {
  switch (key) {
    case 'Fraud':
      return <SvgFraudeVert {...props} />;
    case 'Leak':
      return <SvgFuiteBleu {...props} />;
    case 'Backflow':
      return <SvgRetourEauBleu {...props} />;
    case 'MeterBlock':
      return <SvgArretCompteurBleu {...props} />;
    case 'Battery':
      return <SvgBatterieFaibleBleu {...props} />;
    case 'Clock':
      return <SvgHorlogeVert {...props} />;
    case 'ReversedMeter':
      return <SvgErreurInstallBleu {...props} />;
    case 'Overflow':
      return <SvgSurDebitVert {...props} />;
    case 'Underflow':
      return <SvgSousDebitBleu {...props} />;
    default:
      break;
  }
}
