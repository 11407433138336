import React, { useEffect, useRef, useState } from 'react';
import filterFactory, { customFilter, FILTER_TYPES, selectFilter } from 'react-bootstrap-table2-filter';
import _ from 'lodash';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getFiltersRequest } from '../QueryBuilder/FilterLSManager';
import { customExportActions, pdiActions } from '../_actions';
import ListTools from '../List/ListTools';
import CustomInput from '../List/CustomInput';
import CustomInputText from '../List/CustomInputText';
import OptionsFilterList from '../_shared/OptionsFilterList';
import { translateBasicAlarm } from '../_helpers/locale-helpers';
import locale from '../_shared/Locale.json';
import CustomExportFillFiltersModal from '../CustomExport/CustomExportFillFiltersModal';
import './style.scss';

interface Props {
  pdis: Array<any>;
  displayingColumns: Array<any>;
  clickOnRow: Function;
  rowStyle: any;
  keyStorage: string;
  match: any;
  dispatch: any;
  ruleList: any;
  exportCsvCallback: Function;
  exportDatCallback: Function;
  ascMode: boolean;
  t: Function;
}

function RemoteTable(props: Props) {
  const {
    pdis,
    displayingColumns,
    clickOnRow,
    rowStyle,
    keyStorage,
    ruleList,
    exportCsvCallback,
    exportDatCallback,
    match,
    ascMode,
    t,
    customexport,
    optionOr,
  } = props;

  const [etatFastFilters, setEtatFastFilters] = useState<String[]>([]);
  const [fastFilters, setFastFilters] = useState({});
  const [pagination, setPagination] = useState({
    page: parseInt(localStorage.getItem(`${keyStorage}-page`) || '1', 10),
    sizePerPage: parseInt(localStorage.getItem(`${keyStorage}-sizeperpage`) || '10', 10),
  });
  const [sortOptions, setSortOptions] = useState({});
  const [launchRequest, setLaunchRequest] = useState(0);
  const firstUpdate = useRef(true);
  const lock = useRef(false);
  const [ruleListState, setRuleListState] = useState([]);
  const [optionOrState, setOptionOrState] = useState(false);
  const focus = useRef(null);
  const [exportOpen, setExportOpen] = useState(false);
  const [customExportOpen, setCustomExportOpen] = useState(false);
  const [selectedExport, setSelectedExport] = useState(null);

  const toggle = () => setExportOpen(!exportOpen);
  const toggleCustomExportOpen = () => setCustomExportOpen(!customExportOpen);

  useEffect(() => {
    if (firstUpdate.current) return;
    if (!_.isEqual(ruleList, ruleListState) || !_.isEqual(optionOr, optionOrState)) {
      setRuleListState(ruleList);
      setOptionOrState(optionOr);
      setPagination({ page: 1, sizePerPage: pagination.sizePerPage });
      setLaunchRequest(launchRequest + 1);
    }
  }, [ruleList]);

  useEffect(() => {
    if (etatFastFilters.length > 0) {
      localStorage.setItem(`${keyStorage}-fast`, JSON.stringify(fastFilters));
    } else if (launchRequest != 0 && localStorage.getItem(`${keyStorage}-fast`) != null) {
      localStorage.removeItem(`${keyStorage}-fast`);
    }
  }, [fastFilters]);

  const getContext = () => {
    if (keyStorage.includes('RDO')) {
      return 'radio';
    }
    return null;
  };

  useEffect(() => {
    const {
      dispatch,
      match: {
        params: { locationId },
      },
    } = props;
    const fastFiltersRequest: { [key: string]: string } = {};
    if (_.size(fastFilters) > 0) {
      _.entries(fastFilters).forEach((it: Array<any>) => {
        fastFiltersRequest[it[0]] = it[1].filterVal;
      });
    }
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const context = getContext();
    dispatch(
      pdiActions.getRemotePdi(
        locationId,
        getFiltersRequest(keyStorage),
        pagination.sizePerPage,
        pagination.page,
        {
          field: sortOptions.sortField,
          way: sortOptions.sortOrder,
        },
        fastFiltersRequest,
        null,
        ascMode,
        launchRequest,
        !(localStorage.getItem(`${keyStorage}_optionor`) === 'true'),
        context
      )
    );
    lock.current = false;
    focus.current = null;
  }, [launchRequest]);

  useEffect(() => {
    const doc = document.getElementById('pdi-tab');
    if (doc) {
      doc.addEventListener(
        'scroll',
        e => {
          e.preventDefault();
        },
        { passive: true }
      );
    }
    const mountFastFilters = localStorage.getItem(`${keyStorage}-fast`);
    if (mountFastFilters) {
      setFastFilters(JSON.parse(mountFastFilters));
      setEtatFastFilters(transformFastFilterToList(JSON.parse(mountFastFilters)));
      setLaunchRequest(launchRequest + 1);
    }
  }, []);

  function transformFastFilterToList(fastFiltersDict: { [key: string]: any }): Array<string> {
    return _.entries(fastFiltersDict).map(([key, val]) => `${key}${val.filterVal}`);
  }

  function onFilterChange(filters) {
    const currentEtatFastFilters = transformFastFilterToList(filters);

    if (!_.isEqual(currentEtatFastFilters, etatFastFilters)) {
      setEtatFastFilters(currentEtatFastFilters);
      setFastFilters(filters);
      setLaunchRequest(launchRequest + 1);
      lock.current = true;
      return true;
    }
    lock.current = false;
    return false;
  }

  function onSortChange(sortField, sortOrder) {
    if (sortField !== sortOptions.sortField || sortOrder !== sortOptions.sortOrder) {
      setSortOptions({ sortField, sortOrder });
      setLaunchRequest(launchRequest + 1);
      lock.current = true;
    } else {
      lock.current = false;
    }
  }

  function onPaginationChange(page, sizePerPage) {
    if (page !== pagination.page || sizePerPage !== pagination.sizePerPage) {
      setPagination({ page, sizePerPage });
      setLaunchRequest(launchRequest + 1);
      localStorage.setItem(`${keyStorage}-page`, page);
      localStorage.setItem(`${keyStorage}-sizeperpage`, sizePerPage);
    }
  }

  function onTableChange(typeChangeTable: string, tableOptions: any) {
    const {
      pdis: { loadingFilters },
    } = props;
    const { sortField, sortOrder, filters, page, sizePerPage } = tableOptions;
    if (loadingFilters) return;
    // eslint-disable-next-line default-case
    switch (typeChangeTable) {
      case 'filter':
        if (!lock.current) {
          if (onFilterChange(filters)) {
            onPaginationChange(1, pagination.sizePerPage);
          }
        }
        break;
      case 'sort':
        if (!lock.current) {
          if (focus.current === null) {
            onSortChange(sortField, sortOrder);
          }
        }
        break;
      case 'pagination':
        if (!lock.current) {
          onPaginationChange(page, sizePerPage);
        }
        break;
    }
  }

  function generateFilter(dataField: string, labelField: string) {
    const { user } = props;
    const type = ListTools.findSpecificType(dataField);
    const commonProperties = {
      className: 'remote-table-column',
      defaultValue: _.get(fastFilters, `['${dataField}'].filterVal`),
      disabled: pdis.loadingFilters ? 'disabled' : '',
    };
    switch (type) {
      case 'date':
        return customFilter(
          _.assign(
            {
              type: FILTER_TYPES.DATE,
              delay: 3000,
            },
            commonProperties
          )
        );
      case 'checkbox':
        return selectFilter(
          _.assign(
            {
              options: {
                0: dataField === 'state' ? t('all.text.inactive') : t('all.text.no'),
                1: dataField === 'state' ? t('all.text.active') : t('all.text.yes'),
              },
              placeholder: t('all.text.all_plural').toUpperCase(),
            },
            commonProperties
          )
        );
      case 'selectContValue':
        return selectFilter(
          _.assign(
            {
              options: _.get(OptionsFilterList, dataField),
              placeholder: t('all.text.all_plural').toUpperCase(),
            },
            commonProperties
          )
        );
      case 'selectAlarmTypeBasic':
        return selectFilter(
          _.assign(
            {
              options: translateBasicAlarm('fr', locale).sort((a, b) => {
                if (a.label < b.label) return -1;
                if (a.label === b.label) return 0;
                return 1;
              }),
              placeholder: t('all.text.all_plural').toUpperCase(),
            },
            commonProperties
          )
        );
      case 'text':
      case 'number':
        return customFilter(
          _.assign(
            {
              type: FILTER_TYPES.TEXT,
              placeholder: labelField,
              delay: 3000,
            },
            commonProperties
          )
        );
    }
  }

  function generateFilterRenderer(dataField: string) {
    const type = ListTools.findSpecificType(dataField);
    switch (type) {
      case 'date':
        return (onFilter: any, column: any) => <CustomInput onFilter={onFilter} column={column} />;
      case 'text':
      case 'number':
        return (onFilter: any, column: any) => (
          <CustomInputText
            onFilter={onFilter}
            column={column}
            onFocus={e => (focus.current = e)}
            onBlur={() => (focus.current = null)}
            keyField={dataField}
          />
        );
    }
  }

  function customExportFullyFilled(customExport: any, filterValues: any) {
    return _.isEmpty(
      customExport.exportFilters.filter(el => el.defineLater).filter(el => !_.has(filterValues, el.num))
    );
  }

  function launchCustomExport(exportId: number, filterValues: any = {}) {
    const {
      dispatch,
      match: {
        params: { locationId },
      },
      locations,
    } = props;
    if (customExportFullyFilled(customexport.items.find(el => el.exportId === exportId), filterValues)) {
      dispatch(
        customExportActions.launchCustomExport(exportId, _.get(locations, 'fetchedLocation.code'), filterValues)
      );
      setCustomExportOpen(false);
    } else {
      setCustomExportOpen(true);
    }
    setSelectedExport(customexport.items.find(el => el.exportId === exportId));
  }

  const withFastFilter = /(pdi|meters|radios)\/?$/.test(match.path);
  const withExport = /(pdi)\/?$/.test(match.path);

  const completeColumns = _.cloneDeep(displayingColumns).map(it =>
    _.assign(
      {
        dataField: it.dataField,
        text: it.text,
        default: it.default || false,
        filter: withFastFilter && !/mask/i.test(it.dataField) ? generateFilter(it.dataField, it.text) : null,
        headerStyle: () => ({ width: '100%' }),
        sortCaret: () => {
          return <span className={sortOptions.sortField === it.dataField ? `ordered-${sortOptions.sortOrder}` : ''} />;
        },
        sort: withFastFilter && !/mask/i.test(it.dataField),
        classes: it.dataField === 'address.concatAdress' ? '' : 'crystalList-column',
        formatter: ListTools.findFormatter(it.dataField),
      },
      withFastFilter && generateFilterRenderer(it.dataField)
        ? { filterRenderer: (withFastFilter && generateFilterRenderer(it.dataField)) || it.filterRenderer }
        : {},
      /date/.test(it.dataField) && withFastFilter ? { sortFunc: ListTools.sortTableDates } : {}
    )
  );

  const arrowClick = (e: any, direction: String) => {
    e.preventDefault();
    const doc: any = document.getElementById('pdi-tab');
    const bounding = doc.getBoundingClientRect();
    if (direction === 'right') {
      doc.scrollTo(doc.scrollLeft + Math.round(bounding.width * 0.2), 0);
    } else {
      doc.scrollLeft -= Math.round(bounding.width * 0.2);
    }
  };

  const exportProcessing =
    customexport.export_processing || pdis.loadingFilters || _.get(pdis, 'processing') || _.get(pdis, 'processingDat');
  return (
    <div style={{ maxHeight: 'calc(100% - 40px)', display: 'flex', flexDirection: 'column' }}>
      <div id="remotetable-export">
        {(exportCsvCallback || exportDatCallback) && withExport && (
          <span style={{ marginRight: 10, fontSize: '15 px', fontWeight: 'bold' }}>{t('all.text.export_to')} :</span>
        )}
        {exportCsvCallback && withExport && (
          <Button
            outline
            color="secondary"
            style={{ marginBottom: '10px', marginRight: '10px' }}
            onClick={() => exportCsvCallback(fastFilters, sortOptions)}
            disabled={exportProcessing ? 'disabled' : ''}
          >
            {_.get(pdis, 'processing') && <Spinner size="sm" />} CSV
          </Button>
        )}
        {exportDatCallback && withExport && (
          <Button
            outline
            color="secondary"
            style={{ marginBottom: '10px' }}
            onClick={() => exportDatCallback(fastFilters, sortOptions)}
            disabled={exportProcessing ? 'disabled' : ''}
          >
            {_.get(pdis, 'processingDat') && <Spinner size="sm" />} DAT
          </Button>
        )}
        {withExport && _.size(customexport.items) > 0 && (
          <>
            {selectedExport && customExportOpen && (
              <CustomExportFillFiltersModal
                launchCustomExport={launchCustomExport}
                isOpen={customExportOpen}
                show={toggleCustomExportOpen}
                customExport={selectedExport}
              />
            )}
            <UncontrolledDropdown>
              <DropdownToggle disabled={exportProcessing} outline color="secondary" caret>
                {customexport.export_processing && <Spinner size="sm" />}{' '}
                {t('custom_export.title.custom_export_plural')}
              </DropdownToggle>
              <DropdownMenu>
                {customexport.items.map(el => (
                  <DropdownItem onClick={() => launchCustomExport(el.exportId)}>{el.exportName}</DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        )}
      </div>
      <div id="scrollable-table">
        <BootstrapTable
          id="pdi-tab"
          keyField="id"
          data={!pdis.loadingFilters ? pdis.items : []}
          key={Math.floor(Math.random() * Math.floor(1024))}
          style={{ marginBottom: '70px' }}
          columns={completeColumns}
          bootstrap4
          bordered={false}
          hover
          rowEvents={{ onClick: clickOnRow }}
          rowStyle={rowStyle}
          filter={filterFactory()}
          headerClasses="crystalList-column"
          rowClasses="clickable"
          remote
          sort={{
            dataField: sortOptions.sortField,
            order: sortOptions.sortOrder,
          }}
          pagination={paginationFactory({
            page: pagination.page,
            sizePerPage: pagination.sizePerPage,
            totalSize: pdis.count,
            hidePageListOnlyOnePage: true,
          })}
          onTableChange={onTableChange}
          noDataIndication={() =>
            pdis.loadingFilters ? (
              <Spinner animation="grow" size="lg" role="status" aria-hidden="true" />
            ) : (
              <div>{t('remotereading.text.no_meter_to_display')}</div>
            )
          }
        />
      </div>
    </div>
  );
}

function mapStateToProps(state: any) {
  const { authentication, pdis, locales, vmeters, locations, alert, users, customexport } = state;
  const { user } = authentication;

  return {
    user,
    pdis,
    locales,
    vmeters,
    locations,
    alert,
    users,
    customexport,
  };
}

RemoteTable.defaultProps = { ascMode: false };

export default withTranslation()(withRouter(connect(mapStateToProps)(RemoteTable)));
