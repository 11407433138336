import geolocationcConstants from '../_constants/geolocation.constants';
import { action } from '../_interfaces/action';

export default function geolocation(state = {}, action: action) {
  switch (action.type) {
    case geolocationcConstants.GEOLOCATION_REQUEST:
      return {
        loading: true,
      };
    case geolocationcConstants.GEOLOCATION_FAILURE:
      return {
        error: "Impossible d'obternir la géolocalisation",
      };
    case geolocationcConstants.GEOLOCATION_SUCCESS:
      return {
        position: action.position,
      };
    default:
      return state;
  }
}
