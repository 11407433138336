import React from 'react';

const SvgMessageVert = (props: any) => (
  <svg viewBox="0 0 30.38 27.95" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M26.2,0h-22A4.18,4.18,0,0,0,0,4.18V17.66a4.18,4.18,0,0,0,4.16,4.17V28L13,21.83H26.2a4.18,4.18,0,0,0,4.18-4.17V4.18A4.18,4.18,0,0,0,26.2,0Zm2.4,17.66a2.4,2.4,0,0,1-2.4,2.39H12.39L5.94,24.54V20.05H4.18a2.39,2.39,0,0,1-2.4-2.39V4.18a2.39,2.39,0,0,1,2.4-2.4h22a2.4,2.4,0,0,1,2.4,2.4Z"
        />
        <path fill={props.fill} d="M8.13,6.29H22.25V8.07H8.13Z" />
        <path fill={props.fill} d="M8.13,10.09H22.25v1.78H8.13Z" />
        <path fill={props.fill} d="M8.13,13.88H22.25v1.78H8.13Z" />
      </g>
    </g>
  </svg>
);

export default SvgMessageVert;
