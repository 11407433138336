import React from 'react';

const SvgLastRead = (props: any) => (
  <svg viewBox="0 0 108.84 85.74" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M54.42,24.87a3.69,3.69,0,0,1-3.76-3.6V10.71L29.1,7.25,7.53,10.71V21.27a3.69,3.69,0,0,1-3.77,3.6A3.69,3.69,0,0,1,0,21.27V7.65A3.65,3.65,0,0,1,3.14,4.1l25.34-4a3.63,3.63,0,0,1,1.23,0L55,4.1a3.65,3.65,0,0,1,3.14,3.55V21.27a3.68,3.68,0,0,1-3.76,3.6"
        />
        <path
          fill={props.fill}
          d="M105.08,24.87a3.68,3.68,0,0,1-3.76-3.6V10.71L79.75,7.25,58.19,10.71V21.27a3.77,3.77,0,0,1-7.53,0V7.65A3.65,3.65,0,0,1,53.8,4.1l25.33-4a3.69,3.69,0,0,1,1.24,0L105.7,4.1a3.65,3.65,0,0,1,3.14,3.55V21.27a3.68,3.68,0,0,1-3.76,3.6"
        />
        <path
          fill={props.fill}
          d="M79.75,85.74a3.24,3.24,0,0,1-.62,0l-25.33-4a3.64,3.64,0,0,1-3.14-3.55V64.47a3.76,3.76,0,0,1,7.52,0V75l21.57,3.45L101.32,75V64.47a3.76,3.76,0,0,1,7.52,0V78.09a3.64,3.64,0,0,1-3.14,3.55l-25.33,4a3.24,3.24,0,0,1-.62,0"
        />
        <path
          fill={props.fill}
          d="M29.09,85.74a3.31,3.31,0,0,1-.62,0l-25.32-4A3.65,3.65,0,0,1,0,78.09V64.47a3.68,3.68,0,0,1,3.77-3.6,3.69,3.69,0,0,1,3.76,3.6V75l21.56,3.45L50.66,75V64.47a3.77,3.77,0,0,1,7.53,0V78.09A3.65,3.65,0,0,1,55,81.64l-25.33,4a3.18,3.18,0,0,1-.62,0"
        />
        <path
          fill={props.fill}
          d="M33.78,48.15a8.6,8.6,0,0,1-6.25,2.92,9.67,9.67,0,0,1-8.3-4.23q-3-4.25-3-11.32a20.13,20.13,0,0,1,1.71-8.36,13.85,13.85,0,0,1,4.78-5.89,11.8,11.8,0,0,1,6.84-2.09,11.2,11.2,0,0,1,6.95,2.31,14.87,14.87,0,0,1,4.73,6.65A27.14,27.14,0,0,1,42.89,38v3.27q0,11.81-5.19,18.45T23,66.5h-.92V58.76h.6q5.28,0,7.92-2.73T33.78,48.15Zm-4-4.12c1.74,0,3.1-1.19,4.1-3.57V36.32a16.81,16.81,0,0,0-1.24-7.15c-.82-1.73-1.89-2.6-3.2-2.6s-2.34.86-3.13,2.57a14.84,14.84,0,0,0-1.19,6.26,13.43,13.43,0,0,0,1.24,6.32A3.77,3.77,0,0,0,29.81,44Z"
        />
        <path
          fill={props.fill}
          d="M83.78,48.15a8.6,8.6,0,0,1-6.25,2.92,9.67,9.67,0,0,1-8.3-4.23q-3-4.25-3-11.32a20.13,20.13,0,0,1,1.71-8.36,13.85,13.85,0,0,1,4.78-5.89,11.8,11.8,0,0,1,6.84-2.09,11.2,11.2,0,0,1,7,2.31,14.87,14.87,0,0,1,4.73,6.65A27.14,27.14,0,0,1,92.89,38v3.27q0,11.81-5.19,18.45T73,66.5h-.92V58.76h.6q5.28,0,7.92-2.73T83.78,48.15Zm-4-4.12c1.74,0,3.1-1.19,4.1-3.57V36.32a16.81,16.81,0,0,0-1.24-7.15c-.82-1.73-1.89-2.6-3.2-2.6s-2.34.86-3.13,2.57a14.84,14.84,0,0,0-1.19,6.26,13.43,13.43,0,0,0,1.24,6.32A3.77,3.77,0,0,0,79.81,44Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgLastRead;
