import React from 'react';
import { MainNavbar } from './index';
import Sidebar from './Sidebar';

/**
 * Rend le layout principal
 *
 * @method MainLayout
 * @param props Props du composant
 */
export default function DeviceLayout(props: any) {
  const { children } = props;
  return (
    <div className="device-wrapper" style={{ height: '100%' }}>
      <section style={{ height: '100%' }} className="device-container">
        {children}
      </section>
    </div>
  );
}
