import React from 'react';
import BillingSupport from './BillingSupport';
import PropertyEditor from './PropertyEditor';
import './Support.scss';

export function SupportImportBilling(props: any) {
  return <BillingSupport maxAuthorizedNbPath="columnSize" {...props} />;
}

export function SupportGeneric(props: any) {
  return <PropertyEditor {...props} />;
}

export function SupportRemoteReading(props: any) {
  return <PropertyEditor maxAuthorizedNbPath="default_format_lora_file_column_number" {...props} />;
}
