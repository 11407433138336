import React from 'react';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import TableauBordBlanc from '../SvgComponents/TableauBordBlanc';
import DetailsSiteBlanc from '../SvgComponents/DetailsSiteBlanc';
import HistoriqueAlarmeRadioBlanc from '../SvgComponents/HistoriqueAlarmeRadioBlanc';
import CompteurBlanc from '../SvgComponents/CompteurBlanc';
import PdiBlanc from '../SvgComponents/PdiBlanc';
import Recherche from '../SvgComponents/LoupeBleu';
import AlarmeVert from '../SvgComponents/AlarmeVert';
import { getActiveClassByPath } from '../_helpers';
import { User } from '../_entities/user';
import SvgMasqueVert from '../SvgComponents/Masque_Vert';
import TourneesVertes from '../SvgComponents/TourneesVertes';
import SvgStatistiqueVert from '../SvgComponents/StatistiqueVert';
import LocationModal from './LocationModal';
import SvgReport from '../SvgComponents/Report';
import SvgExportBrut from '../SvgComponents/ExportBrut';
import SvgExportPersonalise from '../SvgComponents/ExportPersonalise';
import Capteur from '../SvgComponents/Capteur';
import locationActions from '../_actions/location.actions';

interface Props {
  user: User;
  locations: any;
  location: any;
  t: any;
}

interface State {
  isOpen: boolean;
}

class SidebarLocationComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    const { params } = match;
    dispatch(locationActions.get(params.locationId));
  }

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  getFontSize(text: string) {
    const sizeText = text.length;
    let fontSize = '';
    if (sizeText > 21) {
      fontSize = '10px';
    } else if (sizeText > 18) {
      fontSize = '12px';
    } else if (sizeText > 15) {
      fontSize = '15px';
    }

    return fontSize;
  }

  render() {
    const { isOpen } = this.state;
    const { locations, location, t, user } = this.props;
    return (
      <div className="sidebar-location">
        <Navbar className="sidebar-location-wrapper" dark color="dark" expand="md">
          {locations.fetchedLocation && (
            <div>
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav navbar>
                  <NavbarBrand
                    id="nameOfLocation"
                    href={`/locations/${locations.fetchedLocation.id}`}
                    className="center"
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      fontSize: this.getFontSize(locations.fetchedLocation.name),
                    }}
                  >
                    {locations.fetchedLocation.name}
                  </NavbarBrand>
                  <NavItem>
                    <NavLink
                      className={getActiveClassByPath(location.pathname, 'details')}
                      href={`/locations/${locations.fetchedLocation.id}`}
                    >
                      <DetailsSiteBlanc
                        fill="currentcolor"
                        stroke="currentcolor"
                        height="1em"
                        width="1em"
                        style={{ marginRight: '5px' }}
                      />
                      {locations.fetchedLocation.isTournee
                        ? t('all.round.round_follow_up')
                        : t('all.round.location_follow_up')}
                    </NavLink>
                  </NavItem>
                  {locations.fetchedLocation.isTournee && (
                    <>
                      <NavItem>
                        <NavLink
                          className={getActiveClassByPath(location.pathname, 'fiche')}
                          href={`/locations/${locations.fetchedLocation.id}/fiche`}
                        >
                          <TourneesVertes
                            fill="currentcolor"
                            stroke="currentcolor"
                            height="1em"
                            width="1em"
                            style={{ marginRight: '5px' }}
                          />
                          {t('sidebar_location.nav_link.record_plural')}
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={getActiveClassByPath(location.pathname, 'statistique')}
                          href={`/locations/${locations.fetchedLocation.id}/statistique`}
                        >
                          <SvgStatistiqueVert
                            fill="currentcolor"
                            stroke="currentcolor"
                            height="1em"
                            width="1em"
                            style={{ marginRight: '5px' }}
                          />
                          {t('sidebar_location.nav_link.stat_plural')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={getActiveClassByPath(location.pathname, 'report')}
                          href={`/locations/${locations.fetchedLocation.id}/report`}
                        >
                          <SvgReport
                            fill="currentcolor"
                            stroke="currentcolor"
                            height="1.5em"
                            width="1.5em"
                            style={{ marginLeft: '-3px' }}
                          />
                          <span style={{ paddingLeft: '2px' }}>Rapport</span>
                        </NavLink>
                      </NavItem>
                    </>
                  )}
                  <NavItem>
                    <NavLink
                      className={getActiveClassByPath(location.pathname, 'dashboards')}
                      href={`/locations/${locations.fetchedLocation.id}/dashboards`}
                    >
                      <TableauBordBlanc
                        fill="currentcolor"
                        stroke="currentcolor"
                        height="1em"
                        width="1em"
                        style={{ marginRight: '5px' }}
                      />
                      {t('sidebar_location.nav_link.dashboard_plural')}
                    </NavLink>
                  </NavItem>
                  {!locations.fetchedLocation.isTournee && (
                    <>
                      <NavItem>
                        <NavLink
                          className={getActiveClassByPath(location.pathname, 'alerts')}
                          href={`/locations/${locations.fetchedLocation.id}/alerts`}
                        >
                          <AlarmeVert
                            fill="currentcolor"
                            stroke="currentcolor"
                            height="1em"
                            width="1em"
                            style={{ marginRight: '5px' }}
                          />
                          {t('sidebar_location.nav_link.alert_plural')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={getActiveClassByPath(location.pathname, 'mask')}
                          href={`/locations/${locations.fetchedLocation.id}/mask`}
                        >
                          <SvgMasqueVert
                            fill="currentcolor"
                            stroke="currentcolor"
                            height="1em"
                            width="1em"
                            style={{ marginRight: '5px' }}
                          />
                          {t('sidebar_location.nav_link.mask_alarm_plural')}
                        </NavLink>
                      </NavItem>
                    </>
                  )}
                  <NavItem>
                    <NavLink
                      className={getActiveClassByPath(location.pathname, 'meters')}
                      href={`/locations/${locations.fetchedLocation.id}/meters`}
                    >
                      <CompteurBlanc
                        fill="currentcolor"
                        stroke="currentcolor"
                        height="1em"
                        width="1em"
                        style={{ marginRight: '5px' }}
                      />
                      {t('sidebar_location.nav_link.meter_plural')}
                    </NavLink>
                  </NavItem>
                  {!locations.fetchedLocation.isTournee && (
                    <NavItem>
                      <NavLink
                        className={getActiveClassByPath(location.pathname, 'virtuals')}
                        href={`/locations/${locations.fetchedLocation.id}/virtuals`}
                      >
                        <CompteurBlanc
                          fill="#2c303b"
                          stroke="currentcolor"
                          height="1em"
                          width="1em"
                          style={{ marginRight: '5px' }}
                        />
                        {t('sidebar_location.nav_link.virtual_meter_plural')}
                      </NavLink>
                    </NavItem>
                  )}
                  <NavItem>
                    <NavLink
                      className={getActiveClassByPath(location.pathname, 'radios')}
                      href={`/locations/${locations.fetchedLocation.id}/radios`}
                    >
                      <HistoriqueAlarmeRadioBlanc
                        fill="currentcolor"
                        stroke="currentcolor"
                        height="1em"
                        width="1em"
                        style={{ marginRight: '5px' }}
                      />
                      {t('sidebar_location.nav_link.radio')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      fill="currentcolor"
                      stroke="currentcolor"
                      height="1em"
                      width="1em"
                      className={getActiveClassByPath(location.pathname, 'pdi')}
                      href={`/locations/${locations.fetchedLocation.id}/pdi`}
                    >
                      <PdiBlanc
                        fill="currentcolor"
                        stroke="currentcolor"
                        height="1em"
                        width="1em"
                        style={{ marginRight: '5px' }}
                      />
                      {t('sidebar_location.nav_link.pdi').toUpperCase()}
                    </NavLink>
                  </NavItem>
                  {!locations.fetchedLocation.isTournee && (
                    <NavItem>
                      <NavLink
                        className={getActiveClassByPath(location.pathname, '/sensor')}
                        href={`/locations/${locations.fetchedLocation.id}/sensor`}
                      >
                        <Capteur
                          fill="currentcolor"
                          stroke="currentcolor"
                          height="1.2em"
                          width="1.2em"
                          style={{ marginRight: '5px' }}
                        />
                        {t('sensor.text.sensor_plural')}
                      </NavLink>
                    </NavItem>
                  )}
                  {!locations.fetchedLocation.isTournee && user.role.name === 'DIOPTASE' && (
                    <NavItem>
                      <NavLink
                        className={getActiveClassByPath(location.pathname, '/export')}
                        href={`/locations/${locations.fetchedLocation.id}/export`}
                      >
                        <SvgExportBrut
                          fill="currentcolor"
                          stroke="currentcolor"
                          height="1em"
                          width="1em"
                          style={{ marginRight: '5px' }}
                        />
                        {t('bread_crumb.name.export_brut')}
                      </NavLink>
                    </NavItem>
                  )}
                  <NavItem>
                    <NavLink
                      className={getActiveClassByPath(location.pathname, '/customexport')}
                      href={`/locations/${locations.fetchedLocation.id}/customexport`}
                    >
                      <SvgExportPersonalise
                        fill="currentcolor"
                        stroke="currentcolor"
                        height="1em"
                        width="1em"
                        style={{ marginRight: '5px' }}
                      />
                      {t('custom_export.title.custom_export_plural')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={getActiveClassByPath(location.pathname, 'search')}
                      href={`/locations/${locations.fetchedLocation.id}/search`}
                    >
                      <Recherche
                        fill="currentcolor"
                        stroke="currentcolor"
                        height="1em"
                        width="1em"
                        style={{ marginRight: '5px' }}
                      />
                      {t('sidebar_location.nav_link.search')}
                    </NavLink>
                  </NavItem>
                  <LocationModal {...this.props} buttonSize="md" mini={false} />
                </Nav>
              </Collapse>
            </div>
          )}
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, dashboards, locations } = state;
  const { user } = authentication;
  return {
    user,
    dashboards,
    locations,
  };
}

const mapping: any = connect(mapStateToProps)(SidebarLocationComponent);

const connectedSidebarLocation = withRouter(mapping);
const tr = withTranslation()(connectedSidebarLocation);
export default tr;
