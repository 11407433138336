import React from 'react';

const SvgFluidAutre = (props: any) => (
  <svg viewBox="0 0 50.79 79.55" {...props}>
    <path
      fill={props.fill}
      d="M23.34,57.25a6.71,6.71,0,0,1-6.42-8.78,11,11,0,0,1,1-2.26q2.19-3.49,8.7-8.51A28.39,28.39,0,0,0,32.78,31a14.83,14.83,0,0,0,2.29-8.08q0-5-2.62-7.76c-1.74-1.86-4.22-2.8-7.46-2.8a10,10,0,0,0-6.75,2.37,7.48,7.48,0,0,0-2,2.75A6.83,6.83,0,0,1,10,21.65H.11L0,21.33Q-.15,11,6.82,5.49T25,0Q37.07,0,43.92,6.11t6.87,16.62a21.89,21.89,0,0,1-4,12.66,35.84,35.84,0,0,1-10,9.74,12.78,12.78,0,0,0-4.31,5.09A10.36,10.36,0,0,0,31.89,52a6.72,6.72,0,0,1-6.55,5.25Zm1.44,22.3H22.41a6.73,6.73,0,0,1-6.73-6.73h0a6.73,6.73,0,0,1,6.73-6.73h2.37a6.73,6.73,0,0,1,6.73,6.73h0A6.73,6.73,0,0,1,24.78,79.55Z"
    />
  </svg>
);

export default SvgFluidAutre;
