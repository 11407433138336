import { colorConstants } from '../_constants';
import Colors from '../_shared/Colors.json';

/**
 * Charge les profils de couleurs
 *
 * @method load
 * @returns {Object} Le fichier de couleurs
 */
function load() {
  return {
    type: colorConstants.LOADED,
    color: Colors,
  };
}

const colorActions = {
  load,
};

export default colorActions;
