import JwtDecode from 'jwt-decode';
import { authHeader, handleResponse, logout } from '../_helpers';
import { PermissionDtoForCreation, User } from '../_entities/user';

function handleToken(response: any) {
  return response.text().then((text: any) => {
    const data = text;
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return response.headers.get('Authorization').replace('Bearer ', '');
  });
}

async function changeHomepage(userId: number, urlHomepage: string) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: urlHomepage,
  };
  const response = await fetch(`/api/user/${userId}/homepage/`, requestOptions);
  return handleResponse(response);
}

async function login(username: string, password: string) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  };
  const response = await fetch('/login', requestOptions);
  const token = await handleToken(response);
  // login successful if there's a jwt token in the response
  if (token) {
    // store jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('token', token);
    let ret: any = JwtDecode(token);
    // Prendre les permissions
    return ret.user;
  }
  return null;
}

async function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch('/api/user/users/', requestOptions);
  return handleResponse(response);
}

async function get(id: any) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/user/${id}/`, requestOptions);
  return handleResponse(response);
}

async function update(user: User) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(user),
  };
  console.log('UPDATE USER : ', user);
  let response = await fetch('/api/user/', requestOptions);
  return handleResponse(response);
}

async function updateWithPassword(user: User) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(user),
  };
  let response = await fetch('/api/user/password', requestOptions);
  return handleResponse(response);
}

async function save(user: User) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(user),
  };
  let response = await fetch('/api/user/', requestOptions);
  return handleResponse(response);
}

async function saveTourmaline(user: string) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: user,
  };
  console.log(requestOptions);
  let response = await fetch('/api/user/tourmaline', requestOptions);
  return handleResponse(response);
}

async function toDelete(id: number) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  let response = await fetch(`/api/user/${id}`, requestOptions);
  return handleResponse(response);
}

async function getPermissions(userId: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/user/${userId}/permissions`, requestOptions);
  return handleResponse(response);
}

async function getLastUpdatedInfo(userId: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/user/${userId}/lastUpdatedInfo`, requestOptions);
  return handleResponse(response);
}

async function updateOrSavePermission(permission: PermissionDtoForCreation, userId: number) {
  let response = null;
  if (null != permission && null != permission.id && permission.id > 0) {
    const requestOptions = {
      method: 'PUT',
      headers: authHeader(),
      body: JSON.stringify(permission),
    };
    response = await fetch(`/api/user/${userId}/permissions/${permission.id}`, requestOptions);
  } else {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(permission),
    };
    response = await fetch(`/api/user/${userId}/permissions/`, requestOptions);
  }
  return handleResponse(response);
}

async function toDeletePermission(id: number, userId: number) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  const response = await fetch(`/api/user/${userId}/permissions/${id}`, requestOptions);
  return handleResponse(response);
}

async function getResetTokenInfo(token: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/password/token/${token}`, requestOptions);
  return handleResponse(response);
}

async function generateToken(email: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/password/${email}/token`, requestOptions);
  return handleResponse(response);
}

async function existEmail(email: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/user/existEmail/${email}`, requestOptions);
  return handleResponse(response);
}

async function blockOrUnblock(id: number) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };
  const response = await fetch(`/api/user/${id}/blockOrUnblock`, requestOptions);
  return handleResponse(response);
}

async function reinit(id: number) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };
  const response = await fetch(`/api/user/${id}/reinit`, requestOptions);
  return handleResponse(response);
}

async function checkPermission(keyPermission: string, valuePermission: any) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/user/permission/${keyPermission}/${valuePermission}`, requestOptions);
  return handleResponse(response);
}

const userService = {
  login,
  logout,
  getAll,
  get,
  update,
  updateWithPassword,
  toDelete,
  save,
  getLastUpdatedInfo,
  getPermissions,
  updateOrSavePermission,
  toDeletePermission,
  getResetTokenInfo,
  generateToken,
  existEmail,
  blockOrUnblock,
  reinit,
  saveTourmaline,
  changeHomepage,
  checkPermission,
};

export default userService;
