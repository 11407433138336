import { authHeader, handleResponse } from '../_helpers';

async function getAll(siteId: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/radio/list/${siteId}/`, requestOptions);
  return handleResponse(response);
}

async function getAllWithChildren(siteId: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/radio/list/${siteId}/children`, requestOptions);
  return handleResponse(response);
}

async function getInfos(siteId: number, param: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/radio/${siteId}/infos${param}`, requestOptions);
  return handleResponse(response);
}

async function getStatements(siteId: number, content: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(content),
  };
  let response = await fetch(`/api/meter/${siteId}/reads`, requestOptions);
  return handleResponse(response);
}

async function getAlarms(siteId: number, content: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(content),
  };
  let response = await fetch(`/api/meter/${siteId}/alarms`, requestOptions);
  return handleResponse(response);
}

async function getRadioInfoWithSerial(serial: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/radio/${serial}`, requestOptions);
  return handleResponse(response);
}

const radioService = {
  getAll,
  getAllWithChildren,
  getInfos,
  getStatements,
  getAlarms,
  getRadioInfoWithSerial,
};

export default radioService;
