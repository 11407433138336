import React from 'react';

const SvgCapteur = (props: any) => (
  <svg viewBox="0 0 51 72.9" {...props}>
    <g id="Calque_2" />
    <g id="Calque_1">
      <g>
        <g>
          <path
            fill={props.fill}
            d="M18.3,0.9c-4.1,1.1-7.7,3-10.7,5.6c-0.7,0.6-1,1.4-1,2.3c0,0.7,0.2,1.4,0.7,2c1.1,1.3,3,1.4,4.3,0.3
				c2.3-2,5.1-3.5,8.3-4.4c1.2-0.3,2.5-0.5,3.7-0.6c5.9-0.5,11.6,1.7,15.8,5.6c1.2,1.1,3.1,1.1,4.3-0.1c0.6-0.6,0.8-1.3,0.8-2.1
				c0-0.1,0-0.1,0-0.2c-0.1-0.7-0.4-1.4-1-2c-5.4-5-12.7-7.8-20.4-7.2C21.5,0.2,19.9,0.5,18.3,0.9z"
          />
          <path
            fill={props.fill}
            d="M23.8,10c-1,0.1-2,0.2-3,0.5c-2.5,0.6-4.7,1.8-6.5,3.4c-0.7,0.6-1.1,1.5-1.1,2.3c0,0.7,0.2,1.4,0.7,1.9
				c1.1,1.3,3,1.5,4.3,0.4c1.2-1,2.6-1.7,4.2-2.1c0.6-0.2,1.3-0.3,1.9-0.3c2.7-0.2,5.3,0.6,7.4,2.2c1.3,1,3.2,0.8,4.3-0.5
				c0.4-0.5,0.6-1.2,0.6-1.8c0-0.1,0-0.2,0-0.2c-0.1-0.8-0.5-1.6-1.2-2.2C32.2,10.9,28.1,9.6,23.8,10z"
          />
        </g>
        <g>
          <path
            fill={props.fill}
            d="M5,31.1v32.5c0,2.3,1.9,4.2,4.2,4.2h32.5c2.3,0,4.2-1.9,4.2-4.2V31.1c0-2.3-1.9-4.2-4.2-4.2H9.2
				C6.9,26.9,5,28.8,5,31.1z M33.6,34.6c2.5,0,4.6,2.1,4.6,4.6v16.3c0,2.5-2.1,4.6-4.6,4.6H17.3c-2.5,0-4.6-2.1-4.6-4.6V39.2
				c0-2.5,2.1-4.6,4.6-4.6H33.6z"
          />
          <path
            fill={props.fill}
            d="M0,31.1v32.5c0,5.1,4.1,9.2,9.2,9.2h32.5c5.1,0,9.2-4.1,9.2-9.2V31.1c0-5.1-4.1-9.2-9.2-9.2H9.2
				C4.1,21.9,0,26.1,0,31.1z M41.7,26.9c2.3,0,4.2,1.9,4.2,4.2v32.5c0,2.3-1.9,4.2-4.2,4.2H9.2C6.9,67.9,5,66,5,63.7V31.1
				c0-2.3,1.9-4.2,4.2-4.2H41.7z"
          />
          <g>
            <g>
              <path
                fill={props.fill}
                d="M17.3,57.7c-1.2,0-2.1-1-2.1-2.1V39.2c0-1.2,1-2.1,2.1-2.1h16.3c1.2,0,2.1,1,2.1,2.1v16.3
						c0,1.2-1,2.1-2.1,2.1H17.3z M25.7,39.2c-4.5,0-8.2,3.7-8.2,8.2c0,4.5,3.7,8.2,8.2,8.2c4.5,0,8.2-3.7,8.2-8.2
						C33.9,42.9,30.2,39.2,25.7,39.2z"
              />
            </g>
            <g>
              <path
                fill={props.fill}
                d="M33.3,39.6v0.2c-0.1-0.1-0.2-0.2-0.2-0.2H33.3 M18.3,39.6c-0.2,0.2-0.4,0.4-0.6,0.6v-0.6H18.3 M17.7,54.6
						c0.2,0.2,0.4,0.4,0.6,0.6h-0.6V54.6 M33.3,55v0.2H33C33.1,55.1,33.2,55.1,33.3,55 M33.6,34.6H17.3c-2.5,0-4.6,2.1-4.6,4.6v16.3
						c0,2.5,2.1,4.6,4.6,4.6h16.3c2.5,0,4.6-2.1,4.6-4.6V39.2C38.3,36.7,36.2,34.6,33.6,34.6L33.6,34.6z M25.7,53.1
						c-3.2,0-5.7-2.6-5.7-5.7s2.6-5.7,5.7-5.7s5.7,2.6,5.7,5.7S28.8,53.1,25.7,53.1L25.7,53.1z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgCapteur;
