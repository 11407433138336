import React, { useState } from 'react';
import _ from 'lodash';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import sensorActions from '../_actions/sensor.actions';
import SvgPlanification from '../SvgComponents/Planification';
import Ajout from '../SvgComponents/AjoutBleu';

const comparator = (start: string, end: string) => {
  const h1 = parseInt(start.length > 0 ? start.split(':')[0] : '00', 10);
  const h2 = parseInt(end.length > 0 ? end.split(':')[0] : '23', 10);

  const m1 = parseInt(start.length > 0 ? start.split(':')[1] : '00', 10);
  const m2 = parseInt(end.length > 0 ? end.split(':')[1] : '59', 10);

  const diffH = h2 - h1;
  const diffM = m2 - m1;
  if (diffH > 0) {
    return true;
  }
  return diffH > 0 || (diffH === 0 && diffM > 0);
};

const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const StatusPlanifier = (props: any) => {
  const { dispatch, t } = props;

  const [error, setError] = useState('');

  const [monday, setMonday] = useState([]);
  const [tuesday, setTuesday] = useState([]);
  const [wednesday, setWednesday] = useState([]);
  const [thursday, setThursday] = useState([]);
  const [friday, setFriday] = useState([]);
  const [saturday, setSaturday] = useState([]);
  const [sunday, setSunday] = useState([]);

  const displayDelay = (key: string) => {
    switch (key) {
      case 'monday':
        return monday;
      case 'tuesday':
        return tuesday;
      case 'wednesday':
        return wednesday;
      case 'thursday':
        return thursday;
      case 'friday':
        return friday;
      case 'saturday':
        return saturday;
      case 'sunday':
        return sunday;
      default:
        return [];
    }
  };

  const addDelay = (key: string) => {
    let newDay: any = null;
    const dailyList = displayDelay(key);
    const lastPeriod: any = dailyList.length > 0 ? dailyList[dailyList.length - 1] : undefined;
    if (
      error.length > 0 ||
      ((lastPeriod && lastPeriod.start.length === 0) || (lastPeriod && lastPeriod.end.length === 0))
    ) {
      setError(t('sensor.error.correct'));
    } else {
      switch (key) {
        case 'monday':
          newDay = _.cloneDeep(monday);
          newDay.push({ start: '', end: '' });
          setMonday(newDay);
          break;
        case 'tuesday':
          newDay = _.cloneDeep(tuesday);
          newDay.push({ start: '', end: '' });
          setTuesday(newDay);
          break;
        case 'wednesday':
          newDay = _.cloneDeep(wednesday);
          newDay.push({ start: '', end: '' });
          setWednesday(newDay);
          break;
        case 'thursday':
          newDay = _.cloneDeep(thursday);
          newDay.push({ start: '', end: '' });
          setThursday(newDay);
          break;
        case 'friday':
          newDay = _.cloneDeep(friday);
          newDay.push({ start: '', end: '' });
          setFriday(newDay);
          break;
        case 'saturday':
          newDay = _.cloneDeep(saturday);
          newDay.push({ start: '', end: '' });
          setSaturday(newDay);
          break;
        case 'sunday':
          newDay = _.cloneDeep(sunday);
          newDay.push({ start: '', end: '' });
          setSunday(newDay);
          break;
        default:
          return [];
      }
    }
  };

  const removeDelay = (day: string) => {
    switch (day) {
      case 'monday':
        setMonday(monday.filter((el: any, index: number) => index !== monday.length - 1));
        break;
      case 'tuesday':
        setTuesday(tuesday.filter((el: any, index: number) => index !== tuesday.length - 1));
        break;
      case 'wednesday':
        setWednesday(wednesday.filter((el: any, index: number) => index !== wednesday.length - 1));
        break;
      case 'thursday':
        setThursday(thursday.filter((el: any, index: number) => index !== thursday.length - 1));
        break;
      case 'friday':
        setFriday(friday.filter((el: any, index: number) => index !== friday.length - 1));
        break;
      case 'saturday':
        setSaturday(saturday.filter((el: any, index: number) => index !== saturday.length - 1));
        break;
      case 'sunday':
        setSunday(sunday.filter((el: any, index: number) => index !== sunday.length - 1));
        break;
      default:
        return [];
    }
  };

  const setDelay = (value: any, day: string, isStart: boolean, index: number) => {
    let tamp: any = null;
    let newDay: any = null;
    switch (day) {
      case 'monday':
        newDay = _.cloneDeep(monday);
        tamp = newDay[index];
        isStart ? (tamp.start = value) : (tamp.end = value);
        if (index === 0 || !isStart) {
          if (comparator(tamp.start, tamp.end)) {
            newDay[index] = tamp;
            setMonday(newDay);
            setError('');
          } else {
            setError(t('sensor.error.incorrect'));
          }
        } else if (isStart && comparator(newDay[index - 1].end, tamp.start)) {
          newDay[index] = tamp;
          setMonday(newDay);
          setError('');
        } else {
          setError(t('sensor.error.previous'));
        }
        break;
      case 'tuesday':
        newDay = _.cloneDeep(tuesday);
        tamp = newDay[index];
        isStart ? (tamp.start = value) : (tamp.end = value);
        if (index === 0 || !isStart) {
          if (comparator(tamp.start, tamp.end)) {
            newDay[index] = tamp;
            setTuesday(newDay);
            setError('');
          } else {
            setError(t('sensor.error.incorrect'));
          }
        } else if (isStart && comparator(newDay[index - 1].end, tamp.start)) {
          newDay[index] = tamp;
          setTuesday(newDay);
          setError('');
        } else {
          setError(t('sensor.error.previous'));
        }
        break;
      case 'wednesday':
        newDay = _.cloneDeep(wednesday);
        tamp = newDay[index];
        isStart ? (tamp.start = value) : (tamp.end = value);
        if (index === 0 || !isStart) {
          if (comparator(tamp.start, tamp.end)) {
            newDay[index] = tamp;
            setWednesday(newDay);
            setError('');
          } else {
            setError(t('sensor.error.incorrect'));
          }
        } else if (isStart && comparator(newDay[index - 1].end, tamp.start)) {
          newDay[index] = tamp;
          setWednesday(newDay);
          setError('');
        } else {
          setError(t('sensor.error.previous'));
        }
        break;
      case 'thursday':
        newDay = _.cloneDeep(thursday);
        tamp = newDay[index];
        isStart ? (tamp.start = value) : (tamp.end = value);
        if (index === 0 || !isStart) {
          if (comparator(tamp.start, tamp.end)) {
            newDay[index] = tamp;
            setThursday(newDay);
            setError('');
          } else {
            setError(t('sensor.error.incorrect'));
          }
        } else if (isStart && comparator(newDay[index - 1].end, tamp.start)) {
          newDay[index] = tamp;
          setThursday(newDay);
          setError('');
        } else {
          setError(t('sensor.error.previous'));
        }
        break;
      case 'friday':
        newDay = _.cloneDeep(friday);
        tamp = newDay[index];
        isStart ? (tamp.start = value) : (tamp.end = value);
        if (index === 0 || !isStart) {
          if (comparator(tamp.start, tamp.end)) {
            newDay[index] = tamp;
            setFriday(newDay);
            setError('');
          } else {
            setError(t('sensor.error.incorrect'));
          }
        } else if (isStart && comparator(newDay[index - 1].end, tamp.start)) {
          newDay[index] = tamp;
          setFriday(newDay);
          setError('');
        } else {
          setError(t('sensor.error.previous'));
        }
        break;
      case 'saturday':
        newDay = _.cloneDeep(saturday);
        tamp = newDay[index];
        isStart ? (tamp.start = value) : (tamp.end = value);
        if (index === 0 || !isStart) {
          if (comparator(tamp.start, tamp.end)) {
            newDay[index] = tamp;
            setSaturday(newDay);
            setError('');
          } else {
            setError(t('sensor.error.incorrect'));
          }
        } else if (isStart && comparator(newDay[index - 1].end, tamp.start)) {
          newDay[index] = tamp;
          setSaturday(newDay);
          setError('');
        } else {
          setError(t('sensor.error.previous'));
        }
        break;
      case 'sunday':
        newDay = _.cloneDeep(sunday);
        tamp = newDay[index];
        isStart ? (tamp.start = value) : (tamp.end = value);
        if (index === 0 || !isStart) {
          if (comparator(tamp.start, tamp.end)) {
            newDay[index] = tamp;
            setSunday(newDay);
            setError('');
          } else {
            setError(t('sensor.error.incorrect'));
          }
        } else if (isStart && comparator(newDay[index - 1].end, tamp.start)) {
          newDay[index] = tamp;
          setSunday(newDay);
          setError('');
        } else {
          setError(t('sensor.error.previous'));
        }
        break;
      default:
        return [];
    }
  };

  const handleSubmit = () => {
    const list = [monday, tuesday, wednesday, thursday, friday, saturday, sunday];
    const obj = Object.fromEntries(
      list
        .map((el: any, index: number) => [
          index.toString(),
          el.map((elem: any) => {
            return {
              startHours: parseInt(elem.start.split(':')[0], 10),
              startMinutes: parseInt(elem.start.split(':')[1], 10),
              endHours: parseInt(elem.end.split(':')[0], 10),
              endMinutes: parseInt(elem.end.split(':')[1], 10),
            };
          }),
        ])
        .filter(([, listTimer]) => _.size(listTimer) > 0)
    );
    dispatch(sensorActions.putUpLinkScheduleTime(1, obj));
  };

  return (
    <div style={{ display: 'flex' }}>
      <div
        className="col-md-12 downlink-container tele-releve-container"
        id="tab-container"
        style={{ flex: '0 0 100%' }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="tele-releve-header flex-box">
            <SvgPlanification fill="#31c6b3" height="2em" />
            <h2 style={{ marginLeft: '20px' }}>{t('sensor.title.open_planned')}</h2>
          </div>
        </div>
        <div className="wrapper">
          {days.map((el: any) => (
            <div className="section">
              <div className="span-container">
                <span>{t(`all.text.${el}`)}</span>
              </div>
              <div className="flex-box">
                {displayDelay(el).map((delay: any, index: number) => (
                  <div className="delay">
                    <input
                      type="time"
                      name="appt"
                      min="00:00"
                      max="23:59"
                      required
                      onChange={(e: any) => setDelay(e.target.value, el, true, index)}
                    />
                    <input
                      type="time"
                      name="appt"
                      min="00:00"
                      max="23:59"
                      required
                      onChange={(e: any) => setDelay(e.target.value, el, false, index)}
                    />
                  </div>
                ))}

                <div className="button-group">
                  {displayDelay(el).length > 0 && (
                    <div
                      id={`del-widget-${el}`}
                      className="round clickable delete"
                      role="presentation"
                      onClick={() => removeDelay(el)}
                    >
                      <div className="less" />
                      <UncontrolledTooltip placement="bottom" target={`del-widget-${el}`}>
                        {' '}
                        {t('sensor.text.remove_interval')}
                      </UncontrolledTooltip>
                    </div>
                  )}
                  {displayDelay(el).length < 4 && (
                    <div
                      id={`addWidget-${el}`}
                      className="round clickable"
                      role="presentation"
                      onClick={() => addDelay(el)}
                    >
                      <Ajout className="add" fill="curentcolor" />
                      <UncontrolledTooltip placement="bottom" target={`addWidget-${el}`}>
                        {' '}
                        {t('sensor.text.add_interval')}
                      </UncontrolledTooltip>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
          <Button
            color="primary"
            className="float-bottom-right"
            style={{ minWidth: '20ch' }}
            onClick={() => handleSubmit()}
          >
            {t('all.button.register')}
          </Button>{' '}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { sensor } = state;
  return {
    sensor,
  };
}

export default withTranslation()(connect(mapStateToProps)(StatusPlanifier));
