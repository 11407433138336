import React from 'react';
import { withTranslation } from 'react-i18next';
import ComAnimation from './ComAnimation';

const MessageCom = (props: any) => {
  const { message, t } = props;
  return (
    <div className="message-com-container">
      <ComAnimation />
      <h5 style={{ color: '#000' }}>{message ? t(`synchro.command.${message.toLowerCase()}`) : ''}</h5>
    </div>
  );
};

export default withTranslation()(MessageCom);
