import React from 'react';

const SvgDoubleFlecheVert = (props: any) => (
  <svg viewBox="0 0 12.95 13.92" {...props}>
    <g id="double_fleche_vert_svg__Calque_2" data-name="Calque 2">
      <g id="double_fleche_vert_svg__Calque_2-2" data-name="Calque 2">
        <path fill={props.fill} strokeLinecap="round" strokeLinejoin="round" d="M.5.5l6.46 6.46L.5 13.42" />
        <path fill={props.fill} d="M5.99.5l6.46 6.46-6.46 6.46" />
      </g>
    </g>
  </svg>
);

export default SvgDoubleFlecheVert;
