import React from 'react';

const SvgBatterieFaibleBleu = (props: any) => (
  <svg viewBox="0 0 12.29 21.01" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M2.84.91v.71H.91A.91.91,0,0,0,0,2.53V20.11a.9.9,0,0,0,.91.9H11.38a.9.9,0,0,0,.91-.9V2.53a.91.91,0,0,0-.91-.91H9.52V.91A.9.9,0,0,0,8.61,0H3.75a.91.91,0,0,0-.91.91Zm7.63,2.52V19.2H1.81V3.43Z"
        />
        <path fill={props.fill} d="M3.36,12v0l4.32,4.71L5.83,12.32l3.09-1.64L4.6,6l1.85,4.36Z" />
      </g>
    </g>
  </svg>
);

export default SvgBatterieFaibleBleu;
