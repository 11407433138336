import React from 'react';
import List from './List';
import ListTools from './ListTools';

export function ListMeter(props: any) {
  return <List type={ListTools.typeOfList.Meters} {...props} />;
}

export function ListVirtualMeter(props: any) {
  return <List type={ListTools.typeOfList.VMeters} {...props} />;
}

export function ListRadio(props: any) {
  return <List type={ListTools.typeOfList.Radios} {...props} />;
}

export function ListPdi(props: any) {
  return <List type={ListTools.typeOfList.Pdi} {...props} />;
}
