import ColorList from '../_shared/Colors.json';

/**
 * Récupère la couleur de l'élément passé en paramètre
 *
 * @method colorize
 * @param {String} type Type d'élément
 * @param {String} context Contexte de l'élément
 * @param {String} param Paramètre recherché
 * @param {any} colorFile Fichier des couleurs
 */
export default function colorize(type: string, context: string, param: string, colorFile: any) {
  const color = (colorFile || ColorList)[type][context][param];
  return color || param;
}
