import React from 'react';

const SvgFavoriActif = (props: any) => (
  <svg viewBox="0 0 24.24 23.14">
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_2-2" data-name="Calque 2">
        <path
          d="M24.21,8.75a.67.67,0,0,0-.55-.46L16.1,7.21,12.7.37a.68.68,0,0,0-1.21,0L8.14,7.24.57,8.36A.69.69,0,0,0,0,8.82a.67.67,0,0,0,.18.69l5.48,5.31L4.43,22.36a.67.67,0,0,0,.66.78.58.58,0,0,0,.31-.08l6.75-3.57L18.92,23a.71.71,0,0,0,.31.08.68.68,0,0,0,.67-.67.41.41,0,0,0,0-.16l-1.31-7.48L24,9.44a.67.67,0,0,0,.18-.69Z"
          fill={props.fill}
          data-name="Calque 2"
        />
      </g>
    </g>
  </svg>
);

export default SvgFavoriActif;
