import React from 'react';

const SvgHistoriqueDonneesVert = (props: any) => (
  <svg viewBox="0 0 26.13 28" {...props}>
    <g id="historique_donnees_vert_svg__Calque_2" data-name="Calque 2">
      <g id="historique_donnees_vert_svg__Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          d="M25.67 1.87H22.4V.47a.47.47 0 0 0-.47-.47h-3.26a.47.47 0 0 0-.47.47v1.4H7.93V.47A.47.47 0 0 0 7.47 0H4.2a.47.47 0 0 0-.47.47v1.4H.47a.47.47 0 0 0-.47.46v25.2a.47.47 0 0 0 .47.47h25.2a.47.47 0 0 0 .46-.47V2.33a.47.47 0 0 0-.46-.46zM19.13.93h2.34v2.8h-2.34zM4.67.93H7v2.8H4.67zM.93 2.8h2.8v1.4a.47.47 0 0 0 .47.47h3.27a.47.47 0 0 0 .46-.47V2.8H18.2v1.4a.47.47 0 0 0 .47.47h3.26a.47.47 0 0 0 .47-.47V2.8h2.8V7H.93zm0 24.27V7.93H25.2v19.14z"
        />
        <path
          fill={props.fill}
          d="M16.8 10.73H4.2v13.54h17.73V10.73zm-3.27.94h3.27v3.26h-3.27zm3.27 7.46h-3.27v-3.26h3.27zm-7.47-3.26h3.27v3.26H9.33zm0-4.2h3.27v3.26H9.33zm-4.2 0H8.4v3.26H5.13zm0 4.2H8.4v3.26H5.13zm3.27 7.46H5.13v-3.26H8.4zm4.2 0H9.33v-3.26h3.27zm4.2 0h-3.27v-3.26h3.27zm4.2 0h-3.27v-3.26H21zm0-4.2h-3.27v-3.26H21zm0-7.46v3.26h-3.27v-3.26z"
        />
      </g>
    </g>
  </svg>
);

export default SvgHistoriqueDonneesVert;
