import React from 'react';

const SvgSupportHeader = (props: any) => (
  <svg viewBox="0 0 22.81 21.64" {...props}>
    <path
      d="M11.41 0C5.11 0 0 4.08 0 9.13c0 3.43 2.38 6.42 5.89 8H5.7v3.42c0 1.26.64 1.48 1.42.49l2.32-2.91a15.88 15.88 0 0 0 2 .14c6.29 0 11.4-4.09 11.4-9.12S17.7 0 11.41 0zm0 16c-5 0-9.13-3.07-9.13-6.84s4.09-6.85 9.13-6.85 9.12 3.06 9.12 6.85S16.45 16 11.41 16z"
      fill={props.fill}
      data-name="Calque 2"
    />
  </svg>
);

export default SvgSupportHeader;
