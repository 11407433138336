import React from 'react';
import { Button, Col, CustomInput, FormGroup, FormText, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import _debounce from 'lodash.debounce';
import { Location } from '../_entities/location';
import Ajout from '../SvgComponents/AjoutBleu';
import PoubelleBleue from '../SvgComponents/PoubelleBleu';
import confirm from '../_components';
import SvgEditionBleu from '../SvgComponents/EditionBleu';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

interface State {
  identity: {
    name: string;
    parentCode: string;
    parentName: string;
  };
  address: {
    streetNumber: any;
    streetNumberAdditionnal: any;
    street: any;
    complement: any;
    zipcode: any;
    city: any;
    country: any;
  };
  contact: {
    civility: any;
    lastName: any;
    firstName: any;
    phone: any;
    mail: any;
    note: any;
  };
  note: string;
  addParent: boolean;
  fromLocation: number;
  editLocation: number;
  editMode: boolean;
}

/**
 * @class IdentityChooserComponent
 * @extends {React.Component<Props>}
 */
class IdentityChooserComponent extends React.Component<any, State> {
  validateExists = _debounce((value, ctx, input, cb) => {
    const { locations, isEdit } = this.props;
    if (isEdit) {
      cb(true);
    } else {
      if (locations.find((l: Location) => l.name.toLowerCase() === value.trim().toLowerCase())) {
        cb('Ce nom est déjà utilisé. Veuillez en choisir un autre.');
      } else {
        cb(true);
      }
    }
  }, 300);

  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof IdentityChooserComponent
   */
  constructor(props: any) {
    super(props);
    this.send = this.send.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleParentChange = this.handleParentChange.bind(this);
    this.handleParentChoice = this.handleParentChoice.bind(this);
    this.deleteObj = this.deleteObj.bind(this);
    this.goToStep = this.goToStep.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getText = this.getText.bind(this);

    this.state = {
      identity: {
        name: '',
        parentCode: '',
        parentName: '',
      },
      address: {
        street: undefined,
        streetNumber: undefined,
        streetNumberAdditionnal: undefined,
        complement: undefined,
        zipcode: undefined,
        city: undefined,
        country: undefined,
      },
      contact: {
        civility: undefined,
        lastName: undefined,
        firstName: undefined,
        phone: undefined,
        mail: undefined,
        note: undefined,
      },
      note: '',
      addParent: false,
      fromLocation: 0,
    };
  }

  static getDerivedStateFromProps(props: any, state: State) {
    const { form, locations, fromLocation } = props;
    if (form && form.identity && locations) {
      return {
        identity: form.identity,
        address: form.location && form.location.address,
        contact: form.contact,
        note: form.note,
      };
    }

    if (_.isEmpty(state.identity.parentCode)) {
      if (fromLocation && fromLocation > 0) {
        const parentLocation = _.find(locations, { id: fromLocation });
        return {
          identity: {
            name: '',
            parentCode: parentLocation && parentLocation.code,
            parentName: parentLocation && parentLocation.name,
          },
        };
      } else {
        return {
          identity: {
            name: '',
            parentCode: 'STOCK',
            parentName: 'STOCK',
          },
        };
      }
    }

    return {
      identity: form.identity || state.identity,
      address: form.location && form.location.address,
      contact: form.contact,
      note: form.note,
    };
  }

  /**
   * Met à jour le template avec la datasource
   * et passe à l'étape suivante
   *
   * @param {any} e Evènement
   * @method send
   * @memberof DatasourceChooserComponent
   */
  send(e: any) {
    e.preventDefault();
    const { update, send } = this.props;
    const { identity } = this.state;
    update('identity', identity);

    send();
  }

  /**
   * Gère le comportement au changement de la datasource
   *
   * @param {Object} event Evènement
   * @method handleOptionChange
   * @memberof DatasourceChooserComponent
   */
  handleParentChange(event: any) {
    const { locations } = this.props;
    const { identity } = this.state;
    const find = _.find(locations, { code: event.target.value });

    //const finded: Location = locations.find((location: Location) => event.target.value === location.code);
    if (find) {
      identity.parentCode = find.code ? find.code : '';
      identity.parentName = find.name;
      this.setState({
        identity,
      });
    } else if (event.target.value === 'STOCK') {
      identity.parentCode = 'STOCK';
      identity.parentName = 'STOCK';
    }
    this.setState({
      identity,
    });
  }

  /**
   * Gère le comportement au changement de la datasource
   *
   * @param {Object} event Evènement
   * @method handleOptionChange
   * @memberof DatasourceChooserComponent
   */
  handleNameChange(event: any) {
    const { identity } = this.state;
    identity.name = event.target.value.trim();
    this.setState({
      identity,
    });
  }

  async deleteObj(key: string) {
    const { Fragment } = React;
    const { update, t } = this.props;

    const type = this.getTypeFromKey(key);

    const result = await confirm({
      title: (
        <Fragment>
          <strong>{t('all.text.delete_x_ask', { type: type })}</strong>
        </Fragment>
      ),
      message: t('all.text.irreversible_action_will_delete_x', { type: type }),
      confirmText: t('all.button.delete'),
      confirmColor: 'danger',
      cancelColor: 'primary',
      cancelText: t('all.button.cancel'),
    });

    if (result) {
      if (key === 'location') update('gpsPosition', undefined);
      update(key, undefined);
    }
  }

  getTypeFromKey(key: string) {
    let type = '';
    switch (key) {
      case 'location':
        type = "l'adresse";
        break;
      case 'contact':
        type = 'le contact';
        break;
      case 'note':
        type = 'la note';
        break;
      default:
        break;
    }
    return type;
  }

  showAddContent(label: string, key: string) {
    const idName = 'addObj-'.concat(key);
    return (
      <FormGroup>
        <Row>
          <Col md="1" className="text-md-left">
            <div
              id={idName}
              className="round addObj"
              style={{ marginBottom: '7px' }}
              onClick={() => this.goToStep(key)}
            >
              <Ajout className="add" fill="curentcolor" />
              <UncontrolledTooltip placement="bottom" target="addWidget">
                {' '}
              </UncontrolledTooltip>
            </div>
          </Col>
          <Col md="11" className="align-self-md-center">
            <Label style={{ fontSize: '15px', fontStyle: 'italic', color: 'gray' }}>{label}</Label>
          </Col>
        </Row>
      </FormGroup>
    );
  }

  showOptionContent(label: string, key: string, content: string) {
    return (
      <FormGroup>
        <Label style={{ color: 'gray' }}>{label}</Label>
        <Row>
          <Col md="10" className="align-self-md-center">
            <h5 style={{ overflowWrap: 'break-word' }}>{content}</h5>
          </Col>
          <Col md="2" className="align-self-md-center">
            <Row>
              <div
                id="editObj"
                className="clickable round"
                style={{ marginRight: '5px' }}
                onClick={() => this.goToStep(key)}
              >
                <SvgEditionBleu height="1em" width="1em" />
              </div>
              <div id="deleteObj" className="clickable round" onClick={() => this.deleteObj(key)}>
                <PoubelleBleue height="1em" width="1em" />
              </div>
            </Row>
          </Col>
        </Row>
      </FormGroup>
    );
  }

  goToStep(key: string) {
    const { steps } = this.props;
    this.props.goToStep(steps[key]);
  }

  toggle() {
    const { toggle } = this.props;
    this.props.toggle();
  }

  formatNoteLength(str: string) {
    const lengthMax = 170;
    return str.length > lengthMax ? str.substr(0, lengthMax - 1) + '...' : str;
  }

  formatContact(contact: any) {
    return (
      <div>
        {contact.civility} {contact.lastName} {contact.firstName}
        {!!contact.phone ? <br /> : null}
        {contact.phone}
        <br />
        {contact.mail}
      </div>
    );
  }

  formatAddress(address: any) {
    return (
      <div>
        {address.streetNumber} {address.streetNumberAdditionnal} {address.street}
        {!!address.complement ? <br /> : null}
        {address.complement}
        <br />
        {address.zipcode} {address.city}
        {!!address.country ? <br /> : null}
        {address.country}
      </div>
    );
  }

  handleParentChoice() {
    const { locations } = this.props;
    const { identity } = this.state;
    console.log(locations);
    const firstLocation: Location = locations.length > 0 ? locations[0] : undefined;
    if (firstLocation) {
      if (_.isEqual(identity.parentCode, 'STOCK')) {
        identity.parentCode = firstLocation.code ? firstLocation.code : '';
        identity.parentName = firstLocation.name;
      } else {
        identity.parentCode = 'STOCK';
        identity.parentName = 'STOCK';
      }
    }

    this.setState({
      identity,
    });
  }

  getText(text: string) {
    const { isEdit, t } = this.props;
    if (isEdit) {
      switch (text) {
        case 'plcHldLocationName':
          return t('identity_chooser.text.location_name');
        case 'treeLocation':
          return 'en cours de modification ';
        case 'validateLocation':
          return t('identity_chooser.button.edit_location');
      }
    } else {
      switch (text) {
        case 'plcHldLocationName':
          return t('identity_chooser.text.new_location_name');
        case 'validateLocation':
          return t('identity_chooser.button.create_location');
      }
    }
  }

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof DatasourceChooserComponent
   */
  render() {
    const { isActive, locations, isEdit, t } = this.props;
    const { identity, address, contact, note } = this.state;
    console.log(locations);
    if (!isActive) return null;
    return (
      <div>
        {locations && (
          <AvForm onValidSubmit={this.send}>
            <div className="identityChoices">
              <Label>
                {t('identity_chooser.text.location_name')} <span style={{ color: 'red' }}>*</span>
              </Label>
              <AvField
                id="nameLocation"
                name="nameLocation"
                placeholder={this.getText('plcHldLocationName')}
                type="text"
                value={identity.name}
                validate={{
                  maxLength: {
                    value: 25,
                    errorMessage: t('identity_chooser.error_msg.max_length_name'),
                  },
                  async: this.validateExists,
                }}
                required
                errorMessage={t('identity_chooser.error_msg.max_length_name', { context: 'required' })}
                onChange={this.handleNameChange}
              />
              <FormGroup>
                {!isEdit && locations.length === 0 && (
                  <p style={{ margin: '15px 0 15px 0', fontSize: '10px' }}>
                    {t('identity_chooser.warning_msg.no_location_to_link')}
                  </p>
                )}
                {!isEdit && locations.length > 0 && (
                  <div>
                    <CustomInput
                      type="checkbox"
                      id="checkboxParentSite"
                      name="customCheckbox"
                      checked={!_.isEqual(identity.parentCode, 'STOCK')}
                      onChange={this.handleParentChoice}
                      label={t('identity_chooser.text.link_to_location')}
                      style={{ margin: '0 0 5px 0' }}
                    />
                    <p style={{ margin: '5px 0 5px 0' }}>{t('identity_chooser.text.select_location_to_link')}</p>
                    <Input
                      type="select"
                      name="select"
                      id="locationSelect"
                      onChange={this.handleParentChange}
                      value={identity.parentCode}
                      disabled={_.isEqual(identity.parentCode, 'STOCK')}
                    >
                      {_.isEqual(identity.parentCode, 'STOCK') && (
                        <option key="racine" value="STOCK">
                          {t('identity_chooser.input_option.no_location')}
                        </option>
                      )}
                      {locations
                        .filter((loc: Location) => loc.code !== 'STOCK')
                        .map((loc: Location) => (
                          <option key={loc.code} value={loc.code}>
                            {loc.name}
                          </option>
                        ))}
                    </Input>
                  </div>
                )}
                <p style={{ margin: '15px 0 15px 0', fontSize: '15px', fontStyle: 'italic' }}>
                  {t('identity_chooser.text.location_x_belong_to', {
                    locationName: _.isEmpty(identity.name) ? '' : ' ' + this.state.identity.name,
                    belongTo: identity.parentName,
                    context:
                      _.isEmpty(identity.parentCode) || _.isEqual(identity.parentCode, 'STOCK')
                        ? 'first_level'
                        : 'location_x',
                  })}
                </p>
              </FormGroup>
              {address &&
                address.street &&
                this.showOptionContent(t('all.location.address'), 'location', this.formatAddress(address))}
              {contact &&
                contact.lastName &&
                this.showOptionContent(t('all.location.contact'), 'contact', this.formatContact(contact))}
              {note && this.showOptionContent(t('all.location.note'), 'note', this.formatNoteLength(note))}

              {(!address || !contact || !note) && (
                <Row>
                  <hr style={{ marginTop: '0px' }} width={'100%'} color={'gray'} />
                </Row>
              )}
              {(!address || !address.street) && this.showAddContent(t('all.location.address'), 'location')}
              {(!contact || !contact.lastName) && this.showAddContent(t('all.location.contact'), 'contact')}
              {!note && this.showAddContent(t('all.location.note'), 'note')}
              <div className="text-right">
                <Label>
                  <span style={{ color: 'red' }}>*</span> {t('all.text.required_field_plural')}
                </Label>
              </div>
            </div>
            <div className="bottomChoice">
              <Row>
                <Col md="6" className="text-right">
                  <Button color="danger" onClick={this.toggle}>
                    {t('all.button.cancel')}
                  </Button>
                </Col>
                <Col md="6" className="text-left">
                  <Button id="siteCreationBtn" type="submit" color="primary">
                    {this.getText('validateLocation')}
                  </Button>
                </Col>
              </Row>
            </div>
          </AvForm>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { locales } = state;
  return {
    locales,
  };
}

const IdentityChooserConnectedComponent = connect(mapStateToProps)(IdentityChooserComponent);
const tr = withTranslation()(IdentityChooserConnectedComponent);
export default tr;
