import React from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

const BarChart = props => {
  const { data, labels, title, t } = props;

  const options = {
    emptyOverlay: {
      message: t('all.text.no_data_available'),
    },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: {
        top: 20,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        display(ctx) {
          return ctx.dataset.data[ctx.dataIndex] !== 0; // or >= 1 or ...
        },
        color(ctx) {
          return 'black';
        },
        borderWidth: 0,
        anchor: 'end',
        clamp: true,
        align: 'end',
      },
      t1: true,
    },
  };

  const dataS = {
    labels: labels.map(el => moment(el).format(t('all.date_format.date'))),
    datasets: [
      {
        data,
        backgroundColor: '#80dfff',
        hoverBackgroundColor: '#43c8fc',
      },
    ],
  };

  return <Bar data={dataS} options={options} />;
};

export default BarChart;
