import React from 'react';

const SvgNoteVert = (props: any) => (
  <svg viewBox="0 0 98.93 125" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M91.12,5.21H83.31V2.6a2.61,2.61,0,0,0-5.21,0V5.21H62.48V2.6a2.61,2.61,0,0,0-5.21,0V5.21H41.65V2.6a2.6,2.6,0,0,0-5.2,0V5.21H20.83V2.6a2.61,2.61,0,0,0-5.21,0V5.21H7.81A7.81,7.81,0,0,0,0,13V117.19A7.82,7.82,0,0,0,7.81,125H91.12a7.83,7.83,0,0,0,7.81-7.81V13A7.82,7.82,0,0,0,91.12,5.21ZM80.7,20.83a2.61,2.61,0,1,1-2.6,2.61A2.61,2.61,0,0,1,80.7,20.83Zm-20.82,0a2.61,2.61,0,1,1-2.61,2.61A2.61,2.61,0,0,1,59.88,20.83Zm-20.83,0a2.61,2.61,0,1,1-2.6,2.61A2.6,2.6,0,0,1,39.05,20.83Zm-20.83,0a2.61,2.61,0,1,1-2.6,2.61A2.61,2.61,0,0,1,18.22,20.83Zm75.5,96.36a2.6,2.6,0,0,1-2.6,2.6H7.81a2.59,2.59,0,0,1-2.6-2.6V13a2.59,2.59,0,0,1,2.6-2.6h7.81V16.1a7.81,7.81,0,1,0,5.21,0V10.42H36.45V16.1a7.81,7.81,0,1,0,5.2,0V10.42H57.27V16.1a7.82,7.82,0,1,0,5.21,0V10.42H78.1V16.1a7.81,7.81,0,1,0,5.21,0V10.42h7.81a2.6,2.6,0,0,1,2.6,2.6V117.19Z"
        />
        <rect fill={props.fill} x="19.5" y="42.8" width="61.24" height="5.87" />
        <rect fill={props.fill} x="19.5" y="59.58" width="61.24" height="5.87" />
        <rect fill={props.fill} x="19.5" y="76.37" width="61.24" height="5.87" />
        <rect fill={props.fill} x="19.5" y="93.15" width="38.17" height="5.87" />
      </g>
    </g>
  </svg>
);

export default SvgNoteVert;
