import React from 'react';

const SvgMultiDrag = (props: any) => (
  <svg viewBox="0 0 544.89 608" {...props}>
    <path
      className="cls-1"
      d="M529.89,192.4H447.73v-81a15,15,0,0,0-15-15h-81.4V15a15,15,0,0,0-15-15H15A15,15,0,0,0,0,15V400.6a15,15,0,0,0,15,15H96.4V497a15,15,0,0,0,15,15h82.16v81a15,15,0,0,0,15,15H529.89a15,15,0,0,0,15-15V207.4A15,15,0,0,0,529.89,192.4ZM30,385.6V30H321.33v81.15c0,.08,0,.17,0,.25s0,.17,0,.25v274Zm96.4,30H336.33a15,15,0,0,0,15-15V126.4h66.4V481.6l-83.64.4H126.4ZM514.89,578H223.56V512H432.73a16.08,16.08,0,0,0,15.76-15.4V222.4h66.4Z"
      fill={props.fill}
    />
  </svg>
);

export default SvgMultiDrag;
