import React from 'react';
import './style.scss';

const RadioButton = props => {
  const { isRight, setRight, leftText, rightText } = props;
  return (
    <div className="radio-button-container">
      <div className={`r-button l ${!isRight ? 'on' : ''}`} onClick={() => setRight(false)}>
        <span>{leftText}</span>
      </div>
      <div className={`r-button r ${isRight ? 'on' : ''}`} onClick={() => setRight(true)}>
        <span>{rightText}</span>
      </div>
    </div>
  );
};

export default RadioButton;
