import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { textFilter } from 'react-bootstrap-table2-filter';
import { withTranslation } from 'react-i18next';
import {
  customExportActions,
  localeActions,
  locationActions,
  meterActions,
  pdiActions,
  radioActions,
  vmeterActions,
} from '../_actions';
import { recursiveTranslate } from '../_helpers';

import { ColumnData } from '../_interfaces/column';

import ListTools from './ListTools';
import ListRender from './ListRender';
import { locales, locations } from '../_interfaces/reducers';
import { User } from '../_entities/user';

import userActions from '../_actions/user.actions';
import history from '../_helpers/history';
import { getFiltersRequest, getLightFilters } from '../QueryBuilder/FilterLSManager';
import locale from '../_shared/Locale.json';
import { translateWithPath } from '../_helpers/locale-helpers';

interface Props {
  type: string;
  location: any;
  history: any;
  match: any;
  dispatch: Function;
  meters: any;
  radios: any;
  vmeters: any;
  pdis: any;
  locales: locales;
  locations: locations;
  user: User;
  users: any;
  tableTitle: string;
}

interface State {
  reduxData: any;
  title: string;
  displayingColumns: Array<ColumnData>;
  sourceColumns: Array<ColumnData>;
  dataFiltered: Array<any>;
  rowEvents: { onClick: Function };
  locationFilters: any;
  locationColumns: any;
  filter: any;
}
/**
 * @class List
 * @extends {Component}
 */
class List extends Component<Props, State> {
  /**
   * @constructor
   * @param {Object} props Propriétés
   */
  constructor(props: Props) {
    super(props);

    const { match, type, history, t } = props;
    const { locationId, tourneeCode } = match.params;
    let title;

    const keyStorage = `filter-${type}-${tourneeCode || locationId}`;

    switch (type) {
      case ListTools.typeOfList.Meters:
        title = t('all.meter.meter_plural');
        break;
      case ListTools.typeOfList.VMeters:
        title = t('all.meter.virtual_meter_plural');
        break;
      case ListTools.typeOfList.Radios:
        title = t('all.radio.radio_plural');
        break;
      case ListTools.typeOfList.Pdi:
        title = t('all.pdi.pdi');
        break;
      default:
        title = '';
    }

    const rowEvents = {
      onClick: (e: Object, row: any) => {
        let uri;
        switch (type) {
          case ListTools.typeOfList.Meters:
            uri = `/locations/${locationId}/meters/info?id=${row.meter.id}`;
            break;
          case ListTools.typeOfList.VMeters:
            uri = `/locations/${locationId}/virtuals/info?id=${row.id}`;
            break;
          case ListTools.typeOfList.Radios:
            uri = `/locations/${locationId}/radios/info?id=${row.radio.id}`;
            break;
          case ListTools.typeOfList.Pdi:
            uri = `/locations/${locationId}/pdi/${row.id}`;
            break;
          default:
            uri = '';
        }
        history.push(uri);
      },
    };

    this.state = {
      displayingColumns: [],
      sourceColumns: [],
      dataFiltered: [],
      reduxData: {},
      title,
      rowEvents,
      locationFilters: {
        value: [],
      },
      locationColumns: {
        value: {},
      },
      filter: {
        type: '',
        value: '',
      },
      sortOptions: {},
      optionOr: localStorage.getItem(`${keyStorage}_optionor`) === 'true',
      tableTitle: '',
    };

    this.resetDefaultColumns = this.resetDefaultColumns.bind(this);
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { type } = props;

    let reduxData: any;

    switch (type) {
      case ListTools.typeOfList.VMeters:
        reduxData = props.vmeters;
        break;
      case ListTools.typeOfList.Meters:
      case ListTools.typeOfList.Radios:
      case ListTools.typeOfList.Pdi:
        reduxData = props.pdis;
        break;
      default:
        reduxData = null;
    }

    if (reduxData && reduxData.items && state.sourceColumns.length === 0) {
      const defaultColumns: any = ListTools.getDefaultColumns(type);
      const data = null;
      const translater: string = ListTools.typeOfList.VMeters === type ? 'virtualMeter' : 'pdi';
      let typeOfList: string = '';
      switch (type) {
        case ListTools.typeOfList.VMeters:
          typeOfList = ListTools.typeOfList.VMeters;
          break;
        case ListTools.typeOfList.Meters:
          typeOfList = ListTools.typeOfList.Meters;
          break;
        case ListTools.typeOfList.Radios:
          typeOfList = ListTools.typeOfList.Radios;
          break;
        case ListTools.typeOfList.Pdi:
          typeOfList = ListTools.typeOfList.Pdi;
          break;
      }

      // Construction des colonnes (avec les clés à la racine de l'objet)
      let sourceColumns: any[] = [];
      const translatedKeys =
        ListTools.typeOfList.Pdi === typeOfList
          ? recursiveTranslate('fr', 'pdi', locale.fr.pdi, props.locales.locale)
          : ListTools.getDefaultColumns(typeOfList).map(el => ({
              path: el,
              value: translateWithPath('fr', translater, el, props.locales.locale),
            }));
      sourceColumns = translatedKeys.map((champ: any) => {
        const regex = new RegExp('date');
        const elem: any = {
          dataField: champ.path,
          text: champ.value,
          sort: !/mask/i.test(champ.path),
          classes: champ.path === 'address.concatAdress' ? '' : 'crystalList-column',
          default: defaultColumns.includes(champ.path),
          filter: textFilter({
            placeholder: champ.value,
          }),
        };
        if (regex.test(champ.path)) {
          elem.sortFunc = ListTools.sortTableDates;
        }
        return elem;
      });
      return {
        sourceColumns,
        defaultColumns: _.cloneDeep(sourceColumns),
        dataFiltered: data || reduxData.items,
        reduxData,
      };
    }

    if (reduxData && reduxData.items && reduxData.items.length === 0) {
      return {
        reduxData,
        dataFiltered: [],
      };
    }

    return { reduxData };
  }

  componentDidMount() {
    const { dispatch, match, type, user } = this.props;
    const { locationId, tourneeCode } = match.params;
    const { locationFilters, locationColumns } = this.state;
    dispatch(locationActions.get(locationId));
    dispatch(userActions.get(user.id));
    dispatch(localeActions.load());
    switch (type) {
      case ListTools.typeOfList.VMeters:
        dispatch(vmeterActions.getAllWithChildren(locationId));
        break;
      default:
        dispatch(locationActions.getInfos(locationId));
        const keyStorage = `filter-${type}-${tourneeCode || locationId}`;
        const localFilters = getLightFilters(keyStorage);
        const filtersRequest = getFiltersRequest(keyStorage);
        this.setState({
          ruleList: localFilters,
        });
        const getContext = () => {
          if (keyStorage.includes('RDO')) {
            return 'radio';
          }
          return null;
        };

        dispatch(
          pdiActions.getRemotePdi(
            locationId,
            filtersRequest,
            parseInt(localStorage.getItem(`${keyStorage}-sizeperpage`) || '10', 10),
            parseInt(localStorage.getItem(`${keyStorage}-page`) || '1', 10),
            null,
            null,
            null,
            null,
            null,
            !(localStorage.getItem(`${keyStorage}_optionor`) === 'true'),
            getContext()
          )
        );
        dispatch(customExportActions.getFavoriCustomExport());
        break;
    }
    this.checkLocalStorage(locationId);
    if (locationFilters.value.length === 0) {
      const localSiteFilters = ListTools.manageLocalFilters(locationId, locationFilters, 'get', type);
      if (localSiteFilters && localSiteFilters.value.length > 0) {
        this.setState({
          locationFilters: localSiteFilters,
        });
      }
    }
    if (Object.keys(locationColumns.value).length === 0 && type === ListTools.typeOfList.Pdi) {
      const localSiteColumns = ListTools.manageLocalColumns(locationId, locationColumns, 'get');
      if (localSiteColumns && Object.keys(localSiteColumns.value).length > 0) {
        this.setState({
          locationColumns: localSiteColumns,
          displayingColumns: localSiteColumns.value.content.map(c => c.src),
        });
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    const { users, locations, user }: any = this.props;
    const roleList = ['DIOPTASE', 'SUPERADMIN', 'ADMIN'];
    if (
      !roleList.includes(user.role.name) &&
      locations.fetchedLocation &&
      !locations.fetchedLocation.tournee && // CETTE LIGNE ALEXIS
      users.fetchedUser &&
      !users.fetchedUser.profils.find(
        el => el.locationCode === locations.fetchedLocation.code && el.profil.permissions.length > 0
      )
    ) {
      history.push('/forbidden');
    }
  }

  checkLocalStorage = (locationId: any) => {
    for (let i = 0; i < localStorage.length; i++) {
      const key: any = localStorage.key(i) !== null ? localStorage.key(i) : '';
      if (key.length > 0 && key.startsWith('site') && !key.includes('Search')) {
        const testKey = parseInt(key.substring(4));
        if (testKey !== parseInt(locationId)) {
          ListTools.manageLocalFilters(testKey, [], 'clear');
        }
      }
    }
  };

  componentWillUnmount() {
    const { dispatch, type } = this.props;
    dispatch(locationActions.clear());
    switch (type) {
      case ListTools.typeOfList.Meters:
        dispatch(meterActions.clear());
        break;
      case ListTools.typeOfList.VMeters:
        dispatch(vmeterActions.clear());
        break;
      case ListTools.typeOfList.Radios:
        dispatch(radioActions.clear());
        break;
      case ListTools.typeOfList.Pdi:
        dispatch(pdiActions.clear());
        break;
      default:
    }
  }

  getListTitle = () => {
    const { type, t } = this.props;
    let listTitle;
    switch (type) {
      case ListTools.typeOfList.Meters:
        listTitle = t('all.meter.meter_plural');
        break;
      case ListTools.typeOfList.VMeters:
        listTitle = t('all.meter.virtual_meter_plural');
        break;
      case ListTools.typeOfList.Radios:
        listTitle = t('all.radio.radio_plural');
        break;
      case ListTools.typeOfList.Pdi:
        listTitle = t('all.pdi.pdi');
        break;
      default:
        listTitle = '';
    }

    return listTitle;
  };

  couldEditOrDeleteVirtualMeter = () => {
    const { user, locations, users } = this.props;
    const roleList = ['DIOPTASE', 'SUPERADMIN', 'ADMIN'];
    if (roleList.includes(user.role.name)) {
      return true;
    }
    if (locations.fetchedLocation && locations.fetchedLocation.tournee) {
      // CETTE LIGNE ALEXIS
      return true;
    }
    if (
      users &&
      users.fetchedUser &&
      locations.fetchedLocation &&
      users.fetchedUser.profils.find(
        (el: any) =>
          el.profil.permissions.find((permission: any) => permission.name === 'edit.pdi') &&
          el.locationCode === locations.fetchedLocation.code
      )
    ) {
      return true;
    }
    return false;
  };

  /**
   * Met à jour les colonnes à afficher
   *
   * @method changeDisplayingColumns
   * @memberof List
   * @param {Array<ColumnData>} columns Liste des colonnes à afficher
   */
  changeDisplayingColumns = (columns: Array<ColumnData>) => {
    const { sourceColumns } = this.state;
    columns.forEach(c => delete c.filter);
    this.setState({
      displayingColumns: columns.length > 0 ? columns : sourceColumns,
    });
  };

  resetDefaultColumns() {
    const { type } = this.state;
    const { t } = this.props;
    const defaultColumns = ListTools.getEmptyColumns(type);
    defaultColumns.forEach(el => ({
      ...el,
      filter: ListTools.withFilter(type, el.text),
      text: t(`columns.${el.dataField}`),
    }));
    this.setState({
      displayingColumns: defaultColumns,
    });
  }

  receiveData = (dataFiltered: any, ruleList: any) => {
    const { dispatch, type, match } = this.props;
    const { locationId, tourneeCode } = match.params;
    const keyStorage = `filter-${type}-${tourneeCode || locationId}`;
    const lightFilters = getLightFilters(keyStorage);
    if (
      !_.isEqual(this.state.ruleList, lightFilters) ||
      !_.isEqual(this.state.optionOr, localStorage.getItem(`${keyStorage}_optionor`) === 'true')
    ) {
      this.setState({ ruleList: lightFilters, optionOr: localStorage.getItem(`${keyStorage}_optionor`) === 'true' });
    }
  };

  removeItem = (item: any, type: string) => {
    const { dispatch } = this.props;
    const { reduxData } = this.state;
    switch (type) {
      case ListTools.typeOfList.VMeters:
        dispatch(vmeterActions.remove(item.id, item.rndCode, reduxData.items));
        break;
      default:
    }
  };

  /**
   * Applique des filtres passés par URL
   * @method getDefaultFilterByURL
   * @memberof List
   */
  getDefaultFilterByURL = () => {
    const { location } = this.props;
    const { locationFilters } = this.state;

    const listeOfFilter = location.search.slice(1).split('&');
    let listDefaultFilter: Array<any> = [];
    listeOfFilter.forEach((filter: any) => {
      const filterUrl = Object.keys(ListTools.filterURL).find(url => url === filter);
      if (filterUrl !== undefined) listDefaultFilter = listDefaultFilter.concat(ListTools.filterURL[filter]);
    });
    listDefaultFilter.map((f, i) => {
      const copy = f;
      copy.num = i;
      return copy;
    });

    if (listDefaultFilter.length > 0) {
      listDefaultFilter.map((f, i) => {
        const copy = f;
        copy.num = i;
        return copy;
      });
    } else {
      listDefaultFilter = locationFilters.value;
    }

    return listDefaultFilter;
  };

  fastFilter = (value: string, filter: any, column: any) => {
    const { dataFiltered } = this.state;
    const copyData = _.cloneDeep(dataFiltered).filter((el: any) => {
      const objectWay = column.dataField.split('.');
      if (objectWay.length > 1) {
        return el[objectWay[0]][objectWay[1]].toUpperCase().includes(value.toUpperCase());
      }
      return el[objectWay[0]].toUpperCase().includes(value.toUpperCase());
    });
    this.setState({
      dataFiltered: copyData,
      filter: {
        type: column.dataField,
        value,
      },
    });
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const {
      displayingColumns,
      sourceColumns,
      dataFiltered,
      reduxData,
      title,
      rowEvents,
      locationColumns,
      locationFilters,
      filter,
      sortOptions,
    } = this.state;
    const { type, match, locales, locations } = this.props;
    return (
      <ListRender
        reduxData={reduxData}
        virtualMeterCheck={this.couldEditOrDeleteVirtualMeter}
        title={this.getListTitle()}
        displayingColumns={displayingColumns}
        sourceColumns={sourceColumns}
        dataFiltered={dataFiltered}
        rowEvents={rowEvents}
        type={type}
        changeDisplayingColumns={this.changeDisplayingColumns}
        sendData={this.receiveData}
        defaultFilterURL={this.getDefaultFilterByURL()}
        locationId={match.params.locationId}
        tourneeCode={match.params.tourneeCode}
        removeItem={this.removeItem}
        localColumns={locationColumns}
        locale={locales.locale}
        filterSelected={locationFilters.id !== undefined ? locationFilters : null}
        isTournee={locations && locations.fetchedLocation && locations.fetchedLocation.tournee}
        resetDefaultColumns={this.resetDefaultColumns}
        fastFilter={this.fastFilter}
        filter={filter}
        sortOptions={sortOptions}
        ruleList={this.state.ruleList}
        optionOr={this.state.optionOr}
      />
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, meters, locales, radios, pdis, vmeters, locations, users } = state;
  const { user } = authentication;

  return {
    user,
    meters,
    locales,
    radios,
    pdis,
    vmeters,
    locations,
    users,
  };
}

export default withTranslation()(connect(mapStateToProps)(List));
