import { mailConstants } from '../_constants';
import { mailService } from '../_services';

/**
 * Envoie un mail avec le message passé en paramètre
 *
 * @method send
 * @param {any} message Message
 * @returns {Object} L'état de l'envoi
 */
function send(message: any) {
  function success() {
    return {
      type: mailConstants.SUCCESS,
    };
  }

  function failed(error: Object) {
    return {
      type: mailConstants.ERROR,
      error,
    };
  }
  return (dispatch: Function) => {
    mailService
      .send(message)
      .then(() => {
        dispatch(success());
      })
      .catch((error: Object) => {
        dispatch(failed(error));
      });
  };
}

/**
 * Vide le state des mails
 *
 * @method clear
 */
function clear() {
  return {
    type: mailConstants.CLEAR,
  };
}

const mailActions = {
  send,
  clear,
};

export default mailActions;
