import colorConstants from '../_constants/color.constants';
import { action } from '../_interfaces/action';

export default function locales(state = {}, action: action) {
  switch (action.type) {
    case colorConstants.LOADED:
      return {
        color: action.color,
      };
    default:
      return state;
  }
}
