import React from 'react';

const ComAnimation = (props: any) => {
  return (
    <div className="wifi-symbol">
      <div className="wifi-circle first" />
      <div className="wifi-circle second" />
      <div className="wifi-circle third" />
      <div className="wifi-circle fourth" />
    </div>
  );
};
export default ComAnimation;
