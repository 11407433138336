import React from 'react';

const SvgGoutteManual = (props: any) => (
  <svg viewBox="0 0 500 500" {...props}>
    <path fill="#000000" d="M249.5,424.3l44.9,45l0,0l0.1,0l-0.1,-0.1l78.9,-79l0,0l35,-34.9l-44.9,-45z" />
    <path
      fill="#000000"
      d="M442.7,299.4l-20.1,-20.1c-7.8,-7.8 -20.4,-7.8 -28.2,0l-7.3,7.3v0l-11.9,11.9l45,45l22.4,-22.4C448.7,315.1 448.7,305.4 442.7,299.4z"
    />
    <path
      fill="#000000"
      d="M277.2,476.4l-39.9,-40l-11,49.2l0,0l-0.4,1.9c-0.8,3.7 2.5,7 6.2,6.1l50.2,-12.2L277.2,476.4L277.2,476.4z"
    />
    <path
      fill={props.fillGoutte}
      d="M213.7,431.2c1.7,-7.5 6.8,-13.7 13.7,-16.8c1.2,-2.6 2.8,-5.1 5,-7.2l114,-114c2.1,-2.1 4.4,-3.7 6.9,-4.8c1.2,-2.5 2.8,-4.8 4.8,-6.9l19.2,-19.2c1,-1 2.2,-1.8 3.4,-2.7c-10.4,-35 -29.3,-75.1 -56.6,-120.2c-34.1,-56.2 -72,-104.9 -91.5,-128.9c-2.6,-3.2 -6.5,-5.1 -10.7,-5.1c-4.1,0 -8.1,1.9 -10.7,5.1c-19.6,24 -57.5,72.7 -91.5,129c-43.9,72.5 -66.2,132.4 -66.2,178.1c0,86.1 65,157.3 148.6,167.1c0.1,-0.8 0.1,-1.6 0.3,-2.3L213.7,431.2z"
    />
  </svg>
);

export default SvgGoutteManual;
