/**
 * Permet de traduire les éléments
 * @method translate
 * @param {string} lang la langue ex "fr"
 * @param {string} context le context de l'élément ex "displayType"
 * @param {string} param la chaine de traduction à trouver ex "Meter"
 * @param {string} localeFile le fichier de traduction à aller chercher "locale.json"
 * @param {string | null | undefined} additionnalParams le paramètre supplémentaire si besoin
 * @returns {string} la traduction soit le paramètre si non traduit
 */
import _, { isPlainObject } from 'lodash';
import i18next from 'i18next';

export default function translate(
  lang: string,
  context: string,
  param: string,
  localeFile: any,
  additionnalParams?: string | null | undefined
): string {
  let translation = localeFile[lang][context][param];
  if (additionnalParams && additionnalParams != null) {
    translation = localeFile[lang][context][additionnalParams][param];
  }
  return translation || param;
}

export function translateWithPath(lang: string, context: string, data: any, localeFile: any) {
  return _.get(localeFile, `${lang}.${context}.${data}`);
}

export function recursiveTranslate(lang: string, context: string, data: any, localeFile: any): string[] {
  const keys = Object.keys(data);
  const values = keys.map(it => data[it]);
  const translations: string[] = [];

  const translate = function(dataKeys: any[], dataValues: any[], dataContext: string, strContext: string) {
    for (let i = 0; i < dataKeys.length; i++) {
      const current = dataValues[i];
      if (dataContext === 'mtrInformations' || dataContext === 'clpInformations' || dataContext === 'alarms') {
      } else if (!isPlainObject(current)) {
        const newItem: any = {
          path: strContext.length === 0 ? `${dataKeys[i]}` : `${strContext}.${dataKeys[i]}`,
        };
        const translation = localeFile[lang][dataContext][`${dataKeys[i]}`];
        if (translation !== null || translation !== undefined) {
          newItem.value = translation;
          translations.push(newItem);
        }
      } else {
        const nextKeys = Object.keys(dataValues[i]);
        const nextValues = nextKeys.map(it => current[it]);
        const nextPath = strContext.length === 0 ? `${dataKeys[i]}` : `.${dataKeys[i]}`;
        translate(nextKeys, nextValues, dataKeys[i], nextPath);
      }
    }
  };
  translate(keys, values, context, '');

  return translations.filter((it: any) => it.value !== undefined);
}

export function translateAlarm(lang: string, localeFile: any) {
  const alarmType = localeFile[lang].alarmType;
  const keys = Object.keys(alarmType);
  return keys.map((it: any) => {
    return {
      label: alarmType[it],
      value: `${it}`,
    };
  });
}

export function translateBasicAlarmForRound(lang: string, localeFile: any) {
  const keys = ['Fraud', 'Leak', 'Backflow', 'Overflow', 'Underflow', 'ModuleDefect', 'Clock', 'Battery', 'MeterBlock'];
  return _.flatten(
    keys.map((it: any) => {
      return ['', 'current', 'past'].map(state => ({
        label: i18next.t(`alarm.type.${it.toLowerCase()}${_.isEmpty(state) ? '' : `.${state}`}`),
        value: `${it}.${state}`,
      }));
    })
  );
}

export function translateBasicAlarm(prefferedlang: string, localeFile: any) {
  const actualCurrentLanguage = i18next.language ? i18next.language : prefferedlang;
  const alarmType = localeFile[actualCurrentLanguage]
    ? localeFile[actualCurrentLanguage].alarmTypeBasic
    : localeFile[prefferedlang].alarmTypeBasic;
  const keys = Object.keys(alarmType);
  return keys.map((it: any) => {
    return {
      label: alarmType[it],
      value: it,
    };
  });
}

export function reverseAlarm(alarmName: string, locale: any) {
  // @ts-ignore
  const reversedAlarm = _.entries(locale.fr.alarmType).find(el => el[1] === alarmName)[0];
  return reversedAlarm;
}
