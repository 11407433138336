import { mapConstants } from '../_constants';
import { MarkerMeter } from '../GoogleMap/GoogleMapReactC';

function fillMarker(listMarker: Array<MarkerMeter>) {
  return (dispatch: Function) => {
    dispatch({ type: mapConstants.FILL_MARKERS, listMarker });
  };
}

function fillGateway(gateways: Array<any>) {
  return (dispatch: Function) => {
    dispatch({ type: mapConstants.FILL_GATEWAY, gateways });
  };
}

function clearMarker() {
  return (dispatch: Function) => {
    dispatch({ type: mapConstants.CLEAR_MARKERS });
  };
}

function selectedMarker(id: any) {
  return (dispatch: Function) => {
    dispatch({ type: mapConstants.SELECTED_MARKER, id });
  };
}

export default {
  fillMarker,
  fillGateway,
  clearMarker,
  selectedMarker,
};
