import React from 'react';

const SvgRadioVert = (props: any) => (
  <svg viewBox="0 0 13.67 18.99" {...props}>
    <g id="radio_vert_svg__Calque_2" data-name="Calque 2">
      <g id="radio_vert_svg__Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          d="M13.41 19H0v-7.9h13.67v7.63a.26.26 0 0 1-.26.26M2.58 8.29h-.41a.09.09 0 0 1-.09-.09V.1a.09.09 0 0 1 .09-.1h.41a.1.1 0 0 1 .1.1v8.1a.1.1 0 0 1-.1.09"
        />
        <path
          fill={props.fill}
          d="M2.65 3.27H2.1a.09.09 0 0 1-.1-.09V.1a.09.09 0 0 1 .1-.1h.55a.09.09 0 0 1 .1.1v3.08a.09.09 0 0 1-.1.09M3.32 10.78H1.44a.12.12 0 0 1-.12-.13v-.51a.12.12 0 0 1 .12-.14h1.88a.12.12 0 0 1 .12.12v.51a.13.13 0 0 1-.12.13"
        />
        <path
          fill={props.fill}
          d="M3.07 10.4H1.68a.08.08 0 0 1-.07-.08V7.85a.08.08 0 0 1 .07-.08h1.39a.09.09 0 0 1 .08.08v2.47a.09.09 0 0 1-.08.08"
        />
        <path
          fill={props.fill}
          d="M3.39 9.52h-2a.06.06 0 0 1-.06-.06v-1.2a.06.06 0 0 1 .06 0h2v1.2a.06.06 0 0 1 0 .06"
        />
      </g>
    </g>
  </svg>
);

export default SvgRadioVert;
