/* eslint-disable react/no-unused-prop-types */

import React, { Component } from 'react';
import Select, { components } from 'react-select';
import { connect } from 'react-redux';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import cloneDeep from 'lodash/cloneDeep';
import _ from 'lodash';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { columnSelectorActions } from '../_actions';
import ModalSauvegardeConfig from '../SavingSettings/SavingSettingsModal';
import ListTools from '../List/ListTools';
import { Column, ColumnData, SavedColumn } from '../_interfaces/column';
import CollonneVert from '../SvgComponents/ColonneVert';
import PoubelleBleue from '../SvgComponents/PoubelleBleu';
import SauvegardeBleu from '../SvgComponents/SauvegardeBleu';
import DisquettePlus from '../SvgComponents/DisquettePlus';
import { User } from '../_entities/user';
import { getLocalStorage, setLocalStorage } from '../_helpers/localStorage-helper';

interface Props extends React.Props<any> {
  listColumns: Array<ColumnData>;
  changeDisplay: Function;
  defaultConfig: boolean | null | undefined;
  saveOption: boolean | null | undefined;
  user: User;
  dispatch: Function;
  columns: any;
  localColumns: any;
  idSite: number;
  save: boolean | null | undefined;
  type: string;
  isOpen: boolean;
  t: Function;
}

interface State {
  columns: Array<Column>;
  askSaveConfig: boolean;
  savedColumnsList: Array<SavedColumn>;
  showColumns: boolean;
  columnSelected: SavedColumn;
  formatColumns: Array<Column>;
}

/**
 * Utiliser ColumnSelector :
 * Créer un state listColumn (default : []) qui contiendra les noms des colonnes dans le component qui l'utilise
 * Créer une property (ex : this.srcColumns) avec la liste complètes des colonnes possibles à afficher (en ajoutant un champ default: true pour que cette colonne soit fixe) et une propriete (ex : this.myFilter) contenant un filtre pour assurer l'intégrité des colonnes
 * Créer une méthode de callback qui servira à ColumnSelector pour envoyer la liste des colonnes selectionnées (A la reception, attention si une fonction filter a été passé dans la liste :
 *
 * displayingColumns: columns.map(c => {
        if (c.filter) c.filter = this.myFilter;
        return c;
    })

 * Ajouter dans l'element BootstrapTable : Pour la propriété columns = {this.state.listColumns.length > 0 ? this.state.listColumns : this.srcColumns}
 */
class ColumnSelector extends Component<Props, State> {
  static defaultProps = {
    save: true,
  };

  constructor(props: Props) {
    super(props);
    const { defaultConfig, changeDisplay, listColumns, localColumns, t } = props;
    const formatColumns = listColumns.map((c: ColumnData) => ({
      label: t(`columns.${c.dataField}`),
      value: c.dataField,
      src: c,
    }));
    let columns: any = [];
    const localData = getLocalStorage(`columns-${props.idSite}-${props.type}`);
    if (localData || (defaultConfig && Object.keys(localColumns.value).length === 0)) {
      columns =
        (localData &&
          localData.map(r => {
            _.set(r, 'src.formatter', ListTools.findFormatter(r.value));
            _.set(r, 'src.dataField', r.value);
            _.set(r, 'src.label', r.label);
            r.label = t(`columns.${r.value}`);
            return r;
          })) ||
        ListTools.getDefaultColumns(props.type).map(el => {
          return formatColumns.find(fc => fc.value === el);
        });
    } else if (_.size(localColumns.value) > 0) {
      columns = formatColumns.filter((c: Column) => {
        const existing = localColumns.value.content.find((it: any) => it.label === c.label);
        if (existing !== undefined) {
          return existing;
        }
      });
    }
    if ((columns && columns.length > 0) || (defaultConfig && defaultConfig.length > 0)) {
      changeDisplay(columns.map((c: Column) => c.src));
    }
    this.state = {
      columns,
      askSaveConfig: false,
      savedColumnsList: [],
      showColumns: Array.isArray(localData) || props.defaultShow,
      columnSelected: { label: '', value: '', src: { dataField: '', text: '' }, id: -1 },
      formatColumns,
    };
  }

  componentDidMount() {
    const { dispatch, user, isOpen } = this.props;
    dispatch(columnSelectorActions.getAll(user.id));
    this.setState({
      showColumns: isOpen,
    });
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { columns: propsColumns, isOpen } = props;
    const {
      savedColumnsList: savedColumnsListState,
      columnSelected: baseColumnSelected,
      showColumns: showColumnsState,
    } = state;
    const columnSelected = baseColumnSelected;

    let savedColumnsList = _.cloneDeep(savedColumnsListState);
    let showColumns = _.cloneDeep(showColumnsState);
    // Reception des colonnes sauvegardés en base
    if (isOpen !== showColumnsState) {
      showColumns = isOpen;
    }

    if (propsColumns.items && Object.keys(propsColumns.items).length > 0) {
      // On enregistre dans le state les configurations de colonnes de la base
      // On les tri par ordre decroissant (du plus récent au plus vieux)
      // Puis on les met en forme
      savedColumnsList = cloneDeep(propsColumns.items)
        .sort((a: any, b: any) =>
          a.updatedAt && b.updatedAt ? ListTools.sortDates(a.updatedAt, b.updatedAt, 'desc') : 0
        )
        .map((f: any) => ({ label: f.name, value: f, id: f.id }));
    }

    return { savedColumnsList, columnSelected, showColumns };
  }

  shouldComponentUpdate(props: Props, state: State) {
    // const { columns: nextColumns, askSaveConfig: nextAskSave } = state;
    // const { columns: actualColumns, askSaveConfig: prevAskSave } = this.state;
    // return (
    //   (actualColumns === null && nextColumns && nextColumns.length >= 0) ||
    //   (actualColumns && nextColumns && actualColumns.length !== nextColumns.length) ||
    //   nextAskSave !== prevAskSave
    // );
    return true;
  }

  /**
   * Applique l'action demandé et envoi par callback au parent la nouvelle liste des colonnes à afficher
   *
   * @method changeColumnDisplaying
   * @memberof ColumnSelector
   * @param {any} columns Liste des colonnes à afficher (retour du Select)
   * @param {string} action Nom de l'action à effectuer
   */
  changeColumnDisplaying = (columnsP: any, { action }: any) => {
    const columns = columnsP ? _.cloneDeep(columnsP) : [];
    const { columns: columnsState } = this.state;
    const { idSite, numberBeforeShowClear } = this.props;
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        // S'il ne reste qu'une seule colonne alors on ne la supprime pas
        if (columns.length < (Number.isInteger(numberBeforeShowClear) ? numberBeforeShowClear : 1)) {
          return;
        }
        break;
      case 'clear':
        // Si on clear la liste, on garde la première colonne de la liste
        if (Number.isNaN(numberBeforeShowClear) || numberBeforeShowClear > 0) columns.push(cloneDeep(columnsState[0]));
        break;
      default:
    }
    const { changeDisplay } = this.props;
    const { columnSelected }: any = this.state;
    columnSelected.value = columnSelected.value === '' ? { content: [] } : columnSelected.value;
    columnSelected.value.content = columns;
    const columnItem = columnSelected.id > 1 ? columnSelected : { value: { content: columns } };
    changeDisplay(columns.map((c: Column) => c.src));
    ListTools.manageLocalColumns(idSite, columnItem, 'post');
    setLocalStorage(`columns-${this.props.idSite}-${this.props.type}`, columns);
    this.setState({ columns });
  };

  /**
   * Demande la sauvegarde des colonnes selectionnées dans une configuration et l'envoi en base
   *
   * @method saveColumnSelectorConfig
   * @memberof ColumnSelector
   * @param {string} nameOfConfig Nom de la configuration à sauvegarder
   */
  saveColumnSelectorConfig = (nameOfConfig: string) => {
    const { dispatch, user } = this.props;
    const { columns } = this.state;
    dispatch(columnSelectorActions.save(nameOfConfig, user.id, columns));
  };

  /**
   * Demande la mise à jour de la configuration selectionnée en mettant le colonnes selectionnées
   *
   * @method updateColumnSelectorConfig
   * @memberof ColumnSelector
   * @param {string} nameOfConfig Nom de la configuration à mettre à jour
   * @param {number} idConfig Identifiant de la configuration en base
   */
  updateColumnSelectorConfig = (nameOfConfig: string, idConfig: number) => {
    const { dispatch, user } = this.props;
    const { columns } = this.state;
    dispatch(columnSelectorActions.update(nameOfConfig, idConfig, user.id, columns));
  };

  /**
   * Demmande la suppression de la configuration selectionnée de la base et remet à zero la configuration selectionnée
   *
   * @method updateColumnSelectorConfig
   * @memberof ColumnSelector
   */
  deleteColumnSelectorConfig = () => {
    const { dispatch, user } = this.props;
    const { savedColumnsList, columnSelected } = this.state;
    dispatch(
      columnSelectorActions.deleteColumn(
        savedColumnsList.find(f => f.id === columnSelected.id),
        columnSelected.id,
        user.id
      )
    );
    this.setState({ columnSelected: { label: '', value: '', src: { dataField: '', text: '' }, id: -1 } });
  };

  /**
   * Joue le rôle de relai entre l'interface et l'action pour récupérer les informations demandées pour la mise à jour
   *
   * @method updateSelectedConfig
   * @memberof ColumnSelector
   * @see updateColumnSelectorConfig
   */
  updateSelectedConfig = () => {
    const { columnSelected } = this.state;
    this.updateColumnSelectorConfig(columnSelected.label, columnSelected.id);
  };

  /**
   * Affiche la modal de sauvegarde
   *
   * @method toggle
   * @memberof ColumnSelector
   */
  toggle = () => {
    const { askSaveConfig } = this.state;
    this.setState({ askSaveConfig: !askSaveConfig });
  };

  /**
   * Applique la configuration selectionnée et change l'affichage
   *
   * @method columnsConfigChanged
   * @memberof ColumnSelector
   * @param {any} columnItem
   */
  columnsConfigChanged = (columnsItem: any, context: any) => {
    const { changeDisplay, idSite, t } = this.props;
    if (context.action !== 'clear') {
      if (columnsItem && columnsItem.value && columnsItem.value.content) {
        const columns = columnsItem.value.content;
        ListTools.manageLocalColumns(idSite, columnsItem, 'post');
        setLocalStorage(`columns-${this.props.idSite}-${this.props.type}`, columns);
        this.setState({ columns, columnSelected: columnsItem }, changeDisplay(columns.map((c: Column) => c.src)));
      }
    } else {
      const item = { label: '', value: '', src: { dataField: '', text: '' }, id: -1 };
      const { listColumns } = this.props;
      const formatColumns = listColumns.map((c: ColumnData) => ({
        label: t(`columns.${c.dataField}`),
        value: c.dataField,
        src: c,
      }));
      const columns: any = formatColumns.filter((c: Column) => c.src.default);
      ListTools.manageLocalColumns(idSite, { value: { content: columns } }, 'post');
      this.setState({ columns, columnSelected: item }, changeDisplay(columns.map((c: Column) => c.src)));
    }
  };

  arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { columns } = this.state;
    const newColumns = this.arrayMove(columns, oldIndex, newIndex);
    this.changeColumnDisplaying(newColumns, 'none');
  };

  render() {
    const { columns, askSaveConfig, savedColumnsList, showColumns, columnSelected, formatColumns } = this.state;
    const { resetDefaultColumns, save, t, hideResetButton, numberBeforeShowClear, handleOpen } = this.props;

    const formatColumns2 = _.entries(
      _.groupBy(formatColumns, ({ value }) => (value.split('.').length > 1 ? value.split('.')[0] : 'PDI'))
    ).map(([key, value]) => ({
      label: t(`columns.group.${key.toLowerCase()}`),
      options: value.map(subC => ({ ...subC, label: t(`columns.${subC.value}`) })),
    }));

    const styles = {
      multiValue: (base: any, state: any) => {
        return state.data.src.default
          ? { ...base, backgroundColor: '#fff', borderRadius: '10px', border: '1px solid' }
          : { ...base, backgroundColor: '#ccc', borderRadius: '10px', border: '1px solid', color: '#fff' };
      },
      multiValueLabel: (base: any, state: any) =>
        state.data.src.default ? { ...base, fontWeight: 'bold', color: '#6c757d', paddingRight: 6 } : base,
      noOptionsMessage: (base: any) => ({ ...base }),
      multiValueRemove: (base: any) => ({ ...base, borderRadius: '10px' }),
    };

    const SortableSelect = SortableContainer(Select);

    const SortableMultiValue = SortableElement(props => {
      const onMouseDown = e => {
        e.preventDefault();
        e.stopPropagation();
      };
      const innerProps = { onMouseDown };
      return <components.MultiValue {...props} innerProps={innerProps} />;
    });

    const formatGroupLabel = data => (
      <div style={{ color: 'black', fontWeight: 'bold', fontSize: '1.2em' }}>{data.label}</div>
    );

    return (
      <div className="column-selector-container">
        <ModalSauvegardeConfig
          saveConfig={this.saveColumnSelectorConfig}
          updateConfig={this.updateColumnSelectorConfig}
          toggle={this.toggle}
          openModal={askSaveConfig}
          listOfExistingConfig={savedColumnsList}
        />
        <Row style={{ margin: '0 0 0 10px' }}>
          <Button
            color="secondary"
            size="lg"
            outline
            active
            style={{
              borderColor: 'white',
              backgroundColor: 'white',
              color: 'black',
              display: 'inline-flex',
              alignItems: 'center',
              marginLeft: '-14px',
              boxShadow: 'none',
            }}
            onClick={() => {
              handleOpen(!showColumns);
              this.setState({ showColumns: !showColumns });
            }}
          >
            <CollonneVert height="1.5em" width="1.5em" stroke="#31c6b3" fill="white" strokeWidth="1.5" />{' '}
            <h2 style={{ margin: '0 10px' }}>{t('column_selector.text.column_plural')}</h2>
            {showColumns ? <FaCaretDown /> : <FaCaretRight />}{' '}
          </Button>
          {showColumns && save && (
            <Col xs="4" sm="4" md="4">
              <Select
                options={savedColumnsList}
                id="savedColumns"
                placeholder=""
                onChange={this.columnsConfigChanged}
                noOptionsMessage={() => t('column_selector.text.no_saved_col_conf')}
                value={columnSelected}
                isClearable
              />
            </Col>
          )}
          {showColumns && save && (
            <Col xs="1" md="1" sm="1">
              <div
                id="deleteColumnSelectorConfig"
                className={columnSelected.id > 0 ? 'clickable round trash-filter' : 'round-disabled trash-filter'}
                role="presentation"
                onClick={columnSelected.id > 0 ? this.deleteColumnSelectorConfig : () => {}}
              >
                <PoubelleBleue height="1em" width="1em" fill={columnSelected.id > 0 ? 'currentcolor' : 'white'} />
                {columnSelected.id > 0 && (
                  <UncontrolledTooltip placement="bottom" target="deleteColumnSelectorConfig">
                    {' '}
                    {t('column_selector.text.delete_saved_conf')}
                  </UncontrolledTooltip>
                )}
              </div>
            </Col>
          )}
          {showColumns && save && (
            <Col xs="1" md="1" sm="1">
              <div
                id="UpdateConfigColumn"
                className={columnSelected.id < 0 ? 'round-disabled save-filter' : 'clickable round  save-filter'}
                role="presentation"
                onClick={columnSelected.id > 0 ? this.updateSelectedConfig : () => {}}
              >
                <SauvegardeBleu height="1em" width="1em" fill={columnSelected.id > 0 ? 'currentcolor' : 'white'} />
                {columnSelected.id > 0 && (
                  <UncontrolledTooltip placement="bottom" target="UpdateConfigColumn">
                    {' '}
                    {t('all.text.update_selected_conf')}
                  </UncontrolledTooltip>
                )}
              </div>
            </Col>
          )}
          {showColumns && save && (
            <Col xs="1" md="1" sm="1">
              <div
                id="NewConfigColumn"
                className="clickable round save-filter"
                role="presentation"
                onClick={this.toggle}
              >
                <DisquettePlus height="1.3em" width="1.3em" fill="currentcolor" />{' '}
                <UncontrolledTooltip placement="bottom" target="NewConfigColumn">
                  {' '}
                  {t('all.text.save_new_conf')}
                </UncontrolledTooltip>
              </div>
            </Col>
          )}
        </Row>
        {showColumns && (
          <Row style={{ marginTop: '10px' }}>
            <Col xs="11" sm="11" md="11">
              <SortableSelect
                // react-sortable-hoc props:
                axis="xy"
                onSortEnd={this.onSortEnd}
                distance={4}
                // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                components={{
                  MultiValue: SortableMultiValue,
                }}
                closeMenuOnSelect={false}
                isMulti
                id="listeColumns"
                isClearable={columns.length > (Number.isInteger(numberBeforeShowClear) ? numberBeforeShowClear : 1)}
                styles={styles}
                options={formatColumns2.sort((a, b) => {
                  if (a.label < b.label) return -1;
                  if (a.label === b.label) return 0;
                  return 1;
                })}
                noOptionsMessage={() =>
                  formatColumns2.length === columns.length
                    ? t('column_selector.no_options_msg.no_more_column_available')
                    : t('column_selector.no_options_msg.no_corresponding_column')
                }
                onChange={this.changeColumnDisplaying}
                value={columns}
                placeholder={t('all.button.select')}
                formatGroupLabel={formatGroupLabel}
              />
            </Col>
            {!hideResetButton && (
              <Button
                onClick={() => {
                  const columns = ListTools.getEmptyColumns(this.props.type)
                    .map((el: any) => ({
                      ...el,
                      filter: ListTools.withFilter(this.props.type, el.dataField),
                      default: true,
                      text: t(`columns.${el.dataField}`),
                    }))
                    .map((c: any) => ({ label: t(`columns.${c.dataField}`), value: c.dataField, src: c }));
                  localStorage.removeItem(`columns-${this.props.idSite}-${this.props.type}`);
                  this.setState(
                    {
                      columns,
                      columnSelected: { label: '', value: '', src: { dataField: '', text: '' }, id: -1 },
                    },
                    resetDefaultColumns
                  );
                }}
              >
                {t('all.button.reset')}
              </Button>
            )}
          </Row>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { columns, authentication, locales } = state;
  const { user } = authentication;

  return {
    columns,
    user,
    locales,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(ColumnSelector)));
