import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import { userActions } from '../_actions';
import logo from '../_svgs/logo_fd_vert.svg';
import { detect } from 'detect-browser';
import { LostPass } from '.';
import { withRouter } from 'react-router-dom';
import profilActions from '../_actions/profil.actions';

class DeviceLoginComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    // reset login status

    this.state = {
      username: '',
      password: '',
      submitted: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.detectBrowser = this.detectBrowser.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(userActions.logout());
    dispatch(profilActions.getVersion());
  }

  handleChange(e: any) {
    const { name, value } = e.target;
    if (name === 'username') {
      this.setState({
        username: value,
      });
    } else {
      this.setState({
        password: value,
      });
    }
  }

  handleSubmit() {
    this.setState({ submitted: true });
    const { username, password } = this.state;
    const { dispatch } = this.props;
    if (username && password) {
      dispatch(userActions.login(username.trim(), password.trim(), true));
    }
  }

  detectBrowser() {
    const browser = detect();
    // handle the case where we don't detect the browser
    switch (browser && browser.name) {
      case 'chrome':
      case 'ios':
      case 'firefox':
        console.log('supported');
        break;
      case 'edge':
      case 'opera':
      case 'vivaldi':
        console.log('kinda ok');
        break;
      default:
        return (
          <Fragment>
            <div className="alert-warning" style={{ position: 'absolute', bottom: '15%', left: '0' }}>
              <div style={{ padding: '15px', textAlign: 'center' }}>
                Votre navigateur n'est pas officiellement supporté, vous pourrez rencontrer certaines lenteurs ou
                comportements non désiré
              </div>
            </div>
            <br />
          </Fragment>
        );
    }
  }

  render() {
    return (
      <Fragment>
        <div className="logo" style={{ backgroundColor: 'white', padding: '15px', height: '20%' }}>
          <img src={logo} alt="Logo" />
        </div>
        <div className="container-login">
          <div style={{ padding: '15px' }}>
            <FormGroup>
              <Label for="exampleEmail" style={{ color: 'white' }}>
                Email
              </Label>
              <Input type="email" name="username" placeholder="mail" onChange={this.handleChange} />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword" style={{ color: 'white' }}>
                Mot de passe
              </Label>
              <Input type="password" name="password" placeholder="mot de passe" onChange={this.handleChange} />
            </FormGroup>
            <div className="lostPass" style={{ marginBottom: '1rem' }}>
              <LostPass />
            </div>
            <div style={{ textAlign: 'center' }}>
              <Button style={{ backgroundColor: '#31c6b3' }} onClick={this.handleSubmit}>
                Se connecter
              </Button>
            </div>
            {this.detectBrowser()}
          </div>
        </div>
        <div
          className="version"
          style={{ position: 'fixed', bottom: '5%', color: 'white', width: '100%', textAlign: 'center' }}
        >
          Version : {this.props.profil.version}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { loggingIn } = state.authentication;
  const { alert, dashboards, profil } = state;
  return {
    loggingIn,
    alert,
    dashboards,
    profil,
  };
}

const clp: any = connect(mapStateToProps)(DeviceLoginComponent);
const connectedLoginPage = withRouter(clp);
export default connectedLoginPage;
