import React from 'react';

const SvgGoutteCluster4parts = (props: any) => (
  <svg viewBox="0 0 224.1 224.1" {...props}>
    <path fill="none" d="M164.1,112c0-4.2-0.5-8.3-1.5-12.2C163.6,103.7,164.1,107.8,164.1,112L164.1,112z" />
    <path fill="none" d="M20,110.4c0,0.6,0,1.1,0,1.7l0,0C20,111.5,20,110.9,20,110.4z" />
    <path
      fill="none"
      d="M161.6,128.1c-3.4,10.6-10.1,19.6-18.9,26c-8.6,6.3-19.2,9.9-30.6,9.9v0c11.4,0,22-3.7,30.6-9.9
	C151.4,147.8,158.1,138.7,161.6,128.1c1.6-5.1,2.5-10.5,2.5-16.1c0,0,0,0,0,0h0C164.1,117.7,163.2,123.1,161.6,128.1z"
    />
    <path
      fill="none"
      d="M112,164.1c-11.4,0-22-3.7-30.6-9.9c-8.8-6.4-15.5-15.5-18.9-26c-0.4-1.3-0.8-2.6-1.1-3.9
	c0.3,1.3,0.7,2.6,1.1,3.9c3.4,10.6,10.1,19.6,18.9,26C90,160.4,100.6,164.1,112,164.1L112,164.1L112,164.1z"
    />

    <path
      opacity={props.fillCluster[0].opacityOut}
      fill={props.fillCluster[0].color}
      d="M20,110.4c0-1.5,0.1-3,0.2-4.5c0.5-7.8,2-15.2,4.3-22.3c6.1-18.7,18-34.7,33.5-46C73.3,26.5,92,20,112,20V0
	C87.5,0,64.7,8,46.2,21.4c-18.9,13.8-33.3,33.3-40.7,56C1.9,88.3,0,100,0,112h20C20,111.5,20,110.9,20,110.4z"
    />
    <path
      opacity={props.fillCluster[0].opacityMiddle}
      fill={props.fillCluster[0].color}
      d="M40,112L40,112L40,112c0-7.8,1.3-15.2,3.5-22.3c4.8-14.6,14-27.1,26.2-36C81.6,45.1,96.2,40,112,40V20
	C92,20,73.3,26.5,58,37.6c-15.5,11.3-27.4,27.2-33.5,46c-2.3,7.1-3.8,14.5-4.3,22.3c-0.1,1.5-0.2,3-0.2,4.5c0,0.6,0,1.1,0,1.7H40z"
    />
    <path
      opacity={props.fillCluster[0].opacityIn}
      fill={props.fillCluster[0].color}
      d="M60,112c0-5.6,0.9-11,2.5-16.1c3.4-10.6,10.1-19.6,18.9-26C90,63.7,100.6,60,112,60h0V40
	c-15.8,0-30.4,5.1-42.3,13.8c-12.2,8.9-21.4,21.4-26.2,36c-2.3,7-3.5,14.5-3.5,22.3v0H60z"
    />

    <path
      opacity={props.fillCluster[1].opacityOut}
      fill={props.fillCluster[1].color}
      enableBackground="new"
      d="M204.1,113.7c0,1.5-0.1,3-0.2,4.5c-0.5,7.8-2,15.2-4.3,22.3c-6.1,18.7-18,34.7-33.5,46
	c-15.3,11.1-34,17.6-54.1,17.6v20c24.6,0,47.3-8,65.8-21.4c18.9-13.8,33.3-33.3,40.7-56c3.6-10.9,5.5-22.5,5.5-34.6h-20
	C204.1,112.6,204.1,113.2,204.1,113.7z"
    />
    <path
      opacity={props.fillCluster[1].opacityMiddle}
      fill={props.fillCluster[1].color}
      enableBackground="new"
      d="M184.1,112c0,7.8-1.3,15.2-3.5,22.3c-4.8,14.6-14,27.1-26.2,36c-11.9,8.7-26.5,13.8-42.3,13.8v20
	c20,0,38.8-6.5,54.1-17.6c15.5-11.3,27.4-27.2,33.5-46c2.3-7.1,3.8-14.5,4.3-22.3c0.1-1.5,0.2-3,0.2-4.5c0-0.6,0-1.1,0-1.7H184.1z"
    />
    <path
      opacity={props.fillCluster[1].opacityIn}
      fill={props.fillCluster[1].color}
      d="M164.1,112.1c0,5.6-0.9,11-2.5,16.1c-3.4,10.6-10.1,19.6-18.9,26c-8.6,6.3-19.2,9.9-30.6,9.9v20
	c15.8,0,30.4-5.1,42.3-13.8c12.2-8.9,21.4-21.4,26.2-36c2.3-7,3.5-14.5,3.5-22.3L164.1,112.1C164.1,112,164.1,112,164.1,112.1z"
    />

    <path
      opacity={props.fillCluster[2].opacityOut}
      fill={props.fillCluster[2].color}
      enableBackground="new"
      d="M105.9,203.9c-17.9-1.2-34.4-7.5-47.9-17.4c-15.6-11.4-27.4-27.5-33.5-46c-2.9-9-4.5-18.6-4.5-28.4H0
	c0,12.1,1.9,23.7,5.5,34.6c7.4,22.7,21.8,42.2,40.7,56c18.5,13.5,41.2,21.4,65.8,21.4v-20C110,204.1,108,204,105.9,203.9z"
    />
    <path
      opacity={props.fillCluster[2].opacityMiddle}
      fill={props.fillCluster[2].color}
      enableBackground="new"
      d="M43.5,134.3c-2.3-7-3.5-14.5-3.5-22.3H20c0,9.9,1.6,19.5,4.5,28.4c6.1,18.5,17.9,34.6,33.5,46
	c13.6,9.9,30,16.2,47.9,17.4c2.1,0.1,4.1,0.2,6.2,0.2v-20c-15.8,0-30.4-5.1-42.3-13.8C57.6,161.5,48.3,148.9,43.5,134.3z"
    />
    <path
      opacity={props.fillCluster[2].opacityIn}
      fill={props.fillCluster[2].color}
      d="M81.5,154.2c-8.8-6.4-15.5-15.5-18.9-26c-0.4-1.3-0.8-2.6-1.1-3.9c-0.9-3.9-1.5-8-1.5-12.2H40
	c0,7.8,1.3,15.2,3.5,22.3c4.8,14.6,14,27.1,26.2,36c11.9,8.7,26.5,13.8,42.3,13.8v-20C100.6,164.1,90,160.4,81.5,154.2z"
    />

    <path
      opacity={props.fillCluster[3].opacityOut}
      fill={props.fillCluster[3].color}
      enableBackground="new"
      d="M177.9,21.4C159.4,8,136.6,0,112,0v20c2,0,4.1,0.1,6.2,0.2c17.9,1.2,34.4,7.5,47.9,17.4
	c15.6,11.4,27.4,27.5,33.5,46c2.9,9,4.5,18.6,4.5,28.4h20c0-12.1-1.9-23.7-5.5-34.6C211.2,54.7,196.8,35.2,177.9,21.4z"
    />
    <path
      opacity={props.fillCluster[3].opacityMiddle}
      fill={props.fillCluster[3].color}
      enableBackground="new"
      d="M154.4,53.8c12.2,8.9,21.4,21.4,26.2,36c2.3,7,3.5,14.5,3.5,22.3l0,0v0h20c0-9.9-1.6-19.5-4.5-28.4
	c-6.1-18.5-17.9-34.6-33.5-46c-13.6-9.9-30-16.2-47.9-17.4c-2.1-0.1-4.1-0.2-6.2-0.2v20C127.9,40,142.5,45.1,154.4,53.8z"
    />
    <path
      opacity={props.fillCluster[3].opacityIn}
      fill={props.fillCluster[3].color}
      d="M154.4,53.8C142.5,45.1,127.9,40,112,40v20c11.4,0,22,3.7,30.6,9.9c8.8,6.4,15.5,15.5,18.9,26
	c0.4,1.3,0.8,2.6,1.1,3.9c0.9,3.9,1.5,8,1.5,12.2h20v0c0-7.8-1.3-15.2-3.5-22.3C175.8,75.2,166.5,62.6,154.4,53.8z"
    />

    <path
      fill="#FFFFFF"
      d="M60,112.1C60,112,60,112,60,112.1L60,112.1c0,4.2,0.5,8.3,1.5,12.2C60.5,120.4,60,116.3,60,112.1z"
    />
    <path
      fill="#FFFFFF"
      d="M161.6,96c-3.4-10.6-10.1-19.6-18.9-26c-8.6-6.3-19.2-9.9-30.6-9.9c-11.4,0-22,3.7-30.6,9.9
	C72.7,76.3,66,85.4,62.5,96c-1.6,5.1-2.5,10.5-2.5,16.1c0,5.6,0.9,11,2.5,16.1c3.4,10.6,10.1,19.6,18.9,26
	c8.6,6.3,19.2,9.9,30.6,9.9s22-3.7,30.6-9.9c8.8-6.4,15.5-15.5,18.9-26c1.6-5.1,2.5-10.5,2.5-16.1C164.1,106.4,163.2,101,161.6,96z
	 M112,112L112,112L112,112L112,112L112,112z"
    />
    <line fill="none" x1="-18.4" y1="112" x2="242.5" y2="112" />
    <line fill="none" x1="177.3" y1="225" x2="46.8" y2="-0.9" />
  </svg>
);

export default SvgGoutteCluster4parts;
