import React, { Component } from 'react';
import ImportBleu from '../SvgComponents/ImportBleu';
import { Button, CustomInput, FormGroup, Input, Label } from 'reactstrap';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import { importActions, localeActions } from '../_actions';
import { connect } from 'react-redux';
import LoadingBand from '../Bands/Loading';
import ErrorBand from '../Bands/Error';
import SuccessBand from '../Bands/Success';
import { translate } from '../_helpers';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { Comparator, textFilter } from 'react-bootstrap-table2-filter';
import { withTranslation } from 'react-i18next';

interface Props {
  title: String;
  user: any;
  history: any;
  dispatch: Function;
  imports: any;
  locales: any;
  t: Function;
}

class ImportComponent extends Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      file: null,
    };
    this.onChange = this.onChange.bind(this);
    this.invalidFile = this.invalidFile.bind(this);
  }

  componentDidMount() {
    const { user, history, dispatch } = this.props;
    if (!user.role.name.includes('ADMIN') && user.role.name !== 'DIOPTASE') {
      history.push('/forbidden');
    }
    dispatch(localeActions.load());
    dispatch(importActions.isRunning());
  }

  onChange(e: any) {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    this.setState({ file: formData });
  }

  invalidFile(e: any) {
    const { dispatch } = this.props;
    dispatch(importActions.clear());
    dispatch(importActions.isRunning());
    this.setState({ file: null });
  }

  submitFile = () => {
    const { dispatch } = this.props;
    const { file } = this.state;
    dispatch(importActions.importFile(file));
  };

  testImport = () => {
    const { dispatch } = this.props;
    dispatch(importActions.testImport());
  };

  confirmImport = () => {
    const { dispatch } = this.props;
    dispatch(importActions.confirmImport());
  };

  chooseFileDone = () => {
    const { file } = this.state;
    const { imports } = this.props;
    return file !== null && file && imports && !imports.running;
  };

  firstStepDone = () => {
    const { imports } = this.props;
    return this.chooseFileDone() && imports && imports.imports != null;
  };

  secondStepDone = () => {
    const { imports } = this.props;
    return this.firstStepDone() && imports && imports.tests;
  };

  thirdStepDone = () => {
    const { imports } = this.props;
    return this.secondStepDone() && imports && imports.confirm;
  };

  changeDisplay = (key: string, context: string) => {
    let state = {};
    state[key + context] = this.state[key + context] ? !this.state[key + context] : true;
    this.setState({ ...state });
  };

  render() {
    const { file } = this.state;
    const { imports, locales, title, t } = this.props;

    const filterPdi = textFilter({
      placeholder: t('all.filter.filter_by_ref'),
      className: 'ref-pdi-custom-text-filter',
      defaultValue: '',
      comparator: Comparator.LIKE,
    });

    const filterMeter = textFilter({
      placeholder: t('all.filter.filter_by_serial'),
      className: 'ref-pdi-custom-text-filter',
      defaultValue: '',
      comparator: Comparator.LIKE,
    });

    const columns = [
      {
        dataField: 'line',
        text: t('all.column_text.row'),
      },
      {
        dataField: 'pdiReference',
        text: t('all.column_text.pdi_ref'),
        filter: filterPdi,
      },
      {
        dataField: 'meterSerial',
        text: t('all.column_text.serial_meter'),
        filter: filterMeter,
      },
    ];

    return (
      <div className="col-12">
        <div className="table-info-container">
          <h2>
            <span>
              <ImportBleu height="1em" width="1em" fill="#31c6b3" />
            </span>
            {title}
          </h2>
          {imports && (!imports.imports || !(file && file.get('file'))) && !imports.loading ? (
            <FormGroup>
              <Label for="dataFile">{t('import_component.input_file_label.import_file')}</Label>
              <Input type="file" name="file" id="dataFile" onChange={this.onChange} accept=".xlsx" />
              {imports && imports.running && (
                <div style={{ marginTop: '10px' }}>
                  <LoadingBand message={t('import.loading_band.import_already_loading')} />
                </div>
              )}
            </FormGroup>
          ) : (
            <div>
              <Button color="danger" onClick={this.invalidFile}>
                {t('all.button.change_file')}
              </Button>
              <p style={{ marginLeft: 2, marginTop: 5, fontWeight: 'bold' }}>{file && file.get('file').name}</p>
            </div>
          )}

          <div style={{ marginBottom: 20 }}> </div>

          {this.chooseFileDone() && (
            <div>
              {file && !imports.imports && !imports.loading && (
                <>
                  <h3>{t('import.step_1_title.first_step_load_file')}</h3>
                  <Button
                    disabled={imports && imports && imports.loading}
                    color="primary"
                    style={{ marginTop: 10 }}
                    onClick={this.submitFile}
                  >
                    {t('all.button.submit')}
                  </Button>
                </>
              )}
              {imports && imports.imports && imports.imports.warning && (
                <SuccessBand message={t('import.step_1_success_band.load_file')} />
              )}
            </div>
          )}

          <div style={{ marginBottom: 20 }}> </div>

          {imports && imports.loading && <LoadingBand message={t('import.step_1_loading_band.file_analysis')} />}
          {imports && imports.error && <ErrorBand message={imports.error} />}
          {this.firstStepDone() && (
            <AvForm onValidSubmit={this.testImport}>
              <h3>{t('import.step_2_title.second_step_check_error')}</h3>
              {Object.entries(imports.imports.error)
                .map(([key, value]) => value.length)
                .reduce((a, b) => a + b) > 0 && <h5>{t('import.step_error.error')}</h5>}
              {Object.entries(imports.imports.error).map(([key, value]) => {
                return (
                  value.length > 0 && (
                    <FormGroup check key={key} style={{ marginBottom: 5 }}>
                      <AvInput type="checkbox" name={key} required tag={CustomInput} style={{ fontWeight: 'bold' }}>
                        {' '}
                        {translate('fr', 'importation', key, locale)} :{' '}
                        <a
                          onClick={() => {
                            this.changeDisplay(key, 'e2');
                          }}
                          className="display_lines"
                        >
                          {value.length}
                        </a>
                      </AvInput>
                      {this.state[key + 'e2'] && (
                        <ul>
                          {value.map((entry: any) => (
                            <li key={entry.line}>{t('import.list.x_row', { row: entry.line })}</li>
                          ))}
                        </ul>
                      )}
                    </FormGroup>
                  )
                );
              })}
              {Object.entries(imports.imports.warning)
                .map(([key, value]) => value.length)
                .reduce((a, b) => a + b) > 0 && <h5>{t('import.step.warning')}</h5>}
              {Object.entries(imports.imports.warning).map(([key, value]) => {
                return (
                  value.length > 0 && (
                    <FormGroup check key={key} style={{ marginBottom: 5 }}>
                      <AvInput type="checkbox" name={key} required tag={CustomInput}>
                        {' '}
                        {translate('fr', 'importation', key, locale)} :{' '}
                        <a
                          onClick={() => {
                            this.changeDisplay(key, 'e2');
                          }}
                          className="display_lines"
                        >
                          {value.length}
                        </a>
                      </AvInput>
                      {this.state[key + 'e2'] && (
                        <ul>
                          {value.map((entry: any) => (
                            <li key={entry.line}>{t('import.list.x_row', { row: entry.line })}</li>
                          ))}
                        </ul>
                      )}
                    </FormGroup>
                  )
                );
              })}
              <div style={{ marginTop: 15 }}>
                {imports && !imports.tests && (
                  <>
                    <p className="help-next-step">{t('import.step_2_help.check_error_for_import')}</p>
                    <Button disabled={imports && imports.tests && imports.tests.loading} color="primary">
                      {t('import.step_2_button.test_integration')}
                    </Button>
                  </>
                )}
                {imports && imports.tests && imports.tests.warning && (
                  <SuccessBand message={t('import.step_2_success_band.test_integration')} />
                )}
              </div>
            </AvForm>
          )}

          <div style={{ marginBottom: 20 }}> </div>

          {imports && imports.tests && imports.tests.loading && (
            <LoadingBand message={t('import_component.step_2_loading_band.testing_file')} />
          )}
          {imports && imports.tests && !imports.tests.warning && imports.tests.error && (
            <ErrorBand message={imports.tests.error} />
          )}
          {this.secondStepDone() && imports.tests.success && (
            <AvForm onValidSubmit={this.confirmImport} style={{ marginTop: 20 }}>
              <h3>{t('import.step_3_title.third_step_check_integration')}</h3>
              {Object.entries(imports.tests.error)
                .map(([key, value]) => value.length)
                .reduce((a, b) => a + b) > 0 && <h5>Erreurs : </h5>}
              {Object.entries(imports.tests.error).map(([key, value]) => {
                return (
                  value.length > 0 && (
                    <FormGroup check key={key} style={{ marginBottom: 5 }}>
                      <AvInput type="checkbox" name={key} required tag={CustomInput} style={{ fontWeight: 'bold' }}>
                        {' '}
                        {translate('fr', 'importation', key, locale)} :{' '}
                        <a
                          onClick={() => {
                            this.changeDisplay(key, 'e3');
                          }}
                          className="display_lines"
                        >
                          {value.length}
                        </a>
                      </AvInput>
                      {this.state[key + 'e3'] && (
                        <ul>
                          {value.map((entry: any) => (
                            <li key={entry.line}>{t('import.list.x_row', { row: entry.line })}</li>
                          ))}
                        </ul>
                      )}
                    </FormGroup>
                  )
                );
              })}
              {Object.entries(imports.tests.warning)
                .map(([key, value]) => value.length)
                .reduce((a, b) => a + b) > 0 && <h5>{t('import.step.warning')}</h5>}
              {Object.entries(imports.tests.warning).map(([key, value]) => {
                return (
                  value.length > 0 && (
                    <FormGroup check key={key} style={{ marginBottom: 5 }}>
                      <AvInput type="checkbox" name={key} required tag={CustomInput}>
                        {' '}
                        {translate('fr', 'importation', key, locale)} :{' '}
                        <a
                          onClick={() => {
                            this.changeDisplay(key, 'e3');
                          }}
                          className="display_lines"
                        >
                          {value.length}
                        </a>
                      </AvInput>
                      {this.state[key + 'e3'] && (
                        <ul>
                          {value.map((entry: any) => (
                            <li key={entry.line}>{t('import.list.x_row', { row: entry.line })}</li>
                          ))}
                        </ul>
                      )}
                    </FormGroup>
                  )
                );
              })}
              <div style={{ marginTop: 15 }}>
                {imports && !imports.confirm && (
                  <>
                    <p className="help-next-step">{t('import.step_3_help.check_error_for_finish_import')}</p>
                    <Button disabled={imports && imports.confirm && imports.confirm.loading} color="primary">
                      {t('all.button.integrate')}
                    </Button>
                  </>
                )}

                {imports && imports.confirm && imports.confirm.warning && (
                  <SuccessBand
                    message={t('import_component.step_3_success_band.integration_x_row_success', {
                      count: imports.confirm.success.length,
                    })}
                  />
                )}
              </div>
            </AvForm>
          )}

          <div style={{ marginBottom: 20 }}> </div>

          {this.thirdStepDone() && imports.confirm.loading && (
            <LoadingBand message={t('import.step_3_loading_band.integration_file')} />
          )}
          {this.thirdStepDone() && !imports.confirm.warning && imports.confirm.error && (
            <ErrorBand message={imports.confirm.error} />
          )}
          {this.thirdStepDone() && imports.confirm.success && (
            <>
              <h3>{t('import.step_4_title.integration_report')}</h3>
              <BootstrapTable
                keyField="results"
                bootstrap4
                bordered={false}
                hover
                data={imports.confirm.success}
                columns={columns}
                pagination={paginationFactory()}
                filter={filterFactory()}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, meters, locations, locales, radios, pdis, imports } = state;
  const { user } = authentication;
  return {
    alert,
    user,
    meters,
    radios,
    imports,
    pdis,
    locations,
    locales,
  };
}

const connectedStockImportation = connect(mapStateToProps)(ImportComponent);
const tr = withTranslation()(connectedStockImportation);
export default tr;
