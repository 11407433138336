import React from 'react';

import logo from '../_svgs/logo_fd_blanc.svg';

import { MainNavbar, SidebarMini, SidebarLocation } from './index';

/**
 * Rend le layout des dashboards
 *
 * @method DashboardLayout
 * @param {any} props Props du composant
 * @returns {JSX} Le layout
 */
export default function DashboardLayout(props: any) {
  const { children } = props;
  return (
    <div>
      <div className="wrapper">
        <SidebarMini />
        <SidebarLocation />
        <div id="main-panel" className="main-panel" />
        <div className="CrystalMainHeader synchronisation flex-box">
          <div className="logo-sidebar">
            <img src={logo} alt="Logo" />
          </div>
          <MainNavbar />
        </div>
        <section className="text-muted dash">
          <div className="containerDash">{children}</div>
        </section>
      </div>
    </div>
  );
}
