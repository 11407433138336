import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import Label from './Label';
import ValidateButtons from './ValidateButtons';
import SvgCroixNoir from '../../../SvgComponents/croixNoir';
import synchroActions from '../../../_actions/synchro.actions';

class RoundInfo extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  indexedGenerator(task: any) {
    const { t } = this.props;
    return {
      labels: [t('round_info.text.indexed'), t('round_info.text.remaining')],
      data: [task.indexedMeter, task.totalMeter - task.indexedMeter],
      colorList: ['#26A653', '#dc3545'],
    };
  }

  modifiedGenerator(task: any) {
    const { t } = this.props;
    return {
      labels: [t('round_info.text.edited'), t('round_info.text.remaining')],
      data: [task.modifiedMeter, task.totalMeter - task.modifiedMeter],
      colorList: ['#F2AE2E', '#dc3545'],
    };
  }

  fullDataGenerator(task: any) {
    const { t } = this.props;
    return {
      labels: [t('round_info.text.indexed'), t('round_info.text.edited'), t('round_info.text.remaining')],
      data: [task.indexedMeter, task.modifiedMeter - task.indexedMeter, task.totalMeter - task.modifiedMeter],
      colorList: ['#26A653', '#F2AE2E', '#dc3545'],
    };
  }

  graphDisplay = () => {
    const { synchrocom, task, switchToPartial, t } = this.props;
    const indexData = this.indexedGenerator(task);
    const modifData = this.modifiedGenerator(task);
    const fullData = this.fullDataGenerator(task);
    const isBigScreen = useMediaQuery({ query: '(min-width: 1650px)' });
    const isNormalScreen = useMediaQuery({ query: '(max-width: 1649px)' });

    return (
      <>
        {isBigScreen && (
          <div style={{ width: '50%' }}>
            <div
              style={{
                color: 'black',
                paddingBottom: '5px',
                height: '45%',
                alignItems: 'center',
                width: '100%',
                margin: 0,
                display: 'flex',
              }}
            >
              <div style={{ width: '35%' }}>
                <span style={{ marginBottom: 0, fontSize: '17px', display: 'block' }}>
                  {t('all.text.indexed_plural')}
                </span>
                <span style={{ marginBottom: 0, fontSize: '15px', color: '#333' }}>
                  {`${task.indexedMeter}/${task.totalMeter}`} ({Math.floor((task.indexedMeter / task.totalMeter) * 100)}
                  %)
                </span>
              </div>
              <Col style={{ width: '60%', padding: '0' }} xs="auto">
                <ProgressBar progressWidth={(task.indexedMeter / task.totalMeter) * 100} color="green" />
              </Col>
            </div>
            <div
              style={{
                color: 'black',
                paddingBottom: '5px',
                height: '45%',
                alignItems: 'center',
                width: '100%',
                margin: 0,
                display: 'flex',
              }}
            >
              <div style={{ width: '35%' }}>
                <span style={{ marginBottom: 0, fontSize: '17px', display: 'block' }}>
                  {t('all.text.modified_plural')}
                </span>
                <span style={{ marginBottom: 0, fontSize: '15px', color: '#333' }}>
                  {`${task.modifiedMeter}/${task.totalMeter}`} (
                  {Math.floor((task.modifiedMeter / task.totalMeter) * 100)}
                  %)
                </span>
              </div>
              <Col style={{ width: '60%', padding: '0' }} xs="auto">
                <ProgressBar progressWidth={(task.modifiedMeter / task.totalMeter) * 100} color="lightblue" />
              </Col>
            </div>
          </div>
        )}
        {isNormalScreen && (
          <div style={{ width: '50%' }}>
            <div
              style={{
                color: 'black',
                paddingBottom: '5px',
                height: '45%',
                alignItems: 'center',
                width: '100%',
                margin: 0,
                display: 'flex',
              }}
            >
              <div style={{ width: '40%' }}>
                <span style={{ marginBottom: 0, fontSize: '15px', display: 'block' }}>
                  {t('round_info.text.indexed_x_percent_plural', {
                    percent: Math.floor((task.indexedMeter / task.totalMeter) * 100),
                  })}
                </span>
                <span style={{ marginBottom: 0, fontSize: '15px' }}>{`${task.indexedMeter}/${task.totalMeter}`}</span>
              </div>
              <Col style={{ width: '55%', padding: '0' }} xs="auto">
                <ProgressBar progressWidth={(task.indexedMeter / task.totalMeter) * 100} color="green" />
              </Col>
            </div>
            <div
              style={{
                color: 'black',
                paddingBottom: '5px',
                height: '45%',
                alignItems: 'center',
                width: '100%',
                margin: 0,
                display: 'flex',
              }}
            >
              <div style={{ width: '40%' }}>
                <span style={{ marginBottom: 0, fontSize: '15px', display: 'block' }}>
                  {t('round_info.text.edited_x_percent_plural', {
                    percent: Math.floor((task.modifiedMeter / task.totalMeter) * 100),
                  })}
                </span>
                <span style={{ marginBottom: 0, fontSize: '15px' }}>{`${task.modifiedMeter}/${task.totalMeter}`}</span>
              </div>
              <Col style={{ width: '55%', padding: '0' }} xs="auto">
                <ProgressBar progressWidth={(task.modifiedMeter / task.totalMeter) * 100} color="lightblue" />
              </Col>
            </div>
          </div>
        )}
      </>
    );
  };

  disaffectAll = () => {
    const { dispatch, match, task } = this.props;
    const { params } = match;
    const { GestionnaireId } = params;
    dispatch(synchroActions.disaffectAllForRound(GestionnaireId, task.id));
  };

  render() {
    const {
      task,
      deathLabel,
      getAllCommunication,
      cancelOne,
      delLabel,
      switchToPartial,
      affects,
      desaffect,
      partiel,
      column,
      t,
    } = this.props;
    const fullLabels = _.concat(deathLabel.affectation, deathLabel.desaffectation).filter(el => el);
    const copyAffects = _.cloneDeep(affects).filter(el => el);
    return (
      <div style={{ padding: '0 0 0 10px', height: '100%' }} className="round-info-container">
        {fullLabels.length === 0 && copyAffects.length === 0 && (
          <div style={{ padding: '15px 0 15px 0', height: '100%' }}>
            <Row style={{ color: 'black', paddingBottom: '5px', height: '45%', alignItems: 'center' }}>
              <Col xs="3">
                <span style={{ marginBottom: 0, fontSize: '15px' }}>
                  {t('round_info.text.indexed_x_percent_plural', {
                    percent: Math.floor((task.indexedMeter / task.totalMeter) * 100),
                  })}
                </span>
              </Col>
              <Col style={{ width: '50%', padding: '0' }} xs="auto">
                <ProgressBar progressWidth={(task.indexedMeter / task.totalMeter) * 100} color="green" />
              </Col>
              <Col style={{ padding: 0, maxWidth: '8%', textAlign: 'right' }} xs="3">
                <span style={{ marginBottom: 0, fontSize: '15px' }}>{`${task.indexedMeter}/${task.totalMeter}`}</span>
              </Col>
            </Row>
            <Row style={{ color: 'black', alignItems: 'center', height: '45%' }}>
              <Col xs="3">
                <span style={{ marginBottom: 0, fontSize: '15px' }}>
                  {t('round_info.text.edited_x_percent_plural', {
                    percent: Math.floor((task.modifiedMeter / task.totalMeter) * 100),
                  })}
                </span>
              </Col>
              <Col style={{ width: '50%', padding: '0' }} xs="auto">
                <ProgressBar progressWidth={(task.modifiedMeter / task.totalMeter) * 100} color="lightblue" />
              </Col>
              <Col style={{ padding: 0, maxWidth: '8%', textAlign: 'right' }} xs="3">
                <span style={{ marginBottom: 0, fontSize: '15px' }}>{`${task.modifiedMeter}/${task.totalMeter}`}</span>
              </Col>
            </Row>
          </div>
        )}

        {(fullLabels.length > 0 || copyAffects.length > 0) && (
          <div className="label-list-container">
            <this.graphDisplay />
            <div style={{ width: '50%', borderLeft: '1px solid lightgrey' }}>
              <div className="labels-container" onScroll={(e: any) => e.stopPropagation()}>
                {fullLabels.length > 0 && (
                  <>
                    <span style={{ width: '100%', borderBottom: '1px solid lightgrey', marginTop: '5px' }}>
                      {t('all.text.waiting_action', { count: fullLabels.length })}
                    </span>
                    {fullLabels &&
                      fullLabels.map((el: any, index: number) => (
                        <Label
                          label={el}
                          cancelOne={delLabel}
                          switchToPartial={switchToPartial}
                          partiel={partiel}
                          column={column}
                        />
                      ))}
                  </>
                )}
                {copyAffects.length > 0 && (
                  <>
                    <div
                      className="flex-box"
                      style={{
                        width: '100%',
                        borderBottom: '1px solid lightgrey',
                        marginBottom: '5px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span>{t('device_info.text.x_loaded_round', { count: copyAffects.length })}</span>
                      <div className="red-cross" onClick={() => this.disaffectAll()}>
                        <div className="svg">
                          <SvgCroixNoir height="1.7em" fill="#dc3545" />
                        </div>
                        <span className="span">{t('tournee_ressources.column_exported_tooltip.unload_all')}</span>
                      </div>
                    </div>
                    {copyAffects.map(
                      (el: any) =>
                        el && (
                          <Label
                            label={el}
                            cancelOne={desaffect}
                            loaded
                            partiel={partiel}
                            switchToPartial={switchToPartial}
                            column={column}
                          />
                        )
                    )}
                  </>
                )}
              </div>
              {fullLabels.length > 0 && (
                <ValidateButtons getAllCommunication={getAllCommunication} cancelOne={cancelOne} online />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { synchro, synchrocom } = state;
  return {
    synchro,
    synchrocom,
  };
}

const mapping: any = connect(mapStateToProps)(RoundInfo);

const connectedTask = withRouter(mapping);
const tr = withTranslation()(connectedTask);
export default tr;
