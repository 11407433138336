import React from 'react';

const SvgPlanification = (props: any) => (
  <svg viewBox="0 0 79 78.4" {...props}>
    <g id="Calque_2" />
    <g id="Calque_1">
      <g>
        <g id="Layer_2_00000121244417282585914050000000770013682670239875_">
          <g>
            <path
              fill={props.fill}
              d="M47.5,71.7c-0.7,0.2-1.3,0.3-2,0.4c-1.7,0.3-2.9,2-2.5,3.7c0.2,0.8,0.6,1.6,1.3,2c0.7,0.5,1.5,0.7,2.4,0.5
					c0.8-0.1,1.6-0.3,2.4-0.5c1.7-0.4,2.7-2.1,2.3-3.8C50.9,72.3,49.2,71.2,47.5,71.7z"
            />
            <path
              fill={props.fill}
              d="M71,29.1c0.2,0.7,0.6,1.2,1.2,1.6c0.8,0.6,1.8,0.7,2.8,0.4c1.7-0.6,2.6-2.3,2-4c-0.3-0.8-0.5-1.5-0.8-2.3
					c-0.7-1.6-2.5-2.4-4.1-1.8c-1.6,0.7-2.4,2.5-1.8,4.1C70.5,27.8,70.7,28.5,71,29.1z"
            />
            <path
              fill={props.fill}
              d="M57.8,67.1c-0.6,0.4-1.1,0.7-1.7,1.1c-1.5,0.9-2,2.8-1.2,4.3c0.2,0.4,0.6,0.8,0.9,1c1,0.7,2.3,0.8,3.4,0.2
					c0.7-0.4,1.4-0.8,2-1.3c1.5-1,1.9-2.9,0.9-4.4C61.2,66.6,59.3,66.2,57.8,67.1z"
            />
            <path
              fill={props.fill}
              d="M79,38c-0.1-1.8-1.5-3.1-3.3-3c-1.8,0.1-3.1,1.5-3,3.3c0,0.7,0,1.3,0,2c0,1.1,0.5,2.1,1.3,2.7
					c0.5,0.4,1.1,0.6,1.8,0.6c1.8,0,3.2-1.4,3.2-3.1C79,39.6,79,38.8,79,38z"
            />
            <path
              fill={props.fill}
              d="M70.4,58.8C69,57.7,67,58,66,59.4c-0.4,0.5-0.8,1.1-1.3,1.6c-1.1,1.3-1,3.3,0.4,4.5
					c0.1,0.1,0.2,0.1,0.2,0.2c1.3,0.9,3.2,0.7,4.2-0.5c0.5-0.6,1-1.2,1.5-1.9C72.1,61.8,71.8,59.8,70.4,58.8z"
            />
            <path
              fill={props.fill}
              d="M75.1,47.3c-1.7-0.5-3.5,0.4-4,2.1c-0.2,0.6-0.4,1.3-0.7,1.9c-0.5,1.4,0,2.9,1.1,3.7
					c0.2,0.1,0.4,0.3,0.7,0.4c1.6,0.6,3.5-0.2,4.1-1.8c0.3-0.7,0.5-1.5,0.8-2.3C77.7,49.6,76.8,47.8,75.1,47.3z"
            />
            <path
              fill={props.fill}
              d="M33.6,72.1c-2.8-0.5-5.6-1.4-8.1-2.6c0,0-0.1,0-0.1,0c-0.6-0.3-1.2-0.6-1.8-0.9c0,0,0,0,0,0
					c-1.1-0.6-2.1-1.3-3.2-2c-14.9-10.5-18.5-31.2-8-46.2c2.3-3.2,5.1-6,8.1-8.1c0,0,0.1-0.1,0.1-0.1c10.9-7.5,25.6-8,37.2-0.3
					l-2.5,3.6c-0.7,1-0.3,1.7,0.9,1.6l10.8-1c1.2-0.1,1.9-1.2,1.6-2.3L65.8,3.4c-0.3-1.2-1.2-1.3-1.8-0.3l-2.5,3.6
					C53,1,42.8-1.2,32.7,0.6c-1,0.2-2,0.4-3,0.6c0,0,0,0,0,0c0,0-0.1,0-0.1,0C20.9,3.5,13.3,8.6,7.9,15.8c0,0.1-0.1,0.1-0.1,0.2
					c-0.2,0.2-0.4,0.5-0.5,0.7c-0.3,0.4-0.6,0.8-0.8,1.2c0,0.1-0.1,0.1-0.1,0.2C1.8,25-0.3,33.1,0,41.2c0,0,0,0.1,0,0.1
					c0,0.8,0.1,1.6,0.2,2.4c0,0.1,0,0.1,0,0.2c0.1,0.8,0.2,1.6,0.3,2.4c1.4,8.2,5.3,15.5,11,21.3c0,0,0,0,0,0c0,0,0,0,0,0
					c1.6,1.5,3.2,2.9,5.1,4.2c4.8,3.4,10.1,5.6,15.7,6.6c1.7,0.3,3.4-0.8,3.7-2.6C36.5,74.1,35.3,72.4,33.6,72.1z"
            />
            <path
              fill={props.fill}
              d="M37.5,13.8c-1.4,0-2.6,1.2-2.6,2.6V42l23.4,12.1c0.4,0.2,0.8,0.3,1.2,0.3c0.9,0,1.8-0.5,2.3-1.4
					c0.7-1.3,0.2-2.8-1.1-3.5L40.1,38.9V16.4C40.1,15,39,13.8,37.5,13.8z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgPlanification;
