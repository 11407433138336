import React, { Component } from 'react';
import _ from 'lodash';
import Slider from '@material-ui/core/Slider';
import { withTranslation } from 'react-i18next';

interface Props {
  handleLimitConso: Function;
  alarmTemplates: any;
  t: Function;
}

class RangeConfigurator extends Component<Props> {
  render() {
    const { handleLimitConso, alarmTemplates, t } = this.props;
    return (
      <div className="range-wrapper wrapper">
        <h5>{t('range_configurator.title.gap_consumption_percent')}</h5>
        <Slider
          defaultValue={
            _.get(alarmTemplates, 'content') ? alarmTemplates.content.dataSourceProperty.condition[0].conditionValue : 0
          }
          step={1}
          valueLabelDisplay="on"
          onChangeCommitted={(e, value) => handleLimitConso(value)}
          style={{ marginTop: '4vh' }}
          valueLabelFormat={x => <div>{x}%</div>}
        />
      </div>
    );
  }
}

const tr = withTranslation()(RangeConfigurator);
export default tr;
