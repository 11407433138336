import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { userActions } from '../_actions';
import logo from '../_svgs/logo_fd_vert.svg';
import { alert, dashboards } from '../_interfaces/reducers';
import { detect } from 'detect-browser';
import { LostPass } from '.';
import { withRouter } from 'react-router-dom';
import profilActions from '../_actions/profil.actions';
import { withTranslation } from 'react-i18next';
import initialProgressActions from '../initialisation/initialProgress.actions';
import _ from 'lodash';
import Lottie from 'react-lottie';
import animInitialJson from '../_lotties/animation_initialisation_cc.json';

interface Props {
  dispatch: Function;
  loggingIn: any;
  alert: alert;
  dashboards: dashboards;
  location: any;
  permissions: any;
  t: Function;
  justDisplay: boolean;
}

interface State {
  username: string;
  password: string;
  submitted: boolean;
  isLoading: boolean;
  intervalId: string;
}

class LoginPageComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // reset login status

    this.state = {
      username: '',
      password: '',
      submitted: false,
      isLoading: true,
      intervalId: '',
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { dispatch } = props;
    console.log('getDerivedStateFromProps I18N : ', props.t('all.user.logIn'));
    if (state.isLoading && _.isEqual(_.get(props, 'progress'), 'END')) {
      dispatch(profilActions.getVersion());
      dispatch(profilActions.getDefaultLanguage());

      clearInterval(state.intervalId);
      return { isLoading: false };
    }
  }

  componentDidMount() {
    const { dispatch, justDisplay } = this.props;
    if (!justDisplay) {
      dispatch(userActions.logout());
    }
    this.getProgress();
    window.addEventListener('keydown', this.handleKeyboardInput.bind(this));
  }

  isProgressFinish() {
    return _.isEqual(_.get(this, 'props.progress'), 'END');
  }

  getProgress() {
    const { dispatch } = this.props;
    return dispatch(initialProgressActions.getProgress());
  }

  handleKeyboardInput = e => {
    const code = e.code;
    const enter = 'Enter';
    if (code === enter) {
      this.handleSubmit();
    }
  };

  handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'username') {
      this.setState({
        username: value,
      });
    } else {
      this.setState({
        password: value,
      });
    }
  };

  handleSubmit = () => {
    this.setState({ submitted: true });
    const { username, password } = this.state;
    const { dispatch } = this.props;
    if (username && password) {
      dispatch(userActions.login(username.trim(), password.trim()));
    }
  };

  detectBrowser = () => {
    const browser = detect();
    // handle the case where we don't detect the browser
    switch (browser && browser.name) {
      case 'chrome':
      case 'firefox':
      case 'edge':
      case 'opera':
      case 'vivaldi':
        return false;
      default:
        return (
          <Fragment>
            <div className="alert-warning">
              <div style={{ padding: '15px', textAlign: 'center' }}>
                Votre navigateur n'est pas officiellement supporté, vous pourrez rencontrer certaines lenteurs ou
                comportements non désiré.
                <br />
                <a href={'https://www.google.com/intl/fr_fr/chrome/'}>Télécharger Google Chrome</a>
              </div>
            </div>
            <br />
          </Fragment>
        );
    }
  };

  render() {
    const { loggingIn, alert, dispatch, t } = this.props;
    const { username, password, submitted, isLoading } = this.state;

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animInitialJson,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <Fragment>
        <div className="container-login">
          <Row>
            <Col className="col-6 login-logo-container">
              <div className="logo">
                <img src={logo} alt="Logo" />
              </div>
            </Col>
            {isLoading ? (
              <Col style={{ left: '50%', 'margin-left': '-15px', 'text-align': 'center' }}>
                <Lottie options={defaultOptions} height={300} width={300} />
                <p style={{ color: 'white' }}>{_.get(this, 'props.progress')}</p>
              </Col>
            ) : (
              <Col className="col-6 login-form">
                <form name="form">
                  {this.detectBrowser() ? (
                    this.detectBrowser()
                  ) : (
                    <>
                      <div>{alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}</div>
                      <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                        <input
                          type="text"
                          className="form-control"
                          name="username"
                          placeholder={t('all.user.user')}
                          value={username}
                          onChange={this.handleChange}
                        />
                        {submitted && !username && <div className="help-block">{t('all.user.user_required')}</div>}
                      </div>
                      <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          placeholder={t('all.user.pwd')}
                          value={password}
                          onChange={this.handleChange}
                        />
                        {submitted && !password && <div className="help-block">Le mot de passe est obligatoire</div>}
                      </div>
                      <div className="form-group">
                        <Button color="primary" onClick={this.handleSubmit}>
                          {t('all.user.logIn')}
                        </Button>
                        {loggingIn}
                      </div>
                      <div className="lostPass">
                        <LostPass />
                      </div>
                    </>
                  )}
                  <div className="version">Version : {this.props.profil.version}</div>
                </form>
              </Col>
            )}
          </Row>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { initialProgress } = state;
  const progress = _.get(initialProgress, 'progress');
  const { loggingIn } = state.authentication;
  const { alert, dashboards, profil } = state;
  return {
    loggingIn,
    alert,
    dashboards,
    profil,
    progress,
  };
}

const clp: any = connect(mapStateToProps)(LoginPageComponent);
const connectedLoginPage = withRouter(clp);
const tr = withTranslation()(connectedLoginPage);
export default tr;
