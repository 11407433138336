import React, { useState } from 'react';

const Switch = (props: any) => {
  const { handleCheck, check } = props;
  const [checked, setChecked] = useState(check);

  function handleClick() {
    setChecked(!checked);
    handleCheck(!checked);
  }

  return (
    <div className={`izi-switch ${checked ? 'checked' : 'unchecked'}`}>
      <div className="circle" onClick={handleClick} />
    </div>
  );
};

export default Switch;
