const templateConstants = {
  GETALL_REQUEST: 'TEMPLATES_GETALL_REQUEST',
  GETALL_SUCCESS: 'TEMPLATES_GETALL_SUCCESS',
  GETALL_FAILURE: 'TEMPLATES_GETALL_FAILURE',

  GET_REQUEST: 'TEMPLATES_GET_REQUEST',
  GET_SUCCESS: 'TEMPLATES_GET_SUCCESS',
  GET_FAILURE: 'TEMPLATES_GET_FAILURE',

  SAVE_REQUEST: 'TEMPLATES_SAVE_REQUEST',
  SAVE_SUCCESS: 'TEMPLATES_SAVE_SUCCESS',
  SAVE_FAILURE: 'TEMPLATES_SAVE_FAILURE',

  UPDATE_REQUEST: 'TEMPLATES_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'TEMPLATES_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'TEMPLATES_UPDATE_FAILURE',

  DELETE_REQUEST: 'TEMPLATES_DELETE_REQUEST',
  DELETE_SUCCESS: 'TEMPLATES_DELETE_SUCCESS',
  DELETE_FAILURE: 'TEMPLATES_DELETE_FAILURE',

  GETALL_DATASOURCE_REQUEST: 'TEMPLATES_GETALL_DATASOURCE_REQUEST',
  GETALL_DATASOURCE_SUCCESS: 'TEMPLATES_GETALL_DATASOURCE_SUCCESS',
  GETALL_DATASOURCE_FAILURE: 'TEMPLATES_GETALL_DATASOURCE__FAILURE',

  CLEAR: 'TEMPLATES_CLEAR',
};

export default templateConstants;
