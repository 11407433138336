import React from 'react';

const SvgList = (props: any) => (
  <svg viewBox="0 0 200 200" {...props}>
    <path
      d="M52.1,87.5h54.4c4.4,0,7.9,3.6,7.9,7.9V109c0,4.4-3.6,7.9-7.9,7.9H52.1c-4.4,0-7.9-3.6-7.9-7.9V95.4
					C44.1,91,47.7,87.5,52.1,87.5z"
      fill={props.fill}
    />
    <path
      d="M7.9,43.4l13.6,0c4.4,0,7.9,3.6,7.9,7.9v13.6c0,4.4-3.6,7.9-7.9,7.9H7.9c-4.4,0-7.9-3.6-7.9-7.9l0-13.6
					C0,46.9,3.6,43.4,7.9,43.4z"
      fill={props.fill}
    />
    <path
      d="M52.1,131.6h54.4c4.4,0,7.9,3.6,7.9,7.9v13.6c0,4.4-3.6,7.9-7.9,7.9H52.1c-4.4,0-7.9-3.6-7.9-7.9v-13.6
					C44.1,135.2,47.7,131.6,52.1,131.6z"
      fill={props.fill}
    />
    <path
      d="M7.9,87.5h13.6c4.4,0,7.9,3.6,7.9,7.9V109c0,4.4-3.6,7.9-7.9,7.9H7.9c-4.4,0-7.9-3.6-7.9-7.9l0-13.6
					C0,91,3.6,87.5,7.9,87.5z"
      fill={props.fill}
    />
    <path
      d="M7.9,131.6h13.6c4.4,0,7.9,3.6,7.9,7.9v13.6c0,4.4-3.6,7.9-7.9,7.9H7.9c-4.4,0-7.9-3.6-7.9-7.9l0-13.6
					C0,135.2,3.6,131.6,7.9,131.6z"
      fill={props.fill}
    />
    <path
      d="M52.1,43.4h54.4c4.4,0,7.9,3.6,7.9,7.9v13.6c0,4.4-3.6,7.9-7.9,7.9H52.1c-4.4,0-7.9-3.6-7.9-7.9V51.3
					C44.1,46.9,47.7,43.4,52.1,43.4z"
      fill={props.fill}
    />
    <path
      d="M137.7,87.5h54.4c4.4,0,7.9,3.6,7.9,7.9V109c0,4.4-3.6,7.9-7.9,7.9h-54.4c-4.4,0-7.9-3.6-7.9-7.9V95.4
					C129.7,91,133.3,87.5,137.7,87.5z"
      fill={props.fill}
    />
    <path
      d="M137.7,131.6h54.4c4.4,0,7.9,3.6,7.9,7.9v13.6c0,4.4-3.6,7.9-7.9,7.9h-54.4c-4.4,0-7.9-3.6-7.9-7.9v-13.6
					C129.7,135.2,133.3,131.6,137.7,131.6z"
      fill={props.fill}
    />
    <path
      d="M137.7,43.4h54.4c4.4,0,7.9,3.6,7.9,7.9v13.6c0,4.4-3.6,7.9-7.9,7.9h-54.4c-4.4,0-7.9-3.6-7.9-7.9V51.3
					C129.7,46.9,133.3,43.4,137.7,43.4z"
      fill={props.fill}
    />
  </svg>
);

export default SvgList;
