import React, { Component, ReactElement } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Col, Input, Row, Button } from 'reactstrap';
import { cloneDeep, uniq as _uniq } from 'lodash';
import { convertDate } from './Chart';
import moment from 'moment';

const heightGraph = 500;

type Props = {
  headerWidget: any;
  bodyWidget: ReactElement<'Chart'> | ReactElement<'Fa'> | ReactElement<'div'>;
  isConfigured: boolean;
  dicoData: any;
  properties: {
    condition: any;
  };
};

type State = {
  bornes: {
    begin: string;
    end: string;
  };
};

/**
 * Le composant Widget en plein écran
 *
 * @export
 * @class FullScreenWidget
 * @extends {Component<Props, State>}
 */
export default class FullScreenWidget extends Component<Props, State> {
  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof FullScreenWidget
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      bornes: { begin: '', end: '' },
    };
  }

  static defaultProps = {
    properties: {
      condition: [],
    },
  };

  /**
   * Construit les sélecteurs de bornes début/fin
   *
   * @returns {JSX} Le bloc d'intervales
   * @method getIntervalTool
   * @memberof FullScreenWidget
   */
  getIntervalTool = () => {
    const { bornes } = this.state;
    const { dicoData, properties } = this.props;

    let listLabels: Array<any> = [];
    let listLabelsBeginning: Array<any> = [];
    let listLabelsEnding: Array<any> = [];
    let listLabelsOrigin: Array<any> = [];
    let data: any = dicoData;

    const xIsTime = properties.condition.find((elem: any) => elem.conditionType === 'DateInterval');
    if (dicoData && xIsTime) {
      let isAlarm = Object.keys(dicoData).find(it => 'data' === it);
      if (undefined !== isAlarm) {
        data = dicoData.data;
      }
      if (data.length > 0) {
        data.forEach((el: any) => {
          listLabelsOrigin.push(el.date);
        });
      }
      listLabels = _uniq(cloneDeep(listLabelsOrigin)).sort((a: any, b: any) => {
        return moment(a).diff(moment(b));
      });

      listLabels = listLabels.map(label => convertDate(label, xIsTime.conditionValue.zoom));
      if (bornes && bornes.begin && bornes.begin.length > 0)
        listLabelsBeginning = listLabels.slice(listLabels.findIndex(elem => elem === bornes.begin));

      if (bornes && bornes.end && bornes.end.length > 0)
        listLabelsEnding = listLabels.slice(0, listLabels.findIndex(elem => elem === bornes.end) + 1);
    }

    return (
      <div>
        {listLabelsOrigin.length > 0 && (
          <Row>
            <Col>
              <Input
                type="select"
                name="startSelect"
                id="startSelect"
                value={bornes.begin}
                onChange={e => {
                  bornes.begin = e.target.value;
                  this.setState({ bornes });
                }}
              >
                <option />
                {bornes && bornes.end && bornes.end.length > 0
                  ? listLabelsEnding.map(label => <option key={label}>{label}</option>)
                  : listLabels.map(label => <option key={label}>{label}</option>)}
              </Input>
            </Col>
            <FaArrowRight style={{ verticalAlign: 'middle' }} />
            <Col>
              <Input
                type="select"
                name="endSelect"
                id="endSelect"
                value={bornes.end}
                onChange={e => {
                  bornes.end = e.target.value;
                  this.setState({ bornes });
                }}
              >
                <option />
                {bornes && bornes.begin && bornes.begin.length > 0
                  ? listLabelsBeginning.map(label => <option key={label}>{label}</option>)
                  : listLabels.map(label => <option key={label}>{label}</option>)}
              </Input>
            </Col>
            <Button
              onClick={() => {
                this.setState({ bornes: { begin: '', end: '' } });
              }}
            >
              Reset
            </Button>
          </Row>
        )}
      </div>
    );
  };

  /**
   * Construit le composant en y ajoutant les
   * le bloc d'intervales
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof FullScreenWidget
   */
  render() {
    const { headerWidget, bodyWidget, isConfigured }: any = this.props;
    const { bornes } = this.state;
    return (
      <div className="widget-info-container" style={{ width: '70%', margin: 'auto', position: 'relative' }}>
        <div className={`container-widget container widget-${isConfigured ? 'ready' : 'unready'}`}>
          {headerWidget}
          <div style={{ height: heightGraph, marginTop: '20px' }}>
            <bodyWidget.type {...bodyWidget.props} {...{ bornes }} />
          </div>
          {this.getIntervalTool()}
        </div>
      </div>
    );
  }
}
