import React from 'react';

const SvgDesaffectation = (props: any) => (
  <svg viewBox="0 0 14.6 13.31" {...props}>
    <path
      d="M0,6.66l6.07,6.65v-4H7.36A7.24,7.24,0,0,0,14.6,2.1V0L14,.63A10.27,10.27,0,0,1,6.43,4H6.07V0Z"
      fill={props.fill}
    />
  </svg>
);

export default SvgDesaffectation;
