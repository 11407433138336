import React from 'react';

const SvgChartBarVerticalNotAvailable = (props: any) => (
  <svg viewBox="0 0 481 320" {...props}>
    <polygon points="192.8,145.2 192.8,145.2 160.8,145.2 160.8,191.2 192.8,169.9 " fill="none" />
    <path d="M196.8,232h-30.5l0,0L196.8,232c2.2,0,4-1.4,4-3.1V228C200.9,230.2,199.1,232,196.8,232z" fill={props.fill} />
    <polygon points="192.8,224 192.8,214.4 178.4,224 " fill={props.fill} />
    <polygon points="152.8,151.4 152.7,151.4 152.7,196.5 152.8,196.5 " fill={props.fill} />
    <polygon points="160.8,211.7 192.8,190.4 192.8,190.3 160.8,211.6 " fill={props.fill} />
    <rect
      x="152.7"
      y="216.9"
      transform="matrix(0.8321 -0.5547 0.5547 0.8321 -94.7041 121.172)"
      width="0"
      height="0.1"
    />
    <polygon points="224.9,169.1 257,147.8 257,147.7 224.9,169 " fill={props.fill} />
    <polygon points="224.9,224 257,224 257,171.8 224.9,193.1 " fill={props.fill} />
    <rect x="265" y="142.4" width="0" height="0.1" />
    <polygon points="265,227.6 265,227.6 265,166.5 265,166.5 " fill={props.fill} />
    <polygon points="265,122 265,116.5 265,116.5 265,122 " fill={props.fill} />
    <polygon points="257,127.3 257,107.6 224.9,107.6 224.9,107.6 224.9,148.6 " fill={props.fill} />
    <path
      d="M216.9,116.5c0-4.9,3.6-8.9,8-8.9V95.7H257v11.8h0c4.4,0,8,4,8,8.9V95.7c0-4.4-3.6-8-8-8h-32.1
	c-4.4,0-8,3.6-8,8v58.2l0,0V116.5z"
      fill={props.fill}
    />
    <path
      d="M261,232h-40.1c-2.2,0-4-2-4-4.4v-29.2l0,0V228c0,2.2,1.8,4,4,4L261,232c2.2,0,4-1.8,4-4v-0.4
	C265,230,263.2,232,261,232z"
      fill={props.fill}
    />
    <rect x="216.9" y="174.3" width="0" height="0.1" />
    <path
      d="M257,171.8V224h-32.1v-30.9l-8,5.3v29.2c0,2.5,1.8,4.4,4,4.4H261c2.2,0,4-2,4-4.4v-61.1L257,171.8z"
      fill={props.fill}
    />
    <path d="M224.9,107.6c-4.4,0-8,4-8,8.9v37.5l8-5.3V107.6z" fill={props.fill} />
    <path d="M257,107.6L257,107.6l0,19.7l8-5.3v-5.5C265,111.6,261.4,107.6,257,107.6z" fill={props.fill} />
    <polygon points="224.9,169 216.9,174.3 216.9,174.4 224.9,169.1 " fill={props.fill} />
    <polygon points="257,147.7 257,147.8 265,142.5 265,142.4 " fill={props.fill} />
    <path
      d="M160.8,145.2L160.8,145.2l0-49.5h32.1v49.5c4.4,0,8,2.8,8,6.2v13.2l0,0V95.7c0-4.4-3.6-8-8-8h-32.1
	c-4.4,0-8,3.6-8,8v55.7C152.8,148,156.4,145.2,160.8,145.2z"
      fill={props.fill}
    />
    <rect x="200.9" y="185" width="0" height="0.1" />
    <polygon points="200.9,228 200.9,228 200.9,209.1 200.9,209.1 " fill={props.fill} />
    <path d="M200.9,164.6v-13.2c0-3.4-3.6-6.2-8-6.2v24.7L200.9,164.6z" fill={props.fill} />
    <path d="M192.8,214.4v9.6h-14.5l-12.1,8h30.5c2.2,0,4-1.8,4-4v-18.9L192.8,214.4z" fill={props.fill} />
    <polygon points="160.8,211.6 152.8,216.9 152.8,217 160.8,211.7 " fill={props.fill} />
    <path d="M160.8,191.2v-46h0c-4.4,0-8,2.8-8,6.2v45.1L160.8,191.2z" fill={props.fill} />
    <polygon points="192.8,190.3 192.8,190.4 200.9,185.1 200.9,185 " fill={props.fill} />
    <polygon points="287.3,195.5 287.3,224 319.4,224 319.4,195.5 319.4,195.5 " fill={props.fill} />
    <path
      d="M323.4,232h-40.1c-2.2,0-4-1.8-4-4v-29.9c0,0,0,0,0,0v32.6c0,0.7,1.8,1.3,4,1.3L323.4,232c2.2,0,4-0.6,4-1.3
	V228C327.4,230.2,325.6,232,323.4,232z"
      fill={props.fill}
    />
    <path d="M287.3,95.7h17.2l12.1-8h-29.2c-4.4,0-8,3.6-8,8v16.7l8-5.3V95.7z" fill={props.fill} />
    <polygon points="279.3,132.9 279.3,133 287.3,127.6 287.3,127.5 " fill={props.fill} />
    <path d="M287.3,195.5L287.3,195.5l0-43.9l-8,5.3v41.1C279.3,196.7,282.9,195.5,287.3,195.5z" fill={props.fill} />
    <path d="M327.4,125l-8,5.3v65.2c4.4,0,8,1.2,8,2.6V228c0,0,0,0,0,0V125z" fill={props.fill} />
    <polygon points="319.4,106.3 327.4,101 327.4,100.9 319.4,106.2 " fill={props.fill} />
    <path
      d="M319.4,195.5V224h-32.1v-28.5h0c-4.4,0-8,1.2-8,2.6V228c0,2.2,1.8,4,4,4h40.1c2.2,0,4-1.8,4-4v-29.9
	C327.4,196.7,323.8,195.5,319.4,195.5z"
      fill={props.fill}
    />
    <polygon points="279.3,132.9 279.3,112.5 265,122 265,142.4 " fill={props.fill} />
    <polygon points="98.2,232.8 107.6,246.9 152.7,216.9 152.7,196.5 " fill={props.fill} />
    <polygon points="216.9,174.3 216.9,153.9 200.9,164.6 200.9,185 " fill={props.fill} />
    <polygon points="319.4,95.7 304.5,95.7 287.3,107.1 287.3,127.5 319.4,106.2 " fill={props.fill} />
    <path d="M327.4,95.7v5.2l44.3-29.4l-9.4-14.2l-45.7,30.4h2.8C323.8,87.7,327.4,91.3,327.4,95.7z" fill={props.fill} />
    <rect
      x="152.7"
      y="196.5"
      transform="matrix(0.8321 -0.5547 0.5547 0.8321 -89.0173 119.4502)"
      width="0"
      height="20.4"
    />
    <polygon points="192.8,190.3 192.8,169.9 160.8,191.2 160.8,211.6 " fill={props.fill} />
    <rect x="265" y="122" transform="matrix(0.8575 -0.5145 0.5145 0.8575 -30.2417 155.1757)" width="0" height="20.4" />
    <polygon points="224.9,169 257,147.7 257,127.3 224.9,148.6 " fill={props.fill} />
    <rect x="216.9" y="153.9" width="0" height="20.4" />
    <polygon points="265,142.4 265,122 257,127.3 257,147.7 " fill={props.fill} />
    <polygon points="224.9,148.6 216.9,153.9 216.9,174.3 224.9,169 " fill={props.fill} />
    <rect
      x="200.9"
      y="164.6"
      transform="matrix(0.8321 -0.5547 0.5547 0.8321 -63.2158 140.7684)"
      width="0"
      height="20.4"
    />
    <polygon points="192.8,190.3 200.9,185 200.9,164.6 192.8,169.9 " fill={props.fill} />
    <polygon points="160.8,191.2 152.8,196.5 152.8,216.9 160.8,211.6 " fill={props.fill} />
    <path d="M319.4,95.7v10.5l8-5.3v-5.2c0-4.4-3.6-8-8-8h-2.8l-12.1,8H319.4z" fill={props.fill} />
    <polygon points="287.3,127.5 287.3,107.1 279.3,112.5 279.3,132.9 " fill={props.fill} />
  </svg>
);

export default SvgChartBarVerticalNotAvailable;
