const sendExports = [
  {
    radioSerial: 'rthrhehrt',
    radioSensorPort: '3000',
    readDate: '2020-11-16',
    readRadioIndex: '1',
    readRadioPulse: '67',
  },
  {
    radioSerial: 'cljluilupId',
    radioSensorPort: '3001',
    readDate: '1980-11-16',
    readRadioIndex: '2',
    readRadioPulse: '108',
  },
  {
    radioSerial: 'eazeqsdc',
    radioSensorPort: '3002',
    readDate: '2014-11-16',
    readRadioIndex: '3',
    readRadioPulse: '10',
  },
  {
    radioSerial: 'try(rtyr',
    radioSensorPort: '3003',
    readDate: '2006-11-16',
    readRadioIndex: '4',
    readRadioPulse: '840',
  },
  {
    radioSerial: 'k§ysguyrg',
    radioSensorPort: '3004',
    readDate: '2018-11-16',
    readRadioIndex: '5',
    readRadioPulse: '259',
  },
];

export default sendExports;
