function getSearch(context: string) {
  let search = localStorage.getItem(context);
  if (search && null !== search) {
    return JSON.parse(search);
  }
  return {
    query: '',
    results: {},
  };
}

function setSearch(data: any, context: string) {
  const strData = JSON.stringify(data);
  localStorage.setItem(context, strData);
}

function removeSearch(context: string) {
  let search = localStorage.getItem(context);
  if (search && null !== search) {
    localStorage.removeItem(context);
  }
}

const storedSearchService = {
  getSearch,
  setSearch,
  removeSearch,
};

export default storedSearchService;
