import React from 'react';

const SvgPhoto = (props: any) => (
  <svg version="1.1" id="Calque_1" x="0px" y="0px" viewBox="0 0 33.1 26.1" {...props}>
    <g>
      <path
        fill={props.fill}
        d="M29.9,21.9l-7.2-7.5c-0.1-0.1-0.2-0.1-0.3,0l-5,4.4L11,11c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0.1L3.1,21.1
		v-18h26.8L29.9,21.9L29.9,21.9z M32.6,0h-32C0.2,0,0,0.2,0,0.5v25c0,0.3,0.2,0.5,0.5,0.5h32c0.3,0,0.5-0.2,0.5-0.5v-25
		C33.1,0.2,32.9,0,32.6,0"
      />
      <path
        fill={props.fill}
        d="M22,10.7c1.4,0,2.6-1.2,2.6-2.6S23.4,5.6,22,5.6c-1.4,0-2.6,1.2-2.6,2.6S20.6,10.7,22,10.7"
      />
    </g>
  </svg>
);

export default SvgPhoto;
