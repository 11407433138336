import React from 'react';

const SvgCleVerte = (props: any) => (
  <svg viewBox="0 0 105.77 108.11" {...props}>
    <path
      className="cls-1"
      d="M105.77,34.1A34.13,34.13,0,0,0,85,2.68,33.71,33.71,0,0,0,71.67,0,34.09,34.09,0,0,0,41.78,50.5L1,91.28A3.34,3.34,0,0,0,.3,95a2.22,2.22,0,0,0,.53.76L10,104.93a2.67,2.67,0,0,0,.76.53,3.34,3.34,0,0,0,3.74-.66l5.65-5.65,8.13,8.13a2.67,2.67,0,0,0,.76.53,3.32,3.32,0,0,0,3.74-.66l9-9a2.58,2.58,0,0,0,.53-.77,3.29,3.29,0,0,0-.66-3.73l-8-8L55.25,64a34.1,34.1,0,0,0,50.52-29.9Zm-22.15,12a16.94,16.94,0,1,1,4.94-12A16.84,16.84,0,0,1,83.62,46.06Z"
    />
    fill={props.fill}
    stroke={props.stroke}
  </svg>
);

export default SvgCleVerte;
