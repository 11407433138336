import React from 'react';

const SvgFlagFr = (props: any) => (
  <svg viewBox="0 0 512 512" {...props}>
    <circle fill="#F0F0F0" cx="256" cy="256" r="256" />
    <path fill="#D80027" d="M512,256c0-110.1-69.5-203.9-167-240.1v480.2C442.5,459.9,512,366.1,512,256z" />
    <path fill="#0052B4" d="M0,256c0,110.1,69.5,203.9,167,240.1V15.9C69.5,52.1,0,145.9,0,256z" />
  </svg>
);

export default SvgFlagFr;
