import React from 'react';

const AttentionRounded = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.89 79.43" {...props}>
    <g>
      <path d="m43.45,55.83c-2.43,0-4.47,2.04-4.47,4.47s2.04,4.47,4.47,4.47,4.47-2.04,4.36-4.36c.11-2.55-1.91-4.57-4.36-4.57Z" />
      <path d="m84.78,71.66c2.8-4.84,2.82-10.61.04-15.43L56.84,7.78C54.07,2.9,49.07,0,43.46,0s-10.61,2.91-13.38,7.75L2.08,56.26c-2.79,4.88-2.77,10.68.05,15.52,2.79,4.79,7.77,7.66,13.34,7.66h55.87c5.59,0,10.61-2.91,13.43-7.79Zm-6.07-3.5c-1.55,2.68-4.3,4.27-7.38,4.27H15.46c-3.04,0-5.77-1.55-7.29-4.18-1.54-2.66-1.55-5.84-.02-8.52L36.16,11.24c1.52-2.66,4.23-4.23,7.31-4.23s5.79,1.59,7.31,4.25l27.99,48.48c1.5,2.61,1.48,5.75-.05,8.41Z" />
      <path d="m42.34,24.48c-2.13.61-3.45,2.54-3.45,4.88.11,1.41.2,2.84.3,4.25.3,5.38.61,10.65.91,16.02.11,1.82,1.52,3.14,3.34,3.14s3.25-1.41,3.34-3.25c0-1.11,0-2.13.11-3.25.2-3.45.41-6.89.61-10.34.11-2.23.3-4.47.41-6.7,0-.8-.11-1.52-.41-2.23-.91-2-3.04-3.02-5.16-2.52Z" />
    </g>
    <path d="m56.84,7.77c-2.77-4.88-7.77-7.77-13.38-7.77s-10.61,2.91-13.38,7.75L2.08,56.25c-2.79,4.88-2.77,10.68.05,15.52,2.79,4.79,7.77,7.66,13.34,7.66h55.87c5.59,0,10.61-2.91,13.43-7.79,2.8-4.84,2.82-10.61.04-15.43L56.84,7.77Zm21.86,60.38c-1.55,2.68-4.3,4.27-7.38,4.27H15.46c-3.04,0-5.77-1.55-7.29-4.18-1.54-2.66-1.55-5.84-.02-8.52L36.16,11.24c1.52-2.66,4.23-4.23,7.31-4.23s5.79,1.59,7.31,4.25l27.99,48.48c1.5,2.61,1.48,5.75-.05,8.41Z" />
  </svg>
);

export default AttentionRounded;
