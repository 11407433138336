import React from 'react';

const SvgMonitorBlanc = (props: any) => (
  <svg viewBox="0 0 20.95 17.19" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          d="M21,1a1,1,0,0,0-1-1H1A1,1,0,0,0,0,1V13.07H0v1.43a1,1,0,0,0,1,1H7.3l-.17.68H5.57a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h9.82a.51.51,0,0,0,.5-.5.5.5,0,0,0-.5-.5H13.82l-.16-.68H20a1,1,0,0,0,1-1V13.09h0ZM20,1V12.57H1V1ZM12.79,16.19H8.16l.17-.68h4.3ZM1,14.52v-.93H20v.92Z"
          fill={props.fill}
        />
      </g>
    </g>
  </svg>
);
export default SvgMonitorBlanc;
