import React from 'react';

const SvgArretCompteurBleu = (props: any) => (
  <svg viewBox="0 0 19.06 21.07" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M19,17.5h-.28a.2.2,0,0,0-.21-.18.2.2,0,0,0-.18.18h0a.19.19,0,0,0-.21-.18.2.2,0,0,0-.17.18h0a.19.19,0,0,0-.38,0H15.23l.65-3.12a.49.49,0,0,0-.49-.49H11.21l2.31,2.31a1,1,0,0,1,0,1.3s0,0,0,0a1,1,0,0,1-1.34,0l0,0h0L9.31,14.68,6.49,17.5l0,0a1,1,0,0,1-1.34,0l0,0h0a1,1,0,0,1,0-1.3l2.31-2.31H3.68a.49.49,0,0,0-.49.49l.65,3.12H1.44a.19.19,0,0,0-.21-.18.2.2,0,0,0-.17.18h0a.19.19,0,0,0-.21-.18.2.2,0,0,0-.17.18h0a.19.19,0,0,0-.21-.18.18.18,0,0,0-.17.18H.05a.05.05,0,0,0,0,.05v2.27a0,0,0,0,0,.05.05H.53l0,.26a.19.19,0,0,0,.19.17h0a.18.18,0,0,0,.17-.19h0v0a.19.19,0,0,0,.19.17h0a.19.19,0,0,0,.17-.19h0v0a.19.19,0,0,0,.19.17h0a.19.19,0,0,0,.17-.21l0-.23H4.32l.15.71a.49.49,0,0,0,.49.49h9.15a.49.49,0,0,0,.49-.49l.15-.71h3.07l0,.26a.19.19,0,0,0,.19.17h0a.18.18,0,0,0,.17-.19h0v0a.18.18,0,0,0,.18.17h0a.19.19,0,0,0,.17-.19h0v0a.19.19,0,0,0,.19.17h0A.2.2,0,0,0,19,20.1l0-.23H19a0,0,0,0,0,0-.05V17.55S19,17.5,19,17.5Z"
        />
        <path
          fill={props.fill}
          d="M2.51,13.34H8L5.11,10.48A.95.95,0,0,1,6.45,9.14L9.31,12l2.87-2.86a.95.95,0,0,1,1.34,1.34l-2.86,2.86h5.9c.63,0,1.1-.82,1-1.55a21.7,21.7,0,0,0-2.58-6.37c-.19-.29-.48-.66-.78-.66H4.92c-.3,0-.59.31-.77.61a21.48,21.48,0,0,0-2.59,6.35C1.41,12.46,1.88,13.34,2.51,13.34Z"
        />
        <path
          fill={props.fill}
          d="M5.53,3.72a1,1,0,0,0,.8-.36,1.53,1.53,0,0,0,.31-1v-1a1.53,1.53,0,0,0-.31-1A1,1,0,0,0,5.53,0a1,1,0,0,0-.82.37,1.53,1.53,0,0,0-.3,1v1a1.53,1.53,0,0,0,.3,1A1,1,0,0,0,5.53,3.72ZM5.11,1.3A1.24,1.24,0,0,1,5.22.74.33.33,0,0,1,5.53.55a.34.34,0,0,1,.3.19,1.24,1.24,0,0,1,.11.56V2.41A1.25,1.25,0,0,1,5.83,3a.33.33,0,0,1-.3.2A.35.35,0,0,1,5.22,3a1.25,1.25,0,0,1-.11-.57Z"
        />
        <path
          fill={props.fill}
          d="M8.2,3.72A1,1,0,0,0,9,3.36a1.53,1.53,0,0,0,.3-1v-1a1.53,1.53,0,0,0-.3-1A1,1,0,0,0,8.2,0a1,1,0,0,0-.81.37,1.48,1.48,0,0,0-.31,1v1a1.48,1.48,0,0,0,.31,1A1,1,0,0,0,8.2,3.72ZM7.79,1.3a1.25,1.25,0,0,1,.1-.56A.34.34,0,0,1,8.2.55a.32.32,0,0,1,.3.19,1.24,1.24,0,0,1,.11.56V2.41A1.13,1.13,0,0,1,8.5,3a.33.33,0,0,1-.3.2A.36.36,0,0,1,7.89,3a1.27,1.27,0,0,1-.1-.57Z"
        />
        <path
          fill={props.fill}
          d="M10.87,3.72a1,1,0,0,0,.81-.36,1.53,1.53,0,0,0,.3-1v-1a1.53,1.53,0,0,0-.3-1A1,1,0,0,0,10.87,0a1,1,0,0,0-.81.37,1.53,1.53,0,0,0-.31,1v1a1.53,1.53,0,0,0,.31,1A1,1,0,0,0,10.87,3.72ZM10.46,1.3a1.25,1.25,0,0,1,.1-.56.35.35,0,0,1,.31-.19.32.32,0,0,1,.3.19,1.24,1.24,0,0,1,.11.56V2.41a1.27,1.27,0,0,1-.1.57.34.34,0,0,1-.62,0,1.27,1.27,0,0,1-.1-.57Z"
        />
        <path
          fill={props.fill}
          d="M13.55,3.72a1,1,0,0,0,.8-.36,1.53,1.53,0,0,0,.31-1v-1a1.53,1.53,0,0,0-.31-1A1,1,0,0,0,13.54,0a1,1,0,0,0-.81.37,1.53,1.53,0,0,0-.3,1v1a1.53,1.53,0,0,0,.3,1A1,1,0,0,0,13.55,3.72ZM13.13,1.3a1.12,1.12,0,0,1,.11-.56.33.33,0,0,1,.6,0A1.12,1.12,0,0,1,14,1.3V2.41a1.27,1.27,0,0,1-.1.57.34.34,0,0,1-.3.2.34.34,0,0,1-.31-.2,1.13,1.13,0,0,1-.11-.57Z"
        />
      </g>
    </g>
  </svg>
);
export default SvgArretCompteurBleu;
