export default {
  GET_ALL_REQUEST: 'GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'GET_ALL_SUCCESS',
  GET_ONE_BY_ID_SUCCESS: 'GET_ONE_BY_ID_SUCCESS',
  GET_ALL_FAILURE: 'GET_ALL_FAILURE',
  SAVE_REQUEST: 'SAVE_REQUEST',
  SAVE_SUCCESS: 'SAVE_SUCCESS',
  SAVE_FAILURE: 'SAVE_FAILURE',
  LAUNCH_REQUEST: 'LAUNCH_REQUEST',
  LAUNCH_SUCCESS: 'LAUNCH_SUCCESS',
  LAUNCH_FAILURE: 'LAUNCH_FAILURE',
};
