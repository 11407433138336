import JwtDecode from 'jwt-decode';
import { User } from '../_entities/user';

/**
 * Décode le token
 *
 * @method userHelper Décode le token et donne un utilisateur
 * @returns {boolean} faux si l'utilisateur doit changer de mot de passe
 */
export default function userPasswordHelper(): boolean {
  if (localStorage.getItem('token')) {
    const token: any = localStorage.getItem('token');
    const decoded: any = JwtDecode(token);
    const { user } = decoded;
    return user.accountPasswordChange;
  }
  return true;
}
