import React from 'react';

const SvgMap = (props: any) => (
  <svg viewBox="0 0 200 200" {...props}>
    <rect x="62.8" y="101" className="st1" width="6.9" height="17.1" />
    <rect x="62.9" y="60.3" className="st1" width="6.9" height="17.1" />
    <path
      d="M193.7,165.1l-56.8,33.3v-22.3h-6.2v22.7l-60.7-37l-0.2-0.1v-24.3h-6.2v24.3l-0.2,0.1L6.3,196.7V49.1
				l57.3-28.5v22.5h6.2V21.6l29.3,18.5c-6.2,7.5-10,17.1-10,27.5c0,4.1,0.5,8,1.6,11.7c0.4,1.5,0.9,3,1.5,4.4l1.4,3l40.2,72.6
				l40.3-72.8l1.2-2.7c0.6-1.5,1.2-3,1.6-4.6c1-3.7,1.6-7.6,1.6-11.7c0-10.6-3.9-20.3-10.3-27.9l25.6-16.1V165.1z M133.8,30
				c21.2,0,38.4,16.8,38.4,37.5c0,3.4-0.5,6.8-1.4,10.1c-0.4,1.3-0.8,2.7-1.3,3.7l-1.1,2.5l-34.7,62.6L99.2,83.9l-1.2-2.6
				c-0.5-1.2-0.9-2.5-1.3-3.8c-0.9-3.2-1.4-6.6-1.4-10C95.4,46.8,112.6,30,133.8,30 M163.7,35.1c-7.9-7-18.4-11.3-29.9-11.3
				c-11.7,0-22.4,4.5-30.4,11.7l-37-23.3L0,45.3v162.4l66.7-40.6l66.7,40.6l66.7-39.1V12.2L163.7,35.1z"
      fill={props.fill}
    />
    <path
      d="M193.7,165.1l-56.8,33.3v-22.3h-6.2v22.7l-60.7-37l-0.2-0.1v-24.3h-6.2v24.3l-0.2,0.1L6.3,196.7V49.1
				l57.3-28.5v22.5h6.2V21.6l29.3,18.5c-6.2,7.5-10,17.1-10,27.5c0,4.1,0.5,8,1.6,11.7c0.4,1.5,0.9,3,1.5,4.4l1.4,3l40.2,72.6
				l40.3-72.8l1.2-2.7c0.6-1.5,1.2-3,1.6-4.6c1-3.7,1.6-7.6,1.6-11.7c0-10.6-3.9-20.3-10.3-27.9l25.6-16.1V165.1z M133.8,30
				c21.2,0,38.4,16.8,38.4,37.5c0,3.4-0.5,6.8-1.4,10.1c-0.4,1.3-0.8,2.7-1.3,3.7l-1.1,2.5l-34.7,62.6L99.2,83.9l-1.2-2.6
				c-0.5-1.2-0.9-2.5-1.3-3.8c-0.9-3.2-1.4-6.6-1.4-10C95.4,46.8,112.6,30,133.8,30z M163.7,35.1c-7.9-7-18.4-11.3-29.9-11.3
				c-11.7,0-22.4,4.5-30.4,11.7l-37-23.3L0,45.3v162.4l66.7-40.6l66.7,40.6l66.7-39.1V12.2L163.7,35.1z"
      fill={props.fill}
    />
    <path
      d="M134.8,52.9c6.6,0,11.9,5.4,11.9,11.9s-5.4,11.9-11.9,11.9c-6.6,0-11.9-5.4-11.9-11.9S128.2,52.9,134.8,52.9
				 M134.8,82.3c9.6,0,17.5-7.8,17.5-17.4c0-9.6-7.8-17.5-17.5-17.5c-9.6,0-17.4,7.8-17.4,17.5C117.3,74.5,125.2,82.3,134.8,82.3"
      fill={props.fill}
    />
    <path
      d="M134.8,52.9c6.6,0,11.9,5.4,11.9,11.9s-5.4,11.9-11.9,11.9c-6.6,0-11.9-5.4-11.9-11.9S128.2,52.9,134.8,52.9
				z M134.8,82.3c9.6,0,17.5-7.8,17.5-17.4c0-9.6-7.8-17.5-17.5-17.5c-9.6,0-17.4,7.8-17.4,17.5C117.3,74.5,125.2,82.3,134.8,82.3z"
      fill={props.fill}
    />
  </svg>
);

export default SvgMap;
