const synchroComConstants = {
  GETCOMMUNICATION_REQUEST: 'GETCOMMUNICATION_REQUEST',
  GETCOMMUNICATION_SUCCESS: 'GETCOMMUNICATION_SUCCESS',
  GETCOMMUNICATION_FAILURE: 'GETCOMMUNICATION_FAILURE',

  GET_ROUNDS_REQUEST: 'GET_ROUNDS_REQUEST',
  GET_ROUNDS_SUCCESS: 'GET_ROUNDS_SUCCESS',
  GET_ROUNDS_FAILURE: 'GET_ROUNDS_FAILURE',

  UPDATELOADER_SUCCESS: 'UPDATE_LOADER_SUCCESS',

  CLEAR: 'SYNCHROCOM_CLEAR',
};

export default synchroComConstants;
