import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import Lock from '../SvgComponents/Lock';
import CadenasOuvert from '../SvgComponents/CadenasOuvert';
import NoFilled from '../Message/NoFilled';
import Mapbox from '../Mapbox/components/Mapbox';

const InfoMap = (props: any) => {
  const { t, sensor, locations } = props;
  const valveStatus = _.get(JSON.parse(_.get(sensor, 'sensor.lastData.sndData', '{}')), 'valveOpen');
  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);

  useEffect(() => {
    setLng(_.get(locations, 'fetchedLocation.content.gpsPosition.lng'));
    setLat(_.get(locations, 'fetchedLocation.content.gpsPosition.lat'));
  }, [locations]);

  return (
    <div className="info-map" style={{ height: '340px' }}>
      <div className="info-pdi" style={{ width: '40%', marginRight: '20px', height: '100%' }}>
        <div style={{ height: '100%' }}>
          <div className="presentation-container bi-container" style={{ marginBottom: 0, height: '100%' }}>
            <div className="presentation-header" style={{ backgroundSize: '800px' }}>
              <div className="ref-pdi">
                <span className="presentation-title">{t('sensor.text.serial')}</span>
                <span className="presentation-main-title">{_.get(sensor, 'sensor.info.sensorSerial')}</span>
              </div>
            </div>
            <div className="presentation-body">
              <br />
              <div
                className="row meterInfo"
                style={{ marginLeft: '10px', maxWidth: '90%', display: 'flex', flexDirection: 'row' }}
              >
                <div className=" picto" style={{ marginTop: '10px', padding: '0 15px' }}>
                  {_.isUndefined(valveStatus) ? (
                    <div />
                  ) : valveStatus ? (
                    <CadenasOuvert fill="#31c6b3" height="3em" width="3em" />
                  ) : (
                    <Lock fill="#31c6b3" height="3em" width="3em" />
                  )}{' '}
                </div>
                <div className="" style={{ flex: 1, padding: '0 15px' }}>
                  <p>
                    <span className="infoLibelle">{t('all.text.state')}</span> <br />
                    <div className="infoDisplay" style={{ overflowWrap: 'break-word' }}>
                      {_.isUndefined(valveStatus) ? (
                        <NoFilled />
                      ) : valveStatus ? (
                        t('all.text.open')
                      ) : (
                        t('all.text.close')
                      )}
                    </div>
                  </p>
                </div>
              </div>
              <div
                className="row meterInfo"
                style={{ marginLeft: '10px', maxWidth: '90%', display: 'flex', flexDirection: 'row' }}
              >
                <div className=" picto" style={{ marginTop: '10px', padding: '0 15px' }} />
                <div className="" style={{ flex: 1, padding: '0 15px' }}>
                  <p>
                    <span className="infoLibelle">{t('sensor.text.sensor_type')}</span> <br />
                    <div className="infoDisplay" style={{ overflowWrap: 'break-word' }}>
                      {t(`sensor.type.${_.get(sensor, 'sensor.info.type')}`)}
                    </div>
                  </p>
                </div>
              </div>
              <div
                className="row meterInfo"
                style={{ marginLeft: '10px', maxWidth: '90%', display: 'flex', flexDirection: 'row' }}
              >
                <div className=" picto" style={{ marginTop: '10px', padding: '0 15px' }} />
                <div className="" style={{ flex: 1, padding: '0 15px' }}>
                  <p>
                    <span className="infoLibelle">{t('telereleveinfo.text.deveui')}</span> <br />
                    <div className="infoDisplay" style={{ overflowWrap: 'break-word' }}>
                      {_.get(sensor, 'sensor.info.sensorDevEUI')}
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="presentation-container-map-meter bi-container presentation-container-map"
        style={{ backgroundColor: 'lightgrey' }}
      >
        {lng && lat ? (
          <Mapbox
            initialMap={{ baseLng: lng, baseLat: lat, baseZoom: 15 }}
            style={{ width: '100%', height: '34.5vh' }}
            homeMarker
          />
        ) : (
          <h2 style={{ margin: 'auto', backgroundColor: 'lightgrey' }}>{t('fiche_detail.title.no_gps')}</h2>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { sensor, locations } = state;
  return {
    sensor,
    locations,
  };
}

export default withTranslation()(connect(mapStateToProps)(InfoMap));
