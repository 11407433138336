import React from 'react';

const SvgAlarmeVert = (props: any) => (
  <svg viewBox="0 0 24.73 29.77" {...props}>
    <path
      d="M12.37 29.77A3.42 3.42 0 0 1 9 26.36a.67.67 0 0 1 .66-.66h5.51a.66.66 0 0 1 .65.66 3.41 3.41 0 0 1-3.45 3.41zm-2-2.76a2.1 2.1 0 0 0 4 0zm13.71-2.28H.65a.65.65 0 0 1-.65-.65V22.7a.66.66 0 0 1 .19-.46l2.57-2.57v-7.3a9.43 9.43 0 0 1 6.89-9.18v-.47a2.72 2.72 0 0 1 5.44 0v.47A9.43 9.43 0 0 1 22 12.37v7.3l2.57 2.57a.66.66 0 0 1 .19.46v1.38a.65.65 0 0 1-.68.65zM1.31 23.42h22.11V23l-2.56-2.6a.62.62 0 0 1-.19-.46v-7.57a8.18 8.18 0 0 0-6.39-8 .64.64 0 0 1-.5-.63v-1a1.41 1.41 0 1 0-2.82 0v1a.65.65 0 0 1-.5.63 8.17 8.17 0 0 0-6.39 8v7.57a.66.66 0 0 1-.19.46L1.31 23z"
      fill={props.fill}
      data-name="Calque 2"
    />
  </svg>
);

export default SvgAlarmeVert;
