import React from 'react';

const SvgSimpleArrowDroite = (props: any) => (
  <svg viewBox="0 0 61.8 109.6" {...props}>
    <g id="Calque_2_00000159454085299009707980000001570018915012497068_">
      <g id="pictos">
        <path
          fill={props.fill}
          d="M7.4,109.6c-4.1,0-7.4-3.3-7.4-7.5c0-2,0.8-3.8,2.2-5.2l41.7-42.1L2.2,12.6C-0.7,9.7-0.6,5,2.3,2.1
			c2.9-2.8,7.5-2.8,10.4,0.1l46.9,47.4c2.9,2.9,2.9,7.6,0,10.5l-46.9,47.4C11.3,108.8,9.4,109.6,7.4,109.6z"
        />
      </g>
    </g>
  </svg>
);

export default SvgSimpleArrowDroite;
