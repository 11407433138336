import { eachDayOfInterval } from 'date-fns';
import moment from 'moment';

export function getDaysInInterval(start: Date, end: Date) {
  let result: number = 0;
  const interval = { start, end };
  if (start && end && moment(start).isSameOrBefore(end)) {
    result = eachDayOfInterval(
      interval || {
        start: new Date(
          moment()
            .add(-1, 'week')
            .toISOString(false)
        ),
        end: new Date(moment().toISOString(false)),
      }
    ).length;
  }
  if (result > 1) result -= 1;
  return result;
}
