import { maskConstants } from '../_constants';
import { maskService } from '../_services';
import meterActions from '../_actions/meter.actions';
import history from '../_helpers/history';

function getMasks(locationId: any) {
  function request() {
    return { type: maskConstants.GET_MASKS_REQUEST };
  }
  function success(masks: Object) {
    return { type: maskConstants.GET_MASKS_SUCCESS, masks };
  }
  function failure(error: Object) {
    return { type: maskConstants.GET_MASKS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    maskService
      .getMasks(locationId)
      .then((masks: Object) => dispatch(success(masks)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getMask(id: number) {
  function request() {
    return { type: maskConstants.GET_ONE_MASK_REQUEST };
  }
  function success(masks: Object) {
    return { type: maskConstants.GET_ONE_MASK_SUCCESS, masks };
  }
  function failure(error: Object) {
    return { type: maskConstants.GET_ONE_MASK_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    maskService
      .getMask(id)
      .then((masks: Object) => dispatch(success(masks)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getMaskInfo(id: number) {
  function request() {
    return { type: maskConstants.GET_MASK_INFO_REQUEST };
  }
  function success(maskInfo: Object) {
    return { type: maskConstants.GET_MASK_INFO_SUCCESS, maskInfo };
  }
  function failure(error: Object) {
    return { type: maskConstants.GET_MASK_INFO_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    maskService
      .getMaskInfo(id)
      .then((maskInfo: Object) => dispatch(success(maskInfo)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function deleteMask(id: number) {
  function request() {
    return { type: maskConstants.DELETE_MASKS_REQUEST, maskId: id };
  }
  function success(maskId: Object) {
    return { type: maskConstants.DELETE_MASKS_SUCCESS, maskId };
  }
  function failure(error: Object) {
    return { type: maskConstants.DELETE_MASKS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    maskService
      .deleteMask(id)
      .then((maskId: Object) => dispatch(success(maskId)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function createMask(
  name: string,
  startDate: string,
  endDate: string,
  pdiList: Array<number>,
  filterClause: string | null | any,
  alarmList: Array<number>,
  locationCode: string,
  mode: string,
  locId: any
) {
  function request() {
    return { type: maskConstants.CREATE_MASKS_REQUEST };
  }
  function success(masks: Object) {
    return { type: maskConstants.CREATE_MASKS_SUCCESS, masks };
  }
  function failure(error: Object) {
    return { type: maskConstants.CREATE_MASKS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    maskService
      .createMask(name, startDate, endDate, pdiList, filterClause, alarmList, locationCode, mode)
      .then((masks: Object) => {
        history.push(`/locations/${locId}/mask`);
        dispatch(success(masks));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function updateMask(
  id: number,
  name: string,
  startDate: string,
  endDate: string,
  pdiList: Array<number>,
  filterClause: string,
  alarmList: Array<number>,
  locationCode: string,
  mode: string
) {
  function request() {
    return { type: maskConstants.UPDATE_MASKS_REQUEST };
  }
  function success(masks: Object) {
    return { type: maskConstants.UPDATE_MASKS_SUCCESS, masks };
  }
  function failure(error: Object) {
    return { type: maskConstants.UPDATE_MASKS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    maskService
      .updateMask(id, name, startDate, endDate, pdiList, filterClause, alarmList, locationCode, mode)
      .then((masks: Object) => dispatch(success(masks)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getAvailableAlarms() {
  function request() {
    return { type: maskConstants.GET_AVAILABLE_ALARMS_REQUEST };
  }
  function success(alarms: Object) {
    return { type: maskConstants.GET_AVAILABLE_ALARMS_SUCCESS, alarms };
  }
  function failure(error: Object) {
    return { type: maskConstants.GET_AVAILABLE_ALARMS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    maskService
      .getAvailableAlarms()
      .then((alarms: Object) => dispatch(success(alarms)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getMaskPdiAlarms(PdiId: number, maskId: number) {
  function request() {
    return { type: maskConstants.GET_MASK_PDI_ALARMS_REQUEST };
  }
  function success(maskPdiAlarms: Object) {
    return { type: maskConstants.GET_MASK_PDI_ALARMS_SUCCESS, maskPdiAlarms };
  }
  function failure(error: Object) {
    return { type: maskConstants.GET_MASK_PDI_ALARMS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    maskService
      .getMaskPdiAlarms(PdiId, maskId)
      .then((maskPdiAlarms: Object) => dispatch(success(maskPdiAlarms)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function createMaskFromChart(
  name: string,
  startDate: string,
  endDate: string,
  pdiList: Array<number>,
  filterClause: null,
  alarmList: Array<string>,
  locationCode: string,
  mode: string,
  interval?: any
) {
  function request() {
    return { type: maskConstants.CREATE_FROMCHART_MASKS_REQUEST };
  }
  function success(mask: Object) {
    return { type: maskConstants.CREATE_FROMCHART_MASKS_SUCCESS, mask };
  }
  function failure(error: Object) {
    return { type: maskConstants.CREATE_MASKS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    maskService
      .createMaskFromChart(name, startDate, endDate, pdiList, filterClause, alarmList, locationCode, mode)
      .then((mask: Object) => {
        dispatch(meterActions.getAllMetersFromPdi(pdiList[0], locationCode, interval));
        dispatch(success(mask));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getMaskByPdiId(PdiId: number, rndId: number) {
  function request() {
    return { type: maskConstants.GET_MASK_PDI_REQUEST };
  }
  function success(masks: Object) {
    return { type: maskConstants.GET_MASK_PDI_SUCCESS, masks };
  }
  function failure(error: Object) {
    return { type: maskConstants.GET_MASK_PDI_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    maskService
      .getMaskByPdiId(PdiId, rndId)
      .then((maskPdiAlarms: Object) => dispatch(success(maskPdiAlarms)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function desactivMask(alarmName: any) {
  function success(alarmName: any) {
    return { type: maskConstants.DESAC_MASK_SUCCESS, alarmName };
  }

  return (dispatch: Function) => {
    dispatch(success(alarmName));
  };
}

function activMask(alarmName: any) {
  function success(alarmName: any) {
    return { type: maskConstants.ACTIV_MASK_SUCCESS, alarmName };
  }

  return (dispatch: Function) => {
    dispatch(success(alarmName));
  };
}

/**
 * Vide le state des masques
 *
 * @method clear
 */
function clear() {
  return {
    type: maskConstants.CLEAR,
  };
}

const maskActions = {
  getMasks,
  getAvailableAlarms,
  createMask,
  deleteMask,
  getMask,
  updateMask,
  createMaskFromChart,
  getMaskInfo,
  getMaskPdiAlarms,
  getMaskByPdiId,
  desactivMask,
  activMask,
  clear,
};

export default maskActions;
