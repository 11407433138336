const columnSelectorConstants = {
  GET_REQUEST: 'COLUMN_GET_REQUEST',
  GET_SUCCESS: 'COLUMN_GET_SUCCESS',
  GET_FAILURE: 'COLUMN_GET_FAILURE',
  SAVE_SUCCESS: 'COLUMN_SAVE_SUCCESS',
  SAVE_REQUEST: 'COLUMN_SAVE_REQUEST',
  UPDATE_REQUEST: 'COLUMN_REQUEST',
  UPDATE_SUCCESS: 'COLUMN_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'COLUMN_UPDATE_FAILURE',
  DELETE_REQUEST: 'COLUMN_DELETE_REQUEST',
  DELETE_SUCCESS: 'COLUMN_DELETE_SUCCESS',
  DELETE_FAILURE: 'COLUMN_DELETE_FAILURE',

  CLEAR: 'COLUMN_CLEAR',
};

export default columnSelectorConstants;
