import React, { Component } from 'react';
import translate from '../_helpers/locale-helpers';

interface Props {
  handleChange: Function;
  typeSelected: Array<string>;
  alarmType: Array<String>;
  locale: any;
  t: Function;
}

export default class AlarmRadioButton extends Component<Props> {
  render() {
    const { handleChange, typeSelected, alarmType, t, locale } = this.props;
    return (
      <div className="alarm-type-selector">
        <div className="flex-box">
          <div className="text-container flex-box" style={{ overflowX: 'auto' }}>
            {alarmType.map((el: any) => (
              <div>
                <h5
                  style={{
                    backgroundColor: typeSelected.find((type: string) => type === el) ? '#31c6b3' : '#fff',
                    color: typeSelected.find((type: string) => type === el) ? '#fff' : '#31c6b3',
                    border: typeSelected.find((type: string) => type === el)
                      ? '1px solid #31c6b3'
                      : '1px solid #34827a',
                    marginRight: '5px',
                  }}
                  onClick={() => handleChange(el)}
                >
                  {translate('fr', 'alarmType', el, locale)}
                </h5>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
