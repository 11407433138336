import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, NavItem } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { mailActions } from '../_actions';
import SupportHeader from '../SvgComponents/SupportHeader';
import { withTranslation } from 'react-i18next';

interface Props {
  mails: any;
  dispatch: any;
  buttonSize: string;
  mini: boolean;
  t: any;
}

interface State {
  subject: string;
  text: string;
  isOpen: boolean;
  updated: boolean;
}

/**
 * @class HelpModalComponent
 * @extends Component
 */
class HelpModalComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      subject: '',
      text: '',
      isOpen: false,
      updated: false,
    };
  }

  /**
   * @method componentDidUpdate
   * @param {any} prevProps Props précédentes
   */
  componentDidUpdate(prevProps: any) {
    const { mails } = this.props;
    if (mails.sended && !prevProps.mails.sended) {
      this.update();
    }
  }

  /**
   * Gère l'ouverture/fermeture de la modal
   *
   * @method toggle
   */
  toogle = () => {
    const { isOpen } = this.state;
    const { dispatch } = this.props;

    this.setState({
      subject: '',
      text: '',
      isOpen: !isOpen,
      updated: false,
    });

    dispatch(mailActions.clear());
  };

  /**
   * Met à jour le state
   *
   * @method update
   */
  update = () => {
    this.setState({
      subject: '',
      text: '',
      updated: true,
    });
  };

  /**
   * Envoie le mail en cas de validation du formulaire
   *
   * @method handleValidSubmit
   * @param {Object} event Evènement
   * @param {any} values Valeurs du formulaire
   */
  handleValidSubmit = (event: Object, values: any) => {
    this.setState({
      subject: values.subject,
      text: values.text,
    });
    const { subject } = this.state;
    const { text } = this.state;
    const { dispatch } = this.props;
    if (subject && subject.length > 0 && text && text.length > 0) {
      // on envoie un mail
      dispatch(mailActions.send({ subject, text }));
    }
  };

  /**
   * Gère l'invalidité du formulaire
   *
   * @method handleInvalidSubmit
   * @param {Object} event Evènement
   * @param {Object} errors Erreurs
   * @param {any} values Valeurs du formulaire
   */
  handleInvalidSubmit = (event: Object, errors: Object, values: any) => {
    this.setState({
      subject: values.subject,
      text: values.text,
    });
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { mails, buttonSize, mini, t } = this.props;
    const { text, subject, updated, isOpen } = this.state;

    return (
      <div>
        <div>
          {!mini && (
            <NavItem
              className="sidebar-item active"
              style={{ position: 'absolute', bottom: 0, marginBottom: '20px' }}
              onClick={this.toogle}
            >
              <Button size={buttonSize} id="HelpMeePlease" style={{ color: '#FFFFFF', paddingLeft: '0px' }}>
                <SupportHeader fill="#FFFFFF" height="1.5em" width="1.5em" />
                {t('help_modal.button_send.send_msg')}
              </Button>
            </NavItem>
          )}

          {mini && (
            <NavItem style={{ position: 'absolute', bottom: 0, marginBottom: '100px' }} onClick={this.toogle}>
              <Button size={buttonSize} id="HelpMeePlease" style={{ color: '#FFFFFF', padding: '8px' }}>
                <SupportHeader fill="#FFFFFF" height="1.2em" width="1.2em" />{' '}
              </Button>
            </NavItem>
          )}

          <Modal isOpen={isOpen} toggle={this.toogle} className="helpModal" size="lg">
            <ModalHeader toggle={this.toogle}>{t('help_modal.main_title.send_msg_to_support')}</ModalHeader>
            <AvForm onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
              <ModalBody>
                <div>{mails.error && <div className="alert alert-danger">{mails.error}</div>}</div>
                <div>
                  {mails.sended && (
                    <div className="alert alert-success">{t('help_modal.success_msg.msg_sent_thanks')}</div>
                  )}
                </div>

                {!updated && (
                  <div>
                    <AvField
                      name="subject"
                      value={subject}
                      label={t('help_modal.field_label.msg_subject')}
                      type="text"
                      required
                      validate={{
                        minLength: {
                          value: 10,
                          errorMessage: t('help_modal.field_required.min_size_10_char'),
                        },
                      }}
                      errorMessage={t('help_modal.field_subject_error.max_size_10_char')}
                    />
                    <AvField
                      name="text"
                      value={text}
                      label={t('all.text.text_email')}
                      type="textarea"
                      required
                      validate={{
                        minLength: {
                          value: 10,
                          errorMessage: t('help_modal.field_required.min_size_10_char'),
                        },
                      }}
                      errorMessage={t('help_modal.field_msg_error.max_size_10_char')}
                    />
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                {!updated && (
                  <Button color="danger" onClick={this.toogle}>
                    {t('all.button.cancel')}
                  </Button>
                )}
                {!updated && <Button color="primary">{t('all.button.register')}</Button>}
              </ModalFooter>
            </AvForm>
          </Modal>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  const { authentication, alert, mails } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    mails,
  };
}

const connectedHelpModalComponent = connect(mapStateToProps)(HelpModalComponent);
const tr = withTranslation()(connectedHelpModalComponent);
export default tr;
