import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Input } from 'reactstrap';
import { animateScroll } from 'react-scroll';
import { FaArrowDown, FaArrowUp } from 'react-icons/all';
import { supportActions } from '../_actions';
import styled from 'styled-components';

const LineLog = styled.p`
  /* This renders the buttons above... Edit me! */
  border-bottom: 1px solid gray;
  padding-bottom: 16px;
`;

class LogSupport extends Component {
  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { appname },
      },
    } = this.props;
    dispatch(supportActions.getLogs(appname, 5000));
  }

  render() {
    const {
      support: { logs },
      match: {
        params: { appname },
      },
    } = this.props;
    return (
      <div style={{ marginLeft: '20px', marginRight: '20px' }}>
        {logs &&
          logs.map(el => (
            <>
              <span style={{ fontSize: 16, whiteSpace: 'nowrap' }}>{el}</span>
              <br />
            </>
          ))}

        <div style={{ position: 'fixed', bottom: '30px', right: '30px' }}>
          <Input
            type="number"
            onKeyPress={e => {
              if (e.key === 'Enter') this.props.dispatch(supportActions.getLogs(appname, e.target.value));
            }}
          />
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button onClick={() => animateScroll.scrollToTop()} style={{ marginRight: '10px' }}>
              <FaArrowUp />
            </Button>
            <Button onClick={() => animateScroll.scrollToBottom()}>
              <FaArrowDown />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { support } = state;
  return {
    support,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(LogSupport)));
