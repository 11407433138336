import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from 'reactstrap';
import { cloneDeep } from 'lodash';
import Sticky from 'react-sticky-fill';
import history from '../_helpers/history';
import { locales, locations } from '../_interfaces/reducers';
import { localeActions, locationActions, searchActions } from '../_actions';
import LoupeBleu from '../SvgComponents/LoupeBleu';
import InformationsBleu from '../SvgComponents/InformationsBleu';
import ListTools from '../List/ListTools';
import { TinyMeter } from '../Meter';
import { TinyRadio } from '../Radio';
import { TinySourceSheetPDI } from '../SourceSheet';
import { storedSearchService } from '../_services';
import userActions from '../_actions/user.actions';
import { withTranslation } from 'react-i18next';
import LoadingBand from '../Bands/Loading';
import _ from 'lodash';

interface Props {
  locales: locales;
  dispatch: any;
  locationId: number;
  match: any;
  locations: locations;
  location: any;
  history: any;
  drag: Boolean;
  handleChange: any;
  user: any;
  t: any;
}

interface State {
  query: string;
  results: any;
  info: any;
  isOpen: boolean;
  activeTab: any;
  key: string;
  enterPress: any;
}

/**
 * @class Search
 * @extends {Component}
 */
class Search extends Component<Props, State> {
  static resultEnum: any = {
    Meter: {
      Label: 'Meter',
      Type: ListTools.typeOfList.Meters_search,
      List: 'meters',
    },
    Radio: {
      Label: 'Radio',
      Type: ListTools.typeOfList.Radios_search,
      List: 'radios',
    },
    PDI: {
      Label: 'PDI',
      Type: ListTools.typeOfList.Pdi_search,
      List: 'pdis',
    },
  };

  /**
   * @constructor
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      query: '',
      results: {},
      info: {},
      isOpen: false,
      activeTab: null,
      key: 'globalSearch',
      enterPress: false,
      searchInProgress: false,
    };
  }

  componentWillMount() {
    window.addEventListener('keydown', this.handleKeyboardInput.bind(this));
  }

  handleKeyboardInput = e => {
    const { enterPress } = this.state;
    const code = e.keyCode ? e.keyCode : e.which;

    if (code === 13) {
      if (enterPress === false) {
        this.setState({ enterPress: true });
      }
    }
  };

  /**
   * Vérifie dans le local storage s'il existe des recherches
   * stockées pour un autre site que le courant, et les
   * supprime dans ce cas
   *
   * @method checkLocalStorage
   * @param {any} locationId
   */
  checkLocalStorage = (locationId: any) => {
    for (let i = 0; i < localStorage.length; i++) {
      const key: any = localStorage.key(i) !== null ? localStorage.key(i) : '';
      if (key.length > 0 && key.startsWith('site') && key.includes('Search')) {
        const testKey = parseInt(key.substring(4));
        if (testKey !== parseInt(locationId)) {
          storedSearchService.removeSearch(key);
        }
      }
    }
  };

  /**
   * Fait les vérifications des données du local
   * storage au montage du composant (suppression
   * si données d'un autre site, application sinon)
   *
   * @method componentDidMount
   */
  componentDidMount() {
    const { dispatch, match, locations, user } = this.props;
    dispatch(userActions.get(user.id));
    dispatch(localeActions.load());
    let { key } = this.state;
    if (match && match.params && match.params.locationId) {
      this.checkLocalStorage(match.params.locationId);
      key = `site${match.params.locationId || 'STOCK'}Search`;
      dispatch(locationActions.get(match.params.locationId));
    }
    const data = storedSearchService.getSearch(key);
    this.setState({
      key,
      results: data.results,
      activeTab: Object.keys(data.results).length > 0 ? '1' : null,
      query: data.query,
    });
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    const { users, locations, user }: any = this.props;
    const roleList = ['DIOPTASE', 'SUPERADMIN', 'ADMIN'];
    if (
      !roleList.includes(user.role.name) &&
      locations.fetchedLocation &&
      users.fetchedUser &&
      !locations.fetchedLocation.tournee && // CETTE LIGNE ALEXIS
      !users.fetchedUser.profils.find(
        el => el.locationCode === locations.fetchedLocation.code && el.profil.permissions.length > 0
      )
    ) {
      history.push('/forbidden');
    }
  }

  /**
   * Lance la recherche en fonction du site courante (ou
   * globalement) et enregistre les résultats dans le
   * local storage, puis met à jour le state
   *
   * @method performSearch
   * @param {any} event Evènement
   */
  performSearch = (event: any) => {
    const { value } = event.target;
    const { locations } = this.props;
    const { key } = this.state;
    const rndCode: any = _.get(locations, 'fetchedLocation.code') || 'STOCK';
    this.setState({
      query: event.target.value,
      searchInProgress: true,
    });
    if (value.length > 2) {
      try {
        searchActions
          .search(value, rndCode)
          .then((results: any) => {
            if (undefined !== results && results !== null) {
              const data = {
                query: value,
                results,
              };
              storedSearchService.setSearch(data, key);
              this.setState({
                results,
                activeTab: this.state.activeTab !== null ? this.state.activeTab : '1',
                searchInProgress: false,
              });
            }
          })
          .catch((error: any) => {
            this.setState({
              searchInProgress: false,
            });
            console.error(error);
          });
      } catch (error) {
        this.setState({
          searchInProgress: false,
        });
        console.error(error);
      }
    }
  };
  /**
   * Tronque les résultats s'ils dépassent 15 entrées
   * et retourne le check
   *
   * @method checkTotal
   * @param {any} data Résultats
   * @returns {boolean} Le dépassement ou non de 15 entrées
   */
  checkTotal = (data: any): boolean => {
    if (data.length > 15) {
      const diff = data.length - 15;
      data.splice(14, diff);
    }

    return data.length > 15;
  };

  /**
   * Retourne le nombre d'entrées, en y ajoutant un + dans
   * le cas où elles dépassent 15
   *
   * @method getTotal
   * @param {any} data Résultats
   * @returns {string} Le nombre de résultats
   */
  getTotal = (data: any) => {
    const clonedData = cloneDeep(data);
    const check = this.checkTotal(clonedData);
    return !check ? `${clonedData.length}` : `${clonedData.length}+`;
  };

  /**
   * Gère l'ouverture/fermeture de la modal d'un
   * résultat
   *
   * @method toggle
   */
  toggle = () => {
    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen,
    });
  };

  /**
   * Change le compteur actif
   *
   * @method toggleMeter
   * @memberof SourceSheetPDIComponent
   */
  toggleTab = (tab: string) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  /**
   * Construit le tableau des résultats du type
   * passé en paramètre
   *
   * @method getTableResult
   * @param {any}     data Résultats
   * @param {string}  type Type
   * @returns {JSX} Le tableau
   */
  getTableResult = (data: any, type: string) => {
    const { t } = this.props;
    const defaultColumns: any = ListTools.getDefaultColumns(Search.resultEnum[type].Type);
    const columns = defaultColumns.map((champ: any) => ({
      dataField: champ,
      text: t(`columns.${champ}`),
      default: true,
      formatter: ListTools.findFormatter(champ),
    }));
    const clonedData = cloneDeep(data);
    const diff = data.length - 15;
    if (diff > 0) {
      clonedData.splice(14, diff);
    }
    const rowEvents = this.getRowEvents(type);

    return (
      <Col xs="12" className={columns && columns.length > 6 ? 'crystalList-container' : ''}>
        {clonedData && clonedData.length > 0 && (
          <BootstrapTable
            keyField="id"
            rowEvents={rowEvents}
            rowClasses="clickable"
            data={clonedData}
            bootstrap4
            bordered={false}
            columns={columns}
            hover
            headerClasses="crystalList-column"
          />
        )}
      </Col>
    );
  };

  /**
   * Gère les évènements du tableau en fonction du
   * type de résultat
   *
   * @method getRowEvents
   * @param {string} type Type
   */
  getRowEvents = (type: string) => ({
    onClick: (e: Object, row: any) => {
      const { match } = this.props;
      const { results } = this.state;
      const data = results[Search.resultEnum[type].List];
      const current = data.find((it: any) => it.id === row.id);
      let locationId = match && match.params && match.params.locationId ? match.params.locationId : null;
      if (locationId === null && current.location !== null) {
        locationId = current.location.id;
      }
      let uri = '';
      if (locationId !== null) {
        switch (type) {
          case Search.resultEnum.Meter.Label:
            uri = row.location.tournee
              ? `/locations/${row.location.id}/fiche/${row.serial.replace('/', '$S')}`
              : `/locations/${locationId}/meters/info?id=${row.id}`;
            break;
          case Search.resultEnum.Radio.Label:
            //H20SE816881
            uri = row.location.tournee
              ? `/locations/${row.location.id}/fiche/${row.meterSerial}`
              : `/locations/${locationId}/radios/info?id=${row.id}`;
            break;
          case Search.resultEnum.PDI.Label:
            uri = row.location.tournee
              ? `/locations/${row.location.id}/fiche/${row.cardOrder}`
              : `/locations/${locationId}/pdi/${row.id}`;
            break;
          default:
            uri = '';
        }
      }

      this.setState({
        info: {
          data: current,
          type,
          uri,
        },
        isOpen: true,
      });
    },
  });

  /**
   * Affiche la mini fiche en fonction du type
   * de l'élément contenu dans le state
   *
   * @method getCurrentInfo
   * @returns {JSX} La fiche
   */
  getCurrentInfo = () => {
    const { info } = this.state;
    if (info && Object.keys(info).length > 0) {
      switch (info.type) {
        case Search.resultEnum.Meter.Label:
          return <TinyMeter meter={info.data} />;
        case Search.resultEnum.Radio.Label:
          return <TinyRadio radio={info.data} />;
        case Search.resultEnum.PDI.Label:
          return <TinySourceSheetPDI pdi={info.data} />;
        default:
          return <div />;
      }
    }
  };

  /**
   * Réinitialiste les informations du state
   * relatives à la recherche, ainsi que celles
   * contenues dans le local storage
   *
   * @method reinit
   */
  reinit = () => {
    const { key } = this.state;
    storedSearchService.removeSearch(key);
    this.setState({
      results: {},
      query: '',
      activeTab: null,
    });
  };

  /**
   * Construit le composant et mise en place
   * d'un système d'onglets pour les résultats
   * de la recherche
   *
   * @method render
   */
  render() {
    const { locations, drag, handleChange, t } = this.props;
    const { query, results, isOpen, info, activeTab, searchInProgress } = this.state;
    return (
      <div className="col-12">
        <Sticky style={{ top: '90px', zIndex: '190' }}>
          <div
            className="presentation-container virtual-meter-info"
            style={{ border: drag ? 'none' : '0.5px solid #ccc' }}
          >
            <div className="presentation-header">
              <span className="presentation-title">{t('search.main_title_label.fast_search')}</span>
              <span className="presentation-main-title">
                {locations.fetchedLocation && locations.fetchedLocation.content
                  ? locations.fetchedLocation.content.name
                  : t('search.main_title.general')}
              </span>
            </div>
            <div className="presentation-body" style={{ background: 'none', marginLeft: drag ? '0' : '20px' }}>
              <Col md="12">
                <Form>
                  <Row>
                    <Col xs="7" md="8" lg="5">
                      {drag && drag === true && (
                        <Input
                          name="query"
                          id="queryField"
                          placeholder={this.props.placeholder}
                          type="text"
                          onChange={handleChange}
                        />
                      )}
                      {!drag && (
                        <Input
                          name="query"
                          id="queryField"
                          placeholder={t('search.input_search.serial_or_address')}
                          type="text"
                          onChange={this.performSearch}
                          value={query}
                          style={{ fontStyle: 'italic' }}
                        />
                      )}
                    </Col>
                    {!drag && (
                      <Col xs="4">
                        <>
                          <span id="info-search">
                            <InformationsBleu
                              className="add"
                              stroke="#808080"
                              height="1.5em"
                              width="1.5em"
                              strokeWidth="1.2"
                            />
                          </span>
                          <UncontrolledTooltip placement="bottom" target="info-search">
                            {t('search.info_tooltip.search_in_pdi_meter_radio')}
                          </UncontrolledTooltip>
                        </>
                        {Object.keys(results).length > 0 && (
                          <Button type="submit" style={{ marginLeft: '15px' }} onClick={this.reinit}>
                            {t('all.button.reset')}
                          </Button>
                        )}
                        {Object.keys(results).length === 0 && (
                          <Button disabled style={{ marginLeft: '15px' }} type="submit">
                            {t('all.button.reset')}
                          </Button>
                        )}
                      </Col>
                    )}
                    {searchInProgress && <LoadingBand style={{ width: '200px' }} message="Recherche en cours ..." />}
                  </Row>
                </Form>
              </Col>
              <div className="clearfix" />
            </div>
          </div>
        </Sticky>
        {!drag && results && Object.keys(results).length > 0 && (
          <div className="table-info-container">
            <h2>
              <span>
                <LoupeBleu height="0.9em" width="0.9em" fill="#31c6b3" stroke="#31c6b3" strokeWidth="0.3" />
              </span>
              {t('search.search_result.result_of_search')}
            </h2>
            <Nav tabs>
              {results && results.pdis && (
                <NavItem key="1">
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.toggleTab('1');
                    }}
                  >
                    {t('search.search_result.x_pdi', { pdiResult: this.getTotal(results.pdis) })}
                  </NavLink>
                </NavItem>
              )}
              {results && results.meters && (
                <NavItem key="2">
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.toggleTab('2');
                    }}
                  >
                    {t('search.search_result.x_meter', {
                      count: results.meters.length,
                      meterResult: this.getTotal(results.meters),
                    })}
                  </NavLink>
                </NavItem>
              )}
              {results && results.radios && (
                <NavItem key="3">
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.toggleTab('3');
                    }}
                  >
                    {t('search.search_result.x_radio', {
                      count: results.radios.length,
                      radioResult: this.getTotal(results.radios),
                    })}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1" key="1">
                {activeTab === '1' && (
                  <Card style={{ borderTop: 'none' }}>
                    <br />
                    {this.getTableResult(results.pdis, 'PDI')}
                  </Card>
                )}
              </TabPane>
              <TabPane tabId="2" key="2">
                {activeTab === '2' && (
                  <Card style={{ borderTop: 'none' }}>
                    <br />
                    {this.getTableResult(results.meters, 'Meter')}
                  </Card>
                )}
              </TabPane>
              <TabPane tabId="3" key="3">
                {activeTab === '3' && (
                  <Card style={{ borderTop: 'none' }}>
                    <br />
                    {this.getTableResult(results.radios, 'Radio')}
                  </Card>
                )}
              </TabPane>
            </TabContent>
          </div>
        )}
        <Modal isOpen={isOpen} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>{t('search.info_title.general_info_plural')}</ModalHeader>
          <ModalBody>{this.getCurrentInfo()}</ModalBody>
          <ModalFooter>
            {info && info.uri && info.uri.length > 0 && (
              <Button
                color="primary"
                onClick={() => {
                  history.push(info.uri);
                }}
              >
                {t('search.record_access_button.go_to_record')}
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, meters, locations, locales, radios, pdis, users } = state;
  const { user } = authentication;

  return {
    alert,
    user,
    meters,
    radios,
    pdis,
    locations,
    locales,
    users,
  };
}

const connectedSearch = connect(mapStateToProps)(Search);
const tr = withTranslation()(connectedSearch);
export default tr;
