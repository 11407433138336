import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { userActions, alertActions } from '../_actions';
import { users, alert } from '../_interfaces/reducers';
import { User } from '../_entities/user';
import { Button } from 'reactstrap';
import { REGEXP, secondsToTime } from '../_helpers';

interface Props {
  users: users;
  user: User;
  dispatch: Function;
  alert: alert;
  history: any;
}

interface State {
  password: string;
  passwordCopy: string;
  time: any;
  seconds: number;
}

/**
 * Gère la réinitialisation du mot de passe utilisateur
 *
 * @class ResetPasswordComponent
 * @extends Component
 */
class ResetPasswordComponent extends React.Component<Props, State> {
  timer: any;

  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      password: '',
      passwordCopy: '',
      time: {},
      seconds: 5,
    };
    this.timer = 0;
  }

  /**
   * @method componentDidMount
   */
  componentDidMount() {
    const { user, history } = this.props;
    if (user === undefined) {
      history.push('/');
    }
    if (user && !user.accountPasswordChange) {
      history.push('/');
    }
    let timeLeftVar = secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }

  /**
   * Nettoie le state au démontage
   *
   * @method componentWillUnmount
   * @memberof PasswordChangeForcedComponent
   */
  componentWillUnmount() {
    const { dispatch }: any = this.props;
    this.setState({
      password: '',
      passwordCopy: '',
    });
    dispatch(alertActions.clear());
  }

  /**
   * @method componentDidUpdate
   * @param {any} prevProps Props précédentes
   * @param {any} prevState State précédent
   */
  componentDidUpdate(prevProps: any, prevState: any) {
    //Si l'utilisateur à été correctement mis à jour on force une déco
    if (!prevProps.users.userUpdated && this.props.users.userUpdated) {
      this.disconnect();
    }
  }

  /**
   * Gère le comportement à la validation du formulaire
   *
   * @method handleValidSubmit
   * @param {Object} event Evènement
   * @param {any} values Valeurs du formulaire
   */
  handleValidSubmit = (event: Object, values: any) => {
    this.setState({
      password: values.password,
    });
    const { password } = this.state;
    const { dispatch } = this.props;
    const { user } = this.props;

    if (password && password.length >= 8) {
      // on créé un utilisateur pour ne pas influencer l'état
      const userToupdate = {
        id: user.id,
        role: user.role,
        email: user.email,
        userName: user.userName,
        firstName: user.firstName,
        lastName: user.lastName,
        password,
      };
      dispatch(userActions.resetPassword(userToupdate, password));
    }
  };

  /**
   * Gère le comportement en cas d'erreur de validation
   *
   * @method handleInvalidSubmit
   * @param {Object} event Evènement
   * @param {Object} errors Erreurs
   * @param {any} values Valeurs du formulaire
   */
  handleInvalidSubmit = (event: Object, errors: Object, values: any) => {
    this.setState({
      password: values.password,
      passwordCopy: values.passwordCopy,
    });
  };

  /**
   * Déconnexion de la plateforme
   *
   * @method logout
   */
  logout = () => {
    const { history } = this.props;
    history.push('/signin');
  };

  /**
   * Gère le temps avant déconnexion
   *
   * @method disconnect
   */
  disconnect = () => {
    this.timer = setInterval(this.countDown, 1000);
  };

  /**
   * Gère le compte à rebours lors d'une déconnexion
   *
   * @method countDown
   */
  countDown = () => {
    let seconds = this.state.seconds - 1;
    this.setState({
      time: secondsToTime(seconds),
      seconds: seconds,
    });
    if (seconds == 0) {
      clearInterval(this.timer);
      this.logout();
    }
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { alert, users }: any = this.props;
    const { password, passwordCopy } = this.state;

    return (
      <div>
        <h2>Réinitialisation du mot de passe</h2>
        {!users.userUpdated && (
          <div>
            <p>
              Votre mot de passe à besoin d'être réinitialisé, merci d'indiquer un mot de passe, de minimum 8
              caractères, devant contenir au moins une majuscule et un chiffre
            </p>
            <AvForm onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
              <div>{alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}</div>
              <div>
                <AvField
                  name="password"
                  value={password}
                  label="Nouveau mot de passe"
                  type="password"
                  required
                  validate={{
                    pattern: { value: REGEXP },
                    minLength: {
                      value: 8,
                      errorMessage: 'Le mot de passe doit faire plus de 8 caractères',
                    },
                  }}
                  errorMessage="Le mot de passe doit faire plus de 8 caractères, contenir au moins une majuscule et un chiffre"
                />
                <AvField
                  name="passwordCopy"
                  value={passwordCopy}
                  label="Retaper votre mot de passe"
                  type="password"
                  required
                  validate={{
                    minLength: {
                      value: 8,
                      errorMessage: 'La taille minimale du champs est de 8 caractères',
                    },
                    match: { value: 'password', errorMessage: 'Les mots de passes ne correspondent pas' },
                  }}
                  errorMessage="Le mot de passe doit faire plus de 8 caractères et doit être la copie du mot de passe précédent"
                />
              </div>
              <Button color="primary">Enregistrer</Button>
            </AvForm>
          </div>
        )}
        {users.userUpdated && (
          <Fragment>
            <div>{alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}</div>
            <p>Vous allez être déconnecté dans {this.state.time.s} s</p>
            <Button color="primary" onClick={this.logout}>
              Se déconnecter
            </Button>
          </Fragment>
        )}
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  const { authentication, alert, users } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    users,
  };
}

const connectedResetPasswordComponent = connect(mapStateToProps)(ResetPasswordComponent);
export default connectedResetPasswordComponent;
