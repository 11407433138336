import React from 'react';

const SvgHomeMarker = (props: any) => (
  <svg viewBox="0 0 131.4 200" {...props}>
    <path
      fill={props.fill ? props.fill : '#31c6b3'}
      d="M64.8,199.4c0.5,0.7,1.7,0.7,2.2,0c0.2-0.3,5.5-8.4,13.1-20.7c1.7-2.7,3.4-5.7,5.3-8.8
				c19-31.8,46-81.1,46-104.4C131.3,29.3,101.8,0,65.5,0C29.3,0.1-0.1,29.6,0,65.8c0,20.8,22.5,64,42.3,97.5
				C42.3,163.3,61.2,194,64.8,199.4z"
    />
    <path
      fill="#FFFFFF"
      d="M98.2,58.4l-5.7-5.2V36c0-1.1-0.9-2-2-2H76.9c-1.1,0-2,0.9-2,2v1.1L68,30.9c-1.3-1.2-3.3-1.2-4.7,0
					L33.1,58.4c-1.1,1-1.4,2.5-0.9,3.8c0.5,1.3,1.8,2.2,3.2,2.2h4.8V92c0,1.1,0.9,2,2,2h16.6c1.1,0,2-0.9,2-2V75.2h9.7V92
					c0,1.1,0.9,2,2,2h16.6c1.1,0,2-0.9,2-2V64.4h4.8c1.4,0,2.7-0.9,3.2-2.2C99.6,60.9,99.3,59.4,98.2,58.4z"
    />
  </svg>
);

export default SvgHomeMarker;
