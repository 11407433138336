import { authHeader, handleResponse } from '../_helpers';

async function getMasks(locationId: any) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/maskalarm/location/${locationId}`, requestOptions);
  return handleResponse(response);
}

async function getMask(id: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/maskalarm/${id}`, requestOptions);
  return handleResponse(response);
}

async function getMaskInfo(id: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/maskalarm/info/${id}`, requestOptions);
  return handleResponse(response);
}

async function deleteMask(id: number) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };
  const response = await fetch(`/api/maskalarm/${id}`, requestOptions);
  return handleResponse(response);
}

async function createMask(
  name: string,
  startDate: string,
  endDate: string,
  pdiList: Array<number>,
  filterClause: any,
  alarmList: Array<number>,
  locationCode: string,
  mode: string
) {
  filterClause.forEach(e => {
    delete e.filter.filter;
    if (_.isArray(e.userInput)) e.userInput = _.join(e.userInput.map(el => el.value), '|');
    if (_.isPlainObject(e.userInput)) e.userInput = e.userInput.value;
  });
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ name, startDate, endDate, pdiList, filterClause, alarmList, locationCode, mode }),
  };
  const response = await fetch(`/api/maskalarm/`, requestOptions);
  return handleResponse(response);
}

async function updateMask(
  id: number,
  name: string,
  startDate: string,
  endDate: string,
  pdiList: Array<number>,
  filterClause: any,
  alarmList: Array<number>,
  locationCode: string,
  mode: string
) {
  filterClause.forEach(e => {
    delete e.filter.filter;
    if (_.isArray(e.userInput)) e.userInput = _.join(e.userInput.map(el => el.value), '|');
    if (_.isPlainObject(e.userInput)) e.userInput = e.userInput.value;
  });
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({ name, startDate, endDate, pdiList, filterClause, alarmList, locationCode, mode }),
  };
  const response = await fetch(`/api/maskalarm/${id}`, requestOptions);
  return handleResponse(response);
}

async function getAvailableAlarms() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/maskalarm/availableAlarms`, requestOptions);
  return handleResponse(response);
}

async function getMaskPdiAlarms(pdiId: number, maskId: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/pdi/${pdiId}/maskAlarm/${maskId}`, requestOptions);
  return handleResponse(response);
}

async function getMaskByPdiId(pdiId: number, rndId: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/maskalarm/pdi/${pdiId}/round/${rndId}`, requestOptions);
  return handleResponse(response);
}

async function createMaskFromChart(
  name: string,
  startDate: string,
  endDate: string,
  pdiList: Array<number>,
  filterClause: null,
  alarmList: Array<string>,
  locationCode: string,
  mode: string
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ name, startDate, endDate, pdiList, filterClause, alarmList, locationCode, mode }),
  };
  const response = await fetch(`/api/maskalarm/add`, requestOptions);
  return handleResponse(response);
}

export default {
  getMasks,
  getAvailableAlarms,
  createMask,
  deleteMask,
  getMask,
  updateMask,
  getMaskInfo,
  getMaskPdiAlarms,
  getMaskByPdiId,
  createMaskFromChart,
};
