import intervalConstants from '../_constants/interval.constants';

export default function interval(state = {}, action: any) {
  switch (action.type) {
    case intervalConstants.UPDATE_INTERVAL_REQUEST:
      return {
        loading: true,
      };
    case intervalConstants.UPDATE_INTERVAL_SUCCESS:
      return {
        current: action.current,
      };
    case intervalConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
