import React, { Component, Fragment } from 'react';
import _debounce from 'lodash.debounce';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row, UncontrolledAlert } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import { cloneDeep, intersection as _intersection, keys as _keys } from 'lodash';
import Sticky from 'react-sticky-fill';
import { history, recursiveTranslate, REGEXVIRTUAL, translate } from '../_helpers';
import listOperator from '../_shared/OperatorList';
import { QueryBuilder } from '../QueryBuilder';
import { alertActions, localeActions, locationActions, pdiActions, vmeterActions } from '../_actions';
import CompteurVert from '../SvgComponents/CompteurVert';
import ListTools from '../List/ListTools';
import { locales, locations, vmeters } from '../_interfaces/reducers';
import { VirtualMeterGeneral } from '../_entities/virtual';
import { User } from '../_entities/user';
import LoadingBand from '../Bands/Loading';
import ErrorBand from '../Bands/Error';
import userActions from '../_actions/user.actions';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

interface Props extends React.Props<any> {
  pdis: any;
  location: any;
  user: User;
  match: any;
  locales: locales;
  dispatch: Function;
  vmeters: vmeters;
  locations: locations;
  alert: any;
  t: Function;
  history: any;
}

interface State {
  meterList: Array<any>;
  metersToRemove: Array<any>;
  sourceColumns: Array<any>;
  name: string;
  availableMeters: Array<VirtualMeterGeneral>;
  initialized: boolean;
  initializedVMeters: boolean;
  saved: boolean;
  vmeter: any;
  options: any;
  mtrKey: number;
}

/**
 * Gère l'édition et création de compteur virtuel
 *
 * @class AddVirtualMeter
 * @extends Component
 */
class AddVirtualMeter extends Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    if (!state.initialized) {
      let allMeters: Array<any> = [];

      let sourceColumns: Array<any> = [];

      let availableMeters: any = [];

      let meterList: Array<any> = [];

      let name = state.name;

      let initialized: boolean = state.initialized;

      let initializedVMeters: boolean = state.initializedVMeters;

      let vmeter = state.vmeter;

      if (props.pdis && props.pdis.items && props.pdis.items.length > 0) {
        const defaultColumns: any = ListTools.getDefaultColumns('VPDI_SEARCH_QUERY');
        const firstLine = props.pdis.items[0];
        const translatedKeys = recursiveTranslate('fr', 'pdi', firstLine, props.locales.locale);
        sourceColumns = translatedKeys.map((champ: any) => ({
          dataField: champ.path,
          text: champ.value,
          sort: true,
          classes: 'crystalList-column',
          default: defaultColumns.includes(champ.path),
        }));
        allMeters = props.pdis.items;
        availableMeters = allMeters;
        initialized = true;
      }

      if (props.vmeters && props.vmeters.fetchedVMeter && props.vmeters.fetchedVMeter.meterList && initialized) {
        vmeter = props.vmeters.fetchedVMeter;
        availableMeters = [];
        name = props.vmeters.fetchedVMeter.general.name;
        allMeters.forEach((it: any) => {
          const existing = props.vmeters.fetchedVMeter.meterList.find((mtr: any) => it.meter.id === mtr.id);
          if (existing) {
            meterList.push(it);
          } else {
            availableMeters.push(it);
          }
        });
        initializedVMeters = true;
      } else {
        meterList = state.meterList;
      }
      return {
        meterList,
        sourceColumns,
        name,
        availableMeters,
        initialized,
        initializedVMeters,
        vmeter,
      };
    }
    if (
      props.vmeters &&
      props.vmeters.fetchedVMeter &&
      props.vmeters.fetchedVMeter.meterList &&
      !state.initializedVMeters
    ) {
      const meterList: Array<any> = [];
      const vmeter = props.vmeters.fetchedVMeter;
      const availableMeters: any[] = [];
      const name = props.vmeters.fetchedVMeter.general.name;
      state.availableMeters.forEach((it: any) => {
        const existing = props.vmeters.fetchedVMeter.meterList.find((mtr: any) => it.meter.id === mtr.id);
        if (existing) {
          meterList.push(it);
        } else {
          availableMeters.push(it);
        }
      });
      const initializedVMeters = true;
      return {
        meterList,
        availableMeters,
        initializedVMeters,
        name,
        vmeter,
      };
    }
    return state;
  }

  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      meterList: [],
      sourceColumns: [],
      availableMeters: [],
      name: '',
      initialized: false,
      initializedVMeters: false,
      saved: false,
      vmeter: {},
      options: {
        hideSizePerPage: true,
      },
      metersToRemove: [],
      mtrKey: Math.floor(Math.random() * Math.floor(1024)),
    };
  }

  validateExists = _debounce((value, ctx, input, cb) => {
    const { vmeters } = this.props;
    value = value.startsWith('V-') ? value : `V-${value.replace(' ', '_')}`;
    if (value === '') {
      cb(true);
    } else if (
      vmeters.items &&
      vmeters.items
        .filter(el => el.id === _.get(vmeters, 'fetchedVMeter.general.id'))
        .find((el: any) => el.name === value)
    ) {
      cb('Ce nom existe déjà');
    } else {
      cb(true);
    }
  }, 0);

  /**
   * Gère le filtrage des données
   *
   * @method receiveData
   * @param {any} dataFiltered
   */
  receiveData = (dataFiltered: any) => {
    const { vmeter } = this.state;
    if (vmeter.meterList) {
      dataFiltered = dataFiltered.filter((it: any) => {
        const existing = vmeter.meterList.find((mtr: any) => it.meter.id === mtr.id);
        if (existing === undefined) {
          return it;
        }
      });
    }
    this.setState({ availableMeters: dataFiltered });
  };

  /**
   * Comportement à la validation du formulaire
   *
   * @method handleValidSubmit
   * @param {Object} event Evènement
   * @param {any} values Valeurs du formulaire
   */
  handleValidSubmit = (event: Object, values: any) => {
    const { dispatch, vmeters, locations } = this.props;
    const { meterList } = this.state;
    const { content } = locations.fetchedLocation;
    let body: any = {
      name: values.virtualName.startsWith('V-') ? values.virtualName.split('-')[1] : values.virtualName,
    };
    const formatName = body.name.replace(' ', '_');
    dispatch(alertActions.clear());

    if (vmeters && vmeters.fetchedVMeter) {
      body = cloneDeep(vmeters.fetchedVMeter.general);
      body.name = values.virtualName.startsWith('V-') ? values.virtualName : `V-${values.virtualName}`;
      if (this.checkSavedMeters()) {
        this.removeExistingMeters();
      }
      body.meters = meterList.map(it => it.meter.id);

      dispatch(vmeterActions.edit(body.id, body));
    } else {
      body.rndCode = content ? content.code : null;
      body.meters = meterList.map(it => it.meter.id);
      dispatch(vmeterActions.create(body));
    }

    this.setState({
      name: values.virtualName.replace(' ', '_'),
      saved: meterList.length > 0,
    });
  };

  /**
   * Comportement en cas d'echec de validation du formulaire
   *
   * @method handleInvalidSubmit
   * @param {Object} event Evènement
   * @param {Object} errors Erreurs
   * @param {any} values Valeurs du formulaire
   */
  handleInvalidSubmit = (event: Object, errors: Object, values: any) => {
    this.setState({
      name: values.virtualName,
    });
  };

  /**
   * Construit toute la partie haute du template
   *
   * @method getHeaderOfList
   * @returns {JSX} La partie du template
   */
  getHeaderOfList = () => {
    const { pdis, match, t } = this.props;
    const { locationId } = match.params;
    const { sourceColumns, meterList, name, vmeter } = this.state;
    const firstElement = pdis.items[0];
    const listFields = sourceColumns
      .map((col: any) => {
        const typeData = ListTools.findSpecificType(col.dataField);

        return {
          label: col.text,
          value: col.dataField,
          type: typeData,
        };
      })
      .sort((a: any, b: any) => {
        if (a.label < b.label) return -1;
        if (a.label === b.label) return 0;
        return 1;
      });

    return (
      <Fragment>
        <Sticky style={{ top: '90px', zIndex: '190' }}>
          <div className="presentation-container virtual-meter-info" style={{ border: '0.5px solid #ccc' }}>
            <div className="presentation-header">
              <span className="presentation-title">{t('all.meter.virtual_meter_edition')}</span>
              <span className="presentation-main-title">
                {Object.keys(vmeter).length > 0 ? vmeter.general.name : t('all.text.new')}
              </span>
            </div>
            <div className="presentation-body" style={{ background: 'none' }}>
              <Col md="12">
                <AvForm onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
                  <Row>
                    <Col md="4">
                      <AvField
                        name="virtualName"
                        placeholder="Nom du compteur virtuel"
                        type="text"
                        validate={{
                          pattern: { value: REGEXVIRTUAL },
                          maxLength: {
                            value: 100,
                            errorMessage: t('all.text.name_lenght_max_100'),
                          },
                          async: this.validateExists,
                        }}
                        required
                        errorMessage={t('all.text.required_field_character_condition')}
                        value={name}
                      />
                    </Col>
                    <Col md={{ size: 4, offset: 4 }}>
                      <div className="float-right">
                        <Button type="button" className="danger" onClick={this.cancelEdition}>
                          {t('all.button.cancel')}
                        </Button>
                        <Button type="submit" style={{ marginLeft: '5px' }}>
                          {t('all.button.register')}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </Col>
              <div className="clearfix" />
            </div>
          </div>
        </Sticky>
        <div className="filter-container" style={{ marginTop: '10px' }}>
          <QueryBuilder
            sendListFiltered={this.receiveData}
            listData={pdis.items}
            listOperator={listOperator}
            listFilters={listFields}
            defaultConfig={this.getDefaultFilterByURL()}
            idContext="VPDI_SEARCH_QUERY"
            save={false}
            idSite={locationId}
          />
        </div>
      </Fragment>
    );
  };

  /**
   * Gère l'annulation de l'édition
   *
   * @method cancelEdition
   */
  cancelEdition = (e: any) => {
    history.goBack();
  };

  /**
   * Teste l'existence des compteurs à supprimer
   *
   * @method checkRemoveExisting
   * @returns {number} La taille du tableau de compteurs
   */
  checkRemoveExisting = () => {
    const { meterList: mtrList, metersToRemove, availableMeters } = this.state;
    const availables = cloneDeep(availableMeters);
    const mtrs = cloneDeep(mtrList);
    metersToRemove.forEach((row: any) => {
      const meterExists = mtrs.find(it => it.id === row.id);
      if (meterExists !== undefined) {
        const i = mtrs.indexOf(meterExists);
        mtrs.splice(i, 1);
        availables.push(row);
      }
    });

    return mtrs.length;
  };

  /**
   * Supprime les compteurs sélectionnés
   *
   * @method removeExistingMeters
   */
  removeExistingMeters = () => {
    const { meterList: mtrList, metersToRemove, availableMeters } = this.state;
    metersToRemove.forEach((row: any) => {
      const meterExists = mtrList.find(it => it.id === row.id);
      if (meterExists !== undefined) {
        const i = mtrList.indexOf(meterExists);
        mtrList.splice(i, 1);
        availableMeters.push(row);
      }
    });
    this.setState({
      meterList: mtrList,
      availableMeters,
    });
  };

  /**
   * Rend la liste des compteurs existants
   *
   * @method getExistingMetersList
   * @returns {JSX} Le tableau
   */
  getExistingMetersList = () => {
    const { vmeters } = this.props;
    const { meterList, options, mtrKey, metersToRemove: mtrRm } = this.state;
    const { fetchedVMeter } = vmeters;
    const associatedMeters = fetchedVMeter && fetchedVMeter.meterList ? fetchedVMeter.meterList : [];
    const selected = mtrRm.map(it => it.id);
    const nonSelectable = meterList
      .filter(it => {
        const exists = associatedMeters.find(m => it.meter.id === m.id);
        if (exists === undefined) {
          return it;
        }
      })
      .map(mtr => mtr.id);
    const selectRow = {
      mode: 'checkbox',
      classes: 'regular-checkbox checkbox-remove',
      clickToSelect: true,
      nonSelectable,
      selected,
      selectionHeaderRenderer: ({ mode, ...props }: any) => {
        if (props.indeterminate) {
          return <input className="all-cb regular-checkbox checkbox-remove-all" type={mode} defaultChecked />;
        }
        return props.checked ? (
          <input className="all-cb regular-checkbox checkbox-remove" type={mode} defaultChecked />
        ) : (
          <input className="all-cb regular-checkbox checkbox-remove" type={mode} />
        );
      },
      onSelect: (row: any, isSelect: any, rowIndex: any, e: any) => {
        const { metersToRemove } = this.state;
        const mtrsCopy = cloneDeep(metersToRemove);

        const existing = mtrsCopy.find(it => it.id === row.id);
        if (isSelect) {
          if (existing === undefined) {
            mtrsCopy.push(row);
          }
        } else if (existing !== undefined) {
          const mtrIndex = mtrsCopy.indexOf(existing);
          mtrsCopy.splice(mtrIndex, 1);
        }

        this.setState({ metersToRemove: mtrsCopy });
      },
      onSelectAll: (isSelect: boolean, rows: any, e: any) => {
        const { metersToRemove } = this.state;
        const mtrsCopy = cloneDeep(metersToRemove);

        rows.forEach((row: any) => {
          const existing = mtrsCopy.find(it => it.id === row.id);
          if (existing === undefined && isSelect) {
            mtrsCopy.push(row);
          }
          if (existing !== undefined && !isSelect) {
            const mtrIndex = mtrsCopy.indexOf(existing);
            mtrsCopy.splice(mtrIndex, 1);
          }
        });
        this.setState({ metersToRemove: mtrsCopy });
      },
    };
    const columns = this.formatColumns('MTR_serial', true);
    return (
      <Col md="12">
        <BootstrapTable
          keyField="id"
          key={mtrKey}
          selectRow={selectRow}
          data={meterList}
          bootstrap4
          bordered={false}
          columns={columns}
          hover
          filter={filterFactory()}
          headerClasses="crystalList-column"
          pagination={paginationFactory(options)}
        />
      </Col>
    );
  };

  /**
   * @method componentDidUpdate
   * @param {Props} prevProps Props précédentes
   * @param {State} prevState State précédent
   * @param {any} snapshot Snapshot
   */
  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    const { saved } = this.state;
    const { vmeters, location, match, users, locations, user } = this.props;
    const { locationId } = match.params;
    console.log('componentDidUpdate::componentDidUpdate::499', vmeters);
    if (saved && vmeters && vmeters.fetchedVMeter) {
      const link = `/locations/${locationId}/virtuals/info`;
      history.push({
        pathname: link,
        search: `?id=${vmeters.fetchedVMeter.general.id}`,
      });
    }
    const roleList = ['DIOPTASE', 'SUPERADMIN', 'ADMIN'];
    if (
      users.fetchedUser &&
      !roleList.includes(user.role.name) &&
      locations.fetchedLocation &&
      !locations.fetchedLocation.tournee && // CETTE LIGNE ALEXIS
      !users.fetchedUser.profils.find(
        (el: any) =>
          el.profil.permissions.find((permission: any) => permission.name === 'edit.virtualMeter') &&
          el.locationCode === locations.fetchedLocation.code
      )
    ) {
      history.push('/forbidden');
    }
  }

  /**
   * @method componentDidMount
   */
  componentDidMount() {
    const { dispatch, match, location, user, locations } = this.props;
    const { locationId } = match.params;
    const vmeterId = location.search;
    dispatch(localeActions.load());
    dispatch(userActions.get(user.id));
    dispatch(locationActions.getInfos(locationId));
    dispatch(
      pdiActions.getRemotePdi(
        locationId,
        [],
        null,
        null,
        null,
        null,
        ListTools.getDefaultColumns('VPDI_SEARCH_QUERY').map(el => ({ fieldName: el, label: el }))
      )
    );
    if (vmeterId !== undefined && vmeterId.length > 0) {
      dispatch(vmeterActions.getInfos(locationId, vmeterId));
    }
    dispatch(vmeterActions.getAll(locationId));
  }

  /**
   * @method componentWillUnmount
   */
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(locationActions.clear());
    dispatch(pdiActions.clear());
    dispatch(vmeterActions.clear());
    dispatch(alertActions.clear());
  }

  /**
   * Applique des filtres passés par URL
   *
   * @method getDefaultFilterByURL
   * @memberof AddVirtualMeter
   */
  getDefaultFilterByURL = () => {
    const { location } = this.props;

    const listeOfFilter = location.search.slice(1).split('&');
    let listDefaultFilter: Array<any> = [];
    listeOfFilter.forEach((filter: any) => {
      const filterUrl = Object.keys(ListTools.filterURL).find(url => url === filter);
      if (filterUrl !== undefined) listDefaultFilter = listDefaultFilter.concat(ListTools.filterURL[filter]);
    });
    listDefaultFilter.map((f, i) => {
      const copy = f;
      copy.num = i;
      return copy;
    });
    return listDefaultFilter;
  };

  /**
   * Formate correctement les colonnes, en effectuant la
   * traduction notamment
   *
   * @method formatColumns
   * @param {string} type Type
   * @param {boolean} recursive Récursif
   */
  formatColumns = (type: string, recursive: boolean) => {
    const { locales, t } = this.props;
    const baseColumns = ListTools.getDefaultColumns(type);
    if (recursive) {
      return baseColumns.map(it => {
        const test: any = it.split('.');
        if (test.length > 1) {
          return {
            dataField: it,
            text: translate('fr', test[0], test[1], locales.locale),
            sort: true,
          };
        }
        return {
          dataField: it,
          text: translate('fr', 'meter', test[0], locales.locale),
          sort: true,
        };
      });
    }
    return baseColumns.map(champ => ({
      dataField: champ,
      text: translate('fr', 'meter', champ, locales.locale),
      sort: true,
    }));
  };

  /**
   * Teste s'il existe déjà une liste de compteurs sauvegardés
   *
   * @method checkSavedMeters
   * @returns {boolean} Le test
   */
  checkSavedMeters = () => {
    const { vmeter, meterList } = this.state;
    let mtrList = [];
    if (vmeter && vmeter.meterList) {
      mtrList = cloneDeep(meterList).filter(it => {
        const exists = vmeter.meterList.find((mtr: any) => it.meter.id === mtr.id);
        if (exists !== undefined) {
          return it;
        }
      });
    }
    return mtrList.length > 0;
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { pdis, alert, vmeters, history, t } = this.props;
    const { availableMeters, vmeter } = this.state;
    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      classes: 'regular-checkbox checkbox-add',
      onSelect: (row: any, isSelect: any, rowIndex: any, e: any) => {
        let { meterList } = this.state;
        meterList = cloneDeep(meterList);
        const existing = meterList.find(it => it.id === row.id);
        if (isSelect) {
          if (existing === undefined) {
            meterList.push(row);
          }
        } else if (existing !== undefined) {
          const mtrIndex = meterList.indexOf(existing);
          meterList.splice(mtrIndex, 1);
        }

        this.setState({ meterList });
      },
      onSelectAll: (isSelect: boolean, rows: any, e: any) => {
        const { meterList } = this.state;

        rows.forEach((row: any) => {
          const existing = meterList.find(it => it.id === row.id);
          if (existing === undefined && isSelect) {
            meterList.push(row);
          }
          if (existing !== undefined && !isSelect) {
            const mtrIndex = meterList.indexOf(existing);
            meterList.splice(mtrIndex, 1);
          }
        });

        setTimeout(() => {
          this.setState({ meterList });
        }, 60);
      },
      selectionHeaderRenderer: ({ mode, ...props }: any) => {
        if (props.indeterminate) {
          return <input className="all-cb regular-checkbox checkbox-add-all" type={mode} defaultChecked />;
        }
        return props.checked ? (
          <input className="all-cb regular-checkbox checkbox-add" type={mode} defaultChecked />
        ) : (
          <input className="all-cb regular-checkbox checkbox-add" type={mode} />
        );
      },
    };

    const baseOptions = {
      onSizePerPageChange: (sizePerPage: number, page: number) => {
        const { options } = this.state;
        options.sizePerPage = sizePerPage;
        this.setState({
          options,
          mtrKey: Math.floor(Math.random() * Math.floor(1024)),
        });
      },
    };

    return (
      <div className="col-md-12 ">
        {pdis && pdis.loading && <LoadingBand message="Chargement des compteurs disponibles" />}
        {pdis && pdis.error && <ErrorBand message={pdis.error} />}
        {pdis && pdis.items && (history.location.search.length > 0 ? vmeters.fetchedVMeter : true) && (
          <div>
            {alert.message && (
              <UncontrolledAlert
                className={`alert ${alert.type}`}
                toggle={() => {
                  const { dispatch } = this.props;
                  dispatch(alertActions.clear());
                }}
              >
                {alert.message}
              </UncontrolledAlert>
            )}
            {this.getHeaderOfList()}
            <div style={{ marginTop: '10px' }}>
              <Row className="virtual-meter-editor">
                <Col md="8">
                  <div className="crystalList-container">
                    <div className="table-info-container">
                      <h2>
                        <span>
                          <CompteurVert height="1em" width="1em" stroke="#31c6b3" fill="white" strokeWidth="1" />
                        </span>
                        {t('all.meter.available_meter', { count: availableMeters.length })}
                      </h2>
                      <br />
                      <BootstrapTable
                        keyField="id"
                        selectRow={selectRow}
                        data={availableMeters}
                        bootstrap4
                        bordered={false}
                        columns={this.formatColumns('VPDI_SEARCH_QUERY', true)}
                        hover
                        filter={filterFactory()}
                        headerClasses="crystalList-column"
                        rowClasses="clickable"
                        pagination={paginationFactory(baseOptions)}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4">
                  <div className="table-info-container">
                    <h2>
                      <span>
                        <CompteurVert height="1em" width="1em" stroke="#31c6b3" fill="white" strokeWidth="1" />
                      </span>
                      {t('all.meter.selected_meter', { count: _.size(_.get(this, 'state.meterList')) })}
                    </h2>
                    <br />
                    <div className="existing-meter-list">{this.getExistingMetersList()}</div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, pdis, locales, vmeters, locations, alert, users } = state;
  const { user } = authentication;

  return {
    user,
    pdis,
    locales,
    vmeters,
    locations,
    alert,
    users,
  };
}

const mapping: any = connect(mapStateToProps)(AddVirtualMeter);

const connectedAddVirtualMeter = withRouter(mapping);
const tr = withTranslation()(connectedAddVirtualMeter);
export default tr;
