import React from 'react';

const SvgDisquettePlus = (props: any) => (
  <svg viewBox="0 0 18.62 18.71" {...props}>
    <path
      d="M12.46 0a6.16 6.16 0 0 0-5.81 4.13H1.71A1.72 1.72 0 0 0 0 5.84V17a1.72 1.72 0 0 0 1.71 1.71h11.16A1.72 1.72 0 0 0 14.58 17v-5.07A6.15 6.15 0 0 0 12.46 0zm-9 5.27h3a6.54 6.54 0 0 0-.07.89 6.17 6.17 0 0 0 .52 2.47H3.42zm-1.18 12.3h-.57a.57.57 0 0 1-.57-.57V5.84a.57.57 0 0 1 .57-.57h.57v4.5h5.2a6.1 6.1 0 0 0 2.86 2.16H2.28zm8.88 0H3.42v-4.5h7.74zm2.28-.57a.58.58 0 0 1-.57.57h-.57v-5.26h.16a6.38 6.38 0 0 0 1-.09zm1.9-10.36h-2.4V9a.48.48 0 1 1-1 0V6.64H9.58a.47.47 0 0 1-.48-.48.48.48 0 0 1 .48-.48H12v-2.4a.48.48 0 1 1 1 0v2.4h2.4a.48.48 0 0 1 0 1z"
      fill={props.fill}
      data-name="Calque 2"
    />
  </svg>
);

export default SvgDisquettePlus;
