import React from 'react';
import { Alert, Spinner } from 'reactstrap';

export default function LoadingBand(props: any) {
  const { message } = props;
  return (
    <Alert color="info">
      <Spinner animation="grow" size="sm" role="status" aria-hidden="true" /> {message}
    </Alert>
  );
}
