import { authHeader, handleResponse } from '../_helpers';

async function getAllByAlert(id: number, rndCode: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/alert/${rndCode}/${id}/reports`, requestOptions);
  return handleResponse(response);
}

async function get(id: number, reportId: number, rndCode: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/alert/${rndCode}/${id}/report/${reportId}`, requestOptions);
  return handleResponse(response);
}

const reportService = {
  getAllByAlert,
  get,
};

export default reportService;
