import React from 'react';

const SvgReadMeterType = (props: any) => (
  <svg viewBox="0 0 200 200" {...props}>
    <path
      d="M143.9,112.7l1.2-5.7c0-2.4-1.9-4.3-4.3-4.3H38.5c-2.4,0-4.3,1.9-4.3,4.3l5.6,27.3H19.1l-0.1-1.2
			c-0.1-0.9-0.9-1.6-1.8-1.5c-0.9,0.1-1.5,0.8-1.5,1.7l0-0.2c-0.1-0.9-0.9-1.6-1.8-1.5c-0.9,0.1-1.5,0.8-1.5,1.7l0-0.2
			c-0.1-0.9-0.9-1.6-1.8-1.5c-0.9,0.1-1.6,0.9-1.5,1.8l0.1,0.9H8.1c-1,0-1.7,0.8-1.7,1.7v17.1c0,1,0.8,1.7,1.7,1.7h2.9l0.1,1.2
			c0.1,0.9,0.8,1.5,1.7,1.5c0.1,0,0.1,0,0.2,0c0.9-0.1,1.5-0.8,1.5-1.7l0,0.2c0.1,0.9,0.8,1.5,1.7,1.5c0.1,0,0.1,0,0.2,0
			c0.9-0.1,1.5-0.8,1.5-1.7l0,0.2c0.1,0.9,0.8,1.5,1.7,1.5c0.1,0,0.1,0,0.2,0c0.9-0.1,1.6-0.9,1.5-1.8l-0.1-0.9h23l1.3,6.2
			c0,2.4,1.9,4.3,4.3,4.3h60c-0.3-2.2-0.5-4.5-0.5-6.8C109.1,136.8,123.8,118.4,143.9,112.7z"
      fill={props.fill}
    />
    <path
      d="M28.3,97.9h61.2H151c5.5,0,9.6-7.1,8.2-13.5c-5.2-24.4-17.2-47.1-22.5-55.7c-1.6-2.5-4.1-5.7-6.8-5.7H49.3
			c-2.7,0-5.2,2.7-6.8,5.2C37.2,36.8,25.2,59.3,20,83.8C18.7,90.2,22.7,97.9,28.3,97.9z"
      fill={props.fill}
    />
    <circle cx="156.9" cy="158.6" r="36.8" fill="none" stroke="#31C6B3" strokeWidth={4} strokeMiterlimit={10} />
    <polygon points="142,140.2 142,177.1 177.5,158.6 142,140.2" fill={props.fill} />
  </svg>
);

export default SvgReadMeterType;
