import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Col, Dropdown, DropdownItem, FormGroup, Label, Row } from 'reactstrap';
import { MenuItem, Select } from '@material-ui/core';
import { localeActions, supportActions } from '../_actions';
import { translate } from '../_helpers';
import Loading from '../_animations/Loading';
import Switch from './_component/Switch';
import TextInput from './TextInput';
import EditionBleu from '../SvgComponents/EditionBleu';
import SelectInput from './SelectInput';

interface Props {
  dispatch: any;
  locales: any;
  support: any;
  t: any;
  pathSupportDetail: string;
  maxAuthorizedNbPath: string;
}

class PropertyEditor extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.sendCustomSetting = this.sendCustomSetting.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getSourceObjectForKey = this.getSourceObjectForKey.bind(this);

    this.state = {
      init: false,
      duplicateAuthorized: false,
      withColumns: ['remotereading', 'importbilling'].includes(_.last(props.location.pathname.split('/'))),
      pathSupportDetail: _.last(props.location.pathname.split('/')),
    };
  }

  static getDerivedStateFromProps(props: Props, state: any) {
    const { withColumns, pathSupportDetail } = state;
    const { support } = props;
    if (
      _.get(support, `${pathSupportDetail}.general`) &&
      (!withColumns || _.get(support, `${pathSupportDetail}.column`)) &&
      !state.init
    ) {
      const newState: any = {
        init: true,
      };
      _.map(_.get(support, `${pathSupportDetail}.general`), (v, k) => {
        let value = v.oldValue && v.oldValue.toString();
        if (_.isEqual(v.type, 'checkbox')) value = v.oldValue === 'true';
        if (v.oldValue) newState[k] = value;
      });
      _.map(_.get(support, `${pathSupportDetail}.column`), (v, k) => {
        if (pathSupportDetail === 'importbilling') {
          _.map(v, (v2, k2) => {
            if (_.isNumber(v2.oldValue)) newState[`${k}.${k2}`] = v2.oldValue.toString();
          });
        } else if (_.isNumber(v.oldValue)) {
          newState[k] = v.oldValue.toString();
        }
      });
      return newState;
    }
  }

  componentDidMount() {
    const { pathSupportDetail } = this.state;
    const { dispatch } = this.props;
    dispatch(localeActions.load());
    console.log(pathSupportDetail);
    switch (pathSupportDetail) {
      case 'export':
        dispatch(supportActions.getExportSetting());
        dispatch(supportActions.getEncodings());
        dispatch(supportActions.getEncodingDetectionMethods());
        break;
      case 'remotereading':
        dispatch(supportActions.getGeneralRemoteReadingSetting());
        dispatch(supportActions.getColumnRemoteReadingSetting());
        break;
      case 'sync':
        dispatch(supportActions.getSyncSetting());
        break;
      case 'report':
        dispatch(supportActions.getCustomReportSetting());
        break;
      case 'general':
        dispatch(supportActions.getCustomGeneralSetting());
        break;
      case 'importbilling':
        dispatch(supportActions.getGeneralImportBillingSetting());
        dispatch(supportActions.getColumnImportBillingSetting());
        break;
    }
  }

  handleChange(evt: any, key: string) {
    const { value } = evt.target;
    console.log(key, value);
    // Chelou mais obligé car si "false" value renvoie "true" et vice-versa
    this.setState({
      [key]: value,
    });
  }

  getSourceObjectForKey(key: string) {
    const { pathSupportDetail } = this.state;
    const { support } = this.props;
    return (
      _.get(support, `${pathSupportDetail}.general.${key}`) || _.get(support, `${pathSupportDetail}.column.${key}`)
    );
  }

  checkIfCheckbox(key: string) {
    return _.isEqual(_.get(this.getSourceObjectForKey(key), 'type'), 'checkbox');
  }

  checkIfNumber(key: string) {
    return _.isEqual(_.get(this.getSourceObjectForKey(key), 'type'), 'number');
  }

  // Method with validity value input rules
  valueIsValid(value: any, type: string, key: string) {
    const { support, maxAuthorizedNbPath } = this.props;
    const { duplicateAuthorized, pathSupportDetail } = this.state;
    let isValid: boolean;
    switch (type) {
      case 'number':
        const valueIsInt = !isNaN(value);
        let isNbColValid = true;
        if (_.get(support, `${pathSupportDetail}.general.${key}`)) {
          isValid = _.toInteger(value) >= 0;
        } else {
          const maxAuthorizedNb = _.get(support, `${pathSupportDetail}.general.${maxAuthorizedNbPath}.oldValue`);
          if (!_.isEqual(key, maxAuthorizedNb) && !_.isEmpty(maxAuthorizedNb)) {
            isNbColValid = _.toInteger(value) >= 0 && _.toInteger(value) < maxAuthorizedNb;
          } else {
            isNbColValid = _.toInteger(value) >= 0;
          }
          const duplicate = duplicateAuthorized
            ? []
            : _.filter(this.state, (v, k) => {
                return (
                  !_.keys(_.get(support, `${pathSupportDetail}.general`)).includes(k) &&
                  !_.isEqual(k, key) &&
                  v === value
                );
              });
          isValid = valueIsInt && isNbColValid && _.isEmpty(duplicate);
        }

        break;
      default:
        isValid = true;
        break;
    }

    return isValid;
  }

  generateSettingLine(key: string, type: string, required: boolean) {
    const { pathSupportDetail } = this.state;
    const { locales, t, support } = this.props;
    const encodings = support.encodings || [];
    const encodingMethods = support.encodingMethods || [];
    const itemsMap = new Map<string, Array<string>>();
    itemsMap.set('export_silex_encoding', encodings);
    itemsMap.set('export_silex_encoding_detection_method', encodingMethods);
    const value = _.get(this.state, key) || null;
    const oldValue = _.get(
      support,
      `${pathSupportDetail}.general["${key}"].oldValue`,
      _.get(support, `${pathSupportDetail}.column.${key}.oldValue`, '')
    );
    let hasChanged =
      _.toString(oldValue) !==
        (!(_.isNull(value) && _.isUndefined(value) && _.isEmpty(value)) ? _.toString(value) : null) ||
      (oldValue && !value) ||
      (!oldValue && value);
    if (hasChanged === '0' || (value === null && oldValue === 'false')) {
      hasChanged = false;
    }
    if (key === 'skip.trigger.alarm') {
      console.log(hasChanged, value, oldValue, key);
    }
    const isValid = _.isEmpty(value) ? null : this.valueIsValid(value, type, key);
    const isInvalid = _.isEmpty(value) ? null : !isValid;
    const isCheckboxType = _.isEqual(type, 'checkbox');
    const isNumber = _.isEqual(type, 'number');
    const isTextType = _.isEqual(type, 'text');
    const isDropdownType = _.isEqual(type, 'dropdown');
    console.log(support, pathSupportDetail, key, this.props);
    console.log(key, type);

    switch (true) {
      case isDropdownType:
        return (
          <div className="inputBlock">
            <SelectInput
              handleChange={(e: any) => this.handleChange(e, key)}
              text={t(`synchronisation.label.${key}`)}
              value={value}
              placeholder={t(`synchronisation.label.${key}`)}
              items={itemsMap.get(key) || []}
              isInvalid={isInvalid}
              isValid={isValid && hasChanged}
              hasChanged={hasChanged}
            />
          </div>
        );
      case !isCheckboxType:
        return (
          <div className="inputBlock">
            <TextInput
              handleChange={(e: any) => this.handleChange(e, key)}
              text={t(translate('fr', `support${_.capitalize(pathSupportDetail)}`, key, locales.locale))}
              placeholder={t(translate('fr', `support${_.capitalize(pathSupportDetail)}`, key, locales.locale))}
              value={value}
              isInvalid={isInvalid}
              isValid={isValid && hasChanged}
              type={type}
              hasChanged={hasChanged}
            />
          </div>
        );
      default:
        return (
          <div className="inputBlock">
            <div className="checkbox-container">
              <label>{t(translate('fr', `support${_.capitalize(pathSupportDetail)}`, key, locales.locale))}</label>
              <div className="flex-box">
                <Switch
                  handleCheck={(checked: boolean) => this.handleChange({ target: { value: checked } }, key)}
                  check={value}
                />
                {hasChanged && <EditionBleu height="16px" fill={isInvalid ? '#ff2281' : '#38ef7d'} />}
              </div>
            </div>
          </div>
        );
    }
  }

  checkIfValidField() {
    const invalidFields = document.getElementsByClassName('is-invalid');

    return !(_.size(_.defaultTo(invalidFields, [])) > 0);
  }

  handleDuplicate(checked: any) {
    this.setState({
      duplicateAuthorized: checked,
    });
  }

  sendCustomSetting() {
    const { pathSupportDetail } = this.state;
    const { dispatch, support } = this.props;
    const generalSetting: any = {};
    let columnSetting: any = {};

    const validForm = this.checkIfValidField();

    if (validForm) {
      _.map(_.get(support, `${pathSupportDetail}.general`), (v, k) => {
        const isNumber = this.checkIfNumber(k);
        const value = _.get(this.state, k);
        const intValue = _.defaultTo(parseInt(value), null);

        generalSetting[k] = isNumber ? intValue : value;
      });

      columnSetting = _.fromPairs(
        _.entries(this.state).filter(([key, value]) => _.get(support, `${pathSupportDetail}.column.${key}`))
      );

      switch (pathSupportDetail) {
        case 'remotereading':
          dispatch(supportActions.sendRemoteReadingSetting(generalSetting, columnSetting));
          break;
        case 'export':
          dispatch(supportActions.sendExportSetting(generalSetting));
          break;
        case 'sync':
          dispatch(supportActions.sendSyncSetting(generalSetting));
          break;
        case 'general':
          dispatch(supportActions.sendCustomGeneralSetting(generalSetting));
          break;
        case 'importbilling':
          dispatch(supportActions.sendAllImportBillingSetting({ general: generalSetting, column: columnSetting }));
          break;
        case 'report':
          dispatch(supportActions.sendCustomReportSetting(generalSetting));
          break;
      }
    }
  }

  handleReset() {
    const { pathSupportDetail } = this.state;
    const { support } = this.props;
    _.map(
      _.fromPairs(
        _.concat(
          _.toPairs(_.get(support, `${pathSupportDetail}.general`)),
          _.toPairs(_.get(support, `${pathSupportDetail}.column`))
        )
      ),
      (v, k) => {
        const existingValue = _.get(this.state, k);
        if (existingValue) {
          this.setState({ [k]: _.toString(v.oldValue) });
        }
      }
    );
  }

  render() {
    const { pathSupportDetail, init } = this.state;
    const { locales, support, t } = this.props;
    return !_.isEmpty(locales) && support && !support.loading && _.get(support, pathSupportDetail) && init ? (
      <div className="col-md-12 support-import-billing">
        <div className="wrapper">
          <div className="title">
            <h2>{t(`support.title.${pathSupportDetail}`)}</h2>
            <button className="flash-button reset" onClick={this.handleReset}>
              Réinitialisation
            </button>
          </div>
          <AvForm onValidSubmit={this.sendCustomSetting} className="custom-setting-container" model={this.state}>
            <Row style={{ margin: 0 }}>
              <h3>Configuration </h3>
              <div className="duplicateAuth form-check">
                <Switch handleCheck={this.handleDuplicate} />
              </div>
            </Row>
            <div className="main-container">
              <div className="block">
                <h4 className="neon second">{t('support.title.general')}</h4>
                <div className="allBlockInput">
                  {_.map(_.get(support, `${pathSupportDetail}.general`), (v, k) =>
                    this.generateSettingLine(k, v.type, v.mandatory)
                  )}
                </div>
              </div>
              {_.get(support, `${pathSupportDetail}.column`) && (
                <div className="block">
                  <h4 className="neon second">{t('support.title.column')}</h4>
                  <div className={pathSupportDetail === 'importbilling' ? 'allBlockInputSection' : 'allBlockInput'}>
                    {pathSupportDetail === 'importbilling'
                      ? _.map(_.get(support, `${pathSupportDetail}.column`), (v, k) => (
                          <div className="block-title">
                            <h5 className="neon third">
                              {t(
                                translate(
                                  'fr',
                                  `support${pathSupportDetail.charAt(0).toUpperCase() + pathSupportDetail.slice(1)}`,
                                  k,
                                  locales.locale
                                )
                              )}
                            </h5>
                            <div className="allBlockInput" style={{ minWidth: '20%' }}>
                              {_.map(v, (v2, k2) => this.generateSettingLine(`${k}.${k2}`, v2.type, v2.mandatory))}
                            </div>
                          </div>
                        ))
                      : _.map(_.get(support, `${pathSupportDetail}.column`), (v, k) =>
                          this.generateSettingLine(k, v.type, v.mandatory)
                        )}
                  </div>
                </div>
              )}
            </div>
            <div>
              <button className="flash-button submit">Envoyer</button>
            </div>
          </AvForm>
        </div>
      </div>
    ) : (
      <Loading />
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, users, locales, support } = state;
  const { user } = authentication;
  return {
    user,
    users,
    locales,
    support,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(PropertyEditor)));
