import React from 'react';

const SvgTourneesVertes = (props: any) => (
  <svg viewBox="0 0 12.4 17.56" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <g id="Calque_1-3" data-name="Calque 1">
          <path
            fill={props.fill}
            d="M12.4,3.39a.56.56,0,0,0-.56-.56H9.92V.55A.56.56,0,0,0,9.7.11.55.55,0,0,0,9.22,0L.48,2.78A.54.54,0,0,0,.2,3a.55.55,0,0,0-.2.42V17a.55.55,0,0,0,.55.55H11.84A.56.56,0,0,0,12.4,17ZM8.82,2.84H3.3L8.82,1.28V2.84Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgTourneesVertes;
