/* eslint-disable react/require-default-props */

import React, { Fragment } from 'react';
import ModeleVert from '../SvgComponents/ModeleVert';
import FabricantVert from '../SvgComponents/FabricantVert';
import DoubleFlecheVert from '../SvgComponents/DoubleFlecheVert';
import CalendrierVert from '../SvgComponents/CalendrierVert';
import ListTools from '../List/ListTools';
import { withTranslation } from 'react-i18next';

interface Props {
  radio: any;
  t: Function;
}

function TinyRadio(props: Props) {
  const { radio, t } = props;

  return (
    <Fragment>
      {radio && Object.keys(radio).length > 0 && (
        <div className="row">
          <div className="col-lg-12">
            <div className="presentation-container">
              <div className="presentation-header">
                <span className="presentation-title">{t('all.meter.radio_serial')}</span>
                <span className="presentation-main-title">{radio.serial}</span>
              </div>
              <div className="presentation-body">
                <div className="display-info">
                  <div className="row" style={{ marginLeft: '10px' }}>
                    <div className="col-md-3" style={{ marginTop: '10px' }}>
                      <DoubleFlecheVert height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />{' '}
                    </div>
                    <div className="col-md-9">
                      <p>
                        <span className="infoLibelle">{t('source_sheet.text.internal_number')}</span> <br /> &nbsp;{' '}
                        <span className="infoDisplay">{radio.internalSerial} </span>
                      </p>
                    </div>
                  </div>
                  <div className="row" style={{ marginLeft: '10px' }}>
                    <div className="col-md-3" style={{ marginTop: '10px' }}>
                      <ModeleVert height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />{' '}
                    </div>
                    <div className="col-md-9">
                      <p>
                        <span className="infoLibelle">{t('all.text.model')}</span> <br /> &nbsp;{' '}
                        <span className="infoDisplay">{radio.type} </span>
                      </p>
                    </div>
                  </div>
                  <div className="row" style={{ marginLeft: '10px' }}>
                    <div className="col-md-3" style={{ marginTop: '10px' }}>
                      <FabricantVert height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />{' '}
                    </div>
                    <div className="col-md-9">
                      <p>
                        <span className="infoLibelle">{t('all.meter.mfr')}</span> <br /> &nbsp;{' '}
                        <span className="infoDisplay">
                          {radio.manufacturer === 'UNKNOWN' ? t('all.text.unknown') : radio.manufacturer}{' '}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="row" style={{ marginLeft: '10px' }}>
                    <div className="col-md-3" style={{ marginTop: '10px' }}>
                      <CalendrierVert height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />{' '}
                    </div>
                    <div className="col-md-9">
                      <p>
                        <span className="infoLibelle">{t('all.radio.mount_date')}</span> <br /> &nbsp;{' '}
                        <span className="infoDisplay">{ListTools.formatDateDay(radio.startDate)} </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

const tr = withTranslation()(TinyRadio);
export default tr;
