import React, { useState } from 'react';
import './style.scss';
import _ from 'lodash';

import { withTranslation } from 'react-i18next';
import { Input } from 'reactstrap';

import SvgReglagesBleu from '../SvgComponents/ReglagesBleu';
import SvgCroixNoir from '../SvgComponents/croixNoir';
import SvgValidation from '../SvgComponents/ValidationVert';

const list = [
  {
    label: 'Minimum',
    defaultValue: 0,
    key: 'min',
  },
  {
    label: 'Maximum',
    defaultValue: 0,
    key: 'max',
  },
];

const Rouage = props => {
  const { validFunction, themeKey, alert } = props;

  const [display, setDisplay] = useState(false);
  const [options, setOptions] = useState({});

  const handleChange = (key, value) => {
    const copyOptions = _.cloneDeep(options);
    _.set(copyOptions, `${themeKey}.${key}`, value);
    setOptions(copyOptions);
  };

  const onValidSetUnit = () => {
    const copyOptions = _.cloneDeep(options);
    validFunction(copyOptions);
  };

  return (
    <div className="rouage-container-f">
      <div className={`svg-container ${display ? 'start' : ''}`} onClick={() => setDisplay(!display)}>
        <SvgReglagesBleu height="2em" />
      </div>
      <div className={`relative-container ${display ? 'start' : ''}`}>
        <div className="pop-up">
          {list.map(el => (
            <div className="section">
              <span>{_.capitalize(el.label)}</span>
              <div className="input-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Input
                  defaultValue={_.get(alert, `fields[${themeKey}][${el.key}]`)}
                  style={{ width: '100%' }}
                  onChange={e => handleChange(el.key, e.target.value)}
                  type="number"
                />
              </div>
            </div>
          ))}
          <div className="button-group">
            <div className="r-button" onClick={e => setDisplay(!display)}>
              <SvgCroixNoir height="1.7em" />
            </div>
            <div className="r-button" onClick={() => onValidSetUnit()}>
              <SvgValidation height="1em" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Rouage);
