import React from 'react';

const SvgPieNotAvailable = (props: any) => (
  <svg viewBox="0 0 481 320" {...props}>
    <path d="M223.2,95.4c-39.9,3-71.5,36.1-71.5,76.3c0,11,2.3,21.4,6.5,30.9l64.9-43.2V95.4z" fill="none" />
    <path d="M229.1,248.7c7.3,0,14.4-1,21.3-3l-18-48.2l-51.6,34.3C194.1,242.4,210.9,248.7,229.1,248.7z" fill="none" />
    <path d="M329,177.6h-66.6l-1,0.7l23.6,63.3C309.9,229.9,326.9,205.2,329,177.6z" fill="none" />
    <path d="M252.7,59.5c-3.3,0-5.9,2.6-5.9,5.9v78.4l75.4-50.2C306,72.9,280.9,59.5,252.7,59.5z" fill={props.fill} />
    <path d="M335.1,154.1c3.3,0,5.9-2.6,5.9-5.9c0-7.3-0.9-14.4-2.6-21.2l-40.7,27.1H335.1z" fill={props.fill} />
    <path
      d="M151.8,171.8c0-40.3,31.5-73.4,71.5-76.3v64l11.8-7.8V89.3c0-3.3-2.6-5.9-5.9-5.9c-48.5,0-89.1,39.3-89.1,88.3
	c0,13.4,3,26.1,8.4,37.5l9.9-6.6C154.1,193.2,151.8,182.8,151.8,171.8z"
      fill={props.fill}
    />
    <path
      d="M250.4,245.7c-6.9,2-14,3-21.3,3c-18.2,0-35-6.3-48.2-16.9l-10.1,6.7c15.7,13.6,36.1,21.9,58.4,21.9
	c10.7,0,21.1-1.9,31-5.6c3-1.1,4.6-4.5,3.4-7.6l-21.1-56.5l-10.1,6.7L250.4,245.7z"
      fill={props.fill}
    />
    <path
      d="M329,177.6c-2.1,27.6-19.1,52.3-44,63.9l-23.6-63.3l-10.1,6.7l24.8,66.4c1.1,3.1,4.6,4.6,7.6,3.5
	C318,242,341,208.6,341,171.8c0-3.3-2.6-5.9-5.9-5.9H280l-17.6,11.7H329z"
      fill={props.fill}
    />
    <path d="M148.4,209.2l-47.3,31.5l9.3,14l46.6-31C153.7,219.2,150.8,214.3,148.4,209.2z" fill={props.fill} />
    <path d="M374.3,79.1l-9.3-14l-42.8,28.5c3.4,4.4,6.5,9.1,9,14.2L374.3,79.1z" fill={props.fill} />
    <path d="M235.3,171.6l26.3-17.5h-8.9c-3.3,0-5.9-2.6-5.9-5.9v-4.4l-11.8,7.8v19.1L235.3,171.6z" fill={props.fill} />
    <path
      d="M223.6,173.8c-0.2-0.7-0.4-1.4-0.4-2.1v-12.3l-64.9,43.2c2.3,5.2,5.1,10,8.5,14.5l58.5-38.9L223.6,173.8z"
      fill={props.fill}
    />
    <path
      d="M252.7,154.1h8.9l69.6-46.3c-2.6-5-5.6-9.8-9-14.2l-75.4,50.2v4.4C246.8,151.5,249.4,154.1,252.7,154.1z"
      fill={props.fill}
    />
    <path d="M223.2,171.8c0,0.7,0.1,1.4,0.4,2.1l1.7,4.5l10.1-6.7l-0.3-0.9v-19.1l-11.8,7.8V171.8z" fill={props.fill} />
    <path d="M166.8,217.2c-3.3-4.5-6.2-9.4-8.5-14.5l-9.9,6.6c2.4,5.1,5.3,9.9,8.6,14.4L166.8,217.2z" fill={props.fill} />
  </svg>
);

export default SvgPieNotAvailable;
