import React from 'react';

const SvgTarget = (props: any) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || '#3EB7AA'}
      d="M48.3,23.4h-2.6C44.9,13.3,36.7,5.1,26.6,4.4V1.7c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6v2.6
		C13.3,5.1,5.1,13.3,4.4,23.4H1.7c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h2.6c0.8,10.2,8.9,18.3,19.1,19.1v2.6
		c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6v-2.6c10.2-0.8,18.3-8.9,19.1-19.1h2.6c0.9,0,1.6-0.7,1.6-1.6
		C49.8,24.1,49.1,23.4,48.3,23.4z M26.6,42.5V41c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6v1.5c-8.5-0.7-15.2-7.5-16-16H9
		c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6H7.5c0.7-8.5,7.5-15.2,16-16V9c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6V7.5
		c8.5,0.7,15.2,7.5,16,16H41c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h1.5C41.8,35,35,41.8,26.6,42.5z"
    />
    <path
      fill={props.fill || '#3EB7AA'}
      d="M25,18.8c-3.4,0-6.2,2.8-6.2,6.2c0,3.4,2.8,6.2,6.2,6.2c3.4,0,6.2-2.8,6.2-6.2C31.2,21.6,28.4,18.8,25,18.8z
		 M25,28.1c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1C28.1,26.7,26.7,28.1,25,28.1z"
    />
  </svg>
);

export default SvgTarget;
