import React, { useState } from 'react';
import Select from 'react-select';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap';
import _ from 'lodash';
import SvgAlarmeVert from '../SvgComponents/AlarmeVert';
import RadioButton from '../_components/RadioButton';

const displayDrop = key => {
  switch (key) {
    case 'hour':
      return 'heure';
    case 'day':
      return 'Jour';
    default:
      return 'minute';
  }
};

const AlertBar = props => {
  const {
    users,
    t,
    options,
    setDropValue,
    setSelectedUsers,
    selectedUsers,
    setOptions,
    dropValue,
    setRight,
    isRight,
    info,
  } = props;

  const [toggle, setToggle] = useState(false);

  const handleChange = value => {
    const copyOptions = _.cloneDeep(options);
    _.set(copyOptions, 'retention.value', value);
    setOptions(copyOptions);
  };

  const handleChangeUsers = e => {
    const copy = _.cloneDeep(e);
    const selectedList = (copy || []).map(el => el.mail);
    setSelectedUsers(selectedList);
  };

  const userList = users.items
    ? users.items.map(user => ({
        label: `${user.firstName} ${user.lastName}<${user.email}>`,
        value: user.id,
        mail: user.email,
      }))
    : [];

  const defaultList = info && (_.get(info, 'usersMail', []) || []).map(el => userList.find(user => user.mail === el));
  console.log(userList, defaultList);
  return (
    <div className="unfold-sensor-alert">
      <div className="title">
        <SvgAlarmeVert height="2em" fill="#31c6b3" />
        <h4>{t('all.alert.alert')}</h4>
      </div>
      <div className="section">
        <div className="center">
          <div>Utilisateurs</div>
          <Select
            defaultValue={defaultList}
            isMulti
            name="colors"
            options={userList}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleChangeUsers}
          />
        </div>
      </div>
      {!isRight && (
        <div className="section">
          <div className="center">
            <div className="title-s">Délai entre 2 alertes</div>
            <div className="flex-box">
              <Input
                onChange={e => handleChange(e.target.value)}
                defaultValue={_.get(info, 'retention.value')}
                type="number"
                className="input-s"
              />
              <Dropdown isOpen={toggle} toggle={() => setToggle(!toggle)} onClick={e => e.stopPropagation()}>
                <DropdownToggle caret>{displayDrop(dropValue)}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setDropValue('minutes')}>Minute</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => setDropValue('hour')}>Heure</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => setDropValue('day')}>Jour</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
      )}
      <div className="section b">
        <RadioButton
          leftText="En continue"
          rightText="Au déclenchement"
          default={info.continuousAlertSending}
          isRight={isRight}
          setRight={setRight}
        />
      </div>
    </div>
  );
};

export default AlertBar;
