import React, { Component, Fragment } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import Switch from 'rc-switch';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Button, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import _ from 'lodash';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Interval, isWithinInterval } from 'date-fns';
import chartTemplate from '../_shared/ChartTemplate.json';
import alarmTemplate from '../_shared/AlarmTemplate.json';
import { colorActions, localeActions, locationActions, meterActions, radioActions } from '../_actions';
import history from '../_helpers/history';
import AlarmSelector from '../Meter/AlarmSelector';
import { Meter } from '../Meter';
import { Radio } from '../Radio';
import HistoriqueVert from '../SvgComponents/HistoriqueVert';
import RadioVert from '../SvgComponents/RadioVert';
import CompteurVert from '../SvgComponents/CompteurVert';
import { colors, locales, locations, radios } from '../_interfaces/reducers';
import { widgetConstants } from '../_constants';
import { Sheet } from '../_entities/sheet';
import { PrintableSourceSheet } from '.';
import { printMultiSheet } from '../_helpers/pdf-helper';
import { downloadCSV } from '../FileDownload';
import { chart2table } from '../_helpers';
import translate, { reverseAlarm } from '../_helpers/locale-helpers';
import LoadingBand from '../Bands/Loading';
import ErrorBand from '../Bands/Error';
import exportAction from '../_actions/export.actions';
import DoubleFlecheVert from '../SvgComponents/DoubleFlecheVert';
import ModeleVert from '../SvgComponents/ModeleVert';
import FabricantVert from '../SvgComponents/FabricantVert';
import SvgArrowVert from '../SvgComponents/Arrow';
import SvgFlecheAller from '../SvgComponents/FlecheAller';
import BestDateComponent from '../_components/BestDateComponent';
import Picto from './Picto_sourceSheet';
import NoFilled from '../Message/NoFilled';
import NoPresent from '../Message/NoPresent';
import Loading from '../_animations/Loading';
import CalendrierVert from '../SvgComponents/CalendrierVert';
import ListTools from '../List/ListTools';
import SvgSimpleArrowDroite from '../SvgComponents/SimpleArrowDroite';
import BarChart from './BarChart';
import { Chart } from '../Widget';
import colorize from '../_helpers/colorize-helper';

const Ligne = styled.div`
  border-bottom: 1px solid lightgrey;
  width: 100%;
  margin: 10px 0;
`;
interface Props {
  displayMode: string;
  meters: any;
  radios: radios;
  sourcesheetContext: string | null | undefined;
  locales: locales;
  colors: colors;
  dispatch: any;
  locationId: number;
  meterId: number;
  match: any;
  locations: locations;
  location: any;
  withoutDateComponent: boolean;
  lastPertinentRead: any;
}

interface State {
  template: any;
  consumption: any;
  alarm: any;
  graphType: string;
  isOpen: boolean;
  printData: Sheet | null;
  isMasked: boolean | undefined;
  chartDisplay: Array<string>;
  selectedTypes: Array<any>;
}

const readMethodColor = (type: string) => {
  switch (type) {
    case 'A':
      return '#91E0F2';
    case 'T':
      return '#1085ED';
    default:
      return '#22A553';
  }
};

const formatAlarmData = (listValues: Array<any>, masksDesac: Array<string>, locale: any, colors: any, t: Function) => {
  const newData: any = {
    labels: [],
    values: [],
  };
  const typesList = [];
  listValues.forEach(el => {
    _.entries(el.types).forEach(type => {
      typesList.push(type[0]);
    });
  });
  newData.labels = listValues.map((el: any) => el.date);
  const allAlarmType = _.uniq(listValues.map(el => _.keys(el.types)).flat());
  newData.values = allAlarmType.map((el: string) => ({
    label: translate('fr', 'alarmType', el, locale.locale),
    color: colorize('chart', 'alarmType', el, colors.color),
    data: listValues.map((element: any) =>
      _.entries(element.types).find(
        (alarmType: any) => alarmType[0] === el && (!alarmType[1] || masksDesac.includes(alarmType[0].split('.')[0]))
      )
        ? typesList.length / Object.keys(element.types).length
        : 0
    ),
  }));
  return newData;
};

/**
 * @class SourceSheet
 * @extends {Component<Props, State>}
 */
class SourceSheet extends Component<Props, State> {
  sourcesheetContext: any;

  static templateEnum: any = {
    Meter: {
      id: 'Meter',
      dataSourceName: 'Meter',
      conditionTitle: 'MeterSerialNumber',
      displayValue: 'MeterReadingValue',
      displayUnit: 'm3',
      displayID: widgetConstants.READINDEXWIDGET,
      Columns: [
        {
          dataField: 'serial',
          text: 'Numéro de série',
        },
        {
          dataField: 'manufacturer',
          text: 'Fabricant',
          formatter: (fabricant: any, row: any) => {
            return fabricant === 'UNKNOWN' ? 'Inconnu' : fabricant;
          },
        },
        {
          dataField: 'startDate',
          text: 'Date montage',
          formatter: (date: any, row: any) => {
            const momentDate = undefined !== date && date !== null ? moment(date).format('DD/MM/YYYY') : 'Non défini';
            return <span>{momentDate}</span>;
          },
        },
        {
          dataField: 'endDate',
          text: 'Date démontage',
          formatter: (date: any, row: any) => {
            const momentDate = undefined !== date && date !== null ? moment(date).format('DD/MM/YYYY') : 'Non défini';
            return <span>{momentDate}</span>;
          },
        },
      ],
    },
    Radio: {
      id: 'Radio',
      dataSourceName: 'Radio',
      conditionTitle: 'RadioSerialNumber',
      displayValue: 'RadioReadingValue',
      displayUnit: 'impulsions',
      displayID: widgetConstants.READRADIOWIDGET,
      Columns: [
        {
          dataField: 'serial',
          text: 'Numéro de série',
        },
        {
          dataField: 'model',
          text: 'Modèle',
        },
      ],
    },
  };

  /**
   * @constructor
   * @param {Props} props Propriétés
   */
  constructor(props: Props) {
    super(props);

    this.sourcesheetContext = SourceSheet.templateEnum[props.displayMode];

    const dateMin = moment()
      .add(-1, 'month')
      .toISOString();
    const dateMax = moment().toISOString();

    /* const defaultMinDate = moment()
      .startOf('month')
      .format(`${moment.HTML5_FMT.DATE} ${moment.HTML5_FMT.TIME_SECONDS}`);
    const defaultMaxDate = moment()
      .endOf('month')
      .format(`${moment.HTML5_FMT.DATE} ${moment.HTML5_FMT.TIME_SECONDS}`); */
    const alarm: any = cloneDeep(alarmTemplate);
    const template: any = cloneDeep(chartTemplate);
    template.dataSourceProperty.displayUnit = SourceSheet.templateEnum[props.displayMode].displayUnit;
    template.dataSourceProperty.displayProperty.condition[1].conditionValue = dateMin;
    template.dataSourceProperty.displayProperty.condition[2].conditionValue = dateMax;
    template.dataSourceName = this.sourcesheetContext.dataSourceName;

    template.dataSourceProperty.displayProperty.condition[0].conditionTitle = this.sourcesheetContext.conditionTitle;
    template.dataSourceProperty.displayValue = this.sourcesheetContext.displayValue;
    template.dataSourceProperty.displayID = this.sourcesheetContext.displayID;

    const consumption: any = cloneDeep(template);
    consumption.dataSourceProperty.displayUnit = SourceSheet.templateEnum.Meter.displayUnit;
    consumption.dataSourceProperty.displayID = widgetConstants.READCONSUMTIONWIDGET;
    consumption.dataSourceName = SourceSheet.templateEnum.Meter.id;
    consumption.dataSourceProperty.displayProperty.condition[0].conditionTitle =
      SourceSheet.templateEnum.Meter.conditionTitle;
    template.dataSourceProperty.displayValue = 'MeterConsumptionValue';

    alarm.dataSourceProperty.displayProperty.condition[1].conditionValue = dateMin;
    alarm.dataSourceProperty.displayProperty.condition[2].conditionValue = dateMax;

    if (props && props.pdiTemplate) {
      template.dataSourceProperty.displayProperty = props.pdiTemplate.dataSourceProperty.displayProperty;
    }
    // TODO : verifier

    this.currentMeter = this.currentMeter.bind(this);
    this.handleChartSelector = this.handleChartSelector.bind(this);
    this.generateIntervalSelector = this.generateIntervalSelector.bind(this);

    this.state = {
      template,
      consumption,
      alarm,
      graphType: template.dataSourceProperty.displayProperty.displayType,
      isOpen: false,
      printData: null,
      isMasked: false,
      chartDisplay: ['consommation', 'index'],
      selectedTypes: [],
    };
  }

  componentDidMount() {
    const { dispatch, match, location, displayMode, sourcesheetContext, meterId, locations, meters } = this.props;
    const id = sourcesheetContext && sourcesheetContext === 'pdi' ? `?id=${meterId}` : location.search;
    const { template, consumption, alarm } = this.state;
    const siteId =
      sourcesheetContext && locations.fetchedLocation ? locations.fetchedLocation.id : match.params.locationId;
    if (displayMode === SourceSheet.templateEnum.Meter.id) {
      dispatch(radioActions.clear());
      if (
        (meters.allMetersInfo && !meters.allMetersInfo.find(el => el.general.id === meterId)) ||
        !meters.allMetersInfo
      ) {
        const interval = {
          dateMin: moment()
            .add(-1, 'month')
            .toISOString(),
          dateMax: moment().toISOString(),
          zoom: 'DAY',
        };
        dispatch(meterActions.getInfosData(id, siteId, interval));
      }
    } else if (displayMode === SourceSheet.templateEnum.Radio.id) {
      dispatch(meterActions.clear());
      dispatch(radioActions.getInfos(siteId, location.search, template, alarm));
    }
    dispatch(localeActions.load());
    dispatch(colorActions.load());
    if (!sourcesheetContext && !location.fetchedLocation) {
      dispatch(locationActions.get(match.params.locationId));
    }
  }

  static getDerivedStateFromProps(props: Props, state: any) {
    const { interval } = props;
    const { template } = state;
    const copyState = _.cloneDeep(state);
    if (_.get(interval, 'current.zoom') !== _.get(template, 'dataSourceProperty.displayZoom')) {
      copyState.template.dataSourceProperty.displayZoom = _.get(interval, 'current.zoom');
    }
    return copyState;
  }

  componentWillUnmount() {
    const { dispatch, sourcesheetContext } = this.props;
    if (!sourcesheetContext) {
      dispatch(locationActions.clear());
    }
    // dispatch(radioActions.clear());
    // dispatch(meterActions.clear());
  }

  currentMeter() {
    const { meters, meterId } = this.props;
    const searchMeter = meters.allMetersInfo && meters.allMetersInfo.find(el => el.general.id === meterId);
    if (searchMeter) {
      return searchMeter;
    }
    return meters.fetchedMeter;
  }

  /**
   * Récupère les données de géolocalisation
   *
   * @method getGPS
   * @returns {any} les données de géoloc
   */
  getGPS = () => {
    const { meters, locations } = this.props;
    const meter = this.currentMeter();
    if (meter && meter.general) {
      if (undefined !== meter.general.gpsPosition && meter.general.gpsPosition !== null) {
        return {
          latitude: meter.general.gpsPosition.lat,
          longitude: meter.general.gpsPosition.lng,
        };
      }
    }
    if (
      locations.fetchedLocation &&
      locations.fetchedLocation.content &&
      undefined !== locations.fetchedLocation.content.gpsPosition &&
      locations.fetchedLocation.content.gpsPosition !== null
    ) {
      return {
        latitude: locations.fetchedLocation.content.gpsPosition.lat,
        longitude: locations.fetchedLocation.content.gpsPosition.lng,
      };
    }
    return null;
  };

  /**
   * Récupère l'élément courant avec ses informations
   *
   * @method getFetchElement
   * @returns {any} L'élément courant
   */
  getFetchElement() {
    const { meters, radios } = this.props;
    return this.isMeter() ? this.currentMeter() : radios && radios.fetchedRadio;
  }

  generateLineInfo(title: string, info: any, imgName: string) {
    return (
      <div
        className="row meterInfo"
        style={{ marginLeft: '10px', maxWidth: '90%', display: 'flex', flexDirection: 'row' }}
      >
        <div className=" picto" style={{ marginTop: '10px', padding: '0 15px' }}>
          {Picto(imgName) ? (
            Picto(imgName, {
              style: { marginRight: '10px' },
              height: '2em',
              width: '2em',
              stroke: '#31c6b3',
              fill: '#31c6b3',
              strokeWidth: '0',
            })
          ) : (
            <div style={{ width: '35.5px' }} />
          )}{' '}
        </div>
        <div className="" style={{ flex: 1, padding: '0 15px' }}>
          <p>
            <span className="infoLibelle">{title}</span> <br />
            {info ? (
              <div className="infoDisplay" style={{ overflowWrap: 'break-word' }}>
                {info}{' '}
              </div>
            ) : (
              <NoFilled />
            )}
          </p>
        </div>
      </div>
    );
  }

  /**
   * Génère le bloc des éléments associés
   *
   * @method generateHistoric
   * @returns {JSX} Le bloc des éléments associés
   */
  generateHistoricRadioForMeterFiche = () => {
    const { displayMode, meters, locationId, match, t } = this.props;
    const locId = locationId || (match && match.params && match.params.locationId);

    const keyField = 'id';

    const rowEvents = {
      onClick: (e: Object, row: any) => {
        const linkOnClick = `/locations/${locId}/radios/info?id=${row.id}`;
        history.push(linkOnClick);
      },
    };
    const data = meters && this.currentMeter() && this.currentMeter().radioList;
    const columns = SourceSheet.templateEnum.Meter.Columns;

    const currentRadio = data && data.find(el => el.endDate === null);
    const oldRadios = (data && data.filter(el => el.endDate !== null)) || [];

    return (
      <Fragment>
        <div className="table-info-container">
          <div>
            <h2>
              <span>
                {this.isMeter() && <RadioVert height="1em" width="1em" fill="#31c6b3" />}
                {!this.isMeter() && <CompteurVert height="1em" width="1em" fill="#31c6b3" />}
              </span>
              {t('all.radio.current_radio')}
              {currentRadio && (
                <div
                  id="addDashboard"
                  style={{ display: 'inline-grid', float: 'right' }}
                  onClick={() => {
                    const radioUrl = `/locations/${locId}/radios/info?id=${currentRadio.id}`;
                    history.push(radioUrl);
                  }}
                >
                  <SvgFlecheAller width="1.5em" height="1.5em" />
                  <UncontrolledTooltip placement="bottom" target="addDashboard">
                    {' '}
                    {t('all.radio.go_to_radio')}
                  </UncontrolledTooltip>
                </div>
              )}
            </h2>

            {currentRadio ? (
              <div className="presentation-body">
                <div className="display-info">
                  <div className="row" style={{ marginLeft: '10px' }}>
                    <div className="col-md-3" style={{ marginTop: '32px' }}>
                      <DoubleFlecheVert height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />{' '}
                    </div>
                    <div className="col-md-9">
                      <p>
                        <span className="infoLibelle">{t('all.meter.serial')}</span>
                        <br />
                        &nbsp; <span className="infoDisplay">{currentRadio.serial} </span>
                        <br />
                        <span className="infoLibelle">{t('source_sheet.text.internal_number')}</span>
                        <br />
                        &nbsp; <span className="infoDisplay">{currentRadio.composeInternalSerial} </span>
                      </p>
                    </div>
                  </div>
                  <div className="row" style={{ marginLeft: '10px' }}>
                    <div className="col-md-3" style={{ marginTop: '10px' }}>
                      <ModeleVert height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />{' '}
                    </div>
                    <div className="col-md-9">
                      <p>
                        <span className="infoLibelle">{t('all.radio.radio_model')}</span> <br /> &nbsp;{' '}
                        <span className="infoDisplay">{currentRadio.type} </span>
                      </p>
                    </div>
                  </div>
                  <div className="row" style={{ marginLeft: '10px' }}>
                    <div className="col-md-3" style={{ marginTop: '10px' }}>
                      <FabricantVert height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />{' '}
                    </div>
                    <div className="col-md-9">
                      <p>
                        <span className="infoLibelle">{t('all.radio.radio_manufacturer')}</span> <br /> &nbsp;{' '}
                        <span className="infoDisplay">
                          {currentRadio.manufacturer === 'UNKNOWN' ? t('all.text.unknown') : currentRadio.manufacturer}{' '}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="row" style={{ marginLeft: '10px' }}>
                    <div className="col-md-3" style={{ marginTop: '10px' }}>
                      <CalendrierVert height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />{' '}
                    </div>
                    <div className="col-md-9">
                      <p>
                        <span className="infoLibelle">{t('all.radio.mount_date')}</span> <br /> &nbsp;{' '}
                        <span className="infoDisplay">{ListTools.formatDateDay(currentRadio.startDate)} </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              t('all.radio.no_linked_radio')
            )}
            <div style={{ marginBottom: '50px' }} />
            <div>
              <h2>
                <span>
                  <RadioVert height="1em" width="1em" fill="#31c6b3" />
                </span>
                {t('all.radio.radio_history')}
              </h2>
              {oldRadios && oldRadios.length > 0 ? (
                <BootstrapTable
                  keyField={keyField}
                  data={oldRadios}
                  rowEvents={rowEvents}
                  columns={columns}
                  bootstrap4
                  bordered
                  condensed
                  hover
                  striped
                  rowClasses="clickable"
                  pagination={data.length > 10 ? paginationFactory() : null}
                />
              ) : (
                <div>{t('all.radio.no_old_radio_linked')}</div>
              )}
            </div>
          </div>
        </div>
        <br />
      </Fragment>
    );
  };

  generateHistoricMetersForRadioFiche = () => {
    const { displayMode, meters, t, radios, locationId, match } = this.props;
    const locId = locationId || (match && match.params && match.params.locationId);

    const keyField = 'id';

    const rowEvents = {
      onClick: (e: any, row: any) => {
        history.push(`/locations/${locId}/meters/info?id=${row.id}`);
      },
    };
    const data = radios && radios.fetchedRadio && radios.fetchedRadio.meterList;
    const columns = SourceSheet.templateEnum.Radio.Columns;

    const currentMeter = data && data.find(el => el.endDate === null);
    const oldMeters = (data && data.filter(el => el.endDate !== null)) || [];

    return (
      <Fragment>
        <div className="table-info-container">
          {data && (
            <div>
              <h2>
                <span>
                  <CompteurVert height="1em" width="1em" fill="#31c6b3" />
                </span>
                {t('source_sheet.text.current_meter')}
                {currentMeter && (
                  <div
                    id="addDashboard"
                    style={{ display: 'inline-grid', float: 'right' }}
                    onClick={() => {
                      const radioUrl = `/locations/${locId}/meters/info?id=${currentMeter.id}`;
                      history.push(radioUrl);
                    }}
                  >
                    <SvgFlecheAller width="1.5em" height="1.5em" />
                    <UncontrolledTooltip placement="bottom" target="addDashboard">
                      {' '}
                      {t('all.meter.go_to_meter')}
                    </UncontrolledTooltip>
                  </div>
                )}
              </h2>
              {currentMeter ? (
                <div className="presentation-body" style={{ marginLeft: '-16px' }}>
                  <div className="display-info">
                    {this.generateLineInfo(t('all.meter.serial'), currentMeter.serial, 'barCode')}
                    {this.generateLineInfo(t('all.meter.meter_model'), currentMeter.model, 'model')}
                    {this.generateLineInfo(
                      t('all.meter.meter_manufacturer'),
                      currentMeter.manufacturer === 'UNKNOWN' ? t('Unkonw') : currentMeter.manufacturer,
                      'manufacturer'
                    )}
                  </div>
                </div>
              ) : (
                <span>{t('source_sheet.text.no_linked_meter')}</span>
              )}
              <div style={{ marginBottom: '50px' }} />
              <h2>
                <span>
                  {this.isMeter() && <RadioVert height="1em" width="1em" fill="#31c6b3" />}
                  {!this.isMeter() && <CompteurVert height="1em" width="1em" fill="#31c6b3" />}
                </span>
                {t('source_sheet.text.meter_linked_history')}
              </h2>
              {oldMeters && oldMeters.length > 0 ? (
                <BootstrapTable
                  keyField={keyField}
                  data={oldMeters}
                  rowEvents={rowEvents}
                  columns={columns}
                  bootstrap4
                  bordered
                  condensed
                  hover
                  striped
                  rowClasses="clickable"
                  pagination={data.length > 10 ? paginationFactory() : null}
                />
              ) : (
                <NoPresent msg={t('source_sheet.text.no_old_meter_linked')} style={{ marginLeft: '40px' }} />
              )}
            </div>
          )}
        </div>
        <br />
      </Fragment>
    );
  };

  /**
   * Met à jour les différents templates de données avec
   * le retour de la saisie, et récupère les nouvelles
   * données
   *
   * @method updateTemplate
   * @param {any} statementsTemplate  Template des index
   * @param {any} consoTemplate       Template des conso
   * @param {any} alarmsTemplate      Template des alarms
   */
  updateTemplate = (value: any, type: string) => {
    const { template, alarm, consumption } = this.state;
    const { dispatch, radios, match, locations, displayMode } = this.props;
    const siteId = locations && locations.fetchedLocation && locations.fetchedLocation.id;
    const newValue = `${value} 00:00:00`;
    switch (type) {
      case 'dateMin':
        template.dataSourceProperty.displayProperty.condition[1].conditionValue = newValue;
        consumption.dataSourceProperty.displayProperty.condition[1].conditionValue = newValue;
        alarm.dataSourceProperty.displayProperty.condition[1].conditionValue = newValue;
        this.setState({
          template,
          alarm,
        });
        break;
      case 'dateMax':
        template.dataSourceProperty.displayProperty.condition[2].conditionValue = newValue;
        consumption.dataSourceProperty.displayProperty.condition[2].conditionValue = newValue;
        alarm.dataSourceProperty.displayProperty.condition[2].conditionValue = newValue;
        this.setState({
          template,
          alarm,
        });
        break;
      default:
        template.dataSourceProperty.displayProperty.displayZoom = value;
        alarm.dataSourceProperty.displayProperty.displayZoom = value;
        consumption.dataSourceProperty.displayProperty.displayZoom = value;
        this.setState({
          template,
          alarm,
          consumption,
        });
    }
    if (displayMode === SourceSheet.templateEnum.Meter.id) {
      const interval: any = {
        dateMin: moment(template.dataSourceProperty.displayProperty.condition[1].conditionValue).toISOString(),
        dateMax: moment(template.dataSourceProperty.displayProperty.condition[2].conditionValue).toISOString(),
        zoom: template.dataSourceProperty.displayProperty.displayZoom.toUpperCase() || 'DAY',
      };
      dispatch(meterActions.getInfosData(location.search, siteId, interval));
    } else if (displayMode === SourceSheet.templateEnum.Radio.id) {
      template.dataSourceProperty.displayProperty.condition[0].conditionValue = radios.fetchedRadio.general.serial;
      alarm.dataSourceProperty.displayProperty.condition[0].conditionValue = radios.fetchedRadio.general.serial;
      dispatch(radioActions.getStatementsAndAlarms(siteId, template, alarm, radios.fetchedRadio));
    }
  };

  /**
   * Met à jour le template des alarmes et récupère les
   * nouvelles données
   *
   * @method updateAlarms
   * @param {any} alarm Template des alarmes
   */
  updateAlarms = (alarm: any, selectedTypes: Array<any>) => {
    const { dispatch, radios, displayMode, meters, locations } = this.props;
    const siteId = locations && locations.fetchedLocation && locations.fetchedLocation.id;
    this.setState({
      alarm,
      selectedTypes,
    });
    if (displayMode === SourceSheet.templateEnum.Meter.id) {
      // dispatch(meterActions.getAlarms(siteId, alarm, this.currentMeter()));
    } else if (displayMode === SourceSheet.templateModalEnum.Radio.id) {
      dispatch(radioActions.getAlarms(siteId, alarm, radios.fetchedRadio));
    }
  };

  /**
   * Simple méthode vérifiant si l'objet courant est un compteur
   *
   * @method isMeter
   * @returns {boolean}
   */
  isMeter = () => {
    const { displayMode } = this.props;
    return displayMode === SourceSheet.templateEnum.Meter.id;
  };

  /**
   * Simple méthode générant les infos additionnelles de base
   *
   * @method getAdditionalInfo
   * @returns {any}
   */
  getAdditionalInfo = (type: string) => {
    return {
      type,
    };
  };

  /**
   * Gère l'affichage de la modal d'impression
   *
   * @method showPrint
   */
  showPrint = () => {
    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen,
    });
  };

  /**
   * Parse les données pour que son format soit consommable par
   * la fiche d'impression
   *
   * @method parseSheetData
   * @param {any} labels      Labels d'index
   * @param {any} values      Valeurs d'index
   * @param {any} labelsConso Labels de consommation
   * @param {any} valuesConso Valeurs de consommation
   * @param {any} dataAlarms  Données des alarmes
   * @returns {any} Les données consommées par la fiche d'impression
   */
  parseSheetData = (labels: any, values: any, labelsConso: any, valuesConso: any, dataAlarms: any): Sheet => {
    if (undefined !== dataAlarms && dataAlarms !== null) {
      dataAlarms.additionnal = this.getAdditionalInfo(widgetConstants.ALARMWIDGET);
    }

    return {
      index: {
        labels,
        values,
        additionnal: this.isMeter()
          ? this.getAdditionalInfo(widgetConstants.READINDEXWIDGET)
          : this.getAdditionalInfo(widgetConstants.READRADIOWIDGET),
      },
      conso: {
        labels: labelsConso,
        values: valuesConso,
        additionnal: this.getAdditionalInfo(widgetConstants.READCONSUMTIONWIDGET),
      },
      alarm: dataAlarms,
    };
  };

  formateDateToCompare(date: any) {
    const reg = /[,]/g;

    let i = 0;
    let dateElement: any = [];
    let newDate: any = [];

    date
      .toString()
      .split('')
      .forEach((element: any) => {
        if (i < 10) {
          dateElement.push(element);
        }
        if (i === 10) {
          newDate = dateElement.toString().replace(reg, '');
          dateElement = [];
        }
        i++;
      });

    return newDate;
  }

  handleChartSelector(chartType: string) {
    const { chartDisplay } = this.state;
    let newList = _.cloneDeep(chartDisplay);

    if (newList.find(el => el === chartType)) {
      newList = newList.filter(el => el !== chartType);
    } else {
      newList.push(chartType);
    }

    this.setState({
      chartDisplay: newList,
    });
  }

  /**
   * Construit toute la partie sélecteur d'intervalles
   *
   * @method generateIntervalSelector
   * @returns {JSX} Le sélecteur d'intervalles
   */
  generateIntervalSelector = currentZoom => {
    const { locales, colors, dispatch, meters, withoutDateComponent, mask, t, interval, displayMode } = this.props;
    const { template, consumption, graphType, alarm, isOpen, isMasked, chartDisplay, selectedTypes } = this.state;
    const fetchElement: any = this.getFetchElement();
    const start: Date = new Date(interval.current ? interval.current.startDate : 0);
    const end: Date = new Date(interval.current ? interval.current.endDate : 1);
    const dateInterval: Interval = { start, end };

    const meterStartDate: Date =
      fetchElement && fetchElement.general && fetchElement.general.startDate
        ? new Date(fetchElement.general.startDate)
        : null;
    let dataSource: any = fetchElement && (fetchElement.reads || fetchElement.statements);
    let reads =
      displayMode === 'Radio'
        ? fetchElement && fetchElement.statements.map(el => el.readList).flat()
        : fetchElement && fetchElement.reads && fetchElement.reads;
    let dataConso: any = fetchElement && fetchElement.consumption;

    if (meterStartDate) {
      dataSource = _.cloneDeep(dataSource).filter(
        it =>
          new Date(it.date ? it.date : 0).getTime() > meterStartDate.getTime() &&
          isWithinInterval(new Date(it.date ? it.date : 0).getTime(), dateInterval)
      );
      dataConso = _.cloneDeep(dataConso).filter(
        it =>
          new Date(it.date ? it.date : 0).getTime() >= meterStartDate.getTime() &&
          isWithinInterval(new Date(it.date ? it.date : 0).getTime(), dateInterval)
      );
      if (displayMode !== 'Radio') {
        reads = _.cloneDeep(reads).filter(
          it =>
            new Date(it.date ? it.date : 0).getTime() > meterStartDate.getTime() &&
            isWithinInterval(new Date(it.date ? it.date : 0).getTime(), dateInterval)
        );
      }
    }

    let labels = reads && reads.map(el => el.date);
    let values = reads && reads.map(el => el.index);

    if (values && values instanceof Array && values.length > 0) {
      values = values.map(it => (_.has(it, 'value') ? it.value : it));
    }

    const labelsConso = dataConso && dataConso.map((it: any) => it.date);
    let valuesConso = dataConso && dataConso.map((it: any) => it.consumption);

    if (valuesConso && valuesConso instanceof Array && valuesConso.length > 0) {
      valuesConso = valuesConso.map(it => (_.has(it, 'value') ? it.value : it));
    }

    const { dataSourceProperty } = template;
    const { dataSourceProperty: dtSourceConso } = consumption;

    const zoom = currentZoom || _.get(dataSourceProperty, 'displayProperty.displayZoom');

    let datas: any = [];
    const alarmsData = _.get(fetchElement, 'alarms.data') || _.get(fetchElement, 'alarms[0].alarms.data');
    if (alarmsData) {
      datas = alarmsData.sort((a: any, b: any) => new Date(a.date) - new Date(b.date));
      const allAlarmType = _.uniq(datas.map(el => _.keys(el.types)).flat());
      if (isMasked) {
        datas.forEach((el: any) => {
          allAlarmType.forEach((type: any) => {
            if (el.types[type]) {
              el.types[type] = false;
              el.isMasked = true;
            }
          });
        });
      } else {
        mask.masks &&
          mask.masks.forEach((el: any) => {
            datas.forEach((data: any) => {
              if (new Date(data.date) > new Date(el.startDate) && new Date(data.date) < new Date(el.endDate)) {
                allAlarmType
                  .filter(type => type.includes(el.alarmName))
                  .forEach((type: any) => {
                    if (data.types[type] === false) {
                      data.types[type] = true;
                      el.isMasked = false;
                    }
                  });
              }
            });
          });
      }
    }
    const dataAlarms = fetchElement && fetchElement.alarms && formatAlarmData(datas, [], locales, colors, t);

    let filteredData: any = [];
    selectedTypes &&
      selectedTypes.forEach((type: any) => {
        filteredData = dataAlarms.values.filter(el => reverseAlarm(el.label, locales.locale).includes(type.value));
      });

    const printData: any = this.parseSheetData(labels, values, labelsConso, valuesConso, dataAlarms);
    let name = fetchElement && fetchElement.general ? fetchElement.general.serial : '';
    if (undefined === name) {
      name = fetchElement.general.name;
    }

    const startDate = _.get(interval, 'current.startDate');

    const endDate = _.get(interval, 'current.endDate');

    let checkPrint = dataSource && dataSource.length === 0 && (dataAlarms.values && dataAlarms.values.length === 0);
    if (this.isMeter()) {
      checkPrint = checkPrint && dataConso && dataConso.length === 0;
    }
    const rmtList =
      interval &&
      interval.current &&
      (_.get(fetchElement, 'reads', []) || [])
        .filter((el: any, index: number) => index !== 0)
        .map((el: any) => readMethodColor(el.source));
    return (
      <div className="container">
        {dataSource && dataAlarms && fetchElement && (
          <div>
            <Modal isOpen={isOpen} toggle={this.showPrint} size="lg">
              <ModalHeader toggle={this.showPrint}>{t('all.text.export_data_from_x', { name })}</ModalHeader>
              <ModalBody>
                <Button
                  onClick={(e: any) => {
                    printMultiSheet('print', name);
                  }}
                  style={{ marginBottom: '10px' }}
                >
                  {t('all.text.save_pdf')}
                </Button>
                <PrintableSourceSheet
                  locales={locales}
                  data={printData}
                  name={name}
                  zoom={zoom}
                  isMeter={this.isMeter()}
                  graphType={graphType}
                  startDate={startDate}
                  endDate={endDate}
                  t={t}
                />
              </ModalBody>
            </Modal>
            <div className="table-info-container">
              <h2>
                <span>
                  <HistoriqueVert height="1em" width="1em" fill="#31c6b3" />
                </span>
                {t('all.read_meter.read_history')}
              </h2>{' '}
              <div className="margin">
                <Button disabled={checkPrint} onClick={(e: any) => this.showPrint()}>
                  {t('all.text.preview_pdf')}
                </Button>
                <Button
                  disabled={checkPrint}
                  style={{ marginLeft: '15px' }}
                  onClick={(e: any) => {
                    if (!this.isMeter()) {
                      dispatch(
                        exportAction.exportCsv(
                          [fetchElement.general.pdi.id],
                          this.formateDateToCompare(startDate),
                          this.formateDateToCompare(endDate),
                          ',',
                          fetchElement.general.location.id
                        )
                      );
                    }
                    Object.keys(printData).forEach((it: any) => {
                      if (this.isMeter()) {
                        const toExport = chart2table(printData[it], it, t);
                        downloadCSV(toExport.columns, toExport.data, `${name}-${it}`);
                      }
                    });
                  }}
                >
                  {t('all.text.export_row_data')}
                </Button>
              </div>
              {!withoutDateComponent && (
                <div className="source-sheet-date-component">
                  <BestDateComponent
                    handleChange={this.updateTemplate}
                    dateMin={startDate}
                    dateMax={endDate}
                    zoom={zoom}
                  />
                </div>
              )}
              {this.isMeter() && (
                <Fragment>
                  <div className="flex-box">
                    <h3 className="title-chart">
                      {t('all.read_meter.cons')} -{' '}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t(`meter.unit.${_.get(fetchElement, 'general.fluidType', 'W')}`, {
                            interpolation: { escapeValue: false },
                          }),
                        }}
                      />{' '}
                      - {t(`best_date_component.zoom_option.${zoom && zoom.toLowerCase()}`)}
                    </h3>
                    <h3 className="title-chart right" onClick={() => this.handleChartSelector('consommation')}>
                      {chartDisplay.find(el => el === 'consommation') ? (
                        <SvgArrowVert height="0.6em" fill="#31c6b3" />
                      ) : (
                        <SvgSimpleArrowDroite height="1em" fill="#6c757d" />
                      )}
                    </h3>
                  </div>
                  {chartDisplay.find(el => el === 'consommation') && (
                    <div className="chart-container">
                      {meters.loadingMeterInfo ? (
                        <LoadingBand message="Chargement des consommations ..." />
                      ) : (
                        <BarChart
                          labels={labelsConso}
                          data={valuesConso}
                          t={t}
                          zoom={zoom}
                          rmtColorList={rmtList}
                          metersInfo={meters.allMetersInfo}
                          formatTime="Day"
                          meter={fetchElement}
                        />
                      )}
                    </div>
                  )}
                </Fragment>
              )}
              <Ligne />
              <div className="flex-box">
                <h3 className="title-chart">
                  {t('all.meter.index')} -{' '}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t(`meter.unit.${_.get(fetchElement, 'general.fluidType', 'W')}`, {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  />{' '}
                  - {t(`best_date_component.zoom_option.${zoom.toLowerCase()}`)}
                </h3>
                <h3 className="title-chart right" onClick={() => this.handleChartSelector('index')}>
                  {chartDisplay.find(el => el === 'index') ? (
                    <SvgArrowVert height="0.6em" fill="#31c6b3" />
                  ) : (
                    <SvgSimpleArrowDroite height="1em" fill="#6c757d" />
                  )}
                </h3>
              </div>
              {chartDisplay.find(el => el === 'index') && (
                <div className="chart-container">
                  {meters.loadingMeterInfo ? (
                    <LoadingBand message="Chargement des index ..." />
                  ) : (
                    <BarChart labels={labels} data={values} t={t} zoom={zoom} meter={fetchElement} />
                  )}
                </div>
              )}
              <Ligne />
              <Fragment>
                <div className="flex-box">
                  <h3 className="title-chart alarm">
                    {t('all.alarm_meter.alarm')} - {t(`best_date_component.zoom_option.${zoom.toLowerCase()}`)}
                  </h3>{' '}
                  <h3 className="title-chart right" onClick={() => this.handleChartSelector('alarm')}>
                    {chartDisplay.find(el => el === 'alarm') ? (
                      <SvgArrowVert height="0.6em" fill="#31c6b3" />
                    ) : (
                      <SvgSimpleArrowDroite height="1em" fill="#6c757d" />
                    )}
                  </h3>
                </div>
                {chartDisplay.find(el => el === 'alarm') && (
                  <>
                    <p style={{ fontSize: '16px' }}>
                      {t('all.alarm_meter.show_masked_alarm_plural')} :
                      <span>
                        <Switch
                          style={{ marginLeft: '10px' }}
                          onChange={() => {
                            this.setState({
                              isMasked: !this.state.isMasked,
                            });
                          }}
                          defaultChecked={isMasked}
                        />
                      </span>
                    </p>
                    <br />
                    <AlarmSelector template={alarm} update={this.updateAlarms} locales={locales} />
                    <div className="chart-container alarm">
                      {meters.loadingMeterInfo ? (
                        <LoadingBand message="Chargement des alarmes ..." />
                      ) : (
                        <Chart
                          labels={dataAlarms.labels}
                          data={selectedTypes.length > 0 ? filteredData : dataAlarms.values}
                          formatTime={zoom}
                          click={this.onClickWeek}
                          type="AlarmMaskHistogram"
                          isMasked={isMasked}
                          additional={this.getAdditionalInfo(alarm.dataSourceProperty.displayID)}
                          noDataLabels
                          t={t}
                        />
                      )}
                    </div>
                  </>
                )}
              </Fragment>
            </div>
            <br />
          </div>
        )}
      </div>
    );
  };

  /**
   * Gère le clic sur un élément de graphe en vue
   * semaine pour zoomer sur celle-ci au jour
   *
   * @method onClickWeek
   * @param {Object} event Evènement
   * @param {Array<any>} item Element
   */
  onClickWeek = (event: Object, item: Array<any>) => {
    if (item && item.length > 0) {
      const { template, consumption, alarm } = this.state;
      if (template.dataSourceProperty.displayProperty.displayZoom === 'Week') {
        const { _model: model } = item[0];
        const momentLabel = moment(model.label, 'DD/MM/YYYY');
        const newStart = momentLabel
          .clone()
          .startOf('week')
          .format(`${moment.HTML5_FMT.DATE} ${moment.HTML5_FMT.TIME_SECONDS}`);
        const newEnd = momentLabel
          .clone()
          .endOf('week')
          .format(`${moment.HTML5_FMT.DATE} ${moment.HTML5_FMT.TIME_SECONDS}`);

        template.dataSourceProperty.displayProperty.condition[1].conditionValue = newStart;
        template.dataSourceProperty.displayProperty.condition[2].conditionValue = newEnd;
        template.dataSourceProperty.displayProperty.displayZoom = 'Day';

        consumption.dataSourceProperty.displayProperty.condition[1].conditionValue = newStart;
        consumption.dataSourceProperty.displayProperty.condition[2].conditionValue = newEnd;
        consumption.dataSourceProperty.displayProperty.displayZoom = 'Day';

        alarm.dataSourceProperty.displayProperty.condition[1].conditionValue = newStart;
        alarm.dataSourceProperty.displayProperty.condition[2].conditionValue = newEnd;
        alarm.dataSourceProperty.displayProperty.displayZoom = 'Day';

        this.updateTemplate(template, consumption, alarm, newStart, newEnd);
      }
    }
  };

  /**
   * Construit le composant
   *
   * @method render
   * @returns {JSX} Le composant
   */
  render() {
    const {
      displayMode,
      meters,
      radios,
      sourcesheetContext,
      match,
      locationId,
      withoutDateComponent,
      lastPertinentRead,
      fullPdi,
    } = this.props;
    const fetchElement: any = this.isMeter() ? meters && this.currentMeter() : radios && radios.fetchedRadio;
    const intervalSelector = this.generateIntervalSelector(this.state.template.dataSourceProperty.displayZoom);
    const historic = this.isMeter()
      ? this.generateHistoricRadioForMeterFiche()
      : this.generateHistoricMetersForRadioFiche();
    const generalInfo = fetchElement && fetchElement.general;
    const loading = this.isMeter() ? meters.loading : radios.loading;
    const error = this.isMeter() ? meters.error : radios.error;
    const gps = !sourcesheetContext ? this.getGPS() : null;
    const lastRead = fetchElement && fetchElement.lastRead;
    const siteId = !sourcesheetContext ? match.params.locationId : locationId;
    return (
      <>
        {loading && <Loading />}
        <div className="col-md-12">
          {error && <ErrorBand message={error} />}
          {generalInfo && (
            <div>
              {displayMode === SourceSheet.templateEnum.Meter.id ? (
                <Meter
                  intervalSelector={intervalSelector}
                  historic={historic}
                  infoGeneral={generalInfo}
                  loading={loading}
                  error={error}
                  gps={gps}
                  markersToCreate={
                    gps &&
                    _.get(meters, 'fetchedMeter.general.gpsPosition') && [
                      { gpsPosition: { lat: gps.latitude, lng: gps.longitude } },
                    ]
                  }
                  lastRead={lastRead}
                  locationId={siteId}
                  context={sourcesheetContext}
                  displayLinkToPdi={sourcesheetContext !== 'pdi'}
                  withoutDateComponent={withoutDateComponent}
                  lastPertinentRead={lastPertinentRead}
                  fullPdi={fullPdi}
                />
              ) : (
                <Radio
                  intervalSelector={intervalSelector}
                  historic={historic}
                  infoGeneral={generalInfo}
                  loading={loading}
                  error={error}
                  locationId={siteId}
                />
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, meters, locations, locales, colors, radios, mask, interval } = state;
  const { user } = authentication;

  return {
    alert,
    user,
    meters,
    radios,
    locations,
    locales,
    colors,
    mask,
    interval,
  };
}

export default withTranslation()(connect(mapStateToProps)(SourceSheet));
