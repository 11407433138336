import _ from 'lodash';
import synchroComConstants from '../_constants/synchrocom.constants';

export default function synchrocom(state: any = {}, action: any) {
  switch (action.type) {
    case synchroComConstants.GETCOMMUNICATION_REQUEST:
      state.currentState = state.currentState ? state.currentState : {};
      state.battery = state.battery ? state.battery : {};
      state.version = state.version ? state.version : {};
      state.online = state.online ? state.online : {};
      state.updateDate = state.updateDate ? state.updateDate : {};
      state.currentCommand = state.currentCommand ? state.currentCommand : {};
      state.loading = true;
      return {
        ...state,
      };
    case synchroComConstants.GETCOMMUNICATION_SUCCESS:
      const copyState = _.cloneDeep(state);
      let hasCom = false;
      action.currentState.forEach((element: any) => {
        copyState.currentState[element.idPortable] = element.hasCommunication;
        copyState.battery[element.idPortable] = element.batteryLevel;
        _.merge(copyState.battery[element.idPortable], { lvl: element.batteryLevel });
        copyState.version[element.idPortable] = element.saphirVersion;
        copyState.updateDate[element.idPortable] = element.updateDate;
        copyState.online[element.idPortable] = element.online;
        copyState.currentCommand[element.idPortable] = element.currentCommand;
        if (element.hasCommunication) {
          hasCom = true;
        }
      });
      copyState.hasCom = hasCom;
      return {
        ...copyState,
      };
    case synchroComConstants.GETCOMMUNICATION_FAILURE:
      return {
        ...state,
        error: "Impossible d'obtenir l'etat actuel du telephone",
      };

    case synchroComConstants.GET_ROUNDS_REQUEST:
      return {
        ...state,
      };
    case synchroComConstants.GET_ROUNDS_SUCCESS:
      if (state.rounds !== action.rounds) {
        state.rounds = action.rounds;
        return {
          ...state,
        };
      }
    case synchroComConstants.GET_ROUNDS_FAILURE:
      return {
        ...state,
        error: "Impossible d'obtenir l'etat actuel des tournées",
      };

    case synchroComConstants.UPDATELOADER_SUCCESS:
      // state.currentState[action.deviceId] = true;
      return {
        ...state,
      };

    case synchroComConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
