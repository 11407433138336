import React from 'react';
import { connect } from 'react-redux';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { geolocationActions, localeActions } from '../_actions';
import MarkerClustererComponent from './MarkerClustererComponent';
import LoadingBand from '../Bands/Loading';
import mapStyles from '../mapStyles.json';
import { translate } from '../_helpers';
import NoFilled from '../Message/NoFilled';
import ModeleVert from '../SvgComponents/ModeleVert';
import _ from 'lodash';
import SvgRadioVert from '../SvgComponents/RadioVert';
import SvgCalendrierVert from '../SvgComponents/CalendrierVert';
import SvgReadMeterType from '../SvgComponents/ReadMeterType';
import SvgCroixNoir from '../SvgComponents/croixNoir';
import getC from '../TourneeDetail/Icon_correspondance';
import { withTranslation } from 'react-i18next';

interface Props {
  lat: number | string;
  lng: number | string;
  loaded: any;
  google: any;
  name: string;
  draggable: boolean;
  draggableMap: boolean;
  zoom: number;
  markersToCreate: Array<Object>;
  history: any;
  handleMarkerChange: Function;
  dispatch: any;
  geolocalisation: {
    position: {
      coords: {
        lat?: number | string;
        lng?: number | string;
      };
    };
  };
  geolocation: any;
  hideMarker: boolean;
  clickable: boolean;
  allowSelectedMeter?: boolean;
  selectedMtr: any;
  toogleMap: any;
  locales: any;
  handleToggle: any;
  t: any;
}

interface State {
  lat?: number | string;
  lng?: number | string;
  toggle: boolean;
  x: number;
  info: MeterInfo;
  init: boolean;
}

interface MeterInfo {
  ficheId: number;
  pdiId: number;
  serial: string;
  serialRadio: string;
}

interface CrysWindow extends Window {
  google: any;
}

declare var window: CrysWindow;

const alarmList = [
  'Fraud',
  'Leak',
  'Backflow',
  'MeterBlock',
  'Battery',
  'Clock',
  'ReversedMeter',
  'Overflow',
  'Underflow',
];

/**
 * Affiche la map en version mini
 *
 * @class TinyMapContainer
 * @exports Component
 */
export class MapContainer extends React.Component<Props, State> {
  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      lat: '',
      lng: '',
      toggle: false,
      x: 0,
      info: 0,
      init: false,
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { lat } = state;
    const { geolocation, lat: propLat, lng: propLng } = props;
    if ((lat === '' || lat === null) && geolocation.position) {
      const { position } = geolocation;
      const { coords } = position;
      return {
        lat: coords.latitude,
        lng: coords.longitude,
      };
    }
    if (propLat && propLat !== '') {
      return {
        lat: propLat,
        lng: propLng,
      };
    }

    return {
      lat: state.lat,
      lng: state.lng,
    };
  }

  /**
   * @method componentDidMount
   */
  componentDidMount() {
    const { lat, lng, dispatch } = this.props;
    const { init } = this.state;
    const doc = document.getElementsByClassName('map')[0];
    dispatch(localeActions.load());
    if (doc && !init) {
      const docInfo = doc.getBoundingClientRect();
      const x = docInfo.width - 200;
      this.setState({
        x,
      });
    }
    // si les props sont vides
    if (lat === '' || lat === null) {
      dispatch(geolocationActions.get());
    }
    this.setState({
      lat,
      lng,
    });
  }

  /**
   * @method componentDidUpdate
   * @param {Props} prevProps Props précédentes
   * @param {State} prevState State précédent
   */
  componentDidUpdate(prevProps: Props, prevState: State) {
    const { geolocation, handleMarkerChange } = prevProps;
    const { lat } = prevState;
    if ((lat === '' || lat === null) && geolocation.position && handleMarkerChange) {
      const { position } = geolocation;
      const { coords } = position;
      this.reverse(coords.latitude, coords.longitude)
        .then(result => handleMarkerChange(result))
        .catch(error => console.log(error));
    }
  }

  /**
   * Evènement gérant l'arrêt de glisser-déposer d'un marqueur
   *
   * @method onMarkerDragEnd
   * @param {Object} marker Marqueur
   * @param {Object} map Carte
   * @param {any} positions Positions
   */
  onMarkerDragEnd = (marker: Object, map: Object, positions: any) => {
    const { latLng } = positions;
    const { handleMarkerChange } = this.props;

    const lat = latLng.lat();
    const lng = latLng.lng();
    this.reverse(lat, lng)
      .then(result => handleMarkerChange(result))
      .catch(error => console.log(error));
  };

  /**
   * @method reverse
   * @returns {Promise}
   */
  reverse = (lat: number, lng: number): Promise<Function> => {
    const latlng = { lat, lng };
    const geocoder = new window.google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: latlng }, (results: any, status: any) => {
        if (status !== window.google.maps.GeocoderStatus.OK) {
          reject(status);
        }
        resolve(results);
      });
    });
  };

  handleClick = (e: any, m: any) => {
    const { handleToggle } = this.props;
    if (e && e.domEvent && e.domEvent.clientX) {
      if (handleToggle) handleToggle(m);
      this.setState({
        info: m,
      });
    }
  };

  handleFicheMeter = () => {
    const { history, selectedMtr, allowSelectedMeter } = this.props;
    const { info } = this.state;
    const selectedMeter = allowSelectedMeter ? selectedMtr : info;
    let toPath = '';
    if (history.location.pathname.includes('/synchronisation/tournees')) {
      toPath = `${history.location.pathname}/fiche/${selectedMeter && selectedMeter.ficheId}`;
    } else {
      toPath = `${history.location.pathname.match(/\/locations\/\d+/)[0]}/pdi/${selectedMeter && selectedMeter.pdiId}`;
    }
    history.push({
      pathname: toPath,
    });
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    try {
      const {
        loaded,
        google,
        zoom,
        name,
        draggable,
        markersToCreate,
        history,
        hideMarker,
        clickable,
        selectedMtr,
        locales,
        hoverEvent,
        handleToggle,
        iconCenter,
        allowSelectedMeter,
        t,
      } = this.props;
      const { lat, lng, toggle, x, info } = this.state;
      const selectedMeter = allowSelectedMeter ? selectedMtr : info;
      const { gpsPosition }: any = selectedMeter || {};
      if (!loaded) return <LoadingBand message={t('single_gmap.loading_band.loading_map')} />;
      const markerClustererProps = {
        clickable,
        markersToCreate,
        history,
        handleClick: this.handleClick,
      };

      //Change marker icon on click
      /*if (markersToCreate) {
        markersToCreate.filter(el => el.icon !== null).forEach(el => (el.icon = null));
        markersToCreate
          .filter(el => el.id === _.get(selectedMtr, 'id') || el.id === _.get(info, 'id'))
          .forEach(el => (el.icon = 'marqueurClic'));
      }*/

      return (
        <Map
          className="map"
          google={google}
          zoom={selectedMeter ? 18 : zoom || 12}
          initialCenter={{ lat, lng }}
          center={gpsPosition ? { lat: gpsPosition.lat, lng: gpsPosition.lng } : { lat, lng }}
          styles={mapStyles}
          zoomControl={true}
        >
          {!hideMarker && (
            <Marker
              name={name}
              position={{ lat, lng }}
              draggable={draggable}
              onDragend={this.onMarkerDragEnd}
              handleToggle={handleToggle}
              icon={{
                url: getC(iconCenter || 'markerHome'),
                anchor: new google.maps.Point(16, 24),
                scaledSize: new google.maps.Size(iconCenter ? 32 : 20, 32),
              }}
            />
          )}
          <MarkerClustererComponent {...markerClustererProps} />
          {allowSelectedMeter && selectedMeter && (
            <div className="clean-meter-bar" style={{ marginBottom: 0, position: 'relative' }}>
              <div className="flex-box">
                <div className="c20 background-texture left" style={{ position: 'relative' }}>
                  <span>Compteur</span>
                  <h3>{selectedMeter.serial}</h3>
                  <span
                    className="clickable"
                    onClick={this.handleFicheMeter}
                    style={{
                      textDecoration: 'underline',
                      textUnderlinePosition: 'auto',
                      color: 'black',
                      fontSize: '0.9em',
                    }}
                  >
                    {t('all.pdi.go_to_pdi')}
                  </span>
                </div>
                <div className="c20 b-right">
                  <div className="flex-box center">
                    <SvgRadioVert height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />{' '}
                    <div>
                      <span>{t('all.radio.radio')}</span>
                      <h3>{selectedMeter.serialRadio || t('single_gmap.text.no_radio')}</h3>
                    </div>
                  </div>
                </div>
                <div className="c20 b-right">
                  <div className="flex-box center">
                    <SvgCalendrierVert height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />{' '}
                    <div>
                      <span>{t('all.read_meter.read_last_date')}</span>
                      {moment.utc(_.get(selectedMeter, 'lastRead.date')).isValid() ? (
                        <h3>{moment.utc(_.get(selectedMeter, 'lastRead.date')).format('DD/MM/YYYY')}</h3>
                      ) : (
                        <NoFilled />
                      )}
                    </div>
                  </div>
                </div>
                <div className="c20 b-right">
                  <div className="flex-box center">
                    <div className="centerAlign">
                      <ModeleVert height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />{' '}
                    </div>
                    <div className="centerAlign">
                      <span>{t('all.meter.index')}</span>
                      <h3>
                        {_.get(selectedMeter, 'lastRead.index') === null ? '-' : _.get(selectedMeter, 'lastRead.index')}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="c20 b-right">
                  <div className="flex-box center">
                    <div className="centerAlign">
                      <span>{t('all.read_meter.cons')}</span>
                      <h3>
                        {_.get(selectedMeter, 'lastRead.consumption') === null
                          ? '-'
                          : _.get(selectedMeter, 'lastRead.consumption')}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="c20 b-right" style={{ border: 0 }}>
                  <div className="flex-box center">
                    <div className="centerAlign">
                      <SvgReadMeterType height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />{' '}
                    </div>
                    <div className="centerAlign">
                      <span>{t('all.read_meter.read_method')}</span>
                      <h3>
                        {translate('fr', 'meterReadMethod', _.get(selectedMeter, 'lastRead.method'), locales.locale) ||
                          '-'}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="close-infomap-container"
                style={{
                  borderRadius: '50%',
                  height: '25px',
                  width: '25px',
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                }}
                onClick={() => handleToggle(undefined)}
              >
                <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                  <span className="absoluteCentered" style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                    <SvgCroixNoir height="1.2em" fill="#000" />
                  </span>
                </div>
              </div>
            </div>
          )}
        </Map>
      );
    } catch (error) {
      {
        console.log('ERROE SIMPLEGMAP : ', error);
      }
      return <div className="map">{t('single_gmap.failure_text.not_able_show_map')}</div>;
    }
  }
}

function mapStateToProps(state: any) {
  const { authentication, geolocation, locales } = state;
  const { user } = authentication;
  return {
    user,
    geolocation,
    locales,
  };
}

const mapping: any = connect(mapStateToProps)(MapContainer);

const simpleGmapMapping = withRouter(mapping);
const simpleGmapGoogleApiWrapper = GoogleApiWrapper((props: any) => ({
  apiKey: 'AIzaSyBSRYNpTYG6I-BWu_MSDpnkDyuKsJrafc4',
  language: props.t('single_gmap.map_setting.language'),
}))(simpleGmapMapping);

const tr = withTranslation()(simpleGmapGoogleApiWrapper);
export default tr;
