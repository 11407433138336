import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import tradFr from './TraductionFile/fr';
import tradBk from './TraductionFile/bk';
import tradEn from './TraductionFile/en';
import tradEs from './TraductionFile/es';
import React from 'react';
// the translations
// (tip move them in a JSON file and import them)
export const resources = {
  //en: tradEn,
  fr: tradFr,
  bk: tradBk,
  en: tradEn,
  es: tradEs,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('language') || 'fr',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    parseMissingKeyHandler(key) {
      return `============= Unknown key : ${key} =============`;
    },
  });

export default i18n;
