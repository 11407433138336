import React from 'react';

const SvgExportPersonalise = (props: any) => (
  <svg viewBox="0 0 170.63 139.3" {...props}>
    <g>
      <g>
        <path
          fill={props.fill}
          d="M101.24,127.65c0,0.9-0.76,1.66-1.66,1.66H11.65c-0.9,0-1.66-0.76-1.66-1.66v-116c0-0.9,0.76-1.66,1.66-1.66
			h87.92c0.9,0,1.66,0.76,1.66,1.66v17.37h9.99V11.65C111.23,5.23,106,0,99.57,0H32.6h-9.99H11.65C5.23,0,0,5.23,0,11.65v116
			c0,6.42,5.23,11.65,11.65,11.65h87.92c6.42,0,11.65-5.23,11.65-11.65v-13.98v-6.2h-9.99V127.65z"
        />
        <path
          fill={props.fill}
          d="M91.23,38.91l-5.85-5.85c-2.09-2.09-4.87-3.24-7.83-3.24c-2.95,0-5.73,1.14-7.83,3.22l-5.66,5.66
			c-0.59,0.59-1.03,1.32-1.29,2.11c-0.79,0.26-1.52,0.7-2.11,1.29L27.33,75.45c-0.62,0.62-1.07,1.38-1.32,2.22c0,0-0.01,0-0.01,0
			c-1.85,0.54-3.25,2.04-3.67,3.91l-3.31,14.94c-0.46,2,0.02,4.07,1.31,5.68c1.29,1.62,3.21,2.55,5.27,2.55
			c0.56,0,1.13-0.07,1.67-0.21l14.61-3.54c1.82-0.44,3.27-1.82,3.81-3.61c0.86-0.25,1.63-0.7,2.26-1.34l33.33-33.36
			c0.59-0.59,1.03-1.32,1.29-2.11c0.79-0.25,1.52-0.69,2.11-1.29l6.55-6.55c1.87-1.84,2.91-4.31,2.91-6.94
			C94.13,43.2,93.1,40.74,91.23,38.91z M39.17,95.49l-13.38,3.24c-0.32,0.07-0.57-0.03-0.76-0.26c-0.08-0.11-0.21-0.33-0.14-0.63
			l3.01-13.62L39.17,95.49z M76.5,58.99l-32.27,32.3L32.11,79.17l32.27-32.3L76.5,58.99z M88.12,45.82c0,1.01-0.4,1.96-1.14,2.68
			l-6.03,6.03L68.84,42.41l5.11-5.1c1.94-1.91,5.28-1.91,7.19,0l5.89,5.89C87.73,43.89,88.12,44.82,88.12,45.82z"
        />
      </g>
      <g>
        <path
          fill={props.fill}
          d="M101.6,68.7c0,2.78,2.28,5.09,5.1,5.14l46.25-0.46l-16.86,16.86c-2.01,2.01-2.01,5.29,0,7.3s5.29,2.01,7.3,0
			l25.73-25.73c2.01-2.01,2.01-5.29,0-7.3l-25.73-25.73c-2.01-2.01-5.29-2.01-7.3,0s-2.01,5.29,0,7.3l16.97,16.97l-46.44,0.46
			C103.79,63.6,101.59,65.88,101.6,68.7z"
        />
      </g>
    </g>
  </svg>
);

export default SvgExportPersonalise;
