import radioConstants from '../_constants/radio.constants';
import { action } from '../_interfaces/action';

export default function radios(state = {}, action: action) {
  switch (action.type) {
    case radioConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case radioConstants.GETALL_SUCCESS:
      return {
        items: action.radios,
      };
    case radioConstants.GETALL_FAILURE:
      return {
        error: "Impossible d'obtenir la liste distante des radios",
      };
    case radioConstants.GETALLWITHCHILDREN_REQUEST:
      return {
        loading: true,
      };
    case radioConstants.GETALLWITHCHILDREN_SUCCESS:
      return {
        items: action.radios,
      };
    case radioConstants.GETALLWITHCHILDREN_FAILURE:
      return {
        error: "Impossible d'obtenir la liste distante des radios",
      };
    case radioConstants.GETINFO_REQUEST:
      return {
        loading: true,
      };
    case radioConstants.GETINFO_SUCCESS:
      return {
        fetchedRadio: action.fetchedRadio,
      };
    case radioConstants.GETINFO_FAILURE:
      return {
        error: "Impossible d'obtenir les informations distantes du module radio",
      };
    case radioConstants.GETSTATES_REQUEST:
      return {
        loading: true,
      };
    case radioConstants.GETSTATES_SUCCESS:
      return {
        fetchedRadio: action.fetchedRadio,
      };
    case radioConstants.GETSTATES_FAILURE:
      return {
        error: "Impossible d'obtenir les informations de relevés du compteur",
      };
    case radioConstants.GETALARMS_REQUEST:
      return {
        loading: true,
      };
    case radioConstants.GETALARMS_SUCCESS:
      return {
        fetchedRadio: action.fetchedRadio,
      };
    case radioConstants.GETALARMS_FAILURE:
      return {
        error: "Impossible d'obtenir les alarmes du compteur",
      };
    case radioConstants.GETDATA_SUCCESS:
      return {
        fetchedRadio: action.fetchedRadio,
      };
    case radioConstants.GETDATA_FAILURE:
      return {
        error: "Impossible d'obtenir les données complémentaires du compteur",
      };
    case radioConstants.GET_INFO_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case radioConstants.GET_INFO_SUCCESS:
      return {
        fetchedRadio: action.fetchedRadio,
        ...state,
      };
    case radioConstants.GET_INFO_FAILURE:
      return {
        error: "Impossible d'obtenir les informations de la radio",
      };
    case radioConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
