import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, Form } from 'reactstrap';
import { localeActions } from '../_actions';
import { connect } from 'react-redux';
import { AlertContent } from '../_components';

interface Props extends React.Props<any> {
  notifications: any;
  dispatch: Function;
  user: any;
  notif: any;
  show: any;
  isOpen: boolean;
  locales: any;
}

interface State {}

/**
 * @class SingleNotification
 * @extends {Component}
 */
class SingleNotification extends Component<Props, State> {
  componentDidMount() {
    localeActions.load();
  }

  render() {
    const { notif, show, isOpen, locales } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={show} size="xl">
        <ModalHeader toggle={show}>{notif.title}</ModalHeader>
        <Form>
          <ModalBody>
            <div className="table-info-container">
              {locales && locales.locale && <AlertContent content={notif.content} locale={locales.locale} />}
            </div>
          </ModalBody>
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, notifications, locales } = state;
  const { user } = authentication;
  return {
    user,
    notifications,
    locales,
  };
}

const connectedSingleNotification = connect(mapStateToProps)(SingleNotification);
export default connectedSingleNotification;
