import React from 'react';

const FluidGaz = (props: any) => (
  <svg viewBox="0 0 16 16" {...props}>
    <path fill={props.fill} d="M6.8,15.8L6.8,15.8C6.8,15.8,6.8,15.8,6.8,15.8C6.8,15.8,6.8,15.8,6.8,15.8z" />
    <path
      fill={props.fill}
      d="M7.2,0.3C7,0.2,6.8,0.2,6.6,0.3C6.4,0.4,6.3,0.7,6.4,0.9C6.8,2.3,4.7,6,3.3,8c-0.6,0.9-0.8,1.9-0.8,2.9
c0,2.1,1.2,4,3.1,5c0.2,0.1,0.5,0,0.6-0.2c0.1-0.2,0.1-0.5-0.1-0.7c0,0-2-1.6,0.1-5.1c0.2,0.4,0.5,0.9,0.6,1.5
c0,0.2,0.2,0.3,0.3,0.4c0.2,0,0.4,0,0.5-0.1c0.1-0.1,1.6-1.6,1.1-4.7c0.8,0.7,1.7,1.8,2,3.2c0.4,1.6,0,3.3-1.2,5
c-0.1,0.2-0.1,0.5,0.1,0.7C9.8,15.9,10,16,10.1,16c0.1,0,0.2,0,0.3-0.1c0.1-0.1,3-2.7,3.1-4.9C13.6,5.2,7.4,0.5,7.2,0.3z"
    />
  </svg>
);

export default FluidGaz;
