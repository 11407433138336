import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { Button, ButtonDropdown, ButtonGroup, Col, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { locales, locations } from '../_interfaces/reducers';
import IntervalSelector from '../Meter/IntervalSelector';
import { localeActions, locationActions, pdiActions } from '../_actions';
import chartTemplate from '../_shared/ChartTemplate.json';
import alarmTemplate from '../_shared/AlarmTemplate.json';
import { widgetConstants } from '../_constants';
import { recursiveTranslate } from '../_helpers';
import SvgEye from '../SvgComponents/Eye';
import SvgCalendar from '../SvgComponents/HistoriqueVert';
import exportActions from '../_actions/export.actions';
import ListTools from '../List/ListTools';
import listOperator from '../_shared/OperatorList';
import sendExports from './ExportList';
import LoadingBand from '../Bands/Loading';
import SuccessBand from '../Bands/Success';
import WarningBand from '../Bands/Warning';
import ImportBleu from '../SvgComponents/ImportBleu';
import PdiVert from '../SvgComponents/PdiVert';
import { QueryBuilder } from '../QueryBuilder';
import ColumnSelector from '../ColumnSelector/ColumnSelector';
import { ColumnData } from '../_interfaces/column';
import history from '../_helpers/history';
import userActions from '../_actions/user.actions';
import Loading from '../_animations/Loading';
import locale from '../_shared/Locale.json';
import { getFiltersRequest, getLightFilters } from '../QueryBuilder/FilterLSManager';
import RemoteTable from '../RemoteTable/RemoteTable';

type State = {
  value: [{ [string]: string }];
  initialized: boolean;
  availablePdis: any;
  update: boolean;
};

const styles = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 } : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};

const orderOptions = values => {
  return values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed));
};

interface Props {
  locations: locations;
  location: any;
  dispatch: Function;
  match: any;
  locales: locales;
  user: User;
  pdis: any;
}

/**
 * Page d'information d'un compteur virtuel
 *
 * @class VirtualMeter
 * @extends Component
 */
class ExportPdi extends Component<Props> {
  static getDerivedStateFromProps(props: Props, state: State) {
    if (!state.initialized) {
      const { t } = props;

      let sourceColumns: Array<any> = [];

      let initialized: boolean = state.initialized;

      if (
        (props.pdis && (props.pdis.itemsStock && props.pdis.itemsStock.length > 0)) ||
        (props.pdis.items && props.pdis.items.length > 0)
      ) {
        const translatedKeys = recursiveTranslate('fr', 'pdi', locale.fr.pdi, props.locales.locale);
        sourceColumns = translatedKeys.map((champ: any) => {
          const regex = new RegExp('date');
          const elem: any = {
            dataField: champ.path,
            text: t(`columns.${champ.path}`),
            sort: true,
            classes: champ.path === 'address.concatAdress' ? '' : 'crystalList-column',
            filter: textFilter({
              placeholder: t(`columns.${champ.path}`),
            }),
          };
          if (regex.test(champ.path)) {
            elem.sortFunc = ListTools.sortTableDates;
          }
          return elem;
        });
        initialized = true;
      }

      if (props && props.pdis && props.pdis.items && !state.update) {
        state.availablePdis = props.pdis.items;
        state.update = true;
      }
      return {
        sourceColumns,
        name,
        initialized,
      };
    }
  }

  constructor(props) {
    super(props);

    const {
      match: {
        params: { locationId },
      },
    } = props;

    const { t } = props;

    const displayingColumns: any[] = ListTools.getDefaultColumns(ListTools.typeOfList.ExportPDI).map((champ: any) => {
      return {
        dataField: champ,
        text: t(`columns.${champ}`),
        sort: true,
        classes: champ.includes('address') ? '' : 'crystalList-column',
        //        formatter: _.get(ListTools.formatField, champ),
        filter: textFilter({
          placeholder: t(`columns.${champ}`),
          delay: 500,
        }),
      };
    });

    const defaultMinDate = moment()
      .subtract(1, 'month')
      .format(`${moment.HTML5_FMT.DATE}`);
    const defaultMaxDate = moment().format(`${moment.HTML5_FMT.DATE}`);
    const alarm: any = cloneDeep(alarmTemplate);

    const template: any = cloneDeep(chartTemplate);
    template.dataSourceProperty.displayUnit = 'm3';
    template.dataSourceProperty.displayProperty.condition[1].conditionValue = defaultMinDate;
    template.dataSourceProperty.displayProperty.condition[2].conditionValue = defaultMaxDate;
    template.dataSourceName = 'Meter';

    template.dataSourceProperty.displayProperty.condition[0].conditionTitle = 'MeterSerialNumber';
    template.dataSourceProperty.displayValue = 'MeterReadingValue';
    template.dataSourceProperty.displayID = widgetConstants.READINDEXWIDGET;

    const consumption: any = cloneDeep(template);
    consumption.dataSourceProperty.displayID = widgetConstants.READCONSUMTIONWIDGET;
    consumption.dataSourceProperty.displayUnit = 'm3';
    template.dataSourceProperty.displayValue = 'MeterConsumptionValue';

    alarm.dataSourceProperty.displayProperty.condition[1].conditionValue = defaultMinDate;
    alarm.dataSourceProperty.displayProperty.condition[2].conditionValue = defaultMaxDate;
    alarm.dataSourceProperty.displayID = widgetConstants.VIRTUALALARMWIDGET;

    const keyStorage = `filter-${ListTools.typeOfList.ExportPDI}-${locationId}`;

    this.state = {
      template,
      consumption,
      alarm,
      graphType: template.dataSourceProperty.displayProperty.displayType,
      isOpen: false,
      onlyValid: false,
      readsFiltered: [],
      consoFiltered: [],
      alarmsFiltered: [],
      ruleList: getLightFilters(keyStorage),
      tableOptions: { filters: {}, page: 1, sizePerPage: 10, sortField: null, sortOrder: null },
      keyStorage,
      isDown: false,
      pdiList: [],
      secondColumns: [],
      displayingColumns,
      availablePdis: [],
      filtrePdis: [],
      sourceColumns: [],
      options: {
        hideSizePerPage: true,
      },
      mtrKey: Math.floor(Math.random() * Math.floor(1024)),
      update: false,
      dataList: [],
      sendExports,
      readDate: {
        min: defaultMinDate,
        max: defaultMaxDate,
        zoom: 'Day',
      },
      separator: { value: ',', label: t('export_pdi.text.comma') },
      isPreviewed: false,
      separatorBtnOpen: false,
    };

    /* Colonnes dynamiques par défaut
      !columns: [
      !{
      !  dataField: 'address.streetNumber',
      !  text: 'numero de rue',
      !  editable: false,
      !},
      !{
      !  dataField: 'gpsPosition.lat',
      !  text: 'Latitude',
      !  editable: false,
      !},
      !],
 */
    this.generateIntervalSelector = this.generateIntervalSelector.bind(this);
    this.resetDefaultColumns = this.resetDefaultColumns.bind(this);
    this.formatExtractColumns = this.formatExtractColumns.bind(this);
    this.onClickSelect = this.onClickSelect.bind(this);
    this.onClickSeparator = this.onClickSeparator.bind(this);
    this.onClickUpdateTab = this.onClickUpdateTab.bind(this);
    this.onClickExportCsv = this.onClickExportCsv.bind(this);
    this.invalidPreview = this.invalidPreview.bind(this);
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { t } = props;
    const copyState: any = _.cloneDeep(state);
    copyState.displayingColumns = ListTools.getDefaultColumns(ListTools.typeOfList.ExportPDI).map((champ: any) => {
      return {
        dataField: champ,
        text: t(`columns.${champ}`),
        sort: true,
        classes: champ.includes('address') ? '' : 'crystalList-column',
        //        formatter: _.get(ListTools.formatField, champ),
        filter: textFilter({
          placeholder: t(`columns.${champ}`),
          delay: 500,
        }),
      };
    });
    return copyState;
  }

  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { locationId },
      },
      user,
    } = this.props;
    const {
      keyStorage,
      tableOptions: { sizePerPage, page, sortField, sortOrder, filters },
    } = this.state;

    dispatch(localeActions.load());
    dispatch(locationActions.getInfos(locationId));
    dispatch(userActions.get(user.id));
    dispatch(
      pdiActions.getRemotePdi(
        locationId,
        getFiltersRequest(keyStorage),
        sizePerPage,
        page,
        {
          field: sortField,
          way: sortOrder,
        },
        filters,
        null,
        false
      )
    );
  }

  changeDisplayingColumns = (columnsChanged: Array<ColumnData>) => {
    const { columns } = this.state;
    this.setState({
      columns: columnsChanged.length > 0 ? columnsChanged : columns,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { pdis, dispatch, exports, locations } = prevProps;
    const { dataList, isPreviewed, readDate, keyStorage } = prevState;
    const locationId = locations && locations.fetchedLocation && locations.fetchedLocation.id;
    let newPdiList = [];
    if (pdis && pdis.items) {
      newPdiList = pdis.items.map((el: any) => el.id);
    }
    {
      pdis &&
        pdis.items &&
        pdis.items.length > 0 &&
        locationId &&
        dataList.length === 0 &&
        !exports.loading &&
        dispatch(
          exportActions.save(getFiltersRequest(keyStorage), readDate.min, readDate.max, readDate.zoom, locationId)
        );
    }

    if (pdis && pdis.items && pdis.items.length > 0 && dataList.length === 0) {
      this.setState({
        dataList: newPdiList,
      });
    }

    if (exports.export && !isPreviewed) {
      this.setState({ isPreviewed: true });
    }

    const { users, user }: any = this.props;
    const roleList = ['DIOPTASE', 'SUPERADMIN', 'ADMIN'];
    if (roleList.includes(user.role.name)) {
      return true;
    }
    if (
      users &&
      users.fetchedUser &&
      this.props.locations.fetchedLocation &&
      !this.props.locations.fetchedLocation.tournee && // CETTE LIGNE ALEXIS
      !users.fetchedUser.profils.find(
        (el: any) =>
          el.profil.permissions.find((permission: any) => permission.name === 'export.brute') &&
          el.locationCode === this.props.locations.fetchedLocation.code
      )
    ) {
      history.push('/forbidden');
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(locationActions.clear());
    dispatch(pdiActions.clear());
    dispatch(exportActions.invalid());
  }

  formateDateToCompare(date: any) {
    const reg = /[,]/g;

    let i = 0;
    let dateElement: any = [];
    let newDate: any = [];

    date
      .toString()
      .split('')
      .forEach((element: any) => {
        if (i < 10) {
          dateElement.push(element);
        }
        if (i === 10) {
          newDate = dateElement.toString().replace(reg, '');
          dateElement = [];
        }
        i++;
      });

    return newDate;
  }

  updateTemplate = (statementsTemplate: any, consoTemplate: any, alarmsTemplate: any) => {
    const readDate = {
      min: this.formateDateToCompare(statementsTemplate.dataSourceProperty.displayProperty.condition[1].conditionValue),
      max: this.formateDateToCompare(statementsTemplate.dataSourceProperty.displayProperty.condition[2].conditionValue),
      zoom: statementsTemplate.dataSourceProperty.displayProperty.displayZoom,
    };

    this.setState({
      readDate,
      template: statementsTemplate,
      alarm: alarmsTemplate,
      consumption: consoTemplate,
    });
  };

  generateIntervalSelector = () => {
    const { locales } = this.props;
    const { template, consumption, alarm, readsFiltered, consoFiltered } = this.state;
    const additionalInfo = {
      values: [],
      colors: [],
      context: 'vmeter',
      type: widgetConstants.READINDEXWIDGET,
    };
    const labels = readsFiltered && readsFiltered.map((it: any) => it.date);
    const labelsConso = consoFiltered && consoFiltered.map((it: any) => it.date);
    const values =
      readsFiltered &&
      readsFiltered.map((it: any) => {
        return {
          value: it.index,
          meterCount: it.meterCount,
          indexCount: it.indexCount,
        };
      });
    const valuesConso =
      consoFiltered &&
      consoFiltered.map((it: any) => {
        return {
          value: it.consumption,
          meterCount: it.meterCount,
          indexCount: it.indexCount,
        };
      });

    const additionalDataConso = cloneDeep(additionalInfo);
    additionalDataConso.type = widgetConstants.READCONSUMTIONWIDGET;

    const customZooms = ['Hour', 'Day', 'Week', 'Month', 'Year'];

    return (
      <div style={{ width: 'fit-content' }}>
        <IntervalSelector
          statementsTemplate={template}
          alarmsTemplate={alarm}
          consoTemplate={consumption}
          update={() => {
            this.updateTemplate(template, consumption, alarm);
            this.invalidPreview();
          }}
          locales={locales}
          key={Math.floor(Math.random() * Math.floor(1024))}
          customZooms={customZooms}
        />
      </div>
    );
  };

  onClickSelect(value: any, row: any) {
    const { columns } = this.state;
    let newCol = cloneDeep(columns);
    if (columns.length < value.length) {
      newCol.push({
        dataField: value[value.length - 1].value,
        text: value[value.length - 1].label,
        editable: false,
      });
    } else {
      newCol = newCol.filter((el: any) => el.text !== row.removedValue.label);
    }
    this.setState({
      columns: newCol,
    });
  }

  receiveData = (dataFiltered: any, rL: any) => {
    const { keyStorage, ruleList } = this.state;
    if (!_.isEqual(ruleList, getLightFilters(keyStorage))) {
      this.setState({ ruleList: getLightFilters(keyStorage) });
      this.invalidPreview();
    }
  };

  formatColumns = (type: string, recursive: boolean) => {
    const { locales, t } = this.props;
    const baseColumns = ListTools.getDefaultColumns(type);

    if (recursive) {
      return baseColumns.map(it => {
        const test: any = it.split('.');
        if (test.length > 1) {
          return {
            dataField: it,
            text: t(`columns.${it}`),
            sort: true,
            formatter: ListTools.findFormatter(it),
          };
        }
        return {
          dataField: it,
          text: t(`columns.${it}`),
          sort: true,
          formatter: ListTools.findFormatter(it),
        };
      });
    }
    return baseColumns.map(champ => ({
      dataField: champ,
      text: t(`columns.${champ}`),
      sort: true,
    }));
  };

  formatExtractColumns() {
    const { locales, exports, t } = this.props;
    const baseColumns = ListTools.getDefaultColumns(ListTools.typeOfList.DataExtract);
    if (exports.export && exports.export.length > 0) {
      return _.keys(exports.export[0]).map((champ: any) => ({
        dataField: champ,
        text: t(`columns.${champ}`),
        default: baseColumns.includes(champ),
        editable: false,
        formatter: ListTools.findFormatter(champ),
      }));
    }
  }

  resetDefaultColumns() {
    this.setState({
      columns: this.formatExtractColumns().filter(r => r.default),
    });
  }

  onClickUpdateTab() {
    const {
      dispatch,
      match: {
        params: { locationId },
      },
    } = this.props;
    const { readDate, keyStorage } = this.state;

    dispatch(exportActions.save(getFiltersRequest(keyStorage), readDate.min, readDate.max, readDate.zoom, locationId));
  }

  onClickExportCsv() {
    const { dispatch, match, locales, t } = this.props;
    const { readDate, availablePdis, filtrePdis, separator, columns, keyStorage } = this.state;
    const locationId = match.params.locationId;
    dispatch(
      exportActions.exportCsv(
        getFiltersRequest(keyStorage),
        columns.map(el => {
          return { fieldName: el.dataField, label: t(`columns.${el.dataField}`) };
        }),
        readDate.min,
        readDate.max,
        readDate.zoom,
        separator.value,
        locationId,
        _.get(locales, 'locale.fr.alarmType')
      )
    );
  }

  invalidPreview() {
    this.setState({ isPreviewed: false });
    exportActions.invalid();
  }

  onClickSeparator(value: any) {
    this.setState({
      separator: value,
    });
  }

  //! <this.generateIntervalSelector />
  render() {
    const {
      columns,
      sourceColumns,
      availablePdis,
      filtrePdis,
      separator,
      isPreviewed,
      keyStorage,
      displayingColumns,
      ruleList,
    } = this.state;
    const {
      pdis,
      exports,
      match: {
        params: { locationId },
      },
      t,
    } = this.props;

    const listFields = sourceColumns
      .map((col: any) => {
        const typeData = ListTools.findSpecificType(col.dataField);

        return {
          label: col.text,
          value: col.dataField,
          type: typeData,
        };
      })
      .sort((a: any, b: any) => {
        if (a.label < b.label) return -1;
        if (a.label === b.label) return 0;
        return 1;
      });

    const intervalSelector = this.generateIntervalSelector();
    /*     if(pdis && pdis.items && locationId){
      this.generateInitialList(pdis.items, locationId)
    } */
    const baseOptions = {
      onSizePerPageChange: (sizePerPage: number, page: number) => {
        const { options } = this.state;
        options.sizePerPage = sizePerPage;
        this.setState({
          options,
          mtrKey: Math.floor(Math.random() * Math.floor(1024)),
        });
      },
    };

    const separatorList = [
      { value: ',', label: t('export_pdi.text.comma') },
      { value: ';', label: t('export_pdi.text.semicolon') },
      { value: '|', label: t('export_pdi.text.vertical_bar') },
    ];

    let newColumns = [];
    if (columns && columns.length > 0) {
      newColumns = columns.map((el: any) => {
        const newEl = el;
        newEl.headerStyle = () => ({ width: `${(window.innerWidth - 60) / 6}px` });
        return newEl;
      });
    }

    return pdis.items ? (
      <div className="export-pdi-content">
        <Col md="12">
          <div className="filter-container">
            <QueryBuilder
              sendListFiltered={this.receiveData}
              listData={pdis.items}
              listOperator={listOperator}
              listFilters={listFields}
              idContext={ListTools.typeOfList.ExportPDI}
              idSite={locationId}
            />
          </div>
        </Col>
        <Col md="12">
          <div className="filter-container crystalList-container">
            <div className="flexbox">
              <div className="title">
                <PdiVert height="2em" width="2em" fill="#31c6b3" style={{ marginLeft: '5px' }} />
                <h2 style={{ marginLeft: '10px' }}>
                  {pdis.count} {t('all.pdi.pdi').toUpperCase()}
                </h2>
              </div>
            </div>
            <RemoteTable
              keyStorage={keyStorage}
              displayingColumns={displayingColumns}
              ruleList={ruleList}
              clickOnRow={() => {}}
            />
          </div>
        </Col>
        <br />
        <Col md="12">
          <div className="filter-container">
            <div className="flexbox">
              <div className="title">
                <SvgCalendar height="2em" fill="#31c6b3" />
                <h2 style={{ marginLeft: '10px' }}>{t('all.date.date_plural')}</h2>
              </div>
            </div>
            {intervalSelector}
          </div>
        </Col>
        <br />
        <Col md="12">
          {this.formatExtractColumns() && (
            <div className="filter-container">
              <ColumnSelector
                listColumns={columns || this.formatExtractColumns()}
                changeDisplay={this.changeDisplayingColumns}
                defaultConfig={this.formatExtractColumns().filter(r => r.default)}
                saveOption
                localColumns={{ value: {} }}
                idSite={locationId}
                resetDefaultColumns={this.resetDefaultColumns}
                save={false}
                type={ListTools.typeOfList.DataExtract}
              />
            </div>
          )}
        </Col>
        <Col md="12">
          <div className="filter-container crystalList-container">
            <div className="flexbox">
              <div className="title">
                <SvgEye height="2em" fill="#31c6b3" />
                <h2 style={{ marginLeft: '10px' }}>{t('export_pdi.text.export_preview')}</h2>
              </div>
              {exports && !exports.loading && !exports.processing && (
                <div className="button-wrapper-export">
                  <Button
                    style={{ marginLeft: '15px', marginTop: '1px' }}
                    type="submit"
                    disabled={isPreviewed}
                    onClick={this.onClickUpdateTab}
                  >
                    {t('export_pdi.text.refresh_preview')}
                  </Button>
                </div>
              )}
            </div>

            {exports && exports.loading && <LoadingBand message={`${t('exportpdi.text.load_reads')} ...`} />}
            {exports && exports.processing && (
              <LoadingBand message={`${t('exportpdi.text.csv_export_processing')} ...`} />
            )}
            {exports && exports.csv && <SuccessBand message={t('export_pdi.text.cvs_export_done')} />}

            {columns && exports && exports.export && exports.export.length > 0 && !exports.processing && (
              <BootstrapTable
                keyField="id"
                data={exports.export}
                columns={newColumns}
                bootstrap4
                hover
                bordered={false}
                filter={filterFactory()}
                pagination={null}
              />
            )}
            {exports && exports.export && exports.export.length == 0 && (
              <WarningBand style={{ marginTop: 10 }} message={t('exportpdi.text.no_read_found')} />
            )}
            {exports && !exports.loading && !exports.processing && (
              <div className="button-wrapper-export" style={{ margin: 'auto', display: 'flex' }}>
                <ButtonGroup style={{ margin: 'auto', display: 'flex' }}>
                  <Button
                    type="submit"
                    disabled={!isPreviewed || (exports.export && exports.export.length == 0)}
                    onClick={this.onClickExportCsv}
                  >
                    <ImportBleu
                      style={{ marginRight: '10px' }}
                      fill="currentcolor"
                      stroke="currentcolor"
                      height="1em"
                      width="1em"
                    />
                    {t('all.button.export')}
                  </Button>
                  <ButtonDropdown
                    direction="top"
                    isOpen={this.state.separatorBtnOpen}
                    toggle={() => this.setState({ separatorBtnOpen: !this.state.separatorBtnOpen })}
                  >
                    <DropdownToggle disabled={!isPreviewed || (exports.export && exports.export.length == 0)} caret>
                      ({t('export_pdi.text.separator')} : {separator.label})
                    </DropdownToggle>
                    <DropdownMenu>
                      {separatorList.map(sep => (
                        <DropdownItem onClick={() => this.setState({ separator: sep })}>{sep.label}</DropdownItem>
                      ))}
                    </DropdownMenu>
                  </ButtonDropdown>
                </ButtonGroup>
              </div>
            )}
          </div>
        </Col>
      </div>
    ) : (
      <Loading message="Chargement des pdis en cours ..." />
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, vmeters, locations, locales, colors, pdis, exports, users } = state;
  const { user } = authentication;

  return {
    alert,
    user,
    locations,
    locales,
    vmeters,
    colors,
    pdis,
    exports,
    users,
  };
}

const connectedExportPdi = connect(mapStateToProps)(ExportPdi);
const tr = withTranslation()(connectedExportPdi);
export default tr;
