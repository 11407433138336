import _ from 'lodash';
import { getLocalStorage } from '../_helpers/localStorage-helper';
import { QueryBuilder } from './index';

export function getLightFilters(keyStorage: string): string {
  const localData = _.get(getLocalStorage(keyStorage), 'ruleList', []);
  const newRuleList = QueryBuilder.WrappedComponent.regenerateOperations(localData).filter(el => el.filterApplied);
  const lightRuleList = newRuleList.map(
    el =>
      `${_.get(el, 'filter.value')}-${_.get(el, 'operator.value')}-${
        Array.isArray(_.get(el, 'userInput'))
          ? _.get(el, 'userInput')
              .map(el => el.value)
              .join(',')
          : _.isObject(_.get(el, 'userInput'))
          ? _.entries(_.get(el, 'userInput'))
              .map(uInput => uInput[1][1])
              .join(',')
          : !_.isEmpty(_.get(el, 'secondInputChange'))
          ? `${_.get(el, 'userInput')}-${_.get(el, 'secondInputChange')}`
          : _.get(el, 'userInput')
      }`
  );
  return lightRuleList;
}

export function getFiltersRequest(keyStorage: string, takeJustApplied: boolean = true) {
  const localData = _.get(getLocalStorage(keyStorage), 'ruleList', []);
  const newRuleList = QueryBuilder.WrappedComponent.regenerateOperations(localData).filter(
    el => !takeJustApplied || el.filterApplied
  );
  return newRuleList;
}
