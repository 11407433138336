import React from 'react';
const SvgAffectation = (props: any) => (
  <svg viewBox="0 0 14.6 13.31" {...props}>
    <path
      d="M14.6,6.65,8.53,0V4H7.24A7.24,7.24,0,0,0,0,11.21v2.1l.57-.63A10.31,10.31,0,0,1,8.16,9.33h.37v4Z"
      fill={props.fill}
    />
  </svg>
);

export default SvgAffectation;
