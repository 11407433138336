import { authHeader, handleResponse } from '../_helpers';

/**
 * Permet l'autocompletion pour les widgets
 * @method autocomplete
 * @param {any} conditions conditions du widget
 * @returns {any}  la réponse http du handleResponse
 */
async function autocomplete(conditions: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(conditions),
  };
  let response = await fetch(`/api/widget/autocomplete/`, requestOptions);
  return handleResponse(response);
}

const autocompleteService = {
  autocomplete,
};
export default autocompleteService;
