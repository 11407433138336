import fileDownload from 'js-file-download';
import moment from 'moment';
import exportConstants from '../_constants/export.constants';
import exportService from '../_services/export.service';

function save(listPdi: any, dateDebut: any, dateFin: any, zoom: string, locationId: any) {
  function request() {
    return { type: exportConstants.UPDATE_VIEW_REQUEST };
  }
  function success(exports: Object) {
    return { type: exportConstants.UPDATE_VIEW_SUCCESS, exports };
  }
  function failure(error: Object) {
    return { type: exportConstants.UPDATE_VIEW_FAILURE, error };
  }

  return (dispatch: Function) => {
    dispatch(request());
    exportService
      .save(listPdi, dateDebut, dateFin, zoom, locationId)
      .then((exportType: any) => {
        dispatch(success(exportType));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function exportCsv(
  filters: any,
  listFields: any,
  dateDebut: any,
  dateFin: any,
  zoom: string,
  separator: any,
  locationId: any,
  alarmType: any
) {
  function request() {
    return { type: exportConstants.EXPORTCSV_REQUEST };
  }
  function success(exports: Object) {
    return { type: exportConstants.EXPORTCSV_SUCCESS, exports };
  }
  function failure(error: Object) {
    return { type: exportConstants.EXPORTCSV_FAILURE, error };
  }

  return (dispatch: Function) => {
    dispatch(request());
    exportService
      .exportCsv(filters, listFields, dateDebut, dateFin, zoom, separator, locationId, alarmType)
      .then((exportType: any) => {
        dispatch(success(exportType));
        const date = moment().format('YYYYMMDD_HHmm');
        fileDownload(exportType.csv, `export_${date}.csv`);
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function invalid() {
  return {
    type: exportConstants.CLEAR,
  };
}

const exportActions = {
  save,
  invalid,
  exportCsv,
};

export default exportActions;
