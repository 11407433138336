import React from 'react';

const SvgExportBrut = (props: any) => (
  <svg viewBox="0 0 170.63 139.3" {...props}>
    <g>
      <g>
        <path
          fill={props.fill}
          d="M101.24,127.65c0,0.9-0.76,1.66-1.66,1.66H11.65c-0.9,0-1.66-0.76-1.66-1.66v-116c0-0.9,0.76-1.66,1.66-1.66
			h87.92c0.9,0,1.66,0.76,1.66,1.66v17.37h9.99V11.65C111.23,5.23,106,0,99.57,0H32.6h-9.99H11.65C5.23,0,0,5.23,0,11.65v116
			c0,6.42,5.23,11.65,11.65,11.65h87.92c6.42,0,11.65-5.23,11.65-11.65v-13.98v-6.2h-9.99V127.65z"
        />
        <path
          fill={props.fill}
          d="M84.02,29.52H27.21c-2.76,0-4.99,2.24-4.99,4.99c0,2.76,2.23,4.99,4.99,4.99h56.81
			c2.76,0,4.99-2.23,4.99-4.99C89.01,31.76,86.78,29.52,84.02,29.52z"
        />
        <path
          fill={props.fill}
          d="M84.02,52.01H27.21c-2.76,0-4.99,2.24-4.99,4.99s2.23,4.99,4.99,4.99h56.81c2.76,0,4.99-2.24,4.99-4.99
			S86.78,52.01,84.02,52.01z"
        />
        <path
          fill={props.fill}
          d="M84.02,74.5H27.21c-2.76,0-4.99,2.23-4.99,4.99c0,2.76,2.23,4.99,4.99,4.99h56.81c2.76,0,4.99-2.23,4.99-4.99
			C89.01,76.73,86.78,74.5,84.02,74.5z"
        />
        <path
          fill={props.fill}
          d="M84.02,96.98H27.21c-2.76,0-4.99,2.24-4.99,4.99s2.23,4.99,4.99,4.99h56.81c2.76,0,4.99-2.24,4.99-4.99
			C89.01,99.22,86.78,96.98,84.02,96.98z"
        />
      </g>
      <g>
        <path
          fill={props.fill}
          d="M101.6,68.7c0,2.78,2.28,5.09,5.1,5.14l46.25-0.46l-16.86,16.86c-2.01,2.01-2.01,5.29,0,7.3s5.29,2.01,7.3,0
			l25.73-25.73c2.01-2.01,2.01-5.29,0-7.3l-25.73-25.73c-2.01-2.01-5.29-2.01-7.3,0s-2.01,5.29,0,7.3l16.97,16.97l-46.44,0.46
			C103.79,63.6,101.59,65.88,101.6,68.7z"
        />
      </g>
    </g>
  </svg>
);

export default SvgExportBrut;
