import { authHeader, handleResponse } from '../_helpers';
import { Mail } from '../_entities/mail';

async function send(message: Mail) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(message),
  };
  let response = await fetch(`/api/mail/report`, requestOptions);
  return handleResponse(response);
}
const mailService = {
  send,
};
export default mailService;
