import React from 'react';

const SvgFluidElectricite = (props: any) => (
  <svg viewBox="0 0 16 16" {...props}>
    <path
      fill={props.fill}
      d="M13,6.2C12.9,6.1,12.8,6,12.7,6H8.3L9,0.4C9,0.2,8.9,0,8.7,0C8.6,0,8.5,0,8.4,0.2L3.1,9.5
C3,9.7,3,9.9,3.2,10c0.1,0,0.1,0,0.2,0h4.3l-0.6,5.6c0,0.2,0.1,0.3,0.3,0.4c0.1,0,0.2-0.1,0.3-0.2l5.2-9.3C13,6.4,13,6.3,13,6.2"
    />
  </svg>
);

export default SvgFluidElectricite;
