import React from 'react';

const SvgDerniereReleveVert = (props: any) => (
  <svg viewBox="0 0 30.72 24.2" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_2-2" data-name="Calque 2">
        <path
          d="M8.36,15.91a1.62,1.62,0,0,0,1.3-.59,2.51,2.51,0,0,0,.51-1.67V13a2.46,2.46,0,0,1-.85.7,2.38,2.38,0,0,1-1.06.23,2.94,2.94,0,0,1-2.33-1,3.88,3.88,0,0,1-.85-2.63,3.7,3.7,0,0,1,1-2.66A3.36,3.36,0,0,1,8.65,6.58a3.47,3.47,0,0,1,2.63,1.09,4.2,4.2,0,0,1,1,3v2.87a3.9,3.9,0,0,1-1.11,2.93,3.89,3.89,0,0,1-2.83,1.09,5.7,5.7,0,0,1-1.14-.13,5.73,5.73,0,0,1-1.11-.34l.36-1.54a4.87,4.87,0,0,0,.9.28A5,5,0,0,0,8.36,15.91Zm.26-3.59a2,2,0,0,0,.92-.21,1.73,1.73,0,0,0,.63-.57V10.3a2.52,2.52,0,0,0-.41-1.54,1.34,1.34,0,0,0-1.1-.53,1.19,1.19,0,0,0-1,.6,2.57,2.57,0,0,0-.4,1.47,2.67,2.67,0,0,0,.36,1.47A1.16,1.16,0,0,0,8.62,12.32Z"
          fill={props.fill}
        />
        <path
          d="M22.42,15.91a1.6,1.6,0,0,0,1.29-.59,2.51,2.51,0,0,0,.51-1.67V13a2.32,2.32,0,0,1-1.91.93,2.94,2.94,0,0,1-2.33-1,3.88,3.88,0,0,1-.85-2.63,3.7,3.7,0,0,1,1-2.66A3.36,3.36,0,0,1,22.7,6.58a3.45,3.45,0,0,1,2.63,1.09,4.2,4.2,0,0,1,1,3v2.87a3.78,3.78,0,0,1-3.93,4,6.29,6.29,0,0,1-2.26-.47l.36-1.54a4.87,4.87,0,0,0,.9.28A5,5,0,0,0,22.42,15.91Zm.25-3.59a2,2,0,0,0,.92-.21,1.73,1.73,0,0,0,.63-.57V10.3a2.52,2.52,0,0,0-.41-1.54,1.34,1.34,0,0,0-1.1-.53,1.19,1.19,0,0,0-1,.6,2.57,2.57,0,0,0-.4,1.47,2.67,2.67,0,0,0,.36,1.47A1.16,1.16,0,0,0,22.67,12.32Z"
          fill={props.fill}
        />
        <path
          d="M15.36,7A1,1,0,0,1,14.3,6V3l-6.09-1L2.13,3V6A1,1,0,0,1,1.06,7,1,1,0,0,1,0,6V2.16a1,1,0,0,1,.89-1L8,0h.34l7.15,1.15a1,1,0,0,1,.89,1V6a1,1,0,0,1-1.06,1"
          fill={props.fill}
        />
        <path
          d="M29.66,7a1,1,0,0,1-1.07-1V3l-6.08-1L16.42,3V6A1.06,1.06,0,0,1,14.3,6V2.16a1,1,0,0,1,.88-1L22.33,0h.35l7.15,1.15a1,1,0,0,1,.89,1V6a1,1,0,0,1-1.06,1"
          fill={props.fill}
        />
        <path
          d="M22.51,24.2l-.18,0L15.18,23a1,1,0,0,1-.88-1V18.19a1.06,1.06,0,0,1,2.12,0v3l6.09,1,6.08-1v-3a1.07,1.07,0,0,1,2.13,0V22a1,1,0,0,1-.89,1l-7.15,1.14-.17,0"
          fill={props.fill}
        />
        <path
          d="M8.21,24.2l-.17,0L.89,23A1,1,0,0,1,0,22V18.19a1,1,0,0,1,1.06-1,1,1,0,0,1,1.06,1v3l6.09,1,6.09-1v-3a1.06,1.06,0,0,1,2.12,0V22a1,1,0,0,1-.89,1L8.39,24.18l-.18,0"
          fill={props.fill}
        />
      </g>
    </g>
  </svg>
);

export default SvgDerniereReleveVert;
