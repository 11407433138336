import React from 'react';

const Telereleve = (props: any) => (
  <svg version="1.1" id="Calque_1" viewBox="0 0 93 76.1" {...props}>
    <g>
      <path
        d="M91.4,22.7c-1.9-7.3-5.5-13.9-10.1-19.2c-1.1-1.2-2.6-1.9-4.1-1.9c-1.3,0-2.5,0.4-3.6,1.3c-2.3,2-2.5,5.4-0.5,7.7
		c3.6,4.2,6.3,9.2,7.8,14.9c0.6,2.2,0.9,4.4,1.1,6.6c0.8,10.6-3,20.8-10,28.3c-2.1,2.2-1.9,5.6,0.3,7.7c1.1,1,2.4,1.5,3.8,1.5
		c0.1,0,0.3,0,0.4,0c1.3-0.1,2.6-0.7,3.5-1.7c9.1-9.6,14-22.9,12.9-36.6C92.6,28.4,92.1,25.6,91.4,22.7z"
      />
      <path
        d="M75.1,32.6c-0.1-1.8-0.4-3.6-0.9-5.4c-1.2-4.4-3.3-8.4-6-11.7c-1.1-1.3-2.6-2-4.2-2c-1.2,0-2.5,0.4-3.5,1.3
		c-2.3,1.9-2.6,5.4-0.7,7.7c1.8,2.1,3.1,4.6,3.9,7.5c0.3,1.1,0.5,2.3,0.6,3.4c0.4,4.9-1.1,9.6-4,13.3c-1.8,2.4-1.4,5.8,1,7.6
		c1,0.8,2.1,1.1,3.3,1.1c0.1,0,0.3,0,0.4,0c1.5-0.1,2.9-0.8,3.9-2.1C73.3,47.6,75.7,40.2,75.1,32.6z"
      />
      <path
        d="M56.8,34C56.8,33.9,56.8,33.9,56.8,34c-0.3-4-2.8-7.1-6.1-8.6c-1.3-0.6-2.7-0.9-4.2-0.9c-0.3,0-0.5,0-0.8,0
		c-5.3,0.4-9.4,4.8-9.5,10c0,0.3,0,0.7,0,1.1c0,0,0,0.1,0,0.1c0.3,3.8,2.6,6.9,5.8,8.4v28.1c0,2.1,1.8,3.9,3.9,3.9h1.2
		c2.1,0,3.9-1.8,3.9-3.9V44.1c3.4-1.6,5.7-5.1,5.8-9C56.8,34.7,56.8,34.3,56.8,34z"
      />
      <path
        d="M12.1,44.1c-0.6-2.2-0.9-4.4-1.1-6.6c-0.8-10.6,3-20.8,10-28.3C23,7,22.9,3.5,20.7,1.5C19.7,0.5,18.3,0,17,0
		c-0.1,0-0.3,0-0.4,0C15.3,0.1,14,0.7,13,1.7C4,11.4-0.9,24.6,0.1,38.3c0.2,2.8,0.7,5.7,1.5,8.5c1.9,7.3,5.5,13.9,10.1,19.2
		c1.1,1.2,2.6,1.9,4.1,1.9c1.3,0,2.5-0.4,3.6-1.3c2.3-2,2.5-5.4,0.5-7.7C16.3,54.8,13.6,49.8,12.1,44.1z"
      />
      <path
        d="M29.3,39.6c-0.3-1.1-0.5-2.3-0.6-3.4c-0.4-4.9,1.1-9.6,4-13.3c1.8-2.4,1.4-5.8-1-7.6c-1-0.8-2.1-1.1-3.3-1.1
		c-0.1,0-0.3,0-0.4,0c-1.5,0.1-2.9,0.8-3.9,2.1c-4.5,5.8-6.8,13.2-6.2,20.8c0.1,1.8,0.4,3.6,0.9,5.4c1.2,4.4,3.3,8.4,6,11.7
		c1.1,1.3,2.6,2,4.2,2c1.2,0,2.5-0.4,3.5-1.3c2.3-1.9,2.6-5.4,0.7-7.7C31.4,44.9,30.1,42.4,29.3,39.6z"
      />
    </g>
  </svg>
);

export default Telereleve;
