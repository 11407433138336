import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import exifr from 'exifr';
import _ from 'lodash';
import moment from 'moment';
import PhotoSvg from '../SvgComponents/PhotoSvg';
import SvgCalendrierVert from '../SvgComponents/CalendrierVert';
import SvgCodeBarreVert from '../SvgComponents/CodeBarreVert';
import SvgTelephoneVert from '../SvgComponents/TelephoneVert';
import Mapbox from '../Mapbox/components/Mapbox';

import pictureActions from '../_actions/picture.actions';

import ImgComponent from './ImgComponent';
import SvgMap from '../SvgComponents/Map';
import SvgPhoto from '../SvgComponents/PhotoSvg';
import SvgDetailMosaique from '../SvgComponents/SvgDetailMosaique';
import SvgMosaique from '../SvgComponents/SvgMosaique';
import LoadingBand from '../Bands/Loading';

const GaleryPhoto = props => {
  const { picture, meters, tournee, dispatch, meterSerials, t } = props;
  const [selected, setSelected] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [pictureList, setPictureList] = useState([]);
  const [others, setOthers] = useState(0);
  const [flip, setFlip] = useState(false);
  const galeryContainer = useRef(null);

  const keyList = [
    { value: t('all.date.date'), key: 'date' },
    { value: t('all.meter.serial'), key: 'meterSerial' },
    { value: t('edit_device.field_label.phone_name'), key: 'deviceSerial' },
  ];

  const keySvg = key => {
    switch (key) {
      case 'date':
        return <SvgCalendrierVert height="25px" fill="#31c6b3" />;
      case 'meterSerial':
        return <SvgCodeBarreVert width="25px" fill="#31c6b3" />;
      default:
        return <SvgTelephoneVert height="25px" fill="#31c6b3" />;
    }
  };

  function trierDatesDecroissant(dates) {
    return dates.sort(function(a, b) {
      return new Date(b.date) - new Date(a.date);
    });
  }

  const getPaths = serialMeter => {
    if (picture.paths && picture.paths[serialMeter]) {
      return picture.paths[serialMeter].map(fileDto => {
        const name = fileDto.meterSerial;
        const deviceSerial = fileDto.device;
        const byteCharacters = atob(fileDto.content);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/jpeg' });

        const file = new File([blob], name, {
          type: 'image/jpeg',
        });

        const date = fileDto.date ? new Date(fileDto.date) : null;
        return {
          src: fileDto.path,
          blob,
          file,
          date: moment(date).format(t('all.date_format.date')),
          meterSerial: serialMeter,
          deviceSerial: deviceSerial || t('all.text.unknown'),
          name,
        };
      });
    }
    return [];
  };

  useEffect(() => {
    if (meterSerials) {
      if (!picture.init) {
        dispatch(pictureActions.getAllPicPath(meterSerials));
      }
    }
  }, [meterSerials]);

  useEffect(() => {
    if (meters.allMetersInfo && !picture.loaded) {
      const meterList = meters.allMetersInfo.map(el => el.general.serial);
      if (!picture.init) {
        dispatch(pictureActions.getAllPicPath(meterList));
      }
    }
  }, [meters.allMetersInfo]);

  useEffect(() => {
    if (_.get(tournee, 'fetchedFiche.baseMeter.theoricSerial') && !picture.loaded) {
      const meterList = [tournee.fetchedFiche.baseMeter.theoricSerial];
      if (_.get(tournee, 'fetchedFiche.changeMeter.theoricSerial')) {
        meterList.push(_.get(tournee, 'fetchedFiche.changeMeter.theoricSerial'));
      }
      if (!picture.init) {
        dispatch(pictureActions.getAllPicPath(meterList));
      }
    }
  }, [tournee.fetchedFiche]);

  useEffect(() => {
    if (galeryContainer && galeryContainer.current) {
      const dimension = galeryContainer.current.getBoundingClientRect();
      let n = 0;
      pictureList.forEach(el => {
        n += el.paths.length;
      });
      const number = n - Math.floor(dimension.width / 170) * 2;
      setOthers(number);
    }
  }, [pictureList]);

  useEffect(() => {
    const doc = document.getElementById('pic-scroll');
    if (!selected && doc) {
      doc.scrollTop = 0;
    }
    if (selected && flip) {
      setFlip(false);
    }
  }, [selected]);

  useEffect(() => {
    if (picture.paths && _.entries(picture.paths)[0]) {
      const formatList =
        meters.allMetersInfo && !meterSerials
          ? meters.allMetersInfo.map(el => ({
              meter: el.general.serial,
              paths: trierDatesDecroissant(getPaths(el.general.serial)),
            }))
          : [
              {
                meter: _.get(tournee, 'fetchedFiche.baseMeter.theoricSerial') || meterSerials[0],
                paths: trierDatesDecroissant(
                  getPaths(_.get(tournee, 'fetchedFiche.baseMeter.theoricSerial') || meterSerials[0])
                ),
              },
            ];
      if (_.get(tournee, 'fetchedFiche.changeMeter.theoricSerial')) {
        formatList.push({
          meter: _.get(tournee, 'fetchedFiche.changeMeter.theoricSerial'),
          paths: trierDatesDecroissant(getPaths(_.get(tournee, 'fetchedFiche.changeMeter.theoricSerial'))),
        });
      }
      setPictureList(formatList);
    }
  }, [picture]);

  const handleClick = (pic, i) => {
    if (selected && pic.src === selected.src) {
      setSelected(null);
    } else {
      setSelected(pic);
    }
  };

  const handleLoaded = (e, i, pI) => {
    const pictures = pictureList[pI];
    const p = pictures.paths[i];
    exifr.parse(e.target).then(output => {
      if (output && picture && output.longitude && output.latitude) {
        p.coordinate = {
          lng: output.longitude,
          lat: output.latitude,
        };
        pictureList[pI].paths[i] = p;
        setPictureList(pictureList);
      }
    });
  };

  const handleMore = e => {
    setShowMore(!showMore);
  };

  return picture.loading ? (
    <LoadingBand message="Chargement des photos" />
  ) : (
    pictureList.map(el => el.paths).flat().length > 0 && (
      <div className="galery-container">
        <div className="title-box">
          <div className="flex-box">
            <PhotoSvg fill="#31c6b3" height="20px" />
            <h3>
              {pictureList.map(el => el.paths).flat().length}{' '}
              {pictureList.map(el => el.paths).flat().length > 1 ? t('galery.title_plural') : t('galery.title')}
            </h3>
          </div>
          <div className="switch-mode">
            <div
              className={`mode-button ${!selected ? 'activ' : 'inactiv'}`}
              style={{ backgroundColor: !selected ? '#31c6b3' : 'white' }}
              onClick={() => setSelected(null)}
            >
              <SvgMosaique fill={!selected ? 'white' : '#31c6b3'} height="20px" />
            </div>
            <div
              className={`mode-button ${selected ? 'activ' : 'inactiv'}`}
              style={{ backgroundColor: selected ? '#31c6b3' : 'white' }}
              onClick={() => setSelected(_.get(pictureList, '[0].paths[0]'))}
            >
              <SvgDetailMosaique fill={selected ? 'white' : '#31c6b3'} height="20px" />
            </div>
          </div>
        </div>
        <div className={`galery-wrapper ${selected ? 'column' : ''}`} ref={galeryContainer}>
          <div
            className="list-wrapper"
            style={{ maxHeight: showMore && !selected ? 'unset' : selected ? '550px' : '330px' }}
            const
            id="pic-scroll"
          >
            {pictureList.map((pic, pIndex) => {
              return pic.paths.map((el, index) => {
                const isLastIndex =
                  selected &&
                  index === pic.paths.length - 1 &&
                  pictureList.length > 1 &&
                  pIndex !== pictureList.length - 1;
                return (
                  <div
                    className="image-container"
                    style={
                      isLastIndex ? { paddingBottom: '40px', borderBottom: '2px solid #ccc', maxHeight: '190px' } : {}
                    }
                    id="pic-item"
                  >
                    <ImgComponent
                      blob={el.blob}
                      onLoad={e => handleLoaded(e, index, pIndex)}
                      onClick={() => handleClick(el, index)}
                      style={{ border: selected && selected.src === el.src ? '2px solid #31c6b3' : 'none' }}
                      name={el.name}
                    />
                    {selected && (
                      <div className="info-container">
                        {keyList.map(key => (
                          <div className="info-box">
                            <div className="svg-box">{keySvg(key.key)}</div>
                            <div className="text-field">
                              <span>{key.value}</span>
                              <h5>{_.get(el, key.key)}</h5>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              });
            })}
          </div>
          {selected && (
            <div className="zoom-info-container">
              <div className="vertical-line" />
              <div className="info-container">
                <div className="info-wrapper">
                  {keyList.map(key => (
                    <div className="info-box">
                      <div className="svg-box">{keySvg(key.key)}</div>
                      <div className="text-field">
                        <span>{key.value}</span>
                        <h5>{_.get(selected, key.key)}</h5>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className={`svg-wrapper ${selected.coordinate ? 'geolocalisable' : ''}`}
                  onClick={() => selected.coordinate && setFlip(!flip)}
                >
                  {flip ? (
                    <SvgPhoto fill={selected.coordinate ? '#31c6b3' : '#a1a1a1'} height="20px" />
                  ) : (
                    <SvgMap fill={selected.coordinate ? '#31c6b3' : '#a1a1a1'} height="20px" />
                  )}
                </div>
              </div>
              <div className={`flip-card ${flip ? 'back' : 'front'}`}>
                <div className="selected-container flip-card-inner">
                  <div className="zoomed-pic flip-card-front">
                    <ImgComponent blob={selected.blob} name={selected.name} />
                  </div>
                  {selected.coordinate && (
                    <div className="map-pdi flip-card-back">
                      <Mapbox
                        initialMap={{
                          baseLng: selected.coordinate.lng,
                          baseLat: selected.coordinate.lat,
                          baseZoom: 16,
                          myLocationEnabled: false,
                        }}
                        homeMarker
                        mapId="picture-map"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {!selected && others > 0 && (
            <div className="more-picture" onClick={handleMore}>
              <i>{showMore ? t('galery.fold') : `+${others} ${t('galery.others')}`}</i>
            </div>
          )}
        </div>
      </div>
    )
  );
};

const mapStateToProps = state => {
  const { picture, meters, tournee } = state;
  return {
    picture,
    meters,
    tournee,
  };
};

const connectedPhoto = connect(mapStateToProps)(GaleryPhoto);
const tr = withTranslation()(connectedPhoto);
export default tr;
