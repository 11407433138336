import React from 'react';

const SvgMosaique = (props: any) => (
  <svg version="1.1" viewBox="0 0 16.1 16.1" {...props}>
    <g>
      <path
        fill={props.fill}
        d="M4,8.1c0,0.7,0,1.3,0,2c-1.3,0-2.7,0-4,0c0,0,0,0,0,0c0-1.3,0-2.7,0-4c0,0,0,0,0,0c1.3,0,2.7,0,4,0
		C4,6.7,4,7.4,4,8.1z"
      />
      <path
        fill={props.fill}
        d="M6,8c0-0.7,0-1.3,0-2c1.3,0,2.7,0,4,0c0,1.3,0,2.7,0,4c-1.3,0-2.7,0-4,0C6,9.4,6,8.7,6,8z"
      />
      <path
        fill={props.fill}
        d="M4,2c0,0.7,0,1.3,0,2C2.7,4,1.3,4,0,4c0,0,0,0,0,0c0-1.3,0-2.7,0-4c0,0,0,0,0,0c1.3,0,2.7,0,4,0
		C4,0.7,4,1.3,4,2z"
      />
      <path
        fill={props.fill}
        d="M6,2c0-0.7,0-1.3,0-2c1.3,0,2.7,0,4,0c0,1.3,0,2.7,0,4c-1.3,0-2.7,0-4,0C6,3.3,6,2.7,6,2z"
      />
      <path
        fill={props.fill}
        d="M2,12.1c0.7,0,1.3,0,2,0c0,1.3,0,2.7,0,4c-1.3,0-2.7,0-4,0c0-1.3,0-2.7,0-4C0.7,12.1,1.4,12.1,2,12.1z"
      />
      <path
        fill={props.fill}
        d="M8.1,12.1c0.7,0,1.3,0,2,0c0,1.3,0,2.7,0,4c-1.3,0-2.7,0-4,0c0-1.3,0-2.7,0-4C6.7,12.1,7.4,12.1,8.1,12.1z"
      />
      <path
        fill={props.fill}
        d="M12.1,8c0-0.7,0-1.3,0-2c1.3,0,2.7,0,4,0c0,1.3,0,2.7,0,4c-1.3,0-2.7,0-4,0C12.1,9.4,12.1,8.7,12.1,8z"
      />
      <path
        fill={props.fill}
        d="M12.1,2c0-0.7,0-1.3,0-2c1.3,0,2.7,0,4,0c0,1.3,0,2.7,0,4c-1.3,0-2.7,0-4,0C12.1,3.3,12.1,2.7,12.1,2z"
      />
      <path
        fill={props.fill}
        d="M14.1,12.1c0.7,0,1.3,0,2,0c0,1.3,0,2.7,0,4c-1.3,0-2.7,0-4,0c0-1.3,0-2.7,0-4C12.7,12.1,13.4,12.1,14.1,12.1z
		"
      />
    </g>
  </svg>
);

export default SvgMosaique;
