import React, { Component } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import SvgHistoriqueDonneesVert from '../SvgComponents/HistoriqueVert';
import { withTranslation } from 'react-i18next';

interface Props {
  dateMin: any;
  dateMax: any;
  handleDate: Function;
  handleZoom: Function;
  noZoom?: boolean;
  zoom: string;
  reverse: boolean;
  t: Function;
}
class BestDateComponent extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {};
    this.generatePeriod = this.generatePeriod.bind(this);
  }

  handleDateDeb = (e: any) => {
    const { handleDate, dateMax } = this.props;
    const dateDeb = _.get(e, 'target.value')
      ? moment.utc(moment(e.target.value).format('YYYY-MM-DD')).toISOString(true)
      : e;
    if (new Date(dateDeb) < new Date(dateMax)) {
      handleDate(dateDeb, dateMax);
    }
  };

  handleDateFin = (e: any) => {
    const { handleDate, dateMin } = this.props;
    const dateFin = _.get(e, 'target.value')
      ? moment
          .utc(moment(e.target.value).format('YYYY-MM-DD'))
          .endOf('day')
          .add(-1, 'second')
          .toISOString(true)
      : e;
    if (new Date(dateMin) < new Date(dateFin)) {
      handleDate(dateMin, dateFin);
    }
  };

  handleZoom = (e: any) => {
    const { handleZoom }: any = this.props;
    const zoom = _.get(e, 'target.value') ? e.target.value : e;
    handleZoom(zoom);
  };

  generatePeriod(amount: number, zoomUsed: string, label: string) {
    const { dateMin, dateMax, zoom, reverse, t } = this.props;
    return (
      <h5
        onClick={() => {
          this.handleZoom(zoomUsed);
          if (reverse) {
            this.handleDateDeb(
              moment
                .utc(
                  moment(dateMax)
                    .add(-1 * amount, zoomUsed)
                    .endOf('day')
                    .add(-1, 'second')
                    .format('YYYY-MM-DD')
                )
                .toISOString(false)
            );
          } else {
            this.handleDateFin(
              moment
                .utc(
                  moment(dateMin)
                    .add(amount, zoomUsed)
                    .endOf('day')
                    .add(-1, 'second')
                    .format('YYYY-MM-DD')
                )
                .toISOString(false)
            );
          }
        }}
        style={{
          backgroundColor: zoom === zoomUsed ? '#31c6b3' : 'white',
          marginBottom: label === '1 semaine' || label === '1 mois' ? '5px' : '0',
        }}
      >
        {label}
      </h5>
    );
  }

  render() {
    const { noZoom, t } = this.props;
    const { dateMin, dateMax, zoom } = this.props;
    const format = 'YYYY-MM-DD';
    return (
      <div className="sidebar-mask-alarm">
        <div className="masque-alarm-side">
          <SvgHistoriqueDonneesVert className="masque-vert-display" width="2em" height="2em" fill="#31c6b3" />
          <h2>{t('all.date.date_plural')}</h2>
        </div>
        <div className="best-date-component-mask flex-box">
          <FormGroup className="c32">
            <Label for="date-debut">{t('all.text.start_date')}</Label>
            {zoom === 'custom' || !noZoom ? (
              <Input
                type="date"
                name="date"
                id="date-debut"
                onInput={this.handleDateDeb}
                value={moment.utc(dateMin).format(format)}
              />
            ) : (
              <h5>{moment.utc(dateMin).format(format)}</h5>
            )}
          </FormGroup>
          <FormGroup className="c32">
            <Label for="date-fin">{t('all.text.end_date')}</Label>
            {zoom === 'custom' || !noZoom ? (
              <Input
                type="date"
                name="date"
                id="date-fin"
                onChange={this.handleDateFin}
                value={moment.utc(dateMax).format(format)}
              />
            ) : (
              <h5>{moment.utc(dateMax).format(format)}</h5>
            )}
          </FormGroup>
          {noZoom && (
            <FormGroup className="c32 flex-box">
              <div className="text-content" style={{ marginRight: '1vw' }}>
                {this.generatePeriod(1, 'week', `1 ${t('best_date_component.zoom_option.week').toLowerCase()}`)}
                {this.generatePeriod(1, 'year', `1 ${t('best_date_component.zoom_option.year').toLowerCase()}`)}
              </div>
              <div className="text-content">
                {this.generatePeriod(1, 'month', `1 ${t('best_date_component.zoom_option.month').toLowerCase()}`)}

                <h5
                  onClick={() => this.handleZoom('custom')}
                  style={{
                    backgroundColor: zoom === 'custom' ? '#31c6b3' : 'white',
                    marginBottom: 0,
                  }}
                >
                  {t('best_date_component.text.custom')}
                </h5>
              </div>
            </FormGroup>
          )}
          {!noZoom && (
            <FormGroup className="c32">
              <Label for="date-zoom">Zoom</Label>
              <Input type="select" name="select" id="exampleSelect" onChange={this.handleZoom}>
                <option value="Day">Jour</option>
                <option value="Week">Semaine</option>
                <option value="Month">Mois</option>
                <option value="Year">Année</option>
              </Input>{' '}
            </FormGroup>
          )}
        </div>
      </div>
    );
  }
}

const tr = withTranslation()(BestDateComponent);
export default tr;
