import { render } from 'react-dom';
import React, { Component } from 'react';
import ConfirmModal from './ConfirmModal';

/**
 * @class Confirm
 */
export default class Confirm {
  options: any;
  el: any;

  /**
   * @constructor
   * @param {any} options Options
   */
  constructor(options: any) {
    this.options = options;
    this.el = document.createElement('div');
  }

  /**
   * Retourne la modal de confirmation
   *
   * @method open
   * @returns {Promise} La modal
   */
  open(): Promise<{}> {
    const confirmPromise = new Promise(resolve => {
      render(
        <ConfirmModal
          {...this.options}
          onClose={(result: any) => {
            resolve(result);
          }}
        />,
        this.el
      );
    });

    return confirmPromise;
  }
}
