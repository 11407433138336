// eslint-disable-next-line import/no-unresolved
import sensorConstants from '../_constants/sensor.constants';

export default function sensor(state: any = {}, action: any) {
  switch (action.type) {
    case sensorConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case sensorConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.sensors,
      };
    case sensorConstants.GETALL_FAILURE:
      return {
        error: "Impossible d'obtenir la liste d'alertes",
      };

    case sensorConstants.GET_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case sensorConstants.GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        sensor: action.sensor,
      };
    case sensorConstants.GET_BY_ID_FAILURE:
      return {
        error: "Impossible d'obtenir la liste d'alertes",
      };
    case sensorConstants.PUT_STREGA_OPEN_REQUEST:
      return {
        ...state,
      };
    case sensorConstants.PUT_STREGA_OPEN_SUCCESS:
      return {
        ...state,
      };
    case sensorConstants.PUT_STREGA_OPEN_FAILURE:
      return {
        error: "Impossible d'ouvrir la vanne",
      };
    case sensorConstants.PUT_STREGA_CLOSE_REQUEST:
      return {
        ...state,
      };
    case sensorConstants.PUT_STREGA_CLOSE_SUCCESS:
      return {
        ...state,
      };
    case sensorConstants.PUT_STREGA_CLOSE_FAILURE:
      return {
        error: 'Impossible de fermer la vanne',
      };

    case sensorConstants.PUT_STREGA_UPLINK_FREQUENCY_REQUEST:
      return {
        ...state,
      };
    case sensorConstants.PUT_STREGA_UPLINK_FREQUENCY_SUCCESS:
      return {
        ...state,
      };
    case sensorConstants.PUT_STREGA_UPLINK_FREQUENCY_FAILURE:
      return {
        error: 'Impossible de maj up link frequency',
      };

    case sensorConstants.PUT_STREGA_UPLINK_SCHEDULE_TIME_REQUEST:
      return {
        ...state,
      };
    case sensorConstants.PUT_STREGA_UPLINK_SCHEDULE_TIME_SUCCESS:
      return {
        ...state,
      };
    case sensorConstants.PUT_STREGA_UPLINK_SCHEDULE_TIME_FAILURE:
      return {
        error: 'Impossible de maj up link frequency',
      };

    case sensorConstants.PUT_STREGA_SYNCTIME_REQUEST:
      return {
        ...state,
      };
    case sensorConstants.PUT_STREGA_SYNCTIME_SUCCESS:
      return {
        ...state,
      };
    case sensorConstants.PUT_STREGA_SYNCTIME_FAILURE:
      return {
        error: 'Impossible de maj la synctime',
      };
    case sensorConstants.ADD_ALERT_SUCCESS:
      const deepCopy = _.cloneDeep(state);
      _.set(deepCopy, 'sensor.info.sensorAlert', action.alert);
      return deepCopy;
    case sensorConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
