import pictureConstants from '../_constants/picture.constants';
import pictureService from '../_services/picture.service';

function getAllPicPath(serialList: any) {
  function request() {
    return { type: pictureConstants.GET_ALL_PIC_PATH_REQUEST };
  }
  function success(pathList: any) {
    return { type: pictureConstants.GET_ALL_PIC_PATH_SUCCESS, pathList };
  }
  function failure(error: Object) {
    return { type: pictureConstants.GET_ALL_PIC_PATH_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    pictureService
      .getAllPicPath(serialList)
      .then((profils: any) => dispatch(success(profils)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function clear() {
  return {
    type: pictureConstants.CLEAR,
  };
}

const pictureActions = {
  getAllPicPath,
  clear,
};

export default pictureActions;
