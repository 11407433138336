const profilConstants = {
  GET_ALL_REQUEST: 'GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'GET_ALL_FAILURE',

  GET_ALL_TYPE_REQUEST: 'GET_ALL_TYPE_REQUEST',
  GET_ALL_TYPE_SUCCESS: 'GET_ALL_TYPE_SUCCESS',
  GET_ALL_TYPE_FAILURE: 'GET_ALL_TYPE_FAILURE',

  SAVE_PROFIL_REQUEST: 'SAVE_PROFIL_REQUEST',
  SAVE_PROFIL_SUCCESS: 'SAVE_PROFIL_SUCCESS',
  SAVE_PROFIL_FAILURE: 'SAVE_PROFIL_FAILURE',

  GETVERSION_REQUEST: 'GETVERSION_REQUEST',
  GETVERSION_SUCCESS: 'GETVERSION_SUCCESS',
  GETVERSION_FAILURE: 'GETVERSION_FAILURE',

  SAVE_PROFIL_LINKS_REQUEST: 'SAVE_PROFIL_LINKS_REQUEST',
  SAVE_PROFIL_LINKS_SUCCESS: 'SAVE_PROFIL_LINKS_SUCCESS',
  SAVE_PROFIL_LINKS_FAILURE: 'SAVE_PROFIL_LINKS_FAILURE',

  DELETE_PROFIL_REQUEST: 'DELETE_PROFIL_REQUEST',
  DELETE_PROFIL_SUCCESS: 'DELETE_PROFIL_SUCCESS',
  DELETE_PROFIL_FAILURE: 'DELETE_PROFIL_FAILURE',

  DELETE_PROFIL_LINKS_REQUEST: 'DELETE_PROFIL_LINKS_REQUEST',
  DELETE_PROFIL_LINKS_SUCCESS: 'DELETE_PROFIL_LINKS_SUCCESS',
  DELETE_PROFIL_LINKS_FAILURE: 'DELETE_PROFIL_LINKS_FAILURE',

  CLEAR: 'CLEAR',
};

export default profilConstants;
