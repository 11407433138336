import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import _, { cloneDeep } from 'lodash';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Button, Col, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Sticky from 'react-sticky-fill';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import _debounce from 'lodash.debounce';
import { alertActions, localeActions, locationActions, pdiActions, vmeterActions } from '../_actions';
import { QueryBuilder } from '../QueryBuilder';
import ListTools from '../List/ListTools';
import Loading from '../_animations/Loading';
import { history, recursiveTranslate, REGEXVIRTUAL } from '../_helpers';
import locale from '../_shared/Locale.json';
import CompteurVert from '../SvgComponents/CompteurVert';
import listOperator from '../_shared/OperatorList';
import userActions from '../_actions/user.actions';
import WarningBand from '../Bands/Warning';
import { getFiltersRequest, getLightFilters } from '../QueryBuilder/FilterLSManager';
import RemoteTable from '../RemoteTable/RemoteTable';
import { withTranslation } from 'react-i18next';

class AddVirtualMeterNew extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    const sourceColumns: any[] = ListTools.getDefaultColumns(ListTools.typeOfList.VPdi).map((champ: any) => {
      return {
        dataField: champ,
        text: t(`columns.${champ}`),
        sort: true,
        classes: champ.includes('address') ? '' : 'crystalList-column',
        filter: textFilter({
          placeholder: t(`columns.${champ}`),
          delay: 500,
        }),
      };
    });
    this.state = {
      listMeter: {},
      tableOptions: { filters: {}, page: 1, sizePerPage: 10, sortField: null, sortOrder: null },
      displayingColumns: sourceColumns,
      vmeter: {},
      displayNoMeterSelected: false,
      allSelectButton: 0,
    };
    this.rowStyle = this.rowStyle.bind(this);
    this.clickOnRow = this.clickOnRow.bind(this);
    this.selectListMeter = this.selectListMeter.bind(this);
    this.deselectListMeter = this.deselectListMeter.bind(this);
  }

  componentDidMount() {
    const { dispatch, match, user, location } = this.props;
    const { locationId } = match.params;
    const vmeterId = location.search;
    dispatch(localeActions.load());
    dispatch(userActions.get(user.id));
    dispatch(locationActions.getInfos(locationId));
    const keyStorage = `filter-${ListTools.typeOfList.VPdi}-${locationId}`;
    this.setState({
      ruleList: getLightFilters(keyStorage),
    });
    dispatch(
      pdiActions.getRemotePdi(
        locationId,
        getFiltersRequest(keyStorage),
        this.state.tableOptions.sizePerPage,
        this.state.tableOptions.page,
        {
          field: this.state.tableOptions.sortField,
          way: this.state.tableOptions.sortOrder,
        },
        this.state.tableOptions.filters
      )
    );
    if (vmeterId && vmeterId.length > 0) {
      dispatch(vmeterActions.getInfos(locationId, vmeterId));
    }
    dispatch(vmeterActions.getAll(locationId));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { vmeters, pdis } = nextProps;
    const { allSelectButton, listMeter } = prevState;
    if (
      _.get(vmeters, 'fetchedVMeter.meterList') &&
      !_.get(prevState, 'existingMeters') &&
      _.size(_.get(vmeters, 'fetchedVMeter.meterList')) > 0
    ) {
      const meterListExisting = {};
      _.get(vmeters, 'fetchedVMeter.meterList').forEach(el => (meterListExisting[el.id] = el.serial));
      return {
        listMeter: meterListExisting,
        existingMeters: meterListExisting,
        vmeter: _.get(vmeters, 'fetchedVMeter'),
        name: _.get(vmeters, 'fetchedVMeter.general.name'),
      };
    }
    if (_.get(pdis, 'clickId') === allSelectButton) {
      const copyListMeter = _.cloneDeep(listMeter);
      _.entries(_.get(pdis, 'mapIdToSerial')).forEach(it => {
        if (!(it[0] in copyListMeter)) {
          copyListMeter[Number.parseInt(it[0])] = it[1];
        }
      });
      return { listMeter: copyListMeter, allSelectButton: allSelectButton + 1 };
    }
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    const { saved, vmeter, listMeter } = this.state;
    const { vmeters, match } = this.props;
    const { locationId } = match.params;
    if (
      saved &&
      vmeters &&
      vmeters.fetchedVMeter &&
      (_.size(vmeter) === 0 || _.size(_.get(vmeters, 'fetchedVMeter.meters')) === _.size(listMeter))
    ) {
      const link = `/locations/${locationId}/virtuals/info`;
      history.push({
        pathname: link,
        search: `?id=${_.get(vmeters, 'fetchedVMeter.id')}`,
      });
    }
  }

  getHeaderOfList = () => {
    const { pdis, match, t } = this.props;
    const { locationId } = match.params;
    const { listMeter, name, vmeter } = this.state;
    // //
    const sourceColumns = recursiveTranslate('fr', 'pdi', locale.fr.pdi, locale).map((champ: any) => {
      const regex = new RegExp('date');
      const elem: any = {
        dataField: champ.path,
        text: t(`columns.${champ.path}`),
        sort: true,
        classes: champ.path === 'address.concatAdress' ? '' : 'crystalList-column',
        filter: textFilter({
          placeholder: t(`columns.${champ.path}`),
          defaultValue: _.get(this.state, `tableOptions.filters['${champ.path}'].filterVal`),
          delay: 500,
        }),
      };
      if (regex.test(champ.path)) {
        elem.sortFunc = ListTools.sortTableDates;
      }
      return elem;
    });
    // //
    const listFields = sourceColumns
      .map((col: any) => {
        const typeData = ListTools.findSpecificType(col.dataField);

        return {
          label: t(`columns.${col.dataField}`),
          value: col.dataField,
          type: typeData,
        };
      })
      .sort((a: any, b: any) => {
        if (a.label < b.label) return -1;
        if (a.label === b.label) return 0;
        return 1;
      });

    return (
      <Fragment>
        <Sticky style={{ top: '90px', zIndex: '190' }}>
          <div className="presentation-container virtual-meter-info" style={{ border: '0.5px solid #ccc' }}>
            <div className="presentation-header">
              <span className="presentation-title">{t('all.meter.virtual_meter_edition')}</span>
              <span className="presentation-main-title">
                {Object.keys(vmeter).length > 0 ? vmeter.general.name : 'Nouveau'}
              </span>
            </div>
            <div className="presentation-body" style={{ background: 'none' }}>
              <Col md="12">
                <AvForm onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
                  <Row>
                    <Col md="4">
                      <AvField
                        name="virtualName"
                        placeholder={t('all.meter.virtual_meter_name')}
                        type="text"
                        validate={{
                          pattern: { value: REGEXVIRTUAL },
                          maxLength: {
                            value: 100,
                            errorMessage: t('all.text.name_lenght_max_100'),
                          },
                          async: this.validateExists,
                        }}
                        required
                        errorMessage={t('all.text.required_field_character_condition')}
                        value={name}
                      />
                    </Col>
                    <Col md={{ size: 4, offset: 4 }}>
                      <div className="float-right">
                        <Button type="button" className="danger" onClick={this.cancelEdition}>
                          {t('all.button.giveup')}
                        </Button>
                        <Button type="submit" style={{ marginLeft: '5px' }}>
                          {t('all.button.register')}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </Col>
              <div className="clearfix" />
            </div>
          </div>
        </Sticky>
        <div className="filter-container" style={{ marginTop: '10px' }}>
          <QueryBuilder
            sendListFiltered={this.receiveData}
            listData={pdis.items}
            listOperator={listOperator}
            listFilters={listFields}
            idContext="VPDI_SEARCH_QUERY"
            save={false}
            idSite={locationId}
          />
        </div>
      </Fragment>
    );
  };

  validateExists = _debounce((value, ctx, input, cb) => {
    const { vmeters } = this.props;
    value = value.startsWith('V-') ? value : `V-${value.replace(' ', '_')}`;
    if (value === '') {
      cb(true);
    } else if (
      vmeters.items &&
      vmeters.items
        .filter(el => el.id !== _.get(vmeters, 'fetchedVMeter.general.id'))
        .find((el: any) => el.name === value)
    ) {
      cb(t('virtualmeter.text.name_already_exist'));
    } else {
      cb(true);
    }
  }, 500);

  receiveData = (dataFiltered: any, ruleList: any) => {
    const { dispatch, match } = this.props;
    const { locationId } = match.params;
    const keyStorage = `filter-${ListTools.typeOfList.VPdi}-${locationId}`;
    if (!_.isEqual(this.state.ruleList, getLightFilters(keyStorage))) {
      this.setState({ ruleList: getLightFilters(keyStorage) }, () =>
        dispatch(pdiActions.getRemotePdi(locationId, getFiltersRequest(keyStorage), 10, 1))
      );
    }
  };

  handleValidSubmit = (event: Object, values: any) => {
    const { dispatch, vmeters, locations } = this.props;
    const { listMeter } = this.state;
    const { content } = locations.fetchedLocation;
    let body: any = {
      name: values.virtualName.startsWith('V-') ? values.virtualName.split('-')[1] : values.virtualName,
    };

    if (_.size(listMeter) === 0) {
      this.setState({ displayNoMeterSelected: true });
      return;
    }

    dispatch(alertActions.clear());

    if (vmeters && vmeters.fetchedVMeter) {
      body = cloneDeep(vmeters.fetchedVMeter.general);
      body.name = values.virtualName.startsWith('V-') ? values.virtualName : `V-${values.virtualName}`;
      body.meters = _.keys(listMeter);

      dispatch(vmeterActions.edit(body.id, body));
    } else {
      body.rndCode = content ? content.code : null;
      body.meters = _.keys(listMeter);
      dispatch(vmeterActions.create(body));
    }
    this.setState({
      name: values.virtualName.replace(' ', '_'),
      saved: _.size(listMeter) > 0,
    });
  };

  selectListMeter(event: Object, values: any) {
    const { allSelectButton } = this.state;
    const {
      dispatch,
      locations,
      match: {
        params: { locationId },
      },
    } = this.props;
    const { content } = locations.fetchedLocation;

    const keyStorage = `filter-${ListTools.typeOfList.VPdi}-${locationId}`;
    dispatch(pdiActions.getMapIdToSerial(getFiltersRequest(keyStorage), content && content.code, allSelectButton + 1));
    this.setState({ allSelectButton: allSelectButton + 1 });
  }

  deselectListMeter() {
    this.setState({ listMeter: {} });
  }

  handleInvalidSubmit = (event: Object, errors: Object, values: any) => {
    this.setState({
      name: values.virtualName,
    });
  };

  rowStyle(row) {
    const { listMeter, existingMeters, filters } = this.state;
    const rowId = _.get(row, 'meter.id');
    const isSelected = rowId in listMeter;
    const linked = rowId in (existingMeters || {});
    if (isSelected && linked) {
      return { backgroundColor: '#b9cfe4' };
    }
    if (isSelected) {
      return { backgroundColor: '#bddbd1' };
    }
    if (linked) {
      return { backgroundColor: '#f4c5bb' };
    }
    return {};
  }

  clickOnRow(e: Object, row: any) {
    const copyListMeter = _.cloneDeep(this.state.listMeter);
    const selectedMeterId = _.parseInt(_.get(row, 'meter.id'));
    if (selectedMeterId in copyListMeter) {
      delete copyListMeter[selectedMeterId];
    } else {
      copyListMeter[selectedMeterId] = row.meter.serial;
    }
    this.setState({ listMeter: copyListMeter });
  }

  render() {
    const {
      pdis,
      t,
      match: {
        params: { locationId },
      },
    } = this.props;
    const { displayingColumns, displayNoMeterSelected, listMeter, existingMeters } = this.state;
    const listAssociated = _.uniqBy(
      _.concat(
        _.entries(existingMeters).map(el => ({ 'meter.id': el[0], 'meter.serial': el[1] })),
        _.entries(listMeter).map(el => ({ 'meter.id': el[0], 'meter.serial': el[1] }))
      ),
      'meter.id'
    ).map(el => ({
      ...el,
      isSelected: _.get(el, 'meter.id') in listMeter,
      linked: _.get(el, 'meter.id') in (existingMeters || {}),
    }));
    const keyStorage = `filter-${ListTools.typeOfList.VPdi}-${locationId}`;
    const displayListSelectedColumns = displayingColumns
      .filter(el => el.dataField.includes('meter.serial'))
      .map(el => ({
        ...el,
        formatter: ListTools.formatSerialWithAction,
      }));
    return pdis.items ? (
      <div className="col-md-12">
        {this.getHeaderOfList()}
        <div style={{ marginTop: '10px' }}>
          <Row className="virtual-meter-editor">
            <Col md="9">
              <div className="crystalList-container">
                <div className="table-info-container">
                  <div style={{ display: 'flex' }}>
                    <h2>
                      <span>
                        <CompteurVert height="1em" width="1em" stroke="#31c6b3" fill="white" strokeWidth="1" />
                      </span>
                      {pdis.count} {t('all.meter.available_meter', { count: pdis.count })}
                    </h2>
                    <Button
                      className="button-all-associate"
                      onClick={this.selectListMeter}
                      style={{ marginBottom: '10px' }}
                      disabled={pdis.count === 0 || pdis.loadingFilters}
                    >
                      {`${t('all.text.select_all')} (${pdis.count})`}
                    </Button>
                  </div>

                  <br />
                  <RemoteTable
                    keyStorage={keyStorage}
                    displayingColumns={displayingColumns}
                    rowStyle={row => this.rowStyle(row)}
                    clickOnRow={this.clickOnRow}
                  />
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="table-info-container">
                <h2>
                  <span>
                    <CompteurVert height="1em" width="1em" stroke="#31c6b3" fill="white" strokeWidth="1" />
                  </span>
                  {_.size(this.state.listMeter)}{' '}
                  {t('all.meter.selected_meter_plural', { count: _.size(this.state.listMeter) })}
                </h2>
                {_.size(this.state.listMeter) > 0 && (
                  <Button
                    className="button-all-associate"
                    onClick={this.deselectListMeter}
                    style={{ marginBottom: '10px' }}
                    disabled={pdis.count === 0}
                  >
                    {t('all.text.deselect_all')}
                  </Button>
                )}
                <br />
                <div className="existing-meter-list">
                  {displayNoMeterSelected && _.size(listMeter) === 0 && (
                    <WarningBand message={t('mask.text.select_at_least_once_meter')} />
                  )}
                  {_.size(listAssociated) > 0 && (
                    <BootstrapTable
                      keyField="id"
                      key="mtrassociated"
                      rowClasses="clickable"
                      data={listAssociated}
                      bootstrap4
                      bordered={false}
                      columns={displayListSelectedColumns}
                      rowEvents={{ onClick: this.clickOnRow }}
                      hover
                      filter={filterFactory()}
                      headerClasses="crystalList-column"
                      pagination={paginationFactory()}
                      rowStyle={this.rowStyle}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    ) : (
      <Loading />
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, pdis, locales, vmeters, locations, alert, users } = state;
  const { user } = authentication;

  return {
    user,
    pdis,
    locales,
    vmeters,
    locations,
    alert,
    users,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(AddVirtualMeterNew)));
