import React from 'react';

const SvgDetailsPointCollecteBlanc = (props: any) => (
  <svg viewBox="0 0 15.71 20.94" {...props}>
    <g id="details_point_collecte_blanc_svg__Calque_2" data-name="Calque 2">
      <g id="details_point_collecte_blanc_svg__Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          d="M15.66 20.2l-2.76-5.84a.55.55 0 0 0-.27-.25l-1.37-.61 2.38-4a6.36 6.36 0 0 0 .72-3A6.51 6.51 0 0 0 3.23 1.93a6.52 6.52 0 0 0-1.18 7.55l2.38 4-1.38.61a.49.49 0 0 0-.26.25L.05 20.2a.52.52 0 0 0 .1.58.52.52 0 0 0 .58.12l3.46-1.54 3.45 1.54a.61.61 0 0 0 .42 0l3.46-1.54L15 20.9a.52.52 0 0 0 .21 0 .49.49 0 0 0 .37-.16.52.52 0 0 0 .08-.54zM2.37 6.48A5.51 5.51 0 0 1 7.78 1a5.49 5.49 0 0 1 5 8L8.3 16.44a.51.51 0 0 1-.89 0L3 9a5.43 5.43 0 0 1-.63-2.52z"
        />
        <path
          fill={props.fill}
          d="M10.21 6.51a2.36 2.36 0 1 0-2.36 2.36 2.36 2.36 0 0 0 2.36-2.36zm-3.68 0a1.33 1.33 0 0 1 2.65 0 1.33 1.33 0 1 1-2.65 0z"
        />
      </g>
    </g>
  </svg>
);

export default SvgDetailsPointCollecteBlanc;
