import React from 'react';

const SvgDoigtVert = (props: any) => (
  <svg viewBox="0 0 102.73 132.95" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M65.05,124.31l25-22.72a11.56,11.56,0,0,0,0-16.36,11.33,11.33,0,0,0-5-2.93,11.58,11.58,0,0,0-6-18.13,11.58,11.58,0,0,0-1-15.2A11.4,11.4,0,0,0,73,46.07l10.7-10.71A11.57,11.57,0,0,0,67.37,19L26.23,60.14,22.4,35.74A13.54,13.54,0,0,0,9.06,24.17,9.08,9.08,0,0,0,0,33.24v57a33,33,0,0,0,9.74,23.5l9.5,9.51a33.2,33.2,0,0,0,45.81,1.08ZM23.51,119l-9.5-9.5A27,27,0,0,1,6,90.22v-57a3,3,0,0,1,3-3,7.48,7.48,0,0,1,7.37,6.42L21.19,67a3,3,0,0,0,5.12,1.66L71.65,23.27a5.53,5.53,0,0,1,7.81,7.82L49.23,61.32a3,3,0,0,0,2.14,5.16,3,3,0,0,0,2.14-.89L65.85,53.24a5.53,5.53,0,0,1,7.82,7.81L61.31,73.41a3,3,0,0,0,2.14,5.15,3,3,0,0,0,2.14-.89l6.31-6.3a5.52,5.52,0,0,1,7.81,7.81l-6.33,6.34s0,0,0,0h0a3,3,0,0,0,4.33,4.21l.26-.26a5.53,5.53,0,0,1,7.92,7.72L61,119.84A27.15,27.15,0,0,1,23.51,119Z"
        />
        <path
          fill={props.fill}
          d="M86.7,52A27.17,27.17,0,1,0,50.78,16a3.09,3.09,0,0,0-.27,1.25,3,3,0,0,0,5.78,1.24A21.12,21.12,0,1,1,84.21,46.44,3,3,0,0,0,86.7,52Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgDoigtVert;
