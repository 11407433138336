import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import Chart from 'chart.js';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

interface Props {
  chartInfo: any;
  index: number;
  handleClick?: Function;
}

function getBoxWidth(labelOpts, fontSize) {
  return labelOpts.usePointStyle ? fontSize * Math.SQRT2 : labelOpts.boxWidth;
}

Chart.Legend.prototype.afterFit = function() {
  this.height = this.height + 25;
};

function createNewLegendAndAttach(chartInstance, legendOpts) {
  var legend = new Chart.NewLegend({
    ctx: chartInstance.chart.ctx,
    options: legendOpts,
    chart: chartInstance,
  });

  if (chartInstance.legend) {
    Chart.layoutService.removeBox(chartInstance, chartInstance.legend);
    delete chartInstance.newLegend;
  }

  chartInstance.newLegend = legend;
  Chart.layoutService.addBox(chartInstance, legend);
}

// Register the legend plugin
Chart.plugins.register({
  id: 't1',
  beforeInit: function(chartInstance) {
    var legendOpts = chartInstance.options.legend;

    if (legendOpts) {
      createNewLegendAndAttach(chartInstance, legendOpts);
    }
  },
  beforeUpdate: function(chartInstance) {
    var legendOpts = chartInstance.options.legend;

    if (legendOpts) {
      legendOpts = Chart.helpers.configMerge(Chart.defaults.global.legend, legendOpts);

      if (chartInstance.newLegend) {
        chartInstance.newLegend.options = legendOpts;
      } else {
        createNewLegendAndAttach(chartInstance, legendOpts);
      }
    } else {
      Chart.layoutService.removeBox(chartInstance, chartInstance.newLegend);
      delete chartInstance.newLegend;
    }
  },
  afterEvent: function(chartInstance, e) {
    var legend = chartInstance.newLegend;
    if (legend) {
      legend.handleEvent(e);
    }
  },
});

class DoughnutChart extends Component<Props> {
  constructor(props: any) {
    super(props);
    this.state = {
      offSet: 0,
    };
  }
  render() {
    const { chartInfo, handleClick, t } = this.props;

    let offSetValue = 0;

    const dataS = {
      labels: chartInfo.labels,
      datasets: [
        {
          data: chartInfo.data,
          backgroundColor: chartInfo.colorList,
          hoverBackgroundColor: chartInfo.hoverColorList,
        },
      ],
    };
    const options: any = {
      emptyOverlay: {
        message: t('all.text.no_data_available'),
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 30,
        },
      },
      scales: {
        yAxes: [
          {
            display: false,
            gridLines: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          display: function(ctx: any) {
            return ctx.dataset.data[ctx.dataIndex] !== 0; // or >= 1 or ...
          },
          color: function(ctx: any) {
            // use the same color as the border
            return ctx.dataset.backgroundColor;
          },
          borderColor: function(ctx: any) {
            // use the same color as the border
            return ctx.dataset.backgroundColor;
          },

          borderRadius: 5,
          borderWidth: 2,
          anchor: 'end',
          clamp: true,
          offset: function(ctx: any) {
            const newList = _.cloneDeep(ctx.dataset.data);
            const max = newList.sort((a: any, b: any) => b - a)[0];
            const dataInf = ctx.dataset.data
              .map((el: any, index: any) => ({ [index]: el }))
              .filter((el: any, index: any) => max / el[index] >= 100);
            if (dataInf.length > 1) {
              const index = dataInf.findIndex((el: any) => el[ctx.dataIndex] === ctx.dataset.data[ctx.dataIndex]);
              offSetValue = (dataInf.length - 1) * 25;
              if (index >= 0) {
                return index * 25;
              } else {
                return 0;
              }
            }
          },
          align: 'end',
        },
        t1: false,
      },
    };

    if (handleClick !== undefined) {
      options.onClick = (event: any, chart: any) => handleClick(event, chart, chartInfo);
    }

    return (
      <div className="doughnut-chart">
        <div className="chart-container" style={{ marginTop: 0 }}>
          <h3>{chartInfo.title}</h3>
          <Doughnut data={dataS} options={options} />
        </div>
      </div>
    );
  }
}

const tr = withTranslation()(DoughnutChart);
export default tr;
