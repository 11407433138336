import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { alertActions, userActions } from '../_actions';
import { alert, users } from '../_interfaces/reducers';
import { User } from '../_entities/user';
import { PasswordChangeRenderWithForm } from './PasswordChangeRenderWithForm';
import { PasswordChangeRenderWithGenerate } from './PasswordChangeRenderWithGenerate';
import { withTranslation } from 'react-i18next';

interface Props {
  edit: Function;
  users: users;
  usertoEdit: User;
  dispatch: Function;
  user: User;
  alert: alert;
  isOpen: boolean;
  updated: boolean;
  generate: string | undefined;
}

interface State {
  usertoEdit: any;
  password: string;
  passwordCopy: string;
  updated: boolean;
}

/**
 * Gère le changement de mot de passe utilisateur
 *
 * @class PasswordChangeComponent
 * @extends Component
 */
class PasswordChangeComponent extends React.Component<Props & React.HTMLAttributes<{}>, State> {
  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      usertoEdit: {},
      password: '',
      passwordCopy: '',
      updated: false,
    };
  }

  /**
   * @static
   * @method getDerivedStateFromProps
   * @param {Props} props Props du composant
   * @param {State} state State du composant
   */
  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.users.passwordUpdated) {
      return { usertoEdit: props.usertoEdit, password: '', passwordCopy: '', updated: true };
    }
    if (!props.users.passwordUpdated) {
      return {
        usertoEdit: props.usertoEdit,
        password: state.password,
        passwordCopy: state.passwordCopy,
        updated: false,
      };
    }
    return null;
  }

  /**
   * @method componentDidMount
   */
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
    this.setState({
      password: '',
      passwordCopy: '',
      updated: false,
    });
  }

  /**
   * Nettoie le state au démontage
   *
   * @method componentWillUnmount
   * @memberof DisplayDashBoardsComponent
   */
  componentWillUnmount() {
    const { dispatch } = this.props;
    this.setState({
      password: '',
      passwordCopy: '',
      updated: false,
    });
  }

  /**
   * @method componentDidUpdate
   */
  componentDidUpdate() {
    const { edit, users } = this.props;
    if (users.passwordUpdated) {
      edit();
    }
  }

  /**
   * Gère l'action à la validation du formulaire
   *
   * @method handleValidSubmit
   * @param {Object} event Evènement
   * @param {any} values Valeurs
   */
  handleValidSubmit = (event: Object, values: any) => {
    this.setState({
      password: values.password,
    });
    const { password } = this.state;
    const { usertoEdit } = this.state;
    const { dispatch } = this.props;
    const { users } = this.props;

    if (password && password.length >= 8) {
      // on créé un utilisateur pour ne pas influencer l'état
      const userToupdate = {
        id: usertoEdit.id,
        role: usertoEdit.role,
        email: usertoEdit.email,
        userName: usertoEdit.userName,
        firstName: usertoEdit.firstName,
        lastName: usertoEdit.lastName,
        password,
      };
      dispatch(
        userActions.updateWithPassword(users.fetchedUser, userToupdate, users.permissions, users.lastUpdatedInfo)
      );
    }
  };

  handleInvalidSubmit = (event: Object, errors: Object, values: any) => {
    this.setState({
      password: values.password,
      passwordCopy: values.passwordCopy,
    });
  };

  /**
   * Gère les droits de mise à jour du mot de passe
   *
   * @method couldUpdatePassword
   * @returns {boolean} Le test
   */
  couldUpdatePassword = () => {
    const { user, usertoEdit } = this.props;
    if (user.role.userName === usertoEdit.role.userName && user.id === usertoEdit.id) {
      return true;
    }

    return ['DIOPTASE', 'SUPERADMIN', 'ADMIN'].includes(user.role.name);
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { alert, className, isOpen, edit, generate, t } = this.props;
    const { password, passwordCopy, updated } = this.state;

    return (
      <div>
        {this.couldUpdatePassword() && !generate && (
          <PasswordChangeRenderWithForm
            isOpen={isOpen}
            alert={alert}
            className={className}
            edit={edit}
            password={password}
            passwordCopy={passwordCopy}
            updated={updated}
            handleInvalidSubmit={this.handleInvalidSubmit}
            handleValidSubmit={this.handleValidSubmit}
            t={t}
          />
        )}
        {this.couldUpdatePassword() && generate && (
          <Fragment>
            <PasswordChangeRenderWithGenerate
              isOpen={isOpen}
              alert={alert}
              className={className}
              edit={edit}
              password={generate}
              passwordCopy={generate}
              updated={updated}
              handleInvalidSubmit={this.handleInvalidSubmit}
              handleValidSubmit={this.handleValidSubmit}
              t={t}
            />
          </Fragment>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, alert, users } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    users,
  };
}

export default withTranslation()(connect(mapStateToProps)(PasswordChangeComponent));
