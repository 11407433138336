import React, { useEffect, useState } from 'react';

const ImgFromBlob = props => {
  const { blob, onLoad, onClick, style } = props;
  const [image, setImage] = useState();

  useEffect(() => {
    setImage(URL.createObjectURL(blob));
  }, [blob]);
  return <img onLoad={e => onLoad && onLoad(e)} src={image} onClick={() => onClick && onClick()} style={style} />;
};

export default ImgFromBlob;
