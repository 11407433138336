import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Loading extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className={'loading-animation-container'} style={{ height: `${window.innerHeight - 110}px` }}>
        <div className={'items-container'}>
          <div className="spinner">
            <div className="dot1" />
            <div className="dot2" />
          </div>
          <div className="spinner2">
            <div className="dot1" />
            <div className="dot2" />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', zIndex: 1 }}>
            <h3 style={{ marginBottom: 0, color: 'black', position: 'absolute', top: '65%' }}>
              {t('all.text.loading')}
            </h3>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Loading);
