import gestionnaireConstants from '../_constants/gestionnaire.constants';
import gestionnaireService from '../_services/gestionnaire.service';
import { gestionnaireActions } from './index';

function getAllGestionnaires(userId: any) {
  function request() {
    return { type: gestionnaireConstants.GETALLGESTIONNAIRE_REQUEST };
  }
  function success(gestionnaires: Array<any>) {
    return { type: gestionnaireConstants.GETALLGESTIONNAIRE_SUCCESS, gestionnaires };
  }
  function failure(error: Object) {
    return { type: gestionnaireConstants.GETALLGESTIONNAIRE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    gestionnaireService
      .getAllGestionnaires()
      .then((gestionnaires: Array<any>) => {
        dispatch(success(gestionnaires));
        if (userId) {
          dispatch(gestionnaireActions.getAllGestionnairesByID(userId));
        }
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getAllGestionnairesByID(userID: number) {
  function request() {
    return { type: gestionnaireConstants.GET_GESTIONNAIRE_PERM_REQUEST };
  }
  function success(gestionnaires: Array<any>) {
    return { type: gestionnaireConstants.GET_GESTIONNAIRE_PERM_SUCCESS, gestionnaires };
  }
  function failure(error: Object) {
    return { type: gestionnaireConstants.GET_GESTIONNAIRE_PERM_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    gestionnaireService
      .getAllGestionnairesByID(userID)
      .then((gestionnaires: Array<any>) => dispatch(success(gestionnaires)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getGestionnaire(gestionnaireId: any) {
  function request() {
    return { type: gestionnaireConstants.GETGESTIONNAIRE_REQUEST };
  }
  function success(gestionnaire: Object) {
    return { type: gestionnaireConstants.GETGESTIONNAIRE_SUCCESS, gestionnaire };
  }
  function failure(error: Object) {
    return { type: gestionnaireConstants.GETGESTIONNAIRE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    gestionnaireService
      .getGestionnaire(gestionnaireId)
      .then((gestionnaire: Object) => dispatch(success(gestionnaire)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function clear() {
  return {
    type: gestionnaireConstants.CLEAR,
  };
}

function create(nameGestionnaire, numeroGestionnaire) {
  function success(gestionnaires: Array<any>) {
    return { type: gestionnaireConstants.GETALLGESTIONNAIRE_SUCCESS, gestionnaires };
  }
  return (dispatch: Function) => {
    gestionnaireService
      .create(nameGestionnaire, numeroGestionnaire)
      .then((gestionnaires: Array<any>) => dispatch(success(gestionnaires)))
      .catch(() => {});
  };
}

function updateGestionnaireSiteId(gestionnaireId, nameGestionnaire, siteId) {
  function success(gestionnaires: Array<any>) {
    return { type: gestionnaireConstants.GETALLGESTIONNAIRE_SUCCESS, gestionnaires };
  }
  return (dispatch: Function) => {
    gestionnaireService
      .updateGestionnaireSiteId(gestionnaireId, nameGestionnaire, siteId)
      .then((gestionnaires: Array<any>) => dispatch(success(gestionnaires)))
      .catch(() => {});
  };
}

const templateActions = {
  getAllGestionnaires,
  getAllGestionnairesByID,
  getGestionnaire,
  clear,
  create,
  updateGestionnaireSiteId,
};

export default templateActions;
