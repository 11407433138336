/* eslint-disable react/require-default-props */

import React from 'react';

type Props = {
  style?: any;
  orientation: string;
  borderColor?: string;
  backgroundColor?: string;
};

export default function Triangle(props: Props) {
  const { style, orientation, borderColor, backgroundColor } = props;

  return (
    <div id="triangle" style={style}>
      <i className={'arrow ' + orientation} style={{ borderColor: borderColor, backgroundColor: backgroundColor }} />
    </div>
  );
}
