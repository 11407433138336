import _ from 'lodash';
import tourneeActions from '../_constants/tournee.constants';
import { action } from '../_interfaces/action';
import exportConstants from '../_constants/export.constants';

export default function gestionnaire(state: any = {}, action: any) {
  switch (action.type) {
    case tourneeActions.GETTOURNEE_REQUEST:
      return {
        ...state,
        loadingFiches: true,
      };
    case tourneeActions.GETTOURNEE_SUCCESS:
      return {
        ...state,
        info: action.tournee,
      };

    case tourneeActions.GETTOURNEE_FAILURE:
      return {
        error: "Impossible d'obtenir les informations de cette tournee",
      };
    case tourneeActions.DELETE_TOURNEE_FAILURE:
      return {
        deletionProcessing: false,
        error: 'Impossible de supprimer la/les tournee(s)',
      };
    case tourneeActions.DELETE_TOURNEE_SUCCESS:
      return {
        ...state,
        deletionProcessing: false,
        tourneeDeletionReport: action.tourneeDeletionReport,
      };
    case tourneeActions.DELETE_TOURNEE_REQUEST:
      return {
        ...state,
        deletionProcessing: true,
      };
    case tourneeActions.GET_TELERELEVE_TOURNEE_SUCCESS:
      return {
        ...state,
        telereleveGet: action.tournee,
        telereleveGetLoading: false,
      };
    case tourneeActions.GET_TELERELEVE_TOURNEE_REQUEST:
      return {
        ...state,
        telereleveGetLoading: true,
      };
    case tourneeActions.GET_TELERELEVE_TOURNEE_FAILURE:
      return {
        error: 'Impossible de supprimer la tournee',
        telereleveGetLoading: false,
      };
    case tourneeActions.FILL_TELERELEVE_TOURNEE_REQUEST:
      return {
        ...state,
        telereleveFillLoading: true,
      };
    case tourneeActions.FILL_TELERELEVE_TOURNEE_SUCCESS:
      return {
        ...state,
        telereleveFill: action.tournee,
        telereleveFillLoading: false,
      };
    case tourneeActions.FILL_TELERELEVE_TOURNEE_FAILURE:
      return {
        error: 'Impossible de supprimer la tournee',
        telereleveFillLoading: false,
      };
    case tourneeActions.GETTOURNEEFICHE_REQUEST:
      return {
        ...state,
      };
    case tourneeActions.GETTOURNEEFICHE_SUCCESS:
      const copy = _.cloneDeep(action.fiche);
      if (copy.changeMeter) {
        copy.changeMeter.informations = action.fiche.baseMeter.informations;
        copy.changeMeter.message = action.fiche.baseMeter.message;
        copy.changeMeter.particularMessage = action.fiche.baseMeter.particularMessage;
      }
      return {
        ...state,
        fetchedFiche: copy,
      };

    case tourneeActions.GETTOURNEEFICHE_FAILURE:
      return {
        error: "Impossible d'obtenir les informations de cette fiche",
      };
    case tourneeActions.GETALLTOURNEEFICHE_REQUEST:
      return { ...state, loadingFiches: true };

    case tourneeActions.GETALLTOURNEEFICHE_SUCCESS:
      return {
        ...state,
        loadingFiches: false,
        fiches: action.fiche,
      };
    case tourneeActions.ROTATE_OPTIONS_SUCCESS:
      return {
        ...state,
        rotateOptions: action.rotateOptions,
      };

    case tourneeActions.GETALLTOURNEEFICHE_FAILURE:
      return {
        error: "Impossible d'obtenir les informations de cette fiche",
      };
    case tourneeActions.STATS_TOURNEE_SUCCESS:
      const stats: any = {
        barChart: [],
        doughnutChart: [],
      };
      _.entries(action.stats.chartDataDtoMap).map((el: any) => {
        if (el[0] === 'metersByYear') {
          stats.barChart.push({ title: el[0], data: el[1].data });
        } else {
          stats.doughnutChart.push({ title: el[0], data: el[1].data });
        }
      });
      return {
        ...state,
        stats,
      };

    case tourneeActions.STATS_TOURNEE_FAILURE:
      return {
        error: "Impossible d'obtenir les statistiques",
      };

    case tourneeActions.EXPORT_TOURNEE_REQUEST:
    case tourneeActions.SILEX_TO_FACTU_REQUEST:
    case tourneeActions.EXPORT_TOURNEE_TELEO_REQUEST:
      return {
        ...state,
        exportProcessing: true,
      };

    case tourneeActions.EXPORT_TOURNEE_SUCCESS:
    case tourneeActions.EXPORT_TOURNEE_TELEO_SUCCESS:
      return {
        ...state,
        exportProcessing: false,
        report: action.report,
      };

    case tourneeActions.SILEX_TO_FACTU_SUCCESS:
      return {
        ...state,
        exportProcessing: false,
        report: action.report,
      };

    case tourneeActions.EXPORT_TOURNEE_TELEO_FAILURE:
    case tourneeActions.SILEX_TO_FACTU_FAILURE:
    case tourneeActions.EXPORT_TOURNEE_FAILURE:
      return {
        ...state,
        errorInfo: action.error,
        exportProcessing: false,
      };
    case tourneeActions.GET_FACTURATION_REQUEST:
      return {
        ...state,
      };

    case tourneeActions.GET_FACTURATION_SUCCESS:
      return {
        ...state,
        facturation: action.name,
      };

    case tourneeActions.GET_FACTURATION_FAILURE:
      return {
        ...state,
        error: 'Nom de la facturation indisponible',
      };
    case tourneeActions.UPDATE_TOURNEEMSG_REQUEST:
      return {
        ...state,
        updatingMsg: true,
      };

    case tourneeActions.UPDATE_TOURNEEMSG_SUCCESS:
      return {
        ...state,
        info: action.tourneeInfo,
        updatingMsg: false,
      };

    case tourneeActions.UPDATE_TOURNEEMSG_FAILURE:
      return {
        ...state,
        updatingMsg: false,
      };
    case tourneeActions.ROUNDS_GETALL_INFO_SUCCESS:
      return {
        ...state,
        allInfos: action.allRoundsInfo,
      };
    case tourneeActions.ROUNDS_GETALL_TOURNEE_INFO_SUCCESS:
      return {
        ...state,
        allTourneeInfos: action.allRoundsInfo,
      };

    case tourneeActions.ROUNDS_GETALL_INFO_FAILURE:
      return {
        error: "Impossible d'obtenir les informations de toutes les tournee",
      };
    case tourneeActions.ROUNDS_EDIT_INFO_SUCCESS:
      return {
        ...state,
        editInfos: action.editInfos,
      };
    case tourneeActions.GET_EXPORT_OPTIONS_SUCCESS:
      return {
        ...state,
        options: action.options,
      };
    case tourneeActions.GET_ID_WITH_CODE_REQUEST:
      return {
        ...state,
      };
    case tourneeActions.GET_ID_WITH_CODE_SUCCESS:
      return {
        ...state,
        locationId: action.id,
      };
    case tourneeActions.GET_ID_WITH_CODE_FAILURE:
      return {
        error: "Impossible de récupérer l'id associé",
      };
    case tourneeActions.CLEAR:
      const noClearState = {
        facturation: state.facturation,
        options: state.options,
      };
      return {
        ...noClearState,
      };
    case tourneeActions.CLEAR_TELERELEVE_READS:
      return {
        ...state,
        telereleveGet: null,
      };
    default:
      return state;
  }
}
