import React from 'react';
import { Alert } from 'reactstrap';
import DisplayBookMarks from './DisplayBookmarks';
import { dashboardService, bookmarkService } from '../_services';
import { Location } from '../_entities/location';
import { User } from '../_entities/user';
import { bookmark } from '../_entities/bookmark';
import { dashboard } from '../_entities/dashboard';
import { withTranslation } from 'react-i18next';

interface Props {
  location: Location;
  user: User;
}

interface State {
  bookmarks: Array<bookmark>;
  dashboards: Array<dashboard>;
  errorBookmarks: any;
}

/**
 * Affiche les marque-pages par site
 *
 * @class BookmarksComponent
 * @extends Component
 */
class BookmarksComponent extends React.Component<Props, State> {
  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      dashboards: [],
      bookmarks: [],
      errorBookmarks: false,
    };
  }

  /**
   * @method componentDidMount
   */
  componentDidMount() {
    const { location } = this.props;
    // pas de redux on monte à la main
    dashboardService
      .getAll(location.id)
      .then(dashboards => {
        this.setState({
          dashboards,
        });
      })
      .catch(error => {
        this.setState({
          errorBookmarks: true,
        });
        console.log(error);
      });
    bookmarkService
      .getAll(location.id)
      .then(bookmarks => {
        this.setState({
          bookmarks,
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          errorBookmarks: true,
        });
      });
  }

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { location, user, t } = this.props;
    const { bookmarks, dashboards, errorBookmarks } = this.state;
    return (
      <div>
        {errorBookmarks && <Alert color="danger">{t('bookmark_location.error_band.impossible_get_favorite')}</Alert>}
        {bookmarks.length > 0 && dashboards.length > 0 && (
          <div>
            <h3>{location.name}</h3>
            <DisplayBookMarks location={location.id} bookmarks={bookmarks} dashboards={dashboards} user={user} />
          </div>
        )}
      </div>
    );
  }
}

const tr = withTranslation()(BookmarksComponent);
export default tr;
