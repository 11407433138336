import React from 'react';

const SmallWeakCons = (props: any) => (
  <svg viewBox="0 0 87.5 87.53" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="pictos">
        <path
          fill={props.fill}
          d="M2.21,12.87,61.74,72.4H14.13a7.57,7.57,0,0,0,0,15.13H79.94A7.55,7.55,0,0,0,87.5,80V14.17a7.57,7.57,0,1,0-15.13,0v47.6L12.91,2.21A7.55,7.55,0,0,0,2.21,12.87"
        />
      </g>
    </g>
  </svg>
);
export default SmallWeakCons;
