import React from 'react';
import Task from '../task';

export default function TaskDisplay({
  task,
  index,
  labelTask,
  column,
  removeTask,
  onDeleteAdd,
  fctBorder,
  onStart,
  taskStarting,
  onEnd,
  multiSelect,
  noDrop,
  multiDragSelect,
  multiDisplay,
  uncheckedTask,
  checkedDisabled,
  checkedIsDisabled,
  delAffect,
  addAffect,
  allTasks,
  onClickCancelAll,
  getAllCommunication,
  unDropAffect,
  loadingList,
  getIdRound,
  labels,
  isOverring,
  beforeStart,
  isOverContent,
  onDragStart,
  onDragEnd,
  onBeforeDragStart,
  updateDrop,
  dragStop,
  taskOverring,
  openFilter,
  affectList,
  switchToPartial,
  partiel,
}) {
  return (
    <Task
      key={task.id}
      task={task}
      index={index}
      labelTask={labelTask}
      column={column}
      columnDisabled={column.id}
      removeTask={removeTask}
      onDeleteAdd={onDeleteAdd}
      fctBorder={fctBorder}
      onStart={onStart}
      taskStarting={taskStarting}
      multiSelect={multiSelect}
      noDrop={noDrop}
      multiDragSelect={multiDragSelect}
      multiDisplay={multiDisplay}
      uncheckedTask={uncheckedTask}
      checkedDisabled={checkedDisabled}
      checkedIsDisabled={checkedIsDisabled}
      delAffect={delAffect}
      addAffect={addAffect}
      allTasks={allTasks}
      onClickCancelAll={onClickCancelAll}
      getAllCommunication={getAllCommunication}
      unDropAffect={unDropAffect}
      getIdRound={getIdRound}
      labelsList={labels}
      isOverring={isOverring}
      beforeStart={beforeStart}
      isOverContent={isOverContent}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onBeforeDragStart={onBeforeDragStart}
      updateDrop={updateDrop}
      dragStop={dragStop}
      taskOverring={taskOverring}
      openFilter={openFilter}
      affectList={affectList}
      switchToPartial={switchToPartial}
      partiel={partiel}
    />
  );
}
