import 'babel-polyfill';
import 'whatwg-fetch';
import 'react-app-polyfill/ie9';
// Load Roboto typeface
import 'typeface-roboto';

import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import './index.css';
import { unregister } from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import './App.scss';
import store from './_helpers/store';
import { App } from './App';
import { ErrorBoundary } from './_components';

import './i18n';

render(
  <Provider store={store}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
);

unregister();
