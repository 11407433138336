import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Alert, Badge, Button, PopoverBody, PopoverHeader, UncontrolledPopover, UncontrolledTooltip } from 'reactstrap';
import SvgLogoSigfox from '../SvgComponents/LogoSigfox';
import SvgLora from '../SvgComponents/LogoLora';
import teleReleveActions from '../_actions/teleReleve.actions';
import { pdiActions } from '../_actions';
import { withTranslation } from 'react-i18next';

interface Props {
  type: string;
  pdis: any;
  meters: any;
  radios: any;
  dispatch: Function;
  locations: any;
}

interface State {
  data: any;
}

class TeleReleveInfo extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      data: {},
    };
  }

  static getDerivedStateFromProps(props: any, state: any) {
    const { type, pdis, meters, radios, telereleve, dispatch } = props;

    const copyState = _.cloneDeep(state);
    switch (type) {
      case 'PDI':
        copyState.data = pdis.fetchedPdi.general.telereleveStatus;
        break;
      case 'METER':
        copyState.data = meters.fetchedMeter.telereleveStatus;
        break;
      case 'RADIO':
        copyState.data = radios.fetchedRadio.general.telereleveStatus;
        break;
    }

    if (_.get(telereleve, 'actions') && copyState.provisionProcessing === null) {
      dispatch(pdiActions.getFullPdi(_.get(pdis, 'fetchedPdi.general.id')));
      copyState.provisionProcessing = _.get(telereleve, 'actions');
    }
    if (telereleve.provisionProcessing) {
      copyState.provisionProcessing = null;
    }

    return copyState;
  }

  isAdmin = () => {
    const { user } = this.props;

    return ['DIOPTASE', 'SUPERADMIN', 'ADMIN'].includes(user.role.name);
  };

  render() {
    const { radios, meters, dispatch, t } = this.props;
    const { data } = this.state;
    let mtrIndex = 0;
    let rdoIndex = 0;
    let color = 'transparent';
    const actualMeter = meters.allMetersInfo && meters.allMetersInfo[meters.allMetersInfo.length - 1];
    if (radios.fetchedRadio && meters.allMetersInfo) {
      mtrIndex = actualMeter.lastRead ? actualMeter.lastRead.index : 0;
      rdoIndex = radios.fetchedRadio.index;
      color =
        meters.allMetersInfo[meters.allMetersInfo.length - 1].telereleveStatus &&
        meters.allMetersInfo[meters.allMetersInfo.length - 1].consumption.length > 0
          ? '#31c6b3'
          : meters.allMetersInfo[meters.allMetersInfo.length - 1].telereleveStatus
          ? '#f2ae2e'
          : '#dc3545';
    }
    return (
      <>
        {data && (
          <div className="tele-releve-info-container">
            <div className="c35 flex-box">
              {data.networkName === 'Lora' ? (
                <SvgLora style={{ color: 'white', margin: '0 30px 0 10px' }} height="3em" width="25%" />
              ) : (
                data.networkName === 'Sigfox' && <SvgLogoSigfox style={{ color: 'white', margin: '0 30px 0 10px' }} />
              )}
              <div style={{ width: '50%' }}>
                <h3>Module IoT</h3>
                <span>
                  {t('telereleveinfo.text.network')}: {data.providerName}
                </span>
              </div>
              <div style={{ height: '20px', width: '20px', borderRadius: '50%', backgroundColor: color }} />
            </div>
            <div className="c25">
              <span>{t('telereleveinfo.text.deveui')}</span>
              <h3>{data.devEUI}</h3>
            </div>
            {data.providerName && (
              <>
                <div className="c20">
                  <span>{t('telereleveinfo.text.last_telereleve_read')}</span>
                  <h3>
                    {moment.utc(data.lastReadDate).isValid()
                      ? moment.utc(data.lastReadDate).format(t('all.date_format.date_and_time'))
                      : t('all.text.not_specified')}
                  </h3>
                </div>
                <div className="c10">
                  <span>{t('telereleveinfo.text.meter_index')}</span>
                  <h3>{data.indexCpt}</h3>
                </div>
                <div className="c10">
                  <span>{t('telereleveinfo.text.radio_index')}</span>
                  <h3>{data.indexRadio}</h3>
                </div>
              </>
            )}
            {this.isAdmin() && (
              <>
                <div style={{ zIndex: 1 }} id="actionProvisioningDiv" className="c5 button">
                  <Button
                    id="actionProvisioning"
                    color={data.providerName ? 'danger' : 'success'}
                    disabled={!data.providerName && !data.canProvision}
                    onClick={() => {
                      if (data.canProvision) {
                        dispatch(
                          teleReleveActions.provision({
                            toRegister: !data.providerName ? [data.devEUI] : [],
                            toUnregister: data.providerName ? [data.devEUI] : [],
                          })
                        );
                      }
                    }}
                  >
                    {`${
                      data.providerName
                        ? t('telereleveinfo.text.deprovision_radio')
                        : t('telereleveinfo.text.provision_radio')
                    }   `}
                    {!data.canProvision && (
                      <Badge style={{ fontSize: '12px' }} color="warning" pill>
                        &#x26A0;
                      </Badge>
                    )}
                  </Button>
                  {!data.canProvision && (
                    <UncontrolledTooltip target="actionProvisioning">
                      {t('telereleveinfo.text.provision_impossible')} <br />
                      {t('telereleveinfo.text.missing_informations')}
                    </UncontrolledTooltip>
                  )}
                  {data.providerName && !data.canProvision && (
                    <UncontrolledPopover trigger={'legacy'} placement="top" target="actionProvisioning">
                      <PopoverHeader style={{ color: 'red' }}>&#x26A0; Provisioning impossible</PopoverHeader>
                      <PopoverBody style={{ display: 'grid' }}>
                        <p>{t('telereleveinfo.text.reprovision_impossible_message')}</p>
                        <Button
                          color="danger"
                          onClick={() =>
                            dispatch(
                              teleReleveActions.provision({
                                toRegister: !data.providerName ? [data.devEUI] : [],
                                toUnregister: data.providerName ? [data.devEUI] : [],
                              })
                            )
                          }
                        >
                          {t('all.button.confirm')}
                        </Button>
                      </PopoverBody>
                    </UncontrolledPopover>
                  )}
                </div>
                {!data.canProvision && !data.providerName && (
                  <div>
                    <Alert color="warning" style={{ marginBottom: 0 }}>
                      {t('telereleveinfo.text.provision_impossible')} <br />
                      {t('telereleveinfo.text.missing_informations')}
                    </Alert>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { pdis, meters, radios, locations, telereleve, authentication } = state;
  const { user } = authentication;

  return {
    pdis,
    meters,
    radios,
    locations,
    telereleve,
    user,
  };
}

const TeleReveleInfo = connect(mapStateToProps)(TeleReleveInfo);
const tr = withTranslation()(TeleReveleInfo);
export default tr;
