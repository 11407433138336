import React from 'react';

const SvgFrequence = (props: any) => (
  <svg viewBox="0 0 123.8 69.4" {...props}>
    <g id="Calque_2" />
    <g id="Calque_1">
      <g>
        <g>
          <path
            fill={props.fill}
            d="M111.9,28.5c-5.3,0-9.8,3.6-11.3,8.4H89.1c-1.4,0-2.6,0.9-3.1,2.1l-4.6,10.1l-8.6-20.3
				c-0.5-1.4-1.9-2.2-3.3-2.1c-1.4,0-2.6,1-3.1,2.2l-8.1,22.7L51.5,2.9C51.3,1.4,50,0.2,48.4,0c-1.5-0.2-3.1,0.7-3.6,2.2L31.6,37.8
				h-8.2c-1.2-5.3-5.8-9.1-11.5-9.1C5.3,28.7,0,34,0,40.5s5.3,11.8,11.8,11.8c5,0,9.3-3.1,11-7.6h11c1.4,0,2.7-0.9,3.3-2.2l9.3-25.1
				l7.4,48.9c0.2,1.5,1.5,2.9,3.1,2.9c0.5,0,2.6,0.2,3.6-2.2l9.8-27.5l8.1,19.4c0.5,1.2,1.7,2.1,3.1,2.1s2.6-0.9,3.1-2.1l6.9-15.3
				h9.3c1.5,4.8,6,8.4,11.3,8.4c6.5,0,11.8-5.3,11.8-11.8C123.8,33.8,118.5,28.5,111.9,28.5z M12,45.3c-2.7,0-5-2.2-5-5s2.2-5,5-5
				s5,2.2,5,5S14.8,45.3,12,45.3z M111.9,45.3c-2.7,0-5-2.2-5-5s2.2-5,5-5c2.7,0,5,2.2,5,5S114.7,45.3,111.9,45.3z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgFrequence;
