const teleReleveConstants = {
  GET_AVAILABLE_REQUEST: 'GET_AVAILABLE_REQUEST',
  GET_AVAILABLE_SUCCESS: 'GET_AVAILABLE_SUCCESS',
  GET_AVAILABLE_FAILURE: 'GET_AVAILABLE_FAILURE',

  GET_GATEWAY_REQUEST: 'GET_GATEWAY_REQUEST',
  GET_GATEWAY_SUCCESS: 'GET_GATEWAY_SUCCESS',
  GET_GATEWAY_FAILURE: 'GET_GATEWAY_FAILURE',

  PROVISION_REQUEST: 'PROVISION_REQUEST',
  PROVISION_SUCCESS: 'PROVISION_SUCCESS',
  PROVISION_FAILURE: 'PROVISION_FAILURE',

  PROVISION_UPDATE_PROGRESS_REQUEST: 'PROVISION_UPDATE_PROGRESS_REQUEST',
  PROVISION_UPDATE_PROGRESS_SUCCESS: 'PROVISION_UPDATE_PROGRESS_SUCCESS',
  PROVISION_UPDATE_PROGRESS_FAILURE: 'PROVISION_UPDATE_PROGRESS_FAILURE',
  PROVISION_UPDATE_REQUEST: 'PROVISION_UPDATE_REQUEST',
  PROVISION_UPDATE_SUCCESS: 'PROVISION_UPDATE_SUCCESS',
  PROVISION_UPDATE_FAILURE: 'PROVISION_UPDATE_FAILURE',

  CLEAR: 'TELE_RELEVE_CLEAR',
};

export default teleReleveConstants;
