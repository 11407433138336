import React from 'react';

const SvgPdiVert = (props: any) => (
  <svg viewBox="0 0 12.97 17.96" {...props}>
    <g id="pdi_vert_svg__Calque_2" data-name="Calque 2">
      <g id="pdi_vert_svg__Calque_2-2" data-name="Calque 2">
        <path fill={props.fill} d="M13 18H0V0h13zM1.47 16.49h10v-15h-10z" />
        <path
          fill={props.fill}
          d="M8.49 18h-4v-5.15h4zm-2.55-1.5H7v-2.19H5.94zM3.55 3.02h2.57V6.7H3.55zM6.85 3.02h2.57V6.7H6.85zM3.55 7.44h2.57v3.68H3.55zM6.85 7.44h2.57v3.68H6.85z"
        />
      </g>
    </g>
  </svg>
);

export default SvgPdiVert;
