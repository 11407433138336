import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';

interface Props {
  data: {
    labels: Array<string>;
    datasets: any;
  };
  showLegend: boolean;
}

class BestDateComponent extends Component<Props, any> {
  render() {
    const { data, showLegend, t } = this.props;

    data.datasets.map((dataset: any) => {
      console.log(dataset);
      if (!t(`alarm.type.${dataset.label.toLowerCase()}`).includes('Unknown')) {
        const newData = dataset;
        newData.label = t(`alarm.type.${dataset.label.toLowerCase()}`);
        return newData;
      }
      return dataset;
    });

    const dataChart = {
      labels: data.labels,
      datasets: data.datasets,
    };

    return (
      <div>
        <Bar
          data={dataChart}
          height={400}
          options={{
            scales: {
              xAxes: [
                {
                  barThickness: data.labels.length > 20 ? 10 : 40,
                  stacked: true,
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  stacked: true,
                  ticks: {
                    beginAtZero: true,
                    display: false,
                  },
                  type: 'linear',
                },
              ],
            },
            emptyOverlay: {
              message: t('mask_alarm.text.no_alarm_masked'),
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              position: 'bottom',
              display: showLegend,
            },
            plugins: {
              t1: false,
              datalabels: {
                display: false,
              },
            },
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(BestDateComponent);
