import React, { PureComponent } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import SvgCroixNoir from '../SvgComponents/croixNoir';
import { UncontrolledTooltip } from 'reactstrap';
import getC from '../TourneeFiches/Picto_correspondance';
import { withTranslation } from 'react-i18next';

export interface Props {
  clusterMeter: Array<ClusterMeter>;
  closePopup: Function;
  onClickMeter: Function;
  meterSelected: number;
  t: Function;
}

export interface ClusterMeter {
  id: number;
  meterSerial: string;
  meterState: string;
}

export interface State {
  currentTablePage: number;
}

class ClusterMeterList extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentTablePage: 0,
    };
  }

  render() {
    const { clusterMeter, closePopup, onClickMeter, meterSelected, t } = this.props;
    const maxPerPage = 9;
    console.log('CLusterMeterList : ', clusterMeter);
    return (
      <div className="clusterMeterList" style={{ paddingBottom: clusterMeter.length > maxPerPage ? '50px' : 0 }}>
        <div
          id="closeClusterMeterList"
          className="close-infomap-container"
          style={{
            borderRadius: '50%',
            height: '25px',
            width: '25px',
            cursor: 'pointer',
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          onClick={() => closePopup()}
        >
          <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <span className="absoluteCentered" style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
              <SvgCroixNoir height="1.2em" fill="#000" />
            </span>
          </div>
          <UncontrolledTooltip
            placement="bottom"
            target="closeClusterMeterList"
            dangerouslySetInnerHTML={{
              __html: t('cluster_meter_list.text.close_meter_list', { interpolation: { escapeValue: false } }),
            }}
          />
        </div>
        <BootstrapTable
          id="pdi-tab tableMeterCluster"
          keyField="id"
          style={{ height: '100%' }}
          data={clusterMeter}
          key={Math.floor(Math.random() * Math.floor(1024))}
          rowEvents={{
            onClick: (e, row, rowIndex) => {
              onClickMeter(row.id);
            },
          }}
          rowStyle={(row, rowIndex) => {
            return {
              backgroundColor: row.id === meterSelected ? '#31c6b3' : 'white',
            };
          }}
          columns={[
            {
              dataField: 'meterSerial',
              text: t('all.meter.meter_serial'),
              classes: 'crystalList-column clusterListColumn',
              sort: true,
              filter: textFilter({ placeholder: t('all.filter.filter_by_serial') }),
              headerStyle: () => ({ width: '160px' }),
              formatter: (value: string, row: ClusterMeter) => {
                return (
                  <>
                    <span className="cell-svg">{getC(row.meterState)}</span>
                    <span className="cell-meterSerial">{row.meterSerial}</span>
                  </>
                );
              },
            },
          ]}
          bootstrap4
          bordered={false}
          hover
          filter={filterFactory()}
          headerClasses="crystalList-column"
          rowClasses="clickable"
          pagination={
            clusterMeter.length > maxPerPage
              ? paginationFactory({
                  custom: false,
                  sizePerPage: maxPerPage,
                  paginationSize: 3,
                  sizePerPageList: [],
                })
              : null
          }
        />
      </div>
    );
  }
}

const tr = withTranslation()(ClusterMeterList);
export default tr;
