import React from 'react';

const SvgBurgerHeader = (props: any) => (
  <svg viewBox="0 0 25.08 18.28" {...props}>
    <g id="burger_header_svg__Calque_2" data-name="Calque 2">
      <g id="burger_header_svg__Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          d="M1.08 2.15H24A1.08 1.08 0 0 0 24 0H1.08a1.08 1.08 0 1 0 0 2.15zM1.08 10.21H24a1.08 1.08 0 0 0 0-2.15H1.08a1.08 1.08 0 1 0 0 2.15zM1.08 18.28H24a1.08 1.08 0 0 0 0-2.15H1.08a1.08 1.08 0 1 0 0 2.15z"
        />
      </g>
    </g>
  </svg>
);

export default SvgBurgerHeader;
