import React from 'react';

const SvgFraudeVert = (props: any) => (
  <svg viewBox="0 0 18.46 20.78" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M9.23,0A9.1,9.1,0,0,0,0,8.94V19.36a1.43,1.43,0,0,0,1.42,1.42H4.79a1.42,1.42,0,0,0,1.42-1.42V11.19c0-2.27,1-4.72,3-4.72s3,2.45,3,4.72v8.17a1.43,1.43,0,0,0,1.42,1.42H17a1.43,1.43,0,0,0,1.42-1.42V8.94A9.1,9.1,0,0,0,9.23,0Zm-4,19.36a.44.44,0,0,1-.43.44H1.42A.44.44,0,0,1,1,19.36V16.15H5.22Zm12.25,0a.44.44,0,0,1-.43.44H13.67a.44.44,0,0,1-.44-.44V16.15h4.24Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgFraudeVert;
