import bookmarkConstants from '../_constants/bookmark.constants';
import { action } from '../_interfaces/action';

export default function locations(state = {}, action: action) {
  switch (action.type) {
    case bookmarkConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case bookmarkConstants.GETALL_SUCCESS:
      return {
        items: action.bookmarks,
      };
    case bookmarkConstants.GETALL_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des favoris",
      };
    case bookmarkConstants.GET_ALL_HOME_REQUEST:
      return {
        loading: true,
      };
    case bookmarkConstants.GET_ALL_HOME_SUCCESS:
      return {
        items: action.bookmarks,
      };
    case bookmarkConstants.GET_ALL_HOME_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des favoris",
      };
    case bookmarkConstants.SAVE_REQUEST:
      return {
        loading: true,
      };
    case bookmarkConstants.SAVE_SUCCESS:
      return {
        items: action.bookmarks.concat(action.savedBookmark),
      };
    case bookmarkConstants.SAVE_FAILURE:
      return {
        error: 'Impossible de sauvegarder le favoris',
      };
    case bookmarkConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case bookmarkConstants.DELETE_SUCCESS:
      return {
        ...state,
      };
    case bookmarkConstants.DELETE_FAILURE:
      return {
        error: 'La suppression du favoris a échouée',
      };
    case bookmarkConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
