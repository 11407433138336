import React from 'react';
import { Button, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import Stats from './Stats';
import translate from '../_helpers/locale-helpers';
import { locales } from '../_interfaces/reducers';
import { dataSourceProperty } from '../_entities/template';

interface State {
  property: any;
  properties: Array<Object>;
}

/**
 * @class DisplayValueChooserComponent
 * @extends {React.Component<Props, State>}
 */
class DisplayValueChooserComponent extends React.Component<any, State> {
  /**
   * Met à jour le state si l'attribut datasource du formulaire
   * contient quelque chose
   *
   * @static
   * @param {Props} props
   * @param {State} state
   * @returns {State} Le nouveau state
   * @method getDerivedStateFromProps
   * @memberof DisplayValueChooserComponent
   */
  static getDerivedStateFromProps(props: any, state: State) {
    const { form } = props;
    let prop: any = state.property;
    if (typeof form.datasource !== 'undefined' || form.datasource != null) {
      const propExists = form.datasource.dataSourceProperty.find((property: dataSourceProperty) => {
        return property.displayValue === prop.displayValue;
      });
      if (!propExists) {
        prop = form.datasource.dataSourceProperty[0];
      }
      return {
        properties: form.datasource.dataSourceProperty,
        property: prop,
      };
    }

    return {
      properties: state.properties,
      property: prop,
    };
  }

  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof DisplayValueChooserComponent
   */
  constructor(props: any) {
    super(props);
    this.send = this.send.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.validate = this.validate.bind(this);
    this.state = {
      properties: [],
      property: {},
    };
  }

  /**
   * Gère le passage à l'étape précédente
   *
   * @method validate
   * @memberof DisplayValueChooserComponent
   */
  validate = () => {
    const { previousStep } = this.props;
    previousStep();
  };

  /**
   * Gère le passage à l'étape suivante et la mise à jour
   * du template
   *
   * @param {Object} e Evènement
   * @method send
   * @memberof DisplayValueChooserComponent
   */
  send(e: any) {
    e.preventDefault();
    const { update, nextStep } = this.props;
    const { property } = this.state;
    update('property', property);
    nextStep();
  }

  /**
   * Gère le changement des propriétés du widget
   *
   * @param {Object} event Evènement
   * @method handleOptionChange
   * @memberof DisplayValueChooserComponent
   */
  handleOptionChange(event: any) {
    const { properties } = this.state;
    const finded = properties.filter((item: any) => event.target.value === item.displayValue);
    if (finded && finded.length > 0) {
      this.setState({
        property: finded[0],
      });
    }
  }

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof DisplayValueChooserComponent
   */
  render() {
    const { isActive, locales } = this.props;
    const { property, properties } = this.state;

    if (!isActive) return null;
    return (
      <div>
        <h3>Choix de la valeur à afficher</h3>
        {properties && (
          <Form onSubmit={this.send}>
            <div className="datasourceChoices">
              {properties.map((item: any) => (
                <div className="templateChoices" style={{ fontSize: '22px' }} key={item.displayValue}>
                  <FormGroup check>
                    <Label check>
                      {' '}
                      <input
                        type="radio"
                        id={item.displayValue}
                        name={item.displayValue}
                        value={item.displayValue}
                        onChange={this.handleOptionChange}
                        checked={property.displayValue === item.displayValue}
                      />{' '}
                      {translate('fr', 'displayValue', item.displayValue, locales.locale)} -{' '}
                      {translate('fr', 'unit', item.displayUnit, locales.locale)}
                    </Label>
                  </FormGroup>
                </div>
              ))}
            </div>
            <div className="bottomChoice">
              <Row>
                <Col md="4">
                  <Stats {...this.props} previousStep={this.validate} />
                </Col>
                <Col md="8" className="text-right">
                  <Button color="primary">Continuer</Button>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { locales } = state;
  return {
    locales,
  };
}

const DisplayValueChooserConnectedComponent = connect(mapStateToProps)(DisplayValueChooserComponent);
export default DisplayValueChooserConnectedComponent;
