const vmeterConstants = {
  GETALL_REQUEST: 'VMETERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'VMETERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'VMETERS_GETALL_FAILURE',

  GETINFO_REQUEST: 'VMETERS_GETINFO_REQUEST',
  GETINFO_SUCCESS: 'VMETERS_GETINFO_SUCCESS',
  GETINFO_FAILURE: 'VMETERS_GETINFO_FAILURE',

  GETFULLINFO_REQUEST: 'VMETERS_GETFULLINFO_REQUEST',
  GETFULLINFO_READS_SUCCESS: 'GETFULLINFO_READS_SUCCESS',
  GETFULLINFO_CONSO_SUCCESS: 'GETFULLINFO_CONSO_SUCCESS',
  GETFULLINFO_ALARMS_SUCCESS: 'GETFULLINFO_ALARMS_SUCCESS',
  GETFULLINFO_LASTREADS_SUCCESS: 'GETFULLINFO_LASTREADS_SUCCESS',
  GETFULLINFO_FAILURE: 'VMETERS_GETFULLINFO_FAILURE',

  GETALLINFO_REQUEST: 'VMETERS_GETALLINFO_REQUEST',
  GETALLINFO_SUCCESS: 'VMETERS_GETALLINFO_SUCCESS',
  GETALLINFO_FAILURE: 'VMETERS_GETALLINFO_FAILURE',

  GETSTATES_REQUEST: 'VMETERS_GETSTATES_REQUEST',
  GETSTATES_SUCCESS: 'VMETERS_GETSTATES_SUCCESS',
  GETSTATES_FAILURE: 'VMETERS_GETSTATES_FAILURE',

  GETALARMS_REQUEST: 'VMETERS_GETALARMS_REQUEST',
  GETALARMS_SUCCESS: 'VMETERS_GETALARMS_SUCCESS',
  GETALARMS_FAILURE: 'VMETERS_GETALARMS_FAILURE',

  GETDATA_SUCCESS: 'VMETERS_GETDATA_SUCCESS',
  GETDATA_FAILURE: 'VMETERS_GETDATA_FAILURE',

  CREATE_REQUEST: 'VMETERS_CREATE_REQUEST',
  CREATE_SUCCESS: 'VMETERS_CREATE_SUCCESS',
  CREATE_FAILURE: 'VMETERS_CREATE_FAILURE',

  EDIT_REQUEST: 'VMETERS_EDIT_REQUEST',
  EDIT_SUCCESS: 'VMETERS_EDIT_SUCCESS',
  EDIT_FAILURE: 'VMETERS_EDIT_FAILURE',

  DELETE_REQUEST: 'VMETERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'VMETERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'VMETERS_DELETE_FAILURE',

  CLEAR: 'VMETERS_CLEAR',
};

export default vmeterConstants;
