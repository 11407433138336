import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { alert } from '../_interfaces/reducers';
import { REGEXP } from '../_helpers';
import React from 'react';

interface Props {
  isOpen: boolean;
  edit: Function;
  className: string | undefined;
  alert: alert;
  updated: boolean;
  password: string;
  passwordCopy: string;
  handleValidSubmit: Function;
  handleInvalidSubmit: Function;
}

/**
 * Formulaire de changement de mot de passe utilisateur
 *
 * @method PasswordChangeRenderWithForm
 * @param props Props du composant
 */
export const PasswordChangeRenderWithForm: React.FunctionComponent<Props & {}> = props => {
  const {
    isOpen,
    edit,
    className,
    handleInvalidSubmit,
    handleValidSubmit,
    alert,
    updated,
    password,
    passwordCopy,
    t,
  } = props;
  return (
    <Modal isOpen={isOpen} toggle={edit as any} className={className}>
      <ModalHeader toggle={edit as any}>Nouveau mot de passe</ModalHeader>
      <AvForm onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit}>
        <ModalBody>
          <p>{t('user.text.please_fill_password')}</p>
          <div>{alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}</div>
          {!updated && (
            <div>
              <AvField
                name="password"
                value={password}
                label={t('user.text.new_password')}
                type="password"
                required
                validate={{
                  pattern: { value: REGEXP },
                  minLength: {
                    value: 8,
                    errorMessage:
                      'La taille minimale du champs est de 8 caractères, contenir au moins une majsucule et un chiffre',
                  },
                }}
                errorMessage="Le mot de passe doit faire plus de 8 caractères,  contenir au moins une majsucule et un chiffre"
              />
              <AvField
                name="passwordCopy"
                value={passwordCopy}
                label={t('user.text.rewrite_new_password')}
                type="password"
                required
                validate={{
                  minLength: {
                    value: 8,
                    errorMessage: t('add_user.field.minimum_x_size'),
                  },
                  match: { value: 'password', errorMessage: t('user.text.password_not_match') },
                }}
                errorMessage="Le mot de passe doit faire plus de 8 caractères et doit être la copie du mot de passe précédent"
              />
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          {!updated && (
            <Button color="danger" onClick={edit as any}>
              {t('all.button.cancel')}
            </Button>
          )}
          {!updated && <Button color="primary">{t('all.button.register')}</Button>}
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};
