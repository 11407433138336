import { authHeader, authHeaderFile, handleResponse } from '../_helpers';

const uri = '/api/facturation/import';

async function importFile(data: FormData) {
  const requestOptions = {
    method: 'POST',
    headers: authHeaderFile(),
    body: data,
  };
  let response = await fetch(`${uri}/`, requestOptions);
  return handleResponse(response);
}

async function analyseFile(data: FormData, headerSize: null | number) {
  const headers = authHeaderFile();
  headers.append('headerSize', headerSize ? headerSize.toString() : '');
  const requestOptions = {
    method: 'POST',
    headers,
    body: data,
  };
  let response = await fetch(`${uri}/analyse`, requestOptions);
  return handleResponse(response);
}

async function importSilexFile(data: FormData, gestionnaireId: number) {
  const requestOptions = {
    method: 'POST',
    headers: authHeaderFile(),
    body: data,
  };
  const response = await fetch(`/api/silex/import/${gestionnaireId}`, requestOptions);
  return handleResponse(response);
}

async function getErrorLastImport() {
  const requestOptions = {
    method: 'GET',
    headers: authHeaderFile(),
  };
  const response = await fetch(`/api/silex/import/error`, requestOptions);
  return handleResponse(response);
}

async function importLoraFile(
  data: FormData,
  radioSerialColumnIndex: number,
  devEUIColumnIndex: number,
  loraSerialColumnIndex: number,
  appEUIColumnIndex: number,
  appKeyColumnIndex: number
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeaderFile(),
    body: data,
  };
  const response = await fetch(
    `/api/telereleve/import/lorafile?radioSerialColumnIndex=${radioSerialColumnIndex ||
      -1}&devEUIColumnIndex=${devEUIColumnIndex || -1}&loraSerialColumnIndex=${loraSerialColumnIndex ||
      -1}&appEUIColumnIndex=${appEUIColumnIndex || -1}&appKeyColumnIndex=${appKeyColumnIndex || -1}`,
    requestOptions
  );
  return handleResponse(response);
}

async function importMessagesFile(data: FormData) {
  const requestOptions = {
    method: 'POST',
    headers: authHeaderFile(),
    body: data,
  };
  let response = await fetch(`/api/silex/import/message`, requestOptions);
  return handleResponse(response);
}

async function importMarquagesFile(data: FormData) {
  const requestOptions = {
    method: 'POST',
    headers: authHeaderFile(),
    body: data,
  };
  let response = await fetch(`/api/silex/import/marquage`, requestOptions);
  return handleResponse(response);
}

async function testImport() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  let response = await fetch(`${uri}/dryrun`, requestOptions);
  return handleResponse(response);
}

async function confirmImport() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  let response = await fetch(`${uri}/confirm`, requestOptions);
  return handleResponse(response);
}

async function isRunning() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`${uri}/isRunning`, requestOptions);
  return handleResponse(response);
}

async function restore() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`${uri}/restore`, requestOptions);
  return handleResponse(response);
}

async function reworkedImportSilexFile(file: FormData, binaryArray: boolean[], gestionnaireID: Number) {
  const headers = authHeaderFile();
  headers.append('selectedLines', binaryArray.toString());
  const requestOptions = {
    method: 'POST',
    headers,
    body: file,
  };
  let response = await fetch(`/api/silex/import/${gestionnaireID}/generic`, requestOptions);
  return handleResponse(response);
}

async function downloadReport() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  const response = await fetch(`${uri}/downloadReport`, requestOptions);
  if (response.ok === false) {
    return handleResponse(response);
  }
  return response.blob().then((blob: any) => ({ content: blob, filename: response.headers.get('export-name') }));
}

const importService = {
  isRunning,
  restore,
  importFile,
  analyseFile,
  testImport,
  confirmImport,
  importSilexFile,
  importMessagesFile,
  importMarquagesFile,
  importLoraFile,
  getErrorLastImport,
  reworkedImportSilexFile,
  downloadReport,
};

export default importService;
