import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { User } from '../_entities/user';
import { bookmarkActions, meterActions } from '../_actions';
import DisplayBookmark from '../Bookmarks/DisplayBookmarks';
import { isMobile } from 'react-device-detect';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Mapbox from '../Mapbox/components/Mapbox';

interface Props {
  user: User;
  t: any;
}

/**
 * Page principale de l'application
 *
 * @class HomePageComponent
 * @extends Component
 */
export class HomePageComponent extends React.Component<any, any> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      buttonText: 'Normal',
    };
  }

  componentDidMount() {
    const { dispatch, history } = this.props;
    dispatch(bookmarkActions.getAllHome());
    if (isMobile && false) {
      history.push('/saphir/synchro');
    }
  }

  render() {
    const { user, bookmarks, t } = this.props;
    return (
      <div className="col-md-12">
        <h1>
          {t('home_page.title.hello')} {user.firstName} {user.lastName}
        </h1>
        <h2>{t('home_page.subtitle.your_dashboard')}</h2>
        {bookmarks.items &&
          bookmarks.items.map(b => (
            <DisplayBookmark
              bookmarks={[]}
              dashboards={b.dashboards}
              location={b.location}
              user={user}
              displayTitle={true}
            />
          ))}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, bookmarks } = state;
  const { user } = authentication;
  return {
    user,
    bookmarks,
  };
}

const mapping: any = connect(mapStateToProps)(HomePageComponent);

const HomePage = withRouter(mapping);
const tr = withTranslation()(HomePage);
export default tr;
