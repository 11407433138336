import React from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js';

Chart.NewLegend = Chart.Legend.extend({
  afterFit: function() {
    this.height = 0;
  },
});

export default function LineChart(props) {
  const { datasets, labels, min, max } = props;
  const data = {
    labels,
    datasets,
  };
  const options = {
    plugins: {
      t1: false,
      datalabels: {
        display: false,
      },
    },
    legend: {
      display: false,
    },
    spanGaps: true,
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0,
      },
    },
  };
  return <Line data={data} options={options} />;
}
