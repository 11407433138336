import React from 'react';

const SvgRetourEauBleu = (props: any) => (
  <svg viewBox="0 0 14.64 21.01" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M11.48,16.14v-9l1.84.75a1,1,0,0,0,1.15-1.43L10.24.41a1,1,0,0,0-1.6.05L4.78,6.81A1,1,0,0,0,4.89,8,1,1,0,0,0,6,8.17l1.76-.83v8.79A1.19,1.19,0,0,1,6.6,17.31H4.87A1.17,1.17,0,0,1,3.7,16.13V1.7A1.58,1.58,0,0,0,2.13.12H1.57A1.57,1.57,0,0,0,0,1.7V16.14A4.87,4.87,0,0,0,4.87,21H6.6a4.88,4.88,0,0,0,4.88-4.87Z"
        />
      </g>
    </g>
  </svg>
);
export default SvgRetourEauBleu;
