import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Col, UncontrolledTooltip } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import GestionTable from '../_components/TableauGestion';
import TourneesVertes from '../SvgComponents/TourneesVertes';
import synchroAction from '../_actions/synchro.actions';
import TableRow from '../_components/TableRow';
import EditionBleu from '../SvgComponents/EditionBleu';
import EditDevice from '../SynchroResources/EditDevice';
import Loading from '../_animations/Loading';
import { userActions } from '../_actions';
import PoubelleBleu from '../SvgComponents/PoubelleBleu';
import StepPopover from '../_components/StepPopover';

class PortablesResources extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      editionOpen: false,
      deletionOpen: false,
      confirmDeletionOpen: false,
      deviceNames: [],
      deviceUpdate: {},
      rowClick: null,
      selectedDevice: null,
    };
    this.editName = this.editName.bind(this);
  }

  componentDidMount() {
    const { dispatch, match, users, authentication } = this.props;
    const { params } = match;
    dispatch(synchroAction.getAllRoundFull(params.GestionnaireId));
    dispatch(synchroAction.getAllDevice(params.GestionnaireId));
    dispatch(userActions.get(_.get(this.props, 'user.id')));
  }

  edit = (e: Object, device: any) => {
    const { dispatch, match } = this.props;
    const { deviceUpdate } = this.state;
    const { params } = match;
    if (device) {
      dispatch(
        synchroAction.updateDeviceName(
          { name: device.name, facturationCode: device.facturationCode },
          deviceUpdate.id,
          params.GestionnaireId
        )
      );
    }
    this.setState({
      editionOpen: false,
    });
  };

  isEdited = () => {
    const { editionOpen } = this.state;
    this.setState({
      editionOpen: false,
    });
  };

  editName(e: any, row: any) {
    const { editionOpen } = this.state;
    this.setState({
      editionOpen: true,
      deviceUpdate: row,
    });
  }

  PopIsOver = props => {
    const { t, synchro, match, users, dispatch } = this.props;
    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const { row } = props;
    const { params } = match;
    this.setState({ selectedDevice: row.id });

    const rowFromProps = _.entries(synchro.devices).map(el => el[1]);
    const rowIDs = rowFromProps.map(row => row.id);

    const handleDeletePhone = (e: any) => {
      e.stopPropagation();
      console.log('handleDeletePhone', row);
      dispatch(synchroAction.deleteDevice(row.id, params.GestionnaireId));
      setConfirm(false);
      setOpen(false);
      this.setState({ deletionOpen: false, selectedDevice: null });
    };

    const userRole = _.defaultTo(_.get(users, 'fetchedUser.role.name'), 'none');
    const gestionnaireDeviceDeletionPermission =
      _.defaultTo(
        _.get(
          _.find(
            _.get(users, 'fetchedUser.gestionnaires'),
            gestionnaire => gestionnaire.gestionnaireId == params.GestionnaireId
          ),
          'deviceDeletionAllowed'
        ),
        false
      ) ||
      userRole === 'DIOPTASE' ||
      userRole === 'SUPERADMIN' ||
      userRole === 'ADMIN';

    return (
      <div
        id={`deletePhone${row.id}`}
        className="clickable round"
        role="presentation"
        onClick={event => {
          event.stopPropagation();
          rowIDs.forEach(it => {
            if (document.getElementById(`deletePhonePopover${it}`)) {
              if (it === row.id) {
                document.getElementById(`deletePhonePopover${it}`).parentElement.style.display = 'block';
              } else {
                document.getElementById(`deletePhonePopover${it}`).parentElement.style.display = 'none';
              }
            }
          });
          setOpen(true);
        }}
      >
        <PoubelleBleu height="1em" width="1em" />
        <StepPopover
          id={`deletePhonePopover${row.id}`}
          target={`deletePhone${row.id}`}
          open={open}
          onClose={() => {
            setOpen(false);
            this.setState({ selectedDevice: null });
          }}
          confirm={confirm}
          setConfirm={setConfirm}
          withTwoSteps={_.get(row, 'affectList').length > 0}
          t={t}
          permission={gestionnaireDeviceDeletionPermission}
          action={handleDeletePhone}
          title={t('edit_device.main_title.delete_phone')}
          warningText={
            <>
              <p style={{ margin: '10px', textAlign: 'center' }}>
                {_.get(row, 'affectList').length === 1
                  ? t('edit_device.modal.text_warning_confirm_round')
                  : t('edit_device.modal.text_warning_confirm_x_round', {
                      count: _.get(row, 'affectList').length,
                    })}
              </p>
              <p>{t('edit_device.modal.text_warning_confirm')}</p>
            </>
          }
          confirmText={t('edit_device.modal.text_confirm')}
        />
        {!open && (
          <UncontrolledTooltip target={`deletePhone${row.id}`}>{t('all.device.delete_device')}</UncontrolledTooltip>
        )}
      </div>
    );
  };

  render() {
    // ? const linkToLinkPdi = `/locations/${locationId}/pdi/link`;
    // ? const unlinkToLinkPdi = `/locations/${locationId}/pdi/unlink`;
    const { match } = this.props;
    const { params } = match;
    const { synchro, t, users } = this.props;
    const { editionOpen, deviceUpdate, deletionOpen, clickX, clickY } = this.state;
    const userRole = _.defaultTo(_.get(users, 'fetchedUser.role.name'), 'none');
    const gestionnaireDeviceDeletionPermission =
      _.defaultTo(
        _.get(
          _.find(
            _.get(this.props, 'users.fetchedUser.gestionnaires'),
            gestionnaire => gestionnaire.gestionnaireId == params.GestionnaireId
          ),
          'deviceDeletionAllowed'
        ),
        false
      ) ||
      (userRole === 'DIOPTASE' || userRole === 'SUPERADMIN' || userRole === 'ADMIN');
    const svg = <TourneesVertes height="1em" width="1em" fill="#31c6b3" className="svg-table-title" />;
    const newTasks = _.entries(synchro.devices).map(el => el[1]);
    const { Fragment } = React;

    const columns = [
      {
        dataField: 'content',
        text: t('all.column_text.name'),
        headerStyle: () => ({ width: '27%' }),
      },
      {
        dataField: 'serial',
        text: t('all.meter.serial'),
        headerStyle: () => ({ width: '27%' }),
      },
      {
        dataField: 'factuCode',
        text: t('columns.facturationCode'),
        headerStyle: () => ({ width: '10%' }),
      },
      {
        dataField: 'affectList',
        isDummyField: true,
        editable: false,

        align: 'center',
        headerStyle: () => ({ width: '31%' }),
        text: t('all.round.assigned_round_plural'),
        formatter: (cellContent: any, row: any) => {
          return <TableRow row={row} />;
        },
      },
      {
        align: 'center',
        style: {
          padding: 0,
          verticalAlign: 'middle',
        },
        headerStyle: () => ({ width: `${gestionnaireDeviceDeletionPermission ? '3%' : '6%'}` }),
        formatter: (cellContent: any, row: any) => {
          return (
            <Col md="4" style={{ padding: '0' }}>
              <Fragment>
                <div
                  id={`editPhone${row.id}`}
                  className="clickable round"
                  role="presentation"
                  onClick={e => this.editName(e, row)}
                >
                  <EditionBleu height="1em" width="1em" />
                  <UncontrolledTooltip placement="bottom" target={`editPhone${row.id}`}>
                    {' '}
                    {t('all.device.edit_device')}
                  </UncontrolledTooltip>
                </div>
              </Fragment>
            </Col>
          );
        },
      },
    ];
    if (gestionnaireDeviceDeletionPermission) {
      columns.push({
        align: 'center',
        style: {
          padding: 0,
          verticalAlign: 'middle',
        },
        headerStyle: () => ({ width: '3%' }),
        formatter: (cellContent: any, row: any) => {
          return (
            <Col md="4" style={{ padding: '0' }}>
              <Fragment>
                <this.PopIsOver row={row} />
              </Fragment>
            </Col>
          );
        },
      });
    }
    return (
      <div>
        {(!synchro.devicesUp || !synchro.roundsUp) && <Loading message="Chargement des portables ..." />}
        {newTasks && editionOpen && (
          <EditDevice device={deviceUpdate} isEdited={this.isEdited} edit={this.edit} isOpen={editionOpen} />
        )}
        <div className="container-tab-synchro">
          {newTasks && synchro.devicesUp && synchro.roundsUp && (
            <GestionTable
              columns={columns}
              data={newTasks}
              svgTitle={svg}
              titleTab={t('all.text.phone_plural')}
              link=""
              unlink=""
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { users, alert, synchro, gestionnaire, authentication } = state;
  const { user } = authentication;
  return {
    user,
    users,
    alert,
    synchro,
    gestionnaire,
  };
}

const mapping: any = connect(mapStateToProps)(PortablesResources);

const connectedUser = withRouter(mapping);
const tr = withTranslation()(connectedUser);
export default tr;
