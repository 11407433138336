import React, { Component } from 'react';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import SvgDoigtVert from '../SvgComponents/DoigtVert';
import FiltreVert from '../SvgComponents/FiltreVert';
import SvgMultiDragVert from '../SvgComponents/MultiDragVert';
import SvgMultiDrag from '../SvgComponents/MultiDragVert';
import { withTranslation } from 'react-i18next';

interface Props {
  handleMod: Function;
  activedMod: string;
  t: Function;
}

const itemList = [
  ['Selection', 'all.text.selection'],
  ['Filtres', 'all.filter.filter_plural'],
  ['Tous', 'all.text.all_plural'],
];

const svgList = [
  <SvgDoigtVert height={'2em'} fill={'#31c6b3'} />,
  <FiltreVert height={'2em'} fill={'#31c6b3'} />,
  <SvgMultiDrag height={'2em'} fill={'#31c6b3'} />,
];

class SelectionComponent extends Component<Props> {
  render() {
    const { handleMod, activedMod, t } = this.props;
    return (
      <div className="selection-component alarm-option-form filter-container">
        <div className="select-onglet flex-box">
          {itemList.map((mode: any, index: number) => (
            <div
              className="c32"
              style={{
                backgroundColor: activedMod === mode[0] ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)',
                borderBottom: activedMod === mode[0] ? 'none' : '1px solid lightgrey',
              }}
              onClick={() => handleMod(mode[0])}
            >
              <div className={'space-svg'}>{svgList[index]}</div>
              <h3 style={{ color: activedMod === mode ? '#31c6b3' : 'black' }}>{t(mode[1])}</h3>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const tr = withTranslation()(SelectionComponent);
export default tr;
