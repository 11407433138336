import React, { ChangeEvent, MutableRefObject, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
//@ts-ignore
import mapboxgl, { Map } from '!mapbox-gl';
import mapboxdraw from '@mapbox/mapbox-gl-draw';
import MapboxDraw, { DrawSelectionChangeEvent } from '@mapbox/mapbox-gl-draw';
import MapboxglSpiderifier from 'mapboxgl-spiderifier';
import mapProperties from '../map/mapProperties';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { mapActions, mapboxActions } from '../../_actions';
import _ from 'lodash';
import allImages, { Image } from '../images/allImages';
import $ from 'jquery';
import lay from '../map/layers';
import * as turf from '@turf/turf';
import { Feature, FeatureCollection, GeoJSONObject, Geometry, Point, Properties } from '@turf/turf';
import { Button, Input, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import data from '../map/data';
import popups from '../map/popup';
import clusterConstruction from '../map/cluster';
import SelectedBand from '../../GoogleMap/SelectedBand';
import { withRouter } from 'react-router';
import events from '../map/events';
import { ErrorEvent, EventData, Popup } from 'mapbox-gl';
import { Gateway, GpsPosition, Props } from '../interface';
import homeMarkerPng from '../images/home/home_marker.png';

function mapStateToProps({ mapbox, maps }: any) {
  return { mapbox, maps };
}

function Mapbox(props: any) {
  const {
    initialMap,
    displayGateways = false,
    displayPopup = false,
    displayPolygons = false,
    displayGeojsonLayers = false,
    displayBandePdi = false,
    drawing = false,
    fullscreen = true,
    geolocation = true,
    filters = false,
    dispatch,
    t,
    history,
    match,
    style,
    uniqIcon,
    mapId = 'map',
    isRound = false,
    homeMarker,
    roundClickMarker,
    isRoundDetail,
    mapbox: { geojsonLayer, gateways, polygons },
    maps: { markers, count },
    selectedMeter,
    setSelectedMeter,
  } = props;
  const { baseLng, baseLat, baseZoom, myLocationEnabled } = initialMap;

  var deepEqual = require('deep-equal');
  const draw = useRef() as MutableRefObject<MapboxDraw>;
  const popup = useRef() as MutableRefObject<Popup>;
  const map = useRef() as MutableRefObject<Map>;

  const handleGatewayCircleRangeChange = (e: ChangeEvent<HTMLInputElement>) =>
    setGatewayCircleRangeOn(e.target.checked);
  const handleGatewayRangeChange = (e: ChangeEvent<HTMLInputElement>) => setGatewayRangeOn(e.target.checked);
  const handleCapturedChange = (e: ChangeEvent<HTMLInputElement>) => setCapturedFilter(e.target.checked);
  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => setLayerOn(e.target.checked);
  const [isGatewayCircleRangeOn, setGatewayCircleRangeOn] = useState<boolean>(false);
  const [displayCluster, setDisplayCluster] = useState({ id: null, display: 'display: block;', spiderify: false });
  const [polygonLayerIds, setPolygonLayerIds] = useState<Array<number>>([]);
  const [isGatewaySelected, setGatewaySelected] = useState<boolean>(false);
  const [isGatewayRangeOn, setGatewayRangeOn] = useState<boolean>(false);
  const [drawFeatureId, setDrawFeatureId] = useState<string | number>();
  const [polygonsArray, setPolygonsArray] = useState<Array<string>>([]);
  const [newDrawFeature, setNewDrawFeature] = useState<boolean>(false);
  const [capturedFilter, setCapturedFilter] = useState<boolean>(false);
  const [drawModeText, setDrawModeText] = useState<string>('cercle');
  const [lat, setLat] = useState<number>(baseLat);
  const [drawMode, setDrawMode] = useState<string>('polygon');
  const [lng, setLng] = useState<number>(baseLng);
  const [zoom, setZoom] = useState<number>(baseZoom || 10);
  const [isLayerOn, setLayerOn] = useState<boolean>(true);
  const [roundName, setRoundName] = useState<string>();
  const [markerEnter, setMarkerEnter] = useState(false);
  const [clusterization, setClusterization] = useState(true);
  const currentEnter = useRef(markerEnter);
  const currentDisplay = useRef(displayCluster);
  const currentMeter = useRef(selectedMeter);
  const isZooming = useRef(false);
  const clusterClickEvent = useRef(false);
  const isStyleLoaded = useRef(false);

  let spiderifier = new MapboxglSpiderifier(map.current, {
    customPin: true,
    animate: true,
    animationSpeed: 200,
    initializeLeg: initializeSpiderLeg,
    circleFootSeparation: 34,
    spiralFootSeparation: 25,
    circleSpiralSwitchover: 10,
    spiralLengthStart: 50,
    spiralLengthFactor: 1.6,
  });

  const update = () => {
    updateMarkerConditions(displayCluster);
  };

  useEffect(() => {
    displayGeojsonLayers && dispatch(mapboxActions.getGeojson());
    displayGateways && dispatch(mapboxActions.getGateways());
    displayPolygons && dispatch(mapboxActions.getPolygons());
    createMap();
    controls();
    map.current && displayPopup && createPopup();
    map.current.on('load', (e: EventData) => {
      updateMarkerConditions(displayCluster);
      isStyleLoaded.current = true;
    });
    map.current.on('render', update).on('idle', update);
    setHomeMarker();
    loadRoundMarkers();

    //events(map.current, 'mouseenter', 'cluster', popup.current, t, displayCluster);
  }, []);

  useEffect(() => {
    uniqIcon && loadUniqIcon();
  }, [uniqIcon]);

  const readMethodMap: any = {
    A: 'radio',
    T: 'telereleve',
    M: 'manual',
  };

  const getMarkerIconKey = (meter: any) => {
    const readMethod = _.get(meter, 'readData.method');
    if (['A', 'T'].includes(readMethod)) {
      return `${_.size(_.get(meter, 'readData.alarm')) > 0 ? 'purple' : 'blue'}_${readMethodMap[readMethod]}`;
    }
    return readMethod === 'M' ? 'green_manual' : `red_${_.size(_.get(meter, 'radioSerial')) > 0 ? 'radio' : 'manual'}`;
  };

  useEffect(() => {
    if (_.get(selectedMeter, 'gps.lng') && selectedMeter.id !== _.get(currentMeter, 'current.id')) {
      map.current.easeTo({ center: [selectedMeter.gps.lng, selectedMeter.gps.lat], zoom: 18 });
      const meterWithIcon = _.cloneDeep(selectedMeter);
      meterWithIcon.icon = getMarkerIconKey(selectedMeter);

      map.current.setLayoutProperty(
        'unclustered-point',
        'icon-image',
        ['match', ['id'], selectedMeter.id, `${meterWithIcon.icon}_selected`, ['get', 'ficheState']],
        { validate: false }
      );
    }
    currentMeter.current = selectedMeter;
    if (!selectedMeter && map.current.isStyleLoaded()) {
      map.current.setLayoutProperty('unclustered-point', 'icon-image', ['get', 'ficheState']);
    }
  }, [selectedMeter]);

  useEffect(() => {
    if (markers && markers.length > 0 && isStyleLoaded.current) {
      removeSourcesAndLayers();
      addMetersSourceAndLayers();
    }
  }, [zoom]);

  useEffect(() => {
    currentDisplay.current = displayCluster;
    if (displayCluster.id) {
      const doc: any = document.getElementById(`cluster-${displayCluster.id}`);
      if (doc) {
        doc.style.opacity = 0;
      }
    }
  }, [displayCluster]);

  useEffect(() => {
    events(map.current, 'mouseenter', 'cluster', popup.current, t, currentDisplay.current);

    map.current.on('mouseleave', 'cluster', e => {
      popup.current && popup.current.removeClassName(e.originalEvent.target.id);
    });
    map.current.on('mouseleave', 'unclustered-point', e => {
      setMarkerEnter(false);
      popup.current && popup.current.remove();
    });
    map.current &&
      map.current.on('mouseenter', 'unclustered-point', (e: any) => {
        setMarkerEnter(true);
      });
  }, [map && map.current]);

  useEffect(() => {
    currentEnter.current = markerEnter;
    updateMarkerConditions(displayCluster);
  }, [markerEnter]);

  useEffect(() => {
    if (displayCluster.id) {
      const doc: any = document.getElementById(`cluster-${displayCluster.id}`);
      if (doc && !doc.className.includes('hide')) {
        doc.classList.add('hide');
      }
    }
    /*
    map.current && geojsonLayer && displayGeojsonLayers && isLayerOn
      ? addSourceAndLayerFromBack()
      : removeGeojsonLayerAndSource(); */
    markers && gateways && gatewayRangeObject();
    //gateways && isGatewayRangeOn ? addGatewayRangeSourceAndLayers() : removeGatewayRangeLayerAndSource();
  });

  useEffect(() => {
    markers && map.current && map.current.isStyleLoaded() && addMetersSourceAndLayers();
    if (markers && map.current) {
      addPopup();
      if (!clusterClickEvent.current) {
        map.current.on('click', lay.name.cluster, mouseClick);
        map.current.on('zoomend', lay.name.cluster, (e: EventData) => {
          setZoom(map.current.getZoom());
          isZooming.current = false;
        });
        map.current.on('zoomstart', e => {
          if (currentDisplay.current.id) {
            spiderifier.unspiderfy();
            const doc: any = document.getElementById(`cluster-${currentDisplay.current.id}`);
            if (doc) {
              doc.style.opacity = 1;
            }
            setDisplayCluster({ id: null, display: 'display: block;', spiderify: false });
          }
        });
        map.current.on('movestart', () => {
          if (currentDisplay.current.id) {
            spiderifier.unspiderfy();
            const doc: any = document.getElementById(`cluster-${currentDisplay.current.id}`);
            if (doc) {
              doc.style.opacity = 1;
            }
            setDisplayCluster({ id: null, display: 'display: block;', spiderify: false });
          }
        });
        clusterClickEvent.current = true;
      }
      !isRoundDetail &&
        map.current.once('styledata', () => {
          if (markers.length > 0) {
            loadAndAddImage();
            addMetersSourceAndLayers();
          }
        });
      if (isRound) {
        ZoomAndMoveEvent();
        updateMarkerConditions(displayCluster);
      }
    }
  }, [markers]);

  useEffect(() => {
    displayGateways && gateways && sourceAndLayerAdder();
  }, [gateways]);

  useEffect(() => {
    displayPolygons && polygons && map.current && addPolygonsToMap();
  }, [polygons]);

  useEffect(() => {
    let gatewayRange = {};
    markers && gateways && (gatewayRange = data.gatewayRange(markers));
    gateways && !_.isEmpty(gatewayRange) && getMaxDistanceAndDrawCircle();
  }, [isGatewayCircleRangeOn]);

  useEffect(() => {
    if (filters) {
      capturedFilter ? addCapturedToMap() : removeCapturedToMap();
    }
  }, [capturedFilter]);

  let rendering = false;
  let visibleCluster: any;

  function updateMarkerConditions(display: any) {
    const features = map.current.querySourceFeatures(lay.name.meterSourceName);
    if (_.isEmpty(features)) {
      return false;
    }
    const seen = new Set();
    const filteredFeatures = features.filter((el: any) => {
      const duplicate = seen.has(el.id);
      seen.add(el.id);
      return !duplicate;
    });

    const featureProperties = _.map(filteredFeatures, (prop: GeoJSON.GeoJsonProperties) => {
      return prop!.properties;
    });

    if (
      !map.current.isZooming() &&
      !map.current.isMoving() &&
      (!rendering || !deepEqual(featureProperties, visibleCluster))
    ) {
      rendering = true;
      visibleCluster = featureProperties;
      if (!updateMarkers(filteredFeatures, display)) {
        rendering = false;
      }
    }
  }

  function ZoomAndMoveEvent() {
    spiderifier = new MapboxglSpiderifier(map.current, {
      customPin: true,
      animate: true,
      animationSpeed: 200,
      initializeLeg: initializeSpiderLeg,
      circleFootSeparation: 34,
      spiralFootSeparation: 25,
      circleSpiralSwitchover: 10,
      spiralLengthStart: 50,
      spiralLengthFactor: 1.6,
    });
  }

  function loadRoundMarkers() {
    if (isRound) {
      map.current &&
        map.current.on('load', () => {
          map.current && drawing && markers && addDrawToMap();
          markers && addMetersSourceAndLayers();
        });
    }
  }

  function setHomeMarker() {
    if (homeMarker) {
      const geojson = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [baseLng, baseLat],
        },
      };

      const el = document.createElement('div');
      el.className = 'marker';
      el.style.backgroundImage = `url(${homeMarkerPng})`;
      el.style.width = '35px';
      el.style.height = '53px';
      el.style.backgroundSize = '100%';
      new mapboxgl.Marker(el).setLngLat(geojson.geometry.coordinates).addTo(map.current);
    }
  }

  function loadUniqIcon() {
    map.current &&
      !isRound &&
      map.current.on('load', () => {
        map.current && drawing && markers && addDrawToMap();
        isRoundDetail && loadAndAddImage();
        if (uniqIcon && map.current) {
          const source = {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Point',
                coordinates: [baseLng, baseLat],
              },
            },
          };
          const layer = {
            id: 'uniq-meter',
            source: 'uniqMeter',
            type: 'symbol',
            filter: ['!=', 'cluster', true],
            layout: {
              'icon-image': uniqIcon,
              'icon-size': 1,
            },
          };
          !map.current.getSource('uniqMeter') && map.current.addSource('uniqMeter', source);
          !map.current.getLayer('uniq-meter') && map.current.addLayer(layer);
        }
      });
  }

  function addPopup() {
    if (markers && map.current && displayPopup) {
      const layersName = [lay.name.soloMeters, lay.name.gatewayRange, lay.name.gatewayLayerName];

      _.each(layersName, function(name) {
        events(map.current, 'mouseenter', name, popup.current, t, displayCluster);
      });

      _.each(lay.layerId, (el: string) => {
        map.current.on('click', el, (e: EventData) => {
          !isGatewaySelected &&
          el != lay.layerId[0] &&
          map.current.getZoom() <= 18 &&
          el !== 'unclustered-point' &&
          el !== 'cluster'
            ? map.current.flyTo({ center: e.lngLat, zoom: e.target.transform.tileZoom + 1 })
            : map.current.easeTo({ center: e.lngLat });
          if (el === lay.name.soloMeters) {
            !isRound
              ? clickMarker(e.features[0].properties, true, e.features[0].id)
              : roundClickMarker(e.features[0].properties.meterId);
          }
        });
      });
      popups.removePopup(map.current, popup.current, lay.name.cluster);
    }
  }

  function addCapturedToMap(): void {
    removeSourcesAndLayers();

    const capturedMeters = data.capturedMeters(markers, lay.clusterPaintWithCase());
    const clusterLayer = lay.clusterLayer(lay.name.capturedMetersName);
    /*
    data.addSource(map.current, [lay.name.capturedMetersName], [capturedMeters]);
    lay.addLayers(map.current, [lay.name.cluster], [clusterLayer]);*/
  }

  function removeCapturedToMap(): void {
    lay.removeLayers(map.current, [lay.name.cluster]);
    data.removeSources(map.current, [lay.name.capturedMetersName]);
    //sourceAndLayerAdder();
  }

  function removeSourcesAndLayers(): void {
    lay.removeLayers(map.current, [
      lay.name.gatewayLayerName,
      lay.name.soloMeters,
      lay.name.cluster,
      lay.name.clusterCount,
      lay.name.gatewayRange,
    ]);
    data.removeSources(map.current, [
      lay.name.gatewaySourceName,
      lay.name.meterSourceName,
      lay.name.gatewayRange,
      lay.name.cluster,
      lay.name.capturedMetersName,
    ]);
  }

  function event(e: FeatureCollection): void {
    if (isGatewayRangeOn) {
      const selectedLines = data.selectedLines(markers, e);
      const filteredMeters = data.filteredMeters(markers, e);
      const unclusteredLayer = lay.unclusteredLayer(lay.name.filteredMetersName, false);
      const selectedGateway = data.selectedGateway(e);
      const selectedGatewayLayer = lay.gatewayLayer(lay.name.selectedGatewayName);
      const gatewayRanges = lay.gatewayRange(lay.name.selectedLinesName, { 'line-color': 'blue', 'line-width': 3 });

      removeSourcesAndLayers();

      data.addSource(
        map.current,
        [lay.name.filteredMetersName, lay.name.selectedGatewayName, lay.name.selectedLinesName],
        [filteredMeters, selectedGateway, selectedLines]
      );
      lay.addLayers(
        map.current,
        [lay.name.soloMeters, lay.name.gatewayLayerName, lay.name.gatewayRange],
        [unclusteredLayer, selectedGatewayLayer, gatewayRanges]
      );

      let groupement = _.groupBy(
        selectedLines.data.features,
        g => `${g.geometry.coordinates[1][0]}|${g.geometry.coordinates[1][1]}`
      );

      let searchMax = _.mapValues(groupement, v => _.maxBy(v, f => f.properties.distance)!.properties.distance);

      let finalMap: { id: number; device: boolean; distance: number; gps: GpsPosition }[] = [];
      _.mapKeys(searchMax, (value, g) => {
        let foundGateway = gateways.find(
          (gtw: Gateway) => `${_.get(gtw, 'gpsPosition.lng')}|${_.get(gtw, 'gpsPosition.lat')}` === g
        );
        finalMap.push({
          id: foundGateway! && foundGateway.id,
          device: foundGateway! && foundGateway.device,
          distance: value,
          gps: foundGateway! && foundGateway.gpsPosition,
        });
        return gateways.find(
          (gtw: Gateway) => `${_.get(gtw, 'gpsPosition.lng')}|${_.get(gtw, 'gpsPosition.lat')}` === g
        );
      });

      let circleList: Array<turf.helpers.Feature<Geometry>> = [];

      _.each(finalMap, function(value) {
        circleList.push(turf.circle([value.gps.lng, value.gps.lat], value.distance));
      });

      const coords = circleList[0].geometry.coordinates.toString().split(',');
      const selectedCircle = data.polygonSource(coords);
      const selectedInCircle = lay.circleLayerIn(lay.name.selectedCircleName);
      const selectedOutCircle = lay.circleLayerOut(lay.name.selectedCircleName);

      data.addSource(map.current, [lay.name.selectedCircleName], [selectedCircle]);
      lay.addLayers(
        map.current,
        [lay.name.selectedCircleName, lay.name.circleLine],
        [selectedInCircle, selectedOutCircle]
      );
      setGatewaySelected(true);
    }
  }

  map.current && map.current.on('click', lay.name.gatewayLayerName, event);

  function addDrawToMap(): void {
    createDraw();
    drawFeatures();
  }

  function resetFilters(): void {
    lay.removeLayers(map.current, [
      lay.name.gatewayLayerName,
      lay.name.soloMeters,
      lay.name.gatewayRange,
      lay.name.selectedCircleName,
      lay.name.circleLine,
    ]);
    data.removeSources(map.current, [
      lay.name.filteredMetersName,
      lay.name.selectedGatewayName,
      lay.name.selectedLinesName,
      lay.name.selectedCircleName,
    ]);

    sourceAndLayerAdder();
    dispatch(mapboxActions.setRangeOn(false));
    setGatewayRangeOn(false);
    setGatewaySelected(false);
  }

  function gatewayRangeObject(): void {
    const gatewayRange = data.gatewayRange(markers);
    markers && gateways && gatewayRange;
  }

  function removeGatewayRangeLayerAndSource(): void {
    lay.removeLayers(map.current, [lay.name.gatewayRange]);
    data.removeSources(map.current, [lay.name.gatewayRange]);
  }

  function addGatewayRangeSourceAndLayers(): void {
    const gatewayRanges = data.gatewayRange(markers);
    if (gatewayRanges.data.features.length > 0) {
      const gatewaysIds: Array<number> = [];
      _.each(gatewayRanges.data.features, function(value) {
        gatewaysIds.push(value.properties.gatewayId);
      });

      const uniqGatewaysIds = _.uniq(gatewaysIds);

      const lineColors: string[] = [];
      _.each(uniqGatewaysIds, function() {
        const randomColor = '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');
        lineColors.push(randomColor);
      });

      const gatewayRangeLayer = lay.gatewayRange(lay.name.gatewayRange, {
        'line-color': [
          'match',
          ['get', 'gatewayId'],
          ..._.flattenDeep([uniqGatewaysIds.map((gId, index) => [gId, lineColors[index]])]),
          lineColors[0],
        ],
        'line-width': 3,
      });
      data.addSource(map.current, [lay.name.gatewayRange], [lay.name.gatewayRange]);
      lay.addLayers(map.current, [lay.name.gatewayRange], [gatewayRangeLayer]), lay.name.gatewayLayerName;
    }
  }

  function addPolygonsToMap(): void {
    const layersIds: number[] = [];
    for (let i = 0; i < polygons.length; i++) {
      const coords = polygons[i].coordinates.split(',');
      const polygonSource = data.polygonSource(coords);
      const polygonInLayer = lay.polygonIn(i);
      const polygonOutLayer = lay.polygonOutline(i);

      map.current.on('load', () => {
        data.addSource(map.current, [`polygon-${i}`], [polygonSource]);
        lay.addLayers(map.current, [polygonInLayer.id, polygonOutLayer.id], [polygonInLayer, polygonOutLayer]);
      });
      layersIds.push(i);
    }
    //setPolygonLayerIds(layersIds);
  }

  function sourceAndLayerAdder(): void {
    map.current && markers && addMetersSourceAndLayers();
    map.current && gateways && addGatewaysSourceAndLayers();
  }

  function addGatewaysSourceAndLayers(): void {
    const gatewaysData = data.basicsGateways(gateways);
    const gatewayLayer = lay.gatewayLayer(lay.name.gatewaySourceName);

    map.current && displayGateways && data.addSource(map.current, [lay.name.gatewaySourceName], [gatewaysData]);
    displayGateways && lay.addLayers(map.current, [lay.name.gatewayLayerName], [gatewayLayer]);
  }

  function addMetersSourceAndLayers(): void {
    let metersLayer: any;
    if (markers) {
      !isRound ? (metersLayer = data.basicsMeters(markers, currentMeter)) : (metersLayer = data.roundMeters(markers));
      const clusterMeters = data.clusterMeter(metersLayer.data, lay.clusterPaintWithCase(), zoom);
      const clusterLayer = lay.clusterLayer(lay.name.meterSourceName);
      const unclusteredLayer = lay.unclusteredLayer(lay.name.meterSourceName, true);
      const clusters = document.getElementsByClassName('mapboxgl-marker');
      if (markers.length > 0) {
        _.entries(clusters).forEach(el => {
          el[1].style.display = 'block';
        });
        data.addSource(map.current, [lay.name.meterSourceName], [clusterization ? clusterMeters : metersLayer]);
        clusterization
          ? lay.addLayers(
              map.current,
              [lay.name.soloMeters, lay.name.cluster, lay.name.clusterCount],
              [unclusteredLayer, clusterLayer, lay.clusterCountLayer]
            )
          : lay.addLayers(map.current, [lay.name.soloMeters], [unclusteredLayer]);
      } else {
        _.entries(clusters).forEach(el => {
          el[1].style.display = 'none';
        });
        removeSourcesAndLayers();
      }
    }
  }

  function loadAndAddImage(): void {
    const imagesTabs = [allImages.gouttesImages];
    gateways && imagesTabs.push(allImages.gatewayImages);
    imagesTabs.map((el: Array<Image>) =>
      el.forEach((img: Image) => {
        map.current.loadImage(img.url, (error: ErrorEvent, image: Image) => {
          if (error) console.log(error);
          map.current.addImage(img.id, image);
        });
      })
    );

    if (homeMarker) {
      map.current.loadImage(allImages.homeMarkerSrc.url, (error: ErrorEvent, image: Image) => {
        if (error) console.log(error);
        map.current.addImage(allImages.homeMarkerSrc.id, image);
      });
    }
  }

  function createMap(): void {
    if (map.current) return;
    const mapDeclaration = mapProperties.mapDeclaration(mapId, [lng, lat], 10);
    mapDeclaration.myLocationEnabled;
    map.current = new mapboxgl.Map(mapDeclaration);
  }

  function createDraw(): void {
    if (draw.current) return;
    draw.current = new mapboxdraw(mapProperties.properties.draw);
  }

  function drawFeatures(): void {
    const drawFunctions = [setThisNewDrawFeature, clickedFeature, setDrawFeature, setDrawFeature];
    map.current.addControl(draw.current, 'top-left');
    for (let i = 0; i < drawFunctions.length; i++) {
      map.current.on(mapProperties.properties.drawFeatures[i], drawFunctions[i]);
    }
  }

  function setThisNewDrawFeature(): void {
    setNewDrawFeature(true);
  }

  function clickedFeature(e: EventData): void {
    if (!newDrawFeature && draw.current) {
      const drawFeatureAtPoint = draw.current.getFeatureIdsAt(e.point);
      //setDrawFeatureId(drawFeatureAtPoint.length ? drawFeatureAtPoint[0] : '');
    }
    setNewDrawFeature(false);
  }

  function setDrawFeature(e: DrawSelectionChangeEvent): void {
    if (e.features.length && e.features[0].type === 'Feature') {
      const feature = e.features[0];
      //setDrawFeatureId(feature.id);
      markers && inPolygon();
    }
  }

  function controls(): void {
    const controls = [];
    fullscreen && controls.push(new mapboxgl.FullscreenControl());
    geolocation && controls.push(new mapboxgl.GeolocateControl(mapProperties.properties.geolocatedControl));
    controls.push(new mapboxgl.NavigationControl());

    for (let i = 0; i < controls.length; i++) {
      map.current && map.current.addControl(controls[i], mapProperties.properties.controls[i]);
    }
  }

  const flattenGeojson = _.flatten(geojsonLayer);
  function addSourceAndLayerFromBack(): void {
    _.each(flattenGeojson, function(value: GeoJSON.GeoJsonProperties, key) {
      data.addSourceFromBack(map.current, [value!.name], [value!]);

      const points = lay.geojsonPoints(value!.name);
      const lines = lay.geojsonLines(value!.name);
      const insidePolygon = lay.geojsonInPolygon(value!.name);
      const polygonBorder = lay.geojsonPolygonBorder(value!.name);

      switch (value!.features[key] && value!.features[key].geometry.type) {
        case 'Point':
          lay.addLayers(map.current, [value!.name], [points]);
        case 'LineString':
          lay.addLayers(map.current, [value!.name], [lines]);
        case 'Polygon':
          lay.addLayers(map.current, ['inside', 'border'], [insidePolygon, polygonBorder]);
        default:
          break;
      }
    });
  }

  function removeGeojsonLayerAndSource() {
    lay.removeLayers(map.current, ['inside', 'border']);

    flattenGeojson.map((el: any) => {
      lay.removeLayers(map.current, [el.name]);
      data.removeSources(map.current, [el.name]);
    });
  }

  function updateMarkers(filteredFeatures: any, display: any): boolean {
    //const capturedFeatures = map.current.querySourceFeatures(lay.name.capturedMetersName)
    map.current.getSource(lay.name.meterSourceName) &&
      clusterConstruction.clusterConstructor(filteredFeatures, map.current, currentDisplay);

    //map.current.getSource(capturedMetersName) && clusterConstruction.clusterConstructor(capturedFeatures, map.current)
    return true;
  }

  function createPopup(): void {
    popup.current = new mapboxgl.Popup(mapProperties.properties.popup);
  }

  function inPolygon() {
    // à corriger
    const metersLayer = data.basicsMeters(markers);

    const polygons: Array<string> = [];
    let inPolygonFeatures: number;
    let metersId: Array<number> = [];
    for (let i = 0; i < draw.current.getAll().features.length; i++) {
      const coords: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> = draw.current.getAll().features[i];

      let polygon: Feature<turf.helpers.Polygon, turf.helpers.Properties> = {
        type: 'Feature',
        geometry: {
          coordinates: [],
          type: 'Polygon',
        },
        properties: {},
      };
      if (coords.geometry.type === 'Polygon') {
        polygon = turf.polygon(coords.geometry.coordinates);
      }

      polygons.push(polygon.geometry.coordinates[0].toString());

      const metersFeatures = metersLayer.data.features;
      const coordinates = metersFeatures.map(el => el.geometry.coordinates);
      const properties = metersFeatures.map(el => el.properties.meterId);

      const coordinatesIterator = coordinates.entries();
      const propertiesIterator = properties.entries();
      let metersPoints: Feature<Point, Properties> = {
        type: 'Feature',
        geometry: { coordinates: [], type: 'Point' },
        properties: {},
      };
      let completeCoords: Array<Array<number>> = [];
      _.each(metersFeatures, function() {
        completeCoords.push(coordinatesIterator.next().value[1]);

        //metersPoints = turf.points(completeCoords, {id :propertiesIterator.next().value[1]} )
      });

      const inPolygon = turf.pointsWithinPolygon(metersPoints, polygon);
      _.each(inPolygon.features, function(el) {
        inPolygonFeatures = el.properties!.meterId;
        metersId.push(inPolygonFeatures);
      });
    }
    const metersIds: Array<number> = metersId.map(el => el);
    setPolygonsArray(polygons);

    return metersIds;
  }

  function postPolygons(): void {
    dispatch(mapboxActions.postPolygons(polygonsArray.map(polygon => ({ coordinates: polygon }))));
  }

  function getMaxDistanceAndDrawCircle(): void {
    let gatewayCoordsArray: Array<string> = [];
    const gatewaysData = data.basicsGateways(gateways);
    const gatewayRange = data.gatewayRange(markers);

    _.each(gatewaysData.data.features, function(value) {
      let idGateway = `${value.geometry.coordinates[0]}|${value.geometry.coordinates[1]}`;
      gatewayCoordsArray.push(idGateway);
    });

    let groupement = _.groupBy(
      gatewayRange.data.features,
      g => `${g.geometry.coordinates[1][0]}|${g.geometry.coordinates[1][1]}`
    );

    _.each(gatewaysData.data.features, function(value: GeoJSONObject) {
      _.set(
        value,
        `properties.lines`,
        groupement[`${_.get(value, 'geometry.coordinates[0]')}|${_.get(value, 'geometry.coordinates[1]')}`]
      );
    });

    let searchMax = _.mapValues(groupement, v => _.maxBy(v, f => f.properties.distance)!.properties.distance);

    let finalMap: { id: number; device: boolean; distance: number; gps: GpsPosition }[] = [];
    _.mapKeys(searchMax, (value, g) => {
      let foundGateway = gateways.find(
        (gtw: Gateway) => `${_.get(gtw, 'gpsPosition.lng')}|${_.get(gtw, 'gpsPosition.lat')}` === g
      );
      finalMap.push({
        id: foundGateway! && foundGateway.id,
        device: foundGateway! && foundGateway.device,
        distance: value,
        gps: foundGateway! && foundGateway.gpsPosition,
      });
      return gateways.find((gtw: Gateway) => `${_.get(gtw, 'gpsPosition.lng')}|${_.get(gtw, 'gpsPosition.lat')}` === g);
    });

    let circleList: Array<turf.helpers.Feature<Geometry>> = [];

    _.each(finalMap, function(value) {
      circleList.push(turf.circle([value.gps.lng, value.gps.lat], value.distance));
    });

    _.each(circleList, function(el: any) {
      const coords = circleList[el].geometry.coordinates.toString().split(',');
      const circles = data.polygonSource(coords);
      const inCirclesLayer = lay.inAllCircles(el);
      const outlineCirclesLayer = lay.allCirclesOutline(el);

      isGatewayCircleRangeOn
        ? data.addSource(map.current, [`circle-${el}`], [circles])
        : !map.current.getLayer(`circle-${el}`) &&
          !map.current.getLayer(`line-${el}`) &&
          data.removeSources(map.current, [`circle-${el}`]);
      isGatewayCircleRangeOn
        ? lay.addLayers(map.current, [`circle-${el}`, `line-${el}`], [inCirclesLayer, outlineCirclesLayer])
        : lay.removeLayers(map.current, [`circle-${el}`, `line-${el}`]);
    });
  }

  function handleFicheMeter(): void {
    let toPath = '';
    toPath = `/locations/${selectedMeter!.rndId!}/pdi/${selectedMeter.clpId}`;
    history!.push({
      pathname: toPath,
    });
  }

  map.current && map.current.scrollZoom.disable();

  function switchDrawMode(): void {
    switch (drawMode) {
      case 'polygon':
        //@ts-ignore
        draw.current.changeMode('draw_circle', { initialRadiusInKm: 2.5 });
        setDrawMode('circle');
        setDrawModeText('polygone');
        break;

      case 'circle':
        draw.current.changeMode('draw_polygon');
        setDrawMode('polygon');
        setDrawModeText('cercle');
        break;
      default:
        break;
    }
  }

  function initializeSpiderLeg(spiderLeg: EventData): void {
    const modes = [
      'red_manual',
      'red_radio',
      'red_telereleve',
      'green_manual',
      'green_radio',
      'green_telereleve',
      'purple_radio',
      'purple_telereleve',
      'blue_radio',
      'blue_telereleve',
      'yellow_manual',
      'yellow_radio',
      'yellow_telereleve',
    ];

    const pinElement = spiderLeg.elements.pin;
    const feature = spiderLeg.feature;
    let popup: Popup;

    const classe = _.find(modes, el => {
      return el === feature.ficheState;
    });

    if (classe) {
      pinElement.className = pinElement.className + ' spiderfier ' + classe;
    }

    $(pinElement)
      .on('mouseenter', () => {
        popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          offset: MapboxglSpiderifier.popupOffsetForSpiderLeg(spiderLeg),
        });
        const meterSerial = feature.meterSerial;
        const clpReference = feature.clpReference;
        const lastRead = JSON.stringify(feature.lastRead) !== '{}' ? feature.lastRead : null;

        popup
          .setHTML(
            lastRead
              ? `<h3 style="font-size:14px; color: #31c6b3; margin-bottom: 5px">Information compteur:</h3></br>` +
                  `<h3 style="font-size:12px; margin: 0">Compteur</h3></br>` +
                  `<h2 style="color: black; font-size: 16px">${meterSerial}</h2></br>` +
                  `${
                    clpReference
                      ? `<h3 style="font-size:12px; margin: 0">Pdi</h3></br><h2 style="color: black; font-size: 16px">${clpReference}</h2></br>`
                      : `</br>`
                  }` +
                  `<h3 style="font-size:14px; color: #31c6b3; margin: 5px 0">Dernière lecture: </h3></br>` +
                  `<h3 style="font-size:12px; margin: 0">index</h3></br><h2 style="color: black; font-size: 16px">${
                    lastRead.index
                  } m<sup>3</sup></h2></br>` +
                  `<h3 style="font-size:12px; margin: 0">consommation</h3></br><h2 style="color: black; font-size: 16px">${
                    lastRead.consumption
                  } m<sup>3</sup></h2></br>`
              : `<h3 style="font-size:12px; color: #31c6b3; margin-bottom: 5px">Information compteur</h3></br>` +
                  `<h3 style="font-size:12px; margin: 0">compteur</h3></br><h2 style="color: black; font-size: 16px">${meterSerial}</h2></br>` +
                  `${
                    clpReference
                      ? `<h3 style="font-size:12px; margin: 0">pdi</h3></br><h2 style="color: black; font-size: 16px">${clpReference}</h2></br>`
                      : `</br>`
                  }`
          )
          .addTo(map.current);
        popup.addClassName('unclustered');
        spiderLeg.mapboxMarker.setPopup(popup);
      })
      .on('mouseenter', (e: any) => {
        setMarkerEnter(true);
      })
      .on('mouseleave', (e: any) => {
        setMarkerEnter(false);
        popup && popup.remove();
      })
      .on('click', e => {
        e.stopPropagation();
        if (markers) {
          !isRound ? clickMarker(feature, false, null) : roundClickMarker(feature.meterId);
        }
      });
  }

  function mouseClick(e: EventData): void {
    const features = map.current.queryRenderedFeatures(e.point, {
      layers: [lay.name.cluster],
    });
    if (!features.length && !currentEnter.current) {
      spiderifier && spiderifier.unspiderfy();
      return;
    }
    const clusterId = _.get(features, '[0].properties.cluster_id');
    if (clusterId !== currentDisplay.current.id || !currentDisplay.current) {
      if (map.current.getZoom() <= 18) {
        map.current.getSource(lay.name.meterSourceName).getClusterExpansionZoom(clusterId, (err: any, zoom: any) => {
          if (!err) {
            map.current.flyTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          }
          isZooming.current = true;

          if (currentDisplay.current.id) {
            const doc: any = document.getElementById(`cluster-${currentDisplay.current.id}`);
            if (doc) {
              doc.style.opacity = 1;
            }
            setDisplayCluster({ id: null, display: 'display: block;', spiderify: false });
          }
        });
      } else {
        map.current
          .getSource(lay.name.meterSourceName)
          .getClusterLeaves(clusterId, 100, 0, function(err: any, leafFeatures: any) {
            if (err) {
              return console.error('error while getting leaves of a cluster', err);
            }

            const markers = _.map(leafFeatures, function(leafFeature) {
              return leafFeature.properties;
            });

            if ((!currentDisplay.current.id && map.current.getZoom() >= 19) || currentDisplay.current.id) {
              spiderifier.spiderfy(features[0].geometry.coordinates, markers);
              setDisplayCluster({ id: clusterId, display: 'display: none;', spiderify: true });
            }
            popups.removeClusterPopup(map.current, popup.current);
          });
      }
    }
  }

  function clickMarker(properties: any, isNotPin: boolean, id: any): void {
    if (properties) {
      let lastRead;
      if (typeof properties.lastRead === 'string') {
        lastRead = JSON.parse(properties.lastRead);
      } else {
        lastRead = properties.lastRead;
      }

      if (id) {
        map.current.setLayoutProperty(
          'unclustered-point',
          'icon-image',
          ['match', ['id'], id, `${properties.ficheState}_selected`, ['get', 'ficheState']],
          { validate: false }
        );
      } else {
        map.current.setLayoutProperty('unclustered-point', 'icon-image', [
          'match',
          ['id'],
          currentMeter.current ? currentMeter.current.meterId : properties.meterId,
          currentMeter.current ? currentMeter.current.ficheState : properties.ficheState,
          ['get', 'ficheState'],
        ]);
      }

      setSelectedMeter({
        id: properties.meterId,
        meterSerial: properties.meterSerial,
        radioSerial: properties.serialRadio,
        coords: typeof properties.coords === 'string' ? JSON.parse(properties.coords) : properties.coords,
        ficheState: properties.ficheState,
        readData: lastRead.date
          ? {
              date: lastRead.date,
              index: lastRead.index,
              consumption: lastRead.consumption,
              method: lastRead.method,
            }
          : null,
        rndId: match.params.locationId,
        clpId: properties.pdiId,
      });
    } else {
      setSelectedMeter(false);
    }
  }
  return (
    <div className={'mapbox-wrapper'}>
      {displayGeojsonLayers && (
        <div>
          <input type="checkbox" checked={isLayerOn} onChange={handleCheckboxChange} />
          <label htmlFor="checkbox">Ajouter les calques</label>
        </div>
      )}
      {draw.current && draw.current.getAll().features.length > 0 && (
        <div>
          <button id="round-button">Créer une tournée</button>
          <UncontrolledPopover trigger={'legacy'} placement="top" target="round-button">
            <PopoverHeader>Création d'une tournée</PopoverHeader>
            <PopoverBody style={{ display: 'grid' }}>
              Nom de la tournée
              <Input onChange={e => setRoundName(e.target.value)} />
              <Button onClick={() => dispatch(mapboxActions.sendRound({ meterIds: inPolygon(), name: roundName }))}>
                Créer la tournée
              </Button>
            </PopoverBody>
          </UncontrolledPopover>
        </div>
      )}
      {displayPolygons && <Button onClick={postPolygons}>{t('mapbox_button.text.save_polygon')}</Button>}
      {draw.current && <Button onClick={switchDrawMode}>Dessiner un {drawModeText}</Button>}
      {isGatewaySelected && <Button onClick={resetFilters}>Réinitialiser les filtres</Button>}
      {gateways && markers && filters && (
        <div>
          <input type="checkbox" checked={isGatewayRangeOn} onChange={e => handleGatewayRangeChange(e)} />
          <label htmlFor="checkbox">Mettre en relation les gateways et les compteurs</label>
          <input type="checkbox" checked={isGatewayCircleRangeOn} onChange={handleGatewayCircleRangeChange} />
          <label htmlFor="checkbox">Afficher le rayon d'action de la gateway</label>
        </div>
      )}
      {selectedMeter && selectedMeter.meterSerial && (
        <div style={{ position: 'relative', marginBottom: '20px' }}>
          <SelectedBand
            selectedMeter={selectedMeter}
            closeSelectedBand={clickMarker}
            goToFicheMeter={handleFicheMeter}
          />
        </div>
      )}
      <div id={mapId} style={style} />
    </div>
  );
}
//@ts-ignore
export default withTranslation()(withRouter(connect(mapStateToProps)(Mapbox)));
