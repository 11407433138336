import React from 'react';

const ChangementCompteur = (props: any) => (
  <svg viewBox="0 0 100.77 89.93" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="pictos">
        <path
          fill={props.fill}
          d="M46.25,47.82c1.72-4.09,3.2-7.28,4.4-9.62.89-1.72,1.75-3.16,2.52-4.37A27.51,27.51,0,0,1,56,30.36a11.59,11.59,0,0,1,3.88-2.52A13.74,13.74,0,0,1,64.79,27H79.17V37.8a1.74,1.74,0,0,0,.52,1.26,1.67,1.67,0,0,0,1.26.52,1.82,1.82,0,0,0,1.29-.49l18-18a1.86,1.86,0,0,0,0-2.58L82.27.56A2.08,2.08,0,0,0,80.92,0a1.7,1.7,0,0,0-1.79,1.79V12.57H64.75a26.93,26.93,0,0,0-7.19.92,27.7,27.7,0,0,0-6,2.36,23.33,23.33,0,0,0-5.08,3.85,44.29,44.29,0,0,0-4.14,4.67A52,52,0,0,0,38.75,30c-1.26,2.25-2.27,4.21-3,5.91s-1.69,3.77-2.8,6.23c-1.71,4.09-3.19,7.29-4.4,9.62-.88,1.72-1.75,3.17-2.51,4.37a26.92,26.92,0,0,1-2.86,3.47,10.87,10.87,0,0,1-3.88,2.52,13.69,13.69,0,0,1-4.88.83H1.78a1.73,1.73,0,0,0-1.29.49A1.73,1.73,0,0,0,0,64.72V75.51a1.73,1.73,0,0,0,1.81,1.78h12.6a26.82,26.82,0,0,0,7.19-.92,28,28,0,0,0,6-2.36,23.52,23.52,0,0,0,5.07-3.85,42.09,42.09,0,0,0,4.14-4.66,50.71,50.71,0,0,0,3.6-5.63c1.27-2.24,2.28-4.22,3-5.9s1.7-3.66,2.8-6.15"
        />
        <path
          fill={props.fill}
          d="M1.81,27.05h12.6a12.47,12.47,0,0,1,4.58.82,13.63,13.63,0,0,1,3.54,2A15.6,15.6,0,0,1,25.38,33a40.78,40.78,0,0,1,2.28,3.56c.55,1,1.26,2.4,2.09,4.09a101.45,101.45,0,0,1,7.71-15.34c-6-8.41-13.68-12.66-23-12.66H1.81a1.74,1.74,0,0,0-1.29.5A1.7,1.7,0,0,0,0,14.45V25.23a1.73,1.73,0,0,0,.49,1.29,1.76,1.76,0,0,0,1.29.53"
        />
        <path
          fill={props.fill}
          d="M82.33,51A2.14,2.14,0,0,0,81,50.43a1.91,1.91,0,0,0-1.29.49,1.76,1.76,0,0,0-.49,1.3V63H64.81a12.27,12.27,0,0,1-4.58-.83,13.39,13.39,0,0,1-3.53-2A15.34,15.34,0,0,1,53.85,57a36.53,36.53,0,0,1-2.28-3.56c-.56-1-1.26-2.4-2.09-4.09a112,112,0,0,1-7.66,15.33A32.48,32.48,0,0,0,45,68.63c1.07,1.13,2.12,2.15,3.11,3a15.22,15.22,0,0,0,3.32,2.24c1.23.65,2.33,1.17,3.25,1.6a16.49,16.49,0,0,0,3.54,1c1.41.27,2.61.46,3.59.58s2.3.22,4,.28,3,0,4.12,0l4.55,0c2,0,3.56,0,4.76,0V88.14a1.74,1.74,0,0,0,.52,1.26,1.66,1.66,0,0,0,1.26.53,1.73,1.73,0,0,0,1.29-.5l18-18a1.7,1.7,0,0,0,.49-1.28,1.72,1.72,0,0,0-.52-1.29Z"
        />
      </g>
    </g>
  </svg>
);

export default ChangementCompteur;
