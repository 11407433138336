import _ from 'lodash';
import React from 'react';

const meterState = [
  'red_manual',
  'red_radio',
  'yellow_manual',
  'yellow_radio',
  'green_manual',
  'green_radio',
  'blue_radio',
  'blue_telereleve',
  'purple_radio',
  'purple_telereleve',
];

export function orderByMeterState(list) {
  return _.fromPairs(_.sortBy(_.entries(list), ([key]) => _.indexOf(meterState, key)));
  //comment multiline
}
