/* eslint-disable react/require-default-props */

import React, { Fragment, ReactElement } from 'react';
import ModeleVert from '../SvgComponents/ModeleVert';
import FabricantVert from '../SvgComponents/FabricantVert';
import { Link } from 'react-router-dom';
import Picto from '../SourceSheet/Picto_sourceSheet';
import NoFilled from '../Message/NoFilled';
import { withTranslation } from 'react-i18next';
import TeleReleveInfo from '../_components/TeleReleveInfo';

interface Props {
  intervalSelector: ReactElement<'IntervalSelector'>;
  historic: ReactElement<'div'>;
  infoGeneral: any;
  loading: boolean;
  error: string;
  locationId: number;
  t: Function;
}

function RadioWrapped(props: Props) {
  const { intervalSelector, historic, infoGeneral, locationId, t } = props;
  const linkToPDI = infoGeneral.pdi && infoGeneral.pdi.id ? `/locations/${locationId}/pdi/${infoGeneral.pdi.id}` : '';

  function generateLineInfo(title: string, info: any, imgName: string) {
    return (
      <div
        className="row meterInfo"
        style={{ marginLeft: '10px', maxWidth: '90%', display: 'flex', flexDirection: 'row' }}
      >
        <div className=" picto" style={{ marginTop: '10px', padding: '0 15px' }}>
          {Picto(imgName) ? (
            Picto(imgName, {
              style: { marginRight: '10px' },
              height: '2em',
              width: '2em',
              stroke: '#31c6b3',
              fill: '#31c6b3',
              strokeWidth: '0',
            })
          ) : (
            <div style={{ width: '35.5px' }} />
          )}{' '}
        </div>
        <div className="" style={{ flex: 1, padding: '0 15px' }}>
          <p>
            <span className="infoLibelle">{title}</span> <br />
            {info ? (
              <div className="infoDisplay" style={{ overflowWrap: 'break-word' }}>
                {info}{' '}
              </div>
            ) : (
              <NoFilled />
            )}
          </p>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {infoGeneral && Object.keys(infoGeneral).length > 0 && (
        <>
          <div className={'radio-bar'}>
            <div className={'radio-info-bar'}>
              <div className={'c60'}>
                <span>{t('all.meter.radio_serial')}</span>
                <h3>{infoGeneral.serial}</h3>
              </div>
              <div className={'c20'}>
                <span>{t('all.meter.meter_manufacturer')}</span>
                <h3>{infoGeneral.manufacturer.name}</h3>
              </div>
              <div className={'c20'}>
                <span>{t('all.meter.meter_model')}</span>
                <h3>{infoGeneral.type}</h3>
              </div>
            </div>
            <TeleReleveInfo type={'RADIO'} />
          </div>
          <div className="row">
            <div className="col-lg-4">
              {infoGeneral.pdi && (
                <div className="clean-meter-bar">
                  <div className="flex-box">
                    <div className="c50 background-texture left">
                      <>
                        <span>{t('all.pdi.link_pdi_ref')}</span>
                        <h3>{infoGeneral.pdi.reference || <NoFilled />}</h3>
                      </>
                    </div>
                    <div className="c50 linkpdi">
                      <div className="flex-box center">
                        <Link className={'infoDisplay'} to={{ pathname: linkToPDI }}>
                          {t('all.round.go_to_record')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {historic && <Fragment>{historic}</Fragment>}
            </div>
            <div className="col-lg-8" style={{ padding: 0 }}>
              {intervalSelector}
              <br />
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
}

RadioWrapped.defaultProps = {
  infoGeneral: {
    pdi: {},
    manufacturer: {},
  },
  loading: false,
  error: '',
};

const tr = withTranslation()(RadioWrapped);
export default tr;
