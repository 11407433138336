import React from 'react';

const SvgAdresseVert = (props: any) => (
  <svg viewBox="0 0 80.55 113.45" {...props}>
    <path
      fill={props.fill}
      d="M35.22,24.87a3.14,3.14,0,0,0-4.92,0C27.2,28.79,0,63.68,0,80.69a32.76,32.76,0,0,0,65.52,0C65.52,63.73,38.32,28.8,35.22,24.87Zm-2.46,82.31A26.52,26.52,0,0,1,6.27,80.69c0-12.94,21.81-42.53,26.18-48.33a.41.41,0,0,1,.63,0c4.37,5.8,26.18,35.39,26.18,48.33A26.52,26.52,0,0,1,32.76,107.18Z"
    />
    <path
      fill={props.fill}
      d="M48.27,77.56a3.13,3.13,0,0,0-3.13,3.13A12.39,12.39,0,0,1,32.76,93.07a3.14,3.14,0,0,0,0,6.27A18.67,18.67,0,0,0,51.41,80.69,3.14,3.14,0,0,0,48.27,77.56Z"
    />
    <path
      fill={props.fill}
      d="M69.41,41a12.39,12.39,0,0,0,3.66,8.81A2.7,2.7,0,1,0,76.9,46a7,7,0,0,1,0-10,12.43,12.43,0,0,0,0-17.6,2.7,2.7,0,0,0-3.83,3.81,7.06,7.06,0,0,1,0,10A12.38,12.38,0,0,0,69.41,41Z"
    />
    <path
      fill={props.fill}
      d="M51.29,30.54a15.74,15.74,0,0,0,4.63,11.18,2.71,2.71,0,0,0,1.92.79,2.67,2.67,0,0,0,1.91-.79,2.7,2.7,0,0,0,0-3.83,10.41,10.41,0,0,1,0-14.72,15.81,15.81,0,0,0,0-22.38,2.71,2.71,0,0,0-3.83,3.83,10.36,10.36,0,0,1,0,14.72,15.79,15.79,0,0,0-4.63,11.2Z"
    />
  </svg>
);

export default SvgAdresseVert;
