import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Pie } from 'react-chartjs-2';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { withTranslation } from 'react-i18next';
import Picto from './Picto_correspondance';
import { localeActions, mapActions, tourneeActions } from '../_actions';
import NoFilled from '../Message/NoFilled';
import { urlGiveAway } from '../_helpers/statistique-helper';
import ListTools from '../List/ListTools';
import EditionBleu from '../SvgComponents/EditionBleu';
import GoogleMapReactC from '../GoogleMap/GoogleMapReactC';
import Mapbox from '../Mapbox/components/Mapbox';

interface ListMeter {
  id: number;
  serial: string;
  latitude: number;
  longitude: number;
}

interface Tournee {
  code: string;
  name: string;
  message: string;
  startDate?: any;
  totalCpt: number;
  totalRadio: number;
  indexedMeters: number;
  modifiedMeters: number;
  listMeters: ListMeter[];
  fiches: any;
  info: any;
}

interface Props {
  tournee: Tournee;
  dispatch: Function;
  match: any;
  map: any;
}

interface State {
  isEditMsgMode: boolean;
  tourneeMsg: any;
  init: boolean;
}

class TourneeDetail extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isEditMsgMode: false,
      tourneeMsg: undefined,
      init: false,
    };
    this.send = this.send.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleEditionMode = this.handleEditionMode.bind(this);
    this.handleNewMsg = this.handleNewMsg.bind(this);
    this.handleCancelEdition = this.handleCancelEdition.bind(this);
    this.clickMarker = this.clickMarker.bind(this);
    this.generateLineInfoEditMsg = this.generateLineInfoEditMsg.bind(this);
  }

  static getDerivedStateFromProps(props: any, state: State) {
    const { tournee, maps, dispatch } = props;
    const { tourneeMsg, init } = state;
    if (tournee && tournee.info) {
      // @ts-ignore
      const newState: State = {};
      if (tourneeMsg === undefined) {
        newState.tourneeMsg = tournee.info.message ? tournee.info.message : '';
      }
      if (!init && !tournee.loadingFiches && tournee.info && tournee.fiches) {
        const markersToCreate =
          tournee.fiches &&
          _.get(tournee, 'info.listMeters') &&
          _.get(tournee, 'info.listMeters').map((meterGps: any) => {
            const read = tournee.fiches.find(
              (el: any) => el.cptSerial === meterGps.serial || el.newMeterSerial === meterGps.serial
            );
            const fiche =
              tournee.fiches &&
              tournee.fiches.find((el: any) => [el.newMeterSerial, el.cptSerial].includes(meterGps.serial));
            return {
              id: meterGps.id,
              meterSerial: meterGps.serial,
              gpsPosition: { lat: meterGps.latitude, lng: meterGps.longitude },
              icon: fiche && fiche.ficheState,
              lastRead: {
                date: read ? read.currentDate : null,
                index: read ? read.currentIndex : null,
                consumption: read ? read.consumption : null,
                method: read ? read.readMethod : null,
              },
            };
          });
        dispatch(mapActions.fillMarker(markersToCreate));
        newState.init = true;
      }
      if (!_.isEmpty(newState)) return newState;
    }
    return null;
  }

  componentDidMount() {
    const { dispatch, match, locations } = this.props;
    const { tourneeCode } = match.params;
    dispatch(tourneeActions.getTournee(tourneeCode || locations.fetchedLocation.code));
    dispatch(tourneeActions.getAllFicheTournee(tourneeCode || locations.fetchedLocation.code));
    dispatch(localeActions.load());
    /* navigator.geolocation.getCurrentPosition((position) => {
      console.log("YES")
      this.setState({defaultPosition : { lat : position.coords.latitude, lng : position.coords.longitude }})
    }); */
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(mapActions.clearMarker());
    dispatch(tourneeActions.clear());
  }

  generateLineInfo(title: string, info: string, imgName: string) {
    return (
      <div className="row" style={{ marginLeft: '10px' }}>
        <div className="col-md-2" style={{ marginTop: '10px' }}>
          {Picto(imgName, {
            style: { marginRight: '10px' },
            height: '2em',
            width: '2em',
            stroke: '#31c6b3',
            fill: '#31c6b3',
            strokeWidth: '0',
          })}{' '}
        </div>
        <div className="col-md-10">
          <p>
            <span className="infoLibelle">{title}</span> <br />
            {info ? <span className="infoDisplay">{info} </span> : <NoFilled />}
          </p>
        </div>
      </div>
    );
  }

  handleEditionMode() {
    const { isEditMsgMode } = this.state;

    this.setState({
      isEditMsgMode: !isEditMsgMode,
    });
  }

  handleNewMsg(event: any) {
    const newMsg = event.target.value.trim();

    this.setState({
      tourneeMsg: newMsg,
    });
  }

  send() {
    const { dispatch, tournee } = this.props;
    const { tourneeMsg } = this.state;

    dispatch(tourneeActions.updateTourneeMsg(tournee.info.code, tourneeMsg));
    this.handleEditionMode();
  }

  handleCancelEdition() {
    const { tournee } = this.props;
    this.setState({ tourneeMsg: _.get(tournee, 'info.message') ? tournee.info.message : '' });
    this.handleEditionMode();
  }

  generateLineInfoEditMsg(title: string, info: string, imgName: string) {
    const { isEditMsgMode, tourneeMsg } = this.state;
    const { t } = this.props;
    return (
      <div className="row" style={{ margin: 0, paddingLeft: '10px', paddingBottom: '20px' }}>
        <div className="col-md-2" style={{ marginTop: '10px' }}>
          {Picto(imgName, {
            style: { marginRight: '10px' },
            height: '2em',
            width: '2em',
            stroke: '#31c6b3',
            fill: '#31c6b3',
            strokeWidth: '0',
          })}{' '}
        </div>
        <div className="col-md-10" style={{ paddingRight: '20px' }}>
          <Row style={{ margin: '0' }}>
            <Col md="9" style={{ padding: '0' }}>
              <span className="infoLibelle">{title}</span>
            </Col>
            <Col md="3" style={{ padding: '0', textAlign: '-webkit-right' }}>
              <div
                className="clickable"
                id="editMsg"
                onClick={this.handleEditionMode}
                role="presentation"
                style={{ width: 'fit-content' }}
              >
                <EditionBleu height="1em" width="1em" fill="#000" />
                <UncontrolledTooltip placement="bottom" target="editMsg">
                  {' '}
                  {t('tournee_detail.tooltip.edit_note')}
                </UncontrolledTooltip>
              </div>
            </Col>
          </Row>
          {isEditMsgMode ? (
            <AvForm onValidSubmit={this.send} style={{ paddingTop: '10px' }}>
              <AvField
                type="textarea"
                name="note"
                rows="7"
                id="note"
                placeholder={t('tournee_detail.field_placeholder.write_note')}
                value={tourneeMsg}
                onChange={this.handleNewMsg}
                validate={{
                  maxLength: { value: 160 },
                }}
              />
              <Row style={{ margin: '0' }}>
                <Col md="6" style={{ textAlign: 'center' }}>
                  <Button onClick={this.handleCancelEdition} color="danger">
                    {t('all.button.cancel')}
                  </Button>
                </Col>
                <Col md="6" style={{ textAlign: 'center' }}>
                  <Button type="submit" color="primary">
                    {t('all.button.validate')}
                  </Button>
                </Col>
              </Row>
            </AvForm>
          ) : info ? (
            <span className="infoDisplay" style={{ overflowWrap: 'break-word' }}>
              {info}{' '}
            </span>
          ) : (
            <NoFilled />
          )}
        </div>
      </div>
    );
  }

  handleClick(event: any, chart: any) {
    const { match, history, locations, t } = this.props;
    const { params } = match;
    if (chart[0]) {
      const urlOption = urlGiveAway(chart[0]._model.label, t);
      if (locations.fetchedLocation) {
        history.push({
          pathname: `/locations/${locations.fetchedLocation.id}/fiche`,
          search: urlOption,
        });
      } else {
        history.push({
          pathname: `/gestionnaires/${params.GestionnaireId}/synchronisation/tournees/${params.tourneeCode}/fiche`,
          search: urlOption,
        });
      }
    }
  }

  generatePieDiagram(title: string, labels: Array<string>, data: Array<number>) {
    return (
      <div>
        <p style={{ margin: '10px 20px' }}>
          <span className="infoLibelle">{title}</span> <br />
          <span className="infoDisplay">{`${data[0]}/${data[0] + data[1]}`} </span>
        </p>
        <Pie
          options={{
            legend: {
              display: false,
            },
            plugins: {
              t1: false,
              datalabels: {
                display: false,
                color: 'white',
              },
            },
            onClick: (event: any, chart: any) => this.handleClick(event, chart, title),
          }}
          data={{
            labels,
            datasets: [
              {
                backgroundColor: ['#31c6b3'],
                hoverBackgroundColor: ['#26A653', '#F24141'],
                data,
              },
            ],
          }}
          height={100}
        />
      </div>
    );
  }

  clickMarker(meterId, lat, lng) {
    const { history, tournee } = this.props;

    if (tournee && tournee.fiches) {
      const gpsMeter = _.get(tournee, 'info.listMeters').find((el: any) => el.id === meterId);

      if (gpsMeter && gpsMeter.serial) {
        history.push({
          pathname: `${history.location.pathname}/fiche/${gpsMeter.serial}`,
        });
      }
    }
  }

  isDioptase = () => {
    const { user } = this.props;

    return ['DIOPTASE'].includes(user.role.name);
  };

  render() {
    const { tournee, maps, dispatch, t } = this.props;
    const { init } = this.state;
    return (
      <div className="col-md-12">
        {tournee && !tournee.updatingMsg && tournee.info && (
          <div className="row">
            <div className="col-lg-3">
              <div className="presentation-container">
                <div className="presentation-header" style={{ backgroundSize: '800px', display: 'flex' }}>
                  <div>
                    <span className="presentation-title">{t('tournee_detail.main_title.round_name')}</span>
                    <span className="presentation-main-title">{tournee.info.name} </span>
                  </div>
                  {false && this.isDioptase() && (
                    <Button
                      style={{ margin: 'auto' }}
                      onClick={() => {
                        dispatch(tourneeActions.rotateFiches(tournee.info.code));
                        this.setState({ init: false });
                      }}
                    >
                      Rotation
                    </Button>
                  )}
                </div>
                <div className="presentation-body">
                  <div className="display-info">
                    {this.generateLineInfo(
                      t('tournee_detail.info_container.round_code'),
                      tournee.info.code,
                      'roundcode'
                    )}
                    {this.generateLineInfoEditMsg(
                      t('tournee_detail.info_container.round_msg'),
                      _.get(tournee, 'info.message'),
                      'roundmessage'
                    )}
                    {this.generateLineInfo(
                      t('tournee_detail.info_container.start_read_date'),
                      ListTools.formatDateDay(_.get(tournee, 'info.startDate')),
                      'roundstartdate'
                    )}
                    {this.generateLineInfo(
                      t('tournee_detail.info_container.all_meter_nb'),
                      tournee.info.totalCpt,
                      'roundtotalcpt'
                    )}
                    {this.generateLineInfo(
                      t('tournee_detail.info_container.all_radio_nb'),
                      tournee.info.totalRadio,
                      'roundtotalradio'
                    )}
                    {this.generatePieDiagram(
                      t('all.meter.indexed_meter_plural'),
                      [
                        t('all.text.indexed', { count: tournee.info.indexedMeters }),
                        t('all.text.not_indexed', {
                          count: tournee.info.totalCpt - tournee.info.indexedMeters,
                        }),
                      ],
                      [tournee.info.indexedMeters, tournee.info.totalCpt - tournee.info.indexedMeters]
                    )}
                    {this.generatePieDiagram(
                      t('all.meter.modified_meter_plural'),
                      [
                        t('all.text.modified', { count: tournee.info.modifiedMeters }),
                        t('all.text.not_modified', {
                          count: tournee.info.totalCpt - tournee.info.modifiedMeters,
                        }),
                      ],
                      [tournee.info.modifiedMeters, tournee.info.totalCpt - tournee.info.modifiedMeters]
                    )}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="col-lg-9" style={{ marginBottom: '10px' }}>
              <div className="presentation-container-map-full-height">
                {init && maps && maps.markers && maps.markers.length > 0 ? (
                  /* <GoogleMapReactC
                    initialCenter={{
                      lat: _.get(maps, 'markers[0].gpsPosition.lat'),
                      lng: _.get(maps, 'markers[0].gpsPosition.lng'),
                    }}
                    zoom={13}
                    yesIWantToUseGoogleMapApiInternals
                    clickMarker={this.clickMarker}
                  /> */
                  <Mapbox
                    initialMap={{
                      baseLng: _.get(maps, 'markers[0].gpsPosition.lng'),
                      baseLat: _.get(maps, 'markers[0].gpsPosition.lat'),
                      baseZoom: 13,
                    }}
                    style={{ width: '100%', height: '82.6vh' }}
                    isRound
                    displayPopup
                    roundClickMarker={this.clickMarker}
                  />
                ) : (
                  <div className="presentation-container-map-location" style={{ borderRadius: '10px' }}>
                    <h2 style={{ margin: 'auto' }}>{t('tournee_detail.map_title.any_meter_gps')}</h2>
                  </div>
                )}
              </div>
              <br />
            </div>
            <br />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { tournee, locations, maps, authentication } = state;
  const { user } = authentication;
  return {
    tournee,
    locations,
    maps,
    user,
  };
}

const DetailTournee = withRouter(connect(mapStateToProps)(TourneeDetail));
const tr = withTranslation()(DetailTournee);
export default tr;
