import React from 'react';

const FlecheAllerLarge = (props: any) => (
  <svg viewBox="0 0 130.31 83.67" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M6.72,48.55H107.38L83.73,72.2a6.72,6.72,0,1,0,9.5,9.5l35.11-35.11a6.62,6.62,0,0,0,1.46-2.19,6.73,6.73,0,0,0-1.46-7.32L93.23,2a6.72,6.72,0,0,0-9.5,9.51l23.65,23.64H6.72a6.72,6.72,0,0,0,0,13.44Z"
        />
      </g>
    </g>
  </svg>
);

export default FlecheAllerLarge;
