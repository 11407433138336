import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import { PlayArrow } from '@material-ui/icons';
import Ajout from '../SvgComponents/AjoutBleu';
import { customExportActions, locationActions } from '../_actions';
import ListTools from '../List/ListTools';
import Loading from '../_animations/Loading';
import { Alert } from '../_entities/alert';
import EditionBleu from '../SvgComponents/EditionBleu';
import PoubelleBleue from '../SvgComponents/PoubelleBleu';
import CustomExportFillFiltersModal from './CustomExportFillFiltersModal';
import Copy from '../SvgComponents/Copy';
import FavoriActif from '../SvgComponents/FavoriActif';
import EtoileFavoris from '../SvgComponents/FavorisVert';
import SvgExportPersonalise from '../SvgComponents/ExportPersonalise';
import LoadingBand from '../Bands/Loading';

class CustomExportList extends Component {
  constructor(props) {
    super(props);
    this.launchCustomExport = this.launchCustomExport.bind(this);
    this.toggleFilters = this.toggleFilters.bind(this);

    this.state = {
      isOpen: false,
      selectedExport: null,
    };
  }

  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { locationId },
      },
    } = this.props;
    dispatch(customExportActions.getCustomExport());
    dispatch(locationActions.getInfos(locationId));
  }

  customExportFullyFilled(customExport: any, filterValues: any) {
    return _.isEmpty(
      customExport.exportFilters.filter(el => el.defineLater).filter(el => !_.has(filterValues, el.num))
    );
  }

  launchCustomExport(exportId: number, filterValues: any) {
    const { dispatch, locations, customexport } = this.props;
    if (this.customExportFullyFilled(customexport.items.find(el => el.exportId === exportId), filterValues)) {
      dispatch(
        customExportActions.launchCustomExport(exportId, _.get(locations, 'fetchedLocation.code'), filterValues)
      );
      this.toggleFilters(false);
    } else {
      this.toggleFilters(true);
    }
    this.setState({ selectedExport: customexport.items.find(el => el.exportId === exportId) });
  }

  changeFavoriState(exportId: number, newFavoriState: boolean) {
    const { dispatch } = this.props;
    dispatch(customExportActions.changeFavoriState(exportId, newFavoriState));
  }

  toggleFilters(state: boolean) {
    this.setState({ isOpen: _.isBoolean(state) ? state : !_.get(this.state, 'isOpen') });
  }

  render() {
    const {
      customexport,
      t,
      match: {
        params: { locationId },
      },
      user,
      dispatch,
    } = this.props;
    const { isOpen, selectedExport } = this.state;
    let columns = ListTools.getDefaultColumns(ListTools.typeOfList.CustomExport).map(champ => ({
      dataField: champ,
      text: t(`columns.${champ.toLowerCase()}`),
      sort: true,
      filter: textFilter({
        placeholder: t(`columns.${champ.toLowerCase()}`),
      }),
      style: { wordBreak: 'break-all' },
    }));
    columns = [
      ...columns,
      {
        dataField: 'exportOwner',
        isDummyField: true,
        align: 'center',
        text: '',
        headerStyle: () => {
          return { width: '15%', textAlign: 'left' };
        },
        formatter: (cellContent: any, row: Alert) => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                id={`customexport-${row.exportId}-favori`}
                className="round clickable"
                role="presentation"
                onClick={() => this.changeFavoriState(row.exportId, !row.favori)}
              >
                {row.favori ? (
                  <FavoriActif className="add" fill="#2c303b" height="1.5em" width="1.5em" />
                ) : (
                  <EtoileFavoris className="add" fill="#2c303b" height="1.5em" width="1.5em" />
                )}
                <UncontrolledTooltip placement="bottom" target={`customexport-${row.exportId}-favori`}>
                  {' '}
                  {row.favori ? t('dashboard.button.delete_from_favorite') : t('all.button.add_to_favorites')}
                </UncontrolledTooltip>
              </div>
              <a
                style={{ visibility: row.exportOwner.id === _.get(user, 'id') ? 'visible' : 'hidden' }}
                href={`/locations/${locationId}/customexport/editexport?id=${row.exportId}`}
              >
                <div id={`customexport-${row.exportId}-edit`} className="round" role="presentation">
                  <EditionBleu fill="curentcolor" height="1em" width="1em" />
                  <UncontrolledTooltip placement="bottom" target={`customexport-${row.exportId}-edit`}>
                    {' '}
                    {t('custom_export.text.edit_custom_export')}
                  </UncontrolledTooltip>
                </div>
              </a>
              <a href={`/locations/${locationId}/customexport/add?id=${row.exportId}`}>
                <div id={`customexport${row.exportId}-duplicate`} className="round" role="presentation">
                  <Copy fill="curentcolor" height="2em" width="2em" />
                  <UncontrolledTooltip placement="bottom" target={`customexport${row.exportId}-duplicate`}>
                    {' '}
                    {t('custom_export.text.duplicate')}
                  </UncontrolledTooltip>
                </div>
              </a>
              <div
                id={`play-${row.exportId}-customexport`}
                className="round clickable"
                role="presentation"
                onClick={() => this.launchCustomExport(row.exportId)}
              >
                <PlayArrow className="add" fill="curentcolor" height="1em" width="1em" />
                <UncontrolledTooltip placement="bottom" target={`play-${row.exportId}-customexport`}>
                  {' '}
                  {t('custom_export.text.launch_export')}
                </UncontrolledTooltip>
              </div>
              <div
                style={{ visibility: row.exportOwner.id === _.get(user, 'id') ? 'visible' : 'hidden' }}
                id={`customexport-${row.exportId}-delete`}
                className="round clickable"
                role="presentation"
                onClick={() => dispatch(customExportActions.deleteExport(row.exportId))}
              >
                <PoubelleBleue fill="curentcolor" height="1em" width="1em" />
                <UncontrolledTooltip placement="bottom" target={`customexport-${row.exportId}-delete`}>
                  {' '}
                  {t('custom_export.text.delete_export')}
                </UncontrolledTooltip>
              </div>
            </div>
          );
        },
      },
    ];
    return (
      <div className="col-12">
        {customexport.items ? (
          <div className="mask-alarm table-info-container">
            {selectedExport && isOpen && (
              <CustomExportFillFiltersModal
                launchCustomExport={this.launchCustomExport}
                isOpen={isOpen}
                show={this.toggleFilters}
                customExport={selectedExport}
              />
            )}{' '}
            <div>
              <h2>
                <span>
                  <SvgExportPersonalise height="1.2em" width="1.2em" fill="#31c6b3" />
                </span>
                {t('custom_export.title.custom_export_plural')}
                <a href={`/locations/${locationId}/customexport/add`}>
                  <span className="addItem float-right">
                    {' '}
                    <div id="addAlert" role="presentation">
                      <Ajout className="add" />
                      <UncontrolledTooltip placement="bottom" target="addAlert">
                        {' '}
                        {t('custom_export.text.add_custom_export')}
                      </UncontrolledTooltip>
                    </div>
                  </span>
                </a>
              </h2>
            </div>
            {customexport.export_processing && (
              <LoadingBand
                message={`Export "${
                  _.find(customexport.items, e => e.exportId === customexport.exportId).exportName
                }" en cours de génération ...`}
              />
            )}
            <BootstrapTable
              keyField="id"
              data={customexport.items}
              columns={columns}
              bootstrap4
              bordered={false}
              hover
              filter={filterFactory()}
              pagination={customexport.items.length > 10 ? paginationFactory() : null}
            />
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const {
    customexport,
    locations,
    authentication: { user },
  } = state;
  return {
    customexport,
    locations,
    user,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(CustomExportList)));
