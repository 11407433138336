import React from 'react';

const SmallStrongCons = (props: any) => (
  <svg viewBox="0 0 87.57 87.5" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="pictos">
        <path
          fill={props.fill}
          d="M12.91,85.29,72.44,25.76v47.6a7.57,7.57,0,1,0,15.13,0V7.56A7.56,7.56,0,0,0,80,0H14.2a7.57,7.57,0,0,0,0,15.13H61.81L2.21,74.62a7.56,7.56,0,1,0,10.7,10.67"
        />
      </g>
    </g>
  </svg>
);
export default SmallStrongCons;
