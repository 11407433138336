import React from 'react';
import './style.scss';
import HistoriqueWithStub from './HistoriqueWithStub';
import stub from './stub';
import GoogleMapReactC from '../GoogleMap/GoogleMapReactC';

const infoList = [
  {
    title: 'Etat',
    value: 'Actif',
  },
  {
    title: 'Adresse',
    value: "2 Rue du plat d'Etain",
  },
  {
    title: 'Informations abonné',
    value: 'Zagor - 37000 Tours No: 56184 4950',
  },
];

const GenerateInfoWithMap = () => (
  <div className="info-container">
    <div className="info-block">
      <div className="header">
        <div className={'title'}>
          <span>Référence du capteur</span>
          <h4>56184</h4>
        </div>
      </div>
      <div className="content">
        {infoList.map((el: any) => (
          <div className="info-box">
            <span>{el.title}</span>
            <h3>{el.value}</h3>
          </div>
        ))}
      </div>
    </div>
    <div className="map">
      <div className="map-container" style={{ backgroundColor: 'lightgrey' }}>
        <GoogleMapReactC
          initialCenter={{
            lat: 47.384347800069825,
            lng: 0.6761473268801241,
          }}
          zoom={16}
          centerMarker={{
            lat: 47.384347800069825,
            lng: 0.6761473268801241,
            icon: 'home_marker',
          }}
          yesIWantToUseGoogleMapApiInternals
        />
      </div>
    </div>
  </div>
);

const SensorAlarms = () => {
  const list = ['A', 'B', 'C', 'D'];
  const sensorInfo = GenerateInfoWithMap();
  return (
    <div className="sensor-graph-container">
      {sensorInfo}
      <div className="sensors-wrapper">
        <div className="title">
          <h2>Etats des vannes</h2>
        </div>
        <div className="sensor-container">
          {list.map((el: any) => (
            <HistoriqueWithStub sensor={stub} title={`Capteur canal ${el}`} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SensorAlarms;
