import { AnyRecord } from 'dns';
import mapboxConstants from '../_constants/mapbox.constants';
import { action } from '../_interfaces/action';

export default function mapbox(state = {}, action: any) {
  switch (action.type) {
    case mapboxConstants.GEOJSON_SUCCESS:
      return {
        ...state,
        geojsonLayer: action.geojson,
      };
    case mapboxConstants.GEOJSON_FAILURE:
      return {
        ...state,
        error: "Impossible d'obtenir la liste distante des geojson",
      };
    case mapboxConstants.METER_SUCCESS:
      return {
        ...state,
        meters: action.meters,
      };
    case mapboxConstants.METER_FAILURE:
      return {
        ...state,
        error: "Impossible d'obtenir la liste distante des gateways",
      };
    case mapboxConstants.GATEWAY_SUCCESS:
      return {
        ...state,
        gateways: action.gateways,
      };
    case mapboxConstants.GATEWAY_FAILURE:
      return {
        ...state,
        error: "Impossible d'obtenir la liste distante des gateways",
      };
    case mapboxConstants.POLYGONS_SUCCESS:
      return {
        ...state,
        polygons: action.polygons,
      };
    case mapboxConstants.POLYGONS_FAILURE:
      return {
        ...state,
        error: "Impossible d'obtenir la liste des polygons",
      };
    case mapboxConstants.POST_POLYGONS_SUCCESS:
      return {
        ...state,
        polygons: action.polygons,
      };
    case mapboxConstants.POST_POLYGONS_FAILURE:
      return {
        ...state,
        error: "Impossible d'envoyer la liste des polygons",
      };
    case mapboxConstants.GET_POINTS_WITHIN_SUCCESS:
      return {
        ...state,
        polygons: action.polygons,
      };
    case mapboxConstants.GET_POINTS_WITHIN_FAILURE:
      return {
        ...state,
        error: "Impossible d'obtenir la liste des points à l'intérieur des polygons",
      };
    case mapboxConstants.SEND_ROUND_SUCCESS:
      return {
        ...state,
        round: action.round,
      };
    case mapboxConstants.SEND_ROUND_FAILURE:
      return {
        ...state,
        error: "Impossible d'envoyer la liste des tournées",
      };
    case mapboxConstants.RANGE_ON_SUCCESS:
      return {
        ...state,
        rangeOn: action.rangeOn,
      };

    case mapboxConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
