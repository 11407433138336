const reportConstants = {
  GETALL_REQUEST: 'NOTIFICATIONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'NOTIFICATIONS_GETALL_SUCCESS',
  GETALL_FAILURE: 'NOTIFICATIONS_GETALL_FAILURE',

  GET_REQUEST: 'NOTIFICATIONS_GET_REQUEST',
  GET_SUCCESS: 'NOTIFICATIONS_GET_SUCCESS',
  GET_FAILURE: 'NOTIFICATIONS_GET_FAILURE',

  CLEAR: 'NOTIFICATIONS_CLEAR',
};

export default reportConstants;
