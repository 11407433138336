import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { dashboardActions } from '../_actions';
import { withTranslation } from 'react-i18next';

type State = {
  name: string;
  saved: boolean;
  dashboard: any;
};
/**
 * @class EditDashBoardComponent
 * @extends {React.Component<Props, State>}
 */
class EditDashBoardComponent extends React.Component<any, State> {
  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof EditDashBoardComponent
   */
  constructor(props: any) {
    super(props);
    this.state = {
      name: '',
      saved: false,
      dashboard: {},
    };
  }

  /**
   * Met à jour le state en fonction de l'état d'edition
   * du dashboard
   *
   * @static
   * @param {Props} props
   * @param {State} state
   * @returns {State} Le nouveau state
   * @method getDerivedStateFromProps
   * @memberof EditDashBoardComponent
   */
  static getDerivedStateFromProps(props: any, state: State) {
    if (Object.keys(state.dashboard).length === 0 && props.dashboard.id) {
      return { dashboard: props.dashboard, name: props.dashboard.name, saved: false };
    }
    if (
      state.dashboard &&
      props.dashboard &&
      state.dashboard.id === props.dashboard.id &&
      props.alert.type === 'alert-success'
    ) {
      return { dashboard: {}, name: '', saved: true };
    }
    if (state.dashboard.id !== props.dashboard.id) {
      return { dashboard: props.dashboard, name: props.dashboard.name, saved: false };
    }
    return null;
  }

  /**
   * Ferme la modal d'édition dans le cas où le
   * dashboard a été sauvegardé
   *
   * @param {Props} prevProps
   * @param {State} prevState
   * @method componentDidUpdate
   * @memberof EditDashBoardComponent
   */
  componentDidUpdate(prevProps: any, prevState: State) {
    const { name } = this.state;
    const { isEdited } = this.props;
    if (name.length > 0 && !prevState.saved) {
      isEdited();
    }
  }

  /**
   * Gère les droits d'édition du dashboard
   *
   * @returns {boolean} Le contrôle
   * @method couldEditDashboard
   * @memberof EditDashBoardComponent
   */
  couldEditDashboard = () => {
    const { user, users, locations } = this.props;
    const { dashboard } = this.state;

    const roleList = ['DIOPTASE', 'SUPERADMIN', 'ADMIN'];
    if (roleList.includes(user.role.name)) {
      return true;
    }

    // si le dashboard est un personal
    if (dashboard && (dashboard.personal || dashboard.private)) {
      return false;
    }
    if (locations.fetchedLocation && locations.fetchedLocation.tournee) {
      //CETTE LIGNE ALEXIS
      return true;
    }

    if (
      users &&
      users.fetchedUser &&
      locations.fetchedLocation &&
      users.fetchedUser.profils.find(
        (el: any) =>
          el.profil.permissions.find((permission: any) => permission.name === 'edit.dashboard') &&
          el.locationCode === locations.fetchedLocation.code
      )
    ) {
      return true;
    }

    // regarder les permissions si l'utilisateur peut ajouter son dashboard
    // regarder les permissions et si la personne est propriétaire du dashboard
    if (dashboard && dashboard.owner && user.id === dashboard.owner.id) {
      return true;
    }

    return false;
  };

  /**
   * Met à jour le dashboard à la soumission valide
   * du formulaire
   *
   * @param {Object} event Evènement
   * @param {Object} values Valeurs du formulaire
   * @method handleValidSubmit
   * @memberof EditDashBoardComponent
   */
  handleValidSubmit = (event: Object, values: any) => {
    const { dispatch, dashboards } = this.props;
    const { dashboard } = this.state;
    this.setState({
      name: values.name,
    });
    const dashboardtoUpdate = Object.assign({}, dashboard);
    dashboardtoUpdate.name = values.name;
    dispatch(dashboardActions.update(dashboardtoUpdate, dashboards.items));
  };

  /**
   * Gère la soumission invalide du formulaire
   *
   * @param {any} values Valeurs du formulaire
   * @method handleInvalidSubmit
   * @memberof EditDashBoardComponent
   */
  handleInvalidSubmit = (values: any) => {
    this.setState({
      name: values.name,
    });
  };

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof EditDashBoardComponent
   */
  render() {
    const { className, alert, edit, isOpen, t } = this.props;
    const { name, saved } = this.state;

    return (
      <div>
        {this.couldEditDashboard() && (
          <div>
            <Modal isOpen={isOpen} toggle={edit} className={className}>
              <ModalHeader toggle={edit}>{t('edit_dashboard.main_title.edit_dashboard')}</ModalHeader>
              <AvForm onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
                <ModalBody>
                  <div>{alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}</div>
                  {!saved && (
                    <div>
                      <AvField
                        name="name"
                        value={name}
                        label={t('edit_dashboard.name_field_label.dashboard_name')}
                        required
                        errorMessage={t('edit_dashboard.name_field_error_msg.name_need_least_2_char')}
                      />
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  {!saved && (
                    <Button color="danger" onClick={edit}>
                      {t('all.button.cancel')}
                    </Button>
                  )}
                  {!saved && <Button color="secondary">{t('all.button.register')}</Button>}
                </ModalFooter>
              </AvForm>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, locations, dashboards, alert, users } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    locations,
    dashboards,
    users,
  };
}

const connectedEditDashBoard = connect(mapStateToProps)(EditDashBoardComponent);
const tr = withTranslation()(connectedEditDashBoard);
export default tr;
