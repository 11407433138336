import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import HomeSiteVert from '../SvgComponents/HomeSiteVert';
import { Row } from 'reactstrap';
import PointCollecteVert from '../SvgComponents/PointCollecteVert';

interface Props {
  paths: Array<string>;
  onClick: Function;
}

interface State {
  hasDefaultHeight: boolean;
  defaultHeight: number;
}

/**
 * @class CrumbComponent
 * @extends {Component}
 */
class CrumbComponent extends React.Component<Props, State> {
  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      hasDefaultHeight: false,
      defaultHeight: 0,
    };
  }

  /**
   * Calcul la taille du Crumb total
   *
   * @method getSizeHiddenDiv
   */
  getSizeHiddenDiv() {
    const obj = document.getElementById('crumbDisplay');
    return obj ? obj.clientHeight : 0;
  }

  /**
   * Calcul le premier élément à afficher en gardant les éléments sur une seule ligne
   *
   * @method getFirstElementToDisplay
   */
  getFirstElementToDisplay() {
    const { paths } = this.props;
    const { defaultHeight } = this.state;
    let actualSize = this.getSizeHiddenDiv();
    let index = -1;

    while (index < paths.length && actualSize > defaultHeight) {
      index++;
      const name = paths[index];
      const obj = document.getElementById(name);
      if (obj) obj.style.display = 'none';
      actualSize = this.getSizeHiddenDiv();
    }

    return index;
  }

  /**
   * Créer les éléments Crumb cachés
   *
   * @method createHiddenElements
   */
  createHiddenElements() {
    const { onClick, paths } = this.props;

    return paths.map((name, index) => {
      if (index != paths.length - 1) {
        return (
          <span key={name} style={{ marginBottom: '9px', alignSelf: 'center', marginLeft: index === 0 ? '-5px' : '' }}>
            &#62;
            <span onClick={() => onClick(index)}>
              <span id={index + 'Icon'} className="crumbTooltip" style={{ cursor: 'pointer', display: 'none' }}>
                <PointCollecteVert height="1.2em" width="1.1em" fill="#31c6b3" />
                <span className="tooltipText">&nbsp;&nbsp;{name}&nbsp;&nbsp;</span>
              </span>
              <span id={name} style={{ cursor: 'pointer', visibility: 'hidden' }}>
                &nbsp;&nbsp;{name}&nbsp;
              </span>
            </span>
          </span>
        );
      }
    });
  }

  /**
   * Change l'état des Crumb pour les visualiser à partir d'un index
   *
   * @method displayFromElement
   */
  displayFromElement(firstIndex: number) {
    const { paths } = this.props;

    return paths.map((name, index) => {
      const obj = document.getElementById(name);
      const icon = document.getElementById(index + 'Icon');

      if (obj && icon) {
        obj.style.display = 'initial';
        obj.style.visibility = 'initial';
        icon.style.display = 'none';
        if (firstIndex != -1 && index <= firstIndex) {
          icon.style.display = 'initial';
          obj.style.display = 'none';
        }
      }
    });
  }

  /**
   * Appel les méthodes nécessaires au bon affichage des Crumbs
   *
   * @method displayVisibleElements
   */
  displayVisibleElements() {
    this.resetExistingElements();
    this.centerTooltipText();

    this.displayFromElement(this.getFirstElementToDisplay());
  }

  /**
   * Remet à zéro l'état caché de tous les Crumbs crées
   *
   * @method resetExistingElements
   */

  resetExistingElements() {
    const { paths } = this.props;

    paths.map(el => {
      const obj = document.getElementById(el);
      if (obj) {
        obj.style.display = 'initial';
        obj.style.visibility = 'hidden';
      }
    });
  }

  /**
   * Calcul la marge nécessaire pour que le Tooltip soit centré
   *
   * @method centerTooltipText
   */
  centerTooltipText() {
    const { paths } = this.props;
    paths.map((el, index) => {
      const iconObj = document.getElementById(index + 'Icon');
      if (iconObj) {
        const tooltipText = iconObj.getElementsByClassName('tooltipText');
        if (tooltipText.length > 0) {
          const size = tooltipText[0].clientWidth / 2;
          tooltipText[0].style.marginLeft = '-' + size + 'px';
        }
      }
    });
  }

  getDefaultHeight() {
    const { hasDefaultHeight } = this.state;

    if (!hasDefaultHeight) {
      const obj = document.getElementById('crumbDisplay');

      if (obj) {
        this.setState({
          hasDefaultHeight: true,
          defaultHeight: obj.clientHeight + obj.clientHeight / 3,
        });
      }
    }
  }

  /**
   * Rends le composant
   *
   * @method render
   */
  render() {
    const { paths, onClick } = this.props;

    this.getDefaultHeight();

    return (
      <Row className="crumbContainer" style={{ margin: '0 22px 0 5px' }}>
        <Row
          className="crumbContent"
          id="crumbDisplay"
          style={{ width: 'fit-content', marginTop: '10px', marginLeft: '-5px' }}
        >
          <span id="home" style={{ cursor: paths && paths.length > 0 ? 'pointer' : '' }} onClick={() => onClick(-1)}>
            <HomeSiteVert height="3em" width="3em" fill="#31c6b3" />
            &nbsp;
          </span>
          {paths && paths.length > 0 && this.createHiddenElements()}
          {this.displayVisibleElements()}
        </Row>
      </Row>
    );
  }
}

function mapStateToProps(state: any) {
  return {};
}

const mapping: any = connect(mapStateToProps)(CrumbComponent);

const connectedCrumb = withRouter(mapping);
export default connectedCrumb;
