import React from 'react';
import { withTranslation } from 'react-i18next';

type Props = any;
type State = {
  hasError: boolean;
};

/**
 * @class ErrorBoundary
 * @extends Component
 */
class ErrorBoundary extends React.Component<Props, State> {
  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  /**
   * Méthode gérant les erreurs remontées
   *
   * @method componentDidCatch
   * @param {any} error Erreur
   * @param {any} info Info
   */
  componentDidCatch(error: any, info: any) {
    // Display fallback UI
    this.setState({ hasError: true });
    console.log(error);
    console.log(info);
  }

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { hasError } = this.state;
    const { children, t } = this.props;

    if (hasError) {
      return <p>{t('error_boundary.main_title.cannot_show_page')}</p>;
    }

    return children;
  }
}

const tr = withTranslation()(ErrorBoundary);
export default tr;
