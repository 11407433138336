const meterConstants = {
  GETALL_REQUEST: 'METERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'METERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'METERS_GETALL_FAILURE',

  GETALLWITHCHILDREN_REQUEST: 'METERS_GETALLWITHCHILDREN_REQUEST',
  GETALLWITHCHILDREN_SUCCESS: 'METERS_GETALLWITHCHILDREN_SUCCESS',
  GETALLWITHCHILDREN_FAILURE: 'METERS_GETALLWITHCHILDREN_FAILURE',

  GETALLFROMLOCATION_REQUEST: 'METERS_GETALLFROMLOCATION_REQUEST',
  GETALLFROMLOCATION_SUCCESS: 'METERS_GETALLFROMLOCATION_SUCCESS',
  GETALLFROMLOCATION_FAILURE: 'METERS_GETALLFROMLOCATION_FAILURE',

  GETUNLINKEDMETERS_REQUEST: 'METERS_UNLINKEDMETERS_REQUEST',
  GETUNLINKEDMETERS_SUCCESS: 'METERS_UNLINKEDMETERS_SUCCESS',
  GETUNLINKEDMETERS_FAILURE: 'METERS_UNLINKEDMETERS_FAILURE',

  GETMANUFACTURERS_REQUEST: 'METERS_GETMANUFACTURERS_REQUEST',
  GETMANUFACTURERS_SUCCESS: 'METERS_GETMANUFACTURERS_SUCCESS',
  GETMANUFACTURERS_FAILURE: 'METERS_GETMANUFACTURERS_FAILURE',

  GETINFO_REQUEST: 'METERS_GETINFO_REQUEST',
  GETINFO_SUCCESS: 'METERS_GETINFO_SUCCESS',
  GETINFO_FAILURE: 'METERS_GETINFO_FAILURE',

  GET_METER_INFOS_DATA_REQUEST: 'GET_METER_INFOS_DATA_REQUEST',
  GET_METER_INFOS_DATA_SUCCESS: 'GET_METER_INFOS_DATA_SUCCESS',
  GET_METER_INFOS_DATA_FAILURE: 'GET_METER_INFOS_DATA_FAILURE',

  GETALLINFO_REQUEST: 'METERS_GETALLINFO_REQUEST',
  GETALLINFO_SUCCESS: 'METERS_GETALLINFO_SUCCESS',
  GETALLINFO_FAILURE: 'METERS_GETALLINFO_FAILURE',

  CREATEMETER_REQUEST: 'METERS_CREATEMETER_REQUEST',
  CREATEMETER_SUCCESS: 'METERS_CREATEMETER_SUCCESS',
  CREATEMETER_FAILURE: 'METERS_CREATEMETER_FAILURE',

  FINDINFOWITHSERIAL_REQUEST: 'METERS_FINDINFOWITHSERIAL_REQUEST',
  FINDINFOWITHSERIAL_SUCCESS: 'METERS_FINDINFOWITHSERIAL_SUCCESS',
  FINDINFOWITHSERIAL_FAILURE: 'METERS_FINDINFOWITHSERIAL_FAILURE',

  GETSTATES_REQUEST: 'METERS_GETSTATES_REQUEST',
  GETSTATES_SUCCESS: 'METERS_GETSTATES_SUCCESS',
  GETSTATES_FAILURE: 'METERS_GETSTATES_FAILURE',

  GETALARMS_REQUEST: 'METERS_GETALARMS_REQUEST',
  GETALARMS_SUCCESS: 'METERS_GETALARMS_SUCCESS',
  GETALARMS_FAILURE: 'METERS_GETALARMS_FAILURE',

  GETDATA_REQUEST: 'METERS_GETDATA_REQUEST',
  GETDATA_SUCCESS: 'METERS_GETDATA_SUCCESS',
  GETDATA_FAILURE: 'METERS_GETDATA_FAILURE',

  GET_ALLFROMPDI_REQUEST: 'GET_ALLFROMPDI_REQUEST',
  GET_ALLFROMPDI_TOOLONG: 'GET_ALLFROMPDI_TOOLONG',
  GET_ALLFROMPDI_SUCCESS: 'GET_ALLFROMPDI_SUCCESS',
  GET_ALLFROMPDI_FAILURE: 'GET_ALLFROMPDI_FAILURE',

  CLEAR: 'METERS_CLEAR',
};

export default meterConstants;
