import { radioConstants } from '../_constants';
import { radioService } from '../_services';
import { meterService } from '../_services';

/**
 * Récupère toutes les radios du site courant
 *
 * @method getAll
 * @param {number} siteId Id du site
 * @returns {Object} Les radios
 */
function getAll(siteId: number) {
  function request() {
    return { type: radioConstants.GETALL_REQUEST };
  }
  function success(radios: Object) {
    return { type: radioConstants.GETALL_SUCCESS, radios };
  }
  function failure(error: Object) {
    return { type: radioConstants.GETALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    radioService
      .getAll(siteId)
      .then((radios: Object) => dispatch(success(radios)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère toutes les radios du site courant
 *
 * @method getAll
 * @param {number} siteId Id du site
 * @returns {Object} Les radios
 */
function getAllWithChildren(siteId: number) {
  function request() {
    return { type: radioConstants.GETALLWITHCHILDREN_REQUEST };
  }
  function success(radios: Object) {
    return { type: radioConstants.GETALLWITHCHILDREN_SUCCESS, radios };
  }
  function failure(error: Object) {
    return { type: radioConstants.GETALLWITHCHILDREN_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    radioService
      .getAllWithChildren(siteId)
      .then((radios: Object) => dispatch(success(radios)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère les infos de la radio, ainsi que les lectures et alarmes
 *
 * @method getInfos
 * @param {number} siteId Id du site
 * @param {string} param Id radio
 * @param {any} contentStatements Lectures
 * @param {any} contentAlarms Alarmes
 * @returns {Object} Toutes les infos de la radio
 */
function getInfos(siteId: number, param: string, contentStatements: any, contentAlarms: any) {
  const dataStatements = contentStatements;
  const dataAlarms = contentAlarms;
  let radio: any;
  function request() {
    return { type: radioConstants.GETINFO_REQUEST };
  }
  function success(fetchedRadio: Object) {
    return { type: radioConstants.GETINFO_SUCCESS, fetchedRadio };
  }
  function failure(error: Object) {
    return { type: radioConstants.GETINFO_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    radioService
      .getInfos(siteId, param)
      .then((fetchedRadio: Object) => {
        radio = fetchedRadio;
        if (radio.general) {
          dataStatements.dataSourceProperty.displayProperty.condition[0].conditionValue = radio.general.serial;
        }
        return radioService.getStatements(siteId, dataStatements);
      })
      .then((statements: any) => {
        radio.statements = statements;
        if (radio.general.meterId && radio.general.meterId !== 0) {
          return meterService
            .getInfos(siteId, `?id=${radio.general.meterId}`)
            .then((meter: any) => {
              if (meter !== null) {
                dataAlarms.dataSourceProperty.displayProperty.condition[0].conditionValue = meter.general.serial;
              }
              return radioService.getAlarms(siteId, dataAlarms);
            })
            .then((data: any) => {
              radio.alarms = data;
              dispatch(success(radio));
            });
        } else {
          dispatch(success(radio));
        }
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère toutes les lectures de la radio passée en
 * paramètre
 *
 * @method getStatements
 * @param {number} siteId Id du site
 * @param {any} content Conditions
 * @param {any} currentRadio Compteur
 * @returns {Object} Lectures
 */
function getStatements(siteId: number, content: any, currentRadio: any) {
  const radio = currentRadio;
  function success(fetchedRadio: Object) {
    return { type: radioConstants.GETSTATES_SUCCESS, fetchedRadio };
  }
  function failure(error: Object) {
    return { type: radioConstants.GETSTATES_FAILURE, error };
  }
  return (dispatch: Function) => {
    radioService
      .getStatements(siteId, content)
      .then((statements: any) => {
        radio.statements = statements;
        dispatch(success(radio));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère toutes les alarmes de la radio passée en
 * paramètre
 *
 * @method getAlarms
 * @param {number} siteId Id du site
 * @param {any} content Conditions
 * @param {any} currentRadio Radio
 * @returns {Object} Alarmes
 */
function getAlarms(siteId: number, content: any, currentRadio: any) {
  const radio = currentRadio;
  function success(fetchedRadio: Object) {
    return { type: radioConstants.GETALARMS_SUCCESS, fetchedRadio };
  }
  function failure(error: Object) {
    return { type: radioConstants.GETALARMS_FAILURE, error };
  }
  return (dispatch: Function) => {
    radioService
      .getAlarms(siteId, content)
      .then((alarms: any) => {
        radio.alarms = alarms;
        dispatch(success(radio));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère toutes les lectures et alarmes de la radio
 * passée en paramètre
 *
 * @method getStatementsAndAlarms
 * @param {number} siteId Id du site
 * @param {any} contentStatements Conditions des lectures
 * @param {any} contentAlarms Conditions des alarmes
 * @param {any} currentMeter Compteur
 * @returns {Object} Lectures et alarmes
 */
function getStatementsAndAlarms(siteId: number, contentStatements: any, contentAlarms: any, currentRadio: any) {
  const radio = currentRadio;
  function success(fetchedRadio: Object) {
    return { type: radioConstants.GETALARMS_SUCCESS, fetchedRadio };
  }
  function failure(error: Object) {
    return { type: radioConstants.GETALARMS_FAILURE, error };
  }
  return (dispatch: Function) => {
    Promise.all([radioService.getStatements(siteId, contentStatements), radioService.getAlarms(siteId, contentAlarms)])
      .then((data: any) => {
        radio.statements = data[0];
        radio.alarms = data[1];
        dispatch(success(radio));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getRadioInfoWithSerial(serial: string) {
  function request() {
    return { type: radioConstants.GET_INFO_REQUEST };
  }
  function success(fetchedRadio: Object) {
    return { type: radioConstants.GET_INFO_SUCCESS, fetchedRadio };
  }
  function failure(error: Object) {
    return { type: radioConstants.GET_INFO_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    radioService
      .getRadioInfoWithSerial(serial)
      .then((radio: any) => {
        dispatch(success(radio));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Vide le state des radios
 *
 * @method clear
 */
function clear() {
  return {
    type: radioConstants.CLEAR,
  };
}

const radioActions = {
  getAll,
  getAllWithChildren,
  getInfos,
  getStatements,
  getAlarms,
  getStatementsAndAlarms,
  getRadioInfoWithSerial,
  clear,
};

export default radioActions;
