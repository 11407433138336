import React, { useEffect, useState } from 'react';
import './style.scss';
import { Line } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import InfoMap from './InfoMap';
import Rouage from './Rouage';
import sensorActions from '../_actions/sensor.actions';
import AlertBar from './AlertBar';
import _ from 'lodash';

const SensorAdeComfortCO2 = ({ sensor, users, dispatch, t, ...props }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dropValue, setDropValue] = useState('minutes');
  const [isRight, setRight] = useState(false);
  const [pOptions, setOptions] = useState({
    usersMail: [],
    retention: '',
    fields: {},
    continuousAlertSending: false,
  });

  useEffect(() => {
    const info = _.get(sensor, 'sensor.info.sensorAlert');
    if (info) {
      setDropValue(_.get(info, 'retention.unit'));
      setOptions(info);
      setSelectedUsers(_.get(info, 'usersMail', []));
      setRight(!_.get(info, 'continuousAlertSending'));
    }
  }, [sensor]);
  const temperatures = _.get(sensor, 'sensor.data.temperature');

  const listChart = ['temperature', 'humidity', 'co2'].map(field => [
    field,
    (canvas: any) => {
      const ctx = canvas.getContext('2d');
      const gradient0 = ctx.createLinearGradient(0, 375, -1, 0);
      gradient0.addColorStop(1, '#31c6b3');
      gradient0.addColorStop(0, 'transparent');
      return {
        labels: _.keys(_.get(sensor, `sensor.data.${field}`)).map(d => moment.utc(d).format('DD/MM/YYYY HH:mm')),
        datasets: [
          {
            label: 'Max',
            data: _.keys(temperatures).map(el => _.get(sensor, `sensor.info.sensorAlert.fields.${field}.max`)),
            fill: false,
            borderColor: '#C42021b8',
            pointRadius: 0,
          },
          {
            label: 'Min',
            data: _.keys(temperatures).map(el => _.get(sensor, `sensor.info.sensorAlert.fields.${field}.min`)),
            fill: false,
            borderColor: '#1E78C2b8',
            pointRadius: 0,
          },
          {
            label: field,
            data: _.values(_.get(sensor, `sensor.data.${field}`)),
            fill: false,
            backgroundColor: gradient0,
            borderColor: '#31c6b3',
            fill: false,
          },
        ].filter(el => _.size(el) > 0),
      };
    },
  ]);

  const handleValidOptions = (minMax: any) => {
    const copyOptions = _.cloneDeep(pOptions);
    _.set(copyOptions, 'fields', minMax);
    if (isRight) {
      _.set(copyOptions, 'retention', null);
    } else {
      _.set(copyOptions, 'retention.unit', dropValue);
    }
    _.set(copyOptions, 'usersMail', selectedUsers);
    _.set(copyOptions, 'continuousAlertSending', !isRight);
    dispatch(sensorActions.addAlert(copyOptions, _.get(sensor, 'sensor.info.sensorId')));
  };

  const options = {
    plugins: {
      t1: false,
      datalabels: {
        display: false,
      },
    },
    legend: {
      display: false,
    },
    spanGaps: true,
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const alertProps = {
    options: pOptions,
    users,
    setDropValue,
    setSelectedUsers,
    setOptions,
    selectedUsers,
    dropValue,
    setRight,
    isRight,
  };

  return (
    <div className="sensor-graph-container">
      <div className="info-container">
        <InfoMap />
      </div>
      {users.items && _.get(sensor, 'sensor.info.sensorAlert') && (
        <AlertBar
          t={t}
          {...alertProps}
          info={_.get(sensor, 'sensor.info.sensorAlert')}
          setRight={setRight}
          isRight={isRight}
        />
      )}
      <div className="sensors-wrapper">
        <div className="title">
          <h2>Qualité d'air Intérieure</h2>
        </div>
        <div className="charts-container">
          {listChart.map(chart => (
            <div className="chart-container" style={{ padding: 0 }}>
              <div className="flex-between">
                <h3>{t(`all.text.${chart[0]}`)}</h3>
                {users && (
                  <Rouage
                    alert={sensor.sensor.info.sensorAlert}
                    users={users.items}
                    validFunction={handleValidOptions}
                    themeKey={chart[0]}
                  />
                )}
              </div>
              <Line options={options} data={chart[1]} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { sensor, users } = state;
  return {
    sensor,
    users,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(SensorAdeComfortCO2)));
