import React from 'react';

const SvgModeleVert = (props: any) => (
  <svg viewBox="0 0 30.72 24.2" {...props}>
    <g id="modele_vert_svg__Calque_2" data-name="Calque 2">
      <g id="modele_vert_svg__Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          d="M15.36 7a1 1 0 0 1-1.06-1V3L8.21 2 2.13 3v3a1 1 0 0 1-1.07 1A1 1 0 0 1 0 6V2.16a1 1 0 0 1 .89-1L8 0h.34l7.15 1.15a1 1 0 0 1 .89 1V6a1 1 0 0 1-1.06 1"
        />
        <path
          fill={props.fill}
          d="M29.66 7a1 1 0 0 1-1.07-1V3l-6.08-1-6.09 1v3a1.06 1.06 0 0 1-2.12 0V2.16a1 1 0 0 1 .88-1L22.33 0h.35l7.15 1.15a1 1 0 0 1 .89 1V6a1 1 0 0 1-1.06 1M22.51 24.2h-.18L15.18 23a1 1 0 0 1-.88-1v-3.81a1.06 1.06 0 0 1 2.12 0v3l6.09 1 6.08-1v-3a1.07 1.07 0 0 1 2.13 0V22a1 1 0 0 1-.89 1l-7.15 1.14h-.17"
        />
        <path
          fill={props.fill}
          d="M8.21 24.2h-.17L.89 23A1 1 0 0 1 0 22v-3.81a1 1 0 0 1 1.06-1 1 1 0 0 1 1.06 1v3l6.09 1 6.09-1v-3a1.06 1.06 0 0 1 2.12 0V22a1 1 0 0 1-.89 1l-7.14 1.18h-.18"
        />
      </g>
    </g>
  </svg>
);

export default SvgModeleVert;
