import React from 'react';

const SvgAjoutBleu = (props: any) => (
  <svg viewBox="0 0 13.35 13.35" {...props}>
    <path
      d="M12.4 5.72H7.63V1a1 1 0 1 0-1.91 0v4.72H1a1 1 0 0 0-.95 1 .94.94 0 0 0 1 1h4.67v4.68a1 1 0 0 0 1.91 0V7.63h4.77a1 1 0 0 0 0-1.91z"
      fill={props.fill}
      data-name="Calque 2"
    />
  </svg>
);

export default SvgAjoutBleu;
