import React, { Component } from 'react';
import SvgMultiDragVert from '../../SvgComponents/MultiDragVert';
import { withTranslation } from 'react-i18next';

class MultiSelectionButton extends Component<any, any> {
  constructor(props: Readonly<any>) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  render() {
    const { hoverMulti, handleChangeChecked, t } = this.props;
    const { checked } = this.state;
    return (
      <div
        className={`title${checked ? ' checked' : ''}`}
        id={'svg-multi-selection'}
        onClick={() => {
          this.setState({
            checked: !checked,
          });
          handleChangeChecked(!checked);
        }}
      >
        <SvgMultiDragVert height={'2em'} />
        {hoverMulti && (
          <div className={'span-button'}>
            <h5 style={{ color: hoverMulti ? (checked ? 'white' : '#31c6b3') : 'white' }}>
              {t('all.text.multiple_select')}
            </h5>
          </div>
        )}
      </div>
    );
  }
}

const tr = withTranslation()(MultiSelectionButton);
export default tr;
