import localeConstants from '../_constants/locale.constants';
import { action } from '../_interfaces/action';

export default function locales(state = {}, action: action) {
  switch (action.type) {
    case localeConstants.LOADED:
      return {
        locale: action.locale,
      };
    default:
      return state;
  }
}
