import React, { PureComponent } from 'react';
import _ from 'lodash';
import getC from '../TourneeFiches/Picto_correspondance';
import { connect } from 'react-redux';
import ClusterMeterList, { ClusterMeter } from './ClusterMeterList';
import { orderByMeterState } from './OrderByMeterState';
import { withTranslation } from 'react-i18next';
import RemoteReadingData from '../RemoteReadingData';

export interface Props {
  clickCluster: Function;
  clickMeter: Function;
  clusterId: string;
  markerCount: number;
  lat: number;
  lng: number;
  locales: any;
  colorCluster: Map<string, number>;
  meterClusters: Array<ClusterMeter>;
  unselectCluster: Function;
  isSelected: boolean;
  meterSelected: number;
  allowClusterList?: boolean;
  t: Function;
}
export interface State {
  clicked: boolean;
}

class ClusterMarkerGM extends PureComponent<Props, State> {
  generateLineInfo(meterState: string, meterNb: number) {
    const { t } = this.props;
    return (
      <p style={{ margin: '10px 0 0 0' }}>
        {getC(meterState, { height: '15px', style: { marginBottom: '6px' } })}
        <span className="nbMeter">{meterNb}</span>
        <span style={{ paddingLeft: '5px' }}>{t('releve_meter_status.text.' + meterState)}</span>
      </p>
    );
  }

  render() {
    const {
      clickCluster,
      clusterId,
      lat,
      lng,
      markerCount,
      colorCluster,
      meterClusters,
      isSelected,
      unselectCluster,
      clickMeter,
      meterSelected,
      allowClusterList,
      t,
    } = this.props;
    const colorList = {};

    _.map(colorCluster, (value, key) => {
      const color = key.split('_')[0];
      colorList[color] = colorList[color] ? colorList[color] + value : value;
    });

    const colorClusterSize = _.size(colorList);
    const clusterIconKey = 'cluster' + '_' + colorClusterSize;

    return (
      <>
        <div
          key={`meter-${clusterId}`}
          className="clickable clusterCC"
          lat={lat}
          lng={lng}
          onClick={() => clickCluster(clusterId, lat, lng, meterClusters)}
          style={{ position: 'relative', width: 'fit-content', height: 'fit-content' }}
        >
          {getC(clusterIconKey, { height: '6em', width: '6em', colorCluster: colorList })}
          <span className="absoluteCentered" style={{ color: 'black', fontWeight: 'bold', fontSize: '1em' }}>
            {markerCount}
          </span>
          <div className="popupCluster absoluteVerticalCenter">
            <h3>{t('cluster_marker_gm.title.meter_detail_plural')}</h3>
            {_.map(orderByMeterState(colorCluster), (value, key) => this.generateLineInfo(key, value))}
          </div>
        </div>
        <div className="popupClusterMeterList absoluteVerticalCenter">
          {allowClusterList && meterClusters.length > 0 && isSelected && (
            <ClusterMeterList
              clusterMeter={meterClusters}
              closePopup={() => unselectCluster()}
              onClickMeter={(meterId: number) => clickMeter(meterId)}
              meterSelected={meterSelected}
            />
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { locales } = state;

  return {
    locales,
  };
}

export default withTranslation()(connect(mapStateToProps)(ClusterMarkerGM));
