import React from 'react';

const SvgPetitEcranVert = (props: any) => (
  <svg viewBox="0 0 119.98 119.98" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M17.62,40.12v7.5H43.87a3.75,3.75,0,0,0,3.75-3.75V17.62h-7.5V34.83L5.29,0,0,5.29,34.83,40.12Z"
        />
        <path
          fill={props.fill}
          d="M76.11,47.62h26.25v-7.5H85.15L120,5.29,114.69,0,79.86,34.83V17.62h-7.5V43.87A3.75,3.75,0,0,0,76.11,47.62Z"
        />
        <path
          fill={props.fill}
          d="M79.86,85.15,114.69,120l5.29-5.29L85.15,79.86h17.21v-7.5H76.11a3.75,3.75,0,0,0-3.75,3.75v26.25h7.5Z"
        />
        <path
          fill={props.fill}
          d="M5.29,120,40.12,85.11v17.21h7.5V76.08a3.74,3.74,0,0,0-3.75-3.75H17.62v7.49H34.83L0,114.66Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgPetitEcranVert;
