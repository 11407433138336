import React, { Component, useRef, useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

const handleDateDeb = (startDate: any, callBack: Function) => {
  callBack(startDate);
};

const SimpleDateComponent = (props: any) => {
  const { isRound, startReleveDate, handleChange, t } = props;
  const delayedQuery = useRef(_.debounce((q: any) => handleDateDeb(q, handleChange), 500)).current;

  const [start, setStart] = useState(
    moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD')
  );

  const handleChangeDebounced = (e: any) => {
    setStart(moment(e.target.value).format('YYYY-MM-DD'));
    delayedQuery(e.target.value);
  };

  const formatDateToString = (date: any) => {
    return moment(date)
      .format('YYYY-MM-DD')
      .toString();
  };

  const formatDateDisplay = (date: any) => {
    return moment(date)
      .format('DD/MM/YYYY')
      .toString();
  };

  const now = moment().endOf('day');
  const maxDateStart = formatDateToString(now);
  const startDateReleveFormat = formatDateToString(startReleveDate);
  return (
    <div className="simple-date-component flex-box" style={{ width: '100%' }}>
      <FormGroup style={{ width: '25%', marginRight: '10px' }}>
        <Label for="start-date" style={{ fontStyle: 'italic', marginBottom: '2px' }}>
          {t(isRound ? 'all.read_meter.read_start_date' : 'all.text.start_date')}
        </Label>
        {isRound ? (
          <Input type="text" placeholder={formatDateDisplay(startDateReleveFormat)} disabled />
        ) : (
          <Input
            type="date"
            name="date"
            id="start-date"
            onChange={handleChangeDebounced}
            value={start}
            max={maxDateStart}
          />
        )}
      </FormGroup>
      <FormGroup style={{ width: '25%', marginRight: '10px' }}>
        <Label for="end-date" style={{ fontStyle: 'italic', marginBottom: '2px' }}>
          {t(isRound ? 'all.date.today_date' : 'all.date.end_date')}
        </Label>
        <Input type="text" placeholder={formatDateDisplay(now)} disabled />
      </FormGroup>
    </div>
  );
};

// @ts-ignore
const mapping: any = connect()(SimpleDateComponent);

const connectedSimpleDate = withRouter(mapping);
const tr = withTranslation()(connectedSimpleDate);
export default tr;
