const enqueteConstants = {
  GET_ALL_REQUEST: 'GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'GET_ALL_FAILURE',

  GET_LINKS_REQUEST: 'GET_LINKS_REQUEST',
  GET_LINKS_SUCCESS: 'GET_LINKS_SUCCESS',
  GET_LINKS_FAILURE: 'GET_LINKS_FAILURE',

  SAVE_LINKS_REQUEST: 'SAVE_LINKS_REQUEST',
  SAVE_LINKS_SUCCESS: 'SAVE_LINKS_SUCCESS',
  SAVE_LINKS_FAILURE: 'SAVE_LINKS_FAILURE',

  GET_BY_NUM_PAGE_REQUEST: 'GET_BY_NUM_PAGE_REQUEST',
  GET_BY_NUM_PAGE_SUCCESS: 'GET_BY_NUM_PAGE_SUCCESS',
  GET_BY_NUM_PAGE_FAILURE: 'GET_BY_NUM_PAGE_FAILURE',

  SAVE_PAGE_REQUEST: 'SAVE_PAGE_REQUEST',
  SAVE_PAGE_SUCCESS: 'SAVE_PAGE_SUCCESS',
  SAVE_PAGE_FAILURE: 'SAVE_PAGE_FAILURE',

  DELETE_PAGE_REQUEST: 'DELETE_PAGE_REQUEST',
  DELETE_PAGE_SUCCESS: 'DELETE_PAGE_SUCCESS',
  DELETE_PAGE_FAILURE: 'DELETE_PAGE_FAILURE',
};

export default enqueteConstants;
