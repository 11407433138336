const dashboardConstants = {
  GET_REQUEST: 'DASHBOARDS_GET_REQUEST',
  GET_SUCCESS: 'DASHBOARDS_GET_SUCCESS',
  GET_FAILURE: 'DASHBOARDS_GET_FAILURE',

  GETALL_REQUEST: 'DASHBOARDS_GETALL_REQUEST',
  GETALL_SUCCESS: 'DASHBOARDS_GETALL_SUCCESS',
  GETALL_FAILURE: 'DASHBOARDS_GETALL_FAILURE',

  UPDATE_REQUEST: 'DASHBOARDS_REQUEST',
  UPDATE_SUCCESS: 'DASHBOARDS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'DASHBOARDS_UPDATE_FAILURE',

  SAVE_REQUEST: 'DASHBOARDS_SAVE_REQUEST',
  SAVE_SUCCESS: 'DASHBOARDS_SAVE_SUCCESS',
  SAVE_FAILURE: 'DASHBOARDS_SAVE_FAILURE',

  DELETE_REQUEST: 'DASHBOARDS_DELETE_REQUEST',
  DELETE_SUCCESS: 'DASHBOARDS_DELETE_SUCCESS',
  DELETE_FAILURE: 'DASHBOARDS_DELETE_FAILURE',
  CLEAR: 'DASHBOARDS_CLEAR',
};

export default dashboardConstants;
