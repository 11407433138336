import { roundReportConstants } from '../_constants';
import { roundReportService } from '../_services';
import { ReportSetting } from '../TourneeReport/RoundReport';

function getAllReport(roundCode: string) {
  function request() {
    return { type: roundReportConstants.GET_ALL_REPORT_REQUEST };
  }
  function success(allRoundReport: any) {
    return { type: roundReportConstants.GET_ALL_REPORT_SUCCESS, allRoundReport };
  }
  function failure(error: Object) {
    return { type: roundReportConstants.GET_ALL_REPORT_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    roundReportService
      .getAllReport(roundCode)
      .then((allRoundReport: any) => dispatch(success(allRoundReport)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getReport(reportKey: string, rndCode: string, reportSetting: ReportSetting, filters: any, optionor: string) {
  function request() {
    return { type: roundReportConstants.GET_REPORT_REQUEST };
  }
  function success(roundReport: any) {
    return { type: roundReportConstants.GET_REPORT_SUCCESS, roundReport, reportKey };
  }
  function failure(error: Object) {
    return { type: roundReportConstants.GET_REPORT_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    roundReportService
      .getReport(reportKey, rndCode, { ...reportSetting, filters, optionor })
      .then((roundReport: any) => {
        dispatch(success(roundReport));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function clear() {
  return {
    type: roundReportConstants.CLEAR,
  };
}

const roundReportActions = {
  getAllReport,
  getReport,
  clear,
};

export default roundReportActions;
