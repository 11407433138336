import React from 'react';

const SvgChartBarHorizontalNotAvailable = (props: any) => (
  <svg viewBox="0 0 481 320" {...props}>
    <polygon points="255.3,80.3 255.3,112.5 255.3,112.5 293.8,112.5 305,105.1 305,80.3 " fill="none" />
    <polygon points="293.1,177 305,177 305,149.3 293.1,157.2 " fill="none" />
    <polygon points="204.7,239.7 204.7,239.7 305,239.7 305,207.5 217.5,207.5 204.7,216 " fill="none" />
    <polygon points="255.3,80.3 176,80.3 176,112.5 255.3,112.5 255.3,112.5 " fill={props.fill} />
    <path
      d="M168,116.5V76.3c0-2.2,1.8-4,4-4h77c0,0,0,0,0,0h-77.9c-1.7,0-3.1,1.8-3.1,4L168,116.5c0,2.2,1.4,4,3.1,4h0.9
	C169.8,120.6,168,118.8,168,116.5z"
      fill={props.fill}
    />
    <polygon points="176,144.7 176,177 196.9,177 245.4,144.7 " fill={props.fill} />
    <polygon points="172.4,185 172.4,185 184.8,185 184.8,185 " fill={props.fill} />
    <polygon points="263.4,177 293.1,177 293.1,157.2 " fill={props.fill} />
    <polygon points="251.2,185 284.1,185 284.1,185 251.2,185 " fill={props.fill} />
    <path
      d="M305,149.3V177h-11.9v0c0,4.4-4,8.1-8.9,8.1H305c4.4,0,8.1-3.6,8.1-8.1v-32.2c0-0.3,0-0.5,0-0.8L305,149.3z"
      fill={props.fill}
    />
    <path
      d="M168,181v-40.3c0-2.2,2-4,4.5-4h85l0,0H172c-2.2,0-4,1.8-4,4L168,181c0,2.2,1.8,4,4,4h0.4
	C170,185,168,183.2,168,181z"
      fill={props.fill}
    />
    <path d="M293.1,177L293.1,177l-29.7,0l-12.1,8.1h32.9C289.1,185,293.1,181.4,293.1,177z" fill={props.fill} />
    <path
      d="M196.9,177H176v-32.2h69.3l12.1-8.1h-85c-2.5,0-4.5,1.8-4.5,4V181c0,2.2,2,4,4.5,4h12.4L196.9,177z"
      fill={props.fill}
    />
    <path
      d="M255.3,80.3L255.3,80.3l49.7,0v24.8l8.1-5.4V80.3c0-4.4-3.6-8.1-8.1-8.1H249C252.5,72.2,255.3,75.8,255.3,80.3z"
      fill={props.fill}
    />
    <path d="M255.3,112.5c0,4.4-2.8,8.1-6.2,8.1h-77c0,0,0,0,0,0h109.7l12.1-8.1H255.3z" fill={props.fill} />
    <path
      d="M255.3,112.5H176V80.3h79.2v0c0-4.4-2.8-8.1-6.2-8.1h-77c-2.2,0-4,1.8-4,4v40.3c0,2.2,1.8,4,4,4h77
	C252.5,120.6,255.3,117,255.3,112.5z"
      fill={props.fill}
    />
    <path d="M168,243.7v-3.3l0,0L168,243.7c0,2.2,0.6,4,1.3,4h2.7C169.8,247.8,168,246,168,243.7z" fill={props.fill} />
    <polygon points="204.7,216 176,235.1 176,239.7 204.7,239.7 204.7,239.7 " fill={props.fill} />
    <path
      d="M305,207.5v32.2H204.7c0,4.4-1.2,8.1-2.6,8.1H172c0,0,0,0,0,0h133c4.4,0,8.1-3.6,8.1-8.1v-32.2
	c0-4.4-3.6-8.1-8.1-8.1h-75.4l-12.1,8.1H305z"
      fill={props.fill}
    />
    <path
      d="M204.7,239.7H176v-4.7l-8.1,5.4v3.3c0,2.2,1.8,4,4,4h30.1C203.5,247.8,204.7,244.2,204.7,239.7z"
      fill={props.fill}
    />
    <polygon points="305,112.5 305,105.1 293.8,112.5 " fill={props.fill} />
    <path d="M168,203.5c0-2.2,0.6-4,1.3-4h24.2l21.6-14.4h-30.3l-83.7,55.7l9.3,14l57.6-38.3V203.5z" fill={props.fill} />
    <path
      d="M374.3,79.1l-9.3-14l-52,34.6v12.8c0,4.4-3.6,8.1-8.1,8.1h-23.3l-24.2,16.1h30.3L374.3,79.1z"
      fill={props.fill}
    />
    <polygon points="275.7,144.7 245.4,144.7 196.9,177 227.2,177 " fill={props.fill} />
    <rect x="184.8" y="185" className="st1" width="30.3" height="0" />
    <path d="M284.1,136.7c1,0,2,0.2,3,0.5l0.7-0.5h-30.3l0,0H284.1z" fill={props.fill} />
    <polygon points="196.9,177 184.8,185 215.1,185 227.2,177 " fill={props.fill} />
    <path d="M287.1,137.2c-0.9-0.3-1.9-0.5-3-0.5h-26.6l-12.1,8.1h30.3L287.1,137.2z" fill={props.fill} />
    <path d="M313,112.5V99.7l-8.1,5.4v7.4h-11.1l-12.1,8.1H305C309.4,120.6,313,117,313,112.5z" fill={props.fill} />
    <polygon points="176,207.5 176,211 181.4,207.5 " fill={props.fill} />
    <path
      d="M168,203.5c0-2.2,1.8-4,4-4h21.5l0,0h-24.2C168.6,199.4,168,201.2,168,203.5l0,13l0,0V203.5z"
      fill={props.fill}
    />
    <path d="M168,203.5v13l8.1-5.4v-3.6h5.4l12.1-8.1H172C169.8,199.4,168,201.2,168,203.5z" fill={props.fill} />
  </svg>
);

export default SvgChartBarHorizontalNotAvailable;
