import React from 'react';

const SvgSousDebitBleu = (props: any) => (
  <svg viewBox="0 0 19.81 20.84" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M15,12.2l-.51,1.24a.65.65,0,0,0,1,.78l4.12-2.86a.65.65,0,0,0,0-1.09L15.18,7.66a.65.65,0,0,0-.77.07.66.66,0,0,0-.15.76l.57,1.2h-6a1.06,1.06,0,0,0-1.06,1.06v.38a1.07,1.07,0,0,0,1.06,1.07Z"
        />
        <path
          fill={props.fill}
          d="M16.24,20.84h-7a1,1,0,0,1-.78-.37L3.2,14.22H1a1,1,0,0,1,0-2H3.67a1,1,0,0,1,.78.35L9.7,18.78h6.55a1,1,0,0,1,1,1A1,1,0,0,1,16.24,20.84Z"
        />
        <path
          fill={props.fill}
          d="M3.67,9.15H1a1,1,0,0,1,0-2H3.17L8.42.4a1,1,0,0,1,.8-.4h7a1,1,0,0,1,0,2.06H9.71L4.47,8.77A1,1,0,0,1,3.67,9.15Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgSousDebitBleu;
