import React from 'react';
const SvgValidation = (props: any) => (
  <svg viewBox="0 0 17 12.63" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          d="M16.66.34a1.12,1.12,0,0,0-1.6,0L5.93,9.43l-4-4a1.13,1.13,0,0,0-1.6,0A1.12,1.12,0,0,0,.34,7l4,4,1.6,1.6L7.53,11l9.13-9.09A1.12,1.12,0,0,0,16.66.34Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgValidation;
