import React from 'react';

const SvgCadenasOuvert = (props: any) => (
  <svg viewBox="0 0 113.1 100.6" {...props}>
    <g id="Calque_2" />
    <g id="Calque_1">
      <path
        fill={props.fill}
        d="M111,37.7H58.7v-8.4C58.7,13.2,45.5,0,29.3,0C13.2,0,0,13.2,0,29.3v8.4h0C0,41.2,2.8,44,6.3,44
		s6.3-2.8,6.3-6.3h0v-8.4c0-9.2,7.5-16.8,16.8-16.8s16.8,7.5,16.8,16.8v8.4h-6.3c-1.2,0-2.1,0.9-2.1,2.1v52.4c0,4.6,3.8,8.4,8.4,8.4
		h58.7c4.6,0,8.4-3.8,8.4-8.4V39.8C113.1,38.6,112.2,37.7,111,37.7z M50.3,37.7v-8.4c0-11.6-9.4-20.9-20.9-20.9S8.4,17.8,8.4,29.3
		v8.4h0c0,1.2-0.9,2.1-2.1,2.1c-1.1,0-2.1-0.9-2.1-2.1v-8.4c0-13.9,11.3-25.1,25.1-25.1c13.9,0,25.1,11.3,25.1,25.1v8.4H50.3z
		 M80.4,69.6l1.3,11.9c0.1,0.6-0.1,1.2-0.5,1.6c-0.4,0.4-1,0.7-1.6,0.7h-8.4c-0.6,0-1.2-0.3-1.6-0.7c-0.4-0.4-0.6-1-0.5-1.6
		l1.3-11.9c-2.1-1.6-3.4-4-3.4-6.7c0-4.6,3.8-8.4,8.4-8.4s8.4,3.8,8.4,8.4C83.8,65.6,82.5,68,80.4,69.6z"
      />
    </g>
  </svg>
);

export default SvgCadenasOuvert;
