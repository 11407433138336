import React from 'react';

const SvgReport = (props: any) => (
  <svg viewBox="0 0 200 200" {...props}>
    <path
      fill={props.fill}
      d="M145,45.9H132V40c0-1.9-2.1-2.8-4-2.8h-10.2c-2.4-6.9-8.4-10.3-15.3-10.3c-6.8-0.3-13,3.9-15.3,10.3h-10
					c-1.9,0-3.8,0.9-3.8,2.8v5.9H60.4c-7.8,0.1-14.1,6.2-14.5,13.9v98.6c0,7.6,6.9,13.1,14.5,13.1H145c7.6,0,14.5-5.5,14.5-13.1
					V59.8C159.1,52.1,152.8,45.9,145,45.9z M80.3,44.1h9.5c1.7-0.2,3-1.5,3.3-3.1c1-4.4,4.9-7.6,9.5-7.7c4.5,0.1,8.3,3.3,9.3,7.7
					c0.3,1.7,1.7,3,3.4,3.1h9.8v13.8H80.3V44.1z M152.6,158.4c0,3.8-3.8,6.2-7.6,6.2H60.4c-3.8,0-7.6-2.4-7.6-6.2V59.8
					c0.4-3.9,3.6-7,7.6-7.1h13.1v8.8c0.2,1.9,1.9,3.4,3.8,3.3H128c2,0.1,3.7-1.3,4-3.3v-8.8H145c4,0.1,7.2,3.1,7.6,7.1L152.6,158.4
					L152.6,158.4z"
    />
    <path
      fill={props.fill}
      d="M89.5,103.9c-1.3-1.4-3.4-1.4-4.8-0.2l-11,10.5l-4.6-4.8c-1.3-1.4-3.4-1.4-4.8-0.2c-1.3,1.4-1.3,3.6,0,5
					l7.1,7.2c0.6,0.7,1.5,1.1,2.4,1c0.9,0,1.8-0.4,2.4-1l13.4-12.7c1.3-1.2,1.4-3.3,0.2-4.6C89.6,104,89.5,103.9,89.5,103.9z"
    />
    <path
      fill={props.fill}
      d="M138.9,111.3H99.3c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4h39.6c1.9,0,3.4-1.5,3.4-3.4
					S140.8,111.3,138.9,111.3z"
    />
    <path
      fill={props.fill}
      d="M89.5,76.3c-1.3-1.4-3.4-1.4-4.8-0.2l-11,10.5L69,81.8c-1.3-1.4-3.4-1.4-4.8-0.2c-1.3,1.4-1.3,3.6,0,5
					l7.1,7.2c0.6,0.7,1.5,1.1,2.4,1c0.9,0,1.8-0.4,2.4-1l13.4-12.7c1.3-1.2,1.4-3.3,0.2-4.6C89.6,76.5,89.5,76.4,89.5,76.3z"
    />
    <path
      fill={props.fill}
      d="M138.9,83.7H99.3c-1.9,0-3.4,1.5-3.4,3.4c0,1.9,1.5,3.4,3.4,3.4h39.6c1.9,0,3.4-1.5,3.4-3.4
					C142.3,85.3,140.8,83.7,138.9,83.7z"
    />
    <path
      fill={props.fill}
      d="M89.5,131.4c-1.3-1.4-3.4-1.4-4.8-0.2l-11,10.5l-4.6-4.8c-1.3-1.4-3.4-1.4-4.8-0.2c-1.3,1.4-1.3,3.6,0,5
					l7.1,7.2c0.6,0.7,1.5,1.1,2.4,1c0.9,0,1.8-0.4,2.4-1l13.4-12.7c1.3-1.2,1.4-3.3,0.2-4.6C89.6,131.5,89.5,131.5,89.5,131.4z"
    />
    <path
      fill={props.fill}
      d="M138.9,138.8H99.3c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4h39.6c1.9,0,3.4-1.5,3.4-3.4
					S140.8,138.8,138.9,138.8z"
    />
  </svg>
);

export default SvgReport;
