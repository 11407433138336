import React, { PureComponent } from 'react';
import getC from '../TourneeFiches/Picto_correspondance';
import SvgEmptyMarker from '../SvgComponents/EmptyMarker';
import { withTranslation } from 'react-i18next';

export interface Props {
  clickMarker?: Function;
  id: number;
  isCenterMarker?: boolean;
  lat: number;
  lng: number;
  icon: any;
  active: boolean;
  isSelected?: boolean;
  name: string;
  t: Function;
}

class MarkerGatewayGM extends PureComponent<Props> {
  render() {
    const { clickMarker, id, lat, lng, icon, isCenterMarker, isSelected, name, t, active } = this.props;
    const iconProps = {
      height: isCenterMarker ? '40px' : '33px',
      width: isCenterMarker ? '40px' : '33px',
    };

    return (
      <div
        className="clickable markerGM"
        //@ts-ignore
        onClick={() => clickMarker && clickMarker(id, lat, lng)}
        key={id ? `gateway-${id}` : `center_marker`}
        //@ts-ignore
        lat={lat}
        lng={lng}
        active={active}
      >
        {!isCenterMarker && (
          <span className="selected-marker" style={{ display: isSelected ? 'inherit' : '' }}>
            <SvgEmptyMarker height="75px" width="75px" />
          </span>
        )}
        <span className={'marker' + (isSelected ? ' markerSelected' : '') + (isCenterMarker ? ' centerMarker' : '')}>
          {getC(icon, iconProps)}
        </span>
        {!isCenterMarker && (
          <div
            className="popupMarker absoluteVerticalCenter"
            style={{ top: isSelected ? 'calc(50% + (-40px))' : '', left: isSelected ? 'calc(100% + 35px)' : '' }}
          >
            <h3>{t('gateway_name.text.map')}</h3>
            <span className="meterSerial">{name}</span>
          </div>
        )}
      </div>
    );
  }
}

const tr = withTranslation()(MarkerGatewayGM);
export default tr;
