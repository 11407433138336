import React from 'react';

const SvgFiltreVert = (props: any) => (
  <svg viewBox="0 0 35.43 32.66" {...props}>
    <path
      d="M35.33.62a1.08 1.08 0 0 0-1-.62H1.07a1.07 1.07 0 0 0-.82 1.76L13 17.27v14.31a1.09 1.09 0 0 0 .51.92 1.13 1.13 0 0 0 .56.16 1 1 0 0 0 .48-.12L21.78 29a1.07 1.07 0 0 0 .59-1V17.25L35.18 1.76a1.08 1.08 0 0 0 .15-1.14zM20.49 16.21a1.06 1.06 0 0 0-.25.68v10.46l-5 2.5v-13a1.06 1.06 0 0 0-.25-.68L3.35 2.15h28.73z"
      fill={props.fill}
      data-name="Calque 2"
    />
  </svg>
);

export default SvgFiltreVert;
