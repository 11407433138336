const locationConstants = {
  GETALL_REQUEST: 'LOCATIONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'LOCATIONS_GETALL_SUCCESS',
  GETALL_FAILURE: 'LOCATIONS_GETALL_FAILURE',

  GETALLFROMSEED_REQUEST: 'LOCATIONS_GETALLFROMSEED_REQUEST',
  GETALLFROMSEED_SUCCESS: 'LOCATIONS_GETALLFROMSEED_SUCCESS',
  GETALLFROMSEED_FAILURE: 'LOCATIONS_GETALLFROMSEED_FAILURE',

  GET_REQUEST: 'LOCATIONS_GET_REQUEST',
  GET_SUCCESS: 'LOCATIONS_GET_SUCCESS',
  GET_FAILURE: 'LOCATIONS_GET_FAILURE',

  GETINFO_REQUEST: 'LOCATIONS_GETINFO_REQUEST',
  GETINFO_SUCCESS: 'LOCATIONS_GETINFO_SUCCESS',
  GETINFO_FAILURE: 'LOCATIONS_GETINFO_FAILURE',

  GETCONTACT_REQUEST: 'LOCATIONS_GETCONTACT_REQUEST',
  GETCONTACT_SUCCESS: 'LOCATIONS_GETCONTACT_SUCCESS',
  GETCONTACT_FAILURE: 'LOCATIONS_GETCONTACT_FAILURE',

  SAVE_REQUEST: 'LOCATIONS_SAVE_REQUEST',
  SAVE_SUCCESS: 'LOCATIONS_SAVE_SUCCESS',
  SAVE_FAILURE: 'LOCATIONS_SAVE_FAILURE',

  UPDATE_REQUEST: 'LOCATIONS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'LOCATIONS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'LOCATIONS_UPDATE_FAILURE',

  DELETE_REQUEST: 'LOCATIONS_DELETE_REQUEST',
  DELETE_SUCCESS: 'LOCATIONS_DELETE_SUCCESS',
  DELETE_FAILURE: 'LOCATIONS_DELETE_FAILURE',

  CLEAR: 'LOCATIONS_CLEAR',
};

export default locationConstants;
