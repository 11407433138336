import cloneDeep from 'lodash/cloneDeep';
import { dashboardConstants } from '../_constants';
import { dashboardService } from '../_services';
import alertActions from './alert.actions';
import { dashboard } from '../_entities/dashboard';
import bookmarActions from './bookmark.actions';

/**
 * Récupère tous les tableaux de bord sur le site
 * courant
 *
 * @method getAll
 * @param {number} locationId Id du site
 * @returns {Object} Les dashboards
 */
function getAll(locationId: number) {
  function request() {
    return {
      type: dashboardConstants.GETALL_REQUEST,
    };
  }

  function success(dashboards: Object) {
    return {
      type: dashboardConstants.GETALL_SUCCESS,
      dashboards,
    };
  }

  function failure(error: Object) {
    return {
      type: dashboardConstants.GETALL_FAILURE,
      error,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    dashboardService
      .getAll(locationId)
      .then((dashboards: Object) => dispatch(success(dashboards)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function get(id: number) {
  function request() {
    return {
      type: dashboardConstants.GET_REQUEST,
    };
  }

  function success(dashboard: dashboard) {
    return {
      type: dashboardConstants.GET_SUCCESS,
      dashboard,
    };
  }

  function failure(error: Object) {
    return {
      type: dashboardConstants.GET_FAILURE,
      error,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    dashboardService
      .get(id)
      .then((dashboard: dashboard) => dispatch(success(dashboard)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function update(dashboard: any, dashboards = [], withAlert = false) {
  const dashboardToUpdate = cloneDeep(dashboard);
  dashboardToUpdate.widgets = [];
  if (dashboard.widgets && dashboard.widgets.length > 0) {
    dashboardToUpdate.widgets = dashboard.widgets.map((it: any) => it.id);
  }
  dashboardToUpdate.owner = dashboard.owner.id;
  function success(updateddashboard: Object) {
    return {
      type: dashboardConstants.UPDATE_SUCCESS,
      dashboards,
      updateddashboard,
    };
  }
  return (dispatch: Function) => {
    dashboardService
      .update(dashboardToUpdate)
      .then((updateddashboard: Object) => {
        dispatch(success(updateddashboard));
        if (withAlert) dispatch(alertActions.success('Opération réussie'));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(alertActions.error(error));
      });
  };
}

function save(dashboard: any, dashboards = []) {
  function success(savedDashboard: Object) {
    return {
      type: dashboardConstants.SAVE_SUCCESS,
      dashboards,
      savedDashboard,
    };
  }
  return (dispatch: Function) => {
    dashboardService
      .save(dashboard)
      .then((savedDashboard: Object) => {
        dispatch(success(savedDashboard));
        dispatch(alertActions.success('Opération réussie'));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(alertActions.error(error));
      });
  };
}

function deleteDashboard(dashboard: any, dashboards = <dashboard[]>[], locationId: number) {
  function request() {
    return {
      type: dashboardConstants.DELETE_REQUEST,
    };
  }
  function failure(error: Object) {
    return {
      type: dashboardConstants.DELETE_FAILURE,
      error,
    };
  }
  function success(deletedDashBoard: Object) {
    return {
      type: dashboardConstants.DELETE_SUCCESS,
      dashboards,
      deletedDashBoard,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    dashboardService
      .deleteDashBoard(dashboard)
      .then(() => {
        dispatch(success(dashboard));
        dispatch(bookmarActions.getAll(locationId));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      });
  };
}

function clear() {
  return {
    type: dashboardConstants.CLEAR,
  };
}

const dashboardActions = {
  get,
  getAll,
  update,
  save,
  deleteDashboard,
  clear,
};

export default dashboardActions;
