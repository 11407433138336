import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { isArray } from 'lodash';
import { withTranslation } from 'react-i18next';
import translate from '../_helpers/locale-helpers';

import Stats from './Stats';
import { locales } from '../_interfaces/reducers';

type Props = {
  locales: locales;
  isActive?: boolean;
  form: any;
  previousStep?: Function;
  update: Function;
  hashKey?: String;
  widget: any;
  dispatch: Function;
  dataPreview: any;
  t: Function;
};

type State = {};

/**
 * Composant de la dernière étape de création de widget
 *
 * @class LastWidgetCreatorComponent
 * @extends {Component<Props, State>}
 */
class LastWidgetCreatorComponent extends Component<Props, State> {
  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof LastWidgetCreatorComponent
   */
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  /**
   * Formate la valeur de la condition passée en
   * paramètre, en mettant l'accent sur les types
   * DateInterval et AlarmType
   *
   * @param {Object} condition La condition
   * @returns {string} La condition formatée
   * @method format
   * @memberof LastWidgetCreatorComponent
   */
  format = (condition: any) => {
    const { locales, t } = this.props;
    if (condition.conditionType === 'DateInterval') {
      return `${condition.conditionValue.value} - ${t(
        `best_date_component.zoom_option.${condition.conditionValue.name.toLowerCase()}`
      )}  - ${t('all.text.zoom')}: ${t(
        `best_date_component.zoom_option.${condition.conditionValue.zoom.toLowerCase()}`
      )}`;
    }
    if (condition.conditionTitle === 'AlarmType') {
      return isArray(condition.conditionValue) && condition.conditionValue.length > 0
        ? condition.conditionValue.map((el: any) => t(el)).join(', ')
        : t('widget_last.text.no_alarm_type');
    }
    return condition.conditionValue;
  };

  /**
   * Lance la mise à jour du widget
   *
   * @method send
   * @memberof LastWidgetCreatorComponent
   */
  send = () => {
    const { update } = this.props;
    update();
  };

  /**
   * Retourne à l'étape précédente
   *
   * @method validate
   * @memberof LastWidgetCreatorComponent
   */
  validate = () => {
    const { previousStep } = this.props;
    if (previousStep != undefined) {
      previousStep();
    }
  };

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof LastWidgetCreatorComponent
   */
  render() {
    const { isActive, locales, widget, t } = this.props;
    if (!isActive) return null;
    const { form, dataPreview }: any = this.props;
    const { name, templateOption }: any = form;
    const { dataSourceProperty } = templateOption;
    const { displayProperty, displayValue } = dataSourceProperty;
    const { condition, displayType } = displayProperty;
    return (
      <div>
        <h4>{t('widget_last.title.summary')}</h4>
        <div className="abstractInModal">
          <p>
            <span className="label-text">{t('widget_last.text.widget_name')} :</span> {name}
          </p>
          <p>
            <span className="label-text">{t('widget_last.text.widget_type')} :</span>{' '}
            {t(`widget.display_value.${displayValue.toLowerCase()}`)}
          </p>
          <p>
            <span className="label-text">{t('all.text.unity')} :</span>{' '}
            {t(dataSourceProperty.displayUnit, translate('fr', 'unit', dataSourceProperty.displayUnit, locales.locale))}
          </p>
          <p>
            <span className="label-text">{t('widget_last.text.widget_display')} :</span>{' '}
            {t(`widget.display_type.${displayType.toLowerCase()}`)}
          </p>
          <div>
            {condition.map((c: any) => (
              <p key={c.conditionTitle}>
                <span className="label-text">{t(`widget.condition_title.${c.conditionTitle.toLowerCase()}`)} :</span>{' '}
                {this.format(c)}
              </p>
            ))}
          </div>
        </div>
        <div className="bottomChoice">
          <Row>
            <Col md="4">
              <Stats {...this.props} previousStep={this.validate} />
            </Col>
            <Col md="8" className="text-right">
              <Button color="primary" onClick={this.send} style={{ marginLeft: '5px' }}>
                {t('all.text.configure')}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
//              <WidgetPreview dataPreview={dataPreview} content={templateOption} widget={widget} />
function mapStateToProps(state: any) {
  const { locales } = state;
  return {
    locales,
  };
}

const LastConnectedComponent = connect(mapStateToProps)(LastWidgetCreatorComponent);
const tr = withTranslation()(LastConnectedComponent);
export default tr;
