import _, { Dictionary } from 'lodash';
import { AnyLayer, CircleLayer, Expression, FillLayer, Layer, LineLayer, LinePaint, Map, SymbolLayer } from 'mapbox-gl';
import allImages from '../images/allImages';

const layerId = ['cluster', 'unclustered-point', 'cluster-count', 'gateway-layer'];

const clusterCountLayer = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'allMeter',
  filter: ['has', 'point_count'],
};

function unclusteredLayer(source: string, iconOverlap: boolean): SymbolLayer {
  return {
    id: 'unclustered-point',
    source: source,
    type: 'symbol',
    filter: ['!=', 'cluster', true],
    layout: {
      'icon-image': allImages.iconImage,
      'icon-size': 1,
      'icon-allow-overlap': iconOverlap,
      'icon-anchor': 'bottom',
    },
  };
}

function gatewayLayer(dataSource: string): SymbolLayer {
  return {
    id: 'gateway-layer',
    source: dataSource,
    type: 'symbol',
    layout: {
      'icon-image': allImages.gatewayImage,
      'icon-size': 0.4,
      'icon-allow-overlap': true,
    },
  };
}

function gatewayRange(dataSource: string, paint: LinePaint): LineLayer {
  return {
    id: 'gateway-range',
    source: dataSource,
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: paint,
  };
}

function circleLayerIn(dataSource: string): FillLayer {
  return {
    id: `selected-circle`,
    type: 'fill',
    source: dataSource,
    layout: {},
    paint: {
      'fill-color': 'transparent',
      'fill-opacity': 0.5,
    },
  };
}

function circleLayerOut(dataSource: string): LineLayer {
  return {
    id: `circle-line`,
    type: 'line',
    source: dataSource,
    layout: {},
    paint: {
      'line-color': '#000',
      'line-width': 1.5,
    },
  };
}

function polygonIn(i: number): FillLayer {
  return {
    id: `polygon-${i}`,
    type: 'fill',
    source: `polygon-${i}`,
    layout: {},
    paint: {
      'fill-color': '#0080ff',
      'fill-opacity': 0.5,
    },
  };
}

function polygonOutline(i: number): LineLayer {
  return {
    id: `outline-${i}`,
    type: 'line',
    source: `polygon-${i}`,
    layout: {},
    paint: {
      'line-color': '#000',
      'line-width': 4,
    },
  };
}

function inAllCircles(i: number): FillLayer {
  return {
    id: `circle-${i}`,
    type: 'fill',
    source: `circle-${i}`,
    layout: {},
    paint: {
      'fill-color': 'transparent',
      'fill-opacity': 0.5,
    },
  };
}

function allCirclesOutline(i: number): LineLayer {
  return {
    id: `line-${i}`,
    type: 'line',
    source: `circle-${i}`,
    layout: {},
    paint: {
      'line-color': '#000',
      'line-width': 1.5,
    },
  };
}

function geojsonPoints(name: string): CircleLayer {
  return {
    id: name,
    type: 'circle',
    source: name,
  };
}

function geojsonLines(name: string): LineLayer {
  return {
    id: name,
    type: 'line',
    source: name,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#BF93E4',
      'line-width': 1,
    },
  };
}

function geojsonInPolygon(name: string): FillLayer {
  return {
    id: 'inside',
    type: 'fill',
    source: name,
    layout: {},
    paint: {
      'fill-color': '#0080ff',
      'fill-opacity': 0.5,
    },
  };
}

function geojsonPolygonBorder(name: string): LineLayer {
  return {
    id: 'border',
    type: 'line',
    source: name,
    layout: {},
    paint: {
      'line-color': '#000',
      'line-width': 0.5,
    },
  };
}

function clusterPaintData(): Array<Array<Expression>> {
  const colorCluster: any = {
    red: '#f24141',
    green: '#26a653',
    blue: '#a63c76',
    purple: '#91e0f2',
    yellow: '#f2ae2e',
  };
  const clusterPaint: Array<string> = [
    'red_manual',
    'red_radio',
    'red_telereleve',
    'green_manual',
    'green_radio',
    'green_telereleve',
    'blue_radio',
    'blue_telereleve',
    'purple_radio',
    'purple_telereleve',
    'yellow_manual',
    'yellow_radio',
    'yellow_telereleve',
  ];
  return clusterPaint.map((cp: string) => [['==', ['get', 'ficheState'], cp], colorCluster[cp.split('_')[0]]]);
}

function clusterPaint(): Array<Expression> {
  return _.flatten(clusterPaintData());
}

function clusterPaintWithCase(): Dictionary<Expression> {
  return _.fromPairs(
    clusterPaintData().map((cp: any, index: number) => ['water-' + index, ['+', ['case', cp[0], 1, 0]]])
  );
}

function clusterLayer(name: string): CircleLayer {
  return {
    id: 'cluster',
    source: name,
    type: 'circle',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': ['case', ...clusterPaint(), 'transparent'],
      'circle-radius': ['step', ['get', 'point_count'], 27, 10, 37, 100, 45, 1000, 61, 10000, 72],
    },
  };
}

function removeLayers(map: Map, layersName: Array<string>): void {
  _.each(layersName, function(layerName) {
    map && map.getLayer(layerName) && map.removeLayer(layerName);
  });
}

function addLayers(map: Map, layersName: Array<string>, layer: Array<any>): void {
  _.each(layersName, function(layerName, index) {
    const layerIdx = layer[index];
    !map.getLayer(layerName) && map.addLayer(layerIdx);
  });
}

const name = {
  cluster: 'cluster',
  soloMeters: 'unclustered-point',
  clusterCount: 'cluster-count',
  gatewaySourceName: 'allGateways',
  gatewayLayerName: 'gateway-layer',
  capturedMetersName: 'captured-meters',
  meterSourceName: 'allMeter',
  gatewayRange: 'gateway-range',
  filteredMetersName: 'filtered-meters',
  selectedGatewayName: 'selected-gateway',
  selectedCircleName: 'selected-circle',
  selectedLinesName: 'selected-lines',
  circleLine: 'circle-line',
};

const layers = {
  clusterLayer,
  clusterCountLayer,
  unclusteredLayer,
  layerId,
  gatewayLayer,
  gatewayRange,
  circleLayerIn,
  circleLayerOut,
  polygonIn,
  polygonOutline,
  inAllCircles,
  allCirclesOutline,
  geojsonPoints,
  geojsonLines,
  geojsonInPolygon,
  geojsonPolygonBorder,
  removeLayers,
  addLayers,
  name,
  clusterPaintWithCase,
};

export default layers;
