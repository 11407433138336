import moment from 'moment';
import _ from 'lodash';

//TRAD -> ne pas toucher SOUS AUCUN prétexte la VALUE
export default {
  operators: {
    number: [
      {
        label: 'Superieur à',
        value: '>',
        operation: (entry, src) => parseFloat(src) > parseFloat(entry),
      },
      {
        label: 'Inferieur à',
        value: '<',
        operation: (entry, src) => parseFloat(src) < parseFloat(entry),
      },
      {
        label: 'Superieur ou égal à',
        value: '>=',
        operation: (entry, src) => parseFloat(src) >= parseFloat(entry),
      },
      {
        label: 'Inferieur ou égal à',
        value: '<',
        operation: (entry, src) => parseFloat(src) <= parseFloat(entry),
      },
      {
        label: 'Egal à',
        value: '=',
        operation: (entry, src) => parseFloat(src) === parseFloat(entry),
      },
      {
        label: 'Différent de',
        value: '!=',
        operation: (entry, src) => parseFloat(src) !== parseFloat(entry),
      },
      {
        label: 'Est nul',
        value: '== null',
        noEntry: true,
        operation: (entry, src) => src === undefined || src === null || Number.isNaN(parseFloat(src)),
      },
      {
        label: "N'est pas nul",
        value: '!= null',
        noEntry: true,
        operation: (entry, src) => src !== undefined && src !== null && !Number.isNaN(parseFloat(src)),
      },
    ],
    text: [
      {
        label: 'Contient',
        value: 'contient',
        operation: (entry, src) => {
          if (Array.isArray(entry)) {
            return (
              entry.filter(el => !(src || '').toLowerCase().includes(el.value.toLowerCase())).length < entry.length
            );
          }
          return src !== undefined && src !== null && src.toLowerCase().includes(entry.toLowerCase());
        },
      },
      {
        label: 'Ne contient pas',
        value: 'contientPas',
        operation: (entry, src) => {
          if (Array.isArray(entry)) {
            return (
              entry.filter(el => !(src || '').toLowerCase().includes(el.value.toLowerCase())).length === entry.length
            );
          }
          return src !== undefined && src !== null && src.toLowerCase().includes(entry.toLowerCase());
        },
      },
      {
        label: 'Commence par',
        value: 'commencePar',
        operation: (entry, src) => {
          if (Array.isArray(entry)) {
            return (
              entry.filter(el => !(src || '').toLowerCase().startsWith((el.value && el.value.toLowerCase()) || ''))
                .length < entry.length
            );
          }
          return src !== undefined && src !== null && src.toLowerCase().startsWith(entry.toLowerCase());
        },
        // src !== undefined && src !== null && src.toLowerCase().startsWith(entry.toLowerCase()),
      },
      {
        label: 'Fini par',
        value: 'finiPar',
        operation: (entry, src) => {
          if (Array.isArray(entry)) {
            return (
              entry.filter(el => !(src || '').toLowerCase().endsWith(el.value.toLowerCase())).length < entry.length
            );
          }
          return src !== undefined && src !== null && src.toLowerCase().startsWith(entry.toLowerCase());
        },
      },
      {
        label: 'Est vide',
        value: 'vide',
        noEntry: true,
        operation: (entry, src) => !src || src.trim().length === 0,
      },
      {
        label: "N'est pas vide",
        value: 'notVide',
        noEntry: true,
        operation: (entry, src) => src !== null && src.trim().length > 0,
      },
    ],
    select: [
      {
        label: 'Egal à',
        value: '=',
        operation: (entry, src) => src === entry,
      },
      {
        label: 'Différent de',
        value: '!=',
        operation: (entry, src) => src !== entry,
      },
    ],
    selectCont: [
      {
        label: 'Est',
        value: '=',
        operation: (entry, src) => {
          return (
            src &&
            entry &&
            entry.label &&
            src.toLowerCase() ===
              (typeof entry.label === 'string' ? entry.label.toLowerCase() : entry.value.toLowerCase())
          );
        },
      },
      {
        label: "N'est pas",
        value: '!=',
        operation: (entry, src) => {
          return (
            !src ||
            src.toLowerCase() !==
              (typeof entry.label === 'string' ? entry.label.toLowerCase() : entry.value.toLowerCase())
          );
        },
      },
    ],
    selectAlarmTypeBasic: [
      {
        label: 'Est',
        value: '=',
        operation: (entry, src) => {
          if (src && Array.isArray(src)) {
            return (
              entry && entry.value && !_.isEmpty(src.filter(c => c.toLowerCase().includes(entry.value.toLowerCase())))
            );
          }
          return src && entry && entry.value && src.toLowerCase().includes(entry.value.toLowerCase());
        },
      },
      {
        label: "N'est pas",
        value: '!=',
        operation: (entry, src) => {
          if (Array.isArray(src)) {
            return (
              !src ||
              (entry && entry.value && _.isEmpty(src.filter(c => c.toLowerCase().includes(entry.value.toLowerCase()))))
            );
          }
          return !src || !src.toLowerCase().includes(entry.value.toLowerCase());
        },
      },
    ],
    selectContValue: [
      {
        label: 'Est',
        value: '=',
        operation: (entry, src) => {
          return src && entry && entry.value && src.toLowerCase() === entry.value.toLowerCase();
        },
      },
      {
        label: "N'est pas",
        value: '!=',
        operation: (entry, src) => !src || src.toLowerCase() !== entry.value.toLowerCase(),
      },
    ],
    date: [
      {
        label: 'Egal à',
        value: '=',
        operation: (entry, src) =>
          moment(src, ['YYYY-MM-DD', 'DD-MM-YYYY', `DD/MM/YYYY`])
            .startOf('day')
            .isSame(moment(entry, ['YYYY-MM-DD', 'DD-MM-YYYY']).startOf('day'), 'day'),
      },
      {
        label: 'Postérieur à',
        value: '>=',
        operation: (entry, src) =>
          moment(src, ['YYYY-MM-DD', 'DD-MM-YYYY', `DD/MM/YYYY`])
            .startOf('day')
            .isSameOrAfter(moment(entry, ['YYYY-MM-DD', 'DD-MM-YYYY']).startOf('day'), 'day'),
      },
      {
        label: 'Antérieur à',
        value: '<=',
        operation: (entry, src) =>
          moment(src, ['YYYY-MM-DD', 'DD-MM-YYYY', `DD/MM/YYYY`])
            .startOf('day')
            .isSameOrBefore(moment(entry, ['YYYY-MM-DD', 'DD-MM-YYYY']).startOf('day'), 'day'),
      },
      {
        label: 'Entre',
        value: 'between',
        operation: (entry, src) =>
          moment(src, ['YYYY-MM-DD', 'DD-MM-YYYY', `DD/MM/YYYY`])
            .startOf('day')
            .isSameOrBefore(moment(entry, ['YYYY-MM-DD', 'DD-MM-YYYY']).startOf('day'), 'day'),
      },
      {
        label: 'Est vide',
        value: 'vide',
        noEntry: true,
        operation: (entry, src) => !src || undefined === src || src.length === 0,
      },
      {
        label: "N'est pas vide",
        value: 'notVide',
        noEntry: true,
        operation: (entry, src) => src !== null && undefined !== src && src.length > 0,
      },
    ],
    checkbox: {
      'splitAlarms.Fraudpoc': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.Fraudpast': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.Leakpoc': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.Leakpast': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.Backflowpoc': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.Backflowpast': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.Batterycurrent': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.Batterypast': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.ModuleDefectcurrent': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.ModuleDefectpast': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.Clockcurrent': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.Clockpast': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.Fraudcurrent': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.Leakcurrent': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.Backflowcurrent': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.MeterBlockpoc': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.MeterBlockpast': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.Underflowpoc': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.Underflowpast': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.Overflowpoc': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.Overflowpast': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.Underflowcurrent': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      'splitAlarms.Overflowcurrent': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      withAlarm: [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src === true,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || src === false,
        },
      ],
      withAlarmMasked: [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0,
        },
      ],
      simultaneousAlarms: [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0,
        },
      ],
      'releveTerrain.seuilOverflow': [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0,
        },
      ],
      indexed: [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => !src,
        },
      ],
      oldMeterEndDate: [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => !src,
        },
      ],
      modified: [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => !src,
        },
      ],
      state: [
        {
          label: 'Actif',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1,
        },
        {
          label: 'Inactif',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0,
        },
      ],
      hasGpsPosition: [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => src === 1 || src,
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || !src,
        },
      ],
      canProvision: [
        {
          label: 'Oui',
          value: 'True',
          noEntry: true,
          operation: (entry, src) => {
            return src === 1 || src;
          },
        },
        {
          label: 'Non',
          value: 'False',
          noEntry: true,
          operation: (entry, src) => src === 0 || !src,
        },
      ],
    },
  },
};
