import React from 'react';
import './style.scss';

const StyledInput = (props: any) => {
  const { text, type, value, handleChange, maxlength } = props;

  return (
    <div className="styled-input">
      <input type={type} required value={value} onChange={handleChange} maxLength={maxlength || 100} />
      <div className="underline" />
      <label className="label-name">{text}</label>
    </div>
  );
};

export default StyledInput;
