export default function authHeaderFile(): Headers {
  let token = undefined;
  try {
    token = localStorage.getItem('token');
  } catch (error) {
    console.log(error);
  }
  let headers: Headers = new Headers();
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }
  return headers;
}
