const dashboardConstants = {
  GETALL_REQUEST: 'BOOKMARKS_GETALL_REQUEST',
  GETALL_SUCCESS: 'BOOKMARKS_GETALL_SUCCESS',
  GETALL_FAILURE: 'BOOKMARKS_GETALL_FAILURE',

  GET_ALL_HOME_REQUEST: 'GET_ALL_HOME_REQUEST',
  GET_ALL_HOME_SUCCESS: 'GET_ALL_HOME_SUCCESS',
  GET_ALL_HOME_FAILURE: 'GET_ALL_HOME_FAILURE',

  SAVE_REQUEST: 'BOOKMARKS_SAVE_REQUEST',
  SAVE_SUCCESS: 'BOOKMARKS_SAVE_SUCCESS',
  SAVE_FAILURE: 'BOOKMARKS_SAVE_FAILURE',

  DELETE_REQUEST: 'BOOKMARKS_DELETE_REQUEST',
  DELETE_SUCCESS: 'BOOKMARKS_DELETE_SUCCESS',
  DELETE_FAILURE: 'BOOKMARKS_DELETE_FAILURE',

  CLEAR: 'BOOKMARKS_CLEAR',
};

export default dashboardConstants;
