const supportConstants = {
  GET_GENERAL_IMPORT_BILLING_SETTING_REQUEST: 'SUPPORT_GET_GENERAL_IMPORT_BILLING_SETTING_REQUEST',
  GET_GENERAL_IMPORT_BILLING_SETTING_SUCCESS: 'SUPPORT_GET_GENERAL_IMPORT_BILLING_SETTING_SUCCESS',
  GET_GENERAL_IMPORT_BILLING_SETTING_FAILURE: 'SUPPORT_GET_GENERAL_IMPORT_BILLING_SETTING_FAILURE',

  GET_COLUMN_IMPORT_BILLING_SETTING_REQUEST: 'SUPPORT_GET_COLUMN_IMPORT_BILLING_SETTING_REQUEST',
  GET_COLUMN_IMPORT_BILLING_SETTING_SUCCESS: 'SUPPORT_GET_COLUMN_IMPORT_BILLING_SETTING_SUCCESS',
  GET_COLUMN_IMPORT_BILLING_SETTING_FAILURE: 'SUPPORT_GET_COLUMN_IMPORT_BILLING_SETTING_FAILURE',

  GET_DEFAULT_IMPORT_BILLING_SETTING_REQUEST: 'SUPPORT_GET_DEFAULT_IMPORT_BILLING_SETTING_REQUEST',
  GET_DEFAULT_IMPORT_BILLING_SETTING_SUCCESS: 'SUPPORT_GET_DEFAULT_IMPORT_BILLING_SETTING_SUCCESS',
  GET_DEFAULT_IMPORT_BILLING_SETTING_FAILURE: 'SUPPORT_GET_DEFAULT_IMPORT_BILLING_SETTING_FAILURE',

  GET_AUTOCHANGE_IMPORT_BILLING_SETTING_REQUEST: 'SUPPORT_GET_AUTOCHANGE_IMPORT_BILLING_SETTING_REQUEST',
  GET_AUTOCHANGE_IMPORT_BILLING_SETTING_SUCCESS: 'SUPPORT_GET_AUTOCHANGE_IMPORT_BILLING_SETTING_SUCCESS',
  GET_AUTOCHANGE_IMPORT_BILLING_SETTING_FAILURE: 'SUPPORT_GET_AUTOCHANGE_IMPORT_BILLING_SETTING_FAILURE',

  SEND_ALL_IMPORT_BILLING_SETTING_REQUEST: 'SUPPORT_SEND_ALL_IMPORT_BILLING_SETTING_REQUEST',
  SEND_ALL_IMPORT_BILLING_SETTING_SUCCESS: 'SUPPORT_SEND_ALL_IMPORT_BILLING_SETTING_SUCCESS',
  SEND_ALL_IMPORT_BILLING_SETTING_FAILURE: 'SUPPORT_SEND_ALL_IMPORT_BILLING_SETTING_FAILURE',

  SEND_GENERAL_IMPORT_BILLING_SETTING_REQUEST: 'SUPPORT_SEND_GENERAL_IMPORT_BILLING_SETTING_REQUEST',
  SEND_GENERAL_IMPORT_BILLING_SETTING_SUCCESS: 'SUPPORT_SEND_GENERAL_IMPORT_BILLING_SETTING_SUCCESS',
  SEND_GENERAL_IMPORT_BILLING_SETTING_FAILURE: 'SUPPORT_SEND_GENERAL_IMPORT_BILLING_SETTING_FAILURE',

  SEND_COLUMN_IMPORT_BILLING_SETTING_REQUEST: 'SUPPORT_SEND_COLUMN_IMPORT_BILLING_SETTING_REQUEST',
  SEND_COLUMN_IMPORT_BILLING_SETTING_SUCCESS: 'SUPPORT_SEND_COLUMN_IMPORT_BILLING_SETTING_SUCCESS',
  SEND_COLUMN_IMPORT_BILLING_SETTING_FAILURE: 'SUPPORT_SEND_COLUMN_IMPORT_BILLING_SETTING_FAILURE',

  SEND_DEFAULT_IMPORT_BILLING_SETTING_REQUEST: 'SUPPORT_SEND_DEFAULT_IMPORT_BILLING_SETTING_REQUEST',
  SEND_DEFAULT_IMPORT_BILLING_SETTING_SUCCESS: 'SUPPORT_SEND_DEFAULT_IMPORT_BILLING_SETTING_SUCCESS',
  SEND_DEFAULT_IMPORT_BILLING_SETTING_FAILURE: 'SUPPORT_SEND_DEFAULT_IMPORT_BILLING_SETTING_FAILURE',

  GET_CUSTOM_GENERAL_SETTING_REQUEST: 'SUPPORT_GET_CUSTOM_GENERAL_SETTING_REQUEST',
  GET_CUSTOM_GENERAL_SETTING_SUCCESS: 'SUPPORT_GET_CUSTOM_GENERAL_SETTING_SUCCESS',
  GET_CUSTOM_GENERAL_SETTING_FAILURE: 'SUPPORT_GET_CUSTOM_GENERAL_SETTING_FAILURE',

  SEND_CUSTOM_GENERAL_SETTING_REQUEST: 'SUPPORT_SEND_CUSTOM_GENERAL_SETTING_REQUEST',
  SEND_CUSTOM_GENERAL_SETTING_SUCCESS: 'SUPPORT_SEND_CUSTOM_GENERAL_SETTING_SUCCESS',
  SEND_CUSTOM_GENERAL_SETTING_FAILURE: 'SUPPORT_SEND_CUSTOM_GENERAL_SETTING_FAILURE',

  GET_CUSTOM_REPORT_SETTING_SUCCESS: 'GET_CUSTOM_REPORT_SETTING_SUCCESS',
  SEND_CUSTOM_REPORT_SETTING_SUCCESS: 'SEND_CUSTOM_REPORT_SETTING_SUCCESS',

  GET_SYNC_SETTING_REQUEST: 'SUPPORT_GET_SYNC_SETTING_REQUEST',
  GET_SYNC_SETTING_SUCCESS: 'SUPPORT_GET_SYNC_SETTING_SUCCESS',
  GET_SYNC_SETTING_FAILURE: 'SUPPORT_GET_SYNC_SETTING_FAILURE',

  SEND_SYNC_SETTING_REQUEST: 'SUPPORT_SEND_SYNC_SETTING_REQUEST',
  SEND_SYNC_SETTING_SUCCESS: 'SUPPORT_SEND_SYNC_SETTING_SUCCESS',
  SEND_SYNC_SETTING_FAILURE: 'SUPPORT_SEND_SYNC_SETTING_FAILURE',

  GET_EXPORT_SETTING_REQUEST: 'SUPPORT_GET_EXPORT_SETTING_REQUEST',
  GET_EXPORT_SETTING_SUCCESS: 'SUPPORT_GET_EXPORT_SETTING_SUCCESS',
  GET_EXPORT_SETTING_FAILURE: 'SUPPORT_GET_EXPORT_SETTING_FAILURE',

  GET_SILEX_ENCODINGS_REQUEST: 'GET_SILEX_ENCODINGS_REQUEST',
  GET_SILEX_ENCODINGS_SUCCESS: 'GET_SILEX_ENCODINGS_SUCCESS',
  GET_SILEX_ENCODINGS_FAILURE: 'GET_SILEX_ENCODINGS_FAILURE',

  GET_SILEX_ENCODING_METHODS_REQUEST: 'GET_SILEX_ENCODING_METHODS_REQUEST',
  GET_SILEX_ENCODING_METHODS_SUCCESS: 'GET_SILEX_ENCODING_METHODS_SUCCESS',
  GET_SILEX_ENCODING_METHODS_FAILURE: 'GET_SILEX_ENCODING_METHODS_FAILURE',

  SEND_EXPORT_SETTING_REQUEST: 'SUPPORT_SEND_EXPORT_SETTING_REQUEST',
  SEND_EXPORT_SETTING_SUCCESS: 'SUPPORT_SEND_EXPORT_SETTING_SUCCESS',
  SEND_EXPORT_SETTING_FAILURE: 'SUPPORT_SEND_EXPORT_SETTING_FAILURE',

  SEND_BILLING_SETTING_REQUEST: 'SUPPORT_SEND_BILLING_SETTING_REQUEST',
  SEND_BILLING_SETTING_SUCCESS: 'SUPPORT_SEND_BILLING_SETTING_SUCCESS',
  SEND_BILLING_SETTING_FAILURE: 'SUPPORT_SEND_BILLING_SETTING_FAILURE',

  GET_REMOTE_READING_SETTING_REQUEST: 'SUPPORT_GET_REMOTE_READING_SETTING_REQUEST',
  GET_REMOTE_READING_SETTING_SUCCESS: 'SUPPORT_GET_REMOTE_READING_SETTING_SUCCESS',
  GET_REMOTE_READING_SETTING_FAILURE: 'SUPPORT_GET_REMOTE_READING_SETTING_FAILURE',

  SEND_REMOTE_READING_SETTING_REQUEST: 'SUPPORT_SEND_REMOTE_READING_SETTING_REQUEST',
  SEND_REMOTE_READING_SETTING_SUCCESS: 'SUPPORT_SEND_REMOTE_READING_SETTING_SUCCESS',
  SEND_REMOTE_READING_SETTING_FAILURE: 'SUPPORT_SEND_REMOTE_READING_SETTING_FAILURE',

  GET_GENERAL_REMOTE_READING_SETTING_REQUEST: 'SUPPORT_GET_GENERAL_REMOTE_READING_SETTING_REQUEST',
  GET_GENERAL_REMOTE_READING_SETTING_SUCCESS: 'SUPPORT_GET_GENERAL_REMOTE_READING_SETTING_SUCCESS',
  GET_GENERAL_REMOTE_READING_SETTING_FAILURE: 'SUPPORT_GET_GENERAL_REMOTE_READING_SETTING_FAILURE',

  GET_COLUMN_REMOTE_READING_SETTING_REQUEST: 'SUPPORT_GET_COLUMN_REMOTE_READING_SETTING_REQUEST',
  GET_COLUMN_REMOTE_READING_SETTING_SUCCESS: 'SUPPORT_GET_COLUMN_REMOTE_READING_SETTING_SUCCESS',
  GET_COLUMN_REMOTE_READING_SETTING_FAILURE: 'SUPPORT_GET_COLUMN_REMOTE_READING_SETTING_FAILURE',

  SEND_GENERAL_REMOTE_READING_SETTING_REQUEST: 'SUPPORT_SEND_GENERAL_REMOTE_READING_SETTING_REQUEST',
  SEND_GENERAL_REMOTE_READING_SETTING_SUCCESS: 'SUPPORT_SEND_GENERAL_REMOTE_READING_SETTING_SUCCESS',
  SEND_GENERAL_REMOTE_READING_SETTING_FAILURE: 'SUPPORT_SEND_GENERAL_REMOTE_READING_SETTING_FAILURE',

  SEND_COLUMN_REMOTE_READING_SETTING_REQUEST: 'SUPPORT_SEND_COLUMN_REMOTE_READING_SETTING_REQUEST',
  SEND_COLUMN_REMOTE_READING_SETTING_SUCCESS: 'SUPPORT_SEND_COLUMN_REMOTE_READING_SETTING_SUCCESS',
  SEND_COLUMN_REMOTE_READING_SETTING_FAILURE: 'SUPPORT_SEND_COLUMN_REMOTE_READING_SETTING_FAILURE',

  AUTOCHANGE_CONFIG_REQUEST: 'AUTOCHANGE_CONFIG_REQUEST',
  AUTOCHANGE_CONFIG_SUCCESS: 'AUTOCHANGE_CONFIG_SUCCESS',
  AUTOCHANGE_CONFIG_FAILURE: 'AUTOCHANGE_CONFIG_FAILURE',

  GET_LOGS_REQUEST: 'GET_LOGS_REQUEST',
  GET_LOGS_SUCCESS: 'GET_LOGS_SUCCESS',
  GET_LOGS_FAILURE: 'GET_LOGS_FAILURE',

  REFRESH_ABO_PORTAL_REQUEST: 'REFRESH_ABO_PORTAL_REQUEST',
  REFRESH_ABO_PORTAL_SUCCESS: 'REFRESH_ABO_PORTAL_SUCCESS',
  REFRESH_ABO_PORTAL_FAILURE: 'REFRESH_ABO_PORTAL_FAILURE',

  CLEAR: 'SUPPORT_CLEAR',
};

export default supportConstants;
