import React from 'react';

const ReleveRadio = (props: any) => (
  <svg version="1.1" id="Calque_1" viewBox="0 0 69 68.5" {...props}>
    <g>
      <path
        d="M51.8,16.1c-2.4-2.4-5.1-4.6-8.1-6.6c-7.6-5.1-15.9-8.3-24.4-9.5c-3.6-0.5-6.9,2-7.4,5.6c-0.5,3.6,2,6.9,5.6,7.4
		c6.6,0.9,13,3.3,18.9,7.3c2.3,1.5,4.3,3.3,6.2,5.1c9.1,9,13.8,21.3,13.3,33.6c-0.1,3.6,2.7,6.7,6.3,6.8c1.9,0.1,3.7-0.7,4.9-2
		c1.1-1.1,1.8-2.7,1.9-4.4C69.6,43.6,63.6,27.7,51.8,16.1z"
      />
      <path
        d="M36.7,31.4c-1.5-1.5-3.2-2.9-5.1-4.2c-4.6-3.1-9.6-5.1-14.7-5.9c-3.6-0.6-6.9,1.9-7.5,5.4c-0.6,3.6,1.9,6.9,5.4,7.5
		c3.3,0.5,6.5,1.8,9.4,3.8c1.2,0.8,2.3,1.7,3.3,2.7c4.2,4.1,6.6,9.6,6.9,15.3c0.2,3.6,3.3,6.4,6.9,6.2c1.7-0.1,3.2-0.8,4.3-2
		c1.2-1.3,2-3.1,1.9-5C47,46.5,43.3,37.9,36.7,31.4z"
      />
      <path d="M3.6,47.3c-4.8,4.9-4.7,12.8,0.2,17.6s12.8,4.7,17.6-0.2c4.8-4.9,4.7-12.8-0.2-17.6C16.3,42.3,8.4,42.4,3.6,47.3z" />
    </g>
  </svg>
);

export default ReleveRadio;
