const reportConstants = {
  GET_ALL_REPORT_REQUEST: 'ROUND_REPORT_GET_ALL_REPORT_REQUEST',
  GET_ALL_REPORT_SUCCESS: 'ROUND_REPORT_GET_ALL_REPORT_SUCCESS',
  GET_ALL_REPORT_FAILURE: 'ROUND_REPORT_GET_ALL_REPORT_FAILURE',

  GET_REPORT_REQUEST: 'ROUND_REPORT_GET_REPORT_REQUEST',
  GET_REPORT_SUCCESS: 'ROUND_REPORT_GET_REPORT_SUCCESS',
  GET_REPORT_FAILURE: 'ROUND_REPORT_GET_REPORT_FAILURE',

  CLEAR: 'ROUND_REPORT_CLEAR',
};

export default reportConstants;
