import React from 'react';

const SvgEye = (props: any) => (
  <svg viewBox="0 0 28.59 19.73" {...props}>
    <g id="eye_svg__Calque_2" data-name="Calque 2">
      <g id="eye_svg__Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          d="M28.32 5.66A20.13 20.13 0 0 0 14.29 0a20.08 20.08 0 0 0-14 5.66.89.89 0 0 0 .6 1.53.86.86 0 0 0 .62-.25 18.43 18.43 0 0 1 25.56 0 .89.89 0 1 0 1.25-1.28z"
        />
        <path
          fill={props.fill}
          d="M14.29 6.47c-6.2 0-10.85 4.2-12.56 6a.89.89 0 0 0 0 1.23c1.71 1.81 6.36 6 12.56 6s10.86-4.21 12.57-6a.89.89 0 0 0 0-1.23c-1.71-1.81-6.36-6-12.57-6zM8.57 9.62a7.23 7.23 0 0 0-.15 6.89 19.78 19.78 0 0 1-4.79-3.41 19.1 19.1 0 0 1 4.94-3.48zm3.43 8.1a5.12 5.12 0 0 1-2.6-4.53 5 5 0 0 1 3.26-4.82 12.38 12.38 0 0 1 1.63-.12c.42 0 .83 0 1.23.07a5.07 5.07 0 0 1 3.4 4.87 5.16 5.16 0 0 1-2.71 4.6 13.33 13.33 0 0 1-1.92.15 12.26 12.26 0 0 1-2.29-.22zm7.8-1a7.07 7.07 0 0 0 .91-3.49 7 7 0 0 0-1.06-3.74A18.73 18.73 0 0 1 25 13.1a19 19 0 0 1-5.15 3.58z"
        />
        <path fill={props.fill} d="M16.43 13.19a2.27 2.27 0 0 1-2.27-2.26 2.26 2.26 0 1 0 2.27 2.26z" />
      </g>
    </g>
  </svg>
);

export default SvgEye;
