function getFilters(locationId: number, context: string) {
  let filters = localStorage.getItem(`site${locationId}`);
  let localFilter: any = { value: [] };
  if (filters && null !== filters) {
    const parsedFilters = JSON.parse(filters);
    localFilter = parsedFilters[context] !== undefined ? parsedFilters[context] : { value: [] };
  }
  return localFilter;
}

function setFilters(locationId: number, filters: any, context: string) {
  let existingFilters = localStorage.getItem(`site${locationId}`);
  let baseFilters: any = {};
  if (existingFilters && null !== existingFilters) {
    baseFilters = JSON.parse(existingFilters);
  }
  const validFilter = filters.value.find((it: any) => it.filterApplied === null || it.filterApplied === false);
  if (validFilter === undefined) {
    baseFilters[context] = filters;
    const strFilters = JSON.stringify(baseFilters);
    localStorage.setItem(`site${locationId}`, strFilters);
  }

  return baseFilters[context];
}

function removeContextFilters(locationId: number, context: string) {
  let key = `site${locationId}`;
  let filters = localStorage.getItem(key);
  if (filters && null !== filters) {
    let newFilters = JSON.parse(filters);
    delete newFilters[context];
    newFilters = JSON.stringify(newFilters);
    localStorage.setItem(`site${locationId}`, newFilters);
  }

  return { value: [] };
}

function clearFilters(locationId: number) {
  let key = `site${locationId}`;
  let filters = localStorage.getItem(key);
  if (filters && null !== filters) {
    localStorage.removeItem(key);
  }

  return {};
}

const storedFiltersService = {
  getFilters,
  setFilters,
  removeContextFilters,
  clearFilters,
};

export default storedFiltersService;
