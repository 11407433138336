import React from 'react';

const SvgTourneeWaitDeaffect = (props: any) => (
  <svg viewBox="0 0 115.13 134.15" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M112.25,14.77h-10V2.87A2.88,2.88,0,0,0,101.1.59,2.91,2.91,0,0,0,98.59.1L53,14.49a2.86,2.86,0,0,0-1.46,1,2.84,2.84,0,0,0-1,2.19V71.45a66.82,66.82,0,0,1-8.39,1.16V60.39a6.69,6.69,0,0,0-11.87-4.23L1.52,91.3a6.65,6.65,0,0,0,.21,8.7l28.74,31.93a6.68,6.68,0,0,0,5,2.22h0a6.7,6.7,0,0,0,6.69-6.7V114.62A57.07,57.07,0,0,0,80.36,93.57c.54-.67,1.07-1.36,1.59-2.06h30.3a2.88,2.88,0,0,0,2.88-2.88v-71A2.88,2.88,0,0,0,112.25,14.77ZM38.44,107.91l-3.31.18v18.56l-28-31.14,28-34.25V79.92l3.62-.11a81.28,81.28,0,0,0,11.77-1.22C69.79,75.07,82.84,64.25,89.4,46.3,89,64.76,83,80.54,73,91.51A49.65,49.65,0,0,1,38.44,107.91ZM67.7,14.79,96.47,6.68v8.11Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgTourneeWaitDeaffect;
