import React from 'react';
import { connect } from 'react-redux';
import Geocode from 'react-geocode';
import { Button, Col, CustomInput, Label, Row } from 'reactstrap';
import _ from 'lodash';
import { AvForm } from 'availity-reactstrap-validation';
import { withTranslation } from 'react-i18next';
import GoogleMapReactC from '../GoogleMap/GoogleMapReactC';
import SimpleGmap from '../_components/SimpleGmap';
import Mapbox from '../Mapbox/components/Mapbox';

Geocode.setApiKey('AIzaSyBSRYNpTYG6I-BWu_MSDpnkDyuKsJrafc4');
Geocode.setLanguage('fr');

interface State {
  locationChecked: boolean;
  locationDisplayedChecked: boolean;
}

/**
 * @class LocationAdressChooser
 * @extends {React.Component<Props, State>}
 */
class LocationAdressDisplay extends React.Component<any, State> {
  /**
   * @param {any} props Propriétés
   * @memberof DisplayTypeChooser
   */
  constructor(props: any) {
    super(props);

    this.formatAddress = this.formatAddress.bind(this);
    this.validate = this.validate.bind(this);
    this.send = this.send.bind(this);
    this.handleSelectedLocation = this.handleSelectedLocation.bind(this);

    this.state = {
      locationChecked: false,
      locationDisplayedChecked: true,
    };
  }

  /**
   * Gère le passage à l'étape précédente
   *
   * @method validate
   * @memberof DisplayTypeChooser
   */
  validate = () => {
    const { steps, update } = this.props;

    update('location', undefined);

    this.setState({
      locationChecked: false,
      locationDisplayedChecked: true,
    });

    this.props.goToStep(steps.location);
  };

  formatAddress(address: any) {
    return (
      <div>
        {address.streetNumber} {address.streetNumberAdditionnal} {address.street}
        {address.complement ? <br /> : null}
        {address.complement}
        <br />
        {address.zipcode} {address.city}
        {address.country ? <br /> : null}
        {address.country}
      </div>
    );
  }

  send(e: any) {
    const { update, form } = this.props;
    const { locationDisplayed } = form;
    const { locationDisplayedChecked } = this.state;

    if (locationDisplayedChecked) {
      update('location', _.cloneDeep(locationDisplayed));
    }
    update('locationDisplayed', undefined);

    this.setState({
      locationChecked: false,
      locationDisplayedChecked: true,
    });

    this.props.firstStep();
  }

  handleSelectedLocation(value: any) {
    const { locationDisplayedChecked, locationChecked } = this.state;
    this.setState({
      locationDisplayedChecked: _.isEqual(value, 'locationDisplayed'),
      locationChecked: _.isEqual(value, 'location'),
    });
  }

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof DisplayTypeChooser
   */
  render() {
    const { isActive, form, t } = this.props;
    const { locationDisplayedChecked, locationChecked } = this.state;
    const { locationDisplayed, location } = form;

    if (!isActive) return null;

    const isSameLocation = () => {
      return (
        location.address.city.toUpperCase() === locationDisplayed.address.city.toUpperCase() &&
        location.address.complement.toUpperCase() === locationDisplayed.address.complement.toUpperCase() &&
        location.address.country.toUpperCase() === locationDisplayed.address.country.toUpperCase() &&
        location.address.street.toUpperCase() === locationDisplayed.address.street.toUpperCase() &&
        location.address.streetNumber.toUpperCase() === locationDisplayed.address.streetNumber.toUpperCase() &&
        location.address.streetNumberAdditionnal.toUpperCase() ===
          locationDisplayed.address.streetNumberAdditionnal.toUpperCase() &&
        location.address.zipcode.toUpperCase() === locationDisplayed.address.zipcode.toUpperCase()
      );
    };
    return (
      <div>
        <h3>{t('location_creator.title.location_place')}</h3>
        <AvForm onValidSubmit={this.send}>
          {location && locationDisplayed && (
            <div className="address_display_container">
              {isSameLocation() ? (
                <div className="address_display_choices">
                  <h6 style={{ marginTop: '15px' }}>{t('location_creator.text.address_entry')}</h6>
                  <Label>{this.formatAddress(location.address)}</Label>
                </div>
              ) : (
                <div className="address_display_choices">
                  <p style={{ marginTop: '15px' }}>{t('location_creator.text.choose_address')} :</p>
                  <Row style={{ margin: '15px 0 15px 0' }}>
                    <Col style={{ paddingLeft: '25px' }} md="6">
                      <Row style={{ marginLeft: '-25px' }}>
                        <CustomInput
                          type="radio"
                          value="location"
                          id="radioLocation"
                          checked={locationChecked}
                          name="customRadio"
                          onClick={() => this.handleSelectedLocation('location')}
                        />
                        <h6 onClick={() => this.handleSelectedLocation('location')}>
                          {t('location_creator.text.address_entry')}
                        </h6>
                      </Row>
                      <Label onClick={() => this.handleSelectedLocation('location')} style={{ color: 'red' }}>
                        {this.formatAddress(location.address)}
                      </Label>
                    </Col>
                    <Col style={{ paddingLeft: '25px' }} md="6">
                      <Row style={{ marginLeft: '-25px' }}>
                        <CustomInput
                          type="radio"
                          id="locationDisplayed"
                          checked={locationDisplayedChecked}
                          name="customRadio"
                          onClick={() => this.handleSelectedLocation('locationDisplayed')}
                        />
                        <h6 onClick={() => this.handleSelectedLocation('locationDisplayed')}>
                          {t('location_creator.text.corrected_address')}
                        </h6>
                      </Row>
                      <Label onClick={() => this.handleSelectedLocation('locationDisplayed')}>
                        {this.formatAddress(locationDisplayed.address)}
                      </Label>
                    </Col>
                  </Row>
                </div>
              )}
              {locationDisplayed.gpsPosition.lat !== 0 && locationDisplayed.gpsPosition.lat !== 0 && (
                <div>
                  {/* <GoogleMapReactC
                    initialCenter={{
                      lat: _.get(locationDisplayed, 'gpsPosition.lat'),
                      lng: _.get(locationDisplayed, 'gpsPosition.lng'),
                    }}
                    zoom={15}
                    centerMarker={{
                      icon: 'home_marker',
                    }}
                    yesIWantToUseGoogleMapApiInternals
                  /> */}
                  <Mapbox
                    initialMap={{
                      baseLng: _.get(locationDisplayed, 'gpsPosition.lng'),
                      baseLat: _.get(locationDisplayed, 'gpsPosition.lat'),
                      baseZoom: 15,
                    }}
                    style={{ width: '100%', height: '25vh', overflow: 'visible' }}
                    mapId="map-2"
                    fullscreen={false}
                    geolocation={false}
                    homeMarker
                  />
                </div>
              )}
              <div className="bottomChoice">
                <Row>
                  <Col md="6" className="text-right">
                    <Button color="primary" onClick={this.validate}>
                      {t('location_creator.button.edit_address')}
                    </Button>
                  </Col>
                  <Col md="6" className="text-left">
                    <Button color="primary">{t('all.button.validate')}</Button>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </AvForm>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { locales } = state;
  return {
    locales,
  };
}

const LocationAdressDisplayConnectedComponent = connect(mapStateToProps)(LocationAdressDisplay);
const tr = withTranslation()(LocationAdressDisplayConnectedComponent);
export default tr;
