import { authHeader, handleResponse } from '../_helpers';
import { Notification } from '../_entities/notification';

async function getAll(id: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/user/${id}/notifications`, requestOptions);
  return handleResponse(response);
}

async function getLast(id: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/user/${id}/notifications/last`, requestOptions);
  return handleResponse(response);
}

async function getCount(id: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/user/${id}/notifications/count`, requestOptions);
  return handleResponse(response);
}

async function get(id: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/notification/${id}`, requestOptions);
  return handleResponse(response);
}

async function save(notification: Notification) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(notification),
  };
  let response = await fetch('/api/notification', requestOptions);
  return handleResponse(response);
}

async function update(notification: Notification) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(notification),
  };
  let response = await fetch(`/api/notification/${notification.id}`, requestOptions);
  return handleResponse(response);
}

async function toDelete(id: number) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };
  let response = await fetch(`/api/notification/${id}`, requestOptions);
  return handleResponse(response);
}

const locationService = {
  getAll,
  getLast,
  getCount,
  get,
  update,
  toDelete,
  save,
};

export default locationService;
