import { maskConstants } from '../_constants';
import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

export default function mask(state: any = {}, action: action) {
  switch (action.type) {
    case maskConstants.GET_MASKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case maskConstants.GET_MASKS_SUCCESS:
      return {
        ...state,
        items: action.masks,
        loading: false,
      };
    case maskConstants.GET_MASKS_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des masques d'alarmes",
      };
    case maskConstants.GET_ONE_MASK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case maskConstants.GET_ONE_MASK_SUCCESS:
      return {
        ...state,
        fetchedMask: action.masks,
        loading: false,
      };
    case maskConstants.GET_ONE_MASK_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des masques d'alarmes",
      };
    case maskConstants.GET_MASK_INFO_REQUEST:
      return {
        maskInfo: { ...state, loading: true },
      };
    case maskConstants.GET_MASK_INFO_SUCCESS:
      const allAlarmsPdi = action.maskInfo.listDatePdiMaskAlarm;
      // @ts-ignore
      const sortedAllAlarmsPdi = allAlarmsPdi.sort((a: any, b: any) => new Date(a.date) - new Date(b.date));
      action.maskInfo.listDatePdiMaskAlarm = sortedAllAlarmsPdi;
      const maskInfo = action.maskInfo;
      maskInfo.loading = false;
      return {
        ...state,
        maskInfo: maskInfo,
      };
    case maskConstants.GET_MASK_INFO_FAILURE:
      return {
        error: "Impossible d'obtenir les info de l'alarme",
      };
    case maskConstants.DELETE_MASKS_REQUEST:
      return {
        ...state,
        deleting: action.maskId,
      };
    case maskConstants.DELETE_MASKS_SUCCESS:
      return {
        items: state.items.filter(m => m.id !== state.deleting),
      };
    case maskConstants.DELETE_MASKS_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des masques d'alarmes",
      };
    case maskConstants.CREATE_MASKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case maskConstants.CREATE_MASKS_SUCCESS:
      return {
        ...state,
        newMask: action.masks,
      };
    case maskConstants.CREATE_MASKS_FAILURE:
      return {
        error: 'Impossible de créer le masque',
      };
    case maskConstants.UPDATE_MASKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case maskConstants.UPDATE_MASKS_SUCCESS:
      return {
        ...state,
        newMask: action.masks,
      };
    case maskConstants.UPDATE_MASKS_FAILURE:
      return {
        error: 'Impossible de créer le masque',
      };
    case maskConstants.GET_AVAILABLE_ALARMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case maskConstants.GET_AVAILABLE_ALARMS_SUCCESS:
      return {
        ...state,
        alarms: action.alarms,
        loading: false,
      };
    case maskConstants.GET_AVAILABLE_ALARMS_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des masques d'alarmes",
      };

    case maskConstants.GET_MASK_PDI_ALARMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case maskConstants.GET_MASK_PDI_ALARMS_SUCCESS:
      const alarms = action.maskPdiAlarms.alarms;
      // @ts-ignore
      const sortedAlarms = alarms.sort((a: any, b: any) => new Date(a.date) - new Date(b.date));
      const sortedMaskPdiAlarms = _.cloneDeep(action.maskPdiAlarms);
      sortedMaskPdiAlarms.alarms = sortedAlarms;

      const stateMaskPdiAlarm = state.maskPdiAlarms || [];
      return {
        ...state,
        maskPdiAlarms: [...stateMaskPdiAlarm, sortedMaskPdiAlarms],
        loading: false,
      };

    case maskConstants.GET_MASK_PDI_ALARMS_FAILURE:
      return {
        ...state,
        error: "Impossible d'obtenir les info de l'alarme",
      };

    case maskConstants.CREATE_FROMCHART_MASKS_REQUEST:
      return {
        ...state,
      };
    case maskConstants.CREATE_FROMCHART_MASKS_SUCCESS:
      console.log(action, state);
      action.mask.listAlarm.forEach((alarm: any) => {
        state.masks.push({
          alarmId: alarm.id,
          alarmName: alarm.name,
          endDate: action.mask.endDate,
          startDate: action.mask.startDate,
        });
      });
      return {
        ...state,
      };
    case maskConstants.CREATE_FROMCHART_MASKS_FAILURE:
      return {
        error: 'Impossible de créer ce masque',
      };
    case maskConstants.GET_MASK_PDI_REQUEST:
      return {
        ...state,
      };
    case maskConstants.GET_MASK_PDI_SUCCESS:
      console.log('test bug', state, action);
      state.alarmDesac = state.alarmDesac ? state.alarmDesac : [];

      return {
        ...state,
        masks: action.masks,
      };
    case maskConstants.GET_MASK_PDI_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des masques d'alarmes",
      };
    case maskConstants.DESAC_MASK_SUCCESS:
      const listMask = cloneDeep(state.masks);
      const newList = state.alarmDesac ? cloneDeep(state.alarmDesac) : [];
      console.log(action, state);
      if (action.alarmName && listMask.find(el => el.alarmName.includes(action.alarmName))) {
        newList.push(listMask.find(el => el.alarmName.includes(action.alarmName)));

        state.alarmDesac = newList;
        state.masks = listMask.filter(el => !el.alarmName.includes(action.alarmName));
      }
      return {
        ...state,
      };
    case maskConstants.ACTIV_MASK_SUCCESS:
      const listMask2 = state.alarmDesac ? cloneDeep(state.alarmDesac) : [];
      const newList2 = cloneDeep(state.masks);
      if (action.alarmName && listMask2.find(el => el.alarmName.includes(action.alarmName))) {
        console.log(listMask2);
        newList2.push(listMask2.find(el => el.alarmName.includes(action.alarmName)));

        state.alarmDesac = listMask2.filter(el => !el.alarmName.includes(action.alarmName));
        state.masks = newList2;
      }

      return {
        ...state,
      };
    default:
      return state;
  }
}
