import { customExportConstants } from '../_constants';

export default function customexport(state: any = {}, action: action) {
  switch (action.type) {
    case customExportConstants.GET_ALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case customExportConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.customExport,
      };
    case customExportConstants.GET_ONE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchedCustomExport: action.customExport,
      };
    case customExportConstants.GET_ALL_FAILURE:
      return {
        loading: false,
        error: action.type,
      };
    case customExportConstants.SAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case customExportConstants.SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        savedCustomExport: action.customExport,
      };
    case customExportConstants.SAVE_FAILURE:
      return {
        loading: false,
        error: action.type,
      };

    case customExportConstants.LAUNCH_REQUEST:
      return {
        ...state,
        exportId: action.exportId,
        export_processing: true,
      };
    case customExportConstants.LAUNCH_SUCCESS:
      return {
        ...state,
        export_processing: false,
      };
    case customExportConstants.LAUNCH_FAILURE:
      return {
        ...state,
        export_processing: false,
      };
    default:
      return state;
  }
}
