import React, { Component } from 'react';
import { Label, Col, Row } from 'reactstrap';
import { AvField, AvGroup } from 'availity-reactstrap-validation';
import { cloneDeep, isEmpty as _isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';

/**
 * @class {ConsoInterval}
 * @extends {Component<any, {}>}
 */
class ConsoInterval extends Component<any, {}> {
  /**
   * @constructor
   * @param {any} props Propriétés
   */
  constructor(props: any) {
    super(props);
    const { condition } = this.props;
    let { conditionValue } = condition;

    this.state = {
      values: conditionValue && Object.keys(conditionValue).length > 0 ? conditionValue : { inf: null, sup: null },
    };
  }

  /**
   * Gère le changement des valeurs des inputs inférieur/supérieur
   */
  onChange = (e: any) => {
    const { values }: any = this.state;
    const valuesMounted = cloneDeep(values);
    if (e.target.name === 'inf') {
      valuesMounted.inf = e.target.value;
    }
    if (e.target.name === 'sup') {
      valuesMounted.sup = e.target.value;
    }

    this.setState({
      values: valuesMounted,
    });
  };

  /**
   * Teste si l'un des deux inputs (inférieur ou supérieur) est
   * bien saisi
   *
   * @method checkInfAndOrSup
   * @param {any} val Valeur
   * @param {any} ctx Contexte
   */
  checkInfAndOrSup = (val: any, ctx: any) => {
    const { condition, t } = this.props;
    if (ctx[condition.conditionTitle] && !ctx[condition.conditionTitle].inf && !ctx[condition.conditionTitle].sup) {
      return t('conso_interval.text.threshold_required');
    }
    return true;
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { values }: any = this.state;
    const { title, condition, unit, t } = this.props;
    return (
      <AvGroup>
        <Label for={`${title}Name`}>{t('conso_interval.text.threshold_x', { unit: unit })}</Label>
        <Row>
          <Col md="6">
            <AvField
              type="number"
              name={`${condition.conditionTitle}[inf]`}
              id="inf"
              value={values.inf}
              step="0.001"
              validate={{ min: { value: 0 }, customValid: this.checkInfAndOrSup }}
              placeholder={t('all.text.less_than')}
            />
          </Col>
          <Col>
            <AvField
              type="number"
              name={`${condition.conditionTitle}[sup]`}
              value={values.sup}
              step="0.001"
              id="sup"
              onChange={this.onChange}
              validate={{ customValid: this.checkInfAndOrSup }}
              placeholder={t('all.text.greater_than')}
            />
          </Col>
        </Row>
      </AvGroup>
    );
  }
}

const tr = withTranslation()(ConsoInterval);
export default tr;
