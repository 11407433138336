import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import PoubelleBleu from '../SvgComponents/PoubelleBleu';
import Ajout from '../SvgComponents/AjoutBleu';

export default class Tag extends Component<any, any> {
  render() {
    const { tag, tagIndex, updateTag, deleteRow } = this.props;
    console.log(tag);
    return (
      <div className={'tag-container'}>
        <div className={'input-container left'}>
          <AvForm>
            <AvField
              name="nameCustomMessage"
              label="Code"
              type="text"
              value={tag.code}
              onChange={(e: any) => updateTag(e.target.value, tagIndex, 'code')}
              validate={{
                required: { value: true, errorMessage: 'Entrez un code svp' },
                pattern: {
                  value: '^[A-Za-z0-9]+$',
                  errorMessage: "Le code ne doit être composer qu'avec des lettres ou des chiffres",
                },
                maxLength: { value: 2, errorMessage: 'Le code ne doit contenir que 2 charactères maximum' },
              }}
            />
          </AvForm>
        </div>
        <div className={'input-container'}>
          <AvForm>
            <AvField
              name="nameCustomMessage"
              label="Message"
              type="text"
              value={tag.label}
              onChange={(e: any) => updateTag(e.target.value, tagIndex, 'label')}
              validate={{
                required: { value: true, errorMessage: 'Entrez un message svp' },
                maxLength: { value: 20, errorMessage: 'Le code ne doit contenir que 20 charactères maximum' },
              }}
            />
          </AvForm>
        </div>
        <div className={'poubelle-container'}>
          <div className={'clickable-poubelle'} onClick={() => deleteRow(tagIndex)}>
            <PoubelleBleu className="add" fill="curentcolor" width="20px" />
          </div>
        </div>
      </div>
    );
  }
}
