import React from 'react';

const SvgMailVert = (props: any) => (
  <svg viewBox="0 0 121.38 82.98" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M102.58,56,82.93,32.9l17.46-10a3.56,3.56,0,0,0-3.53-6.18L60.69,37.39,24.52,16.76A3.56,3.56,0,0,0,21,22.94l17.46,10L18.8,56a3.56,3.56,0,0,0,5.42,4.61L44.74,36.48l14.19,8.1a3.56,3.56,0,0,0,3.52,0l14.19-8.1L97.16,60.62A3.56,3.56,0,0,0,102.58,56Z"
        />
        <path
          fill={props.fill}
          d="M110.71,0h-100A10.69,10.69,0,0,0,0,10.67V72.31A10.69,10.69,0,0,0,10.67,83h100a10.68,10.68,0,0,0,10.67-10.67V10.67A10.68,10.68,0,0,0,110.71,0Zm3.56,72.31a3.56,3.56,0,0,1-3.56,3.55h-100a3.56,3.56,0,0,1-3.56-3.55V10.67a3.57,3.57,0,0,1,3.56-3.56h100a3.57,3.57,0,0,1,3.56,3.56Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgMailVert;
