const exportConstants = {
  UPDATE_VIEW_REQUEST: 'EXPORT_UPDATE_VIEW_REQUEST',
  UPDATE_VIEW_SUCCESS: 'EXPORT_UPDATE_VIEW_SUCCESS',
  UPDATE_VIEW_FAILURE: 'EXPORT_UPDATE_VIEW_FAILURE',

  EXPORTCSV_REQUEST: 'EXPORT_CSV_REQUEST',
  EXPORTCSV_SUCCESS: 'EXPORT_CSV_SUCCESS',
  EXPORTCSV_FAILURE: 'EXPORT_CSV_FAILURE',

  CLEAR: 'EXPORT_CLEAR',
};

export default exportConstants;
