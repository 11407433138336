import _ from 'lodash';
import gestionnaireConstants from '../_constants/gestionnaire.constants';

export default function gestionnaire(state: any = {}, action: any) {
  switch (action.type) {
    case gestionnaireConstants.GETALLGESTIONNAIRE_REQUEST:
      return {
        loading: true,
      };
    case gestionnaireConstants.GETALLGESTIONNAIRE_SUCCESS:
      return {
        ...state,
        gestionnaires: action.gestionnaires,
        loading: false,
      };

    case gestionnaireConstants.GETALLGESTIONNAIRE_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des gestionnaires",
      };

    case gestionnaireConstants.GETGESTIONNAIRE_REQUEST:
      return state;

    case gestionnaireConstants.GETGESTIONNAIRE_SUCCESS:
      return {
        ...state,
        gestionnaire: action.gestionnaire,
      };
    case gestionnaireConstants.GETGESTIONNAIRE_FAILURE:
      return {
        error: "Impossible d'obtenir ce gestionnaire",
      };
    case gestionnaireConstants.ADD_GESTIONNAIRE_PERM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case gestionnaireConstants.ADD_GESTIONNAIRE_PERM_SUCCESS:
      const copyGestionnaire = _.cloneDeep(state.gestionnaires).map(el => {
        const copy = _.cloneDeep(el);
        if (!copy.access) {
          copy.access = action.gestionnaires.find(gestionnaire => gestionnaire.gestionnaireId === el.id);
        }
        return copy;
      });
      console.log(copyGestionnaire);
      return {
        ...state,
        gestionnaires: copyGestionnaire,
        loading: false,
      };
    case gestionnaireConstants.ADD_GESTIONNAIRE_PERM_FAILURE:
      return {
        error: "Impossible d'ajouter ce gestionnaire à cet utilisateur",
      };
    case gestionnaireConstants.DELETE_GESTIONNAIRE_PERM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case gestionnaireConstants.DELETE_GESTIONNAIRE_PERM_SUCCESS:
      console.log(state);
      const copyGestionnaires = _.cloneDeep(state.gestionnaires).map(el => {
        const copy = _.cloneDeep(el);
        if (action.gestionnaires.find(gestionnaire => gestionnaire.gestionnaireId === el.id)) {
          copy.access = null;
        }
        return copy;
      });
      return {
        ...state,
        gestionnaires: copyGestionnaires,
        loading: false,
      };
    case gestionnaireConstants.DELETE_GESTIONNAIRE_PERM_FAILURE:
      return {
        error: 'Impossible de supprimer ce gestionnaire de cet utilisateur',
      };
    case gestionnaireConstants.GET_GESTIONNAIRE_PERM_REQUEST:
      return {
        ...state,
        loading: true,
        init: true,
      };
    case gestionnaireConstants.GET_GESTIONNAIRE_PERM_SUCCESS:
      console.log(action, state);
      state.gestionnaires = _.cloneDeep(state.gestionnaires)
        .map(el => action.gestionnaires.find(perm => perm.id === el.id) || el)
        .map(el => {
          if (!el.access) {
            el.access = false;
          }
          return el;
        });
      return {
        ...state,
        loading: false,
      };
    case gestionnaireConstants.GET_GESTIONNAIRE_PERM_FAILURE:
      return {
        error: 'Impossible de supprimer ce gestionnaire de cet utilisateur',
      };
    case gestionnaireConstants.SWITCH_DEVICE_DELETION_PERMISSION_REQUEST:
      return {
        ...state,
      };
    case gestionnaireConstants.SWITCH_DEVICE_DELETION_PERMISSION_SUCCESS:
      state.gestionnaires = _.cloneDeep(state.gestionnaires).map(el => {
        const elCopy = _.cloneDeep(el);
        elCopy.access = action.gestionnaires.find(gest => gest.gestionnaireId === el.id) || el.access;
        return elCopy;
      });
      return {
        ...state,
      };
    case gestionnaireConstants.SWITCH_DEVICE_DELETION_PERMISSION_FAILURE:
      return {
        error: "Impossible de modifier l'autorisation du gestionnaire de cet utilisateur",
      };
    case gestionnaireConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
