import { authHeader, handleResponse } from '../_helpers';

async function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/enquete`, requestOptions);
  return handleResponse(response);
}

async function getByNumPage(numPage: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/enquete/${numPage}`, requestOptions);
  return handleResponse(response);
}

async function savePage(body: object) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };
  const response = await fetch(`/api/enquete`, requestOptions);
  return handleResponse(response);
}

async function updatePage(body: object, numPage: string) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  };
  const response = await fetch(`/api/enquete/${numPage}`, requestOptions);
  return handleResponse(response);
}

async function getLinks(gestionnaireId: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/enquete/links/${gestionnaireId}`, requestOptions);
  return handleResponse(response);
}

async function saveLinks(links: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(links),
  };
  const response = await fetch(`/api/enquete/links`, requestOptions);
  return handleResponse(response);
}

async function deleteEnquetePage(enqueteContent: string) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };
  const response = await fetch(`/api/enquete/${enqueteContent}`, requestOptions);
  return handleResponse(response);
}

export default {
  getAll,
  getByNumPage,
  updatePage,
  savePage,
  getLinks,
  saveLinks,
  deleteEnquetePage,
};
