import React from 'react';

const FlecheRetourLarge = (props: any) => (
  <svg viewBox="0 0 130.31 83.67" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M123.59,35.12H22.93L46.58,11.47A6.72,6.72,0,1,0,37.08,2L2,37.08A6.62,6.62,0,0,0,.51,39.27a6.75,6.75,0,0,0,0,5.13A6.92,6.92,0,0,0,2,46.59L37.08,81.71a6.72,6.72,0,0,0,9.5-9.51L22.93,48.55H123.59a6.72,6.72,0,1,0,0-13.43Z"
        />
      </g>
    </g>
  </svg>
);

export default FlecheRetourLarge;
