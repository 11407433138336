import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, Comparator } from 'react-bootstrap-table2-filter';
import { animateScroll } from 'react-scroll';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import AddDashBoard from './AddDashBoard';
import EditDashBoard from './EditDashBoard';
import TableauBordVert from '../SvgComponents/TableauBordVert';
import PoubelleBleue from '../SvgComponents/PoubelleBleu';
import EditionBleu from '../SvgComponents/EditionBleu';

import { locationActions, dashboardActions, bookmarkActions, alertActions } from '../_actions';
import DisplayBookmark from '../Bookmarks/DisplayBookmarks';
import { dashboard } from '../_entities/dashboard';
import { User } from '../_entities/user';
import { dashboards, locations, bookmarks } from '../_interfaces/reducers';
import { formatDashboardName } from '../_helpers';
import LoadingBand from '../Bands/Loading';
import ErrorBand from '../Bands/Error';
import listLevel from '../User/listPermission';
import history from '../_helpers/history';
import userActions from '../_actions/user.actions';
import { withTranslation } from 'react-i18next';
import StepPopover from '../_components/StepPopover';

interface Props {
  dashboards: dashboards;
  locations: locations;
  history: Array<string>;
  bookmarks: bookmarks;
  user: User;
  dispatch: Function;
  match: any;
  users: any;
  t: Function;
}

interface State {
  locationId: number;
  dashboardToEdit: Object;
  editionOpen: boolean;
  open: boolean;
  confirm: boolean;
}

/**
 * @class DisplayDashBoardsComponent
 * @extends {React.Component<Props, State>}
 */
class DisplayDashBoardsComponent extends React.Component<Props, State> {
  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof DisplayDashBoardsComponent
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      locationId: 0,
      dashboardToEdit: {},
      editionOpen: false,
      open: false,
      confirm: false,
    };
    this.delete = this.delete.bind(this);
  }

  /**
   * Récupère tous les éléments nécessaires au composant
   * à son montage
   *
   * @method componentDidMount
   * @memberof DisplayDashBoardsComponent
   */
  componentDidMount() {
    const { dispatch, match, user } = this.props;
    this.setState({
      locationId: match.params.locationId,
    });
    dispatch(userActions.get(user.id));
    dispatch(locationActions.get(match.params.locationId));
    dispatch(dashboardActions.getAll(match.params.locationId));
    dispatch(bookmarkActions.getAll(match.params.locationId));
    animateScroll.scrollToTop({ duration: 0 });
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    const { users, locations, user }: any = this.props;
    const roleList = ['DIOPTASE', 'SUPERADMIN', 'ADMIN'];
    if (
      !roleList.includes(user.role.name) &&
      locations.fetchedLocation &&
      !locations.fetchedLocation.tournee && //CETTE LIGNE ALEXIS
      users.fetchedUser &&
      !users.fetchedUser.profils.find(
        el => el.locationCode === locations.fetchedLocation.code && el.profil.permissions.length > 0
      )
    ) {
      history.push('/forbidden');
    }
  }

  /**
   * Nettoie le state redux au démontage
   *
   * @method componentWillUnmount
   * @memberof DisplayDashBoardsComponent
   */
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(locationActions.clear());
    dispatch(dashboardActions.clear());
    dispatch(bookmarkActions.clear());
  }

  couldSee = (dashboards: any) => {
    const { user } = this.props;
    let accessLevel = 0;
    const dataTab: any = [];

    console.log(dashboards);

    dashboards.forEach((element: any) => {
      accessLevel = listLevel[user.role.name] - listLevel[element.owner.role.name];

      switch (accessLevel) {
        case 0:
          if ((element && element.owner && user.id === element.owner.id) || !element.private) {
            return dataTab.push(element);
          }
          break;
        case -1:
        case -2:
          if (!element.private) {
            return dataTab.push(element);
          }
          break;
        case 1:
        case 2:
        default:
          return dataTab.push(element);
      }
    });
    return dataTab;
  };

  /**
   * Gère les droits d'edition des dashboards
   *
   * @returns {boolean} Le contrôle
   * @method couldEditOrDeleteDashboard
   * @memberof DisplayDashBoardsComponent
   */
  couldEditOrDeleteDashboard = (dashboard: dashboard) => {
    const { user, users, locations } = this.props;

    const roleList = ['DIOPTASE', 'SUPERADMIN', 'ADMIN'];
    if (roleList.includes(user.role.name)) {
      return true;
    }

    // regarder les permissions et si la personne est propriétaire du dashboard
    if (dashboard && dashboard.owner && user.id === dashboard.owner.id) {
      return true;
    }
    if (locations.fetchedLocation && locations.fetchedLocation.tournee) {
      //CETTE LIGNE ALEXIS
      return true;
    }
    if (
      users &&
      users.fetchedUser &&
      locations.fetchedLocation &&
      users.fetchedUser.profils.find(
        (el: any) =>
          el.profil.permissions.find((permission: any) => permission.name === 'edit.dashboard') &&
          el.locationCode === locations.fetchedLocation.code &&
          !dashboard.personal &&
          !dashboard.private
      )
    ) {
      return true;
    }

    return false;
  };

  /**
   * Gère le rechargement dans le cas où un dashboard
   * a été édité
   *
   * @method isEdited
   * @memberof DisplayDashBoardsComponent
   */
  isEdited = () => {
    const { editionOpen } = this.state;
    const { dispatch, match, locations } = this.props;
    dispatch(alertActions.clear());
    this.setState({
      dashboardToEdit: {},
      editionOpen: !editionOpen,
    });
    // pour reloader, peux on faire plus propre ?
    dispatch(locationActions.get(match.params.locationId));
    dispatch(dashboardActions.getAll(match.params.locationId));
    dispatch(bookmarkActions.getAll(match.params.locationId));
  };

  /**
   * Affiche la modal d'édition du dashboard
   *
   * @method edit
   * @memberof DisplayDashBoardsComponent
   */
  edit = (e: Object, dashboardToEdit: dashboard) => {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
    const { editionOpen } = this.state;

    this.setState({
      dashboardToEdit,
      editionOpen: !editionOpen,
    });
  };

  /**
   * Formate les dashboards
   *
   * @param {Array<Object>} dashboards Dashboards
   * @method formated
   * @memberof DisplayDashBoardsComponent
   */
  formated = (dasboards: Array<dashboard>) => dasboards.map(it => this.formatedName(it));

  /**
   * Formate le nom d'un dashboard
   *
   * @param {Object} dashboard Dashboard
   * @returns {Object} Le dashboard
   * @method formatedName
   * @memberof DisplayDashBoardsComponent
   */
  formatedName = (dashboard: dashboard) => {
    const { user, t } = this.props;
    const copy = cloneDeep(dashboard);
    copy.name = formatDashboardName(user, dashboard, t);
    return copy;
  };

  /**
   * Gère la suppression d'un dashboard
   *
   * @param {Object} event Evènement
   * @param {dashboard} dashboard Dashboard
   * @method delete
   * @memberof DisplayDashBoardsComponent
   */
  async delete(event: Object, dashboard: dashboard) {
    const { dashboards, dispatch, locations } = this.props;
    dispatch(dashboardActions.deleteDashboard(dashboard, dashboards.items, _.get(locations, 'fetchedLocation.id')));
  }

  PopIsOver = ({ row }: { row: any }) => {
    const { dashboards, t } = this.props;
    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const rowFromProps = _.entries(dashboards.items).map(el => el[1]);
    const rowIDs = rowFromProps.map(row => row.id);

    return (
      <div
        id={`dashboard-remove${row.id}`}
        className="clickable round"
        role="presentation"
        onClick={event => {
          event.stopPropagation();
          rowIDs.forEach(it => {
            if (document.getElementById(`dashboard-remove-popover${it}`)) {
              if (it === row.id) {
                document.getElementById(`dashboard-remove-popover${it}`).parentElement.style.display = 'block';
              } else {
                document.getElementById(`dashboard-remove-popover${it}`).parentElement.style.display = 'none';
              }
            }
          });
          setOpen(true);
        }}
      >
        <PoubelleBleue height="1em" width="1em" />
        <StepPopover
          id={`dashboard-remove-popover${row.id}`}
          target={`dashboard-remove${row.id}`}
          open={open}
          onClose={() => setOpen(false)}
          confirm={confirm}
          setConfirm={setConfirm}
          withTwoSteps={row.personal}
          t={t}
          permission={this.couldEditOrDeleteDashboard(row)}
          action={e => this.delete(e, row)}
          title={t('display_dashboards.delete_modal.title')}
          warningText={
            <>
              <p style={{ margin: '10px', textAlign: 'center' }}>
                {t('display_dashboards.delete_modal. warning_confirm_dashboard', {
                  ownerFirstName: row.owner.firstName,
                  ownerLastName: row.owner.lastName,
                })}
              </p>
              <p style={{ textAlign: 'center' }}>{t('display_dashboards.delete_modal.warning_confirm')}</p>
            </>
          }
          confirmText={t('display_dashboards.delete_modal.confirm')}
        />
        {!open && (
          <UncontrolledTooltip placement="bottom" target={`dashboard-remove${row.id}`}>
            {' '}
            {t('display_dashboards.button.delete_dashboard')}
          </UncontrolledTooltip>
        )}
      </div>
    );
  };

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof DisplayDashBoardsComponent
   */
  render() {
    const { dashboards, locations, history, bookmarks, user, t } = this.props;
    const { locationId, dashboardToEdit, editionOpen } = this.state;
    const { Fragment } = React;

    const dasboardFilter = textFilter({
      placeholder: t('display_dashboards.filter_placeholder.filter_by_dashboard'), // custom the input placeholder
      className: 'dashboard-custom-text-filter', // custom classname on input
      defaultValue: '', // default filtering value
      comparator: Comparator.LIKE, // default is Comparator.LIKE
    });

    if (dashboards.items) {
      console.log(dashboards.items, this.formated(this.couldSee(dashboards.items)));
    }

    const rowEvents = (e: Object, row: any) => {
      history.push(`/locations/${locationId}/dashboards/${row.id}`);
    };
    const columns = [
      {
        dataField: 'name',
        text: '',
        filter: dasboardFilter, // apply text filter
        isDummyField: true,
        classes: 'dashboard-cell',
        formatter: (cellContent: any, row: any) => (
          <div onClick={e => rowEvents(e, row)} role="presentation">
            {row.name}
          </div>
        ),
      },
      {
        dataField: 'action',
        isDummyField: true,
        align: 'center',
        headerStyle: () => ({ width: '150px' }),
        text: '',
        formatter: (cellContent: any, row: any) =>
          this.couldEditOrDeleteDashboard(row) && (
            <Row>
              <Col md="4">
                <Fragment>
                  <this.PopIsOver row={row} />
                </Fragment>
              </Col>
              <Col md="2" />
              <Col md="4">
                <Fragment>
                  <div
                    id={`dashboard-edit${row.id}`}
                    className="clickable round"
                    role="presentation"
                    onClick={e => this.edit(e, row)}
                  >
                    <EditionBleu height="1em" width="1em" />
                    <UncontrolledTooltip placement="bottom" target={`dashboard-edit${row.id}`}>
                      {' '}
                      {t('display_dashboards.button.rename_dashboard')}
                    </UncontrolledTooltip>
                  </div>
                </Fragment>
              </Col>
            </Row>
          ),
      },
    ];

    const dashboardsItems = dashboards.items;

    dashboardsItems &&
      dashboardsItems.sort((d1, d2) => {
        if (moment(d1.createdAt).isBefore(moment(d2.createdAt))) {
          return 1;
        }
        if (moment(d1.createdAt).isAfter(moment(d2.createdAt))) {
          return -1;
        }
        return 0;
      });

    return (
      <div className="col-md-12">
        {(dashboards.loading || locations.loading) && <LoadingBand />}
        {dashboards.error && <ErrorBand message={dashboards.error} />}
        {locations.error && <ErrorBand message={locations.error} />}
        {bookmarks.items && bookmarks.items.length > 0 && locations.fetchedLocation && (
          <h3>{t('display_dashboards.title.favorite_dashboard')}</h3>
        )}
        {bookmarks.items && (
          <div>
            <div />
            {bookmarks.items &&
              bookmarks.items.map(b => (
                <DisplayBookmark bookmarks={[]} dashboards={b.dashboards} location={b.location} user={user} />
              ))}
            {dashboardToEdit && Object.keys(dashboardToEdit).length > 0 && (
              <EditDashBoard
                dashboard={dashboardToEdit}
                isEdited={this.isEdited}
                edit={this.edit}
                isOpen={editionOpen}
              />
            )}
            <div className="table-info-container">
              <h2>
                <span>
                  <TableauBordVert height="1em" width="1em" fill="#31c6b3" />
                </span>
                {t('display_dashboards.title.dashboard_plural')} :
                <span className="addItem float-right">
                  {' '}
                  <AddDashBoard location={locations.fetchedLocation} />
                </span>
              </h2>
              <br />

              {dashboards.items && (
                <BootstrapTable
                  keyField="id"
                  data={this.formated(this.couldSee(dashboards.items))}
                  columns={columns}
                  bootstrap4
                  bordered={false}
                  hover
                  rowClasses="clickable"
                  filter={filterFactory()}
                  pagination={dashboards.items.length > 10 ? paginationFactory() : null}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, locations, dashboards, bookmarks, users } = state;
  const { user } = authentication;
  return {
    user,
    locations,
    dashboards,
    bookmarks,
    users,
  };
}

const connectedDisplayDashBoards = connect(mapStateToProps)(DisplayDashBoardsComponent);
const tr = withTranslation()(connectedDisplayDashBoards);
export default tr;
