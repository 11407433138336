import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import logo from '../_svgs/logo_fd_vert.svg';
import { userActions, alertActions } from '../_actions';
import { Button, Row, Col } from 'reactstrap';

import { users, alert } from '../_interfaces/reducers';
import { User } from '../_entities/user';
import moment from 'moment';
import LoadingBand from '../Bands/Loading';

interface Props {
  users: users;
  user: User;
  dispatch: Function;
  alert: alert;
  history: any;
  match: any;
}

/**
 * Gère la réinitialisation du composant
 *
 * @class ResetTokenComponent
 * @extends Component
 */
class ResetTokenComponent extends React.Component<Props, any> {
  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);
  }

  /**
   * @method componentDidMount
   */
  componentDidMount() {
    const { match, dispatch } = this.props;
    dispatch(userActions.getResetTokenInfo(match.params.token));
  }

  /**
   * Nettoie le state au démontage
   *
   * @method componentWillUnmount
   * @memberof ResetTokenComponent
   */
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
  }

  /**
   * Teste si le token utilisé est bien valide
   *
   * @method isTokenValid
   * @returns {boolean} Le test
   */
  isTokenValid = () => {
    const { users } = this.props;
    if (users.token) {
      if (users.token.createdAt && (null === users.token.expiredAt || undefined === users.token.expiredAt)) {
        let created = moment(users.token.createdAt);
        let now = moment();
        return now.diff(created, 'hours') < 48;
      }
    }
    return false;
  };

  /**
   * Retourne à l'accueil de la plateforme
   *
   * @method go
   */
  go = () => {
    const { history } = this.props;
    history.push('/');
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { alert, users } = this.props;

    return (
      <Fragment>
        <div className="container-login">
          <Row>
            <Col className="col-6 login-logo-container">
              <div className="logo">
                <img src={logo} alt="Logo" />
              </div>
            </Col>
            <Col className="col-6 login-form reset-login">
              <h2>Réinitialisation du mot de passe</h2>
              {users && users.loading && <LoadingBand message="Chargement des informations de l'utilisateur" />}

              {users && users.token && this.isTokenValid() && (
                <Fragment>
                  <p>Un rappel d'identifiant avec un mot de passe viens de vous être envoyé.</p>
                  <p>Vous serez invité à changer le mot de passe envoyé par mail à la prochaine connexion</p>
                </Fragment>
              )}

              {users && users.token && !this.isTokenValid() && (
                <Fragment>
                  <div className="alert alert-danger">Jeton périmé</div>
                </Fragment>
              )}
              <Row>
                <Col>
                  <Button color="primary" onClick={this.go}>
                    Aller à l'accueil
                  </Button>
                </Col>
              </Row>

              <div>{alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}</div>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}
function mapStateToProps(state: any) {
  const { authentication, alert, users } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    users,
  };
}

const connectedResetTokenComponentComponent = connect(mapStateToProps)(ResetTokenComponent);
export default connectedResetTokenComponentComponent;
