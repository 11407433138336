import React from 'react';

const Paste = (props: any) => (
  <svg viewBox="0 0 200 200" {...props}>
    <g id="Calque_2" />
    <g id="Calque_1">
      <g>
        <path
          fill={props.fill}
          d="M46.1,52.6v77.7c0,4.3,3.5,7.8,7.8,7.8h7.3v9.4c0,4.3,3.5,7.8,7.8,7.8h58.9c4.3,0,7.8-3.5,7.8-7.8V69.7
			c0-4.3-3.5-7.8-7.8-7.8h-7.3v-9.4c0-4.3-3.5-7.8-7.8-7.8H53.9C49.6,44.8,46.1,48.3,46.1,52.6z M52.8,52.6c0-0.6,0.5-1.1,1.1-1.1
			h58.9c0.6,0,1.1,0.5,1.1,1.1v9.4H69c-4.3,0-7.8,3.5-7.8,7.8v61.6h-7.3c-0.6,0-1.1-0.5-1.1-1.1V52.6z"
        />
        <path
          fill={props.fill}
          d="M76.1,82.5c0,1.8,1.5,3.3,3.3,3.3h38c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3h-38
			C77.6,79.2,76.1,80.7,76.1,82.5z"
        />
        <path
          fill={props.fill}
          d="M76.1,97.6c0,1.8,1.5,3.3,3.3,3.3h38c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3h-38
			C77.6,94.2,76.1,95.7,76.1,97.6z"
        />
        <path
          fill={props.fill}
          d="M76.1,112.6c0,1.8,1.5,3.3,3.3,3.3h38c1.8,0,3.3-1.5,3.3-3.3s-1.5-3.3-3.3-3.3h-38
			C77.6,109.3,76.1,110.8,76.1,112.6z"
        />
        <path
          fill={props.fill}
          d="M76.1,127.7c0,1.8,1.5,3.3,3.3,3.3h38c1.8,0,3.3-1.5,3.3-3.3s-1.5-3.3-3.3-3.3h-38
			C77.6,124.3,76.1,125.8,76.1,127.7z"
        />
      </g>
      <path fill={props.fill} d="M139.6,41.6c0-6.4-5.2-11.5-11.5-11.5h-23.3" />
      <polyline fill={props.fill} points="130.8,42.9 139.6,49.7 148.4,42.9 	" />
    </g>
  </svg>
);
export default Paste;
