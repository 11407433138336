const filtreConstants = {
  GET_REQUEST: 'FILTRE_GET_REQUEST',
  GET_SUCCESS: 'FILTRE_GET_SUCCESS',
  GET_FAILURE: 'FILTRE_GET_FAILURE',

  SAVE_REQUEST: 'FILTRE_SAVE_REQUEST',
  SAVE_SUCCESS: 'FILTRE_SAVE_SUCCESS',

  UPDATE_REQUEST: 'FILTRE_REQUEST',
  UPDATE_SUCCESS: 'FILTRE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'FILTRE_UPDATE_FAILURE',

  DELETE_REQUEST: 'FILTRE_DELETE_REQUEST',
  DELETE_SUCCESS: 'FILTRE_DELETE_SUCCESS',
  DELETE_FAILURE: 'FILTRE_DELETE_FAILURE',

  CLEAR: 'FILTRE_CLEAR',
};

export default filtreConstants;
