export default {
  DIOPTASE: 4,
  SUPERADMIN: 3,
  ADMIN: 2,
  USER: 1,
};

export const levels: any = [
  {
    label: 'DIOPTASE',
    value: 4,
  },
  {
    label: 'SUPERADMIN',
    value: 3,
  },
  {
    label: 'ADMIN',
    value: 2,
  },
  {
    label: 'USER',
    value: 1,
  },
];
