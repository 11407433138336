const userConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  GET_REQUEST: 'USERS_GET_REQUEST',
  GET_SUCCESS: 'USERS_GET_SUCCESS',
  GET_FAILURE: 'USERS_GET_FAILURE',

  GET_ADDITIONAL_INFO_REQUEST: 'GET_ADDITIONAL_INFO_REQUEST',
  GET_ADDITIONAL_INFO_SUCCESS: 'GET_ADDITIONAL_INFO_SUCCESS',
  GET_ADDITIONAL_INFO_FAILURE: 'GET_ADDITIONAL_INFO_FAILURE',

  SAVE_REQUEST: 'USERS_SAVE_REQUEST',
  SAVE_SUCCESS: 'USERS_SAVE_SUCCESS',
  SAVE_FAILURE: 'USERS_SAVE_FAILURE',

  SAVETOURMALINE_REQUEST: 'USERS_SAVETOURMALINE_REQUEST',
  SAVETOURMALINE_SUCCESS: 'USERS_SAVETOURMALINE_SUCCESS',
  SAVETOURMALINE_FAILURE: 'USERS_SAVETOURMALINE_FAILURE',

  UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

  UPDATE_URLHOMEPAGE_SUCCESS: 'USERS_UPDATE_URLHOMEPAGE_SUCCESS',
  UPDATE_URLHOMEPAGE_FAILURE: 'USERS_UPDATE_URLHOMEPAGE_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',
  CLEAR: 'CLEAR',

  TOKEN_SUCCESS: 'TOKEN_SUCCESS',
  TOKEN_FAILURE: 'TOKEN_FAILURE',
  TOKEN_REQUEST: 'TOKEN_FAILURE',

  BLOCK_REQUEST: 'USERS_BLOCK_REQUEST',
  BLOCK_SUCCESS: 'USERS_BLOCK_SUCCESS',
  BLOCK_FAILURE: 'USERS_BLOCK_FAILURE',

  CHECKPERMISSION_SUCCESS: 'USERS_CHECKPERMISSION_SUCCESS',
  CHECKPERMISSION_FAILURE: 'USERS_CHECKPERMISSION_FAILURE',
};

export default userConstants;
