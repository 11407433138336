import { authHeader, handleResponse } from '../_helpers';

async function getGeneralImportBillingSetting() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/support/facturation/keys/general`, requestOptions);
  return handleResponse(response);
}

async function getColumnImportBillingSetting() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/support/facturation/keys/column`, requestOptions);
  return handleResponse(response);
}

async function getDefaultImportBillingSetting() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/support/`, requestOptions); // TODO
  return handleResponse(response);
}

async function getAutoChangeImportBillingSetting() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/support/facturation/keys/autochange`, requestOptions);
  return handleResponse(response);
}

async function sendAllImportBillingSetting(allSetting: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(allSetting),
  };
  const response = await fetch(`/api/support/facturation`, requestOptions);
  return handleResponse(response);
}

async function sendGeneralImportBillingSetting(generalSetting: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(generalSetting),
  };
  const response = await fetch(`/api/support/facturation/general`, requestOptions);
  return handleResponse(response);
}

async function sendColumnImportBillingSetting(columnSetting: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(columnSetting),
  };
  const response = await fetch(`/api/support/facturation/column`, requestOptions);
  return handleResponse(response);
}

async function sendDefaultImportBillingSetting(defaultSettingKey: string) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };
  const response = await fetch(`/api/support/`, requestOptions); // TODO
  return handleResponse(response);
}

async function getCustomGeneralSetting() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/support/general`, requestOptions);
  return handleResponse(response);
}

async function sendCustomGeneralSetting(generalSetting: Object) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(generalSetting),
  };
  const response = await fetch(`/api/support/general`, requestOptions);
  return handleResponse(response);
}

async function getSyncSetting() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/support/synchronisation`, requestOptions);
  return handleResponse(response);
}

async function sendSyncSetting(generalSetting: Object) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(generalSetting),
  };
  const response = await fetch(`/api/support/synchronisation`, requestOptions);
  return handleResponse(response);
}

async function getExportSetting() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/support/export`, requestOptions);
  return handleResponse(response);
}
async function getEncodings() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/support/encodings`, requestOptions);
  return handleResponse(response);
}
async function getEncodingMethods() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/support/encoding/methods`, requestOptions);
  return handleResponse(response);
}

async function sendExportSetting(generalSetting: Object) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(generalSetting),
  };
  const response = await fetch(`/api/support/export`, requestOptions);
  return handleResponse(response);
}

async function sendBillingSetting(billingSetting: Object) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(billingSetting),
  };
  const response = await fetch(`/api/support/billing`, requestOptions);
  return handleResponse(response);
}

async function getSettingsReport() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/support/report`, requestOptions);
  return handleResponse(response);
}

async function sendSettingsReport(generalSetting: Object) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(generalSetting),
  };
  const response = await fetch(`/api/support/report`, requestOptions);
  return handleResponse(response);
}

async function sendColumnRemoteReadingSetting(remoteReadingSetting: Object) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(remoteReadingSetting),
  };
  const response = await fetch(`/api/support/telereleve/column`, requestOptions);
  return handleResponse(response);
}

async function sendGeneralRemoteReadingSetting(remoteReadingSetting: Object) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(remoteReadingSetting),
  };
  const response = await fetch(`/api/support/telereleve/general`, requestOptions);
  return handleResponse(response);
}

async function getGeneralRemoteReadingSetting() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/support/telereleve/keys/general`, requestOptions);
  return handleResponse(response);
}

async function getColumnRemoteReadingSetting() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/support/telereleve/keys/column`, requestOptions);
  return handleResponse(response);
}

async function sendAutoChangeConfiguration(autoChangeSettings: Object) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(autoChangeSettings),
  };
  const response = await fetch(`/api/support/facturation/autochange`, requestOptions);
  return handleResponse(response);
}

async function getLogs(appname: string, nbLines: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/support/log/${appname}/${nbLines}`, requestOptions);
  return handleResponse(response);
}

async function refreshAboPortal() {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };
  const response = await fetch(`/api/abonne/refresh`, requestOptions);
  return handleResponse(response);
}

const supportService = {
  getGeneralImportBillingSetting,
  getColumnImportBillingSetting,
  getDefaultImportBillingSetting,
  getAutoChangeImportBillingSetting,
  sendGeneralImportBillingSetting,
  sendColumnImportBillingSetting,
  sendDefaultImportBillingSetting,
  getCustomGeneralSetting,
  sendCustomGeneralSetting,
  getSyncSetting,
  sendSyncSetting,
  getExportSetting,
  getEncodings,
  getEncodingMethods,
  sendExportSetting,
  getGeneralRemoteReadingSetting,
  getColumnRemoteReadingSetting,
  sendAllImportBillingSetting,
  sendColumnRemoteReadingSetting,
  sendGeneralRemoteReadingSetting,
  getSettingsReport,
  sendSettingsReport,
  sendAutoChangeConfiguration,
  getLogs,
  refreshAboPortal,
  sendBillingSetting,
};

export default supportService;
