const synchroConstants = {
  GETALLROUND_REQUEST: 'ROUND_GETALL_REQUEST',
  GETALLROUND_SUCCESS: 'ROUND_GETALL_SUCCESS',
  GETALLROUND_FAILURE: 'ROUND_GETALL_FAILURE',

  GETALLROUND_FULL_REQUEST: 'ROUND_GETALL_FULL_REQUEST',
  GETALLROUND_FULL_SUCCESS: 'ROUND_GETALL_FULL_SUCCESS',
  GETALLROUND_FULL_FAILURE: 'ROUND_GETALL_FULL_FAILURE',

  GETALLDEVICES_REQUEST: 'DEVICE_GETALL_REQUEST',
  GETALLDEVICES_SUCCESS: 'DEVICE_GETALL_SUCCESS',
  GETALLDEVICES_FAILURE: 'DEVICE_GETALL_FAILURE',

  GETALLLINKS_REQUEST: 'LINKS_GETALL_REQUEST',
  GETALLLINKS_SUCCESS: 'LINKS_GETALL_SUCCESS',
  GETALLLINKS_FAILURE: 'LINKS_GETALL_FAILURE',

  COMMUNICATION_REQUEST: 'COMMUNICATION_REQUEST',
  COMMUNICATION_SUCCESS: 'COMMUNICATION_SUCCESS',
  COMMUNICATION_FAILURE: 'COMMUNICATION_FAILURE',

  UPDATENAME_REQUEST: 'UPDATENAME_REQUEST',
  UPDATENAME_SUCCESS: 'UPDATENAME_SUCCESS',
  UPDATENAME_FAILURE: 'UPDATENAME_FAILURE',

  DELETE_DEVICE_REQUEST: 'DELETE_DEVICE_REQUEST',
  DELETE_DEVICE_SUCCESS: 'DELETE_DEVICE_SUCCESS',
  DELETE_DEVICE_FAILURE: 'DELETE_DEVICE_FAILURE',

  GETALLMESSAGES_REQUEST: 'MESSAGES_GETALL_REQUEST',
  GETALLMESSAGES_SUCCESS: 'MESSAGES_GETALL_SUCCESS',
  GETALLMESSAGES_FAILURE: 'MESSAGES_GETALL_FAILURE',

  GETALLMARQUAGES_REQUEST: 'MARQUAGES_GETALL_REQUEST',
  GETALLMARQUAGES_SUCCESS: 'MARQUAGES_GETALL_SUCCESS',
  GETALLMARQUAGES_FAILURE: 'MARQUAGES_GETALL_FAILURE',

  SYNCHROROUND_REQUEST: 'ROUND_SYNCHRO_REQUEST',
  SYNCHROROUND_SUCCESS: 'ROUND_SYNCHRO_SUCCESS',
  SYNCHROROUND_FAILURE: 'ROUND_SYNCHRO_FAILURE',

  SYNCHRODEVICE_REQUEST: 'DEVICE_SYNCHRO_REQUEST',
  SYNCHRODEVICE_SUCCESS: 'DEVICE_SYNCHRO_SUCCESS',
  SYNCHRODEVICE_FAILURE: 'DEVICE_SYNCHRO_FAILURE',

  ADDAFFECT_REQUEST: 'ADD_AFFECT_REQUEST',
  ADDAFFECT_SUCCESS: 'ADD_AFFECT_SUCCESS',
  ADDAFFECT_FAILURE: 'ADD_AFFECT_FAILURE',

  DELAFFECT_REQUEST: 'DEL_AFFECT_REQUEST',
  DELAFFECT_SUCCESS: 'DEL_AFFECT_SUCCESS',
  DELAFFECT_FAILURE: 'DEL_AFFECT_FAILURE',

  ADDDESAFFECT_REQUEST: 'ADD_DESAFFECT_REQUEST',
  ADDDESAFFECT_SUCCESS: 'ADD_DESAFFECT_SUCCESS',
  ADDDESAFFECT_FAILURE: 'ADD_DESAFFECT_FAILURE',

  DELDESAFFECT_REQUEST: 'DEL_DESAFFECT_REQUEST',
  DELDESAFFECT_SUCCESS: 'DEL_DESAFFECT_SUCCESS',
  DELDESAFFECT_FAILURE: 'DEL_DESAFFECT_FAILURE',

  GETLABELS_REQUEST: 'GET_LABELS_REQUEST',
  GETLABELS_SUCCESS: 'GET_LABELS_SUCCESS',
  GETLABELS_FAILURE: 'GET_LABELS_FAILURE',

  GETCURRENTVERSION_REQUEST: 'GETCURRENT_VERSION_REQUEST',
  GETCURRENTVERSION_SUCCESS: 'GETCURRENT_VERSION_SUCCESS',
  GETCURRENTVERSION_FAILURE: 'GETCURRENT_VERSION_FAILURE',

  GETMINIVERSION_REQUEST: 'GETMINI_VERSION_REQUEST',
  GETMINIVERSION_SUCCESS: 'GETMINI_VERSION_SUCCESS',
  GETMINIVERSION_FAILURE: 'GETMINI_VERSION_FAILURE',

  GET_FULLAFFECT_REQUEST: 'GET_FULLAFFECT_REQUEST',
  GET_FULLAFFECT_SUCCESS: 'GET_FULLAFFECT_SUCCESS',
  GET_FULLAFFECT_FAILURE: 'GET_FULLAFFECT_FAILURE',

  CANCEL_ALL_REQUEST: 'CANCEL_ALL_REQUEST',
  CANCEL_ALL_SUCCESS: 'CANCEL_ALL_SUCCESS',
  CANCEL_ALL_FAILURE: 'CANCEL_ALL_FAILURE',

  COM_ALL_REQUEST: 'COM_ALL_REQUEST',
  COM_ALL_SUCCESS: 'COM_ALL_SUCCESS',
  COM_ALL_FAILURE: 'COM_ALL_FAILURE',

  COM_DEVICE_REQUEST: 'COM_DEVICE_REQUEST',
  COM_DEVICE_SUCCESS: 'COM_DEVICE_SUCCESS',
  COM_DEVICE_FAILURE: 'COM_DEVICE_FAILURE',

  CANCEL_DEVICE_REQUEST: 'CANCEL_DEVICE_REQUEST',
  CANCEL_DEVICE_SUCCESS: 'CANCEL_DEVICE_SUCCESS',
  CANCEL_DEVICE_FAILURE: 'CANCEL_DEVICE_FAILURE',

  COM_ROUND_REQUEST: 'COM_ROUND_REQUEST',
  COM_ROUND_SUCCESS: 'COM_ROUND_SUCCESS',
  COM_ROUND_FAILURE: 'COM_ROUND_FAILURE',

  CANCEL_ROUND_REQUEST: 'CANCEL_ROUND_REQUEST',
  CANCEL_ROUND_SUCCESS: 'CANCEL_ROUND_SUCCESS',
  CANCEL_ROUND_FAILURE: 'CANCEL_ROUND_FAILURE',

  SWITCH_PARTIAL_REQUEST: 'SWITCH_PARTIAL_REQUEST',
  SWITCH_PARTIAL_SUCCESS: 'SWITCH_PARTIAL_SUCCESS',
  SWITCH_PARTIAL_FAILURE: 'SWITCH_PARTIAL_FAILURE',

  GET_DEVICE_REQUEST: 'GET_DEVICE_REQUEST',
  GET_DEVICE_SUCCESS: 'GET_DEVICE_SUCCESS',
  GET_DEVICE_FAILURE: 'GET_DEVICE_FAILURE',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  UPDATE_UNDROP_REQUEST: 'UPDATE_UNDROP_REQUEST',
  UPDATE_UNDROP_SUCCESS: 'UPDATE_UNDROP_SUCCESS',

  ADD_MESSAGES_REQUEST: 'ADD_MESSAGES_REQUEST',
  ADD_MESSAGES_SUCCESS: 'ADD_MESSAGES_SUCCESS',
  ADD_MESSAGES_FAILURE: 'ADD_MESSAGES_FAILURE',

  ADD_MARQUAGES_REQUEST: 'ADD_MARQUAGES_REQUEST',
  ADD_MARQUAGES_SUCCESS: 'ADD_MARQUAGES_SUCCESS',
  ADD_MARQUAGES_FAILURE: 'ADD_MARQUAGES_FAILURE',

  ROTATE_ROUND_REQUEST: 'ROTATE_ROUND_REQUEST',
  ROTATE_ROUND_SUCCESS: 'ROTATE_ROUND_SUCCESS',
  ROTATE_ROUND_FAILURE: 'ROTATE_ROUND_FAILURE',

  DISAFFECT_ALL_REQUEST: 'DISAFFECT_ALL_REQUEST',
  DISAFFECT_ALL_SUCCESS: 'DISAFFECT_ALL_SUCCESS',
  DISAFFECT_ALL_FAILURE: 'DISAFFECT_ALL_FAILURE',

  DISAFFECT_ALL_FOR_ROUND_REQUEST: 'DISAFFECT_ALL_FOR_ROUND_REQUEST',
  DISAFFECT_ALL_FOR_ROUND_SUCCESS: 'DISAFFECT_ALL_FOR_ROUND_SUCCESS',
  DISAFFECT_ALL_FOR_ROUND_FAILURE: 'DISAFFECT_ALL_FOR_ROUND_FAILURE',

  GET_DEVICES_ERROR_REQUEST: 'DEVICES_ERROR_REQUEST',
  GET_DEVICES_ERROR_SUCCESS: 'DEVICES_ERROR_SUCCESS',
  GET_DEVICES_ERROR_FAILURE: 'DEVICES_ERROR_FAILURE',

  REMOVE_DEVICES_ERROR_REQUEST: 'REMOVE_DEVICES_ERROR_REQUEST',
  REMOVE_DEVICES_ERROR_SUCCESS: 'REMOVE_DEVICES_ERROR_SUCCESS',
  REMOVE_DEVICES_ERROR_FAILURE: 'REMOVE_DEVICES_ERROR_FAILURE',

  DATA_ERROR: 'DATA_ERROR',

  CLEAR: 'SYNCHRO_CLEAR',
};

export default synchroConstants;
