import { supportConstants } from '../_constants';
import supportService from '../_services/support.service';

function getGeneralImportBillingSetting() {
  function request() {
    return { type: supportConstants.GET_GENERAL_IMPORT_BILLING_SETTING_REQUEST };
  }
  function success(generalSetting: Array<string>) {
    return { type: supportConstants.GET_GENERAL_IMPORT_BILLING_SETTING_SUCCESS, generalSetting };
  }
  function failure(error: Object) {
    return { type: supportConstants.GET_GENERAL_IMPORT_BILLING_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .getGeneralImportBillingSetting()
      .then((generalSetting: Array<string>) => {
        dispatch(success(generalSetting));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getColumnImportBillingSetting() {
  function request() {
    return { type: supportConstants.GET_COLUMN_IMPORT_BILLING_SETTING_REQUEST };
  }
  function success(columnSetting: any) {
    return { type: supportConstants.GET_COLUMN_IMPORT_BILLING_SETTING_SUCCESS, columnSetting };
  }
  function failure(error: Object) {
    return { type: supportConstants.GET_COLUMN_IMPORT_BILLING_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .getColumnImportBillingSetting()
      .then((columnSetting: any) => {
        dispatch(success(columnSetting));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getDefaultImportBillingSetting() {
  function request() {
    return { type: supportConstants.GET_DEFAULT_IMPORT_BILLING_SETTING_REQUEST };
  }
  function success(defaultSetting: Array<string>) {
    return { type: supportConstants.GET_DEFAULT_IMPORT_BILLING_SETTING_SUCCESS, defaultSetting };
  }
  function failure(error: Object) {
    return { type: supportConstants.GET_DEFAULT_IMPORT_BILLING_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .getDefaultImportBillingSetting()
      .then((defaultSetting: Array<string>) => {
        dispatch(success(defaultSetting));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getAutoChangeImportBillingSetting() {
  function request() {
    return { type: supportConstants.GET_AUTOCHANGE_IMPORT_BILLING_SETTING_REQUEST };
  }
  function success(autoChangeSetting: Array<string>) {
    return { type: supportConstants.GET_AUTOCHANGE_IMPORT_BILLING_SETTING_SUCCESS, autoChangeSetting };
  }
  function failure(error: Object) {
    return { type: supportConstants.GET_AUTOCHANGE_IMPORT_BILLING_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .getAutoChangeImportBillingSetting()
      .then((autoChangeSetting: Array<string>) => {
        dispatch(success(autoChangeSetting));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function sendAllImportBillingSetting(allSetting: any) {
  function request() {
    return { type: supportConstants.SEND_ALL_IMPORT_BILLING_SETTING_REQUEST };
  }
  function success() {
    return { type: supportConstants.SEND_ALL_IMPORT_BILLING_SETTING_SUCCESS };
  }
  function failure(error: Object) {
    return { type: supportConstants.SEND_ALL_IMPORT_BILLING_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .sendAllImportBillingSetting(allSetting)
      .then(() => {
        dispatch(success());
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function sendGeneralImportBillingSetting(generalSetting: any) {
  function request() {
    return { type: supportConstants.SEND_GENERAL_IMPORT_BILLING_SETTING_REQUEST };
  }
  function success() {
    return { type: supportConstants.SEND_GENERAL_IMPORT_BILLING_SETTING_SUCCESS };
  }
  function failure(error: Object) {
    return { type: supportConstants.SEND_GENERAL_IMPORT_BILLING_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .sendGeneralImportBillingSetting(generalSetting)
      .then(() => {
        dispatch(success());
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function sendColumnImportBillingSetting(columnSetting: any) {
  function request() {
    return { type: supportConstants.SEND_COLUMN_IMPORT_BILLING_SETTING_REQUEST };
  }
  function success() {
    return { type: supportConstants.SEND_COLUMN_IMPORT_BILLING_SETTING_SUCCESS };
  }
  function failure(error: Object) {
    return { type: supportConstants.SEND_COLUMN_IMPORT_BILLING_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .sendColumnImportBillingSetting(columnSetting)
      .then(() => {
        dispatch(success());
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function sendDefaultImportBillingSetting(defaultSettingKey: string) {
  function request() {
    return { type: supportConstants.SEND_DEFAULT_IMPORT_BILLING_SETTING_REQUEST };
  }
  function success() {
    return { type: supportConstants.SEND_DEFAULT_IMPORT_BILLING_SETTING_SUCCESS };
  }
  function failure(error: Object) {
    return { type: supportConstants.SEND_DEFAULT_IMPORT_BILLING_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .sendDefaultImportBillingSetting(defaultSettingKey)
      .then(() => {
        dispatch(success());
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getCustomGeneralSetting() {
  function request() {
    return { type: supportConstants.GET_CUSTOM_GENERAL_SETTING_REQUEST };
  }
  function success(setting: any) {
    return { type: supportConstants.GET_CUSTOM_GENERAL_SETTING_SUCCESS, setting };
  }
  function failure(error: Object) {
    return { type: supportConstants.GET_CUSTOM_GENERAL_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .getCustomGeneralSetting()
      .then((setting: any) => {
        dispatch(success(setting));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function sendCustomGeneralSetting(generalSetting: Object) {
  function request() {
    return { type: supportConstants.SEND_CUSTOM_GENERAL_SETTING_REQUEST };
  }
  function success(setting: any) {
    return { type: supportConstants.SEND_CUSTOM_GENERAL_SETTING_SUCCESS, setting };
  }
  function failure(error: Object) {
    return { type: supportConstants.SEND_CUSTOM_GENERAL_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .sendCustomGeneralSetting(generalSetting)
      .then((setting: any) => {
        dispatch(success(setting));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getCustomReportSetting() {
  function request() {
    return { type: supportConstants.GET_CUSTOM_GENERAL_SETTING_REQUEST };
  }
  function success(setting: any) {
    return { type: supportConstants.GET_CUSTOM_REPORT_SETTING_SUCCESS, setting };
  }
  function failure(error: Object) {
    return { type: supportConstants.GET_CUSTOM_GENERAL_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .getSettingsReport()
      .then((setting: any) => {
        dispatch(success(setting));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function sendCustomReportSetting(generalSetting: Object) {
  function request() {
    return { type: supportConstants.SEND_CUSTOM_GENERAL_SETTING_REQUEST };
  }
  function success(setting: any) {
    return { type: supportConstants.SEND_CUSTOM_REPORT_SETTING_SUCCESS, setting };
  }
  function failure(error: Object) {
    return { type: supportConstants.SEND_CUSTOM_GENERAL_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .sendSettingsReport(generalSetting)
      .then((setting: any) => {
        dispatch(success(setting));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getSyncSetting() {
  function request() {
    return { type: supportConstants.GET_SYNC_SETTING_REQUEST };
  }
  function success(syncSetting: any) {
    return { type: supportConstants.GET_SYNC_SETTING_SUCCESS, syncSetting };
  }
  function failure(error: Object) {
    return { type: supportConstants.GET_SYNC_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .getSyncSetting()
      .then((setting: any) => {
        dispatch(success(setting));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function sendSyncSetting(syncSettingParams: Object) {
  function request() {
    return { type: supportConstants.SEND_SYNC_SETTING_REQUEST };
  }
  function success(syncSetting: any) {
    return { type: supportConstants.SEND_SYNC_SETTING_SUCCESS, syncSetting };
  }
  function failure(error: Object) {
    return { type: supportConstants.SEND_SYNC_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .sendSyncSetting(syncSettingParams)
      .then((settings: any) => {
        dispatch(success(settings));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getExportSetting() {
  function request() {
    return { type: supportConstants.GET_EXPORT_SETTING_REQUEST };
  }
  function success(exportSetting: any) {
    return { type: supportConstants.GET_EXPORT_SETTING_SUCCESS, exportSetting };
  }
  function failure(error: Object) {
    return { type: supportConstants.GET_EXPORT_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .getExportSetting()
      .then((setting: any) => {
        dispatch(success(setting));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}
function getEncodings() {
  function request() {
    return { type: supportConstants.GET_SILEX_ENCODINGS_REQUEST };
  }
  function success(encodings: any) {
    return { type: supportConstants.GET_SILEX_ENCODINGS_SUCCESS, encodings };
  }
  function failure(error: Object) {
    return { type: supportConstants.GET_SILEX_ENCODINGS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .getEncodings()
      .then((encodings: any) => {
        dispatch(success(encodings));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}
function getEncodingDetectionMethods() {
  function request() {
    return { type: supportConstants.GET_SILEX_ENCODING_METHODS_REQUEST };
  }
  function success(encodingMethods: any) {
    return { type: supportConstants.GET_SILEX_ENCODING_METHODS_SUCCESS, encodingMethods };
  }
  function failure(error: Object) {
    return { type: supportConstants.GET_SILEX_ENCODING_METHODS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .getEncodingMethods()
      .then((encodingsMth: any) => {
        dispatch(success(encodingsMth));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}
function sendExportSetting(syncSettingParams: Object) {
  function request() {
    return { type: supportConstants.SEND_EXPORT_SETTING_REQUEST };
  }
  function success(exportSetting: any) {
    return { type: supportConstants.SEND_EXPORT_SETTING_SUCCESS, exportSetting };
  }
  function failure(error: Object) {
    return { type: supportConstants.SEND_EXPORT_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .sendExportSetting(syncSettingParams)
      .then((settings: any) => {
        dispatch(success(settings));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function sendBillingSetting(billingSettingParams: Object) {
  function request() {
    return { type: supportConstants.SEND_BILLING_SETTING_REQUEST };
  }
  function success(autoChangeSetting: any) {
    return { type: supportConstants.SEND_BILLING_SETTING_SUCCESS, autoChangeSetting };
  }
  function failure(error: Object) {
    return { type: supportConstants.SEND_BILLING_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .sendBillingSetting(billingSettingParams)
      .then((settings: any) => {
        dispatch(success(settings));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function sendRemoteReadingSetting(generalRemoteReadingSettingParams: Object, columnRemoteReadingSetting: Object) {
  function request() {
    return { type: supportConstants.SEND_REMOTE_READING_SETTING_REQUEST };
  }
  function success(generalRemoteReadingSetting: any) {
    return { type: supportConstants.SEND_REMOTE_READING_SETTING_SUCCESS, generalRemoteReadingSetting };
  }
  function failure(error: Object) {
    return { type: supportConstants.SEND_REMOTE_READING_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .sendGeneralRemoteReadingSetting(generalRemoteReadingSettingParams)
      .then(generalRemoteReadingSetting => {
        dispatch(success(generalRemoteReadingSetting));
        dispatch(sendColumnRemoteReadingSetting(columnRemoteReadingSetting));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getGeneralRemoteReadingSetting() {
  function request() {
    return { type: supportConstants.GET_GENERAL_REMOTE_READING_SETTING_REQUEST };
  }
  function success(generalRemoteReadingSetting: Array<string>) {
    return { type: supportConstants.GET_GENERAL_REMOTE_READING_SETTING_SUCCESS, generalRemoteReadingSetting };
  }
  function failure(error: Object) {
    return { type: supportConstants.GET_GENERAL_REMOTE_READING_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .getGeneralRemoteReadingSetting()
      .then((generalSetting: Array<string>) => {
        dispatch(success(generalSetting));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getColumnRemoteReadingSetting() {
  function request() {
    return { type: supportConstants.GET_COLUMN_REMOTE_READING_SETTING_REQUEST };
  }
  function success(columnRemoteReadingSetting: any) {
    return { type: supportConstants.GET_COLUMN_REMOTE_READING_SETTING_SUCCESS, columnRemoteReadingSetting };
  }
  function failure(error: Object) {
    return { type: supportConstants.GET_COLUMN_REMOTE_READING_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .getColumnRemoteReadingSetting()
      .then((columnSetting: any) => {
        dispatch(success(columnSetting));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function sendColumnRemoteReadingSetting(columnSetting: any) {
  function request() {
    return { type: supportConstants.SEND_COLUMN_REMOTE_READING_SETTING_REQUEST };
  }
  function success(columnRemoteReadingSetting: any) {
    return { type: supportConstants.SEND_COLUMN_REMOTE_READING_SETTING_SUCCESS, columnRemoteReadingSetting };
  }
  function failure(error: Object) {
    return { type: supportConstants.SEND_COLUMN_REMOTE_READING_SETTING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .sendColumnRemoteReadingSetting(columnSetting)
      .then((columnRemoteReadingSetting: any) => {
        dispatch(success(columnRemoteReadingSetting));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getLogs(appname: string, nbLines: number) {
  function request() {
    return { type: supportConstants.GET_LOGS_REQUEST };
  }
  function success(logs: any) {
    return { type: supportConstants.GET_LOGS_SUCCESS, logs };
  }
  function failure(error: Object) {
    return { type: supportConstants.GET_LOGS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .getLogs(appname, nbLines)
      .then((logs: any) => {
        dispatch(success(logs));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function refreshAboPortal() {
  function request() {
    return { type: supportConstants.REFRESH_ABO_PORTAL_REQUEST };
  }
  function success() {
    return { type: supportConstants.REFRESH_ABO_PORTAL_SUCCESS };
  }
  function failure() {
    return { type: supportConstants.REFRESH_ABO_PORTAL_FAILURE };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .refreshAboPortal()
      .then(() => {
        dispatch(success());
      })
      .catch(() => {
        dispatch(failure());
      });
  };
}

function sendAutoChangeconfiguration(autoChangeSettings: Object) {
  function request() {
    return { type: supportConstants.AUTOCHANGE_CONFIG_REQUEST };
  }
  function success(autoChangeSetting: Array<string>) {
    return { type: supportConstants.AUTOCHANGE_CONFIG_SUCCESS, autoChangeSetting };
  }
  function failure(error: Object) {
    return { type: supportConstants.AUTOCHANGE_CONFIG_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    supportService
      .sendAutoChangeConfiguration(autoChangeSettings)
      .then((autoChangeSetting: Array<string>) => {
        dispatch(success(autoChangeSetting));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function clear() {
  return (dispatch: Function) => {
    dispatch({ type: supportConstants.CLEAR });
  };
}

const supportActions = {
  getGeneralImportBillingSetting,
  getColumnImportBillingSetting,
  getDefaultImportBillingSetting,
  getAutoChangeImportBillingSetting,
  sendGeneralImportBillingSetting,
  sendColumnImportBillingSetting,
  sendDefaultImportBillingSetting,
  getCustomGeneralSetting,
  sendCustomGeneralSetting,
  getSyncSetting,
  sendSyncSetting,
  getExportSetting,
  getEncodings,
  sendExportSetting,
  sendRemoteReadingSetting,
  getGeneralRemoteReadingSetting,
  getColumnRemoteReadingSetting,
  sendColumnRemoteReadingSetting,
  getEncodingDetectionMethods,
  sendAllImportBillingSetting,
  getCustomReportSetting,
  sendCustomReportSetting,
  sendAutoChangeconfiguration,
  clear,
  getLogs,
  refreshAboPortal,
  sendBillingSetting,
};

export default supportActions;
