import React from 'react';

const SvgTeleReleve = (props: any) => (
  <svg viewBox="0 0 200 200" {...props}>
    <g id="Calque_2" />
    <g id="Calque_1">
      <g>
        <path
          fill={props.fill}
          d="M155.3,81.1c-2.4-9-6.7-17.1-12.5-23.7c-1.3-1.5-3.2-2.3-5.1-2.3c-1.6,0-3.1,0.5-4.4,1.6    c-2.8,2.4-3.1,6.7-0.7,9.5c4.4,5.1,7.8,11.3,9.6,18.3c0.7,2.7,1.2,5.4,1.4,8.1c1,13-3.7,25.6-12.3,34.9c-2.5,2.7-2.4,7,0.3,9.5    c1.3,1.2,3,1.8,4.6,1.8c0.2,0,0.3,0,0.5,0c1.6-0.1,3.2-0.8,4.4-2.1c11.2-11.9,17.2-28.2,15.9-45.1    C156.8,88.1,156.3,84.6,155.3,81.1z"
        />
        <path
          fill={props.fill}
          d="M135.3,93.3c-0.2-2.2-0.5-4.4-1.1-6.6c-1.4-5.5-4-10.4-7.4-14.4c-1.3-1.6-3.2-2.4-5.1-2.4    c-1.5,0-3,0.5-4.3,1.6c-2.8,2.4-3.2,6.6-0.9,9.4c2.2,2.6,3.8,5.7,4.7,9.2c0.4,1.4,0.6,2.9,0.7,4.2c0.5,6-1.4,11.8-4.9,16.4    c-2.3,2.9-1.7,7.2,1.2,9.4c1.2,0.9,2.6,1.4,4.1,1.4c0.2,0,0.4,0,0.5,0c1.8-0.1,3.6-1,4.8-2.6C133.1,111.8,136,102.7,135.3,93.3z"
        />
        <path
          fill={props.fill}
          d="M112.7,95c0,0,0-0.1,0-0.1c-0.4-4.8-3.4-8.7-7.5-10.5c-1.6-0.7-3.3-1.1-5.2-1.1c-0.3,0-0.7,0-1,0    c-6.6,0.5-11.6,5.9-11.7,12.4c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c0.4,4.6,3.2,8.5,7.2,10.4v34.7c0,2.6,2.2,4.8,4.8,4.8h1.4    c2.6,0,4.8-2.2,4.8-4.8v-34.7c4.2-2,7.1-6.3,7.2-11.1C112.7,95.8,112.7,95.4,112.7,95z"
        />
        <path
          fill={props.fill}
          d="M57.6,107.4c-0.7-2.7-1.2-5.4-1.4-8.1c-1-13,3.7-25.6,12.3-34.9c2.5-2.7,2.4-7-0.3-9.5    c-1.3-1.2-3-1.8-4.6-1.8c-0.2,0-0.3,0-0.5,0c-1.6,0.1-3.2,0.8-4.4,2.1c-11.2,11.9-17.2,28.2-15.9,45.1c0.3,3.5,0.9,7,1.8,10.5    c2.4,9,6.7,17.1,12.5,23.7c1.3,1.5,3.2,2.3,5.1,2.3c1.6,0,3.1-0.5,4.4-1.6c2.8-2.4,3.1-6.7,0.7-9.5    C62.8,120.6,59.5,114.4,57.6,107.4z"
        />
        <path
          fill={props.fill}
          d="M78.8,101.9c-0.4-1.4-0.6-2.9-0.7-4.2c-0.5-6,1.4-11.8,4.9-16.4c2.3-2.9,1.7-7.2-1.2-9.4    c-1.2-0.9-2.6-1.4-4.1-1.4c-0.2,0-0.4,0-0.5,0c-1.8,0.1-3.6,1-4.8,2.6c-5.5,7.1-8.4,16.2-7.7,25.6c0.2,2.2,0.5,4.4,1.1,6.6    c1.4,5.5,4,10.4,7.4,14.4c1.3,1.6,3.2,2.4,5.1,2.4c1.5,0,3-0.5,4.3-1.6c2.8-2.4,3.2-6.6,0.9-9.4C81.4,108.5,79.8,105.4,78.8,101.9    z"
        />
      </g>
    </g>
  </svg>
);

export default SvgTeleReleve;
