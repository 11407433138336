import React from 'react';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getActiveClassByPath } from '../_helpers';
import { User } from '../_entities/user';
import SvgTeleReleve from '../SvgComponents/LogoTeleReleve';
import SvgImportBleu from '../SvgComponents/ImportBleu';
import { withTranslation } from 'react-i18next';
import SvgRadioVert from '../SvgComponents/RadioVert';

interface Props {
  user: User;
  locations: any;
  location: any;
}

interface State {
  isOpen: boolean;
}

class SidebarTeleComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  isAdmin = () => {
    const { user } = this.props;

    if (!user) {
      return false;
    }
    if (user.role && user.role.name === 'DIOPTASE') {
      return true;
    }
    return !!(user.role && user.role.name.includes('ADMIN'));
  };

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  render() {
    const { isOpen } = this.state;
    const { locations, location, t } = this.props;
    return (
      <div className="sidebar-location ">
        <Navbar className="sidebar-location-wrapper tele-releve" dark color="dark" expand="md">
          <div>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav navbar>
                <NavbarBrand
                  id="nameOfLocation"
                  href="/telereleve"
                  className="center"
                  style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                >
                  {t('telereleve.text.telereleve')}
                </NavbarBrand>
                <NavItem>
                  <NavLink
                    className={getActiveClassByPath(location.pathname, 'teleRessource')}
                    href="/telereleve"
                    style={{ marginLeft: '-5px' }}
                  >
                    <SvgTeleReleve fill="currentcolor" stroke="currentcolor" height="2em" />{' '}
                    <span style={{ marginLeft: '-5px' }}>{t('telereleve.text.telereleve')}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={getActiveClassByPath(location.pathname, 'gateways')} href="/telereleve/gateways">
                    <SvgRadioVert fill="currentcolor" stroke="currentcolor" height="1.5em" />{' '}
                    <span style={{ marginLeft: '1px' }}>{t('gateway.text.gateway')}</span>
                  </NavLink>
                </NavItem>
                {this.isAdmin() && (
                  <NavItem>
                    <NavLink className={getActiveClassByPath(location.pathname, 'import')} href="/telereleve/import">
                      <SvgImportBleu fill="currentcolor" stroke="currentcolor" height="1em" width="1em" />{' '}
                      <span style={{ marginLeft: '3px' }}>{t('loraimport.text.import_lora')}</span>
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, dashboards, locations } = state;
  const { user } = authentication;
  return {
    user,
    dashboards,
    locations,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(SidebarTeleComponent)));
