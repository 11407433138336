import React from 'react';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';
import { connect } from 'react-redux';
import { FaHome } from 'react-icons/fa';
import PointCollecteVert from '../SvgComponents/PointCollecteVert';
import MesInformationsBlanc from '../SvgComponents/MesInformationsBlanc';
import LoupeBleu from '../SvgComponents/LoupeBleu';
import GestionUtilisateurBlanc from '../SvgComponents/GestionUtilisateurBlanc';
import { User } from '../_entities/user';
import { HelpModal } from '.';
import SvgImportBleu from '../SvgComponents/ImportBleu';
import SvgTourneesVertes from '../SvgComponents/TourneesVertes';
import SvgCleVerte from '../SvgComponents/CleVert';
import { withTranslation } from 'react-i18next';

interface Props {
  user: User;
}
interface State {
  isOpen: boolean;
}

/**
 * @class SidebarMiniComponent
 * @extends Component
 */
class SidebarMiniComponent extends React.Component<Props, State> {
  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  /**
   * @method toggle
   */
  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  /**
   * Gère le droit d'édition d'utilisateurs
   *
   * @method couldManageUser
   * @returns {Boolean} Le droit
   */
  isAdmin = () => {
    const { user } = this.props;
    return user && ['DIOPTASE', 'SUPERADMIN', 'ADMIN'].includes(user.role.name);
  };

  isDioptase = () => {
    const { user } = this.props;
    return user && _.get(user, 'role.name') === 'DIOPTASE';
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { isOpen } = this.state;
    const { user, t } = this.props;

    return (
      <div className="sidebar-mini">
        <Navbar className="sidebar-wrapper-mini" color="green" expand="md">
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar>
              <NavbarBrand href="/" className="center">
                <FaHome />
              </NavbarBrand>
              <NavItem>
                <NavLink href="/locations?root=STOCK" id="nav-mini-locations">
                  <PointCollecteVert
                    height="1em"
                    width="1em"
                    stroke="currentcolor"
                    fill="currentcolor"
                    strokeWidth="0"
                  />
                  <UncontrolledTooltip placement="right" target="nav-mini-locations">
                    {t('sidebar.nav_link.location_plural')}
                  </UncontrolledTooltip>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/locations?root=TOURNEES" id="nav-mini-tournees">
                  <SvgTourneesVertes
                    height="1em"
                    width="1em"
                    stroke="currentcolor"
                    fill="currentcolor"
                    strokeWidth="0"
                  />
                  <UncontrolledTooltip placement="right" target="nav-mini-tournees">
                    {t('sidebar.nav_link.round_plural')}
                  </UncontrolledTooltip>
                </NavLink>
              </NavItem>
              {this.isAdmin() && (
                <NavItem>
                  <NavLink href="/profils" id="nav-mini-users">
                    <SvgCleVerte
                      height="1.2em"
                      width="1.2em"
                      stroke="currentcolor"
                      fill="currentcolor"
                      strokeWidth="0"
                    />
                    <UncontrolledTooltip placement="right" target="nav-mini-users">
                      {' '}
                      Gestion des profils
                    </UncontrolledTooltip>
                  </NavLink>
                </NavItem>
              )}
              {this.isAdmin() && (
                <NavItem>
                  <NavLink href="/users/" id="nav-mini-users">
                    <MesInformationsBlanc
                      height="1.8em"
                      width="1.8em"
                      stroke="currentcolor"
                      fill="currentcolor"
                      strokeWidth="0"
                    />{' '}
                    <UncontrolledTooltip placement="right" target="nav-mini-users">
                      {' '}
                      Gestion des utilisateurs
                    </UncontrolledTooltip>
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink href={`/users/${user.id}`} id="nav-mini-myself">
                  <GestionUtilisateurBlanc
                    height="1em"
                    width="1em"
                    stroke="currentcolor"
                    fill="currentcolor"
                    strokeWidth="0"
                  />
                  <UncontrolledTooltip placement="right" target="nav-mini-myself">
                    {t('sidebar.nav_link.my_info_plural')}
                  </UncontrolledTooltip>
                </NavLink>
              </NavItem>
              {this.isAdmin() && (
                <NavItem>
                  <NavLink href="/import" id="nav-mini-import-factu">
                    <SvgImportBleu height="1em" width="1em" stroke="currentcolor" fill="currentcolor" strokeWidth="0" />{' '}
                    <UncontrolledTooltip placement="right" target="nav-mini-import-factu">
                      {t('sidebar.nav_link.import_billing')}
                    </UncontrolledTooltip>
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink href="/search" id="nav-mini-search">
                  <LoupeBleu height="1em" width="1em" stroke="currentcolor" fill="currentcolor" strokeWidth="0" />
                  <UncontrolledTooltip placement="right" target="nav-mini-search">
                    {t('sidebar.nav_link.global_search')}
                  </UncontrolledTooltip>
                </NavLink>
              </NavItem>
              <HelpModal buttonSize="md" mini={true} />
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user,
  };
}

const connectedSidebarMini = connect(mapStateToProps)(SidebarMiniComponent);
const tr = withTranslation()(connectedSidebarMini);
export default tr;
