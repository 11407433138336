import React from 'react';

const SvgPointCollecteVert = (props: any) => (
  <svg viewBox="0 0 19.04 28.98" {...props}>
    <path
      d="M9.4 28.9a.2.2 0 0 0 .32 0s.8-1.21 1.89-3l.77-1.27C15.14 20 19 12.88 19 9.5a9.52 9.52 0 0 0-19 0c0 3 3.27 9.27 6.13 14.13 0 .04 2.74 4.48 3.27 5.27zM4.73 9.53a4.79 4.79 0 1 1 4.8 4.78 4.79 4.79 0 0 1-4.8-4.78z"
      fill={props.fill}
      data-name="Calque 2"
    />
  </svg>
);

export default SvgPointCollecteVert;
