import { userConstants } from '../_constants';
import { userService } from '../_services';
import alertActions from './alert.actions';
import { history } from '../_helpers';
import { User } from '../_entities/user';
import i18n from 'i18next';

//TODO refacto les actions car c'est moche

/**
 * Authentifie l'utilisateur sur la plateforme
 *
 * @method login
 * @param username Username
 * @param password Mot de passe
 * @returns {Object} L'état de la connexion
 */
function login(username: string, password: string, device?: boolean) {
  function request(user: Object) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user: Object) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error: Object) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request({ username }));
    userService.login(username, password).then(
      user => {
        dispatch(success(user));
        localStorage.setItem('homepage_url', user.homepageUrl || '/');
        localStorage.setItem('language', user.language || 'fr');
        const lng = localStorage.getItem('language');
        i18n.changeLanguage(lng, () =>
          device ? history.push('/saphir/synchro') : history.push(localStorage.getItem('homepage_url') || '/')
        );
        // Function to clear complete cache data
        caches &&
          caches.keys().then(names => {
            names.forEach(name => {
              caches.delete(name);
            });
          });
        console.log('Complete Cache Cleared');
      },
      error => {
        dispatch(alertActions.error('Non autorisé'));
        dispatch(failure(error));
      }
    );
  };
}

/**
 * Déconnecte l'utilisateur courant
 *
 * @method logout
 * @returns {Object} L'état de déconnexion
 */
function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function getAll() {
  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users: Array<User>) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error: Object) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    userService
      .getAll()
      .then((users: Array<User>) => dispatch(success(users)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure("Impossible d'obtenir la liste des utilisateurs"));
      });
  };
}

/**
 * Récupère l'utilisateur dont l'id est passé en paramètre
 *
 * @method get
 * @param {number} id Id utilisateur
 * @returns {Object} L'utilisateur
 */
function get(id: number) {
  function request() {
    return { type: userConstants.GET_REQUEST };
  }
  function success(fetchedUser: any) {
    return { type: userConstants.GET_SUCCESS, fetchedUser };
  }
  function failure(error: Object) {
    return { type: userConstants.GET_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    userService
      .get(id)
      .then((fetchedUser: any) => dispatch(success(fetchedUser)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure('Impossible de remonter les informations utilisateurs'));
      });
  };
}

/**
 * Sauvegarde l'utilisateur passé en paramètre
 *
 * @method save
 * @param {any} user Utilisateur
 * @param {Array<any>} users Tous les utilisateurs
 * @returns {Object} L'état de la sauvegarde
 */
function save(user: any, users: Array<User>) {
  function success(savedUser: Object) {
    return { type: userConstants.SAVE_SUCCESS, savedUser };
  }

  return (dispatch: Function) => {
    userService
      .save(user)
      .then((savedUser: any) => {
        dispatch(success(savedUser));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(alertActions.error(error));
      });
  };
}

function saveTourmaline(user: any, users: Array<User>) {
  function success(savedTourmalineUser: Object) {
    return { type: userConstants.SAVETOURMALINE_SUCCESS, savedTourmalineUser };
  }

  return (dispatch: Function) => {
    userService
      .saveTourmaline(user)
      .then((savedUser: any) => {
        dispatch(success(savedUser));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(alertActions.error(error));
      });
  };
}

/**
 * Met à jour l'utilisateur passé en paramètre
 *
 * @method update
 * @param original Infos utilisateur d'origine
 * @param {any} user Nouvelles infos utilisateur
 * @param {any} permissions Permissions
 * @param {any} lastUpdatedInfo Dernière info mise à jour
 * @returns {Object} L'état de la mise à jour
 */
function update(original: User, user: any) {
  function success(updatedUser: any) {
    return { type: userConstants.UPDATE_SUCCESS, original, updatedUser, updated: true };
  }

  function failure(error: Object) {
    return { type: userConstants.UPDATE_FAILURE, original, user };
  }

  return (dispatch: Function) => {
    userService
      .update(user)
      .then((updatedUser: any) => dispatch(success(updatedUser)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
        dispatch(alertActions.error("Problème rencontré lors de la modification de l'utilisateur"));
      });
  };
}

function changeHomepage(userId: number, urlHomepage: string) {
  function success() {
    return { type: userConstants.UPDATE_URLHOMEPAGE_SUCCESS, urlHomepage };
  }

  function failure() {
    return { type: userConstants.UPDATE_URLHOMEPAGE_FAILURE, urlHomepage };
  }

  return (dispatch: Function) => {
    userService
      .changeHomepage(userId, urlHomepage)
      .then((_: any) => dispatch(success()))
      .catch((_: any) => {
        dispatch(failure());
        dispatch(alertActions.error("Problème rencontré lors de la modification de la page d'accueil"));
      });
  };
}

/**
 * Met à jour l'utilisateur passé en paramètre avec son mot de passe
 *
 * @method updateWithPassword
 * @param original Infos utilisateur d'origine
 * @param {any} user Nouvelles infos utilisateur
 * @param {any} permissions Permissions
 * @param {any} lastUpdatedInfo Dernière info mise à jour
 * @returns {Object} L'état de la mise à jour
 */
function updateWithPassword(original: any, user: any) {
  function failure(error: Object) {
    return { type: userConstants.UPDATE_FAILURE, original, user };
  }
  function success(updatedUser: User) {
    return { type: userConstants.UPDATE_SUCCESS, updatedUser, passwordUpdated: true };
  }

  return (dispatch: Function) => {
    userService
      .updateWithPassword(user)
      .then((updatedUser: any) => dispatch(success(updatedUser)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure("Impossible de mettre à jour l'utilisateur"));
        dispatch(alertActions.error(error));
      });
  };
}

/**
 * Supprime l'utilisateur passé en paramètre
 *
 * @method toDelete
 * @param {any} user Utilisateur
 * @param {Array<User>} users Tous les utilisateurs
 * @returns {Object} L'état de la suppression
 */
function toDelete(user: any, users = <User[]>[]) {
  function request() {
    return { type: userConstants.DELETE_REQUEST };
  }
  function success() {
    return { type: userConstants.DELETE_SUCCESS, user, users };
  }
  function failure(error: Object) {
    return { type: userConstants.DELETE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    userService
      .toDelete(user.id)
      .then(() => {
        dispatch(success());
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      });
  };
}

/**
 * Vide les infos de l'utilisateur passé en paramètre
 *
 * @method clear
 * @param {Object} user Utilisateur
 * @param {Array<any>} permissions Permissions
 * @param {any} lastUpdatedInfo Dernière info mise à jour
 */
function clear(user: Object, permissions: Array<any>, lastUpdatedInfo: any) {
  return {
    type: userConstants.CLEAR,
    user,
  };
}

/**
 * Vide le state des utilisateurs
 *
 * @method clearAll
 */
function clearAll() {
  return {
    type: userConstants.CLEAR,
  };
}

/**
 * Réinitialise le mot de passe de l'utilisateur passé en paramètre
 *
 * @method resetPassword
 * @param {any} user Utilisateur
 * @param {string} password Mot de passe
 * @returns {Object} L'état de la réinitialisation
 */
function resetPassword(user: any, password: string) {
  function success(updatedUser: any) {
    localStorage.clear();
    return { type: userConstants.UPDATE_SUCCESS, user, updatedUser, updated: true };
  }

  return (dispatch: Function) => {
    userService
      .updateWithPassword(user)
      .then((updatedUser: any) => {
        dispatch(success(updatedUser));
        dispatch(alertActions.success('Merci de vous déconnecter afin de prendre en compte le changement'));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(alertActions.error('Une erreur est survenue, merci de contacter votre administrateur'));
      });
  };
}

/**
 * Récupère le reset token à partir du token courant
 *
 * @method getResetTokenInfo
 * @param {string} token Token courant
 * @returns {Object} Le reset token
 */
function getResetTokenInfo(token: string) {
  function request() {
    return { type: userConstants.TOKEN_REQUEST };
  }
  function success(token: any) {
    return { type: userConstants.TOKEN_SUCCESS, token };
  }
  function failure(error: Object) {
    return { type: userConstants.TOKEN_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    userService
      .getResetTokenInfo(token)
      .then((token: any) => {
        dispatch(success(token));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(alertActions.error('Une erreur est survenue, merci de contacter votre administrateur'));
        dispatch(failure('Une erreur est survenue, merci de contacter votre administrateur'));
      });
  };
}

/**
 * Génère un nouveau token à partir du mail passé en paramètre
 *
 * @method generateToken
 * @param {string} email Email
 * @returns {Object} Le token
 */
function generateToken(email: string) {
  function request() {
    return { type: userConstants.TOKEN_REQUEST };
  }
  function success(token: any) {
    return { type: userConstants.TOKEN_SUCCESS, token };
  }
  function failure(error: Object) {
    return { type: userConstants.TOKEN_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    userService
      .generateToken(email)
      .then((token: any) => {
        dispatch(success(token));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

/**
 * Bloque ou débloque l'utilisateur passé en paramètre
 *
 * @method blockOrUnblock
 * @param {any} user Utilisateur
 * @param {Array<User>} users Tous les utilisateurs
 * @returns {Object} L'état de l'utilisateur
 */
function blockOrUnblock(user: any, users = <User[]>[]) {
  function request() {
    return { type: userConstants.BLOCK_REQUEST };
  }
  function success(updatedUser: any, dispatch: any) {
    dispatch(alertActions.success('Opération réussie'));
    return { type: userConstants.BLOCK_SUCCESS, updatedUser, users };
  }
  function failure(error: Object) {
    return { type: userConstants.BLOCK_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    userService
      .blockOrUnblock(user.id)
      .then(updatedUser => {
        dispatch(success(updatedUser, dispatch));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      });
  };
}

/**
 * Bloque ou débloque l'utilisateur passé en paramètre en édition
 *
 * @method blockOrUnblock
 * @param {any} user Utilisateur
 * @param {any} permissions Permissions
 * @param {any} lastUpdatedInfo Dernière info mise à jour
 * @param {any} connectedUser Utilisateur actuellement connecté
 * @returns {Object} L'état de l'utilisateur
 */
function blockOrUnblockInEditionContext(user: any, connectedUser: any) {
  function success(updatedUser: any) {
    return {
      type: userConstants.UPDATE_SUCCESS,
      original: user,
      updatedUser,
      updated: false,
      connectedUser,
    };
  }

  function failure(error: Object) {
    return { type: userConstants.UPDATE_FAILURE, original: user, user };
  }

  return (dispatch: Function) => {
    userService
      .blockOrUnblock(user.id)
      .then((updatedUser: any) => dispatch(success(updatedUser)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      });
  };
}

/**
 * Réinitialise le mot de passe de l'utilisateur passé en paramètre
 *
 * @method reinitPassword
 * @param {any} user Utilisateur
 * @param {any} permissions Permissions
 * @param lastUpdatedInfo Dernière info mise à jour
 * @returns {Object} L'état de la réinitialisation
 */
function reinitPassword(user: any, permissions: any, lastUpdatedInfo: any) {
  function success(updatedUser: any) {
    return {
      type: userConstants.UPDATE_SUCCESS,
      original: user,
      updatedUser,
      updated: false, //Le flag updated sert uniquement pour les popups d'éditions
      permissions,
      lastUpdatedInfo,
    };
  }

  function failure(error: Object) {
    return { type: userConstants.UPDATE_FAILURE, original: user, user, permissions, lastUpdatedInfo };
  }

  return (dispatch: Function) => {
    userService
      .reinit(user.id)
      .then((updatedUser: any) => dispatch(success(updatedUser)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      });
  };
}

function checkPermission(keyPermission: string, valuePermission: number) {
  function success(authorize: boolean) {
    return { type: userConstants.CHECKPERMISSION_SUCCESS, key: keyPermission, authorize };
  }
  function failure() {
    return { type: userConstants.CHECKPERMISSION_FAILURE, key: keyPermission };
  }
  return (dispatch: Function) => {
    userService
      .checkPermission(keyPermission, valuePermission)
      .then((authorize: boolean) => dispatch(success(authorize)))
      .catch(() => {
        dispatch(failure());
      });
  };
}

const userActions = {
  login,
  logout,
  getAll,
  get,
  update,
  save,
  toDelete,
  updateWithPassword,
  clear,
  clearAll,
  resetPassword,
  getResetTokenInfo,
  generateToken,
  blockOrUnblock,
  blockOrUnblockInEditionContext,
  reinitPassword,
  saveTourmaline,
  changeHomepage,
  checkPermission,
};

export default userActions;
