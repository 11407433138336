import React, { Component } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Select, { components } from 'react-select';
import { Button, PopoverBody, PopoverHeader, UncontrolledPopover, UncontrolledTooltip } from 'reactstrap';
import filterFactory from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { enqueteActions } from '../_actions';
import synchroAction from '../_actions/synchro.actions';
import EnqueteSvg from '../SvgComponents/EnqueteSvg';
import Paste from '../SvgComponents/Paste';
import Copy from '../SvgComponents/Copy';
import Loading from '../_animations/Loading';
import './style.scss';

const SortableMultiValue = SortableElement(props => {
  const onMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableSelect = SortableContainer(Select);

class EnqueteLinkTournee extends Component {
  constructor(props: any) {
    super(props);

    this.generateEnqueteOptions = this.generateEnqueteOptions.bind(this);
    this.sendLinks = this.sendLinks.bind(this);
    this.changeCodeOfField = this.changeCodeOfField.bind(this);
    this.getStartLinkOfRound = this.getStartLinkOfRound.bind(this);

    this.state = { init: false, withDeletion: false };
  }

  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { GestionnaireId },
      },
    } = this.props;
    dispatch(enqueteActions.getAll());
    dispatch(enqueteActions.getLinks(GestionnaireId));
    dispatch(synchroAction.getAllRoundFull(GestionnaireId));
  }

  static getDerivedStateFromProps(props: any, state: any) {
    const {
      enquete,
      match: {
        params: { GestionnaireId },
      },
      history,
    } = props;
    if (!state.init && enquete.links && enquete.items) {
      const generateEnqueteOptions = enquete.items.map(el => ({ label: el.enqName, value: el.enqNumPage }));
      const links = _.fromPairs(
        _.toPairs(_.get(enquete, 'links')).map(([key, value]) => [
          key,
          value.map(v => generateEnqueteOptions.find(option => option.value === v)),
        ])
      );
      return { links, init: true };
    }
    if (enquete.savedLinks) {
      history.push({ pathname: `/gestionnaires/${GestionnaireId}/synchronisation/enquetes` });
    }
  }

  getStartLinkOfRound(rndCode: string) {
    const { enquete } = this.props;
    return enquete.links[rndCode];
  }

  /**
   * SortableSelect CONFIGURATION
   */

  createOption(label: string) {
    return {
      label,
      value: label,
    };
  }

  arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  }

  onSortEnd = ({ oldIndex, newIndex }, rndCode) => {
    const { links } = this.state;
    const newColumns = this.arrayMove(_.get(links, rndCode), oldIndex, newIndex);
    this.changeCodeOfField(rndCode, newColumns);
  };

  generateEnqueteOptions() {
    const { enquete } = this.props;
    return enquete.items.map(el => ({ label: el.enqName, value: el.enqNumPage }));
  }

  changeCodeOfField(rndCode: string, value: any) {
    const copyState = _.cloneDeep(this.state);
    const startLinksForRound = this.getStartLinkOfRound(rndCode);
    _.set(copyState, `links.${rndCode}`, value);
    _.set(
      copyState,
      'withDeletion',
      _.get(copyState, 'withDeletion') ||
        _.size(_.difference(startLinksForRound, (value || []).map(el => el.value))) > 0
    );
    this.setState(copyState);
  }

  sendLinks() {
    const { dispatch } = this.props;
    const { links } = this.state;
    const mappedLinks = _.fromPairs(_.toPairs(links).map(([key, value]) => [key, value && value.map(v => v.value)]));
    dispatch(enqueteActions.saveLinks(mappedLinks));
  }

  copyConf(rndCode: string) {
    const { links } = this.state;
    this.setState({ copyConf: _.get(links, rndCode) });
  }

  pastConf(rndCode: string) {
    const { links } = this.state;
    const copyLinks = _.cloneDeep(links);
    _.set(copyLinks, rndCode, _.get(this.state, 'copyConf'));
    this.setState({ links: copyLinks });
  }

  /* END */
  render() {
    const { synchro, t, enquete } = this.props;
    const { links, withDeletion } = this.state;
    return (
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }} className={'enquete-link'}>
        {synchro.tourneeRessources && enquete.items ? (
          <div className="table-info-container">
            <h2>
              <EnqueteSvg height="1em" width="1em" fill="#31c6b3" className="svg-table-title" />{' '}
              {t('sidebar_synchro.nav_link.enquiry_plural')}{' '}
              <Button
                id="registerLinks"
                style={{ marginLeft: '50px', minWidth: '15ch' }}
                onClick={!withDeletion && this.sendLinks}
              >
                {t('all.button.register')}
              </Button>
              {withDeletion && (
                <UncontrolledPopover trigger={'legacy'} placement="top" target="registerLinks">
                  <PopoverHeader style={{ color: 'red' }}>&#x26A0; {t('enquete.text.deletion_enquetes')}</PopoverHeader>
                  <PopoverBody style={{ display: 'grid' }}>
                    <p>{t('enquete.text.enquete_deleted_links_warning')}</p>
                    <Button color="danger" onClick={this.sendLinks}>
                      {t('all.button.confirm')}
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </h2>
            {synchro.tourneeRessources && (
              <BootstrapTable
                key={links}
                keyField="rndCode"
                data={_.values(synchro.tourneeRessources)}
                columns={[
                  {
                    dataField: 'content',
                    text: t('all.round.round_name'),
                    sort: true,
                    headerStyle: () => {
                      return { width: `${_.max([t('all.round.round_name').length, 20]) + 5}ch`, textAlign: 'center' };
                    },
                  },
                  {
                    dataField: 'rndCode',
                    text: t('all.round.round_code'),
                    sort: true,
                    headerStyle: () => {
                      return { width: `${_.max([t('all.round.round_code').length, 8]) + 5}ch`, textAlign: 'center' };
                    },
                  },
                  {
                    dataField: 'id',
                    text: t('enquete.text.associated_page'),
                    headerStyle: () => {
                      return { textAlign: 'center' };
                    },
                    formatExtraData: links,
                    formatter: (field, row) => (
                      <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
                        <div className="enq-rnd-link">
                          <SortableSelect
                            // react-sortable-hoc props:
                            axis="xy"
                            distance={4}
                            // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                            getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                            onSortEnd={elem => this.onSortEnd(elem, row.rndCode)}
                            components={{
                              MultiValue: SortableMultiValue,
                            }}
                            placeholder={t('linefilter.userinput.placeholder_input')}
                            closeMenuOnSelect={false}
                            isMulti
                            options={this.generateEnqueteOptions()}
                            onChange={columns => {
                              this.changeCodeOfField(row.rndCode, columns);
                            }}
                            value={_.get(links, row.rndCode) || []}
                            isDisabled={!_.isEmpty(row.affectList)}
                          />
                          {!_.isEmpty(row.affectList) && (
                            <span style={{ color: 'gray' }}>
                              {t('enquete.text.round_loaded_devices_no_update_allow')}
                            </span>
                          )}
                        </div>
                        <span
                          className="clickable round"
                          id={`copy${row.rndCode}`}
                          onClick={() => this.copyConf(row.rndCode)}
                          role="presentation"
                        >
                          <Copy className="clickable " fill="currentcolor" height="2em" width="2em" />
                          <UncontrolledTooltip placement="bottom" target={`copy${row.rndCode}`}>
                            {' '}
                            {t('all.button.copy')}
                          </UncontrolledTooltip>
                        </span>
                        <span
                          className="clickable round"
                          id={`paste${row.rndCode}`}
                          onClick={() => this.pastConf(row.rndCode)}
                          role="presentation"
                        >
                          <Paste className="clickable " fill="currentcolor" height="2em" width="2em" />
                          <UncontrolledTooltip placement="bottom" target={`paste${row.rndCode}`}>
                            {' '}
                            {t('all.button.paste')}
                          </UncontrolledTooltip>
                        </span>
                      </div>
                    ),
                  },
                ]}
                bootstrap4
                hover
                bordered={false}
                filter={filterFactory()}
              />
            )}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { enquete, synchro } = state;
  return {
    enquete,
    synchro,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(EnqueteLinkTournee)));
