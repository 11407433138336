import React, { Component } from 'react';
import SvgTeleReleve from '../SvgComponents/LogoTeleReleve';
import { withTranslation } from 'react-i18next';

interface Props {
  handleMod: Function;
  activedMod: string;
}

const itemList = ['provisioning.text.provisionned', 'provisioning.text.not_provisionned', 'provisioning.text.actions'];

const svgList = [<SvgTeleReleve height="3em" fill="#51c22d" />, <SvgTeleReleve height="3em" fill="#ce4646" />];

class SelectionComponent extends Component<Props> {
  render() {
    const { handleMod, activeMod, decoupage, withAction, t } = this.props;
    /*
            <div className="preview">
          <h4>{explaination}</h4>
        </div>
    * */

    return (
      <div className="selection-component alarm-option-form filter-container">
        <div className="select-onglet flex-box">
          {itemList.map((mode: any, index: number) => (
            <div
              className={withAction ? 'c33' : 'c50'}
              style={{
                backgroundColor: activeMod === index ? 'rgba(255, 255, 255, 1)' : 'rgba(245, 246, 248, 1)',
                borderBottom: activeMod === index ? 'none' : '1px solid lightgrey',
                display: index === 2 && !withAction ? 'none' : '',
              }}
              onClick={() => handleMod(index)}
            >
              {index < 2 && (
                <div>
                  <div className="space-svg">{svgList[index]}</div>
                  <h3 style={{ color: activeMod === index ? 'black' : 'darkgrey' }}>
                    {decoupage[index]} {t(mode)}
                  </h3>
                </div>
              )}
              {index === 2 && (
                <div style={{ display: 'inline-flex', width: '100%' }}>
                  <div style={{ margin: 'auto', borderRight: '1px solid lightgrey', width: '100%' }}>
                    <SvgTeleReleve height="3em" fill="#ce4646" />
                    &rarr;
                    <SvgTeleReleve height="3em" fill="#51c22d" />
                    <h3 style={{ color: activeMod === index ? 'black' : 'darkgrey' }}>{decoupage[index][0]}</h3>
                  </div>
                  <div style={{ marginLeft: '10px', margin: 'auto', width: '100%' }}>
                    <SvgTeleReleve height="3em" fill="#51c22d" />
                    &rarr;
                    <SvgTeleReleve height="3em" fill="#ce4646" />
                    <h3 style={{ color: activeMod === index ? 'black' : 'darkgrey' }}>{decoupage[index][1]}</h3>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(SelectionComponent);
