const notificationConstants = {
  GETALL_REQUEST: 'NOTIFICATIONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'NOTIFICATIONS_GETALL_SUCCESS',
  GETALL_FAILURE: 'NOTIFICATIONS_GETALL_FAILURE',

  GETLAST_REQUEST: 'NOTIFICATIONS_GETLAST_REQUEST',
  GETLAST_SUCCESS: 'NOTIFICATIONS_GETLAST_SUCCESS',
  GETLAST_FAILURE: 'NOTIFICATIONS_GETLAST_FAILURE',

  GETCOUNT_REQUEST: 'NOTIFICATIONS_GETCOUNT_REQUEST',
  GETCOUNT_SUCCESS: 'NOTIFICATIONS_GETCOUNT_SUCCESS',
  GETCOUNT_FAILURE: 'NOTIFICATIONS_GETCOUNT_FAILURE',

  GET_REQUEST: 'NOTIFICATIONS_GET_REQUEST',
  GET_SUCCESS: 'NOTIFICATIONS_GET_SUCCESS',
  GET_FAILURE: 'NOTIFICATIONS_GET_FAILURE',

  UPDATE_REQUEST: 'NOTIFICATIONS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'NOTIFICATIONS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'NOTIFICATIONS_UPDATE_FAILURE',

  READ_ALL_SUCCESS: 'NOTIFICATIONS_READ_ALL_SUCCESS',
  READ_ALL_FAILURE: 'NOTIFICATIONS_READ_ALL_FAILURE',

  DELETE_REQUEST: 'NOTIFICATIONS_DELETE_REQUEST',
  DELETE_SUCCESS: 'NOTIFICATIONS_DELETE_SUCCESS',
  DELETE_FAILURE: 'NOTIFICATIONS_DELETE_FAILURE',

  DELETE_MULTI_SUCCESS: 'NOTIFICATIONS_DELETE_MULTI_SUCCESS',
  DELETE_MULTI_FAILURE: 'NOTIFICATIONS_DELETE_MULTI_FAILURE',

  CLEAR: 'NOTIFICATIONS_CLEAR',
};

export default notificationConstants;
