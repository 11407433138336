import React, { PureComponent } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import SvgRadioVert from '../SvgComponents/RadioVert';
import SvgCalendrierVert from '../SvgComponents/CalendrierVert';
import NoFilled from '../Message/NoFilled';
import ModeleVert from '../SvgComponents/ModeleVert';
import SvgReadMeterType from '../SvgComponents/ReadMeterType';
import SvgCroixNoir from '../SvgComponents/croixNoir';
import ListTools from '../List/ListTools';
import SvgPdiVert from '../SvgComponents/PdiVert';

export interface Props {
  closeSelectedBand: Function;
  goToFicheMeter: Function;
  selectedMeter: CollectPoint;
  locales?: any;
  t: Function;
}

export interface Gps {
  lat: number;
  lng: number;
  alt?: any;
}

export interface ReadData {
  id: number;
  index: number;
  consumption: number;
  date: Date;
  method: string;
  remoteReadingType: string;
  alarm?: any;
}

export interface CollectPoint {
  id: number;
  pdiId: number;
  clpReference: string;
  meterSerial: string;
  radioSerial: string;
  gps: Gps;
  meterModel: string;
  pulseWeight: number;
  wheels: number;
  address: string;
  readData: ReadData;
}

class SelectedBand extends PureComponent<Props> {
  getSection(svgKey: String, title: string, info: any, style?: Object) {
    return (
      <div className="c20 b-right elementWithSvg" style={style || {}}>
        <div className="flex-box center">
          {this.getSvg(svgKey)}
          <div>
            <span>{title}</span>
            {(!_.isNull(info) && _.size(info) > 0) || parseInt(info, 10) === 0 ? <h3>{info}</h3> : <NoFilled />}
          </div>
        </div>
      </div>
    );
  }

  getSvg(type: String) {
    switch (type) {
      case 'radio':
        return <SvgRadioVert height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />;
      case 'lastRead':
        return <SvgCalendrierVert height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />;
      case 'index':
        return <ModeleVert height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />;
      case 'readMethod':
        return <SvgReadMeterType height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />;
      case 'pdi':
        return <SvgPdiVert height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />;
    }
  }

  render() {
    const { closeSelectedBand, goToFicheMeter, t } = this.props;
    const selectedMeter: CollectPoint = this.props.selectedMeter;
    return (
      <div className="clean-meter-bar selectedBand" style={{ marginBottom: 0, position: 'relative' }}>
        <div className="flex-box">
          <div className="c20 background-texture left" style={{ position: 'relative', width: '15%' }}>
            <span>{t('all.meter.meter')}</span>
            <h3>{selectedMeter.meterSerial}</h3>
          </div>
          {this.getSection('radio', t('all.radio.radio'), selectedMeter.radioSerial || t('all.text.none_female'), {
            width: '18%',
          })}
          {this.getSection(
            'lastRead',
            t('all.read_meter.read_last_date'),
            _.get(selectedMeter, 'readData.date')
              ? moment.utc(_.get(selectedMeter, 'readData.date')).isValid() &&
                  moment.utc(_.get(selectedMeter, 'readData.date')).format('DD/MM/YYYY')
              : null,
            { width: '15%' }
          )}
          {this.getSection(
            'index',
            t('all.meter.index'),
            _.isNaN(_.get(selectedMeter, 'readData.index'))
              ? null
              : ListTools.formatterIndex(_.get(selectedMeter, 'readData.index'), selectedMeter),
            { width: '18%' }
          )}
          {this.getSection(
            '',
            t('all.read_meter.cons'),
            _.isNaN(_.get(selectedMeter, 'readData.consumption'))
              ? null
              : ListTools.formatterIndex(_.get(selectedMeter, 'readData.consumption'), selectedMeter),
            { width: '14%' }
          )}
          {this.getSection(
            'readMethod',
            t('all.read_meter.read_method'),
            _.get(selectedMeter, 'readData.method')
              ? t(`all.read_meter.type.${_.get(selectedMeter, 'readData.method')}`)
              : null,
            { width: '12%' }
          )}
          <div className="c20 b-right" style={{ border: 0, width: '10%' }}>
            <div className="flex-box center">
              <div style={{ textAlign: 'center' }}>
                <Button id="goToPdiButtonFromMap" color="primary" onClick={() => goToFicheMeter()}>
                  <SvgPdiVert height="1.5em" fill="white" style={{ margin: 0 }} />
                  <span
                    style={{
                      paddingLeft: '4px',
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: '1.12em',
                    }}
                  >
                    {t('all.pdi.pdi').toUpperCase()}
                  </span>
                  <UncontrolledTooltip placement="bottom" target="goToPdiButtonFromMap">
                    {t('all.pdi.go_to_pdi')}
                  </UncontrolledTooltip>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="close-infomap-container"
          style={{
            borderRadius: '50%',
            height: '25px',
            width: '25px',
            cursor: 'pointer',
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          onClick={() => closeSelectedBand(undefined)}
        >
          <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <span className="absoluteCentered" style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
              <SvgCroixNoir height="1.2em" fill="#000" />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const tr = withTranslation()(SelectedBand);
export default tr;
