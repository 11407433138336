import TourneesVertes from '../SvgComponents/TourneesVertes';
import React from 'react';
import SvgCompteurVert from '../SvgComponents/CompteurVert';
import SvgRadioVert from '../SvgComponents/RadioVert';
import SvgHistoriqueDonneesVert from '../SvgComponents/HistoriqueVert';
import SvgMessageVert from '../SvgComponents/MessageVert';

function getC(key, props) {
  switch (key) {
    case 'roundcode':
      return <TourneesVertes {...props} />;
    case 'roundtotalcpt':
      return <SvgCompteurVert {...props} />;
    case 'roundtotalradio':
      return <SvgRadioVert {...props} />;
    case 'roundstartdate':
      return <SvgHistoriqueDonneesVert {...props} />;
    case 'roundmessage':
      return <SvgMessageVert {...props} />;

    default:
      break;
  }
}

export default getC;
