import React from 'react';

const SvgReadMeter = (props: any) => (
  <svg viewBox="0 0 200 200" {...props}>
    <path
      d="M29.3,93.5c-2.2,0-3.9,1.7-3.9,3.9l5.1,24.9h-19l-0.1-1.1c-0.1-0.8-0.8-1.5-1.7-1.4c-0.8,0.1-1.4,0.7-1.4,1.5
			l0-0.2c-0.1-0.8-0.8-1.5-1.7-1.4c-0.8,0.1-1.4,0.7-1.4,1.5l0-0.2c-0.1-0.8-0.8-1.5-1.7-1.4c-0.8,0.1-1.5,0.8-1.4,1.7l0.1,0.8H1.6
			c-0.9,0-1.6,0.7-1.6,1.6v15.7c0,0.9,0.7,1.6,1.6,1.6h2.7l0.1,1.1c0.1,0.8,0.7,1.4,1.5,1.4c0.1,0,0.1,0,0.2,0
			c0.8-0.1,1.4-0.7,1.4-1.5l0,0.2c0.1,0.8,0.7,1.4,1.5,1.4c0.1,0,0.1,0,0.2,0c0.8-0.1,1.4-0.7,1.4-1.5l0,0.2
			c0.1,0.8,0.7,1.4,1.5,1.4c0.1,0,0.1,0,0.2,0c0.8-0.1,1.5-0.8,1.4-1.7l-0.1-0.8h21l1.2,5.7c0,2.2,1.7,3.9,3.9,3.9h27.7l0-57.2H29.3
			z"
      fill={props.fill}
    />
    <path
      d="M76.5,78.1l29.4-4.7c1.2-0.2,2.4-0.2,3.5,0l27.6,4.4l2.8-0.4c0-0.2-0.1-0.4-0.1-0.6
			c-4.7-22.3-15.7-43.1-20.6-50.8c-1.5-2.3-3.8-5.2-6.2-5.2H39.3c-2.4,0-4.7,2.5-6.2,4.8c-4.9,7.8-15.9,28.4-20.6,50.7
			C11.2,82,15,89.1,20,89.1h47.2v-0.3C67.2,83.5,71.1,78.9,76.5,78.1z"
      fill={props.fill}
    />
    <path
      d="M136.9,108.8c-2.4,0-4.4-1.9-4.4-4.2V92.4l-25-4l-25,4v12.2c0,2.3-2,4.2-4.4,4.2s-4.4-1.9-4.4-4.2V88.8
						c0-2,1.5-3.8,3.6-4.1l29.4-4.7c0.5-0.1,1-0.1,1.4,0l29.4,4.7c2.1,0.3,3.6,2.1,3.6,4.1v15.8
						C141.3,106.9,139.3,108.8,136.9,108.8"
      fill={props.fill}
    />
    <path
      d="M195.6,108.8c-2.4,0-4.4-1.9-4.4-4.2V92.4l-25-4l-25,4v12.2c0,2.3-2,4.2-4.4,4.2c-2.4,0-4.4-1.9-4.4-4.2
						V88.8c0-2,1.5-3.8,3.6-4.1l29.4-4.7c0.5-0.1,1-0.1,1.4,0l29.4,4.7c2.1,0.3,3.6,2.1,3.6,4.1v15.8
						C200,106.9,198,108.8,195.6,108.8"
      fill={props.fill}
    />
    <path
      d="M166.3,179.3c-0.2,0-0.5,0-0.7-0.1l-29.3-4.7c-2.1-0.3-3.6-2.1-3.6-4.1v-15.8c0-2.3,2-4.2,4.4-4.2
						c2.4,0,4.4,1.9,4.4,4.2v12.3l25,4l25-4v-12.3c0-2.3,2-4.2,4.4-4.2c2.4,0,4.4,1.9,4.4,4.2v15.8c0,2-1.5,3.8-3.6,4.1l-29.3,4.7
						C166.8,179.3,166.5,179.3,166.3,179.3"
      fill={props.fill}
    />
    <path
      d="M107.6,179.3c-0.2,0-0.5,0-0.7-0.1l-29.3-4.7c-2.1-0.3-3.6-2.1-3.6-4.1v-15.8c0-2.3,2-4.2,4.4-4.2
						c2.4,0,4.4,1.9,4.4,4.2v12.3l25,4l25-4v-12.3c0-2.3,2-4.2,4.4-4.2c2.4,0,4.4,1.9,4.4,4.2v15.8c0,2-1.5,3.8-3.6,4.1l-29.3,4.7
						C108.1,179.3,107.8,179.3,107.6,179.3"
      fill={props.fill}
    />
    <path
      d="M113,135.8c-2.2,2.3-4.6,3.4-7.2,3.4c-4.1,0-7.3-1.6-9.6-4.9c-2.3-3.3-3.5-7.6-3.5-13.1
					c0-3.5,0.7-6.8,2-9.7c1.3-2.9,3.2-5.2,5.5-6.8c2.4-1.6,5-2.4,7.9-2.4c3,0,5.7,0.9,8.1,2.7c2.4,1.8,4.2,4.4,5.5,7.7
					c1.3,3.3,1.9,7.2,1.9,11.4v3.8c0,9.1-2,16.2-6,21.4c-4,5.1-9.7,7.8-17,7.9h-1.1v-9h0.7c4.1,0,7.1-1.1,9.2-3.2
					C111.4,142.8,112.6,139.8,113,135.8z M108.4,131c2,0,3.6-1.4,4.7-4.1v-4.8c0-3.5-0.5-6.3-1.4-8.3s-2.2-3-3.7-3
					c-1.5,0-2.7,1-3.6,3c-0.9,2-1.4,4.4-1.4,7.3c0,3.1,0.5,5.5,1.4,7.3C105.4,130.1,106.7,131,108.4,131z"
      fill={props.fill}
    />
    <path
      d="M171,135.8c-2.2,2.3-4.6,3.4-7.2,3.4c-4.1,0-7.3-1.6-9.6-4.9c-2.3-3.3-3.5-7.6-3.5-13.1
					c0-3.5,0.7-6.8,2-9.7c1.3-2.9,3.2-5.2,5.5-6.8c2.4-1.6,5-2.4,7.9-2.4c3,0,5.7,0.9,8.1,2.7c2.4,1.8,4.2,4.4,5.5,7.7
					c1.3,3.3,1.9,7.2,1.9,11.4v3.8c0,9.1-2,16.2-6,21.4c-4,5.1-9.7,7.8-17,7.9h-1.1v-9h0.7c4.1,0,7.1-1.1,9.2-3.2
					C169.3,142.8,170.5,139.8,171,135.8z M166.4,131c2,0,3.6-1.4,4.7-4.1v-4.8c0-3.5-0.5-6.3-1.4-8.3c-1-2-2.2-3-3.7-3
					c-1.5,0-2.7,1-3.6,3c-0.9,2-1.4,4.4-1.4,7.3c0,3.1,0.5,5.5,1.4,7.3C163.3,130.1,164.7,131,166.4,131z"
      fill={props.fill}
    />
  </svg>
);

export default SvgReadMeter;
