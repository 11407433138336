import React from 'react';

const SvgPoidsImpulsionVert = (props: any) => (
  <svg viewBox="0 0 30.31 30.31" {...props}>
    <path
      d="M5.93 9.23a.66.66 0 0 0-.64.49L0 29.49a.66.66 0 0 0 .12.57.64.64 0 0 0 .52.25h29a.67.67 0 0 0 .53-.25.69.69 0 0 0 .11-.57L25 9.72a.66.66 0 0 0-.64-.49h-2v-4h2a.65.65 0 0 0 .64-.62V.66a.65.65 0 0 0-.62-.66H5.93a.66.66 0 0 0-.66.66v4a.66.66 0 0 0 .66.66h2v4zM1.52 29l.7-2.64h25.87L28.8 29zm26.22-4H2.57l3.87-14.5h17.44zM6.59 1.32h17.13V4H6.59zm2.64 3.95h11.86v4H9.23z"
      fill={props.fill}
      data-name="Calque 2"
    />
  </svg>
);

export default SvgPoidsImpulsionVert;
