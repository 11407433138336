import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import { alarmActions, localeActions, locationActions, reportActions } from '../_actions';
import { locationService } from '../_services';
import HistoriqueVert from '../SvgComponents/HistoriqueVert';
import InformationsBleu from '../SvgComponents/InformationsBleu';
import history from '../_helpers/history';
import LoadingBand from '../Bands/Loading';
import ErrorBand from '../Bands/Error';
import SvgEye from '../SvgComponents/Eye';
import { QueryBuilder } from '../QueryBuilder';
import listOperator from '../_shared/OperatorList';
import ListTools from '../List/ListTools';
import StaticList from '../TourneeFiches/StaticList';
import getC from '../TourneeFiches/Picto_correspondance';
import { translateBasicAlarm } from '../_helpers/locale-helpers';
import locale from '../_shared/Locale.json';

interface Props extends React.Props<any> {
  reports: any;
  location: any;
  locations: any;
  alarms: any;
  match: any;
  dispatch: Function;
  alert: any;
  user: any;
  history: any;
}

interface State {}

class Reports extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { alertId, locationId } = match.params;

    dispatch(localeActions.load());
    locationService.get(locationId).then((site: any) => {
      dispatch(alarmActions.get(alertId, site.code));
      dispatch(reportActions.getAllByAlert(alertId, site.code));
    });
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(reportActions.clear());
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    const { locations, match, dispatch } = this.props;
    const { locationId } = match.params;

    if (Object.keys(prevProps.locations).length === 0 && Object.keys(locations).length === 0) {
      dispatch(locationActions.get(locationId));
    }
  }

  formatDate = (cell: any, row: any) => {
    const { alarms } = this.props;
    const date = moment(cell).isValid() ? moment(cell).format(`DD/MM/YYYY ${moment.HTML5_FMT.TIME_SECONDS}`) : '';

    return <span>{date}</span>;
  };

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof Notifications
   */
  render() {
    const { reports, match, tournee, t } = this.props;
    const { alertId, locationId } = match.params;
    let reportsList = [];
    if (reports && reports.items) {
      reportsList = reports.items;
    }
    const columns = [
      {
        dataField: 'createdAt',
        text: 'Date',
        sort: true,
        formatter: this.formatDate,
      },
      {
        dataField: 'action',
        isDummyField: true,
        align: 'center',
        headerStyle: () => ({ width: '180px' }),
        text: '',
        formatter: (cellContent: any, row: any) => {
          const { alarms } = this.props;
          let diff = 0;
          if (alarms.fetchedAlarm) {
            const { updatedAt } = alarms.fetchedAlarm;
            diff = moment(row.createdAt).diff(moment(updatedAt));
          }
          return (
            <Row>
              <Col xs="4">
                <div
                  id={`report${row.id}-view`}
                  className="clickable round"
                  role="presentation"
                  onClick={e => {
                    history.push(`/locations/${locationId}/alerts/${alertId}/reports/${row.id}`);
                  }}
                >
                  <SvgEye height="1.3em" width="1.3em" />
                  <UncontrolledTooltip placement="bottom" target={`report${row.id}-view`}>
                    {' '}
                    Consulter le rapport
                  </UncontrolledTooltip>
                </div>
              </Col>
              {diff < 0 && (
                <Col xs="4" style={{ margin: 'auto' }}>
                  <span id={`expired-${row.id}`}>
                    <InformationsBleu className="add" fill="red" height="2em" width="2em" />
                  </span>
                  <UncontrolledTooltip placement="bottom" target={`expired-${row.id}`}>
                    {' '}
                    Peut présenter des données expirées
                  </UncontrolledTooltip>
                </Col>
              )}
            </Row>
          );
        },
      },
    ];

    const fields = ListTools.getDefaultColumns(ListTools.typeOfList.LightFiche).map(champ => {
      const typeData = ListTools.findSpecificType(champ);
      return {
        label: t(`columns.${champ}`),
        value: champ,
        type: typeData,
        classes: champ === 'hasGpsPosition' ? 'center-td' : '',
        opts:
          champ === 'ficheState'
            ? StaticList.etatFiche.map(el => {
                return {
                  value: el.picto,
                  label: (
                    <div>
                      {getC(el.picto, { height: '1.5em', width: '1.5em' })}
                      {el.label}
                    </div>
                  ),
                };
              })
            : champ === 'consoState'
            ? StaticList.consoState
            : champ === 'alarms'
            ? translateBasicAlarm('fr', locale).sort((a, b) => {
                if (a.label < b.label) return -1;
                if (a.label === b.label) return 0;
                return 1;
              })
            : [],
      };
    });

    return (
      <div className="col-md-12">
        {reports.loading && <LoadingBand message="Chargement des rapports" />}
        {reports.error && <ErrorBand message={reports.error} />}
        {reports.items && (
          <div className="table-info-container">
            <h2>
              <span>
                <HistoriqueVert height="1em" width="1em" fill="#31c6b3" />
              </span>
              {t('bread_crumb.name.report_plural')} :{' '}
            </h2>
            <br />
            <div className="rapport-filter">
              <QueryBuilder
                sendListFiltered={() => {}}
                listData={[]}
                listOperator={listOperator}
                listFilters={fields}
                idContext={ListTools.typeOfList.Fiche}
                save={false}
                idSite={locationId}
                tournee={tournee}
              />
            </div>
            <br />
            <BootstrapTable
              keyField="id"
              data={reportsList}
              columns={columns}
              bootstrap4
              bordered={false}
              hover
              filter={filterFactory()}
              pagination={paginationFactory()}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, locales, reports, alert, alarms, locations } = state;
  const { user } = authentication;

  return {
    user,
    locales,
    reports,
    alert,
    alarms,
    locations,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(Reports)));
