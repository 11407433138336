import React from 'react';

const SvgPleinEcranVert = (props: any) => (
  <svg viewBox="0 0 119.98 119.98" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M30,7.5V0H3.75A3.75,3.75,0,0,0,0,3.75V30H7.5V12.79L42.33,47.62l5.29-5.29L12.79,7.5Z"
        />
        <path
          fill={props.fill}
          d="M116.23,0H90V7.5H107.2L72.36,42.33l5.29,5.29,34.83-34.83V30H120V3.75A3.75,3.75,0,0,0,116.23,0Z"
        />
        <path
          fill={props.fill}
          d="M112.48,107.2,77.65,72.36l-5.29,5.29,34.84,34.83H90V120h26.24a3.75,3.75,0,0,0,3.75-3.75V90h-7.5Z"
        />
        <path
          fill={props.fill}
          d="M42.33,72.33,7.5,107.2V90H0v26.24A3.75,3.75,0,0,0,3.75,120H30v-7.5H12.79L47.62,77.65Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgPleinEcranVert;
