import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import tools from './tools';
import './style.scss';
import './responsiv.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
  Spinner,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { localeActions, locationActions, mapActions, pdiActions, remoteReadingActions } from '../_actions';
import moment from 'moment';
import SvgChartBar from '../SvgComponents/ChartBar';
import SvgChartPie from '../SvgComponents/ChartPie';
import SvgPieNotAvailable from '../SvgComponents/PieNotAvailable';
import SvgChartBarHorizontalNotAvailable from '../SvgComponents/ChartBarHorizontalNotAvailable';
import SvgMap from '../SvgComponents/Map';
import SvgCompteurVert from '../SvgComponents/CompteurVert';
import SvgReadMeter from '../SvgComponents/ReadMeter';
import SvgMeterWithAlarm from '../SvgComponents/MeterWithAlarm';
import SvgEmptyMeter from '../SvgComponents/EmptyMeter';
import SvgCalendar from '../SvgComponents/HistoriqueVert';
import SvgReadMeterType from '../SvgComponents/ReadMeterType';
import SvgLastRead from '../SvgComponents/LastRead';
import RadioVert from '../SvgComponents/RadioVert';
import filterFactory, { customFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import Mapbox from '../Mapbox/components/Mapbox';
import ListTools from '../List/ListTools';
import Loading from '../_animations/Loading';
import ChartRemoteReading from './ChartRemoteReading';
import BarRemoteReading from './BarRemoteReading';
import FlipChartRemoteReading from './FlipChartRemoteReading';
import SvgList from '../SvgComponents/List';
import paginationFactory from 'react-bootstrap-table2-paginator';
import SvgCroixNoir from '../SvgComponents/croixNoir';
import DigitRoll from 'digit-roll-react';
import SvgPdiVert from '../SvgComponents/PdiVert';
import translate from '../_helpers/locale-helpers';
import locale from '../_shared/Locale.json';
import CustomInput from '../List/CustomInput';
import NoFilled from '../Message/NoFilled';
import PictoAlarm from '../pdi_vue/PictoAlarm';

import fr from '../TraductionFile/fr';
import en from '../TraductionFile/en';
import es from '../TraductionFile/es';
import { getFiltersRequest } from '../QueryBuilder/FilterLSManager';
import ruleListConverter from './ruleListConverter';
import OptionsFilterList from '../_shared/OptionsFilterList';

const exportableFields = [
  {
    fieldName: 'reference',
    label: 'Référence PDI',
  },
  {
    fieldName: 'meter.serial',
    label: 'Numéro de série compteur',
  },
  {
    fieldName: 'radio.serial',
    label: 'Numéro de série radio',
  },
  {
    fieldName: 'meter.manufacturer',
    label: 'Constructeur du compteur',
  },
  {
    fieldName: 'read.readMethod',
    label: 'Méthode de lecture',
  },
  {
    fieldName: 'read.meterIndex',
    label: 'Index du compteur',
  },
  {
    fieldName: 'read.consumption',
    label: 'Consommation',
  },
  {
    fieldName: 'read.date',
    label: 'Date de dernière lecture',
  },
  {
    fieldName: 'alarms',
    label: 'Alarmes',
  },
  {
    fieldName: 'address.concatAdress',
    label: 'Adresse complète',
  },
];

const Card = ({ card, flipList, data, callBack, index, back, remoteReading, handleClick, t }) => {
  return (
    <div className={`card ${flipList.includes(card.type) ? (back ? 'un' : 'zero') : back ? 'zero' : '1'}`}>
      <div className="header-t">
        <div className="left-t">
          {card.svg}
          <h2>{card.title}</h2>
        </div>
        {(index === 4 || index === 5) && (
          <div className="svg-container" onClick={() => callBack(card.type)}>
            {back ? (
              <SvgChartPie height="1.5em" width="1.5em" fill="#333" />
            ) : (
              <SvgChartBar height="1.5em" width="1.5em" fill="#333" />
            )}
          </div>
        )}
      </div>
      {index === 0 && remoteReading.data ? (
        <div className="synthese-container">
          <span>
            <SvgCompteurVert fill="#31c6b3" height="2em" />
            <h4>{remoteReading.data.meters.length}</h4>
            {t('all.meter.meter_plural')}
          </span>
          <h4 className="h4-period">{t('remotereading.text.for_selected_period')} :</h4>
          <span>
            <SvgReadMeter fill="#31c6b3" height="2em" />
            <h4>{remoteReading.data.nbReadMeter}</h4>
            {t('virtual_meter.text.read_meter_plural')}
          </span>
          <span>
            <SvgMeterWithAlarm fill="#31c6b3" height="2em" />
            <h4>{remoteReading.data.nbReadMeterWithAlarm}</h4>
            {t('remotereading.text.in_alarm')}
          </span>
        </div>
      ) : (
        <div className="chart-container" style={{ height: 'calc(100% - 30px)' }}>
          {data.length > 0 ? (
            <>
              {back || index === 5 ? (
                <BarRemoteReading
                  chartData={data}
                  handleClick={(event, element) => handleClick(event, element, card.type)}
                />
              ) : (
                <ChartRemoteReading
                  chartData={data}
                  handleClick={(event, element) => (index === 2 ? null : handleClick(event, element, card.type))}
                />
              )}
            </>
          ) : (
            <>
              {back || index === 5 ? (
                <SvgChartBarHorizontalNotAvailable width="100%" fill="lightgrey" />
              ) : (
                <SvgPieNotAvailable width="100%" fill="lightgrey" />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

const readMethodMap = {
  A: 'radio',
  T: 'telereleve',
  M: 'manual',
};

const getMarkerIconKey = meter => {
  const readMethod = _.get(meter, 'readData.method');
  if (['A', 'T'].includes(readMethod)) {
    return `${_.size(_.get(meter, 'readData.alarm')) > 0 ? 'purple' : 'blue'}_${readMethodMap[readMethod]}`;
  }
  return readMethod === 'M' ? 'green_manual' : `red_${_.size(_.get(meter, 'radioSerial')) > 0 ? 'radio' : 'manual'}`;
};

const convertMeters = meterList => {
  return meterList.map(el => {
    return {
      gpsPosition: el.gps,
      icon: getMarkerIconKey(el),
      id: el.id,
      clpReference: el.clpReference,
      lastRead: {
        date: _.get(el, 'readData.date'),
        index: _.get(el, 'readData.index') && ListTools.formatterIndex(_.get(el, 'readData.index'), el),
        consumption:
          _.get(el, 'readData.consumption') && ListTools.formatterIndex(_.get(el, 'readData.consumption'), el),
        method: _.get(el, 'readData.method'),
      },
      meterSerial: el.meterSerial,
      pdiId: el.pdiId,
      serialRadio: el.radioSerial,
    };
  });
};

const RemoteReadingDataBetter = props => {
  const { dispatch, match, remoteReading, locations, maps, t, history, pdis, locales, customexport } = props;
  const { params } = match;
  const { locationId } = params;
  const {
    cardDisplayer,
    optionsBox,
    BandInfo,
    defaultSelectedBox,
    initSelectedBox,
    filterGpsMeterWithOptionsToFillMarker,
    RemoteDate,
    getColor,
  } = tools;

  const [checkBoxList, setCheckboxList] = useState(optionsBox(t));
  const [fullData, setFullData] = useState(0);
  const [flipList, setFlipList] = useState([]);
  const [selectedMeter, setSelectedMeter] = useState(null);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [selectedBox, setSelectedBox] = useState(defaultSelectedBox);
  const [filter, setFilter] = useState({});
  const [startDate, setStartDate] = useState(
    moment()
      .utc()
      .subtract(1, 'days')
  );
  const [side, setSide] = useState('front');
  const { genericData } = remoteReading;

  const isRound = _.get(locations, 'fetchedLocation.tournee');
  const startReleveDate = isRound
    ? moment.utc(_.get(remoteReading, 'genericData.startDate'))
    : moment.utc(_.get(locations, 'fetchedLocation.content.createdAt'));

  useEffect(() => {
    dispatch(remoteReadingActions.getGenericData(params.locationId));
    dispatch(locationActions.getInfos(params.locationId));
    dispatch(localeActions.load());
    const initFilter = localStorage.getItem('remoteReadingDataBetter-fast');
    if (initFilter) {
      setFilter(JSON.parse(initFilter));
    }
    return () => {
      dispatch(remoteReadingActions.clear());
      dispatch(locationActions.clear());
      dispatch(mapActions.clearMarker());
    };
  }, []);

  useEffect(() => {
    if (filter.current) {
      const doc = document.getElementsByClassName(`filter-${filter.current}`)[0];
      doc && doc.focus();
    }
    localStorage.setItem('remoteReadingDataBetter-fast', JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    if (_.get(remoteReading, 'data.meters')) {
      const filteredList = convertMeters(
        filterGpsMeterWithOptionsToFillMarker(
          selectedBox,
          _.cloneDeep(remoteReading.data.gpsMeters),
          startDate,
          _.get(remoteReading, 'genericData.meterModel')
        )
      );
      const everyData = convertMeters(
        filterGpsMeterWithOptionsToFillMarker(
          selectedBox,
          _.cloneDeep(remoteReading.data.meters),
          startDate,
          _.get(remoteReading, 'genericData.meterModel')
        )
      );
      setFullData(everyData.length);
      dispatch(mapActions.fillMarker(filteredList));
    } else if (_.get(remoteReading, 'genericData.allMeter')) {
      dispatch(
        remoteReadingActions.getData(
          params.locationId,
          isRound
            ? startReleveDate
                .utcOffset(0)
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .toISOString(false)
            : startDate
                .utcOffset(0)
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .toISOString(false)
        )
      );
    }
    if (_.get(remoteReading, 'genericData.meterModel')) {
      const checkClone = _.cloneDeep(checkBoxList);
      let boxClone = _.cloneDeep(selectedBox);

      remoteReading.genericData.meterModel.forEach(el => {
        boxClone = _.set(boxClone, el.toLowerCase(), true);
        if (!checkClone[1][1].find(clone => clone.title === el)) {
          checkClone[1][1].push({
            title: el,
            code: el.toLowerCase(),
          });
        }
      });

      setSelectedBox(boxClone);
      setCheckboxList(checkClone);
    }
  }, [remoteReading]);

  useEffect(() => {
    if (_.get(remoteReading, 'genericData.allMeter')) {
      dispatch(
        remoteReadingActions.getData(
          params.locationId,
          isRound ? startReleveDate.toISOString(false) : startDate.toISOString(false)
        )
      );
    }
  }, [startDate]);

  useEffect(() => {
    if (_.get(remoteReading, 'data.meters')) {
      const filteredList = convertMeters(
        filterGpsMeterWithOptionsToFillMarker(
          selectedBox,
          remoteReading.data.gpsMeters,
          startDate,
          _.get(remoteReading, 'genericData.meterModel')
        )
      );
      const everyData = convertMeters(
        filterGpsMeterWithOptionsToFillMarker(
          selectedBox,
          remoteReading.data.meters,
          startDate,
          _.get(remoteReading, 'genericData.meterModel')
        )
      );
      setFullData(everyData.length);
      dispatch(mapActions.fillMarker(filteredList));
    } else if (_.get(remoteReading, 'genericData.meters')) {
      const filteredList = convertMeters(
        filterGpsMeterWithOptionsToFillMarker(
          selectedBox,
          remoteReading.genericData.gpsMeters,
          startDate,
          _.get(remoteReading, 'genericData.meterModel')
        )
      );
      const everyData = convertMeters(
        filterGpsMeterWithOptionsToFillMarker(
          selectedBox,
          remoteReading.genericData.meters,
          startDate,
          _.get(remoteReading, 'genericData.meterModel')
        )
      );
      setFullData(everyData.length);
      dispatch(mapActions.fillMarker(filteredList));
    }
  }, [selectedBox]);

  const handleFlip = type => {
    let clone = _.cloneDeep(flipList);
    if (clone.includes(type)) {
      clone = clone.filter(el => el !== type);
    } else {
      clone.push(type);
    }
    setFlipList(clone);
  };

  const handleCheckbox = (e, code) => {
    const clone = _.cloneDeep(selectedBox);
    clone[code] = e.target.checked;
    if (code.includes('Alarm') && e.target.checked) {
      clone.A = true;
      clone.M = true;
      clone.T = true;
    }
    if (
      ((code === 'withoutAlarm' && !clone.withAlarm) || (code === 'withAlarm' && !clone.withoutAlarm)) &&
      !e.target.checked
    ) {
      clone.A = false;
      clone.M = false;
      clone.T = false;
    }
    setSelectedBox(clone);
  };

  const handleDateChange = dateMin => {
    if (startDate !== moment.utc(dateMin)) {
      setStartDate(moment.utc(dateMin));
    }
  };

  const getColumns = () => {
    const selectOptions = {
      'readData.method': genericData.readMethod.map(man => ({
        value: man,
        label: man === 'UNKNOWN' ? t('all.text.unknown') : t(`all.read_meter.type.${man}`),
      })),
      'readData.alarm': _.uniq(
        _.flatten(
          genericData.allMeter
            .map(meter => _.keys(_.get(meter, 'readData.alarm')))
            .filter(alarmList => alarmList && alarmList.length > 0)
        )
      ).map(man => ({ value: man, label: t(`alarm.type.${man.toLowerCase()}`) })),
      meterModel: genericData.meterModel.map(
        man => ({ value: man, label: man }) // TODO --> to trasnlate
      ),
      'readData.date': customFilter(),
    };
    return ListTools.getDefaultColumns(ListTools.typeOfList.MeterTelereleve).map(field => {
      if (field === 'readData.date') {
        return {
          dataField: field,
          text: t(translate('fr', 'meterTelereleve', field, locale)),
          classes: 'crystalList-column',
          sort: true,
          formatter: data => formatColumn(field, data),
          filter: _.get(selectOptions, field)
            ? selectFilter({
                options: _.get(selectOptions, field),
                placeholder: t('all.text.all_plural').toUpperCase(),
                onFilter: value => {
                  const cloneFilter = _.cloneDeep(filter);
                  cloneFilter[field] = value;
                  cloneFilter.current = field;
                  if (cloneFilter[field] !== filter[field]) {
                    setFilter(cloneFilter);
                  }
                },
                defaultValue: filter[field],
              })
            : textFilter({ placeholder: t(translate('fr', 'meterTelereleve', field, locale)) }),
          filterRenderer: (onFilter, column) => <CustomInput onFilter={onFilter} column={column} />,
          headerStyle: () => ({ width: '160px' }),
        };
      }
      return {
        dataField: field,
        text: t(translate('fr', 'meterTelereleve', field, locale)),
        classes: 'crystalList-column',
        sort: true,
        formatter: (data, row) => data && formatColumn(field, data, row),
        filter: _.get(selectOptions, field)
          ? selectFilter({
              options: _.get(selectOptions, field),
              placeholder: t('all.text.all_plural').toUpperCase(),
              defaultValue: filter[field],
              className: `filter-${field}`,
              onFilter:
                field === 'readData.alarm'
                  ? (filterValue, rows) => {
                      const cloneFilter = _.cloneDeep(filter);
                      cloneFilter[field] = filterValue;
                      cloneFilter.current = field;
                      if (cloneFilter[field] !== filter[field]) {
                        setFilter(cloneFilter);
                      }
                      return filterValue
                        ? rows.filter(
                            el =>
                              _.get(el, 'readData.alarm') &&
                              _.entries(el.readData.alarm).find(read => read[0] === filterValue)
                          )
                        : rows;
                    }
                  : (filterValue, rows) => {
                      const cloneFilter = _.cloneDeep(filter);
                      cloneFilter[field] = filterValue;
                      cloneFilter.current = field;
                      if (cloneFilter[field] !== filter[field]) {
                        setFilter(cloneFilter);
                      }
                      return filterValue ? rows.filter(el => _.get(el, field) === filterValue) : rows;
                    },
            })
          : textFilter({
              placeholder: t(translate('fr', 'meterTelereleve', field, locale)),
              onFilter: value => {
                const cloneFilter = _.cloneDeep(filter);
                cloneFilter[field] = value;
                cloneFilter.current = field;
                if (cloneFilter[field] !== filter[field]) {
                  setFilter(cloneFilter);
                }
              },
              defaultValue: filter[field],
              className: `filter-${field}`,
            }),
        headerStyle: () => ({
          width:
            field === 'readData.alarm'
              ? '200px'
              : field === 'address'
              ? '250px'
              : field === 'meterModel' || field === 'readData.index'
              ? '110px'
              : field === 'meterSerial'
              ? '30ch'
              : field === 'radioSerial'
              ? '25ch'
              : '145px',
        }),
      };
    });
  };

  const formatColumn = (key, data, row) => {
    switch (key) {
      case 'readData.method':
        return t(`all.read_meter.type.${data}`);
      case 'readData.index':
      case 'readData.consumption':
        return ListTools.formatterIndex(data, row);
      case 'readData.date':
        return data && moment.utc(data).format(`DD/MM/YYYY`);
      case 'readData.alarm':
        const dataTranslated = data && _.keys(data).map(el => t(`alarm.type.${el.toLowerCase()}`));
        return dataTranslated && dataTranslated.join(', ');
      default:
        return data;
    }
  };

  const displayAlarmInfo = () => {
    return (
      <Row style={{ margin: 0, paddingTop: '15px' }}>
        <Col
          md={1}
          style={{
            padding: 0,
            textAlign: 'center',
          }}
        >
          {getIcon('alarmType', '2em')}
        </Col>
        <Col md={11}>
          <span className="infoLibelle">{t('all.alarm_meter.alarm')}</span>
          <br />
          {selectedMeter.readData && selectedMeter.readData.alarm && _.size(selectedMeter.readData.alarm) > 0 ? (
            <Row style={{ margin: 0, marginTop: '5px' }}>
              {_.entries(selectedMeter.readData.alarm).map(el => (
                <>
                  <Col
                    md={1}
                    sm={3}
                    id={`alarm-${el[0].replaceAll('.', '-')}`}
                    style={{ padding: 0, textAlign: 'center', marginRight: 10 }}
                  >
                    {PictoAlarm(el[1].split('.')[0], {
                      height: '2em',
                      width: '2em',
                      // eslint-disable-next-line no-nested-ternary
                      stroke:
                        el[0].includes('poc') || el[0].includes('past')
                          ? '#808080'
                          : el[0].includes('current')
                          ? '#CF7830'
                          : '#ffffff',
                      fill:
                        el[0].includes('poc') || el[0].includes('past')
                          ? '#808080'
                          : el[0].includes('current')
                          ? '#CF7830'
                          : '#ffffff',
                      strokeWidth: '0',
                    })}
                    <UncontrolledTooltip placement="bottom" target={`alarm-${el[0].replaceAll('.', '-')}`}>
                      {' '}
                      {t(`alarm.type.${el[0].toLowerCase()}`)}
                    </UncontrolledTooltip>
                  </Col>
                </>
              ))}
            </Row>
          ) : (
            <span className="infoDisplay noFilled">{t('all.text.none_female')}</span>
          )}
        </Col>
      </Row>
    );
  };

  const disabledCheckbox = code => {
    if (code === 'withAlarm' && _.get(remoteReading, 'data.nbReadMeterWithAlarm') === 0) {
      return true;
    }
    if (code === 'withoutAlarm' && _.get(remoteReading, 'data.nbReadMeter') === 0) {
      return true;
    }
    if (code.length === 1 && !selectedBox.withoutAlarm && !selectedBox.withAlarm) {
      return true;
    }
    return false;
  };

  const readMethodName = rm => {
    switch (rm) {
      case 'A':
        return t('all.read_meter.type.A');
      case 'T':
        return t('all.read_meter.type.T');
      case 'M':
        return t('all.read_meter.type.M');
      default:
        return 'unknown';
    }
  };

  const dataFormater = type => {
    let data = [];
    if (_.get(remoteReading, 'data')) {
      switch (type) {
        case 'read':
          data.push({
            color: getColor('read'),
            data: remoteReading.data.nbReadMeter,
            hoverColor: '#ced4da',
            label: t('remotereading.text.read'),
          });
          data.push({
            color: getColor('notRead'),
            data: remoteReading.data.meters.length - remoteReading.data.nbReadMeter,
            hoverColor: '#ced4da',
            label: t('remotereading.text.not_read'),
          });
          break;
        case 'withAlarm':
          if (remoteReading.data.nbReadMeter > 0) {
            data.push({
              color: getColor('withAlarm'),
              data: remoteReading.data.nbReadMeterWithAlarm,
              hoverColor: '#ced4da',
              label: t('remotereading.text.read_with_alarm'),
            });
            data.push({
              color: getColor('withoutAlarm'),
              data: remoteReading.data.nbReadMeter,
              hoverColor: '#ced4da',
              label: t('remotereading.text.read_with_no_alarm'),
            });
          }
          break;
        case 'read_mode':
          data = remoteReading.data.readMethod.map(el => ({
            color: getColor(el.name),
            data: el.cnt,
            hoverColor: '#ced4da',
            label: readMethodName(el.name),
          }));
          break;
        case 'model':
          data = remoteReading.data.meterModel.map(el => ({
            color: getColor(el.name),
            data: el.cnt,
            hoverColor: '#ced4da',
            label: el.name,
          }));
          break;
        case 'alarmType':
          data = remoteReading.data.alarmType.map(el => ({
            color: getColor(el.name),
            data: el.cnt,
            hoverColor: '#ced4da',
            label: t(`alarm.type.${el.name.toLowerCase()}`),
          }));
          break;
        case 'updatedMeter':
          data.push({
            color: getColor('read'),
            data: remoteReading.data.updatedMeter.length,
            hoverColor: '#ced4da',
            label: t('all.text.modified_plural'),
          });
          data.push({
            color: getColor('notRead'),
            data: remoteReading.data.meters.length - remoteReading.data.updatedMeter.length,
            hoverColor: '#ced4da',
            label: t('all.text.not_modified_plural'),
          });
          break;
        default:
          data = remoteReading.data.readMethod.map(el => ({
            color: getColor(el.name),
            data: el.cnt,
            hoverColor: '#ced4da',
            label: el.name,
          }));
          break;
      }
    }
    return data;
  };

  const getTradFromFile = lang => {
    switch (lang) {
      case 'en':
        return en;
      case 'es':
        return es;
      default:
        return fr;
    }
  };

  const handleChartClick = (event, element, type) => {
    if (element && element.length > 0) {
      const { _model } = element[0];
      const { label } = _model;
      if (type !== 'alarmType' && label !== 'Autres') {
        const newBox = _.cloneDeep(initSelectedBox(_.get(remoteReading, 'genericData.meterModel')));
        switch (label) {
          case t('remotereading.text.read'):
            newBox.withAlarm = true;
            newBox.withoutAlarm = true;
            newBox.A = true;
            newBox.M = true;
            newBox.T = true;
            break;
          case t('remotereading.text.not_read'):
            newBox.notRead = true;
            break;
          case t('all.alarm_meter.alarm'):
            newBox.withAlarm = true;
            newBox.A = true;
            newBox.M = true;
            newBox.T = true;
            break;
          case t('remotereading.text.without_alarm'):
            newBox.withoutAlarm = true;
            newBox.A = true;
            newBox.M = true;
            newBox.T = true;
            break;
          case t('all.read_meter.type.A'):
            newBox.A = true;
            newBox.M = false;
            newBox.T = false;
            newBox.withAlarm = true;
            newBox.withoutAlarm = true;
            break;
          case t('all.read_meter.type.T'):
            newBox.T = true;
            newBox.A = false;
            newBox.M = false;
            newBox.withAlarm = true;
            newBox.withoutAlarm = true;
            break;
          case t('all.read_meter.type.M'):
            newBox.M = true;
            newBox.T = false;
            newBox.A = false;
            newBox.withAlarm = true;
            newBox.withoutAlarm = true;
            break;
          default:
            if (_.get(remoteReading, 'genericData.meterModel')) {
              remoteReading.genericData.meterModel.forEach(el => {
                if (el !== label) {
                  newBox[el.toLowerCase()] = false;
                }
              });
            }
            newBox[label.toLowerCase()] = true;
            newBox.notRead = true;
            newBox.withAlarm = true;
            newBox.withoutAlarm = true;
            newBox.M = true;
            newBox.T = true;
            newBox.A = true;
            break;
        }
        setSelectedBox(newBox);
      } else if (type === 'alarmType') {
        const language = localStorage.getItem('language');
        const file = language && getTradFromFile(language);
        const { translation } = file;
        if (translation) {
          let key = _.entries(translation).find(el => el[1] === label);
          if (key) {
            const copy = _.cloneDeep(selectedBox);
            key = key[0].slice(11);
            copy.alarmType = key;
            copy.withAlarm = true;
            copy.withoutAlarm = false;
            copy.notRead = false;
            copy.A = true;
            copy.M = true;
            copy.T = true;
            setSelectedBox(copy);
          }
        }
      }
    }
    document.getElementById('map-container-r').scrollIntoView();
  };

  const handleSelect = isAll => {
    const selectedClone = _.cloneDeep(initSelectedBox(_.get(remoteReading, 'genericData.meterModel')));
    selectedClone.notRead = true;

    if (isAll) {
      selectedClone.withAlarm = true;
      selectedClone.withoutAlarm = true;
      selectedClone.A = true;
      selectedClone.M = true;
      selectedClone.T = true;
    }

    setSelectedBox(selectedClone);
  };

  const generateChartData = (type, labels, data, allData) => {
    const dataList = allData ? [data, allData - data] : data;
    const dataIsPresent = allData === undefined ? true : !(allData === 0 && data === 0);

    const result = labels.map((el, index) => {
      return {
        label: el,
        color: getColor(el),
        data: dataList[index],
        hoverColor: '#ced4da',
      };
    });

    return dataIsPresent ? result : [];
  };

  const getMeterModelProps = card => {
    const type = 'meterModel';
    const data =
      remoteReading &&
      remoteReading.data &&
      generateChartData(
        type,
        remoteReading.data.meterModel.map(el => el.name),
        remoteReading.data.meterModel.map(el => el.cnt)
      );

    const chartModel = {
      title: t('remotereading.text.read_sort_by_model'),
      data: data && data.length > 0 ? data : null,
      handleClick: (event, element) => handleChartClick(event, element, type),
    };

    return {
      pieChart: chartModel,
      barChart: chartModel,
      type,
      code: card.type,
    };
  };
  const getIcon = (type, size) => {
    const iconSize = size || '1em';
    const iconColor = '#31c6b3';

    switch (type) {
      case 'readMeter':
        return <SvgReadMeter height={iconSize} fill={iconColor} />;
      case 'readMethod':
        return <SvgReadMeterType height={iconSize} fill={iconColor} />;
      case 'alarmType':
        return <SvgMeterWithAlarm height={iconSize} fill={iconColor} />;
      case 'readDate':
        return <SvgCalendar height={iconSize} fill={iconColor} />;
      case 'index':
        return <SvgLastRead height={iconSize} fill={iconColor} />;
      case 'radioSerial':
        return <RadioVert height={iconSize} fill={iconColor} />;
      case 'meterSerial':
        return <SvgCompteurVert height={iconSize} fill={iconColor} />;
    }
  };

  const displayInfoMtr = (title, data, iconKey) => {
    let iconSize = '';
    switch (iconKey) {
      case 'index':
      case 'meterSerial':
        iconSize = '1.5em';
        break;
      default:
        iconSize = '2em';
        break;
    }
    return (
      <Row style={{ margin: 0, paddingTop: '15px' }}>
        <Col
          md={1}
          style={{
            padding: 0,
            textAlign: 'center',
          }}
        >
          {getIcon(iconKey, iconSize)}
        </Col>
        <Col md={11} style={{ position: 'relative' }}>
          <span className="infoLibelle">{title}</span>
          <br />
          <span className="infoDisplay">{data || data === 0 ? data : <NoFilled />}</span>
        </Col>
      </Row>
    );
  };

  const getAlarmProps = card => {
    const type = 'alarm';
    return {
      pieChart: {
        title: t('remotereading.text.meter_alarm'),
        data:
          remoteReading.data &&
          generateChartData(
            type,
            [t('all.alarm_meter.alarm'), t('remotereading.text.without_alarm')],
            remoteReading.data.nbReadMeterWithAlarm,
            remoteReading.data.nbReadMeter
          ),
        handleClick: (event, element) => handleChartClick(event, element, type),
      },
      barChart: {
        title: t('remotereading.text.meter_alarm_by_model'),
        data:
          remoteReading.data &&
          generateChartData(
            'alarmMeterModel',
            remoteReading.data.alarmType
              .sort((a, b) => b.cnt - a.cnt)
              .map(el => t(`alarm.type.${el.name.toLowerCase()}`)),
            remoteReading.data.alarmType.sort((a, b) => b.cnt - a.cnt).map(el => el.cnt)
          ),
        extra: card.type === 'alarmType' ? (remoteReading.data && remoteReading.data.alarmType) || [] : null,
        handleClick: (event, element) => handleChartClick(event, element, card.type),
      },
      type,
      code: card.type,
    };
  };

  const handleFicheMeter = () => {
    let toPath = '';
    if (history.location.pathname.includes('/synchronisation/tournees')) {
      toPath = `${history.location.pathname}/fiche/${selectedMeter.ficheId}`;
    } else {
      toPath = `${history.location.pathname.match(/\/locations\/\d+/)[0]}/pdi/${selectedMeter.pdiId}`;
    }
    history.push({
      pathname: toPath,
    });
  };

  const handleExport = () => {
    const mappedBox = _.entries(selectedBox).filter(el => el[1]);
    const noManufacturerField = ['withAlarm', 'withoutAlarm', 'notRead', 'A', 'M', 'T', 'alarmType'];
    const manufacturer = mappedBox
      .filter(el => !noManufacturerField.includes(el[0]))
      .filter(el => el[1])
      .map(el => el[0]);
    let ruleList = _.cloneDeep(mappedBox).map(el => el[0]);
    if (selectedBox.withAlarm && selectedBox.withoutAlarm) {
      ruleList = ruleList.filter(el => el !== 'withAlarm' && el !== 'withoutAlarm');
      ruleList.push({ value: 'read', extra: moment(startDate).format('YYYY-MM-DD') });
    }
    if (selectedBox.A || selectedBox.M || selectedBox.T) {
      ruleList = ruleList.filter(el => el !== 'A' && el !== 'M' && el !== 'T');
      ruleList.push({ value: 'readMethod', extra: selectedBox });
    }
    if (selectedBox.notRead) {
      ruleList = ruleList.filter(el => el !== 'notRead');
      ruleList.push({ value: 'notRead', extra: moment(startDate).format('YYYY-MM-DD') });
    }
    if (manufacturer.length > 0) {
      ruleList = ruleList.filter(el => !manufacturer.includes(el));
      ruleList.push({ value: 'manufacturer', extra: manufacturer });
    }
    if (selectedBox.alarmType) {
      ruleList = ruleList.filter(el => el !== 'alarmType' && el !== 'withAlarm');
      ruleList.push({ value: 'alarmType', extra: selectedBox });
    }
    let mappedRuleList = ruleList.map(el => ruleListConverter(el.value || el, el.extra));
    if (mappedRuleList.find(el => Array.isArray(el))) {
      mappedRuleList = mappedRuleList.flat();
    }

    const fastFilters = {};
    _.entries().map(it => (fastFilters[it[0]] = it[1].filterVal));
    const trads = {
      alarms: _.get(locales, 'locale.fr.alarmType'),
    };
    for (const optionsFilterListKey in OptionsFilterList) {
      trads[optionsFilterListKey.replace('.', '')] = OptionsFilterList[optionsFilterListKey];
    }
    dispatch(
      pdiActions.exportPdiCsv(
        locationId,
        mappedRuleList.filter(el => el),
        {},
        fastFilters,
        exportableFields,
        trads,
        false
      )
    );
  };

  const cardProps = {
    flipList,
    callBack: handleFlip,
    remoteReading,
    handleClick: handleChartClick,
  };

  const exportProcessing =
    customexport.export_processing || pdis.loadingFilters || _.get(pdis, 'processing') || _.get(pdis, 'processingDat');

  return (
    <div className="remote-reading-container">
      {remoteReading && !remoteReading.genericData && <Loading />}
      {remoteReading && remoteReading.genericData && remoteReading.genericData.allMeter.length > 0 ? (
        <>
          <RemoteDate handleChange={handleDateChange} isRound={isRound} startReleveDate={startReleveDate} t={t} />
          <div className={'cards-wrapper'}>
            <div className="cards-container">
              {cardDisplayer(t).map((card, index) => {
                if (index === 4 || index === 5) {
                  return (
                    <div className="flip-card-M flip">
                      <FlipChartRemoteReading
                        {...(card.type === 'model' ? getMeterModelProps(card) : getAlarmProps(card))}
                      />
                    </div>
                  );
                }
                return (
                  <div className={`flip ${flipList.includes(card.type) ? 'back' : 'front'}`}>
                    <Card card={card} index={index} data={dataFormater(card.type)} t={t} {...cardProps} />
                    <Card card={card} index={index} data={dataFormater(card.type)} t={t} back {...cardProps} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="remote-map-container">
            <div className="header-o">
              <div className="flex-box-t">
                <div className="title-o">
                  {side === 'front' ? <SvgMap height="2em" fill="#31c6b3" /> : <SvgList height="2em" fill="#31c6b3" />}
                  <h2>{side === 'back' ? t('remotereading.text.list_view') : t('remotereading.text.map_view')}</h2>
                </div>
                {side === 'front' ? (
                  <Button onClick={() => setSide('back')}>
                    <SvgList height="1.5rem" fill="#31c6b3" style={{ position: 'relative', top: '14%' }} />
                  </Button>
                ) : (
                  <Button onClick={() => setSide('front')}>
                    <SvgMap height="1.5rem" fill="#31c6b3" style={{ position: 'relative', top: '14%' }} />
                  </Button>
                )}
              </div>
              <div id="remotetable-export">
                <span style={{ marginRight: 10, fontSize: '15 px', fontWeight: 'bold' }}>
                  {t('all.text.export_to')} :
                </span>
                <Button
                  outline
                  color="secondary"
                  style={{ marginBottom: '10px', marginRight: '10px' }}
                  onClick={handleExport}
                  disabled={exportProcessing ? 'disabled' : ''}
                >
                  {_.get(pdis, 'processing') && <Spinner size="sm" />} CSV
                </Button>
              </div>
              <div className="selection-t">
                <span onClick={() => handleSelect(true)}>{t('all.text.select_all')}</span>
                <span onClick={() => handleSelect(false)}>{t('remotereading.text.select_default')}</span>
              </div>
              <div className="all-options">
                {checkBoxList.map((el, index) => (
                  <div className="options-row">
                    <span className="title-s">{el[0]}* :</span>
                    <div className="flex-checkbox">
                      {el[1].map(checkbox => (
                        <div className={`checkbox-container ${checkbox.code}`}>
                          <Label>
                            <Input
                              type="checkbox"
                              id={checkbox.code}
                              checked={selectedBox[checkbox.code]}
                              disabled={disabledCheckbox(checkbox.code)}
                              onChange={e => handleCheckbox(e, checkbox.code)}
                            />
                            {checkbox.title}
                          </Label>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="multiple-choice">*{t('remotereading.text.multi_choices_allow')}</div>
              <div className="relative" style={{ position: 'relative', height: '85vh' }}>
                {remoteReading && remoteReading.genericData && maps.markers && (
                  <BandInfo
                    nbrTotal={remoteReading.genericData.allMeter.length}
                    nbrSelect={fullData}
                    nbrGeoloc={maps.markers.length}
                    t={t}
                  />
                )}

                <div
                  className="remote-map-container-b"
                  id="map-container-r"
                  style={{
                    opacity: side === 'front' ? 1 : 0,
                    top: '60px',
                    left: 0,
                    display: side === 'front' ? 'block' : 'none',
                    width: '100%',
                    pointerEvents: side === 'front' ? 'all' : 'none',
                  }}
                >
                  {_.get(remoteReading, 'data.gpsMeters[0].gps.lng') && (
                    <Mapbox
                      initialMap={{
                        baseLng: _.get(remoteReading, 'data.gpsMeters[0].gps.lng'),
                        baseLat: _.get(remoteReading, 'data.gpsMeters[0].gps.lat'),
                        baseZoom: 10,
                      }}
                      displayPopup
                      displayBandePdi
                      style={{ width: '100%', height: '75vh' }}
                      setSelectedMeter={setSelectedMeter}
                      selectedMeter={selectedMeter}
                    />
                  )}
                </div>
                <div
                  style={{
                    height: '100%',
                    flexDirection: 'row',
                    display: side === 'front' ? 'none' : 'flex',
                    marginTop: '20px',
                    opacity: side === 'front' ? 0 : 1,
                    pointerEvents: side === 'front' ? 'none' : 'all',
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      maxWidth: selectedMeter ? 'calc(100% - 315px)' : '100%',
                      overflow: 'auto',
                      maxHeight: '75vh',
                    }}
                  >
                    {_.get(remoteReading, 'data.meters') && (
                      <BootstrapTable
                        id="pdi-tab tableRemoteReadingMeter"
                        keyField="id"
                        style={{ height: '100%' }}
                        data={filterGpsMeterWithOptionsToFillMarker(
                          selectedBox,
                          remoteReading.data.meters.filter(el => el !== undefined),
                          startDate,
                          _.get(remoteReading, 'genericData.meterModel')
                        )}
                        filter={filterFactory()}
                        key={Math.floor(Math.random() * Math.floor(1024))}
                        rowEvents={{
                          onClick: (e, row, rowIndex) => {
                            setSelectedMeter(row);
                          },
                        }}
                        rowStyle={(row, rowIndex) => {
                          return {
                            backgroundColor: row.id === (selectedMeter && selectedMeter.id) ? '#31c6b3' : 'white',
                          };
                        }}
                        columns={getColumns()}
                        bootstrap4
                        bordered={false}
                        hover
                        headerClasses="crystalList-column"
                        rowClasses="clickable"
                        pagination={
                          remoteReading.data.meters.length > 15
                            ? paginationFactory({
                                custom: false,
                                sizePerPage: 15,
                                sizePerPageList: [],
                                page: currentTablePage,
                                onPageChange: page => setCurrentTablePage(page),
                              })
                            : null
                        }
                      />
                    )}
                  </div>
                  {selectedMeter && (
                    <div
                      className="table-info-container selectedMeterInfoFromTable"
                      style={{
                        height: '100%',
                        position: 'relative',
                        paddingBottom: '15px',
                        marginLeft: '15px',
                        width: '300px',
                      }}
                    >
                      <h2>{t('columns.meter.mtrInformations')}</h2>
                      <>
                        <div
                          className="close-infomap-container"
                          style={{
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '5px',
                            height: '25px',
                            width: '25px',
                            cursor: 'pointer',
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                          }}
                          onClick={() => setSelectedMeter(null)}
                        >
                          <SvgCroixNoir height="1.7em" fill="#000" />
                        </div>
                        {displayInfoMtr(
                          t('all.meter.meter_serial'),
                          selectedMeter.meterSerial ? selectedMeter.meterSerial : null,
                          'meterSerial'
                        )}
                        {displayInfoMtr(
                          t('all.meter.radio_serial'),
                          selectedMeter.radioSerial ? selectedMeter.radioSerial : null,
                          'radioSerial'
                        )}
                        {displayInfoMtr(
                          t('all.read_meter.read_last_date'),
                          _.size(selectedMeter.readData) > 0
                            ? moment(selectedMeter.readData.date).format(t('all.date_format.date'))
                            : null,
                          'readDate'
                        )}
                        {displayInfoMtr(
                          t('all.read_meter.read_method'),
                          selectedMeter.readData ? t(`all.read_meter.type.${selectedMeter.readData.method}`) : null,
                          'readMethod'
                        )}
                        {displayInfoMtr(
                          t('all.meter.index'),
                          _.size(selectedMeter.readData) > 0 ? (
                            <DigitRoll
                              num={parseInt(selectedMeter.readData.index, 10)}
                              length={selectedMeter.wheels || 5}
                              divider=""
                            />
                          ) : null,
                          'index'
                        )}
                        {displayInfoMtr(
                          t('all.read_meter.cons'),
                          _.size(selectedMeter.readData) > 0
                            ? ListTools.formatterIndex(selectedMeter.readData.consumption, selectedMeter)
                            : null,
                          'cons'
                        )}
                        {displayAlarmInfo()}
                      </>

                      <Button
                        id="goToPdiButtonFromTable"
                        className="absoluteHorizontalCenter"
                        style={{ marginTop: '10px', position: 'relative', margin: 'auto' }}
                        color="primary"
                        onClick={() => handleFicheMeter()}
                      >
                        <SvgPdiVert height="1.5em" fill="white" style={{ margin: 0 }} />
                        <span
                          style={{
                            paddingLeft: '4px',
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '1.12em',
                          }}
                        >
                          {t('all.pdi.pdi')}
                        </span>
                        <UncontrolledTooltip placement="bottom" target="goToPdiButtonFromTable">
                          {t('all.pdi.go_to_pdi')}
                        </UncontrolledTooltip>
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        remoteReading &&
        remoteReading.genericData && (
          <div style={{ height: '500px', position: 'relative' }}>
            <SvgEmptyMeter
              className="absoluteCentered"
              height="500px"
              width="500px"
              fill="#6c757d"
              stroke="#6c757d"
              opacity="0.1"
              strokeWidth="1"
            />
            <div className="absoluteHorizontalCenter" style={{ fontSize: '40px', fontWeight: 'bold' }}>
              {t('remotereading.text.no_meter_link_to')} {isRound ? 'cette tournee' : 'ce site'}
            </div>
            <div style={{ textAlign: 'center' }}>
              <Button
                color="primary"
                style={{ marginTop: '100px', fontSize: '1.5em', color: 'white', borderRadius: '50px' }}
                onClick={() => history.push({ pathname: `${history.location.pathname}/pdi/link` })}
              >
                {t('remotereading.text.link_meter_now')}
              </Button>
            </div>
          </div>
        )
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { authentication, remoteReading, mask, maps, locations, locales, pdis, customexport } = state;
  const { user } = authentication;
  return {
    user,
    remoteReading,
    mask,
    maps,
    locations,
    locales,
    pdis,
    customexport,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(RemoteReadingDataBetter)));
