import React, { Component } from 'react';
import { Input } from 'reactstrap';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

interface Props {
  isHour?: boolean;
  handleChangePeriode: Function;
  handleHourInterval?: Function | any;
  alarmTemplates: any;
  t: Function;
}

const periodList = [
  { value: 'Day', label: 'Day' },
  { value: 'Week', label: 'Week' },
  { value: 'Month', label: 'Month' },
  { value: 'Year', label: 'Year' },
];

const hour = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];

class PeriodSelector extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    const index = _.get(props, 'alarmTemplates.content')
      ? props.alarmTemplates.content.dataSourceProperty.condition.findIndex((el: any) => el.conditionTitle === 'Period')
      : null;
    const indexHour = props.alarmTemplates.content.dataSourceProperty.condition.findIndex(
      (el: any) => el.conditionTitle === 'HourPeriod'
    );
    this.state = {
      period: {
        value: _.get(props, `alarmTemplates.content.dataSourceProperty.condition[${index}].conditionValue.value`)
          ? props.alarmTemplates.content.dataSourceProperty.condition[index].conditionValue.value
          : '1',
        name: _.get(props, `alarmTemplates.content.dataSourceProperty.condition[${index}].conditionValue.name`)
          ? props.alarmTemplates.content.dataSourceProperty.condition[index].conditionValue.name
          : 'Month',
      },
      interval: {
        min: _.get(props, `alarmTemplates.content.dataSourceProperty.condition[${indexHour}].conditionValue.min`)
          ? props.alarmTemplates.content.dataSourceProperty.condition[indexHour].conditionValue.min
          : 0,
        max: _.get(props, `alarmTemplates.content.dataSourceProperty.condition[${indexHour}].conditionValue.max`)
          ? props.alarmTemplates.content.dataSourceProperty.condition[indexHour].conditionValue.max
          : 1,
      },
    };
  }

  handleChangeP = (e: any, type: string) => {
    const { handleChangePeriode } = this.props;
    const { period } = this.state;
    const newPeriod = period;
    if (_.get(e, 'target.value')) {
      if (type === 'nbr') {
        newPeriod.value = e.target.value;
      } else {
        const period = periodList.find(el => el.label === e.target.value);
        if (period) {
          newPeriod.name = period.value;
        }
      }
      this.setState({
        period: newPeriod,
      });
      handleChangePeriode(newPeriod);
    }
  };

  handleChangeI = (e: any, type: string) => {
    const { handleHourInterval } = this.props;
    const { interval } = this.state;
    const newInterval = interval;
    if (type === 'h1') {
      newInterval.min = parseInt(e.target.value);
    } else {
      newInterval.max = parseInt(e.target.value);
    }
    this.setState({
      interval: newInterval,
    });
    handleHourInterval(newInterval);
  };

  render() {
    const { isHour, t } = this.props;
    const { period, interval } = this.state;
    const defaultValue: any = periodList.find(el => el.value === period.name);
    const reg = '/^d+$/';
    return (
      <div className="wrapper">
        <div className="period">
          <h5>{t('period_selector.text.trigger_period')}</h5>
          <div className="flex-box">
            <Input
              style={{ marginRight: '5%' }}
              defaultValue={period.value}
              onChange={e => this.handleChangeP(e, 'nbr')}
            />
            <Input
              type="select"
              defaultValue={defaultValue.label}
              name="select"
              id="exampleSelect"
              onChange={e => this.handleChangeP(e, 'type')}
            >
              {periodList.map(el => (
                <option value={el.value}>{t(`best_date_component.zoom_option.${el.label.toLowerCase()}`)}</option>
              ))}
            </Input>
          </div>
        </div>
        {isHour && (
          <div className="period-selector wrapper">
            <h5>{t('period_selector.text.schedule_interval')}</h5>
            <div className="flex-box">
              <Input
                type="select"
                name="select"
                id="exampleSelect"
                style={{ marginRight: '5%' }}
                onChange={e => this.handleChangeI(e, 'h1')}
                defaultValue={interval.min}
                valid={interval.min !== interval.max}
                invalid={interval.min === interval.max}
              >
                {hour.map(el => (
                  <option>{el}</option>
                ))}
              </Input>
              <Input
                style={{ backgroundImage: 'none' }}
                type="select"
                name="select"
                id="exampleSelect"
                onChange={e => this.handleChangeI(e, 'h2')}
                defaultValue={interval.max}
                valid={interval.min !== interval.max}
                invalid={interval.min === interval.max}
              >
                {hour.map(el => (
                  <option>{el}</option>
                ))}
              </Input>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const tr = withTranslation()(PeriodSelector);
export default tr;
