import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import { Button, Col, ListGroup, ListGroupItem, Row, UncontrolledTooltip } from 'reactstrap';
import _ from 'lodash';
import { Fade } from 'react-awesome-reveal';
import Autosuggest from 'react-autosuggest';
import { locationActions, meterActions, tourneeActions, userActions } from '../_actions';
import PointCollecteVert from '../SvgComponents/PointCollecteVert';
import { locations } from '../_interfaces/reducers';
import LocationCreator from '../LocationCreator';
import ErrorBand from '../Bands/Error';
import { Location } from '../_entities/location';
import NbCompteurVert from '../SvgComponents/NbCompteurVert';
import NbRadioVert from '../SvgComponents/RadioVert';
import AdresseVert from '../SvgComponents/AdresseVert';
import SvgLoupeBleu from '../SvgComponents/LoupeBleu';
import Crumb from '../_components/Crumb';
import NoFilled from '../Message/NoFilled';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Pie } from 'react-chartjs-2';
import { urlGiveAway } from '../_helpers/statistique-helper';
import SvgTourneesVertes from '../SvgComponents/TourneesVertes';
import FusionTournees from '../SvgComponents/FusionTournees';
import Loading from '../_animations/Loading';
import GoogleMapReactC from '../GoogleMap/GoogleMapReactC';
import { withTranslation } from 'react-i18next';
import Mapbox from '../Mapbox/components/Mapbox';
import SvgCapteur from '../SvgComponents/Capteur';

interface Props {
  dispatch: Function;
  locations: locations;
  history: Array<string>;
  user: any;
  meters: { items: Array<Object> };
  location: any;
  t: any;
}

interface State {
  dropdownOpen: boolean;
  locations: any;
  breadcrumb: Array<Location>;
  value: string;
  suggestions: Array<Location>;
  init: boolean;
  filter: any;
}

/**
 * @class LocationsComponent
 * @extends {React.Component<Props>}
 */
class LocationsComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.locationContent = this.locationContent.bind(this);
    this.handleBreadcrumb = this.handleBreadcrumb.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.generateReverseBreadcrumb = this.generateReverseBreadcrumb.bind(this);
    this.getRoot = this.getRoot.bind(this);
    this.renderInputComponent = this.renderInputComponent.bind(this);
    this.createListOfLocation = this.createListOfLocation.bind(this);

    this.state = {
      dropdownOpen: false,
      locations: undefined,
      breadcrumb: [],
      value: '',
      suggestions: [],
      init: false,
      filter: '',
    };
  }

  static getDerivedStateFromProps(props: any, state: any) {
    const { locations } = props;
    if (locations && locations.items) {
      return {
        locations: locations.items.filter(loc => loc.code !== 'STOCK'),
      };
    }
    return null;
  }

  toggle = () => {
    const { dropdownOpen } = this.state;
    this.setState({
      dropdownOpen: !dropdownOpen,
    });
  };

  /**
   * Récupère les sites et contacts au montage
   * du composant
   *
   * @method componentDidMount
   * @memberof LocationsComponent
   */
  componentDidMount() {
    const { dispatch, user, match, location, locations } = this.props;
    dispatch(userActions.get(user.id));
    dispatch(locationActions.getContacts());
    dispatch(locationActions.getAll(location.search.replace('?root=', '')));
    if (location.search.replace('?root=', '') !== 'STOCK') {
      dispatch(tourneeActions.getAllInfo());
      dispatch(tourneeActions.getAllTourneeInfos());
    }
    animateScroll.scrollToTop({ duration: 0 });
  }

  getRoot() {
    const { location } = this.props;
    return location.search.replace('?root=', '');
  }

  componentDidUpdate() {
    const { locations, history } = this.props;
    if (locations && locations.savedLocation && locations.savedLocation.id) {
      history.push(`/locations/${locations.savedLocation.id}`);
    }
    const root: any = locations.items && locations.items.find(l => l.code === this.getRoot());

    const docRow: any = document.getElementById('rowid');
    if (docRow && root && root.name && root.name === 'TOURNEES') {
      const height = docRow.getBoundingClientRect().height;
      const maxHeight = height ? `${height}px` : '0';
      const listLocationDoc: any = document.getElementById('rowListLocation');
      if (docRow && listLocationDoc) {
        listLocationDoc.style.maxHeight = maxHeight;
        docRow.style.maxHeight = maxHeight;
      }
    }
  }

  getLocationInfo(locationId: number) {
    const { dispatch, meters, locations } = this.props;
    const { locations: locationsProps } = this.props;
    const root: any = locationsProps.items && locationsProps.items.find(l => l.code === this.getRoot());
    let isRound = false;
    if (root && root.name && root.name === 'TOURNEES') {
      isRound = true;
    }
  }

  /**
   * Vide le state redux des sites au démontage
   *
   * @method componentWillUnmount
   * @memberof LocationsComponent
   */
  componentWillUnmount() {
    const { dispatch }: any = this.props;
    dispatch(meterActions.clear());
  }

  handleClick(event: any, chart: any) {
    const { history, locations, t } = this.props;

    if (chart[0] && locations.fetchedLocation) {
      const urlOption = urlGiveAway(chart[0]._model.label, t);
      history.push({
        pathname: `/locations/${locations.fetchedLocation.id}/fiche`,
        search: urlOption,
      });
    }
  }

  generatePieDiagram(title: string, labels: Array<string>, data: Array<number>) {
    return (
      <div>
        <p style={{ margin: '10px 20px' }}>
          <span className="infoLibelle">{title}</span> <br />
          <span className="infoDisplay">{`${data[0]}/${data[0] + data[1]}`} </span>
        </p>
        <Pie
          options={{
            legend: {
              display: false,
            },
            plugins: {
              t1: false,
              datalabels: {
                display: false,
                color: 'white',
              },
            },
            onClick: (event: any, chart: any) => this.handleClick(event, chart, title),
          }}
          data={{
            labels,
            datasets: [
              {
                backgroundColor: ['#31c6b3'],
                hoverBackgroundColor: ['#26A653', '#F24141'],
                data,
              },
            ],
          }}
          height={100}
        />
      </div>
    );
  }

  locationContent() {
    const { users, t } = this.props;
    const { breadcrumb, locations } = this.state;
    const currentId = breadcrumb.length > 0 && _.last(breadcrumb).id;
    const currentLocation = locations.find(el => el.id === currentId);
    const access = users.fetchedUser.profils.find(el => el.locationCode === currentLocation.code)
      ? users.fetchedUser.profils.find(el => el.locationCode === currentLocation.code).profil.permissions.length > 0
      : this.isAdmin();
    return (
      <div className="height100">
        {currentLocation && (
          <div className="height100">
            <div className="locationsTitleInfo">
              <h4 className="text-center">{currentLocation.name}</h4>
            </div>
            <Col md="12" className="mapContainer">
              <Col id="locationListContainer">
                <Row>
                  <Col md="1">
                    <AdresseVert height="2em" width="2em" stroke="#31c6b3" fill="white" strokeWidth="1.4" />
                  </Col>
                  <Col md="11">
                    <p className="infoLibelle">{t('all.location.address')} </p>
                    <p className="infoDisplay">
                      {' '}
                      {currentLocation.content.address.street ? (
                        <span>
                          {currentLocation.content.address.streetNumber} {currentLocation.content.address.street}
                          {currentLocation.content.address.complement} <br />
                          {currentLocation.content.address.zipcode} {currentLocation.content.address.city} <br />
                          {currentLocation.content.address.country}
                        </span>
                      ) : (
                        <NoFilled />
                      )}
                    </p>
                  </Col>
                  {currentLocation.content.gpsPosition && (
                    <Col md="12">
                      <div id="map_locations" style={{ cursor: '' }}>
                        <Mapbox
                          initialMap={{
                            baseLng: _.get(currentLocation, 'content.gpsPosition.lng'),
                            baseLat: _.get(currentLocation, 'content.gpsPosition.lat'),
                            baseZoom: 15,
                          }}
                          style={{ width: '100%', height: '20vh' }}
                          fullscreen={false}
                          geolocation={false}
                          homeMarker
                        />
                      </div>
                    </Col>
                  )}
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col md="1">
                    <NbCompteurVert height="2em" width="2em" stroke="#31c6b3" fill="white" strokeWidth="1.5" />
                  </Col>
                  <Col md="5">
                    <span className="infoLibelle">{t('locations.text.total_nb_meter')} </span>
                    <p className="infoDisplay infoDisplayMeters">{currentLocation.nbMeters}</p>
                  </Col>
                  {currentLocation.nbSensors > 0 && (
                    <Col md="1">
                      <SvgCapteur height="2em" width="2em" stroke="#31c6b3" fill="white" strokeWidth="1.5" />
                    </Col>
                  )}
                  {currentLocation.nbSensors > 0 && (
                    <Col md="5">
                      <span className="infoLibelle">{t('locations.text.total_nb_sensor')} </span>
                      <p className="infoDisplay infoDisplayMeters">{currentLocation.nbSensors}</p>
                    </Col>
                  )}
                </Row>
                {breadcrumb.length > 0 && (
                  <>
                    <Button
                      id="access-button"
                      className="col-right footer-content"
                      onClick={() => this.goOnLocation(breadcrumb[breadcrumb.length - 1].id)}
                      disabled={!access}
                    >
                      {t('all.location_button.access_location')}
                    </Button>
                    {!access && (
                      <UncontrolledTooltip placement="bottom" target="access-button">
                        {' '}
                        {t('all.text.forbidden_access')}
                      </UncontrolledTooltip>
                    )}
                  </>
                )}
              </Col>
            </Col>
          </div>
        )}
      </div>
    );
  }

  roundContent(tourneeBis: any, isRound: boolean) {
    const { breadcrumb } = this.state;
    const { tournee, locations, t } = this.props;
    const foundTournee =
      tourneeBis.code === 'TOURNEES'
        ? tournee.allInfos
        : _.get(tournee, 'allTourneeInfos').find(el => el.code === tourneeBis.code);

    const allRoundId = locations.items.find((el: any) => el.code === 'TOURNEES')
      ? locations.items.find((el: any) => el.code === 'TOURNEES').id
      : 0;

    console.log(allRoundId, '');
    return (
      <div className="height100">
        {foundTournee && (
          <div className="height100">
            <div className="locationsTitleInfo">
              <h4 className="text-center">{t('all.round.round_plural')}</h4>
            </div>
            <Col md="12" className="mapContainer">
              <Col id="locationListContainer">
                <Row style={{ marginTop: '20px' }}>
                  <Col md="1">
                    <NbCompteurVert height="2em" width="2em" stroke="#31c6b3" fill="white" strokeWidth="1.5" />
                  </Col>
                  <Col md="11">
                    <span className="infoLibelle">{t('locations.text.total_nb_meter')}</span>
                    <p className="infoDisplay infoDisplayMeters">{foundTournee.totalCpt}</p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col md="1">
                    <NbRadioVert height="2em" width="2em" stroke="#31c6b3" fill="white" strokeWidth="1.5" />
                  </Col>
                  <Col md="11">
                    <span className="infoLibelle">{t('locations.text.total_nb_radio')}</span>
                    <p className="infoDisplay infoDisplayMeters">{foundTournee.totalRadio}</p>
                  </Col>
                </Row>
                <Row style={{ display: 'flex' }}>
                  <div style={{ width: '50%' }}>
                    {this.generatePieDiagram(
                      t('all.meter.indexed_meter_plural'),
                      [t('all.text.indexed_plural'), t('all.text.not_indexed_plural')],
                      [foundTournee.indexedMeters, foundTournee.totalCpt - foundTournee.indexedMeters]
                    )}
                  </div>
                  <div style={{ width: '50%' }}>
                    {this.generatePieDiagram(
                      t('all.meter.modified_meter_plural'),
                      [t('all.text.modified_plural'), t('all.text.not_modified_plural')],
                      [foundTournee.modifiedMeters, foundTournee.totalCpt - foundTournee.modifiedMeters]
                    )}
                  </div>
                </Row>
                <Button
                  className="col-right footer-content"
                  onClick={() =>
                    this.goOnLocation(
                      breadcrumb[breadcrumb.length - 1] ? breadcrumb[breadcrumb.length - 1].id : allRoundId
                    )
                  }
                >
                  {t('all.round.go_to_round')}
                </Button>
              </Col>
            </Col>
          </div>
        )}
      </div>
    );
  }

  goOnLocation(key: any) {
    const { history } = this.props;
    if (key) history.push(`/locations/${key}`);
  }

  handleBreadcrumb(locationSelected: any) {
    const { breadcrumb } = this.state;
    const { locations: locationsProps } = this.props;

    const position = _.findIndex(breadcrumb, { groupId: locationSelected.groupId });
    const root: any = locationsProps.items && locationsProps.items.find(l => l.code === this.getRoot());
    let isRound = false;
    if (root && root.name && root.name === 'TOURNEES') {
      isRound = true;
    }

    this.getLocationInfo(locationSelected.id);

    if (position != -1) {
      while (breadcrumb.length > position) {
        breadcrumb.pop();
      }
    }

    breadcrumb.push(locationSelected);
    if (!isRound) this.slider.slickGoTo(breadcrumb.length - 1);
    this.setState(
      {
        breadcrumb,
      },
      this.scrollOnY
    );
  }

  handleBack(positionToGo: number) {
    const { breadcrumb } = this.state;

    if (breadcrumb && breadcrumb.length > 0) {
      while (breadcrumb.length - 1 > positionToGo) {
        breadcrumb.pop();
      }

      this.setState({
        breadcrumb,
      });
    }
  }

  isAdmin = () => {
    const { user } = this.props;

    return ['DIOPTASE', 'SUPERADMIN', 'ADMIN'].includes(user.role.name);
  };

  createListOfLocation(groupId: number) {
    const { locations, filter, breadcrumb } = this.state;
    const { users, t } = this.props;
    const listLocations = _.filter(locations, { groupId }).filter((el: any) =>
      el.name.toLowerCase().includes(filter.toLowerCase())
    );
    const findParentLocation = _.find(locations, { id: groupId });
    const parentLocation = findParentLocation ? findParentLocation.code : 'STOCK';
    const locationCreatorProps = {
      fromLocation: groupId,
      padding: '5px',
    };
    const root: any =
      (this.props.locations.items && this.props.locations.items.find(l => l.code === this.getRoot())) || {};
    let isRound = false;
    if (root.name && root.name === 'TOURNEES') {
      isRound = true;
    }
    console.log(filter, listLocations);
    if (listLocations.length > 0) {
      return (
        <div style={{ position: 'relative', width: isRound ? '100%' : '33%' }}>
          <div
            className={`locationsList ${parentLocation}`}
            style={{ width: isRound ? '100%' : '', borderRight: isRound ? 'none' : '1px solid ligthgrey' }}
          >
            <ListGroup>
              {users &&
                users.fetchedUser &&
                listLocations.map((item: any, index: any) => {
                  const access = users.fetchedUser.profils.find(el => el.locationCode === item.code)
                    ? users.fetchedUser.profils.find(el => el.locationCode === item.code).profil.permissions.length > 0
                    : this.isAdmin();
                  return (
                    <ListGroupItem
                      className={item.code}
                      color={breadcrumb.length > 0 && breadcrumb.includes(item) ? 'primary' : 'initial'}
                      onClick={() => this.handleBreadcrumb(item)}
                      style={{
                        cursor: 'pointer',
                        borderBottom: index === listLocations.length - 1 ? '1px solid lightgrey' : 'none',
                      }}
                    >
                      <span
                        style={{
                          color: breadcrumb.length > 0 && breadcrumb.includes(item) ? '#2c303b' : '',
                          opacity: access || isRound ? 1 : 0.5,
                        }}
                      >
                        {item.name}
                        {_.find(locations, { groupId: item.id }) && (
                          <span style={{ position: 'absolute', right: '15px' }}>&#62;</span>
                        )}
                      </span>
                    </ListGroupItem>
                  );
                })}
            </ListGroup>
          </div>

          {breadcrumb && breadcrumb.length > 0 && breadcrumb[breadcrumb.length - 1].id === groupId && !isRound && (
            <span className="addLocationButton">
              <LocationCreator {...locationCreatorProps} />
            </span>
          )}
        </div>
      );
    }
    if (!isRound) {
      return (
        <div style={{ position: 'relative' }}>
          <div className={`locationsList ${parentLocation}`}>
            <p className="noChildLocation">{t('locations.text.no_sub_location')}</p>
          </div>
          <span className="addLocationButton">
            <LocationCreator {...locationCreatorProps} />
          </span>
        </div>
      );
    }
  }

  /**
   * Méthodes de l'Autosuggest
   * */

  onChange = (event: any, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected(event: any, { suggestion }) {
    this.generateReverseBreadcrumb(suggestion);

    // Vide la barre de recherche
    this.setState({
      value: '',
      filter: '',
    });
  }

  generateReverseBreadcrumb(location: Location) {
    const { locations } = this.state;
    const { locations: locationsProps } = this.props;
    const newReverseBcLocation = [];
    let lastGroupId: Number = location.id;
    let locationToBrowse = location;
    let isRound = false;
    const root: any = locationsProps.items && locationsProps.items.find(l => l.code === this.getRoot());
    if (root && root.name && root.name === 'TOURNEES') {
      isRound = true;
    }

    while (lastGroupId > 0) {
      newReverseBcLocation.push(locationToBrowse);
      lastGroupId = locationToBrowse.groupId;
      locationToBrowse = _.find(locations, { id: locationToBrowse.groupId });
    }

    if (!isRound) this.slider.slickGoTo(newReverseBcLocation.length - 1);

    this.setState(
      {
        breadcrumb: newReverseBcLocation.reverse(),
      },
      this.scrollOnY
    );
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions(value: string) {
    const { locations } = this.state;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    this.setState({
      inputValue,
    });

    return inputLength === 0
      ? []
      : locations.filter((loc: Location) => {
          if (!_.isEqual(loc.name, 'TOURNEES')) {
            return loc.name.toLowerCase().includes(inputValue);
          }
        });
  }

  getSuggestionValue(suggestion: Location) {
    return suggestion.name;
  }

  renderSuggestion(suggestion: Location) {
    return <div>{suggestion.name}</div>;
  }

  renderInputComponent(inputProps: any) {
    const { filter } = this.state;
    console.log(inputProps);
    const handleChange = (e: any) => {
      this.setState({ filter: e.target.value });
      inputProps.onChange(e);
    };

    return (
      <div className="inputContainer">
        <input {...inputProps} onChange={handleChange} />
        <SvgLoupeBleu className="icon" />
      </div>
    );
  }

  // Scroll
  scrollOnY() {
    const { breadcrumb, locations } = this.state;

    // Scroll down in list when element selected
    if (breadcrumb && breadcrumb.length > 0) {
      breadcrumb.map(item => {
        const containerName =
          item.groupId === 0
            ? 'STOCK'
            : _.find(locations, { id: item.groupId }) && _.find(locations, { id: item.groupId }).code;
        const itemName = item.code;

        if (itemName && containerName) {
          const listElement = document.getElementById('tourneeScroll');

          if (listElement) {
            const rowElements = listElement.getElementsByClassName(itemName);
            const rowElement = _.find(rowElements, { tagName: 'LI' });

            if (rowElement) {
              const containerHeight = listElement.getBoundingClientRect().y;
              const itemHeight = rowElement.getBoundingClientRect().y;

              listElement.scrollTo(rowElement.getBoundingClientRect().x, itemHeight - containerHeight);
            }
          }
        }
      });
    }
  }

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof LocationsComponent
   */

  render() {
    const { locations, breadcrumb, value, suggestions, filter } = this.state;
    const { locations: locationsProps, location, tournee, history, t }: any = this.props;

    const root: any = locationsProps.items && locationsProps.items.find(l => l.code === this.getRoot());
    let isRound = false;
    if (root && root.name && root.name === 'TOURNEES') {
      isRound = true;
    }
    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: isRound ? t('locations.placeholder.search_rounds') : t('locations.placeholder.search_location'),
      value,
      onChange: this.onChange,
    };

    // Slider settings
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      arrows: false,
      draggable: false,
      slidesToShow: 2,
      slidesToScroll: 1,
    };
    console.log(filter, suggestions);
    return (
      <>
        {locationsProps.loading && !locations && <Loading />}
        <div className="col-md-12">
          {this.props.locations.error && <ErrorBand message={this.props.locations.error} />}
          {locations && (
            <div className="table-info-container locationContainer">
              <h2>
                {isRound ? (
                  <>
                    <span>
                      <SvgTourneesVertes height="1em" width="1em" fill="#31c6b3" />
                    </span>
                    {t('all.round.round_plural')}
                  </>
                ) : (
                  <>
                    <span>
                      <PointCollecteVert height="1em" width="1em" fill="#31c6b3" />
                    </span>
                    {t('all.location.location_plural')} :
                    <span className="addWidget float-right">
                      {' '}
                      <LocationCreator />
                    </span>
                  </>
                )}
              </h2>
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
                renderInputComponent={this.renderInputComponent}
                onSuggestionSelected={this.onSuggestionSelected}
              />
              <p className="noLocationFoundError">
                {suggestions &&
                  suggestions.length === 0 &&
                  !_.isEmpty(value) &&
                  t('locations.error_msg.no_location_found')}
              </p>

              <Row id="rowid">
                <Col md="6" className="height100" style={{ height: isRound ? '100%' : '100%' }} id="height100">
                  <div className="locBrowser" style={{ overflow: 'hidden' }}>
                    {isRound ? (
                      <>
                        <div
                          className="clickable"
                          style={{ width: '100%', backgroundColor: breadcrumb.length > 0 ? 'white' : '#c5efea' }}
                          onClick={() => this.handleBack(-1)}
                        >
                          <h3 style={{ padding: '10px' }}>
                            <FusionTournees width="1em" height="1em" fill="#31c6b3" />
                            {t('locations.title.all_rounds')}
                          </h3>
                        </div>
                        <div style={{ height: '500px', overflowY: 'auto' }} id="tourneeScroll">
                          {this.createListOfLocation(root && root.id)}
                        </div>
                      </>
                    ) : (
                      <>
                        {breadcrumb && <Crumb paths={breadcrumb.map(item => item.name)} onClick={this.handleBack} />}
                        <div>
                          <Slider ref={slider => (this.slider = slider)} {...settings} style={{ height: '500px' }}>
                            {this.createListOfLocation(root && root.id)}
                            {breadcrumb && breadcrumb.map((item: any) => this.createListOfLocation(item.id))}
                            {!breadcrumb || (breadcrumb.length === 0 && <div className="locationsList" />)}
                          </Slider>
                          <div
                            style={{ height: '25px', backgroundColor: breadcrumb.length < 2 ? 'white' : '#e9ecef' }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </Col>
                {isRound ? (
                  <>
                    {breadcrumb && breadcrumb.length > 0 ? (
                      <Col md="6">
                        <Fade className="height100">
                          <div className="locationShowInfo height100" key={breadcrumb[breadcrumb.length - 1].name}>
                            {tournee &&
                              tournee.allTourneeInfos &&
                              this.roundContent(breadcrumb[breadcrumb.length - 1], true)}
                          </div>
                        </Fade>
                      </Col>
                    ) : (
                      <Col md="6">
                        <Fade className="height100">
                          <div className="locationShowInfo height100" key="tournees">
                            {tournee && tournee.allInfos && this.roundContent(tournee.allInfos, false)}
                          </div>
                        </Fade>
                      </Col>
                    )}
                  </>
                ) : (
                  <>
                    {breadcrumb && breadcrumb.length > 0 ? (
                      <Col md="6">
                        <Fade className="height100">
                          <div className="locationShowInfo height100" key={breadcrumb[breadcrumb.length - 1].name}>
                            {this.locationContent()}
                          </div>
                        </Fade>
                      </Col>
                    ) : (
                      <Col md="6">
                        <div className="locationNotSelected height100">
                          <p>{t('location_creator.text.no_location_selected')}</p>
                        </div>
                      </Col>
                    )}
                  </>
                )}
              </Row>
            </div>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, locations, dashboards, meters, contacts, permissions, tournee, users } = state;
  const { user } = authentication;
  return {
    user,
    locations,
    dashboards,
    contacts,
    meters,
    permissions,
    tournee,
    users,
  };
}

// @ts-ignore
const mapping: any = connect(mapStateToProps)(LocationsComponent);

const connectedLocations = withRouter(mapping);
const tr = withTranslation()(connectedLocations);
export default tr;
