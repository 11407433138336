import React, { Component, Fragment } from 'react';
import { Col, Label, Row } from 'reactstrap';
import { AvField, AvGroup } from 'availity-reactstrap-validation';
import cloneDeep from 'lodash/cloneDeep';
import { withTranslation } from 'react-i18next';

/**
 * Composant gérant l'intervalle de date (dans les widgets notamment)
 *
 * @class DateInterval
 * @extends Component
 */
class DateInterval extends Component<any, {}> {
  constructor(props: any) {
    super(props);
    const { values, title } = this.props;

    this.state = {
      values,
      title,
      conditions: props.conditions,
      selectedInterval: null,
      intervalValues: [],
      zoom: [],
    };
    this.onChange = this.onChange.bind(this);
    this.setIntervalValues = this.setIntervalValues.bind(this);
    this.setZoom = this.setZoom.bind(this);
  }

  /**
   * @method componentDidMount
   */
  componentDidMount() {
    const { values, title }: any = this.state;
    const { conditions, period } = this.props;
    const valuesMounted = cloneDeep(values);
    if (valuesMounted[`${title}Name`]) {
      const interval = conditions.filter((c: any) => c.name === valuesMounted[`${title}Name`])[0];
      this.setState({ selectedInterval: cloneDeep(interval) });
      this.setIntervalValues(interval);
      if (!period) {
        this.setZoom(interval);
      }
      this.setState({
        values: valuesMounted,
      });
    } else {
      this.setIntervalValues(conditions[0]);
      if (!period) {
        this.setZoom(conditions[0]);
      }
      // peut on faire autrement ?
      valuesMounted[`${title}Name`] = conditions[0].name;
      if (!period) {
        valuesMounted[`${title}Zoom`] = conditions[0].zoom[0];
      }
      valuesMounted[`${title}Value`] = conditions[0].value[0];
      this.setState({
        values: valuesMounted,
      });
    }
  }

  /**
   * @method componentDidUpdate
   * @param {any} prevProps Props précédentes
   * @param {any} prevState State précédent
   */
  componentDidUpdate(prevProps: any, prevState: any) {
    const { period } = this.props;
    const { selectedInterval }: any = this.state;
    if (selectedInterval !== prevState.selectedInterval) {
      this.setIntervalValues(selectedInterval);
      if (!period) {
        this.setZoom(selectedInterval);
      }
    }
  }

  /**
   * @method onChange
   * @param {any} e Evènement
   */
  onChange(e: any) {
    const { period } = this.props;
    const { conditions }: any = this.state;
    const { values, title }: any = this.state;
    const valuesMounted = Object.assign({}, values);
    const interval = conditions.filter((c: any) => c.name === e.target.value)[0];
    this.setState({ selectedInterval: Object.assign({}, interval) });
    if (!period) {
      this.setZoom(interval);
    }
    this.setIntervalValues(interval);
    // peut on faire autrement ?
    valuesMounted[`${title}Name`] = interval.name;
    if (!period) {
      valuesMounted[`${title}Zoom`] = interval.zoom[0];
    }
    valuesMounted[`${title}Value`] = interval.value[0];
    this.setState({
      values: valuesMounted,
    });
  }

  /**
   * Met à jour l'intervalle sélectionné
   *
   * @method setIntervalValues
   * @param {any} selectedInterval Intervalle sélectionné
   */
  setIntervalValues(selectedInterval: any) {
    const splited = selectedInterval.value.split('-');
    const intervalValues = [];
    const begin = splited[0];
    const end = splited[1];
    // fait planter chrome si i+=1
    for (let i = begin; i <= end; i++) {
      intervalValues.push(i);
    }
    this.setState({ intervalValues });
  }

  /**
   * Sélectionne un zoom
   *
   * @method setZoom
   * @param {any} selectedInterval Intervalle sélectionné
   */
  setZoom(selectedInterval: any) {
    this.setState({ zoom: selectedInterval.zoom });
  }

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { conditions, intervalValues, zoom, values }: any = this.state;
    const { title, locales, period, t } = this.props;
    return (
      <AvGroup>
        <Label for={`${title}Name`}>{period ? t('widget.condition_title.period') : t('all.text.interval')}</Label>
        <Row>
          <Col md="3">
            <AvField type="select" name={`${title}Value`} id={`${title}Value`} value={values[`${title}Value`]}>
              {intervalValues.map((item: any) => (
                <option key={item} value={item}>
                  {' '}
                  {item}
                </option>
              ))}
            </AvField>
          </Col>
          <Col>
            <AvField
              type="select"
              name={`${title}Name`}
              value={values[`${title}Name`]}
              id={`${title}Name`}
              onChange={this.onChange}
            >
              {conditions.map((item: any) => (
                <option key={item.name} value={item.name}>
                  {' '}
                  {t(`best_date_component.zoom_option.${item.name.toLowerCase()}`)}
                </option>
              ))}
            </AvField>
          </Col>
        </Row>
        {!period && (
          <Fragment>
            <Label for={`${title}Zoom`}>{t('all.text.zoom')}</Label>
            <AvField type="select" name={`${title}Zoom`} id={`${title}Zoom`} value={values[`${title}Zoom`]}>
              {zoom.map((item: any) => (
                <option key={item} value={item}>
                  {' '}
                  {t(`best_date_component.zoom_option.${item.toLowerCase()}`)}
                </option>
              ))}
            </AvField>
          </Fragment>
        )}
      </AvGroup>
    );
  }
}

export default withTranslation()(DateInterval);
