import React from 'react';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

interface State {
  noteInProgress: string;
  createdNote: string;
}

/**
 * @class DisplayValueChooserComponent
 * @extends {React.Component<Props, State>}
 */
class LocationNote extends React.Component<any, State> {
  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof DisplayValueChooserComponent
   */
  constructor(props: any) {
    super(props);
    this.send = this.send.bind(this);
    this.handleNoteChange = this.handleNoteChange.bind(this);
    this.validate = this.validate.bind(this);
    this.state = {
      noteInProgress: '',
      createdNote: '',
    };
  }

  /**
   * Met à jour le state si l'attribut datasource du formulaire
   * contient quelque chose
   *
   * @static
   * @param {Props} props
   * @param {State} state
   * @returns {State} Le nouveau state
   * @method getDerivedStateFromProps
   * @memberof DisplayValueChooserComponent
   */
  static getDerivedStateFromProps(props: any, state: State) {
    const { form } = props;
    const { createdNote } = state;

    if (!form.note && _.isEmpty(form.note) && !_.isEmpty(createdNote)) {
      return {
        createdNote: '',
        noteInProgress: '',
      };
    }

    if (form.note && !state.createdNote && !state.noteInProgress) {
      return {
        createdNote: _.cloneDeep(form.note),
        noteInProgress: _.cloneDeep(form.note),
      };
    }

    return null;
  }

  /**
   * Gère le passage à l'étape précédente
   *
   * @method validate
   * @memberof DisplayValueChooserComponent
   */
  validate = () => {
    const { form } = this.props;
    const { createdNote } = this.state;

    //Remettre l'état initial de 'noteInProgress'
    if (!_.isEmpty(form.note) && !_.isEmpty(createdNote)) {
      this.setState({
        noteInProgress: _.cloneDeep(createdNote),
      });
    }

    this.props.firstStep();
  };

  /**
   * Gère le changement des propriétés du widget
   *
   * @param {Object} event Evènement
   * @method handleOptionChange
   * @memberof DisplayValueChooserComponent
   */
  handleNoteChange(event: any) {
    const noteInProgress = event.target.value.trim();
    this.setState({
      noteInProgress,
    });
  }

  /**
   * Gère le passage à l'étape suivante et la mise à jour
   * du template
   *
   * @param {Object} e Evènement
   * @method send
   * @memberof DisplayValueChooserComponent
   */
  send(e: any) {
    //e.preventDefault();
    const { update } = this.props;
    const { noteInProgress } = this.state;
    update('note', noteInProgress);

    this.setState({
      noteInProgress,
      createdNote: _.cloneDeep(noteInProgress),
    });
    this.props.firstStep();
  }

  getValidationsForField() {
    return { required: this.hasRequired() };
  }

  hasRequired() {
    return { value: true, errorMessage: 'Renseigner au moins un caractère' };
  }

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof DisplayValueChooserComponent
   */
  render() {
    const { isActive, t } = this.props;
    const { noteInProgress, createdNote } = this.state;

    if (!isActive) return null;
    return (
      <div>
        <h3>{t('location_creator.title.location_note')}</h3>
        <AvForm onValidSubmit={this.send}>
          <div className="noteChoices">
            <FormGroup>
              <AvField
                type="textarea"
                name="note"
                rows="7"
                id="note"
                placeholder={t('location_creator.placeholder.write_note')}
                value={createdNote ? createdNote : noteInProgress}
                onChange={this.handleNoteChange}
                validate={this.getValidationsForField()}
              />
              <div className="bottomChoice">
                <Row>
                  <Col md="6" className="text-right">
                    <Button color="danger" onClick={this.validate}>
                      {t('all.button.cancel')}
                    </Button>
                  </Col>
                  {createdNote ? (
                    <Col md="6" className="text-left">
                      <Button color="primary">{t('all.button.edit')}</Button>
                    </Col>
                  ) : (
                    <Col md="6" className="text-left">
                      <Button color="primary">{t('all.button.validate')}</Button>
                    </Col>
                  )}
                </Row>
              </div>
            </FormGroup>
          </div>
        </AvForm>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { locales } = state;
  return {
    locales,
  };
}

const LocationNoteConnectedComponent = connect(mapStateToProps)(LocationNote);
const tr = withTranslation()(LocationNoteConnectedComponent);
export default tr;
