import { columnSelectorConstants } from '../_constants';
import { columnSelectorService } from '../_services';
import alertActions from './alert.actions';
import { Setting } from '../_entities/settings';

/**
 * Récupère les colonnes relatives au user
 * connecté
 *
 * @method getAll
 * @param {number} userId id utilisateur
 * @returns {Object} Les colonnes
 */
function getAll(userId: number) {
  function request() {
    return {
      type: columnSelectorConstants.GET_REQUEST,
    };
  }

  function success(columns: Object) {
    return {
      type: columnSelectorConstants.GET_SUCCESS,
      columns,
    };
  }

  function failure(error: Object) {
    return {
      type: columnSelectorConstants.GET_FAILURE,
      error,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    columnSelectorService
      .getAllByType(userId)
      .then((columns: Object) => {
        dispatch(success(columns));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Sauvegarde la configuration de colonnes passée en paramètre
 *
 * @method save
 * @param {string} nameColumn Nom de la configuration
 * @param {number} userId Id de l'utilisateur
 * @param {Object} column Configuration
 * @returns {Object} L'état de la sauvegarde
 */
function save(nameColumn: string, userId: number, column: Object) {
  const toSave: any = {
    userId,
    content: column,
    name: nameColumn,
  };
  function success(savedColumns: Setting) {
    return {
      type: columnSelectorConstants.SAVE_SUCCESS,
      savedColumns,
    };
  }
  return (dispatch: Function) => {
    columnSelectorService
      .save(toSave, userId)
      .then((savedColumns: Setting) => {
        dispatch(success(savedColumns));
        dispatch(alertActions.success('Opération réussie'));
      })
      .catch((error: Object) => {
        dispatch(alertActions.error(error));
      });
  };
}

/**
 * Met à jour la configuration de colonne passée en paramètre
 *
 * @method save
 * @param {string} nameOfConfig Nom de la configuration
 * @param {number} idConfig Id de la configuration
 * @param {number} userId Id de l'utilisateur
 * @param {Object} column Configuration
 * @returns {Object} L'état de la mise à jour
 */
function update(nameOfConfig: string, idConfig: number, userId: number, column: Object) {
  const toSave: any = {
    userId,
    content: column,
    name: nameOfConfig,
  };
  function success(updatedColumn: Object) {
    return {
      type: columnSelectorConstants.UPDATE_SUCCESS,
      updatedColumn,
    };
  }
  return (dispatch: Function) => {
    columnSelectorService
      .update(toSave, idConfig, userId)
      .then((updatedColumns: Object) => {
        dispatch(success(updatedColumns));
        dispatch(alertActions.success('Opération réussie'));
      })
      .catch((error: Object) => {
        dispatch(alertActions.error(error));
      });
  };
}

/**
 * Supprime la configuration de colonnes passée en paramètre
 *
 * @method deleteColumn
 * @param {any | null | undefined} column Configuration
 * @param {number} columnId Id de la configuration
 * @param {number} userId Id de l'utilisateur
 * @returns {Object} Etat de la suppression
 */
function deleteColumn(column: any | null | undefined, columnId: number, userId: number) {
  function success() {
    return {
      type: columnSelectorConstants.DELETE_SUCCESS,
      deletedColumn: column,
    };
  }
  return (dispatch: Function) => {
    columnSelectorService
      .deleteColumn(columnId, userId)
      .then(() => {
        dispatch(success());
        dispatch(alertActions.success('Opération réussie'));
      })
      .catch((error: Object) => {
        dispatch(alertActions.error(error));
      });
  };
}

/**
 * Vide le state des colonnes
 *
 * @method clear
 */
function clear() {
  return {
    type: columnSelectorConstants.CLEAR,
  };
}

const filtreActions = {
  getAll,
  save,
  update,
  deleteColumn,
  clear,
};

export default filtreActions;
