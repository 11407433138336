import React, { ReactElement } from 'react';
import { Button } from 'reactstrap';
import ReglagesBleu from '../SvgComponents/ReglagesBleu';
import PoubelleBleu from '../SvgComponents/PoubelleBleu';
import SvgPleinEcranVert from '../SvgComponents/PleinEcranVert';

type Props = {
  displayFullScreen: Function;
  isConfigured: boolean;
  headerWidget: any;
  bodyWidget: ReactElement<'div'>;
  widgetId: string | number;
  couldEditWidget: boolean;
  openWidgetEditor: Function;
  remove: Function;
};

/**
 * Créé le composant widget comme il est
 * représenté sur un dashboard
 *
 * @export
 * @method SimpleWidget
 * @param {Props} {
 *   displayFullScreen,
 *   isConfigured,
 *   headerWidget,
 *   bodyWidget,
 *   infoWidget
 *   widgetId,
 *   couldEditWidget,
 *   openWidgetEditor,
 *   remove,
 * }
 * @returns {JSX} Le composant
 */
export default function SimpleWidget({
  displayFullScreen,
  isConfigured,
  headerWidget,
  bodyWidget,
  widgetId,
  couldEditWidget,
  openWidgetEditor,
  remove,
}: Props) {
  return (
    <div className={`container-widget container widget-${isConfigured ? 'ready' : 'unready'}`}>
      {isConfigured && (
        <div className="simple-container">
          <div
            className="clickable round fullscreen-button"
            role="presentation"
            onClick={() => displayFullScreen(widgetId)}
          >
            <SvgPleinEcranVert className="add" fill="#808080" height="1.5em" width="1.5em" />
          </div>
        </div>
      )}
      {headerWidget}
      <span style={{ height: '40px', width: '100%', display: 'block' }} />
      <>
        {bodyWidget}
        {couldEditWidget && (
          <div className="widget-unready">
            <div className="btn-group" role="group">
              <Button size="sm" color="secondary" onClick={openWidgetEditor as any}>
                <ReglagesBleu className="add" fill="#808080" height="1.5em" width="1.5em" />
              </Button>
              {'   '}
              <Button size="sm" color="danger" onClick={remove as any}>
                <PoubelleBleu className="add" fill="#808080" height="1.5em" width="1.5em" />
              </Button>
            </div>
          </div>
        )}
      </>
    </div>
  );
}
