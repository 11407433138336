import React from 'react';
import { connect } from 'react-redux';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { alertActions, userActions } from '../_actions';
import { alert } from '../_interfaces/reducers';
import { User } from '../_entities/user';
import ListPermissions from '../User/listPermission';
import listLevel, { levels } from '../User/listPermission';
import { resources } from '../i18n';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

interface Props {
  edit: Function;
  users: any;
  user: User;
  usertoEdit: User;
  dispatch: Function;
  alert: alert;
  isOpen: boolean;
}

interface State {
  usertoEdit: any;
  firstName: string;
  lastName: string;
  email: string;
  updated: boolean | null | undefined;
  role: any;
}

/**
 * @class InformationUserChangeComponent
 * @extends Component
 */
class InformationUserChangeComponent extends React.Component<Props & React.HTMLAttributes<{}>, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      role: '',
      usertoEdit: null,
      updated: null,
    };
  }

  /**
   * @static
   * @method getDerivedStateFromProps
   * @param {Props} props Props du composant
   * @param {State} state State du composant
   * @returns {Object} Le nouveau state
   */
  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.users.userUpdated) {
      return {
        usertoEdit: props.usertoEdit,
        email: props.usertoEdit.email,
        lastName: props.usertoEdit.lastName,
        firstName: props.usertoEdit.firstName,
        role: props.usertoEdit.role.name,
        updated: true,
      };
    }
    if (props.users.updatedUser) {
      return {
        usertoEdit: props.users.updatedUser,
        email: props.users.updatedUser.email,
        lastName: props.users.updatedUser.lastName,
        firstName: props.users.updatedUser.firstName,
        role: props.usertoEdit.role.name,
        updated: false,
      };
    }
    if (!props.users.userUpdated && !state.usertoEdit && props.usertoEdit) {
      return {
        usertoEdit: props.usertoEdit,
        email: props.usertoEdit.email,
        lastName: props.usertoEdit.lastName,
        firstName: props.usertoEdit.firstName,
        role: props.usertoEdit.role.name,
        updated: false,
      };
    }
    return {
      usertoEdit: state.usertoEdit,
      email: state.email,
      lastName: state.lastName,
      firstName: state.firstName,
      role: state.role,
      updated: false,
    };
  }

  /**
   * @method componentDidMount
   */
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
  }

  /**
   * Nettoie le state au démontage
   *
   * @method componentWillUnmount
   * @memberof DisplayDashBoardsComponent
   */
  componentWillUnmount() {
    const { dispatch } = this.props;
    this.setState({
      email: '',
      firstName: '',
      lastName: '',
      usertoEdit: null,
      updated: null,
    });
    dispatch(alertActions.clear());
  }

  /**
   * @method componentDidUpdate
   */
  componentDidUpdate() {
    const { edit, users } = this.props;
    if (users.userUpdated) {
      edit();
    }
  }

  /**
   * Gère l'action à la validation du formulaire
   *
   * @method handleValidSubmit
   * @param {Object} event Evènement
   * @param {any} values Valeurs
   */
  handleValidSubmit = (event: Object, values: any) => {
    this.setState({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
    });
    const { dispatch, users } = this.props;
    const { usertoEdit, firstName, lastName, email } = this.state;
    // on créé un utilisateur pour ne pas influencer l'état
    const userToupdate = {
      id: usertoEdit.id,
      role: values.role || users.fetchedUser.role.name,
      email: values.email || usertoEdit.email,
      userName: usertoEdit.userName,
      language: values.language || users.fetchedUser.language,
      firstName,
      lastName,
    };
    dispatch(userActions.update(usertoEdit, userToupdate));
    //dispatch(userActions.update(usertoEdit, userToupdate, users.permissions, users.lastUpdatedInfo));
  };

  /**
   * Gère l'action en cas d'echec de validation du formulaire
   *
   * @method handleInvalidSubmit
   * @param {Object} event Evènement
   * @param {Object} errors Erreurs
   * @param {any} values Valeurs
   */
  handleInvalidSubmit = (event: Object, errors: Object, values: any) => {
    this.setState({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
    });
  };

  /**
   * Teste les permissions d'édition d'utilisateur
   *
   * @method couldUpdate
   * @returns {boolean} Le test
   */
  couldUpdate = () => {
    const { user, usertoEdit } = this.props;

    if (user.role.userName === usertoEdit.role.userName && user.id === usertoEdit.id) {
      return true;
    }

    return user.role.name !== 'USER' && ListPermissions[user.role.name] > ListPermissions[usertoEdit.role.name];
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { alert, className, isOpen, edit, user, users, t } = this.props;
    const { updated, firstName, lastName, email } = this.state;
    let newList: any = [];
    if (users.fetchedUser) {
      newList = levels.filter((el: any) => el.value < listLevel[user.role.name]);
    }
    return (
      <div>
        {this.couldUpdate() && (
          <div>
            <Modal isOpen={isOpen} toggle={edit as any} className={className}>
              <ModalHeader toggle={edit as any}>{t('all.text.edit_information')}</ModalHeader>
              <AvForm onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
                <ModalBody>
                  <div>{alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}</div>
                  {!updated && (
                    <div>
                      <AvField
                        name="lastName"
                        value={lastName}
                        label={t('all.contact.last_name')}
                        required
                        errorMessage={t('add_user.field.name_error')}
                        validate={{
                          pattern: {
                            value: '^[A-Za-z]+$',
                            errorMessage: t('add_user.field.field_only_alphabetical'),
                          },
                          minLength: {
                            value: 2,
                            errorMessage: t('add_user.field.minimum_x_size', { min: 2 }),
                          },
                          maxLength: {
                            value: 50,
                            errorMessage: t('add_user.field.maximum_x_size', { max: 50 }),
                          },
                        }}
                      />
                      <AvField
                        name="firstName"
                        value={firstName}
                        label={t('all.contact.first_name')}
                        required
                        errorMessage={t('add_user.field.minimum_x_size', { min: 2 })}
                      />
                      {!users.fetchedUser.ldap && (
                        <AvField
                          type="select"
                          name="role"
                          label={t('all.user.role')}
                          placeholder={t('user.text.select_role')}
                        >
                          <option disabled={true} value="">
                            {t('user.text.select_role')}
                          </option>
                          {newList.map((el: any) => (
                            <option>{el.label}</option>
                          ))}
                        </AvField>
                      )}
                      {users.fetchedUser.ldap && (
                        <AvField
                          name="email"
                          value={email}
                          label={t('all.contact.email')}
                          errorMessage={t('add_user.field.minimum_x_size', { min: 2 })}
                        />
                      )}
                      {localStorage.getItem('language') && (
                        <AvField
                          type="select"
                          name="language"
                          label={t('user.text.lang_choosen')}
                          placeholder={t('user.text.select_lang')}
                          value={users.fetchedUser.language || null}
                        >
                          {_.map(_.keys(resources).filter(el => el !== 'bk'), lng => (
                            <option>{lng}</option>
                          ))}
                        </AvField>
                      )}
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  {!updated && (
                    <Button color="danger" onClick={edit as any}>
                      {t('all.button.cancel')}
                    </Button>
                  )}
                  {!updated && <Button color="primary">{t('all.button.register')}</Button>}
                </ModalFooter>
              </AvForm>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, alert, users } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    users,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(InformationUserChangeComponent)));
