import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { jwtHelper, userPasswordHelper, getAuthUser, authHeader, handleResponse } from '../_helpers';
import { getAuthId } from '../_helpers/jwthelper';

interface IDefaultProps {
  component: any;
  layout: any;
  path?: string;
  exact?: boolean;
  authAccepted?: Array<String>;
  isDevice?: any;
}

async function AsyncPermissions(userId: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  try {
    const response = await fetch(`/api/permission/crystal/user/${userId}`, requestOptions);
    return handleResponse(response);
  } catch (e) {
    console.log(e);
  }
}
/**
 * Rend le composant gérant les routes privées
 *
 * @method PrivateRoute
 */
export const PrivateRoute: React.FunctionComponent<IDefaultProps> = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  const userId = getAuthId();
  const authUser = getAuthUser();
  //const perms = AsyncPermissions(userId)

  if (rest.authAccepted && !rest.authAccepted.find(i => i === authUser)) {
    return (
      <Route
        {...rest}
        render={props => <Redirect to={{ pathname: '/forbidden', state: { from: props.location } }} />}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={props =>
        jwtHelper() ? (
          // Si l'utilisateur doit changer son mot de passe

          !userPasswordHelper() ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect to={{ pathname: '/resetPassword', state: { from: props.location } }} />
          )
        ) : (
          <Redirect to={{ pathname: rest.isDevice ? '/saphir/signin' : '/signin', state: { from: props.location } }} />
        )
      }
    />
  );
};

export const PublicRoute: React.FunctionComponent<IDefaultProps> = ({
  component: Component,
  layout: Layout,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);
