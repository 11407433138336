import React from 'react';

const SvgSurDebitVert = (props: any) => (
  <svg viewBox="0 0 20.39 21.05" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M7.44,11.58l-.53,1.29a.67.67,0,0,0,1,.81l4.28-3a.67.67,0,0,0,.29-.58.65.65,0,0,0-.33-.55L7.68,6.86a.68.68,0,0,0-.8.08.69.69,0,0,0-.16.79L7.31,9H1.11A1.11,1.11,0,0,0,0,10.08v.39a1.11,1.11,0,0,0,1.11,1.11Z"
        />
        <path
          fill={props.fill}
          d="M19.33,9h-3a1.09,1.09,0,0,1-.81-.41L10.12,2.06H3.53A1,1,0,0,1,2.47,1,1,1,0,0,1,3.53,0h7.08a1.07,1.07,0,0,1,.81.37l5.44,6.46h2.47a1.08,1.08,0,0,1,1.06,1.08A1.07,1.07,0,0,1,19.33,9Z"
        />
        <path
          fill={props.fill}
          d="M10.61,21.05H3.53a1,1,0,1,1,0-2.06h6.59l5.45-6.51a1.07,1.07,0,0,1,.8-.41h3a1.08,1.08,0,0,1,0,2.15H16.87l-5.45,6.47A1.06,1.06,0,0,1,10.61,21.05Z"
        />
      </g>
    </g>
  </svg>
);
export default SvgSurDebitVert;
