import React, { Component } from 'react';
import { Alert, Button, CustomInput, FormGroup, Label, Progress } from 'reactstrap';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import SuccessBand from '../Bands/Success';
import ErrorBand from '../Bands/Error';
import LoadingBand from '../Bands/Loading';
import { importActions, localeActions } from '../_actions';
import ImportBleu from '../SvgComponents/ImportBleu';

export class StockImportation extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      file: null,
    };
    this.onChange = this.onChange.bind(this);
    this.invalidFile = this.invalidFile.bind(this);
    this.downloadReport = this.downloadReport.bind(this);
  }

  componentDidMount() {
    const { user, history, dispatch } = this.props;

    const roleList = ['DIOPTASE', 'SUPERADMIN', 'ADMIN'];
    if (!roleList.includes(user.role.name)) {
      history.push('/forbidden');
    }
    dispatch(localeActions.load());
    dispatch(importActions.isRunning());
    dispatch(importActions.restore());
  }

  onChange(e: any) {
    const { dispatch } = this.props;
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    this.setState({ file: formData });
    dispatch(importActions.clear());
  }

  invalidFile(e: any) {
    const { dispatch } = this.props;
    dispatch(importActions.clear());
    this.setState({ file: null });
  }

  submitFile = () => {
    const { dispatch } = this.props;
    const { file } = this.state;
    dispatch(importActions.importFile(file));
  };

  testImport = () => {
    const { dispatch } = this.props;
    dispatch(importActions.testImport());
  };

  confirmImport = () => {
    const { dispatch } = this.props;
    dispatch(importActions.confirmImport());
  };

  chooseFileDone = () => {
    const { file } = this.state;
    const { imports } = this.props;
    return (file !== null && file && file.get('file') && file.get('file').name) || imports.file;
  };

  firstStepDone = () => {
    const { imports } = this.props;
    return this.chooseFileDone() && _.get(imports, 'imports') && !_.get(imports, 'imports.loading');
  };

  secondStepDone = () => {
    const { imports } = this.props;
    return this.firstStepDone() && _.get(imports, 'tests') && !_.get(imports, 'tests.loading');
  };

  thirdStepDone = () => {
    const { imports } = this.props;
    return this.secondStepDone() && _.get(imports, 'confirm') && !_.get(imports, 'confirm.loading');
  };

  changeDisplay = (key: string, context: string) => {
    const state = {};
    state[key + context] = this.state[key + context] ? !this.state[key + context] : true;
    this.setState({ ...state });
  };

  downloadReport = (e: React.MouseEvent) => {
    const { dispatch } = this.props;

    e.stopPropagation();
    dispatch(importActions.downloadReport());
  };

  render() {
    const { file } = this.state;
    const { imports, locales, t } = this.props;
    const { job } = imports;
    const importProgress = (_.get(job, 'treated', 0) / _.get(job, 'total', 1)) * 100;

    const getResultData = () => {
      const { imports } = this.props;
      return imports.confirmResult.map((result: any) => ({
        id: result.pdiNumber + result.action,
        pdi: result.pdiNumber,
        action: t(`stock_importation.importation_report.action_${result.action.toLowerCase()}`),
        detail:
          result.detail != null ? (
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {result.detail.reduce(
                (detail: string, value: { label: string; previousValue: string; newValue: string }) =>
                  detail +
                  `${t(`stock_importation.importation_report.detail_${value.label}`)}:\n  ${value.previousValue ||
                    t('stock_importation.importation_report.detail_empty')} -> ${value.newValue}\n`,
                ''
              )}
            </div>
          ) : (
            ''
          ),
      }));
    };

    const columns = [
      {
        dataField: 'pdi',
        text: 'PDI',
      },
      {
        dataField: 'action',
        text: 'Action',
      },
      {
        dataField: 'detail',
        text: 'Détails',
      },
    ];

    const errorImportColumns = [
      {
        dataField: 'description',
        text: t('import_silex.column_label.description'),
      },
      {
        dataField: 'line',
        text: t('import_silex.column_label.lines'),
      },
      {
        dataField: 'column',
        text: 'Nom de colonne',
      },
    ];

    const getErrorMessage = (error: any) => {
      switch (error.errorCode) {
        case 400:
          if (error.errorMessage === 'error in facturation file') {
            return t('import_silex.error_text.silex_error');
          }
        default:
          if (error.message === 'File not match configuration') {
            return t('stock_importation.error.not_match_config');
          }
          return error.message;
      }
    };

    const displayError = (error: any) => {
      if (error.errorMessage === 'error in facturation file') {
        const data = error.errorList.map((el: { _1: string; _2: number; _3: string; _4: string }) => ({
          id: el._1 + el._2 + el._3,
          description: t(`stock_importation.error.${el._1}`, { element: el._4 }),
          line: el._2,
          column: el._3,
        }));
        return (
          <BootstrapTable
            keyField="id"
            bootstrap4
            bordered
            hover
            data={data.flat().sort((a: any, b: any) => a.line - b.line)}
            columns={errorImportColumns}
          />
        );
      }
    };

    return (
      <div className="col-12">
        <div className="table-info-container">
          <h2>
            <span>
              <ImportBleu height="1em" width="1em" fill="#31c6b3" />
            </span>
            {t('stock_importation.main_title.import_billing')}
          </h2>
          {imports && (!imports.imports || !this.chooseFileDone()) && !imports.loading ? (
            <FormGroup style={{ width: '25%' }}>
              <Label for="dataFile">{t('import_component.input_file_label.import_file')} (.csv,.tsv,.xlsx)</Label>
              <CustomInput
                type="file"
                id="import-silex-button"
                onChange={this.onChange}
                accept=".csv,.tsv,.xlsx"
                placeholder="Sélectionner un fichier"
                name="customFile"
                label={file ? file.get('file').name : t('all.text.select_file')}
                style={{ whiteSpace: 'nowrap' }}
              />

              {imports && imports.running && (
                <div style={{ marginTop: '10px' }}>
                  <LoadingBand message={t('import.loading_band.import_already_loading')} />
                </div>
              )}
            </FormGroup>
          ) : (
            <div>
              <Button color="danger" onClick={this.invalidFile}>
                {t('all.button.change_file')}
              </Button>
              <p style={{ marginLeft: 2, marginTop: 5, fontWeight: 'bold' }}>{this.chooseFileDone()}</p>
            </div>
          )}

          <div style={{ marginBottom: 20 }}> </div>

          {this.chooseFileDone() && (
            <div>
              {imports && imports.imports && (
                <p>
                  {t('stock_importation.text.launch_by')} {imports.operatingUser}
                </p>
              )}
              {file && !imports.imports && !imports.loading && (
                <>
                  <h3>{t('import.step_1_title.first_step_load_file')}</h3>
                  <Button
                    disabled={imports && imports && imports.loading}
                    color="primary"
                    style={{ marginTop: 10 }}
                    onClick={this.submitFile}
                  >
                    {t('all.button.submit')}
                  </Button>
                </>
              )}
              {this.firstStepDone() &&
                (_.get(imports, 'hasError') && !this.secondStepDone() ? (
                  <ErrorBand message="Une erreur est survenue pendant le chargement du fichier" />
                ) : (
                  <SuccessBand message={t('import.step_1_success_band.load_file')} />
                ))}
            </div>
          )}

          <div style={{ marginBottom: 20 }}> </div>

          {imports && imports.loading && !_.get(imports, 'job.processing') && (
            <div>
              <LoadingBand message={t('stock_importation.step_1_loading_band.send_file')} />
            </div>
          )}
          {imports && _.get(imports, 'imports.loading') && _.get(imports, 'job.processing') && (
            <div>
              <LoadingBand message={t('import.step_1_loading_band.file_analysis')} />
              <Progress animated value={importProgress}>{`${_.get(job, 'treated', 0)}/${_.get(
                job,
                'total',
                1
              )}`}</Progress>
            </div>
          )}
          {imports && (imports.errorInfo || imports.error) && (
            <>
              <ErrorBand message={getErrorMessage(imports.errorInfo ? imports.errorInfo : imports.error.message)} />
              {imports.errorInfo && displayError(imports.errorInfo)}
            </>
          )}
          {this.firstStepDone() && (
            <AvForm onValidSubmit={this.testImport}>
              <h3>{t('import.step_2_title.second_step_check_error')}</h3>
              {Object.entries(imports.imports.error)
                .map(([key, value]) => value.length)
                .reduce((a, b) => a + b) > 0 && <h5>{t('import.step_error.error')} : </h5>}
              {Object.entries(imports.imports.error).map(([key, value]) => {
                return (
                  value.length > 0 && (
                    <FormGroup check key={key} style={{ marginBottom: 5 }}>
                      <AvInput
                        type="checkbox"
                        name={key}
                        required
                        tag={CustomInput}
                        style={{ fontWeight: 'bold' }}
                        disabled={imports && imports.tests}
                      >
                        {' '}
                        {t(`import.anomaly_text.${key}`)} :{' '}
                        <a
                          onClick={() => {
                            this.changeDisplay(key, 'e2');
                          }}
                          className="display_lines"
                        >
                          {value.length}
                        </a>
                      </AvInput>
                      {this.state[`${key}e2`] && (
                        <ul>
                          {value.map((entry: any) => (
                            <li key={entry.line}>
                              {t('import.list.x_row', {
                                row: entry.line,
                                pdi: entry.pdiReference,
                                serial: entry.meterSerial,
                              })}
                            </li>
                          ))}
                        </ul>
                      )}
                    </FormGroup>
                  )
                );
              })}
              {Object.entries(imports.imports.warning)
                .map(([key, value]) => value.length)
                .reduce((a, b) => a + b) > 0 && <h5>{t('import.step.warning')}</h5>}
              {Object.entries(imports.imports.warning).map(([key, value]) => {
                return (
                  value.length > 0 && (
                    <FormGroup check key={key} style={{ marginBottom: 5 }}>
                      <AvInput
                        type="checkbox"
                        name={key}
                        required
                        tag={CustomInput}
                        disabled={imports && imports.tests}
                      >
                        {' '}
                        {t(`import.anomaly_text.${key}`)} :{' '}
                        <a
                          onClick={() => {
                            this.changeDisplay(key, 'e2');
                          }}
                          className="display_lines"
                        >
                          {value.length}
                        </a>
                      </AvInput>
                      {this.state[`${key}e2`] && (
                        <ul>
                          {value.map((entry: any) => (
                            <li key={entry.line}>
                              {t('import.list.x_row', {
                                row: entry.line,
                                pdi: entry.pdiReference,
                                serial: entry.meterSerial,
                              })}
                            </li>
                          ))}
                        </ul>
                      )}
                    </FormGroup>
                  )
                );
              })}
              <div style={{ marginTop: 15 }}>
                {imports && !imports.tests && (
                  <>
                    <p className="help-next-step">{t('import.step_2_help.check_error_for_import')}</p>
                    <Button disabled={imports && imports.tests && imports.tests.loading} color="primary">
                      {t('import.step_2_button.test_integration')}
                    </Button>
                  </>
                )}
                {this.secondStepDone() &&
                  (_.get(imports, 'hasError') && !this.thirdStepDone() ? (
                    <ErrorBand message="Une erreur est survenue pendant l'analyse du fichier" />
                  ) : (
                    <SuccessBand message={t('import.step_2_success_band.test_integration')} />
                  ))}
              </div>
            </AvForm>
          )}

          <div style={{ marginBottom: 20 }}> </div>

          {_.get(imports, 'tests.loading') && _.get(imports, 'job.processing') && (
            <div>
              <LoadingBand message={t('stock_importation.step_2_loading_band.check_file')} />
              <Progress animated value={importProgress}>{`${_.get(job, 'treated', 0)}/${_.get(
                job,
                'total',
                1
              )}`}</Progress>
            </div>
          )}
          {imports && imports.tests && !imports.tests.warning && imports.tests.error && (
            <ErrorBand message={imports.tests.error} />
          )}
          {this.secondStepDone() && imports.tests.modified && imports.tests.unmodified && (
            <AvForm onValidSubmit={this.confirmImport} style={{ marginTop: 20 }}>
              <h3>{t('import.step_3_title.third_step_check_integration')}</h3>
              {Object.entries(imports.tests.error)
                .map(([key, value]) => value.length)
                .reduce((a, b) => a + b) > 0 && <h5>{t('import.step_error.error')}</h5>}
              {Object.entries(imports.tests.error).map(([key, value]) => {
                return (
                  value.length > 0 && (
                    <FormGroup check key={key} style={{ marginBottom: 5 }}>
                      <AvInput
                        type="checkbox"
                        name={key}
                        required
                        tag={CustomInput}
                        style={{ fontWeight: 'bold' }}
                        disabled={imports && imports.confirm}
                      >
                        {' '}
                        {t(`import.anomaly_text.${key}`)} :{' '}
                        <a
                          onClick={() => {
                            this.changeDisplay(key, 'e3');
                          }}
                          className="display_lines"
                        >
                          {value.length}
                        </a>
                      </AvInput>
                      {this.state[`${key}e3`] && (
                        <ul>
                          {value.map((entry: any) => (
                            <li key={entry.line}>
                              {t('import.list.x_row', {
                                row: entry.line,
                                pdi: entry.pdiReference,
                                serial: entry.meterSerial,
                              })}
                            </li>
                          ))}
                        </ul>
                      )}
                    </FormGroup>
                  )
                );
              })}
              {Object.entries(imports.tests.warning)
                .map(([key, value]) => value.length)
                .reduce((a, b) => a + b) > 0 && <h5>{t('import.step.warning')}</h5>}
              {Object.entries(imports.tests.warning).map(([key, value]) => {
                return (
                  value.length > 0 && (
                    <FormGroup check key={key} style={{ marginBottom: 5 }}>
                      <AvInput
                        type="checkbox"
                        name={key}
                        required
                        tag={CustomInput}
                        disabled={imports && imports.confirm}
                      >
                        {' '}
                        {t(`import.anomaly_text.${key}`)} :{' '}
                        <a
                          onClick={() => {
                            this.changeDisplay(key, 'e3');
                          }}
                          className="display_lines"
                        >
                          {value.length}
                        </a>
                      </AvInput>
                      {this.state[`${key}e3`] && (
                        <ul>
                          {value.map((entry: any) => (
                            <li key={entry.line}>
                              {t('import.list.x_row', {
                                row: entry.line,
                                pdi: entry.pdiReference,
                                serial: entry.meterSerial,
                              })}
                            </li>
                          ))}
                        </ul>
                      )}
                    </FormGroup>
                  )
                );
              })}
              <div style={{ marginTop: 15 }}>
                {imports && !imports.confirm && (
                  <>
                    <p className="help-next-step">{t('import.step_3_help.check_error_for_finish_import')}</p>
                    <Button disabled={imports && imports.confirm && imports.confirm.loading} color="primary">
                      {t('all.button.integrate')}
                    </Button>
                  </>
                )}
                {this.thirdStepDone() &&
                  (_.get(imports, 'hasError') ? (
                    <ErrorBand message="Une erreur est survenue pendant l'intégration du fichier (Aucune modifications intégrées)" />
                  ) : (
                    <>
                      <SuccessBand
                        message={t('stock_importation.step_3_success_band.integration_x_row_and_x_modified', {
                          nbRow:
                            _.get(imports, 'confirm.modified.length', 0) +
                            _.get(imports, 'confirm.unmodified.length', 0),
                          count: _.get(imports, 'confirm.modified.length', 0),
                        })}
                      />
                      {_.get(imports, 'confirm.archived', 0) > 0 && (
                        <Alert color="info">
                          {t('stock_importation.step_3_success_band.nb_meter_archived', {
                            nbRow: _.get(imports, 'confirm.archived', 0),
                          })}
                        </Alert>
                      )}
                    </>
                  ))}
              </div>
            </AvForm>
          )}

          <div style={{ marginBottom: 20 }}> </div>

          {this.secondStepDone() && _.get(imports, 'confirm.loading') && _.get(imports, 'job.processing') && (
            <div>
              <LoadingBand message={t('import.step_3_loading_band.integration_file')} />
              <Progress animated value={importProgress}>{`${_.get(job, 'treated', 0)}/${_.get(
                job,
                'total',
                1
              )}`}</Progress>
            </div>
          )}
          {this.thirdStepDone() && !_.get(imports, 'confirm.warning') && _.get(imports, 'confirm.error') && (
            <ErrorBand message={imports.confirm.error} />
          )}
          {this.thirdStepDone() && imports.confirmResult && imports.confirmResult.length != 0 && (
            <>
              <h3>{t('import.step_4_title.integration_report')}</h3>
              <div style={{ marginBottom: 16 }}>
                {imports.downloadLoading ? (
                  <LoadingBand message={t('stock_importation.importation_report.download_loading')} />
                ) : (
                  <>
                    {imports.reportError && (
                      <ErrorBand message={t('stock_importation.importation_report.download_error')} />
                    )}
                    <Button disabled={imports.confirm.loading} color="primary" onClick={this.downloadReport}>
                      {t('stock_importation.importation_report.download_button')}
                    </Button>
                  </>
                )}
              </div>
              <BootstrapTable
                keyField="id"
                bootstrap4
                bordered={false}
                hover
                data={getResultData()}
                columns={columns}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, meters, locations, locales, radios, pdis, imports } = state;
  const { user } = authentication;
  return {
    alert,
    user,
    meters,
    radios,
    imports,
    pdis,
    locations,
    locales,
  };
}

const connectedStockImportation = connect(mapStateToProps)(StockImportation);
const tr = withTranslation()(connectedStockImportation);
export default tr;
