import { authHeader, handleResponse } from '../_helpers';
import { Template } from '../_entities/template';

async function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch('/api/widget/template/templates', requestOptions);
  return handleResponse(response);
}

async function getAllDatasources() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch('/api/widget/template/datasources', requestOptions);
  return handleResponse(response);
}

async function get(id: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/widget/template/${id}/`, requestOptions);
  return handleResponse(response);
}

async function save(template: Template) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(template),
  };
  let response = await fetch('/api/widget/template/', requestOptions);
  return handleResponse(response);
}

async function update(template: Template) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(template),
  };
  let response = await fetch(`/api/widget/template/${template.id}`, requestOptions);
  return handleResponse(response);
}

async function toDelete(id: number) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };
  let response = await fetch(`/api/widget/template/${id}`, requestOptions);
  return handleResponse(response);
}

const templateService = {
  getAll,
  get,
  update,
  toDelete,
  save,
  getAllDatasources,
};

export default templateService;
