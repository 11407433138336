import React from 'react';

const SvgBatterieVert = (props: any) => (
  <svg viewBox="0 0 22.87 10.67" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M22.49,0H3.43A1.14,1.14,0,0,0,2.29,1.14V2.67H1.14A1.14,1.14,0,0,0,0,3.81V6.86A1.15,1.15,0,0,0,1.14,8H2.29V9.53a1.14,1.14,0,0,0,1.14,1.14H22.49a.38.38,0,0,0,.38-.38V.38A.38.38,0,0,0,22.49,0ZM1.14,7.24a.38.38,0,0,1-.38-.38V3.81a.38.38,0,0,1,.38-.38H2.29V7.24Zm21,2.67H3.43a.38.38,0,0,1-.38-.38V1.14A.38.38,0,0,1,3.43.76H22.11Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgBatterieVert;
