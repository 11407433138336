import teleReleveConstants from '../_constants/teleReleve.constants';
import { action } from '../_interfaces/action';

export default function telereleve(state = {}, action: action) {
  switch (action.type) {
    case teleReleveConstants.GET_AVAILABLE_REQUEST:
      return {
        ...state,
        loading: !state.provisionProcessing && !state.actions,
        actions: null,
        items: [],
      };
    case teleReleveConstants.GET_AVAILABLE_SUCCESS:
      return {
        ...state,
        items: action.availables,
        loading: false,
      };
    case teleReleveConstants.GET_AVAILABLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Impossible d'obtenir la liste des télé-relèves",
      };
    case teleReleveConstants.GET_GATEWAY_REQUEST:
      return {
        ...state,
      };
    case teleReleveConstants.GET_GATEWAY_SUCCESS:
      return {
        ...state,
        gateways: action.gateways,
      };
    case teleReleveConstants.GET_GATEWAY_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Impossible d'obtenir la liste des télé-relèves",
      };
    case teleReleveConstants.PROVISION_REQUEST:
      return {
        ...state,
        provisionProcessing: true,
      };
    case teleReleveConstants.PROVISION_SUCCESS:
      return {
        ...state,
        actions: action.availables,
        provisionProcessing: false,
      };
    case teleReleveConstants.PROVISION_FAILURE:
      return {
        ...state,
        provisionProcessing: false,
        error: "Impossible d'obtenir la liste des télé-relèves",
      };
    case teleReleveConstants.PROVISION_UPDATE_PROGRESS_SUCCESS:
      return {
        ...state,
        progress: action.progress,
      };
    case teleReleveConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
