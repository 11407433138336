import React from 'react';

const SvgNbCompteurVert = (props: any) => (
  <svg viewBox="0 0 36.91 31.71" {...props}>
    <g id="nb_compteur_vert_svg__Calque_2" data-name="Calque 2">
      <g id="nb_compteur_vert_svg__Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M36 24.5h-.22v-.26a.36.36 0 0 0-.72 0 .35.35 0 0 0-.39-.32.35.35 0 0 0-.32.36.36.36 0 0 0-.71.07v.19h-4.45l1.22-5.88a.93.93 0 0 0-.93-.92h-22a.92.92 0 0 0-.92.92l1.16 5.84H3.24v-.26a.36.36 0 0 0-.4-.32.35.35 0 0 0-.32.36.35.35 0 0 0-.39-.32.35.35 0 0 0-.32.36.35.35 0 0 0-.39-.32.37.37 0 0 0-.33.39v.19H.88a.38.38 0 0 0-.38.38v3.69a.38.38 0 0 0 .38.35h.63v.27a.35.35 0 0 0 .35.32.36.36 0 0 0 .32-.36.36.36 0 0 0 .36.32.37.37 0 0 0 .46-.37.36.36 0 0 0 .36.32.37.37 0 0 0 .33-.4v-.19h5l.27 1.34a.92.92 0 0 0 .92.92h17.2a.92.92 0 0 0 .92-.92l.28-1.25h5.81v.27a.35.35 0 0 0 .35.32.35.35 0 0 0 .32-.36.36.36 0 0 0 .36.32.37.37 0 0 0 .33-.36.36.36 0 0 0 .36.32.37.37 0 0 0 .33-.4v-.26a.37.37 0 0 0 .16-.31v-3.66a.38.38 0 0 0-.3-.38zM5.22 16.65h26.47c1.2 0 2.07-1.54 1.78-2.92a41 41 0 0 0-4.86-12C28.26 1.18 27.72.5 27.15.5H9.77c-.58 0-1.12.58-1.46 1.13a40.49 40.49 0 0 0-4.86 12c-.3 1.37.55 3.02 1.77 3.02z"
        />
      </g>
    </g>
  </svg>
);

export default SvgNbCompteurVert;
