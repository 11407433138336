import sensorConstants from '../_constants/sensor.constants';
import sensorService from '../_services/sensor.service';

/**
 * Récupère toutes les alarmes du site courant
 *
 * @methd getAll
 * @param {string} rndCode Code du site
 * @returns {Object} Les alarmes (retour ws)
 */
function getAll() {
  function request() {
    return { type: sensorConstants.GETALL_REQUEST };
  }
  function success(sensors: Object) {
    return { type: sensorConstants.GETALL_SUCCESS, sensors };
  }
  function failure(error: Object) {
    return { type: sensorConstants.GETALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    sensorService
      .getAll()
      .then((sensors: any) => {
        dispatch(success(sensors));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getAllByLocationId(locationId: number) {
  function request() {
    return { type: sensorConstants.GETALL_REQUEST };
  }
  function success(sensors: Object) {
    return { type: sensorConstants.GETALL_SUCCESS, sensors };
  }
  function failure(error: Object) {
    return { type: sensorConstants.GETALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    sensorService
      .getAllByLocationId(locationId)
      .then((sensors: any) => {
        dispatch(success(sensors));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getSensorById(id: number) {
  function request() {
    return { type: sensorConstants.GET_BY_ID_REQUEST };
  }
  function success(sensor: Object) {
    return { type: sensorConstants.GET_BY_ID_SUCCESS, sensor };
  }
  function failure(error: Object) {
    return { type: sensorConstants.GET_BY_ID_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    sensorService
      .getSensorById(id)
      .then((sensor: any) => {
        dispatch(success(sensor));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function putOpen(devEUI: any) {
  function request() {
    return { type: sensorConstants.PUT_STREGA_OPEN_REQUEST };
  }
  function success(isOpen: Object) {
    return { type: sensorConstants.PUT_STREGA_OPEN_SUCCESS, isOpen };
  }
  function failure(error: Object) {
    return { type: sensorConstants.PUT_STREGA_OPEN_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    sensorService
      .putOpen(devEUI)
      .then((isOpen: any) => {
        dispatch(success(isOpen));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function putClose(devEUI: any) {
  function request() {
    return { type: sensorConstants.PUT_STREGA_CLOSE_REQUEST };
  }
  function success(isClose: Object) {
    return { type: sensorConstants.PUT_STREGA_CLOSE_SUCCESS, isClose };
  }
  function failure(error: Object) {
    return { type: sensorConstants.PUT_STREGA_CLOSE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    sensorService
      .putClose(devEUI)
      .then((isClose: any) => {
        dispatch(success(isClose));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function putUpLinkFrequency(devEUI: any, content: any) {
  function request() {
    return { type: sensorConstants.PUT_STREGA_UPLINK_FREQUENCY_REQUEST };
  }
  function success(upLinkFrequency: Object) {
    return { type: sensorConstants.PUT_STREGA_UPLINK_FREQUENCY_SUCCESS, upLinkFrequency };
  }
  function failure(error: Object) {
    return { type: sensorConstants.PUT_STREGA_UPLINK_FREQUENCY_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    sensorService
      .putUpLinkFrequency(devEUI, content)
      .then((upLinkFrequency: any) => {
        dispatch(success(upLinkFrequency));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function putUpLinkScheduleTime(devEUI: any, content: any) {
  function request() {
    return { type: sensorConstants.PUT_STREGA_UPLINK_SCHEDULE_TIME_REQUEST };
  }
  function success(schedule: Object) {
    return { type: sensorConstants.PUT_STREGA_UPLINK_SCHEDULE_TIME_SUCCESS, schedule };
  }
  function failure(error: Object) {
    return { type: sensorConstants.PUT_STREGA_UPLINK_SCHEDULE_TIME_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    sensorService
      .putUpLinkScheduleTime(devEUI, content)
      .then((schedule: any) => {
        dispatch(success(schedule));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function putSyncTime(devEUI: any, content: string) {
  function request() {
    return { type: sensorConstants.PUT_STREGA_UPLINK_FREQUENCY_REQUEST };
  }
  function success(syncTime: Object) {
    return { type: sensorConstants.PUT_STREGA_UPLINK_FREQUENCY_SUCCESS, syncTime };
  }
  function failure(error: Object) {
    return { type: sensorConstants.PUT_STREGA_UPLINK_FREQUENCY_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    sensorService
      .putSyncTime(devEUI, content)
      .then((syncTime: any) => {
        dispatch(success(syncTime));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function addAlert(options: any, sensorId: any) {
  function request() {
    return { type: sensorConstants.ADD_ALERT_REQUEST };
  }
  function success(alert: any) {
    return { type: sensorConstants.ADD_ALERT_SUCCESS, alert };
  }
  function failure(error: Object) {
    return { type: sensorConstants.ADD_ALERT_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    sensorService
      .addAlert(options, sensorId)
      .then((alert: any) => {
        dispatch(success(alert));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function clear() {
  return {
    type: sensorConstants.CLEAR,
  };
}

const sensorActions = {
  getAll,
  getAllByLocationId,
  getSensorById,
  putOpen,
  putClose,
  putUpLinkFrequency,
  putUpLinkScheduleTime,
  putSyncTime,
  addAlert,
  clear,
};

export default sensorActions;
