import React from 'react';

const SvgGoutteRadio = (props: any) => (
  <svg viewBox="0 0 500 500" {...props}>
    <path
      fill="#000000"
      d="M364.9,305.6c-24.4,-16.6 -51.4,-26.7 -78.8,-30.6c-11.6,-1.6 -22.3,6.4 -23.9,18c-1.6,11.6 6.4,22.3 18,23.9c21.2,3 42,10.8 60.9,23.6c7.3,5 14,10.5 20.1,16.5c29.4,28.9 44.4,68.6 43,108.5c-0.4,11.7 8.7,21.5 20.4,21.9c6.2,0.2 11.8,-2.2 15.9,-6.3c3.6,-3.6 5.8,-8.6 6,-14.1c1.9,-51.4 -17.5,-102.7 -55.6,-140.2C383,319.2 374.4,312 364.9,305.6z"
    />
    <path
      fill="#000000"
      d="M342.4,376.1c-5,-4.9 -10.4,-9.4 -16.4,-13.4c-14.7,-10 -31,-16.3 -47.5,-18.9c-11.5,-1.9 -22.4,6 -24.2,17.5c-1.9,11.5 6,22.4 17.5,24.2c10.6,1.7 20.9,5.7 30.4,12.1c3.8,2.6 7.4,5.5 10.5,8.6c13.6,13.3 21.2,31 22.3,49.3c0.7,11.7 10.7,20.6 22.4,19.9c5.4,-0.3 10.3,-2.7 13.8,-6.3c4,-4.1 6.4,-9.9 6,-16.1l0,0C375.6,424.7 363.6,397 342.4,376.1z"
    />
    <path
      fill="#000000"
      d="M235.4,427.4c-15.5,15.8 -15.3,41.2 0.5,56.8s41.2,15.3 56.8,-0.5c15.5,-15.8 15.3,-41.2 -0.5,-56.8C276.4,411.4 250.9,411.6 235.4,427.4z"
    />
    <path
      fill={props.fillGoutte}
      d="M218.2,410.6c6.9,-7 15.1,-12 24.1,-15.2c-1.3,-1.4 -2.6,-2.7 -3.7,-4.2c-7.1,-9.8 -9.9,-21.7 -8,-33.7c2.1,-13 9.6,-23.8 20,-30.6c-1.2,-1.2 -2.4,-2.4 -3.4,-3.8c-7.3,-9.7 -10.3,-21.6 -8.6,-33.5c3.4,-24.2 26.2,-41.9 51.1,-38.4c32.1,4.6 62,16.2 88.9,34.5c3.2,2.2 6.3,4.7 9.4,7c-6.1,-41.9 -27.4,-93.4 -63.9,-153.8c-34,-56.1 -71.8,-104.6 -91.3,-128.6c-2.6,-3.2 -6.5,-5.1 -10.7,-5.1c-4.1,0 -8.1,1.9 -10.7,5.1C191.8,34.4 154,83 120.1,139c-43.8,72.3 -66,132 -66,177.6c0,87.4 67.1,159.3 152.6,167.1C195,460 198.6,430.5 218.2,410.6z"
    />
  </svg>
);

export default SvgGoutteRadio;
