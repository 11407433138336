import React from 'react';

const SvgLogoSigfox = (props: any) => (
  <svg viewBox="0 0 941.47 306.29" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={'#1d1d1b'}
          d="M335.08.11a27.71,27.71,0,0,0-8.45.46c-11.16,3.05-22.7,5.22-33.22,9.83C255.47,27,193.51,79.26,182.53,88.64,171.56,79.26,109.6,27,71.65,10.4,61.14,5.79,49.59,3.62,38.43.57A27.65,27.65,0,0,0,30,.11C12.44,1,2.58,11.4,2.19,29.53,2,41.08,4.87,52.08,8.68,62.83c8.37,23.54,20.83,44.82,34.66,65.3a532.4,532.4,0,0,0,47.9,60.18A36,36,0,0,0,96,192.05q-6.94,12.55-13.87,25.12c-10.75,19.76-19.41,40.14-18.63,63.52.48,14.31,11,25.1,21.83,25.49,5.15,0,10.3.08,15.44.11,5.73-1.35,11.45-2.71,17.18-4,6.12-2.39,12.24-4.75,18.36-7.13q10.26-5.36,20.53-10.73,7.5-4.68,15-9.34,5.34-2.87,10.67-5.7,5.34,2.83,10.68,5.7,7.5,4.65,15,9.34l20.53,10.73c6.12,2.38,12.25,4.74,18.37,7.13,5.73,1.33,11.44,2.69,17.18,4,5.13,0,10.28-.09,15.44-.11,10.82-.39,21.34-11.18,21.83-25.49.77-23.38-7.89-43.76-18.64-63.52Q276,204.6,269,192.05a36.79,36.79,0,0,0,4.79-3.74,532.4,532.4,0,0,0,47.9-60.18c13.83-20.48,26.29-41.76,34.65-65.3,3.82-10.75,6.72-21.75,6.49-33.3C362.48,11.4,352.63,1,335.08.11Z"
        />
        <path
          fill={'#1d1d1b'}
          d="M645.64,105.77c-19.62-4.44-38.95-8.86-58.77-3-17.76,5.27-28.55,17.75-31.51,36.34a80.63,80.63,0,0,0,.15,25.24c3.47,21.39,17.13,33.4,38.34,34.09,7.49.26,15-1.35,22.58-2.13,1,13-4.78,20.17-17.57,21.16-11.68.89-23-1.19-34.75-5.49-1.7,8.43-3.27,16.29-4.88,24.35,14,4.88,27.68,7,41.73,6,29.9-2,44.22-17.49,44.6-48.37.26-18.79.08-37.6.08-56.41Zm-29.25,65.51c-5.87,4-12.41,4.41-19.12,3.25s-10.82-5.83-12.61-12.4a41.09,41.09,0,0,1,.14-22.64c3.08-10.46,9.78-15.59,20.44-15.77,3.54,0,7.09.39,11.15.64Z"
        />
        <path
          fill={'#1d1d1b'}
          d="M793.18,99.92c-23.34-1.84-42.32,9.76-50.44,31-5.56,14.64-5.56,29.54-.72,44.32,7,21.34,25.13,33.78,47.54,33.06,23.05-.71,39.92-14.16,45.56-36.67,1.37-5.59,1.82-11.44,2.69-17.12-.76-5.69-1.12-11.27-2.31-16.66C830.76,116.52,813.92,101.56,793.18,99.92Zm10.58,73.62c-6.67,13.12-24.43,13.35-31.36.35-6.84-12.82-7-26.55-.3-39.47a17.75,17.75,0,0,1,31.95.32c2.88,5.93,3.6,13,5.36,19.8C807.51,161.17,806.64,167.89,803.76,173.54Z"
        />
        <path
          fill={'#1d1d1b'}
          d="M718.37,78.61c4.26.43,8.46,1.49,13.11,2.33,1.8-7.66,3.51-15,5.46-23.33-7.28-1.41-13.73-3.4-20.3-3.83-32.06-2.07-48.29,13.71-48.33,46.62q-.07,48.87,0,97.71c0,2.3.24,4.63.37,7h28.51V126.5h34.43v-24H697.7C695.1,84.93,702.15,77,718.37,78.61Z"
        />
        <path
          fill={'#1d1d1b'}
          d="M941.47,103H910.85l-19.56,28.18-19.16-27.95h-30.7l34.48,50.5-34.7,50.83h30.65c6.47-9.36,12.78-18.5,19.54-28.25,7,10.15,13.37,19.36,19.47,28.19h30.6c-11.83-17.36-23.16-34-34.65-50.77Z"
        />
        <path
          fill={'#1d1d1b'}
          d="M477.26,151.57c-5.79-3.37-12-5.93-18.05-8.83-3.72-1.78-7.77-3.06-11.06-5.46a10.79,10.79,0,0,1-3.88-7c-.29-3.6,2.81-5.56,6-6.14a34.89,34.89,0,0,1,10-.62c6.82.8,13.56,2.13,21,3.35,1.57-7.07,3.14-14.16,5-22.56-8.12-1.55-15.11-3.35-22.22-4.15-10-1.1-20.09-.89-29.6,3.24-10.79,4.69-17.57,12.72-18.46,25.12-.93,13,3.57,23.4,15,29.77,4.72,2.64,9.87,4.48,14.78,6.76,4.2,1.94,8.48,3.82,12.53,6.07,2.9,1.62,5,4.61,3.43,7.86-1.09,2.17-4,4.65-6.18,4.77a114.5,114.5,0,0,1-20.69-.64c-5.52-.68-10.89-2.66-17-4.23-1.53,7.89-3,15.48-4.63,23.6,6.16,1.66,11.26,3.56,16.52,4.37,14.36,2.29,28.75,2.87,42.62-2.61,10.38-4.09,17-11.58,17.9-23.54C491.19,168.48,488.28,158,477.26,151.57Z"
        />
        <rect fill={'#1d1d1b'} x="508.13" y="103.12" width="27.62" height="102.26" />
        <path
          fill={'#1d1d1b'}
          d="M521.87,55.42c-9.95,0-17.08,7.32-17,17.47S512.25,90.2,522.2,90c9.53-.17,16.6-7.42,16.72-17.08C539,63,531.69,55.42,521.87,55.42Z"
        />
        <path
          fill={'#211265'}
          d="M270.18,190.86C360,99.08,368.23,46,364.29,21.49A25.38,25.38,0,0,0,342,.31C279.81-6.13,182.53,90.14,182.53,90.14S233.78,136,270.18,190.86Z"
        />
        <path
          className="cls-3"
          d="M291.84,229a289.49,289.49,0,0,0-21.66-38.16C233.78,136,182.53,90.14,182.53,90.14v178.3s54.66,41.22,102.14,37.63C284.67,306.07,324.1,298.91,291.84,229Z"
        />
        <path
          className="cls-4"
          d="M94.88,190.86C5,99.08-3.17,46,.78,21.49A25.37,25.37,0,0,1,23.08.31C85.26-6.13,182.53,90.14,182.53,90.14S131.29,136,94.88,190.86Z"
        />
        <path
          fill={'#211265'}
          d="M73.22,229a289.49,289.49,0,0,1,21.66-38.16C131.29,136,182.53,90.14,182.53,90.14v178.3s-54.65,41.22-102.14,37.63C80.39,306.07,41,298.91,73.22,229Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgLogoSigfox;

/*
    <defs>
      <style>.cls-3{fill:url(#Dégradé_sans_nom_8);}.cls-4{fill:url(#Dégradé_sans_nom_6);}</style>
      <linearGradient id="Dégradé_sans_nom_8" x1="182.53" y1="198.22" x2="304.53" y2="198.22"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#4377fe"/>
        <stop offset="1" stop-color="#734bf9"/>
      </linearGradient>
      <linearGradient id="Dégradé_sans_nom_6" y1="95.43" x2="182.53" y2="95.43" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#02daff"/>
        <stop offset="1" stop-color="#407fff"/>
      </linearGradient>
    </defs>
 */
