const remoteReadingConstants = {
  GET_DATA_SUCCESS: 'REMOTE_READING_GET_DATA_SUCCESS',

  GET_GENERIC_DATA_REQUEST: 'REMOTE_READING_GET_GENERIC_DATA_REQUEST',
  GET_GENERIC_DATA_SUCCESS: 'REMOTE_READING_GET_GENERIC_DATA_SUCCESS',
  GET_GENERIC_DATA_FAILURE: 'REMOTE_READING_GET_GENERIC_DATA_FAILURE',

  CLEAR: 'REMOTE_READING_CLEAR',
};

export default remoteReadingConstants;
