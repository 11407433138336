import { alertConstants } from '../_constants';

/**
 * Gère l'alerte en cas de succès
 *
 * @method success
 * @param {Object} message Message
 * @returns {Object} L'action
 */
function success(message: Object) {
  return {
    type: alertConstants.SUCCESS,
    message,
  };
}

/**
 * Gère l'alerte en cas d'erreur
 *
 * @method error
 * @param {Object} message Message
 * @returns {Object} L'action
 */
function error(message: Object) {
  return {
    type: alertConstants.ERROR,
    message,
  };
}

function configAlert(alertName: string) {
  function success(name: Object) {
    return { type: alertConstants.CONFIG_ALERT_SUCCESS, name };
  }
  return (dispatch: Function) => {
    dispatch(success(alertName));
  };
}

/**
 * Vide le state des alertes
 *
 * @method clear
 */
function clear() {
  return {
    type: alertConstants.CLEAR,
  };
}

const alertActions = {
  success,
  error,
  configAlert,
  clear,
};

export default alertActions;
