import React from 'react';

export default function DragIcone() {
  return (
    <div className="draggable-icone-format">
      <ul>
        <li>
          <div className="draggable-icone-row">
            <div className="drag-point" />
            <div className="drag-point" />
            <div className="drag-point" />
          </div>
        </li>
        <li>
          <div className="draggable-icone-row">
            <div className="drag-point" />
            <div className="drag-point" />
            <div className="drag-point" />
          </div>
        </li>
        <li>
          <div className="draggable-icone-row">
            <div className="drag-point" />
            <div className="drag-point" />
            <div className="drag-point" />
          </div>
        </li>
      </ul>
    </div>
  );
}
