import locationConstants from '../_constants/location.constants';
import { action } from '../_interfaces/action';

export default function contacts(state = {}, action: action) {
  switch (action.type) {
    case locationConstants.GETCONTACT_SUCCESS:
      return {
        items: action.contacts,
      };
    case locationConstants.GETCONTACT_FAILURE:
      return {
        error: "Impossible d'obtenir les informations du contact",
      };
    default:
      return state;
  }
}
