import React from 'react';

const SvgSaphirAttention = (props: any) => (
  <svg viewBox="0 0 35.65 30.93" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_2-2" data-name="Calque 2">
        <circle fill={props.fill} cx="15.14" cy="15.14" r="15.14" />
        <path
          fill="#fff"
          d="M20.86,18.47a3.71,3.71,0,0,1-1.57,3,6.2,6.2,0,0,1-4,1.24,6.3,6.3,0,0,1-3.65-1.08A6,6,0,0,1,9.42,18.8l2.3-1a4.24,4.24,0,0,0,1.48,1.92,3.58,3.58,0,0,0,2.12.69,3.7,3.7,0,0,0,2.07-.53,1.53,1.53,0,0,0,.83-1.27c0-.88-.67-1.52-2-1.94l-2.37-.59c-2.68-.67-4-2-4-3.88a3.59,3.59,0,0,1,1.53-3,6.42,6.42,0,0,1,3.93-1.14,6.36,6.36,0,0,1,3.3.87,4.71,4.71,0,0,1,2.07,2.33l-2.31,1a2.9,2.9,0,0,0-1.28-1.36,4,4,0,0,0-2-.5,3.29,3.29,0,0,0-1.81.51,1.46,1.46,0,0,0-.81,1.24c0,.79.75,1.35,2.23,1.69l2.08.53C19.5,15,20.86,16.38,20.86,18.47Z"
        />
        <g id="Calque_1-2" data-name="Calque 1">
          <path d="M28.25,16.15a7.39,7.39,0,1,0,7.4,7.39,7.42,7.42,0,0,0-7.4-7.39ZM29,27.23H27.52V25.76H29Zm0-2.95H27.52V19.84H29Z" />
          <rect fill="#fff" x="27.52" y="19.84" width="1.48" height="4.43" />
          <rect fill="#fff" x="27.52" y="25.76" width="1.48" height="1.48" />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgSaphirAttention;
