import { EventData, Map, MapLayerEventType, Popup } from 'mapbox-gl';
import React from 'react';
import popups from './popup';

export default function events(
  map: Map,
  eventType: keyof MapLayerEventType,
  layerName: string,
  popup: Popup,
  t: Function,
  displayCluster: any
): void {
  const eventFunction = (e: any) => {
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
    map.getCanvas().style.cursor = 'pointer';
    const coordinates = e.features[0].geometry.coordinates.slice();
    const meterSerial = e.features[0].properties.meterSerial;
    const clpReference = e.features[0].properties.clpReference;
    const readData = e.features[0].properties.lastRead;
    const gatewayName = e.features[0].properties.gatewayName || e.features[0].properties.name;
    const count = e.features[0].properties;
    let popupImage;
    let content: string = '';

    switch (layerName) {
      case 'unclustered-point':
        content = popups.unclusteredPointEnter(
          meterSerial,
          clpReference,
          readData && readData !== '{}' ? JSON.parse(readData) : null
        );
        popups.createPopup(popup, coordinates, map, content, e, 'unclustered');
        break;
      case 'gateway-range':
        const rssi = e.features[0].properties.rssi;
        content = popups.links(gatewayName, meterSerial, rssi);
        popups.createPopup(popup, coordinates, map, content, e, 'gateway-range');
        break;
      case 'cluster':
        popupImage = popups.popupImageGenerator(count, t);
        content = popups.clusterPointEnter(t) + popupImage;
        popups.createPopup(popup, coordinates, map, content, e, 'cluster');
        break;
      case 'gateway-layer':
        popupImage = popups.popupImageGenerator(count, t);
        content = popups.gateways(gatewayName, t);
        popups.createPopup(popup, coordinates, map, content, e, 'gateway');
        break;
      default:
        break;
    }
  };
  map.off(eventType, layerName, eventFunction);
  map.on(eventType, layerName, eventFunction);
}
