export function urlGiveAway(chartLabel: string, t: Function) {
  let giveAway = '';
  console.log('urlGiveAway::urlGiveAway::3', chartLabel, t('all.text.edited_not_indexed_plural'));
  switch (chartLabel) {
    case t('all.text.indexed_plural'):
      giveAway = 'indexe';
      break;
    case t('all.text.indexed'):
      giveAway = 'indexe';
      break;
    case t('all.text.not_indexed_plural'):
      giveAway = 'noIndex';
      break;
    case t('all.text.not_indexed'):
      giveAway = 'noIndex';
      break;
    case t('all.text.edited_not_indexed_plural'):
      giveAway = 'noIndex';
      break;
    case t('tournee_stat.container_label.not_edited_not_indexed_plural'):
      giveAway = 'noUpdate';
      break;
    case t('tournee_stat.container_state_read.read_with_no_alarm'):
      giveAway = 'withoutAlarm';
      break;
    case t('tournee_stat.container_state_read.not_radio'):
      giveAway = 'noAmr';
      break;
    case t('tournee_stat.container_state_read.read_with_alarm'):
      giveAway = 'withAlarm';
      break;
    case t('tournee_stat.container_state_read.not_read'):
      giveAway = 'noReadAmr';
      break;
    case t('all.read_meter.high_cons'):
      giveAway = `conso=${t('static_list.value.strong_cons')}`;
      break;
    case t('all.read_meter.normal_cons'):
      giveAway = `conso=${t('static_list.value.normal_cons')}`;
      break;
    case t('all.read_meter.low_cons'):
      giveAway = `conso=${t('static_list.value.weak_cons')}`;
      break;
    case t('all.read_meter.empty_cons'):
      giveAway = `conso=${t('static_list.value.nul_cons')}`;
      break;
    case t('all.text.modified_plural'):
      giveAway = 'update';
      break;
    case t('all.text.modified'):
      giveAway = 'update';
      break;
    case t('all.text.not_modified_plural'):
      giveAway = 'noUpdate';
      break;
    case t('all.text.not_modified'):
      giveAway = 'noUpdate';
      break;
    case t('all.text.gps'):
      giveAway = 'geolocalisation';
      break;
    case t('all.text.gps_plural'):
      giveAway = 'geolocalisation';
      break;
    case t('all.text.no_gps'):
      giveAway = 'nogeolocalisation';
      break;
    case t('all.text.no_gps_plural'):
      giveAway = 'nogeolocalisation';
      break;
  }
  return giveAway;
}
