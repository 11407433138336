import MarkerClusterer from '@googlemaps/markerclustererplus';
import getC from '../TourneeDetail/Icon_correspondance';
import { Component } from 'react';

/**
 * Rend le composant gérant le multi marqueurs
 *
 * @method MarkerClustererComponent
 * @param {any} props Props du composant
 */

export default class MarkerClustererComponent extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      clusters: undefined,
      init: false,
    };
  }

  static getDerivedStateFromProps(props: any, state: any) {
    if (props && props.map) {
      if (state.clusters) state.clusters.clearMarkers();

      if (props.markersToCreate) {
        const markers = MarkerClustererComponent.generateMarkers(props);
        return {
          clusters: MarkerClustererComponent.generateMarkerCluster(props, markers),
          init: true,
        };
      }
    }
  }

  static generateMarkers(props: any) {
    const { map, markersToCreate } = props;

    if (map) {
      return markersToCreate.map((m: any) => MarkerClustererComponent.createMarker(m, props));
    }
  }

  static generateMarkerCluster(props: any, markers: any) {
    const { map } = props;

    if (map) {
      const options = {
        imagePath: '/images/m',
      };

      return new MarkerClusterer(map, markers, options);
    }
  }

  static createMarker(m: any, props: any) {
    const { map, google, history, clickable, handleClick } = props;

    try {
      const marker: any = new google.maps.Marker({
        position: new google.maps.LatLng(
          m.gpsPosition ? m.gpsPosition.lat : null,
          m.gpsPosition ? m.gpsPosition.lng : null
        ),
        map,
        title: m.serial,
        icon: m.icon && {
          url: getC(m.icon),
          anchor: new google.maps.Point(16, 24),
          scaledSize: new google.maps.Size(m.icon == 'marqueurClic' ? 64 : 32, m.icon == 'marqueurClic' ? 64 : 32),
        },
      });

      if (clickable && marker) {
        let toPath = '';

        if (history.location.pathname.includes('/synchronisation/tournees')) {
          toPath = `${history.location.pathname}/fiche/${m.ficheId}`;
        } else {
          toPath = `${history.location.pathname.match(/\/locations\/\d+/)[0]}/pdi/${m.pdiId}`;
        }

        marker.addListener('dblclick', () => {
          history.push({
            pathname: toPath,
          });
        });
        marker.addListener('click', (e: any) => {
          handleClick(e, m);
        });
      }
      return marker;
    } catch (error) {
      console.log(error);
    }
    return null;
  }

  render() {
    return false;
  }
}
