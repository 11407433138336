import React from 'react';

const SvgTourneeWaitAffect = (props: any) => (
  <svg viewBox="0 0 115.12 134.15" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M112.24,14.77h-10V2.87A2.88,2.88,0,0,0,101.09.59,2.91,2.91,0,0,0,98.58.1L53,14.49a2.86,2.86,0,0,0-1.46,1,2.84,2.84,0,0,0-1.05,2.19V57.83A57.1,57.1,0,0,0,16.06,78.31C5.7,91.05,0,108.5,0,127.45a6.67,6.67,0,0,0,5.62,6.61,6.36,6.36,0,0,0,1,.09h0A6.68,6.68,0,0,0,13,129.57c6.24-18.72,19.75-28.66,41.25-30.3v12.22a6.69,6.69,0,0,0,11.87,4.23L86,91.51h26.28a2.88,2.88,0,0,0,2.88-2.88v-71A2.88,2.88,0,0,0,112.24,14.77ZM57.67,92.08c-25.8.84-42.83,12.11-50.65,33.5.66-31.74,18-55.52,43.49-60.65A53.31,53.31,0,0,1,58,64l3.31-.19V45.23l28,31.14L76.92,91.51,61.29,110.62V92Zm10-77.29L96.46,6.68v8.11Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgTourneeWaitAffect;
