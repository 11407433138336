import TourneesVertes from '../SvgComponents/TourneesVertes';
import React from 'react';
import SvgCompteurVert from '../SvgComponents/CompteurVert';
import SvgRadioVert from '../SvgComponents/RadioVert';
import SvgMessageVert from '../SvgComponents/MessageVert';
import SvgDiametreVert from '../SvgComponents/DiametreVert';
import SvgMarquagesVert from '../SvgComponents/MarquageVert';
import SvgFabricantVert from '../SvgComponents/FabricantVert';
import SvgModeleVert from '../SvgComponents/ModeleVert';
import SvgPoidsImpulsionVert from '../SvgComponents/PoidsImpulsionVert';
import SvgCalendrierVert from '../SvgComponents/CalendrierVert';
import CodeBarreVert from '../SvgComponents/CodeBarreVert';
import SvgNoteVert from '../SvgComponents/NoteVert';
import SvgInfoMeter from '../SvgComponents/InfoMeter';
import SvgInfoPdi from '../SvgComponents/InfoPdi';

function getC(key, props) {
  switch (key) {
    case 'pdi_info':
      return <SvgInfoPdi {...props} />;
    case 'meter_info':
      return <SvgInfoMeter {...props} />;
    case 'marquage':
      return <SvgMarquagesVert {...props} />;
    case 'diameter':
      return <SvgDiametreVert {...props} />;
    case 'wheels':
      return <SvgModeleVert {...props} />;
    case 'manufacturer':
      return <SvgFabricantVert {...props} />;
    case 'modelmeter':
      return <SvgCompteurVert {...props} />;
    case 'message':
      return <SvgMessageVert {...props} />;
    case 'internalserialradio':
      return <CodeBarreVert {...props} />;
    case 'modelradio':
      return <SvgRadioVert {...props} />;
    case 'pulse':
      return <SvgPoidsImpulsionVert {...props} />;
    case 'date':
      return <SvgCalendrierVert {...props} />;
    case 'note':
      return <SvgNoteVert {...props} />;

    default:
      break;
  }
}

export default getC;
