import React from 'react';

const StrongCons = (props: any) => (
  <svg viewBox="0 0 108.18 117.52" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="pictos">
        <path
          fill={props.fill}
          d="M61.66,109.93V25.76L95.31,59.41A7.54,7.54,0,1,0,106,48.74L59.44,2.21a7.53,7.53,0,0,0-10.66,0L2.22,48.74a7.55,7.55,0,1,0,10.66,10.7L46.54,25.78V110a7.56,7.56,0,1,0,15.12,0"
        />
      </g>
    </g>
  </svg>
);
export default StrongCons;
