import React from 'react';
import { Button, Form, FormGroup, Label, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { connect } from 'react-redux';
import translate from '../_helpers/locale-helpers';
import { cloneDeep } from 'lodash';
import InformationsBleu from '../SvgComponents/InformationsBleu';
import { Condition } from '../_entities/widget';
import { withTranslation } from 'react-i18next';

interface State {
  content: any;
  templates: any[];
}

/**
 * @class TypeChooserComponent
 * @extends {React.Component<Props>}
 */
class TypeChooserComponent extends React.Component<any, State> {
  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof TypeChooserComponent
   */
  constructor(props: any) {
    super(props);
    let content = cloneDeep(props.content);
    let templates = cloneDeep(props.alarmTemplates);
    this.resetValues(content, templates);
    this.state = {
      content,
      templates,
    };
  }

  /**
   * Réinitialise toutes les valeurs des templates passés au composant
   *
   * @method resetValues
   * @param {any} content Contenu
   * @param {Array<any>} templates Templates
   */
  resetValues = (content: any, templates: any[]) => {
    if (Object.keys(content).length > 0 && content.dataSourceProperty) {
      content.dataSourceProperty.condition = content.dataSourceProperty.condition.map((it: Condition) => {
        it.conditionValue = null;
        return it;
      });
    }

    if (templates && templates.length > 0) {
      templates.map(tpl => {
        tpl.content.dataSourceProperty.condition = tpl.content.dataSourceProperty.condition.map((it: Condition) => {
          it.conditionValue = null;
          return it;
        });
        return tpl;
      });
    }
  };

  /**
   * Met à jour le template avec la datasource
   * et passe à l'étape suivante
   *
   * @param {any} e Evènement
   * @method send
   * @memberof TypeChooserComponent
   */
  send = (e: any) => {
    e.preventDefault();
    const { update, nextStep } = this.props;
    const { content } = this.state;
    update('content', content);
    nextStep();
  };

  /**
   * Gère le comportement au changement de la datasource
   *
   * @param {Object} event Evènement
   * @method handleOptionChange
   * @memberof TypeChooserComponent
   */
  handleOptionChange = (event: any) => {
    const { templates } = this.state;
    const finded = cloneDeep(templates).filter((item: any) => event.target.value === item.content.alertName);
    if (finded && finded.length > 0) {
      this.setState({
        content: finded[0].content,
      });
    }
  };

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof TypeChooserComponent
   */
  render() {
    const { locales, t } = this.props;
    const { content, templates } = this.state;
    return (
      <div>
        {templates && (
          <Form onSubmit={this.send}>
            <h4>Choix du type d'alerte</h4>
            <div className="datasourceChoices">
              {templates.map((item: any) => (
                <div className="templateChoices" key={item.content.alertName}>
                  <FormGroup check key={item.content.alertName}>
                    <Label check>
                      {' '}
                      <input
                        type="radio"
                        id={item.content.alertName}
                        name={item.content.alertName}
                        value={item.content.alertName}
                        onChange={this.handleOptionChange}
                        checked={content.alertName === item.content.alertName}
                      />{' '}
                      {translate('fr', 'alarmName', item.content.alertName, locales.locale)} -{' '}
                      {translate('fr', 'unit', item.content.dataSourceProperty.alertUnit, locales.locale)}{' '}
                      <span id={`calculate-${item.id}`}>
                        <InformationsBleu
                          className="add"
                          stroke="#808080"
                          height="0.8em"
                          width="0.8em"
                          strokeWidth="1"
                        />
                      </span>
                      <UncontrolledTooltip placement="bottom" target={`calculate-${item.id}`}>
                        &nbsp;&nbsp; {translate('fr', 'alarmCalculation', item.content.alertName, locales.locale)}
                      </UncontrolledTooltip>
                    </Label>
                  </FormGroup>
                </div>
              ))}
            </div>
            <div className="bottomChoice">
              <Row>
                <Col md="12" className="text-right">
                  <Button color="primary">{t('all.alert.alert_conf')}</Button>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { locales } = state;
  return {
    locales,
  };
}

const TypeChooserConnectedComponent = connect(mapStateToProps)(TypeChooserComponent);
const tr = withTranslation()(TypeChooserConnectedComponent);
export default tr;
