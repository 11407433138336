import React from 'react';

const SvgSynchroHeader = (props: any) => (
  <svg viewBox="0 0 14.81 14.02" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M14.38,1.22a.54.54,0,0,0-.64.41l-.39,1.82A7,7,0,0,0,.34,7,.54.54,0,0,0,1.41,7a5.91,5.91,0,0,1,11-3l-1.79-.39a.54.54,0,0,0-.64.42.53.53,0,0,0,.41.63l3.08.66a.29.29,0,0,0,.16,0,.43.43,0,0,0,.24-.08.55.55,0,0,0,.24-.34l.65-3.07a.53.53,0,0,0-.41-.64Z"
        />
        <path
          fill={props.fill}
          d="M13.84,6.28a.54.54,0,0,0-.52.56,5.91,5.91,0,0,1-5.71,6.1h-.2a5.89,5.89,0,0,1-5-2.8l1.87.5a.54.54,0,0,0,.28-1l-3-.81a.54.54,0,0,0-.41.05.56.56,0,0,0-.25.33l-.81,3a.53.53,0,0,0,.38.66l.14,0a.54.54,0,0,0,.52-.4l.45-1.71A7,7,0,0,0,7.41,14h.23A7,7,0,0,0,14.4,6.8a.54.54,0,0,0-.56-.52Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgSynchroHeader;
