import React, { Fragment } from 'react';
import DiametreVert from '../SvgComponents/DiametreVert';
import ModeleVert from '../SvgComponents/ModeleVert';
import FabricantVert from '../SvgComponents/FabricantVert';

type Props = {
  meter: any;
};

export default function TinyMeter(props: Props) {
  const { meter } = props;
  return (
    <Fragment>
      {meter && Object.keys(meter).length > 0 && (
        <div className="row">
          <div className="col-lg-12">
            <div className="presentation-container">
              <div className="presentation-header">
                <span className="presentation-title">Numéro de série du compteur</span>
                <span className="presentation-main-title">{meter.serial}</span>
              </div>
              <div className="presentation-body">
                <div className="display-info">
                  <div className="row" style={{ marginLeft: '10px' }}>
                    <div className="col-md-3" style={{ marginTop: '10px' }}>
                      <DiametreVert height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />{' '}
                    </div>
                    <div className="col-md-9">
                      <p>
                        <span className="infoLibelle">Diamètre </span> <br /> &nbsp;{' '}
                        <span className="infoDisplay">{meter.diameter} </span>
                      </p>
                    </div>
                  </div>
                  <div className="row" style={{ marginLeft: '10px' }}>
                    <div className="col-md-3" style={{ marginTop: '10px' }}>
                      <ModeleVert height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />{' '}
                    </div>
                    <div className="col-md-9">
                      <p>
                        <span className="infoLibelle">Modèle </span> <br /> &nbsp;{' '}
                        <span className="infoDisplay">{meter.model} </span>
                      </p>
                    </div>
                  </div>
                  <div className="row" style={{ marginLeft: '10px' }}>
                    <div className="col-md-3" style={{ marginTop: '10px' }}>
                      <FabricantVert height="2em" width="2em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />{' '}
                    </div>
                    <div className="col-md-9">
                      <p>
                        <span className="infoLibelle">Fabricant </span> <br /> &nbsp;{' '}
                        <span className="infoDisplay">
                          {meter.manufacturer === 'UNKNOWN' ? 'Inconnu' : meter.manufacturer}{' '}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
