import React from 'react';

interface configType {
  type: string;
  oldValue: string;
}

const BillingGeneralConfig: React.FC<{
  columnSize: string | number;
  endOfFirstCell: string;
  firstCellColumn: string | number;
  firstCellRow: string | number;
  firstLineNumber: string | number;
  headerSize: string | number;
  t: any;
}> = ({ columnSize, endOfFirstCell, firstCellColumn, firstCellRow, firstLineNumber, headerSize, t }) => {
  return (
    <div className="allBlockInput" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
      <div className="inputBlock" style={{ margin: 0 }}>
        <h4 style={{ margin: 0 }}>{t('import_billing.label.id_cell')}</h4>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%', textAlign: 'end', paddingRight: '10px' }}>
            <div className="textBlock">
              {t('import_billing.label.id_cell_row')}: {firstCellRow}
            </div>
            <div className="textBlock">
              {t('import_billing.label.id_cell_col')}: {firstCellColumn}
            </div>
          </div>
          <div style={{ width: '50%', paddingLeft: '10px', borderLeft: 'solid white 1px' }}>
            <div className="textBlock">{t('import_billing.label.id_cell_content')}:</div>
            <div className="textBlock">"{endOfFirstCell}"</div>
          </div>
        </div>
      </div>
      <div className="inputBlock" style={{ margin: 0 }}>
        <div className="textBlock">
          {t('import_billing.label.header_size')}: {headerSize}
        </div>
        <div className="textBlock">
          {t('import_billing.label.first_line_number')}: {firstLineNumber}
        </div>
        <div className="textBlock">
          {t('import_billing.label.column_size')}: {columnSize}
        </div>
      </div>
    </div>
  );
};

export default BillingGeneralConfig;
