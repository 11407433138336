import React, { Component } from 'react';
import { Label } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import Select from 'react-select';
import { formatAlarmTypes } from '../_helpers/alarms-types-helper';
import alarms from '../_shared/Alarms.json';
import { locales } from '../_interfaces/reducers';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

interface Props {
  template: any;
  update: Function;
  locales: locales;
  t: Function;
}

interface State {
  baseTypes: Array<any>;
  selectedTypes: Array<any> | null | undefined;
}

/**
 * Formate les labels de groupes
 *
 * @param {Object} data
 * @returns {JSX}
 */
function formatGroupOptions(data: any) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <span style={{ fontWeight: 'bold', color: 'black' }}>{data.label}</span>
    </div>
  );
}

/**
 * @class AlarmSelector
 * @extends {Component}
 */
class AlarmSelector extends Component<Props, State> {
  /**
   * Formate les types d'alarmes pour les rendre
   * exploitables par le composant Select, et créé
   * l'instance
   *
   * @param {Object} props Propriétés
   * @memberof AlarmSelector
   */
  constructor(props: Props) {
    super(props);
    const baseTypes = formatAlarmTypes(alarms.conditionHelper, props.locales.locale, props.t);
    this.state = {
      baseTypes,
      selectedTypes: null,
    };
  }

  /**
   * Met à jour le template d'alarmes à chaque mise à
   * jour de la sélection et le renvoie au composant
   * parent
   *
   * @method handleUpdate
   * @memberof AlarmSelector
   */
  handleUpdate = (selectedTypes: any) => {
    const { template, update } = this.props;
    this.setState({ selectedTypes });
    if (_.isArray(selectedTypes)) {
      template.dataSourceProperty.displayProperty.condition[3].conditionValue = selectedTypes.map(
        (el: any) => el.value
      );
    }
    update(template, selectedTypes || []);
  };

  /**
   * Rend le composant
   *
   * @method render
   * @returns {JSX} le composant
   * @memberof AlarmSelector
   */
  render() {
    const { baseTypes, selectedTypes } = this.state;
    const { t } = this.props;

    return (
      <AvForm className="row">
        <div className="col-md-6">
          <Label for="alarmTypes">{t('all.text.type_plural')}</Label>
          <Select
            value={selectedTypes}
            formatGroupLabel={formatGroupOptions}
            id="alarmTypes"
            name="alarmTypes"
            onChange={this.handleUpdate}
            options={baseTypes}
            isMulti
            placeholder={t('alarm_selector.text.type_to_display_plural')}
          />
        </div>
      </AvForm>
    );
  }
}

const tr = withTranslation()(AlarmSelector);
export default tr;
