import React, { Component } from 'react';
import TourneesVertes from '../SvgComponents/TourneesVertes';
import SvgCroixNoir from '../SvgComponents/croixNoir';
import Label from '../_components/DragDrop/TaskComponent/Label';

export default class ActionContainer extends Component<any, any> {
  render() {
    const { affectList, desaffectList, closeActions, cancelOne } = this.props;
    return (
      <div className={'actions-container'}>
        <div className={'title-action'}>
          <TourneesVertes fill={'#31c6b3'} height={'2em'} style={{ width: '10%' }} />
          <h3 style={{ width: '30%' }}>Actions</h3>
          <div style={{ width: '55%' }}>
            <div className={'round'} onClick={closeActions}>
              <SvgCroixNoir fill={'#31c6b3'} height={'2em'} />
            </div>
          </div>
        </div>
        {affectList.length > 0 && (
          <div className={'title-affect'}>
            <h5>Affectations:</h5>
            <div>
              {affectList.map((el: any) => (
                <Label label={el.rndCode} loaded={false} isAffect={true} cancelOne={cancelOne} />
              ))}
            </div>
          </div>
        )}
        {desaffectList.length > 0 && (
          <div className={'title-affect'}>
            <h5>Desaffectations:</h5>
            <div>
              {desaffectList.map((el: any) => (
                <Label label={el.rndCode} loaded={false} isAffect={false} cancelOne={cancelOne} />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}
