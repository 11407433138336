import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import { withTranslation } from 'react-i18next';

interface Props {
  chartInfo: any;
  handleClick: Function;
}

class BarChart extends Component<Props> {
  render() {
    const { chartInfo, handleClick, t } = this.props;

    const options: any = {
      emptyOverlay: {
        message: t('all.text.no_data_available'),
      },
      maintainAspectRatio: false,
      responsive: true,
      layout: {
        padding: {
          top: 20,
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          display(ctx: any) {
            return ctx.dataset.data[ctx.dataIndex] !== 0; // or >= 1 or ...
          },
          color(ctx: any) {
            // use the same color as the border
            return ctx.dataset.backgroundColor;
          },
          borderWidth: 0,
          anchor: 'end',
          clamp: true,
          align: 'end',
        },
        t1: true,
      },
    };

    if (handleClick !== undefined) {
      options.onClick = (event: any, chart: any) => handleClick(event, chart, chartInfo);
    }

    const dataS = {
      labels: chartInfo.labels,
      datasets: [
        {
          label: chartInfo.label ? chartInfo.label : 'Untitled',
          data: chartInfo.data,
          backgroundColor: chartInfo.backgroundColor ? chartInfo.backgroundColor : 'lightgrey',
          borderColor: chartInfo.borderColor ? chartInfo.borderColor : 'lightgrey',
          borderWidth: chartInfo.borderWidth ? chartInfo.borderWidth : 1,
          hoverBackgroundColor: chartInfo.hoverBackgroundColor ? chartInfo.hoverBackgroundColor : '#333',
          hoverBorderColor: chartInfo.hoverBorderColor ? chartInfo.hoverBorderColor : '#333',
        },
      ],
    };
    return (
      <div className="doughnut-chart bar">
        <div className="chart-container" style={{ height: '100%', width: '100%', marginTop: 0 }}>
          <h3>{chartInfo.title}</h3>
          <Bar data={dataS} options={options} />
        </div>
      </div>
    );
  }
}

const tr = withTranslation()(BarChart);
export default tr;
