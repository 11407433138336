import React from 'react';

const SvgDetailMosaique = (props: any) => (
  <svg version="1.1" viewBox="0 0 16.1 16.1" {...props}>
    <g>
      <path
        fill={props.fill}
        d="M4,8.1c0,0.7,0,1.3,0,2c-1.3,0-2.7,0-4,0c0,0,0,0,0,0c0-1.3,0-2.7,0-4c0,0,0,0,0,0c1.3,0,2.7,0,4,0
		C4,6.7,4,7.4,4,8.1z"
      />
      <path
        fill={props.fill}
        d="M4,2c0,0.7,0,1.3,0,2C2.7,4,1.3,4,0,4c0,0,0,0,0,0c0-1.3,0-2.7,0-4c0,0,0,0,0,0c1.3,0,2.7,0,4,0
		C4,0.7,4,1.3,4,2z"
      />
      <path
        fill={props.fill}
        d="M2,12.1c0.7,0,1.3,0,2,0c0,1.3,0,2.7,0,4c-1.3,0-2.7,0-4,0c0-1.3,0-2.7,0-4C0.7,12.1,1.4,12.1,2,12.1z"
      />
      <path
        fill={props.fill}
        d="M6,8c0-2.7,0-5.3,0-8c3.3,0,6.7,0,10,0c0,5.4,0,10.7,0,16.1c-3.3,0-6.7,0-10,0C6,13.4,6,10.7,6,8z"
      />
    </g>
  </svg>
);

export default SvgDetailMosaique;
