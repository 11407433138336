import React from 'react';

/**
 * Rend le layout du mode plein écran sur les dashboards
 *
 * @method DashboardFullScreenLayout
 * @param {any} props Props du composant
 */
export default function DashboardFullScreenLayout(props: any) {
  const { children } = props;
  return (
    <div>
      <div className="wrapper">
        <div className="containerDashFullScreen">{children}</div>
      </div>
    </div>
  );
}
