import exportConstants from '../_constants/export.constants';

export default function exports(state = {}, action: any) {
  switch (action.type) {
    case exportConstants.UPDATE_VIEW_REQUEST:
      return {
        loading: true,
      };
    case exportConstants.UPDATE_VIEW_SUCCESS:
      return {
        export: action.exports,
      };
    case exportConstants.UPDATE_VIEW_FAILURE:
      return {
        error: 'Impossible de récupérer les informations demandées',
      };
    case exportConstants.EXPORTCSV_REQUEST:
      state.processing = true;
      state.csv = null;
      return { ...state };
    case exportConstants.EXPORTCSV_SUCCESS:
      state.processing = false;
      state.csv = action.exports;
      return { ...state };
    case exportConstants.EXPORTCSV_FAILURE:
      return {
        error: 'Impossible de récupérer les informations demandées',
      };
    case exportConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
