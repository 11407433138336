import userConstants from '../_constants/user.constants';
import profilConstants from '../_constants/profil.constants';
import i18n from 'i18next';

export default function users(state: any = {}, action: any) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case userConstants.GETALL_SUCCESS:
      state.loading = false;
      return {
        items: action.users,
        ...state,
      };
    case userConstants.GETALL_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des utilisateurs",
      };
    case userConstants.GET_REQUEST:
      state.fetchedUser ? state.fetchedUser : {};
      return {
        loading: true,
        ...state,
      };
    case userConstants.GET_SUCCESS:
      state.loading = false;
      state.fetchedUser = action.fetchedUser;
      return {
        lastUpdatedInfo: action.lastUpdatedInfo,
        permissions: action.permissions,
        ...state,
      };
    case userConstants.GET_FAILURE:
      return {
        error: "Impossible d'obtenir les informations d'utilisateurs",
      };
    case userConstants.SAVE_REQUEST:
      return {
        loading: true,
      };
    case userConstants.SAVE_SUCCESS:
      return {
        savedUser: action.savedUser,
      };
    case userConstants.SAVE_FAILURE:
      return {
        error: "Impossible de sauver l'utilisateur",
      };
    case userConstants.SAVETOURMALINE_REQUEST:
      return {
        loading: true,
      };
    case userConstants.SAVETOURMALINE_SUCCESS:
      return {
        savedUser: action.savedTourmalineUser,
      };
    case userConstants.SAVETOURMALINE_FAILURE:
      return {
        error: "Impossible de sauvegarder l'utilisateur Tourmaline",
      };
    case userConstants.UPDATE_REQUEST:
      return {
        passwordUpdated: false,
        userUpdated: false,
        lastUpdatedInfo: action.lastUpdatedInfo,
        permissions: action.permissions,
      };
    case userConstants.UPDATE_SUCCESS:
      if (action.connectedUser && action.fetchedUser && action.connectedUser.userName === action.updatedUser.userName) {
        localStorage.setItem('language', action.updatedUser.language);
        i18n.changeLanguage(action.updatedUser.language);
      }
      return {
        fetchedUser: action.updatedUser,
        passwordUpdated: action.passwordUpdated,
        userUpdated: action.updated,
        lastUpdatedInfo: action.lastUpdatedInfo,
        permissions: action.permissions,
        permissionuUpdated: action.permissionuUpdated,
      };
    case userConstants.UPDATE_URLHOMEPAGE_SUCCESS:
      localStorage.setItem('homepage_url', action.urlHomepage);
      return state;
    case userConstants.UPDATE_URLHOMEPAGE_FAILURE:
      return {
        ...state,
        error: "Impossible de modifier la page d'accueil de l'utilisateur",
      };
    case userConstants.UPDATE_FAILURE:
      return {
        fetchedUser: action.original,
        updatedUser: action.updatedUser,
        lastUpdatedInfo: action.lastUpdatedInfo,
        permissions: action.permissions,
      };
    case userConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case userConstants.DELETE_SUCCESS:
      return {
        items: action.users.filter(user => user.id !== action.user.id),
      };
    case userConstants.DELETE_FAILURE:
      return {
        error: "Impossible de supprimer l'utilisateur",
      };

    case userConstants.BLOCK_REQUEST:
      return {
        loading: true,
      };
    case userConstants.BLOCK_SUCCESS:
      return {
        items: Object.assign(
          action.users,
          action.users.map(el => (el.id === action.updatedUser.id ? action.updatedUser : el))
        ),
      };
    case userConstants.BLOCK_FAILURE:
      return {
        error: "Impossible de bloquer ou débloquer l'utilisateur",
      };
    case userConstants.CLEAR:
      return {
        fetchedUser: action.user,
        passwordUpdated: false,
        userUpdated: false,
        lastUpdatedInfo: action.lastUpdatedInfo,
        permissions: action.permissions,
      };
    case userConstants.TOKEN_REQUEST:
      return {
        loading: true,
      };
    case userConstants.TOKEN_SUCCESS:
      return {
        token: action.token,
      };
    case userConstants.TOKEN_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des utilisateurs",
      };
    case userConstants.CHECKPERMISSION_SUCCESS:
      const checkPermissionsSuccess = _.cloneDeep(_.get(state, 'permissions', {}));
      _.set(checkPermissionsSuccess, action.key, action.authorize);
      return {
        ...state,
        checkPermissions: checkPermissionsSuccess,
      };
    case userConstants.CHECKPERMISSION_FAILURE:
      const checkPermissionsError = _.cloneDeep(_.get(state, 'permissions', {}));
      _.set(checkPermissionsError, action.key, false);
      return {
        ...state,
        checkPermissions: checkPermissionsError,
      };
    case profilConstants.DELETE_PROFIL_LINKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case profilConstants.DELETE_PROFIL_LINKS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case profilConstants.DELETE_PROFIL_LINKS_FAILURE:
      return {
        error: 'Impossible de retirer ce profil',
      };
    case profilConstants.ADD_GESTIONNAIRE_PERM_SUCCESS:
      action.gestionnaires.forEach((el: any) => state.fetchedUser.gestionnaires.push(el));
      return {
        ...state,
      };
    case profilConstants.DELETE_GESTIONNAIRE_PERM_SUCCESS:
      console.log(action, state);
      action.gestionnaires.forEach((el: any) => {
        state.fetchedUser.gestionnaires = state.fetchedUser.gestionnaires.filter(
          (perm: any) => perm.gestionnaireId !== el.gestionnaireId
        );
      });
      return {
        ...state,
      };
    default:
      return state;
  }
}
