import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Alert,
  CustomInput,
} from 'reactstrap';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import Loading from '../Bands/Loading';
import CompteurBlanc from '../SvgComponents/CompteurBlanc';
import AlarmeVert from '../SvgComponents/AlarmeVert';
import WidgetBlanc from '../SvgComponents/WidgetBlanc';
import { withTranslation } from 'react-i18next';

interface Props {
  onValid: any;
  toggle: any;
  isOpen: boolean;
  actions: Array<any>;
}

function correspondance(type: string) {
  if (type === 'W') {
    return 'Widget';
  } else if (type === 'A') {
    return 'Alarmes';
  } else if (type === 'CV') {
    return 'Compteurs virtuels';
  } else {
    return 'NO DEFINE';
  }
}

/**
 * @class ConfirmModal
 * @extends Component
 */
function ModalConfirmUnlink(props: any) {
  const { isOpen, toggle, onValid, actions, t } = props;

  const listWidget = actions && actions.length > 0 && actions.filter((a: any) => a.type === 'W');
  const listAlarms = actions && actions.length > 0 && actions.filter((a: any) => a.type === 'A');
  const listVMeter = actions && actions.length > 0 && actions.filter((a: any) => a.type === 'CV');

  return (
    <div>
      {actions && (
        <Modal isOpen={isOpen}>
          <ModalHeader toggle={toggle}>{t('all.text.validate-_deletion_link')}</ModalHeader>
          <AvForm onValidSubmit={onValid}>
            <ModalBody>
              {actions && listWidget.length > 0 && (
                <div className="with-border">
                  <h3>
                    <span>
                      <WidgetBlanc height="1em" width="1em" fill="#31c6b3" strokeWidth="1" /> {t('all.text.widgets')} -{' '}
                      {listWidget.length}
                    </span>
                    <FormGroup check key="widget" className="float-right">
                      <AvInput type="checkbox" name="widget" required tag={CustomInput} style={{ textAlign: 'center' }}>
                        {' '}
                      </AvInput>
                    </FormGroup>
                  </h3>

                  <ul>
                    {listWidget.map((el: any) => (
                      <li
                        style={{
                          borderBottom: '1px solid #ccc',
                          listStyle: 'none',
                          fontSize: '1.1em',
                          marginBottom: 10,
                        }}
                      >
                        {el.nomCible}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {actions && listVMeter.length > 0 && (
                <div className="with-border">
                  <h3>
                    <span>
                      <CompteurBlanc height="1em" width="1em" fill="#31c6b3" /> {t('all.meter.virtual_meter_plural')} -{' '}
                      {listVMeter.length}
                    </span>
                    <FormGroup check key="vmeter" className="float-right">
                      <AvInput type="checkbox" name="vmeter" required tag={CustomInput}>
                        {' '}
                      </AvInput>
                    </FormGroup>
                  </h3>

                  <ul>
                    {listVMeter.map((el: any) => (
                      <li
                        style={{
                          borderBottom: '1px solid #ccc',
                          listStyle: 'none',
                          fontSize: '1.1em',
                          marginBottom: 10,
                        }}
                      >
                        {el.nomCible}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {actions && listAlarms.length > 0 && (
                <div className="with-border">
                  <h3>
                    <span>
                      <AlarmeVert height="1em" width="1em" fill="#31c6b3" strokeWidth="1" /> Alertes -{' '}
                      {listAlarms.length}
                    </span>
                    <FormGroup check key="alarms" className="float-right">
                      <AvInput type="checkbox" name="alarms" required tag={CustomInput}>
                        {' '}
                      </AvInput>
                    </FormGroup>
                  </h3>

                  <ul>
                    {listAlarms.map((el: any) => (
                      <li
                        style={{
                          borderBottom: '1px solid #ccc',
                          listStyle: 'none',
                          fontSize: '1.1em',
                          marginBottom: 10,
                        }}
                      >
                        {el.nomCible}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {actions && actions.length === 0 && <Label>{t('all.meter.all_meter_dissociation_easy')}</Label>}

              {actions && actions.loading && <Loading />}
            </ModalBody>
            <ModalFooter>
              <Button disabled={!actions || actions.loading}>{t('all.button.validate')}</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      )}
    </div>
  );
}

const tr = withTranslation()(ModalConfirmUnlink);
export default tr;
