import React from 'react';

const SvgCalendrierVert = (props: any) => (
  <svg viewBox="0 0 26.13 28" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          d="M25.67,1.87H22.4V.47A.47.47,0,0,0,21.93,0H18.67a.47.47,0,0,0-.47.47v1.4H7.93V.47A.47.47,0,0,0,7.47,0H4.2a.47.47,0,0,0-.47.47v1.4H.47A.47.47,0,0,0,0,2.33v25.2A.47.47,0,0,0,.47,28h25.2a.47.47,0,0,0,.46-.47V2.33a.47.47,0,0,0-.46-.46ZM19.13.93h2.34v2.8H19.13ZM4.67.93H7v2.8H4.67ZM.93,2.8h2.8V4.2a.47.47,0,0,0,.47.47H7.47a.47.47,0,0,0,.46-.47V2.8H18.2V4.2a.47.47,0,0,0,.47.47h3.26a.47.47,0,0,0,.47-.47V2.8h2.8V7H.93Zm0,24.27V7.93H25.2V27.07Z"
        />
        <path
          fill={props.fill}
          d="M16.8,10.73H4.2V24.27H21.93V10.73Zm-3.27.94H16.8v3.26H13.53Zm3.27,7.46H13.53V15.87H16.8ZM9.33,15.87H12.6v3.26H9.33Zm0-4.2H12.6v3.26H9.33Zm-4.2,0H8.4v3.26H5.13Zm0,4.2H8.4v3.26H5.13ZM8.4,23.33H5.13V20.07H8.4Zm4.2,0H9.33V20.07H12.6Zm4.2,0H13.53V20.07H16.8Zm4.2,0H17.73V20.07H21Zm0-4.2H17.73V15.87H21Zm0-7.46v3.26H17.73V11.67Z"
        />
      </g>
    </g>
  </svg>
);
export default SvgCalendrierVert;
