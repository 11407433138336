import { authHeader, handleResponse } from '../_helpers';

async function getAvailable() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/telereleve/available`, requestOptions);
  return handleResponse(response);
}

async function getGateway() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/telereleve/gateway`, requestOptions);
  return handleResponse(response);
}

async function provision(body: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };
  const response = await fetch(`/api/telereleve/provision`, requestOptions);
  return handleResponse(response);
}

async function getProgressOfUpdateRadio() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/telereleve/provision/update/progress`, requestOptions);
  return handleResponse(response);
}

async function updateProvisioningRadio() {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };
  const response = await fetch(`/api/telereleve/provision/update`, requestOptions);
  return handleResponse(response);
}

const teleReleveService = {
  getAvailable,
  provision,
  getProgressOfUpdateRadio,
  updateProvisioningRadio,
  getGateway,
};

export default teleReleveService;
