import _ from 'lodash';
import { EventData, Map, Popup } from 'mapbox-gl';
import allImages from '../images/allImages';

function unclusteredPointEnter(meterSerial: string, clpReference: string, lastRead: any): string {
  return lastRead
    ? `<h3 style="font-size:14px; color: #31c6b3; margin-bottom: 5px">Information compteur:</h3></br>` +
        `<h3 style="font-size:12px; margin: 0">Compteur</h3></br>` +
        `<h2 style="color: black; font-size: 16px">${meterSerial}</h2></br>` +
        `${
          clpReference
            ? `<h3 style="font-size:12px; margin: 0">Pdi</h3></br><h2 style="color: black; font-size: 16px">${clpReference}</h2></br>`
            : `</br>`
        }` +
        `<h3 style="font-size:14px; color: #31c6b3; margin: 5px 0">Dernière lecture: </h3></br>` +
        `<h3 style="font-size:12px; margin: 0">index</h3></br><h2 style="color: black; font-size: 16px">${
          lastRead.index
        } m<sup>3</sup></h2></br>` +
        `<h3 style="font-size:12px; margin: 0">consommation</h3></br><h2 style="color: black; font-size: 16px">${
          lastRead.consumption
        } m<sup>3</sup></h2></br>`
    : `<h3 style="font-size:12px; color: #31c6b3; margin-bottom: 5px">Information compteur</h3></br>` +
        `<h3 style="font-size:12px; margin: 0">compteur</h3></br><h2 style="color: black; font-size: 16px">${meterSerial}</h2></br>` +
        `${
          clpReference
            ? `<h3 style="font-size:12px; margin: 0">pdi</h3></br><h2 style="color: black; font-size: 16px">${clpReference}</h2></br>`
            : `</br>`
        }`;
}

function clusterPointEnter(t: Function): string {
  return `<h3 style="color: #35c5b3; font-size: 16px; text-align: center;">${t(
    'cluster_marker_gm.title.meter_detail_plural'
  )}</h3>`;
}

function links(gatewayName: string, meterSerial: string, rssi: string): string {
  return (
    `<h3 style="text-align: center; color: #35c5b3; font-size: 16px;">Information de télérelève</h3>` +
    '\n' +
    `<strong style="font-size:14px">Gateway: ${gatewayName}</strong><br>` +
    `<strong style="font-size:14px">Compteur cible: ${meterSerial}</strong><br>` +
    `<strong style="font-size:14px">Rssi: ${rssi || 'Inconnu'}</strong>`
  );
}

function gateways(name: string, t: Function): string {
  return (
    `<h3 style="text-align: center; color: #35c5b3; font-size: 16px;">${t('gateway_name.text.map')}</h3>` +
    '\n' +
    `<strong style="font-size:14px">${name}</strong>`
  );
}

function popupImageGenerator(count: EventData, t: Function): string {
  return allImages.gouttesImages
    .map((el, index) => {
      if (index < 13) {
        const counter: number = _.entries(count)[index][1];
        if (counter > 0 && typeof counter === 'number') {
          return `<div style="padding-bottom: 0px;"><img class='mapbox-popup-image' src=${el.url} />
            <strong>${_.entries(count)[index][1]}</strong>
            <strong>${t(allImages.trad[index])}</strong></div>`;
        }
      }
    })
    .join('');
}

function createPopup(popup: any, coordinates: any, map: Map, content: string, e: EventData, type: string): void {
  if (popup) {
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }
    popup
      .setLngLat(coordinates)
      .setHTML(content)
      .addTo(map);

    popup.setMaxWidth('300');
    switch (type) {
      case 'unclustered':
        popup.removeClassName('gateway-range');
        popup.removeClassName('cluster');
        popup.removeClassName('gateway');
        popup.removeClassName('hide');
        popup.addClassName('unclustered');
        break;
      case 'gateway-range':
        popup.removeClassName('unclustered');
        popup.removeClassName('cluster');
        popup.removeClassName('gateway');
        popup.removeClassName('hide');
        popup.addClassName('gateway-range');
        break;
      case 'cluster':
        const doc: any = document.getElementById(`cluster-${e.features[0].id}`);
        popup.removeClassName('gateway-range');
        popup.removeClassName('unclustered');
        popup.removeClassName('gateway');
        popup.addClassName('cluster');
        if (_.get(doc, 'className') && doc.className.includes('hide')) {
          popup.addClassName('hide');
        } else {
          popup.removeClassName('hide');
          popup.addClassName(`cluster-${e.features[0].id}`);
        }
        break;
      case 'gateway':
        popup.removeClassName('gateway-range');
        popup.removeClassName('unclustered');
        popup.removeClassName('cluster');
        popup.removeClassName('hide');
        popup.addClassName('gateway');
        break;
      default:
        break;
    }
  }
}

function removePopup(map: Map, popup: Popup, layersName: string): void {
  map.on('mouseout', layersName, () => {
    map.getCanvas().style.cursor = '';
    popup.remove();
  });
}

function removeClusterPopup(map: Map, popup: Popup): void {
  map.getCanvas().style.cursor = '';
  popup.remove();
}

const popups = {
  unclusteredPointEnter,
  clusterPointEnter,
  links,
  gateways,
  popupImageGenerator,
  createPopup,
  removePopup,
  removeClusterPopup,
};

export default popups;
