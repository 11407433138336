import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { alertActions, userActions } from '../_actions';
import { alert } from '../_interfaces/reducers';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

interface Props {
  dispatch: Function;
  alert: alert;
}
interface State {
  email: string;
  modal: boolean;
  operation: boolean;
}

class LostPassComponent extends React.Component<Props & React.HTMLAttributes<{}>, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      modal: false,
      operation: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    this.setState({
      email: '',
      operation: false,
    });
  }

  /**
   * Nettoie le state au démontage
   *
   * @method componentWillUnmount
   * @memberof DisplayDashBoardsComponent
   */
  componentWillUnmount() {
    const { dispatch } = this.props;
    this.setState({
      email: '',
      operation: false,
    });
  }

  toggle = () => {
    const { dispatch } = this.props;
    this.setState({
      modal: !this.state.modal,
      email: '',
      operation: false,
    });
    dispatch(alertActions.clear());
  };

  handleValidSubmit = (event: Object, values: any) => {
    const { dispatch } = this.props;
    const email = values.email;
    this.setState({
      email: email,
      operation: true,
    });
    localStorage.clear();
    dispatch(userActions.generateToken(email));
  };

  handleInvalidSubmit = (event: Object, errors: Object, values: any) => {
    this.setState({
      email: values.email,
    });
  };

  render() {
    const { className, t } = this.props;
    const { email, modal, operation } = this.state;

    return (
      <div>
        <div>
          <span className="clickable" role="presentation" onClick={this.toggle}>
            {t('lostpass.text.password_identifiant_lost')}{' '}
          </span>
          <Modal isOpen={modal} toggle={this.toggle} className={className}>
            <ModalHeader toggle={this.toggle}>{t('lostpass.text.get_identifants')}</ModalHeader>
            <AvForm onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
              <ModalBody>
                {!operation && (
                  <Fragment>
                    <p>{t('lostpass.text.enter_email')}</p>
                    <div>
                      <AvField
                        name="email"
                        value={email}
                        label={t('lostpass.text.your_email')}
                        type="email"
                        required
                        errorMessage={t('lostpass.text.enter_valid_email')}
                      />
                    </div>
                  </Fragment>
                )}
                {operation && <p>{t('lostpass.text.check_email')}</p>}
              </ModalBody>
              <ModalFooter>
                {!operation && (
                  <Button color="danger" onClick={this.toggle}>
                    {t('all.button.cancel')}
                  </Button>
                )}
                {!operation && <Button color="primary">{t('all.button.register')}</Button>}
                {operation && (
                  <Button onClick={this.toggle} color="primary">
                    {t('all.button.close')}
                  </Button>
                )}
              </ModalFooter>
            </AvForm>
          </Modal>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  const { authentication, alert, users } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    users,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(LostPassComponent)));
