import React from 'react';

const SvgEmptyMarker = (props: any) => (
  <svg viewBox="0 0 319.9 495.8" {...props}>
    <path
      fill={props.fill || 'black'}
      d="M159.9,0C71.6,0,0,71.6,0,159.9c0,109,122.6,148.7,149.3,317.5c1.7,10.9,1.8,18.4,10.7,18.4
	c8.8,0,9-7.5,10.7-18.4c26.7-168.8,149.3-208.5,149.3-317.5C319.9,71.6,248.3,0,159.9,0z M159.9,294.3
	c-74.2,0-134.4-60.2-134.4-134.4S85.7,25.6,159.9,25.6c74.2,0,134.4,60.2,134.4,134.4S234.2,294.3,159.9,294.3z"
    />
  </svg>
);

export default SvgEmptyMarker;
