import React from 'react';
import EditionBleu from '../SvgComponents/EditionBleu';

const TextInput = (props: any) => {
  const { handleChange, type, placeholder, text, value, isInvalid, isValid, hasChanged } = props;
  return (
    <div className="styled-input">
      <div className="form__group field">
        <input
          type={type}
          className={`form__field ${isInvalid ? 'error' : ''} ${isValid ? 'valid' : ''}`}
          placeholder={placeholder}
          required
          onChange={(e: any) => handleChange(e)}
          value={value}
        />
        <label htmlFor="name" className={`form__label ${isInvalid ? 'error' : ''} ${isValid ? 'valid' : ''}`}>
          {text}
          {hasChanged && <EditionBleu height="16px" fill={isInvalid ? '#ff2281' : '#38ef7d'} />}
        </label>
      </div>
    </div>
  );
};

export default TextInput;
