import Confirm from './Confirm';

export { default as MainLayout } from './MainLayout';
export { default as NotFound } from './NotFound';
export { default as EmptyLayout } from './EmptyLayout';
export * from './Route';
export { default as MainNavbar } from './MainNavbar';
export { default as Sidebar } from './Sidebar';
export { default as Forbidden } from './Forbidden';
export { default as DashboardLayout } from './DashboardLayout';
export { default as SidebarTele } from './SidebarTele';
export { default as StockLayout } from './StockLayout';
export { default as CrystalBreadCrumb } from './BreadCrumb';
export { default as ErrorBoundary } from './ErrorBoundary';
export { default as SidebarMini } from './SidebarMini';
export { default as SidebarLocation } from './SidebarLocation';
export { default as HelpModal } from './HelpModal';
export { default as NotificationModal } from './NotificationModal';
export { default as Search } from './Search';
export { default as AlertContent } from './AlertContent';
export { default as SidebarStock } from './SidebarStock';
export { default as MaskAlarmCompenent } from '../MaskBar/MaskAlarmCompenent';
export { default as DragAndDrop } from './DragDrop/DragAndDrop';
export { default as SideBarSynchro } from './SidebarSynchronisation';
export { default as SynchroLayout } from './SynchroLayout';
export { default as ImportComponent } from './ImportComponent';
export { default as SidebarTournees } from './SidebarTournees';
export { default as TourneeNavbar } from './TourneeNavbar';
export { default as DeviceLayout } from './DeviceLayout';
export { default as SupportLayout } from './SupportLayout';

export default function confirm(options = {}) {
  const instance = new Confirm(options);
  return instance.open();
}
