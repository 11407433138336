import synchroConstants from '../_constants/synchro.constants';
import synchroService from '../_services/synchro.service';

import synchroComActions from './synchrocom.actions';

function getAllRoundFull(gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.GETALLROUND_FULL_REQUEST };
  }
  function success(rounds: Object) {
    return { type: synchroConstants.GETALLROUND_FULL_SUCCESS, rounds };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GETALLROUND_FULL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getAllRoundFull(gestionnaireId)
      .then((rounds: Object) => dispatch(success(rounds)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getLabels(gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.GETLABELS_REQUEST };
  }
  function success(labels: Object) {
    return { type: synchroConstants.GETLABELS_SUCCESS, labels };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GETLABELS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getLabels(gestionnaireId)
      .then((roundCode: Object) => dispatch(success(roundCode)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getAllDevice(gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.GETALLDEVICES_REQUEST };
  }
  function success(devices: Object) {
    return { type: synchroConstants.GETALLDEVICES_SUCCESS, devices };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GETALLDEVICES_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getAllDevices(gestionnaireId)
      .then((devices: any) => dispatch(success(devices)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getAllDevicesTools() {
  function request() {
    return { type: synchroConstants.GETALLDEVICES_REQUEST };
  }
  function success(devices: Object) {
    return { type: synchroConstants.GETALLDEVICES_SUCCESS, devices };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GETALLDEVICES_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getAllDevicesTools()
      .then((devices: any) => dispatch(success(devices)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function communication(communication: any, gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.COMMUNICATION_REQUEST };
  }
  function success(communication: Object) {
    return { type: synchroConstants.COMMUNICATION_SUCCESS, communication };
  }
  function failure(error: Object) {
    return { type: synchroConstants.COMMUNICATION_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .communication(communication, gestionnaireId)
      .then((communication: Object) => {
        dispatch(synchroComActions.getCurrentCommunication(gestionnaireId));
        dispatch(synchroComActions.getCurrentRounds(gestionnaireId));
        dispatch(success(communication));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function cancelAll(gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.CANCEL_ALL_REQUEST };
  }
  function success(all: Object) {
    return { type: synchroConstants.CANCEL_ALL_SUCCESS, all };
  }
  function failure(error: Object) {
    return { type: synchroConstants.CANCEL_ALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .cancelAll(gestionnaireId)
      .then((all: Object) => dispatch(success(all)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function comWithAll(gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.COM_ALL_REQUEST };
  }
  function success(all: Object) {
    return { type: synchroConstants.COM_ALL_SUCCESS, all };
  }
  function failure(error: Object) {
    return { type: synchroConstants.COM_ALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .comWithAll(gestionnaireId)
      .then((all: Object) => {
        dispatch(synchroComActions.getCurrentCommunication(gestionnaireId));
        dispatch(synchroComActions.getCurrentRounds(gestionnaireId));
        dispatch(success(all));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function deviceCom(deviceId: string, gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.COM_ALL_REQUEST };
  }
  function success(communication: Object) {
    return { type: synchroConstants.COM_DEVICE_SUCCESS, communication };
  }
  function failure(error: Object) {
    return { type: synchroConstants.COM_DEVICE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .deviceCom(deviceId, gestionnaireId)
      .then((communication: Object) => {
        dispatch(synchroComActions.getCurrentCommunication(gestionnaireId));
        dispatch(synchroComActions.getCurrentRounds(gestionnaireId));
        dispatch(success(communication));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function deviceCancel(deviceId: string, gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.CANCEL_DEVICE_REQUEST };
  }
  function success(deviceId: any) {
    return { type: synchroConstants.CANCEL_DEVICE_SUCCESS, deviceId };
  }
  function failure(error: Object) {
    return { type: synchroConstants.CANCEL_DEVICE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .deviceCancel(deviceId, gestionnaireId)
      .then(() => {
        dispatch(getLabels(gestionnaireId));
        dispatch(success(deviceId));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function roundCom(rndCode: string, gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.COM_ROUND_REQUEST };
  }
  function success(communication: Object) {
    return { type: synchroConstants.COM_ROUND_SUCCESS, communication };
  }
  function failure(error: Object) {
    return { type: synchroConstants.COM_ROUND_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .roundCom(rndCode, gestionnaireId)
      .then((communication: Object) => {
        dispatch(synchroComActions.getCurrentCommunication(gestionnaireId));
        dispatch(synchroComActions.getCurrentRounds(gestionnaireId));
        dispatch(success(communication));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function roundCancel(rndCode: string, gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.CANCEL_ROUND_REQUEST };
  }
  function success(cancel: Object) {
    return { type: synchroConstants.CANCEL_ROUND_SUCCESS, cancel };
  }
  function failure(error: Object) {
    return { type: synchroConstants.CANCEL_ROUND_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .roundCancel(rndCode, gestionnaireId)
      .then((cancel: Object) => {
        dispatch(getLabels(gestionnaireId));
        dispatch(success(cancel));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function updateDeviceName(deviceConfig: any, deviceId: any, gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.UPDATENAME_REQUEST };
  }
  function success(device: Object) {
    return { type: synchroConstants.UPDATENAME_SUCCESS, device };
  }
  function failure(error: Object) {
    return { type: synchroConstants.UPDATENAME_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .updateDeviceName(deviceConfig, deviceId, gestionnaireId)
      .then((deviceName: Object) => {
        dispatch(success(deviceName));
        dispatch(getAllDevice(gestionnaireId));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function deleteDevice(deviceId: any, gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.DELETE_DEVICE_REQUEST };
  }
  function success(device: Object) {
    return { type: synchroConstants.DELETE_DEVICE_SUCCESS, device };
  }
  function failure(error: Object) {
    return { type: synchroConstants.DELETE_DEVICE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .deleteDevice(deviceId, gestionnaireId)
      .then((deviceName: Object) => {
        dispatch(success(deviceName));
        dispatch(getAllDevice(gestionnaireId));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getAllMessages(gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.GETALLMESSAGES_REQUEST };
  }
  function success(messages: Object) {
    return { type: synchroConstants.GETALLMESSAGES_SUCCESS, messages };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GETALLMESSAGES_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getAllMessages(gestionnaireId)
      .then((messages: Object) => dispatch(success(messages)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getAllMarquages(gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.GETALLMARQUAGES_REQUEST };
  }
  function success(marquages: Object) {
    return { type: synchroConstants.GETALLMARQUAGES_SUCCESS, marquages };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GETALLMARQUAGES_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getAllMarquages(gestionnaireId)
      .then((marquages: Object) => dispatch(success(marquages)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function synchroDevice(deviceCode: any, gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.SYNCHRODEVICE_REQUEST };
  }
  function success(deviceCode: Object) {
    return { type: synchroConstants.SYNCHRODEVICE_SUCCESS, deviceCode };
  }
  function failure(error: Object) {
    return { type: synchroConstants.SYNCHRODEVICE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .synchroDevice(deviceCode, gestionnaireId)
      .then((deviceCode: Object) => {
        dispatch(synchroComActions.getCurrentCommunication(gestionnaireId));
        dispatch(synchroComActions.getCurrentRounds(gestionnaireId));
        dispatch(success(deviceCode));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function synchroRound(roundCode: any, gestionnaireId: any) {
  return () => {
    synchroService.synchroRound(roundCode, gestionnaireId);
  };
}

function rotateRound(roundCode: any, gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.ROTATE_ROUND_REQUEST, roundCode };
  }
  function success(roundCode: Object) {
    return { type: synchroConstants.ROTATE_ROUND_SUCCESS, roundCode };
  }
  function failure(error: Object) {
    return { type: synchroConstants.ROTATE_ROUND_FAILURE, error, roundCode };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .rotateRound(roundCode, gestionnaireId)
      .then((roundCodeMap: { [roundCode: string]: boolean }) => {
        if (!roundCodeMap[roundCode]) {
          dispatch(getAllRoundFull(gestionnaireId));
        }
        dispatch(success(roundCodeMap));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function addAffect(affect: any, gestionnaireId: any, deviceId: any) {
  function request() {
    return { type: synchroConstants.ADDAFFECT_REQUEST };
  }
  function success(affect: Object) {
    return { type: synchroConstants.ADDAFFECT_SUCCESS, affect };
  }
  function failure(error: Object) {
    return { type: synchroConstants.ADDAFFECT_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .addAffect(affect, gestionnaireId, deviceId)
      .then((affect: Object) => dispatch(success(affect)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function delAffect(affect: any, gestionnaireId: any, deviceId: any) {
  function request() {
    return { type: synchroConstants.DELAFFECT_REQUEST };
  }
  function success(affect: Object) {
    return { type: synchroConstants.DELAFFECT_SUCCESS, affect };
  }
  function failure(error: Object) {
    return { type: synchroConstants.DELAFFECT_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .delAffect(affect, gestionnaireId, deviceId)
      .then((affect: Object) => dispatch(success(affect)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function addDesaffect(desaffect: any, gestionnaireId: any, deviceId: any) {
  function request() {
    return { type: synchroConstants.ADDDESAFFECT_REQUEST };
  }
  function success(desaffect: Object) {
    return { type: synchroConstants.ADDDESAFFECT_SUCCESS, desaffect };
  }
  function failure(error: Object) {
    return { type: synchroConstants.ADDDESAFFECT_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .addDesaffect(desaffect, gestionnaireId, deviceId)
      .then((desaffect: Object) => dispatch(success(desaffect)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function delDesaffect(desaffect: any, gestionnaireId: any, deviceId: any) {
  console.log(desaffect);
  function request() {
    return { type: synchroConstants.DELDESAFFECT_REQUEST, list: desaffect, device: deviceId };
  }
  function success(desaffect: Object) {
    return { type: synchroConstants.DELDESAFFECT_SUCCESS, desaffect };
  }
  function failure(error: Object) {
    return { type: synchroConstants.DELDESAFFECT_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .delDesaffect(desaffect, gestionnaireId, deviceId)
      .then((desaffect: Object) => dispatch(success(desaffect)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getCurrentVersion() {
  function request() {
    return { type: synchroConstants.GETCURRENTVERSION_REQUEST };
  }
  function success(version: Object) {
    return { type: synchroConstants.GETCURRENTVERSION_SUCCESS, version };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GETCURRENTVERSION_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getCurrentVersion()
      .then((version: Object) => dispatch(success(version)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getMiniVersion() {
  function request() {
    return { type: synchroConstants.GETMINIVERSION_REQUEST };
  }
  function success(version: Object) {
    return { type: synchroConstants.GETMINIVERSION_SUCCESS, version };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GETMINIVERSION_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getMiniVersion()
      .then((version: Object) => dispatch(success(version)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getFullAffect(gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.GET_FULLAFFECT_REQUEST };
  }
  function success(fullAffect: Array<string>) {
    return { type: synchroConstants.GET_FULLAFFECT_SUCCESS, fullAffect };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GET_FULLAFFECT_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getFullAffect(gestionnaireId)
      .then((fullAffect: Array<string>) => dispatch(success(fullAffect)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function updateUndroppable(devicesId: any) {
  function request() {
    return { type: synchroConstants.UPDATE_UNDROP_SUCCESS };
  }
  function success(devicesId: any) {
    return { type: synchroConstants.UPDATE_UNDROP_SUCCESS, devicesId };
  }

  return (dispatch: Function) => {
    dispatch(success(devicesId));
  };
}

function switchToPartiel(gestionnaireId: any, deviceSerial: any, rcList: Array<string>, isPartial: boolean) {
  function request() {
    return { type: synchroConstants.SWITCH_PARTIAL_REQUEST };
  }
  function success(affectation: any) {
    return { type: synchroConstants.SWITCH_PARTIAL_SUCCESS, affectation };
  }
  function failure(error: Object) {
    return { type: synchroConstants.SWITCH_PARTIAL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .switchToPartiel(gestionnaireId, deviceSerial, rcList, isPartial)
      .then((affectation: any) => dispatch(success(affectation)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function addMessages(messages: any, gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.ADD_MESSAGES_REQUEST };
  }
  function success(messages: any) {
    return { type: synchroConstants.ADD_MESSAGES_SUCCESS, messages };
  }
  function failure(error: Object) {
    return { type: synchroConstants.ADD_MESSAGES_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .addMessages(messages, gestionnaireId)
      .then((messages: any) => dispatch(success(messages)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function addMarquages(marquages: any, gestionnaireId: any) {
  function request() {
    return { type: synchroConstants.ADD_MARQUAGES_REQUEST };
  }
  function success(marquages: any) {
    return { type: synchroConstants.ADD_MARQUAGES_SUCCESS, marquages };
  }
  function failure(error: Object) {
    return { type: synchroConstants.ADD_MARQUAGES_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .addMarquages(marquages, gestionnaireId)
      .then((marquages: any) => dispatch(success(marquages)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getDevice(deviceSerial: String) {
  function request() {
    return { type: synchroConstants.GET_DEVICE_REQUEST };
  }
  function success(serial: Object) {
    return { type: synchroConstants.GET_DEVICE_SUCCESS, serial };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GET_DEVICE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getDevice(deviceSerial)
      .then((serial: Object) => dispatch(success(serial)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function saphirLogin(deviceSerial: string) {
  function request() {
    return { type: synchroConstants.LOGIN_REQUEST };
  }
  function success(serial: string) {
    return { type: synchroConstants.LOGIN_SUCCESS, serial };
  }
  function failure(error: Object) {
    return { type: synchroConstants.LOGIN_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .saphirLogin(deviceSerial)
      .then((serial: string) => dispatch(success(serial)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function saphirLogout(deviceSerial: string) {
  function request() {
    return { type: synchroConstants.LOGOUT_REQUEST };
  }
  function success(serial: string) {
    return { type: synchroConstants.LOGOUT_SUCCESS, serial };
  }
  function failure(error: Object) {
    return { type: synchroConstants.LOGOUT_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .saphirLogout(deviceSerial)
      .then((serial: string) => dispatch(success(serial)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

/**
 TOOLS ACTION
 */

function connectToDeviceTools(deviceSerial: string) {
  return () => {
    synchroService
      .connectToDeviceTools(deviceSerial)
      .then((serial: string) => console.log('connectToDeviceTools::connectToDeviceTools::691', 'YES -> ', serial))
      .catch((error: Object) => {
        console.log(error);
      });
  };
}

function disconnectOfDeviceTools(deviceSerial: string) {
  return () => {
    synchroService
      .disconnectOfDeviceTools(deviceSerial)
      .then((serial: string) => console.log('disconnectOfDeviceTools::disconnectOfDeviceTools::691', 'YES -> ', serial))
      .catch((error: Object) => {
        console.log(error);
      });
  };
}

function launchCommand(deviceSerial: string, commandName: string, options: FormData | string) {
  return () => {
    synchroService
      .launchCommand(deviceSerial, commandName, options)
      .then((serial: string) =>
        console.log('connectToDeviceTools::launchCommand::691', 'YES -> ', serial, commandName, options)
      )
      .catch((error: Object) => {
        console.log(error);
      });
  };
}

function disaffectAllForDevice(gestionnaireId: any, deviceSerial: any) {
  function request() {
    return { type: synchroConstants.DISAFFECT_ALL_REQUEST };
  }
  function success(device: any) {
    return { type: synchroConstants.DISAFFECT_ALL_SUCCESS, device };
  }
  function failure(error: Object) {
    return { type: synchroConstants.DISAFFECT_ALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .disaffectAllForDevice(gestionnaireId, deviceSerial)
      .then((device: any) => dispatch(success(device)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function disaffectAllForRound(gestionnaireId: any, deviceSerial: any) {
  function request() {
    return { type: synchroConstants.DISAFFECT_ALL_FOR_ROUND_REQUEST };
  }
  function success(round: any) {
    return { type: synchroConstants.DISAFFECT_ALL_FOR_ROUND_SUCCESS, round };
  }
  function failure(error: Object) {
    return { type: synchroConstants.DISAFFECT_ALL_FOR_ROUND_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .disaffectAllForRound(gestionnaireId, deviceSerial)
      .then((device: any) => dispatch(success(device)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getListDeviceError() {
  function request() {
    return { type: synchroConstants.GET_DEVICES_ERROR_REQUEST };
  }
  function success(devices: any) {
    return { type: synchroConstants.GET_DEVICES_ERROR_SUCCESS, devices };
  }
  function failure(error: Object) {
    return { type: synchroConstants.GET_DEVICES_ERROR_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getListDeviceError()
      .then((devices: any) => dispatch(success(devices)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function removeDeviceError(serial: string) {
  function request() {
    return { type: synchroConstants.REMOVE_DEVICES_ERROR_REQUEST };
  }
  function success(devices: any) {
    return { type: synchroConstants.REMOVE_DEVICES_ERROR_SUCCESS, devices };
  }
  function failure(error: Object) {
    return { type: synchroConstants.REMOVE_DEVICES_ERROR_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .removeDeviceError(serial)
      .then((devices: any) => dispatch(success(devices)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function dataError(message: string) {
  return {
    type: synchroConstants.DATA_ERROR,
    error: message,
  };
}

/** END* */

function clear() {
  return {
    type: synchroConstants.CLEAR,
  };
}

const synchroActions = {
  getAllRoundFull,
  getAllDevice,
  communication,
  updateDeviceName,
  getAllMessages,
  getAllMarquages,
  synchroDevice,
  synchroRound,
  addAffect,
  delAffect,
  addDesaffect,
  delDesaffect,
  getLabels,
  getCurrentVersion,
  getMiniVersion,
  updateUndroppable,
  getFullAffect,
  comWithAll,
  cancelAll,
  deviceCom,
  deviceCancel,
  roundCom,
  roundCancel,
  getDevice,
  saphirLogin,
  saphirLogout,
  addMessages,
  addMarquages,
  switchToPartiel,
  clear,
  getAllDevicesTools,
  connectToDeviceTools,
  launchCommand,
  disconnectOfDeviceTools,
  rotateRound,
  disaffectAllForDevice,
  disaffectAllForRound,
  getListDeviceError,
  removeDeviceError,
  deleteDevice,
  dataError,
};

export default synchroActions;
