import React, { Component, useEffect } from 'react';
import { Button, CustomInput, FormGroup, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import _, { findIndex, indexOf } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, FormLabel, withStyles } from '@material-ui/core';
import ImportBleu from '../SvgComponents/ImportBleu';
import { importActions, localeActions } from '../_actions';
import LoadingBand from '../Bands/Loading';
import SuccessBand from '../Bands/Success';
import ErrorBand from '../Bands/Error';
import WarningBand from '../Bands/Warning';
import Alarme_ErreurInstall_Bleu from '../SvgComponents/Alarme_ErreurInstall_Bleu';
import imports from '../_reducers/import.reducer';

interface Props {
  title: String;
  user: any;
  history: any;
  dispatch: Function;
  imports: any;
  locales: any;
  match: any;
}

const defaultSelectedLinesValue = [true, true, false, true, false, true, true, true];

export class ImportSilex extends Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      file: null,
      byPass: false,
      lineType: 0,
      selectedRound: {},
      init: false,
      selectedLines: defaultSelectedLinesValue,
    };
    this.onChange = this.onChange.bind(this);
    this.invalidFile = this.invalidFile.bind(this);
    this.statutFormatter = this.statutFormatter.bind(this);
  }

  static getDerivedStateFromProps(props: any, state: any) {
    const { imports } = props;
    const copyState = _.cloneDeep(state);

    if (imports.errorSilex && imports && !copyState.init) {
      copyState.selectedRound = imports.errorSilex.find(el =>
        el.listResults.find((error: any) => error.differences.length > 0)
      );
      copyState.lineType =
        _.size(_.get(copyState, 'selectedRound.listResults')) > 0 &&
        _.get(
          _.get(copyState, 'selectedRound.listResults').find((error: any) => error.differences.length > 0),
          'lineType'
        );
      copyState.init = true;
    }

    return copyState;
  }

  componentDidMount() {
    const { user, history, dispatch } = this.props;
    const roleList = ['DIOPTASE', 'SUPERADMIN', 'ADMIN', 'USER'];
    if (!roleList.includes(user.role.name)) {
      history.push('/forbidden');
    }
    dispatch(localeActions.load());
  }

  onChange(e: any) {
    const { dispatch } = this.props;
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    this.setState({ file: formData });
    dispatch(importActions.clear());
  }

  invalidFile() {
    const { dispatch } = this.props;
    dispatch(importActions.clear());
    this.setState({ file: null });
  }

  handleLineChoice(line: number, newState: boolean) {
    const { selectedLines } = this.state;
    const targetArray = [...selectedLines];
    switch (line) {
      case 0:
      case 1:
        targetArray[0] = newState;
        targetArray[1] = newState;
        break;
      case 2:
        targetArray[2] = newState;
        if (!newState) {
          targetArray[4] = newState;
        }
        break;
      case 4:
        targetArray[4] = newState;
        if (newState) {
          targetArray[2] = newState;
        }
        break;
      case 5:
      case 6:
        targetArray[5] = newState;
        targetArray[6] = newState;
        break;
      default:
        targetArray[line] = newState;
    }
    this.setState({ selectedLines: targetArray });
  }

  newfileSubmition = () => {
    const { dispatch, match } = this.props;
    const { file, selectedLines } = this.state;
    const { params } = match;

    dispatch(importActions.reworkedImport(file, selectedLines, params.GestionnaireId));
    this.setState({ selectedRound: {}, lineType: 0, init: false, adminImport: true });
  };

  submitFile = () => {
    const { dispatch, match } = this.props;
    const { file } = this.state;
    const { params } = match;

    dispatch(importActions.importSilexFile(file, params.GestionnaireId));
    this.setState({ selectedRound: {}, lineType: 0, init: false, adminImport: false });
  };

  chooseFileDone = () => {
    const { file } = this.state;
    const { imports } = this.props;
    return file !== null && file && imports && !imports.running;
  };

  changeDisplay = (key: string, context: string) => {
    const state: any = {};
    state[key + context] = this.state[key + context] ? !this.state[key + context] : true;
    this.setState({ ...state });
  };

  statutFormatter(cell: any) {
    const { t } = this.props;
    return cell ? (
      <span>{t('import_silex.status_text.already_exist')}</span>
    ) : (
      <span>{t('import_silex.status_text.imported')}</span>
    );
  }

  getAdminData = (selectedRound: any, lineType: any) => {
    const resultLine = _.get(selectedRound, `t${lineType}Result`);
    switch (lineType) {
      case 0:
        return [
          {
            identifiant: resultLine.codeTournee,
            label: resultLine.label,
            notes: resultLine.modified ? 'Modifier' : resultLine.alreadyExist ? 'Existe déjà' : '',
          },
        ];
      case 3:
        return resultLine.map((el: any) => ({
          identifiant: el.newSerial,
          oldSerial: el.theoricalOldSerial,
          changeDate: el.dateChangement.substr(0, 10),
        }));
      case 6:
      case 7:
        return resultLine.map((el: string, index: number) => ({ identifiant: index, serial: el }));
    }
  };

  componentDidUpdate(prevProps: any) {
    const { imports } = this.props;
    if (!Object.is(imports, prevProps.imports) && _.get(imports, 'silex[0].t0Result') === null) {
      console.log(imports);
      this.setState({ selectedRound: _.get(imports, 'silex[0]') });
    }
  }

  render() {
    const { file, byPass, lineType, selectedRound, selectedLines } = this.state;
    const { imports, user, t } = this.props;
    const admin = user.role.name === 'DIOPTASE';
    const extension = '.dat,.rtr,.txt,.xls,.xlsx';
    const columns = [
      {
        dataField: 'codeTournee',
        text: t('import_silex.column_header.round_code'),
      },
      {
        dataField: 'label',
        text: t('import_silex.column_header.round_name'),
      },
      {
        dataField: 'alreadyExist',
        text: t('import_silex.column_header.round_status'),
        type: 'bool',
        formatter: this.statutFormatter,
      },
      {
        dataField: 'startIndex',
        text: t('import_silex.column_header.first_row'),
        type: 'number',
      },
      {
        dataField: 'endIndex',
        text: t('import_silex.column_header.last_row'),
        type: 'number',
      },
    ];

    const errorColumns = [
      {
        dataField: 'identifiant',
        text: t('import_silex.columns.identifiant'),
      },
      {
        dataField: 'fieldName',
        text: t('import_silex.columns.field'),
      },
      {
        dataField: 'valueTemoin',
        text: t('import_silex.columns.temoin_value'),
        type: 'number',
      },
      {
        dataField: 'valueGenerated',
        text: t('import_silex.columns.generated_value'),
      },
    ];

    const adminImportColumns = {
      t0Result: [
        {
          dataField: 'identifiant',
          text: t('import_silex.columns.identifiant'),
        },
        {
          dataField: 'label',
          text: t('import_silex.columns.field'),
        },
        {
          dataField: 'notes',
          text: 'Notes',
        },
      ],
      t3Result: [
        {
          dataField: 'identifiant',
          text: t('import_silex.columns.identifiant'),
        },
        {
          dataField: 'oldSerial',
          text: 'Ancien numéro de série',
        },
        {
          dataField: 'changeDate',
          text: 'Date du changement',
          type: 'date',
        },
      ],
      t6Result: [
        {
          dataField: 'identifiant',
          text: t('import_silex.columns.identifiant'),
        },
        {
          dataField: 'serial',
          text: 'Numero de Série',
        },
      ],
      t7Result: [
        {
          dataField: 'identifiant',
          text: t('import_silex.columns.identifiant'),
        },
        {
          dataField: 'serial',
          text: 'Numero de Série',
        },
      ],
    };

    const errorImportColumns = [
      {
        dataField: 'line',
        text: t('import_silex.column_label.lines'),
      },
      {
        dataField: 'type',
        text: t('import_silex.column_label.types'),
      },
      {
        dataField: 'column',
        text: t('import_silex.column_label.positions'),
      },
      {
        dataField: 'description',
        text: t('import_silex.column_label.description'),
      },
    ];

    const errorAlreadyLinkMeterColumns = [
      {
        dataField: 'serial',
        text: t('all.meter.meter_serial'),
      },
      {
        dataField: 'round',
        text: t('import_silex.column_label.associated_round'),
      },
    ];

    const rowStyle = (row: any) => {
      const style: any = {};
      row.alreadyExist ? (style.backgroundColor = '#FFD70088') : (style.backgroundColor = '#c8e6c9');
      return style;
    };

    const getErrorMessage = (error: any) => {
      switch (error.errorCode) {
        case '400':
          if (error.errorMessage === 'silex_format_error') {
            return t('import_silex.error_text.silex_error');
          }
          if (error.errorMessage === 'Meters already in a round') {
            return t('import_silex.error_text.meter_in_round');
          }
        case '500':
          if (error.errorMessage === 'charset_rejected') {
            const fileCharset: string = _.get(error, 'errorDetail.fileCharset[0]', '');
            const paramCharset: string = _.get(error, 'errorDetail.paramCharset[0]', '');

            return t('import_silex.text.wrong_encoding', {
              filename: file.get('file').name,
              fileCharset,
              paramCharset,
            });
          }
        default:
          return t('import_silex.text.load_file_failed', { filename: file.get('file').name });
      }
    };
    const getErrorTable = (error: any) => {
      switch (error.errorCode) {
        case '400':
          if (error.errorMessage === 'silex_format_error') {
            const errorFoundList: string[] = JSON.parse(error.errorFound);
            const data = errorFoundList.map(label => {
              const missingObjects = JSON.parse(_.get(error, label));
              return missingObjects.map((missingObject: any) => {
                if (label === 'lines_incorrect_length') {
                  return {
                    id: label + missingObject,
                    description: t(`import_silex.error_text.${label}`),
                    line: missingObject,
                  };
                } else if (label === 'wrongData') {
                  return {
                    id: label + missingObject._1,
                    description: t('import_silex.error_text.wrong_data'),
                    line: missingObject._1,
                    type: missingObject._3,
                    column: missingObject._2,
                  };
                } else if (missingObject._4) {
                  return {
                    id: label + missingObject._1,
                    description: t(`import_silex.error_text.${label}`),
                    line: missingObject._1,
                    type: missingObject._4,
                    column:
                      missingObject._2 !== missingObject._3
                        ? missingObject._2 + '-' + missingObject._3
                        : missingObject._2,
                  };
                } else {
                  return {
                    id: label + missingObject._2 + missingObject._3,
                    // label: t(`import_silex.error_text.${label}`),
                    type: missingObject._1,
                    description:
                      missingObject._2 !== '' || missingObject._3 !== ''
                        ? t(`import_silex.error_text.${label}`, {
                            serial: missingObject._2,
                            page: missingObject._3,
                          })
                        : t(`import_silex.error_text.${label}_empty`),
                  };
                }
              });
            });
            return (
              <BootstrapTable
                keyField="id"
                bootstrap4
                bordered
                hover
                data={data.flat().sort((a, b) => a.type - b.type)}
                columns={errorImportColumns}
              />
            );
          }

          if (error.errorMessage === 'Meters already in a round') {
            const data = Object.entries(error.errorDetail).map(([meterSerial, listRnd]: any) =>
              listRnd.map((rnd: string) => ({
                id: meterSerial + rnd,
                serial: meterSerial,
                round: rnd,
              }))
            );
            return (
              <BootstrapTable
                keyField="id"
                bootstrap4
                bordered
                hover
                data={data.flat().sort((a, b) => a.serial - b.serial)}
                columns={errorAlreadyLinkMeterColumns}
              />
            );
          }
      }
    };

    console.log('ImportSilex::render::196', selectedRound);
    return (
      <div className="col-12">
        <div className="table-info-container">
          <h2>
            <span>
              <ImportBleu height="1em" width="1em" fill="#31c6b3" />
            </span>
            {t('import_silex.text.import_rounds')}
          </h2>
          {!imports.loading && !imports.silex ? (
            <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
              <FormGroup style={{ width: '25%' }}>
                <Label for="dataFile">
                  {t('import_silex.text.round_file')} ({t('import_silex.text.silex_format')}) {`(${extension})`}
                </Label>
                <CustomInput
                  type="file"
                  id="import-silex-button"
                  onChange={this.onChange}
                  accept={extension}
                  name="customFile"
                  label={file ? file.get('file').name : t('all.text.select_file')}
                />
              </FormGroup>
              {this.chooseFileDone() && (
                <>
                  {admin && (
                    <div style={{ display: 'flex' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormGroup component="fieldset" style={{ width: '100%', margin: '-4px 16px 4px 8px' }}>
                          <FormLabel component="legend">
                            Choisir le type d'import{' '}
                            <span
                              style={{ background: 'gold', borderRadius: 100, padding: '2px 10px', color: 'white' }}
                            >
                              admin
                            </span>{' '}
                            :
                          </FormLabel>
                          {selectedLines.map((value: boolean, type: number) => (
                            <FormControlLabel
                              key={type}
                              control={
                                <Checkbox
                                  checked={value}
                                  name={`T${type}`}
                                  color="primary"
                                  style={{ color: '#31c6b3' }}
                                  onClick={() => this.handleLineChoice(type, !value)}
                                  disabled={!admin}
                                />
                              }
                              label={`Ligne T${type}`}
                            />
                          ))}
                        </FormGroup>
                        <div style={{ display: 'flex', gap: 8 }}>
                          <Button
                            className={
                              'import-silex-selection-button' +
                              (new Array<boolean>(8)
                                .fill(true)
                                .every((value: boolean, index: number) => value === selectedLines[index])
                                ? ' selected'
                                : '')
                            }
                            onClick={() => this.setState({ selectedLines: new Array<boolean>(8).fill(true) })}
                          >
                            Tout sélectionner
                          </Button>
                          <Button
                            className={
                              'import-silex-selection-button' +
                              (new Array<boolean>(8)
                                .fill(false)
                                .every((value: boolean, index: number) => value === selectedLines[index])
                                ? ' selected'
                                : '')
                            }
                            onClick={() => this.setState({ selectedLines: new Array<boolean>(8).fill(false) })}
                          >
                            Tout désélectionner
                          </Button>
                          <Button
                            className={
                              'import-silex-selection-button' +
                              ([true, true, false, true, false, true, true, true].every(
                                (value: boolean, index: number) => value === selectedLines[index]
                              )
                                ? ' selected'
                                : '')
                            }
                            onClick={() => this.setState({ selectedLines: defaultSelectedLinesValue })}
                          >
                            Sélection par défaut
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div>
                    <Button
                      disabled={imports && imports.loading}
                      color="primary"
                      style={{
                        marginBottom: 16,
                        color: 'white',
                      }}
                      onClick={!admin ? this.submitFile : this.newfileSubmition}
                    >
                      {t('all.button.submit')}
                    </Button>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <Button color="danger" onClick={this.invalidFile}>
                {t('all.button.change_file')}
              </Button>
              <p
                style={{
                  padding: 'auto',
                  fontWeight: 'bold',
                  marginBottom: '0',
                  alignSelf: 'center',
                  marginLeft: '5px',
                }}
              >
                {file && file.get('file').name}
              </p>
            </div>
          )}

          <div style={{ marginBottom: 20 }}> </div>

          {this.chooseFileDone() && (
            <div>
              <div style={{ display: 'flex' }}>
                {file && imports && imports.loading && (
                  <div style={{ marginTop: '10px' }}>
                    <LoadingBand message={t('import_silex.loading_band.silex_file_loading')} />
                  </div>
                )}
              </div>
              <div style={{ marginBottom: 10 }} />
              {imports &&
                imports.silex &&
                (imports.silex.filter((rnd: any) => rnd.t0Result.alreadyExist).length === 0 ? (
                  <SuccessBand
                    message={t('import_silex.success_msg.x_loaded_silex_file', {
                      count: imports.silex.filter((rnd: any) => !rnd.t0Result.alreadyExist).length,
                    })}
                  />
                ) : (
                  <WarningBand
                    message={
                      t('import_silex.error_band.load_x_file_failed', { fileName: file.get('file').name }) +
                      '. ' +
                      (imports.silex.length === 1
                        ? t('import_silex.error_band.already_exists_round', {
                            round: imports.silex[0].t0Result.label + `[${imports.silex[0].t0Result.codeTournee}]`,
                          })
                        : t('import_silex.error_band.already_exists_rounds', {
                            rounds: imports.silex
                              .map((rnd: any) => rnd.t0Result.label + `[${rnd.t0Result.codeTournee}]`)
                              .join(', '),
                          }))
                    }
                  />
                ))}
            </div>
          )}
          {file && imports && imports.error && (
            <ErrorBand
              message={
                <>
                  <p>{getErrorMessage(imports.error)}</p>
                </>
              }
            />
          )}
          {imports && imports.silex && !this.state.adminImport && (
            <>
              <h3>{t('import_silex.report_title.importation_report')}</h3>
              <BootstrapTable
                keyField="results"
                bootstrap4
                bordered={false}
                hover
                data={imports.silex}
                columns={columns}
                rowStyle={rowStyle}
              />
            </>
          )}
          {imports.silex && this.state.adminImport && (
            <div className="tab-onglet-import-container">
              {_.get(imports, 'silex[0].t0Result') && (
                <FormGroup style={{ marginBottom: '0.5rem', width: '15%' }}>
                  <Label for="exampleSelect">Liste des tournées</Label>
                  <Input
                    type="select"
                    name="select"
                    id="exampleSelect"
                    onChange={(e: any) => {
                      this.setState({
                        selectedRound: imports.silex.find(
                          (round: any) => round.t0Result.codeTournee === e.target.value
                        ),
                      });
                    }}
                  >
                    <option disabled selected>
                      {t('import_silex.text.select_round')}
                    </option>
                    {imports.silex.map((round: any) => (
                      <option
                        key={round.t0Result.codeTournee}
                        value={round.t0Result.codeTournee}
                        selected={Object.is(round, selectedRound)}
                      >
                        {round.t0Result.codeTournee}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              )}
              <div className="onglet-container">
                {_.uniq<string>(
                  imports.silex
                    .map((round: any) =>
                      _.entries(round)
                        .filter((rndMap: any) => rndMap[1] !== null)
                        .map((rndMap: any) => rndMap[0])
                    )
                    .flat()
                )
                  .map((type: string) => parseInt(type[1]))
                  .map((el: number) => (
                    <div
                      key={`t${el}Result`}
                      className={`onglet ${
                        lineType === el ? 'actif' : !_.get(selectedRound, `t${el}Result`) ? 'inactif' : ''
                      }`}
                      onClick={() => {
                        if (_.get(selectedRound, `t${el}Result`)) {
                          this.setState({ lineType: el });
                        }
                      }}
                    >
                      <span>{`Type ${el}`}</span>
                    </div>
                  ))}
              </div>
              {_.get(selectedRound, `t${lineType}Result`) && (
                <BootstrapTable
                  keyField="identifiant"
                  bootstrap4
                  bordered
                  hover
                  data={this.getAdminData(selectedRound, lineType)}
                  columns={_.get(adminImportColumns, `t${lineType}Result`)}
                />
              )}
            </div>
          )}
          {imports.error && getErrorTable(imports.error)}
          {imports.errorSilex && (
            <>
              {_.get(imports, 'errorSilex[0].listResults') && (
                <div className="tab-onglet-import-container">
                  <FormGroup style={{ marginBottom: '0.5rem', width: '15%' }}>
                    <Label for="exampleSelect">Liste des tournées</Label>
                    <Input
                      type="select"
                      name="select"
                      id="exampleSelect"
                      defaultValue=""
                      onChange={(e: any) => {
                        this.setState({
                          selectedRound: imports.errorSilex.find((error: any) => error.roundCode === e.target.value),
                        });
                      }}
                    >
                      <option disabled>{t('import_silex.text.select_round')}</option>
                      {selectedRound &&
                        imports.errorSilex
                          .map((el: any) => el.roundCode)
                          .map((roundCode: string, index: number) => (
                            <option
                              key={roundCode + index}
                              value={roundCode}
                              selected={roundCode === selectedRound.roundCode}
                            >
                              {roundCode}
                            </option>
                          ))}
                    </Input>
                  </FormGroup>
                  <div className="onglet-container">
                    {_.uniq(
                      imports.errorSilex
                        .map((round: any) => round.listResults.map((error: any) => error.lineType))
                        .flat()
                    ).map((el: any) => (
                      <div
                        key={el}
                        className={`onglet ${
                          lineType === el
                            ? 'actif'
                            : selectedRound.listResults.find((round: any) => round.lineType === el).differences
                                .length === 0
                            ? 'inactif'
                            : ''
                        }`}
                        onClick={() => {
                          if (
                            selectedRound.listResults.find((round: any) => round.lineType === el).differences.length > 0
                          ) {
                            this.setState({ lineType: el });
                          }
                        }}
                      >
                        <span>{`Type ${el}`}</span>
                      </div>
                    ))}
                  </div>
                  {selectedRound && (
                    <BootstrapTable
                      keyField="identifiant"
                      bootstrap4
                      bordered
                      hover
                      data={selectedRound.listResults.find((el: any) => el.lineType === lineType).differences}
                      columns={errorColumns}
                    />
                  )}
                </div>
              )}
              {_.size(_.get(imports, 'errorSilex[0].doublonsLine')) > 0 && (
                <div>
                  <h2>{t('import.anomaly_text.doublons')} :</h2>
                  {_.entries(_.get(imports, 'errorSilex[0].doublonsLine')).map(([key, value]) => (
                    <div>
                      <h4>{key}</h4>
                      {value.map(el => (
                        <div className="tourneeDoublon" style={{ borderLeft: 'solid', paddingLeft: '15px' }}>
                          <p className="bold-tournee">
                            {t('all.round.round_name')} : {el.rndName}
                          </p>
                          <p className="bold-tournee">
                            {t('all.round.round_code')} : {el.rndCode}
                          </p>
                          <p>
                            {t('columns.cardOrder')} : {el.cardOrder}
                          </p>
                          <p>
                            {t('columns.clpInformations')} : {el.clpInformations}
                          </p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
              {_.size(_.get(imports, 'errorSilex[0].emptySerialLine')) > 0 && (
                <div>
                  <h2>{t('import.anomaly_text.emptyMeterSerial')} :</h2>
                  {_.get(imports, 'errorSilex[0].emptySerialLine').map(el => (
                    <div>
                      <div className="tourneeDoublon" style={{ borderLeft: 'solid', paddingLeft: '15px' }}>
                        <p className="bold-tournee">
                          {t('all.round.round_name')} : {el.rndName}
                        </p>
                        <p className="bold-tournee">
                          {t('all.round.round_code')} : {el.rndCode}
                        </p>
                        <p>
                          {t('columns.cardOrder')} : {el.cardOrder}
                        </p>
                        <p>
                          {t('columns.clpInformations')} : {el.clpInformations}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {_.size(_.get(imports, 'errorSilex[0].dateLineError')) > 0 && (
                <div>
                  <h2>{t('import.anomaly_text.dateLineError')} :</h2>
                  {_.get(imports, 'errorSilex[0].dateLineError').map(el => (
                    <div>
                      <h4>{el.serialCpt}</h4>
                      <div className="tourneeDoublon" style={{ borderLeft: 'solid', paddingLeft: '15px' }}>
                        <p className="bold-tournee">
                          {t('all.round.round_name')} : {el.rndName}
                        </p>
                        <p className="bold-tournee">
                          {t('all.round.round_code')} : {el.rndCode}
                        </p>
                        <p>
                          {t('columns.cardOrder')} : {el.cardOrder}
                        </p>
                        <p>
                          {t('columns.clpInformations')} : {el.clpInformations}
                        </p>
                        <p>
                          {t('import.anomaly_text.field_with_date_inf_1970')} : {t(`columns.${el.fieldError}`)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, meters, radios, imports } = state;
  const { user } = authentication;
  return {
    user,
    imports,
  };
}

const connectedStockImportation = connect(mapStateToProps)(ImportSilex);
const tr = withTranslation()(connectedStockImportation);
export default tr;
