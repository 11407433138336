import initialProgressService from './initialProgress.service';
import initialProgressConstants from './initialProgress.constants';
import _ from 'lodash';
import tourneeConstants from '../_constants/tournee.constants';
import tourneeService from '../_services/tournee.service';

let intervalTelereleveReads: NodeJS.Timeout | null = null;
function launchIntervalTelereleveReads(roundCode: string, startDate: any, endDate: any, replaceMoreRecent: boolean) {
  function success(tournee: Array<any>) {
    return { type: tourneeConstants.GET_TELERELEVE_TOURNEE_SUCCESS, tournee };
  }
  return (dispatch: Function) => {
    tourneeService
      .launchIntervalTelereleveReads(roundCode, startDate, endDate, replaceMoreRecent)
      .then((tournee: any) => {
        if (_.isNull(intervalTelereleveReads)) {
          intervalTelereleveReads = setInterval(() => {
            dispatch(launchIntervalTelereleveReads(roundCode, startDate, endDate, replaceMoreRecent));
          }, 1000);
        }
        if (tournee) {
          clearInterval(intervalTelereleveReads);
          intervalTelereleveReads = null;
          dispatch(success(tournee));
        }
      })
      .catch((error: Object) => {});
  };
}

let intervalRecupProgress: NodeJS.Timeout | null = null;
function getProgress() {
  function request() {
    return { type: initialProgressConstants.GET_INITIAL_PROGRESS_REQUEST };
  }
  function success(progressCode: Number) {
    return { type: initialProgressConstants.GET_INITIAL_PROGRESS_SUCCESS, progressCode };
  }
  function failure(error: Object) {
    return { type: initialProgressConstants.GET_INITIAL_PROGRESS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    initialProgressService
      .getProgress()
      .then((progressCode: String) => {
        if (_.isNull(intervalRecupProgress) && _.toInteger(progressCode) < 5) {
          intervalRecupProgress = setInterval(() => {
            dispatch(getProgress());
          }, 5000);
        }
        if (_.toInteger(progressCode) === 5 && !_.isNull(intervalRecupProgress)) {
          clearInterval(intervalRecupProgress);
          intervalRecupProgress = null;
        }
        dispatch(success(_.toInteger(progressCode)));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

export default {
  getProgress,
};
