import React, { Component, Fragment, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  Button,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  PopoverBody,
  PopoverHeader,
  Spinner,
  UncontrolledDropdown,
  UncontrolledPopover,
  UncontrolledTooltip,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import filterFactory from 'react-bootstrap-table2-filter';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { ar } from 'date-fns/locale';
import BurgerHeader from '../SvgComponents/BurgerHeader';
import { localeActions } from '../_actions';
import ErrorBand from '../Bands/Error';
import LoadingBand from '../Bands/Loading';
import ListAdder from '../SynchroResources/ListAdder';
import SwitchButton from './SwitchButton';
import SvgPoubelleBleu from '../SvgComponents/PoubelleBleu';
import StepPopover from './StepPopover';

interface Props {
  permissions: any;
  users: any;
  columns: any;
  dispatch: Function;
  match: any;
  data: any;
  titleTab: string;
  link: string;
  unlink: string;
  svgTitle: any;
  burger: boolean;
  withAdd: boolean;
  clickable: boolean;
  t: any;
}

/**
 * @class Resources
 * @extends {Component}
 */
class TableauGestion extends Component<Props, any> {
  /**
   * @constructor
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      activeTab: null,
      authorized: true,
      multiOn: false,
      isDeletionPopupOpen: false,
      dataLength: null,
    };
    this.handleChangeAdd = this.handleChangeAdd.bind(this);
    this.afterFilter = this.afterFilter.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(localeActions.load());
  }

  MutliDeletionPopupTrigger = props => {
    const { t, synchro, deleteTournee } = this.props;
    const { selectedRows } = props;
    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const newTasks = synchro && synchro.tourneeRessources && _.entries(synchro.tourneeRessources).map(el => el[1]);
    let undeletableRounds: string[] = [];
    let undeletableSelectedRounds: string[] = [];
    let selectedUnDeletableRoundFull = [];
    let deletableRounds: string[] = [];
    if (newTasks) {
      newTasks.forEach(el => (el.canExport = this.props.canAction(el)));
      undeletableRounds = newTasks.filter(el => !el.canDelete).map(el => el.id);
    }
    const selectedRowsFull = newTasks.filter(el => selectedRows.includes(el.id));
    deletableRounds = selectedRowsFull.filter(el => el.canDelete);
    undeletableSelectedRounds = undeletableRounds.filter(el => selectedRows.includes(el));
    selectedUnDeletableRoundFull = newTasks.filter(el => undeletableSelectedRounds.includes(el.id));
    const areRoundsUndeletable = undeletableSelectedRounds.length > 0;
    return (
      <>
        <Button
          id="delete-mutli"
          style={{ marginRight: '20px' }}
          color="danger"
          disabled={_.size(selectedRows) === 0 && !open}
          onClick={e => {
            e.stopPropagation();
            setOpen(deletableRounds.length !== 0);
          }}
        >
          {t('all.button.delete')}
        </Button>
        {deletableRounds.length === 0 && (
          <UncontrolledTooltip placement="bottom" target="delete-mutli">
            {t('tournee_ressources.no_round_can_be_deleted')}
          </UncontrolledTooltip>
        )}
        <StepPopover
          id="deleteTourneePopoverMutli"
          target="delete-mutli"
          open={open}
          confirm={confirm}
          setConfirm={setConfirm}
          withTwoSteps={areRoundsUndeletable}
          onClose={() => {
            setOpen(false);
          }}
          t={t}
          permission
          action={() => {
            deleteTournee();
            setOpen(false);
          }}
          title={
            selectedRows.length > 1
              ? t('tournee_ressources.deleting_round_plural')
              : t('tournee_ressources.deleting_round')
          }
          confirmText={
            !areRoundsUndeletable &&
            t('tournee_ressources.popup_title.ask_delete_x_round', {
              count: selectedRows ? selectedRows.length : 0,
              roundName: selectedRows && selectedRows.toString(),
            })
          }
          confirmElement={
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <b
                style={{ color: 'red', width: 'fit-content', margin: '0 auto', marginBottom: 10, textAlign: 'center' }}
              >
                {areRoundsUndeletable
                  ? t('tournee_ressources.popup_title.some_round_cant_delete') + ' :'
                  : t('all.text.irreversible_action_warning')}
              </b>
              {selectedRowsFull && selectedRowsFull.length > 1 && (
                <div className="delete-round-preview-container">
                  {areRoundsUndeletable
                    ? _.take(selectedUnDeletableRoundFull, 5).map(row => {
                        return <div className="delete-round-preview-undeletable">{row.content}</div>;
                      })
                    : _.take(selectedRowsFull, 5).map(row => {
                        return <div className="delete-round-preview">{row.content}</div>;
                      })}
                  {areRoundsUndeletable
                    ? _.size(selectedUnDeletableRoundFull) > 5 && (
                        <div className="delete-round-preview">
                          {' '}
                          + {_.size(selectedUnDeletableRoundFull) - 5} {t('all.text.other_plural_fem')}{' '}
                        </div>
                      )
                    : _.size(selectedRowsFull) > 5 && (
                        <div className="delete-round-preview">
                          {' '}
                          + {_.size(selectedRowsFull) - 5} {t('all.text.other_plural_fem')}{' '}
                        </div>
                      )}
                </div>
              )}
            </div>
          }
          warningText={<p style={{ textAlign: 'center' }}>{t('tournee_ressources.deletable_rounds_confirmation')}</p>}
          warningElement={
            <div className="delete-round-preview-container">
              {_.take(deletableRounds, 10).map(row => {
                return <div className="delete-round-preview">{row.content}</div>;
              })}
              {_.size(deletableRounds) > 10 && (
                <div className="delete-round-preview">
                  {' '}
                  + {_.size(selectedUnDeletableRoundFull) - 10} {t('all.text.other_plural_fem')}{' '}
                </div>
              )}
            </div>
          }
        />
      </>
    );
  };

  handleChangeAdd() {
    this.setState({ multiOn: !this.state.multiOn });
    this.props.handleMultiSelection();
  }

  afterFilter = (result: any, filters) => {
    const { dataLength } = this.state;
    if (result.length !== dataLength) {
      this.setState({
        dataLength: result.length,
      });
    }
  };

  /**
   * Construit le composant et mise en place
   * d'un système d'onglets pour les résultats
   * de la recherche
   *
   * @method render
   */
  render() {
    const {
      permissions,
      columns,
      user,
      data,
      titleTab,
      svgTitle,
      burger,
      withAdd,
      clickable,
      tournee,
      synchro,
      withRedirection,
      t,
      selectedRows,
      expandRow,
      customRowStyle,
      multiSelection,
      selectRow,
    } = this.props;
    const { authorized, dataLength } = this.state;

    const rowClasses = (row: any, rowIndex: any) => {
      if (row.new) {
        return 'synchro-message-new';
      }
      if (row.old) {
        return 'synchro-message-old';
      }
      return 'synchro-message';
    };

    return (
      <div className="table-info-container">
        <h2>
          {svgTitle} {`${dataLength || data.length} ${titleTab}`}
          <span className="addItem float-right">
            {withAdd && (
              <span className="addWidget float-right">
                <ListAdder type={titleTab} withRedirection={withRedirection} className="List-synchro-adder" />
              </span>
            )}
            {burger && (
              <div className="extra-tab-synchro">
                <UncontrolledDropdown nav inNavbar className="header-item-last">
                  <DropdownToggle nav caret>
                    <BurgerHeader fill="#808080" height="0.8em" width="0.8em" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    {titleTab === 'Portables :' && authorized && (
                      <DropdownItem tag={Link} to={`/users/${user.id}`}>
                        {t('tableau_gestion.menu_option.add')}
                      </DropdownItem>
                    )}
                    <DropdownItem divider />
                    <DropdownItem tag={Link} to="/signin/">
                      {t('tableau_gestion.menu_option.unlink')}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem tag={Link} to="/signin/">
                      {t('tableau_gestion.menu_option.link')}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem tag={Link} to="/synchronisation/portables/alarmes">
                      {t('tableau_gestion.menu_option.alarm_plural')}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            )}
          </span>
        </h2>
        {titleTab === t('all.round.round_plural') && (
          <div style={{ display: 'flex' }}>
            <h3 style={{ margin: '0 20px' }}>{t('all.text.multiple_select')}</h3>
            <SwitchButton
              switchClass="synchroSwitch"
              checked={multiSelection}
              handleChange={this.handleChangeAdd}
              onColor="#409F95"
              offColor="#999999"
              offHandleColor="#CCCCCC"
              onHandleColor="#31C6B3"
            />
            {this.props.multiSelection && !tournee.exportProcessing && (
              <div style={{ marginLeft: '20px' }}>
                <Fragment>
                  <this.MutliDeletionPopupTrigger selectedRows={this.props.selectedRows} />
                </Fragment>
                <Button
                  color="primary"
                  id="export-multi"
                  disabled={_.size(this.props.selectedRows) === 0}
                  onClick={
                    _.get(tournee, 'options.export_round_csv_custom') !== 'true' &&
                    _.get(tournee, 'options.export_round_teleo') !== 'true' &&
                    _.get(tournee, 'options.export_round_factu') !== 'true' &&
                    this.props.exportTournee
                  }
                >
                  {t('all.button.export')}
                </Button>
                {(_.get(tournee, 'options.export_round_csv_custom') === 'true' ||
                  _.get(tournee, 'options.export_round_csv_custom') === true ||
                  _.get(tournee, 'options.export_round_teleo') === 'true' ||
                  _.get(tournee, 'options.export_round_teleo') === true ||
                  _.get(tournee, 'options.export_round_factu') === 'true' ||
                  _.get(tournee, 'options.export_round_factu') === true) && (
                  <UncontrolledPopover trigger="legacy" placement="top" target="export-multi">
                    <PopoverHeader style={{ color: 'primary' }}>
                      {t('tournee.text.export_round_csv_custom')}
                    </PopoverHeader>
                    <PopoverBody style={{ display: 'grid' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <div className="button-box">
                          <p>Dioptase</p>
                          <Button color="info" onClick={e => this.props.exportTournee(e, null)} style={{ margin: 0 }}>
                            {t('tournee.text.export_dat')}
                          </Button>
                        </div>
                        {(_.get(tournee, 'options.export_round_csv_custom') === 'true' ||
                          _.get(tournee, 'options.export_round_csv_custom') === true) && (
                          <div className="button-box">
                            <p>CSV</p>
                            {_.sortBy(_.get(tournee, 'options.listExportRound').split(',')).map(el => (
                              <Button
                                key={el}
                                color="info"
                                onClick={e => this.props.exportTourneeCsv(e, null, el)}
                                style={{ margin: 0, marginTop: '10px' }}
                              >
                                {el}
                              </Button>
                            ))}
                          </div>
                        )}
                        {(_.get(tournee, 'options.export_round_teleo') === 'true' ||
                          _.get(tournee, 'options.export_round_teleo') === true) && (
                          <div className="button-box">
                            <p>Veolia</p>
                            <Button
                              color="info"
                              onClick={e => this.props.exportTourneeTeleo(e, null)}
                              style={{ margin: 0, marginTop: '10px' }}
                            >
                              {t('all.text.export_to_teleo')}
                            </Button>
                          </div>
                        )}
                        {(_.get(tournee, 'options.export_round_factu') === 'true' ||
                          _.get(tournee, 'options.export_round_factu') === true) && (
                          <div className="button-box">
                            <p>{t('all.text.factu')}</p>
                            <Button
                              color="info"
                              onClick={e => this.props.exportFactu(e, null)}
                              style={{ margin: 0, marginTop: '10px' }}
                            >
                              {tournee.facturation}
                            </Button>
                          </div>
                        )}
                      </div>
                    </PopoverBody>
                  </UncontrolledPopover>
                )}
              </div>
            )}
          </div>
        )}
        <br /> <div className="col-md-12">{user.error && <ErrorBand message={user.error} />}</div>
        <br />{' '}
        <div className="col-md-12">
          {(user.error && <ErrorBand message={user.error} />) ||
            (tournee.errorInfo && <ErrorBand message={t('tableau_gestion.error_band.exporting')} />)}
        </div>
        <BootstrapTable
          keyField="id"
          data={data}
          columns={columns}
          bootstrap4
          hover
          bordered={false}
          filter={filterFactory({ afterFilter: this.afterFilter })}
          rowClasses={titleTab === 'Messages' || titleTab === 'Marquages' ? rowClasses : clickable && 'clickable'}
          expandRow={expandRow && expandRow}
          rowStyle={customRowStyle && customRowStyle}
          selectRow={selectRow && selectRow}
          // pagination={permissions.items > 10 ? paginationFactory() : null}
        />
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, permissions, tournee, synchro } = state;
  const { user } = authentication;

  return {
    user,
    tournee,
    synchro,
    // permissions,
  };
}

const connectedResources = connect(mapStateToProps)(TableauGestion);
const tr = withTranslation()(connectedResources);
export default tr;
