import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

type Props = {
  msg: string;
  style?: Object;
};

class NoPresent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { msg, style } = this.props;
    return (
      <div className={'infoDisplay noFilled'} style={style}>
        {msg}
      </div>
    );
  }
}

export default NoPresent;
