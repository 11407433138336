import { authHeader, handleResponse } from '../_helpers';

async function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/sensor`, requestOptions);
  return handleResponse(response);
}

async function getAllByLocationId(locationId: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/sensor/location/${locationId}`, requestOptions);
  return handleResponse(response);
}

async function getSensorById(sensorId: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/sensor/${sensorId}`, requestOptions);
  return handleResponse(response);
}

async function putOpen(devEUI: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  const response = await fetch(`/api/sensor/${devEUI}/open`, requestOptions);
  return handleResponse(response);
}

async function putClose(devEUI: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  const response = await fetch(`/api/sensor/${devEUI}/close`, requestOptions);
  return handleResponse(response);
}

async function putUpLinkFrequency(devEUI: string, content: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(content),
  };
  const response = await fetch(`/api/sensor/${devEUI}/uplinkfrequency`, requestOptions);
  return handleResponse(response);
}

async function putUpLinkScheduleTime(devEUI: string, content: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(content),
  };
  const response = await fetch(`/api/sensor/${devEUI}/uplinkschedule`, requestOptions);
  return handleResponse(response);
}

async function putSyncTime(devEUI: string, content: string) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: content,
  };
  const response = await fetch(`/api/sensor/${devEUI}/synctime`, requestOptions);
  return handleResponse(response);
}

async function addAlert(alert: any, sensorId: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(alert),
  };
  const response = await fetch(`/api/sensor/${sensorId}/alert`, requestOptions);
  return handleResponse(response);
}

const sensorService = {
  getAll,
  getAllByLocationId,
  getSensorById,
  putOpen,
  putClose,
  putUpLinkFrequency,
  putUpLinkScheduleTime,
  putSyncTime,
  addAlert,
};

export default sensorService;
