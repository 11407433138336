import { authHeader, handleResponse } from '../_helpers';
import { Widget } from '../_entities/widget';

async function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch('/api/widget', requestOptions);
  return handleResponse(response);
}

async function get(widgetId: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/widget/${widgetId}/`, requestOptions);
  return handleResponse(response);
}

async function getWidgetData(widgetId: number, displayId: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(
    `/api/widget/${widgetId}/content${displayId === 'ALARM' ? '/ALARM' : ''}`,
    requestOptions
  );
  return handleResponse(response);
}

async function getContent(displayId: string, widgetContent: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(widgetContent),
  };
  let response = await fetch(`/api/widget/content/${displayId}`, requestOptions);
  return handleResponse(response);
}

async function save(widget: Widget, dashboardId: number) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(widget),
  };
  let response = await fetch(`/api/widget/dashboard/${dashboardId}`, requestOptions);
  return handleResponse(response);
}

async function update(widget: Widget, dashboardId: number) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(widget),
  };
  let response = await fetch(`/api/widget/dashboard/${dashboardId}/${widget.id}`, requestOptions);
  return handleResponse(response);
}

async function toDelete(widgetId: number, dashboardId: number) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };
  let response = await fetch(`/api/widget/dashboard/${dashboardId}/${widgetId}`, requestOptions);
  return handleResponse(response);
}

async function getWidgetContent(template: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(template),
  };
  let response = await fetch(`/api/widget/preview/content`, requestOptions);
  return handleResponse(response);
}

const widgetService = {
  getAll,
  get,
  update,
  toDelete,
  save,
  getContent,
  getWidgetData,
  getWidgetContent,
};

export default widgetService;
