import React from 'react';
import 'rc-switch/assets/index.css';
import _ from 'lodash';
import { Col, Input, Row, Table } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import SvgMasqueVert from '../SvgComponents/Masque_Vert';
import SvgFiltreVert from '../SvgComponents/FiltreVert';
import SvgCompteurVert from '../SvgComponents/CompteurVert';
import SvgAlarmeVert from '../SvgComponents/AlarmeVert';
import { User } from '../_entities/user';
import { locales, locations, vmeters } from '../_interfaces/reducers';
import { colorActions, locationActions, masksActions } from '../_actions';
import Picto from '../MaskBar/AlarmPicto';
import ListTools from '../List/ListTools';
import ChartMaskAlarm from './ChartMaskAlarm';
import LoadingBand from '../Bands/Loading';
import colorize from '../_helpers/colorize-helper';
import Loading from '../_animations/Loading';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

interface Props {
  pdis: any;
  location: any;
  user: User;
  match: any;
  mask: {
    fetchedMask: any;
    maskInfo: any;
    maskPdiAlarms: any;
  };
  locales: locales;
  dispatch: Function;
  vmeters: vmeters;
  locations: locations;
  alert: any;
  t: Function;
}

interface State {
  maskInfo: any;
  alarmSelected: any;
  meterSelected: any;
  suggestions: any;
}

/**
 * Liste des templates
 *
 * @class AlarmsComponent
 * @extends {React.Component<Props>}
 */
class MaskAlarm extends React.Component<Props, State> {
  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof AlarmsComponent
   */
  constructor(props: Props) {
    super(props);
    this.getAlarms = this.getAlarms.bind(this);
    this.onChange = this.onChange.bind(this);
    this.dataGenerator = this.dataGenerator.bind(this);

    this.state = {
      maskInfo: undefined,
      alarmSelected: undefined,
      meterSelected: undefined,
      suggestions: undefined,
    };
  }

  static getDerivedStateFromProps(props: any, state: State) {
    if (props.mask.maskInfo && !props.mask.maskInfo.loading && !state.maskInfo) {
      const maskInfo = _.cloneDeep(props.mask.maskInfo);
      maskInfo.listPdi.map(pdi => (pdi.nbDayAlarm = 0));
      if (maskInfo.listDatePdiMaskAlarm.length > 0) {
        maskInfo.listDatePdiMaskAlarm.map((alarm: any) => {
          alarm.pdiIds.map((pdiId: string) => {
            if (_.find(maskInfo.listPdi, { mtrSerial: pdiId })) {
              const nbDayAlarm = _.find(maskInfo.listPdi, { mtrSerial: pdiId }).nbDayAlarm;
              _.find(maskInfo.listPdi, { mtrSerial: pdiId }).nbDayAlarm = nbDayAlarm ? nbDayAlarm + 1 : 1;
            }
          });
        });
      }

      return {
        maskInfo,
        suggestions: maskInfo.listPdi,
      };
    }
  }

  componentDidMount() {
    const { dispatch, match, location } = this.props;
    const { locationId } = match.params;
    const maskId = location.search;
    console.log(locationId, this.props);
    if (maskId !== undefined && maskId.length > 0) {
      dispatch(masksActions.getMask(maskId.replace('?id=', '')));
    }
    dispatch(locationActions.getInfos(locationId));
    dispatch(masksActions.getMaskInfo(maskId.replace('?id=', '')));
    dispatch(colorActions.load());
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(masksActions.clear());
  }

  handleAlarms(alarmId: any) {
    const { alarmSelected } = this.state;

    if (alarmSelected !== alarmId) {
      this.setState({
        alarmSelected: alarmId,
      });
    } else {
      this.setState({
        alarmSelected: undefined,
      });
    }
  }

  getAlarms() {
    const { t } = this.props;
    const { maskInfo, alarmSelected } = this.state;

    return (
      <div>
        {maskInfo.listAlarm.map(({ name }) => (
          <div style={{ marginTop: '20px', marginBottom: '10px' }}>
            {Picto(name, {
              width: '2em',
              height: '2em',
              fill: '#31c6b3',
            })}
            <span style={{ marginLeft: '15px' }}>{t(`alarm.type.${name.toLowerCase()}`)}</span>

            {/* <span className={alarmSelected === alarm.id ? 'maskSelectedArrow' : 'maskUnselectedArrow'}>&#x3009;</span> */}
          </div>
        ))}
      </div>
    );
  }

  formatColumns = (type: string, recursive: boolean) => {
    const { t } = this.props;
    return ListTools.getDefaultColumns(type).map(champ => ({
      dataField: champ,
      text: t(`columns.${champ}`),
      sort: true,
    }));
  };

  onChange(e: any) {
    const { maskInfo } = this.state;
    const value = e.target.value.toLowerCase();
    let newSuggestion = [];

    if (_.isEmpty(value)) {
      newSuggestion = maskInfo.listPdi;
    } else {
      newSuggestion = _.filter(maskInfo.listPdi, function(item) {
        return !!(
          (item.mtrSerial && item.mtrSerial.toLowerCase().includes(value)) ||
          (item.rdoSerial && item.rdoSerial.toLowerCase().includes(value)) ||
          (item.clpReference && item.clpReference.toLowerCase().includes(value))
        );
      });
    }
    this.setState({
      suggestions: newSuggestion,
    });
  }

  dataGenerator(meterId: number) {
    const { mask, colors } = this.props;
    const maskPdiAlarm = _.find(mask.maskPdiAlarms, { pdiId: meterId });
    const listPdiAlarm = mask.maskInfo.listDatePdiMaskAlarm;

    const types: string[] = [];
    const labels: string[] = [];
    const datasets: any[] = [];

    console.log('MASKALARM debug : ', colors, listPdiAlarm);

    if (!meterId && mask.maskInfo && listPdiAlarm) {
      listPdiAlarm.map(el => {
        labels.push(moment(el.date).format(`DD/MM/YYYY`));
        el.pdiIds.map(pdiId => {
          if (!types.includes(pdiId)) {
            types.push(pdiId);
          }
        });
      });

      types.map((type: string, index) => {
        const data = [];
        listPdiAlarm.map(el => {
          data.push(el.pdiIds.includes(type) ? 1 : 0);
        });

        console.log('MASKALARM debug : ', type);

        const baseOpacity = 1.0 / types.length;
        datasets.push({
          label: type,
          backgroundColor: `rgba(166, 60, 118, ${baseOpacity * (index + 1)})`,
          data,
        });
      });
    } else if (meterId && maskPdiAlarm && maskPdiAlarm.alarms.length > 0) {
      maskPdiAlarm.alarms.map((alarm: any) => {
        labels.push(moment(alarm.date).format(`DD/MM/YYYY`));
        alarm.types.map((type: string) => {
          if (!types.includes(type)) {
            types.push(type);
          }
        });
      });

      types.map((type: string, index) => {
        const data = maskPdiAlarm.alarms.map((alarm: any) => (alarm.types.includes(type) ? 1 : 0));
        const baseOpacity = 1.0 / types.length;
        datasets.push({
          label: type,
          backgroundColor: colorize('chart', 'alarmType', type, colors.color),
          data,
        });
      });
    }

    console.log(datasets);

    return {
      labels,
      datasets,
    };
  }

  displayFilterInfo() {
    const { maskInfo } = this.state;
    const filterList = maskInfo.filterCause;

    return filterList.map((filterInfo: any) => (
      <tr>
        <td>{filterInfo.filter.label}</td>
        <td>{filterInfo.operator.label}</td>
        <td>
          {_.isEmpty(filterInfo.userInput)
            ? '-'
            : filterInfo.filter.type === 'text'
            ? filterInfo.userInput.replaceAll('|', ' ou ')
            : filterInfo.userInput}
        </td>
      </tr>
    ));
  }

  /**
   * Methode de rendu
   *
   * @method render
   */
  render() {
    const { dispatch, mask, colors, t } = this.props;
    const { maskInfo, meterSelected, suggestions } = this.state;
    const rowEvents = {
      onClick: (e: Object, row: any) => {
        if (row.nbDayAlarm != 0) {
          if (row != meterSelected) {
            this.setState({
              meterSelected: row,
            });
            if (!_.find(mask.maskPdiAlarms, { pdiId: row.id })) {
              dispatch(masksActions.getMaskPdiAlarms(row.id, maskInfo.id));
            }
          } else {
            this.setState({
              meterSelected: undefined,
            });
          }
        }
      },
    };

    const rowStyle = (row, rowIndex) => {
      if (meterSelected && meterSelected.id === row.id) {
        return { backgroundColor: 'rgba(49, 198, 179, 0.3)' };
      }
      if (row.nbDayAlarm === 0) {
        return { backgroundColor: 'rgba(233, 236, 239, 0.3)' };
      }
    };

    const rowClasses = (row, rowIndex) => {
      return row.nbDayAlarm === 0 ? '' : 'clickable';
    };

    const defaultSorted = [
      {
        dataField: 'nbDayAlarm',
        order: 'desc',
      },
    ];

    return (
      <div className="maskAlarmComponent">
        {((maskInfo && !maskInfo.listAlarm) || _.get(maskInfo, 'listAlarm', []).length === 0) && <Loading />}
        {maskInfo && maskInfo.listAlarm && maskInfo.listAlarm.length > 0 && colors && (
          <>
            <Row style={{ margin: '0' }}>
              <Col md="3">
                <div className="presentation-container">
                  <div className="presentation-header">
                    <span className="presentation-title">{t('all.mask.mask_name')}</span>
                    <h1 className="presentation-main-title">{maskInfo.name}</h1>
                  </div>
                  <div className="presentation-body">
                    <div className="display-info" style={{ margin: '15px' }}>
                      <p>
                        <span className="infoLibelle">{t('all.text.start_date')}</span> <br />
                        <span className="bold-text maskDate">
                          {moment(maskInfo.startDate).format(t('all.date_format.date'))}
                        </span>
                      </p>
                      <p>
                        <span className="infoLibelle">{t('all.text.end_date')}</span> <br />
                        <span className="bold-text maskDate">
                          {moment(maskInfo.endDate).format(t('all.date_format.date'))}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="table-info-container maskContainer spacingBlock">
                  <h2>
                    <SvgMasqueVert height="1em" width="1em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />
                    <span>{t('columns.alarmsMasked')}</span>
                  </h2>
                  {this.getAlarms()}
                </div>
              </Col>
              <Col md="9">
                {maskInfo.filterCause && maskInfo.filterCause.length > 0 && (
                  <div className="table-info-container maskContainer">
                    <h2>
                      <SvgFiltreVert height="1em" width="1em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />
                      <span>{t('all.filter.filter_plural')}</span>
                    </h2>
                    <Table>
                      <thead>
                        <tr>
                          <th>{t('all.text.field')}</th>
                          <th>{t('all.text.operator')}</th>
                          <th>{t('all.text.value')}</th>
                        </tr>
                      </thead>
                      <tbody>{this.displayFilterInfo()}</tbody>
                    </Table>
                  </div>
                )}
                <div
                  className="table-info-container maskContainer"
                  style={{
                    marginTop: maskInfo.filterCause && maskInfo.filterCause.length > 0 ? '20px' : '',
                  }}
                >
                  <h2>
                    <SvgCompteurVert height="1em" width="1em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />
                    <span>
                      {_.size(suggestions)} {t('all.meter.meter', { count: _.size(suggestions) })}
                    </span>
                  </h2>
                  {suggestions && suggestions.length > 0 ? (
                    <div style={{ paddingTop: '15px' }}>
                      <Input placeholder={t('all.text.filter')} onChange={this.onChange} />
                      <BootstrapTable
                        keyField="id"
                        data={suggestions}
                        key={Math.floor(Math.random() * Math.floor(1024))}
                        rowEvents={rowEvents}
                        columns={[
                          {
                            dataField: 'clpReference',
                            text: t('all.pdi.pdi_ref'),
                            sort: true,
                            classes: 'crystalList-column',
                            default: true,
                          },
                          {
                            dataField: 'mtrSerial',
                            text: t('all.meter.meter_serial'),
                            sort: true,
                            classes: 'crystalList-column',
                            default: true,
                          },
                          {
                            dataField: 'rdoSerial',
                            text: t('all.meter.radio_serial'),
                            sort: true,
                            classes: 'crystalList-column',
                            default: true,
                          },
                          {
                            dataField: 'nbDayAlarm',
                            text: t('mask_alarm.text.nb_days_with_alarm'),
                            sort: true,
                            classes: 'crystalList-column',
                            default: true,
                          },
                        ]}
                        bootstrap4
                        bordered={false}
                        hover
                        filter={filterFactory()}
                        headerClasses="crystalList-column"
                        rowClasses={rowClasses}
                        rowStyle={rowStyle}
                        pagination={paginationFactory()}
                        defaultSorted={defaultSorted}
                      />
                    </div>
                  ) : (
                    <div>{t('mask_alarm.text.no_meter_associated')}</div>
                  )}
                </div>
              </Col>
            </Row>
            <Row style={{ margin: '0' }}>
              <div className="table-info-container maskContainer spacingBlock" style={{ width: '100%' }}>
                <h2>
                  <SvgAlarmeVert height="1em" width="1em" stroke="#31c6b3" fill="#31c6b3" strokeWidth="0" />
                  <span>
                    {meterSelected
                      ? `${t('all.alarm_meter.alarm_plural')} - ${meterSelected.mtrSerial}`
                      : t('mask_alarm.text.alarm_of_meters')}
                  </span>
                </h2>
                <div className="chart-container" style={{ height: '400px', width: '100%' }}>
                  {maskInfo.loading ? (
                    <LoadingBand message={t('virtual_meter.text.load_alarms')} />
                  ) : meterSelected ? (
                    <ChartMaskAlarm data={this.dataGenerator(meterSelected.id)} showLegend={!!meterSelected} />
                  ) : (
                    <ChartMaskAlarm data={this.dataGenerator(undefined)} showLegend={!!meterSelected} />
                  )}
                </div>
              </div>
            </Row>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, pdis, locales, vmeters, locations, alert, mask, colors } = state;
  const { user } = authentication;

  return {
    user,
    pdis,
    locales,
    vmeters,
    locations,
    alert,
    mask,
    colors,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(MaskAlarm)));
