import { authHeader, handleResponse } from '../_helpers';

/**
 * Permet l'autocompletion pour les widgets
 * @method autocomplete
 * @param {any} conditions conditions du widget
 * @returns {any}  la réponse http du handleResponse
 */
async function search(query: string, site: string | null | undefined) {
  let param = `query=${query}`;
  if (null !== site) {
    param += `&site=${site}`;
  }
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/search?${param}`, requestOptions);
  return handleResponse(response);
}

async function specificSearch(query: string, site: string | null, specific: string | null) {
  let param = `query=${query}`;
  if (null !== site) {
    param += `&site=${site}`;
  }
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/search/${specific}?${param}`, requestOptions);
  return handleResponse(response);
}

const searchService = {
  search,
  specificSearch,
};
export default searchService;
