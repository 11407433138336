import React from 'react';

const SvgManageTournee = (props: any) => (
  <svg viewBox="0 0 152.9 163.06" {...props}>
    <g>
      <g>
        <g>
          <path
            fill={props.fill}
            d="M149.95,74.12c-2.02-0.43-4,0.85-4.43,2.87l-2.71,12.66c-8.64-14.74-24.5-23.99-41.88-23.99
				c-26.76,0-48.54,21.77-48.54,48.54c0,2.06,1.67,3.73,3.73,3.73c2.06,0,3.73-1.67,3.73-3.73c0-22.65,18.43-41.07,41.07-41.07
				c14.77,0,28.23,7.89,35.53,20.44L124,90.91c-2.02-0.43-4,0.85-4.43,2.87c-0.43,2.02,0.85,4,2.87,4.43l21.37,4.58
				c0.36,0.08,0.73,0.1,1.1,0.07c0.61-0.05,1.2-0.25,1.72-0.59c0.83-0.54,1.42-1.38,1.62-2.35l4.58-21.37
				C153.25,76.54,151.97,74.55,149.95,74.12L149.95,74.12z M149.95,74.12"
          />
          <path
            fill={props.fill}
            d="M146.19,109.31c-2.06,0.07-3.68,1.79-3.61,3.86c0.36,10.96-3.57,21.41-11.06,29.42
				c-7.5,8.01-17.66,12.62-28.63,12.98c-0.46,0.01-0.91,0.02-1.37,0.02c-14.31,0-27.49-7.43-34.93-19.47l12.98,3.48
				c1.99,0.53,4.04-0.65,4.57-2.64c0.54-1.99-0.65-4.04-2.64-4.57l-21.11-5.66c-0.96-0.26-1.98-0.12-2.83,0.37
				c-0.86,0.49-1.48,1.31-1.74,2.27l-5.66,21.11c-0.53,1.99,0.65,4.04,2.64,4.57c0.32,0.09,0.65,0.13,0.97,0.13
				c1.65,0,3.16-1.1,3.6-2.77l3.18-11.86c8.85,13.94,24.25,22.51,40.96,22.51c0.54,0,1.08-0.01,1.61-0.03
				c12.96-0.43,24.98-5.88,33.84-15.34c8.86-9.47,13.5-21.82,13.08-34.78C149.98,110.86,148.25,109.24,146.19,109.31L146.19,109.31z
				 M146.19,109.31"
          />
        </g>
      </g>
      <path
        fill={props.fill}
        d="M53.54,121.27c-4.26-0.58-7.56-4.23-7.56-8.65c0-29.52,24.02-53.54,53.54-53.54c0.18,0,0.36,0.01,0.54,0.01
		V27.34c0-2.45-1.99-4.46-4.46-4.46H80.08V4.45c0-1.38-0.66-2.71-1.76-3.54c-1.12-0.83-2.56-1.12-3.89-0.75L3.89,22.44
		c-0.92,0.26-1.7,0.81-2.27,1.53C0.63,24.77,0,26.01,0,27.36v109.91c0,2.45,1.99,4.46,4.46,4.46H45.5l4.08-15.24
		C50.18,124.29,51.58,122.44,53.54,121.27z M26.6,22.9l44.56-12.55V22.9h0.03h-0.03H26.6z"
      />
    </g>
  </svg>
);

export default SvgManageTournee;
