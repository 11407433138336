import React, { Component } from 'react';
import CircleChart from '../_components/_canvas/CircleChart';
import SvgAffectation from '../SvgComponents/affectationNoir';
import SvgDesaffectation from '../SvgComponents/desaffectationNoir';

export default class DeviceRound extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      clicked: false,
    };
  }

  handleClick = () => {
    const { round, handleAffect, deviceInfo } = this.props;
    const { clicked } = this.state;
    const isAffect = deviceInfo.affectList.find((el: any) => el === round.rndCode);
    this.setState({
      clicked: !clicked,
    });
    handleAffect(round, !clicked, isAffect);
  };

  render() {
    const { round, deviceInfo, actions } = this.props;
    const isAffect = deviceInfo.affectList.find((el: any) => el === round.rndCode);
    const inAction = actions.find((el: any) => el.rndCode === round.rndCode);
    return (
      <div className={'round-device'} onClick={this.handleClick}>
        {inAction ? (
          <div style={{ width: '10%' }}>
            {isAffect ? (
              <SvgDesaffectation fill={'#dc3545'} width={'2em'} />
            ) : (
              <SvgAffectation fill={'#31c6b3'} width={'2em'} />
            )}
          </div>
        ) : (
          <div className={'point-container'}>
            <div className={'point'} style={{ backgroundColor: isAffect ? '#dc3545' : '#31c6b3' }} />
          </div>
        )}
        <div className={'title'}>
          <h5>{round.content}</h5>
          <span>{round.rndCode}</span>
        </div>
        <div className={'infos'}>
          <CircleChart
            percent={(round.indexedMeter / round.totalMeter) * 100}
            firstColor={'#31c6b3'}
            secondColor={'#1e796e'}
            canvasSize={80}
            fontSize={12}
            id={round.rndCode}
          />
          <CircleChart
            percent={(round.modifiedMeter / round.totalMeter) * 100}
            firstColor={'#31c6b3'}
            secondColor={'#1e796e'}
            canvasSize={80}
            fontSize={12}
            id={round.rndCode + 'scd'}
          />
        </div>
      </div>
    );
  }
}
