import React from 'react';

const SvgLoupeBleu = (props: any) => (
  <svg viewBox="0 0 19.33 19.33" {...props}>
    <path
      d="M19.09 18l-4.65-4.64a8.13 8.13 0 1 0-1.14 1.14l4.7 4.59A.81.81 0 0 0 19.09 18zM8.15 14.67a6.53 6.53 0 1 1 6.52-6.52 6.53 6.53 0 0 1-6.52 6.52z"
      fill={props.fill}
      data-name="Calque 2"
    />
  </svg>
);

export default SvgLoupeBleu;
