import React, { Component } from 'react';
import { Input } from 'reactstrap';

export default class CustomInput extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      text: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleChange(e: any) {
    const { onFilter } = this.props;
    if (e.target.value) {
      onFilter(e.target.value);
    } else {
      onFilter('');
    }
  }

  handleKeyPress(e: any) {
    const { onFilter } = this.props;
    const { text } = this.state;
    if (e.charCode === 13) {
      this.launchDebounceChange && this.launchDebounceChange.cancel();
      e.stopPropagation();
      e.preventDefault();
      onFilter(text);
    }
  }

  render() {
    const { onFocus, onBlur, keyField } = this.props;
    return (
      <Input
        type="text"
        className={`unsortable-input ${
          _.size(_.get(this.props, 'column.filter.props.defaultValue')) > 0 ? '' : 'placeholder-selected'
        } ${_.get(this.props, 'column.filter.props.className')}`}
        onChange={e => {
          this.setState({ text: e.target.value });
        }}
        onKeyPress={e => {
          this.handleKeyPress(e);
        }}
        onFocus={() => onFocus(keyField)}
        onBlur={() => onBlur(keyField)}
        style={{ marginBottom: '0.5rem' }}
        disabled={_.get(this.props, 'column.filter.props.disabled')}
        placeholder={_.get(this.props, 'column.filter.props.placeholder')}
        defaultValue={_.get(this.props, 'column.filter.props.defaultValue')}
        value={
          _.isString(_.get(this.state, 'text'))
            ? _.get(this.state, 'text')
            : _.get(this.props, 'column.filter.props.defaultValue') || ''
        }
      />
    );
  }
}
