import React from 'react';

const SvgTourneeExportee = (props: any) => (
  <svg viewBox="0 0 101.15 113.23" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M89.74,0H58.67a11.41,11.41,0,0,0,0,22.82h3.71L51.71,33.59v-9a2.89,2.89,0,0,0-1.14-2.29,2.85,2.85,0,0,0-2.5-.48L2.51,36.2a2.85,2.85,0,0,0-1.47,1,2.86,2.86,0,0,0-1,2.19v71a2.88,2.88,0,0,0,2.88,2.88H61.73a2.89,2.89,0,0,0,2.88-2.88V53L78.33,39.14v3.34a11.41,11.41,0,0,0,22.82,0V11.41A11.42,11.42,0,0,0,89.74,0Zm4.41,42.48a4.41,4.41,0,0,1-8.82,0V22.13L64.61,43.05,27,81a4.46,4.46,0,0,1-3.09,1.24,4.4,4.4,0,0,1-3.2-7.43l38-38.36L79.17,15.82H58.67a4.41,4.41,0,0,1,0-8.82H89.74a4.41,4.41,0,0,1,4.41,4.41ZM46,28.4v8.1H17.17Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgTourneeExportee;
