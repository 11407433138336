import { authHeader, handleResponse } from '../_helpers';
import { Bookmark } from '../_entities/bookmark';

async function getAll(id: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/dashboard/location/${id}/bookmarks/`, requestOptions);
  return handleResponse(response);
}

async function getAllHome() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/dashboard/bookmarks/`, requestOptions);
  return handleResponse(response);
}

async function save(bookmark: Bookmark) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(bookmark),
  };
  const response = await fetch(`/api/dashboard/location/${bookmark.locationId}/bookmarks/`, requestOptions);
  return handleResponse(response);
}

async function deleteBookmark(bookmark: Bookmark) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };
  const response = await fetch(
    `/api/dashboard/location/${bookmark.locationId}/bookmarks/${bookmark.userId}/${bookmark.dashboardId}/`,
    requestOptions
  );
  return handleResponse(response);
}

const bookmarkService = {
  getAll,
  save,
  deleteBookmark,
  getAllHome,
};
export default bookmarkService;
