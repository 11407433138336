import React from 'react';

const ReleveManuelle = (props: any) => (
  <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.6 68.3" {...props}>
    <g>
      <polygon points="7.4,46.8 21.3,60.7 21.3,60.7 21.3,60.7 21.3,60.7 45.7,36.2 45.7,36.2 56.6,25.4 42.7,11.4 	" />
      <path d="M67.2,8L61,1.8c-2.4-2.4-6.3-2.4-8.7,0L50,4.1l0,0l-3.7,3.7l13.9,13.9l6.9-6.9C69.1,12.9,69.1,9.9,67.2,8z" />
      <path d="M15.9,62.9L3.6,50.5L0.2,65.8l0,0L0,66.3c-0.2,1.1,0.8,2.2,1.9,1.9l15.6-3.8L15.9,62.9L15.9,62.9z" />
    </g>
  </svg>
);

export default ReleveManuelle;
