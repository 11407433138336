import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row, Button, UncontrolledAlert } from 'reactstrap';
import { translate, recursiveTranslate } from '../_helpers';
import listOperator from '../_shared/OperatorList';
import { QueryBuilder } from '../QueryBuilder';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import { locationActions, pdiActions, localeActions, vmeterActions, alertActions } from '../_actions';
import CompteurVert from '../SvgComponents/CompteurVert';
import ListTools from '../List/ListTools';
import { locales, vmeters, locations } from '../_interfaces/reducers';
import { history } from '../_helpers';
import { cloneDeep, get as _get } from 'lodash';
import { VirtualMeterGeneral } from '../_entities/virtual';
import { User } from '../_entities/user';
import ModalConfirmUnlink from '../ManagePdi/ModalConfirmUnlink';
import Loading from '../Bands/Loading';
import Error from '../Bands/Error';
import SuccessBand from '../Bands/Success';

import MaskAlarmCompenent from '../MaskBar/MaskAlarmCompenent';
import { withTranslation } from 'react-i18next';

interface Props extends React.Props<any> {
  pdis: any;
  location: any;
  user: User;
  match: any;
  locales: locales;
  dispatch: Function;
  locations: locations;
  alert: any;
  linkMode: boolean;
  initializedVMeters: boolean;
  t: Function;
}

interface State {
  pdiList: Array<any>;
  sourceColumns: Array<any>;
  availablePdis: Array<VirtualMeterGeneral>;
  initialized: boolean;
  saved: boolean;
  asked: boolean;
  options: any;
  mtrKey: number;
  isOpen: boolean;
  selectAll: Boolean;
}

/**
 * Gère l'édition et création de compteur virtuel
 *
 * @class ManagePdi
 * @extends Component
 */
class ManageAlarm extends Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    if (!state.initialized) {
      let sourceColumns: Array<any> = [],
        initialized: boolean = state.initialized;

      if (
        (props.pdis && (props.pdis.itemsStock && props.pdis.itemsStock.length > 0)) ||
        (props.pdis.items && props.pdis.items.length > 0)
      ) {
        const defaultColumns: any = ListTools.getDefaultColumns('PDI_SEARCH_QUERY');
        const firstLine = props.linkMode ? props.pdis.itemsStock[0] : props.pdis.items[0];
        let translatedKeys = recursiveTranslate('fr', 'pdi', firstLine, props.locales.locale);
        sourceColumns = translatedKeys.map((champ: any) => ({
          dataField: champ.path,
          text: champ.value,
          sort: true,
          classes: 'crystalList-column',
          default: defaultColumns.includes(champ.path),
        }));
        initialized = true;
      }
      return {
        sourceColumns,
        name,
        initialized,
      };
    }
  }

  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      pdiList: [],
      sourceColumns: [],
      availablePdis: [],
      initialized: false,
      initializedVMeters: false,
      saved: false,
      asked: false,
      options: {
        hideSizePerPage: true,
      },
      mtrKey: Math.floor(Math.random() * Math.floor(1024)),
      isOpen: false,
      selectAll: false,
    };
  }

  /**
   * Permet d'ouvrir/fermer la modal
   *
   * @method toggle
   * @param {boolean} result Résultat
   */
  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  /**
   * Utilise la méthode de fermeture pour la confirmation
   *
   * @method confirm
   */
  confirm = () => {
    const { dispatch, pdis, match } = this.props;
    const { pdiList } = this.state;
    const { locationId } = match.params;
    dispatch(alertActions.clear());

    this.toggle();
    dispatch(pdiActions.unlinkPdisToLocation(locationId, pdiList.map(pdi => pdi.id), pdis.unlinkActions));
  };

  /**
   * Gère le filtrage des données
   *
   * @method receiveData
   * @param {any} dataFiltered
   */
  receiveData = (dataFiltered: any) => {
    this.setState({ availablePdis: dataFiltered });
  };

  /**
   * Comportement à la validation du formulaire
   *
   * @method handleValidSubmit
   * @param {Object} event Evènement
   * @param {any} values Valeurs du formulaire
   */
  handleValidSubmit = (_: Object /*, values: any*/) => {
    const { dispatch, match, linkMode } = this.props;
    const { pdiList } = this.state;
    const { locationId } = match.params;
    dispatch(alertActions.clear());

    if (linkMode) {
      dispatch(pdiActions.linkPdisToLocation(locationId, pdiList.map(pdi => pdi.id)));
    } else {
      dispatch(pdiActions.unlinkActionsPdisToLocation(locationId, pdiList.map(pdi => pdi.id)));
    }

    this.setState({
      isOpen: true,
    });
  };

  /**
   * Associer tous les compteurs présent dans le tableau filtré ou non
   *
   * @method handleValidSubmit
   * @param {Object} event Evènement
   * @param {any} values Valeurs du formulaire
   */
  handleValidSubmitAll = () => {
    const { availablePdis } = this.state;

    const { dispatch, linkMode, locations, pdis } = this.props;
    const { Fragment } = React;
    dispatch(alertActions.clear());

    if (linkMode) {
      dispatch(pdiActions.linkPdisToLocation(locations.fetchedLocation.id, availablePdis.map(pdi => pdi.id)));
    } else {
      dispatch(pdiActions.unlinkActionsPdisToLocation(locations.fetchedLocation.id, availablePdis.map(pdi => pdi.id)));
    }

    this.setState({
      isOpen: true,
    });
  };

  /**
   * Construit toute la partie haute du template
   *
   * @method getHeaderOfList
   * @returns {JSX} La partie du template
   */
  getHeaderOfList = () => {
    const { pdis, match, linkMode } = this.props;
    const { locationId } = match.params;
    const { sourceColumns } = this.state;
    const firstElement = linkMode ? pdis.itemsStock[0] : pdis.items;
    const listFields = sourceColumns
      .map((col: any) => {
        let typeData;
        // S'il s'agit d'un type qui ne peut pas être determiné en regardant le type de la valeur, renseigné dans la liste specificFieldType
        const specificFieldType = Object.keys(ListTools.specificFieldType).find(spec => spec === col.dataField);
        if (specificFieldType !== undefined) {
          typeData = ListTools.specificFieldType[col.dataField];
        } else {
          typeData =
            typeof _get(firstElement, col.dataField) === 'number' || _get(firstElement, col.dataField) === null
              ? 'number'
              : 'text';
        }

        return {
          label: col.text,
          value: col.dataField,
          type: typeData,
        };
      })
      .sort((a: any, b: any) => {
        if (a.label < b.label) return -1;
        if (a.label === b.label) return 0;
        return 1;
      });

    return (
      <Fragment>
        <div className="filter-container" style={{ marginTop: '10px' }}>
          <QueryBuilder
            sendListFiltered={this.receiveData}
            listData={linkMode ? pdis.itemsStock : pdis.items}
            listOperator={listOperator}
            listFilters={listFields}
            defaultConfig={this.getDefaultFilterByURL()}
            idContext={ListTools.typeOfList.Pdi}
            save={false}
            idSite={locationId}
          />
        </div>
      </Fragment>
    );
  };

  /**
   * Gère l'annulation de l'édition
   *
   * @method cancelEdition
   */
  cancelEdition = (e: any) => {
    history.goBack();
  };

  /**
   * @method componentDidUpdate
   * @param {Props} prevProps Props précédentes
   * @param {State} prevState State précédent
   * @param {any} snapshot Snapshot
   */
  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    const { match, pdis, linkMode } = this.props;
    const { locationId } = match.params;
    if (linkMode) {
      if (pdis && pdis.movedPdi && pdis.movedPdi.length > 0) {
        const link = `/locations/${locationId}/pdi`;
        setTimeout(() => {
          history.push(link);
        }, 2000);
      }
    } else {
      if (pdis && pdis.unlinkActions && pdis.unlinkActions.status && pdis.unlinkActions.status == 'OK') {
        const link = `/locations/${locationId}/pdi`;
        setTimeout(() => {
          history.push(link);
        }, 2000);
      }
    }
  }

  /**
   * @method componentDidMount
   */
  componentDidMount() {
    const { dispatch, match, user, linkMode } = this.props;
    const { locationId } = match.params;

    dispatch(localeActions.load());
    dispatch(locationActions.getInfos(locationId));
    //TODO changer pdiActions.getAll with getAllAscendant pour get tous les pdi en montant
    if (linkMode) {
      dispatch(pdiActions.getStock(0));
    } else {
      dispatch(pdiActions.getAllWithChildren(locationId));
    }
  }

  /**
   * @method componentWillUnmount
   */
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(locationActions.clear());
    dispatch(pdiActions.clear());
    dispatch(alertActions.clear());
  }

  /**
   * Applique des filtres passés par URL
   *
   * @method getDefaultFilterByURL
   * @memberof AddVirtualMeter
   */
  getDefaultFilterByURL = () => {
    const { location } = this.props;

    const listeOfFilter = location.search.slice(1).split('&');

    let listDefaultFilter: Array<any> = [];
    listeOfFilter.forEach((filter: any) => {
      const filterUrl = Object.keys(ListTools.filterURL).find(url => url === filter);
      if (filterUrl !== undefined) listDefaultFilter = listDefaultFilter.concat(ListTools.filterURL[filter]);
    });

    listDefaultFilter.map((f, i) => {
      const copy = f;
      copy.num = i;
      return copy;
    });
    return listDefaultFilter;
  };

  /**
   * Formate correctement les colonnes, en effectuant la
   * traduction notamment
   *
   * @method formatColumns
   * @param {string} type Type
   * @param {boolean} recursive Récursif
   */
  formatColumns = (type: string, recursive: boolean) => {
    const { locales } = this.props;
    const baseColumns = ListTools.getDefaultColumns(type);
    if (recursive) {
      return baseColumns.map(it => {
        let test: any = it.split('.');
        if (test.length > 1) {
          return {
            dataField: it,
            text: translate('fr', test[0], test[1], locales.locale),
            sort: true,
          };
        } else {
          return {
            dataField: it,
            text: translate('fr', 'pdi', test[0], locales.locale),
            sort: true,
          };
        }
      });
    } else {
      return baseColumns.map(champ => ({
        dataField: champ,
        text: translate('fr', 'pdi', champ, locales.locale),
        sort: true,
      }));
    }
  };

  /**
   * Rend la liste des compteurs existants
   *
   * @method getExistingMetersList
   * @returns {JSX} Le tableau
   */
  getExistingPdisList = () => {
    let { pdiList, options, mtrKey } = this.state;
    const columns = this.formatColumns(ListTools.typeOfList.PdiLight, true);
    return (
      <Col md="12">
        <BootstrapTable
          keyField="id"
          key={mtrKey}
          data={pdiList}
          bootstrap4
          bordered={false}
          columns={columns}
          hover
          filter={filterFactory()}
          headerClasses="crystalList-column"
          pagination={paginationFactory(options)}
        />
      </Col>
    );
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { pdis, alert, linkMode, t } = this.props;
    const { availablePdis, isOpen, pdiList } = this.state;
    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      classes: 'regular-checkbox checkbox-add',
      onSelect: (row: any, isSelect: any, rowIndex: any, e: any) => {
        let { pdiList } = this.state;
        pdiList = cloneDeep(pdiList);
        const existing = pdiList.find(it => it.id === row.id);
        if (isSelect) {
          if (existing === undefined) {
            pdiList.push(row);
          }
        } else {
          if (existing !== undefined) {
            const mtrIndex = pdiList.indexOf(existing);
            pdiList.splice(mtrIndex, 1);
          }
        }

        setTimeout(() => {
          this.setState({ pdiList });
        }, 60);
      },
      onSelectAll: (isSelect: boolean, rows: any, e: any) => {
        let { pdiList } = this.state;

        rows.forEach((row: any) => {
          const existing = pdiList.find(it => it.id === row.id);
          if (existing === undefined && isSelect) {
            pdiList.push(row);
          }
          if (existing !== undefined && !isSelect) {
            const mtrIndex = pdiList.indexOf(existing);
            pdiList.splice(mtrIndex, 1);
          }
        });

        setTimeout(() => {
          this.setState({ pdiList });
        }, 60);
      },
      selectionHeaderRenderer: ({ mode, ...props }: any) => {
        if (props.indeterminate) {
          return <input className="all-cb regular-checkbox checkbox-add-all" type={mode} defaultChecked />;
        }
        return props.checked ? (
          <input className="all-cb regular-checkbox checkbox-add" type={mode} defaultChecked />
        ) : (
          <input className="all-cb regular-checkbox checkbox-add" type={mode} />
        );
      },
    };

    const baseOptions = {
      onSizePerPageChange: (sizePerPage: number, page: number) => {
        let { options } = this.state;
        options.sizePerPage = sizePerPage;
        this.setState({
          options,
          mtrKey: Math.floor(Math.random() * Math.floor(1024)),
        });
      },
    };

    return (
      <div className="col-md-12 ">
        {pdis && pdis.loading && <Loading />}
        {pdis && pdis.error && <Error message={pdis.error} />}
        {linkMode && pdis && pdis.movedPdi && pdis.movedPdi.length > 0 && (
          <SuccessBand message={t('manage_alarm.text.x_linked_pdi_to_location', { count: pdiList.length })} />
        )}
        {!linkMode && pdis && pdis.unlinkActions && pdis.unlinkActions.status && pdis.unlinkActions.status == 'OK' && (
          <SuccessBand message={t('manage_alarm.text.x_dissociate_pdi_to_location', { count: pdiList.length })} />
        )}
        {pdis && ((linkMode && pdis.itemsStock) || (!linkMode && pdis.items)) && (
          <div>
            {alert.message && (
              <UncontrolledAlert
                className={`alert ${alert.type}`}
                toggle={() => {
                  const { dispatch } = this.props;
                  dispatch(alertActions.clear());
                }}
              >
                {alert.message}
              </UncontrolledAlert>
            )}
            {this.getHeaderOfList()}
            <MaskAlarmCompenent />
            <div style={{ marginTop: '10px' }}>
              <Row className="virtual-meter-editor">
                <Col md="8">
                  <div className="crystalList-container">
                    <div className="table-info-container">
                      <h2>
                        <span>
                          <CompteurVert height="1em" width="1em" stroke="#31c6b3" fill="white" strokeWidth="1" />
                        </span>
                        {availablePdis.length} {t('all.meter.available_meter', { count: availablePdis.length })}
                      </h2>
                      <br />
                      <BootstrapTable
                        keyField="id"
                        selectRow={selectRow}
                        data={availablePdis}
                        bootstrap4
                        bordered={false}
                        columns={this.formatColumns(ListTools.typeOfList.Pdi, true)}
                        hover
                        filter={filterFactory()}
                        headerClasses="crystalList-column"
                        rowClasses="clickable"
                        pagination={paginationFactory(baseOptions)}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4">
                  <div className="table-info-container">
                    <h2>
                      <span>
                        <CompteurVert height="1em" width="1em" stroke="#31c6b3" fill="white" strokeWidth="1" />
                      </span>
                      {t('all.meter.meter_to_x_plural', {
                        mode: linkMode ? t('all.text.link').toLowerCase() : t('all.text.unlink').toLowerCase(),
                      })}
                    </h2>
                    <br />
                    <Button
                      onClick={this.handleValidSubmit}
                      style={{ marginBottom: '10px', marginTop: '10px' }}
                      style={{ marginBottom: '10px' }}
                      disabled={pdiList && pdiList.length == 0}
                    >
                      {linkMode ? t('all.text.link') : t('all.text.unlink')}
                    </Button>
                    <Button
                      onClick={this.handleValidSubmitAll}
                      style={{ marginBottom: '10px', marginLeft: '3%', marginTop: '10px' }}
                    >
                      {linkMode ? t('all.text.link_all') : t('all.text.dissociate_all')}
                    </Button>
                    <div className="existing-meter-list">{this.getExistingPdisList()}</div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
        <ModalConfirmUnlink
          onValid={this.confirm}
          toggle={this.toggle}
          isOpen={isOpen}
          actions={pdis && pdis.unlinkActions}
        />
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, pdis, locales, locations, alert } = state;
  const { user } = authentication;

  return {
    user,
    pdis,
    locales,
    locations,
    alert,
  };
}

const mapping: any = connect(mapStateToProps)(ManageAlarm);

const connectedManageAlarmMeter = withRouter(mapping);
const tr = withTranslation()(connectedManageAlarmMeter);
export default tr;
