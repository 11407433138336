import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Label } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import confirm from '../_components';
import { locales } from '../_interfaces/reducers';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';
import { withTranslation } from 'react-i18next';

registerLocale('fr', fr);
setDefaultLocale('fr');

interface Props {
  statementsTemplate: any;
  consoTemplate: any;
  alarmsTemplate: any;
  update: Function;
  locales: locales;
  customZooms?: Array<string>;
  t: Function;
}

interface State {
  currentDate: any;
  defaultMin: string;
  defaultMax: string;
  defaultZoom: string | undefined;
  zooms: Array<string>;
}

/**
 * @class IntevalSelectorComponent
 * @extends {Component}
 */
class IntervalSelectorComponent extends Component<Props, State> {
  form: any;
  defaultMin: any;
  defaultMax: any;

  static confirmFragment = {
    title: (
      <Fragment>
        <strong>Interval supérieur à un mois</strong>
      </Fragment>
    ),
    message: 'Le chargement des données risque de prendre du temps',
    confirmText: 'Continuer',
    cancelText: 'Annuler',
    confirmColor: 'danger',
    cancelColor: 'primary',
  };

  /**
   * Récupère les informations du template de relèves pour
   * gérer les valeurs par défaut, et créé l'instance
   *
   * @constructor
   * @param {Object} props Propriétés
   */
  constructor(props: Props) {
    super(props);

    this.form = {};
    this.state = {
      currentDate: moment(),
      defaultMin: props.statementsTemplate.dataSourceProperty.displayProperty.condition[1].conditionValue,
      defaultMax: props.statementsTemplate.dataSourceProperty.displayProperty.condition[2].conditionValue,
      defaultZoom: props.statementsTemplate.dataSourceProperty.displayProperty.displayZoom,
      zooms: props.customZooms !== undefined ? props.customZooms : ['Hour', 'Day', 'Week'],
    };
  }

  /**
   * Gère les valeurs par défaut des dates de
   * début et de fin
   *
   * @method componentDidMount
   */
  componentDidMount() {
    const { currentDate } = this.state;
    let { defaultMin, defaultMax } = this.state;
    const { t } = this.props;
    if (defaultMin === '') {
      defaultMin = currentDate
        .clone()
        .startOf('month')
        .format('YYYY-MM-DD');
    } else {
      defaultMin = moment(defaultMin).format('YYYY-MM-DD');
    }
    if (defaultMax === '') {
      defaultMax = currentDate
        .clone()
        .endOf('month')
        .format('YYYY-MM-DD');
    } else {
      defaultMax = moment(defaultMax).format('YYYY-MM-DD');
    }
    if (defaultMin === '' || defaultMax === '') {
      this.setState({
        defaultMin,
        defaultMax,
      });
    }
  }

  /**
   * Met à jour les templates de relèves et alarmes,
   * puis renvoie le tout au composant parent
   *
   * @method confirmFormData
   * @memberof IntervalSelectorComponent
   * @param {Object} values Valeurs du formulaire
   */
  confirmFormData = (values: any) => {
    const { defaultMin, defaultMax, defaultZoom } = values;
    const { statementsTemplate, alarmsTemplate, consoTemplate, update } = this.props;
    this.setState(values);
    const newMaxDate = moment(defaultMax).format(`${moment.HTML5_FMT.DATE} ${moment.HTML5_FMT.TIME_SECONDS}`);
    const newMinDate = moment(defaultMin).format(`${moment.HTML5_FMT.DATE} ${moment.HTML5_FMT.TIME_SECONDS}`);

    statementsTemplate.dataSourceProperty.displayProperty.condition[1].conditionValue = newMinDate;
    statementsTemplate.dataSourceProperty.displayProperty.condition[2].conditionValue = newMaxDate;
    statementsTemplate.dataSourceProperty.displayProperty.displayZoom = defaultZoom;

    consoTemplate.dataSourceProperty.displayProperty.condition[1].conditionValue = newMinDate;
    consoTemplate.dataSourceProperty.displayProperty.condition[2].conditionValue = newMaxDate;
    consoTemplate.dataSourceProperty.displayProperty.displayZoom = defaultZoom;

    alarmsTemplate.dataSourceProperty.displayProperty.condition[1].conditionValue = newMinDate;
    alarmsTemplate.dataSourceProperty.displayProperty.condition[2].conditionValue = newMaxDate;
    alarmsTemplate.dataSourceProperty.displayProperty.displayZoom = defaultZoom;

    update(statementsTemplate, consoTemplate, alarmsTemplate, newMinDate, newMaxDate);
  };

  /**
   * Déclenche la soumission du formulaire à la
   * mise à jour de chaque input
   *
   * @method handleUpdate
   * @memberof IntervalSelectorComponent
   */
  handleUpdate = () => {
    this.form.submit();
  };

  handleDateMin = (min: any) => {
    const { t } = this.props;
    const newMin = moment(min);
    if (newMin.isValid()) {
      this.setState(
        {
          defaultMin: newMin.format('YYYY-MM-DD'),
        },
        () => {
          this.handleUpdate();
        }
      );
    }
  };

  handleDateMax = (max: any) => {
    const { t } = this.props;
    const newMax = moment(max);
    if (newMax.isValid()) {
      this.setState(
        {
          defaultMax: moment(max).format('YYYY-MM-DD'),
        },
        () => {
          this.handleUpdate();
        }
      );
    }
  };

  /**
   * Gère les erreurs à la soumission du formulaire
   *
   * @method handleInvalidSubmit
   * @memberof IntervalSelectorComponent
   * @param {Object} event Evènement
   * @param {Object} errors Erreurs relevées
   * @param {Object} values Values du formulaire
   */
  handleInvalidSubmit = (event: Object, errors: Object, values: any) => {
    this.setState(values);
  };

  /**
   * Gère la soumission du formulaire, et préviens
   * l'utilisateur s'il charge beaucoup de données
   * avec un pas à l'heure
   *
   * @async
   * @method handleValidSubmit
   * @memberof IntervalSelectorComponent
   *
   */
  handleValidSubmit = async (event: Object, values: any) => {
    const { defaultMin, defaultMax, defaultZoom } = values;
    const minDate = moment(defaultMin).startOf('day');
    const maxDate = moment(defaultMax).endOf('day');
    const diff = maxDate.diff(minDate, 'days');

    if (diff > 30 && defaultZoom === 'Hour') {
      await confirm(IntervalSelectorComponent.confirmFragment).then(resolve => {
        if (resolve) {
          this.confirmFormData(values);
        }
      });
    } else {
      this.confirmFormData(values);
      await Promise.resolve();
    }
  };

  /**
   * Rend le composant
   *
   * @returns {JSX} le composant
   * @method render
   * @memberof IntervalSelectorComponent
   */
  render() {
    const { defaultMin, defaultMax, defaultZoom, zooms } = this.state;
    const { locales, t } = this.props;
    return (
      <AvForm
        ref={(c: any) => {
          this.form = c;
        }}
        className="row interval-date"
        onValidSubmit={this.handleValidSubmit}
        onInvalidSubmit={this.handleInvalidSubmit}
      >
        <div className="col-md-4">
          <Label for="defaultMin">{t('all.text.start_date')}</Label>
          <div className="form-group">
            <DatePicker
              className="form-control"
              selected={moment(defaultMin).toDate()}
              onChange={this.handleDateMin}
              dateFormat={'dd/MM/yyyy'}
            />
          </div>
          <AvField type="date" style={{ display: 'none' }} id="defaultMin" name="defaultMin" value={defaultMin} />
        </div>
        <div className="col-md-4">
          <Label for="defaultMax">{t('all.text.end_date')}</Label>
          <div className="form-group">
            <DatePicker
              className="form-control"
              selected={moment(defaultMax).toDate()}
              onChange={this.handleDateMax}
              dateFormat={'dd/MM/yyyy'}
            />
          </div>
          <AvField type="date" style={{ display: 'none' }} id="defaultMax" name="defaultMax" value={defaultMax} />
        </div>
        <div className="col-md-4">
          <Label for="defaultZoom">{t('all.text.zoom')}</Label>
          <AvField
            type="select"
            className="form-control"
            id="defaultZoom"
            value={defaultZoom}
            name="defaultZoom"
            required
            errorMessage={t('all.text.required_field')}
            onChange={this.handleUpdate}
          >
            {locales &&
              locales.locale &&
              zooms.map(item => (
                <option key={item} value={item}>
                  {' '}
                  {t(`best_date_component.zoom_option.${item.toLowerCase()}`)}
                </option>
              ))}
          </AvField>
        </div>
        {new Date(defaultMin).getTime() >= new Date(defaultMax).getTime() && (
          <span style={{ color: '#dc3545', marginLeft: '15px' }}>{t('best_date_component.error')}</span>
        )}
      </AvForm>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication } = state;
  const { user } = authentication;

  return {
    user,
  };
}

const IntervalSelector = connect(mapStateToProps)(IntervalSelectorComponent);
const tr = withTranslation()(IntervalSelector);
export default tr;
