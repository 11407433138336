import React from 'react';

const SvgEditionBleu = (props: any) => (
  <svg viewBox="0 0 14.33 14.26" {...props}>
    <path
      d="M8.91 2.38l2.92 2.91-7.38 7.39-2.92-2.92zm5.09-.7L12.74.38a1.29 1.29 0 0 0-1.83 0L9.67 1.62l2.91 2.92L14 3.09a1 1 0 0 0 0-1.41zM0 13.86a.32.32 0 0 0 .4.39l3.25-.78-2.91-2.92z"
      fill={props.fill}
      data-name="Calque 2"
    />
  </svg>
);

export default SvgEditionBleu;
