import React, { Component } from 'react';
import { connect } from 'react-redux';
import SvgAjoutBleu from '../SvgComponents/AjoutBleu';
import BootstrapTable from 'react-bootstrap-table-next';
import profilActions from '../_actions/profil.actions';
import { localeActions } from '../_actions';
import AddProfil from './AddProfil';
import { UncontrolledTooltip } from 'reactstrap';
import SvgPoubelleBleu from '../SvgComponents/PoubelleBleu';
import SvgCleVerte from '../SvgComponents/CleVert';
import { withTranslation } from 'react-i18next';

interface Props {
  dispatch: Function;
  profil: any;
  locales: any;
  t: Function;
}

class ProfilMain extends Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      newProfil: {
        name: '',
        permissions: [],
      },
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(profilActions.getAll());
    dispatch(profilActions.getAllTypePerm());
    dispatch(localeActions.load());
  }

  toggle() {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  }

  handleChange = (value: any, select: boolean) => {
    const { newProfil } = this.state;
    const { profil } = this.props;
    const neoProfil = newProfil;
    if (select) {
      neoProfil.permissions = value.map((el: any) => el.value);
    } else {
      if (profil.items.find((el: any) => el.name.toUpperCase() === value.toUpperCase())) {
        this.setState({
          valid: false,
        });
      } else {
        this.setState({
          valid: true,
        });
      }
      neoProfil.name = value;
    }
    this.setState({ newProfil: neoProfil });
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    const { newProfil, isOpen } = this.state;
    if (newProfil.name.length > 0 && newProfil.permissions.length > 0) {
      dispatch(profilActions.addProfil(newProfil));
      this.setState({ isOpen: !isOpen });
    }
  };

  handleDelete = (e: any, row: any) => {
    const { dispatch } = this.props;
    dispatch(profilActions.deleteProfil(row.id));
  };

  render() {
    const { profil, locales, t } = this.props;
    const { isOpen } = this.state;

    const columns = [
      {
        dataField: 'name',
        text: t('all.column_text.name'),
        sort: true,
      },
      {
        dataField: 'permissions',
        isDummyField: true,
        editable: false,
        align: 'left',
        text: t('all.user.permission_plural'),
        formatter: (cellContent: any, row: any) => {
          return (
            <div>
              {row.permissions.map((el: any) => (
                <div>
                  <span>{t(`permission.text.${el.name}`)}</span>
                </div>
              ))}
            </div>
          );
        },
      },
      {
        align: 'center',
        style: {
          padding: 0,
          verticalAlign: 'middle',
        },
        headerStyle: () => ({ width: '10%' }),
        formatter: (cellContent: any, row: any) => {
          return (
            <div>
              <div
                id={'deleteProfil' + row.id}
                className={'delete-profil-row'}
                role="presentation"
                onClick={e => this.handleDelete(e, row)}
              >
                <SvgPoubelleBleu height="1em" width="1em" fill={'#000'} />
                <UncontrolledTooltip placement="bottom" target={'deleteProfil' + row.id}>
                  {' '}
                  {t('all.user.delete_profile')}
                </UncontrolledTooltip>
              </div>
            </div>
          );
        },
      },
    ];

    return (
      <div style={{ padding: '0 20px' }}>
        <div className="col-md-12 profil-container">
          <AddProfil
            toggle={this.toggle}
            isOpen={isOpen}
            types={profil.types}
            locales={locales}
            handleChange={this.handleChange}
            submit={this.handleSubmit}
          />
          <div className={'profil-header flex-box'}>
            <SvgCleVerte fill={'#31c6b3'} height={'2em'} />
            <h2>{t('all.user.profile')}</h2>
            <div className={'add-container'} onClick={() => this.setState({ isOpen: true })}>
              <div>
                <SvgAjoutBleu fill={'#000'} height={'1.5em'} />
              </div>
            </div>
          </div>
          <div className={'profil-body'}>
            {profil.items && (
              <BootstrapTable keyField="id" bootstrap4 bordered={false} hover data={profil.items} columns={columns} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, users, alert, locations, profil, locales } = state;
  const { user } = authentication;
  return {
    users,
    alert,
    locations,
    authentication,
    profil,
    user,
    locales,
  };
}

const connectedProfilMain = connect(mapStateToProps)(ProfilMain);
const tr = withTranslation()(connectedProfilMain);
export default tr;
