import { Bar } from 'react-chartjs-2';
import React from 'react';
import _ from 'lodash';
import * as chartjs from 'chart.js';

interface Props {
  data: any;
  options: chartjs.ChartOptions;
  alarmKeys: Array<string>;
  dataValues: any;
  switchOff: boolean | undefined;
}

export default class CustomBar extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  createDataSet(switchOff: boolean | undefined, data: Array<any>, type: string, label: string, color: string) {
    return {
      borderWidth: switchOff,
      data: data,
      type: 'bar',
      label: label,
      pointBackgroundColor: color,
      backgroundColor: color,
      fill: true,
      pointBorderColor: 'black',
    };
  }

  formatDatasets() {
    const { data, dataValues, switchOff } = this.props;
    const colorNotMasked = '#ACACAC4A';
    const colorMasked = '#FF0000CE';
    data.datasets = [];
    dataValues &&
      dataValues
        .filter((elem: any) => {
          return (!switchOff && !_.get(elem, 'masked')) || switchOff;
        })
        .forEach((elem: any) => {
          let color = elem.color;

          data.datasets.push(this.createDataSet(switchOff && elem.masked, elem.data, elem.type, elem.label, color));
        });
  }

  render() {
    const { data, options } = this.props;
    this.formatDatasets();

    return <Bar data={data} options={options} />;
  }
}
