import React from 'react';

const SvgGoutteCluster2parts = (props: any) => (
  <svg viewBox="0 0 224.1 224.1" {...props}>
    <path
      opacity={props.fillCluster[0].opacityOut}
      fill={props.fillCluster[0].color}
      enableBackground="new"
      d="M118.2,20.2c17.9,1.2,34.4,7.5,47.9,17.4l11.7-16.2C159.4,8,136.6,0,112,0v20C114.1,20,116.1,20.1,118.2,20.2z"
    />
    <path
      opacity={props.fillCluster[0].opacityMiddle}
      fill={props.fillCluster[0].color}
      enableBackground="new"
      d="M154.4,53.8l11.8-16.2c-13.6-9.9-30-16.2-47.9-17.4c-2.1-0.1-4.1-0.2-6.2-0.2v20C127.9,40,142.5,45.1,154.4,53.8z"
    />
    <path
      opacity={props.fillCluster[0].opacityIn}
      fill={props.fillCluster[0].color}
      d="M112,60c11.4,0,22,3.7,30.6,9.9l11.7-16.2C142.5,45.1,127.9,40,112,40V60z"
    />

    <path
      opacity={props.fillCluster[0].opacityOut}
      fill={props.fillCluster[0].color}
      enableBackground="new"
      d="M199.6,83.6l19-6.2c-7.4-22.7-21.8-42.2-40.7-56l-11.7,16.2C181.7,49,193.5,65.1,199.6,83.6z"
    />
    <path
      opacity={props.fillCluster[0].opacityMiddle}
      fill={props.fillCluster[0].color}
      enableBackground="new"
      d="M166.1,37.6l-11.8,16.2c12.2,8.9,21.4,21.4,26.2,36l19-6.2C193.5,65.1,181.7,49,166.1,37.6z"
    />
    <path
      opacity={props.fillCluster[0].opacityIn}
      fill={props.fillCluster[0].color}
      d="M161.6,96l19-6.2c-4.8-14.6-14-27.1-26.2-36l-11.7,16.2C151.4,76.3,158.1,85.4,161.6,96z"
    />

    <path
      opacity={props.fillCluster[0].opacityOut}
      fill={props.fillCluster[0].color}
      enableBackground="new"
      d="M218.6,77.4l-19,6.2c3.5,10.9,5.1,22.5,4.3,34.6c-0.5,7.8-2,15.2-4.3,22.3l19,6.2c3.6-10.9,5.5-22.5,5.5-34.6S222.2,88.3,218.6,77.4z"
    />
    <path
      opacity={props.fillCluster[0].opacityMiddle}
      fill={props.fillCluster[0].color}
      enableBackground="new"
      d="M184.1,112c0,7.8-1.3,15.2-3.5,22.3l19,6.2c2.3-7.1,3.8-14.5,4.3-22.3c0.8-12.1-0.8-23.8-4.3-34.6l-19,6.2C182.8,96.8,184.1,104.3,184.1,112z"
    />
    <path
      opacity={props.fillCluster[0].opacityIn}
      fill={props.fillCluster[0].color}
      d="M164.1,112.1c0,5.6-0.9,11-2.5,16.1l19,6.2c2.3-7,3.5-14.5,3.5-22.3s-1.3-15.2-3.5-22.3l-19,6.2C163.2,101,164.1,106.4,164.1,112.1z"
    />

    <path
      opacity={props.fillCluster[0].opacityOut}
      fill={props.fillCluster[0].color}
      enableBackground="new"
      d="M166.1,186.5l11.8,16.2c18.9-13.8,33.3-33.3,40.7-56l-19-6.2C193.5,159.2,181.6,175.2,166.1,186.5z"
    />
    <path
      opacity={props.fillCluster[0].opacityMiddle}
      fill={props.fillCluster[0].color}
      enableBackground="new"
      d="M154.4,170.3l11.8,16.2c15.5-11.3,27.4-27.2,33.5-46l-19-6.2C175.8,148.9,166.5,161.5,154.4,170.3z"
    />
    <path
      opacity={props.fillCluster[0].opacityIn}
      fill={props.fillCluster[0].color}
      d="M142.6,154.2l11.7,16.2c12.2-8.9,21.4-21.4,26.2-36l-19-6.2C158.1,138.7,151.4,147.8,142.6,154.2z"
    />

    <path
      opacity={props.fillCluster[0].opacityOut}
      fill={props.fillCluster[0].color}
      d="M112,204.1v20c24.6,0,47.3-8,65.8-21.4l-11.8-16.2C150.8,197.6,132.1,204.1,112,204.1z"
    />
    <path
      opacity={props.fillCluster[0].opacityMiddle}
      fill={props.fillCluster[0].color}
      d="M112,184.1v20c20,0,38.8-6.5,54.1-17.6l-11.8-16.2C142.5,179,127.9,184.1,112,184.1z"
    />
    <path
      opacity={props.fillCluster[0].opacityIn}
      fill={props.fillCluster[0].color}
      d="M112,164.1v20c15.8,0,30.4-5.1,42.3-13.8l-11.7-16.2C134.1,160.4,123.5,164.1,112,164.1z"
    />

    <path
      opacity={props.fillCluster[1].opacityOut}
      fill={props.fillCluster[1].color}
      enableBackground="new"
      d="M105.9,203.9c-17.9-1.2-34.4-7.5-47.9-17.4l-11.7,16.2c18.5,13.5,41.2,21.4,65.8,21.4v-20
	C110,204.1,108,204,105.9,203.9z"
    />
    <path
      opacity={props.fillCluster[1].opacityMiddle}
      fill={props.fillCluster[1].color}
      enableBackground="new"
      d="M69.7,170.3L58,186.5c13.6,9.9,30,16.2,47.9,17.4c2.1,0.1,4.1,0.2,6.2,0.2v-20C96.2,184.1,81.6,179,69.7,170.3z"
    />
    <path
      opacity={props.fillCluster[1].opacityIn}
      fill={props.fillCluster[1].color}
      d="M81.5,154.2l-11.7,16.2c11.9,8.7,26.5,13.8,42.3,13.8v-20C100.6,164.1,90,160.4,81.5,154.2z"
    />

    <path
      opacity={props.fillCluster[1].opacityOut}
      fill={props.fillCluster[1].color}
      enableBackground="new"
      d="M24.5,140.5l-19,6.2c7.4,22.7,21.8,42.2,40.7,56L58,186.5C42.4,175.1,30.6,159,24.5,140.5z"
    />
    <path
      opacity={props.fillCluster[1].opacityMiddle}
      fill={props.fillCluster[1].color}
      enableBackground="new"
      d="M43.5,134.3l-19,6.2c6.1,18.5,17.9,34.6,33.5,46l11.8-16.2C57.6,161.5,48.3,148.9,43.5,134.3z"
    />
    <path
      opacity={props.fillCluster[1].opacityIn}
      fill={props.fillCluster[1].color}
      d="M62.5,128.1l-19,6.2c4.8,14.6,14,27.1,26.2,36l11.7-16.2C72.7,147.8,66,138.7,62.5,128.1z"
    />

    <path
      opacity={props.fillCluster[1].opacityOut}
      fill={props.fillCluster[1].color}
      enableBackground="new"
      d="M20.2,105.9c0.5-7.8,2-15.2,4.3-22.3l-19-6.2C1.9,88.3,0,100,0,112s1.9,23.7,5.5,34.6l19-6.2C21,129.6,19.4,118,20.2,105.9z"
    />
    <path
      opacity={props.fillCluster[1].opacityMiddle}
      fill={props.fillCluster[1].color}
      enableBackground="new"
      d="M40,112c0-7.8,1.3-15.2,3.5-22.3l-19-6.2c-2.3,7.1-3.8,14.5-4.3,22.3c-0.8,12.1,0.8,23.8,4.3,34.6l19-6.2C41.3,127.3,40,119.8,40,112z"
    />
    <path
      opacity={props.fillCluster[1].opacityIn}
      fill={props.fillCluster[1].color}
      d="M60,112.1c0-5.6,0.9-11,2.5-16.1l-19-6.2c-2.3,7-3.5,14.5-3.5,22.3s1.3,15.2,3.5,22.3l19-6.2C60.9,123.1,60,117.7,60,112.1z"
    />

    <path
      opacity={props.fillCluster[1].opacityOut}
      fill={props.fillCluster[1].color}
      enableBackground="new"
      d="M58,37.6L46.2,21.4c-18.9,13.8-33.3,33.3-40.7,56l19,6.2C30.6,64.9,42.5,48.9,58,37.6z"
    />
    <path
      opacity={props.fillCluster[1].opacityMiddle}
      fill={props.fillCluster[1].color}
      enableBackground="new"
      d="M69.7,53.8L58,37.6c-15.5,11.3-27.4,27.2-33.5,46l19,6.2C48.3,75.2,57.6,62.6,69.7,53.8z"
    />
    <path
      opacity={props.fillCluster[1].opacityIn}
      fill={props.fillCluster[1].color}
      d="M81.5,69.9L69.7,53.8c-12.2,8.9-21.4,21.4-26.2,36l19,6.2C66,85.4,72.7,76.3,81.5,69.9z"
    />

    <path
      opacity={props.fillCluster[1].opacityOut}
      fill={props.fillCluster[1].color}
      d="M112,20V0C87.5,0,64.7,8,46.2,21.4L58,37.6C73.3,26.5,92,20,112,20z"
    />
    <path
      opacity={props.fillCluster[1].opacityMiddle}
      fill={props.fillCluster[1].color}
      d="M112,40V20C92,20,73.3,26.5,58,37.6l11.8,16.2C81.6,45.1,96.2,40,112,40z"
    />
    <path
      opacity={props.fillCluster[1].opacityIn}
      fill={props.fillCluster[1].color}
      d="M112,60V40c-15.8,0-30.4,5.1-42.3,13.8l11.7,16.2C90,63.7,100.6,60,112,60z"
    />

    <rect
      x="112"
      y="112"
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -46.4126 112.0498)"
      fill="#FFFFFF"
      width="0"
      height="0"
    />
    <polygon fill="#FFFFFF" points="112,112 112,112 112,112 " />
    <polygon fill="#FFFFFF" points="112,112 112,112 112,112 " />
    <polygon fill="#FFFFFF" points="112,112 112,112 112,112 " />
    <rect x="112" y="112" fill="#FFFFFF" width="0" height="0" />
    <path
      fill="#FFFFFF"
      d="M161.6,96c-3.4-10.6-10.1-19.6-18.9-26c-8.6-6.3-19.2-9.9-30.6-9.9c-11.4,0-22,3.7-30.6,9.9
	C72.7,76.3,66,85.4,62.5,96c-1.6,5.1-2.5,10.5-2.5,16.1c0,5.6,0.9,11,2.5,16.1c3.4,10.6,10.1,19.6,18.9,26
	c8.6,6.3,19.2,9.9,30.6,9.9s22-3.7,30.6-9.9c8.8-6.4,15.5-15.5,18.9-26c1.6-5.1,2.5-10.5,2.5-16.1C164.1,106.4,163.2,101,161.6,96z
	 M112,112L112,112L112,112L112,112L112,112z"
    />
  </svg>
);

export default SvgGoutteCluster2parts;
