import React from 'react';

const SvgStatistiqueVert = (props: any) => (
  <svg viewBox="0 0 111.86 100.67" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M100.67,0a11.16,11.16,0,0,0-8,18.93L72.2,59.8a10.27,10.27,0,0,0-1.36-.14,11.19,11.19,0,0,0-4.2.83L50.77,42.65a10.94,10.94,0,0,0,1.43-5.36,11.19,11.19,0,1,0-19.63,7.25l-19.39,34a12,12,0,0,0-2-.2,11.31,11.31,0,1,0,8.44,3.93L39,48.27a10.67,10.67,0,0,0,6.19-.63L61.08,65.48a11.14,11.14,0,0,0,9.73,16.58,11.18,11.18,0,0,0,8.07-19L99.32,22.23a10.3,10.3,0,0,0,1.35.14,11.19,11.19,0,1,0,0-22.37ZM11.19,93.22a3.73,3.73,0,1,1,3.72-3.73A3.73,3.73,0,0,1,11.19,93.22ZM41,41a3.73,3.73,0,1,1,3.72-3.73A3.73,3.73,0,0,1,41,41ZM70.84,74.57a3.73,3.73,0,1,1,3.73-3.73A3.73,3.73,0,0,1,70.84,74.57Zm29.83-59.66a3.73,3.73,0,1,1,3.73-3.72A3.71,3.71,0,0,1,100.67,14.91Z"
        />
      </g>
    </g>
  </svg>
);
export default SvgStatistiqueVert;
