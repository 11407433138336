import { roundReportConstants } from '../_constants';
import { action } from '../_interfaces/action';

export default function roundReports(state: any = {}, action: action) {
  switch (action.type) {
    case roundReportConstants.GET_ALL_REPORT_REQUEST:
      return {
        loading: true,
      };
    case roundReportConstants.GET_ALL_REPORT_SUCCESS:
      return {
        allReport: action.allRoundReport,
      };
    case roundReportConstants.GET_ALL_REPORT_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des rapports de tournée",
      };
    case roundReportConstants.GET_REPORT_REQUEST:
      return {
        ...state,
        reportProcessing: true,
      };
    case roundReportConstants.GET_REPORT_SUCCESS:
      return {
        ...state,
        report: action.roundReport,
        reportProcessing: false,
        reportKey: action.reportKey,
      };
    case roundReportConstants.GET_REPORT_FAILURE:
      return {
        error: "Impossible d'obtenir le rapport",
        reportProcessing: false,
      };
    case roundReportConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
