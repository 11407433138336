import React from 'react';
import { Button } from 'reactstrap';

/**
 * Affiche si nécessaire le bouton de retour à l'étape
 * précédente
 *
 * @method Stats
 * @param {any} props Props du composant
 * @returns {JSX} Le composant
 */
const Stats = (props: any) => {
  const { currentStep, previousStep } = props;
  return (
    <div>
      {currentStep > 1 && (
        <Button color="primary" onClick={previousStep}>
          {' '}
          Retour
        </Button>
      )}
    </div>
  );
};
export default Stats;
