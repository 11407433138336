import React from 'react';

const SvgTourneeUnload = (props: any) => (
  <svg viewBox="0 0 132.98 126.68" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M121.9,27h-.16l7.52-7.59.06-.07.06-.06a11.41,11.41,0,0,0-16.24-16l-.06.06-.06.06L89.62,27H11.09A11.09,11.09,0,0,0,0,38V104.5a11.09,11.09,0,0,0,11.08,11.09H58.17v5.54H41.55a2.77,2.77,0,1,0,0,5.54H91.42a2.78,2.78,0,0,0,0-5.55H74.8v-5.54h47.09A11.09,11.09,0,0,0,133,104.52V38A11.09,11.09,0,0,0,121.9,27ZM94,32.49,99.47,27,118,8.24A4.44,4.44,0,0,1,121.09,7a4.4,4.4,0,0,1,3.19,7.43L111.88,27l-5.48,5.54-40.58,41H86.31a4.41,4.41,0,1,1,0,8.81H55.24a4.42,4.42,0,0,1-4.41-4.41V46.79a4.41,4.41,0,0,1,8.82,0V67.15ZM69.25,121.13H63.71v-5.54h5.55Zm58.18-16.61a5.55,5.55,0,0,1-5.54,5.54H11.08a5.55,5.55,0,0,1-5.54-5.55V38a5.54,5.54,0,0,1,5.54-5.54h73L66.65,50.13V46.79a11.41,11.41,0,0,0-22.82,0V77.86A11.42,11.42,0,0,0,55.24,89.27H86.31a11.41,11.41,0,1,0,0-22.81H82.6l33.65-34h5.65A5.55,5.55,0,0,1,127.44,38Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgTourneeUnload;
