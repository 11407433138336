import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import MasqueVert from '../SvgComponents/Masque_Vert';
import Picto from './AlarmPicto';
import WarningBand from '../Bands/Warning';
import { withTranslation } from 'react-i18next';

interface Alarm {
  id: number;
  name: string;
}

interface Props {
  mask: any;
  updateActive: Function;
  alarmSelected: any;
  t: Function;
}

interface State {}

class MaskAlarmCompenent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.verifActive = this.verifActive.bind(this);
    this.onClickSvg = this.onClickSvg.bind(this);
    this.generateAlarmButton = this.generateAlarmButton.bind(this);
  }

  onClickSvg(id: number) {
    const { updateActive, alarmSelected } = this.props;
    let newStateAlarm = _.cloneDeep(alarmSelected);
    if (this.verifActive(id)) {
      newStateAlarm.splice(newStateAlarm.indexOf(id), 1);
    } else {
      newStateAlarm.push(id);
    }
    updateActive(newStateAlarm);
  }

  verifActive(value: number) {
    const { alarmSelected, mask } = this.props;
    return alarmSelected.find(el => el === value);
  }

  generateAlarmButton(alarm: Alarm) {
    const { t } = this.props;
    if (this.verifActive(alarm.id)) {
      return (
        <div
          className="round clickable"
          id={alarm.name}
          onClick={() => this.onClickSvg(alarm.id)}
          style={{ border: '3px solid #333333' }}
        >
          {Picto(alarm.name, {
            width: '2em',
            height: '2em',
            fill: '#31c6b3',
          })}
          <UncontrolledTooltip placement="bottom" target={alarm.name}>
            {' '}
            {t(`alarm.type.${alarm.name.toLowerCase()}`)}
          </UncontrolledTooltip>
        </div>
      );
    }
    return (
      <div className="round clickable" id={alarm.name} onClick={() => this.onClickSvg(alarm.id)}>
        {Picto(alarm.name, {
          width: '2em',
          height: '2em',
        })}
        <UncontrolledTooltip placement="bottom" target={alarm.name}>
          {' '}
          {t(`alarm.type.${alarm.name.toLowerCase()}`)}
        </UncontrolledTooltip>
      </div>
    );
  }

  render() {
    const { mask, alarmSelected, displayNoAlarmSelected, t } = this.props;
    // ? color : fill="#31c6b3"
    return (
      <div>
        {mask.alarms && (
          <div className="sidebar-mask-alarm">
            <div className="masque-alarm-side">
              <MasqueVert className="masque-vert-display" width="1.5em" height="1.5em" fill="#31c6b3" />
              <h2>{t('all.alarm_meter.alarm_plural')}</h2>
            </div>
            <div className="svgAlarm">{mask.alarms.map(this.generateAlarmButton)}</div>
            {displayNoAlarmSelected && _.size(alarmSelected) === 0 && (
              <WarningBand
                style={{ margin: 'auto', marginRight: '10px' }}
                message={t('mask.text.select_at_least_once_alarm')}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { locales, alert, mask } = state;

  return {
    alert,
    locales,
    mask,
  };
}

export default withTranslation()(connect(mapStateToProps)(MaskAlarmCompenent));
