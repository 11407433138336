import React from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import sensorActions from '../_actions/sensor.actions';
import SvgManuel from '../SvgComponents/Manuel';

const Manual = (props: any) => {
  const { t, dispatch, sensor, match } = props;
  const { params } = match;
  const lastData = JSON.parse(_.get(sensor, 'sensor.lastData.sndData', '{}'));
  const handeSubmit = () => {
    if (lastData.valveOpen) {
      dispatch(sensorActions.putClose(params.sensorId));
    } else {
      dispatch(sensorActions.putOpen(params.sensorId));
    }
  };

  return (
    <div
      className="downlink-container tele-releve-container"
      id="tab-container"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
    >
      <div className="tele-releve-header flex-box">
        <SvgManuel fill="#31c6b3" height="2.5em" />
        <h2 style={{ marginLeft: '20px' }}>{t('sensor.title.manual_management')}</h2>
      </div>
      <div className="wrapper">
        <div className="manual-container">
          <Button color="primary" onClick={handeSubmit}>
            {lastData.valveOpen ? t('all.text.doclose') : t('all.text.doopen')}
          </Button>{' '}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { sensor } = state;
  return {
    sensor,
  };
}

const mapping: any = connect(mapStateToProps)(Manual);
const connectedUser = withRouter(mapping);
export default withTranslation()(connectedUser);
