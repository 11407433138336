import React from 'react';

const SvgChartBar = (props: any) => (
  <svg viewBox="0 0 200 200" {...props}>
    <path
      d="M40.8,68V38.3c0-1.6,1-3,2.3-3h57.4c2.5,0,4.6,2.7,4.6,5.9V65c0,3.3-2.1,5.9-4.6,5.9H43.1
			C41.8,70.9,40.8,69.6,40.8,68z"
      fill={props.fill}
    />
    <path
      d="M40.8,115.5V85.8c0-1.6,1.5-3,3.3-3h82.3c3.6,0,6.6,2.7,6.6,5.9v23.8c0,3.3-3,5.9-6.6,5.9H44.1
			C42.2,118.5,40.8,117.1,40.8,115.5z"
      fill={props.fill}
    />
    <path
      d="M40.8,115.5V85.8c0-1.6,1.3-3,3-3h98c3.3,0,5.9,2.7,5.9,5.9v23.8c0,3.3-2.7,5.9-5.9,5.9h-98
			C42.1,118.5,40.8,117.1,40.8,115.5z M46.7,88.8v23.8h95V88.8H46.7z"
      fill={props.fill}
    />
    <path
      d="M40.8,68V38.3c0-1.6,1.3-3,3-3h98c3.3,0,5.9,2.7,5.9,5.9V65c0,3.3-2.7,5.9-5.9,5.9h-98
			C42.1,70.9,40.8,69.6,40.8,68z M46.7,41.3V65h95V41.3H46.7z"
      fill={props.fill}
    />
    <path
      d="M40.8,161.7V132c0-1.6,0.4-3,1-3h24.2c1.1,0,1.9,2.7,1.9,5.9v23.8c0,3.3-0.9,5.9-1.9,5.9H41.7
			C41.2,164.7,40.8,163.4,40.8,161.7z"
      fill={props.fill}
    />
    <path
      d="M40.8,161.7V132c0-1.6,1.3-3,3-3h98c3.3,0,5.9,2.7,5.9,5.9v23.8c0,3.3-2.7,5.9-5.9,5.9h-98
			C42.1,164.7,40.8,163.4,40.8,161.7z M46.7,135v23.8h95V135H46.7z"
      fill={props.fill}
    />
  </svg>
);

export default SvgChartBar;
