import React from 'react';

const SvgAdresseVert = (props: any) => (
  <svg viewBox="0 0 67.42 92.08" {...props}>
    <path
      fill={props.fill || '#06d0e7'}
      d="M33.71,92.08A33.75,33.75,0,0,1,0,58.37c0-17.39,27.73-53,30.9-57A3.54,3.54,0,0,1,33.71,0a3.6,3.6,0,0,1,2.82,1.37c3.16,4,30.89,39.61,30.89,57A33.75,33.75,0,0,1,33.71,92.08Zm0-82.61C24.33,21.92,7.18,47,7.18,58.37a26.53,26.53,0,0,0,53.06,0C60.24,47,43.1,21.92,33.71,9.47Z"
    />
    <path
      fill={props.fill || '#06d0e7'}
      d="M33.71,77.73a3.59,3.59,0,1,1,0-7.18A12.19,12.19,0,0,0,45.89,58.37a3.59,3.59,0,0,1,7.18,0A19.38,19.38,0,0,1,33.71,77.73Z"
    />
  </svg>
);

export default SvgAdresseVert;
