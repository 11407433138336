import React from 'react';
import { Button, Form, FormGroup, Label, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import Stats from './Stats';
import translate from '../_helpers/locale-helpers';

interface State {
  display: string;
  displays: Array<Object>;
}

/**
 * @class DisplayTypeChooser
 * @extends {React.Component<Props, State>}
 */
class DisplayTypeChooser extends React.Component<any, State> {
  /**
   * Met à jour le state si l'attribut property du formulaire
   * contient quelque chose
   *
   * @static
   * @param {any} props
   * @param {State} state
   * @returns {State} Le nouveau states
   * @method getDerivedStateFromProps
   * @memberof DisplayTypeChooser
   */
  static getDerivedStateFromProps(props: any, state: State) {
    const { form } = props;
    if (typeof form.property !== 'undefined' || form.property != null) {
      let prop = form.property.displayProperty[0].displayType;
      if (Object.keys(state.display).length > 0) {
        prop = state.display;
      }
      return {
        displays: form.property.displayProperty,
        display: prop,
      };
    }
    return {
      displays: state.displays,
      display: state.display,
    };
  }

  /**
   * @param {any} props Propriétés
   * @memberof DisplayTypeChooser
   */
  constructor(props: any) {
    super(props);

    this.state = {
      displays: [],
      display: '',
    };
  }

  /**
   * Gère le passage à l'étape précédente
   *
   * @method validate
   * @memberof DisplayTypeChooser
   */
  validate = () => {
    const { previousStep } = this.props;
    previousStep();
  };

  /**
   * Gère le passage à l'étape suivante et la mise à jour
   * du template
   *
   * @param {Object} e Evènement
   * @method send
   * @memberof DisplayTypeChooser
   */
  send = (e: any) => {
    e.preventDefault();
    const { update, nextStep } = this.props;
    const { display, displays } = this.state;
    update('display', displays.find((item: any) => item.displayType === display));
    nextStep();
  };

  /**
   * Gère le changement du type d'affichage
   *
   * @param {Object} event Evènement
   * @method handleOptionChange
   * @memberof DisplayTypeChooser
   */
  handleOptionChange = (event: any) => {
    const { displays } = this.state;
    this.setState({
      display: event.target.value,
    });
  };

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof DisplayTypeChooser
   */
  render() {
    const { isActive, locales } = this.props;
    const { display, displays } = this.state;
    if (!isActive) return null;
    return (
      <div>
        <h3>Choix du type de représentation</h3>
        {displays && (
          <Form onSubmit={this.send}>
            <div className="datasourceChoices">
              {displays.map((item: any) => (
                <div className="templateChoices" key={item.displayType}>
                  <FormGroup check>
                    <Label check>
                      {' '}
                      <input
                        type="radio"
                        id={item.displayType}
                        name={item.displayType}
                        value={item.displayType}
                        onChange={this.handleOptionChange}
                        checked={display === item.displayType}
                      />{' '}
                      {translate('fr', 'displayType', item.displayType, locales.locale)}
                    </Label>
                  </FormGroup>
                </div>
              ))}
            </div>
            <div className="bottomChoice">
              <Row>
                <Col md="4">
                  <Stats {...this.props} previousStep={this.validate} />
                </Col>
                <Col md="8" className="text-right">
                  <Button color="primary">Continuer</Button>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { locales } = state;
  return {
    locales,
  };
}

const DisplayTypeChooserConnectedComponent = connect(mapStateToProps)(DisplayTypeChooser);
export default DisplayTypeChooserConnectedComponent;
