import React, { Component } from 'react';
import _ from 'lodash';

export default class YieldChart extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      init: false,
      dots: [],
      hover: -1,
      width: 0,
      height: 0,
    };
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.drawLine = this.drawLine.bind(this);
    this.gridDisplay = this.gridDisplay.bind(this);
  }

  gridDisplay(ctx: any, max: number, labels: any, coefX: number, coefY: number, canvasInfo: any) {
    const heightOccurence = Math.round(canvasInfo.height / 50);
    let coef = max / heightOccurence;
    const maxLabels = canvasInfo.width / 85;
    let coefMaxLabels = Math.round((labels.length / maxLabels) * 1.5);
    const lengthX = max.toString().length * 10;
    ctx.strokeStyle = 'lightgrey';
    ctx.lineWidth = 1;
    ctx.font = '14px Arial';
    ctx.fillStyle = '#666666';
    for (let i = 1; i < heightOccurence; i++) {
      ctx.beginPath();
      ctx.moveTo(lengthX - 5, coef * i * coefY);
      ctx.lineTo(canvasInfo.width, coef * i * coefY);
      ctx.fillText(`${Math.round(max - coef * i)}`, 0, coef * i * coefY + 4);
      ctx.stroke();
    }

    labels.forEach((el: any, index: number) => {
      const length = el.length * 4;
      ctx.beginPath();
      ctx.moveTo(lengthX + coefX * (index + 1), canvasInfo.height - 20);
      ctx.lineTo(lengthX + coefX * (index + 1), 5);
      if (labels.length <= maxLabels) {
        ctx.fillText(`${el}`, coefX * index - (index !== 0 ? 0 : -length), canvasInfo.height);
      } else {
        if (index % coefMaxLabels === 0) {
          ctx.fillText(`${el}`, coefX * index + length, canvasInfo.height);
        }
      }
      ctx.stroke();
    });
  }

  handleMouseMove(e: any) {
    const { containerId } = this.props;
    const { dots, hover } = this.state;
    const canvas: any = document.getElementById('yield-chart');
    const tooltip: any = document.getElementById('tooltip');

    if (canvas && tooltip) {
      const ctxTip = tooltip.getContext('2d');
      const canvasInfo = canvas.getBoundingClientRect();
      const mouseX = e.clientX - canvasInfo.x;
      const mouseY = e.clientY - canvasInfo.y;
      let hit = false;
      dots.forEach((dot: any) => {
        const dx = mouseX - dot.x;
        const dy = mouseY - dot.y;
        if (dx * dx + dy * dy < 16) {
          let offSetX = 0;

          const bigContainer: any = document.getElementById(containerId);
          if (bigContainer && bigContainer.children && bigContainer.children[0]) {
            const doc: any = bigContainer.children[0];

            const containerInfo = bigContainer.getBoundingClientRect();
            const docInfo = doc.getBoundingClientRect();

            offSetX = (containerInfo.width - 60 - docInfo.width) / 2;
          }

          tooltip.style.left = dot.x + offSetX + 'px';
          tooltip.style.top = dot.y + 10 + 'px';
          ctxTip.clearRect(0, 0, 130, 40);
          ctxTip.fillStyle = 'white';
          ctxTip.font = '14px impact bold';
          ctxTip.fillText(dot.label, 5, 15);
          ctxTip.font = '12px arial';
          ctxTip.fillText(`${dot.meter}: ` + dot.tip, 5, 35);
          tooltip.style.visibility = 'visible';
          tooltip.style.border = '1px solid lightgrey';
          tooltip.style.backgroundColor = '#000000b3';
          hit = true;
          this.setState({
            hover: dot.tip,
          });
          this.componentDidMount();
        }
      });
      if (!hit) {
        tooltip.style.left = '-200px';
        tooltip.style.visibility = 'hidden';
        tooltip.style.border = 'none';
        this.setState({
          hover: -1,
        });
        if (hover >= 0) {
          this.componentDidMount();
        }
      }
    }
  }

  componentDidMount() {
    const { height, width } = this.state;
    const { data, labels } = this.props;
    const doc = document.getElementById('resize-container');
    if (height === 0 && width === 0 && doc) {
      const info = doc.getBoundingClientRect();
      this.setState({
        height: info.height,
        width: info.width,
      });
    }
    if (data.length > 0) {
      this.drawLine(data, labels);
    } else {
      setTimeout(() => this.componentDidMount(), 250);
    }
    if (doc) {
      doc.addEventListener('resize', () => this.componentDidMount());
    }
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    const { height, width } = this.state;
    const { data, labels } = this.props;
    const doc: any = document.getElementById('resize-container');
    const info = doc.getBoundingClientRect();
    const canvas: any = document.getElementById('yield-chart');
    const ctx = canvas.getContext('2d');
    if (prevState.height !== info.height || prevState.width !== info.width) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.setState({
        height: info.height,
        width: info.width,
        dots: [],
        init: false,
      });
      this.componentDidMount();
    }
    if (data !== prevProps.data) {
      this.setState({
        height: info.height,
        width: info.width,
        dots: [],
        init: false,
      });
      this.componentDidMount();
    }
  }

  drawLine(data: Array<Array<any>>, labels: Array<string>) {
    const { init, hover, dots, height, width } = this.state;
    const { meters } = this.props;
    const canvas: any = document.getElementById('yield-chart');
    if (canvas && canvas.height !== 0 && canvas.width !== 0) {
      const ctx = canvas.getContext('2d');

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.addEventListener('mousemove', this.handleMouseMove);
      const canvasInfo = canvas.getBoundingClientRect();

      // @ts-ignore
      const fullData = _.concat(data[0], data[1]).filter(el => el);
      const max = Math.max(...fullData) + Math.max(...fullData) * 0.1;
      const lengthX = max.toString().length * 10;
      const lengthY = 20;
      const coefY = (canvasInfo.height - lengthY) / max;
      const coefX = (canvasInfo.width - lengthX) / (labels.length - 0.8);

      ctx.beginPath();
      ctx.strokeStyle = 'lightgrey';
      ctx.lineWidth = 1;
      ctx.moveTo(lengthX, 5);
      ctx.lineTo(lengthX, canvasInfo.height - lengthY);
      ctx.lineTo(canvasInfo.width, canvasInfo.height - lengthY);
      ctx.stroke();

      this.gridDisplay(ctx, max, labels, coefX, coefY, canvasInfo);
      ctx.lineWidth = 2;
      let copyDots: any = _.cloneDeep(dots);
      data.forEach((el: any, indexList: number) => {
        ctx.beginPath();
        ctx.strokeStyle = indexList === 0 ? '#31c6b3' : '#34827a';
        el.forEach((el: any, index: number) => {
          if (index === 0) {
            ctx.lineTo(lengthX, (max - el) * coefY);
            copyDots.push({
              index,
              x: lengthX,
              y: (max - el) * coefY,
              tip: el,
              width: hover === el ? 8 : 4,
              label: labels[index],
              meter: meters[indexList],
            });
          } else {
            ctx.lineTo(lengthX + index * coefX, (max - el) * coefY);
            copyDots.push({
              index,
              x: lengthX + index * coefX,
              y: (max - el) * coefY,
              tip: el,
              width: hover === el ? 8 : 4,
              label: labels[index],
              meter: meters[indexList],
            });
          }
        });
        ctx.stroke();
      });
      this.drawArea(ctx, data, coefX, coefY, canvasInfo, lengthX, copyDots);

      copyDots.forEach((el: any, index: number) => {
        if (index === 0) {
          this.drawDot(lengthX, el.y, ctx, el.width);
        } else {
          this.drawDot(el.x, el.y, ctx, el.width);
        }
      });
      if (!init) {
        this.setState({
          init: true,
          dots: copyDots,
        });
      }
    }
  }

  drawArea(
    ctx: any,
    data: Array<Array<number>>,
    coefX: any,
    coefY: any,
    canvasInfo: any,
    lengthX: number,
    dots: Array<any>
  ) {
    if (data[0]) {
      const length = data[0].length;
      for (let i = 0; i < length - 1; i++) {
        let a, b, c, d;
        if (data[0][i] >= data[1][i] && data[0][i + 1] < data[1][i + 1]) {
          a = data[1][i];
          b = data[0][i];
          c = data[1][i + 1];
          d = data[0][i + 1];

          const dif_AC_Y = c - a;
          const dif_BD_Y = d - b;

          const systemA = dif_AC_Y - dif_BD_Y;
          const systemB = b - a;

          const X = systemB / systemA;
          const Y = dif_AC_Y * X + a;

          const crossX = lengthX + coefX * i + X * coefX;
          const crossY = canvasInfo.height - coefY * Y - 20;

          const triPoints = dots.filter((el: any) => el.index === i + 1);

          ctx.beginPath();
          ctx.fillStyle = '#dc3545';
          ctx.moveTo(triPoints[0].x, triPoints[0].y);
          ctx.lineTo(triPoints[1].x, triPoints[1].y);
          ctx.lineTo(crossX, crossY);
          ctx.fill();
        } else if (data[0][i] <= data[1][i] && data[0][i + 1] > data[1][i + 1]) {
          a = data[0][i];
          b = data[1][i];
          c = data[0][i + 1];
          d = data[1][i + 1];

          const dif_AC_Y = c - a;
          const dif_BD_Y = d - b;

          const systemA = dif_AC_Y - dif_BD_Y;
          const systemB = b - a;

          const X = systemB / systemA;
          const Y = dif_AC_Y * X + a;

          const crossX = lengthX + coefX * i + X * coefX;
          const crossY = canvasInfo.height - coefY * Y - 20;

          const triPoints = dots.filter((el: any) => el.index === i);

          ctx.beginPath();
          ctx.fillStyle = '#dc3545';
          ctx.moveTo(triPoints[0].x, triPoints[0].y);
          ctx.lineTo(triPoints[1].x, triPoints[1].y);
          ctx.lineTo(crossX, crossY);
          ctx.fill();
        } else if (data[0][i] <= data[1][i] && data[0][i + 1] <= data[0][i + 1]) {
          a = dots.find((el: any) => el.tip === data[0][i]);
          b = dots.find((el: any) => el.tip === data[1][i]);
          c = dots.find((el: any) => el.tip === data[0][i + 1]);
          d = dots.find((el: any) => el.tip === data[1][i + 1]);

          ctx.beginPath();
          ctx.fillStyle = '#dc3545';
          ctx.moveTo(a.x, a.y);
          ctx.lineTo(b.x, b.y);
          if (c && d) {
            ctx.lineTo(d.x, d.y);
            ctx.lineTo(c.x, c.y);
          }
          ctx.fill();
        }
      }
    } else {
      this.componentDidMount();
    }
  }

  drawDot(x: any, y: any, ctx: any, width: number) {
    ctx.beginPath();
    ctx.fillStyle = '#31c6b3';
    ctx.arc(x, y, width, 0, 2 * Math.PI, true);
    ctx.fill();
  }

  render() {
    const { height, width } = this.state;
    return (
      <div className={'yield-chart-container'} id={'resize-container'}>
        <canvas id={'yield-chart'} width={width} height={height} onMouseMove={this.handleMouseMove} />
        <canvas id={'tooltip'} width="130" height="40" />
      </div>
    );
  }
}
