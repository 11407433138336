import intervalConstants from '../_constants/interval.constants';

function updateInterval(sDate: string, eDate: string, zoom: string) {
  function success(startDate: string, endDate: string) {
    return { type: intervalConstants.UPDATE_INTERVAL_SUCCESS, current: { startDate, endDate, zoom } };
  }

  return (dispatch: Function) => {
    dispatch(success(sDate, eDate));
  };
}

function clear() {
  return {
    type: intervalConstants.CLEAR,
  };
}

const intervalActions = {
  updateInterval,
  clear,
};

export default intervalActions;
