import React, { Component } from 'react';
import _ from 'lodash';
import Slider from 'rc-slider';
import { withTranslation } from 'react-i18next';
import { translate } from '../_helpers';
import locale from '../_shared/Locale.json';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

interface Props {
  handleChange: Function;
  unit: string;
  alarmTemplates: any;
  t: Function;
}

class SeuilComponent extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    const index = _.get(props, 'alarmTemplates.content')
      ? props.alarmTemplates.content.dataSourceProperty.condition.findIndex(
          (el: any) => el.conditionTitle === 'RangeConsumption'
        )
      : null;
    this.state = {
      seuil: {
        inf: _.isNumber(
          _.get(props, `alarmTemplates.content.dataSourceProperty.condition[${index}].conditionValue.inf`)
        )
          ? _.get(props, `alarmTemplates.content.dataSourceProperty.condition[${index}].conditionValue.inf`)
          : '0',
        sup: _.isNumber(
          _.get(props, `alarmTemplates.content.dataSourceProperty.condition[${index}].conditionValue.sup`)
        )
          ? _.get(props, `alarmTemplates.content.dataSourceProperty.condition[${index}].conditionValue.sup`)
          : '100',
      },
    };
  }

  handleChange = (value: string) => {
    const { handleChange } = this.props;
    const { seuil } = this.state;
    const newSeuil = seuil;
    newSeuil.inf = value[0];
    newSeuil.sup = value[1];
    this.setState({
      seuil: newSeuil,
    });
    handleChange(newSeuil);
  };

  render() {
    const { unit, alarmTemplates, t } = this.props;
    const { seuil } = this.state;
    const reg = '/^d+$/';
    return (
      <div className="wrapper">
        <h5>
          {t('all.text.threshold')} ({translate('fr', 'unit', unit, locale)})
        </h5>
        <div className="flex-box">
          <Range
            min={0}
            max={100}
            value={[seuil.inf, seuil.sup]}
            tipFormatter={(value: any) => `${value}%`}
            onChange={(value: any) => this.handleChange(value)}
            tipProps={{ visible: true }}
          />
        </div>
      </div>
    );
  }
}

const tr = withTranslation()(SeuilComponent);
export default tr;
