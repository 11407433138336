import React from 'react';
import { Collapse, Nav, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { User } from '../_entities/user';
import ImportVert from '../SvgComponents/ImportBleu';
import { getActiveClassByPath } from '../_helpers';
import TourneesVertes from '../SvgComponents/TourneesVertes';
import TelephoneVert from '../SvgComponents/TelephoneVert';
import SvgMessageVert from '../SvgComponents/MessageVert';
import SvgMarquagesVert from '../SvgComponents/MarquageVert';
import { gestionnaireActions } from '../_actions/index';
import EnqueteSvg from '../SvgComponents/EnqueteSvg';
import SvgManageTournee from '../SvgComponents/ManageTournee';

interface Props {
  user: User;
}
interface State {
  isOpen: boolean;
}

class SidebarMiniSynchroComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    const { params } = match;
    const { GestionnaireId } = params;
    dispatch(gestionnaireActions.getGestionnaire(GestionnaireId));
  }

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  render() {
    const { isOpen } = this.state;
    const { locations, gestionnaire } = this.props;
    return (
      <div>
        {gestionnaire && gestionnaire.gestionnaire && (
          <div className="sidebar-mini-synchro">
            <Navbar className="sidebar-mini sidebar-stock" dark expand="md">
              <div className="sidebar-synchro">
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={isOpen} navbar>
                  <Nav navbar>
                    <NavItem>
                      <NavLink
                        className={getActiveClassByPath(location.pathname, 'manager')}
                        href={`/gestionnaires/${gestionnaire.gestionnaire.id}/synchronisation`}
                        id="sidebar-synchro"
                      >
                        <SvgManageTournee fill="currentcolor" stroke="currentcolor" height="1em" width="1em" />{' '}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={getActiveClassByPath(location.pathname, 'tournees')}
                        href={`/gestionnaires/${gestionnaire.gestionnaire.id}/synchronisation/tournees`}
                        id="sidebar-synchro"
                      >
                        <TourneesVertes fill="currentcolor" stroke="currentcolor" height="1em" width="1em" />
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={getActiveClassByPath(location.pathname, 'portables')}
                        href={`/gestionnaires/${gestionnaire.gestionnaire.id}/synchronisation/portables`}
                        id="sidebar-synchro"
                      >
                        <TelephoneVert fill="currentcolor" stroke="currentcolor" height="1em" width="1em" />
                      </NavLink>
                    </NavItem>
                    {false && (
                      <NavItem>
                        <NavLink
                          className={getActiveClassByPath(location.pathname, 'constructeur')}
                          href={`/gestionnaires/${gestionnaire.gestionnaire.id}/synchronisation/constructeur`}
                          id="sidebar-synchro"
                        >
                          <ImportVert fill="currentcolor" stroke="currentcolor" height="1em" width="1em" />
                        </NavLink>
                      </NavItem>
                    )}
                    <NavItem>
                      <NavLink
                        className={getActiveClassByPath(location.pathname, 'messages')}
                        href={`/gestionnaires/${gestionnaire.gestionnaire.id}/synchronisation/messages`}
                        id="sidebar-synchro"
                      >
                        <SvgMessageVert height="1em" width="1em" fill="currentcolor" stroke="currentcolor" />
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={getActiveClassByPath(location.pathname, 'marquages')}
                        href={`/gestionnaires/${gestionnaire.gestionnaire.id}/synchronisation/marquages`}
                        id="sidebar-synchro"
                      >
                        <SvgMarquagesVert height="1em" width="1em" fill="currentcolor" stroke="currentcolor" />
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={getActiveClassByPath(location.pathname, 'enquetes')}
                        href={`/gestionnaires/${gestionnaire.gestionnaire.id}/synchronisation/enquetes`}
                        id="sidebar-synchro"
                      >
                        <EnqueteSvg height="1em" width="1em" fill="currentcolor" stroke="currentcolor" />
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={getActiveClassByPath(location.pathname, 'silex')}
                        href={`/gestionnaires/${gestionnaire.gestionnaire.id}/synchronisation/silex`}
                        id="sidebar-synchro"
                      >
                        <ImportVert fill="currentcolor" stroke="currentcolor" height="1em" width="1em" />
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Collapse>
              </div>
            </Navbar>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, locations, gestionnaire } = state;
  const { user } = authentication;
  return {
    user,
    locations,
    gestionnaire,
  };
}

const mapping: any = connect(mapStateToProps)(SidebarMiniSynchroComponent);

const connectedSidebarStock = withRouter(mapping);
export default connectedSidebarStock;
