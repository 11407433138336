import synchroComConstants from '../_constants/synchrocom.constants';
import synchroComService from '../_services/synchrocom.service';

function getCurrentCommunication(gestionnaireId: any) {
  function request() {
    return { type: synchroComConstants.GETCOMMUNICATION_REQUEST };
  }
  function success(currentState: Object) {
    return { type: synchroComConstants.GETCOMMUNICATION_SUCCESS, currentState };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.GETCOMMUNICATION_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroComService
      .getAllDevicesInfo(gestionnaireId)
      .then((state: Object) => {
        dispatch(success(state));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getCurrentRounds(gestionnaireId: any) {
  function request() {
    return { type: synchroComConstants.GET_ROUNDS_REQUEST };
  }
  function success(rounds: any) {
    return { type: synchroComConstants.GET_ROUNDS_SUCCESS, rounds };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.GET_ROUNDS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroComService
      .getAllRoundsInfo(gestionnaireId)
      .then((state: Object) => dispatch(success(state)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function updateTaskLoader(deviceId: string) {
  function success(deviceId: string) {
    return { type: synchroComConstants.UPDATELOADER_SUCCESS, deviceId };
  }

  return (dispatch: Function) => {
    dispatch(success(deviceId));
  };
}

function clear() {
  return {
    type: synchroComConstants.CLEAR,
  };
}

const templateActions = {
  getCurrentCommunication,
  getCurrentRounds,
  updateTaskLoader,
  clear,
};

export default templateActions;
