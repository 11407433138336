import { authHeader, handleResponse } from '../_helpers';
import { handleInputChange } from 'react-select/src/utils';

async function getTournee(tourneeCode: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/tournee/${tourneeCode}`, requestOptions);
  return handleResponse(response);
}

async function rotateFiches(tourneeCode: string) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };
  const response = await fetch(`/api/tournee/${tourneeCode}/rotatefiches`, requestOptions);
  return handleResponse(response);
}

async function deleteTournee(tourneeCode: Array<string>) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    body: JSON.stringify(tourneeCode),
  };
  const response = await fetch(`/api/tournee/`, requestOptions);
  return handleResponse(response);
}

async function getTelereleveReads(roundCode: Array<string>, startDate: any, endDate: any, replaceMoreRecent: boolean) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ roundCode, startDate, endDate, replaceMoreRecent }),
  };
  const response = await fetch(`/api/tournee/${roundCode}/telereleve`, requestOptions);
  return handleResponse(response);
}

async function launchIntervalTelereleveReads(roundCode: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/tournee/${roundCode}/telereleve/first`, requestOptions);
  return handleResponse(response);
}

async function fillTelereleveReads(roundCode: Array<string>, startDate: any, endDate: any, replaceMoreRecent: boolean) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({ roundCode, startDate, endDate, replaceMoreRecent }),
  };
  const response = await fetch(`/api/tournee/${roundCode}/telereleve`, requestOptions);
  return handleResponse(response);
}

async function launchIntervalFillTelereleveReads(roundCode: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/tournee/${roundCode}/telereleve/second`, requestOptions);
  return handleResponse(response);
}

async function getFicheTournee(tourneeCode: string, ficheId: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/tournee/${tourneeCode}/fiche/${ficheId}`, requestOptions);
  return handleResponse(response);
}

async function getAllFicheTournee(tourneeCode: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/tournee/${tourneeCode}/fiche`, requestOptions);
  return handleResponse(response);
}

async function invalidCache(tourneeCode: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/tournee/${tourneeCode}/cache`, requestOptions);
  return handleResponse(response);
}

async function exportTournee(tourneeCode: Array<string>) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(tourneeCode),
  };
  const response = await fetch(`/api/tournee/export`, requestOptions);
  if (!response.ok) {
    return handleResponse(response);
  }
  return response;
}

async function exportTourneeCsv(tourneeCode: Array<string>, format: string) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(tourneeCode),
  };
  const response = await fetch(`/api/tournee/export/csv${format && `/format/${format}`}`, requestOptions);
  return response.blob().then((blob: any) => ({
    content: blob,
    filename: response.headers.get('export-name'),
    report: response.headers.get('report'),
  }));
}

async function loadSilexToFactu(roundCode: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(roundCode),
  };

  let response = await fetch(`/api/tournee/export/factu`, requestOptions);
  return handleResponse(response);
}

async function getFacturationName() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let response = await fetch(`/api/tournee/facturation`, requestOptions);
  return response.text().then(text => text);
}

async function exportTourneeTeleo(tourneeCode: Array<string>) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(tourneeCode),
  };
  const response = await fetch(`/api/tournee/export/teleo`, requestOptions);
  return handleResponse(response);
}

async function getStats(tourneeCode: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/tournee/${tourneeCode}/stats`, requestOptions);
  return handleResponse(response);
}

async function updateTourneeMsg(tourneeCode: string, tourneeMsg: string) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: tourneeMsg,
  };
  const response = await fetch(`/api/tournee/${tourneeCode}/messagetournee`, requestOptions);
  return handleResponse(response);
}

async function getAllInfo() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/tournee/allInfo`, requestOptions);
  return handleResponse(response);
}

async function getAllTourneeInfos() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/tournee/all/infos`, requestOptions);
  return handleResponse(response);
}

async function getRotateOption() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/tournee/rotateoption`, requestOptions);
  return handleResponse(response);
}

async function getEditInfos(tourneeCode: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/tournee/${tourneeCode}/editinfos`, requestOptions);
  return handleResponse(response);
}

async function getOptionsExport() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/tournee/export/options`, requestOptions);
  return handleResponse(response);
}

async function updateFieldFiche(tourneeCode: string, theoricalSerial: string, field: string, value: any) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: _.isString(value) ? value : JSON.stringify(value),
  };
  const response = await fetch(`/api/tournee/${tourneeCode}/fiche/${theoricalSerial}/update/${field}`, requestOptions);
  return handleResponse(response);
}

async function delMessage(tourneeCode: string, theoricalSerial: String) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };
  const response = await fetch(`/api/tournee/${tourneeCode}/fiche/${theoricalSerial}/delete/message`, requestOptions);
  return handleResponse(response);
}

async function delMarquage(tourneeCode: string, theoricalSerial: String) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };
  const response = await fetch(`/api/tournee/${tourneeCode}/fiche/${theoricalSerial}/delete/marquage`, requestOptions);
  return handleResponse(response);
}

async function getLocationIdWithCode(tourneeCode: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/tournee/${tourneeCode}/id`, requestOptions);
  return handleResponse(response);
}

const tourneeService = {
  getTournee,
  rotateFiches,
  getFicheTournee,
  getAllFicheTournee,
  exportTournee,
  getStats,
  deleteTournee,
  updateTourneeMsg,
  getAllInfo,
  invalidCache,
  getTelereleveReads,
  fillTelereleveReads,
  getAllTourneeInfos,
  getRotateOption,
  getEditInfos,
  getOptionsExport,
  updateFieldFiche,
  exportTourneeCsv,
  exportTourneeTeleo,
  launchIntervalTelereleveReads,
  launchIntervalFillTelereleveReads,
  delMarquage,
  delMessage,
  getLocationIdWithCode,
  loadSilexToFactu,
  getFacturationName,
};

export default tourneeService;
