import React from 'react';

const SvgHistoriqueAlarmeRadioVert = (props: any) => (
  <svg viewBox="0 0 23.92 35.43" {...props}>
    <g id="historique_alarme_radio_vert_svg__Calque_2" data-name="Calque 2">
      <g id="historique_alarme_radio_vert_svg__Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          d="M21.43 21.86a1 1 0 0 1 1 1v10.08a1 1 0 0 1-1 1H2.49a1 1 0 0 1-1-1V22.82a1 1 0 0 1 1-1h18.94m0-1.53H2.49A2.5 2.5 0 0 0 0 22.82v10.12a2.5 2.5 0 0 0 2.49 2.49h18.94a2.5 2.5 0 0 0 2.49-2.49V22.82a2.5 2.5 0 0 0-2.49-2.49zM5.57 1.53a.14.14 0 0 1 .15.14v4.71a.15.15 0 0 1-.11.13v6.88h.6a.12.12 0 0 1 .12.12v.54h.37a.08.08 0 0 1 .07.08V16a.08.08 0 0 1-.07.08h-.37v.77h.26a.18.18 0 0 1 .18.18v.79a.18.18 0 0 1-.18.18H3.72a.19.19 0 0 1-.19-.18V17a.19.19 0 0 1 .19-.18H4v-.77h-.39a.09.09 0 0 1-.08-.05v-1.87a.09.09 0 0 1 .08-.08H4v-.54a.12.12 0 0 1 .12-.12h.59V6.51a.14.14 0 0 1-.1-.13V1.67a.14.14 0 0 1 .14-.14h.84M5.57 0h-.84a1.67 1.67 0 0 0-1.67 1.67v4.71a1.63 1.63 0 0 0 .11.62v5.18a1.71 1.71 0 0 0-.62.78A1.59 1.59 0 0 0 2 14.13V16a1.7 1.7 0 0 0 .08.51A1.77 1.77 0 0 0 2 17v.79a1.72 1.72 0 0 0 1.72 1.71h2.87a1.72 1.72 0 0 0 1.71-1.71V17a1.77 1.77 0 0 0-.08-.52A1.7 1.7 0 0 0 8.3 16v-1.87a1.57 1.57 0 0 0-.55-1.2 1.69 1.69 0 0 0-.61-.78V7a1.71 1.71 0 0 0 .11-.59V1.67A1.68 1.68 0 0 0 5.57 0z"
        />
      </g>
    </g>
  </svg>
);

export default SvgHistoriqueAlarmeRadioVert;
