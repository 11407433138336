import { authHeader, handleResponse } from '../_helpers';

async function getAllDevicesInfo(gestionnaireId: any) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/synchro/gestionnaire/${gestionnaireId.toString()}/devices/status`, requestOptions);
  return handleResponse(response);
}

async function getAllRoundsInfo(gestionnaireId: any) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/synchro/gestionnaire/${gestionnaireId.toString()}/rounds/status`, requestOptions);
  return handleResponse(response);
}

const reportService = {
  getAllDevicesInfo,
  getAllRoundsInfo,
};

export default reportService;
