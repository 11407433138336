export default {
  GET_MASKS_REQUEST: 'GET_MASKS_REQUEST',
  GET_MASKS_SUCCESS: 'GET_MASKS_SUCCESS',
  GET_MASKS_FAILURE: 'GET_MASKS_FAILURE',

  GET_ONE_MASK_REQUEST: 'GET_ONE_MASK_REQUEST',
  GET_ONE_MASK_SUCCESS: 'GET_ONE_MASK_SUCCESS',
  GET_ONE_MASK_FAILURE: 'GET_ONE_MASK_FAILURE',

  GET_MASK_INFO_REQUEST: 'GET_MASK_INFO_REQUEST',
  GET_MASK_INFO_SUCCESS: 'GET_MASK_INFO_SUCCESS',
  GET_MASK_INFO_FAILURE: 'GET_MASK_INFO_FAILURE',

  DELETE_MASKS_REQUEST: 'DELETE_MASKS_REQUEST',
  DELETE_MASKS_SUCCESS: 'DELETE_MASKS_SUCCESS',
  DELETE_MASKS_FAILURE: 'DELETE_MASKS_FAILURE',

  CREATE_MASKS_REQUEST: 'CREATE_MASKS_REQUEST',
  CREATE_MASKS_SUCCESS: 'CREATE_MASKS_SUCCESS',
  CREATE_MASKS_FAILURE: 'CREATE_MASKS_FAILURE',

  CREATE_FROMCHART_MASKS_REQUEST: 'CREATE_FROMCHART_MASKS_REQUEST',
  CREATE_FROMCHART_MASKS_SUCCESS: 'CREATE_FROMCHART_MASKS_SUCCESS',
  CREATE_FROMCHART_MASKS_FAILURE: 'CREATE_FROMCHART_MASKS_FAILURE',

  UPDATE_MASKS_REQUEST: 'UPDATE_MASKS_REQUEST',
  UPDATE_MASKS_SUCCESS: 'UPDATE_MASKS_SUCCESS',
  UPDATE_MASKS_FAILURE: 'UPDATE_MASKS_FAILURE',

  GET_AVAILABLE_ALARMS_REQUEST: 'GET_AVAILABLE_ALARMS_REQUEST',
  GET_AVAILABLE_ALARMS_SUCCESS: 'GET_AVAILABLE_ALARMS_SUCCESS',
  GET_AVAILABLE_ALARMS_FAILURE: 'GET_AVAILABLE_ALARMS_FAILURE',

  GET_MASK_PDI_ALARMS_REQUEST: 'GET_MASK_PDI_ALARMS_REQUEST',
  GET_MASK_PDI_ALARMS_SUCCESS: 'GET_MASK_PDI_ALARMS_SUCCESS',
  GET_MASK_PDI_ALARMS_FAILURE: 'GET_MASK_PDI_ALARMS_FAILURE',

  GET_MASK_PDI_REQUEST: 'GET_MASK_PDI_REQUEST',
  GET_MASK_PDI_SUCCESS: 'GET_MASK_PDI_SUCCESS',
  GET_MASK_PDI_FAILURE: 'GET_MASK_PDI_FAILURE',

  DESAC_MASK_SUCCESS: 'DESAC_MASK_SUCCESS',
  ACTIV_MASK_SUCCESS: 'ACTIV_MASK_SUCCESS',

  CLEAR: 'MASKS_CLEAR',
};
