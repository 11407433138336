import fileDownload from 'js-file-download';
import { convertArrayToCSV } from 'convert-array-to-csv';
import { ColumnData } from '../_interfaces/column';
import { get as _get } from 'lodash';
import StaticList from '../TourneeFiches/StaticList';

/**
 * Exporte les colonnes et les données sous forme de fichier CSV à télécharger
 *
 * @param {Array<ColumnData>} columns Colonnes affichées
 * @param {Array<{ [string]: string | number }>} data Données affichées
 */
function downloadCSV(columns: Array<ColumnData>, data: Array<any>, name = 'exportCrystalCloud') {
  const arrayCSVHeader: Array<any> = [];
  const arrayCSVData: Array<any> = [];
  columns.forEach(c => arrayCSVHeader.push(c.text));
  data.forEach(d => {
    arrayCSVData.push([]);
    columns.forEach((c: any) => {
      let elem = _get(d, c.dataField);
      if (undefined !== elem && null !== elem && typeof elem !== 'string') {
        elem = elem.toString();
      }
      arrayCSVData[arrayCSVData.length - 1].push(elem);
    });
  });
  fileDownload(convertArrayToCSV(arrayCSVData, { header: arrayCSVHeader, separator: ';' }), `${name}.csv`);
}

export default downloadCSV;
