import React, { ReactElement } from 'react';

type Props = {
  bodyWidget: ReactElement<'div'>;
};

/**
 * Créé le composant widget comme il est
 * représenté sur un dashboard
 *
 * @export
 * @method SimpleWidget
 * @param {Props} {
 *   displayFullScreen,
 *   isConfigured,
 *   headerWidget,
 *   bodyWidget,
 * }
 * @returns {JSX} Le composant
 */
export default function TinyWidget({ bodyWidget }: Props) {
  return (
    <div className="container-widget container widget-ready" style={{ height: 'fit-content', maxHeight: '500px' }}>
      <span style={{ height: '30px', width: '100%', display: 'block' }} />
      {bodyWidget}
    </div>
  );
}
