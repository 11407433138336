import React from 'react';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CompteurBlanc from '../SvgComponents/CompteurBlanc';
import PdiBlanc from '../SvgComponents/PdiBlanc';
import { getActiveClassByPath } from '../_helpers';
import { User } from '../_entities/user';
import ColonneVert from '../SvgComponents/ColonneVert';
import { withTranslation } from 'react-i18next';

interface Props {
  user: User;
  locations: any;
  location: any;
}
interface State {
  isOpen: boolean;
}

class SidebarStockComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  render() {
    const { isOpen } = this.state;
    const { location, t } = this.props;
    return (
      <div className="sidebar-location">
        <Navbar className="sidebar-location-wrapper sidebar-stock" dark expand="md">
          <div>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav navbar>
                <NavbarBrand
                  href="/stock/resources"
                  className="center"
                  style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                >
                  {t('sidebar_stock.nav_title.stock')}
                </NavbarBrand>
                <NavItem>
                  <NavLink className={getActiveClassByPath(location.pathname, 'resources')} href="/stock">
                    <ColonneVert
                      fill="currentcolor"
                      stroke="currentcolor"
                      height="1em"
                      width="1em"
                      style={{ marginRight: '5px' }}
                    />
                    {t('sidebar_stock.nav_link.resources_list')}
                  </NavLink>
                </NavItem>
                {false && (
                  <div>
                    <NavItem>
                      <NavLink
                        className={getActiveClassByPath(location.pathname, 'createmeter')}
                        href="/stock/createmeter"
                      >
                        <CompteurBlanc fill="currentcolor" stroke="currentcolor" height="1em" width="1em" />
                        {t('sidebar_stock.nav_link.create_meter')}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={getActiveClassByPath(location.pathname, 'createpdi')} href="/stock/createpdi">
                        <PdiBlanc fill="currentcolor" stroke="currentcolor" height="1em" width="1em" />
                        {t('sidebar_stock.nav_link.create_pdi')}
                      </NavLink>
                    </NavItem>
                  </div>
                )}
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, dashboards, locations } = state;
  const { user } = authentication;
  return {
    user,
    dashboards,
    locations,
  };
}

const mapping: any = connect(mapStateToProps)(SidebarStockComponent);

const connectedSidebarStock = withRouter(mapping);
const tr = withTranslation()(connectedSidebarStock);
export default tr;
