import React from 'react';

const SvgAttentionJaune = (props: any) => (
  <svg viewBox="0 0 500 500" {...props}>
    <g id="repères" />
    <g id="Calque_1">
      <g>
        <path
          fill={props.fill}
          d="M250,112c-75.8,0-138,62.1-138,138c0,75.8,62.1,138,138,138c75.8,0,138-62.1,138-138
			C388,174.2,325.8,112,250,112L250,112z M263.8,319h-27.6v-27.6h27.6V319z M263.8,263.8h-27.6V181h27.6V263.8z M263.8,263.8"
        />
      </g>
    </g>
  </svg>
);

export default SvgAttentionJaune;
