import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { HorizontalBar } from 'react-chartjs-2';
import _ from 'lodash';
import tools from './tools';

const { getColor } = tools;

interface ChartData {
  label: string;
  color: string;
  hoverColor?: string;
  data: number;
}

interface Props {
  chartData: ChartData[];
  handleClick?: Function;
}

class BarRemoteReading extends Component<Props, any> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  static getDerivedStateFromProps(props: any, state: any) {
    const { chartData, handleClick, extra } = props;
    if (chartData) {
      const labels: string[] = [];
      const colors: string[] = [];
      const hoverColors: string[] = [];
      const data: number[] = [];

      let totData = 0;

      chartData.sort((a, b) => (a.data > b.data ? -1 : 1));

      chartData.map(chartData => (totData += chartData.data));

      chartData.map(chart => {
        labels.push(chart.label);
        colors.push(chart.color);
        hoverColors.push(chart.hoverColor ? chart.hoverColor : '');
        data.push(chart.data);
      });
      let extraData: any = [];
      if (extra) {
        extraData = _.uniq(
          extra
            .map((el: any) => Object.keys(el))
            .flat()
            .filter(el => el !== 'name' && el !== 'cnt')
        )
          .map(el => ({
            label: el,
            data: extra.map((ex: any) => ex[el]),
            backgroundColor: getColor(el),
          }))
          .filter(el => el);
      }

      const dataBar = {
        labels,
        datasets:
          extraData.length > 0
            ? extraData
            : [
                {
                  backgroundColor: colors,
                  hoverBackgroundColor: hoverColors,
                  data,
                },
              ],
      };

      const optionsBar: any = {
        responsive: true,
        layout: {
          padding: {
            left: 5,
            right: 50, // To avoid datalabels to be cut off
            top: 0,
            bottom: 0,
          },
        },
        tooltips: {
          mode: 'index',
          filter(item, data) {
            var data = data.datasets[item.datasetIndex].data[item.index];
            return !isNaN(data) && data !== null;
          },
        },
        legend: {
          display: false, // Légendes explicatives
        },
        plugins: {
          t1: false,
          datalabels: {
            display: 'auto',
            color: 'black',
            formatter(value, context) {
              return value;
            },
            align: 'center',
            anchor: 'center',
            font: {
              size: 12,
            },
          },
          zoom: {
            enabled: true,
            mode: 'x',
          },
        },
        scales: extra
          ? {
              xAxes: [
                {
                  stacked: true,
                },
              ],
              yAxes: [
                {
                  stacked: true,
                },
              ],
            }
          : {
              xAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    callback(value) {
                      if (value % 1 === 0) {
                        return value;
                      }
                    },
                  },
                },
              ],
            },
      };

      if (handleClick !== undefined) {
        optionsBar.onClick = handleClick;
      }

      return {
        dataBar,
        optionsBar,
      };
    }
  }

  render() {
    const { dataBar, optionsBar } = this.state;
    return (
      <div>
        <HorizontalBar data={dataBar} options={optionsBar} />
      </div>
    );
  }
}

// @ts-ignore
const mapping: any = connect()(BarRemoteReading);

const connectedBarRemoteReading = withRouter(mapping);
export default connectedBarRemoteReading;
