import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/fr';
import { UncontrolledTooltip } from 'reactstrap';
import SvgAlarm from '../SvgComponents/Alarme_Fuite_Bleu';
import SvgCalendar from '../SvgComponents/HistoriqueVert';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const dataFormator = (data: any) => {
  const language: any = localStorage.getItem('language');
  const sData = _.entries(data).map((el: any) => {
    const datas = _.range(0, 24).map((box: any) => {
      if (_.entries(el[1]).find((sBox: any) => box === parseInt(sBox[0], 10))) {
        return _.entries(el[1]).find((sBox: any) => box === parseInt(sBox[0], 10));
      }
      return box;
    });
    moment.locale(language);
    return {
      date: _.capitalize(moment(el[0]).format('ddd DD/MM/YY')),
      data: datas,
    };
  });
  while (sData.length < 3) {
    sData.push({ date: '', data: _.range(0, 24) });
  }
  return sData;
};

const Historique = (props: any) => {
  const { sensor, keyProps, dryContact, t } = props;
  //valvestatus
  const sData = dataFormator(_.get(sensor, `sensor.data.${keyProps}`));
  return (
    <div className="fichePdi" style={{ padding: 0 }}>
      <div className="historique-container block-pdi-container own-container container-fluid">
        <div className="title flex-box" style={{ marginBottom: '20px' }}>
          <SvgCalendar height="2em" fill="#31c6b3" />
          <h2>{dryContact || t('sensor.title.vanne_status')}</h2>
          <div style={{ position: 'absolute', right: '40px' }}>
            <div className="legende" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', marginRight: '30px' }}>
                <div style={{ backgroundColor: '#8CC63F' }} className="legend-box" />
                <span className="legend-label">{dryContact ? 'Etat 1' : t('all.text.open')}</span>
              </div>
              <div style={{ display: 'flex', marginRight: '30px' }}>
                <div style={{ backgroundColor: '#1C5359' }} className="legend-box" />
                <span className="legend-label">{dryContact ? 'Etat 0' : t('all.text.open')}</span>
              </div>
              <div style={{ display: 'flex', marginRight: '30px' }}>
                <div style={{ backgroundColor: '#A63C76' }} className="legend-box" />
                <span className="legend-label">{t('all.alarm_meter.alarm_plural')}</span>
              </div>
              <div style={{ display: 'flex', marginRight: '30px' }}>
                <div style={{ backgroundColor: 'lightgrey' }} className="legend-box" />
                <span className="legend-label">{t('all.text.no_data_available')}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="row">
            <div className="day" />
            <div className="flex-box">
              {_.range(0, 24).map((el: any) => (
                <div className="box">
                  <span>{el.toString().padStart(2, '0')}h</span>
                </div>
              ))}
            </div>
          </div>
          {sData.map((el: any, index: number) => (
            <div className="row">
              <div className="day">
                <span>{el.date}</span>
              </div>
              <div className="flex-box">
                {el.data.map((box: any, dataIndex: number) => (
                  <div className="box schema" id={`box-${index}-${dataIndex}`} style={{ backgroundColor: 'lightgrey' }}>
                    {Array.isArray(box[1]) &&
                      box[1].map((s: any) => (
                        <>
                          <div
                            className="section-box"
                            style={{
                              width: `${s.endPercent - s.startPercent}%`,
                              backgroundColor: s.status ? '#8CC63F' : '#1C5359',
                              height: '100%',
                            }}
                          />
                          {s.fraud ||
                            (s.leak && (
                              <div
                                className="section-box"
                                style={{
                                  width: `${s.endPercent - s.startPercent}%`,
                                  backgroundColor: '#A63C76',
                                  height: '30%',
                                  position: 'absolute',
                                  bottom: 0,
                                }}
                              />
                            ))}
                        </>
                      ))}
                    {Math.round(Math.random() * 100) % 5 === 21 ? <SvgAlarm fill="#cf7830" width="30px" /> : <div />}
                    <UncontrolledTooltip placement="bottom" target={`box-${index}-${dataIndex}`}>
                      {Array.isArray(box[1]) &&
                        box[1].map((s: any) => (
                          <h5 style={{ marginTop: '5px' }}>
                            {s.status ? t('all.text.open') : t('all.text.close')}{' '}
                            {moment.utc(s.startDate).format('HH:mm')}
                            {' > '}
                            {moment.utc(s.endDate).format('HH:mm')}
                            <br />
                          </h5>
                        ))}
                    </UncontrolledTooltip>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { sensor } = state;
  return {
    sensor,
  };
}

export default withTranslation()(connect(mapStateToProps)(Historique));
