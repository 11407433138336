const meterGatewayConstants = {
  GET_GATEWAY_REQUEST: 'GET_GATEWAY_REQUEST',
  GET_GATEWAY_SUCCESS: 'GET_GATEWAY_SUCCESS',
  GET_GATEWAY_FAILURE: 'GET_GATEWAY_FAILURE',

  GET_METER_REQUEST: 'GET_METER_REQUEST',
  GET_METER_SUCCESS: 'GET_METER_SUCCESS',
  GET_METER_FAILURE: 'GET_METER_FAILURE',

  CLEAR: 'TELE_RELEVE_CLEAR',
};

export default meterGatewayConstants;
