import { authHeader, handleResponse } from '../_helpers';

async function getAll(siteId: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/virtualmeter/list/${siteId}`, requestOptions);
  return handleResponse(response);
}

async function getAllWithChildren(siteId: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/virtualmeter/list/${siteId}/children`, requestOptions);
  return handleResponse(response);
}

async function create(body: Object) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };
  let response = await fetch('/api/virtualmeter/', requestOptions);
  return handleResponse(response);
}

async function edit(id: number, body: Object) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  };
  let response = await fetch(`/api/virtualmeter/${id}`, requestOptions);
  return handleResponse(response);
}

async function remove(id: number, locationId: number) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };
  let response = await fetch(`/api/virtualmeter/${id}/${locationId}`, requestOptions);
  return handleResponse(response);
}

async function getInfos(siteId: number, id: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/virtualmeter/${siteId}/infos${id}`, requestOptions);
  return handleResponse(response);
}

async function getLastReads(siteId: number, id: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/virtualmeter/${siteId}/lastread${id}`, requestOptions);
  return handleResponse(response);
}

async function getReads(siteId: number, content: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(content),
  };
  let response = await fetch(`/api/virtualmeter/${siteId}/reads`, requestOptions);
  return handleResponse(response);
}

async function getConsumptions(siteId: number, content: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(content),
  };
  let response = await fetch(`/api/virtualmeter/${siteId}/consumptions`, requestOptions);
  return handleResponse(response);
}

async function getAlarms(siteId: number, content: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(content),
  };
  let response = await fetch(`/api/virtualmeter/${siteId}/alarms`, requestOptions);
  return handleResponse(response);
}

const vmeterService = {
  getAll,
  getAllWithChildren,
  getInfos,
  getReads,
  getConsumptions,
  getAlarms,
  create,
  edit,
  remove,
  getLastReads,
};

export default vmeterService;
