const widgetConstants = {
  GETALL_REQUEST: 'WIDGETS_GETALL_REQUEST',
  GETALL_SUCCESS: 'WIDGETS_GETALL_SUCCESS',
  GETALL_FAILURE: 'WIDGETS_GETALL_FAILURE',

  GET_REQUEST: 'WIDGETS_GET_REQUEST',
  GET_SUCCESS: 'WIDGETS_GET_SUCCESS',
  GET_FAILURE: 'WIDGETS_GET_FAILURE',

  GET_DATA_REQUEST: 'WIDGETS_GET_DATA_REQUEST',
  GET_DATA_SUCCESS: 'WIDGETS_GET_DATA_SUCCESS',
  GET_DATA_FAILURE: 'WIDGETS_GET_DATA_FAILURE',

  SAVE_REQUEST: 'WIDGETS_SAVE_REQUEST',
  SAVE_SUCCESS: 'WIDGETS_SAVE_SUCCESS',
  SAVE_FAILURE: 'WIDGETS_SAVE_FAILURE',

  UPDATE_REQUEST: 'WIDGETS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'WIDGETS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'WIDGETS_UPDATE_FAILURE',

  DELETE_REQUEST: 'WIDGETS_DELETE_REQUEST',
  DELETE_SUCCESS: 'WIDGETS_DELETE_SUCCESS',
  DELETE_FAILURE: 'WIDGETS_DELETE_FAILURE',

  GETCONTENT_REQUEST: 'WIDGETS_GETCONTENT_REQUEST',
  GETCONTENT_SUCCESS: 'WIDGETS_GETCONTENT_SUCCESS',
  GETCONTENT_FAILURE: 'WIDGETS_GETCONTENT_FAILURE',

  CLEAR: 'WIDGETS_CLEAR',

  READINDEXWIDGET: 'METER_INDEX',
  READCONSUMTIONWIDGET: 'METER_CONSO',
  READRADIOWIDGET: 'RADIO_INDEX',
  ALARMWIDGET: 'ALARM',
  VIRTUALALARMWIDGET: 'VIRTUAL_ALARM',
  MULTIREADINDEXWIDGET: 'MULTI_INDEX',
  MULTIREADCONSOWIDGET: 'MULTI_CONSO',
  METERYIELDWIDGET: 'METER_YIELD',

  GET_PREVISU_DATA_REQUEST: 'GET_PREVISU_DATA_REQUEST',
  GET_PREVISU_DATA_SUCCESS: 'GET_PREVISU_DATA_SUCCESS',
  GET_PREVISU_DATA_FAILURE: 'GET_PREVISU_DATA_FAILURE',
};

export default widgetConstants;
