import alertConstants from '../_constants/alert.constants';
import { action } from '../_interfaces/action';

export default function alert(state = {}, action: action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'alert-success',
        message: action.message,
      };
    case alertConstants.ERROR:
      return {
        type: 'alert-danger',
        message: action.message,
      };
    case alertConstants.CONFIG_ALERT_SUCCESS:
      return {
        selectedAlert: action.name,
      };
    case alertConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
