import React from 'react';

const SvgMarquagesVert = (props: any) => (
  <svg viewBox="0 0 16.72 37.62" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M10.73,10.25H6a6,6,0,0,0-6,6v7.54A1.2,1.2,0,0,0,1.2,25H2.63L7.16,36.5c.32.57.57,1.12,1.2,1.12h0c.63,0,.95-.47,1.19-1.12L14.09,25h1.43a1.2,1.2,0,0,0,1.2-1.2V16.24A6,6,0,0,0,10.73,10.25Zm3.59,12.33H13a1.2,1.2,0,0,0-1.2,1.12L8.34,35.23h0L4.94,23.7a1.19,1.19,0,0,0-1.19-1.12H2.39V16.24A3.59,3.59,0,0,1,6,12.65h4.75a3.59,3.59,0,0,1,3.59,3.59v6.34Z"
        />
        <path
          fill={props.fill}
          d="M8.34,0a4.79,4.79,0,1,0,4.79,4.79A4.8,4.8,0,0,0,8.34,0Zm0,7.18a2.4,2.4,0,1,1,2.39-2.39A2.4,2.4,0,0,1,8.34,7.18Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgMarquagesVert;
