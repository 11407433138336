import React from 'react';
import FluidEau from '../SvgComponents/FluidEau';
import FluidEauFroide from '../SvgComponents/FluidEauFroide';
import FluidGaz from '../SvgComponents/FluidGaz';
import FluidAutre from '../SvgComponents/FluidAutre';
import FluidThermique from '../SvgComponents/FluidThermique';
import FluidElectricite from '../SvgComponents/FluidElectricite';
import FluidEauChaude from '../SvgComponents/FluidEauChaude';

export default function(fluidCode: string, props: any) {
  switch (fluidCode) {
    case 'W':
      return <FluidEau {...props} />;
    case 'C':
      return <FluidEauFroide {...props} />;
    case 'H':
      return <FluidEauChaude {...props} />;
    case 'E':
      return <FluidElectricite {...props} />;
    case 'G':
      return <FluidGaz {...props} />;
    case 'T':
      return <FluidThermique {...props} />;
    default:
    case 'O':
      return <FluidAutre {...props} />;
  }
}
