const etatFiche = [
  {
    picto: 'red_manual',
    label: 'Non indexé (relève manuel)',
  },
  {
    picto: 'red_radio',
    label: 'Non indexé (relève radio)',
  },
  {
    picto: 'yellow_manual',
    label: 'Modifié (relève manuel)',
  },
  {
    picto: 'yellow_radio',
    label: 'Modifié (relève radio)',
  },
  {
    picto: 'green_manual',
    label: 'Indéxé (relève manuel) sans radio',
  },
  {
    picto: 'green_radio',
    label: 'Indéxé (relève manuel) avec radio',
  },
  {
    picto: 'blue_radio',
    label: 'Indéxé (relève radio)',
  },
  {
    picto: 'blue_telereleve',
    label: 'Indéxé (télérelève)',
  },
  {
    picto: 'purple_radio',
    label: 'Indéxé avec alarme (relève radio)',
  },
  {
    picto: 'purple_telereleve',
    label: 'Indéxé avec alarme (télérelève)',
  },
];

const consoState = [
  {
    label: 'Normale',
    value: '0',
  },
  {
    label: 'Faible',
    value: '1',
  },
  {
    label: 'Nulle',
    value: '2',
  },
  {
    label: 'Forte',
    value: '3',
  },
];

const fluidType = [
  {
    label: 'Eau',
    value: 'W',
  },
  {
    label: 'Eau froide',
    value: 'C',
  },
  {
    label: 'Eau chaude',
    value: 'H',
  },
  {
    label: 'Electricité',
    value: 'E',
  },
  {
    label: 'Gaz',
    value: 'G',
  },
  {
    label: 'Thermique',
    value: 'T',
  },
  {
    label: 'Autre',
    value: 'O',
  },
];

const readMethod = [
  {
    label: 'Manuelle',
    value: 'M',
  },
  {
    label: 'Radio',
    value: 'A',
  },
  {
    label: 'Télérelève',
    value: 'T',
  },
];

export default { consoState, etatFiche, fluidType, readMethod };
