import { authHeader, handleResponse } from '../_helpers';
import { Location, LocationContent, LocationLight } from '../_entities/location';

async function getAll(root: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/location/locations?root=${root}`, requestOptions);
  return handleResponse(response);
}

async function getAllFromSeed(id: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/location/${id}/children`, requestOptions);
  return handleResponse(response);
}

async function get(id: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/location/${id}/`, requestOptions);
  return handleResponse(response);
}

async function getInfos(id: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/location/infos/${id}/`, requestOptions);

  return handleResponse(response);
}

async function getContacts() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/location/contacts/`, requestOptions);
  return handleResponse(response);
}

async function save(location: LocationLight) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(location),
  };
  let response = await fetch('/api/location/', requestOptions);
  return handleResponse(response);
}

async function update(content: LocationContent) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(content),
  };
  let response = await fetch(`/api/location/`, requestOptions);
  return handleResponse(response);
}

async function toDelete(code: string) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };
  let response = await fetch(`/api/location/${code}`, requestOptions);
  return handleResponse(response);
}

const locationService = {
  getAll,
  getAllFromSeed,
  get,
  getInfos,
  update,
  toDelete,
  save,
  getContacts,
};

export default locationService;
