import React from 'react';

const SvgInfoPdi = (props: any) => (
  <svg viewBox="0 0 126.82 146.15" {...props}>
    <g>
      <path
        fill={props.fill}
        d="M87.73,93.06c0.27-1.34,0.41-2.57,0.41-3.65c0-0.41-0.02-0.73-0.04-0.98c-0.41-0.06-1.01-0.11-1.84-0.11
		c-0.91,0-1.91,0.16-2.95,0.46c-1.36,0.4-2.61,0.81-3.71,1.22L69,93.92l2.69-10.97l1.56-6.38l0.73-2.97l2.84-1.15
		c0.9-0.37,1.79-0.72,2.68-1.06V56.19c2.77-2.68,4.38-6.35,4.38-10.29V24.07C83.88,10.78,73.06,0,59.81,0h-4.7
		C41.83,0,31.05,10.83,31.05,24.07V45.9c0,3.94,1.61,7.61,4.38,10.29v16.82C30.2,75.7,17.4,82.68,5.46,92.52
		C1.97,95.38,0,99.59,0,104.11v14.94c0,2.46,1.97,4.43,4.43,4.43h75.29c0.24-1.33,0.53-2.75,0.9-4.3l0.03-0.12l0.03-0.12l5.83-20.63
		C87.01,96.4,87.41,94.68,87.73,93.06z"
      />
      <path
        fill={props.fill}
        d="M112.94,60.58c3.82,0,7.09-1.27,9.8-3.8c2.72-2.52,4.07-5.59,4.07-9.18c0-3.58-1.35-6.65-4.07-9.21
		c-2.71-2.55-5.98-3.82-9.8-3.82c-3.83,0-7.12,1.27-9.85,3.82c-2.74,2.55-4.11,5.62-4.11,9.21c0,3.59,1.37,6.66,4.11,9.18
		C105.83,59.31,109.11,60.58,112.94,60.58z"
      />
      <path
        fill={props.fill}
        d="M116.64,136.16c-3.01,0-5.14-0.49-6.37-1.49c-1.22-0.99-1.83-2.86-1.83-5.6c0-1.08,0.18-2.7,0.57-4.81
		c0.05-0.27,0.1-0.52,0.15-0.78c0.34-1.81,0.72-3.45,1.13-4.89l4.56-16.14l1.26-4.47c0.57-1.89,0.96-3.97,1.17-6.24
		c0.22-2.26,0.31-3.85,0.31-4.75c0-4.35-1.52-7.87-4.57-10.59c-3.05-2.72-7.39-4.08-13.01-4.08c-3.13,0-6.44,0.56-9.94,1.67
		c-1.74,0.55-3.52,1.16-5.35,1.82c-1.84,0.67-3.72,1.4-5.64,2.18l-1.56,6.38c1.14-0.42,2.5-0.88,4.09-1.35
		c1.58-0.47,3.14-0.71,4.65-0.71c3.08,0,5.16,0.53,6.25,1.56c1.09,1.04,1.64,2.89,1.64,5.53c0,1.46-0.17,3.08-0.53,4.85
		c-0.35,1.78-0.8,3.65-1.32,5.63l-5.84,20.69c-0.24,1.02-0.45,1.98-0.64,2.9c-0.21,1.04-0.38,2.03-0.5,2.95
		c-0.24,1.73-0.35,3.42-0.35,5.06c0,4.25,1.57,7.76,4.71,10.52c3.14,2.75,7.54,4.14,13.21,4.14c3.69,0,6.92-0.48,9.71-1.45
		c2.78-0.97,6.52-2.37,11.2-4.22l1.56-6.37c-0.81,0.38-2.11,0.81-3.89,1.31C119.66,135.91,118.05,136.16,116.64,136.16z"
      />
    </g>
  </svg>
);

export default SvgInfoPdi;
