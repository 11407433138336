import React from 'react';

const SvgInformationsBleu = (props: any) => (
  <svg viewBox="0 0 35 35" {...props}>
    <g id="informations_bleu_svg__Calque_2" data-name="Calque 2">
      <g id="informations_bleu_svg__Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          d="M15.52 11.42a1.9 1.9 0 0 0 .19.35c.06.1.16.2.22.29a3.09 3.09 0 0 0 .32.29 1.18 1.18 0 0 0 .39.19 1.23 1.23 0 0 0 .41.13 2.13 2.13 0 0 0 .42 0 1.84 1.84 0 0 0 .41 0 1 1 0 0 0 .36-.13 2.48 2.48 0 0 0 .38-.22 2.26 2.26 0 0 0 .29-.26 1.94 1.94 0 0 0 .23-.29 1.2 1.2 0 0 0 .16-.32v-.06a3.38 3.38 0 0 0 .13-.42 1.93 1.93 0 0 0 0-.41 2 2 0 0 0 0-.42c0-.13-.07-.26-.13-.42a1.92 1.92 0 0 0-.3-.6l-.06-.07a3 3 0 0 0-.32-.25c-.13-.07-.22-.13-.35-.19a1.49 1.49 0 0 0-.39-.13 2 2 0 0 0-.41 0 2 2 0 0 0-.42 0 1.43 1.43 0 0 0-.38.13 2.42 2.42 0 0 0-.36.19c-.12.09-.22.16-.32.25a3 3 0 0 0-.25.29 2.89 2.89 0 0 0-.19.35 1.49 1.49 0 0 0-.13.39 2.08 2.08 0 0 0 0 .41 2 2 0 0 0 0 .42 2.15 2.15 0 0 0 .1.51zM20 23.13h-.73v-7.84a.82.82 0 0 0-.83-.83H15a.83.83 0 0 0-.84.83V17a.83.83 0 0 0 .84.83h.67v5.31H15a.83.83 0 0 0-.84.83v1.73a.83.83 0 0 0 .84.83h5a.83.83 0 0 0 .84-.83V24a.86.86 0 0 0-.84-.87z"
        />
        <path
          fill={props.fill}
          d="M17.5 0A17.5 17.5 0 1 0 35 17.5 17.51 17.51 0 0 0 17.5 0zm0 32.7a15.2 15.2 0 1 1 15.2-15.2 15.22 15.22 0 0 1-15.2 15.2z"
        />
      </g>
    </g>
  </svg>
);

export default SvgInformationsBleu;
