import { authHeader, handleResponse } from '../_helpers';
import { Setting } from '../_entities/settings';

const uri = '/api/setting';
const type = 'PDI_COL_FILTER';

async function getAllByType(userId: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let response = await fetch(`${uri}/user/${userId}/type/${type}`, requestOptions);
  return handleResponse(response);
}

async function save(columnConfig: Setting, userId: number) {
  const toSave = columnConfig;
  toSave.type = type;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(toSave),
  };
  let response = await fetch(`${uri}/user/${userId}/type/${type}`, requestOptions);
  return handleResponse(response);
}

async function deleteColumn(idConfig: number, userId: number) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };
  let response = await fetch(`${uri}/user/${userId}/type/${type}/${idConfig}`, requestOptions);
  return handleResponse(response);
}

async function update(columnConfig: Setting, idConfig: number, userId: number) {
  columnConfig.type = type;
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(columnConfig),
  };
  let response = await fetch(`${uri}/user/${userId}/type/${type}/${idConfig}`, requestOptions);
  return handleResponse(response);
}

const filtreService = {
  getAllByType,
  save,
  deleteColumn,
  update,
};
export default filtreService;
