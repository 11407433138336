import React from 'react';

const SvgAjoutBleu = (props: any) => (
  <svg viewBox="0 0 109.62 61.78" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="pictos">
        <path
          fill={props.fill}
          d="M0,7.45A7.45,7.45,0,0,1,12.69,2.16L54.81,43.84,96.93,2.16a7.45,7.45,0,0,1,10.48,10.59L60.05,59.62a7.44,7.44,0,0,1-10.48,0L2.21,12.75A7.45,7.45,0,0,1,0,7.45Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgAjoutBleu;
