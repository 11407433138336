import React, { Component } from 'react';
import Switch from 'react-switch';
import _ from 'lodash';

export default class ProgressBar extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { checked: false };
  }

  render() {
    const { progressWidth, color } = this.props;
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="progress-bar-wrapper" style={{ width: '100%', height: '22px' }} />
        <div
          className="progress-bar-content"
          style={{
            height: '18px',
            width: `calc(${progressWidth}% - 4px)`,
            backgroundColor: color,
            position: 'absolute',
            marginLeft: '2px',
          }}
        />
      </div>
    );
  }
}
