import React, { Component } from 'react';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import _ from 'lodash';
import moment from 'moment';
import { formatAlarmData } from '../_helpers/alarms-types-helper';
import YieldChart from './YieldChart';
import { withTranslation } from 'react-i18next';

interface Props {
  type: string;
  data: any;
  chartStyle?: any;
  zoom: string;
  widgetType: string;
  displayMasked: boolean;
  containerId: any;
  t: Function;
}

const colorList = ['#31c6b3', '#0CD7FC', '#0F82FF', '#6014FF'];
const hoverColorList = ['#34827a', '#09A3BF', '#0B5EB8', '#470FBD'];

class WidgetChart extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.optionGenerator = this.optionGenerator.bind(this);
    this.dataGenerator = this.dataGenerator.bind(this);
    this.chartSelector = this.chartSelector.bind(this);
  }

  chartSelector() {
    const { type, widgetType, data, containerId } = this.props;
    const options: any = this.optionGenerator();
    const dataGen = this.dataGenerator();
    switch (type) {
      case 'Histogram':
      case 'StackedHistogram':
        return <Bar data={dataGen} options={options} />;
      case 'Curve':
        if (widgetType === 'METER_YIELD') {
          const yieldData = _.entries(data).map((el: any) => _.entries(el[1]).map((conso: any) => conso[1]));
          return (
            <YieldChart
              data={yieldData}
              labels={dataGen.labels}
              containerId={containerId}
              meters={dataGen.datasets.map((el: any) => el.label)}
            />
          );
        } else {
          return <Line data={dataGen} options={options} />;
        }
      case 'doughnut':
        return <Doughnut data={dataGen} />;
    }
  }

  dataGenerator() {
    const { type, data, chartStyle, zoom, widgetType, locales, colors, displayMasked } = this.props;
    const datasets: any = [];
    // @ts-ignore
    let labels: any = _.entries(data).map(el => _.keys(el[1]));
    labels = _.uniq(_.concat(labels[0], labels[1])).filter(el => el !== undefined);
    switch (type) {
      case 'StackedHistogram':
        const formatAlarm = formatAlarmData(data && data.data, locales, colors, displayMasked);
        labels = formatAlarm.labels;
        formatAlarm.values.map((el: any, index: number) => {
          datasets.push({
            label: el.label,
            backgroundColor: el.color,
            borderColor: el.color,
            borderWidth: chartStyle.borderWidth ? chartStyle.borderWidth : 1,
            hoverBackgroundColor: el.color,
            hoverBorderColor: el.color,
            data: el.data,
          });
        });
        break;
      case 'Histogram' || 'StackedHistogram':
        _.entries(data).map((el: any, index: number) => {
          datasets.push({
            label: el[0],
            backgroundColor: colorList[index],
            borderColor: hoverColorList[index],
            borderWidth: chartStyle.borderWidth ? chartStyle.borderWidth : 1,
            hoverBackgroundColor: hoverColorList[index],
            hoverBorderColor: colorList[index],
            data: labels.map((date: any) => {
              return _.entries(el[1]).find(element => element[0] === date) ? el[1][date] : 0;
            }),
          });
        });
        break;
      case 'Curve':
        _.entries(data).forEach((el: any, index: number) => {
          datasets.push({
            label: el[0],
            fill: false,
            lineTension: 0.1,
            backgroundColor: colorList[index],
            borderColor: hoverColorList[index],
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: hoverColorList[index],
            pointBackgroundColor: colorList[index],
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: hoverColorList[index],
            pointHoverBorderColor: colorList[index],
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: _.values(el[1]),
          });
        });
        break;
      case 'dougnut':
        _.entries(data).map((el: any) => {
          datasets.push({
            data: el.data,
            backgroundColor: chartStyle.backgroundColor
              ? chartStyle.backgroundColor
              : ['#FF6384', '#36A2EB', '#FFCE56'],
            hoverBackgroundColor: chartStyle.borderColor ? chartStyle.borderColor : ['#FF6384', '#36A2EB', '#FFCE56'],
          });
        });
        break;
      default:
    }
    switch (zoom) {
      case 'Hour':
        labels = labels.map((el: any) => moment(el).format('DD/MM/YYYY HH:mm'));
        break;
      case 'Day':
        labels = labels.map((el: any) => moment(el).format('DD/MM/YYYY'));
        break;
      case 'Week':
        labels = labels.map((el: any) => moment(el).format('DD/MM/YYYY'));
        break;
      case 'Month':
        labels = labels.map((el: any) => moment(el).format('MM/YYYY'));
        break;
      case 'Year':
        labels = labels.map((el: any) => moment(el).format('YYYY'));
        break;
      default:
    }
    return {
      labels: _.cloneDeep(labels),
      datasets: _.cloneDeep(datasets),
    };
  }

  optionGenerator() {
    const { type, widgetType, t } = this.props;
    const options: any = {
      plugins: {
        t1: false,
        datalabels: false,
      },
      emptyOverlay: {
        message: t('all.text.no_data_available'),
      },
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        yAxes: [
          {
            stacked: type === 'StackedHistogram',
            ticks: {
              beginAtZero: true,
              display: widgetType !== 'ALARM',
            },
          },
        ],
        xAxes: [
          {
            stacked: type === 'StackedHistogram',
          },
        ],
      },
    };
    return options;
  }

  render() {
    const chart = this.chartSelector();
    return <div style={{ width: '100%', height: '100%' }}>{chart}</div>;
  }
}

WidgetChart.defaultProps = {
  chartStyle: {},
};

const tr = withTranslation()(WidgetChart);
export default tr;
