import React from 'react';
import { Button, Input, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import NoPresent from '../Message/NoPresent';
import Picto from './Picto_manager';
import Lock from '../SvgComponents/Lock';
import SvgEditionBleu from '../SvgComponents/EditionBleu';
import { gestionnaireActions } from '../_actions';

type Props = {
  manager: {
    id: number;
    name: string;
    nbRound: number;
    nbPhone: number;
  };
  enabled: boolean;
};

class ManagerRoundUnit extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  generateLineInfo(info: any, title: string, errorMsg: string, imgName: string) {
    return (
      <div
        className="row meterInfo"
        style={{ marginLeft: '10px', maxWidth: '90%', display: 'flex', flexDirection: 'row' }}
      >
        <div className=" picto" style={{ marginTop: '10px', padding: '0 15px' }}>
          {Picto(imgName) ? (
            Picto(imgName, {
              style: { marginRight: '10px' },
              height: '2em',
              width: '2em',
              stroke: '#31c6b3',
              fill: '#31c6b3',
              strokeWidth: '0',
            })
          ) : (
            <div style={{ width: '35.5px' }} />
          )}{' '}
        </div>
        <div className="" style={{ flex: 1, padding: '0 15px' }}>
          <p>
            <span className="infoLibelle">{title}</span> <br />
            {info ? (
              <div className="infoDisplay" style={{ overflowWrap: 'break-word' }}>
                {info}{' '}
              </div>
            ) : (
              <NoPresent msg={errorMsg} />
            )}
          </p>
        </div>
      </div>
    );
  }

  isDioptase = () => {
    const { user } = this.props;
    console.log('ManagerRoundUnit::isDioptase::68', user && user.role.name === 'DIOPTASE');
    return user && user.role.name === 'DIOPTASE';
  };

  render() {
    const { manager, enabled } = this.props;
    return (
      <div
        className="presentation-container manager"
        style={{ margin: 0, height: '100%', border: '1px solid lightgray' }}
      >
        <div className="presentation-header" style={{ margin: 0, padding: '10px', backgroundSize: '800px' }}>
          <span className="presentation-main-title" style={{ margin: 0 }}>
            <h3 style={{ paddingRight: 0, margin: 0, wordBreak: 'break-word' }}>{manager.name}</h3>
            <p style={{ textAlign: 'center' }}>
              {this.isDioptase() ? `Numéro de site : ${manager.siteId}` : ''}
              <span
                onClick={e => {
                  e.stopPropagation();
                  console.log('ManagerRoundUnit::yes::98');
                  this.setState({ nameGestionnaire: manager.name, numeroGestionnaire: manager.siteId });
                }}
                id={`change_site_${manager.id}`}
                className="round clickable"
                style={{ marginLeft: '20px' }}
              >
                {' '}
                <SvgEditionBleu height="1em" />
              </span>
              <UncontrolledPopover
                onClick={e => e.stopPropagation()}
                placement="bottom"
                target={`change_site_${manager.id}`}
              >
                <PopoverHeader>Modification gestionnaire</PopoverHeader>
                <PopoverBody style={{ display: 'grid' }}>
                  Nom gestionnaire
                  <Input
                    value={this.state.nameGestionnaire}
                    onChange={e => this.setState({ nameGestionnaire: e.target.value })}
                  />
                  Numero de site
                  <Input
                    value={this.state.numeroGestionnaire}
                    type="number"
                    onChange={e => this.setState({ numeroGestionnaire: e.target.value })}
                  />
                  <Button
                    onClick={e => {
                      const { dispatch } = this.props;
                      const { numeroGestionnaire, nameGestionnaire } = this.state;
                      e.stopPropagation();
                      dispatch(
                        gestionnaireActions.updateGestionnaireSiteId(manager.id, nameGestionnaire, numeroGestionnaire)
                      );
                    }}
                  >
                    Mettre à jour
                  </Button>
                </PopoverBody>
              </UncontrolledPopover>
            </p>
          </span>
        </div>
        <div className="presentation-body" style={{ paddingBottom: 0, borderRadius: '0 0 10px 10px', display: 'flex' }}>
          <div className="display-info" style={{ marginTop: 0, paddingTop: '20px', width: '50%' }}>
            {this.generateLineInfo(manager.nbRound, 'Nombre de tournée', 'Aucune tournée', 'round')}
            {this.generateLineInfo(manager.nbPhone, 'Nombre de portable', 'Aucun téléphone', 'phone')}
          </div>
          {!enabled && (
            <div style={{ width: '50%' }}>
              <span style={{ display: 'block', textAlign: 'center', marginTop: '10%' }}>Accès non autorisé</span>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60%' }}>
                <Lock height="5em" fill="#333" />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user,
  };
}

export default withTranslation()(connect(mapStateToProps)(ManagerRoundUnit));
