import { authHeader, handleResponse } from '../_helpers';
import { CrystalPermissionDto } from '../_entities/permission';

async function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/profil/all`, requestOptions);
  return handleResponse(response);
}

async function getAllTypePerm() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/profil/type`, requestOptions);
  return handleResponse(response);
}

async function getVersion() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const response = await fetch('/api/application/version', requestOptions);
  return response.text();
}

async function getDefaultLanguage() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const response = await fetch('/api/application/defaultLanguage', requestOptions);
  return response.text();
}

async function addProfil(profil: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(profil),
  };
  let response = await fetch(`/api/profil/add`, requestOptions);
  return handleResponse(response);
}

async function deleteProfil(id: any) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };
  let response = await fetch(`/api/profil/delete/${id}`, requestOptions);
  return handleResponse(response);
}

async function addProfilToUser(links: any, userId: number) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(links),
  };
  let response = await fetch(`/api/profil/add/${userId}`, requestOptions);
  return handleResponse(response);
}

async function deleteProfilLinks(locationsId: any, userId: number) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    body: JSON.stringify(locationsId),
  };
  let response = await fetch(`/api/profil/delete/links/${userId}`, requestOptions);
  return handleResponse(response);
}

async function addGestionnairePermission(gestionnaires: Array<any>) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(gestionnaires),
  };
  let response = await fetch(`/api/permission/gestionnaire`, requestOptions);
  return handleResponse(response);
}

async function deleteGestionnairePermission(gestionnaires: any) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    body: JSON.stringify(gestionnaires),
  };
  let response = await fetch(`/api/permission/gestionnaire`, requestOptions);
  return handleResponse(response);
}

async function switchGestionnaireDevicePermission(gestionnaires: any) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(gestionnaires),
  };
  let response = await fetch(`/api/permission/gestionnaire`, requestOptions);
  return handleResponse(response);
}

const profilService = {
  getVersion,
  getAll,
  getAllTypePerm,
  addProfil,
  deleteProfil,
  addProfilToUser,
  deleteProfilLinks,
  addGestionnairePermission,
  deleteGestionnairePermission,
  switchGestionnaireDevicePermission,
  getDefaultLanguage,
};
export default profilService;
