import React from 'react';

const Copy = (props: any) => (
  <svg viewBox="0 0 200 200" {...props}>
    <g id="Calque_2" />
    <g id="Calque_1">
      <g>
        <path
          fill={props.fill}
          d="M137,44.8H78.1c-4.3,0-7.8,3.5-7.8,7.8v9.4H63c-4.3,0-7.8,3.5-7.8,7.8v77.7c0,4.3,3.5,7.8,7.8,7.8h58.9
			c4.3,0,7.8-3.5,7.8-7.8v-9.4h7.3c4.3,0,7.8-3.5,7.8-7.8V52.6C144.8,48.3,141.3,44.8,137,44.8z M123,147.4c0,0.6-0.5,1.1-1.1,1.1
			H63c-0.6,0-1.1-0.5-1.1-1.1V69.7c0-0.6,0.5-1.1,1.1-1.1h58.9c0.6,0,1.1,0.5,1.1,1.1V147.4z M138.1,130.3c0,0.6-0.5,1.1-1.1,1.1
			h-7.3V69.7c0-4.3-3.5-7.8-7.8-7.8H77v-9.4c0-0.6,0.5-1.1,1.1-1.1H137c0.6,0,1.1,0.5,1.1,1.1V130.3z"
        />
        <path
          fill={props.fill}
          d="M111.4,79.2h-38c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3h38c1.8,0,3.3-1.5,3.3-3.3
			C114.8,80.7,113.3,79.2,111.4,79.2z"
        />
        <path
          fill={props.fill}
          d="M111.4,94.2h-38c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3h38c1.8,0,3.3-1.5,3.3-3.3
			C114.8,95.7,113.3,94.2,111.4,94.2z"
        />
        <path
          fill={props.fill}
          d="M111.4,109.3h-38c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3h38c1.8,0,3.3-1.5,3.3-3.3
			C114.8,110.8,113.3,109.3,111.4,109.3z"
        />
        <path
          fill={props.fill}
          d="M111.4,124.3h-38c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3h38c1.8,0,3.3-1.5,3.3-3.3
			C114.8,125.8,113.3,124.3,111.4,124.3z"
        />
      </g>
    </g>
  </svg>
);
export default Copy;
