import { localeConstants } from '../_constants';
import Locale from '../_shared/Locale.json';

/**
 * Charge les traductions
 *
 * @method load
 * @returns {Object} Le fichier de trad
 */
function load() {
  return {
    type: localeConstants.LOADED,
    locale: Locale,
  };
}

const localeActions = {
  load,
};

export default localeActions;
