import React, { Component } from 'react';
import { UncontrolledTooltip, Row, Col } from 'reactstrap';
import PoubelleBleue from '../SvgComponents/PoubelleBleu';
import HistoriqueVert from '../SvgComponents/HistoriqueVert';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import moment from 'moment';
import { locales } from '../_interfaces/reducers';
import { localeActions, notificationActions, alertActions } from '../_actions';
import confirm from '../_components';
import Alerte from '../SvgComponents/AlarmeVert';
import { notificationService } from '../_services';
import { SingleNotification } from '.';
import { Notification } from '../_entities/notification';
import { cloneDeep } from 'lodash';
import LoadingBand from '../Bands/Loading';
import ErrorBand from '../Bands/Error';
import SvgEye from '../SvgComponents/Eye';
import { withTranslation } from 'react-i18next';

interface Props extends React.Props<any> {
  notifications: any;
  location: any;
  match: any;
  locales: locales;
  dispatch: Function;
  alert: any;
  user: any;
  history: any;
}

interface State {
  isOpen: boolean;
  activeNotif: any;
  selected: Notification[];
  notifKey: number;
}

/**
 * @class Notifications
 * @extends {Component}
 */
class Notifications extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      activeNotif: {},
      selected: [],
      notifKey: Math.floor(Math.random() * Math.floor(1024)),
    };
  }

  componentDidMount() {
    const { dispatch, user } = this.props;

    dispatch(localeActions.load());
    dispatch(notificationActions.getAll(user.id));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(notificationActions.clear());
    dispatch(alertActions.clear());
  }

  /**
   * Applique du bold sur la type dans le cas
   * d'une notification non lue
   *
   * @method checkStateForStyle
   * @param {any} cell      Cellule
   * @param {any} row       Ligne
   * @param {any} rowIndex  Index de la ligne
   * @param {any} colIndex  Index de la colonne
   */
  checkStateForStyle = (cell: any, row: any, rowIndex: any, colIndex: any) => {
    if (!row.state) {
      return {
        fontWeight: 'bold',
      };
    }
    return {
      fontWeight: 'regular',
    };
  };

  /**
   * Formate la date de réception de la
   * notification dans le tableau
   *
   * @method formatDate
   * @param {any} cell  Cellule
   * @param {any} row   Ligne
   * @returns {JSX} La date
   */
  formatDate = (cell: any, row: any) => {
    return (
      <span>{moment(cell).isValid() ? moment(cell).format(`DD/MM/YYYY ${moment.HTML5_FMT.TIME_SECONDS}`) : ''}</span>
    );
  };

  /**
   * Permet d'afficher seulement les 50 premiers
   * caractères du contenu en cas de dépassement
   *
   * @method getExcerpt
   * @param {any} cell Cellule
   * @returns {JSX} Le contenu
   *
   */
  getExcerpt = (cell: any, row: any) => {
    const limit = 50;
    cell = null !== cell ? cell : '';
    const excerpt = cell.length > limit ? `${cell.substring(0, limit - 1)}...` : cell;

    return <span>{excerpt}</span>;
  };

  /**
   * Affiche la modal d'édition du dashboard
   *
   * @method edit
   * @memberof DisplayDashBoardsComponent
   */
  show = (e: Object, activeNotif: any) => {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
    const { isOpen } = this.state;

    this.setState({
      activeNotif,
      isOpen: !isOpen,
    });
  };

  async delete(e: any, notif: Notification) {
    const { dispatch, t } = this.props;
    const { Fragment } = React;
    let { selected } = this.state;

    const result = await confirm({
      title: (
        <Fragment>
          <strong>{t('notifications.popup_delete_title.delete_notification')}</strong>
        </Fragment>
      ),
      message: t('notifications.popup_delete_msg.sure_delete_notification'),
      confirmText: t('all.button.delete'),
      confirmColor: 'danger',
      cancelColor: 'primary',
      cancelText: t('all.button.cancel'),
    });
    if (result) {
      let finded: any = selected.find(it => it.id === notif.id);
      if (undefined !== finded) {
        selected = selected.filter(it => it.id !== finded.id);
      }
      dispatch(notificationActions.toDelete(notif.id));
      this.setState({
        notifKey: Math.floor(Math.random() * Math.floor(1024)),
        selected,
      });
    }
  }

  async deleteMulti(e: any) {
    const { dispatch, t } = this.props;
    const { selected } = this.state;
    const { Fragment } = React;

    const result = await confirm({
      title: (
        <Fragment>
          <strong>{t('notifications.popup_delete_title.delete_selected_notifications')}</strong>
        </Fragment>
      ),
      message: t('notifications.popup_delete_msg.sure_delete_all_notifications'),
      confirmText: t('all.button.delete'),
      confirmColor: 'danger',
      cancelColor: 'primary',
      cancelText: t('all.button.cancel'),
    });
    if (result) {
      const notifsIds: number[] = selected.map(it => it.id);
      dispatch(notificationActions.deleteMulti(notifsIds));
      this.setState({
        notifKey: Math.floor(Math.random() * Math.floor(1024)),
        selected: [],
      });
    }
  }

  /**
   * Met à jour l'état de la notification à
   * l'ouverture
   *
   * @method updateNotif
   * @param {any} notification Notification
   */
  updateNotif = (notification: any) => {
    const { dispatch, user } = this.props;
    notificationService.update(notification).then(() => {
      dispatch(notificationActions.getAll(user.id));
    });
  };

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof Notifications
   */
  render() {
    const { notifications, dispatch, t } = this.props;
    const { isOpen, activeNotif, notifKey, selected } = this.state;
    const columns = [
      {
        dataField: 'createdAt',
        text: t('all.read_meter.read_last_date'),
        style: this.checkStateForStyle,
        formatter: this.formatDate,
      },
      {
        dataField: 'title',
        text: t('all.text.title'),
        style: this.checkStateForStyle,
      },
      {
        dataField: 'action',
        isDummyField: true,
        align: 'center',
        headerStyle: () => ({ width: '120px' }),
        text: '',
        formatter: (cellContent: any, row: any) => (
          <Row>
            <Col xs="6">
              <div
                id={`notif${row.id}-read`}
                className="clickable round"
                role="presentation"
                style={{ backgroundColor: !row.state && '#31c6b3' }}
                onClick={e => {
                  if (!row.state) {
                    row.state = true;
                    dispatch(notificationActions.update(row));
                  }
                  this.setState({
                    activeNotif: row,
                    isOpen: true,
                    notifKey: Math.floor(Math.random() * Math.floor(1024)),
                  });
                }}
              >
                <SvgEye height="1.3em" width="1.3em" />
                <UncontrolledTooltip placement="bottom" target={`notif${row.id}-read`}>
                  {' '}
                  {t('all.text.read_notification')}
                </UncontrolledTooltip>
              </div>
            </Col>
            <Col xs="6">
              <div
                id={`notif${row.id}-remove`}
                className="clickable round delete-alert"
                role="presentation"
                onClick={e => this.delete(e, row)}
              >
                <PoubelleBleue height="1em" width="1em" />
                <UncontrolledTooltip placement="bottom" target={`notif${row.id}-remove`}>
                  {' '}
                  {t('notifications.popup_delete_title.delete_notification')}
                </UncontrolledTooltip>
              </div>
            </Col>
          </Row>
        ),
      },
    ];
    const selectRow = {
      mode: 'checkbox',
      classes: 'regular-checkbox checkbox-add',
      selectionHeaderRenderer: ({ mode, ...props }: any) => {
        if (props.indeterminate) {
          return <input className="all-cb regular-checkbox checkbox-add-all" type={mode} defaultChecked />;
        }
        return props.checked ? (
          <input className="all-cb regular-checkbox checkbox-add" type={mode} defaultChecked />
        ) : (
          <input className="all-cb regular-checkbox checkbox-add" type={mode} />
        );
      },
      onSelect: (row: any, isSelect: any, rowIndex: any, e: any) => {
        let selectedNotifs = cloneDeep(selected);

        const existing = selectedNotifs.find(it => it.id === row.id);
        if (isSelect) {
          if (existing === undefined) {
            selectedNotifs.push(row);
          }
        } else {
          if (existing !== undefined) {
            const mtrIndex = selectedNotifs.indexOf(existing);
            selectedNotifs.splice(mtrIndex, 1);
          }
        }

        this.setState({ selected: selectedNotifs });
      },
      onSelectAll: (isSelect: boolean, rows: any, e: any) => {
        let selectedNotifs = cloneDeep(selected);

        rows.forEach((row: any) => {
          const existing = selectedNotifs.find((it: Notification) => it.id === row.id);
          if (existing === undefined && isSelect) {
            selectedNotifs.push(row);
          }
          if (existing !== undefined && !isSelect) {
            const mtrIndex = selectedNotifs.indexOf(existing);
            selectedNotifs.splice(mtrIndex, 1);
          }
        });

        this.setState({ selected: selectedNotifs });
      },
    };
    return (
      <div className="col-md-12">
        {notifications.loading && <LoadingBand message={t('notifications.loading_band.loading_notifications')} />}
        {notifications.error && <ErrorBand message={notifications.error} />}
        {notifications.items && (
          <div className="table-info-container">
            <h2>
              <span>
                <Alerte height="1em" width="1em" fill="#31c6b3" />
              </span>
              {t('all.text.notif_plural')} :{' '}
              <span className="float-right">
                <div
                  id="readAll"
                  className="round"
                  style={{ fontSize: '0em', cursor: 'pointer', marginLeft: '35px' }}
                  onClick={() => {
                    const { notifications } = this.props;
                    let notifsToRead = notifications.items.filter((it: any) => !it.state);
                    if (notifsToRead.length > 0) {
                      notifsToRead = notifsToRead.map((it: Notification) => {
                        it.state = true;
                        return it;
                      });
                      dispatch(notificationActions.readAll(notifsToRead));
                      this.setState({
                        notifKey: Math.floor(Math.random() * Math.floor(1024)),
                      });
                    }
                  }}
                  role="presentation"
                >
                  <HistoriqueVert className="add" />
                  <UncontrolledTooltip placement="bottom" target="readAll">
                    {' '}
                    {t('all.text.mark_all_as_read')}
                  </UncontrolledTooltip>
                </div>
              </span>
              {selected.length > 0 && (
                <span className="float-right">
                  <div
                    id="deleteMulti"
                    className="round"
                    style={{ fontSize: '0em', cursor: 'pointer' }}
                    onClick={(e: any) => this.deleteMulti(e)}
                    role="presentation"
                  >
                    <PoubelleBleue className="add" />
                    <UncontrolledTooltip placement="bottom" target="deleteMulti">
                      {t('notifications.tooltip.delete_selected')}
                    </UncontrolledTooltip>
                  </div>
                </span>
              )}
            </h2>
            <br />
            <BootstrapTable
              key={notifKey}
              keyField="id"
              data={notifications.items}
              columns={columns}
              bootstrap4
              bordered={false}
              hover
              selectRow={selectRow}
              filter={filterFactory()}
              pagination={paginationFactory()}
            />
          </div>
        )}
        {activeNotif && Object.keys(activeNotif).length > 0 && (
          <SingleNotification notif={activeNotif} show={this.show} isOpen={isOpen} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, locales, notifications, alert } = state;
  const { user } = authentication;

  return {
    user,
    locales,
    notifications,
    alert,
  };
}

const mapping: any = connect(mapStateToProps)(Notifications);

const connectedNotifications = withRouter(mapping);
const tr = withTranslation()(connectedNotifications);
export default tr;
