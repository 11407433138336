import React from 'react';

const SvgFabricantVert = (props: any) => (
  <svg viewBox="0 0 37.32 37.32" {...props}>
    <path
      d="M17.84 22.46l5.46 5.46a7.2 7.2 0 0 0 9.46 8.91.63.63 0 0 0 .4-.48.64.64 0 0 0-.17-.59l-4.16-4.16 2.77-2.77L35.76 33a.64.64 0 0 0 .59.17.63.63 0 0 0 .48-.4 7.2 7.2 0 0 0-1.61-7.71 7.18 7.18 0 0 0-7.3-1.75L14 9.41A7.19 7.19 0 0 0 4.56.5a.68.68 0 0 0-.4.47.67.67 0 0 0 .18.6L8.5 5.72 5.72 8.5 1.57 4.34A.67.67 0 0 0 1 4.16a.68.68 0 0 0-.47.4A7.19 7.19 0 0 0 9.41 14l5.19 5.19m.93.93l1.38 1.38m19 9.75l-3.85-3.84a.66.66 0 0 0-.92 0l-3.7 3.7a.65.65 0 0 0 0 .92l3.84 3.85A5.89 5.89 0 0 1 24.66 28a.65.65 0 0 0-.15-.7L10 12.81a.68.68 0 0 0-.46-.19.69.69 0 0 0-.24.05A5.89 5.89 0 0 1 1.42 6l3.84 3.88a.65.65 0 0 0 .93 0l3.69-3.69a.65.65 0 0 0 0-.93L6 1.42a5.89 5.89 0 0 1 6.63 7.92.65.65 0 0 0 .14.7l14.52 14.47a.65.65 0 0 0 .7.15 5.89 5.89 0 0 1 7.92 6.62z"
      fill={props.fill}
      data-name="Calque 2"
    />
  </svg>
);

export default SvgFabricantVert;
