import React from 'react';

const SvgWidgetBlanc = (props: any) => (
  <svg viewBox="0 0 15.43 15.05" {...props}>
    <g id="widget_blanc_svg__Calque_2" data-name="Calque 2">
      <g id="widget_blanc_svg__Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          d="M0 0h2.36v15.05H0zM4.28 4.22h2.37v10.82H4.28zM8.78 0h2.37v15.05H8.78zM13.06 4.22h2.36v10.82h-2.36z"
        />
      </g>
    </g>
  </svg>
);

export default SvgWidgetBlanc;
