import initialProgressConstants from '../initialisation/initialProgress.constants';

export default function initialProgress(state: any = {}, action: any) {
  switch (action.type) {
    case initialProgressConstants.GET_INITIAL_PROGRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case initialProgressConstants.GET_INITIAL_PROGRESS_SUCCESS:
      console.log('RENDER  PROGRESS : ', action.progressCode);

      let progress = '';

      switch (action.progressCode) {
        case 1:
          progress = 'Initialisation de CrystalCloud en cours [1/4]';
          break;
        case 2:
          progress = 'Préparation des données en cours [2/4]';
          break;
        case 3:
          progress = 'Récupération des données en cours [3/4]';
          break;
        case 4:
          progress = 'Chargement des données en cours [4/4]';
          break;
        case 5:
          progress = 'END';
          break;
        default:
          progress = `Echec de l'initialisation : ${action.progressCode}`;
          break;
      }
      return {
        ...state,
        loading: false,
        progress: progress,
        progressCode: action.progressCode,
      };
    case initialProgressConstants.GET_INITIAL_PROGRESS_FAILURE:
      return {
        error: "Impossible d'obtenir la progression",
      };

    default:
      return state;
  }
}
