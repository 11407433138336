import { columnSelectorConstants } from '../_constants';
import { action } from '../_interfaces/action';

export default function columns(state = {}, action: action) {
  switch (action.type) {
    case columnSelectorConstants.GET_SUCCESS:
      return {
        items: action.columns,
      };
    case columnSelectorConstants.GET_FAILURE:
      return {
        error: 'Impossible de récupérer les filtres',
      };
    case columnSelectorConstants.SAVE_SUCCESS:
      return {
        column: action.savedColumns,
        items: [...state.items, action.savedColumns],
      };
    case columnSelectorConstants.DELETE_SUCCESS:
      return {
        deletedColumn: action.deletedColumn,
        items: state.items.filter(el => el.id !== action.deletedColumn.id),
      };
    case columnSelectorConstants.DELETE_FAILURE:
      return {
        error: 'La suppression du filtre a échouée',
      };
    case columnSelectorConstants.UPDATE_SUCCESS:
      const columns = state.items.filter(el => el.id !== action.updatedColumn.id);
      columns.push(action.updatedColumn);
      return {
        items: columns,
        column: action.updatedColumn,
      };
    case columnSelectorConstants.UPDATE_FAILURE:
      return {
        error: 'Impossible de sauvegarder les informations du filtre',
      };
    case columnSelectorConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
