import React from 'react';

const SvgFlagEn = (props: any) => (
  <svg viewBox="0 0 512 512" {...props}>
    <circle fill="#F0F0F0" cx="256" cy="256" r="256" />
    <g>
      <path fill="#0052B4" d="M52.9,100.1c-20.1,26.2-35.3,56.3-44.1,89.1H142L52.9,100.1z" />
      <path fill="#0052B4" d="M503.2,189.2c-8.8-32.8-24-62.9-44.1-89.1L370,189.2H503.2z" />
      <path fill="#0052B4" d="M8.8,322.8c8.8,32.8,24,62.9,44.1,89.1l89.1-89.1H8.8L8.8,322.8z" />
      <path fill="#0052B4" d="M411.9,52.9c-26.2-20.1-56.3-35.3-89.1-44.1V142L411.9,52.9z" />
      <path fill="#0052B4" d="M100.1,459.1c26.2,20.1,56.3,35.3,89.1,44.1V370L100.1,459.1z" />
      <path fill="#0052B4" d="M189.2,8.8c-32.8,8.8-62.9,24-89.1,44.1l89.1,89.1V8.8z" />
      <path fill="#0052B4" d="M322.8,503.2c32.8-8.8,62.9-24,89.1-44.1L322.8,370L322.8,503.2L322.8,503.2z" />
      <path fill="#0052B4" d="M370,322.8l89.1,89.1c20.1-26.2,35.3-56.3,44.1-89.1H370z" />
    </g>
    <g>
      <path
        fill="#D80027"
        d="M509.8,222.6H289.4h0V2.2C278.5,0.7,267.3,0,256,0c-11.3,0-22.5,0.7-33.4,2.2v220.4v0H2.2
		C0.7,233.5,0,244.7,0,256c0,11.3,0.7,22.5,2.2,33.4h220.4h0v220.4c10.9,1.4,22.1,2.2,33.4,2.2c11.3,0,22.5-0.7,33.4-2.2V289.4v0
		h220.4c1.4-10.9,2.2-22.1,2.2-33.4C512,244.7,511.3,233.5,509.8,222.6z"
      />
      <path
        fill="#D80027"
        d="M322.8,322.8L322.8,322.8L437,437c5.3-5.3,10.3-10.7,15-16.4l-97.8-97.8L322.8,322.8L322.8,322.8L322.8,322.8z
		"
      />
      <path
        fill="#D80027"
        d="M189.2,322.8L189.2,322.8L75,437c5.3,5.3,10.7,10.3,16.4,15l97.8-97.8L189.2,322.8L189.2,322.8z"
      />
      <path
        fill="#D80027"
        d="M189.2,189.2L189.2,189.2L75,75c-5.3,5.3-10.3,10.7-15,16.4l97.8,97.8L189.2,189.2L189.2,189.2z"
      />
      <path fill="#D80027" d="M322.8,189.2L322.8,189.2L437,75c-5.3-5.3-10.7-10.3-16.4-15l-97.8,97.8V189.2z" />
    </g>
  </svg>
);

export default SvgFlagEn;
