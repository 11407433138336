import { vmeterConstants } from '../_constants';
import { vmeterService } from '../_services';
import { VirtualMeterGeneral } from '../_entities/virtual';
import { alertActions } from '.';
import moment from 'moment';

/**
 * Récupère tous les compteurs virtuels du site courant
 *
 * @method getAll
 * @param siteId Id du site
 * @returns {Object} Les compteurs virtuels
 */
function getAll(siteId: number) {
  function request() {
    return { type: vmeterConstants.GETALL_REQUEST };
  }
  function success(vmeters: any) {
    return { type: vmeterConstants.GETALL_SUCCESS, vmeters };
  }
  function failure(error: Object) {
    return { type: vmeterConstants.GETALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    vmeterService
      .getAll(siteId)
      .then((meters: Object) => {
        dispatch(success(meters));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère tous les compteurs virtuels du site courant
 *
 * @method getAllWithChildren
 * @param siteId Id du site
 * @returns {Object} Les compteurs virtuels
 */
function getAllWithChildren(siteId: number) {
  function request() {
    return { type: vmeterConstants.GETALL_REQUEST };
  }
  function success(vmeters: any) {
    return { type: vmeterConstants.GETALL_SUCCESS, vmeters };
  }
  function failure(error: Object) {
    return { type: vmeterConstants.GETALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    vmeterService
      .getAllWithChildren(siteId)
      .then((meters: Object) => {
        dispatch(success(meters));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Sauvegarde le compteur virtuel passé en paramètre
 *
 * @method create
 * @param {any} body Compteur virtuel
 * @returns {Object} L'état de la sauvegarde
 */
function create(body: any) {
  function request() {
    return { type: vmeterConstants.CREATE_REQUEST };
  }
  function success(fetchedVMeter: any) {
    return { type: vmeterConstants.CREATE_SUCCESS, fetchedVMeter };
  }
  function failure(error: Object) {
    return { type: vmeterConstants.CREATE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    vmeterService
      .create(body)
      .then((fetchedVMeter: Object) => {
        dispatch(success(fetchedVMeter));
      })
      .catch((error: Object) => {
        dispatch(alertActions.error("Une erreur s'est produite lors de la création du compteur virtuel"));
        dispatch(failure(error));
      });
  };
}

/**
 * Met à jour le compteur virtuel passé en paramètre
 *
 * @method edit
 * @param {number} id Id du compteur virtuel
 * @param {any} body Compteur virtuel
 * @returns {Object} L'état de la mise à jour
 */
function edit(id: number, body: any) {
  function request() {
    return { type: vmeterConstants.EDIT_REQUEST };
  }
  function success(fetchedVMeter: any) {
    return { type: vmeterConstants.EDIT_SUCCESS, fetchedVMeter };
  }
  function failure(error: Object) {
    return { type: vmeterConstants.EDIT_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    vmeterService
      .edit(id, body)
      .then((fetchedVMeter: Object) => {
        dispatch(success(fetchedVMeter));
      })
      .catch((error: Object) => {
        dispatch(alertActions.error("Une erreur s'est produite lors de l'édition du compteur virtuel"));
        dispatch(failure(error));
      });
  };
}

/**
 * Supprime le compteur virtuel dont l'id est passé en paramètre
 *
 * @method remove
 * @param {number} id Identifiant
 * @param {number} locationId Id du site
 * @param {Array<VirtualMeterGeneral>} vmeters Tous les compteurs virtuels
 * @returns {Object} L'état de la suppression
 */
function remove(id: number, locationId: number, vmeters: VirtualMeterGeneral[]) {
  function request() {
    return { type: vmeterConstants.DELETE_REQUEST };
  }
  function success() {
    return { type: vmeterConstants.DELETE_SUCCESS, vmeterId: id, vmeters };
  }
  function failure(error: Object) {
    return { type: vmeterConstants.DELETE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    vmeterService
      .remove(id, locationId)
      .then(() => {
        dispatch(success());
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère les infos du compteur virtuel dont l'id est
 * passé en paramètre
 *
 * @method getInfos
 * @param {number} siteId Id du site
 * @param {string} id Id du compteur virtuel
 * @returns {Object} Les infos du compteur virtuel
 */
function getInfos(siteId: number, id: string) {
  function request() {
    return { type: vmeterConstants.GETINFO_REQUEST };
  }
  function success(fetchedVMeter: Object) {
    return { type: vmeterConstants.GETINFO_SUCCESS, fetchedVMeter };
  }
  function failure(error: Object) {
    return { type: vmeterConstants.GETINFO_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    vmeterService
      .getInfos(siteId, id)
      .then((fetchedVMeter: Object) => {
        dispatch(success(fetchedVMeter));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère toutes les infos du compteur virtuel (dont lectures, consos et alarmes)
 * dont l'id est passé en paramètre
 *
 * @method getFullInfos
 * @param {number} siteId Id du site
 * @param {string} param Id du compteur virtuel
 * @param {any} contentReads Requête des lectures
 * @param {any} contentConso Requête des consos
 * @param {any} contentAlarms Requête des alarmes
 * @returns {Object} Les infos du compteur virtuel
 */
function getFullInfos(siteId: number, param: string, contentReads: any, contentConso: any, contentAlarms: any) {
  const dataStatements = contentReads;
  const dataAlarms = contentAlarms;
  const dataConso = contentConso;
  let vmeter: any;
  function request() {
    return { type: vmeterConstants.GETFULLINFO_REQUEST };
  }
  function success(fetchedVMeter: Object) {
    return { type: vmeterConstants.GETINFO_SUCCESS, fetchedVMeter };
  }
  function successReads(data: Object) {
    return { type: vmeterConstants.GETFULLINFO_READS_SUCCESS, data };
  }
  function successConso(data: Object) {
    return { type: vmeterConstants.GETFULLINFO_CONSO_SUCCESS, data };
  }
  function successAlarms(data: Object) {
    return { type: vmeterConstants.GETFULLINFO_ALARMS_SUCCESS, data };
  }
  function successLastReads(data: Object) {
    return { type: vmeterConstants.GETFULLINFO_LASTREADS_SUCCESS, data };
  }
  function failure(error: Object) {
    return { type: vmeterConstants.GETFULLINFO_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    vmeterService
      .getInfos(siteId, param)
      .then((fetchedVMeter: Object) => {
        vmeter = fetchedVMeter;
        dispatch(success(vmeter));
        if (vmeter.general) {
          dataStatements.dataSourceProperty.displayProperty.condition[0].conditionValue = vmeter.general.name;
          dataConso.dataSourceProperty.displayProperty.condition[0].conditionValue = vmeter.general.name;
          dataAlarms.dataSourceProperty.displayProperty.condition[0].conditionValue = vmeter.general.name;
        }
        vmeterService.getReads(siteId, dataStatements).then(data => dispatch(successReads(data)));
        vmeterService.getConsumptions(siteId, dataConso).then(data => dispatch(successConso(data)));
        vmeterService.getAlarms(siteId, dataAlarms).then(data => dispatch(successAlarms(data)));
        vmeterService.getLastReads(siteId, param).then(data => dispatch(successLastReads(data)));
      })
      .catch(r => dispatch(failure(r)));
  };
}

/**
 * Récupère toutes les lectures du compteur virtuel passé en
 * paramètre
 *
 * @method getReads
 * @param {number} siteId Id du site
 * @param {Object} content Conditions
 * @param {any} currentVMeter Compteur virtuel
 * @returns {Object} Lectures
 */
function getReads(siteId: number, content: Object, currentVMeter: any) {
  const vmeter = currentVMeter;
  function success(fetchedVMeter: Object) {
    return { type: vmeterConstants.GETSTATES_SUCCESS, fetchedVMeter };
  }
  function failure(error: Object) {
    return { type: vmeterConstants.GETSTATES_FAILURE, error };
  }
  return (dispatch: Function) => {
    vmeterService
      .getReads(siteId, content)
      .then((statements: any) => {
        vmeter.statements = statements;
        dispatch(success(vmeter));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère toutes les consommations du compteur virtuel passé en
 * paramètre
 *
 * @method getConsumptions
 * @param {number} siteId Id du site
 * @param {Object} content Conditions
 * @param {any} currentVMeter Compteur virtuel
 * @returns {Object} Consommations
 */
function getConsumptions(siteId: number, content: Object, currentVMeter: any) {
  const vmeter = currentVMeter;
  function success(fetchedVMeter: Object) {
    return { type: vmeterConstants.GETSTATES_SUCCESS, fetchedVMeter };
  }
  function failure(error: Object) {
    return { type: vmeterConstants.GETSTATES_FAILURE, error };
  }
  return (dispatch: Function) => {
    vmeterService
      .getConsumptions(siteId, content)
      .then((statements: any) => {
        vmeter.consumption = statements;
        dispatch(success(vmeter));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère toutes les alarmes du compteur virtuel passé en
 * paramètre
 *
 * @method getAlarms
 * @param {number} siteId Id du site
 * @param {Object} content Conditions
 * @param {any} currentVMeter Compteur virtuel
 * @returns {Object} Alarmes
 */
function getAlarms(siteId: number, content: Object, currentVMeter: any) {
  const vmeter = currentVMeter;
  function success(fetchedVMeter: Object) {
    return { type: vmeterConstants.GETALARMS_SUCCESS, fetchedVMeter };
  }
  function failure(error: Object) {
    return { type: vmeterConstants.GETALARMS_FAILURE, error };
  }
  return (dispatch: Function) => {
    vmeterService
      .getAlarms(siteId, content)
      .then((alarms: any) => {
        vmeter.alarms = alarms;
        dispatch(success(vmeter));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère toutes les lectures, consos et alarmes du compteur
 * virtuel passé en paramètre
 *
 * @method getStatementsAndAlarms
 * @param {number} siteId Id du site
 * @param {any} contentReads Conditions des lectures
 * @param {any} contentConso Conditions des consos
 * @param {any} contentAlarms Conditions des alarmes
 * @param {any} currentVMeter Compteur virtuel
 * @returns {Object} Lectures, consommations et alarmes
 */
function getStatementsAndAlarms(
  siteId: number,
  contentReads: any,
  contentConso: any,
  contentAlarms: any,
  currentVMeter: any
) {
  const vmeter = _.cloneDeep(currentVMeter);
  function request() {
    const vmeterClean = _.cloneDeep(currentVMeter);
    delete vmeterClean.alarms;
    delete vmeterClean.consumption;
    delete vmeterClean.statements;
    return { type: vmeterConstants.GETINFO_SUCCESS, fetchedVMeter: vmeterClean };
  }
  function success(fetchedVMeter: Object) {
    return { type: vmeterConstants.GETDATA_SUCCESS, fetchedVMeter };
  }
  function failure(error: Object) {
    return { type: vmeterConstants.GETDATA_FAILURE, error };
  }
  function successReads(data: Object) {
    return { type: vmeterConstants.GETFULLINFO_READS_SUCCESS, data };
  }
  function successConso(data: Object) {
    return { type: vmeterConstants.GETFULLINFO_CONSO_SUCCESS, data };
  }
  function successAlarms(data: Object) {
    return { type: vmeterConstants.GETFULLINFO_ALARMS_SUCCESS, data };
  }
  return (dispatch: Function) => {
    dispatch(request());

    contentReads.dataSourceProperty.displayProperty.condition[0].conditionValue = vmeter.general.name;
    contentConso.dataSourceProperty.displayProperty.condition[0].conditionValue = vmeter.general.name;
    contentAlarms.dataSourceProperty.displayProperty.condition[0].conditionValue = vmeter.general.name;

    vmeterService.getReads(siteId, contentReads).then(data => dispatch(successReads(data)));
    vmeterService.getConsumptions(siteId, contentConso).then(data => dispatch(successConso(data)));
    vmeterService.getAlarms(siteId, contentAlarms).then(data => dispatch(successAlarms(data)));
  };
}

/**
 * Vide le state des compteurs virtuels
 *
 * @method clear
 */
function clear() {
  return {
    type: vmeterConstants.CLEAR,
  };
}

const vmeterActions = {
  getAll,
  getAllWithChildren,
  getInfos,
  getFullInfos,
  getReads,
  getConsumptions,
  getAlarms,
  getStatementsAndAlarms,
  create,
  clear,
  edit,
  remove,
};

export default vmeterActions;
