const tourneeConstants = {
  GETTOURNEE_REQUEST: 'TOURNEE_GET_REQUEST',
  GETTOURNEE_SUCCESS: 'TOURNEE_GET_SUCCESS',
  GETTOURNEE_FAILURE: 'TOURNEE_GET_FAILURE',

  DELETE_TOURNEE_REQUEST: 'DELETE_TOURNEE_REQUEST',
  DELETE_TOURNEE_SUCCESS: 'DELETE_TOURNEE_SUCCESS',
  DELETE_TOURNEE_FAILURE: 'DELETE_TOURNEE_FAILURE',

  GET_TELERELEVE_TOURNEE_REQUEST: 'GET_TELERELEVE_TOURNEE_REQUEST',
  GET_TELERELEVE_TOURNEE_SUCCESS: 'GET_TELERELEVE_TOURNEE_SUCCESS',
  GET_TELERELEVE_TOURNEE_FAILURE: 'GET_TELERELEVE_TOURNEE_FAILURE',

  FILL_TELERELEVE_TOURNEE_REQUEST: 'FILL_TELERELEVE_TOURNEE_REQUEST',
  FILL_TELERELEVE_TOURNEE_SUCCESS: 'FILL_TELERELEVE_TOURNEE_SUCCESS',
  FILL_TELERELEVE_TOURNEE_FAILURE: 'FILL_TELERELEVE_TOURNEE_FAILURE',

  EXPORT_TOURNEE_REQUEST: 'EXPORT_TOURNEE_REQUEST',
  EXPORT_TOURNEE_SUCCESS: 'EXPORT_TOURNEE_SUCCESS',
  EXPORT_TOURNEE_FAILURE: 'EXPORT_TOURNEE_FAILURE',

  SILEX_TO_FACTU_REQUEST: 'SILEX_TO_FACTU_REQUEST',
  SILEX_TO_FACTU_SUCCESS: 'SILEX_TO_FACTU_SUCCESS',
  SILEX_TO_FACTU_FAILURE: 'SILEX_TO_FACTU_FAILURE',

  GET_FACTURATION_REQUEST: 'GET_FACTURATION_REQUEST',
  GET_FACTURATION_SUCCESS: 'GET_FACTURATION_SUCCESS',
  GET_FACTURATION_FAILURE: 'GET_FACTURATION_FAILURE',

  EXPORT_TOURNEE_TELEO_REQUEST: 'EXPORT_TOURNEE_TELEO_REQUEST',
  EXPORT_TOURNEE_TELEO_SUCCESS: 'EXPORT_TOURNEE_TELEO_SUCCESS',
  EXPORT_TOURNEE_TELEO_FAILURE: 'EXPORT_TOURNEE_TELEO_FAILURE',

  ROTATE_OPTIONS_SUCCESS: 'ROTATE_OPTIONS_SUCCESS',

  GETTOURNEEFICHE_REQUEST: 'TOURNEEFICHE_GET_REQUEST',
  GETTOURNEEFICHE_SUCCESS: 'TOURNEEFICHE_GET_SUCCESS',
  GETTOURNEEFICHE_FAILURE: 'TOURNEEFICHE_GET_FAILURE',

  GETALLTOURNEEFICHE_REQUEST: 'TOURNEEFICHE_GETALL_REQUEST',
  GETALLTOURNEEFICHE_SUCCESS: 'TOURNEEFICHE_GETALL_SUCCESS',
  GETALLTOURNEEFICHE_FAILURE: 'TOURNEEFICHE_GETALL_FAILURE',

  STATS_TOURNEE_REQUEST: 'STATS_TOURNEE_REQUEST',
  STATS_TOURNEE_SUCCESS: 'STATS_TOURNEE_SUCCESS',
  STATS_TOURNEE_FAILURE: 'STATS_TOURNEE_FAILURE',

  UPDATE_TOURNEEMSG_REQUEST: 'UPDATE_TOURNEEMSG_REQUEST',
  UPDATE_TOURNEEMSG_SUCCESS: 'UPDATE_TOURNEEMSG_SUCCESS',
  UPDATE_TOURNEEMSG_FAILURE: 'UPDATE_TOURNEEMSG_FAILURE',

  ROUNDS_GETALL_INFO_REQUEST: 'ROUNDS_GETALL_INFO_REQUEST',
  ROUNDS_GETALL_INFO_SUCCESS: 'ROUNDS_GETALL_INFO_SUCCESS',
  ROUNDS_GETALL_INFO_FAILURE: 'ROUNDS_GETALL_INFO_FAILURE',

  ROUNDS_GETALL_TOURNEE_INFO_REQUEST: 'ROUNDS_GETALL_TOURNEE_INFO_REQUEST',
  ROUNDS_GETALL_TOURNEE_INFO_SUCCESS: 'ROUNDS_GETALL_TOURNEE_INFO_SUCCESS',
  ROUNDS_GETALL_TOURNEE_INFO_FAILURE: 'ROUNDS_GETALL_TOURNEE_INFO_FAILURE',

  ROUNDS_EDIT_INFO_REQUEST: 'ROUNDS_EDIT_INFO_REQUEST',
  ROUNDS_EDIT_INFO_SUCCESS: 'ROUNDS_EDIT_INFO_SUCCESS',
  ROUNDS_EDIT_INFO_FAILURE: 'ROUNDS_EDIT_INFO_FAILURE',

  DEL_MARQUAGE_REQUEST: 'DEL_MARQUAGE_REQUEST',
  DEL_MARQUAGE_SUCCESS: 'DEL_MARQUAGE_SUCCESS',
  DEL_MARQUAGE_FAILURE: 'DEL_MARQUAGE_FAILURE',

  DEL_MESSAGE_REQUEST: 'DEL_MESSAGE_REQUEST',
  DEL_MESSAGE_SUCCESS: 'DEL_MESSAGE_SUCCESS',
  DEL_MESSAGE_FAILURE: 'DEL_MESSAGE_FAILURE',

  GET_ID_WITH_CODE_REQUEST: 'GET_ID_WITH_CODE_REQUEST',
  GET_ID_WITH_CODE_SUCCESS: 'GET_ID_WITH_CODE_SUCCESS',
  GET_ID_WITH_CODE_FAILURE: 'GET_ID_WITH_CODE_FAILURE',

  GET_EXPORT_OPTIONS_SUCCESS: 'GET_EXPORT_OPTIONS_SUCCESS',
  CLEAR: 'TOURNEE_CLEAR',
  CLEAR_TELERELEVE_READS: 'CLEAR_TELERELEVE_READS',

  FICHE_EDIT_INFO_SUCCESS: 'FICHE_EDIT_INFO_SUCCESS',
};

export default tourneeConstants;
