import { authHeader, handleResponse } from '../_helpers';
import { dashboard } from '../_entities/dashboard';

async function get(id: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let response = await fetch(`/api/dashboard/${id}/`, requestOptions);
  return handleResponse(response);
}

async function update(dashboard: dashboard) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(dashboard),
  };

  // return fetch(`/api/dashboard/${dashboard.id}`, requestOptions).then(handleResponseHardUpdate);
  // handleResponseHardUpdate(dashboard);
  let response = await fetch(`/api/dashboard/${dashboard.id}`, requestOptions);
  return handleResponse(response);
}

async function getAll(locationId: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/dashboard/location/${locationId}`, requestOptions);
  return handleResponse(response);
}

async function save(dashboard: dashboard) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(dashboard),
  };
  let response = await fetch(`/api/dashboard/location/${dashboard.location.id}`, requestOptions);
  return handleResponse(response);
}

async function deleteDashBoard(dashboard: dashboard) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    body: JSON.stringify(dashboard),
  };
  let response = await fetch(`/api/dashboard/${dashboard.id}`, requestOptions);
  return handleResponse(response);
}

const dashboardService = {
  get,
  update,
  getAll,
  save,
  deleteDashBoard,
};
export default dashboardService;
