import Goutte from '../_ressources/Goutte.png';
import MarqueurClic from '../_ressources/Marqueur clic.png';
import releveRadioBleu from '../_svgs/releve_radio_bleu.svg';
import releveRadioViolet from '../_svgs/releve_radio_violet.svg';
import releveTelereleveBleu from '../_svgs/releve_telereleve_blue.svg';
import releveTelereleveViolet from '../_svgs/releve_telereleve_violet.svg';
import releveRougeManuelle from '../_svgs/releve_manuelle_rouge.svg';
import releveRougeRadio from '../_svgs/releve_radio_rouge.svg';
import releveJauneRadio from '../_svgs/releve_radio_yellow.svg';
import releveJauneManuelle from '../_svgs/releve_manuelle_yellow.svg';
import releveManuelleVerte from '../_svgs/releve_manuelle_verte.svg';
import activeGateway from '../_svgs/gateway_vert.svg';
import inactiveGateway from '../_svgs/gateway_rouge.svg';

import MarkerHome from '../_components/marker.png';

function getC(key, props) {
  switch (key) {
    case 'red_manual':
      return releveRougeManuelle; //? Non relevé / manuelle
    case 'red_radio':
      return releveRougeRadio; //? Non relevé / radio
    case 'yellow_manual':
      return releveJauneManuelle; //? Modifié / manuelle
    case 'yellow_radio':
      return releveJauneRadio; //? Modifié / radio
    case 'green_manual':
      return releveManuelleVerte; //? Relevé / manuelle
    case 'blue_radio':
      return releveRadioBleu; //? Relevé / radio
    case 'purple_radio':
      return releveRadioViolet;
    case 'blue_telereleve':
      return releveTelereleveBleu;
    case 'active_gateway':
      return activeGateway;
    case 'inactive_gateway':
      return inactiveGateway;
    case 'purple_telereleve':
      return releveTelereleveViolet;
    case 'site':
      return Goutte;
    case 'marqueurClic':
      return MarqueurClic;
    case 'markerHome':
      return MarkerHome;
    default:
      break;
  }
  return;
}

export default getC;
