import React from 'react';

const SvgDiametreVert = (props: any) => (
  <svg viewBox="0 0 26.91 29.8" {...props}>
    <g id="diametre_vert_svg__Calque_2" data-name="Calque 2">
      <g id="diametre_vert_svg__Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          d="M13.48 28.36A13.49 13.49 0 0 1 0 14.93 13.46 13.46 0 0 1 13.43 1.45a13.46 13.46 0 0 1 0 26.91zM2.21 14.92A11.25 11.25 0 1 0 13.46 3.66 11.25 11.25 0 0 0 2.21 14.92z"
        />
        <path
          fill={props.fill}
          d="M2.57 29.8a.79.79 0 0 1-.46-.15.76.76 0 0 1-.11-1.06L23.74.29a.76.76 0 1 1 1.2.92L3.17 29.51a.76.76 0 0 1-.6.29z"
        />
      </g>
    </g>
  </svg>
);

export default SvgDiametreVert;
