const alarmConstants = {
  GETALL_REQUEST: 'ALARMS_GETALL_REQUEST',
  GETALL_SUCCESS: 'ALARMS_GETALL_SUCCESS',
  GETALL_FAILURE: 'ALARMS_GETALL_FAILURE',

  GETALL_TYPE_REQUEST: 'ALARMS_GETALL_TYPE_REQUEST',
  GETALL_TYPE_SUCCESS: 'ALARMS_GETALL_TYPE_SUCCESS',
  GETALL_TYPE_FAILURE: 'ALARMS_GETALL_TYPE_FAILURE',

  GET_REQUEST: 'ALARMS_GET_REQUEST',
  GET_SUCCESS: 'ALARMS_GET_SUCCESS',
  GET_FAILURE: 'ALARMS_GET_FAILURE',

  SAVE_REQUEST: 'ALARMS_SAVE_REQUEST',
  SAVE_SUCCESS: 'ALARMS_SAVE_SUCCESS',
  SAVE_FAILURE: 'ALARMS_SAVE_FAILURE',

  UPDATE_REQUEST: 'ALARMS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ALARMS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ALARMS_UPDATE_FAILURE',

  DELETE_REQUEST: 'ALARMS_DELETE_REQUEST',
  DELETE_SUCCESS: 'ALARMS_DELETE_SUCCESS',
  DELETE_FAILURE: 'ALARMS_DELETE_FAILURE',

  CLEAR: 'ALARMS_CLEAR',
};

export default alarmConstants;
