import React from 'react';

const SvgFlecheAller = (props: any) => (
  <svg viewBox="0 0 122.31 75.67" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M122.11,38.87a2.75,2.75,0,0,0,0-2.07,2.59,2.59,0,0,0-.6-.89L86.4.8a2.72,2.72,0,0,0-3.84,3.84L113,35.12H2.72a2.72,2.72,0,1,0,0,5.43H113L82.56,71a2.72,2.72,0,0,0,3.84,3.85l35.11-35.12A2.59,2.59,0,0,0,122.11,38.87Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgFlecheAller;
