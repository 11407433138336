import React from 'react';
import ModeleVert from '../SvgComponents/ModeleVert';
import FabricantVert from '../SvgComponents/FabricantVert';
import CodeBarreVert from '../SvgComponents/CodeBarreVert';

function getC(key, props) {
  switch (key) {
    case 'manufacturer':
      return <FabricantVert {...props} />;
    case 'model':
      return <ModeleVert {...props} />;
    case 'barCode':
      return <CodeBarreVert {...props} />;
    default:
      break;
  }
}

export default getC;
