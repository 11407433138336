import React from 'react';

const SvgTourneeLoad = (props: any) => (
  <svg viewBox="0 0 101.3 114.1" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M97.9,3.3a11.31,11.31,0,0,0-8-3.3,11.43,11.43,0,0,0-7.94,3.2l-.06.06-.06.06L57.18,28.18V22.32a6.67,6.67,0,0,0-6.65-6.65H6.65A6.68,6.68,0,0,0,0,22.32v85.13a6.67,6.67,0,0,0,6.65,6.65H50.53a6.67,6.67,0,0,0,6.65-6.65V89.07a11.4,11.4,0,0,0,0-22.42v-6L98.05,19.36l.06-.07.06-.06A11.35,11.35,0,0,0,97.9,3.3ZM34.61,73.46H55.1a4.29,4.29,0,0,1,2.08.54,4.41,4.41,0,0,1-2.08,8.27H24a4.42,4.42,0,0,1-4.41-4.41V46.79a4.41,4.41,0,0,1,8.82,0V67.15L52.55,42.8l4.63-4.67L86.78,8.24A4.46,4.46,0,0,1,89.87,7a4.4,4.4,0,0,1,3.2,7.43L57.18,50.67l-4.63,4.67Zm-13-53h14a.81.81,0,0,1,.81.82.8.8,0,0,1-.81.79h-14a.8.8,0,0,1-.81-.79A.81.81,0,0,1,21.57,20.46Zm7,90.32a3.33,3.33,0,1,1,3.32-3.33A3.31,3.31,0,0,1,28.59,110.78Zm24-9H4.63V26.22H52.55v6.64L35.44,50.13V46.79a11.41,11.41,0,1,0-22.82,0V77.86A11.42,11.42,0,0,0,24,89.27H52.55Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgTourneeLoad;
