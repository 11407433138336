import React from 'react';

const SvgHorlogeVert = (props: any) => (
  <svg viewBox="0 0 19.52 21.02" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M11.14,12.11H13.4a.94.94,0,1,0,0-1.88H11.14A1.67,1.67,0,0,0,9.76,9.5h0L7.67,6.6a.94.94,0,0,0-1.31-.22.93.93,0,0,0-.22,1.31L8.2,10.6a1.8,1.8,0,0,0-.11.57,1.67,1.67,0,0,0,3.05.94Z"
        />
        <path
          fill={props.fill}
          d="M16.72,18A9.76,9.76,0,1,0,2.8,18l-.88.88a1.24,1.24,0,0,0,0,1.76A1.21,1.21,0,0,0,2.8,21a1.24,1.24,0,0,0,.88-.37l1.1-1.1a9.68,9.68,0,0,0,10,0l1.1,1.1a1.25,1.25,0,0,0,.88.37,1.21,1.21,0,0,0,.88-.37,1.26,1.26,0,0,0,0-1.76Zm-7-1.09a.63.63,0,0,0-.63.62v.85A7.26,7.26,0,0,1,2.54,11.8h.85A.63.63,0,0,0,4,11.17a.63.63,0,0,0-.63-.63H2.54A7.27,7.27,0,0,1,9.13,4V4.8a.63.63,0,0,0,.63.63.63.63,0,0,0,.63-.63V4A7.26,7.26,0,0,1,17,10.54h-.85a.63.63,0,0,0,0,1.26H17a7.25,7.25,0,0,1-6.59,6.59v-.85a.62.62,0,0,0-.63-.62Z"
        />
        <path
          fill={props.fill}
          d="M7.26.89A4.53,4.53,0,0,0,0,4.55,4.61,4.61,0,0,0,.37,6.29,10.66,10.66,0,0,1,7.26.89Z"
        />
        <path
          fill={props.fill}
          d="M19.15,6.29a4.62,4.62,0,0,0,.35-1.74A4.53,4.53,0,0,0,12.27.89a10.61,10.61,0,0,1,6.88,5.4Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgHorlogeVert;
