import React from 'react';

const SvgAdresseVert = (props: any) => (
  <svg viewBox="0 0 23.24 34.86" {...props}>
    <path
      d="M11.48 34.27a.22.22 0 0 0 .37 0c0-.05.94-1.42 2.21-3.51.29-.46.59-1 .9-1.49 3.22-5.39 7.79-13.73 7.78-17.67a11.12 11.12 0 1 0-22.24.05c0 3.52 3.81 10.83 7.16 16.51 0 0 3.2 5.19 3.82 6.11zM6 11.64a5.59 5.59 0 1 1 5.6 5.58A5.59 5.59 0 0 1 6 11.64z"
      fill={props.fill}
      stroke={props.stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      data-name="Calque 2"
    />
  </svg>
);

export default SvgAdresseVert;
