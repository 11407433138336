import React from 'react';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';

/**
 * Affiche si nécessaire le bouton de retour à l'étape
 * précédente
 *
 * @method Stats
 * @param {any} props Props du composant
 * @returns {JSX} Le composant
 */
const Stats = (props: any) => {
  const { currentStep, previousStep, t } = props;
  return (
    <div>
      {currentStep > 1 && (
        <Button color="primary" onClick={previousStep}>
          {' '}
          {t('all.button.back')}
        </Button>
      )}
    </div>
  );
};

export default withTranslation()(Stats);
