import React, { Fragment } from 'react';

/**
 * Rend un layout vide
 *
 * @method EmptyLayout
 * @param {any} props Props du composant
 */
export default function EmptyLayout(props: any) {
  const { children } = props;
  return <Fragment>{children}</Fragment>;
}
