import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class ValidateButtons extends Component<any, any> {
  render() {
    const { cancelOne, getAllCommunication, online, t } = this.props;
    return (
      <div className="waiting-action-button">
        <Button
          className="button-valid-drag"
          color="primary"
          onClick={cancelOne}
          style={{ borderRadius: '0', borderRight: '2px solid white', borderTop: 'none', outline: 'none' }}
        >
          {t('all.button.cancel')}
        </Button>{' '}
        <Button
          className="button-valid-drag"
          onClick={getAllCommunication}
          style={{ borderRadius: '0 0 10px 0', borderLeft: '2px solid white', borderTop: 'none', outline: 'none' }}
          disabled={!online}
        >
          {t('all.button.apply')}
        </Button>{' '}
      </div>
    );
  }
}
const tr = withTranslation()(ValidateButtons);
export default tr;
