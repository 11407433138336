import React, { Component } from 'react';
import './style.scss';
import styled from 'styled-components';
import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Row, Col, UncontrolledTooltip, Spinner, Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Column from './Column';
import synchroAction from '../../_actions/synchro.actions';
import synchroComAction from '../../_actions/synchrocom.actions';
import synchrocom from '../../_reducers/synchrocom.reducer';
import initialData from '../../SynchroResources/listDrag';
import DragButton from './DragButton';
import MultiSelectionButton from './MultiSelectionButton';

const Container = styled.div`
  display: flex;
  z-index: 0;
  width: 95%;
`;

class DragAndDrop extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      state: {
        tasks: props.synchro.tasks,
        columns: {
          'column-1': {
            id: 'column-1',
            title: props.t('all.round.round_plural'),
            taskIds: [],
          },
          'column-2': {
            id: 'column-2',
            title: props.t('all.device.phone_plural'),
            taskIds: [],
          },
        },
        columnOrder: ['column-1', 'column-2'],
      }, // insérer la liste ici
      labelTask: [],
      isAffect: [],
      onStart: false,
      onEnd: false,
      taskStarting: null,
      taskOverring: null,
      unDropColumn: '',
      multiDrag: false,
      dragSelect: [],
      multiDisplay: [],
      uncheckedTask: false,
      cmdPressed: false,
      checkedIsDisabled: false,
      checked: false,
      delAffect: {
        id: '',
        title: '',
      },
      addAffect: [],
      initialization: false,
      unDropAffect: [],
      loadingList: [],
      initialLabels: [],
      openclone: false,
      isOverContent: '',
      beforeStart: false,
      x: 0,
      y: 0,
      columnFiltered: {
        col1: 'default',
        col2: 'default',
        inversed1: false,
        inversed2: false,
      },
      partiel: [],
      hoverMulti: false,
    };

    this.removeTask = this.removeTask.bind(this);
    this.onDeleteAdd = this.onDeleteAdd.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onClickBorderLine = this.onClickBorderLine.bind(this);
    this.multiDragIsActiv = this.multiDragIsActiv.bind(this);
    this.multiDragSelect = this.multiDragSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeAdd = this.handleChangeAdd.bind(this);
    this.onClickCancelAll = this.onClickCancelAll.bind(this);
    this.getAllCommunication = this.getAllCommunication.bind(this);
    this.isOverring = this.isOverring.bind(this);
    this.onBeforeDragStart = this.onBeforeDragStart.bind(this);
    this.updateDrop = this.updateDrop.bind(this);
    this.dragStop = this.dragStop.bind(this);
    this.switchToPartial = this.switchToPartial.bind(this);
  }

  static getDerivedStateFromProps = (props: any, state: any) => {
    const { initialLabels } = state;
    const copyState = _.cloneDeep(state);
    if (props.synchro && props.synchro.labels && props.synchro.labels.affect) {
      copyState.partiel = props.synchro.labels.affect.filter((el: any) => el.partialOption) || copyState.partiel;
    }

    if (props && props.synchro && props.synchro.tasks) {
      copyState.state.tasks = _.cloneDeep(props.synchro.tasks);
      _.entries(copyState.state.tasks).map(el => {
        if (_.hasIn(el[1], 'rndCode') === true) {
          copyState.state.columns['column-1'].taskIds.push(el[0]);
          copyState.state.columns['column-1'].taskIds = _.uniqWith(
            copyState.state.columns['column-1'].taskIds,
            _.isEqual
          );
        } else {
          if (props.synchrocom && props.synchrocom.online && props.synchrocom.online[el[0]]) {
            copyState.state.columns['column-2'].taskIds.unshift(el[0]);
          } else {
            copyState.state.columns['column-2'].taskIds.push(el[0]);
          }
          copyState.state.columns['column-2'].taskIds = _.uniqWith(
            copyState.state.columns['column-2'].taskIds,
            _.isEqual
          );
        }
      });
    }
    let theList = [];
    if (
      props &&
      props.synchro &&
      props.synchro.labels &&
      props.synchro.labels.affect &&
      (props.synchro.labels.affect.length > 0 || props.synchro.labels.disaffect.length > 0)
    ) {
      theList = props.synchro.labels.affect.map(el => el.tournee + el.device);
      theList = theList.concat(props.synchro.labels.disaffect.map(el => el.tournee + el.device));
    }
    if (props.synchro && props.synchro.labels && props.synchro.tasks) {
      copyState.labelTask = [];
      (props.synchro.labels.affect || [])
        .filter(el => _.get(props, `synchro.tasks[${el.device}`) && _.get(props, `synchro.tasks[${el.tournee}`))
        .forEach((el: any) => {
          copyState.labelTask.push({
            draggableId: el.device,
            destination: { droppableId: el.tournee, index: NaN },
            taskTitle: props.synchro.tasks[el.device].content,
            taskId: el.device,
            partial: el.partialOption,
          });
          copyState.labelTask.push({
            draggableId: el.tournee,
            destination: { droppableId: el.device, index: NaN },
            taskTitle: props.synchro.tasks[el.tournee].content,
            taskId: el.tournee,
            partial: el.partialOption,
          });
        });
      (props.synchro.labels.disaffect || [])
        .filter(el => _.get(props, `synchro.tasks[${el.device}`) && _.get(props, `synchro.tasks[${el.tournee}`))
        .forEach((el: any) => {
          copyState.labelTask.push({
            draggableId: null,
            destination: { droppableId: el.tournee, index: 5 },
            taskTitle: props.synchro.tasks[el.device].content,
            taskId: el.device,
            partial: el.partialOption,
          });
          copyState.labelTask.push({
            draggableId: null,
            destination: { droppableId: el.device, index: 5 },
            taskTitle: props.synchro.tasks[el.tournee].content,
            taskId: el.tournee,
            partial: el.partialOption,
          });
        });

      copyState.initialLabels = theList;
    }

    if (props.checked !== state.cmdPressed) {
      copyState.cmdPressed = props.checked;
    }

    if (!props.checked) {
      copyState.dragSelect = [];
    }

    copyState.state.columns['column-1'].title = props.t('all.round.round_plural');
    copyState.state.columns['column-2'].title = props.t('all.device.phone_plural');

    return copyState;
  };

  componentDidUpdate() {
    const { checked, cmdPressed, initEvent, initialization } = this.state;
    const { com } = this.props;
    if (checked !== cmdPressed) {
      this.setState({
        checked: cmdPressed,
      });
      this.handleChangeAdd(cmdPressed);
    }

    const multiSelection = document.getElementById('svg-multi-selection');
    if (multiSelection && !initEvent) {
      multiSelection.addEventListener('mouseenter', () => this.setState({ hoverMulti: true }));
      multiSelection.addEventListener('mouseleave', () => this.setState({ hoverMulti: false }));
      this.setState({
        initEvent: true,
      });
    }
  }

  onDragStart(start) {
    const { state, unDropAffect } = this.state;
    const { synchro, dispatch } = this.props;
    for (const property in state.columns) {
      state.columns[property].taskIds.forEach((el: any) => {
        if (el === start.draggableId) {
          this.setState({
            unDropColumn: property,
          });
        }
      });
    }

    for (const property in state.tasks) {
      _.cloneDeep(state.tasks[start.draggableId].affectList).forEach((el: any) => {
        if (state.tasks[property].content === el) {
          unDropAffect.push(el);
        }
      });
    }
    const isUndroppable: any = [];
    _.cloneDeep(synchro.tasks[start.draggableId].affectList).forEach(element => {
      isUndroppable.push(element);
    });
    synchro.labels.affect.forEach((element: any) => {
      if (element.device === start.draggableId) {
        isUndroppable.push(element.tournee);
      }
      if (element.tournee === start.draggableId) {
        isUndroppable.push(element.device);
      }
    });
    dispatch(synchroAction.updateUndroppable(isUndroppable));

    this.setState({
      onStart: true,
      openclone: true,
      onEnd: false,
      taskStarting: start.draggableId,
      boolDrag: 'block',
      uncheckedTask: false,
      unDropAffect,
    });
    if (state && state.tasks && start && start.draggableId) {
      this.setState({
        titleOfLabel: _.cloneDeep(state.tasks[start.draggableId].content),
      });
    }
  }

  onBeforeDragStart(before: any) {
    this.setState({
      beforeStart: true,
    });
  }

  onDragEnd() {
    const { labelTask, state, multiDrag, dragSelect, taskStarting, taskOverring } = this.state;
    const { handleChangeChecked, dispatch, match, synchro } = this.props;
    const result = {
      combine: null,
      destination: {
        droppableId: taskOverring,
        index: NaN,
      },
      draggableId: taskStarting,
      source: {
        index: NaN,
        droppableId: taskOverring,
      },
    };
    const { params } = match;
    const { draggableId, destination, source } = result;
    let listMultiDrag = [];
    this.setState({
      homeIndex: null,
      taskStarting: null,
      onStart: false,
      onEnd: true,
      beforeStart: false,
    });

    const taskTitle = state.tasks[draggableId].content;
    if (
      destination &&
      destination.droppableId &&
      synchro.tasks[result.draggableId].affectList.find(
        el => el === synchro.tasks[result.destination.droppableId].content
      ) === undefined
    ) {
      const destinationTask = state.tasks[destination.droppableId];
      // ? TypeError: Cannot read property 'content' of undefined
      if (destination.droppableId !== 'column-1' && destination.droppableId !== 'column-2') {
        let newTaskTitle: any;
        let labelPrise: any;
        let labelDestination: any;
        if (state.tasks && state.tasks[destination.droppableId] && state.tasks[destination.droppableId].content) {
          newTaskTitle = state.tasks[destination.droppableId].content;
          labelPrise = { draggableId, destination, taskTitle };
          labelDestination = {
            draggableId: destination.droppableId,
            destination: { droppableId: draggableId, index: NaN },
            taskTitle: newTaskTitle,
          };
        }

        if (dragSelect && dragSelect.length > 0) {
          const newSelect = cloneDeep(dragSelect);
          const select = newSelect.map((el: any) => {
            return {
              draggableId: el.id,
              destination,
              taskTitle: el.content,
            };
          });
          const newDestination = cloneDeep(dragSelect);
          const destinationSelect = newDestination.map((el: any) => {
            if (el.affectList.find(el => el === newTaskTitle) === undefined) {
              return {
                draggableId: destination.droppableId,
                destination: { droppableId: el.id, index: NaN },
                taskTitle: newTaskTitle,
              };
            }
          });
          listMultiDrag = select;
          destinationSelect.forEach((element: any) => {
            listMultiDrag.push(element);
          });
          listMultiDrag = listMultiDrag.filter((el: any) => el !== undefined);
        }
        if (listMultiDrag.length > 0) {
          const multiLabels = {
            device: [],
            tournee: [],
          };
          listMultiDrag.forEach(el => {
            if (state.columns['column-2'].taskIds.find(element => element === el.draggableId)) {
              multiLabels.device.push(el.draggableId);
              multiLabels.tournee.push(el.destination.droppableId);
            }
          });
          multiLabels.device = _.uniqWith(multiLabels.device, _.isEqual);
          multiLabels.device.forEach((el: any) => {
            dispatch(synchroAction.addAffect(multiLabels.tournee, params.GestionnaireId, el));
          });
        } else if (state.columns['column-2'].taskIds.find(element => element === draggableId)) {
          dispatch(synchroAction.addAffect([destination.droppableId], params.GestionnaireId, draggableId));
        } else {
          dispatch(synchroAction.addAffect([draggableId], params.GestionnaireId, destination.droppableId));
        }

        let affect = false;
        if (!multiDrag && labelDestination) {
          for (const property in state.tasks) {
            // ?  TypeError: Cannot read property 'taskTitle' of undefined
            if (state.tasks[property].content === labelDestination.taskTitle) {
              _.cloneDeep(state.tasks[property].affectList).forEach(element => {
                if (element === labelPrise.taskTitle) {
                  affect = true;
                }
              });
            }
          }
        }
        if (multiDrag) {
          for (const property in state.tasks) {
            if (state.tasks[property].content === labelDestination.taskTitle) {
              _.cloneDeep(state.tasks[property].affectList).forEach(element => {
                if (element === labelPrise.taskTitle && dragSelect.length < 2) {
                  affect = true;
                }
              });
            }
          }
        }

        if (labelTask.length > 0) {
          if (
            labelPrise &&
            labelPrise.destination &&
            labelPrise.destination.droppableId !== labelPrise.draggableId &&
            affect === false
          ) {
            if (multiDrag && dragSelect && dragSelect.length > 0) {
              listMultiDrag.forEach(element => {
                if (destinationTask.affectList.find((el: any) => el === element.taskTitle) === undefined) {
                  labelTask.unshift(element);
                }
              });
              this.setState({
                dragSelect: [],
                multiDisplay: [],
              });
            } else {
              labelTask.forEach(element => {
                if (
                  element.taskTitle !== labelPrise.taskTitle ||
                  element.destination.droppableId !== labelPrise.destination.droppableId
                ) {
                  labelTask.unshift(labelPrise, labelDestination);
                  this.setState({
                    dragSelect: [],
                    multiDisplay: [],
                  });
                }
              });
            }
          }
        } else if (labelPrise.destination.droppableId !== labelPrise.draggableId && affect === false) {
          if (multiDrag && dragSelect && dragSelect.length > 0) {
            listMultiDrag.forEach(element => {
              if (destinationTask.affectList.find((el: any) => el === element.taskTitle) === undefined) {
                labelTask.unshift(element);
              }
            });
            this.setState({
              dragSelect: [],
              multiDisplay: [],
            });
          } else {
            labelTask.unshift(labelPrise, labelDestination);
          }
        }
      }
    }
    this.setState({
      uncheckedTask: true,
      unDropAffect: [],
      openclone: false,
    });
    handleChangeChecked(true);
  }

  removeTask(id, name) {
    const { labelTask, state, addAffect } = this.state;
    const { dispatch, match } = this.props;
    const { params } = match;
    const newLabels = _.cloneDeep(labelTask);
    const labelAffec = { id: null, name: null };
    labelAffec.id = _.first(
      _.entries(state.tasks)
        .filter(([key, value]) => value.id === name)
        .map(([key, value]) => value.id)
    );
    labelAffec.name = _.values(state.tasks).find(el => el.id === id).content;
    if (newLabels.length > 0) {
      newLabels.forEach((element: any) => {
        if (
          element &&
          element.destination &&
          element.destination.droppableId === id &&
          element.taskId === name &&
          element.destination.index === 5
        ) {
          addAffect.push({ draggableId: null, destination: { droppableId: id, index: 0 }, taskTitle: name });
          addAffect.push({
            draggableId: null,
            destination: { droppableId: labelAffec.id, index: 0 },
            taskTitle: labelAffec.name,
          });
          this.setState({
            addAffect,
          });
          if (state.columns['column-2'].taskIds.find(element => element === id)) {
            dispatch(synchroAction.delDesaffect([labelAffec.id], params.GestionnaireId, id));
          } else {
            dispatch(synchroAction.delDesaffect([id], params.GestionnaireId, labelAffec.id));
          }
        } else if (
          (id === element.draggableId || id === element.destination.droppableId) &&
          (name === element.draggableId || element.destination.droppableId)
        ) {
          if (state.columns['column-2'].taskIds.find(element => element === id)) {
            dispatch(synchroAction.delAffect([labelAffec.id], params.GestionnaireId, id));
          } else {
            dispatch(synchroAction.delAffect([id], params.GestionnaireId, labelAffec.id));
          }
        }
      });
      const lbl = newLabels.filter(
        (el: any) =>
          !(el.destination.droppableId === id && el.draggableId === name) &&
          !(el.destination.droppableId === name && el.draggableId === id)
      );
      this.setState({
        labelTask: lbl,
      });
    }
  }

  onDeleteAdd(droppableId: string, id: string) {
    const { labelTask, state } = this.state;
    const { dispatch, match } = this.props;
    const { params } = match;
    const newTitle = _.first(
      _.entries(state.tasks)
        .filter(([key, value]) => key === droppableId)
        .map(([key, value]) => value.content)
    );

    const scdTitle = _.entries(state.tasks)
      .filter(([key, value]) => value.id === id)
      .map(([key, value]) => value.content)[0];

    labelTask.push(
      { draggableId: null, destination: { droppableId, index: 5 }, taskTitle: scdTitle },
      { draggableId: null, destination: { droppableId: id, index: 5 }, taskTitle: newTitle }
    );
    if (state.columns['column-2'].taskIds.find(element => element === droppableId)) {
      dispatch(synchroAction.addDesaffect([id], params.GestionnaireId, droppableId));
    } else {
      dispatch(synchroAction.addDesaffect([droppableId], params.GestionnaireId, id));
    }
    this.setState({
      labelTask,
      delAffect: {
        id,
        title: newTitle,
      },
      addAffect: [],
    });
  }

  onClickBorderLine(task: any) {
    this.setState({
      isAffect: _.cloneDeep(task.affectList),
    });
  }

  multiDragIsActiv(isActive: Boolean) {
    this.setState({
      multiDrag: isActive,
      cmdPressed: isActive,
    });
  }

  handleChangeAdd(checked: any) {
    this.setState({ checked });
    this.multiDragIsActiv(checked);
  }

  handleChange(checked: any) {
    const { handleChangeChecked } = this.props;
    this.setState({ checked });
    handleChangeChecked(false);
    this.handleChangeAdd(checked);
  }

  multiDragSelect(task: any, action: string) {
    const { dragSelect, state } = this.state;
    let newSelect = cloneDeep(dragSelect);
    state.columnOrder.forEach((element: any) => {
      if (state.columns[element].taskIds.find(el => el === task.id) === undefined) {
        this.setState({
          checkedIsDisabled: element,
        });
      }
    });
    if (action === 'add') {
      newSelect.push(task);
      this.setState({
        dragSelect: newSelect,
      });
    } else {
      const cloneSelect = cloneDeep(newSelect);
      newSelect = cloneSelect.filter((el: any) => el.id != task.id);
      this.setState({
        dragSelect: newSelect,
      });
    }
  }

  onClickCancelAll(task: any) {
    const { labelTask, addAffect, state } = this.state;
    const { dispatch, match } = this.props;
    const { params } = match;
    const newLabels = cloneDeep(_.uniqWith(labelTask, _.isEqual));
    if (task && task.id) {
      newLabels.forEach((el: any) => {
        if (el.destination.index === 5) {
          addAffect.push({
            draggableId: null,
            destination: { droppableId: el.destination.droppableId, index: 0 },
            taskTitle: el.taskTitle,
          });
        }
      });
      if (_.has(task, 'serial')) {
        dispatch(synchroAction.deviceCancel(task.id, params.GestionnaireId));
      } else {
        dispatch(synchroAction.roundCancel(task.id, params.GestionnaireId));
      }
      this.setState({
        addAffect,
      });
    } else {
      dispatch(synchroAction.cancelAll(params.GestionnaireId));
      this.setState({
        labelTask: [],
      });
    }
  }

  deviceTest(task: any) {
    const { labelTask, state } = this.state;
    let result = false;
    state.columns['column-2'].taskIds.forEach((el: any) => {
      if (el === task) {
        result = true;
      }
    });
    return result;
  }

  getAllCommunication(taskId: any) {
    const { labelTask, state } = this.state;
    const { dispatch, match } = this.props;
    const { params } = match;
    const newLabels = _.cloneDeep(_.uniqWith(labelTask, _.isEqual));
    const affect: any = [];
    const desAffect: any = [];
    const loadList: any = [];
    newLabels.forEach((el: any) => {
      if (!_.has(taskId, 'id')) {
        if (el.destination.index !== 5) {
          if (this.deviceTest(el.draggableId)) {
            affect.unshift({ device: el.draggableId, tournee: el.destination.droppableId });
            dispatch(synchroComAction.updateTaskLoader(el.draggableId));
          } else {
            affect.unshift({ device: el.destination.droppableId, tournee: el.draggableId });
            dispatch(synchroComAction.updateTaskLoader(el.destination.droppableId));
          }
        } else if (this.deviceTest(el.destination.droppableId)) {
          const newId = _.values(state.tasks).find(element => element.content === el.taskTitle).id;
          desAffect.unshift({ device: el.destination.droppableId, tournee: newId });
          dispatch(synchroComAction.updateTaskLoader(el.destination.droppableId));
        } else {
          const newId = _.values(state.tasks).find(element => element.content === el.taskTitle).id;
          desAffect.unshift({ device: newId, tournee: el.destination.droppableId });
          dispatch(synchroComAction.updateTaskLoader(newId));
        }
      } else if (el.destination.droppableId === taskId.id || el.draggableId === taskId) {
        if (el.destination.index !== 5) {
          if (this.deviceTest(el.draggableId)) {
            affect.unshift({ device: el.draggableId, tournee: el.destination.droppableId });
            dispatch(synchroComAction.updateTaskLoader(el.draggableId));
          } else {
            affect.unshift({ device: el.destination.droppableId, tournee: el.draggableId });
            dispatch(synchroComAction.updateTaskLoader(el.destination.droppableId));
          }
        } else if (this.deviceTest(el.destination.droppableId)) {
          const newId = _.values(state.tasks).find(element => element.content === el.taskTitle).id;
          desAffect.unshift({ device: el.destination.droppableId, tournee: newId });
          dispatch(synchroComAction.updateTaskLoader(el.destination.droppableId));
        } else {
          const newId = _.values(state.tasks).find(
            element => element.content === el.taskTitle || element.id === el.taskTitle
          ).id;
          dispatch(synchroComAction.updateTaskLoader(newId));
          desAffect.unshift({ device: newId, tournee: el.destination.droppableId });
        }
      }
    });
    if (_.has(taskId, 'id')) {
      const currentLabels = newLabels.filter((el: any) => el.destination.droppableId !== taskId);
      if (_.has(taskId, 'serial')) {
        dispatch(synchroAction.deviceCom(taskId.id, params.GestionnaireId));
      } else {
        dispatch(synchroAction.roundCom(taskId.id, params.GestionnaireId));
      }
      this.setState({
        labelTask: currentLabels,
      });
    } else {
      dispatch(synchroAction.comWithAll(params.GestionnaireId));
    }

    this.setState({
      loadingList: loadList,
    });
  }

  isOverring(snapshot) {
    const { synchro } = this.props;
    const { isOverContent, taskStarting } = this.state;
    if (snapshot && snapshot.draggingOver !== taskStarting && synchro && synchro.labels) {
      if (snapshot.draggingOver !== isOverContent) {
        this.setState({
          isOverContent: snapshot.draggingOver,
        });
      }
    }
    if (snapshot && snapshot.draggingOver === null && isOverContent === true) {
      this.setState({
        isOverContent: '',
      });
    }
  }

  updateDrop(isOverring: any) {
    this.setState({
      taskOverring: isOverring,
    });
  }

  dragStop() {
    this.setState({
      onStart: false,
      taskStarting: null,
    });
  }

  updateTriMod = (mod: any, columnId: any, inversed: boolean) => {
    const { columnFiltered }: any = this.state;
    if (columnId === 'column-1') {
      this.setState({
        columnFiltered: {
          col1: mod,
          col2: columnFiltered.col2,
          inversed1: inversed,
          inversed2: columnFiltered.inversed2,
        },
      });
    } else {
      this.setState({
        columnFiltered: {
          col1: columnFiltered.col1,
          col2: mod,
          inversed1: columnFiltered.inversed1,
          inversed2: inversed,
        },
      });
    }
  };

  sortedList(tasks: any, columnId: any) {
    const extension = columnId === 'column-1' ? 'col1' : 'col2';
    const { columnFiltered }: any = this.state;
    const { synchro }: any = this.props;
    let copyTasks = _.cloneDeep(tasks);
    switch (columnFiltered[extension]) {
      case 'code':
        copyTasks = copyTasks.sort((a: any, b: any) => {
          if (a.serial) {
            return a.serial.toUpperCase() > b.serial.toUpperCase() ? 1 : -1;
          }
          return a.rndCode.toUpperCase() > b.rndCode.toUpperCase() ? 1 : -1;
        });
        break;
      case 'libelle':
        copyTasks = copyTasks.sort((a: any, b: any) => (a.content.toUpperCase() > b.content.toUpperCase() ? 1 : -1));
        break;
      case 'actions':
        const rounds = _.concat(
          synchro.labels.affect.map((el: any) => el.tournee),
          synchro.labels.disaffect.map((el: any) => el.tournee)
        );
        const devices = _.concat(
          synchro.labels.affect.map((el: any) => el.device),
          synchro.labels.disaffect.map((el: any) => el.device)
        );
        copyTasks = copyTasks.sort((a: any, b: any) => {
          if (a.serial) {
            const occuA = devices.filter((el: any) => el === a.serial).length;
            const occuB = devices.filter((el: any) => el === b.serial).length;
            return occuB - occuA;
          }
          const occuA = rounds.filter((el: any) => el === a.rndCode).length;
          const occuB = rounds.filter((el: any) => el === b.rndCode).length;
          return occuB - occuA;
        });
        break;
      case 'charges':
        copyTasks = _.cloneDeep(copyTasks).sort((a: any, b: any) => b.affectList.length - a.affectList.length);
        break;
      default:
        break;
    }
    if (columnId === 'column-1' && columnFiltered.inversed1) {
      copyTasks = _.reverse(copyTasks);
    }

    if (columnId === 'column-2' && columnFiltered.inversed2) {
      copyTasks = _.reverse(copyTasks);
    }

    return copyTasks;
  }

  switchToPartial(label: any, column: any) {
    const { dispatch, match } = this.props;
    const { partiel } = this.state;
    let copyPartiel = _.cloneDeep(partiel);
    let round = '';
    let device = '';
    if (label.firstId) {
      round = column.id === 'column-1' ? label.firstId : label.id;
      device = column.id === 'column-2' ? label.firstId : label.id;
    } else {
      round = column.id === 'column-1' ? label.destination.droppableId : label.draggableId;
      device = column.id === 'column-2' ? label.destination.droppableId : label.draggableId;
    }
    let isPartial = false;
    if (copyPartiel.find((el: any) => el.tournee === round && el.device === device)) {
      copyPartiel = copyPartiel.filter((el: any) => el.tournee !== round || el.device !== device);
    } else {
      copyPartiel.push({
        tournee: round,
        device,
      });
      isPartial = true;
    }
    this.setState({
      partiel: copyPartiel,
    });
    dispatch(synchroAction.switchToPartiel(match.params.GestionnaireId, device, [round], isPartial));
  }

  render() {
    const {
      filter,
      labels,
      synchro,
      stateTasks,
      x,
      y,
      stickyButton,
      handleFilterChange,
      handleChangeChecked,
      handleChange,
    } = this.props;
    const {
      titleOfLabel,
      state,
      labelTask,
      isAffect,
      onStart,
      taskStarting,
      unDropColumn,
      onEnd,
      dragSelect,
      uncheckedTask,
      checkedIsDisabled,
      checked,
      delAffect,
      addAffect,
      initialization,
      unDropAffect,
      loadingList,
      initialLabels,
      isOverContent,
      beforeStart,
      taskOverring,
      columns,
      columnFiltered,
      partiel,
      hoverMulti,
    } = this.state;
    const col1 = 'column-1';
    const newFilter = filter.round && filter.round.toUpperCase();
    const deviceFilter = filter.device && filter.device.toUpperCase();
    let newAffectState = [];
    if (state.tasks !== null && !initialization) {
      let i = 0;
      if (state && state.tasks) {
        newAffectState = _.values(state.tasks).map(el =>
          el.affectList.map(element => {
            if (state.tasks[element]) {
              return state.tasks[element].content;
            }
          })
        );
      }

      for (const task in state.tasks) {
        state.tasks[task].affectList = newAffectState[i];
        i++;
      }
      this.setState({
        initialization: true,
        state,
      });
    }
    let task;
    if (taskStarting) {
      task = state.tasks[taskStarting];
    }
    return (
      <div style={{ width: '100%', display: 'flex' }}>
        <Container>
          {state.columnOrder.map((columnId: any, index) => {
            const column = state.columns[columnId];

            let tasks = column.taskIds.map(taskId => {
              return synchro.tasks[taskId];
            });
            if (columnId === col1) {
              if (newFilter.length > 0) {
                tasks = tasks.filter(
                  el => el.content.toUpperCase().includes(newFilter) || el.id.toUpperCase().includes(newFilter)
                );
              } else {
                tasks = column.taskIds.map(taskId => synchro.tasks[taskId]);
              }
            } else if (deviceFilter.length > 0) {
              tasks = tasks.filter(
                el => el.content.toUpperCase().includes(deviceFilter) || el.id.toUpperCase().includes(deviceFilter)
              );
            } else {
              tasks = column.taskIds.map(taskId => synchro.tasks[taskId]);
            }
            if (columnId === 'column-1') {
              tasks = this.sortedList(tasks, columnId);
            } else {
              tasks = this.sortedList(tasks, columnId);
            }
            return (
              <Column
                key={column.id}
                column={column}
                tasks={tasks}
                allTasks={state.tasks}
                taskLabel={labelTask}
                titleOfLabel={titleOfLabel}
                removeTask={this.removeTask}
                onDeleteAdd={this.onDeleteAdd}
                onClickBorderLine={this.onClickBorderLine}
                isAffect={isAffect}
                onStart={onStart}
                onEnd={onEnd}
                taskStarting={taskStarting}
                unDropColumn={unDropColumn}
                multiDragIsActiv={this.multiDragIsActiv}
                multiDragSelect={this.multiDragSelect}
                multiDisplay={dragSelect}
                uncheckedTask={uncheckedTask}
                checkedIsDisabled={checkedIsDisabled}
                checked={checked}
                handleChangeAdd={this.handleChangeAdd}
                handleChange={this.handleChange}
                delAffect={delAffect}
                addAffect={addAffect}
                onClickCancelAll={this.onClickCancelAll}
                getAllCommunication={this.getAllCommunication}
                unDropAffect={unDropAffect}
                loadingList={loadingList}
                labels={labels}
                isOverring={this.isOverring}
                beforeStart={beforeStart}
                isOverContent={isOverContent}
                onDragStart={this.onDragStart}
                onDragEnd={this.onDragEnd}
                onBeforeDragStart={this.onBeforeDragStart}
                updateDrop={this.updateDrop}
                dragStop={this.dragStop}
                f
                taskOverring={taskOverring}
                handleFilterChange={handleFilterChange}
                updateTriMod={this.updateTriMod}
                columnFiltered={columnFiltered}
                switchToPartial={this.switchToPartial}
                partiel={partiel}
                filter={filter}
              />
            );
          })}
        </Container>
        <div className="multi-drag-bar">
          <MultiSelectionButton handleChangeChecked={handleChange} hoverMulti={hoverMulti} />
          <DragButton onClickCancelAll={this.onClickCancelAll} getAllCommunication={this.getAllCommunication} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { users, authentication, alert, locations, synchro, synchrocom } = state;
  const { user } = authentication;
  return {
    user,
    users,
    alert,
    locations,
    synchro,
    synchrocom,
  };
}

const mapping: any = connect(mapStateToProps)(DragAndDrop);

const connectedUser = withRouter(mapping);
const tr = withTranslation()(connectedUser);
export default tr;
