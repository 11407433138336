import React, { Component } from 'react';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import GestionUtilisateurBlanc from '../SvgComponents/GestionUtilisateurBlanc';
import WarningBand from '../Bands/Warning';

interface Props {
  users: Array<any>;
  handleSelect: Function;
  selectedUsers: Array<any>;
  t: Function;
}

class UserSelector extends Component<Props, any> {
  render() {
    const { users, handleSelect, selectedUsers, t } = this.props;
    const options = users && users.map(user => ({ value: user, label: user.email }));
    return (
      <div className="user-selector">
        <div className="filter-container">
          <div className="title flex-box">
            <GestionUtilisateurBlanc height="2em" fill="#31c6b3" />
            <h2>{t('all.user.user_plural')}</h2>
          </div>
          <div style={{ display: 'flex', marginTop: '20px' }}>
            <div style={{ width: '30%', marginRight: '20px' }}>
              <Select
                defaultValue={selectedUsers.map(el => options[el])}
                onChange={e => handleSelect(e)}
                isMulti
                name="colors"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
            {_.get(this.props, 'displayNotSet') && _.size(selectedUsers) === 0 && (
              <WarningBand message="Veuillez selectionner au moins 1 utilisateur" />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const tr = withTranslation()(UserSelector);
export default tr;
