import React from 'react';

const SvgInfoVert = (props: any) => (
  <svg viewBox="0 0 6.59 18.11" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          d="M1.31,3h0c.07.12.13.25.19.35s.16.19.23.29h0a2.29,2.29,0,0,0,.32.28,1,1,0,0,0,.38.2h0a1.72,1.72,0,0,0,.42.12,1.57,1.57,0,0,0,.41,0,1.5,1.5,0,0,0,.42,0A1.11,1.11,0,0,0,4,4.1h0a2.65,2.65,0,0,0,.39-.23,2.1,2.1,0,0,0,.28-.25l0,0A1.79,1.79,0,0,0,5,3.3,1.2,1.2,0,0,0,5.15,3s0,0,0-.07a1.76,1.76,0,0,0,.13-.41,2,2,0,0,0,0-.42,2,2,0,0,0,0-.42,2.35,2.35,0,0,0-.13-.41h0A1.67,1.67,0,0,0,4.8.67L4.73.61A2.24,2.24,0,0,0,4.41.35C4.29.29,4.19.22,4.06.16h0A1.71,1.71,0,0,0,3.68,0a2.13,2.13,0,0,0-.42,0,1.93,1.93,0,0,0-.41,0,2,2,0,0,0-.39.13h0a3.69,3.69,0,0,0-.35.19,3,3,0,0,0-.32.26h0A1.75,1.75,0,0,0,1.5.9c-.06.09-.12.22-.19.35h0a2.84,2.84,0,0,0-.13.38h0a2.13,2.13,0,0,0,0,.42,1.84,1.84,0,0,0,0,.41A4.25,4.25,0,0,0,1.31,3Z"
        />
        <path
          fill={props.fill}
          d="M5.76,14.68H5V6.85A.83.83,0,0,0,4.19,6H.83A.83.83,0,0,0,0,6.85V8.57a.83.83,0,0,0,.83.84H1.5v5.31H.83a.82.82,0,0,0-.83.83v1.73a.83.83,0,0,0,.83.83H5.76a.82.82,0,0,0,.83-.83V15.55A.86.86,0,0,0,5.76,14.68Z"
        />
      </g>
    </g>
  </svg>
);
export default SvgInfoVert;
