import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { Comparator, customFilter, textFilter } from 'react-bootstrap-table2-filter';
import { Col, Input, Row, UncontrolledTooltip } from 'reactstrap';
import PoubelleBleue from '../SvgComponents/PoubelleBleu';
import Edition from '../SvgComponents/EditionBleu';
import { alarmActions, alertActions, locationActions, masksActions } from '../_actions';
import confirm from '../_components';
import { alarms, locales, locations } from '../_interfaces/reducers';
import { User } from '../_entities/user';
import { Alert } from '../_entities/alert';
import translate from '../_helpers/locale-helpers';
import 'rc-switch/assets/index.css';
import LoadingBand from '../Bands/Loading';
import ErrorBand from '../Bands/Error';
import Eye from '../SvgComponents/Eye';
import SvgMasqueVert from '../SvgComponents/Masque_Vert';
import ListTools from '../List/ListTools';
import Ajout from '../SvgComponents/AjoutBleu';
import locale from '../_shared/Locale.json';
import userActions from '../_actions/user.actions';
import history from '../_helpers/history';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

interface Props {
  dispatch: Function;
  alarms: alarms;
  locales: locales;
  user: User;
  history: Array<string>;
  match: any;
  locations: locations;
  users: any;
  mask: any;
  t: Function;
}

/**
 * Liste des templates
 *
 * @class AlarmsComponent
 * @extends {React.Component<Props>}
 */
class MasksAlarm extends React.Component<Props, any> {
  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof AlarmsComponent
   */
  constructor(props: Props) {
    super(props);
    this.delete = this.delete.bind(this);
    this.state = {
      availableUsers: [],
      toUpdate: {},
      isOpen: false,
      typeAlarmFilter: '',
      filter: {},
    };
    this.applyFilter = this.applyFilter.bind(this);
    this.alarmTypeFilter = this.alarmTypeFilter.bind(this);
  }

  /**
   * Charge les traductions et les alarmes
   * au montage du composant
   *
   * @method componentDidMount
   * @memberof AlarmsComponent
   */
  componentDidMount() {
    const { dispatch, match, user, history, locations } = this.props;
    const { locationId } = match.params;
    console.log(locationId, this.props);
    dispatch(userActions.get(user.id));
    dispatch(locationActions.getInfos(locationId));
    dispatch(masksActions.getMasks(locationId));

    const mountFastFilters = localStorage.getItem('maskAlarmFilter-fast');
    if (mountFastFilters) {
      this.setState({ filter: JSON.parse(mountFastFilters) });
    }
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    const { users, locations, user }: any = this.props;
    const { filter } = this.state;
    const roleList = ['DIOPTASE', 'SUPERADMIN', 'ADMIN'];
    if (
      !roleList.includes(user.role.name) &&
      locations.fetchedLocation &&
      !locations.fetchedLocation.tournee && // CETTE LIGNE ALEXIS
      users.fetchedUser &&
      !users.fetchedUser.profils.find(
        el => el.locationCode === locations.fetchedLocation.code && el.profil.permissions.length > 0
      )
    ) {
      history.push('/forbidden');
    }
    if (
      filter.current !== prevState.filter.current ||
      filter[filter.current] !== prevState.filter[prevState.filter.current]
    ) {
      localStorage.setItem('maskAlarmFilter-fast', JSON.stringify(filter));
    }
  }

  /**
   * Vide le state redux des alarmes au
   * démontage du composant
   *
   * @method componentWillUnmount
   * @memberof AlarmsComponent
   */
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(alarmActions.clear());
  }

  /**
   * Gère les droits d'edition des alertes
   *
   * @returns {boolean} Le contrôle
   * @method couldEditOrDeleteAlert
   * @memberof AlarmsComponent
   */
  couldEditOrDeleteMask = () => {
    const { user, locations, users } = this.props;
    const roleList = ['DIOPTASE', 'SUPERADMIN', 'ADMIN'];
    if (roleList.includes(user.role.name)) {
      return true;
    }
    if (locations.fetchedLocation && locations.fetchedLocation.tournee) {
      // CETTE LIGNE ALEXIS
      return true;
    }
    if (
      users &&
      users.fetchedUser &&
      users.fetchedUser.profils.find(
        (el: any) =>
          el.profil.permissions.find((permission: any) => permission.name === 'edit.maskAlarm') &&
          el.locationCode === locations.fetchedLocation.code
      )
    ) {
      return true;
    }
    return false;
  };

  /**
   * Gère l'ouverture et fermeture de la modal
   *
   * @method toggle
   * @memberof AlarmCreatorComponent
   */
  toggleUpdate = () => {
    const { dispatch } = this.props;
    const { isOpen } = this.state;
    dispatch(alertActions.clear());
    this.setState({
      isOpen: !isOpen,
      toUpdate: {},
    });
  };

  /**
   * Formate les alarmes
   *
   * @returns {Array<Object>}
   * @method representItem
   * @memberof AlarmsComponent
   */
  representItem = () => {
    const { mask } = this.props;
    const { availableUsers } = this.state;
    return mask.items;
  };

  /**
   * Gère la suppression du template donné
   *
   * @async
   * @param {Object} e Evènement
   * @param {*} template Template
   * @method delete
   * @memberof AlarmsComponent
   */
  async delete(e: any, mask: any) {
    const { dispatch, t } = this.props;
    const { Fragment } = React;

    const result = await confirm({
      title: (
        <Fragment>
          <strong>{t('all.mask.delete_mask')}</strong>
        </Fragment>
      ),
      message: t('mask_alarm.text.delete_mask_action'),
      confirmText: t('all.button.delete'),
      confirmColor: 'danger',
      cancelColor: 'primary',
      cancelText: t('all.button.cancel'),
    });
    if (result) {
      dispatch(masksActions.deleteMask(mask.id));
    }
  }

  alarmTypeFilter() {
    const { filter } = this.state;
    const { t } = this.props;
    return (
      <Input
        placeholder={filter.listAlarm || t('all.filter.alarm_type_filter')}
        type="text"
        style={{ fontStyle: 'italic' }}
        onChange={e => {
          const cloneFilter = _.cloneDeep(filter);
          cloneFilter.listAlarm = e.target.value;
          cloneFilter.current = 'listAlarm';
          this.setState({ filter: cloneFilter });
          this.setState({
            typeAlarmFilter: e.target.value,
          });
        }}
      />
    );
  }

  applyFilter(alarmString: any) {
    const { typeAlarmFilter } = this.state;
    if (typeAlarmFilter.length > 0) {
      return alarmString.filter((el: any) =>
        el.listAlarm
          .map((alarm: any) => translate('fr', 'alarmType', alarm.name, locale))
          .join(', ')
          .includes(typeAlarmFilter)
      );
    }
    return alarmString;
  }

  /**
   * Methode de rendu
   *
   * @method render
   */
  render() {
    const { mask, match, locations, dispatch, t, users } = this.props;
    const { availableUsers, isOpen, filter } = this.state;
    const { locationId } = match.params;

    const alarmFilter = textFilter({
      placeholder: t('all.filter.mask_filter'), // custom the input placeholder
      className: 'mask-custom-text-filter', // custom classname on input
      onFilter: value => {
        const cloneFilter = _.cloneDeep(filter);
        cloneFilter.name = value;
        cloneFilter.current = 'name';
        if (cloneFilter.name !== filter.name) {
          this.setState({ filter: cloneFilter });
        }
      },
      defaultValue: filter.name,
      comparator: Comparator.LIKE, // default is Comparator.LIKE
    });

    const customTypeFilter = this.alarmTypeFilter();
    const columns = [
      {
        dataField: 'name',
        text: t('all.column_text.name'),
        sort: true,
        filter: alarmFilter, // apply text filter
      },
      {
        dataField: 'startDate',
        text: t('all.text.start_date'),
        sort: true,
        formatter: ListTools.formatDateDay,
      },
      {
        dataField: 'endDate',
        text: t('all.text.end_date'),
        sort: true,
        formatter: ListTools.formatDateDay,
      },
      {
        dataField: 'listAlarm',
        text: t('all.alarm_meter.alarms_list'),
        formatter: (list: Array<any>) => {
          return list.map(({ name }) => t(`alarm.type.${name.toLowerCase()}`)).join(', ');
        },
        filter: customFilter(),
        filterRenderer: (onFilter: any, column: any) => {
          return customTypeFilter;
        },
      },
      {
        dataField: 'action',
        isDummyField: true,
        align: 'center',
        text: '',
        formatter: (cellContent: any, row: Alert) => (
          <Row>
            <Col style={{ margin: 'auto' }} md="4">
              <a href={`/locations/${locationId}/mask/info?id=${row.id}`}>
                <div id={`mask${row.id}-view`} className="round" role="presentation">
                  <Eye fill="curentcolor" height="1em" width="1em" />
                  <UncontrolledTooltip placement="bottom" target={`mask${row.id}-view`}>
                    {' '}
                    {t('mask_alarm.button.access_card')}
                  </UncontrolledTooltip>
                </div>
              </a>
            </Col>
            <Col style={{ margin: 'auto' }} md="4">
              {this.couldEditOrDeleteMask() ? (
                <a href={`/locations/${locationId}/mask/editmask?id=${row.id}`}>
                  <div id={`addMask-${row.id}`} className="round" role="presentation">
                    <Edition className="add" fill="curentcolor" height="1em" width="1em" />
                    <UncontrolledTooltip placement="bottom" target={`addMask-${row.id}`}>
                      {' '}
                      {t('all.mask.edit_mask')}
                    </UncontrolledTooltip>
                  </div>
                </a>
              ) : (
                <div id={`addMask-${row.id}`} className="round-disabled edit-alert" role="presentation">
                  <Edition fill="white" height="1em" width="1em" />
                </div>
              )}
            </Col>
            <Col md="4">
              {this.couldEditOrDeleteMask() ? (
                <div
                  id={`mask${row.id}-remove`}
                  className="clickable round delete-alert"
                  role="presentation"
                  onClick={e => this.delete(e, row)}
                >
                  <PoubelleBleue height="1em" width="1em" />
                  <UncontrolledTooltip placement="bottom" target={`mask${row.id}-remove`}>
                    {' '}
                    {t('all.mask.delete_mask')}
                  </UncontrolledTooltip>
                </div>
              ) : (
                <div id={`mask${row.id}-remove`} className="round-disabled delete-alert" role="presentation">
                  <PoubelleBleue height="1em" width="1em" fill="white" />
                </div>
              )}
            </Col>
          </Row>
        ),
      },
    ];
    return (
      <div className="col-12">
        {mask.loading && <LoadingBand message="Chargement des alertes" />}
        {!mask.error && locations.error && <ErrorBand message={locations.error} />}
        {mask.error && <ErrorBand message={mask.error} />}
        {mask.items && locations && locations.fetchedLocation && users.fetchedUser && (
          <div className="mask-alarm table-info-container">
            {' '}
            <div>
              <h2>
                <span>
                  <SvgMasqueVert height="1em" width="1em" fill="#31c6b3" />
                </span>
                {t('all.mask.mask_plural')} :
                {this.couldEditOrDeleteMask() && (
                  <a href={`/locations/${locationId}/mask/add`}>
                    <span className="addItem float-right">
                      {' '}
                      <div id="addAlert" role="presentation">
                        <Ajout className="add" />
                        <UncontrolledTooltip placement="bottom" target="addAlert">
                          {' '}
                          {t('all.mask.add_mask')}
                        </UncontrolledTooltip>
                      </div>
                    </span>
                  </a>
                )}
              </h2>
            </div>
            <BootstrapTable
              keyField="id"
              data={this.applyFilter(mask.items)}
              columns={columns}
              bootstrap4
              bordered={false}
              hover
              filter={filterFactory()}
              pagination={mask.items.length > 10 ? paginationFactory() : null}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, alarms, alert, locales, locations, mask, users } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    alarms,
    locales,
    locations,
    mask,
    users,
  };
}

const wt: any = connect(mapStateToProps)(MasksAlarm);
const connectedAlarms = withRouter(wt);
const tr = withTranslation()(connectedAlarms);
export default tr;
