import React from 'react';

const SvgImportBleu = (props: any) => (
  <svg viewBox="0 0 23.65 23.64" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          d="M23.39.25a.85.85,0,0,0-1.19,0L10.91,11.63V7.69a.85.85,0,1,0-1.7,0v6a.86.86,0,0,0,.85.86h6a.86.86,0,0,0,.85-.86.85.85,0,0,0-.85-.85h-4L23.41,1.44A.85.85,0,0,0,23.39.25Z"
          fill={props.fill}
        />
        <path
          d="M19.79,12.93a.86.86,0,0,0-.86.85v6.6a1.57,1.57,0,0,1-1.55,1.56H3.26a1.57,1.57,0,0,1-1.55-1.56V6.27A1.56,1.56,0,0,1,3.26,4.71h6.6A.86.86,0,1,0,9.86,3H3.26A3.28,3.28,0,0,0,0,6.27V20.38a3.27,3.27,0,0,0,3.26,3.26H17.38a3.27,3.27,0,0,0,3.26-3.26v-6.6A.85.85,0,0,0,19.79,12.93Z"
          fill={props.fill}
        />
      </g>
    </g>
  </svg>
);

export default SvgImportBleu;
