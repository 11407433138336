import reportConstants from '../_constants/report.constants';
import { action } from '../_interfaces/action';

export default function reports(state: any = {}, action: action) {
  switch (action.type) {
    case reportConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case reportConstants.GETALL_SUCCESS:
      return {
        items: action.reports,
      };
    case reportConstants.GETALL_FAILURE:
      return {
        error: "Impossible d'obtenir la liste des rapports",
      };
    case reportConstants.GET_REQUEST:
      return {
        loading: true,
      };
    case reportConstants.GET_SUCCESS:
      return {
        fetchedReport: action.fetchedReport,
      };
    case reportConstants.GET_FAILURE:
      return {
        error: "Impossible d'obtenir le rapport",
      };
    case reportConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
