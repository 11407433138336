import { authHeader, handleResponse } from '../_helpers';

async function getAllGestionnaires() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/synchro/gestionnaire`, requestOptions);
  return handleResponse(response);
}

async function getAllGestionnairesByID(userID: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/synchro/gestionnaire/user/${userID}`, requestOptions);
  return handleResponse(response);
}

async function getGestionnaire(gestionnaireId: any) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/synchro/gestionnaire/${gestionnaireId}`, requestOptions);
  return handleResponse(response);
}

async function create(nameGestionnaire: string, numeroGestionnaire: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  const response = await fetch(
    `/api/synchro/gestionnaire/${nameGestionnaire}/site/${numeroGestionnaire}`,
    requestOptions
  );
  return handleResponse(response);
}

async function updateGestionnaireSiteId(gestionnaireId: number, gestionnaireName: string, siteId: number) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: gestionnaireName,
  };
  const response = await fetch(`/api/synchro/gestionnaire/${gestionnaireId}/site/${siteId}`, requestOptions);
  return handleResponse(response);
}

const reportService = {
  getAllGestionnaires,
  getAllGestionnairesByID,
  getGestionnaire,
  create,
  updateGestionnaireSiteId,
};

export default reportService;
