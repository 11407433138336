import React from 'react';
import { Alert } from 'reactstrap';

export default function ErrorBand(props: any) {
  const { message } = props;
  return (
    <Alert style={props.style} color="warning">
      {' '}
      {message}
    </Alert>
  );
}
