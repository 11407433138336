import React from 'react';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FaDatabase, FaDesktop } from 'react-icons/all';
import { User } from '../_entities/user';
import ColonneVert from '../SvgComponents/ColonneVert';
import { getClassNameByRegexp } from '../_helpers';
import SvgImportBleu from '../SvgComponents/ImportBleu';
import SynchroHeader from '../SvgComponents/SynchroHeader';
import SvgTeleReleve from '../SvgComponents/LogoTeleReleve';
import TelephoneVert from '../SvgComponents/TelephoneVert';
import SvgReport from '../SvgComponents/Report';
import SvgExportBleu from '../SvgComponents/ExportBleu';

interface Props {
  user: User;
  locations: any;
  location: any;
}
interface State {
  isOpen: boolean;
}

class SidebarSupportComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  render() {
    const { isOpen } = this.state;
    const { location, t } = this.props;
    return (
      <div className="sidebar-location">
        <Navbar className="sidebar-location-wrapper sidebar-stock" dark expand="md">
          <div>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav navbar>
                <NavbarBrand
                  href="/support/importbilling"
                  className="center"
                  style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                >
                  Support
                </NavbarBrand>
                <NavItem>
                  <NavLink
                    className={getClassNameByRegexp(location.pathname, RegExp('/support/importbilling\\/?$'))}
                    href="/support/importbilling"
                  >
                    <SvgImportBleu
                      height="1em"
                      width="1em"
                      stroke="currentcolor"
                      fill="currentcolor"
                      style={{ marginRight: '5px' }}
                    />
                    Import facturation
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={getClassNameByRegexp(location.pathname, RegExp('/support/general\\/?$'))}
                    href="/support/general"
                  >
                    <ColonneVert
                      fill="currentcolor"
                      stroke="currentcolor"
                      height="1em"
                      width="1em"
                      style={{ marginRight: '5px' }}
                    />
                    General
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={getClassNameByRegexp(location.pathname, RegExp('/support/export\\/?$'))}
                    href="/support/export"
                  >
                    <SvgExportBleu
                      fill="currentcolor"
                      stroke="currentcolor"
                      height="1em"
                      width="1em"
                      style={{ marginRight: '5px' }}
                    />
                    Import/Export
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={getClassNameByRegexp(location.pathname, RegExp('/support/sync\\/?$'))}
                    href="/support/sync"
                  >
                    <SynchroHeader
                      fill="currentcolor"
                      stroke="currentcolor"
                      height="1em"
                      width="1em"
                      style={{ marginRight: '5px' }}
                    />
                    Synchronisation
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={getClassNameByRegexp(location.pathname, RegExp('/support/remotereading\\/?$'))}
                    href="/support/remotereading"
                  >
                    <SvgTeleReleve
                      fill="currentcolor"
                      stroke="currentcolor"
                      height="2em"
                      width="2em"
                      style={{ marginRight: '-2px', marginLeft: '-5px' }}
                    />
                    Telereleve
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={getClassNameByRegexp(location.pathname, RegExp('/support/report\\/?$'))}
                    href="/support/report"
                  >
                    <SvgReport fill="currentcolor" height="1.5em" width="1.5em" style={{ marginLeft: '-3px' }} />
                    Rapport
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={getClassNameByRegexp(location.pathname, RegExp('/support/synchrotools\\/?$'))}
                    href="/support/synchrotools"
                  >
                    <TelephoneVert
                      fill="currentcolor"
                      stroke="currentcolor"
                      height="1.5em"
                      width="1.5em"
                      style={{ marginRight: '-2px', marginLeft: '-5px' }}
                    />
                    Synchro tools
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={getClassNameByRegexp(location.pathname, RegExp('/support/logs/cc\\/?$'))}
                    href="/support/logs/cc"
                  >
                    <FaDesktop />
                    Logs CrystalCloud
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={getClassNameByRegexp(location.pathname, RegExp('/support/logs/ccws\\/?$'))}
                    href="/support/logs/ccws"
                  >
                    <FaDatabase />
                    Logs CrystalCloud WS
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, dashboards, locations } = state;
  const { user } = authentication;
  return {
    user,
    dashboards,
    locations,
  };
}

const mapping: any = connect(mapStateToProps)(SidebarSupportComponent);

const connectedSidebarSupport = withRouter(mapping);
const tr = withTranslation()(connectedSidebarSupport);
export default tr;
