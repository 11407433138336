import React, { useEffect } from 'react';
import Select from 'react-select';

const CustomSelect = props => {
  const { onFilter, defaultValue } = props;
  useEffect(() => {
    if (defaultValue) {
      onFilter(defaultValue.field);
    }
  }, []);

  return <Select {...props} />;
};

export default CustomSelect;
