export default [
  'id',
  'meter.id',
  'meter.diameter',
  'radio.id',
  'lat',
  'lng',
  'alt',
  'gpsPosition.lat',
  'gpsPosition.lng',
  'gpsPosition.alt',
  'read.meterIndex',
  'read.radioIndex',
  'meter.pulseWeight',
];
