import React from 'react';

const SvgPartielVert = (props: any) => (
  <svg viewBox="0 0 200 200" {...props}>
    <g id="Calque_2" />
    <g id="Calque_1">
      <g>
        <g>
          <path
            fill={props.fill}
            d="M173.9,99.6l-23.9-3.9c-0.4-0.1-0.8-0.1-1.2,0l-23.3,3.8l-23.4-3.8c-0.4-0.1-0.8-0.1-1.2,0l-23.9,3.9
				c-1.7,0.3-3,1.7-3,3.4v13.1c0,1.9,1.6,3.5,3.6,3.5c2,0,3.6-1.6,3.6-3.5v-10.2l20.4-3.3l20.4,3.3v10.2c0,1.9,1.6,3.5,3.6,3.5
				c0.1,0,0.2,0,0.4,0c1.8-0.2,3.2-1.7,3.2-3.4v-10.2l20.4-3.3l20.4,3.3v10.2c0,1.9,1.6,3.5,3.6,3.5c2,0,3.6-1.6,3.6-3.5V103
				C176.9,101.3,175.6,99.9,173.9,99.6"
          />
          <path
            fill={props.fill}
            d="M173.3,154.2c-2,0-3.6,1.6-3.6,3.5v10.2l-20.4,3.3l-20.4-3.3v-10.2c0-0.8-0.3-1.6-0.8-2.2
				c-0.2-0.3-0.5-0.5-0.8-0.7c-0.6-0.4-1.3-0.6-2-0.6c-2,0-3.6,1.6-3.6,3.5v10.2l-20.4,3.3l-20.4-3.3v-10.2c0-1.9-1.6-3.5-3.6-3.5
				c-2,0-3.6,1.6-3.6,3.5v13.1c0,1.7,1.3,3.1,3,3.4l23.9,3.9c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0l23.4-3.8l23.4,3.8
				c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0l23.9-3.9c1.7-0.3,3-1.7,3-3.4v-13.1C176.8,155.8,175.2,154.2,173.3,154.2"
          />
        </g>
        <path
          fill={props.fill}
          d="M122.7,91.6V49c0-2.4-2-4.4-4.4-4.4h-15.4V26.3c0-1.4-0.6-2.7-1.8-3.5c-1.1-0.9-2.5-1.1-3.9-0.7L27,44.2
			c-0.9,0.3-1.7,0.8-2.3,1.5c-1,0.8-1.6,2-1.6,3.4v109.4c0,2.4,2,4.4,4.4,4.4h39.1v-5.2c0,0,0-0.1,0-0.1V103c0-5.3,3.8-9.8,9.1-10.6
			l23.9-3.9c1.2-0.2,2.4-0.2,3.5,0L122.7,91.6z M94,32.1v12.5H49.6L94,32.1z"
        />
      </g>
    </g>
  </svg>
);

export default SvgPartielVert;
