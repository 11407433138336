import React from 'react';

const SvgEmptyMeter = (props: any) => (
  <svg viewBox="0 0 500 500" {...props}>
    <path
      fill={props.fill}
      d="M363.9,465.2H137.1c-13.5,0-24.5-10.5-25.3-23.8l-1.2-5.9H66.4c-0.3,0.4-0.6,0.8-0.9,1.2
			c-3.1,3.7-7.4,6-12.2,6.5c-0.6,0.1-1.2,0.1-1.8,0.1c-1.6,0-3.2-0.2-4.8-0.6c-1,0.3-2,0.4-3,0.5c-0.6,0.1-1.2,0.1-1.8,0.1
			c-1.6,0-3.2-0.2-4.8-0.6c-1,0.3-2,0.4-3,0.5c-0.6,0.1-1.2,0.1-1.8,0.1c-6.2,0-11.6-3.1-14.9-7.8C8.4,434.6,1,426.8,1,417.3v-48.7
			c0-6.7,3.7-12.6,9.1-15.7c0.6-1.3,1.4-2.5,2.3-3.6c3.1-3.7,7.4-6,12.2-6.5c2.2-0.2,4.4,0,6.5,0.5c1-0.3,2-0.4,3-0.5
			c2.2-0.2,4.4,0,6.5,0.5c1-0.3,2-0.4,3-0.5c4.8-0.5,9.5,1,13.2,4c1.3,1.1,2.4,2.3,3.3,3.6H93L80,287.7v-1.4
			c0-14,11.4-25.4,25.4-25.4h290.2c14,0,25.4,11.4,25.4,25.4v1.4L408,350.4h32.1c2.9-4.2,7.6-7.1,13-7.6c2.2-0.2,4.4,0,6.5,0.5
			c1-0.3,2-0.4,3-0.5c2.2-0.2,4.4,0,6.5,0.5c1-0.3,2-0.4,3-0.5c4.8-0.5,9.4,0.9,13.2,4c1.9,1.5,3.4,3.4,4.5,5.5
			c6,3,10.2,9.2,10.2,16.3v48.7c0,2.7-0.6,5.4-1.8,7.8c0,4.2-1.4,8.3-4.1,11.6c-3.1,3.7-7.4,6-12.2,6.5c-0.6,0.1-1.2,0.1-1.8,0.1
			c-1.6,0-3.2-0.2-4.8-0.6c-1,0.3-2,0.4-3,0.5c-0.6,0.1-1.2,0.1-1.8,0.1c-1.6,0-3.2-0.2-4.8-0.6c-1,0.3-2,0.4-3,0.5
			c-0.6,0.1-1.2,0.1-1.8,0.1c-6.1,0-11.6-3-14.8-7.8h-55.9l-1.2,5.9C388.5,454.7,377.4,465.2,363.9,465.2z M36.4,376.9h-8.9v32h12
			h1.9h90.6l6.1,29.7h224.6v-0.2l6.1-29.5h99.3h5.3v-32h-6.5h-1.9h-89.6l18.4-89.5H107l18.4,89.5H38.3H36.4z M481.8,376.9
			L481.8,376.9L481.8,376.9z"
    />
    <path
      fill={props.fill}
      d="M424.6,273.7H76.4c-9.8,0-19.1-4.8-26.1-13.4C41,248.8,37,232,40,217.5c17.7-83.8,60.9-154.1,65.7-161.8
			c4.9-7.9,15.5-21.1,30.5-21.1h228.6c10.9,0,21.1,7.5,30.5,22.4c17.6,28,51.2,93.7,65.7,162.2c3,14.2-0.7,29.8-9.7,40.8
			C444.3,268.8,434.8,273.7,424.6,273.7z M136.4,61.2c-1.2,0.4-4.6,2.9-8.2,8.7C111.5,96.4,79.6,158.6,66,223
			c-1.4,6.8,0.6,15.2,4.9,20.5c0.9,1.1,3.2,3.6,5.5,3.6h348.2c2.7,0,5-2.3,6.1-3.8c3.9-4.8,5.6-12.3,4.3-18.7
			c-13.7-64.9-45.6-127.1-62.2-153.6c-4.2-6.7-7.4-9.4-8.6-10H136.4z M136.5,61.1L136.5,61.1z M364,61L364,61L364,61z"
    />
  </svg>
);

export default SvgEmptyMeter;
