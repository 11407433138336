import React from 'react';

const SvgExportBleu = (props: any) => (
  <svg viewBox="0 0 86.72 86.71" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M34.7,52.48a3.13,3.13,0,0,0,4.38,0L80.46,10.73V25.16a3.13,3.13,0,1,0,6.26,0v-22A3.13,3.13,0,0,0,83.59,0h-22a3.13,3.13,0,0,0,0,6.25H76.09L34.63,48.11A3.1,3.1,0,0,0,34.7,52.48Z"
        />
        <path
          fill={props.fill}
          d="M72.56,47.41a3.12,3.12,0,0,0-3.12,3.12V74.74a5.74,5.74,0,0,1-5.72,5.71H12a5.72,5.72,0,0,1-5.71-5.71V23A5.71,5.71,0,0,1,12,17.27h24.2a3.13,3.13,0,0,0,0-6.26H12A12,12,0,0,0,0,23V74.74a12,12,0,0,0,12,12H63.72a12,12,0,0,0,12-12V50.53A3.13,3.13,0,0,0,72.56,47.41Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgExportBleu;
