import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Badge, Button, CustomInput, FormGroup, FormText, Input, Row, UncontrolledTooltip } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Select, { components } from 'react-select';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { enqueteActions } from '../_actions';
import EnqueteSvg from '../SvgComponents/EnqueteSvg';
import Ajout from '../SvgComponents/AjoutBleu';
import PoubelleBleue from '../SvgComponents/PoubelleBleu';
import Loading from '../_animations/Loading';
import WarningBand from '../Bands/Warning';
import { Simulate } from 'react-dom/test-utils';
import select = Simulate.select;
import { Tooltip } from '@material-ui/core';

interface SelectError {
  field: number;
  message: string | undefined | null;
}
function mapStateToProps({ enquete }) {
  return { enquete };
}

const SortableMultiValue = SortableElement(props => {
  const onMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableSelect = SortableContainer(Select);

class EnquetePageAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      fields: [{ type: 'A' }],
      fillFetchedEnquete: props.location.pathname.endsWith('add'),
      inputValue: '',
      selectErrors: [],
    };
    this.changeValue = this.changeValue.bind(this);
    this.saveEnquete = this.saveEnquete.bind(this);
    this.removeField = this.removeField.bind(this);
    this.codeListChange = this.codeListChange.bind(this);
    this.userInputChange = this.userInputChange.bind(this);
    this.changeCodeOfField = this.changeCodeOfField.bind(this);
    this.getBadgeOfBeginningLine = this.getBadgeOfBeginningLine.bind(this);
    this.checkField = this.checkField.bind(this);
    this.resetDefaultValue = this.resetDefaultValue.bind(this);
  }

  componentDidMount() {
    const { dispatch, match, history } = this.props;
    if (history.location.pathname.match(/enquetes\/[0-9A-Z]/)) {
      dispatch(enqueteActions.getByNumPage(match.params.enqueteNumPage));
    }
  }

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any) {
    const { history, enquete, match } = this.props;
    const { selectErrors: preSelectErrors } = prevState;
    const { selectErrors } = this.state;
    if (enquete.pageSaved) {
      history.push({
        pathname: `/gestionnaires/${match.params.GestionnaireId}/synchronisation/enquetes`,
      });
    }
    if (!this.state.fillFetchedEnquete && this.props.enquete.fetchedEnquete) {
      this.setState({
        fields: this.props.enquete.fetchedEnquete.zoneList.map(el => {
          el.codes = (el.listLabel || []).map(this.createOption);
          return el;
        }),
        name: this.props.enquete.fetchedEnquete.enqName,
        codeList: this.props.enquete.fetchedEnquete.codeList.map(el => this.createOption(el)),
        fillFetchedEnquete: true,
      });
    }

    if (selectErrors.length > preSelectErrors.length) {
      setTimeout(() => {
        this.setState({ selectErrors: preSelectErrors });
      }, 4000);
    }
  }

  checkIfNoEmoji(textTest: string) {
    return !/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gm.test(
      textTest
    );
  }

  changeValue(num: number, field: string, value: string) {
    if (this.checkIfNoEmoji(value)) {
      const copyState = _.cloneDeep(this.state);
      _.set(copyState, `fields.${num}.${field}`, value);
      if (field === 'type') {
        _.set(copyState, `fields.${num}.defaultValue`, '');
      }
      this.setState(copyState);
    }
  }

  resetDefaultValue(num: number) {
    const copyState = _.cloneDeep(this.state);
    _.set(copyState, `fields.${num}.defaultValue`, '');
    this.setState(copyState);
  }

  changeCodeOfField(num: number, value: any) {
    const { selectErrors } = this.state;
    const { t } = this.props;
    if (_.size(value) <= 8) {
      const filteredErrors = selectErrors.filter((error: SelectError) => error.field != num);
      const copyState = _.cloneDeep(this.state);
      _.set(copyState, `fields.${num}.listLabel`, value.map(el => el.value));
      _.set(copyState, `fields.${num}.codes`, value);
      _.set(copyState, `selectErrors`, filteredErrors);
      this.setState(copyState);
    } else {
      if (!selectErrors.find((error: SelectError) => error.field == num)) {
        this.setState({
          selectErrors: [
            ...selectErrors,
            {
              field: num,
              message: t('enquete.error.choice_max_length'),
            },
          ],
        });
      }
    }
  }

  changeNameEnquete(enqName: string) {
    if (this.checkIfNoEmoji(enqName)) {
      this.setState({ name: enqName });
    }
  }

  removeField(num: number) {
    this.setState({ fields: _.filter(this.state.fields, (n, i) => i !== num) });
  }

  checkLibelle(libelle: string) {
    return libelle && _.size(libelle) <= 16;
  }

  checkTaille(taille: number) {
    return taille % 1 === 0 && taille <= 30 && taille > 0;
  }

  checkDefaultValue(defaultValue: string, taille: number) {
    return _.size(defaultValue) <= (taille || 30);
  }

  checkField(field: object): boolean {
    return (
      this.checkLibelle(_.get(field, 'libelle')) &&
      ((this.checkTaille(_.get(field, 'taille', 30) || 30) &&
        this.checkDefaultValue(_.get(field, 'defaultValue'), field.taille || 30)) ||
        (field.type === 'C' && !_.isEmpty(field.codes)))
    );
  }

  getBadgeOfBeginningLine(indexLine: number) {
    const { fields } = this.state;
    const currentField = fields[indexLine];

    return (
      <Badge
        color="white"
        pill
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '3em',
          marginRight: '20px',
        }}
      >
        {this.checkField(currentField) ? <FaCheck fill="#28a745" /> : <FaTimes fill="#dc3545" />}
      </Badge>
    );
  }

  saveEnquete() {
    const { dispatch, match, enquete } = this.props;
    const { name, fields, fillFetchedEnquete, codeList } = this.state;
    const mapCodeList = (codeList || []).map(el => el.value);
    const clonedFields = _.cloneDeep(fields).filter(this.checkField);
    _.range(_.size(clonedFields)).forEach(el => delete clonedFields[el].codes);
    if (enquete.fetchedEnquete) {
      dispatch(
        enqueteActions.updatePage(
          { enqName: name, zoneList: clonedFields, codeList: mapCodeList },
          match.params.enqueteNumPage
        )
      );
    } else {
      dispatch(enqueteActions.savePage({ enqName: name, zoneList: clonedFields, codeList: mapCodeList }));
    }
  }

  codeListChange() {
    const { inputValue, codeList } = this.state;
    const newValue = codeList || [];
    if (_.size(inputValue) > 0 && _.size(inputValue) <= 20) {
      newValue.push(this.createOption(inputValue));
      this.setState({
        inputValue: '',
        codeList: _.uniqBy(newValue, 'value'),
      });
    }
  }

  handleInputChange = (inputValue: string) => {
    this.setState({
      inputValue: _.size(this.state.codeList) >= 16 ? '' : _.size(inputValue) > 20 ? this.state.inputValue : inputValue,
    });
  };

  userInputChange = (userInput: any) => {
    const { fields } = this.state;
    const cloneFields = _.cloneDeep(fields);
    cloneFields
      .filter(el => el.type === 'C')
      .forEach(el => {
        _.set(
          el,
          `listLabel`,
          _.get(el, 'listLabel', []).filter(lab => (userInput || []).map(u => u.value).includes(lab))
        );
        _.set(
          el,
          `codes`,
          _.get(el, 'codes', []).filter(lab => (userInput || []).map(u => u.value).includes(lab.value))
        );
        if (!(userInput || []).map(u => u.value).includes(_.get(el, 'defaultValue'))) {
          _.set(el, `defaultValue`, '');
        }
      });
    this.setState({ codeList: userInput, fields: cloneFields });
  };

  createOption(label: string) {
    return {
      label,
      value: label,
    };
  }

  handleKeyDown = (event: any) => {
    const { inputValue } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
        this.codeListChange();
        event.preventDefault();
      default:
    }
  };

  onBlur = () => {
    const { inputValue } = this.state;
    if (!inputValue) return;
    this.codeListChange();
  };

  arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  }

  onSortEnd = ({ oldIndex, newIndex }, index) => {
    const { fields } = this.state;
    const newColumns = this.arrayMove(_.get(fields, `[${index}].codes`), oldIndex, newIndex);
    this.changeCodeOfField(index, newColumns);
  };

  render() {
    const { enquete, t } = this.props;
    const { fields, name, codeList, inputValue, fillFetchedEnquete, selectErrors } = this.state;
    const typeOptions = ['A', 'N', 'C'].map(el => ({ value: el, label: t(`enquete.field_type.${el}`) }));

    return fillFetchedEnquete ? (
      _.get(enquete, 'fetchedEnquete.used') ? (
        <WarningBand message={t('enquete.text.edit_impossible_in_used')} />
      ) : (
        <div style={{ paddingLeft: '20px', paddingRight: '20px' }} className={'enquete-page-container'}>
          <div className="table-info-container" style={{ position: 'relative' }}>
            <h2 style={{ display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
              <EnqueteSvg height="1em" width="1em" fill="#31c6b3" />{' '}
              <span style={{ marginLeft: '20px' }}>{t('sidebar_synchro.nav_link.enquiry_plural')}</span>
              <FormGroup style={{ marginLeft: '30px', width: '30%' }} className="formgroup-align">
                <Input
                  type="text"
                  name="enqname"
                  id="enqname"
                  placeholder={t('enquete.text.name_enquete_page')}
                  onChange={e => this.changeNameEnquete(e.target.value)}
                  value={name}
                />
              </FormGroup>
            </h2>
            <Button
              disabled={_.isEmpty(name) || _.size(fields.filter(this.checkField)) !== _.size(fields)}
              className="float-bottom-right"
              onClick={this.saveEnquete}
              style={{ minWidth: '20ch' }}
            >
              {t('all.button.register')}
            </Button>
            <CreatableSelect
              components={{
                DropdownIndicator: null,
              }}
              name="codeList"
              id="codeList"
              placeholder={t('enquete.text.placeholder_create_options')}
              onChange={this.userInputChange}
              value={codeList}
              isMulti
              isClearable
              menuIsOpen={false}
              onKeyDown={this.handleKeyDown}
              onInputChange={this.handleInputChange}
              onBlur={this.onBlur}
              inputValue={inputValue}
            />
            <span>{t('enquete.text.each_option_max_20_char')}</span>
            {_.range(_.size(fields)).map(el => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '6em',
                  marginTop: '20px',
                  marginBottom: '20px',
                }}
              >
                <div style={{ height: '100%', textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                  {this.getBadgeOfBeginningLine(el)}
                  <h3>
                    {t('enquete.text.field')} {el + 1}
                  </h3>
                </div>
                {false && (
                  <>
                    <CustomInput type="checkbox" name={`hidden_${el}`} id={`hidden_${el}`} label="Champ masqué" />
                    <CustomInput type="checkbox" name={`disable_${el}`} id={`disable_${el}`} label="Non modifiable" />
                  </>
                )}
                <FormGroup style={{ width: '28%' }} className="formgroup-align">
                  <Input
                    type="text"
                    name={`libelle_${el}`}
                    id={`libelle_${el}`}
                    placeholder={t('enquete.text.field_libelle')}
                    onChange={e => {
                      this.changeValue(el, 'libelle', e.target.value);
                    }}
                    value={_.get(fields, `[${el}].libelle`) || ''}
                  />
                  {_.get(fields, `[${el}].libelle`) && !this.checkLibelle(_.get(fields, `[${el}].libelle`)) && (
                    <FormText color="danger" inline={false}>
                      {t('enquete.text.libelle_max_16_char')}
                    </FormText>
                  )}
                </FormGroup>

                <Select
                  options={typeOptions}
                  id="select-type"
                  placeholder=""
                  onChange={e => {
                    this.changeValue(el, 'type', e.value);
                  }}
                  value={typeOptions.find(opt => opt.value === _.get(fields, `[${el}].type`))}
                />

                {_.get(fields, `${el}.type`) !== 'C' ? (
                  <>
                    <FormGroup className="formgroup-align" style={{ width: '10%' }}>
                      <Input
                        type="number"
                        name={`taille_${el}`}
                        id={`taille_${el}`}
                        placeholder={t('enquete.text.field_size')}
                        onChange={e => this.changeValue(el, 'taille', e.target.value)}
                        value={_.get(fields, `[${el}].taille`) || ''}
                      />
                      {_.get(fields, `[${el}].taille`) && !this.checkTaille(_.get(fields, `[${el}].taille`)) && (
                        <FormText color="danger" inline={false}>
                          {t('enquete.text.size_max_30')}
                        </FormText>
                      )}
                    </FormGroup>
                    <FormGroup className="formgroup-align" style={{ width: '30%' }}>
                      <Input
                        type={_.get(fields, `[${el}].type`) === 'A' ? 'text' : 'number'}
                        name={`default_${el}`}
                        id={`defaultValue_${el}`}
                        placeholder={t('enquete.text.field_default_value')}
                        onChange={e => this.changeValue(el, 'defaultValue', e.target.value)}
                        max={30}
                        value={_.get(fields, `[${el}].defaultValue`) || ''}
                      />
                      {_.get(fields, `[${el}].defaultValue`) &&
                        !this.checkDefaultValue(
                          _.get(fields, `[${el}].defaultValue`),
                          _.get(fields, `[${el}].taille`, 30)
                        ) && (
                          <FormText color="danger" inline={false}>
                            {t('enquete.text.default_value_max_size')} {_.get(fields, `[${el}].taille`, 30) || 30}
                          </FormText>
                        )}
                    </FormGroup>
                  </>
                ) : (
                  <>
                    <Select
                      options={_.get(fields, `[${el}].codes`) || []}
                      placeholder="Champ par défaut"
                      id={`defaultValue_list_${el}`}
                      onChange={e => this.changeValue(el, 'defaultValue', e.value)}
                      value={
                        !_.isEmpty(_.get(fields, `[${el}].defaultValue`))
                          ? this.createOption(_.get(fields, `[${el}].defaultValue`))
                          : ''
                      }
                      noOptionsMessage={() => t('enquete.text.no_field_selected')}
                      isSearchable
                    />
                    <div className={'select-sortable-container'}>
                      {_.find(selectErrors, (error: SelectError) => error.field == el) && (
                        <div className={'select-sortable-error-container'}>
                          <div className={'select-sortable-error-head'}>!</div>
                          <p>{selectErrors.find((error: SelectError) => error.field == el).message}</p>
                        </div>
                      )}
                      <SortableSelect
                        key={_.size(codeList)}
                        // react-sortable-hoc props:
                        axis="xy"
                        distance={4}
                        // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                        onSortEnd={elem => this.onSortEnd(elem, el)}
                        noOptionsMessage={() => t('enquete.text.no_field_exist')}
                        components={{
                          MultiValue: SortableMultiValue,
                        }}
                        closeMenuOnSelect={false}
                        isMulti
                        options={codeList}
                        className={`listeColumns${_.size(codeList)} select-sortable-select ${_.find(
                          selectErrors,
                          (error: SelectError) => error.field == el
                        ) && 'select-sortable-select-error'}`}
                        onChange={columns => {
                          this.changeCodeOfField(el, columns);
                        }}
                        value={_.get(fields, `[${el}].codes`) || ''}
                        placeholder={t('enquete.text.choices_list')}
                      />
                    </div>
                  </>
                )}
                <div
                  id={`removeLine${el}`}
                  className={`${_.size(fields) > 1 ? 'clickable' : ''} round`}
                  role="presentation"
                  onClick={() => _.size(fields) > 1 && this.removeField(el)}
                >
                  {' '}
                  <PoubelleBleue height="1em" width="1em" fill={_.size(fields) <= 1 && 'lightgray'} />
                  <UncontrolledTooltip placement="bottom" target={`removeLine${el}`}>
                    {' '}
                    {_.size(fields) > 1 ? t('enquete.text.delete_field') : t('enquete.text.delete_field_impossible')}
                  </UncontrolledTooltip>
                </div>
              </div>
            ))}
            {_.size(fields) < 5 && (
              <Row style={{ margin: 10 }}>
                <span
                  className="clickable round"
                  id="addLineFilter"
                  onClick={() => {
                    const copyFields = _.cloneDeep(this.state.fields);
                    copyFields.push({ type: 'A' });
                    this.setState({ fields: copyFields });
                  }}
                  role="presentation"
                >
                  <Ajout className="clickable " fill="currentcolor" height="1em" width="1em" />
                  <UncontrolledTooltip placement="bottom" target="addLineFilter">
                    {' '}
                    {t('enquete.text.add_field')}
                  </UncontrolledTooltip>
                </span>
              </Row>
            )}
          </div>
        </div>
      )
    ) : (
      <Loading />
    );
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(EnquetePageAdd)));
