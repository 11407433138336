import { authHeader, handleResponse } from '../_helpers';

async function getAllLayers() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch(`/api/mapbox/geojson`, requestOptions);
  return handleResponse(response);
}

async function getMeters() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch('/api/mapbox/meters', requestOptions);
  return handleResponse(response);
}

async function getGateways() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const response = await fetch(`/api/telereleve/gateways`, requestOptions);
  return handleResponse(response);
}

async function getPolygons() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch('/api/mapbox/polygons', requestOptions);
  return handleResponse(response);
}

async function postPolygons(coords: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(coords),
  };
  let response = await fetch(`/api/mapbox/polygons/create`, requestOptions);
  return handleResponse(response);
}

async function getPointsInPolygons() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let response = await fetch('/api/mapbox/inPolygons', requestOptions);
  return handleResponse(response);
}

async function sendRound(round: Object) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(round),
  };
  let response = await fetch('/api/mapbox/sendRound', requestOptions);
  return handleResponse(response);
}

const mapboxService = {
  getAllLayers,
  getMeters,
  getGateways,
  getPolygons,
  postPolygons,
  getPointsInPolygons,
  sendRound,
};

export default mapboxService;
