import React from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import confirm from '../_components';
import translate from '../_helpers/locale-helpers';

import Stats from '../WidgetTemplate/Stats';
import Select from 'react-select';
import { cloneDeep, isArray } from 'lodash';
import { withTranslation } from 'react-i18next';

interface State {
  selectedUsers: any;
  validated: boolean;
}

/**
 * @class LastComponent
 * @extends {React.Component<Props, State>}
 */
class LastComponent extends React.Component<any, State> {
  static getDerivedStateFromProps(props: any, state: State) {
    const { form, availableUsers } = props;
    if (form && form.users && state.selectedUsers === null) {
      let selectedUsers = cloneDeep(availableUsers).filter((it: any) => {
        let found = form.users.find((u: any) => u === it.id);
        if (undefined !== found) {
          return it;
        }
      });
      if (selectedUsers.length > 0) {
        selectedUsers = selectedUsers.map((it: any) => ({ label: it.userName, value: it.id }));
      }
      return {
        selectedUsers,
      };
    }
    if (state.validated) {
      return {
        selectedUsers: [],
      };
    }
    return state;
  }
  /**
   * @param {Props} props
   * @memberof LastComponent
   */
  constructor(props: any) {
    super(props);
    this.validate = this.validate.bind(this);
    this.state = {
      selectedUsers: null,
      validated: false,
    };
  }

  /**
   * Affiche une modal de confirmation de passage à
   * l'étape précédente, et y retourne en fonction
   * du choix de l'utilisateur
   *
   * @async
   * @method validate
   * @memberof LastComponent
   */
  async validate() {
    const { previousStep, t } = this.props;
    const { Fragment } = React;

    const result = await confirm({
      title: (
        <Fragment>
          <strong>{t('all.text.go_back')}</strong>
        </Fragment>
      ),
      message: t('all.text.go_backward_step'),
      confirmText: t('all.text.yes'),
      cancelText: t('all.text.no'),
      confirmColor: 'danger',
      cancelColor: 'primary',
    });
    if (result) {
      previousStep();
    }
  }

  /**
   * Gère le comportement à la soumission du
   * formulaire
   *
   * @param {Object} event Evènement
   * @param {Object} values Valeurs du formulaire
   * @memberof LastComponent
   */
  handleValidSubmit = (event: any, values: any) => {
    const { update, send } = this.props;
    const { selectedUsers } = this.state;
    if (selectedUsers.length > 0) {
      let newUsers = cloneDeep(selectedUsers);
      newUsers = newUsers.map((it: any) => it.value);
      this.setState({
        validated: true,
      });
      update('users', newUsers);
      update('saved', true);
      send();
    }
  };

  /**
   * Gère le comportement à la soumission
   * invalide du formulaire
   *Props
   * @param {Object} event Evènement
   * @param {Object} errors Erreurs
   * @param {Object} values Valeurs du formulaire
   * @memberof LastComponent
   */
  handleInvalidSubmit = (event: any, errors: any, values: any) => {
    this.setState({
      selectedUsers: values.users,
    });
  };

  /**
   * Formate la valeur de la condition passée en
   * paramètre
   *
   * @param {Object} condition La condition
   * @returns {string} La condition formatée
   * @method format
   * @memberof LastComponent
   */
  format = (condition: any) => {
    const { locales, t } = this.props;
    if (condition.conditionTitle === 'RangeConsumption') {
      if (Object.keys(condition.conditionValue).length > 0) {
        let threshold = [];
        if (condition.conditionValue.inf && condition.conditionValue.inf.length !== 0) {
          threshold.push(t('all.threshold.min_x_threshold', { value: condition.conditionValue.inf }));
        }
        if (condition.conditionValue.sup && condition.conditionValue.sup.length !== 0) {
          threshold.push(t('all.threshold.max_x_threshold', { value: condition.conditionValue.sup }));
        }
        return threshold.join(' - ');
      } else {
        return t('all.text.not_define');
      }
    }
    if (condition.conditionTitle === 'AlarmType') {
      return isArray(condition.conditionValue) && condition.conditionValue.length > 0
        ? condition.conditionValue.map((el: any) => t(`alarm.type.${el}`)).join(', ')
        : t('all.text.none_male');
    }
    if (condition.conditionTitle === 'GapConsumption') {
      return `${condition.conditionValue}%`;
    }
    if (condition.conditionType === 'Period') {
      return `${condition.conditionValue.value} ${translate(
        'fr',
        'conditionHelper',
        condition.conditionValue.name,
        locales.locale,
        'name'
      )}`;
    }
    if (condition.conditionType === 'HourPeriod') {
      if (Object.keys(condition.conditionValue).length > 0) {
        let threshold = [];
        if (condition.conditionValue.min && condition.conditionValue.min.length !== 0) {
          threshold.push(`${condition.conditionValue.min}h`);
        }
        if (condition.conditionValue.max && condition.conditionValue.max.length !== 0) {
          threshold.push(`${condition.conditionValue.max}h`);
        }
        return threshold.join(' - ');
      } else {
        return t('all.text.not_define');
      }
    }
    if (condition.conditionTitle === 'meters') {
      if (
        undefined === condition.conditionValue ||
        null === condition.conditionValue ||
        !isArray(condition.conditionValue)
      ) {
        return '';
      }
      return condition.conditionValue.join(', ');
    }
    return condition.conditionValue;
  };

  /**
   * Gère la multi sélection des alarmes
   *
   * @param {Array} types Types
   * @method handleChangeSelect
   * @memberof AlarmeOptionComponent
   */
  handleChangeSelect = (selectedUsers: any) => {
    this.setState({
      selectedUsers,
    });
  };

  /**
   * Construit le composant
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof LastComponent
   */
  render() {
    const { selectedUsers } = this.state;
    const { isActive, form, locales, availableUsers, t } = this.props;
    const { content } = form;
    const { dataSourceProperty } = content;
    const { condition } = dataSourceProperty;

    const users = availableUsers.map((it: any) => {
      return { label: it.userName, value: it.id };
    });

    if (!isActive) return null;
    return (
      <div className="abstractInModal">
        <h3>{t('all.text.summary')}</h3>
        <p>
          <span className="label-text">{t('all.alert.alert_name')} :</span> {content.name}
        </p>
        <p>
          <span className="label-text">{t('all.text.unity')} :</span>{' '}
          {translate('fr', 'unit', content.dataSourceProperty.alertUnit, locales.locale)}
        </p>
        <p>
          <span className="label-text">{t('all.alert.alert_type')} :</span>{' '}
          {translate('fr', 'alarmName', content.alertName, locales.locale)}
        </p>
        <p>
          <span className="label-text">{t('all.text.calculation_method')} :</span>{' '}
          {translate('fr', 'alarmCalculation', content.alertName, locales.locale)}
        </p>
        <div>
          {condition.map((c: any) => (
            <p key={c.conditionTitle}>
              <span className="label-text">
                {translate('fr', 'conditionTitle', c.conditionTitle, locales.locale)} :
              </span>{' '}
              {this.format(c)}
            </p>
          ))}
        </div>
        {availableUsers && null !== availableUsers && (
          <AvForm onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
            <Label for="users">{t('last.text.recipient_user_plural')}</Label>
            <Select
              options={users}
              isMulti
              name="users"
              id="users"
              onChange={this.handleChangeSelect}
              value={selectedUsers}
            />
            <div style={{ marginTop: '20px' }}>
              <Row>
                <Col md="4">
                  <Stats {...this.props} previousStep={this.validate} />
                </Col>
                <Col md="8" className="text-right">
                  {selectedUsers && selectedUsers.length > 0 ? (
                    <Button color="primary">{t('all.button.register')}</Button>
                  ) : (
                    <Button color="primary" disabled>
                      {t('all.button.register')}
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </AvForm>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { locales } = state;
  return {
    locales,
  };
}

const LastConnectedComponent = connect(mapStateToProps)(LastComponent);
const tr = withTranslation()(LastConnectedComponent);
export default tr;
