import React, { Component } from 'react';

export default class CircleChart extends Component {
  constructor() {
    super();
    this.draw = this.draw.bind(this);
  }

  componentDidMount() {
    window.onload = this.draw();
  }

  draw() {
    const { firstColor, secondColor, percent, canvasSize, fontSize, id } = this.props;
    const roundPercent = Math.round(percent);
    const canvas = document.getElementById(`canvas-${id}`);
    const ctx = canvas.getContext('2d');

    const circle = Math.PI * 2;
    const quart = Math.PI / 2;

    ctx.beginPath();
    window.devicePixelRatio = 2; //Clear Text
    const size = canvasSize;
    canvas.style.width = size + 'px';
    canvas.style.height = size + 'px';
    const scale = window.devicePixelRatio;
    canvas.width = Math.floor(size * scale);
    canvas.height = Math.floor(size * scale);

    ctx.scale(scale, scale);
    ctx.lineWidth = 1.2;
    ctx.font = '14px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.strokeStyle = 'black';

    const x = size / 2;
    const y = size / 2;

    ctx.strokeText(`${roundPercent}%`, x, y);

    ctx.beginPath();
    ctx.lineWidth = 5;
    ctx.shadowBlur = 5;
    ctx.shadowOffsetX = 2;
    ctx.shadowOffsetY = 2;
    ctx.strokeStyle = firstColor;
    ctx.shadowColor = firstColor;
    ctx.arc(canvasSize / 2, canvasSize / 2, canvasSize / 3, -quart, (circle * roundPercent) / 100 - quart, false);
    ctx.stroke();

    ctx.beginPath();
    ctx.shadowColor = 'transparent';
    ctx.lineWidth = 2;
    ctx.strokeStyle = secondColor;
    ctx.arc(
      canvasSize / 2,
      canvasSize / 2,
      canvasSize / 3,
      roundPercent >= 99 ? 0 : -quart * 1.04,
      roundPercent >= 99 ? 0 : (circle * (roundPercent + 1)) / 100 - quart,
      true
    );
    ctx.stroke();
  }

  render() {
    const { canvasSize, id } = this.props;
    return (
      <div>
        <canvas id={`canvas-${id}`} height={canvasSize} width={canvasSize} />
      </div>
    );
  }
}
